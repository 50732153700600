import { Injectable } from "@angular/core";

import { isNullOrUndefined } from "@ops/shared";
import { DemurrageStatus } from "@ops/shared/reference-data";

import { Fixture } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class ExternalVisibilityDemurrageStatusRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        if (isNullOrUndefined(fixture.demurrage) || isNullOrUndefined(fixture.demurrage.status)) {
            return warnings;
        }

        if (fixture.demurrage.status.id === DemurrageStatus.Fixed.id && fixture.demurrage.claims.length > 0) {
            const message = "Invalid Demurrage Status";
            const path = [new FixtureWarningPathSegment("demurrage", null), new FixtureWarningPathSegment("status", "Demurrage Status")];
            const warningHash = new WarningHashBuilder().withFixture(fixture.fixtureId).withCategory("data").withMessage(message).build();
            warnings.push(new FixtureWarning("data", message, path, dismissedWarningRecord(warningHash)));
        }

        return warnings;
    }
}
