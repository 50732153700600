import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, props } from "@ngrx/store";
import { tap } from "rxjs/operators";

import { ActionDataService } from "../../../../left-bar/actions/services/action-data.service";
import { LeftBarStateService } from "../../../../left-bar/left-bar-state.service";

/* ACTIONS */
export const editLiftingNominationTaskActionAction = createAction("[Lifting Nomination Task Actions] Edit Action", props<{ actionId: string }>());

/* EFFECTS */
export const editLiftingNominationTaskActionEffect$ = (actions$: Actions, leftBarStateService: LeftBarStateService, actionDataService: ActionDataService) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(editLiftingNominationTaskActionAction),
                tap(({ actionId }) => {
                    leftBarStateService.open();
                    actionDataService.setEditingActionIdValue(actionId);
                })
            ),
        { dispatch: false }
    );
