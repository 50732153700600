/**
 * Laytime Calculator data types with only required properties.
 * Note the use of number / DateTime vs string.
 */

import { DateTime } from "luxon";

import { DayOfWeek } from "@ops/shared";

import {
    AllowanceUnit,
    LaytimeCalculationRounding,
    LaytimeCalculationDurationUnit,
    QuantityUnit,
    Reversible,
    TimeAllowance,
    TimeSaved,
    ActivityLocationId,
    LaytimeEventId,
    ActivityCargoId,
    ActivityType
} from "../state";

export type LaytimeCalculationTerms = Readonly<{
    timeAllowance: TimeAllowance;
    durationUnit: LaytimeCalculationDurationUnit;
    rounding: LaytimeCalculationRounding;
    /**
     * The total allowed laytime expressed in hours. Applicable to Fixed time allowance.
     */
    fixedAllowanceHours?: number;
    /**
     * Specifies how despatch is calculated when laytime occurs outside of the working day or within the exclusion period.
     * Applicable to Dry Cargo sector.
     */
    timeSaved?: TimeSaved;
    /**
     * Indicates that the calculation is for a voyage that forms part of a CoA.
     * When true, any unused laytime allowance will be used to calculate a negative demurrage value using the Demurrage Rate.
     * Applicable to PCG sector.
     */
    demurrageBank?: boolean;
    /**
     * The demurrage rate expressed per the duration unit of the calculation.
     */
    demurrageRate?: number;
    /**
     * The despatch rate expressed per the duration unit of the calculation.
     * Applicable to Dry Cargo sector.
     */
    despatchRate?: number;
    /**
     * The detention rate expressed per the duration unit of the calculation.
     * Applicable to Non Fixed time allowance and Dry Cargo sector.
     */
    detentionRate?: number;
}>;

export type ActivityLocation = Readonly<{
    id: ActivityLocationId;
    exclusionStartDay?: DayOfWeek;
    exclusionStartTime?: string;
    exclusionEndDay?: DayOfWeek;
    exclusionEndTime?: string;
    timeZone?: string;
    activity?: ActivityType;
    /**
     * Indicates there are no despatch, time allowance or exclusions meaning the time spent
     * in port only counts if the Charterparty terms include clauses to charge damages for detention.
     * Applicable to Dry Cargo sector.
     */
    customaryQuickDespatch?: boolean;
    cargoes: ReadonlyArray<ActivityCargo>;
    laytimeEvents: ReadonlyArray<LaytimeEvent>;
}>;

export type ActivityCargo = Readonly<{
    id: ActivityCargoId;
    quantity?: number;
    quantityUnit: QuantityUnit;
    allowance?: number;
    allowanceUnit?: AllowanceUnit;
    extraHours?: number;
    reversible: Reversible;
}>;

export type LaytimeEvent = Readonly<{
    id: LaytimeEventId;
    date: DateTime;
    percentage?: number;
    cargoId?: string;
}>;

export const createLaytimeCalculationTerms = (
    timeAllowance: TimeAllowance,
    durationUnit: LaytimeCalculationDurationUnit,
    rounding: LaytimeCalculationRounding,
    values?: Partial<Omit<LaytimeCalculationTerms, "timeAllowance" | "durationUnit" | "rounding">>
): LaytimeCalculationTerms => ({
    ...values,
    timeAllowance,
    durationUnit,
    rounding
});
export const createActivityLocation = (
    id: string,
    cargoes?: ReadonlyArray<ActivityCargo>,
    laytimeEvents?: ReadonlyArray<LaytimeEvent>,
    values?: Partial<Omit<ActivityLocation, "id" | "cargoes" | "laytimeEvents">>
): ActivityLocation => ({
    ...values,
    id: id as ActivityLocationId,
    cargoes: cargoes ?? [],
    laytimeEvents: laytimeEvents ?? []
});
export const createActivityCargo = (id: string, reversible: Reversible, values?: Partial<Omit<ActivityCargo, "id" | "reversible">>): ActivityCargo => ({
    quantityUnit: "MT",
    ...values,
    id: id as ActivityCargoId,
    reversible
});
export const createLaytimeEvent = (id: string, date: DateTime, percentage?: number, cargoId?: ActivityCargoId): LaytimeEvent => ({
    id: id as LaytimeEventId,
    date,
    percentage,
    cargoId
});
