import { Observable, of, throwError } from "rxjs";
import { delay, mergeMap, retryWhen } from "rxjs/operators";

export const retryWithBackoff = (maxRetry = 7, backoffMs = 1000) => {
    let retries = maxRetry;

    return (src: Observable<unknown>) =>
        src.pipe(
            retryWhen((errors: Observable<unknown>) =>
                errors.pipe(
                    mergeMap((error) => {
                        if (retries-- > 0) {
                            const backoffTime = (maxRetry - retries) * backoffMs;
                            return of(error).pipe(delay(backoffTime));
                        }
                        return throwError(error);
                    })
                )
            )
        );
};
