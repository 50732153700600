<div>
    <table class="table table-striped">
        <thead>
        <tr>
            <th class="type-fixed">Type</th>
            <th>Cargo : Description</th>
            <th class="bl-fixed text-right">B/L (MT)</th>
            <th class="actual-freight-rate-fixed text-right">Actual Freight Rate</th>
            <th class="freight-spend-fixed text-right">Freight Spend</th>
            <th scope="col" class="button-column-fixed" *ngIf="!readonly"></th>
            <th scope="col" class="button-column-fixed" *ngIf="!readonly"></th>
        </tr>
    </thead>
    <tbody class="form-group">
        <tr *ngFor="let totalForm of totals; let i = index; last as isLast; trackBy: trackBy">
            <td data-cy="total-type">{{ totalForm.total.type }}</td>
            <td *ngIf="totalForm.total.type !== CargoTotalType.AdditionalFreight || readonly" data-cy="total-cargo-description-main-cargo">
                {{ totalForm.total.description }}
            </td>
            <td *ngIf="totalForm.total.type === CargoTotalType.AdditionalFreight && !readonly" class="input-cell">
                <input type="text"
                       [ngrxFormControlState]="totalForm.form.controls.description"
                       ngrxUpdateOn="blur"
                       class="form-control"
                       data-test-description/>
            </td>
            <td class="text-right" data-cy="total-bl">{{ totalForm.total.bl | opsNumber: false:2:4 }}</td>
            <td class="text-right" data-cy="total-actual-freight-rate">
                {{ totalForm.total.actualFreightRate | opsNumber: false:2:4 }} {{ totalForm.total?.freightRateUnit }}
            </td>
            <td *ngIf="totalForm.total.type !== CargoTotalType.AdditionalFreight || readonly" warningActive
                [ngrxFormControlId]="getControlId(i, 'freightSpend')" class="text-right" data-cy="total-freight-spend-main-cargo">
                {{ totalForm.total.freightSpend | opsNumber: false:2:4 }}
            </td>
            <td *ngIf="totalForm.total.type === CargoTotalType.AdditionalFreight && !readonly" warningActive
                [ngrxFormControlId]="getControlId(i, 'freightSpend')" class="input-cell">
                <input
                    type="text"
                    class="form-control text-right"
                    [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                    [ngrxFormControlState]="totalForm.form.controls.freightSpend"
                    ngrxUpdateOn="blur"
                    data-test-freightSpend
                />
            </td>
            <td class="button-cell" *ngIf="!readonly">
                <button
                    *ngIf="canAddTotal && isLast"
                    [disabled]="readonly"
                    (click)="add.emit()"
                    type="button"
                    class="btn-add has-icon icon--add-circle-outline"
                    data-test-add-additionalfreight
                ></button>
            </td>
            <td class="button-cell" *ngIf="!readonly">
                <button
                    *ngIf="totalForm.total.type === CargoTotalType.AdditionalFreight"
                    (click)="remove.emit(totalForm.total.additionalFreightExpenseId)"
                    type="button"
                    class="btn-delete has-icon icon--delete"
                    data-test-delete-additionalfreight
                ></button>
            </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
            <td></td>
            <td></td>
            <td class="text-right">{{ blTotal | opsNumber: false:2:4 }}</td>
            <td></td>
            <td warningActive [ngrxFormControlId]="getControlId('total', 'freightSpend')" 
                class="text-right" data-cy="final-total-freight-spend"
            >
                {{ freightSpendTotal | opsNumber: false:2:4 }}
            </td>
            <td *ngIf="!readonly"></td>
            <td *ngIf="!readonly"></td>
        </tr>
        </tfoot>
    </table>
    <div class="d-flex flex-row-reverse" *ngIf="freightSpendTotal > 0">
        <button
            type="button"
            class="btn btn-link p-0 m-0 border-0 shadow-none btn-action"
            data-test-generateFreightInvoice
            (click)="generateFreightInvoice()">
            GENERATE FREIGHT INVOICE
        </button>
    </div>
</div>

