import { ActionType, On, on } from "@ngrx/store";
import { DateTime } from "luxon";
import { saveLiftingHeaderSuccessAction } from "../lifting-header/form/save-header";
import { CoasState, LiftingId, User } from "../model";
import { createFixtureSuccessAction } from "./create-fixture";
import { liftingStateReducer } from "./reducer";
import { removeVesselNominationSuccessAction } from "./vessel-nomination/form/remove-vessel-nomination";
import {
    renominateNewVesselSuccessAction,
    saveVesselNominationSuccessAction,
    updateVesselNominationSuccessAction
} from "./vessel-nomination/form/save-vessel-nomination";
import {
    acceptVesselNominationSuccessAction,
    preferVesselNominationSuccessAction,
    rejectVesselNominationSuccessAction,
    renominateVesselSuccessAction,
    reviewVesselNominationSuccessAction
} from "./vessel-nomination/update-status";

/* REDUCERS */
export const updateTimestampReducer: On<CoasState> = on(
    saveLiftingHeaderSuccessAction,
    saveVesselNominationSuccessAction,
    updateVesselNominationSuccessAction,
    removeVesselNominationSuccessAction,
    acceptVesselNominationSuccessAction,
    rejectVesselNominationSuccessAction,
    reviewVesselNominationSuccessAction,
    preferVesselNominationSuccessAction,
    renominateVesselSuccessAction,
    renominateNewVesselSuccessAction,
    createFixtureSuccessAction,
    (state: CoasState, { liftingId, user }: ActionType<{ liftingId: LiftingId, user: User }> ) =>
        liftingStateReducer(state, liftingId, (liftingState) => {
            return {
                ...liftingState,
                lifting: { ...liftingState.lifting, lastUpdatedAt: DateTime.utc().toISO(), lastUpdatedBy: user }
            };
        })
);
