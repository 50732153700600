import { createAction, On, on, props } from "@ngrx/store";
import { updateGroup } from "ngrx-forms";

import { opsAddArrayControl } from "@ops/state";

import {
    activityForm,
    associatedCargoForm,
    berthForm,
    createDestinationId,
    destinationExpandedKey,
    destinationForm,
    DestinationId,
    FixturesState,
    getDivision,
    laytimeEventForm,
    VoyageForm
} from "../../model";
import { getNextId } from "../../utils";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const cloneDestinationAction = createAction("[Voyage Form] Clone Destination", props<{ destinationId: DestinationId }>());

/* REDUCERS */
export const cloneDestinationReducer: On<FixturesState> = on(cloneDestinationAction, (state, { destinationId }) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        const division = getDivision(fixtureState);
        const destinations = voyageState.form.value.destinations;
        const sourceDestination = destinations.find((x) => x.id === destinationId);

        if (!sourceDestination) {
            throw Error(`Unable to clone destination. Invalid destinationId=${destinationId}`);
        }

        const sourceDestinationIndex = destinations.indexOf(sourceDestination);
        const newDestinationId = createDestinationId();

        const clonedDestination = {
            ...destinationForm(division, newDestinationId, getNextId(voyageState.form.value.destinations, "destinationId")),
            location: sourceDestination.location,
            berths: sourceDestination.berths.map((sourceBerth) => ({
                ...berthForm(division, sourceBerth.id, sourceBerth.berthId),
                activities: sourceBerth.activities.map((sourceActivity) => ({
                    ...activityForm(division, sourceActivity.activityId, sourceActivity.legacyActivityId),
                    type: sourceActivity.type,
                    associatedCargoes: sourceActivity.associatedCargoes.map((sourceCargo) =>
                        associatedCargoForm(division, sourceCargo.associatedCargoId, sourceCargo.legacyAssociatedCargoId, sourceCargo.cargoId)
                    ),
                    laytimeEvents: sourceActivity.laytimeEvents
                        ? sourceActivity.laytimeEvents.map((sourceLaytimeEvent) => ({
                              ...laytimeEventForm(division, sourceLaytimeEvent.laytimeEventId),
                              type: sourceLaytimeEvent.type
                          }))
                        : null
                }))
            }))
        };

        return {
            ...voyageState,
            form: updateGroup<VoyageForm>({
                destinations: opsAddArrayControl(clonedDestination, sourceDestinationIndex + 1, { markAsTransient: true, scrollIntoView: true })
            })(voyageState.form),
            expandedSections: {
                ...voyageState.expandedSections,
                [destinationExpandedKey(newDestinationId)]: true
            }
        };
    })
);
