import { createFormGroupState } from "ngrx-forms";
import * as R from "ramda";

import { ActivityCargoId } from "./activity-cargo";
import { ActivityLocation } from "./activity-location";
import { LaytimeCalculation } from "./laytime-calculation";
import { AssociatedCargo, AssociatedCargoId, CargoId, Destination, Voyage, VoyageActivityId } from "./voyage";

export const addActivityLocationsFilter = ["Locations", "Cargoes"] as const;
export type AddActivityLocationsFilter = typeof addActivityLocationsFilter[number];

export type CargoFilterForm = Readonly<{
    cargoId: CargoId;
    selected: boolean;
    imported: boolean;
}>;

export type VoyageActivityFilterForm = Readonly<{
    voyageActivityId: VoyageActivityId;
    selected: boolean;
    imported: boolean;
}>;

export type AssociatedCargoSelectionForm = Readonly<{
    associatedCargoId: AssociatedCargoId;
    cargoId: CargoId;
    voyageActivityId: VoyageActivityId;
    selected: boolean;
    imported: boolean;
}>;

export type AddActivityLocationsForm = Readonly<{
    filter: AddActivityLocationsFilter;
    cargoes: ReadonlyArray<CargoFilterForm>;
    voyageActivities: ReadonlyArray<VoyageActivityFilterForm>;
    associatedCargoes: ReadonlyArray<AssociatedCargoSelectionForm>;
    importPortTimes: boolean;
}>;

export const toCargoFilterForms = (voyage: Voyage, calculation: LaytimeCalculation) => {
    const associatedCargoes = R.pipe(
        R.map((d: Destination) => d.berths),
        R.flatten,
        R.map((b) => b.cargoBerthActivities),
        R.flatten,
        R.map((cba) => cba.associatedCargoes),
        R.flatten,
        R.map(({ cargoId, id }) => ({ cargoId, id }))
    )(voyage.destinations);

    const activityCargoIds = R.pipe(
        R.map((al: ActivityLocation) => al.cargoes),
        R.flatten,
        R.map((ac) => ac.id)
    )(calculation.activityLocations);

    const importedCargoIds = associatedCargoes.filter((ac) => activityCargoIds.includes(ac.id as ActivityCargoId)).map((ac) => ac.cargoId);

    return voyage.cargoes
        .filter((x) => x.cargoProduct)
        .map((c) => {
            const imported = importedCargoIds.includes(c.id);
            return { cargoId: c.id, selected: imported, imported };
        });
};

export const toVoyageActivityFilterForms = (voyage: Voyage, calculation: LaytimeCalculation) =>
    R.pipe(
        R.map((d: Destination) => d.berths),
        R.flatten,
        R.map((b) => b.cargoBerthActivities),
        R.flatten,
        R.map((cba) => {
            const imported = !!calculation.activityLocations.find((x) => x.voyageActivityId === cba.id);
            return { voyageActivityId: cba.id, imported, selected: imported };
        })
    )(voyage.destinations.filter((d) => d.location));

export const toAssociatedCargoSelectionForms = (voyage: Voyage, calculation: LaytimeCalculation) => {
    const cargoIds = voyage.cargoes.filter((x) => x.cargoProduct).map((x) => x.id);
    return R.pipe(
        R.map((d: Destination) => d.berths),
        R.flatten,
        R.map((b) => b.cargoBerthActivities),
        R.flatten,
        R.map((cba) =>
            R.map((ac: AssociatedCargo) => {
                const imported = !!calculation.activityLocations.find((x) => x.voyageActivityId === cba.id)?.cargoes.find((x) => x.id === ac.id);
                return {
                    associatedCargoId: ac.id,
                    selected: imported,
                    cargoId: ac.cargoId,
                    voyageActivityId: cba.id,
                    imported
                };
            })(cba.associatedCargoes.filter((x) => cargoIds.includes(x.cargoId)))
        ),
        R.flatten
    )(voyage.destinations.filter((d) => d.location));
};

export const toAddActivityLocationsForm = (voyage: Voyage, calculation: LaytimeCalculation): AddActivityLocationsForm => ({
    filter: "Locations",
    cargoes: toCargoFilterForms(voyage, calculation),
    voyageActivities: toVoyageActivityFilterForms(voyage, calculation),
    associatedCargoes: toAssociatedCargoSelectionForms(voyage, calculation),
    importPortTimes: false
});

export const addActivityLocationsFormKey = "addActivityLocationsForm";
export const createAddActivityLocationsFormState = (voyage: Voyage, calculation: LaytimeCalculation) =>
    createFormGroupState(`${voyage.voyageId}.${addActivityLocationsFormKey}`, toAddActivityLocationsForm(voyage, calculation));
