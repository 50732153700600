import { AbstractControl, ValidatorFn } from "@angular/forms";

import { LocationFormModel } from "../../../../shared/models/form-models/location.model";

export class DeliveryLocationsValidator {
    static validate(): ValidatorFn {
        return (control: AbstractControl): null => {
            const deliveryLocationsControl = control.get("deliveryLocations");
            const actualDeliveryDateControl = control.get("actualDeliveryDate");

            if (deliveryLocationsControl && actualDeliveryDateControl) {
                const locations = <LocationFormModel[]>deliveryLocationsControl.value;
                const actualDeliveryDate = actualDeliveryDateControl.value;

                if (actualDeliveryDate && locations && locations.length > 1) {
                    deliveryLocationsControl.setErrors({ exactPortIsNotSpecified: true });
                }
            }

            return null;
        };
    }
}
