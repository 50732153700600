import { unbox } from "ngrx-forms";

import { CargoBerthActivityType, LaytimeEventType } from "@ops/shared/reference-data";

import { Division } from "../../shared/models/enums/division";
import { ActivityForm, ActivityId, activityLastLaytimeEvent } from "./activity";
import { AssociatedCargoId } from "./associated-cargo";
import { BerthForm, BerthId, berthLastLaytimeEvent } from "./berth";
import { CargoForm, CargoId } from "./cargo";
import { DestinationForm, DestinationId, destinationLastLaytimeEvent } from "./destination";
import { LaytimeEventForm } from "./laytime-event";

export declare type VoyageExpandedSections = Readonly<{
    [key: string]: boolean;
    destinations: boolean;
    totals: boolean;
    notes: boolean;
}>;

export const CARGOES_EXPANDED_KEY = "cargoes";
export const cargoExpandedKey = (cargoId: CargoId) => `${CARGOES_EXPANDED_KEY}/${cargoId}`;

export const defaultCargoExpanded = (division: Division): boolean => division === Division.specialisedProducts;

export const defaultCargoesExpanded = (division: Division, cargoForms: ReadonlyArray<CargoForm>): { [key: string]: boolean } => {
    const map: { [key: string]: boolean } = {};

    for (const cargoForm of cargoForms) {
        map[cargoExpandedKey(cargoForm.cargoId)] = defaultCargoExpanded(division);
    }

    return map;
};

export const DESTINATIONS_EXPANDED_KEY = "destinations";
export const BERTHS_EXPANDED_KEY = "berths";
export const ACTIVITIES_EXPANDED_KEY = "activities";
export const ASSOCIATED_CARGOES_EXPANDED_KEY = "associated-cargoes";

export const destinationExpandedKey = (destinationId: DestinationId) => `${DESTINATIONS_EXPANDED_KEY}/${destinationId}`;
export const destinationNotesExpandedKey = (destinationId: DestinationId) => `${DESTINATIONS_EXPANDED_KEY}/${destinationId}/notes`;

export const berthExpandedKey = (destinationId: DestinationId, berthId: BerthId) => `${destinationExpandedKey(destinationId)}/${BERTHS_EXPANDED_KEY}/${berthId}`;

export const activityExpandedKey = (destinationId: DestinationId, berthId: BerthId, activityId: ActivityId) =>
    `${berthExpandedKey(destinationId, berthId)}/${ACTIVITIES_EXPANDED_KEY}/${activityId}`;

export const associatedCargoExpandedKey = (destinationId: DestinationId, berthId: BerthId, activityId: ActivityId, associatedCargoId: AssociatedCargoId) =>
    `${activityExpandedKey(destinationId, berthId, activityId)}/${ASSOCIATED_CARGOES_EXPANDED_KEY}/${associatedCargoId}`;

export const defaultDestinationExpanded = (division: Division, destination: DestinationForm): boolean => shouldBeExpanded(division, destinationLastLaytimeEvent(destination));

export const defaultDestinationNotesExpanded = (destination: DestinationForm): boolean => !!destination.comments || !!destination.instructions;

export const defaultBerthExpanded = (division: Division, berth: BerthForm): boolean => shouldBeExpanded(division, berthLastLaytimeEvent(berth));

export const defaultActivityExpanded = (division: Division, activity: ActivityForm): boolean =>
    //For now the Specialised Products distinction is made here until we align the other divisions destination expansion logic with Specialised expansion logic.
    division === Division.specialisedProducts ? shouldBeExpandedForSpecialised(activity) : shouldBeExpanded(division, activityLastLaytimeEvent(activity));

export const defaultDestinationsExpanded = (division: Division, destinationForms: ReadonlyArray<DestinationForm>): { [key: string]: boolean } => {
    const map: { [key: string]: boolean } = {};

    if (division === Division.specialisedProducts) {
        return defaultDestinationsExpandedForSpecialised(destinationForms, map, division);
    } else {
        for (const destination of destinationForms) {
            map[destinationExpandedKey(destination.id)] = defaultDestinationExpanded(division, destination);
            map[destinationNotesExpandedKey(destination.id)] = defaultDestinationNotesExpanded(destination);

            for (const berth of destination.berths) {
                map[berthExpandedKey(destination.id, berth.id)] = defaultBerthExpanded(division, berth);

                for (const activity of berth.activities) {
                    map[activityExpandedKey(destination.id, berth.id, activity.activityId)] = defaultActivityExpanded(division, activity);
                    for (const acc of activity.associatedCargoes) {
                        map[associatedCargoExpandedKey(destination.id, berth.id, activity.activityId, acc.associatedCargoId)] = false;
                    }
                }
            }
        }
    }

    return map;
};

//This will create the default expand settings for destination, berths, and activities for Specialised
function defaultDestinationsExpandedForSpecialised(destinationForms: readonly DestinationForm[], map: { [key: string]: boolean }, division: Division) {
    for (const destination of destinationForms) {
        map[destinationNotesExpandedKey(destination.id)] = defaultDestinationNotesExpanded(destination);

        if (destination.berths && destination.berths.length === 0) {
            map[destinationExpandedKey(destination.id)] = true;
            continue;
        }

        let defaultAnyBerthShouldBeExpanded = false;

        for (const berth of destination.berths) {
            let defaultAnyActivityShouldBeExpanded = false;

            if (berth.activities && berth.activities.length === 0) {
                defaultAnyActivityShouldBeExpanded = true;
            }

            for (const activity of berth.activities) {
                const activityExpanded = defaultActivityExpanded(division, activity);

                map[activityExpandedKey(destination.id, berth.id, activity.activityId)] = activityExpanded;
                defaultAnyActivityShouldBeExpanded = defaultAnyActivityShouldBeExpanded || activityExpanded;

                for (const acc of activity.associatedCargoes) {
                    map[associatedCargoExpandedKey(destination.id, berth.id, activity.activityId, acc.associatedCargoId)] = false;
                }
            }

            map[berthExpandedKey(destination.id, berth.id)] = defaultAnyActivityShouldBeExpanded;
            //Atleast one activity should be expandable for a berth to be expandable
            defaultAnyBerthShouldBeExpanded = defaultAnyBerthShouldBeExpanded || defaultAnyActivityShouldBeExpanded;
        }

        //Atleast one berth should be expandable for a destination to be expandable
        map[destinationExpandedKey(destination.id)] = defaultAnyBerthShouldBeExpanded;
    }

    return map;
}

/**
 * Returns true if the section should be expanded by default.
 *
 * That is roughly, does the last berth/activity/laytime event with a (division specific) type and date filled.
 */
function shouldBeExpanded(division: Division, lastLaytimeEvent: LaytimeEventForm): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const collapseLastLaytimeEventType: LaytimeEventType = (<any>{
        [Division.dryCargo]: LaytimeEventType.CargoCompleted,
        [Division.tankers]: LaytimeEventType.Sailed,
        [Division.gas]: LaytimeEventType.Sailed
    })[division];

    if (!collapseLastLaytimeEventType || !lastLaytimeEvent || !lastLaytimeEvent.eventDate) {
        return true;
    }

    const lastLaytimeEventType = unbox(lastLaytimeEvent.type);

    return !lastLaytimeEventType || lastLaytimeEventType.id !== collapseLastLaytimeEventType.id;
}

function shouldBeExpandedForSpecialised(activity: ActivityForm): boolean {
    const laytimeEvents = activity.laytimeEvents ?? [];

    if (laytimeEvents.length === 0) {
        return true;
    }

    //The Laytime Event that determines the collapsability of an activity depends on Activity type
    if (CargoBerthActivityType.isLoadOrDischarge(unbox(activity.type))) {
        const associatedCargoes = activity.associatedCargoes || [];

        //If there are not enough laytime events to match with every cargo then the activity should be expanded
        if (laytimeEvents.length < associatedCargoes.length) {
            return true;
        }

        const hosesDisconnectedEvents =
            laytimeEvents.filter((l) => {
                const laytimeEventType = unbox(l.type);
                return laytimeEventType?.id === LaytimeEventType.HosesDisconnected.id && l.eventDate;
            }) || [];

        //If there are multiple cargoes then every cargo should have
        //the Hoses Disconnected laytime event with date to make it collapsable
        if (hosesDisconnectedEvents.length < associatedCargoes.length) {
            return true;
        }

        if (associatedCargoes.length <= 1) {
            return hosesDisconnectedEvents.length === 0;
        } else {
            return !associatedCargoes.every((cargo) => hosesDisconnectedEvents.some((l) => l.cargoId === cargo.cargoId));
        }
    } else {
        for (let i = laytimeEvents.length - 1; i >= 0; i--) {
            const laytimeEventType = unbox(laytimeEvents[i].type);
            if (laytimeEventType?.id === LaytimeEventType.Sailed.id && laytimeEvents[i].eventDate) {
                return false;
            }
        }
        return true;
    }
}
