import { createAction, on, On, props } from "@ngrx/store";
import { FixturesState, VoyageId } from "../model";

// TODO: (JC NGRX) Unit tests

/* ACTIONS */

/**
 * @internal
 */
export const routerSetCurrentVoyageAction = createAction("[Router] Set Current Voyage", props<{ voyageId: VoyageId }>());

/* REDUCERS */
export const routerSetCurrentVoyageReducer: On<FixturesState> = on(routerSetCurrentVoyageAction, (state, action) => {
    if (state.currentVoyageId !== action.voyageId) {
        return {
            ...state,
            currentVoyageId: action.voyageId
        };
    }

    return state;
});
