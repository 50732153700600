import { box, Boxed, unbox } from "ngrx-forms";

import { DateRange, SeaNetLocation } from "../../../../fixture/shared/models";
import { QuantityModel } from "../../../../fixture/shared/models/form-models";
import { CargoProduct } from "../../../../fixture/state";
import { getFlagImageUrl } from "../../utils";
import { Cargo, CargoId, getNewCargoId } from "../cargo";
import {
    coaTypeToFreightRateUnit,
    coaTypeToQuantityUnit,
    coaTypeToToleranceOption,
    coaTypeToToleranceUnit,
    freightRateUnitToCoaType,
    LiftingCargoFreightRateUnit,
    quantityUnitToCoaType,
    toleranceOptionToCoaType,
    toleranceUnitToCoaType
} from "../cargo-type-mappings";
import { Location } from "../location";
import { Lifting, LiftingCargoPlanStatus } from "./lifting";

export type LiftingCargoLocation = Location &
    Readonly<{
        eta?: DateRange;
    }>;

export type LiftingCargoListItemLocation = LiftingCargoLocation &
    Readonly<{
        flagImgUrl: string;
    }>;

const liftingCargoLocationToListItem = (cdnUrl: string) => (source: LiftingCargoLocation): LiftingCargoListItemLocation => ({
    ...source,
    flagImgUrl: getFlagImageUrl(cdnUrl, source.countryUnCode)
});

export type LiftingCargo = Cargo &
    Readonly<{
        orderId: string;
        freightRate: string;
        freightRateUnit: LiftingCargoFreightRateUnit;
        loadLocations: ReadonlyArray<LiftingCargoLocation>;
        dischargeLocations: ReadonlyArray<LiftingCargoLocation>;
    }>;

export type LiftingCargoListItem = Readonly<{
    cargoId: CargoId;
    orderId: string;
    productName: string;
    quantity?: number;
    quantityUnit: string;
    minTolerance?: number;
    maxTolerance?: number;
    toleranceUnit: string;
    toleranceOption: string;
    freightRate?: number;
    freightRateUnit: string;
    loadLocations: ReadonlyArray<LiftingCargoLocation>;
    dischargeLocations: ReadonlyArray<LiftingCargoLocation>;

    isVisible: boolean;
    error?: Error;
}>;

export const liftingCargoToListItem = (cdnUrl: string) => (source: LiftingCargo): LiftingCargoListItem => ({
    cargoId: source.cargoId,
    orderId: source.orderId,
    productName: source.name,
    quantity: source.quantity && Number(source.quantity),
    quantityUnit: coaTypeToQuantityUnit(source.quantityUnit),
    minTolerance: source.minTolerance && Number(source.minTolerance),
    maxTolerance: source.maxTolerance && Number(source.maxTolerance),
    toleranceUnit: coaTypeToToleranceUnit(source.toleranceUnit)?.replace("+- ", ""),
    toleranceOption: coaTypeToToleranceOption(source.toleranceOption),
    freightRate: source.freightRate && Number(source.freightRate),
    freightRateUnit: coaTypeToFreightRateUnit(source.freightRateUnit),
    loadLocations: source.loadLocations.map(liftingCargoLocationToListItem(cdnUrl)),
    dischargeLocations: source.dischargeLocations.map(liftingCargoLocationToListItem(cdnUrl)),
    isVisible: true
});

export type LiftingCargoLaycanForm = Readonly<{
    laycan: Boxed<DateRange>;
    cargoPlanStatus: LiftingCargoPlanStatus;
}>;

export const liftingCargoLaycanForm = (): LiftingCargoLaycanForm => ({
    laycan: box(null),
    cargoPlanStatus: "Tentative"
});

export const liftingToCargoLaycanForm = (source: Lifting): LiftingCargoLaycanForm => ({
    laycan: box(source.cargoLaycan),
    cargoPlanStatus: source.cargoPlanStatus
});

export type LiftingCargoLocationForm = Readonly<{
    location?: Boxed<SeaNetLocation>;
    eta?: Boxed<DateRange>;
}>;

export const liftingCargoLocationForm = (): LiftingCargoLocationForm => ({
    location: box(null),
    eta: box(null)
});

const liftingCargoLocationToForm = (source: LiftingCargoLocation): LiftingCargoLocationForm => ({
    location: box(
        source.locationId
            ? <SeaNetLocation>{
                  locationId: source.locationId,
                  displayName: source.name,
                  countryUnCode: source.countryUnCode,
                  countryName: source.countryName
              }
            : null
    ),
    eta: box(source.eta)
});

const formToLiftingCargoLocation = (source: LiftingCargoLocationForm): LiftingCargoLocation => {
    const location = unbox(source.location);

    return {
        countryName: location?.countryName,
        countryUnCode: location?.countryUnCode,
        locationId: location?.locationId,
        name: location?.displayName,
        eta: unbox(source.eta)
    };
};

export type LiftingCargoForm = Readonly<{
    cargoId: CargoId;
    orderId: string;
    cargoProduct: Boxed<CargoProduct>;
    quantity: Boxed<QuantityModel>;
    minTolerance?: number;
    maxTolerance?: number;
    toleranceUnit: string;
    toleranceOption: string;
    freightRate: Boxed<QuantityModel>;
    loadLocations: ReadonlyArray<LiftingCargoLocationForm>;
    dischargeLocations: ReadonlyArray<LiftingCargoLocationForm>;
}>;

export const liftingCargoForm = (): LiftingCargoForm => ({
    cargoId: getNewCargoId(),
    orderId: null,
    cargoProduct: box(null),
    quantity: box({ value: null, unit: null }),
    minTolerance: null,
    maxTolerance: null,
    toleranceUnit: null,
    toleranceOption: null,
    freightRate: box({ value: null, unit: null }),
    loadLocations: [liftingCargoLocationForm()],
    dischargeLocations: [liftingCargoLocationForm()]
});

export const liftingCargoToForm = (source: LiftingCargo): LiftingCargoForm => ({
    cargoId: source.cargoId,
    orderId: source.orderId,
    cargoProduct: box(source.productId && source.name && { id: source.productId, name: source.name }),
    quantity: box({ value: source.quantity && Number(source.quantity), unit: coaTypeToQuantityUnit(source.quantityUnit) }),
    minTolerance: source.minTolerance && Number(source.minTolerance),
    maxTolerance: source.maxTolerance && Number(source.maxTolerance),
    toleranceUnit: coaTypeToToleranceUnit(source.toleranceUnit),
    toleranceOption: coaTypeToToleranceOption(source.toleranceOption),
    freightRate: box({ value: source.freightRate && Number(source.freightRate), unit: coaTypeToFreightRateUnit(source.freightRateUnit) }),
    loadLocations: source.loadLocations.map(liftingCargoLocationToForm),
    dischargeLocations: source.dischargeLocations.map(liftingCargoLocationToForm)
});

export const formToLiftingCargo = (source: LiftingCargoForm): LiftingCargo => {
    const cargoProduct = unbox(source.cargoProduct);
    const quantity = unbox(source.quantity);
    const freightRate = unbox(source.freightRate);

    return {
        cargoId: source.cargoId,
        orderId: source.orderId,
        productId: cargoProduct?.id,
        name: cargoProduct?.name,
        quantity: quantity.value && String(quantity.value),
        quantityUnit: quantityUnitToCoaType(quantity.unit),
        minTolerance: source.minTolerance && String(source.minTolerance),
        maxTolerance: source.maxTolerance && String(source.maxTolerance),
        toleranceUnit: toleranceUnitToCoaType(source.toleranceUnit),
        toleranceOption: toleranceOptionToCoaType(source.toleranceOption),
        freightRate: freightRate.value && String(freightRate.value),
        freightRateUnit: freightRateUnitToCoaType(freightRate.unit),
        loadLocations: source.loadLocations.map(formToLiftingCargoLocation),
        dischargeLocations: source.dischargeLocations.map(formToLiftingCargoLocation)
    };
};

const isInvalidCargoLocation = (cargoLocation: LiftingCargoLocation) => !cargoLocation.locationId || !cargoLocation.eta;

const isInvalidLiftingCargo = (cargo: LiftingCargo) =>
    !cargo.quantity ||
    !cargo.freightRate ||
    !cargo.loadLocations.length ||
    !cargo.dischargeLocations.length ||
    cargo.loadLocations.some(isInvalidCargoLocation) ||
    cargo.dischargeLocations.some(isInvalidCargoLocation);

export declare type LiftingCargoValidationResult = { isInvalid: false } | { isInvalid: true; invalidCargoes: ReadonlyArray<CargoId> };

export const validateLiftingCargoes = (lifting: Lifting): LiftingCargoValidationResult => {
    const invalidCargoes = lifting.cargoes.filter(isInvalidLiftingCargo).map((x) => x.cargoId);
    return invalidCargoes.length || !lifting.cargoes.length || !lifting.cargoLaycan ? { isInvalid: true, invalidCargoes } : { isInvalid: false };
};
