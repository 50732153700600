import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { markAsTouched } from "ngrx-forms";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { CoaHttpService } from "../../../services/coa-http.service";
import { currentCoaStateReducer } from "../../coa/reducer";
import { selectCurrentCoa } from "../../coa/selectors";
import { Cargo, CoaFeatureState, CoasState, formToCargo, getCurrentCoaState } from "../../model";
import { selectCargoForm } from "../selectors";

/* ACTIONS */
const SAVE_CARGO_ACTION_NAME = "[Coa Cargo Form] Save Cargo";
const SAVE_CARGO_SUCCESS_ACTION_NAME = "[Coa Cargo Form] Save Cargo Success";
export const saveCargoAction = createAction(SAVE_CARGO_ACTION_NAME);
export const saveNewCargoAction = createAction("[Coa Cargo Form] Save New Cargo");
export const saveCargoSuccessAction = createAction(SAVE_CARGO_SUCCESS_ACTION_NAME);
export const saveNewCargoSuccessAction = createAction("[Coa Cargo Form] Save New Cargo Success");
export const saveCargoFailAction = createAction(`${SAVE_CARGO_ACTION_NAME} Fail`, props<{ error: Error }>());

/* REDUCERS */
export const saveCargoReducer: On<CoasState> = on(saveCargoAction, saveNewCargoAction, (state) =>
    currentCoaStateReducer(state, (coaState) => {
        const form = markAsTouched(coaState.cargoForm);
        return { ...coaState, cargoForm: form, cargoFormSaveStatus: form.isValid ? "persisting" : "invalid" };
    })
);

export const saveCargoFailReducer: On<CoasState> = on(saveCargoFailAction, (state) =>
    currentCoaStateReducer(state, (coaState) => ({ ...coaState, cargoFormSaveStatus: "failed" }))
);

export const saveCargoSuccessReducer: On<CoasState> = on(saveCargoSuccessAction, saveNewCargoSuccessAction, (state, action) => {
    const currentCoa = getCurrentCoaState(state);
    const form = currentCoa?.cargoForm;

    if (!form) {
        return state;
    }

    const coaCargoes = currentCoa.coa.cargoes;
    let updatedCargoes: Cargo[] = [];
    if (action.type === SAVE_CARGO_SUCCESS_ACTION_NAME) {
        const existingCargo = coaCargoes.find((c) => c.cargoId === form.value.cargoId);
        const existingCargoIndex = coaCargoes.indexOf(existingCargo);
        updatedCargoes = [...coaCargoes.slice(0, existingCargoIndex), formToCargo(form.value), ...coaCargoes.slice(existingCargoIndex + 1)];
    } else {
        updatedCargoes = [...coaCargoes, formToCargo(currentCoa.cargoForm.value)];
    }

    return currentCoaStateReducer(state, (coaState) => ({
        ...coaState,
        coa: { ...coaState.coa, cargoes: updatedCargoes },
        cargoForm: null,
        cargoFormSaveStatus: "persisted"
    }));
});

/* EFFECTS */
export const saveCargoEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, coaService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveCargoAction, saveNewCargoAction),
            withLatestFrom(store.select(selectCurrentCoa), store.select(selectCargoForm)),
            filter(([, , form]) => form.isValid),
            mergeMap(([action, coa, form]) => {
                if (action.type === SAVE_CARGO_ACTION_NAME) {
                    return coaService.updateCargo(coa.coaId, formToCargo(form.value)).pipe(
                        map(() => saveCargoSuccessAction()),
                        catchError((err) => of(saveCargoFailAction({ error: err })))
                    );
                }

                return coaService.addCargo(coa.coaId, formToCargo(form.value)).pipe(
                    map(() => saveNewCargoSuccessAction()),
                    catchError((err) => of(saveCargoFailAction({ error: err })))
                );
            })
        )
    );
