import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { FixtureCreation } from "src/app/left-bar/create-fixture/shared/create-fixture.dto";
import { FixtureCreateResponse } from "../shared/models/common/fixture-create-response";
import { FixtureHttpService } from "./fixture-http.service";

@Injectable({ providedIn: "root" })
export class FixtureCreateService {
    save$: Subject<FixtureCreateResponse>;

    constructor(private fixtureHttpService: FixtureHttpService) {
        this.save$ = new Subject<FixtureCreateResponse>();
    }

    create(fixture: FixtureCreation) {
        return this.fixtureHttpService.create(fixture).pipe(
            tap((fixtureId: string) => {
                this.save$.next({ fixture, fixtureId });
            })
        );
    }
}
