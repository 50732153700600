import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaustMap, switchMap, withLatestFrom } from "rxjs/operators";

import { LaytimeCalculationHttpService } from "../../../services";
import { createLtcId, LaytimeCalculationState, LtcFeatureState, LtcId, LtcState } from "../../model";
import { calculationStateReducer } from "../reducer";
import { navigateToLaytimeCalculationAction } from "../router";
import { selectCurrentCalculationId, selectCurrentLaytimeCalculationState } from "../selectors";

const CLONE_LAYTIME_CALCULATION_ACTION_NAME = "[Laytime Calculation List] Clone Laytime Calculation";
export const cloneLaytimeCalculationAction = createAction(CLONE_LAYTIME_CALCULATION_ACTION_NAME);
export const cloneLaytimeCalculationFailAction = createAction(`${CLONE_LAYTIME_CALCULATION_ACTION_NAME} Fail`, props<{ ltcId: LtcId; error: Error }>());
export const cloneLaytimeCalculationSuccessAction = createAction(
    `${CLONE_LAYTIME_CALCULATION_ACTION_NAME} Success`,
    props<{ currentLaytimeCalculationState: LaytimeCalculationState; cloneId: LtcId }>()
);

export const cloneLaytimeCalculationSuccessReducer: On<LtcState> = on(cloneLaytimeCalculationSuccessAction, (state, { currentLaytimeCalculationState, cloneId }) => {
    const calculation = currentLaytimeCalculationState.calculation;

    return calculationStateReducer(state, cloneId, () => ({
        ...currentLaytimeCalculationState,
        calculation: {
            ...calculation,
            name: `${calculation.name ?? ""} - Copy`,
            id: cloneId
        }
    }));
});

/* EFFECTS */
export const cloneLaytimeCalculationEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(cloneLaytimeCalculationAction),
            withLatestFrom(store.select(selectCurrentCalculationId), store.select(selectCurrentLaytimeCalculationState)),
            exhaustMap(([, ltcId, currentLaytimeCalculationState]) => {
                const cloneId = createLtcId();
                return laytimeCalculationHttpService.cloneLaytimeCalculation(ltcId, cloneId).pipe(
                    switchMap(() => [navigateToLaytimeCalculationAction({ ltcId: cloneId }), cloneLaytimeCalculationSuccessAction({ currentLaytimeCalculationState, cloneId })]),
                    catchError((error) => of(cloneLaytimeCalculationFailAction({ ltcId, error })))
                );
            })
        )
    );
