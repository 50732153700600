import { Injectable } from "@angular/core";

import { CargoBerthActivityType } from "../../../../../shared/reference-data/cargo-berth-activity-type";
import { Fixture, FixtureType, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

// Section rule
@Injectable()
export class ExternalVisibilityLoadDischargeActivityRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        voyages.forEach((voyage) => {
            if (!this.hasActivity(voyage, CargoBerthActivityType.Load)) {
                warnings.push(this.createWarning(fixture, voyage, CargoBerthActivityType.Discharge, "Missing Load Activity"));
            }

            if (!this.hasActivity(voyage, CargoBerthActivityType.Discharge)) {
                warnings.push(this.createWarning(fixture, voyage, CargoBerthActivityType.Load, "Missing Discharge Activity"));
            }
        });

        return warnings;
    }

    private createWarning(fixture: Fixture, voyage: Voyage, activityType: CargoBerthActivityType, message: string) {
        const voyagePath = this.getVoyagePathSegment(fixture.fixtureType, voyage);
        const path = [...voyagePath, ...this.firstBerthPathWithoutActivityType(voyage, activityType)];
        const dismissingRecord =
            fixture.fixtureType.id !== FixtureType.Voyage
                ? dismissedWarningRecord(new WarningHashBuilder().withVoyage(voyage.voyageId).withCategory("data").withMessage(message).build())
                : null;
        return new FixtureWarning("data", message, path, dismissingRecord);
    }

    private hasActivity(voyage: Voyage, activityType: CargoBerthActivityType) {
        return voyage.destinations.some((destination) =>
            destination.berths.some((berth) => berth.cargoBerthActivities.some((activity) => activity.type && activity.type.id === activityType.id))
        );
    }

    private firstBerthPathWithoutActivityType(voyage: Voyage, activityType: CargoBerthActivityType) {
        for (let destinationIndex = 0; destinationIndex < voyage.destinations.length; destinationIndex++) {
            const destination = voyage.destinations[destinationIndex];

            for (let berthIndex = 0; berthIndex < destination.berths.length; berthIndex++) {
                const berth = destination.berths[berthIndex];

                if (!berth.cargoBerthActivities.some((activity) => activity.type && activity.type.id === activityType.id)) {
                    const path = [
                        new FixtureWarningPathSegment("destinations", "Locations"),
                        FixtureWarningPathSegment.fromIndex(destinationIndex, "Location", destination.location ? destination.location.displayName : null),
                        new FixtureWarningPathSegment("berths", null),
                        FixtureWarningPathSegment.fromIndex(berthIndex, "Berth", berth.name),
                        new FixtureWarningPathSegment("cargoBerthActivities", "Activity")
                    ];

                    // If we have a placeholder activity ("Select Activity"), the path should be to the selection field
                    const placeholderActivityIndex = berth.cargoBerthActivities.findIndex((activity) => !activity.type);

                    if (placeholderActivityIndex > -1) {
                        path.push(FixtureWarningPathSegment.fromIndex(placeholderActivityIndex, null, null), new FixtureWarningPathSegment("type", null));
                    }

                    return path;
                }
            }
        }

        return [new FixtureWarningPathSegment("destinations", "Locations")];
    }
}
