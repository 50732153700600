import { Injectable } from "@angular/core";

import { CommandHandler } from "../../../../mediator/command-handlers/command-handler";
import { Voyage } from "../../../../shared/models";
import { UpdateAssociatedCargoesStatementsOfFactsCommand } from "./update-associated-cargoes-statement-of-facts.command";

@Injectable({
    providedIn: "root"
})
export class UpdateAssociatedCargoesStatementsOfFactsCommandHandler implements CommandHandler {
    handle(voyage: Voyage, command: UpdateAssociatedCargoesStatementsOfFactsCommand) {
        if (!command || !command.data || !command.destinationId) {
            throw new Error("Given form model is invalid.");
        }

        if (!voyage.destinations) {
            throw new Error("Unable to update the rates for associated cargo because there are no destinations.");
        }

        const destinationToUpdate = voyage.destinations.find((d) => d.id === command.destinationId);
        if (!destinationToUpdate) {
            throw new Error("Unable to update associated cargo because destination with given ID does not exist.");
        }

        if (!destinationToUpdate.berths) {
            throw new Error("Unable to update associated cargo because there are no berths.");
        }
        const berthToUpdate = destinationToUpdate.berths.find((b) => b.id === command.data.berthId);
        if (!berthToUpdate) {
            throw new Error("Unable to update associated cargo because berth with given ID does not exist.");
        }

        if (!berthToUpdate.cargoBerthActivities) {
            throw new Error("Unable to update associated cargo because there are no activities.");
        }
        const activityToUpdate = berthToUpdate.cargoBerthActivities.find((a) => a.id === command.data.activityId);
        if (!activityToUpdate) {
            throw new Error("Unable to update associated cargo because activity with given ID does not exist.");
        }

        if (!activityToUpdate.associatedCargoes) {
            throw new Error("Unable to find associated cargoes added to the activity.");
        }
        const associatedCargoToUpdate = activityToUpdate.associatedCargoes.find((a) => a.id === command.data.associatedCargoId);

        if (!associatedCargoToUpdate) {
            throw new Error("Unable to find associated cargo with given id.");
        }

        associatedCargoToUpdate.cpRate = command.data.cpRate;
        associatedCargoToUpdate.cpRateUnit = command.data.cpRateUnit;
        associatedCargoToUpdate.extraHours = command.data.extraHours;
        associatedCargoToUpdate.reversibleLaytimeType = command.data.reversibleLaytimeType;
        associatedCargoToUpdate.customaryQuickDespatch = command.data.customaryQuickDespatch;
    }
}
