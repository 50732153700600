import { Directive, HostListener } from "@angular/core";

@Directive({ selector: "[formGridInput]" })
export class FormGridInputDirective {
    @HostListener("click", ["$event"])
    onClick(event: Event) {
        if ((<HTMLElement>event.target).className.includes("ng-option")) {
            event.stopPropagation();
        }
    }

    @HostListener("keydown.enter", ["$event"])
    onEnterKeydown(event: KeyboardEvent) {
        event.stopPropagation();
    }

    @HostListener("keydown.tab", ["$event"])
    onTab(event: KeyboardEvent) {
        const target = <HTMLElement>event.target;

        // This will ensure that the date-input tabbing will not skip the date icon button when the button exists
        // Also that quantity input components tab correctly
        if ((target.className.includes("date-input") || target.parentElement?.className.includes("quantity-input-wrapper")) && target.nextElementSibling) {
            event.stopPropagation();
        }
    }
}
