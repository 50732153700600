<div *ngIf="invoicesExist" [formGroup]="invoicesForm">
    <div *opsDivisions="[Division.gas]" class="row no-gutters col-12 help-text p-0">
        <span class="has-icon icon--lightbulb-outline p-0"></span>
        <span class="d-flex align-items-center ml-1">Changes made to invoices will alter the actions associated with them.</span>
    </div>
    <div formArrayName="invoices">
        <div *ngFor="let invoice of invoices; let i = index" class="invoice">
            <div class="card" [formGroupName]="i">
                <input type="hidden" formControlName="originalInvoiceIndex" />
                <div class="invoice-summary card-header pl-0 row no-gutters align-items-center" [ngClass]="{ collapsed: !invoice.accordion.isOpen }">
                    <div>
                        <button
                            type="button"
                            (click)="toggleAccordion(invoice)"
                            class="has-icon btn-inverted"
                            [ngClass]="{ 'icon--arrow-drop-right': !invoice.accordion.isOpen, 'icon--arrow-drop-down': invoice.accordion.isOpen }"
                            data-test-toggle-invoiceaccordion
                        ></button>
                    </div>
                    <div
                        class="validation-indicator has-icon icon--warning"
                        [class.invalid]="invoice.invalid"
                        [class.touched]="invoice.touched"
                        container="body"
                        ngbTooltip="There are validation errors on this invoice"
                        data-test-freight-invoices-warning
                    ></div>
                    <ops-invoice-summary
                        [invoiceNumber]="invoice.get('externalInvoiceNumber').value"
                        [receivedFromOwnerDate]="invoice.get('receivedFromOwnerDate').value"
                        [value]="invoice.get('grossValue')?.value | currency: invoice.get('currency').value.code + ' ':'code'"
                        [status]="invoice.get('allocatedStatus').value?.name"
                    >
                    </ops-invoice-summary>
                    <div class="align-right last">
                        <ops-deletion-confirmation
                            buttonClass="btn-inverted"
                            [disabled]="invoicesForm.disabled"
                            (deleteConfirmed)="deleteInvoice(i)"
                            data-test-invoicedelete
                        ></ops-deletion-confirmation>
                    </div>
                </div>
                <div class="card-body" [hidden]="!invoice.accordion.isOpen" data-test-invoiceaccordion>
                    <div class="row no-gutters">
                        <div class="col-8 invoice-details">
                            <div class="row no-gutters">
                                <div class="form-group col">
                                    <label>Currency</label>
                                    <ng-select
                                        id="currency"
                                        class="ops-select"
                                        formControlName="currency"
                                        [compareWith]="referenceDataService.compareCurrencies"
                                        [items]="currencies$ | async"
                                        [clearable]="false"
                                        bindLabel="code"
                                        [selectOnTab]="true"
                                        data-test-invoicecurrency
                                        #currency
                                    >
                                    </ng-select>
                                </div>
                                <div class="form-group col required">
                                    <label>Value</label>
                                    <input
                                        class="form-control text-right"
                                        id="grossValue"
                                        formControlName="grossValue"
                                        [opsNumber]="{ precision: 2 }"
                                        data-test-invoicevalue
                                    />
                                    <ops-validation [form]="invoice.get('grossValue')"></ops-validation>
                                </div>
                                <div class="form-group col">
                                    <label>Rec. From Owner</label>
                                    <ops-date-input
                                        id="receivedFromOwnerDate"
                                        timeZone="utc"
                                        formControlName="receivedFromOwnerDate"

                                        data-test-invoicereceivedfromowner
                                    ></ops-date-input>
                                </div>
                                <div class="form-group col">
                                    <label>Sent To Charterer</label>
                                    <ops-date-input
                                        timeZone="utc"
                                        formControlName="sentToChartererDate"

                                        data-test-invoicesenttocharterer
                                    ></ops-date-input>
                                </div>
                                <div class="form-group col pr-2">
                                    <label>Sent To Accounts</label>
                                    <ops-date-input
                                        timeZone="utc"
                                        formControlName="sentToAccountsDate"

                                        data-test-senttoaccountsdate
                                    ></ops-date-input>
                                </div>
                            </div>
                            <div class="row no-gutters">
                                <div class="form-group col">
                                    <label>Invoice Number</label>
                                    <input class="form-control" formControlName="externalInvoiceNumber" data-test-invoiceexternalinvoicenumber />
                                </div>
                                <div class="form-group col">
                                    <label>Invoice Date</label>
                                    <ops-date-input
                                        timeZone="utc"
                                        formControlName="invoiceDate"
                                        data-test-invoicedate
                                    ></ops-date-input>
                                </div>
                                <div class="form-group col">
                                    <label>Allocated Status</label>
                                    <ng-select
                                        class="ops-select form-control"
                                        [items]="allocatedStatuses"
                                        placeholder="Select"
                                        formControlName="allocatedStatus"
                                        [clearable]="true"
                                        bindLabel="name"
                                        [compareWith]="referenceDataService.compare"
                                        data-test-invoiceallocatedstatus
                                        [selectOnTab]="true"
                                    >
                                    </ng-select>
                                </div>
                                <div class="form-group col">
                                    <label>Amount Paid</label>
                                    <input
                                        class="form-control text-right"
                                        formControlName="amountPaid"
                                        [opsNumber]="{ precision: 2 }"
                                        data-test-invoiceamountpaid
                                    />
                                </div>
                                <div class="form-group col pr-2">
                                    <label>Date Paid</label>
                                    <ops-date-input
                                        timeZone="utc"
                                        formControlName="datePaid"

                                        data-test-datepaid
                                    ></ops-date-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 form-group">
                            <label>Comments</label>
                            <textarea
                                formControlName="comments"
                                rows="5"
                                cols="75"
                                id="deliveryNotes"
                                class="form-control flex-grow-1 mt-0 mb-0"
                                data-test-invoicecomments
                            ></textarea>
                            <ops-validation [form]="invoice.get('comments')"></ops-validation>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col text-right last-updated">Last Updated by:&nbsp;</div>
                        <div class="col-auto pull-right last-updated" *ngIf="!invoice.disabled" data-test-lastupdated>
                            {{ invoice.controls["lastUpdatedByUser"].value?.fullName }}
                            {{ invoice.controls["lastUpdatedDate"].value | date: "dd MMM yy, HH:mm:ss" }}
                        </div>
                        <ops-load-animation class="col-auto pull-right light-background" size="small" *ngIf="invoice.disabled"></ops-load-animation>
                    </div>
                </div>
            </div>
        </div>
        <div class="row newinvoice">
            <div class="col text-right">
                <button type="button" class="btn btn-primary btn-add m-0" [disabled]="invoicesForm.disabled" (click)="addInvoice()">
                    <span class="has-icon icon--add-circle-outline"></span> INVOICE
                </button>
            </div>
        </div>
    </div>
</div>
<ops-no-data-panel
    *ngIf="!invoicesExist"
    heading="Freight Invoices"
    title="No invoices have been added"
    description="Click the button to add an invoice."
    [useNewButton]="true"
    newButtonText="INVOICE"
    [disabled]="invoicesForm.disabled"
    (addNew)="addInvoice()"
>
</ops-no-data-panel>
