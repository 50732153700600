import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { DateTime } from "luxon";
import { Subject, Subscription } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { AppInsightsService } from "@ops/core";
import { Enumeration, ReferenceDataService } from "@ops/shared/reference-data";

import { ActionDto, ActionFormModel } from "../../../action";
import { FixtureDataService } from "../../../fixture/services/fixture-data.service";
import { Fixture, User } from "../../../fixture/shared/models";
import { Time } from "../../../shared/components/time/time";
import { dateToISOString } from "../../../shared/date-utils/date-utilities";
import { RequiredIfValidator } from "../../../shared/validators/required-if.validator";

@Component({
    selector: "ops-edit-action",
    templateUrl: "./edit-action.component.html",
    styleUrls: ["./edit-action.component.scss"]
})
export class EditActionComponent implements OnInit, OnDestroy {
    static componentName = "EditActionComponent";

    private readonly destroy$ = new Subject<void>();

    private fixtureSubscription: Subscription;
    private defaultRecipients: User[] = [];

    fixtureId: string;
    fixtureSource: Enumeration;
    defaultSelectTime = new Time(7, 30, 0);

    isInvalidOnSave = false;
    isNotificationsExpanded = true;
    actionForm: UntypedFormGroup;

    @Input() title = "";
    @Input() priorities: Enumeration[];
    @Input() actionToEdit: ActionFormModel;

    @Output() cancelled = new EventEmitter();
    @Output() deleteAction = new EventEmitter<ActionDto>();
    @Output() saveAction = new EventEmitter<ActionDto>();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private fixtureDataService: FixtureDataService,
        public referenceDataService: ReferenceDataService,
        private appInsightsMonitoringService: AppInsightsService
    ) {}

    ngOnInit(): void {
        this.fixtureSubscription = this.fixtureDataService.currentFixture$
            .pipe(
                takeUntil(this.destroy$),
                filter((x) => !!x)
            )
            .subscribe((fixture) => {
                this.defaultRecipients = this.getDefaultRecipients(fixture);
                this.fixtureId = fixture.fixtureId;
                this.fixtureSource = fixture.fixtureSource;
            });

        this.createForm();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get assignedToCount(): number {
        return this.actionForm.controls["assignedTo"].value.length;
    }

    get notificationDateCount(): number {
        return this.notificationDatesFormArray.value.length;
    }

    get recipientCount(): number {
        return this.actionForm.controls["notificationRecipients"].value.length;
    }

    cancel(): void {
        this.cancelled.emit();
    }

    delete(): void {
        const actionDto = <ActionDto>{ actionId: this.actionToEdit.actionId };
        this.deleteAction.emit(actionDto);
    }

    save(): void {
        if (this.actionForm.valid) {
            this.isInvalidOnSave = false;

            const dates = this.notificationDatesFormArray.controls.map((control) => dateToISOString(control.value.date));
            const actionModel = this.actionForm.value;
            const actionDto = <ActionDto>{
                actionId: this.actionToEdit.actionId,
                fixtureId: this.fixtureId,
                type: this.actionToEdit.type,
                isComplete: this.actionToEdit.isComplete,
                summary: actionModel.summary,
                description: actionModel.description,
                dueDate: dateToISOString(actionModel.dueDate),
                priority: actionModel.priority,
                assignedTo: actionModel.assignedTo,
                notificationRecipients: actionModel.notificationRecipients,
                notificationDates: dates
            };

            this.saveAction.emit(actionDto);
        } else {
            this.logFormValidationErrors();
            this.isInvalidOnSave = true;
        }
    }

    addNotification(): void {
        if (this.notificationDateCount === 0) {
            this.actionForm.patchValue({ notificationRecipients: this.defaultRecipients });
        }

        const latestDate = this.getNextNotificationDate();
        this.notificationDatesFormArray.push(this.createNotificationDateFormGroup(latestDate));
    }

    deleteNotification(index: number): void {
        this.notificationDatesFormArray.removeAt(index);

        if (this.notificationDateCount === 0) {
            this.actionForm.patchValue({ notificationRecipients: [] });
        }
    }

    toggleNotifications() {
        this.isNotificationsExpanded = !this.isNotificationsExpanded;
    }

    getDefaultFocusDate(index: number): string {
        const dateToUse = index === 0 ? this.actionForm.get("dueDate").value : this.notificationDatesFormArray.value[index - 1].date;
        return DateTime.fromJSDate(dateToUse).toISODate();
    }

    private createForm(): void {
        this.actionForm = this.formBuilder.group(
            {
                summary: [this.actionToEdit.summary, Validators.required],
                description: [this.actionToEdit.description],
                dueDate: [this.actionToEdit.dueDate, Validators.required],
                priority: [this.actionToEdit.priority],
                assignedTo: [this.actionToEdit.assignedTo, Validators.required],
                notificationDates: this.formBuilder.array([]),
                notificationRecipients: [this.actionToEdit.notificationRecipients]
            },
            {
                validator: Validators.compose([RequiredIfValidator.validate("notificationDates", "notificationRecipients")])
            }
        );

        this.actionToEdit.notificationDates.forEach((date) => this.notificationDatesFormArray.push(this.createNotificationDateFormGroup(date)));
    }

    private getNextNotificationDate(): Date {
        const notifications = this.notificationDatesFormArray.value as { date: Date }[];
        const notificationDates = notifications.map((d) => d.date as Date).filter((d) => d) as Date[];
        return notificationDates && notificationDates.length ? notificationDates[notificationDates.length - 1] : this.actionForm.value.dueDate;
    }

    private createNotificationDateFormGroup(date?: Date): UntypedFormGroup {
        const group = this.formBuilder.group({
            date: new UntypedFormControl(date ? date : "", Validators.required)
        });

        return group;
    }

    private get notificationDatesFormArray(): UntypedFormArray {
        return this.actionForm.get("notificationDates") as UntypedFormArray;
    }

    private getDefaultRecipients(fixture: Fixture): User[] {
        const defaultRecipients = [...fixture.brokers, ...fixture.operators, ...fixture.claims];
        const uniqueDefaultRecipients: { [id: number]: User } = {};

        defaultRecipients.forEach((u) => {
            if (!uniqueDefaultRecipients[u.userId]) {
                uniqueDefaultRecipients[u.userId] = u;
            }
        });

        return Object.values(uniqueDefaultRecipients);
    }

    private logFormValidationErrors() {
        let errorMessage = this.actionToEdit.actionId
            ? `action validation error on save, actionId: ${this.actionToEdit.actionId},  \n `
            : "action validation error on save, new action,  \n ";

        Object.keys(this.actionForm.controls).forEach((key) => {
            const controlErrors: ValidationErrors = this.actionForm.get(key).errors;
            if (controlErrors !== null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    errorMessage = errorMessage + `"${key}:  ${keyError}" \n`;
                });
            }
        });

        errorMessage += `fixtureId: ${this.fixtureId}`;

        this.appInsightsMonitoringService.logInfo(errorMessage);
    }
}
