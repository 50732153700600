import { ColumnState } from "@ag-grid-community/core/dist/cjs/columns/columnModel";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { auditTime, filter, mergeMap, tap } from "rxjs/operators";

import { createRequestId, SearchToken, Suggestion, SuggestionTerm, toColumnDefinition } from "@ops/state";

import { GridRecordNumbers, ISearchService } from "../../shared/grid";
import { LtcFeatureState, LaytimeCalculationGridRow } from "../state";
import {
    removeLaytimeCalculationGridRequest,
    searchLaytimeCalculationGrid,
    selectLaytimeCalculationGridCriteria,
    selectLaytimeCalculationGridPage,
    selectLaytimeCalculationGridRecordNumbers,
    selectLaytimeCalculationSuggestionItems,
    selectLaytimeCalculationSuggestionsLoading,
    updateLaytimeCalculationGridColumns,
    updateLaytimeCalculationGridSearchTokens,
    updateLaytimeCalculationGridSorting,
    updateLaytimeCalculationGridSuggestionTerm
} from "../state/calculations/grid";

@Injectable({
    providedIn: "root"
})
export class LaytimeCalculationSearchService implements ISearchService<LaytimeCalculationGridRow> {
    private columnState$ = new BehaviorSubject<ColumnState[]>(null);

    readonly criteria$: Observable<ReadonlyArray<SearchToken>>;
    readonly gridRecordNumbers$: Observable<GridRecordNumbers>;
    readonly suggestions$: Observable<ReadonlyArray<Suggestion>>;
    readonly suggestionsLoading$: Observable<boolean>;

    constructor(private store: Store<LtcFeatureState>) {
        this.criteria$ = this.store.select(selectLaytimeCalculationGridCriteria);
        this.gridRecordNumbers$ = this.store.select(selectLaytimeCalculationGridRecordNumbers).pipe(filter((x) => !!x));
        this.suggestionsLoading$ = this.store.select(selectLaytimeCalculationSuggestionsLoading);
        this.suggestions$ = this.store.select(selectLaytimeCalculationSuggestionItems);

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.columnState$
            .pipe(
                filter((columnState) => !!columnState),
                auditTime(300)
            )
            .subscribe((columnState) => {
                this.store.dispatch(updateLaytimeCalculationGridColumns({ columns: columnState.map(toColumnDefinition) }));
            });
    }

    updateSuggestionTerm(suggestionTerm: SuggestionTerm) {
        this.store.dispatch(updateLaytimeCalculationGridSuggestionTerm({ suggestionTerm }));
    }

    updateSearchTokens(suggestions: ReadonlyArray<Suggestion>) {
        this.store.dispatch(updateLaytimeCalculationGridSearchTokens({ searchTokens: suggestions }));
    }

    updateSorting(sortColumn: string, sortOrder: string) {
        this.store.dispatch(updateLaytimeCalculationGridSorting({ sorting: { column: sortColumn, order: sortOrder } }));
    }

    updateColumns(columnState: ColumnState[]) {
        this.columnState$.next(columnState);
    }

    search(skip: number, take: number): Observable<ReadonlyArray<LaytimeCalculationGridRow>> {
        const requestId = createRequestId();

        this.store.dispatch(searchLaytimeCalculationGrid({ requestId, skip, take }));
        return this.store.select(selectLaytimeCalculationGridPage, { requestId }).pipe(
            filter((x) => !!x),
            tap(() => this.store.dispatch(removeLaytimeCalculationGridRequest({ requestId }))),
            mergeMap((x) => (x.loadStatus === "failed" ? throwError(x.error) : of(x.items)))
        );
    }
}
