import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, of, of as observableOf } from "rxjs";
import { map } from "rxjs/operators";

import { AppConfigService } from "../../core";
import { SharingGroupsHttpService } from "../../fixture/services/sharing-groups-http.service";
import { CompanyType } from "../../fixture/shared/models/enums/company-type";
import { FixtureSource } from "../../fixture/shared/models/enums/fixture-source";
import { Enumeration } from "../../shared/reference-data/enumeration";
import { deepCopyArray } from "../../shared/utils/deep-copy";
import { ConfigurationRolesFixtureViewModel } from "../shared/models/sharing-group.model";
import { Company } from "./models/company.model";
import { Person } from "./models/person.model";

@Injectable({
    providedIn: "root"
})
export class ContactService {
    private readonly apiGatewayUrl: string;
    private fixtureId: string;

    private companiesSubject = new BehaviorSubject<Company[]>([]);
    private fixtureGroupsSubject = new BehaviorSubject<ConfigurationRolesFixtureViewModel>(null);

    companies$ = this.companiesSubject.asObservable();
    fixtureGroups$ = this.fixtureGroupsSubject.asObservable();

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService, private sharingGroupsHttpService: SharingGroupsHttpService) {
        this.apiGatewayUrl = appConfigService.config.apiGatewayUrl;
    }

    loadCompanies(fixtureId: string, fixtureSource: FixtureSource): void {
        if (!fixtureId) {
            throw new Error("Fixture ID is mandatory");
        }
        this.fixtureId = fixtureId;
        this.httpClient
            .get<Company[]>(`${this.apiGatewayUrl}/api/v1.0/fixture/${fixtureId}/company`)
            .pipe(map((x) => this.orderCompaniesAndAddTbn(x, fixtureSource)))
            .subscribe(
                (companies) => this.companiesSubject.next(companies),
                (error) => observableOf(error)
            );
    }

    addCompany(accountId: number, companyName: string, groupName: string, companyRole: Enumeration): void {
        if (!accountId) {
            throw new Error("account ID is mandatory");
        }

        const company: Company = {
            id: undefined,
            accountId,
            fixtureId: this.fixtureId,
            name: companyName,
            groupName,
            role: companyRole.name,
            typeName: companyRole.name,
            typeId: companyRole.id,
            imported: false,
            people: [],
            collapsed: false,
            isNew: true
        };

        this.execAddCompany(company).subscribe(
            (id) => {
                const values = this.companiesSubject.getValue();
                company.id = id;
                values.push(company);
                this.companiesSubject.next(values);
            },
            (error) => observableOf(error)
        );
    }

    addPerson(person: Person): void {
        if (!person || !person.accountId || !person.id) {
            throw new Error("account ID and person ID are mandatory");
        }

        const values = this.companiesSubject.getValue();

        const thecompaniesToBeUpdated = values.filter((x) => x.accountId === person.accountId);

        this.execAddPerson(
            thecompaniesToBeUpdated.map((x) => x.id),
            person
        ).subscribe(
            () => {
                for (const companyToBeUpdated of thecompaniesToBeUpdated) {
                    if (!companyToBeUpdated.people) {
                        companyToBeUpdated.people = [];
                    }
                    if (companyToBeUpdated.people.filter((x) => x.id === person.id).length === 0) {
                        companyToBeUpdated.people.push(person);
                        companyToBeUpdated.collapsed = false;
                    }
                }

                const clonedValues = deepCopyArray(values);
                this.companiesSubject.next(clonedValues);
            },
            (error) => observableOf(error)
        );
    }

    removeCompany(companyId: string, refreshSharingGroups: boolean): void {
        if (!companyId) {
            throw new Error("company ID is mandatory");
        }

        this.httpClient.delete(`${this.apiGatewayUrl}/api/v1.0/fixture/${this.fixtureId}/company/${companyId}?deleteSharingGroups=${refreshSharingGroups}`).subscribe(
            () => {
                let currentCompanyList = this.companiesSubject.getValue();
                currentCompanyList = currentCompanyList.filter((x) => x.id !== companyId);

                if (refreshSharingGroups) {
                    this.getSharingGroupsForFixture(this.fixtureId);
                }

                this.companiesSubject.next(currentCompanyList);
            },
            (error) => of(error)
        );
    }

    removePerson(personId: string, companyId: string): void {
        if (!personId) {
            throw new Error("person ID and company ID are mandatory");
        }

        this.httpClient.delete(`${this.apiGatewayUrl}/api/v1.0/company/${companyId}/person/${personId}`).subscribe(
            () => {
                const currentCompanyList = this.companiesSubject.getValue();
                const theCompanyToBeUpdated = currentCompanyList.filter((x) => x.id === companyId);
                for (const companyToBeUpdated of theCompanyToBeUpdated) {
                    companyToBeUpdated.people = companyToBeUpdated.people.filter((x: any) => x.id !== personId);
                }

                const clonedCompanyList = deepCopyArray(currentCompanyList);
                this.companiesSubject.next(clonedCompanyList);
            },
            (error) => of(error)
        );
    }

    saveComment(comment: string, personId: string, companyId: string): void {
        if (!personId) {
            throw new Error("person ID and company ID are mandatory");
        }

        const options: any = { responseType: "text" };
        this.httpClient.put(`${this.apiGatewayUrl}/api/v1.0/company/${companyId}/person/${personId}/comment`, { comments: comment }, options).subscribe(() => {
            const values = this.companiesSubject.getValue();
            const theCompanyToBeUpdated = values.filter((x) => x.id === companyId)[0];
            theCompanyToBeUpdated.collapsed = false;
            const thePersonToBeUpdated = theCompanyToBeUpdated.people.filter((x) => x.id === personId)[0];
            thePersonToBeUpdated.comments = comment;
            thePersonToBeUpdated.hidePersonExtraInfo = false;

            const clonedValues = deepCopyArray(values);
            this.companiesSubject.next(clonedValues);
        });
    }

    getSharingGroupsForFixture(fixtureId: string) {
        if (!fixtureId) {
            this.fixtureGroupsSubject.next(new ConfigurationRolesFixtureViewModel());
            return;
        }

        this.sharingGroupsHttpService.getForFixture(fixtureId).subscribe((configurationRoles) => {
            this.fixtureGroupsSubject.next(new ConfigurationRolesFixtureViewModel(fixtureId, configurationRoles));
        });
    }

    private orderCompaniesAndAddTbn(companies: Company[], fixtureSource: FixtureSource): Company[] {
        const owners = companies.filter((x) => x.typeId === CompanyType.Owner);
        if (owners.length === 0 && fixtureSource === FixtureSource.Gain) {
            owners.unshift({
                id: undefined,
                accountId: undefined,
                fixtureId: undefined,
                name: "TBN",
                groupName: null,
                role: "Owner",
                typeId: CompanyType.Owner,
                typeName: "Owner",
                imported: true,
                people: [],
                isNew: false
            });
        }
        const charterers = companies.filter((x) => x.typeId === CompanyType.Charterer);
        if (charterers.length === 0 && fixtureSource === FixtureSource.Gain) {
            charterers.unshift({
                id: undefined,
                accountId: undefined,
                fixtureId: undefined,
                name: "TBN",
                groupName: null,
                role: "Charterer",
                typeId: CompanyType.Charterer,
                typeName: "Charterer",
                imported: true,
                people: [],
                isNew: false
            });
        }
        const restImported = companies
            .filter((x) => x.typeId !== CompanyType.Owner && x.typeId !== CompanyType.Charterer && x.imported)
            .sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });
        const restNonImported = companies
            .filter((x) => x.typeId !== CompanyType.Owner && x.typeId !== CompanyType.Charterer && !x.imported)
            .sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });
        return [...owners, ...charterers, ...restImported, ...restNonImported];
    }

    private execAddCompany(company: Company): Observable<any> {
        const options: any = { responseType: "text" };
        return this.httpClient.post<any>(`${this.apiGatewayUrl}/api/v1.0/fixture/${this.fixtureId}/company`, company, options);
    }

    private execAddPerson(companyIds: string[], person: Person): Observable<any> {
        const options: any = { responseType: "text" };
        const observableArray: any[] = [];

        companyIds.forEach((componentArray) => {
            observableArray.push(this.httpClient.put<any>(`${this.apiGatewayUrl}/api/v1.0/company/${componentArray}?personId=${person.id}`, null, options));
        });

        return forkJoin(observableArray);
    }
}
