<div class="col" [ngbTooltip]="invoiceNumber" container="body" data-test-invoice-no-header>
    {{invoiceNumber}}
</div>
<div class="col" [ngbTooltip]="receivedFromOwnerDate | timezoneDate:'DATE':'utc'" container="body" data-test-received-from-owner-header>
    Rec From Owner:&nbsp;{{receivedFromOwnerDate | timezoneDate:'DATE':'utc'}}
</div>
<div class="col" *ngIf="showCoveringPeriod" [ngbTooltip]="invoiceNumber" container="body" data-test-covering-period-header>
    Hire Period:&nbsp;{{coveringPeriod}}
</div>
<div class="col" [ngbTooltip]="value" container="body" data-test-invoice-value-header>
    Value:&nbsp;{{value}}
</div>
<div class="col" [ngbTooltip]="status" container="body" data-test-allocated-status-header>
    Status:&nbsp;{{status}}
</div>
