import { Enumeration } from "./enumeration";

export class LocationType extends Enumeration {
    static Port: LocationType = new LocationType("46607d43-3649-4cd9-8b1b-d24112ddbe99", "Port");
    static GasAndPcgZones: LocationType = new LocationType("15fd0111-7cbc-4017-9ac9-d613d6225fd7", "Gas And PCG Zones");
    static DryCargoZones: LocationType = new LocationType("403B82FC-2AB9-47B3-82F4-894E616ECBD5", "Dry Cargo Zones");
    static TankerZones: LocationType = new LocationType("6359BFEE-E9BE-4976-B379-E717F24A7ACF", "Tanker Zones");
    static NonPortVoyageLocation: LocationType = new LocationType("A7D0853B-2B8D-4F6B-843E-FAAED569C559", "Non-Port Voyage Location");
    static SpecialisedZones: LocationType = new LocationType("C73B3B7F-6E25-4F28-BB99-907D4ABCFC2A", "Specialised Zones");
    static Ranges: LocationType = new LocationType("D98836CE-F374-402E-8E2C-034E96B6E3F7", "Ranges");
    static ArcWaypoints: LocationType = new LocationType("DF4C9AFA-478E-46B9-92CC-5693FE65C739", "Arc Waypoints");
    static Anchorages: LocationType = new LocationType("7F5CFA49-6C19-46C0-8A51-9B40FC0B406E", "Anchorages");
    static CanalsAndStraits: LocationType = new LocationType("B70F7AA8-CA8C-4FA0-AA7A-10631962BDC3", "Canals and Straits");
    static Country: LocationType = new LocationType("ff568c35-27a2-49ad-bd12-b1bf4f187bc6", "Country");

    static parse(name: string) {
        const locationTypes: LocationType[] = Object.values(LocationType);
        return locationTypes.find((x) => x.name === name);
    }
}
