import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { Search, SearchFail, searchFailReducer, searchReducer, SearchSuccess, searchSuccessReducer } from "@ops/state";

import { LaytimeCalculationHttpService } from "../../../services";
import { LaytimeCalculationIndex, LtcFeatureState, LtcState } from "../../model";
import { selectLaytimeCalculationGridSearchCriteria } from "./selectors";

/* ACTIONS */
const ACTION_NAME = "[Laytime Calculation Grid] Search";

export const searchLaytimeCalculationGridAction = createAction(ACTION_NAME, props<Search>());
export const searchLaytimeCalculationGridSuccessAction = createAction(`${ACTION_NAME} Success`, props<SearchSuccess<LaytimeCalculationIndex>>());
export const searchLaytimeCalculationGridFailAction = createAction(`${ACTION_NAME} Fail`, props<SearchFail>());

/* REDUCERS */
export const searchLaytimeCalculationGridReducer: On<LtcState> = on(searchLaytimeCalculationGridAction, (state, action) => searchReducer(state, action, "grid"));
export const searchLaytimeCalculationGridSuccessReducer: On<LtcState> = on(searchLaytimeCalculationGridSuccessAction, (state, action) =>
    searchSuccessReducer(state, action, "grid")
);
export const searchLaytimeCalculationGridFailReducer: On<LtcState> = on(searchLaytimeCalculationGridFailAction, (state, action) => searchFailReducer(state, action, "grid"));

/* EFFECTS */
export const searchCalculationsEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(searchLaytimeCalculationGridAction),
            withLatestFrom(store.select(selectLaytimeCalculationGridSearchCriteria)),
            mergeMap(([{ requestId, skip, take }, { criteria, sorting }]) =>
                laytimeCalculationHttpService.searchCalculations(criteria, skip, take, sorting).pipe(
                    map((x) => searchLaytimeCalculationGridSuccessAction({ requestId, items: x.documents, total: x.total })),
                    catchError((error) => of(searchLaytimeCalculationGridFailAction({ requestId, error })))
                )
            )
        )
    );
