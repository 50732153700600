import { Enumeration } from "./enumeration";

export class TimeSavedType extends Enumeration {
    static WorkingTimeSaved: TimeSavedType = new TimeSavedType(1, "Working Time Saved", 1);
    static AllTimeSaved: TimeSavedType = new TimeSavedType(2, "All Time Saved", 2);

    static isWorkingTimeSave(timeSavedType: TimeSavedType): boolean {
        return timeSavedType && timeSavedType.id === TimeSavedType.WorkingTimeSaved.id;
    }
}
