import { FormArrayState, FormState, ProjectFn2, updateArrayWithFilter, updateGroup } from "ngrx-forms";

import { BerthForm, BerthId, DestinationForm, DestinationId, VoyageForm } from "../model";

export const updateBerthForm = (id: { destinationId: DestinationId; berthId: BerthId }, updateFn: ProjectFn2<FormState<BerthForm>, FormArrayState<BerthForm>>) =>
    updateGroup<VoyageForm>({
        destinations: updateArrayWithFilter(
            (d) => d.value.id === id.destinationId,
            updateGroup<DestinationForm>({
                berths: updateArrayWithFilter((b) => b.value.id === id.berthId, updateFn)
            })
        )
    });

export type BerthFormContext = { destination: DestinationForm };

export const forEachBerth = (voyageForm: VoyageForm, fn: (berth: BerthForm, { destination }: BerthFormContext) => void) => {
    voyageForm.destinations.forEach((destination) => destination.berths.forEach((berth) => fn(berth, { destination })));
};
