import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { FixtureFeatureState, selectCurrentFixture, selectCurrentVoyage } from "../state";
import { ExpenseDataService } from "./expense-data.service";
import { generateProfitAndLoss, ProfitAndLossReport, ReportView } from "./profit-and-loss";

@Injectable({
    providedIn: "root"
})
export class ProfitAndLossService {
    constructor(public store: Store<FixtureFeatureState>, private expenseService: ExpenseDataService) {}

    getReport(view: ReportView): Observable<ProfitAndLossReport> {
        if (view === "owner") {
            throw Error("owner view currently unsupported");
        }

        return combineLatest([
            this.store.select(selectCurrentFixture),
            this.store.select(selectCurrentVoyage),
            this.expenseService.currentExpenses$
        ]).pipe(
            map(([fixture, voyage, expenses]) =>
                generateProfitAndLoss(view, fixture, voyage, expenses.expenses)
            )
        );
    }
}
