import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FixtureSource } from "src/app/fixture/shared/models/enums/fixture-source";
import { SharingGroupsViewModel } from "../../shared/models/sharing-group.model";
import { ContactService } from "../contact.service";
import { Company } from "../models/company.model";

@Component({
    selector: "ops-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactComponent implements OnInit {
    static componentName = "ContactComponent";

    @Input() company: Company;
    @Input() fixtureSource: FixtureSource;

    FixtureSource = FixtureSource;
    collapsed = true;

    constructor(private contactService: ContactService) {}

    ngOnInit() {
        if (this.company.collapsed !== undefined) {
            this.collapsed = this.company.collapsed || this.fixtureSource !== FixtureSource.Ops;
        }
    }

    removeCompany(company: Company) {
        this.contactService.removeCompany(company.id, this.fixtureSource === FixtureSource.Ops);
    }

    togglePeople(): void {
        this.collapsed = !this.collapsed;
        this.company.collapsed = this.collapsed;
    }
}
