import { createAction, on, On, props } from "@ngrx/store";

import { CargoTermsId, laytimeCalculationCargoTermsToForm, LtcState } from "../../../../model";
import { currentCalculationStateReducer } from "../../../reducer";

/* ACTIONS */
export const editCargoTermsAction = createAction("[Laytime Calculation] Edit Cargo Terms", props<{ cargoTermsId: CargoTermsId }>());
export const cancelCargoTermsEditAction = createAction("[Laytime Calculation] Cancel Edit Cargo Terms");

/* REDUCERS */
export const editCargoTermsReducer: On<LtcState> = on(editCargoTermsAction, (state, { cargoTermsId }) =>
    currentCalculationStateReducer(state, (ltcState) => ({
        ...ltcState,
        currentCargoTermsId: cargoTermsId,
        cargoTermsForm: laytimeCalculationCargoTermsToForm(ltcState.calculation.cargoTerms.find((c) => c.id === cargoTermsId))
    }))
);

export const cancelCargoTermsEditReducer: On<LtcState> = on(cancelCargoTermsEditAction, (state) =>
    currentCalculationStateReducer(state, { currentCargoTermsId: null, cargoTermsForm: null })
);
