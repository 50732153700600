import { createSelector } from "@ngrx/store";
import { Duration } from "luxon";
import { FormGroupState } from "ngrx-forms";

import { isNullOrUndefined, parseNumber } from "@ops/shared";

import { ActivityCargo, ActivityCargoForm, ActivityCargoId } from "../../../model";
import { equalByCargoAndProductIds } from "../../../model/calculations/utils";
import { selectCurrentLaytimeCalculationState } from "../../selectors";
import { selectCurrentCalculationCargoTerms } from "../../terms";
import { selectCurrentActivityLocation, selectCurrentActivityLocationResult } from "../selectors";

export type ActivityLocationCargoState = Readonly<{
    id: ActivityCargoId;
    form: FormGroupState<ActivityCargoForm>;
    laytimeAllowed: Duration;
    canImportRateTerms: boolean;
    /**
     * The actual load or discharge rate according to the allowance unit,
     * quantity and attributed time used.
     */
    actual?: number;
}>;

export const selectActivityLocationCargoesForms = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.activityCargoForms);
export const selectActivityLocationCargoesFormsId = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.activityCargoForms?.id);

export const selectCurrentActivityLocationCargoes = createSelector(
    selectActivityLocationCargoesForms,
    selectCurrentActivityLocation,
    selectCurrentActivityLocationResult,
    selectCurrentCalculationCargoTerms,
    (forms, activityLocation, activityLocationResult, cargoTerms) =>
        forms?.controls.map((form: FormGroupState<ActivityCargoForm>) => {
            const { id } = form.value;
            const cargo = activityLocation.cargoes.find((x) => x.id === id);
            const cargoResult = activityLocationResult.cargoes?.find((x) => x.id === id);
            const actual = !!cargo && cargoResult ? getActual(cargo, cargoResult.laytimeUsed) : null;
            const canImportRateTerms =
                !!cargo &&
                cargoTerms?.some(
                    (ct) =>
                        equalByCargoAndProductIds(ct, cargo) &&
                        ((activityLocation.activity === "Load" && ct.loadLocations.some((l) => l.locationId === activityLocation.locationId)) ||
                            (activityLocation.activity === "Discharge" && ct.dischargeLocations.some((l) => l.locationId === activityLocation.locationId)))
                );

            return <ActivityLocationCargoState>{ id, form, laytimeAllowed: cargoResult?.laytimeAllowed, canImportRateTerms, actual };
        })
);

export const getActual = (cargo: ActivityCargo, timeUsed: Duration): number => {
    const { allowanceUnit, quantity: quantityStr, quantityUnit } = cargo;

    if (!allowanceUnit) {
        return null;
    }

    if (+timeUsed === 0) {
        return 0;
    }

    const quantity = parseNumber(quantityStr);

    switch (allowanceUnit) {
        case "Hours":
            return timeUsed.as("hours");
        case "MT/Hour":
            return !isNullOrUndefined(quantity) && quantityUnit === "MT" ? quantity / timeUsed.as("hours") : null;
        case "MT/Day":
            return !isNullOrUndefined(quantity) && quantityUnit === "MT" ? quantity / timeUsed.as("days") : null;
        default:
            throw Error(`Unknown allowance unit '${allowanceUnit}'`);
    }
};
