import { parseISODate } from "src/app/shared/date-utils/date-utilities";
import { Invoice } from "../shared/models";

export class InvoiceSortingUtility {
    static sortInvoices(invoiceArray: Invoice[]) {
        return invoiceArray.sort((invoiceA: Invoice, invoiceB: Invoice) => {
            const dateA = parseISODate(invoiceA.coveringFromDate || invoiceA.receivedFromOwnerDate || invoiceA.lastUpdatedDate);
            const dateB = parseISODate(invoiceB.coveringFromDate || invoiceB.receivedFromOwnerDate || invoiceB.lastUpdatedDate);

            if (!dateA && dateB) {
                return 1;
            }

            if (dateA && !dateB) {
                return -1;
            }

            if (!dateA && !dateB) {
                return 0;
            }

            return dateA.getTime() - dateB.getTime();
        });
    }
}
