import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { removeArrayControl, updateGroup } from "ngrx-forms";
import * as R from "ramda";
import { of } from "rxjs";
import { switchMap, withLatestFrom } from "rxjs/operators";

import { FixtureDataService } from "../../../services/fixture-data.service";
import { FixtureType } from "../../../shared/models";
import { updateDestinationForm } from "../../destinations/shared";
import { selectCurrentFixtureType } from "../../fixture";
import { berthExpandedKey, BerthId, DestinationId, FixtureFeatureState, FixturesState, VoyageExpandedSections } from "../../model";
import { selectCurrentVoyageState } from "../../voyage";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const removeBerthAction = createAction("[Voyage Form] Remove Berth", props<{ destinationId: DestinationId; berthId: BerthId }>());
export const removeBerthFromVoyageAction = createAction("[Voyage Form] Remove Berth From Voyage", props<{ destinationId: DestinationId; berthId: BerthId }>());

/* REDUCERS */
export const removeBerthReducer: On<FixturesState> = on(removeBerthFromVoyageAction, (state, { destinationId, berthId }) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const destination = voyageState.form.controls.destinations.controls.find((d) => d.value.id === destinationId);
        if (!destination) {
            return voyageState;
        }
        const berthIndex = destination.value.berths.findIndex((x) => x.id === berthId);
        if (berthIndex < 0) {
            return voyageState;
        }

        return {
            ...voyageState,
            form: updateDestinationForm({ destinationId }, (destinationForm) =>
                updateGroup(destinationForm, {
                    berths: removeArrayControl(berthIndex)
                })
            )(voyageState.form),
            expandedSections: <VoyageExpandedSections>R.omit([berthExpandedKey(destinationId, berthId)], voyageState.expandedSections)
        };
    })
);

/* EFFECTS */
export const interopRemoveBerthFromDemurrageEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, fixtureService: FixtureDataService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeBerthAction),
            withLatestFrom(store.select(selectCurrentVoyageState), store.select(selectCurrentFixtureType)),
            switchMap(([{ destinationId, berthId }, currentVoyageState, fixtureType]) => {
                if (fixtureType === FixtureType.Voyage) {
                    const destination = currentVoyageState.workingVoyage.destinations.find((d) => d.id === destinationId);
                    const berth = destination.berths.find((b) => b.id === berthId);
                    fixtureService.removeLocationFromDemurrageClaim(destination.destinationId, berth.berthId);
                }
                return of(removeBerthFromVoyageAction({ destinationId, berthId }));
            })
        )
    );
