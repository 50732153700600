import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of, throwError } from "rxjs";
import { catchError, filter, map, mapTo, mergeMap, withLatestFrom } from "rxjs/operators";

import { navigateToVoyage } from "../../routing";
import { VoyageHttpService } from "../../services";
import { FixtureDataService } from "../../services/fixture-data.service";
import { FixtureFeatureState, FixtureId } from "../model";
import { selectCurrentFixtureVoyageStates, selectFixtureVoyageStates, selectVoyage, selectVoyageIsValid, selectVoyageState } from "../voyage";
import { saveVoyageAction, saveVoyageFailAction, saveVoyageSuccessAction } from "../voyage/form/save-voyage";
import { saveFixtureAction, saveFixtureFailAction, saveFixtureSuccessAction } from "./save-fixture";
import { selectCurrentFixtureVoyageIds } from "./selectors";

/* See docs/fixture-save.plantuml */
export const saveFixtureVoyagesEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveFixtureAction),
            withLatestFrom(store.pipe(select(selectCurrentFixtureVoyageIds))),
            // mergeMap required to return multiple actions
            mergeMap(([, voyageIds]) => voyageIds.map((voyageId) => saveVoyageAction({ voyageId })))
        )
    );

export const saveVoyageEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, voyageService: VoyageHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveVoyageAction),
            mergeMap(({ voyageId }) => of(voyageId).pipe(withLatestFrom(store.pipe(select(selectVoyage, { voyageId })), store.pipe(select(selectVoyageIsValid, { voyageId }))))),
            filter(([, , isValid]) => isValid),
            mergeMap(([voyageId, voyage]) =>
                voyageService.save(voyage).pipe(
                    mapTo(saveVoyageSuccessAction({ voyageId })),
                    catchError((err) => of(saveVoyageFailAction({ voyageId, error: err })))
                )
            )
        )
    );

export const showInvalidVoyageOnFixtureSaveEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, router: Router) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(saveFixtureAction),
                withLatestFrom(store.pipe(select(selectCurrentFixtureVoyageStates))),
                map(([, voyageStates]) => voyageStates.filter((x) => x.form.isInvalid)),
                filter((invalidVoyageStates) => invalidVoyageStates.length > 0),
                mergeMap((invalidVoyageStates) => navigateToVoyage(router, invalidVoyageStates[0].voyage))
            ),
        { dispatch: false }
    );

export const saveFixtureOnVoyagesSaveEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, fixtureService: FixtureDataService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveVoyageSuccessAction, saveVoyageFailAction),
            mergeMap(({ voyageId }) => of(voyageId).pipe(withLatestFrom(store.pipe(select(selectVoyageState, { voyageId }))))),
            mergeMap(([, { voyage: { fixtureId } }]) => of(fixtureId).pipe(withLatestFrom(store.pipe(select(selectFixtureVoyageStates, { fixtureId }))))),
            map(([, voyageStates]) => ({
                fixtureId: voyageStates[0].voyage.fixtureId,
                invalidVoyages: voyageStates.some((x) => x.form.isInvalid),
                erroredVoyages: voyageStates.some((x) => x.persistenceStatus === "failed"),
                anyPersisting: voyageStates.some((x) => x.persistenceStatus === "persisting")
            })),
            filter(({ anyPersisting }) => !anyPersisting),
            mergeMap(({ fixtureId, invalidVoyages, erroredVoyages }) => {
                const currentFixtureId = fixtureService.currentFixtureSnapshot.fixtureId;
                if (currentFixtureId !== fixtureId) {
                    return throwError(
                        `saveFixtureOnVoyagesSave$: Attempt to save non-current fixture from saveVoyageAction. saveVoyageAction.fixtureId=${fixtureId}, FixtureDataService.currentFixtureSnapshot.fixtureId=${currentFixtureId}`
                    );
                }

                // NOTE: NGRX Interop, saves current fixture
                const unlock = !invalidVoyages && !erroredVoyages;
                if (!unlock) {
                    console.warn(`saveFixtureOnVoyagesSave$: voyages saved invalidVoyages=${invalidVoyages}, erroredVoyages=${erroredVoyages}`);
                }

                return fixtureService.save(unlock).pipe(
                    map(() => saveFixtureSuccessAction({ fixtureId: fixtureId as FixtureId, unlock })),
                    catchError((err) => of(saveFixtureFailAction({ fixtureId: fixtureId as FixtureId, error: err })))
                );
            })
        )
    );
