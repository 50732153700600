import { Injectable } from "@angular/core";

import { Fixture, FixtureType, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class ExternalVisibilityMissingCargoRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    private readonly warningMessage = "Missing Cargo";

    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        voyages.forEach((voyage) => {
            if (!voyage.cargoes.length) {
                const path = [...this.getVoyagePathSegment(fixture.fixtureType, voyage), new FixtureWarningPathSegment("cargoes", null)];
                const dismissingRecord =
                    fixture.fixtureType.id !== FixtureType.Voyage
                        ? dismissedWarningRecord(new WarningHashBuilder().withVoyage(voyage.voyageId).withCategory("data").withMessage(this.warningMessage).build())
                        : null;
                warnings.push(new FixtureWarning("data", this.warningMessage, path, dismissingRecord));
            } else {
                voyage.cargoes.forEach((cargo, cargoIndex) => {
                    if (!cargo.cargoProduct) {
                        const path = [
                            ...this.getVoyagePathSegment(fixture.fixtureType, voyage),
                            new FixtureWarningPathSegment("cargoes", "Cargoes"),
                            FixtureWarningPathSegment.fromIndex(cargoIndex, "Cargo"),
                            new FixtureWarningPathSegment("cargoProduct", null)
                        ];
                        const dismissingRecord =
                            fixture.fixtureType.id !== FixtureType.Voyage
                                ? dismissedWarningRecord(
                                      new WarningHashBuilder().withVoyage(voyage.voyageId).withCargo(cargo.cargoId).withCategory("data").withMessage(this.warningMessage).build()
                                  )
                                : null;
                        warnings.push(new FixtureWarning("data", this.warningMessage, path, dismissingRecord));
                    }
                });
            }
        });

        return warnings;
    }
}
