import { Injectable } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";

import { FixturePopupTabCreator } from "./fixture-popup-tab-creator";
import { Fixture, Voyage } from "../../shared/models";
import { FixturePopupTab } from "../models/fixture-popup-tab";

@Injectable({
    providedIn: "root"
})
export class FixturePopupCommentsTabCreator implements FixturePopupTabCreator {
    constructor(private formBuilder: UntypedFormBuilder) {}

    createTabs(fixture: Fixture, _: Voyage, field: string, isReadOnly: boolean): FixturePopupTab[] {
        const form = this.formBuilder.group({
            comments: [fixture.comments]
        });
        if (isReadOnly) {
            form.disable();
        }
        return [
            {
                title: "Comments",
                type: "simple",
                isActive: field === "comments",
                isReadOnly,
                fixture,
                form
            }
        ];
    }
}
