<div class="row ops-placeholder" [class.has-description]="!!description">
    <div class="col-3">
        <ng-container *ngTemplateOutlet="icon || defaultIcon"></ng-container>
    </div>
    <div class="col-9">
        <div class="ops-placeholder-title">{{title}}</div>
        <div class="ops-placeholder-description" *ngIf="description">{{description}}</div>
    </div>
</div>

<ng-template #defaultIcon>
    <div class="ops-placeholder-icon-circle">
        <span class="has-icon icon--lightbulb-outline"></span>
    </div>
</ng-template>
