import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { map } from "rxjs/operators";

import { routeParamChange } from "@ops/state";

import { routerLoadLaytimeCalculationAction, routerLoadLaytimeCalculationActivityLocationAction } from "./calculations";
import { ActivityLocationId, LtcId } from "./model";

export const routeCalculationIdChange = () => routeParamChange("ltcId");
export const routeLocationIdChange = () => routeParamChange("locationId");

@Injectable()
export class LtcRoutingEffects {
    loadCalculation$ = createEffect(() =>
        this.actions$.pipe(
            routeCalculationIdChange(),
            map((ltcId: LtcId) => routerLoadLaytimeCalculationAction({ ltcId }))
        )
    );

    loadActivityLocation$ = createEffect(() =>
        this.actions$.pipe(
            routeLocationIdChange(),
            map((activityLocationId: ActivityLocationId) => routerLoadLaytimeCalculationActivityLocationAction({ activityLocationId }))
        )
    );

    constructor(private actions$: Actions) {}
}
