import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { Currency, hasValue } from "@ops/shared";

import { ActivityLocationId, LaytimeCalculationDurationUnit, LaytimeCalculationSummaryLocation, LtcId } from "../../state";

@Component({
    selector: "ops-laytime-calculation-summary-locations",
    templateUrl: "./laytime-calculation-summary-locations.component.html",
    styleUrls: ["./laytime-calculation-summary-locations.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationSummaryLocationsComponent {
    readonly hasValue = hasValue;

    @Input() ltcId: LtcId;
    @Input() isFixed: boolean;
    @Input() currency: Currency;
    @Input() locations: ReadonlyArray<LaytimeCalculationSummaryLocation>;
    @Input() durationUnit: LaytimeCalculationDurationUnit;
    @Input() hasDemurrage: boolean;
    @Input() hasDespatch: boolean;
    @Input() hasDetention: boolean;
    @Input() lastReversibleLocationId: ActivityLocationId;
    @Input() claimValue: number;
    @Input() claimType: string;
}
