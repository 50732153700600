import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfigService } from "src/app/core";
import { Vessel } from "src/app/fixture/shared/models/common/vessel";
import { VesselData } from "src/app/fixture/shared/models/common/vessel-data";
import { SuggestionService } from "../suggestion.service";

@Injectable({
    providedIn: "root"
})
export class VesselSuggestionService implements SuggestionService<VesselData> {
    private vesselServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.vesselServiceUrl = appConfigService.config.vesselServiceUrl;
    }

    getSuggestions(term: string): Observable<VesselData[]> {
        if (!term) {
            return of([]);
        }

        const url = `${this.vesselServiceUrl}/api/v1.0/vessels/search?query=${term}`;

        return this.httpClient.get<Vessel>(url).pipe(
            map((vessel) => {
                return vessel.data;
            })
        );
    }
}
