<div class="notes-form" >
    <textarea
        class="form-control"
        [ngrxFormControlState]="form.controls.notes"
        ngrxUpdateOn="blur"
        [readOnly]="readonly"
        id="notes"
        name="notes"
        maxlength="3000"
        placeholder="Notes saved here will be displayed on the map"
        data-public-notes
    ></textarea>
    <div class="last-updated" *ngIf="notesAudit">
        <span>Last updated: {{ notesAudit.dateModified | timezoneDate: "DATETIME" }} by {{ notesAudit.modifiedByUser?.fullName }}</span>
    </div>
</div>
