import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { ActionsSubject } from "@ngrx/store";
import { FocusAction, UnfocusAction } from "ngrx-forms";

import { CustomNgrxValueConverters } from "@ops/state";

import { laytimeEventRemarks, LaytimeCalculationDurationUnit } from "../../state";
import { DeductionState } from "../../state/calculations/activity-location/deductions/selectors";

@Component({
    selector: "ops-laytime-calculation-deductions",
    templateUrl: "./laytime-calculation-deductions.component.html",
    styleUrls: ["./laytime-calculation-deductions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationDeductionsComponent {
    readonly dateValueConverter = CustomNgrxValueConverters.dateToLocalDateTimeString;
    readonly laytimeEventRemarks = laytimeEventRemarks;

    @Input() deductions: ReadonlyArray<DeductionState>;
    @Input() durationUnit: LaytimeCalculationDurationUnit;

    @Output() readonly add = new EventEmitter<number>();
    @Output() readonly remove = new EventEmitter<string>();
    @Output() readonly clear = new EventEmitter();

    constructor(@Inject(ActionsSubject) private actionsSubject: ActionsSubject | null) {}

    trackBy(deduction: DeductionState) {
        return deduction.form?.id;
    }

    onDateFocus(controlId: string, elementName: string) {
        if (!elementName) {
            this.actionsSubject.next(new FocusAction(controlId));
        }
    }

    onDateBlur(controlId: string, elementName: string) {
        if (!elementName) {
            this.actionsSubject.next(new UnfocusAction(controlId));
        }
    }

    hasTooltip(deduction: DeductionState) {
        return deduction.beforeFirstLaytimeEvent || deduction.afterLastLaytimeEvent || deduction.overridesLaytimeEvent;
    }
}
