import { createAction, on, On, props } from "@ngrx/store";
import { setValue, StateUpdateFns, updateArrayWithFilter, updateGroup } from "ngrx-forms";

import {
    VoyageActivityFilterForm,
    AddActivityLocationsForm,
    AssociatedCargoSelectionForm,
    CargoFilterForm,
    LtcState,
    CargoId,
    VoyageActivityId,
    activityTypes
} from "../../../model";
import { currentCalculationStateReducer } from "../../reducer";
import { toVoyageActivityFilters } from "../selectors";

/* ACTIONS */
const SELECT_ALL_ACTION_NAME = "[LTC Add Activity Locations] Select All";
export const addActivityLocationsFilterSelectAllAction = createAction(`${SELECT_ALL_ACTION_NAME} Filter`);
export const addActivityLocationsSelectionSelectAllAction = createAction(`${SELECT_ALL_ACTION_NAME} Selection`);
export const addActivityLocationsSelectionSelectAllByCargoAction = createAction(`${SELECT_ALL_ACTION_NAME} Selection By Cargo`, props<{ cargoId: CargoId }>());
export const addActivityLocationsSelectionSelectAllByVoyageActivityAction = createAction(
    `${SELECT_ALL_ACTION_NAME} Selection By Voyage Activity`,
    props<{ voyageActivityId: VoyageActivityId }>()
);

/* REDUCERS */
export const addActivityLocationsFilterSelectAllReducer: On<LtcState> = on(addActivityLocationsFilterSelectAllAction, (state) =>
    currentCalculationStateReducer(state, (calculationState) => {
        const updateFns: StateUpdateFns<AddActivityLocationsForm>[] = [];
        let selected: boolean;
        const locationIds: Array<string> = [];
        const voyageActivityFilters = toVoyageActivityFilters(calculationState.addActivityLocationsForm.controls.voyageActivities, calculationState.voyage);
        voyageActivityFilters.forEach((a) => {
            if (activityTypes.includes(a.activity)) {
                locationIds.push(a.voyageActivityId);
            }
        });

        if (calculationState.addActivityLocationsForm.value.filter === "Locations") {
            selected = !calculationState.addActivityLocationsForm.value.voyageActivities.some((x) => x.selected && !x.imported);
            updateFns.push({
                voyageActivities: updateArrayWithFilter(
                    (va) => !va.value.imported && locationIds.includes(va.value.voyageActivityId),
                    updateGroup<VoyageActivityFilterForm>({ selected: setValue<boolean>(selected) })
                )
            });
        } else {
            selected = !calculationState.addActivityLocationsForm.value.cargoes.some((x) => x.selected && !x.imported);
            updateFns.push({
                cargoes: updateArrayWithFilter(
                    (c) => !c.value.imported,
                    updateGroup<CargoFilterForm>({ selected: setValue<boolean>(selected) })
                )
            });
        }

        if (!selected) {
            updateFns.push({
                associatedCargoes: updateArrayWithFilter(
                    (ac) => !ac.value.imported,
                    updateGroup<AssociatedCargoSelectionForm>({ selected: setValue<boolean>(false) })
                )
            });
        }

        return {
            ...calculationState,
            addActivityLocationsForm: updateGroup(updateFns)(calculationState.addActivityLocationsForm)
        };
    })
);

export const addActivityLocationsSelectionSelectAllReducer: On<LtcState> = on(addActivityLocationsSelectionSelectAllAction, (state) =>
    currentCalculationStateReducer(state, (calculationState) => {
        const form = calculationState.addActivityLocationsForm.value;

        const filteredCargoIds = form.cargoes.filter((x) => x.selected).map((x) => x.cargoId);
        const filteredVoyageActivityIds = form.voyageActivities.filter((x) => x.selected).map((x) => x.voyageActivityId);
        const filteredAssociatedCargoes = form.associatedCargoes.filter((x) =>
            form.filter === "Locations" ? filteredVoyageActivityIds.includes(x.voyageActivityId) : filteredCargoIds.includes(x.cargoId)
        );

        const selected = !filteredAssociatedCargoes.some((x) => x.selected && !x.imported);
        const filteredAssociatedCargoIds = filteredAssociatedCargoes.map((x) => x.associatedCargoId);

        return {
            ...calculationState,
            addActivityLocationsForm: updateGroup<AddActivityLocationsForm>({
                associatedCargoes: updateArrayWithFilter(
                    (ac) => filteredAssociatedCargoIds.includes(ac.value.associatedCargoId) && !ac.value.imported,
                    updateGroup<AssociatedCargoSelectionForm>({ selected: setValue<boolean>(selected) })
                )
            })(calculationState.addActivityLocationsForm)
        };
    })
);

export const addActivityLocationsSelectionSelectAllByCargoReducer: On<LtcState> = on(addActivityLocationsSelectionSelectAllByCargoAction, (state, { cargoId }) =>
    currentCalculationStateReducer(state, (calculationState) => {
        const selected = !calculationState.addActivityLocationsForm.value.associatedCargoes.filter((x) => x.cargoId === cargoId).some((x) => x.selected && !x.imported);

        return {
            ...calculationState,
            addActivityLocationsForm: updateGroup<AddActivityLocationsForm>({
                associatedCargoes: updateArrayWithFilter(
                    (ac) => ac.value.cargoId === cargoId && !ac.value.imported,
                    updateGroup<AssociatedCargoSelectionForm>({ selected: setValue<boolean>(selected) })
                )
            })(calculationState.addActivityLocationsForm)
        };
    })
);

export const addActivityLocationsSelectionSelectAllByVoyageActivityReducer: On<LtcState> = on(
    addActivityLocationsSelectionSelectAllByVoyageActivityAction,
    (state, { voyageActivityId }) =>
        currentCalculationStateReducer(state, (calculationState) => {
            const selected = !calculationState.addActivityLocationsForm.value.associatedCargoes
                .filter((x) => x.voyageActivityId === voyageActivityId)
                .some((x) => x.selected && !x.imported);

            return {
                ...calculationState,
                addActivityLocationsForm: updateGroup<AddActivityLocationsForm>({
                    associatedCargoes: updateArrayWithFilter(
                        (ac) => ac.value.voyageActivityId === voyageActivityId && !ac.value.imported,
                        updateGroup<AssociatedCargoSelectionForm>({ selected: setValue<boolean>(selected) })
                    )
                })(calculationState.addActivityLocationsForm)
            };
        })
);
