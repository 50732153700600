import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AppConfigService } from "@ops/core";
import { VoyageStatus } from "@ops/shared/reference-data";

import { Voyage } from "../shared/models";
import { PatchRequest } from "../shared/models/common/patch-request";
import { BerthId, DestinationId } from "../state/model";

export interface VoyageCreate {
    voyageNumber: number;
    voyageStatus: VoyageStatus;
    fixtureId: string;
}

@Injectable({ providedIn: "root" })
export class VoyageHttpService {
    private readonly voyageServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.voyageServiceUrl = appConfigService.config.voyageServiceUrl;
    }

    save(voyage: Voyage): Observable<Voyage> {
        return this.httpClient.put(`${this.voyageServiceUrl}/api/v2.0/fixtures/${voyage.fixtureId}/voyages/${voyage.voyageId}`, voyage).pipe(map(() => voyage));
    }

    create(voyage: VoyageCreate): Observable<string> {
        return this.httpClient.post<string>(`${this.voyageServiceUrl}/api/v2.0/fixtures/${voyage.fixtureId}/voyages/`, voyage);
    }

    getAll(fixtureId: string): Observable<Voyage[]> {
        return this.httpClient.get<Voyage[]>(`${this.voyageServiceUrl}/api/v2.0/fixtures/${fixtureId}/voyages`);
    }

    get(fixtureId: string, voyageId: string): Observable<Voyage> {
        return this.httpClient.get<Voyage>(`${this.voyageServiceUrl}/api/v2.0/fixtures/${fixtureId}/voyages/${voyageId}`);
    }

    verifyEta(fixtureId: string, voyageId: string, destinationId: DestinationId): Observable<any> {
        return this.httpClient.put(`${this.voyageServiceUrl}/api/v2.0/fixtures/${fixtureId}/voyages/${voyageId}/destinations/${destinationId}/verify-eta`, {});
    }

    verifyEtb(fixtureId: string, voyageId: string, destinationId: DestinationId, berthId: BerthId): Observable<any> {
        return this.httpClient.put(`${this.voyageServiceUrl}/api/v2.0/fixtures/${fixtureId}/voyages/${voyageId}/destinations/${destinationId}/berths/${berthId}/verify-etb`, {});
    }

    updateEtaEmailGenerated(fixtureId: string, voyageId: string): Observable<string> {
        const url = `${this.voyageServiceUrl}/api/v2.0/fixtures/${fixtureId}/voyages/${voyageId}/eta-email-generated`;
        return this.httpClient.put(url, {}).pipe(map((response) => <string>response));
    }

    updateLaytimeEmailGenerated(fixtureId: string, voyageId: string, destinationId: DestinationId, berthId: BerthId): Observable<string> {
        const url = `${this.voyageServiceUrl}/api/v2.0/fixtures/${fixtureId}/voyages/${voyageId}/destinations/${destinationId}/berths/${berthId}/laytime-email-generated`;
        return this.httpClient.put(url, {}).pipe(map((response) => <string>response));
    }

    patch(fixtureId: string, voyageId: string, request: PatchRequest) {
        const url = `${this.voyageServiceUrl}/api/v2.0/fixtures/${fixtureId}/voyages/${voyageId}`;
        return this.httpClient.patch(url, request);
    }
}
