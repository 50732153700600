<div class="card embedded-card-1">
    <div class="card-header row no-gutters align-items-center">
        {{ title }}
    </div>
    <div [formGroup]="parentForm" class="cp-speed">
        <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let column of (columnDefs$ | async)" class="{{ column.class }}">{{ column.headerName }}</th>
                </tr>
            </thead>
            <tbody [formArrayName]="formName">
                <tr *ngFor="let row of formArray.controls; let i = index" [formGroupName]="i" class="form-group">
                    <td>
                        <input type="text" readonly class="form-control" formControlName="seaStatus" data-test-sea-status />
                    </td>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="ballast"
                            [opsNumber]="{ precision: 3, minValue: 0 }"
                            data-test-ballast
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="laden"
                            [opsNumber]="{ precision: 3, minValue: 0 }"
                            data-test-laden
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
