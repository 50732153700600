import { BehaviorSubject } from "rxjs";
import { User } from "src/app/fixture/shared/models";
import { DocumentDto } from "./document.dto";
import { FileView } from "./file-view.model";

export class DocumentView {
    constructor(documentDto?: DocumentDto) {
        if (documentDto) {
            this.documentId = documentDto.documentId;
            this.name = documentDto.name;
            this.file = documentDto.file;
            this.permissions = documentDto.permissions;
            this.uploadedBy = documentDto.uploadedBy;
            this.uploadDate = documentDto.uploadDate;
        }
    }

    documentId?: string;
    name: string;
    file: FileView;
    permissions = new Array<string>();
    uploadedBy: User;
    uploadDate: Date;

    readonly loadPercentage$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    error?: string;

    static createForUpload(id: string, name: string, extension: string, file: File, user: User) {
        const document = new DocumentView();

        document.documentId = id;
        document.name = name;
        document.file = new FileView(`${name}${extension}`, extension, file.size);
        document.uploadDate = new Date();
        document.uploadedBy = user;
        document.loadPercentage$.next(0);

        return document;
    }
}
