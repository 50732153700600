import { Injectable } from "@angular/core";

export type QueryParam = "hideSeaHeader";

@Injectable({
    providedIn: "root"
})
export class InitialParamsService {
    private initialQueryParamMap = new Map<string, string>();

    setQueryParams(paramMap: Record<string, string>) {
        this.initialQueryParamMap = new Map(Object.entries(paramMap));
    }

    hasQueryParam(queryParam: QueryParam) {
        return this.initialQueryParamMap.has(queryParam);
    }
}
