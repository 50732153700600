<ng-select
    [items]="suggestions$ | async"
    [loading]="isLoading$ | async"
    [(ngModel)]="selection"
    [readonly]="disabled"
    bindLabel="companyName"
    placeholder="Search Company Name"
    (change)="onSelectionChange($event)"
    [selectOnTab]="true"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span container="body" [ngbTooltip]="item.companyName" class="ng-value-label">
            {{ item.companyName }}
        </span>
        <button type="button" class="ng-value-icon has-icon icon--cancel" (click)="clear(item)"></button>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div class="company flex-row no-gutters w-100 d-flex">
            <span [ngOptionHighlight]="search">{{ item.companyName }}</span>
        </div>
    </ng-template>
</ng-select>
