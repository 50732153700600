import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, filter, map, switchMap, withLatestFrom } from "rxjs/operators";

import { isNullOrUndefined } from "@ops/shared";
import { emptyObjectMap } from "@ops/state";

import { retryWithBackoff } from "../../../shared/utils/rxjs-utils";
import { LaytimeCalculationHttpService, FixtureHttpService } from "../../services";
import {
    LaytimeCalculation,
    LtcId,
    initialLaytimeCalculationState,
    LtcState,
    FixtureIndex,
    LtcFeatureState,
    ActivityLocationId,
    initLaytimeCalculationActivityLocationState
} from "../model";
import { calculationStateReducer } from "./reducer";
import { selectCurrentLaytimeCalculation } from "./selectors";

/* ACTIONS */
const LOAD_LAYTIME_CALCULATION_ACTION_NAME = "[Router] Load Laytime Calculation";
export const routerLoadLaytimeCalculationAction = createAction(LOAD_LAYTIME_CALCULATION_ACTION_NAME, props<{ ltcId: LtcId }>());
export const routerLoadLaytimeCalculationSuccessAction = createAction(`${LOAD_LAYTIME_CALCULATION_ACTION_NAME} Success`, props<{ calculation: LaytimeCalculation }>());
export const routerLoadLaytimeCalculationFailAction = createAction(`${LOAD_LAYTIME_CALCULATION_ACTION_NAME} Fail`, props<{ ltcId: LtcId; error: Error }>());

const LOAD_FIXTURE_ACTION_NAME = "[Router] Load Fixture";
export const routerLoadFixtureSuccessAction = createAction(`${LOAD_FIXTURE_ACTION_NAME} Success`, props<{ ltcId: LtcId; fixture: FixtureIndex }>());
export const routerLoadFixtureFailAction = createAction(`${LOAD_FIXTURE_ACTION_NAME} Fail`, props<{ ltcId: LtcId; error: Error }>());

const LOAD_LAYTIME_CALCULATION_ACTIVITY_LOCATION_ACTION_NAME = "[Router] Load Laytime Calculation Activity Location";
export const routerLoadLaytimeCalculationActivityLocationAction = createAction(
    LOAD_LAYTIME_CALCULATION_ACTIVITY_LOCATION_ACTION_NAME,
    props<{ activityLocationId: ActivityLocationId }>()
);

/* REDUCERS */
export const routerLoadLaytimeCalculationReducer: On<LtcState> = on(routerLoadLaytimeCalculationAction, (state, { ltcId }) => {
    if (isNullOrUndefined(ltcId)) {
        return {
            ...state,
            calculations: emptyObjectMap(),
            currentCalculationId: null
        };
    } else if (state.currentCalculationId !== ltcId) {
        return {
            ...state,
            ...calculationStateReducer(state, ltcId, { calculationLoadStatus: "loading" }),
            currentCalculationId: ltcId
        };
    }

    return state;
});

export const routerLoadLaytimeCalculationSuccessReducer: On<LtcState> = on(routerLoadLaytimeCalculationSuccessAction, (state, { calculation }) =>
    calculationStateReducer(state, calculation.id, (ltcState) => initLaytimeCalculationActivityLocationState({ ...ltcState, ...initialLaytimeCalculationState(calculation) }))
);

export const routerLoadLaytimeCalculationFailReducer: On<LtcState> = on(routerLoadLaytimeCalculationFailAction, (state, { ltcId }) =>
    calculationStateReducer(state, ltcId, { calculationLoadStatus: "failed" })
);

export const routerLoadFixtureSuccessReducer: On<LtcState> = on(routerLoadFixtureSuccessAction, (state, { ltcId, fixture }) =>
    calculationStateReducer(state, ltcId, { fixture, fixtureLoadStatus: "loaded" })
);

export const routerLoadFixtureFailReducer: On<LtcState> = on(routerLoadFixtureFailAction, (state, { ltcId }) =>
    calculationStateReducer(state, ltcId, { fixtureLoadStatus: "failed" })
);

export const routerLoadLaytimeCalculationActivityLocationReducer: On<LtcState> = on(routerLoadLaytimeCalculationActivityLocationAction, (state, { activityLocationId }) =>
    !isNullOrUndefined(state.currentCalculationId)
        ? calculationStateReducer(state, state.currentCalculationId, (ltcState) => {
              const newState = { ...ltcState, currentActivityLocationId: activityLocationId || null };

              if (ltcState.calculationLoadStatus === "loaded") {
                  return initLaytimeCalculationActivityLocationState(newState);
              }

              return newState;
          })
        : state
);

/* EFFECTS */
export const routerLoadLaytimeCalculationEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(routerLoadLaytimeCalculationAction),
            filter(({ ltcId }) => !isNullOrUndefined(ltcId)),
            withLatestFrom(store.select(selectCurrentLaytimeCalculation)),
            switchMap(([{ ltcId }, currentCalculation]) =>
                currentCalculation?.id === ltcId
                    ? of(routerLoadLaytimeCalculationSuccessAction({ calculation: currentCalculation }))
                    : laytimeCalculationHttpService.get(ltcId).pipe(
                          retryWithBackoff(15),
                          map((calculation: LaytimeCalculation) => routerLoadLaytimeCalculationSuccessAction({ calculation })),
                          catchError((error) => of(routerLoadLaytimeCalculationFailAction({ ltcId, error })))
                      )
            )
        )
    );

export const routerLoadFixtureEffect$ = (actions$: Actions, fixtureHttpService: FixtureHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(routerLoadLaytimeCalculationSuccessAction),
            switchMap(({ calculation: { fixtureId, id } }) =>
                fixtureHttpService.get(fixtureId).pipe(
                    map((fixture) => routerLoadFixtureSuccessAction({ ltcId: id, fixture })),
                    catchError((error) => of(routerLoadFixtureFailAction({ ltcId: id, error })))
                )
            )
        )
    );
