import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { AppConfigService, AuthService, NotificationService } from "@ops/core";

import { FavoriteDataService } from "./favorite-data.service";
import { DateUtilities } from "../../shared/date-utils/date-utilities";

@Injectable({
    providedIn: "root"
})
export class ExportService {
    private reportServiceUrl: string;

    constructor(
        private favouriteDataService: FavoriteDataService,
        private authService: AuthService,
        private notificationService: NotificationService,
        appConfigService: AppConfigService,
        private httpClient: HttpClient
    ) {
        this.reportServiceUrl = appConfigService.config.reportServiceUrl;
    }

    exportSelectedFavorites(reportType: string): void {
        const fixtures = this.favouriteDataService.getSelectedFavorites();

        if (fixtures.length === 0) {
            return;
        }

        this.notificationService.info("", "Your file(s) are being prepared, you will be notified when they are ready for download", 4000, true);

        fixtures.forEach((f) => {
            this.httpClient
                .post(
                    `${this.reportServiceUrl}/api/v1.0/report/generate/${reportType}`,
                    {
                        sourceUser: this.authService.user,
                        recipients: [this.authService.systemUser.systemUserId],
                        data: { fixtureId: f.fixtureId }
                    },
                    {
                        responseType: "text"
                    }
                )
                .subscribe(
                    (d) => {
                        console.log("Generating report with Id: " + d);
                    },
                    (error) => of(error)
                );
        });
    }

    exportLaytimeSummary(fixtureId: string, vesselName: string, chartererName: string, cpDate: string, reportType: string): Observable<void> {
        return this.httpClient
            .post(
                `${this.reportServiceUrl}/api/v1.0/report/generate/${reportType}`,
                {
                    sourceUser: this.authService.user,
                    recipients: [this.authService.systemUser.systemUserId],
                    data: { fixtureId: fixtureId, synchronous: true }
                },
                {
                    responseType: "blob"
                }
            )
            .pipe(
                tap((data) => {
                    const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                    const date = DateUtilities.formatISODate(cpDate, "utc", "DD-MM-YYYY");
                    const filename = `Laytime Calculation - ${vesselName.substring(0, 25)} - ${chartererName.substring(0, 25)} - ${date}.xlsx`;
                    saveAs(blob, filename);
                }),
                catchError((err) => {
                    console.error("Error occured while generating Laytime summary report", err);
                    return of(null);
                })
            );
    }
}
