import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { Evolver } from "ramda";
import * as R from "ramda";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";

import { persisted, selectCurrentUser } from "@ops/state";

import { LiftingHttpService } from "../../../../services";
import { coaLiftingsStateReducer } from "../../../coa/reducer";
import { CoaFeatureState, CoaId, CoasState, CoaState, LiftingId, toUser, User, VesselId, vesselIdEq } from "../../../model";
import { currentLiftingStateReducer, liftingStateReducer } from "../../reducer";
import { selectCurrentLifting } from "../../selectors";

/* ACTIONS */
const REMOVE_ACTION_NAME = "[Vessel Nomination Form] Remove Vessel Nomination";

export const removeVesselNominationAction = createAction(REMOVE_ACTION_NAME, props<{ vesselId: VesselId }>());
export const removeVesselNominationSuccessAction = createAction(`${REMOVE_ACTION_NAME} Success`, props<{ coaId: CoaId; liftingId: LiftingId; vesselId: VesselId; user: User }>());
export const removeVesselNominationFailAction = createAction(`${REMOVE_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

/* REDUCERS */
export const removeVesselNominationReducer: On<CoasState> = on(removeVesselNominationAction, (state) =>
    currentLiftingStateReducer(state, { vesselNominationFormSaveStatus: "persisting" })
);

export const removeVesselNominationSuccessReducer: On<CoasState> = on(removeVesselNominationSuccessAction, (state, { coaId, vesselId, liftingId }) => {
    const updateFns: Evolver = {
        vesselNominationFormSaveStatus: persisted,
        lifting: { vessels: R.reject(vesselIdEq(vesselId)) }
    };
    const withUpdatedLiftingState = liftingStateReducer(state, liftingId, R.evolve(updateFns));

    const getLiftings = (coaState: CoaState) =>
        coaState.fetchedLiftings.map((l) => (l.documentId !== liftingId ? l : { ...l, vessels: withUpdatedLiftingState.liftings.byId[liftingId].lifting.vessels }));

    return coaLiftingsStateReducer(withUpdatedLiftingState, coaId, getLiftings);
});

export const removeVesselNominationFailReducer: On<CoasState> = on(removeVesselNominationFailAction, (state, { liftingId }) =>
    liftingStateReducer(state, liftingId, { vesselNominationFormSaveStatus: "failed" })
);

/* EFFECTS */
export const removeVesselNominationEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeVesselNominationAction),
            withLatestFrom(store.select(selectCurrentLifting), store.select(selectCurrentUser)),
            switchMap(([{ vesselId }, { coaId, liftingId }, appUser]) =>
                liftingHttpService.removeVessel(coaId, liftingId, vesselId).pipe(
                    map(() => removeVesselNominationSuccessAction({ coaId, liftingId, vesselId, user: toUser(appUser) })),
                    catchError((error) => of(removeVesselNominationFailAction({ liftingId, error })))
                )
            )
        )
    );
