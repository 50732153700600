<div class="p-grid">
    <div class="p-field p-col-2">
        <label data-cy="working-day-label">Working Day</label>
        <ng-select class="ops-select" [ngrxFormControlState]="form.controls.workingDay" [items]="workingDays"
                   [clearable]="false" [selectOnTab]="true" data-cy="working-day">
        </ng-select>
    </div>
    <div class="p-field p-col-2" *ngIf="isExclusionVisible">
        <label data-cy="excluding-from-label">Excluding From</label>
        <ng-select class="ops-select" placeholder="Select" [ngrxFormControlState]="form.controls.exclusionStartDay" [items]="daysOfWeek"
                   [clearable]="true" [selectOnTab]="true" data-cy="excluding-from">
        </ng-select>
    </div>
    <div class="p-field col-time-input" *ngIf="isExclusionVisible">
        <input type="time" class="p-inputtext text-right headerless" ngrxUpdateOn="blur"
               [ngrxFormControlState]="form.controls.exclusionStartTime" data-cy="exclusion-start-time" />
    </div>
    <div class="p-field p-col-2" *ngIf="isExclusionVisible">
        <label data-cy="excluding-to-label">Excluding To</label>
        <ng-select class="ops-select" placeholder="Select" [ngrxFormControlState]="form.controls.exclusionEndDay" [items]="daysOfWeek"
                   [clearable]="true" [selectOnTab]="true" data-cy="excluding-to">
        </ng-select>
    </div>
    <div class="p-field col-time-input" *ngIf="isExclusionVisible">
        <input type="time" class="p-inputtext text-right headerless" ngrxUpdateOn="blur"
               [ngrxFormControlState]="form.controls.exclusionEndTime" data-cy="exclusion-end-time" />
    </div>
    <div class="p-field p-col-3">
        <label data-cy="laytime-range-label">Laytime Range</label>
        <ops-date-input
            *ngIf="form.value.laytimeRange?.value"
            mode="range"
            [enableTime]="true"
            timeZone="local"
            [ngrxFormControlState]="form.controls.laytimeRange"
            [ngrxValueConverter]="dateRangeValueConverter"
            data-cy="laytime-range"
        >
        </ops-date-input>
        <div class="p-d-flex p-ai-end" *ngIf="!form.value.laytimeRange?.value">
            <span *ngIf="laytimeEventRange" class="p-pt-2" data-cy="laytime-range">{{laytimeEventRange | maritimeDate}}</span>
            <span *ngIf="!laytimeEventRange" class="p-pt-2 no-laytime-range" data-cy="laytime-range">No laytime range</span>
            <ops-icon-button icon="edit" (click)="prefillLaytimeRange.emit(laytimeEventRange)" tooltip="Set Laytime Range" data-cy="set-laytime-range"></ops-icon-button>
        </div>
        <ops-validation [form]="form.controls.laytimeRange"></ops-validation>
    </div>
    <div *ngIf="!isAlwaysOnDemurrage && isExclusionVisible || isAlwaysOnDemurrage" class="p-col-12 p-d-flex">
        <span *ngIf="!isAlwaysOnDemurrage && isShex" class="has-icon icon--info-outline terms-message">&nbsp;Event % will be
            calculated according to SHEX terms.</span>
        <span *ngIf="!isAlwaysOnDemurrage && isShexUU" class="has-icon icon--info-outline terms-message">&nbsp;Event % will
            be calculated according to SHEX UU terms.</span>
        <span *ngIf="isAlwaysOnDemurrage && isShex" class="has-icon icon--info-outline terms-message">&nbsp;Event % will be
            calculated based on SHEX and “Once on demurrage, always on demurrage” terms.</span>
        <span *ngIf="isAlwaysOnDemurrage && isShexUU" class="has-icon icon--info-outline terms-message">&nbsp;Event % will be
            calculated based on SHEX UU and “Once on demurrage, always on demurrage” terms.</span>
    </div>
    <div *ngIf="isCustomaryQuickDespatchVisible" class="p-col-10 p-d-flex">
        <div class="p-field-checkbox">
            <input id="customaryQuickDespatch" type="checkbox" name="customaryQuickDespatch" class="p-checkbox"
                   [ngrxFormControlState]="form.controls.customaryQuickDespatch" data-cy="customary-quick-despatch" />
            <label for="customaryQuickDespatch">Customary Quick Despatch</label>
        </div>
    </div>
</div>
