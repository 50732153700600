import { User } from "@ops/state";
import { box, Boxed } from "ngrx-forms";
import { Lifting } from "./lifting";

export type LiftingHeaderForm = Readonly<{
    operators: Boxed<ReadonlyArray<User>>;
    liftingNumber: string;
    declarationDate: string;
}>;

export const liftingToForm = (source: Lifting): LiftingHeaderForm => ({
    operators: box(source.operators.map((x) => <User>{ userId: x.userId, userCode: x.userCode, fullName: x.name })),
    liftingNumber: source.liftingNumber,
    declarationDate: source.declarationDate
});
