<div class="side-panel" data-test-actions-panel>
    <span class="side-panel-title">
        Actions
        <div class="left-toolbar-expander" (click)="collapseLeftBar()">
            <div class="has-icon icon--close"></div>
        </div>
    </span>

    <div *ngIf="editingAction" class="d-flex flex-column side-panel-body">
        <ops-edit-action
            (cancelled)="cancelEditAction()"
            (deleteAction)="deleteAction($event)"
            (saveAction)="saveAction($event)"
            [title]="editActionTitle"
            [priorities]="priorities"
            [actionToEdit]="actionToEdit"
            class="d-flex flex-column h-100"
        ></ops-edit-action>
    </div>

    <div *ngIf="!editingAction" class="d-flex flex-column side-panel-body">
        <div class="actions-summary-title">
            <div class="row no-gutters">
                <div class="col-10">
                    <button type="button" (click)="addAction()" class="btn btn-primary m-0" data-test-create-action>
                        <span class="has-icon icon--add-circle-outline"></span>
                        <span>ACTION</span>
                    </button>
                </div>
                <div ngbDropdown role="group" placement="bottom-right" class="col-1 align-self-center" autoClose="outside">
                    <button
                        [ngClass]="isOverdue || isPending || isCompleted ? 'has-icon icon--filter float-right' : 'has-icon icon--empty-filter float-right'"
                        ngbDropdownToggle
                        date-test-filter-menu
                        [ngbTooltip]="tooltipContent"
                        container="body"
                        placement="left"
                    ></button>
                    <div class="dropdown-menu rounded-0 p-0 m-0" ngbDropdownMenu>
                        <div class="form-group d-flex dropdown-item m-0">
                            <input #overdue type="checkbox" name="overdue" [(ngModel)]="isOverdue" data-test-overdue-action />
                            <span>Overdue</span>
                            <div class="w-100 h-100 mask" (click)="overdue.click()"></div>
                        </div>
                        <div class="form-group d-flex dropdown-item m-0">
                            <input #pending type="checkbox" name="pending" [(ngModel)]="isPending" data-test-pending-action />
                            <span>Pending</span>
                            <div class="w-100 h-100 mask" (click)="pending.click()"></div>
                        </div>
                        <div class="form-group d-flex dropdown-item m-0">
                            <input #completed type="checkbox" name="completed" [(ngModel)]="isCompleted" data-test-completed-action />
                            <span>Completed</span>
                            <div class="w-100 h-100 mask" (click)="completed.click()"></div>
                        </div>
                    </div>
                </div>
                <div ngbDropdown role="group" placement="bottom-right" class="col-1 align-self-center">
                    <button class="has-icon icon--more-vert float-right" ngbDropdownToggle data-test-expand-menu></button>
                    <div class="dropdown-menu rounded-0 p-0 m-0" ngbDropdownMenu autoClose="outside">
                        <button class="dropdown-item" (click)="scrollToTop()" data-test-scroll-to-top>Scroll to top</button>
                        <button class="dropdown-item" (click)="collapseAll()" data-test-collapse>Collapse all</button>
                        <button class="dropdown-item" (click)="expandAll()" data-test-expand>Expand all</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isLoaded" class="actions-loading-panel">
            <ops-load-animation size="x-large" class="light-background"></ops-load-animation>
        </div>
        <div class="scrollable-content" *ngIf="actions.length > 0 && !allCompleted(isCompleted)" #scrollToPlaceholder>
            <ops-action
                *ngFor="let action of (actions | actionStatus: isOverdue:isPending:isCompleted)"
                [action]="action"
                (updateActionComplete)="updateActionComplete($event)"
                (editAction)="editAction($event)"
                (cloneAction)="cloneAction($event)"
                (toggleExpansion)="toggleExpansion($event)"
                [isExpanded]="isExpanded(action.actionId)"
                [isNew]="action.isNew"
                [isFirstNew]="action.isFirstNew"
                [fixtureId]="fixtureId"
                [fixtureSource]="fixtureSource"
            ></ops-action>
        </div>
        <div *ngIf="actions.length == 0 && isLoaded">
            <ops-placeholder title="No actions against this fixture" [description]="placeholderDescription" data-test-no-actions-placeholder> </ops-placeholder>
        </div>
        <div *ngIf="allCompleted(isCompleted)">
            <ops-placeholder [icon]="iconTemplate" title="All actions completed" [description]="placeholderDescription" data-test-completed-actions-placeholder>
                <ng-template #iconTemplate>
                    <div class="ops-placeholder-icon-circle circle-success">
                        <span class="has-icon icon--done-all"></span>
                    </div>
                </ng-template>
            </ops-placeholder>
        </div>
    </div>
</div>
