import { cloneNominationTaskReducer } from "./form/clone-nomination-task";
import { cancelNominationTaskEditReducer, editNominationTaskReducer, processNominationTaskFormChangeReducer } from "./form/edit-nomination-task";
import { removeNominationTaskFailReducer, removeNominationTaskSuccessReducer } from "./form/remove-nomination-task";
import { saveNominationTaskFailReducer, saveNominationTaskReducer, saveNominationTaskSuccessReducer } from "./form/save-nomination-task";

export default [
    editNominationTaskReducer,
    cancelNominationTaskEditReducer,
    saveNominationTaskSuccessReducer,
    saveNominationTaskFailReducer,
    saveNominationTaskReducer,
    cloneNominationTaskReducer,
    removeNominationTaskSuccessReducer,
    removeNominationTaskFailReducer,
    processNominationTaskFormChangeReducer
];
