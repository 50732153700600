import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mapTo, mergeMap, tap, withLatestFrom } from "rxjs/operators";

import { selectCurrentUser, User } from "@ops/state";

import { AppInsightsService } from "../../../../core/app-insights.service";
import { EmailPreviewService } from "../../../../shared/email";
import { VoyageHttpService } from "../../../services";
import { EmailGenerationService, EtaEmailGenerator } from "../../../shared/email";
import { Voyage } from "../../../shared/models";
import { selectCurrentFixtureId } from "../../fixture";
import { FixtureFeatureState, FixtureId, FixturesState, VoyageId } from "../../model";
import { selectCurrentVoyageId } from "../../voyage";
import { voyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
const GENERATE_ACTION_NAME = "[Voyage Form] Generate ETA Email";
const AUDIT_ACTION_NAME = "[Voyage Form] Audit Generate ETA Email";

export const generateEtaEmailAction = createAction(GENERATE_ACTION_NAME);
export const auditGenerateEtaEmailAction = createAction(AUDIT_ACTION_NAME, props<{ fixtureId: FixtureId; voyageId: VoyageId }>());
export const auditGenerateEtaEmailSuccessAction = createAction(`${AUDIT_ACTION_NAME} Success`, props<{ fixtureId: FixtureId; voyageId: VoyageId; date: string; user: User }>());
export const auditGenerateEtaEmailFailAction = createAction(`${AUDIT_ACTION_NAME} Fail`, props<{ fixtureId: FixtureId; voyageId: VoyageId; error: Error }>());

/* REDUCERS */
export const auditGenerateEtaEmailSuccessReducer: On<FixturesState> = on(auditGenerateEtaEmailSuccessAction, (state, { voyageId, date, user }) => {
    function updateVoyage(voyage: Voyage) {
        return {
            ...voyage,
            etaEmailGenerated: { date: date, user: user }
        };
    }

    return voyageStateReducer(state, voyageId, (voyageState) => ({
        ...voyageState,
        voyage: updateVoyage(voyageState.voyage),
        workingVoyage: voyageState.workingVoyage ? updateVoyage(voyageState.workingVoyage) : voyageState.workingVoyage
    }));
});

/* EFFECTS */
export const generateEtaEmailEffect$ = (
    actions$: Actions,
    store: Store<FixtureFeatureState>,
    emailGeneration: EmailGenerationService,
    emailPreviewer: EmailPreviewService,
    appInsightsMonitoringService: AppInsightsService
) =>
    createEffect(() =>
        actions$.pipe(
            ofType(generateEtaEmailAction),
            withLatestFrom(store.select(selectCurrentFixtureId), store.select(selectCurrentVoyageId)),
            mergeMap(([, fixtureId, voyageId]) =>
                emailGeneration.generate(EtaEmailGenerator).pipe(
                    tap((email) => {
                        emailPreviewer.preview(email, "ETA Email");
                        appInsightsMonitoringService.logInfo(`ETA Email generated. Subject: ${email.subject}.\n Body: ${email.body}`);
                    }),
                    mapTo(auditGenerateEtaEmailAction({ fixtureId, voyageId }))
                )
            )
        )
    );

export const auditGenerateEtaEmailEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, voyageService: VoyageHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(auditGenerateEtaEmailAction),
            withLatestFrom(store.select(selectCurrentUser)),
            mergeMap(([{ fixtureId, voyageId }, user]) =>
                voyageService.updateEtaEmailGenerated(fixtureId, voyageId).pipe(
                    map((date) =>
                        auditGenerateEtaEmailSuccessAction({
                            fixtureId: fixtureId,
                            voyageId: voyageId,
                            date: date,
                            user: user
                        })
                    ),
                    catchError((err) => of(auditGenerateEtaEmailFailAction({ fixtureId: fixtureId, voyageId: voyageId, error: err })))
                )
            )
        )
    );
