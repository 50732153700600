import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";

import { DATA_ACCESS_ERROR_MESSAGE, DATA_VALIDATION_ERROR_MESSAGE, GENERIC_ERROR_MESSAGE, isBadRequest, isForbidden, NotificationService } from "@ops/shared";

import { routerLoadLaytimeCalculationFailAction } from "./calculations";
import { addActivityLocationsFailAction } from "./calculations/add-activity-locations/form/add-activity-locations";
import { cloneLaytimeCalculationFailAction } from "./calculations/clone/clone-calculation";

const errorToMessage = (error: Error) => (isBadRequest(error) ? DATA_VALIDATION_ERROR_MESSAGE : isForbidden(error) ? DATA_ACCESS_ERROR_MESSAGE : GENERIC_ERROR_MESSAGE);

@Injectable()
export class LtcNotificationEffects {
    calculationLoadFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(routerLoadLaytimeCalculationFailAction),
                tap(({ error }) => this.notificationService.error("Failed to load calculation.", errorToMessage(error), true))
            ),
        { dispatch: false }
    );

    addActivityLocationsFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addActivityLocationsFailAction),
                tap(({ error }) => this.notificationService.error("Failed to add activity locations to calculation.", errorToMessage(error), true))
            ),
        { dispatch: false }
    );

    cloneCalculationFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(cloneLaytimeCalculationFailAction),
                tap(({ error }) => this.notificationService.error("Failed to clone calculation.", errorToMessage(error), true))
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private notificationService: NotificationService) {}
}
