import { MaritimeDate } from "@maritech/maritime-date";
import { DateTime } from "luxon";

import { Nullable, toDateTimeAsUtc } from "../../shared";

export const mapArrayToCommaSeparatedNames = (array: Nullable<any[]>) => mapArrayToCommaSeparatedString(array, (item) => item.name);

export const mapArrayToCommaSeparatedString = (array: Nullable<any[]>, mapper: (item: any) => any = (item) => item) => array?.map(mapper).join(", ") ?? "";

export const formatDate = (date: Nullable<string>, timeZone: string = "utc"): string =>
    date ? DateTime.fromISO(date).setZone(timeZone).toLocaleString(MaritimeDate.DATE_FORMAT) : "";

export const formatUtcDate = (date: Nullable<string>): string => (date ? toDateTimeAsUtc(date).toLocaleString(MaritimeDate.DATE_FORMAT) : "");

export const formatDateTime = (date: Nullable<string>, timeZone: string = "utc"): string =>
    date ? DateTime.fromISO(date).setZone(timeZone).toLocaleString(MaritimeDate.DATETIME_FORMAT) : "";

export const formatUtcDateTime = (date: Nullable<string>): string => (date ? toDateTimeAsUtc(date).toLocaleString(MaritimeDate.DATETIME_FORMAT) : "");
