import { MaritimeDate } from "@maritech/maritime-date";
import { DateTime } from "luxon";

import { Company } from "../../../left-bar/contacts/models/company.model";
import { Email } from "../../../shared/email";
import { FixtureType } from "../../../shared/reference-data";
import { isNullOrUndefined } from "../../../shared/utils";
import { getTotalLaytime } from "../../state/laytime-events/laytime-calculation";
import { BerthId, CargoId, DestinationId } from "../../state/model";
import { Berth, CargoBerthActivity, Destination, Fixture, Voyage } from "../models";
import { AbstractEmailGenerator } from "./email-generator";

export interface LaytimeEmailOptions {
    destinationId: DestinationId;
    berthId: BerthId;
}

export class PortTimesEmailGenerator extends AbstractEmailGenerator<LaytimeEmailOptions> {
    generate(fixture: Fixture, voyages: readonly Voyage[], companies: readonly Company[], options: LaytimeEmailOptions) {
        if (fixture.fixtureType.id === FixtureType.TimeCharter.id) {
            throw Error("FixtureType=TimeCharter not supported");
        }

        if (isNullOrUndefined(options) || isNullOrUndefined(options.destinationId) || isNullOrUndefined(options.berthId)) {
            throw Error("'options' is required and must contain valid destination and berth ids");
        }

        const voyage = voyages[0];

        const { destination, berth } = this.getBerth(voyage, options.destinationId, options.berthId);

        const subject = this.generateSubject(fixture, voyage, companies);
        const body = this.generateBody(fixture, voyage, companies, destination, berth);

        return new Email(subject, body, null, null);
    }

    private generateBody(fixture: Fixture, voyage: Voyage, companies: readonly Company[], destination: Destination, berth: Berth) {
        let body = this.generateBodySubject(fixture, voyage, companies) + "\r\n\r\n";

        if (destination.location) {
            body += `${destination.location.displayName}\r\n`;
        }

        if (berth.name) {
            body += `${berth.name}\r\n`;
        }

        const activitiesWithLaytimeEvents = berth.cargoBerthActivities.filter(
            (activity) => activity.type && activity.laytimeEvents && activity.laytimeEvents.some((laytimeEvent) => !!laytimeEvent.type)
        );

        if (activitiesWithLaytimeEvents.length > 1) {
            body += "\r\n";
        }

        for (const activity of activitiesWithLaytimeEvents) {
            body += `${activity.type.name}\r\n`;
            body += `${this.getLaytimeEvents(voyage, destination, activity)}\r\n`;
            body += "\r\n";
            const totalLaytimeTime = this.getLaytimeEventsTotalTime(activity);
            if (totalLaytimeTime) {
                body += `Laytime: ${totalLaytimeTime.toFixed(2)} hrs\r\n`;
            }
            body += "\r\n";
        }

        return body;
    }

    private getBerth(voyage: Voyage, destinationId: DestinationId, berthId: BerthId): { destination: Destination; berth: Berth } {
        const destination = voyage.destinations.find((d) => d.id === destinationId);
        const berth = destination ? destination.berths.find((b) => b.id === berthId) : null;

        if (!destination) {
            throw Error(`Invalid destinationId=${destinationId}`);
        }

        if (!berth) {
            throw Error(`Invalid berthId=${berthId}`);
        }

        return { destination, berth };
    }

    private getLaytimeEventsTotalTime(activity: CargoBerthActivity) {
        const laytimeEventsData = activity.laytimeEvents.map((laytimeEvent) => ({
            eventDate: laytimeEvent.eventDate,
            isStartOrStop: laytimeEvent.isStartOrStop,
            percentage: laytimeEvent.percentage
        }));

        return getTotalLaytime(laytimeEventsData);
    }

    private getLaytimeEvents(voyage: Voyage, destination: Destination, activity: CargoBerthActivity) {
        const laytimeEvents = new Array<string>();
        let hasLastStart = false;

        for (const laytimeEvent of activity.laytimeEvents) {
            const timeZone = (destination.location ? destination.location.timeZone : null) || "utc";
            const eventDate = DateTime.fromISO(laytimeEvent.eventDate, { zone: timeZone });

            let laytimeEventStr = `${eventDate.isValid ? eventDate.toLocaleString(MaritimeDate.DATETIME_FORMAT) : ""}\t`;
            const percentage = laytimeEvent.percentage;
            if (percentage) {
                laytimeEventStr += `${laytimeEvent.type?.name + " " + percentage + "%"}\t`;
            } else {
                laytimeEventStr += `${laytimeEvent.type?.name}\t`;
            }

            if (laytimeEvent.isStartOrStop) {
                const isStart = !hasLastStart;
                laytimeEventStr += `${isStart ? "Start" : "Stop"}\t`;
                hasLastStart = !hasLastStart;
            } else {
                laytimeEventStr += "\t";
            }

            if (laytimeEvent.cargoId) {
                const cargoProductName = this.getCargoProductName(voyage, laytimeEvent.cargoId);

                laytimeEventStr += cargoProductName;
            }

            laytimeEvents.push(laytimeEventStr);
        }

        return laytimeEvents.join("\r\n");
    }

    private getCargoProductName(voyage: Voyage, cargoId: CargoId) {
        const cargo = voyage.cargoes.find((c) => c.id === cargoId);
        return cargo && cargo.cargoProduct ? cargo.cargoProduct.name : null;
    }
}
