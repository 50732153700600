<ng-select
    [items]="suggestions$ | async"
    [loading]="isLoading$ | async"
    [(ngModel)]="selection"
    bindLabel="name"
    (change)="onSelectionChange($event)"
    class="auto-grow"
    [selectOnTab]="true"
    [readonly]="readonly || disabled"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span container="body" [ngbTooltip]="item.name" class="ng-value-label">
            <span>{{ item.name }}</span>
        </span>
        <button type="button" class="ng-value-icon has-icon icon--cancel" (click)="clear(item)"></button>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div class="vessel flex-row no-gutters w-100 d-flex">
            <img class="flex-shrink-1 position-absolute" [src]="!!item.flagCode ? clarksonsCdnUrl + '/Data/PublicArtificats/flags/ALPHA3/16x11/' + item.flagCode + '.gif' : ''" />
            <span class="vessel-identifier">{{ getVesselIdentifier(item) || "-" }}</span>
            <span class="name" [ngOptionHighlight]="search">{{ item.name }}</span>
            <span class="dead-weight">{{ item.deadWeight || "-" }}</span>
            <span class="build-date">{{ item.buildDate | date: "yyyy" }}</span>
            <span class="fleet-type">{{ getFleetType(item) || "-" }}</span>
        </div>
    </ng-template>
</ng-select>
