import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";

import { LaytimeCalculationUnit } from "@ops/shared/reference-data";

import { LaytimeRounding, LaytimeType, TimeSavedType } from "../../../shared/reference-data";
import { FormComponentBase } from "../../shared/form-component-base";
import { Division, Fixture } from "../../shared/models";
import { LaytimeTermsViewModel } from "../../shared/models/form-models/laytime-terms.model";
import { UpdateLaytimeTermsCommand } from "./commands/update-laytime-terms.command";

const HOURS_PER_DAY = 24;

@Component({
    selector: "ops-laytime-container",
    templateUrl: "./laytime-container.component.html",
    styleUrls: ["./laytime-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeContainerComponent extends FormComponentBase implements OnInit, OnDestroy, OnChanges {
    static componentName = "LaytimeContainerComponent";

    readonly ratesDivisions = [Division.gas, Division.dryCargo];

    laytimeTermsForm: UntypedFormGroup;
    Division = Division;

    @Input() parentForm: UntypedFormGroup;
    @Input() fixture: Fixture;
    @Input() controlName: string;
    @Input() displayUnitType: LaytimeCalculationUnit;
    @Output() laytimeTermsUpdated = new EventEmitter();

    get displayFormat() {
        return this.displayUnitType === "Hours" ? "(per hour)" : "(per day)";
    }

    constructor(private formBuilder: UntypedFormBuilder) {
        super();
    }

    ngOnInit() {
        this.createForm();
        this.setForm();
        this.subscribeToFormValueChanges();
        this.parentForm.registerControl(this.controlName, this.laytimeTermsForm);
    }

    ngOnDestroy() {
        this.removeFormSubscriptions();
        delete this.parentForm.controls[this.controlName];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fixture?.firstChange) {
            return;
        }
        if (this.laytimeTermsForm) {
            this.setForm();
        }
    }

    get isNonReversibleLaytime(): boolean {
        return !this.fixture.laytimeType || this.fixture.laytimeType.id === LaytimeType.NonFixed.id;
    }

    protected subscribeToFormValueChanges(): void {
        this.formValueSubscription = this.laytimeTermsForm.valueChanges.pipe(debounceTime(10)).subscribe((data) => {
            data.demurrageRate = this.setPerDayRate(data.demurrageRate);
            data.despatchRate = this.setPerDayRate(data.despatchRate);
            data.detentionRate = this.setPerDayRate(data.detentionRate);
            this.laytimeTermsUpdated.emit(new UpdateLaytimeTermsCommand(data));
        });
    }

    private createForm() {
        this.laytimeTermsForm = this.formBuilder.group({
            laytimeType: [],
            fixedLaytime: [],
            demurrageRate: [],
            despatchRate: [],
            detentionRate: [],
            isOnDemurrage: [],
            demurrageBankEnabled: [],
            laytimeTermsComments: [],
            timeSavedType: [],
            laytimeRounding: []
        });

        if (this.parentForm.disabled) {
            this.laytimeTermsForm.disable();
        } else {
            this.laytimeTermsForm.enable();
        }
    }

    private setForm() {
        const dataModel: LaytimeTermsViewModel = {
            laytimeType: this.fixture.laytimeType && this.fixture.laytimeType.id === LaytimeType.Fixed.id ? LaytimeType.Fixed.name : LaytimeType.NonFixed.name,
            fixedLaytime: this.fixture.fixedLaytime,
            demurrageRate: this.setPerHourRate(this.fixture.demurrage.rate),
            despatchRate: this.setPerHourRate(this.fixture.despatchRate),
            detentionRate: this.setPerHourRate(this.fixture.detentionRate),
            isOnDemurrage: this.fixture.isOnDemurrage,
            laytimeTermsComments: this.fixture.laytimeTermsComments,
            timeSavedType: this.fixture.timeSavedType?.id === TimeSavedType.WorkingTimeSaved.id ? "workingTimeSaved" : "allTimeSaved",
            demurrageBankEnabled: this.fixture.demurrageBankEnabled,
            laytimeRounding: this.setLaytimeRounding()
        };

        this.laytimeTermsForm.patchValue(dataModel, { emitEvent: false });
    }

    private setPerHourRate(value: number) {
        if (this.displayUnitType !== "Hours" || !value) {
            return value;
        }
        return value / HOURS_PER_DAY;
    }

    private setPerDayRate(value: number) {
        if (this.displayUnitType !== "Hours" || !value) {
            return value;
        }
        return value * HOURS_PER_DAY;
    }

    private setLaytimeRounding() {
        if (this.fixture.laytimeRounding) {
            return this.fixture.laytimeRounding.name.toLowerCase();
        }
        return LaytimeRounding.Exact.name.toLowerCase();
    }
}
