<table class="table">
   <thead>
      <tr>
          <th class="type-column-fixed">Type</th>
          <th class="description-column-fixed">Description</th>
          <th class="cost-column-fixed text-right">Cost</th>
      </tr>
   </thead>
   <tbody>
      <tr *ngFor="let cost of breakdown; trackBy: trackBy">
          <td>{{ cost.type }}</td>
          <td>{{ cost.description }}</td>
          <td class="text-right">{{ cost.cost | opsNumber: false:2:4 }}</td>
      </tr>
   </tbody>
   <tfoot>
      <tr>
          <td></td>
          <td></td>
          <td class="total text-right">{{ overallCost | opsNumber: false:2:4 }}</td>
      </tr>
   </tfoot>
</table>
