import { createAction, on, On, props } from "@ngrx/store";

import { FixtureTabName } from "../../shared/tab-validation/tab-validation-info";
import { getTabState } from "../../shared/tab-validation/tab-validation-utils";
import { currentFixtureStateReducer } from "../fixture/reducer";
import { FixturesState } from "../model";

/* ACTIONS */
export const updateValidationTabAction = createAction("[Validation] Update Validation Tab", props<{ tabName: FixtureTabName; isValid?: boolean }>());

/* REDUCERS */
export const updateValidationTabReducer: On<FixturesState> = on(updateValidationTabAction, (state, { tabName, isValid }) => {
    const tabState = getTabState({ tabName, isValid });

    return currentFixtureStateReducer(state, (fixtureState) => ({
        ...fixtureState,
        validationByTabs: {
            ...fixtureState.validationByTabs,
            [tabName]: {
                ...(fixtureState.validationByTabs?.[tabName] ?? getTabState({ tabName, isValid })),
                isValid: isValid ?? fixtureState.validationByTabs?.[tabName]?.isValid ?? tabState.isValid
            }
        }
    }));
});
