import { hasValue } from "./check";

/**
 * Returns the string representation of the number supplied, rounding the number to the maximum number
 * of decimal places specified.
 *
 * @param num The value to round and stringify.
 * @param maxDecimalPlaces The maximum number of decimal places to return.
 */
export const roundAndStringifyNumber = (num: number | null | undefined, maxDecimalPlaces: number) => (hasValue(num) ? Number(num.toFixed(maxDecimalPlaces)).toString() : undefined);
