import { Injectable } from "@angular/core";
import { of, Subject } from "rxjs";
import { AuthService } from "src/app/core";
import { SharingGroupsHttpService } from "src/app/fixture/services/sharing-groups-http.service";
import { ConfigurationRolesFixtureViewModel, SharingGroupsCompanyViewModel, SharingGroupsViewModel } from "./models/sharing-group.model";

@Injectable({
    providedIn: "root"
})
export class SharingGroupsService {
    private sharingGroupsSubject = new Subject<SharingGroupsCompanyViewModel>();
    sharingGroups$ = this.sharingGroupsSubject.asObservable();

    constructor(private sharingGroupsHttpService: SharingGroupsHttpService, private authService: AuthService) {}

    getSharingGroupsForCompany(companyId: number) {
        if (!companyId) {
            this.sharingGroupsSubject.next(new SharingGroupsCompanyViewModel());
            return;
        }

        const currentUserSharingGroups = this.authService.user.sharingGroups;

        this.sharingGroupsHttpService.get(companyId).subscribe((sharingGroups) => {
            const model = new SharingGroupsCompanyViewModel();
            model.companyId = companyId;

            if (sharingGroups && sharingGroups.length > 0) {
                for (const sharingGroup of sharingGroups) {
                    const sharingGroupModel = new SharingGroupsViewModel();

                    sharingGroupModel.name = sharingGroup.name;
                    sharingGroupModel.code = sharingGroup.code;
                    sharingGroupModel.isGroupAll = sharingGroup.isGroupAll;
                    sharingGroupModel.isCurrentUserPresent = currentUserSharingGroups.find((cs) => cs === sharingGroup.code) ? true : false;
                    sharingGroupModel.parentCode = sharingGroup.parentCode;

                    model.sharingGroups.push(sharingGroupModel);
                }
            }

            this.sharingGroupsSubject.next(model);
        });
    }

    updateSharingGroups(fixtureId: string, companyId: string, sharingGroups: SharingGroupsViewModel[]) {
        this.sharingGroupsHttpService.updateForFixture(fixtureId, companyId, sharingGroups).subscribe((d) => {}, (err) => of(err));
    }
}
