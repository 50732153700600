import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";

import { GENERIC_ERROR_MESSAGE, DATA_VALIDATION_ERROR_MESSAGE, isBadRequest, NotificationService } from "@ops/shared";

import { verifyEtbFail } from "./berths";
import { verifyEtaFail } from "./destinations";
import { saveFixtureFail } from "./fixture";
import { addVoyageFail, saveVoyageFail } from "./voyage";
import { loadDismissedWarningsFailAction, saveDismissedWarningsFailAction } from "./warnings/dismiss";

// TODO: (NGRX JC) Unit tests + look at CustomErrorHandler / ErrorMapppings / refactor

@Injectable()
export class FixtureNotificationEffects {
    fixtureSaveFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveFixtureFail),
                tap(({ error }) => this.notificationService.error("Failed to save fixture.", isBadRequest(error) ? DATA_VALIDATION_ERROR_MESSAGE : null, true))
            ),
        { dispatch: false }
    );

    voyageSaveFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addVoyageFail, saveVoyageFail),
                tap(({ error }) => this.notificationService.error("Failed to save voyage.", isBadRequest(error) ? DATA_VALIDATION_ERROR_MESSAGE : null, true))
            ),
        { dispatch: false }
    );

    verifyEtxFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(verifyEtaFail, verifyEtbFail),
                tap(({ type, error }) => {
                    const isEtb = type === verifyEtbFail.type;

                    this.notificationService.error(`Failed to verify et${isEtb ? "b" : "a"}.`, isBadRequest(error) ? DATA_VALIDATION_ERROR_MESSAGE : null, true);
                })
            ),
        { dispatch: false }
    );

    loadDismissedWarningsFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadDismissedWarningsFailAction),
                tap(() => this.notificationService.error("Failed to load dismissed warnings.", GENERIC_ERROR_MESSAGE, true))
            ),
        { dispatch: false }
    );

    saveDismissedWarningsFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveDismissedWarningsFailAction),
                tap(({ error }) =>
                    this.notificationService.error("Failed to save dismissed warnings.", isBadRequest(error) ? DATA_VALIDATION_ERROR_MESSAGE : GENERIC_ERROR_MESSAGE, true)
                )
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private notificationService: NotificationService) {}
}
