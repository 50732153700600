import { FormGroupState, updateArray, updateGroup, validate } from "ngrx-forms";
import { required } from "ngrx-forms/validation";

import { validateActivitiesForm } from "../../activities/form/validation";
import { BerthForm, FixtureState, VoyageState } from "../../model";

export const validateBerthForm = (voyageState: VoyageState, fixtureState: FixtureState) => (form: FormGroupState<BerthForm>) =>
    updateGroup<BerthForm>({
        id: validate(required),
        activities: validateActivitiesForm(voyageState, fixtureState)
    })(form);

export const validateBerthsForm = (voyageState: VoyageState, fixtureState: FixtureState) => updateArray<BerthForm>(validateBerthForm(voyageState, fixtureState));
