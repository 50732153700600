<div [formGroup]="cpSpeedAndConsumptionForm" class="card embedded-card-1">
    <div class="card-header row no-gutters align-items-center">
        <span class="title">CP Speed and Consumption</span>
    </div>

    <div class="card-body">
        <div class="row">
            <div class="col-4 pl-xl-2 col-xl-2">
                <ops-cp-speed
                    title="CP Speed"
                    formName="cpSpeedForm"
                    [parentForm]="cpSpeedAndConsumptionForm"
                    [model]="cpSpeedAndConsumption"
                    (updated)="updateCpSpeedAndConsumption($event)"
                >
                </ops-cp-speed>
            </div>

            <div class="d-xl-none d-block col-12 divider"></div>

            <div class="col-12 pl-xl-2 col-xl-6">
                <ops-bunker-consumption
                    title="CP Bunker Consumption (MT)"
                    formName="cpBunkerConsumptionForm"
                    [parentForm]="cpSpeedAndConsumptionForm"
                    [model]="cpSpeedAndConsumption.cpBunkersConsumption"
                    (updated)="updateCpSpeedAndConsumption($event)"
                >
                </ops-bunker-consumption>
            </div>

            <div class="d-xl-none d-block col-12 divider"></div>

            <div class="col-12 pl-xl-2 col-xl-4">
                <ops-engine-consumption
                    title="Engine Consumption"
                    formName="cpEngineConsumptionForm"
                    [parentForm]="cpSpeedAndConsumptionForm"
                    [model]="cpSpeedAndConsumption.engineConsumption"
                    (updated)="updateCpSpeedAndConsumption($event)"
                >
                </ops-engine-consumption>
            </div>
        </div>
        <div class="row" formGroupName="beaufortForceAndComments">
            <div class="d-xl-none d-block col-12 divider"></div>

            <div class="col-4 pl-xl-2 col-xl-2">
                <div class="form-group force">
                    <div class="d-flex force-labels">
                        <label for="maxBeaufortForce">Not exceeding Beaufort Force</label>
                    </div>
                    <div class="no-gutters force-controls">
                        <ng-select
                            class="ops-select"
                            [items]="beaufortForces$ | async"
                            placeholder="Select Beaufort Force"
                            formControlName="maxBeaufortForce"
                            [clearable]="true"
                            id="maxBeaufortForce"
                            data-test-maxbeaufortforce
                            [selectOnTab]="true"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>

            <div class="col-8 pl-xl-2 col-xl-10">
                <div class="form-group comments">
                    <div class="d-flex comments-labels">
                        <label for="comments">Comments</label>
                    </div>
                    <div class="no-gutters comments-controls">
                        <textarea
                            class="form-control"
                            formControlName="comments"
                            id="comments"
                            rows="2"
                            name="comments"
                            maxlength="3000"
                            data-test-comments
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
