import { Enumeration } from "../../../../../shared/reference-data";
import { Command } from "../../../../mediator/commands/command";
import { ActivityId, BerthId, DestinationId } from "../../../../state/model";
import { AddBunkersCommandHandler } from "./add-bunkers.command-handler";

export class AddBunkersCommand extends Command {
    constructor(destinationId: DestinationId, berthId: BerthId, berthActivityId: ActivityId, bunkerTypes: Enumeration[] = []) {
        super(AddBunkersCommandHandler, {
            destinationId,
            berthId,
            berthActivityId,
            bunkerTypes
        });
    }
}
