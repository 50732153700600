import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroupState } from "ngrx-forms";

import { VoyageCost } from "../../services/voyage-cost"; // TODO: (NGRX) Move this
import { VoyageForm, VoyageId, VoyageNumber } from "../../state";

@Component({
    selector: "ops-tc-voyage-form",
    templateUrl: "./tc-voyage-form.component.html",
    styleUrls: ["./tc-voyage-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeCharterVoyageFormComponent {
    @Input() form: FormGroupState<VoyageForm>;
    @Input() voyages: ReadonlyArray<VoyageNumber>;
    @Input() cost: VoyageCost;
    @Input() readonly: boolean;

    @Output() readonly voyageSelected = new EventEmitter<VoyageId>();
}
