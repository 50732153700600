import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { TypedAction } from "@ngrx/store/src/models";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";

import {
    ColumnDefinition,
    CreateWorksheet,
    getWorksheetTypeLocalStorageIdentifier,
    Search,
    SearchFail,
    searchFailReducer,
    searchReducer,
    SearchSuccess,
    searchSuccessReducer,
    SearchToken,
    selectCurrentWorksheet,
    selectSelectedWorksheetId,
    Sorting,
    toColumnSort,
    WorksheetFeatureState
} from "@ops/state";

import { selectCoaGridSearchCriteria } from "./selectors";
import { updateCoaGridColumnsAction } from "./update-columns";
import { updateCoaGridSearchTokensAction } from "./update-search-tokens";
import { updateCoaGridSortingAction } from "./update-sorting";
import { WorksheetLocalStorageService } from "../../../left-bar/worksheets/service/worksheet-local-storage.service";
import { CoaHttpService } from "../../services/coa-http.service";
import { CoaIndexItem } from "../model/coa-index-item";
import { CoaFeatureState, CoasState } from "../model/state";

/* ACTIONS */
const ACTION_NAME = "[Coa Grid] Search";

export const searchCoaGridAction = createAction(ACTION_NAME, props<Search>());
export const searchCoaGridSuccessAction = createAction(`${ACTION_NAME} Success`, props<SearchSuccess<CoaIndexItem>>());
export const searchCoaGridFailAction = createAction(`${ACTION_NAME} Fail`, props<SearchFail>());

/* REDUCERS */
export const searchCoaGridReducer: On<CoasState> = on(searchCoaGridAction, (state, action) => searchReducer(state, action, "grid"));
export const searchCoaGridSuccessReducer: On<CoasState> = on(searchCoaGridSuccessAction, (state, action) => searchSuccessReducer(state, action, "grid"));
export const searchCoaGridFailReducer: On<CoasState> = on(searchCoaGridFailAction, (state, action) => searchFailReducer(state, action, "grid"));

/* EFFECTS */
export const searchEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, coaHttpService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(searchCoaGridAction),
            withLatestFrom(store.select(selectCoaGridSearchCriteria)),
            mergeMap(([{ requestId, skip, take }, { criteria, sorting }]) =>
                coaHttpService.searchIndex(criteria, skip, take, sorting).pipe(
                    map((x) => searchCoaGridSuccessAction({ requestId, items: x.documents, total: x.total })),
                    catchError((error) => of(searchCoaGridFailAction({ requestId, error })))
                )
            )
        )
    );

export const updateCurrentWorksheetEffect$ = (actions$: Actions, store: Store<WorksheetFeatureState>, localStorage: WorksheetLocalStorageService) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(updateCoaGridColumnsAction, updateCoaGridSortingAction, updateCoaGridSearchTokensAction),
                withLatestFrom(store.select(selectCurrentWorksheet), store.select(selectSelectedWorksheetId)),
                filter(([, currentWorksheet]) => !!currentWorksheet),
                tap(([action, currentWorksheet, selectedWorksheetId]) => {
                    let updatedWorksheet: CreateWorksheet;

                    switch (action.type) {
                        case updateCoaGridColumnsAction.type:
                            const columns = (<{ columns: ReadonlyArray<ColumnDefinition> } & TypedAction<string>>action).columns;
                            updatedWorksheet = { ...currentWorksheet, columns };
                            break;
                        case updateCoaGridSortingAction.type:
                            const sort = toColumnSort((<{ sorting: Sorting } & TypedAction<string>>action).sorting);
                            updatedWorksheet = { ...currentWorksheet, sort };
                            break;
                        case updateCoaGridSearchTokensAction.type:
                            const queries = (<{ searchTokens: ReadonlyArray<SearchToken> } & TypedAction<string>>action).searchTokens;
                            updatedWorksheet = { ...currentWorksheet, queries };
                            break;
                    }

                    localStorage.set(getWorksheetTypeLocalStorageIdentifier(updatedWorksheet.worksheetType), { selectedWorksheetId, currentWorksheet: updatedWorksheet });
                })
            ),
        { dispatch: false }
    );
