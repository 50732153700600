import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap, withLatestFrom } from "rxjs/operators";

import { deepFreeze } from "@ops/shared";

import { deepEqual, isNullOrUndefined } from "../../../../app/shared/utils";
import { ExpenseDataService } from "../../services/expense-data.service";
import { FixtureDataService } from "../../services/fixture-data.service";
import { VoyageDataService } from "../../services/voyage-data.service";
import { FixtureFeatureState, selectCurrentFixtureDismissedWarnings } from "../../state";
import { activateWarningAction, dismissWarningAction, recalculateDismissedWarningsAction } from "../../state/warnings/dismiss";
import { FixtureWarningRules } from "./fixture-warning-rules";
import { DismissedWarningRecord, FixtureWarning, FixtureWarningState } from "./fixture-warning.model";
import { getVoyageId } from "./utils";

@Injectable({
    providedIn: "root"
})
export class FixtureWarningService {
    private readonly _currentWarning$ = new BehaviorSubject<FixtureWarning>(null);

    readonly warnings$: Observable<FixtureWarning[]>;

    constructor(
        warningRules: FixtureWarningRules,
        private fixtureService: FixtureDataService,
        private voyageService: VoyageDataService,
        private expenseDataService: ExpenseDataService,
        private store: Store<FixtureFeatureState>,
        private router: Router
    ) {
        //Fixture expenses from expenseDataService.currentExpenses$ get loaded on expense tab component init and then updated on the component model update.
        const uniqueVoyages$ = voyageService.voyages$.pipe(distinctUntilChanged((prev, current) => deepEqual(prev, current)));
        this.warnings$ = combineLatest([fixtureService.currentFixture$, uniqueVoyages$, expenseDataService.currentExpenses$]).pipe(
            map(([fixture, voyages, fixtureExpenses]) => {
                if (isNullOrUndefined(fixture) || isNullOrUndefined(voyages) || !voyages.every((x) => x.fixtureId === fixture.fixtureId)) {
                    return [];
                }
                const expenses = fixtureExpenses?.fixtureId === fixture.fixtureId && fixtureExpenses.expenses ? fixtureExpenses.expenses : [];
                const warnings = warningRules.getRules(fixture).reduce((prev, curr) => [...prev, ...curr.evaluate(fixture, voyages, expenses)], new Array<FixtureWarning>());

                this.store.dispatch(recalculateDismissedWarningsAction({ warnings }));

                return deepFreeze(warnings);
            }),
            tap((warnings) => {
                if (this._currentWarning$.value && !warnings.some((warning) => this.warningEquals(this._currentWarning$.value, warning))) {
                    this._currentWarning$.next(null);
                }
            }),
            shareReplay(1)
        );

        // When the voyage changes and the current warning is for a different voyage (index based - without this check
        // warning voyage navigation would be superseded by this) then set the current warning to null else it will
        // scroll back to that warning when switching back to that voyage.
        voyageService.current$
            .pipe(
                distinctUntilChanged((prev, curr) => {
                    const hasPrev = !isNullOrUndefined(prev);
                    const hasCurr = !isNullOrUndefined(curr);

                    return (!hasPrev && !hasCurr) || (hasPrev && hasCurr && prev.voyageId === curr.voyageId);
                }),
                withLatestFrom(this._currentWarning$),
                filter(([currentVoyage, warning]) => {
                    if (isNullOrUndefined(warning) || warning.path.length < 2 || warning.path[0].segment !== "voyages") {
                        return false;
                    }

                    return isNullOrUndefined(currentVoyage) || warning.path[1].segment !== currentVoyage.voyageId;
                })
            )
            .subscribe(() => {
                this._currentWarning$.next(null);
            });

        this.warnings$.subscribe();
    }

    get currentWarning$(): Observable<FixtureWarning> {
        return this._currentWarning$.asObservable();
    }

    setCurrentWarning(warning: FixtureWarning | null) {
        of(warning)
            .pipe(
                filter((x) => !!x),
                withLatestFrom(this.fixtureService.currentFixture$, this.voyageService.current$),
                switchMap(([currentWarning, fixture, voyage]) => {
                    const currentFixtureId = fixture?.fixtureId;
                    const currentVoyageId = voyage?.voyageId;
                    const warningVoyageId = getVoyageId(currentWarning.path);

                    if (!isNullOrUndefined(warningVoyageId) && currentVoyageId !== warningVoyageId) {
                        return this.navigateToVoyage(currentFixtureId, warningVoyageId);
                    }
                    return of(true);
                }),
                tap(() => this._currentWarning$.next(warning))
            )
            .subscribe();
    }

    get warningStates$(): Observable<FixtureWarningState[]> {
        return combineLatest([this.fixtureService.isLockedByCurrentUser$, this.warnings$, this.dismissedWarnings$]).pipe(
            map(([isLockedByCurrentUser, warnings, dismissedWarnings]) =>
                warnings.map((warning) => ({
                    isEditMode: isLockedByCurrentUser,
                    warning,
                    isDismissed: warning.record && dismissedWarnings?.some((w) => w.warningHash === warning.record.warningHash)
                }))
            )
        );
    }

    activateWarning(warning: FixtureWarning) {
        if (warning.record) {
            this.store.dispatch(activateWarningAction({ warningHash: warning.record.warningHash }));
        }
    }

    dismissWarning(warning: FixtureWarning) {
        if (warning.record) {
            this.store.dispatch(dismissWarningAction({ record: warning.record }));
        }
    }

    private get dismissedWarnings$(): Observable<ReadonlyArray<DismissedWarningRecord>> {
        return this.store.select(selectCurrentFixtureDismissedWarnings);
    }

    private navigateToVoyage(fixtureId: string, voyageId: string): Promise<boolean> {
        return this.router.navigate([{ outlets: { primary: ["fixture", fixtureId, "voyage", voyageId] } }], {
            replaceUrl: true,
            queryParamsHandling: "merge"
        });
    }

    private warningEquals(a: FixtureWarning, b: FixtureWarning) {
        if (a.path.length !== b.path.length) {
            return false;
        }
        if (a.path === b.path) {
            return true;
        }

        if (a.path.length !== b.path.length) {
            return false;
        }

        for (let i = 0; i < a.path.length; i++) {
            if (a.path[i].segment !== b.path[i].segment) {
                return false;
            }
        }

        return true;
    }
}
