import { createAction, On, on, props } from "@ngrx/store";
import { updateGroup } from "ngrx-forms";

import { opsAddArrayControl } from "@ops/state";

import { updateBerthForm } from "../../berths/shared";
import { activityExpandedKey, activityForm, BerthId, createActivityId, DestinationId, FixturesState, getDivision } from "../../model";
import { getNextId } from "../../utils";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const addActivityAction = createAction("[Voyage Form] Add Activity", props<{ destinationId: DestinationId; berthId: BerthId }>());

/* REDUCERS */
export const addActivityReducer: On<FixturesState> = on(addActivityAction, (state, { destinationId, berthId }) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        const destination = voyageState.form.controls.destinations.controls.find((d) => d.value.id === destinationId);
        if (!destination) {
            return voyageState;
        }
        const berth = destination.controls.berths.controls.find((b) => b.value.id === berthId);
        if (!berth) {
            return voyageState;
        }
        const division = getDivision(fixtureState);
        const newActivityId = createActivityId();
        const newLegacyActivityId = getNextId(berth.value.activities, "legacyActivityId");

        return {
            ...voyageState,
            form: updateBerthForm({ destinationId, berthId }, (berthForm) =>
                updateGroup(berthForm, {
                    activities: opsAddArrayControl(activityForm(division, newActivityId, newLegacyActivityId), { markAsTransient: true, scrollIntoView: true })
                })
            )(voyageState.form),
            expandedSections: {
                ...voyageState.expandedSections,
                [activityExpandedKey(destinationId, berthId, newActivityId)]: true
            }
        };
    })
);
