import { InjectionToken } from "@angular/core";

import { Enumeration } from "../../../../shared/reference-data/enumeration";
import { ExpenseClaim, Voyage } from "../../../shared/models";
import { AssociatedCargo, Berth, CargoBerthActivity, Destination, Fixture, FixtureType } from "../../models";
import { FixtureWarning, FixtureWarningPath } from "../fixture-warning.model";
import { FixtureWarningPathSegment } from "../fixture-warning.model";

export const FIXTURE_WARNING_RULE = new InjectionToken("FixtureWarningRule");

export interface FixtureWarningRule {
    evaluate(fixture: Fixture, voyages: Voyage[], expenses: ExpenseClaim[]): FixtureWarning[];

    shouldEvaluate(fixture: Fixture): boolean;
}

export abstract class AbstractFixtureWarningRule {
    shouldEvaluate(_fixture: Fixture) {
        return true;
    }

    protected getVoyagePathSegment(fixtureType: Enumeration, voyage: Voyage): FixtureWarningPath {
        return [
            new FixtureWarningPathSegment("voyages", null),
            new FixtureWarningPathSegment(voyage.voyageId, fixtureType.id === FixtureType.Voyage ? "Voyage" : `Voyage ${voyage.voyageNumberDisplay}`)
        ];
    }

    protected getActivityPath(
        fixture: Fixture,
        voyage: Voyage,
        destinationIndex: number,
        destination: Destination,
        berthIndex: number,
        berth: Berth,
        activityIndex: number,
        activity: CargoBerthActivity
    ) {
        return [
            ...this.getVoyagePathSegment(fixture.fixtureType, voyage),
            FixtureWarningPathSegment.fromProperty<Voyage>("destinations", "Locations"),
            FixtureWarningPathSegment.fromIndex(destinationIndex, "Location", destination.location ? destination.location.displayName : null),
            FixtureWarningPathSegment.fromProperty<Destination>("berths", null),
            FixtureWarningPathSegment.fromIndex(berthIndex, "Berth", berth.name),
            FixtureWarningPathSegment.fromProperty<Berth>("cargoBerthActivities"),
            FixtureWarningPathSegment.fromIndex(activityIndex, "Activity", activity.type ? activity.type.name : null)
        ];
    }

    protected getAssociatedCargoPath(
        fixture: Fixture,
        voyage: Voyage,
        destinationIndex: number,
        destination: Destination,
        berthIndex: number,
        berth: Berth,
        activityIndex: number,
        activity: CargoBerthActivity,
        associatedCargoIndex: number,
        associatedCargo: AssociatedCargo
    ) {
        const cargo = associatedCargo.cargoId && voyage.cargoes.find((c) => c.id === associatedCargo.cargoId);

        return [
            ...this.getActivityPath(fixture, voyage, destinationIndex, destination, berthIndex, berth, activityIndex, activity),
            FixtureWarningPathSegment.fromProperty<CargoBerthActivity>("associatedCargoes"),
            FixtureWarningPathSegment.fromIndex(associatedCargoIndex, "Cargo", cargo?.cargoProduct?.name)
        ];
    }
}
