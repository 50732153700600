import { FormGroupState, StateUpdateFns, updateGroup, validate } from "ngrx-forms";
import { greaterThan } from "ngrx-forms/validation";

import { inclusiveBetween, validateRequired } from "@ops/state";

import { FixtureType } from "../../../shared/models";
import { validateCargoesForm } from "../../cargoes/form/validation";
import { validateDestinationsForm } from "../../destinations/form/validation";
import { FixtureId, FixturesState, getFixtureState, isFixtureType, VoyageForm, VoyageState } from "../../model";

export const validateVoyageForm = (form: FormGroupState<VoyageForm>, voyageState: VoyageState, fixturesState: FixturesState) => {
    const fixtureState = getFixtureState(fixturesState, voyageState.voyage.fixtureId as FixtureId);

    // If we don't yet have the state or the fixture, bail, the reducer will run again when it is set
    if (!fixtureState || !fixtureState.fixture) {
        return form;
    }

    const conditionalValidations = new Set<StateUpdateFns<VoyageForm>>();

    if (isFixtureType(fixtureState, FixtureType.TimeCharter)) {
        conditionalValidations.add({
            voyageStatus: validateRequired()
        });
    }

    return updateGroup<VoyageForm>(
        {
            cargoes: validateCargoesForm(voyageState, fixtureState),
            destinations: validateDestinationsForm(voyageState, fixtureState),
            cargoPercentage: validate(inclusiveBetween(0, 100)),
            cargoQuantityLoadedMt: validate(greaterThan(0)),
            vesselDeadweight: validate(greaterThan(0))
        },
        ...conditionalValidations
    )(form);
};
