import { Directive, HostBinding, HostListener, Input, OnDestroy, OnInit } from "@angular/core";
import { Table } from "primeng/table";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({
    selector: "tr[opsMultiselect]"
})
export class MultiselectTableRowDirective implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("opsMultiselect") data: unknown;
    @Input() index: number;
    @Input() isReadonly: boolean;

    @HostBinding("class.p-highlight") selected: boolean;
    // needed for focusing to work
    @HostBinding("attr.tabindex") tabIndex = this.isEnabled() ? 0 : undefined;

    constructor(public table: Table) {
        if (!this.isReadonly) {
            this.table.tableService.selectionSource$.pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.selected = this.table.isSelected(this.data);
            });
        }
    }

    @HostListener("click", ["$event"])
    onClick(event: MouseEvent) {
        if (!this.isReadonly && (event.metaKey || event.ctrlKey || event.shiftKey)) {
            this.table.handleRowClick({ rowIndex: this.index, originalEvent: event, rowData: this.data });
        }
    }

    @HostListener("keydown.shift.arrowdown", ["$event"])
    onArrowDownKeyDown(event: KeyboardEvent) {
        if (this.isReadonly) {
            return;
        }

        const row = <HTMLTableRowElement>event.currentTarget;
        const nextRow = <HTMLTableRowElement>row.nextElementSibling;

        if (nextRow) {
            // get index and data of next row
            const nextRowIndex = this.index + 1;
            const nextRowData = this.table._value[nextRowIndex];

            // perform a select action on that row
            this.table.handleRowClick({ rowIndex: nextRowIndex, originalEvent: event, rowData: nextRowData });

            nextRow.focus();
        }

        event.preventDefault();
    }

    @HostListener("keydown.shift.arrowup", ["$event"])
    onArrowUpKeyDown(event: KeyboardEvent) {
        if (this.isReadonly) {
            return;
        }

        const row = <HTMLTableRowElement>event.currentTarget;
        const prevRow = <HTMLTableRowElement>row.previousElementSibling;

        if (prevRow) {
            // get index and data of previous row
            const prevRowIndex = this.index - 1;
            const prevRowData = this.table._value[prevRowIndex];

            // perform a select action on that row
            this.table.handleRowClick({ rowIndex: prevRowIndex, originalEvent: event, rowData: prevRowData });

            prevRow.focus();
        }

        event.preventDefault();
    }

    isEnabled() {
        return !this.isReadonly;
    }

    ngOnInit() {
        if (!this.isReadonly) {
            this.selected = this.table.isSelected(this.data);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
