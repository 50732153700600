import { createFormArrayState, FormArrayState } from "ngrx-forms";

import { ActivityLocationId } from "./activity-location";
import { LaytimeEventRemark } from "./laytime-event-remarks";

export type Deduction = Readonly<{
    id: string;
    timeFrom: string;
    timeTo: string;
    remarks: LaytimeEventRemark;
}>;

export const createDeduction = (id: string, timeFrom: string = "", timeTo: string = "", remarks: LaytimeEventRemark = null): Deduction => ({
    id,
    timeFrom,
    timeTo,
    remarks
});

export const deductionFormsKey = "deductionForms";
export const createDeductionFormsState = (activityLocationId: ActivityLocationId, deductions: Deduction[]): FormArrayState<Deduction> =>
    createFormArrayState(`${activityLocationId}.${deductionFormsKey}`, deductions);
