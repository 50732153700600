import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { CompanySearchResult } from "../../../../fixture/shared/models/common/company-search-result.model";
import { AutosuggestComponent } from "../../../../shared/components/autosuggest/autosuggest.component";
import { CompanyAutosuggestService } from "../../company-autosuggest.service";

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CompanyAutosuggestComponent),
    multi: true
};

@Component({
    selector: "ops-company-autosuggest",
    templateUrl: "./company-autosuggest.component.html",
    styleUrls: ["../../../../shared/components/autosuggest/autosuggest.component.scss", "./company-autosuggest.component.scss"],
    providers: [VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyAutosuggestComponent extends AutosuggestComponent<CompanySearchResult> implements ControlValueAccessor {
    @Input() readonly: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() selectionChange = new EventEmitter<any[]>();

    constructor(protected companySuggestionService: CompanyAutosuggestService, protected element: ElementRef, protected changeDetector: ChangeDetectorRef) {
        super(companySuggestionService, element, changeDetector);
    }

    onSelectionChange(selection: any): void {
        this.selectionChange.emit(selection);
        if (selection) {
            this.ngSelectComponent.clearItem(selection);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any) {
        this.ngSelectComponent.registerOnChange((company: CompanySearchResult) => {
            fn(company);
        });
    }
}
