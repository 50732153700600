import { Pipe, PipeTransform } from "@angular/core";
import { TcVoyageModel } from "./tc-voyage.model";

@Pipe({
  name: "voyageStatus"
})
export class VoyageStatusPipe implements PipeTransform {

  transform(voyages: TcVoyageModel[], isOpen: boolean, isClosed: boolean): any {

    const filteredVoyages: TcVoyageModel[] = [];

    for (const voyage of voyages) {
      if (voyage.isOpen && !isOpen) {
        continue;
      }

      if (voyage.isClosed && !isClosed) {
        continue;
      }

      filteredVoyages.push(voyage);
    }

    return filteredVoyages;
  }
}
