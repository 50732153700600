<form
    novalidate
    [ngrxFormState]="form"
    class="worksheet-grid-rename-form"
    (submit)="onSubmit()"
>
    <div class="input-cancel-wrapper">
        <input
            type="text"
            class="worksheet-name"
            [ngrxFormControlState]="form.controls.name"
            [ngrxEnableFocusTracking]="true"
            (keyup)="processWorksheetNameKeypress($event)"
            [ngbPopover]="nameValidation"
            placement="top-left"
            container="body"
            #namePopover="ngbPopover"
            (blur)="namePopover.close()"
            (focus)="namePopover.open()"
            [autoClose]="false"
            triggers="manual"
            data-test-edit-worksheet-name
        />
        <button type="button" class="btn has-icon icon--cancel cancel-worksheet-name-edit" (click)="cancel.emit()"></button>
    </div>
    <ng-template #nameValidation>
        <ops-field-validation *ngIf="showValidationMessage" fieldDisplayName="Name" [errorMessage]="errorMessage"></ops-field-validation>
    </ng-template>
</form>
