import { Action, On, createReducer } from "@ngrx/store";

import { ObjectMapUpdateFns, ObjectUpdateFns, onObjectMapForms, onObjectForms, wrapReducerWithObjectFormStatesUpdate, wrapReducerWithObjectMapFormStatesUpdate } from "./forms-reducer";
import { emptyObjectMap } from "./utils";
import { WorksheetsState, WorksheetState } from "./worksheets";
import { validateWorksheetGridRenameForm, validateWorksheetListRenameForm } from "./worksheets/form/validation";
import reducers from "./worksheets/reducers";

export const initialWorksheetsState: WorksheetsState = {
    worksheets: emptyObjectMap<WorksheetState>()
};

const rawReducer = createReducer(
    initialWorksheetsState,
    ...reducers,
    onObjectMapForms((s) => s.worksheets) as On<WorksheetsState>,
    onObjectForms as On<WorksheetsState>
);

export const worksheetsReducer = (state: WorksheetsState | undefined, action: Action) => {
    const validateWorksheets: ObjectUpdateFns<WorksheetsState> = {
        gridRenameForm: validateWorksheetGridRenameForm,
    };

    const validateWorksheet: ObjectMapUpdateFns<WorksheetsState, WorksheetState> = {
        renameForm: validateWorksheetListRenameForm
    };

    let reducedState = rawReducer(state, action);
    reducedState = wrapReducerWithObjectFormStatesUpdate(validateWorksheets)(reducedState);
    reducedState = wrapReducerWithObjectMapFormStatesUpdate((s) => s.worksheets, validateWorksheet)(reducedState);

    return reducedState;
};
