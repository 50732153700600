import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { FixtureAutosuggestService } from "./fixture-autosuggest.service";
import { SuggestedFixtureDocument } from "../../../../fixture/shared/models/common/suggested-fixture-document";
import { AutosuggestComponent } from "../autosuggest.component";

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FixtureAutosuggestComponent),
    multi: true
};

@Component({
    selector: "ops-fixture-autosuggest",
    templateUrl: "./fixture-autosuggest.component.html",
    styleUrls: ["../../../../shared/components/autosuggest/autosuggest.component.scss", "./fixture-autosuggest.component.scss"],
    providers: [VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixtureAutosuggestComponent extends AutosuggestComponent<SuggestedFixtureDocument> {
    static componentName = "FixtureAutosuggestComponent";

    constructor(protected fixtureAutosuggestService: FixtureAutosuggestService, protected element: ElementRef, protected changeDetector: ChangeDetectorRef) {
        super(fixtureAutosuggestService, element, changeDetector);
    }
}
