import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    selector: "ops-length-counter",
    // eslint-disable-next-line @typescript-eslint/quotes
    template: `<span [class.warn]="lengthWarning" [class.full]="maxLengthReached">{{ currentLength }}/{{ maxLength }}</span>`,
    styleUrls: ["./length-counter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LengthCounterComponent {
    @Input() length: number;
    @Input() maxLength: number;
    @Input() warnAtCharactersRemaining = 50;

    get currentLength() {
        return this.length ?? 0;
    }

    get lengthWarning(): boolean {
        return this.charactersRemaining <= this.warnAtCharactersRemaining && this.charactersRemaining > 0;
    }

    get maxLengthReached(): boolean {
        return this.charactersRemaining === 0;
    }

    private get charactersRemaining(): number {
        return this.maxLength - this.currentLength;
    }
}
