import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionFormModel } from "src/app/action";
import { ActionIsCompleteRequest } from "../shared/action-is-complete-request";
import { ActionType } from "../shared/action-type";

@Component({
    selector: "ops-action",
    templateUrl: "./action.component.html",
    styleUrls: ["./action.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionComponent {
    static componentName = "ActionComponent";

    @Input() action: ActionFormModel;
    @Input() isExpanded: boolean;
    @Input() isNew: boolean;
    @Input() isFirstNew: boolean;
    @Input() fixtureId: string;
    @Input() fixtureSource: string;

    @Output() editAction = new EventEmitter<string>();
    @Output() cloneAction = new EventEmitter<string>();
    @Output() updateActionComplete = new EventEmitter<ActionIsCompleteRequest>();
    @Output() toggleExpansion = new EventEmitter<string>();

    toggleComplete() {
        this.action.isComplete = !this.action.isComplete;
        const request = <ActionIsCompleteRequest>{
            actionId: this.action.actionId,
            isComplete: this.action.isComplete
        };

        this.updateActionComplete.emit(request);
    }

    edit() {
        this.editAction.emit(this.action.actionId);
    }

    clone() {
        this.cloneAction.emit(this.action.actionId);
    }

    toggle() {
        this.toggleExpansion.emit(this.action.actionId);
    }

    isAutoAction(actionId: any) {
        return actionId !== ActionType.UserCreated.id;
    }
}
