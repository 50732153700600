<div class="card-header pl-0 row no-gutters">
    <div class="d-flex w-100">
        <button
            type="button"
            (click)="toggle()"
            class="has-icon"
            [class.icon--arrow-drop-right]="!expanded"
            [class.icon--arrow-drop-down]="expanded"
            data-test-toggle-button
        ></button>
        <ng-content></ng-content>
    </div>
</div>

