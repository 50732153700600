import { box, Boxed, unbox } from "ngrx-forms";

import { CargoBerthActivityType, LaytimeEventType, VoyageStatus } from "@ops/shared/reference-data";

import { BerthForm } from ".";
import { ActivityForm } from "./activity";
import { CargoForm, CargoId, cargoToForm, formToCargo } from "./cargo";
import { DestinationForm, destinationToForm, formToDestination } from "./destination";
import { AdditionalFreightExpense, AtSeaBunkerConsumption, Division, FixtureType, Voyage } from "../../shared/models";

export type VoyageId = string;
export type TimeCharterVoyageReference = string;

export type VoyageForm = Readonly<{
    voyageId: VoyageId;
    cargoes: ReadonlyArray<CargoForm>;
    destinations: ReadonlyArray<DestinationForm>;
    additionalFreightExpenses?: ReadonlyArray<AdditionalFreightExpense>;
    notes?: string;
    notesIsPrivate?: boolean;

    /* Voyages - LTC */
    // cargoAllowedRates?: ReadonlyArray<CargoAllowedRate>;

    /* Time Charters */
    tcVoyageReference?: TimeCharterVoyageReference;
    voyageStatus?: Boxed<VoyageStatus>; // TODO: Ideally these would just be ids?

    /* Speed & Cons */
    cargoQuantityLoadedMt?: number;
    cargoPercentage?: number;
    vesselDeadweight?: number;
    lineOfBusiness?: string;

    /* Not in NGRX */
    atSeaBunkersConsumption: ReadonlyArray<AtSeaBunkerConsumption>;
}>;
type SpeedAndConsumptionFormKey = keyof Pick<VoyageForm, "cargoQuantityLoadedMt" | "cargoPercentage" | "vesselDeadweight" | "lineOfBusiness">;
export const speedAndConsTabFormKeys: SpeedAndConsumptionFormKey[] = ["cargoQuantityLoadedMt", "cargoPercentage", "vesselDeadweight", "lineOfBusiness"];

export const voyageToForm = (source: Voyage, fixtureType: FixtureType, division: Division): VoyageForm => ({
    voyageId: source.voyageId,
    cargoes: source.cargoes.map(cargoToForm(fixtureType, division, source.destinations)),
    destinations: source.destinations.map(destinationToForm(fixtureType, division)),
    notes: source.notes ? source.notes.comment : null,
    notesIsPrivate: source.notes && source.notes.isPrivate,
    additionalFreightExpenses: source.additionalFreightExpenses || [],

    tcVoyageReference: source.tcVoyageReference,
    voyageStatus: box(source.voyageStatus),

    cargoPercentage: source.cargoPercentage,
    cargoQuantityLoadedMt: source.cargoQuantityLoadedMt,
    vesselDeadweight: source.vesselDeadweight,
    lineOfBusiness: source.lineOfBusiness,

    atSeaBunkersConsumption: source.atSeaBunkersConsumption
});

export const formToVoyage = (source: VoyageForm, current: Voyage): Voyage => ({
    ...current,

    cargoes: source.cargoes.map((cargo) =>
        formToCargo(
            cargo,
            current.cargoes.find((x) => x.id === cargo.cargoId)
        )
    ),
    destinations: source.destinations.map((destination) =>
        formToDestination(
            destination,
            current.destinations.find((x) => x.id === destination.id)
        )
    ),
    additionalFreightExpenses: source.additionalFreightExpenses.concat() || [],
    notes: source.notes
        ? {
              ...current.notes,
              comment: source.notes,
              isPrivate: source.notesIsPrivate ?? false
          }
        : null,

    voyageNumber: current.voyageNumber,
    voyageNumberDisplay: current.voyageNumberDisplay,
    tcVoyageReference: source.tcVoyageReference,
    voyageStatus: unbox(source.voyageStatus),

    cargoPercentage: source.cargoPercentage,
    cargoQuantityLoadedMt: source.cargoQuantityLoadedMt,
    vesselDeadweight: source.vesselDeadweight,
    lineOfBusiness: source.lineOfBusiness,

    atSeaBunkersConsumption: source.atSeaBunkersConsumption.concat()
});

/**
 * Returns the event date of the latest laytime event for the given activity type and laytime event type.
 *
 * @param voyage The voyage to search.
 * @param activityType The activity type to match.
 * @param laytimeEventType The laytime event type to match.
 */
export const getLatestLaytimeEventDate = (voyage: VoyageForm, activityType: CargoBerthActivityType, laytimeEventType: LaytimeEventType) => {
    let latestEventDate: string;

    for (const destination of voyage.destinations) {
        for (const berth of destination.berths) {
            for (const activity of berth.activities) {
                const currActivityType = unbox(activity.type);

                if (!currActivityType || currActivityType.id !== activityType.id) {
                    continue;
                }

                for (const laytimeEvent of activity.laytimeEvents) {
                    const currLaytimeEventType = unbox(laytimeEvent.type);

                    if (currLaytimeEventType && currLaytimeEventType.id === laytimeEventType.id && (!latestEventDate || laytimeEvent.eventDate > latestEventDate)) {
                        latestEventDate = laytimeEvent.eventDate;
                    }
                }
            }
        }
    }

    return latestEventDate;
};

export const getActivitiesWithCargo = (voyageForm: VoyageForm, cargoId: CargoId, activityType: CargoBerthActivityType): ReadonlyArray<ActivityForm> => {
    const activities: ActivityForm[] = [];

    for (const destination of voyageForm.destinations) {
        for (const berth of destination.berths) {
            for (const activity of berth.activities) {
                const activityActivityType = unbox(activity.type);

                if (!activityActivityType || activityActivityType.id !== activityType.id) {
                    continue;
                }

                if (activity.associatedCargoes.some((c) => c.cargoId === cargoId)) {
                    activities.push(activity);
                }
            }
        }
    }

    return activities;
};

/**
 * Returns the activities for the given activity type.
 *
 * @param voyage The voyage to search.
 * @param activityType The activity type to match.
 */
export const getActivities = (voyage: VoyageForm, activityType: CargoBerthActivityType): ReadonlyArray<ActivityForm> => {
    const activities: ActivityForm[] = [];

    for (const destination of voyage.destinations) {
        for (const berth of destination.berths) {
            for (const activity of berth.activities) {
                const activityActivityType = unbox(activity.type);

                if (!activityActivityType || activityActivityType.id !== activityType.id) {
                    continue;
                }

                activities.push(activity);
            }
        }
    }

    return activities;
};

/**
 * Returns the destinations that has activities with given cargo for the given activity type.
 *
 * @param voyage The voyage to search.
 * @param cargoId The cargoId to match.
 * @param activityType The activity type to match.
 */
export const getDestinationsWithCargo = (voyageForm: VoyageForm, cargoId: CargoId, activityType: CargoBerthActivityType) => {
    for (const destination of voyageForm.destinations) {
        const destinationElements = getBerthsWithCargo(cargoId, activityType, destination.berths);
        if (destinationElements) {
            return {
                destination,
                ...destinationElements
            };
        }
    }
};

const getBerthsWithCargo = (cargoId: CargoId, activityType: CargoBerthActivityType, berths: Readonly<BerthForm[]>) => {
    for (const berth of berths) {
        for (const activity of berth.activities) {
            const activityActivityType = unbox(activity.type);

            if (!activityActivityType || activityActivityType.id !== activityType.id) {
                continue;
            }

            for (const associatedCargo of activity.associatedCargoes) {
                if (associatedCargo.cargoId === cargoId) {
                    return {
                        berth,
                        activity,
                        associatedCargo
                    };
                }
            }
        }
    }
};
