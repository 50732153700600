import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import { Delivery } from "src/app/fixture/shared/models/dtos/delivery.dto";
import { HireRateUnit } from "src/app/fixture/shared/models/enums/hire-rate-unit";
import { DateUtilities } from "../../../../../shared/date-utils/date-utilities";
import { CommandHandler } from "../../../../mediator/command-handlers/command-handler";
import { Fixture } from "../../../../shared/models";
import { Period } from "../../../../shared/models/dtos/period.dto";
import { sortPeriods } from "../periods-sorter";
import { UpdatePeriodCommand } from "./update-period.command";

@Injectable({
    providedIn: "root"
})
export class UpdatePeriodCommandHandler implements CommandHandler {
    private secondsInADay = 86400;

    handle = (fixture: Fixture, command: UpdatePeriodCommand) => {
        const updatedPeriod = command.period;
        const matchingPeriod = fixture.periods.find((p) => p.hireId === updatedPeriod.hireId);

        matchingPeriod.toleranceInDays = updatedPeriod.toleranceInDays;
        matchingPeriod.minDuration = updatedPeriod.minDuration;
        matchingPeriod.maxDuration = updatedPeriod.maxDuration;
        matchingPeriod.durationType = updatedPeriod.durationType;
        matchingPeriod.hireRateUnit = updatedPeriod.hireRateUnit;
        matchingPeriod.hireRate = updatedPeriod.hireRate;
        matchingPeriod.invoicesPaidUpTo = DateUtilities.dateToISOString(updatedPeriod.invoicesPaidUpTo);

        const existingPeriodFrom = DateUtilities.parseISODate(matchingPeriod.periodRange.from);
        const existingPeriodTo = DateUtilities.parseISODate(matchingPeriod.periodRange.to);

        if (DateUtilities.notEquals(existingPeriodFrom, updatedPeriod.periodFrom)) {
            this.handlePeriodFromChange(matchingPeriod, existingPeriodTo, updatedPeriod.periodFrom);
            this.updateOffHireValue(matchingPeriod);
        } else if (DateUtilities.notEquals(existingPeriodTo, updatedPeriod.periodTo)) {
            this.handlePeriodToChange(matchingPeriod, existingPeriodFrom, updatedPeriod.periodTo);
            this.updateOffHireValue(matchingPeriod);
        } else if (matchingPeriod.durationInDays !== updatedPeriod.durationInDays) {
            this.handleDurationChange(matchingPeriod, existingPeriodFrom, existingPeriodTo, updatedPeriod.durationInDays);
            this.updateOffHireValue(matchingPeriod);
        }

        fixture.periods = sortPeriods(fixture.periods);
        this.updateActualDeliveryDateToFirstPeriodFromDate(fixture.delivery, fixture.periods);
    };

    private handlePeriodFromChange(period: Period, existingPeriodTo: Date, newPeriodFrom: Date) {
        period.periodRange.from = DateUtilities.dateToISOString(newPeriodFrom);

        if (newPeriodFrom && existingPeriodTo) {
            period.durationInDays = DateUtilities.calculateDurationInDays(existingPeriodTo, newPeriodFrom);
        }
    }

    private handlePeriodToChange(period: Period, existingPeriodFrom: Date, newPeriodTo: Date) {
        period.periodRange.to = DateUtilities.dateToISOString(newPeriodTo);

        if (newPeriodTo && existingPeriodFrom) {
            period.durationInDays = DateUtilities.calculateDurationInDays(newPeriodTo, existingPeriodFrom);
        }
    }

    private handleDurationChange(period: Period, existingPeriodFrom: Date, existingPeriodTo: Date, updatedDuration: number) {
        period.durationInDays = updatedDuration;

        if (period.durationInDays !== null && period.durationInDays !== undefined) {
            const durationInSeconds = period.durationInDays * this.secondsInADay;

            if (existingPeriodFrom) {
                period.periodRange.to = DateUtilities.dateToISOString(
                    moment(existingPeriodFrom)
                        .add(durationInSeconds, "seconds")
                        .toDate()
                );
            } else if (existingPeriodTo) {
                period.periodRange.from = DateUtilities.dateToISOString(
                    moment(existingPeriodTo)
                        .subtract(durationInSeconds, "seconds")
                        .toDate()
                );
            }
        }
    }

    private updateOffHireValue(matchingPeriod: Period) {
        if (matchingPeriod.hireRateUnit !== null && matchingPeriod.hireRateUnit.id === HireRateUnit.LumpSum) {
            const offHires = matchingPeriod.offHires;
            offHires.forEach((offHire) => {
                const hireRatePerDay = matchingPeriod.hireRate / matchingPeriod.durationInDays;
                offHire.value = offHire.durationInDays ? hireRatePerDay * offHire.durationInDays : 0;
            });
        }
    }

    private updateActualDeliveryDateToFirstPeriodFromDate(delivery: Delivery, periods: Period[]): void {
        delivery.actualDeliveryDate = periods[0].periodRange.from;
    }
}
