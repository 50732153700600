import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";

import { QuantityModel } from "../../../fixture/shared/models/form-models";
import { NumberInputOptions } from "../../directives/number-input/number-input.directive";
import { formatNumber, parseNumber } from "../../number-format";
import { Enumeration } from "../../reference-data";
import { ReferenceDataType } from "../../reference-data/reference-data-type";
import { hasValue, isWhitespace } from "../../utils";
import { ReferenceDataDropdownComponent } from "../reference-data-dropdown/reference-data-dropdown.component";

@Component({
    selector: "ops-quantity-input",
    templateUrl: "./quantity-input.component.html",
    styleUrls: ["./quantity-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuantityInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: QuantityInputComponent,
            multi: true
        }
    ]
})
export class QuantityInputComponent implements ControlValueAccessor, Validator, OnInit {
    configuration: NumberInputOptions = {
        precision: 2,
        showTrailingZeros: true,
        minimumFractionDigits: 0,
        minValue: 0,
        maxValue: null,
        type: "number"
    };

    unit: string = null;
    disabled = false;

    get inputText() {
        return this.inputEl.nativeElement.value;
    }

    @Input() type: ReferenceDataType;
    @Input() readonly: boolean;
    @Input() placeholder: string;
    @Input() allowClear: boolean;
    @Input() coaSector: string;
    @Input() dropdownBindValue: string;
    @Input() dropdownValues: ReadonlyArray<string>;
    @Input() appendTo: string;
    @Input() set inputNumberFormat(value: NumberInputOptions) {
        Object.assign(this.configuration, value);
    }

    @Output() readonly focus = new EventEmitter();
    @Output() readonly blur = new EventEmitter();

    @ViewChild("input", { static: true }) inputEl: ElementRef;
    @ViewChild(ReferenceDataDropdownComponent, { static: true }) dropdown: ReferenceDataDropdownComponent;

    ngOnInit() {
        if (!this.dropdownBindValue) {
            // bind the dropdown to the id property unless specified
            this.dropdownBindValue = "id";
        }
    }

    validate(control: FormControl): ValidationErrors | null {
        const value = control.value?.value;
        const { precision, type } = this.configuration;
        const normalisedNumber = type === "string" ? parseNumber(value, precision) : value;

        return this.validateNumber(normalisedNumber);
    }

    writeValue(quantity: QuantityModel): void {
        this.unit = quantity?.unit;
        this.dropdown.writeValue(quantity?.unit);
        this.setTextToInput(quantity?.value);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.dropdown.setDisabledState(isDisabled);
    }

    onInputChange() {
        const normalizedNumber = parseNumber(this.inputText, this.configuration.precision);
        const value = this.formatInputText(this.inputText);

        this.setTextToInput(normalizedNumber);
        this._onChange({ value, unit: this.unit });
    }

    onDropdownChange(unit: string | Enumeration) {
        this.unit = (<Enumeration>unit)?.name ?? <string>unit;
        const value = this.formatInputText(this.inputText);

        this._onChange({ value, unit: this.unit });
    }

    onBlur() {
        this._onTouched();
        this.blur.emit();
    }

    private formatInputText(inputValue: string) {
        if (!hasValue(inputValue) || isWhitespace(inputValue)) {
            return null;
        }

        const normalizedNumber = parseNumber(inputValue, this.configuration.precision);
        if (this.configuration?.type === "number") {
            return normalizedNumber;
        }

        const { showTrailingZeros, minimumFractionDigits, precision } = this.configuration;
        return formatNumber(normalizedNumber, showTrailingZeros, minimumFractionDigits, precision, false);
    }

    private setTextToInput(value: number) {
        const { showTrailingZeros, minimumFractionDigits, precision } = this.configuration;
        const requiredValue = formatNumber(value, showTrailingZeros, minimumFractionDigits, precision);

        if (this.inputText !== requiredValue) {
            this.inputEl.nativeElement.value = requiredValue;
        }
    }

    private validateNumber(normalizedNumber: number, configuration = this.configuration): ValidationErrors {
        if (configuration.minValue !== null) {
            const minValue = configuration.minValue;
            if (normalizedNumber < minValue) {
                return {
                    minValue: {
                        valid: false
                    }
                };
            }
        }

        if (configuration.maxValue !== null) {
            const maxValue = configuration.maxValue;
            if (normalizedNumber > maxValue) {
                return {
                    maxValue: {
                        valid: false
                    }
                };
            }
        }

        return null;
    }

    private _onTouched = () => {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _onChange = (_: any) => {};
}
