import { Injectable } from "@angular/core";
import { CommandHandler } from "../../../../mediator/command-handlers/command-handler";
import { Fixture } from "../../../../shared/models";
import { DeleteBunkerCommand } from "./delete-bunker.command";

@Injectable({
    providedIn: "root"
})
export class DeleteBunkerCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: DeleteBunkerCommand) {
        const index = command.payload;
        fixture.delivery.bunkers.splice(index, 1);
    }
}
