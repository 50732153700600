import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaustMap, map, tap, withLatestFrom } from "rxjs/operators";

import { navigateToLifting } from "../../routing";
import { coaToAddLifting, LiftingHttpService } from "../../services/lifting-http.service";
import { CoaFeatureState, CoaId, CoasState, LiftingId } from "../model";
import { coaStateReducer, currentCoaStateReducer } from "./reducer";
import { selectCurrentCoa } from "./selectors";

/* ACTIONS */
const ACTION_NAME = "[Coa Shell] Add Lifting";

export const addLiftingAction = createAction(ACTION_NAME);
export const addLiftingSuccessAction = createAction(`${ACTION_NAME} Success`, props<{ coaId: CoaId; liftingId: LiftingId }>());
export const addLiftingFailAction = createAction(`${ACTION_NAME} Fail`, props<{ coaId: CoaId; error: Error }>());

/* REDUCERS */
export const addLiftingReducer: On<CoasState> = on(addLiftingAction, (state) => currentCoaStateReducer(state, { liftingCreationPending: true }));
export const addLiftingSuccessAndFailReducer: On<CoasState> = on(addLiftingSuccessAction, addLiftingFailAction, (state, { coaId }) =>
    coaStateReducer(state, coaId, { liftingCreationPending: false })
);

/* EFFECTS */
export const addLiftingEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(addLiftingAction),
            withLatestFrom(store.select(selectCurrentCoa)),
            exhaustMap(([, coa]) => {
                const addLifting = coaToAddLifting(coa);
                return liftingHttpService.addLifting(addLifting).pipe(
                    map(() => addLiftingSuccessAction({ coaId: addLifting.coaId, liftingId: addLifting.liftingId })),
                    catchError((error) => of(addLiftingFailAction({ coaId: addLifting.coaId, error })))
                );
            })
        )
    );

export const showNewLiftingEffect$ = (actions$: Actions, router: Router) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(addLiftingSuccessAction),
                tap(({ coaId, liftingId }) => navigateToLifting(router, coaId, liftingId))
            ),
        { dispatch: false }
    );
