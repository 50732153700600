import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FileSystemFileEntry, NgxFileDropEntry } from "ngx-file-drop";

import { DocumentDataService } from "./services/document-data.service";
import { DocumentHelperService } from "./services/document-helper.service";
import { DocumentView } from "./shared/document-view.model";
import { AppConfigService, AuthService } from "../../core";
import { formatBytes } from "../../shared/utils";
import { LeftBarStateService } from "../left-bar-state.service";

@Component({
    selector: "ops-documents",
    templateUrl: "./documents.component.html",
    styleUrls: ["./documents.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsComponent implements OnInit {
    static componentName = "DocumentsComponent";

    readonly isLoading$ = this.documentDataService.isLoading$;
    readonly loadingFailed$ = this.documentDataService.loadingFailed$;

    maxFileSize: number;
    documents: DocumentView[];
    droppedFiles: NgxFileDropEntry[] = [];
    isFileOver = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private authService: AuthService,
        private documentHelperService: DocumentHelperService,
        private documentDataService: DocumentDataService,
        private appConfigService: AppConfigService,
        private leftBarStateService: LeftBarStateService
    ) {
        this.maxFileSize = appConfigService.config.documentUploadMaxFileSize;
    }

    ngOnInit() {
        this.getUploadedDocuments();
    }

    fileDropped(files: NgxFileDropEntry[]) {
        this.isFileOver = false;
        this.droppedFiles = files;

        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile && droppedFile.fileEntry.name) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file) => {
                    this.uploadDocument(file);
                });
            }
        }
    }

    onFileChange(event: Event) {
        const target = <HTMLInputElement>event.target;
        if (target.files.length > 0) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < target.files.length; i++) {
                this.uploadDocument(target.files[i]);
            }
            target.value = "";
        }
    }

    fileOver() {
        this.isFileOver = true;
    }

    fileLeave() {
        this.isFileOver = false;
    }

    removeDocumentFromList(documentId: string) {
        const index = this.documents.findIndex((d) => d.documentId === documentId);
        if (index > -1) {
            this.documents.splice(index, 1);
        }
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }

    private getUploadedDocuments() {
        this.documentDataService.documents$.subscribe((documents) => {
            this.documents = documents.sort((a, b) => a.file.name.localeCompare(b.file.name));
        });
    }

    private uploadDocument(document: File) {
        const filenameParts = this.documentHelperService.splitFileName(document.name);
        const name = filenameParts ? filenameParts[0] : "";
        const extension = filenameParts ? filenameParts[1] : "";

        const tempId = Math.max(0, ...this.documents.map((d) => +d.documentId).filter((id) => !isNaN(id))) + 1;
        const newDoc = DocumentView.createForUpload(tempId.toString(), name, extension, document, this.authService.user);

        this.changeDetectorRef.markForCheck();

        if (!filenameParts) {
            newDoc.error = "Invalid file name";
            this.documents.unshift(newDoc);
        } else if (!this.documentHelperService.isSupportedExtension(extension)) {
            newDoc.error = `This file format '${extension}' is not accepted`;
            this.documents.unshift(newDoc);
        } else if (document.size > this.maxFileSize) {
            newDoc.error = `The maximum size allowed is ${formatBytes(this.maxFileSize)}`;
            this.documents.unshift(newDoc);
        } else {
            this.documents.unshift(newDoc);
            const uploadSub = this.documentDataService.uploadDocument(document).subscribe(
                (res) => {
                    switch (res.status) {
                        case "progress":
                            newDoc.loadPercentage$.next(res.message);
                            break;
                        case "complete":
                            newDoc.documentId = res.message;
                            newDoc.loadPercentage$.next(null);
                            uploadSub.unsubscribe();
                            break;
                        default:
                            break;
                    }
                },
                () => {
                    newDoc.error = "Server error";
                    newDoc.loadPercentage$.next(null);
                }
            );
        }
    }
}
