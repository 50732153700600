export class Email {
    private separator: string;

    constructor(readonly subject: string, readonly body: string, readonly bcc: string, readonly to: string) {}

    get mailTo() {
        let mailTo = "mailto:";
        this.separator = "?";

        if (this.to) {
            mailTo = mailTo + this.to;
        }

        if (this.bcc) {
            mailTo = this.addHeader(mailTo, "bcc", this.bcc);
        }

        if (this.subject) {
            mailTo = this.addHeader(mailTo, "subject", encodeURIComponent(this.subject));
        }

        if (this.body) {
            mailTo = this.addHeader(mailTo, "body", encodeURIComponent(this.body));
        }

        return mailTo;
    }

    private addHeader = (mailTo: string, headerName: string, headerValue: string) => {
        mailTo += `${this.separator}${headerName}=${headerValue}`;
        this.separator = "&";
        return mailTo;
    };
}
