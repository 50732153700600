import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { FixtureFeatureState } from "../state/model";
import { selectCurrentFixtureVoyageNumbers, VoyageNumber } from "../state/voyage";

export { VoyageNumber };

@Injectable({
    providedIn: "root"
})
export class VoyageNumberService {
    /**
     * @deprecated
     * Use this.store.select(selectCurrentFixtureVoyageNumbers) directly.
     */
    readonly voyageNumbers$: Observable<ReadonlyArray<VoyageNumber>>;

    constructor(private store: Store<FixtureFeatureState>) {
        this.voyageNumbers$ = this.store.select(selectCurrentFixtureVoyageNumbers);
    }
}
