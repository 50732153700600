import * as R from "ramda";

import { Company } from "./model";

export const nameOrTbn = R.propOr("TBN", "name");
export const isInRole = R.propEq("role");
export const isOwner = isInRole("Owner");
export const isCharterer = isInRole("Charterer");
export const isOwnerOrCharterer = R.either(isOwner, isCharterer);

export const getCompanyNameOrTbn = (pred: (c: Company) => boolean) =>
    R.pipe<{ contacts: ReadonlyArray<Company> }, ReadonlyArray<Company>, Company, string>(R.prop("contacts"), R.find(pred), nameOrTbn);

export const getOwnerNameOrTbn = getCompanyNameOrTbn(isOwner);
export const getChartererNameOrTbn = getCompanyNameOrTbn(isCharterer);

export const getFlagImageUrl = (cdnUrl: string, countryUnCode: string) => (countryUnCode ? `${cdnUrl}/Data/PublicArtificats/flags/ALPHA2/32/${countryUnCode}.png` : null);

export const defineProperty = (obj: unknown, name: string, value: string) => {
    Object.defineProperty(obj, name, { value, enumerable: true });
};
