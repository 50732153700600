import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from "@angular/core";
import { ActionsSubject } from "@ngrx/store";
import { DateTime, Duration } from "luxon";
import { FocusAction, NgrxValueConverter, UnfocusAction } from "ngrx-forms";

import { sumDuration, zeroDuration } from "@ops/shared";

import { IntervalPart } from "../../../../fixture/state";
import { RowInsertPosition, TableInsertOverlayComponent } from "../../../../shared/form-grid";
import { dateTimeFromISO, stringifyDuration } from "../../../../shared/utils";
import { ActivityCargo, LaytimeCalculationDurationUnit, LaytimeEventId, laytimeEventRemarks, LaytimeEventState, laytimeEventTypes } from "../../../state";

@Component({
    selector: "ops-laytime-calculation-laytime-events",
    templateUrl: "./laytime-calculation-laytime-events.component.html",
    styleUrls: ["./laytime-calculation-laytime-events.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationLaytimeEventsComponent {
    readonly laytimeEventTypes = laytimeEventTypes;
    readonly laytimeEventRemarks = laytimeEventRemarks;

    dateValueConverter: NgrxValueConverter<DateTime | Date | null, string | null> = {
        convertViewToStateValue: (date) => (date instanceof Date ? DateTime.fromJSDate(date, { zone: this.timezone }).toISO({ includeOffset: false }) : null),
        convertStateToViewValue: (isoDateTime) => dateTimeFromISO(isoDateTime, this.timezone)
    };

    @Input() cargoes: ReadonlyArray<ActivityCargo>;
    @Input() laytimeEvents: ReadonlyArray<LaytimeEventState>;
    @Input() firstLaytimeEventIndex: number;
    @Input() lastLaytimeEventIndex: number;
    @Input() laytimeEventsOrderingEnabled: boolean;
    @Input() durationUnit: LaytimeCalculationDurationUnit;
    @Input() isVoyageLoading: boolean;
    @Input() timezone: string;

    @Output() readonly order = new EventEmitter();
    @Output() readonly addLaytimeEvent = new EventEmitter<number>();
    @Output() readonly addDeduction = new EventEmitter<{ index: number; date: string }>();
    @Output() readonly remove = new EventEmitter<LaytimeEventId>();
    @Output() readonly clear = new EventEmitter();
    @Output() readonly importPortTimes = new EventEmitter();
    @Output() readonly enterDeductions = new EventEmitter();

    @ViewChild("buttonsBlock", { static: false }) buttonsBlock: ElementRef<HTMLElement>;
    @ViewChild("overlayComponent", { static: false }) overlayComponent: TableInsertOverlayComponent;

    constructor(@Inject(ActionsSubject) private actionsSubject: ActionsSubject | null) {}

    get gridClasses() {
        return `ops-form-grid ops-ltc-form-grid ${this.cargoes ? "laytime-events-with-cargo" : "laytime-events-without-cargo"}`;
    }

    get timeCounted() {
        const durationArray = this.laytimeEvents.map((laytimeEvent) => laytimeEvent.laytimeUsed);
        return this.calculateSum(durationArray);
    }

    get allTime() {
        const durationArray = this.laytimeEvents.map((laytimeEvent) => laytimeEvent.laytimeAllowed);
        return this.calculateSum(durationArray);
    }

    get deductions() {
        return this.allTime.minus(this.timeCounted);
    }

    calculateSum(durationArray: ReadonlyArray<Duration>) {
        const firstEventIdx = this.laytimeEvents.findIndex((laytimeEvent) => laytimeEvent.intervalPart === IntervalPart.Start);
        let lastEventIdx = this.laytimeEvents.findIndex((laytimeEvent) => laytimeEvent.intervalPart === IntervalPart.Stop);
        lastEventIdx = lastEventIdx !== -1 ? lastEventIdx : firstEventIdx;
        return durationArray.filter((item, i) => item && i >= firstEventIdx && i <= lastEventIdx).reduce(sumDuration, zeroDuration());
    }

    getStartStopClass(intervalPart: IntervalPart) {
        const isStart = intervalPart === IntervalPart.Start;
        const isStop = intervalPart === IntervalPart.Stop;
        const isImplicitStop = intervalPart === IntervalPart.ImplicitStop;
        const isDuring = intervalPart === IntervalPart.During;
        return {
            "icon--play-outline": isStart,
            "icon--stop-outline": isImplicitStop || isStop,
            "laytime-in-progress": isDuring,
            "laytime-pause": isImplicitStop,
            "laytime-start": isStart,
            "laytime-stop": isStop
        };
    }

    trackBy(laytimeEvent: LaytimeEventState) {
        return laytimeEvent.id;
    }

    onEventDateFocus(controlId: string, elementName: string) {
        if (!elementName) {
            this.actionsSubject.next(new FocusAction(controlId));
        }
    }

    onEventDateBlur(controlId: string, elementName: string) {
        if (!elementName) {
            this.actionsSubject.next(new UnfocusAction(controlId));
        }
    }

    insertLaytimeEvent() {
        const index = this.findLaytimeEventIndex();
        this.addLaytimeEvent.emit(index);
    }

    insertDeduction() {
        const index = this.findLaytimeEventIndex();
        const eventIndexForDate = index < this.laytimeEvents.length ? index : index - 1;
        const date = this.laytimeEvents[eventIndexForDate].form.value.date;
        this.addDeduction.emit({ index, date });
    }

    getTimeConventionChangeTooltip(offsetDiffWithPrevEvent: Duration) {
        if (offsetDiffWithPrevEvent && !offsetDiffWithPrevEvent.equals(zeroDuration())) {
            const isPositive = offsetDiffWithPrevEvent > zeroDuration();

            return `Clocks ${isPositive ? "go forward" : "went back"} ${stringifyDuration(offsetDiffWithPrevEvent)}`;
        }

        return null;
    }

    getDemurrageEventTooltip(isDemurrageEvent?: boolean) {
        return isDemurrageEvent ? "Vessel On Demurrage" : null;
    }

    private findLaytimeEventIndex() {
        const selectedKey = this.buttonsBlock.nativeElement.getAttribute("selectedKey");
        const selectedInsertPosition = Number(this.buttonsBlock.nativeElement.getAttribute("selectedInsertPosition"));
        const index = this.laytimeEvents.findIndex((e) => e.id === selectedKey);
        return selectedInsertPosition === RowInsertPosition.TOP ? index : index + 1;
    }
}
