<div class="company-container row">
    <div class="col-1 pl-0">
        <button
            (click)="togglePeople()"
            type="button"
            class="has-icon"
            [ngClass]="{ 'icon--arrow-drop-right': collapsed, 'icon--arrow-drop-down': !collapsed }"
            data-test-togglepeople
        ></button>
    </div>
    <div class="col-10 pl-0">
        <div class="company-header" (click)="togglePeople()">
            <span class="tag" *ngIf="company.isCreator">CREATOR</span>
            <span class="company-title" data-test-company-name>{{ company.name }}</span
            >&nbsp;
            <span *ngIf="fixtureSource === FixtureSource.Gain" class="company-people-count" data-test-company-people-count>
                ({{ company.people ? company.people.length : 0 }})</span
            >
        </div>
        <div class="company-subtitle" (click)="togglePeople()" data-test-company-role-in-fixture>{{ company.typeName }}</div>
    </div>
    <div class="delete-column col-1 pr-0 pl-0">
        <button
            *ngIf="!(company.imported || company.isCreator)"
            (click)="removeCompany(company)"
            type="button"
            class="btn-delete has-icon icon--delete float-right"
            data-test-removecompany
        ></button>
    </div>
</div>

<div [hidden]="collapsed" *ngFor="let person of company.people">
    <ops-person [person]="person" [companyId]="company.id"></ops-person>
</div>

<div [hidden]="collapsed" *ngIf="fixtureSource !== FixtureSource.Gain">
    <ops-sharing-groups [company]="company"></ops-sharing-groups>
</div>
