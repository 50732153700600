import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgrxFormsModule } from "ngrx-forms";
import { NgxFileDropModule } from "ngx-file-drop";

import { SharedModule } from "@ops/shared";

import { FixtureWarningsModule } from "../fixture/shared/warnings/fixture-warnings.module";
import { LtcModule } from "../ltc/ltc.module";
import { ActionsModule } from "./actions/actions.module";
import { CoaContactsShellComponent } from "./coa-contacts/coa-contacts-shell.component";
import { CoaCompanySearchComponent } from "./coa-contacts/company-search/company-search.component";
import { CoaContactComponent } from "./coa-contacts/contact/contact.component";
import { CoaContactsFormComponent } from "./coa-contacts/contacts-form/contacts-form.component";
import { CoaContactsListComponent } from "./coa-contacts/contacts-list/contacts-list.component";
import { LiftingContactsShellComponent } from "./coa-contacts/lifting-contacts/lifting-contacts-shell.component";
import { CoaComponent } from "./coa/coa.component";
import { CompanySearchComponent } from "./contacts/company-search/company-search.component";
import { ContactComponent } from "./contacts/contact/contact.component";
import { ContactsListComponent } from "./contacts/contacts-list/contacts-list.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { ExternalCompanyAutosuggestComponent } from "./contacts/external-company-autosuggest/external-company-autosuggest.component";
import { PersonAutosuggestComponent } from "./contacts/person-autosuggest/person-autosuggest.component";
import { PersonSearchComponent } from "./contacts/person-search/person-search.component";
import { PersonComponent } from "./contacts/person/person.component";
import { SharingGroupsComponent } from "./contacts/sharing-groups/sharing-groups.component";
import { CreateFixtureComponent } from "./create-fixture/create-fixture.component";
import { DocumentComponent } from "./documents/document/document.component";
import { DocumentsComponent } from "./documents/documents.component";
import { FavoriteColorPickerComponent } from "./favorites/favorite-color-picker/favorite-color-picker.component";
import { FavoriteListComponent } from "./favorites/favorite-list/favorite-list.component";
import { FavoriteToolbarComponent } from "./favorites/favorite-toolbar/favorite-toolbar.component";
import { FavoritesComponent } from "./favorites/favorites.component";
import { LeftBarRoutingModule } from "./left-bar-routing.module";
import { LeftToolbarComponent } from "./left-toolbar/left-toolbar.component";
import { LiftingHistoryEventComponent } from "./lifting-history/lifting-history-event.component";
import { LiftingHistoryEventsComponent } from "./lifting-history/lifting-history-events.component";
import { LiftingHistoryExpandableTextComponent } from "./lifting-history/lifting-history-expandable-text.component";
import { LiftingHistoryFilterComponent } from "./lifting-history/lifting-history-filter.component";
import { LiftingHistoryShellComponent } from "./lifting-history/lifting-history-shell.component";
import { EditEngineConsumptionComponent } from "./noon-reports/edit-engine-consumption/edit-engine-consumption.component";
import { EditNoonReportComponent } from "./noon-reports/edit-noon-report/edit-noon-report.component";
import { EngineConsumptionComponent } from "./noon-reports/engine-consumption/engine-consumption.component";
import { NoonReportComponent } from "./noon-reports/noon-report/noon-report.component";
import { NoonReportsComponent } from "./noon-reports/noon-reports.component";
import { CompanyAutosuggestComponent } from "./shared/components/company-autosuggest/company-autosuggest.component";
import { FixtureStatusService } from "./shared/fixture-status.service";
import { TemperatureLogComponent } from "./temperature-log/temperature-log.component";
import { TcVoyageComponent } from "./voyages/tc-voyage/tc-voyage.component";
import { TcVoyagesComponent } from "./voyages/tc-voyages.component";
import { FixtureWarningsComponent } from "./warnings/fixture-warnings.component";
import { FixtureWarningComponent } from "./warnings/warning/fixture-warning.component";
import { WorksheetsModule } from "./worksheets";
import { WorksheetRenameFormComponent } from "./worksheets/worksheet-rename-form.component";
import { WorksheetsShellComponent } from "./worksheets/worksheets-shell.component";
import { WorksheetsVersionSwitcherComponent } from "./worksheets/worksheets-version-switcher.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        LeftBarRoutingModule,
        ReactiveFormsModule,
        FixtureWarningsModule,
        WorksheetsModule,
        NgxFileDropModule,
        ActionsModule,
        LtcModule,
        NgrxFormsModule
    ],
    declarations: [
        FavoritesComponent,
        FavoriteListComponent,
        FavoriteToolbarComponent,
        LeftToolbarComponent,
        FavoriteColorPickerComponent,
        CoaComponent,
        CoaContactsShellComponent,
        CoaContactsFormComponent,
        CoaCompanySearchComponent,
        CoaContactsListComponent,
        CoaContactComponent,
        LiftingContactsShellComponent,
        LiftingHistoryEventComponent,
        LiftingHistoryEventsComponent,
        LiftingHistoryExpandableTextComponent,
        LiftingHistoryFilterComponent,
        LiftingHistoryShellComponent,
        ContactsComponent,
        ContactsListComponent,
        ContactComponent,
        CompanySearchComponent,
        CompanyAutosuggestComponent,
        PersonComponent,
        PersonSearchComponent,
        PersonAutosuggestComponent,
        CreateFixtureComponent,
        ExternalCompanyAutosuggestComponent,
        SharingGroupsComponent,
        TcVoyagesComponent,
        TcVoyageComponent,
        DocumentsComponent,
        DocumentComponent,
        FixtureWarningsComponent,
        FixtureWarningComponent,
        NoonReportsComponent,
        EditNoonReportComponent,
        NoonReportComponent,
        EditEngineConsumptionComponent,
        EngineConsumptionComponent,
        TemperatureLogComponent,
        WorksheetsShellComponent,
        WorksheetRenameFormComponent,
        WorksheetsVersionSwitcherComponent
    ],
    exports: [LeftToolbarComponent],
    providers: [FixtureStatusService]
})
export class LeftBarModule {}
