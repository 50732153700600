import { Injectable } from "@angular/core";
import { LaytimeCalculationUnitEnum } from "@ops/shared/reference-data";
import { LaytimeRounding } from "src/app/shared/reference-data/laytime-rounding";

@Injectable({
    providedIn: "root"
})
export class LaytimeRoundingService {
    round(usedTimeInHours: number, ltcRounding: LaytimeRounding, laytimeUnit: LaytimeCalculationUnitEnum) {
        const hours = 24;
        
        if (ltcRounding && usedTimeInHours) {
            let rounded = 0;
            let timeUsed = usedTimeInHours;
            if (laytimeUnit && laytimeUnit.id === LaytimeCalculationUnitEnum.Days.id) {
                timeUsed = usedTimeInHours / hours;
            }

            if (ltcRounding?.id === LaytimeRounding.Up.id) {
                rounded = Math.ceil(timeUsed);
            } else if (ltcRounding?.id === LaytimeRounding.Down.id) {
                rounded = Math.floor(timeUsed);
            } else {
                rounded = timeUsed;
            }

            if (laytimeUnit && laytimeUnit.id === LaytimeCalculationUnitEnum.Days.id) {
                rounded = rounded * hours;
            }

            return rounded;
        }

        return usedTimeInHours;
    }
   
}
