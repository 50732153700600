import { createSelector } from "@ngrx/store";
import { DateTime, Duration } from "luxon";
import { FormArrayState, FormGroupState } from "ngrx-forms";

import { Deduction, LaytimeEventForm } from "../../../model";
import { selectCurrentLaytimeCalculationState } from "../../selectors";

export const selectCurrentDeductions = createSelector(
    selectCurrentLaytimeCalculationState,
    (state) => (state?.deductionForms && toDeductionStates(state.deductionForms, state.laytimeEventForms)) || []
);

export type DeductionState = Readonly<{
    form: FormGroupState<Deduction>;
    beforeFirstLaytimeEvent: boolean;
    afterLastLaytimeEvent: boolean;
    overridesLaytimeEvent: boolean;
    duration: Duration;
}>;

export const toDeductionStates = (deductionForms: FormArrayState<Deduction>, laytimeEvents: FormArrayState<LaytimeEventForm>) =>
    deductionForms?.controls.map((form: FormGroupState<Deduction>) => {
        const hasLaytimeEventsWithDate = !!laytimeEvents?.controls.find((lte) => lte.value.date);
        return <DeductionState>{
            form,
            beforeFirstLaytimeEvent:
                hasLaytimeEventsWithDate && laytimeEvents?.controls.every((lte) => !lte.value.date || DateTime.fromISO(form.value.timeFrom) < DateTime.fromISO(lte.value.date)),
            afterLastLaytimeEvent:
                hasLaytimeEventsWithDate && laytimeEvents?.controls.every((lte) => !lte.value.date || DateTime.fromISO(form.value.timeTo) > DateTime.fromISO(lte.value.date)),
            overridesLaytimeEvent: willOverrideLaytimeEvent(form.value, laytimeEvents),
            duration: form.value.timeFrom && form.value.timeTo && DateTime.fromISO(form.value.timeTo).diff(DateTime.fromISO(form.value.timeFrom))
        };
    });

const willOverrideLaytimeEvent = (deduction: Deduction, laytimeEvents: FormArrayState<LaytimeEventForm>) => {
    for (let index = 1; index < laytimeEvents.controls.length; index++) {
        const currentLte = laytimeEvents.controls[index];
        const previousLte = laytimeEvents.controls[index - 1];
        if (
            currentLte.value.date &&
            previousLte.value.date &&
            DateTime.fromISO(deduction.timeFrom) <= DateTime.fromISO(previousLte.value.date) &&
            DateTime.fromISO(deduction.timeTo) > DateTime.fromISO(currentLte.value.date)
        ) {
            return true;
        }
    }
    return false;
};
