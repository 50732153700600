import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "ops-fixture-popup-header",
    templateUrl: "./fixture-popup-header.component.html",
    styleUrls: ["./fixture-popup-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupHeaderComponent {
    @Input() header: string;
    @Input() isReadOnly: boolean;
    @Input() lockedBy: string;

    @Output() cancel = new EventEmitter();
}
