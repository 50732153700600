import { Injectable } from "@angular/core";
import { DateTime, Interval } from "luxon";

import { isNumber } from "@ops/shared";
import { CargoBerthActivityType, LaytimeEventType } from "@ops/shared/reference-data";

import { dateTimeFromISO } from "../../shared";
import { isNullOrUndefined } from "../../shared";
import { DateRange } from "../../shared/date-utils/date-range";
import { DateUtilities, parseISODate } from "../../shared/date-utils/date-utilities";
import { BunkerRemainingOnboard, Destination, HireRateUnit, Period, QuantityUnit, Voyage } from "../shared/models";
import { getLaytimeEventDate } from "../state/model";
import { FuelCost, HireCost, OffHireCost, PortCost, VoyageCost } from "./voyage-cost";

interface HirePeriod {
    from: Date;
    to: Date;
    hireRate: number;
}

interface OffHirePeriod {
    interval: Interval;
    hireRate: number;
}

export const calcVoyageCost = (voyage: Voyage, periods: Period[]): VoyageCost => {
    const hireCosts = getHireCosts(voyage, periods);
    const offHireCosts = getOffHireCosts(voyage, periods);
    const portCosts = getPortCosts(voyage);
    const fuelCosts = getFuelCosts(voyage);

    const overallVoyageCost =
        hireCosts.reduce((totalCost, current) => totalCost + current.days * current.rate, 0) -
        offHireCosts.reduce((totalCost, current) => totalCost + current.days * current.rate, 0) +
        portCosts.reduce((totalCost, current) => totalCost + current.cost, 0) +
        fuelCosts.reduce((totalCost, current) => totalCost + current.price * current.quantity, 0);

    const overallEffectiveFreight = calcEffectiveFreight(voyage, overallVoyageCost);

    return { overallVoyageCost, overallEffectiveFreight, portCosts, hireCosts, fuelCosts, offHireCosts };
};

const getEarliestArrivalDateTime = (destinations: Destination[]) => {
    const arrivalDates = destinations.map(({ arrivalDateTime, location }) => dateTimeFromISO(arrivalDateTime, location?.timeZone ?? "utc")).filter((date) => !!date) as DateTime[];

    return DateTime.min(...arrivalDates);
};

const getLatestSailedEventDateTime = (destinations: Destination[]) => {
    const sailedEventsDates = destinations
        .filter((destination) => !!destination.arrivalDateTime)
        .map((destination) => {
            const zone = destination.location?.timeZone;
            return destination.berths
                .flatMap((b) => b.cargoBerthActivities)
                .flatMap((ac) => ac.laytimeEvents)
                .filter((lE) => lE.type?.id === LaytimeEventType.Sailed.id && lE.eventDate)
                .map((lE) => dateTimeFromISO(lE.eventDate, zone ?? "utc"));
        })
        .flatMap((date) => date)
        .filter((date) => !!date) as DateTime[];

    return DateTime.max(...sailedEventsDates);
};

export const getOffHirePeriods = (periods: Period[]): OffHirePeriod[] =>
    periods
        .flatMap((p) => p.offHires)
        .map(({ hireRate, offHireDateRange }) => {
            const from = dateTimeFromISO(offHireDateRange?.from, "utc");
            const to = dateTimeFromISO(offHireDateRange?.to, "utc");
            const interval = from && to ? Interval.fromDateTimes(from, to) : Interval.invalid("'From' or 'To' date is invalid");
            return { hireRate, interval };
        })
        .filter(({ interval }) => interval.isValid);

export const getVoyageInterval = (destinations: Destination[]) => {
    const start = getEarliestArrivalDateTime(destinations);
    const end = getLatestSailedEventDateTime(destinations);
    return start && end ? Interval.fromDateTimes(start, end) : null;
};

export const getOffHireCosts = (voyage: Voyage, periods: Period[]): OffHireCost[] => {
    const offHirePeriods = getOffHirePeriods(periods);
    const voyageInterval = getVoyageInterval(voyage.destinations);
    if (voyageInterval) {
        const offHireCosts = offHirePeriods.map(({ hireRate, interval }) => {
            const days = voyageInterval.intersection(interval)?.toDuration().shiftTo("days").days ?? 0;
            return { days, rate: hireRate };
        });
        return offHireCosts.filter((x) => !!x.days && !!x.rate);
    }

    return [];
};

export const calcEffectiveFreight = (voyage: Voyage, overallVoyageCost: number) => {
    const blQantities = (voyage.destinations || [])
        .map((x) => x.berths || [])
        .reduce((a, b) => a.concat(b), [])
        .map((x) => x.cargoBerthActivities || [])
        .reduce((a, b) => a.concat(b), [])
        .filter((x) => x.type && x.type.id === CargoBerthActivityType.Load.id)
        .map((x) => x.associatedCargoes || [])
        .reduce((a, b) => a.concat(b), [])
        .filter((x) => x.quantityUnit)
        .map((x) => (x.quantityUnit?.id === QuantityUnit.MT ? x.quantity : x.mt));

    if (blQantities.every((x) => !isNumber(x))) {
        return null;
    }
    const totalBlQuantity = (blQantities.filter((x) => isNumber(x)) as number[]).reduce((total, current) => total + current, 0);
    return overallVoyageCost / totalBlQuantity;
};

export const getVoyagePeriods = (voyage: Voyage): DateRange[] => {
    const destinations = voyage.destinations.map((x) => ({ arrivalDateTime: x.arrivalDateTime, sailedDateTime: getLaytimeEventDate(x, LaytimeEventType.Sailed) }));
    const voyagePeriods: DateRange[] = [];

    for (let i = 0; i < destinations.length; i++) {
        const destination = destinations[i];
        if (destination.arrivalDateTime && destination.sailedDateTime) {
            voyagePeriods.push(new DateRange(parseISODate(destination.arrivalDateTime), parseISODate(destination.sailedDateTime)));
        }

        const nextDestination = destinations[i + 1];
        if (nextDestination && nextDestination.arrivalDateTime && destination.sailedDateTime) {
            voyagePeriods.push(new DateRange(parseISODate(destination.sailedDateTime), parseISODate(nextDestination.arrivalDateTime)));
        }
    }

    return voyagePeriods;
};

export const getHireCosts = (voyage: Voyage, periods: Period[]): HireCost[] => {
    if (!voyage.destinations || !voyage.destinations.length || !periods || !periods.length) {
        return [];
    }

    const voyagePeriods = getVoyagePeriods(voyage);
    const hirePeriods = normalizeHirePeriods(periods);
    const map = new Map<string, HireCost>();

    for (const voyagePeriod of voyagePeriods) {
        for (const hirePeriod of hirePeriods) {
            const days = calcHireDays(voyagePeriod, hirePeriod);
            if (!days) {
                continue;
            }

            const key = `${hirePeriod.from} - ${hirePeriod.to}`;
            if (map.has(key)) {
                map.get(key).days += days;
            } else {
                map.set(key, { rate: hirePeriod.hireRate, days });
            }
        }
    }

    return Array.from(map.values());
};

export const calcHireDays = (voyagePeriod: DateRange, hirePeriod: HirePeriod) => {
    if (voyagePeriod.from >= hirePeriod.to || voyagePeriod.to <= hirePeriod.from) {
        return 0;
    }

    const from = voyagePeriod.from >= hirePeriod.from ? voyagePeriod.from : hirePeriod.from;
    const to = voyagePeriod.to <= hirePeriod.to ? voyagePeriod.to : hirePeriod.to;

    return DateUtilities.calculateDurationInDays(to, from);
};

export const normalizeHirePeriods = (periods: Period[]) => {
    const periodComparer = (c1: Period, c2: Period) => parseISODate(c1.periodRange.from).getTime() - parseISODate(c2.periodRange.from).getTime();
    const orderedPeriods = (periods || [])
        .filter((x) => x.periodRange && x.periodRange.from && x.periodRange.to && x.periodRange.from < x.periodRange.to)
        .filter((x) => isNumber(x.hireRate) && x.hireRateUnit && x.durationInDays)
        .sort(periodComparer);

    const hirePeriods: HirePeriod[] = [];
    orderedPeriods.forEach((period) => {
        const from = parseISODate(period.periodRange.from);
        const to = parseISODate(period.periodRange.to);

        const rate = period.hireRateUnit.id === HireRateUnit.LumpSum ? period.hireRate / period.durationInDays : period.hireRate;
        const periodsToAdd: HirePeriod[] = [];

        for (const hirePeriod of hirePeriods) {
            if (!hirePeriod.hireRate) {
                continue;
            }

            if (from <= hirePeriod.from && to >= hirePeriod.to) {
                hirePeriod.hireRate = 0;
            } else if (from > hirePeriod.from && to < hirePeriod.to) {
                periodsToAdd.push({
                    from: to,
                    to: hirePeriod.to,
                    hireRate: hirePeriod.hireRate
                });
                hirePeriod.to = from;
            } else if (from > hirePeriod.from && from < hirePeriod.to) {
                hirePeriod.to = from;
            } else if (to > hirePeriod.from && to < hirePeriod.to) {
                hirePeriod.from = to;
            }
        }

        hirePeriods.push(
            {
                from,
                to,
                hireRate: rate
            },
            ...periodsToAdd
        );
    });

    const hirePeriodComparer = (c1: HirePeriod, c2: HirePeriod) => c1.from.getTime() - c2.from.getTime();
    return hirePeriods.filter((x) => x.hireRate).sort(hirePeriodComparer);
};

export function getPortCosts(voyage: Voyage): PortCost[] {
    return (voyage.destinations || [])
        .filter((x) => isNumber(x.portCosts))
        .map((x) => ({
            port: x.location ? x.location.displayName : "",
            cost: x.portCosts,
            activities: (x.berths || [])
                .map((c) => c.cargoBerthActivities || [])
                .reduce((a, b) => a.concat(b), [])
                .filter((c) => c.type)
                .map((c) => c.type.name)
        }));
}

export function getFuelCosts(voyage: Voyage): FuelCost[] {
    const fuelCosts = [...getAtSeaFuelCosts(voyage), ...getAtPortFuelCosts(voyage)].reduce((map, item) => {
        const key = `${item.bunkerType} - ${item.price}`;
        if (map.has(key)) {
            map.get(key).quantity += item.quantity;
        } else {
            map.set(key, { ...item });
        }
        return map;
    }, new Map<string, FuelCost>());

    return Array.from(fuelCosts.values());
}

export function getAtSeaFuelCosts(voyage: Voyage): FuelCost[] {
    return (voyage.atSeaBunkersConsumption || [])
        .map((x) => x.bunkersConsumed || [])
        .reduce((a, b) => a.concat(b), [])
        .filter((x) => isNumber(x.quantityMt) && isNumber(x.pricePerMt))
        .map((x) => ({ price: x.pricePerMt, quantity: x.quantityMt, bunkerType: x.type ? x.type.name : "" }));
}

export function getAtPortFuelCosts(voyage: Voyage): FuelCost[] {
    return (voyage.destinations || [])
        .map((x) => x.berths || [])
        .reduce((a, b) => a.concat(b), [])
        .map((x) => x.cargoBerthActivities || [])
        .reduce((a, b) => a.concat(b), [])
        .map((x) => x.bunkersRemainingOnboard || [])
        .reduce((a, b) => a.concat(b), [])
        .filter((x) => isNumber(x.quantityAtArrival) && isNumber(x.quantityAtSailing) && isNumber(x.pricePerMt) && !isBunkered(x))
        .map((x) => ({ price: x.pricePerMt, quantity: x.quantityAtArrival - x.quantityAtSailing, bunkerType: x.type ? x.type.name : "" }));
}

export const isBunkered = (bunker: BunkerRemainingOnboard) =>
    bunker.isBunkered === true || (isNullOrUndefined(bunker.isBunkered) && !bunker.quantityAtArrival && bunker.quantityAtSailing > 0);

@Injectable({
    providedIn: "root"
})
export class VoyageCostCalculator {
    calcVoyageCost = calcVoyageCost;
    calcEffectiveFreight = calcEffectiveFreight;
    getVoyagePeriods = getVoyagePeriods;
    getHireCosts = getHireCosts;
    calcHireDays = calcHireDays;
    normalizeHirePeriods = normalizeHirePeriods;
    getPortCosts = getPortCosts;
    getFuelCosts = getFuelCosts;
    getAtSeaFuelCosts = getAtSeaFuelCosts;
    getAtPortFuelCosts = getAtPortFuelCosts;
}
