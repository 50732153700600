import { isNullOrUndefined } from "../utils/check";

/**
 * @deprecated Use MaritimeDateRange
 */
export class DateRange {
    constructor(public from: Date, public to: Date) {}

    static equals(d1: DateRange, d2: DateRange): boolean {
        return (isNullOrUndefined(d1) && isNullOrUndefined(d2)) || (d1 && d1.equals(d2));
    }

    equals(other: DateRange): boolean {
        return other && this.dateEquals(this.from, other.from) && this.dateEquals(this.to, other.to);
    }

    private dateEquals(d1: Date, d2: Date): boolean {
        return (d1 && d2 && d1.getTime() === d2.getTime()) || (isNullOrUndefined(d1) && isNullOrUndefined(d2));
    }
}
