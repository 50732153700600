import { Interval } from "luxon";

import { ExclusionInterval, hasIntersection, intersectionDuration } from "../../../shared/utils/interval-utils";

/**
 * <b>Determines the percentage overlap of the duration that occurs for an interval within the given weekday period in the specified timezone.</b>
 * <br>
 * For example an interval of Mon 17 May 10:00 to Sat 22 May 12:00 with a weekday period of Friday 17:00 to Monday 10:00
 * will return 15.57377... as that is the amount of time in the given interval which is within the weekday period.
 *
 * @param interval  The interval to intersect.
 * @param startDayOfWeek The start day of week in the weekday period.
 * @param startTime The start time in the weekday period.
 * @param endDayOfWeek The end day of week in the weekday period.
 * @param endTime The end time in the weekday period.
 * @param zone The timezone to calculate the duration in.
 * @returns number The percentage of the interval duration elapsed within the weekday period.
 */
export const intersectionPercentage = (interval: Interval, exclusionInterval: ExclusionInterval): number => {
    if (!exclusionInterval.exclusionStartDay || !exclusionInterval.exclusionStartTime || !exclusionInterval.exclusionEndDay || !exclusionInterval.exclusionEndTime) {
        return 0;
    }

    const intersectionHours = intersectionDuration(interval, exclusionInterval).as("hours");

    const intervalHours = interval.toDuration().as("hours");
    // if intervalHours === 0, intersectionHours === 0 as well,
    // and (0 / 0) === NaN, which is unwished
    return intervalHours ? (intersectionHours / intervalHours) * 100 : hasIntersection(interval, exclusionInterval) ? 100 : 0;
};
