<div [formGroup]="laytimeCalculationForm" class="card">
    <div class="card-header pl-0 row no-gutters align-items-center">
        <div class="d-flex w-100">
            <button
                type="button"
                class="has-icon btn-inverted"
                [class.icon--arrow-drop-right]="isAccordionClosed"
                [class.icon--arrow-drop-down]="!isAccordionClosed"
                (click)="toggleAccordion()"
                data-test-toggle-location-button
            ></button>
            <div>{{locationName}}</div>
        </div>
    </div>

    <div class="card-body p-0" [hidden]="isAccordionClosed">
        <ops-statement-of-facts-container
            [parentForm]="laytimeCalculationForm"
            [destinationId]="destination.id"
            [workingDayType]="destination.workingDayType"
            [unlessUsed]="destination.unlessUsed"
            [excludingFromDay]= "destination.excludingFromDay"
            [excludingFromTime]="destination.excludingFromTime"
            [excludingToDay]= "destination.excludingToDay"
            [excludingToTime]="destination.excludingToTime"
            [destination] = "destination"
            [cargoes]="cargoes"
            [destinations]="destinations"
            [locationTimezone]="destination.location && destination.location.timeZone ? destination.location.timeZone : 'utc'"
            [defaultEventStartDate]="destination.arrivalDateTime"
            [fixture]= "fixture"
            [cargoAllowedRates]="cargoAllowedRates"
            [displayUnitType]="displayUnitType"
            (statementOfFactsUpdated)="updateStatementOfFacts($event)">
        </ops-statement-of-facts-container>
    </div>
</div>
