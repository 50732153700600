import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { LtcLoggingEffects } from "./ltc-logging.effects";
import { LtcNotificationEffects } from "./ltc-notification.effects";
import { LtcRoutingEffects } from "./ltc-routing.effects";
import { LtcEffects } from "./ltc.effects";
import { reducer } from "./ltc.reducer";
import { ltcFeatureKey } from "./ltc.selectors";

@NgModule({
    imports: [StoreModule.forFeature(ltcFeatureKey, reducer), EffectsModule.forFeature([LtcEffects, LtcLoggingEffects, LtcRoutingEffects, LtcNotificationEffects])]
})
export class LtcStoreModule {}
