import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { TimeBarType } from "../../../../shared/reference-data/timebar-type";
import { CommandHandler } from "../../../mediator/command-handlers/command-handler";
import { Fixture } from "../../../shared/models";
import { TimeBarsFormModel } from "../../../shared/models/form-models/timebars.model";
import { UpdateTimebarsCommand } from "./update-timebars.command";

@Injectable({
    providedIn: "root"
})
export class UpdateTimebarsCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateTimebarsCommand) {
        const timebarsForm = command.payload as TimeBarsFormModel;
        if (!timebarsForm) {
            throw new Error("Given timebars form model is invalid.");
        }

        let demurrageNotifyTimebarToUpdate = fixture.demurrage.timeBars.find((t) => t.type.id === TimeBarType.DemurrageNotify.id);
        const demurrageNotifyFromDate = DateTime.fromJSDate(timebarsForm.demurrageNotifyFromDate);
        let demurrageNotifyDateAdded: boolean;
        if (!demurrageNotifyTimebarToUpdate) {
            demurrageNotifyDateAdded = !!timebarsForm.demurrageNotifyFromDate;
            demurrageNotifyTimebarToUpdate = { type: TimeBarType.DemurrageNotify };
            fixture.demurrage.timeBars.push(demurrageNotifyTimebarToUpdate);
        } else {
            demurrageNotifyDateAdded = !!timebarsForm.demurrageNotifyFromDate && !demurrageNotifyTimebarToUpdate.fromDate;
        }

        let demurragePresentTimebarToUpdate = fixture.demurrage.timeBars.find((t) => t.type.id === TimeBarType.DemurragePresent.id);
        const demurragePresentFromDate = DateTime.fromJSDate(timebarsForm.demurragePresentFromDate);
        let demurragePresentDateAdded: boolean;
        if (!demurragePresentTimebarToUpdate) {
            demurragePresentDateAdded = !!timebarsForm.demurragePresentFromDate;
            demurragePresentTimebarToUpdate = { type: TimeBarType.DemurragePresent };
            fixture.demurrage.timeBars.push(demurragePresentTimebarToUpdate);
        } else {
            demurragePresentDateAdded = !!timebarsForm.demurragePresentFromDate && !demurragePresentTimebarToUpdate.fromDate;
        }

        let expenseNotifyTimebarToUpdate = fixture.demurrage.timeBars.find((t) => t.type.id === TimeBarType.ExpenseNotify.id);
        const expenseNotifyFromDate = DateTime.fromJSDate(timebarsForm.expenseNotifyFromDate);
        let expenseNotifyDateAdded: boolean;
        if (!expenseNotifyTimebarToUpdate) {
            expenseNotifyDateAdded = !!timebarsForm.expenseNotifyFromDate;
            expenseNotifyTimebarToUpdate = { type: TimeBarType.ExpenseNotify };
            fixture.demurrage.timeBars.push(expenseNotifyTimebarToUpdate);
        } else {
            expenseNotifyDateAdded = !!timebarsForm.expenseNotifyFromDate && !expenseNotifyTimebarToUpdate.fromDate;
        }

        let expensePresentTimebarToUpdate = fixture.demurrage.timeBars.find((t) => t.type.id === TimeBarType.ExpensePresent.id);
        const expensePresentFromDate = DateTime.fromJSDate(timebarsForm.expensePresentFromDate);
        let expensePresentDateAdded: boolean;
        if (!expensePresentTimebarToUpdate) {
            expensePresentDateAdded = !!timebarsForm.expensePresentFromDate;
            expensePresentTimebarToUpdate = { type: TimeBarType.ExpensePresent };
            fixture.demurrage.timeBars.push(expensePresentTimebarToUpdate);
        } else {
            expensePresentDateAdded = !!timebarsForm.expensePresentFromDate && !expensePresentTimebarToUpdate.fromDate;
        }

        demurrageNotifyTimebarToUpdate.numberOfDays = timebarsForm.demurrageNotifyDaysForExpiring;
        demurragePresentTimebarToUpdate.numberOfDays = timebarsForm.demurragePresentDaysForExpiring;
        expenseNotifyTimebarToUpdate.numberOfDays = timebarsForm.expenseNotifyDaysForExpiring;
        expensePresentTimebarToUpdate.numberOfDays = timebarsForm.expensePresentDaysForExpiring;

        let addedDate: string = null;
        if (demurrageNotifyDateAdded && !timebarsForm.demurragePresentFromDate && !timebarsForm.expenseNotifyFromDate && !timebarsForm.expensePresentFromDate) {
            addedDate = demurrageNotifyFromDate.toISO();
        } else if (
            demurragePresentDateAdded &&
            !timebarsForm.demurrageNotifyFromDate &&
            !timebarsForm.expenseNotifyFromDate &&
            !timebarsForm.expensePresentFromDate
        ) {
            addedDate = demurragePresentFromDate.toISO();
        } else if (
            expenseNotifyDateAdded &&
            !timebarsForm.demurragePresentFromDate &&
            !timebarsForm.demurrageNotifyFromDate &&
            !timebarsForm.expensePresentFromDate
        ) {
            addedDate = expenseNotifyFromDate.toISO();
        } else if (
            expensePresentDateAdded &&
            !timebarsForm.demurragePresentFromDate &&
            !timebarsForm.expenseNotifyFromDate &&
            !timebarsForm.demurrageNotifyFromDate
        ) {
            addedDate = expensePresentFromDate.toISO();
        }

        demurrageNotifyTimebarToUpdate.fromDate = addedDate || demurrageNotifyFromDate.toISO();
        demurragePresentTimebarToUpdate.fromDate = addedDate || demurragePresentFromDate.toISO();
        expenseNotifyTimebarToUpdate.fromDate = addedDate || expenseNotifyFromDate.toISO();
        expensePresentTimebarToUpdate.fromDate = addedDate || expensePresentFromDate.toISO();
    }
}
