import { createAction, on, On, props } from "@ngrx/store";

import { AuthState, User } from "../model";

/* ACTIONS */
export const interopUserAuthenticatedAction = createAction("[AuthService Interop] User Authenticated", props<{ user: User }>());

/* REDUCERS */
export const interopUserAuthenticatedReducer: On<AuthState> = on(interopUserAuthenticatedAction, (state, action) => ({
    ...state,
    currentUser: action.user,
    isAuthenticated: true
}));
