import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MaritimeDate } from "@maritech/maritime-date";
import { NgbCalendar, NgbDate, NgbDatepicker, NgbDatepickerNavigateEvent, NgbDateStruct, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { DateTime } from "luxon";

import { SimpleChanges } from "@ops/shared";

import { selectWord } from "../../directives/select-words/select-words.handler";
import { TimePickerComponent } from "../time/time-picker.component";

export interface NgbDateTimeStruct {
    date?: NgbDateStruct;
    time?: NgbTimeStruct;
}

export interface NgbDateRangeStruct {
    from: NgbDateTimeStruct;
    to: NgbDateTimeStruct;
}

const DATE_RANGE_PICKER_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
};

const RANGE_DEFAULT_DISPLAY_MONTHS = 2;

/**
 * Date picker component, designed to be used from the DateInput control.
 *
 * Note this does not use date adapter, it is not designed to be used alone.
 */
@Component({
    selector: "ops-date-picker",
    exportAs: "opsDatePicker",
    templateUrl: "./date-picker.component.html",
    styleUrls: ["./date-picker.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DATE_RANGE_PICKER_VALUE_ACCESSOR]
})
export class DatePickerComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
    private _disabled = false;
    private _timeZone: string;
    private _disposers: Array<() => void> = [];

    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    hoveredDate: NgbDateStruct;

    fromTime: NgbTimeStruct;
    toTime: NgbTimeStruct;

    fromDateFormatted: string;
    toDateFormatted: string;

    fromTimeSelection?: readonly number[];
    toTimeSelection?: readonly number[];

    defaultFocusDate: { year: number; month: number; day?: number };
    defaultDisplayMonths: number;
    defaultNavigation: "select" | "arrows" | "none";

    @Input() mode: "single" | "range" | "dynamic" = "range";
    @Input() displayMode: "single" | "range" = "range";

    /**
     * Time to use when parsing dates without a time specified. Only applicable when `mode='single' and enableTime=true`.
     */
    @Input() defaultTime?: NgbTimeStruct;

    @Input() dateFormat: string;
    @Input() displayMonths: number;
    @Input() firstDayOfWeek: number;
    @Input() maxDate: NgbDateStruct;
    @Input() minDate: NgbDateStruct;
    /**
     * Navigation type.
     *
     * - `'select'` - select boxes for month and navigation arrows
     * - `'arrows'` - only navigation arrows
     * - `'none'` - no navigation visible at all
     */
    @Input() navigation: "select" | "arrows" | "none";
    @Input() outsideDays: "visible" | "collapsed" | "hidden";
    @Input() showWeekdays: boolean;
    @Input() showWeekNumbers: boolean;
    @Input() startDate: { year: number; month: number; day?: number };
    @Output() navigate = new EventEmitter<NgbDatepickerNavigateEvent>();
    @Output() dateSelect = new EventEmitter<NgbDateRangeStruct>();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() close = new EventEmitter();

    @Input() useTimepicker = false;

    @Input()
    get timeZone() {
        return this._timeZone;
    }
    set timeZone(value: string) {
        this._timeZone = value === "browser" || value === "local" ? Intl.DateTimeFormat().resolvedOptions().timeZone : value;
    }

    @Input()
    get disabled() {
        return this._disabled;
    }
    set disabled(value: string | boolean) {
        this._disabled = value === "" || (value && value !== "false");
    }

    @ViewChild(NgbDatepicker, { static: true }) datepicker: NgbDatepicker;
    @ViewChild(TimePickerComponent, { static: false }) timepicker: TimePickerComponent;

    get isSingle() {
        return this.displayMode === "single";
    }

    get isRange() {
        return this.displayMode === "range";
    }

    get canToggleRange() {
        return this.mode === "dynamic";
    }

    get actualDisplayMonths() {
        return this.displayMonths ?? this.defaultDisplayMonths;
    }

    get actualNavigation() {
        return this.navigation ?? this.defaultNavigation;
    }

    constructor(private elRef: ElementRef, private renderer: Renderer2, private calendar: NgbCalendar, private changeDetectorRef: ChangeDetectorRef) {}

    private static constructDateTime(date?: NgbDateStruct, time?: NgbTimeStruct): NgbDateTimeStruct {
        return date || time ? { date, time } : null;
    }

    @HostListener("click", ["$event"])
    onClick(event: Event) {
        event.stopPropagation();
    }

    ngOnInit() {
        this.setDefaults();
        this.setFormattedDates();
        this.addHandlersForKeyboardEvents();
    }

    ngOnChanges(changes: SimpleChanges<DatePickerComponent>) {
        if (changes.startDate) {
            this.setDefaultFocusDate();
        }
        if (changes.mode) {
            this.setDefaults();
        }
    }

    ngOnDestroy() {
        this._disposers.forEach((dispose) => dispose());
        this._disposers.length = 0;
    }

    onDateSelectedOnCalendar(value: NgbDateStruct) {
        // Sometimes we get javascript events (on blur) which aren't dates that we don't care about
        if (value && isNaN(value.year)) {
            return;
        }

        const date = this.fromDateStruct(value);

        if (this.isSingle) {
            this.fromDate = date;
            this.toDate = date;

            if (this.useTimepicker) {
                this.toTime ??= (this.defaultTime as NgbTimeStruct) ?? { hour: 0, minute: 0, second: 0 };
                this.fromTime = this.toTime;

                setTimeout(() => this.timepicker.focusInput());
            }
        } else {
            if (!this.fromDate && !this.toDate) {
                this.fromDate = date;
            } else if (this.fromDate && !this.toDate && (date.equals(this.fromDate) || date.after(this.fromDate))) {
                this.toDate = date;

                if (this.useTimepicker) {
                    if (!this.toTime) {
                        this.toTime = { hour: 23, minute: 59, second: 0 };
                    }

                    setTimeout(() => this.timepicker.focusInput());
                }
            } else {
                this.toDate = null;
                this.fromDate = date;
            }

            if (!this.fromTime) {
                this.fromTime = this.useTimepicker ? { hour: 0, minute: 0, second: 0 } : null;
            }
        }

        this.onDateTimeChange();
    }

    onFromTimeChange(value: NgbTimeStruct) {
        if (value && isNaN(value.hour)) {
            return;
        }

        this.fromTime = value;

        if (this.isSingle) {
            this.toTime = value;
        }

        this.onDateTimeChange();
    }

    onToTimeChange(value: NgbTimeStruct) {
        if (value && isNaN(value.hour)) {
            return;
        }

        this.toTime = value;

        if (this.isSingle) {
            this.fromTime = value;
        }

        this.onDateTimeChange();
    }

    isBoundary(date: NgbDate) {
        return date.equals(this.fromDate) || date.equals(this.toDate);
    }

    get today(): NgbDate {
        const today = DateTime.local().setZone(this.timeZone);
        return new NgbDate(today.year, today.month, today.day);
    }

    focus() {
        this.datepicker.focus();
    }

    navigateTo(date?: { year: number; month: number; day?: number }) {
        this.datepicker.navigateTo(date);
    }

    clear() {
        this.writeValue(null);
    }

    writeValue(value: NgbDateRangeStruct) {
        if (value) {
            if (value.from) {
                this.fromDate = value.from.date;
                this.fromTime = value.from.time;
            } else {
                this.fromDate = null;
                this.fromTime = null;
            }

            if (value.to) {
                const toDate = this.fromDateStruct(value.to.date);

                if (toDate && (toDate.equals(this.fromDate) || toDate.after(this.fromDate))) {
                    this.toDate = toDate;
                } else {
                    this.toDate = null;
                }

                this.toTime = value.to.time;
            } else {
                this.toDate = null;
                this.toTime = null;
            }

            if (this.fromDate) {
                this.navigateTo(this.fromDate);
            }
        } else {
            this.fromDate = null;
            this.fromTime = null;
            this.toDate = null;
            this.toTime = null;
        }

        this.setFormattedDates();
        this.setDefaultFocusDate();

        this.changeDetectorRef.markForCheck();
    }

    registerOnChange(fn: (value: unknown) => unknown): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => unknown): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    processFromTimeInputClick(event: MouseEvent) {
        const target = event.target as HTMLInputElement;
        if (target.value && !this.fromTimeSelection) {
            this.fromTimeSelection = selectWord(target);
        }
    }

    processToTimeInputClick(event: MouseEvent) {
        const target = event.target as HTMLInputElement;
        if (target.value && !this.toTimeSelection) {
            this.toTimeSelection = selectWord(target);
        }
    }

    toggleRange() {
        if (this.displayMode === "single") {
            this.displayMode = "range";
            this.toDate = null;
            this.toTime = null;
        } else {
            this.displayMode = "single";
            this.toDate = this.fromDate;
            this.toTime = this.fromTime;
        }

        this.onDateTimeChange();
        this.setDefaults();
    }

    private setDefaults() {
        this.displayMode = this.mode === "dynamic" ? this.displayMode : this.mode;
        this.defaultDisplayMonths = this.displayMonths ?? (this.displayMode === "range" ? RANGE_DEFAULT_DISPLAY_MONTHS : 1);
        this.defaultNavigation = this.navigation ?? (this.displayMode === "range" ? "arrows" : "select");
    }

    private setDefaultFocusDate() {
        this.defaultFocusDate = this.fromDate ?? this.toDate ?? this.startDate;
    }

    private setFormattedDates() {
        this.fromDateFormatted = this.fromDate ? DateTime.fromObject(this.fromDate).toLocaleString(MaritimeDate.DATE_FORMAT) : "--";
        this.toDateFormatted = this.toDate ? DateTime.fromObject(this.toDate).toLocaleString(MaritimeDate.DATE_FORMAT) : this.fromDateFormatted || "--";
    }

    private onDateTimeChange() {
        this.setFormattedDates();

        const model = {
            from: DatePickerComponent.constructDateTime(this.fromDate, this.fromTime),
            to: DatePickerComponent.constructDateTime(this.toDate, this.toTime)
        };

        this.dateSelect.emit(model);
        this._onChange(model);
        this._onTouched();
    }

    private addHandlersForKeyboardEvents() {
        // Note we have to use keydown as the timepicker will receive focus before keyup is triggered by hitting enter
        // on a date in the calendar.
        this._disposers.push(
            this.renderer.listen(this.elRef.nativeElement, "keydown", (event: KeyboardEvent) => {
                // When enter is pressed inside the timepicker
                if (!this.useTimepicker || event.key !== "Enter") {
                    return;
                }

                // Did the event originate from within the timepicker?
                const timepickerElement = this.elRef.nativeElement;
                const target = <HTMLElement>event.target;

                // eslint-disable-next-line no-bitwise
                if (target.compareDocumentPosition(timepickerElement) & Node.DOCUMENT_POSITION_CONTAINS) {
                    // Find the apply button
                    const applyButton: HTMLElement = this.elRef.nativeElement.querySelector("button.apply-button");

                    // If the event target is not the apply button (i.e. it came from one of the time picker inputs)
                    // prevent default and focus the apply button.
                    if (target !== applyButton) {
                        event.preventDefault();
                        applyButton.focus();
                    }
                }
            })
        );
    }

    private fromDateStruct(date: NgbDateStruct): NgbDate {
        const ngbDate = date ? new NgbDate(date.year, date.month, date.day) : null;
        return this.calendar.isValid(ngbDate) ? ngbDate : null;
    }

    private _onChange = (_: unknown) => {};
    private _onTouched = () => {};
}
