import { DateTime } from "luxon";

import { isNumber } from "../../../shared/utils";
import { LaytimeEventFact } from "../models";
import { LaytimeEventFactModel } from "../models/form-models/laytime-event-fact.model";

export function mapToLaytimeEventFactModel(laytimeEvent: LaytimeEventFact): LaytimeEventFactModel {
    if (!laytimeEvent) {
        return null;
    }

    const fromDate = laytimeEvent.fromDate ? DateTime.fromISO(laytimeEvent.fromDate) : null;
    const toDate = laytimeEvent.toDate ? DateTime.fromISO(laytimeEvent.toDate) : null;

    const percentage = !isNumber(laytimeEvent.percentage) ? 1 : laytimeEvent.percentage / 100;
    const difference =
        fromDate &&
        toDate &&
        toDate
            .diff(fromDate)
            .mapUnits((x) => Math.round(x * percentage))
            .shiftTo("days", "hours", "minutes");

    return {
        laytimeEventId: laytimeEvent.id,
        type: laytimeEvent.type,
        comments: laytimeEvent.comments,
        percentage: laytimeEvent.percentage,
        fromDate: fromDate ? fromDate.toJSDate() : null,
        toDate: toDate ? toDate.toJSDate() : null,
        cargoId: laytimeEvent.cargoId,
        demurrageReason: laytimeEvent.demurrageReason,
        timePassedTotalHours: difference ? difference.as("hours") : 0,
        timePassedDays: difference ? difference.days : 0,
        timePassedHrs: difference ? difference.hours : 0,
        timePassedMins: difference ? Math.round(difference.minutes) : 0
    };
}

export function mapToLaytimeEventFactModels(laytimeEvents: LaytimeEventFact[]): LaytimeEventFactModel[] {
    if (!laytimeEvents) {
        return [];
    }

    return laytimeEvents.map((laytimeEvent) => mapToLaytimeEventFactModel(laytimeEvent));
}
