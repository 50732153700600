<div class="card-header pl-0 row no-gutters align-items-center">
    <button
        data-cy="toggle-location-button"
        type="button"
        class="has-icon btn-inverted"
        [class.icon--arrow-drop-right]="!expanded"
        [class.icon--arrow-drop-down]="expanded"
        (click)="toggle()"
        data-test-toggle-location-button
    ></button>

    <div
        [class.invalid]="showInvalid"
        class="validation-indicator has-icon icon--warning"
        container="body"
        ngbTooltip="There are validation errors on this location"
        data-test-location-warning
    ></div>

    <div class="summary-container">
        <div class="col-3 location-name-summary" data-test-locationnamesummary>{{ summary?.locationName }}</div>
        <div class="col-auto activities-summary" data-test-activitiessummary>{{ summary?.activitiesSummary }}</div>
        <div class="col-auto" *ngIf="!summary?.showTotalLaytime && summary?.dateRangeToDisplay" data-test-datesummary>
            {{ summary?.dateLabelToDisplay }}: {{ summary?.dateRangeToDisplay | timezoneDateRange: "DD MMM YY, HH:mm":(summary?.timeZone || "utc") }}
        </div>
        <div class="col-auto" *ngIf="summary?.showTotalBl" data-test-totalblsummary>Total B/L: {{ summary?.totalBl | number:"0.2-2" }} MT</div>
        <div class="col-auto" *ngIf="summary?.showTotalQty" data-test-totalblsummary>Total Qty: {{ summary?.totalQty | number:"0.2-2" }} MT</div>
        <div class="col-auto" *ngIf="summary?.showTotalLaytime" data-test-totallaytimesummary>Total Laytime: {{ summary?.totalLaytime.toFixed(2) }} hrs</div>
        <div class="col-auto" *ngIf="summary?.lastEvent" [attr.title]="summary?.lastEvent" data-test-lastevent>{{ summary?.lastEvent }}</div>
    </div>

    <div *ngIf="canOrder || canClone || canRemove" class="button-container">
        <div *ngIf="canOrder" class="btn-group" role="group">
            <button
                [disabled]="!moveUpEnabled"
                type="button"
                class="has-icon icon--chevron-up btn-inverted"
                (click)="moveUp.emit()"
                data-test-move-location-up-button
            ></button>
            <button
                [disabled]="!moveDownEnabled"
                type="button"
                class="has-icon icon--chevron-down btn-inverted"
                (click)="moveDown.emit()"
                data-test-move-location-down-button
            ></button>
        </div>

        <div class="clone-delete-container d-flex" *ngIf="canClone || canRemove">
            <button
                *ngIf="canClone"
                [disabled]="!cloneEnabled"
                (click)="clone.emit()"
                type="button"
                class="has-icon icon--clone btn-inverted"
                data-test-clone-location-button
            ></button>
            <button
                *ngIf="canRemove"
                [disabled]="!removeEnabled"
                (click)="remove.emit()"
                type="button"
                class="btn-delete has-icon icon--delete ml-auto btn-inverted"
                data-test-delete-location-button
            ></button>
        </div>
    </div>
</div>
