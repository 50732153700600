import { box, Boxed, unbox } from "ngrx-forms";
import * as R from "ramda";
import { v4 as uuid } from "uuid";

import { Identity, isNullOrUndefined } from "@ops/shared";
import { CargoBerthActivityType, Enumeration, LaytimeEventType } from "@ops/shared/reference-data";

import { ActivityForm } from "./activity";
import { berthForm, BerthForm, berthToForm, createBerthId, formToBerth } from "./berth";
import { LaytimeEventForm } from "./laytime-event";
import { toDateTime } from "../../../shared/utils";
import { Berth, CargoBerthActivity, DateRange, Destination, Division, FixtureType, LaytimeEvent, SeaNetLocation } from "../../shared/models";

export type DestinationId = Identity<string, "DestinationId">;
export const createDestinationId = (): DestinationId => uuid() as DestinationId;

export type PortCostStatus = Readonly<Enumeration>;

export type DestinationForm = Readonly<{
    id: DestinationId;
    destinationId: number;
    location?: Boxed<SeaNetLocation>;
    etaRange?: Boxed<DateRange>;
    etaFixedRange?: Boxed<DateRange>;
    etd?: string;
    arrivalDateTime?: string;
    portAgent?: string;
    portCosts?: number;
    portCostStatus?: Boxed<PortCostStatus>;
    comments?: string;
    commentsImportant?: boolean;
    instructions?: string;
    instructionsImportant?: boolean;
    berths: ReadonlyArray<BerthForm>;
}>;

/**
 * Creates a default `DestinationForm` for the specified `division`.
 */
export const destinationForm = (division: Division, id: DestinationId, destinationId: number): DestinationForm => ({
    id,
    destinationId,
    location: null,
    etaRange: null,
    etaFixedRange: null,
    etd: null,
    arrivalDateTime: null,
    portAgent: null,
    portCosts: null,
    portCostStatus: null,
    comments: null,
    commentsImportant: false,
    instructions: null,
    instructionsImportant: false,
    berths: [berthForm(division, createBerthId(), 1)]
});

export const destinationToForm =
    (fixtureType: FixtureType, division: Division) =>
    (source: Destination): DestinationForm => ({
        id: source.id,
        destinationId: source.destinationId,
        location: box(source.location),
        etaRange: source.etaRange ? box(source.etaRange) : null,
        etaFixedRange: source.etaFixedRange ? box(source.etaFixedRange) : null,
        etd: source.etd,
        arrivalDateTime: source.arrivalDateTime,
        portAgent: source.portAgent,
        portCosts: source.portCosts,
        portCostStatus: box(source.portCostStatus),
        comments: source.comments,
        commentsImportant: source.areCommentsImportant,
        instructions: source.instructions,
        instructionsImportant: source.areInstructionsImportant,
        berths: source.berths.map(berthToForm(fixtureType, division, source))
    });

export const formToDestination = (source: DestinationForm, current: Destination): Destination => ({
    ...current,
    id: source.id,
    destinationId: source.destinationId,
    location: unbox(source.location),
    etd: source.etd,
    arrivalDateTime: source.arrivalDateTime,
    portAgent: source.portAgent,
    portCosts: source.portCosts,
    portCostStatus: unbox(source.portCostStatus),
    comments: source.comments,
    areCommentsImportant: source.commentsImportant,
    instructions: source.instructions,
    areInstructionsImportant: source.instructionsImportant,
    etaRange: source.etaRange ? unbox(source.etaRange) : null,
    etaFixedRange: source.etaFixedRange ? unbox(source.etaFixedRange) : null,
    berths: source.berths.map((berth) => formToBerth(berth, current ? current.berths.find((x) => x.id === berth.id) : null))
});

/**
 * Returns true if the destination has one or more laytime events with the event date set.
 */
export const destinationHasActivityTypes = (destination: DestinationForm | Destination, ...types: ReadonlyArray<CargoBerthActivityType>): boolean =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    destination.berths.some((berth: any) =>
        (berth.activities || berth.cargoBerthActivities)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((activity: any) => unbox(activity.type))
            .some((type: CargoBerthActivityType) => type && types.some((t) => type.id === t.id))
    );

/**
 * Returns true if the destination has one or more laytime events with the event date set.
 */
export const destinationHasLaytimeEventDate = (destination: DestinationForm): boolean =>
    destination.berths.some((berth) => berth.activities.some((activity) => activity.laytimeEvents && activity.laytimeEvents.some((event: LaytimeEventForm) => !!event.eventDate)));

/**
 * Returns the last laytime event of the last activity of the last berth.
 */
export const destinationLastLaytimeEvent = (destination: DestinationForm): LaytimeEventForm => {
    const lastBerth = R.last(destination.berths);
    const lastActivity = lastBerth && R.last(lastBerth.activities);
    return lastActivity && R.last(lastActivity.laytimeEvents);
};

/**
 * Returns the last laytime event date with the given type and the event date set.
 */
export const getLaytimeEventDate = (destination: Destination | DestinationForm, laytimeEventType: LaytimeEventType): string =>
    R.pipe(
        R.map((b) => (b as BerthForm)?.activities ?? (b as Berth)?.cargoBerthActivities),
        R.flatten,
        R.map((ac: ActivityForm | CargoBerthActivity) => ac.laytimeEvents),
        R.flatten,
        R.filter((e: LaytimeEventForm | LaytimeEvent) => e.eventDate && e.type && unbox(e.type).id === laytimeEventType.id),
        R.map((e: LaytimeEventForm | LaytimeEvent) => e.eventDate),
        R.last
    )(destination.berths) as string;

/**
 * Returns all laytime event date with the given type and the event date set.
 */
export const getAllLaytimeEventDate = (destinations: Destination[], laytimeEventType: LaytimeEventType): string[] =>
    R.pipe(
        R.map((d: Destination) => d.berths),
        R.flatten,
        R.map((b) => b.cargoBerthActivities),
        R.flatten,
        R.map((ac: CargoBerthActivity) => ac.laytimeEvents),
        R.flatten,
        R.filter((e: LaytimeEvent) => e.eventDate && e.type && unbox(e.type).id === laytimeEventType.id),
        R.map((e: LaytimeEvent) => e.eventDate),
        R.sort((a, b) => toDateTime(a).toMillis() - toDateTime(b).toMillis())
    )(destinations) as string[];

/**
 * Returns the earliest laytime event date with the given type and the event date set.
 */
export const getEarliestLaytimeEventDate = (destinations: Destination[], laytimeEventType: LaytimeEventType): string =>
    R.head(getAllLaytimeEventDate(destinations, laytimeEventType)) as string;

/**
 * Returns the recent laytime event date with the given type and the event date set.
 */
export const getRecentLaytimeEventDate = (destinations: Destination[], laytimeEventType: LaytimeEventType): string =>
    R.last(getAllLaytimeEventDate(destinations, laytimeEventType)) as string;

/**
 * Creates function for returning a flattened collection of cargo berth activities optionally filtering to the given type.
 *
 * @param activityType The activity type.
 */
export const getCargoBerthActivities = (activityType?: CargoBerthActivityType) => (destination: Destination) =>
    R.pipe(
        R.map((b: Berth) => (activityType ? b.cargoBerthActivities.filter((a) => a.type && a.type.id === activityType.id) : b.cargoBerthActivities)),
        R.flatten
    )(destination.berths);

/**
 * Creates function for returning a flattened collection of associated cargoes optionally filtering to the given activity type.
 *
 * @param activityType The activity type.
 */
export const getAssociatedCargoes = (activityType?: CargoBerthActivityType) => (destinations: ReadonlyArray<Destination>) =>
    R.pipe(
        R.map(getCargoBerthActivities(activityType)),
        R.flatten,
        R.map((a) => a.associatedCargoes.filter((ac) => !isNullOrUndefined(ac.cargoId))),
        R.flatten
    )(destinations);

export const getDestinationStartDate = (destination: DestinationForm) =>
    getLaytimeEventDate(destination, LaytimeEventType.NORTendered) ?? destination.arrivalDateTime ?? unbox(destination.etaRange)?.to;

export const getDestinationTimeZone = (destination: DestinationForm) => unbox(destination.location)?.timeZone;
