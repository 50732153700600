import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { AppState, interopLeftBarCollapseToggle, interopLeftBarSetCurrentPanel } from "@ops/state";

import { LeftBarStateService } from "../../left-bar/left-bar-state.service";

@Injectable({
    providedIn: "root"
})
export class LeftBarInterop {
    constructor(private leftBarStateService: LeftBarStateService, private store: Store<AppState>) {}

    init() {
        this.leftBarStateService.isCollapsed$.subscribe((isCollapsed) => {
            this.store.dispatch(interopLeftBarCollapseToggle({ isCollapsed }));
        });
    }

    setCurrentPanel(panel: string | undefined) {
        this.store.dispatch(interopLeftBarSetCurrentPanel({ panel }));
    }
}
