import { Injectable } from "@angular/core";

export type MixpanelProperties = Record<string, string | number | boolean>;

declare global {
    interface Window {
        seaTracker: { trackEvent: (eventName: string, properties: MixpanelProperties) => void };
    }
}

@Injectable({
    providedIn: "root"
})
export class MixpanelService {
    track(eventName: string, properties: MixpanelProperties = {}): void {
        queueMicrotask(() => {
            if (window.seaTracker) {
                window.seaTracker.trackEvent(eventName, properties);
            } else {
                console.log("SeaTracker not loaded - tracking Mixpanel event", eventName, properties);
            }
        });
    }
}
