import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, UntypedFormBuilder } from "@angular/forms";
import { takeUntil } from "rxjs/operators";

import { AtSeaVesselStatus } from "../../../../shared/reference-data/at-sea-vessel-status";
import { BunkerSeaStatus } from "../../../../shared/reference-data/bunker-sea-status";
import { EngineConsumption } from "../../../shared/models/dtos/engine-consumption.dto";
import { EngineConsumptionFormModel } from "../../../shared/models/form-models/engine-consumption.model";
import { AbstractSimpleGridComponent } from "../abstract-simple-grid/abstract-simple-grid.component";
import { UpdateEngineConsumptionCommand } from "./commands/update-engine-consumption.command";

@Component({
    selector: "ops-engine-consumption",
    templateUrl: "./engine-consumption.component.html",
    styleUrls: ["./engine-consumption.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EngineConsumptionComponent extends AbstractSimpleGridComponent<EngineConsumptionFormModel> implements OnChanges {
    static componentName = "EngineConsumptionComponent";

    private bunkerSeaStatus = BunkerSeaStatus;
    private atSeaCargoStatus = AtSeaVesselStatus;

    private emptyModel = [
        this.createFormModel(AtSeaVesselStatus.Ballast, BunkerSeaStatus.Full),
        this.createFormModel(AtSeaVesselStatus.Ballast, BunkerSeaStatus.Eco),
        this.createFormModel(AtSeaVesselStatus.Laden, BunkerSeaStatus.Full),
        this.createFormModel(AtSeaVesselStatus.Laden, BunkerSeaStatus.Eco)
    ];

    private columnDefs = [
        { headerName: "", field: "cargoStatus", class: "cargo-status-column-fixed" },
        { headerName: "", field: "seaStatus", class: "sea-status-column-fixed" },
        { headerName: "Main", field: "mainEngine", class: "main-engine-column-fixed text-right" },
        { headerName: "Auxiliary", field: "auxiliaryEngine", class: "auxiliary-engine-column-fixed text-right" },
        { headerName: "Total", field: "total", class: "total-column-fixed text-right" }
    ];

    @Output() updated = new EventEmitter();
    @Input() model: EngineConsumption;

    constructor(public changeDetectorRef: ChangeDetectorRef, protected formBuilder: UntypedFormBuilder) {
        super("key", changeDetectorRef, formBuilder);

        this.columnDefs$.next(this.columnDefs);
        this.rowChanged$.pipe(takeUntil(this.destroy$)).subscribe((row) => this.updated.emit(new UpdateEngineConsumptionCommand(row)));
    }

    trackByFn(index: number, _: AbstractControl) {
        return index;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["model"] === undefined) {
            return;
        }

        const modelChange = changes["model"];
        const currentValue = (modelChange.currentValue as any) as EngineConsumption;

        if (!currentValue) {
            this.rowData$.next(Object.assign([], this.emptyModel));
            return;
        }

        this.rowData$.next([
            {
                ...this.createFormModel(AtSeaVesselStatus.Ballast, BunkerSeaStatus.Full),
                mainEngine: currentValue.ballastFullMain,
                auxiliaryEngine: currentValue.ballastFullAuxiliary,
                total: this.calculateTotal([currentValue.ballastFullMain, currentValue.ballastFullAuxiliary])
            },
            {
                ...this.createFormModel(AtSeaVesselStatus.Ballast, BunkerSeaStatus.Eco),
                mainEngine: currentValue.ballastEcoMain,
                auxiliaryEngine: currentValue.ballastEcoAuxiliary,
                total: this.calculateTotal([currentValue.ballastEcoMain, currentValue.ballastEcoAuxiliary])
            },
            {
                ...this.createFormModel(AtSeaVesselStatus.Laden, BunkerSeaStatus.Full),
                mainEngine: currentValue.ladenFullMain,
                auxiliaryEngine: currentValue.ladenFullAuxiliary,
                total: this.calculateTotal([currentValue.ladenFullMain, currentValue.ladenFullAuxiliary])
            },
            {
                ...this.createFormModel(AtSeaVesselStatus.Laden, BunkerSeaStatus.Eco),
                mainEngine: currentValue.ladenEcoMain,
                auxiliaryEngine: currentValue.ladenEcoAuxiliary,
                total: this.calculateTotal([currentValue.ladenEcoMain, currentValue.ladenEcoAuxiliary])
            }
        ]);
    }

    private calculateTotal(numbers: (number | null)[]): number | null {
        const total = numbers.reduce((acc, x) => (x !== null && x !== undefined && x >= 0 ? acc + x : acc), 0);
        return total > 0 ? total : null;
    }

    private createFormModel(cargoStatus: AtSeaVesselStatus, seaStatus: BunkerSeaStatus): EngineConsumptionFormModel {
        return {
            key: cargoStatus.name + "." + seaStatus.name,
            cargoStatus: cargoStatus,
            seaStatus: seaStatus,
            auxiliaryEngine: null,
            mainEngine: null,
            total: null
        };
    }
}
