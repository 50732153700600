import { Injectable } from "@angular/core";

import { CargoBerthActivityType } from "../../../shared/reference-data";
import { hasItems } from "../../../shared/utils";
import { CommandHandler } from "../../mediator";
import { CargoBerthActivity, LaytimeEvent, Voyage } from "../../shared/models";
import { LaytimeEventFact } from "../../shared/models/dtos/laytime-event-fact.dto";
import { createLaytimeEventId } from "../../state/model";
import { SyncLaytimeEventsCommand } from "./sync-laytime-events-command";

@Injectable({
    providedIn: "root"
})
export class SyncLaytimeEventsCommandHandler implements CommandHandler {
    handle(voyage: Voyage, command: SyncLaytimeEventsCommand): void {
        const berthToUpdate = this.getBerthToUpdate(voyage, command);

        if (!hasItems(berthToUpdate.cargoBerthActivities)) {
            return;
        }

        const cargoBerthActivitiesToSync = berthToUpdate.cargoBerthActivities.filter((c) => CargoBerthActivityType.isLoadOrDischarge(c.type));

        cargoBerthActivitiesToSync.forEach((activity: CargoBerthActivity) => {
            if (hasItems(activity.laytimeEvents)) {
                const laytimeEventsToSync = activity.laytimeEvents.filter((l) => !l.isSynced && l.type && l.eventDate);
                activity.laytimeEventFacts = activity.laytimeEventFacts || [];
                this.syncLaytimeEvents(laytimeEventsToSync, activity.laytimeEventFacts);
            }
        });
    }

    private syncLaytimeEvents(laytimeEvents: LaytimeEvent[], laytimeEventFacts: LaytimeEventFact[]) {
        if (!hasItems(laytimeEvents)) {
            return;
        }

        let isCalculationStarted = false;
        for (let index = 0; index < laytimeEvents.length; index++) {
            const laytimeEvent = laytimeEvents[index];

            // set toDate to eventDate of next laytimeEvent or current eventDate
            const toDate = index < laytimeEvents.length - 1 ? laytimeEvents[index + 1].eventDate : laytimeEvent.eventDate;

            // set percentage to next laytimeEvent or null
            const percentage = index < laytimeEvents.length - 1 && laytimeEvents[index + 1].percentage ? laytimeEvents[index + 1].percentage : 100;

            const laytimeEventFact = new LaytimeEventFact(createLaytimeEventId(), laytimeEvent.type, laytimeEvent.eventDate, toDate);

            // isCalculationStarted is true if the event is an start event or between start and stope events
            isCalculationStarted = isCalculationStarted ? !laytimeEvent.isStartOrStop : laytimeEvent.isStartOrStop;

            // set percentage to 0 if event is not between start and stop
            laytimeEventFact.percentage = isCalculationStarted ? percentage : 0;

            laytimeEventFact.comments = laytimeEvent.comments;
            laytimeEventFact.demurrageReason = laytimeEvent.demurrageReason;
            laytimeEventFact.cargoId = laytimeEvent.cargoId;

            laytimeEventFacts.push(laytimeEventFact);
            laytimeEvents[index].isSynced = true;
        }
    }

    private getBerthToUpdate(voyage: Voyage, command: SyncLaytimeEventsCommand) {
        const destinationToUpdate = voyage.destinations.find((d) => d.id === command.destinationId);
        if (!destinationToUpdate) {
            throw new Error(`destination with Id: ${command.destinationId} does not exist.`);
        }
        if (!hasItems(destinationToUpdate.berths)) {
            throw new Error(`there are no berths on destinationId: ${command.destinationId}.`);
        }
        const berthToUpdate = destinationToUpdate.berths.find((b) => b.id === command.berthId);
        if (!berthToUpdate) {
            throw new Error(`berth with Id: ${command.berthId} does not exist.`);
        }
        return berthToUpdate;
    }
}
