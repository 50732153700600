import * as R from "ramda";

import { CargoHistoricalEvent, LiftingCargoPlanStatus, LiftingVesselPlanStatus, VesselHistoricalEvent, VesselNominationStatus } from "../../coa/state";
import { DateRange } from "../../fixture/shared/models";
import { deepEqual, isNullOrUndefined, isNumber, toDateTime, toDateTimeAsUtc } from "../../shared";

export const liftingHistoryFilterType = ["Laycan", "Cargo Nomination", "Vessel Nomination"] as const;
export type LiftingHistoryFilterType = typeof liftingHistoryFilterType[number];

export type LiftingHistorySearchForm = Readonly<{
    eventType: LiftingHistoryFilterType;
}>;

export const liftingHistoryEventType = ["Add", "Update", "Delete"] as const;
export type LiftingHistoryEventType = typeof liftingHistoryEventType[number];

export type LiftingHistoryEvent = Readonly<{
    title: string;
    date: string;
    user: string;
    status: string | undefined;
    eventType: LiftingHistoryEventType;
    filterType: LiftingHistoryFilterType;
    changes: ReadonlyArray<LiftingHistoryEventChange>;
    changeReason?: LiftingHistoryChangeReason;
}>;

declare type LiftingHistoryChangeReason = Readonly<{
    reason: string;
    responsibleForChange?: string;
}>;

declare type LiftingHistoryEventChange = Readonly<{
    name: string;
    type: "amount" | "tolerance" | "expandable-text" | "text";
    value: any;
}>;

declare type HistoricalState = Readonly<{
    date: string;
    user: string;
    entityId: string | undefined;
    payload: HistoricalStatePayload;
}>;

declare type HistoricalStatePayload = Readonly<{
    initialCargoAdded: boolean;
    initialVesselAdded: boolean;
    cargoes: ReadonlyArray<CargoHistoricalState>;
    cargoLaycan: DateRange | undefined;
    cargoPlanStatus: LiftingCargoPlanStatus;
    vesselPlanStatus: LiftingVesselPlanStatus;
    vessels: ReadonlyArray<VesselHistoricalState>;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _changeReason: ChangeReasonState | string | undefined;
}>;

export type VesselHistoricalState = Readonly<{
    vesselId: string;
    name: string;
    locationPriorToVoyage: NamedItem | null;
    estimatedTimeOfDeparture: string | null;
    lastCargoes: ReadonlyArray<NamedItem> | null;
    cargoComments: string | null;
    laycan: DateRange | null;
    vesselNominationStatus: VesselNominationStatus;
}>;

declare type NamedItem = Readonly<{
    name: string;
}>;

export type CargoHistoricalState = Readonly<{
    cargoId: string;
    name: string;
    orderId: string | null;
    quantity: string | null;
    quantityUnit: string | 0 | null;
    minTolerance?: string | null;
    maxTolerance?: string | null;
    toleranceUnit: string | 0 | null;
    toleranceOption: string | 0 | null;
    freightRate: string | null;
    freightRateUnit: string | 0 | null;
    loadLocations: ReadonlyArray<LocationHistoricalState>;
    dischargeLocations: ReadonlyArray<LocationHistoricalState>;
}>;

export type LocationHistoricalState = Readonly<{
    name: string | null;
    estimatedTimeOfArrival: DateRange | null;
}>;

declare type ChangeReasonState = Readonly<{
    responsibleForChange: string;
    reason: string;
}>;

export const getLiftingHistory = (cargoNominationHistory: ReadonlyArray<CargoHistoricalEvent>, vesselNominationHistory: ReadonlyArray<VesselHistoricalEvent>) => {
    const sortedHistory = R.sortBy(R.prop("date"), [...(vesselNominationHistory ?? []), ...(cargoNominationHistory ?? [])]);

    const processors = R.pipe(
        R.groupBy((event: CargoHistoricalEvent | VesselHistoricalEvent) => getGroupingKey(event)),
        R.values,
        R.map((grouping: (CargoHistoricalEvent | VesselHistoricalEvent)[]) =>
            grouping.map(getHistoricalEventProcessor).reduce(
                (f, g) => (x: HistoricalState) => g(f(x)),
                (x: HistoricalState) => x
            )
        )
    )(sortedHistory);

    const historicalStates = processors.reduce(
        (acc, p) => {
            const stateWithoutChangeReason: HistoricalState = {
                ...acc[acc.length - 1],
                payload: {
                    ...acc[acc.length - 1].payload,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    _changeReason: undefined
                }
            };
            acc.push(p(stateWithoutChangeReason));
            return acc;
        },
        [getInitialHistoricalState()]
    );

    const result: LiftingHistoryEvent[] = [];

    for (let index = 1; index < historicalStates.length; index++) {
        const oldHistoricalState = historicalStates[index - 1];
        const newHistoricalState = historicalStates[index];
        logCargoNomination(oldHistoricalState, newHistoricalState, result);
        logVesselNomination(oldHistoricalState, newHistoricalState, result);
        logCargoLaycan(oldHistoricalState, newHistoricalState, result);
        logVesselLaycan(oldHistoricalState, newHistoricalState, result);
        logCargoPlanStatus(oldHistoricalState, newHistoricalState, result);
        logVesselPlanStatus(oldHistoricalState, newHistoricalState, result);
    }

    return result;
};

const getGroupingKey = (event: CargoHistoricalEvent | VesselHistoricalEvent) => {
    let result = event.date;
    if (event.name === "LiftingCargoPlanStatusChangedV1" || event.name === "LiftingVesselPlanStatusChangedV1") {
        result += "-plan";
    }
    return result;
};

const logVesselLaycan = (oldHistoricalState: HistoricalState, newHistoricalState: HistoricalState, result: LiftingHistoryEvent[]) => {
    const oldNominatedVessel = getNominatedVessel(oldHistoricalState.payload.vessels);
    const oldVesselLaycan = oldNominatedVessel?.laycan;
    const newVesselLaycan = getNominatedVessel(newHistoricalState.payload.vessels)?.laycan;

    switch (getLaycanEventType(oldVesselLaycan, newVesselLaycan)) {
        case "Add":
            result.push(getVesselLaycanAddedEvent(newHistoricalState));
            break;
        case "Delete":
            result.push(getVesselLaycanDeletedEvent(newHistoricalState, oldNominatedVessel?.name));
            break;
        case "Update":
            result.push(getVesselLaycanUpdatedEvent(newHistoricalState));
            break;
    }
};

const logCargoLaycan = (oldHistoricalState: HistoricalState, newHistoricalState: HistoricalState, result: LiftingHistoryEvent[]) => {
    const oldCargoLaycan = oldHistoricalState.payload.cargoLaycan;
    const newCargoLaycan = newHistoricalState.payload.cargoLaycan;

    switch (getLaycanEventType(oldCargoLaycan, newCargoLaycan)) {
        case "Add":
            result.push(getCargoLaycanAddedEvent(newHistoricalState));
            break;
        case "Delete":
            result.push(getCargoLaycanDeletedEvent(newHistoricalState));
            break;
        case "Update":
            result.push(getCargoLaycanUpdatedEvent(newHistoricalState));
            break;
    }
};

const logCargoNomination = (oldHistoricalState: HistoricalState, newHistoricalState: HistoricalState, result: LiftingHistoryEvent[]) => {
    const oldCargoIds = new Set(oldHistoricalState.payload.cargoes.map((c) => c.cargoId));
    const newCargoIds = new Set(newHistoricalState.payload.cargoes.map((c) => c.cargoId));

    [...newCargoIds]
        .filter((cargoId) => !oldCargoIds.has(cargoId))
        .forEach((cargoId, index) => {
            const cargo = getNormalizedCargo(newHistoricalState, cargoId);
            result.push(getCargoNominationAddedEvent(newHistoricalState, cargo, !oldHistoricalState.payload.initialCargoAdded && index === 0));
        });

    [...oldCargoIds]
        .filter((cargoId) => !newCargoIds.has(cargoId))
        .forEach((cargoId) => {
            const cargo = getNormalizedCargo(oldHistoricalState, cargoId);
            result.push(getCargoNominationRemovedEvent(newHistoricalState, cargo));
        });

    [...newCargoIds]
        .filter((cargoId) => oldCargoIds.has(cargoId))
        .forEach((cargoId) => {
            const oldCargo = getNormalizedCargo(oldHistoricalState, cargoId);
            const newCargo = getNormalizedCargo(newHistoricalState, cargoId);
            if (!deepEqual(oldCargo, newCargo)) {
                result.push(getCargoNominationUpdatedEvent(newHistoricalState, oldCargo, newCargo));
            }
        });
};

const logCargoPlanStatus = (oldHistoricalState: HistoricalState, newHistoricalState: HistoricalState, result: LiftingHistoryEvent[]) => {
    if (oldHistoricalState.payload.cargoPlanStatus !== newHistoricalState.payload.cargoPlanStatus) {
        result.push(getCargoPlanStatusUpdatedEvent(newHistoricalState));
    }
};

/**
 * We need to normalize cargo to compare it correctly and then prepare the list of changed properties.
 * Cargoes coming from the server have "Unspecified" for empty values which is more convenient to have as nulls.
 * Also cargoes from the server have redundant properties that are not part of the interface and they are there simply because of
 * the approach of accumulating changes via applying raw json changes.
 * Also after applying updates like `cargoes/0/freightRate(pin):"10"` Ramda interpetes 10 as number instead of string,
 * and saves the number in the model, which needs cast to string before comparing.
 * Also this allows to unify null and undefined.
 */
const getNormalizedCargo = (historicalState: HistoricalState, cargoId: string): CargoHistoricalState => {
    const cargo = historicalState.payload.cargoes.find((c) => c.cargoId === cargoId);
    if (!cargo) {
        throw new Error(`Cargo with id=${cargoId} was not found in the historical state`);
    }
    return {
        cargoId: cargo.cargoId,
        name: cargo.name,
        orderId: hasValue(cargo.orderId) ? `${cargo.orderId}` : null,
        quantity: hasValue(cargo.quantity) ? `${cargo.quantity}` : null,
        quantityUnit: cargo.quantityUnit && hasValue(cargo.quantityUnit) ? cargo.quantityUnit : null,
        minTolerance: hasValue(cargo.minTolerance) ? cargo.minTolerance : null,
        maxTolerance: hasValue(cargo.maxTolerance) ? cargo.maxTolerance : null,
        toleranceUnit: cargo.toleranceUnit && hasValue(cargo.toleranceUnit) ? cargo.toleranceUnit : null,
        toleranceOption: cargo.toleranceOption && hasValue(cargo.toleranceOption) ? cargo.toleranceOption : null,
        freightRate: hasValue(cargo.freightRate) ? `${cargo.freightRate}` : null,
        freightRateUnit: cargo.freightRateUnit && hasValue(cargo.freightRateUnit) ? cargo.freightRateUnit : null,
        loadLocations: getNormalizedLocations(cargo.loadLocations),
        dischargeLocations: getNormalizedLocations(cargo.dischargeLocations)
    };
};

const getNormalizedLocations = (locations: ReadonlyArray<LocationHistoricalState>) =>
    locations
        ?.filter((location) => !!location)
        .map((location) => ({
            name: location.name ?? null,
            estimatedTimeOfArrival: getNormalizedDateRange(location.estimatedTimeOfArrival)
        }));

const logVesselNomination = (oldHistoricalState: HistoricalState, newHistoricalState: HistoricalState, result: LiftingHistoryEvent[]) => {
    const oldVesselIdsSet = new Set(oldHistoricalState.payload.vessels.map((c) => c.vesselId));
    const newVesselIdsSet = new Set(newHistoricalState.payload.vessels.map((c) => c.vesselId));
    let newVesselIds = [...newVesselIdsSet];
    const entityId = newHistoricalState.entityId;
    if (entityId && newVesselIdsSet.has(entityId)) {
        newVesselIds = [entityId, ...newVesselIds.filter((item) => item !== entityId)];
    }

    newVesselIds
        .filter((vesselId) => !oldVesselIdsSet.has(vesselId))
        .forEach((vesselId, index) => {
            const vessel = getNormalizedVessel(newHistoricalState, vesselId);
            result.push(getVesselNominationAddedEvent(newHistoricalState, vessel, !oldHistoricalState.payload.initialVesselAdded && index === 0));
        });

    [...oldVesselIdsSet]
        .filter((vesselId) => !newVesselIdsSet.has(vesselId))
        .forEach((vesselId) => {
            const vessel = getNormalizedVessel(oldHistoricalState, vesselId);
            result.push(getVesselNominationRemovedEvent(newHistoricalState, vessel));
        });

    newVesselIds
        .filter((vesselId) => oldVesselIdsSet.has(vesselId))
        .forEach((vesselId) => {
            const oldVessel = getNormalizedVessel(oldHistoricalState, vesselId);
            const newVessel = getNormalizedVessel(newHistoricalState, vesselId);
            if (!deepEqual(oldVessel, newVessel)) {
                result.push(getVesselNominationUpdatedEvent(newHistoricalState, oldVessel, newVessel));
            }
        });
};

const logVesselPlanStatus = (oldHistoricalState: HistoricalState, newHistoricalState: HistoricalState, result: LiftingHistoryEvent[]) => {
    if (oldHistoricalState.payload.vesselPlanStatus !== newHistoricalState.payload.vesselPlanStatus) {
        result.push(getVesselPlanStatusUpdatedEvent(newHistoricalState));
    }
};

const getNormalizedVessel = (historicalState: HistoricalState, vesselId: string): VesselHistoricalState => {
    const vessel = historicalState.payload.vessels.find((c) => c.vesselId === vesselId);
    if (!vessel) {
        throw new Error(`Vessel with id=${vesselId} was not found in the historical state`);
    }
    return {
        vesselId: vessel.vesselId,
        name: vessel.name,
        locationPriorToVoyage: hasValue(vessel.locationPriorToVoyage?.name) ? vessel.locationPriorToVoyage : null,
        estimatedTimeOfDeparture: getNormalizedDate(vessel.estimatedTimeOfDeparture),
        lastCargoes: getNormalizedNamedItems(vessel.lastCargoes),
        cargoComments: hasValue(vessel.cargoComments) ? vessel.cargoComments : null,
        laycan: getNormalizedDateRange(vessel.laycan),
        vesselNominationStatus: vessel.vesselNominationStatus ?? null
    };
};

const getNormalizedNamedItems = (items: ReadonlyArray<NamedItem> | null) => {
    const filtetedItems = items?.filter((item) => !!item);
    return filtetedItems?.length ? filtetedItems : null;
};

const getNormalizedDateRange = (dateRange: DateRange | null | undefined) => {
    if (!dateRange) {
        return null;
    }
    return {
        from: getNormalizedDate(dateRange.from) ?? undefined,
        to: getNormalizedDate(dateRange.to) ?? undefined
    };
};

const getNormalizedDate = (date: string | null | undefined) => date?.replace(/\.[^.]*$/, "") ?? null;

const hasValue = (value: string | null | undefined) => !isNullOrUndefined(value) && value !== "" && value !== "Unspecified";

const getLaycanEventType = (oldLaycan: DateRange | undefined, newLaycan: DateRange | undefined): LiftingHistoryEventType | undefined => {
    if (!oldLaycan && newLaycan) {
        return "Add";
    } else if (oldLaycan && !newLaycan) {
        return "Delete";
    } else if (oldLaycan && newLaycan && (!datesEqual(oldLaycan.from, newLaycan.from) || !datesEqual(oldLaycan.to, newLaycan.to))) {
        return "Update";
    }
};

const datesEqual = (date1: string | undefined, date2: string | undefined) => formatUtcDate(date1) === formatUtcDate(date2);

const getInitialHistoricalState = (): HistoricalState => ({
    date: "",
    user: "",
    entityId: undefined,
    payload: {
        initialCargoAdded: false,
        initialVesselAdded: false,
        cargoes: [],
        cargoLaycan: undefined,
        cargoPlanStatus: "Tentative",
        vesselPlanStatus: "Tentative",
        vessels: [],
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _changeReason: undefined
    }
});

const getHistoricalEventProcessor = (event: CargoHistoricalEvent | VesselHistoricalEvent): ((_: HistoricalState) => HistoricalState) => {
    let processor: (_: HistoricalState) => HistoricalState = (_) => _;
    switch (event.name) {
        case "LiftingVesselNominationRemovedV1":
            processor = getVesselNominationRemovedProcessor(event as VesselHistoricalEvent);
            break;
        case "LiftingVesselNominationAcceptedV1":
            processor = getVesselNominationStatusProcessor(event as VesselHistoricalEvent, "Accepted");
            break;
        case "LiftingVesselNominationRejectedV1":
            processor = getVesselNominationStatusProcessor(event as VesselHistoricalEvent, "Rejected");
            break;
        case "LiftingVesselNominationUnderReviewV1":
            processor = getVesselNominationStatusProcessor(event as VesselHistoricalEvent, "Under Review");
            break;
        case "LiftingVesselNominationPreferredV1":
            processor = getVesselNominationPreferredProcessor(event as VesselHistoricalEvent);
            break;
        case "LiftingCargoOrderRemovedV1":
            processor = getCargoOrderRemovedProcessor(event as CargoHistoricalEvent);
            break;
    }
    return (hs: HistoricalState) =>
        processor({
            ...hs,
            payload: applyChangesToPayload(hs.payload, event),
            date: event.date,
            user: event.user?.name,
            entityId: event.entityId
        });
};

const applyChangesToPayload = (source: HistoricalStatePayload, event: CargoHistoricalEvent | VesselHistoricalEvent) => {
    let result = source;
    for (const key in event.changes) {
        if (event.changes.hasOwnProperty(key)) {
            const path = key
                .split("/")
                .filter((s) => s.length)
                .map((item) => (isNumber(item) ? +item : item));
            result = R.assocPath(path, getChangeValue(event.changes[key]), result);
        }
    }
    return {
        ...result,
        initialCargoAdded: result.initialCargoAdded || result.cargoes.length > 0,
        initialVesselAdded: result.initialVesselAdded || result.vessels.length > 0
    };
};

const getChangeValue = (value: string) => {
    try {
        return JSON.parse(value);
    } catch {
        return value;
    }
};

const getVesselNominationRemovedProcessor = (event: VesselHistoricalEvent) => (historicalState: HistoricalState) => {
    const newVessels = historicalState.payload.vessels.filter((v) => v.vesselId !== event.entityId);
    return R.assocPath(["payload", "vessels"], newVessels, historicalState);
};

const getVesselNominationStatusProcessor = (event: VesselHistoricalEvent, status: VesselNominationStatus) => (historicalState: HistoricalState) => {
    const index = historicalState.payload.vessels.findIndex((v) => v.vesselId === event.entityId);
    return R.assocPath(["payload", "vessels", index, "vesselNominationStatus"], status, historicalState);
};

const getVesselNominationPreferredProcessor = (event: VesselHistoricalEvent) => (historicalState: HistoricalState) => {
    let newPayload = historicalState.payload;
    const index = newPayload.vessels.findIndex((v) => v.vesselId === event.entityId);
    newPayload.vessels.forEach((vessel, i) => {
        if (i === index) {
            newPayload = R.assocPath(["vessels", i, "vesselNominationStatus"], "Preferred", newPayload);
        } else if (vessel.vesselNominationStatus === "Preferred") {
            newPayload = R.assocPath(["vessels", i, "vesselNominationStatus"], "Accepted", newPayload);
        }
    });
    return {
        ...historicalState,
        payload: newPayload
    };
};

const getCargoOrderRemovedProcessor = (event: CargoHistoricalEvent) => (historicalState: HistoricalState) => {
    const newCargoes = historicalState.payload.cargoes.filter((c) => c.cargoId !== event.entityId);
    return R.assocPath(["payload", "cargoes"], newCargoes, historicalState);
};

const getNominatedVessel = (vessels: ReadonlyArray<VesselHistoricalState>) =>
    vessels.find((v) => v.vesselNominationStatus === "Preferred") ?? vessels.find((v) => v.vesselNominationStatus === "Accepted");

const formatDateRange = (laycan: DateRange | null | undefined): string =>
    laycan && laycan.from && laycan.to && laycan.from !== laycan.to ? `${formatUtcDate(laycan.from)} - ${formatUtcDate(laycan.to)}` : formatUtcDate(laycan?.from);

const formatUtcDate = (date: string | null | undefined): string => (date ? toDateTimeAsUtc(date).toFormat("dd LLL yy HH:mm") : "");
const formatDate = (date: string | null | undefined): string => (date ? toDateTime(date).toFormat("dd LLL yy HH:mm") : "");

const getVesselLaycanAddedEvent = (historicalState: HistoricalState): LiftingHistoryEvent => ({
    title: "Vessel Laycan Added",
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Add",
    filterType: "Laycan",
    status: historicalState.payload.vesselPlanStatus,
    changes: getVesselLaycanEventChanges(historicalState)
});

const getVesselLaycanDeletedEvent = (historicalState: HistoricalState, oldVesselName: string): LiftingHistoryEvent => ({
    title: "Vessel Laycan Removed",
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Delete",
    filterType: "Laycan",
    status: historicalState.payload.vesselPlanStatus,
    changes: [createTextChange("Vessel", oldVesselName), createTextChange("Laycan", undefined)]
});

const getVesselLaycanUpdatedEvent = (historicalState: HistoricalState): LiftingHistoryEvent => ({
    title: "Vessel Laycan Updated",
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Update",
    filterType: "Laycan",
    status: historicalState.payload.vesselPlanStatus,
    changes: getVesselLaycanEventChanges(historicalState)
});

const getVesselLaycanEventChanges = (historicalState: HistoricalState): LiftingHistoryEventChange[] => {
    const nominatedVessel = getNominatedVessel(historicalState.payload.vessels);
    return [createTextChange("Vessel", nominatedVessel?.name), createTextChange("Laycan", formatDateRange(nominatedVessel?.laycan))];
};

const getCargoLaycanAddedEvent = (historicalState: HistoricalState): LiftingHistoryEvent => ({
    title: "Cargo Laycan Added",
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Add",
    filterType: "Laycan",
    status: historicalState.payload.cargoPlanStatus,
    changes: getCargoLaycanEventChanges(historicalState),
    changeReason: historicalState.payload._changeReason as LiftingHistoryChangeReason
});

const getCargoLaycanDeletedEvent = (historicalState: HistoricalState): LiftingHistoryEvent => ({
    title: "Cargo Laycan Removed",
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Delete",
    filterType: "Laycan",
    status: historicalState.payload.cargoPlanStatus,
    changes: getCargoLaycanEventChanges(historicalState),
    changeReason: historicalState.payload._changeReason as LiftingHistoryChangeReason
});

const getCargoLaycanUpdatedEvent = (historicalState: HistoricalState): LiftingHistoryEvent => ({
    title: "Cargo Laycan Updated",
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Update",
    filterType: "Laycan",
    status: historicalState.payload.cargoPlanStatus,
    changes: getCargoLaycanEventChanges(historicalState),
    changeReason: historicalState.payload._changeReason as LiftingHistoryChangeReason
});

const getCargoLaycanEventChanges = (historicalState: HistoricalState): LiftingHistoryEventChange[] => [
    createTextChange("Cargo", historicalState.payload.cargoes.map((c) => c.name).join(", ")),
    createTextChange("Laycan", formatDateRange(historicalState.payload.cargoLaycan))
];

const getCargoNominationAddedEvent = (historicalState: HistoricalState, cargo: CargoHistoricalState, initialCargo: boolean): LiftingHistoryEvent => ({
    title: initialCargo ? "Initial Cargo Nomination Added" : "Cargo Added",
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Add",
    filterType: "Cargo Nomination",
    status: historicalState.payload.cargoPlanStatus,
    changes: getCargoNominationEventChanges(undefined, cargo),
    changeReason: historicalState.payload._changeReason as LiftingHistoryChangeReason
});

const getCargoNominationUpdatedEvent = (historicalState: HistoricalState, oldCargo: CargoHistoricalState, newCargo: CargoHistoricalState): LiftingHistoryEvent => ({
    title: "Cargo Updated",
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Update",
    filterType: "Cargo Nomination",
    status: historicalState.payload.cargoPlanStatus,
    changes: getCargoNominationEventChanges(oldCargo, newCargo),
    changeReason: historicalState.payload._changeReason as LiftingHistoryChangeReason
});

const getCargoNominationRemovedEvent = (historicalState: HistoricalState, cargo: CargoHistoricalState): LiftingHistoryEvent => ({
    title: "Cargo Removed",
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Delete",
    filterType: "Cargo Nomination",
    status: historicalState.payload.cargoPlanStatus,
    changes: getCargoNominationEventChanges(cargo, undefined),
    changeReason: historicalState.payload._changeReason as LiftingHistoryChangeReason
});

const getCargoPlanStatusUpdatedEvent = (historicalState: HistoricalState): LiftingHistoryEvent => ({
    title: `Cargo Nomination ${historicalState.payload.cargoPlanStatus}`,
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Update",
    filterType: "Cargo Nomination",
    status: historicalState.payload.cargoPlanStatus,
    changes: []
});

const getCargoNominationEventChanges = (oldCargo: CargoHistoricalState | undefined, newCargo: CargoHistoricalState | undefined) => {
    const changes: LiftingHistoryEventChange[] = [createTextChange("Cargo", newCargo?.name ?? oldCargo?.name)];
    if (!newCargo) {
        return changes;
    }
    if (shouldLogChange(oldCargo, newCargo, ["orderId"])) {
        changes.push(createTextChange("Order ID", newCargo.orderId));
    }
    if (shouldLogChange(oldCargo, newCargo, ["quantity", "quantityUnit"])) {
        changes.push(createAmountChange("Nominated Quantity", newCargo.quantity, newCargo.quantityUnit));
    }
    if (shouldLogChange(oldCargo, newCargo, ["freightRate", "freightRateUnit"])) {
        changes.push(createAmountChange("Freight Rate", newCargo.freightRate, newCargo.freightRateUnit));
    }
    if (shouldLogChange(oldCargo, newCargo, ["minTolerance", "maxTolerance", "toleranceUnit", "toleranceOption"])) {
        changes.push(createToleranceChange("Tolerance", newCargo));
    }
    logLocationsChange(oldCargo, newCargo, "loadLocations", "Load Location", "Load ETA", changes);
    logLocationsChange(oldCargo, newCargo, "dischargeLocations", "Discharge Location", "Discharge ETA", changes);
    return changes;
};

const logLocationsChange = (
    oldCargo: CargoHistoricalState | undefined,
    newCargo: CargoHistoricalState,
    property: "loadLocations" | "dischargeLocations",
    locationCaption: string,
    etaCaption: string,
    changes: LiftingHistoryEventChange[]
) => {
    if (shouldLogLocationsChange(oldCargo, newCargo, property)) {
        const newLocations = newCargo[property].filter((location) => location.name || location.estimatedTimeOfArrival);
        if (oldCargo && oldCargo[property]) {
            const oldLocations = oldCargo[property].filter((location) => location.name || location.estimatedTimeOfArrival);
            let diffLocationsWithEta =
                oldLocations.filter((location) => location.estimatedTimeOfArrival).length - newLocations.filter((location) => location.estimatedTimeOfArrival).length;
            // if eta is not set, we should log as many empty etas as the difference of filled etas in old and new cargoes
            newLocations.forEach((location) => {
                changes.push(createTextChange(locationCaption, location.name));
                if (location.estimatedTimeOfArrival) {
                    changes.push(createTextChange(etaCaption, formatDateRange(location.estimatedTimeOfArrival)));
                } else if (diffLocationsWithEta > 0) {
                    changes.push(createTextChange(etaCaption, ""));
                    diffLocationsWithEta--;
                }
            });
            // logging deleted locations; we need to name as empty in any case and log eta as empty only if eta was actually deleted
            for (let i = 0; i < oldLocations.length - newLocations.length; i++) {
                changes.push(createTextChange(locationCaption, null));
                if (diffLocationsWithEta > 0) {
                    changes.push(createTextChange(etaCaption, ""));
                    diffLocationsWithEta--;
                }
            }
        } else {
            newLocations.forEach((location) => {
                changes.push(createTextChange(locationCaption, location.name));
                if (location.estimatedTimeOfArrival) {
                    changes.push(createTextChange(etaCaption, formatDateRange(location.estimatedTimeOfArrival)));
                }
            });
        }
    }
};

const getVesselNominationAddedEvent = (historicalState: HistoricalState, vessel: VesselHistoricalState, initialVessel: boolean): LiftingHistoryEvent => ({
    title: initialVessel ? "Initial Vessel Nomination Added" : "Vessel Added",
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Add",
    filterType: "Vessel Nomination",
    status: historicalState.payload.vesselPlanStatus,
    changes: getVesselNominationEventChanges(undefined, vessel),
    changeReason: getVesselChangeReason(historicalState.payload)
});

const getVesselNominationUpdatedEvent = (historicalState: HistoricalState, oldVessel: VesselHistoricalState, newVessel: VesselHistoricalState): LiftingHistoryEvent => ({
    title: "Vessel Updated",
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Update",
    filterType: "Vessel Nomination",
    status: historicalState.payload.vesselPlanStatus,
    changes: getVesselNominationEventChanges(oldVessel, newVessel),
    changeReason: getVesselChangeReason(historicalState.payload)
});

const getVesselNominationRemovedEvent = (historicalState: HistoricalState, vessel: VesselHistoricalState): LiftingHistoryEvent => ({
    title: "Vessel Removed",
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Delete",
    filterType: "Vessel Nomination",
    status: historicalState.payload.vesselPlanStatus,
    changes: getVesselNominationEventChanges(vessel, undefined),
    changeReason: getVesselChangeReason(historicalState.payload)
});

const getVesselPlanStatusUpdatedEvent = (historicalState: HistoricalState): LiftingHistoryEvent => ({
    title: `Vessel Nomination ${historicalState.payload.vesselPlanStatus}`,
    date: formatDate(historicalState.date),
    user: historicalState.user,
    eventType: "Update",
    filterType: "Vessel Nomination",
    status: historicalState.payload.vesselPlanStatus,
    changes: []
});

const getVesselNominationEventChanges = (oldVessel: VesselHistoricalState | undefined, newVessel: VesselHistoricalState | undefined) => {
    const changes: LiftingHistoryEventChange[] = [createTextChange("Vessel", newVessel?.name ?? oldVessel?.name)];
    if (!newVessel) {
        return changes;
    }
    logVesselStatus(oldVessel, newVessel, changes);
    if (shouldLogChange(oldVessel, newVessel, ["laycan"])) {
        changes.push(createTextChange("Laycan", formatDateRange(newVessel.laycan)));
    }
    if (shouldLogChange(oldVessel, newVessel, ["locationPriorToVoyage"])) {
        changes.push(createTextChange("Port Prior To Voyage", newVessel.locationPriorToVoyage?.name));
    }
    if (shouldLogChange(oldVessel, newVessel, ["estimatedTimeOfDeparture"])) {
        changes.push(createTextChange("ETD", formatDate(newVessel.estimatedTimeOfDeparture)));
    }
    if (shouldLogChange(oldVessel, newVessel, ["lastCargoes"])) {
        changes.push(createTextChange("Last Cargoes", newVessel.lastCargoes?.map((c) => c.name).join(", ")));
    }
    if (shouldLogChange(oldVessel, newVessel, ["cargoComments"])) {
        changes.push(createExpandableTextChange("Comments", newVessel.cargoComments));
    }
    return changes;
};

const logVesselStatus = (oldVessel: VesselHistoricalState | undefined, newVessel: VesselHistoricalState, changes: LiftingHistoryEventChange[]) => {
    if (oldVessel && oldVessel.vesselNominationStatus !== newVessel.vesselNominationStatus) {
        changes.push(createTextChange("Vessel Status", newVessel.vesselNominationStatus !== "Preferred" ? newVessel.vesselNominationStatus : "Accepted"));
        if (oldVessel?.vesselNominationStatus === "Preferred") {
            changes.push(createTextChange("Vessel is Preferred", "No"));
        }
        if (newVessel.vesselNominationStatus === "Preferred") {
            changes.push(createTextChange("Vessel is Preferred", "Yes"));
        }
    } else {
        changes.push(createTextChange("Vessel Status", newVessel.vesselNominationStatus));
    }
};

const getVesselChangeReason = (state: HistoricalStatePayload) => (state._changeReason ? { reason: state._changeReason as string } : undefined);

const shouldLogChange = <T>(oldItem: T | undefined, newItem: T, properties: (keyof T)[]) =>
    (oldItem || !isNullOrUndefined(newItem[properties[0]])) && !properties.every((p) => oldItem && deepEqual(oldItem[p], newItem[p]));

const shouldLogLocationsChange = (oldCargo: CargoHistoricalState | undefined, newCargo: CargoHistoricalState, property: "loadLocations" | "dischargeLocations") =>
    (!oldCargo && newCargo[property] && (newCargo[property][0]?.name || newCargo[property][0]?.estimatedTimeOfArrival)) ||
    (oldCargo && !R.equals(oldCargo[property], newCargo[property]));

const createToleranceChange = (name: string, cargo: CargoHistoricalState): LiftingHistoryEventChange => {
    if (!cargo.toleranceUnit) {
        return createTextChange(name, "");
    }
    return {
        name,
        type: "tolerance",
        value: {
            min: cargo.minTolerance,
            max: cargo.maxTolerance,
            unit: cargo.toleranceUnit === "Percentage" ? "%" : cargo.toleranceUnit,
            option: `In ${cargo.toleranceOption}'s Option`
        }
    };
};

const createAmountChange = (name: string, amount: string | null, unit: string | 0 | null): LiftingHistoryEventChange => {
    if (!amount) {
        return createTextChange(name, "");
    }
    return {
        name,
        type: "amount",
        value: { amount, unit }
    };
};

const createExpandableTextChange = (name: string, text: string | null | undefined): LiftingHistoryEventChange => ({
    name,
    type: "expandable-text",
    value: text
});

const createTextChange = (name: string, text: string | null | undefined): LiftingHistoryEventChange => ({
    name,
    type: "text",
    value: text
});
