import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Component({
    selector: "ops-simple-field-validation",
    templateUrl: "./simple-field-validation.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleFieldValidationComponent implements OnChanges {
    private defaultMessages: { [key: string]: string } = { ["required"]: "is required", ["invalidRange"]: "is invalid date range", ["pattern"]: "pattern" };

    condition = false;

    @Input() userMessages: { [key: string]: string };
    @Input() fieldDisplayName: string;
    @Input() control: AbstractControl;

    ngOnChanges(): void {
        const hasError = Object.keys(this.defaultMessages).some((x) => this.control.hasError(x));
        this.condition = this.control.touched && hasError;
    }

    get message(): string {
        const key = Object.keys(this.defaultMessages).find((x) => this.control.hasError(x));
        return key && ((this.userMessages && this.userMessages[key]) || this.defaultMessages[key]);
    }
}
