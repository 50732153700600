import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

import { dateTimeFromISO } from "../../utils/date-utils";

/**
 * For new places where this pipe may be used I suggest implementing another pipe which takes ONLY 'DateTime'.
 * For new pipe js 'Date' argument should be replaced in favor of 'DateTime' from 'luxon'.
 * 'iso strings' have different formats which behave differently when converting to DateTime,
 * that's why I'd recommend to avoid making new pipe capable of converting from 'iso string' too.
 */
@Pipe({
    name: "timezoneDate"
})
export class TimezoneDatePipe implements PipeTransform {
    transform(value: string | Date | DateTime | null, format: string, timeZone = "local", shiftTimezone = true) {
        if (!value) {
            return null;
        }

        const resolvedFormat = this.resolveFormat(format);
        return this.toDateTime(value, timeZone, shiftTimezone)?.toFormat(resolvedFormat) ?? null;
    }

    private resolveFormat(format: string) {
        if (format === "DATE") {
            return "dd MMM yy";
        }

        if (format === "DATETIME") {
            return "dd MMM yy, HH:mm";
        }

        return format;
    }

    private toDateTime(value: string | Date | DateTime, zone: string, shiftTimezone: boolean) {
        if (typeof value === "string") {
            return shiftTimezone ? dateTimeFromISO(value, "local")?.setZone(zone) : dateTimeFromISO(value, zone);
        }

        if (value instanceof Date) {
            return shiftTimezone ? DateTime.fromJSDate(value, { zone }) : DateTime.fromJSDate(value);
        }

        if (DateTime.isDateTime(value)) {
            return shiftTimezone ? value.setZone(zone) : value;
        }

        return null;
    }
}
