<div *ngIf="bunkersArray.length" class="card embedded-card-1" [formGroup]="activityForm">
    <div class="card-header row no-gutters align-items-center">{{ activityName }} - Bunker Remaining Onboard</div>
    <div class="bunkers-remaining">
        <table class="table table-striped table-compact">
            <thead [ngClass]="{ 'no-bunkers': !bunkersArray.length }">
                <tr>
                    <th scope="col" class="type-column-fixed">Type</th>
                    <th scope="col" class="quantity-arival-column-fixed text-right">Quantity at Arrival</th>
                    <th scope="col" class="quantity-sailing-column-fixed text-right">Quantity at Sailing</th>
                    <th scope="col" class="quantity-consumed-column-fixed text-right">Quantity Consumed at Port</th>
                    <th scope="col" class="quantity-price-column-fixed text-right">Price per MT</th>
                    <th scope="col" class="bunkered-column-fixed">Bunkered</th>
                    <th scope="col" class="button-column-fixed"></th>
                    <th scope="col" class="button-column-fixed"></th>
                </tr>
            </thead>
            <tbody formArrayName="bunkers">
                <tr *ngFor="let bunker of bunkersArray; let i = index" [formGroupName]="i" class="form-group">
                    <td>
                        <ng-select
                            class="ops-select"
                            [items]="allBunkerTypes"
                            formControlName="type"
                            [clearable]="false"
                            bindLabel="name"
                            [compareWith]="referenceDataService.compare"
                            triggers="manual"
                            data-test-bunker-type
                            [selectOnTab]="true"
                        >
                        </ng-select>
                    </td>
                    <td>
                        <input
                            type="text"
                            [opsNumber]="{ precision: 3 }"
                            class="form-control text-right input-sm"
                            formControlName="quantityAtArrival"
                            data-test-quantity-arrival
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            [opsNumber]="{ precision: 3 }"
                            class="form-control text-right input-sm"
                            formControlName="quantityAtSailing"
                            data-test-quantity-sailing
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right input-sm"
                            [opsNumber]="{ precision: 3 }"
                            formControlName="quantityConsumed"
                            data-test-quantity-consumed
                            readonly
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            [opsNumber]="{ precision: 3 }"
                            class="form-control text-right input-sm"
                            formControlName="pricePerMt"
                            data-test-price
                        />
                    </td>
                    <td>
                        <div class="checkbox-cell">
                            <input type="checkbox" formControlName="bunkered" (click)="onBunkeredClicked(i)" />
                        </div>
                    </td>
                    <td>
                        <button
                            *ngIf="i === bunkersArray.length - 1"
                            [disabled]="bunkersForm.disabled"
                            (click)="addBunker()"
                            type="button"
                            class="btn-add has-icon icon--add-circle-outline"
                            data-test-add-associated-cargo-button
                        ></button>
                    </td>
                    <td>
                        <button
                            [disabled]="bunkersForm.disabled"
                            (click)="deleteBunker(i)"
                            type="button"
                            class="btn-delete has-icon icon--delete"
                            data-test-remove-associated-cargo-button
                        ></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ops-no-data-panel
    *ngIf="!bunkersArray.length"
    heading="Bunkers"
    title="No bunkers have been added"
    description="To add a bunker, click the button."
    [useNewButton]="true"
    newButtonText="BUNKER"
    [disabled]="bunkersForm.disabled"
    (addNew)="addInitialBunkers()"
>
</ops-no-data-panel>
