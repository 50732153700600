<div class="col">
    <div>Type:&nbsp;</div>
    <div class="summary-value" data-test-expensetypesummary>{{ expenseType }}</div>
</div>
<div class="col">
    <div class="separator"></div>
    <div>Rec From Owner:&nbsp;</div>
    <div class="summary-value" data-test-receivedfromownersummary>{{ receivedFromOwnerDate | date: "d MMM yy" }}</div>
</div>
<div class="col">
    <div class="separator"></div>
    <div>Currency:&nbsp;</div>
    <div class="summary-value" data-test-currencysummary>{{ currency }}</div>
</div>
<div class="col">
    <div class="separator"></div>
    <div>Value:&nbsp;</div>
    <div class="summary-value" data-test-valuesummary>{{ value | number: "1.2-2" }}</div>
</div>
<div class="col">
    <div class="separator"></div>
    <div>Date Paid:&nbsp;</div>
    <div class="summary-value" data-test-paiddatesummary>{{ paidDate | date: "d MMM yy" }}</div>
</div>
<div class="col">
    <div class="separator"></div>
    <div>Commissionable:&nbsp;</div>
    <div class="summary-value" data-test-commissionablesummary>{{ commissionable ? "Yes" : "No" }}</div>
</div>
