/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */

import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "[opsDatePickerDayView]",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./date-picker-day-view.component.scss"],
    host: {
        "[class.outside]": "isOutside()",
        "[class.muted]": "isMuted()",
        "[class.active]": "focused",
        "[class.today]": "isToday()",
        "[class.selected]": "selected",
        "[class.range]": "inRange()",
        "[class.range-start]": "isRangeStart()",
        "[class.range-end]": "isRangeEnd()"
    },
    template: '<div class="ops-dp-day">{{ date.day }}</div>'
})
export class DatePickerDayViewComponent {
    private _date: NgbDate;
    private _rangeStart: NgbDate;
    private _rangeEnd: NgbDate;

    @Input() currentMonth: number;
    @Input() today: NgbDateStruct;
    @Input() disabled: boolean;
    @Input() focused: boolean;
    @Input() selected: boolean;

    @Input()
    get date(): NgbDateStruct {
        return this._date;
    }
    set date(value: NgbDateStruct) {
        this._date = value ? NgbDate.from(value) : null;
    }

    @Input()
    get rangeStart(): NgbDateStruct {
        return this._rangeStart;
    }
    set rangeStart(value: NgbDateStruct) {
        this._rangeStart = value ? NgbDate.from(value) : null;
    }

    @Input()
    get rangeEnd(): NgbDateStruct {
        return this._rangeEnd;
    }
    set rangeEnd(value: NgbDateStruct) {
        this._rangeEnd = value ? NgbDate.from(value) : null;
    }

    isMuted() {
        return !this.selected && (this.date.month !== this.currentMonth || this.disabled);
    }

    isOutside() {
        return this.date.month !== this.currentMonth;
    }

    isToday() {
        return this._date.equals(this.today);
    }

    hasValidRange() {
        return this._rangeStart && this._rangeEnd && this._rangeStart.before(this._rangeEnd);
    }

    isRangeStart() {
        return this.hasValidRange() && this._date.equals(this._rangeStart);
    }

    isRangeEnd() {
        return this.hasValidRange() && this._date.equals(this._rangeEnd);
    }

    inRange() {
        return this.hasValidRange() && this._date.after(this._rangeStart) && this._date.before(this._rangeEnd);
    }
}
