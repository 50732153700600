import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { BerthId, BerthState } from "../../state";

@Component({
    selector: "ops-berths",
    templateUrl: "./berths.component.html",
    styleUrls: ["./berths.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BerthsComponent {
    @Input() berths: ReadonlyArray<BerthState>;
    @Input() readonly: boolean;

    @Output() readonly expand = new EventEmitter<BerthId>();
    @Output() readonly collapse = new EventEmitter<BerthId>();
    @Output() readonly moveUp = new EventEmitter<BerthId>();
    @Output() readonly moveDown = new EventEmitter<BerthId>();
    @Output() readonly add = new EventEmitter();
    @Output() readonly clone = new EventEmitter<BerthId>();
    @Output() readonly remove = new EventEmitter<BerthId>();

    get canAdd() {
        return !this.readonly;
    }

    trackBy(index: number, item: BerthState) {
        return item.berthId;
    }
}
