import { MaritimeDate } from "@maritech/maritime-date";
import { DateTime } from "luxon";

import { ActionDto } from "../../../../action";
import { LiftingId } from "./lifting";

export type ActionDtoWithLiftingId = ActionDto &
    Readonly<{
        liftingId: LiftingId;
    }>;

export type LiftingAction = Readonly<{
    actionId: string;
    name: string;
    dueDate: string;
}>;

export const actionDtoToLiftingAction = (source: ActionDto): LiftingAction => ({
    actionId: source.actionId,
    name: source.summary,
    dueDate: DateTime.fromISO(source.dueDate).toLocaleString(MaritimeDate.DATE_FORMAT)
});
