/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { compare } from "fast-json-patch";
import { DateTime } from "luxon";
import { Observable, of } from "rxjs";
import { catchError, exhaustMap, map, take, withLatestFrom } from "rxjs/operators";

import { LegacyWorksheetActionType } from "./legacy-worksheet-action-type";
import {
    DeselectWorksheetAction,
    DeselectWorksheetSuccessAction,
    GetAllWorksheetsAction,
    GetAllWorksheetsFailAction,
    GetAllWorksheetsSuccessAction,
    LocalStorageQueryPerformedAction,
    PerformGridSetupAction,
    RemoveWorksheetAction,
    RemoveWorksheetFailAction,
    RemoveWorksheetSuccessAction,
    RenameWorksheetAction,
    RenameWorksheetFailAction,
    RenameWorksheetSuccessAction,
    SaveWorksheetAction,
    SaveWorksheetFailAction,
    SaveWorksheetSuccessAction,
    SelectWorksheetAction,
    SelectWorksheetSuccessAction,
    UpdateCurrentWorksheetSavableAction,
    UpdateWorksheetAction,
    UpdateWorksheetFailAction,
    UpdateWorksheetSuccessAction
} from "./legacy-worksheet.actions";
import { LegacyWorksheetState } from "./legacy-worksheet.reducer";
import { selectLegacyWorksheetState } from "./legacy-worksheet.selectors";
import { GridType } from "../../../fixture/shared/models/enums/grid-type";
import { WorksheetHttpService } from "../service/worksheet-http.service";
import { WorksheetLocalStorageService } from "../service/worksheet-local-storage.service";
import { LegacyDuplicateWorksheetNameError } from "../shared/legacy-duplicate-name.error";
import { WorksheetLocalDataModel } from "../shared/worksheet-local-data.model";
import { CreateWorksheetModel, Worksheet, WorksheetView } from "../shared/worksheet.model";
import { WorksheetUtilities } from "../shared/worksheet.utils";

@Injectable({
    providedIn: "root"
})
export class LegacyWorksheetEffects {
    private getAllSuccess = false;

    constructor(private actions$: Actions, private worksheetHttpService: WorksheetHttpService, private store: Store, private localStorage: WorksheetLocalStorageService) {}

    @Effect()
    getAllWorksheets: Observable<Action> = this.actions$.pipe(
        ofType<GetAllWorksheetsAction>(LegacyWorksheetActionType.GetAll),
        exhaustMap(() => {
            if (!this.getAllSuccess) {
                return this.worksheetHttpService.get().pipe(
                    map((worksheets: Worksheet[]) => {
                        this.getAllSuccess = true;
                        return new GetAllWorksheetsSuccessAction({ worksheets: worksheets.map((w) => new WorksheetView(w)) });
                    }),
                    catchError((error) => of(new GetAllWorksheetsFailAction({ error })))
                );
            }
            return of(new PerformGridSetupAction());
        })
    );

    @Effect()
    getAllWorksheetsComplete: Observable<Action> = this.actions$.pipe(
        ofType(LegacyWorksheetActionType.GetAllSuccess, LegacyWorksheetActionType.GetAllFail),
        exhaustMap(() => of(new PerformGridSetupAction()))
    );

    @Effect()
    saveWorksheet: Observable<Action> = this.actions$.pipe(
        ofType<SaveWorksheetAction>(LegacyWorksheetActionType.Save),
        withLatestFrom(this.store.select(selectLegacyWorksheetState)),
        exhaustMap(([, state]) => {
            if (this.getWorksheetNames(state).indexOf(state.currentWorksheet.name.toLowerCase()) > -1) {
                return of(new SaveWorksheetFailAction({ error: new LegacyDuplicateWorksheetNameError(), worksheet: state.currentWorksheet }));
            }

            return this.worksheetHttpService.post(state.currentWorksheet).pipe(
                map((response) => {
                    const location = response.headers.get("Location");
                    const worksheetId = location.substring(location.lastIndexOf("/") + 1);

                    this.setLocalStorage(state.worksheetTypeToShow, worksheetId, state.currentWorksheet);

                    return new SaveWorksheetSuccessAction({ worksheetId, worksheet: state.currentWorksheet });
                }),
                catchError((error) => of(new SaveWorksheetFailAction({ error, worksheet: state.currentWorksheet })))
            );
        })
    );

    @Effect()
    updateWorksheet: Observable<Action> = this.actions$.pipe(
        ofType<UpdateWorksheetAction>(LegacyWorksheetActionType.Update),
        withLatestFrom(this.store.select(selectLegacyWorksheetState)),
        exhaustMap(([, state]) => {
            if (!state.selectedWorksheetId) {
                return of(new UpdateWorksheetFailAction({ error: new Error("No selected worksheet Id"), id: null }));
            }

            const savedWorksheet = state.entities[state.selectedWorksheetId];
            const savedUpdate = {
                columns: savedWorksheet.columns,
                queries: savedWorksheet.queries,
                sort: savedWorksheet.sort
            };
            const changedUpdate = {
                columns: state.currentWorksheet.columns,
                queries: state.currentWorksheet.queries,
                sort: state.currentWorksheet.sort
            };
            const patch = compare(savedUpdate, changedUpdate);

            return this.worksheetHttpService.patch(state.selectedWorksheetId, patch).pipe(
                map(
                    () =>
                        new UpdateWorksheetSuccessAction({
                            columns: changedUpdate.columns,
                            queries: changedUpdate.queries,
                            sort: changedUpdate.sort,
                            worksheetType: state.currentWorksheet.worksheetType
                        })
                ),
                catchError((error) => of(new UpdateWorksheetFailAction({ error, id: state.selectedWorksheetId })))
            );
        })
    );

    @Effect()
    removeWorksheet: Observable<Action> = this.actions$.pipe(
        ofType<RemoveWorksheetAction>(LegacyWorksheetActionType.Remove),
        map((action) => action.payload),
        exhaustMap((payload) =>
            this.worksheetHttpService.delete(payload.id).pipe(
                map(() => new RemoveWorksheetSuccessAction({ id: payload.id })),
                catchError((error) => of(new RemoveWorksheetFailAction({ error, id: payload.id })))
            )
        )
    );

    @Effect()
    renameWorksheet: Observable<Action> = this.actions$.pipe(
        ofType<RenameWorksheetAction>(LegacyWorksheetActionType.Rename),
        withLatestFrom(this.store.select(selectLegacyWorksheetState)),
        exhaustMap(([action, state]) => {
            const payload = action.payload;

            if (payload.name === state.entities[payload.id].name) {
                return of(new RenameWorksheetSuccessAction({ update: { id: payload.id, changes: { saving: false, renaming: false, error: null } } }));
            }

            if (this.getWorksheetNames(state).indexOf(payload.name.toLowerCase()) > -1) {
                return of(new RenameWorksheetFailAction({ id: payload.id, error: new LegacyDuplicateWorksheetNameError() }));
            }

            return this.worksheetHttpService.rename(payload.id, payload.name).pipe(
                map(
                    () =>
                        new RenameWorksheetSuccessAction({
                            update: {
                                id: payload.id,
                                changes: { name: payload.name, saving: false, renaming: false, updatedDate: DateTime.local(), error: null }
                            }
                        })
                ),
                catchError((error) => of(new RenameWorksheetFailAction({ error, id: payload.id })))
            );
        })
    );

    @Effect()
    checkSavable: Observable<Action> = this.actions$.pipe(
        ofType(
            LegacyWorksheetActionType.UpdateCurrentWorksheetColumns,
            LegacyWorksheetActionType.UpdateCurrentWorksheetQueries,
            LegacyWorksheetActionType.UpdateCurrentWorksheetSort
        ),
        withLatestFrom(this.store.select(selectLegacyWorksheetState)),
        exhaustMap(([, state]) => {
            const selectedWorksheet = state.entities[state.selectedWorksheetId];
            const compareWorksheet = selectedWorksheet
                ? new CreateWorksheetModel(selectedWorksheet.name, selectedWorksheet.queries, selectedWorksheet.columns, selectedWorksheet.sort, selectedWorksheet.worksheetType)
                : state.defaultWorksheet;

            if (state.fullLoadComplete) {
                this.getLocalStorage(state.worksheetTypeToShow).subscribe(() => this.setLocalStorage(state.worksheetTypeToShow, state.selectedWorksheetId, state.currentWorksheet));
            }

            const saveable = !WorksheetUtilities.areWorksheetsSame(state.currentWorksheet, compareWorksheet);

            return of(new UpdateCurrentWorksheetSavableAction({ savable: saveable }));
        })
    );

    @Effect()
    performGridSetup: Observable<Action> = this.actions$.pipe(
        ofType<PerformGridSetupAction>(LegacyWorksheetActionType.PerformGridSetup),
        withLatestFrom(this.store.select(selectLegacyWorksheetState)),
        exhaustMap(([, state]) =>
            this.getLocalStorage(state.worksheetTypeToShow).pipe(
                map((data) => new LocalStorageQueryPerformedAction({ worksheetId: data.selectedWorksheetId, currentWorksheet: data.currentWorksheet })),
                catchError(() => of(new LocalStorageQueryPerformedAction({ worksheetId: null, currentWorksheet: null })))
            )
        )
    );

    @Effect()
    selectWorksheet: Observable<Action> = this.actions$.pipe(
        ofType<SelectWorksheetAction>(LegacyWorksheetActionType.Select),
        withLatestFrom(this.store.select(selectLegacyWorksheetState)),
        exhaustMap(([action, state]) => {
            const id = action.payload.id;

            this.setLocalStorage(state.worksheetTypeToShow, id, state.entities[id]);
            return of(new SelectWorksheetSuccessAction({ id }));
        })
    );

    @Effect()
    deselectWorksheet: Observable<Action> = this.actions$.pipe(
        ofType<DeselectWorksheetAction>(LegacyWorksheetActionType.Deselect),
        withLatestFrom(this.store.select(selectLegacyWorksheetState)),
        exhaustMap(([, state]) => {
            this.setLocalStorage(state.worksheetTypeToShow, null, state.currentWorksheet);
            return of(new DeselectWorksheetSuccessAction());
        })
    );

    private getWorksheetNames(state: LegacyWorksheetState) {
        return Object.entries(state.entities)
            .filter(([id, worksheet]) => isNaN(Number(id)) && worksheet.worksheetType === state.worksheetTypeToShow)
            .map(([, worksheet]) => worksheet.name.toLowerCase());
    }

    private setLocalStorage(type: GridType, selectedId: string, current: CreateWorksheetModel) {
        this.localStorage.set(`worksheetType${type}`, new WorksheetLocalDataModel(selectedId, current));
    }

    private getLocalStorage(type: GridType) {
        return this.localStorage.get(`worksheetType${type}`).pipe(
            take(1),
            map((localData) => localData as WorksheetLocalDataModel)
        );
    }
}
