<div class="ops-search-title row no-gutters">
    Calculations
</div>

<div class="ops-search-bar row no-gutters">
    <ops-laytime-calculation-grid-search class="col"></ops-laytime-calculation-grid-search>
</div>

<div class="ops-grid-row">
    <div *ngIf="(gridRecordNumbers$ | async) as gridRecordNumbers; else noRecords" class="ops-results-summary">
        Showing {{ gridRecordNumbers.loaded }} of {{ gridRecordNumbers.total }} results
    </div>
    <ng-template #noRecords>
        <div class="ops-results-summary">0 results</div>
    </ng-template>
    <ag-grid-angular
        class="ag-theme-balham ops-grid"
        [columnDefs]="columnDefs"
        [columnTypes]="columnTypes"
        [rowHeight]="gridSettings.rowHeight"
        [getRowNodeId]="getRowNodeId"
        [colResizeDefault]="gridSettings.colResizeDefault"
        [suppressContextMenu]="true"
        [frameworkComponents]="gridSettings.frameworkComponents"
        [rowModelType]="gridSettings.rowModelType"
        [cacheBlockSize]="gridSettings.cacheBlockSize"
        [loadingOverlayComponent]="gridSettings.loadingOverlay"
        (gridReady)="onGridReady($event)"
        (sortChanged)="onSortChanged()"
        (dragStopped)="onColumnsChanged()"
        (columnPinned)="onColumnsChanged()"
        (columnVisible)="onColumnsChanged()"
        [gridOptions]="gridSettings.gridOptions"
        [modules]="gridSettings.modules"
        [blockLoadDebounceMillis]="gridSettings.blockLoadDebounceMillis"
    >
    </ag-grid-angular>
</div>
