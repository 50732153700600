<td class="specialised-cargo-container">
    <div class="row col-12 no-gutters details-container">
        <div class="location-etc-components">
            <div class="col-3 form-group details-container-item location-wrapper">
                <label>Load Location</label>
                <ops-location-autosuggest
                    [multiple]="false"
                    [ngrxFormControlState]="form.controls.loadLocation.controls.location"
                    opsNgrxWarningFormControl
                    [readonly]="readonly"
                    data-test-load-location
                >
                </ops-location-autosuggest>
                <ops-validation [form]="form.controls.loadLocation.controls.location"></ops-validation>
            </div>
            <div class="col-2 form-group details-container-item location-eta-wrapper">
                <label>ETA</label>
                <ops-verified-timestamp [verified]="loadEtaVerifiedAudit"> </ops-verified-timestamp>
                <ops-date-input
                    mode="range"
                    [enableTime]="true"
                    [timeZone]="loadLocationTimezone"
                    [ngrxFormControlState]="form.controls.loadLocation.controls.eta"
                    [ngrxValueConverter]="dateRangeValueConverter"
                    [startDate]="laycanToDate"
                    [readonly]="readonly"
                    date-test-load-eta
                >
                </ops-date-input>
                <ops-validation [form]="form.controls.loadLocation.controls.eta">
                    <ops-warning *ngIf="showLoadEtaWarning">
                        <span>Later than Laycan To</span>
                        <span class="has-icon icon--flag"></span>
                    </ops-warning>
                </ops-validation>
            </div>
            <div class="verify-eta-wrapper">
                <label></label>
                <div>
                    <ops-action-button
                        type="action"
                        icon="tag-tick"
                        tooltip="Verify ETA"
                        [disabled]="!loadEtaVerifyEnabled"
                        (click)="verifyEta.emit(loadDestinationId)"
                    >
                    </ops-action-button>
                </div>
            </div>
        </div>
        <div>
            <div class="col-3 form-group details-container-item location-wrapper">
                <label>Discharge Location</label>
                <ops-location-autosuggest
                    [multiple]="false"
                    [ngrxFormControlState]="form.controls.dischargeLocation.controls.location"
                    opsNgrxWarningFormControl
                    [readonly]="readonly"
                    data-test-discharge-location
                >
                </ops-location-autosuggest>
                <ops-validation [form]="form.controls.dischargeLocation.controls.location"></ops-validation>
            </div>

            <div class="col-2 form-group details-container-item location-eta-wrapper">
                <label>ETA</label>
                <ops-verified-timestamp [verified]="dischargeEtaVerifiedAudit"> </ops-verified-timestamp>
                <ops-date-input
                    mode="range"
                    [enableTime]="true"
                    [timeZone]="dischargeLocationTimezone"
                    [ngrxFormControlState]="form.controls.dischargeLocation.controls.eta"
                    [ngrxValueConverter]="dateRangeValueConverter"
                    [startDate]="dischargeEtaStartDate"
                    [readonly]="readonly"
                    date-test-discharge-eta
                >
                </ops-date-input>
                <ops-validation [form]="form.controls.dischargeLocation.controls.eta">
                    <ops-warning *ngIf="showDischargeEtaWarning">
                        <span>Later than Laycan To</span>
                        <span class="has-icon icon--flag"></span>
                    </ops-warning>
                </ops-validation>
            </div>
            <div class="verify-eta-wrapper">
                <label></label>
                <div>
                    <ops-action-button
                        type="action"
                        icon="tag-tick"
                        tooltip="Verify ETA"
                        [disabled]="!dischargeEtaVerifyEnabled"
                        (click)="verifyEta.emit(dischargeDestinationId)"
                    >
                    </ops-action-button>
                </div>
            </div>
        </div>
    </div>
</td>
