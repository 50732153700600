import { box, Boxed, createFormArrayState, FormArrayState, unbox } from "ngrx-forms";
import * as R from "ramda";
import { v4 as uuid } from "uuid";

import { Identity } from "@ops/shared";

import { getFlagImageUrl } from "../../../../coa/state";
import { QuantityModel } from "../../../../fixture/shared/models/form-models";
import { AllowanceUnit, Reversible } from "./laytime-calculation";
import { CargoId } from "./voyage";

export type CargoTermsId = Identity<string, "CargoTerms">;
export const createCargoTermId = (): CargoTermsId => uuid() as CargoTermsId;

export type LocationId = Identity<string, "LocationId">;
export const createLocationId = (): LocationId => uuid() as LocationId;

export type LaytimeCalculationCargoTerms = Readonly<{
    id: CargoTermsId;
    cargoId?: CargoId;
    productId: number;
    name: string;
    loadLocations: ReadonlyArray<LaytimeCalculationCargoTermsLocation>;
    dischargeLocations: ReadonlyArray<LaytimeCalculationCargoTermsLocation>;
}>;

export type LaytimeCalculationCargoTermsLocation = Readonly<{
    locationId: LocationId;
    allowance: string;
    allowanceUnit: AllowanceUnit;
    extraHours: string;
    reversible: Reversible;
    name: string;
    countryUnCode: string;
    countryName: string;
}>;

export type LaytimeCalculationCargoTermsListItem = Readonly<{
    id: CargoTermsId;
    cargoId: CargoId;
    productId: number;
    name: string;
    loadLocations: ReadonlyArray<LaytimeCalculationCargoTermsLocationListItem>;
    dischargeLocations: ReadonlyArray<LaytimeCalculationCargoTermsLocationListItem>;
}>;

export type LaytimeCalculationCargoTermsLocationListItem = Readonly<{
    locationId: LocationId;
    allowance: number;
    allowanceUnit: AllowanceUnit;
    extraHours: number;
    reversible: Reversible;
    name: string;
    countryUnCode: string;
    countryName: string;
    flagImgUrl: string;
}>;

export type LaytimeCalculationCargoTermsForm = Readonly<{
    loadLocations: FormArrayState<LaytimeCalculationCargoTermsLocationForm>;
    dischargeLocations: FormArrayState<LaytimeCalculationCargoTermsLocationForm>;
}>;

export type LaytimeCalculationCargoTermsLocationForm = Readonly<{
    locationId: LocationId;
    allowance: Boxed<QuantityModel>;
    extraHours: number;
    reversible: Reversible;
}>;

const laytimeCalculationCargoTermsLocationToListItem = (cdnUrl: string) => (source: LaytimeCalculationCargoTermsLocation): LaytimeCalculationCargoTermsLocationListItem => ({
    locationId: source.locationId,
    allowance: source.allowance && Number(source.allowance),
    allowanceUnit: source.allowanceUnit,
    extraHours: source.extraHours && Number(source.extraHours),
    reversible: source.reversible,
    name: source.name,
    countryUnCode: source.countryUnCode,
    countryName: source.countryName,
    flagImgUrl: getFlagImageUrl(cdnUrl, source.countryUnCode)
});

export const laytimeCalculationCargoTermsToListItem = (cdnUrl: string) => (source: LaytimeCalculationCargoTerms): LaytimeCalculationCargoTermsListItem => ({
    id: source.id,
    cargoId: source.cargoId,
    productId: source.productId,
    name: source.name,
    loadLocations: source.loadLocations.map(laytimeCalculationCargoTermsLocationToListItem(cdnUrl)),
    dischargeLocations: source.dischargeLocations.map(laytimeCalculationCargoTermsLocationToListItem(cdnUrl))
});

export const laytimeCalculationCargoTermsToForm = (source: LaytimeCalculationCargoTerms): LaytimeCalculationCargoTermsForm => ({
    loadLocations: createCargoTermsLocationFormsState(
        source.id,
        "load",
        source.loadLocations.map((l) => l)
    ),
    dischargeLocations: createCargoTermsLocationFormsState(
        source.id,
        "discharge",
        source.dischargeLocations.map((l) => l)
    )
});

export const laytimeCalculationCargoTermsLocationToForm = (source: LaytimeCalculationCargoTermsLocation): LaytimeCalculationCargoTermsLocationForm => ({
    locationId: source.locationId,
    allowance: box({ value: source.allowance && Number(source.allowance), unit: source.allowanceUnit }),
    extraHours: source.extraHours && Number(source.extraHours),
    reversible: source.reversible
});

export const formToLaytimeCalculationCargoTermsLocationDiff = (
    source: LaytimeCalculationCargoTermsLocationForm,
    original: LaytimeCalculationCargoTermsLocation
): Partial<LaytimeCalculationCargoTermsLocation> => {
    const allowance = unbox(source.allowance);
    const newChanges: Partial<LaytimeCalculationCargoTermsLocation> = {
        allowance: allowance.value && String(allowance.value),
        allowanceUnit: allowance.unit as AllowanceUnit,
        extraHours: source.extraHours && String(source.extraHours),
        reversible: source.reversible
    };
    const changedProperties = Object.getOwnPropertyNames(newChanges).filter((p: keyof LaytimeCalculationCargoTermsLocation) => newChanges[p] !== original[p]);
    return R.pick(changedProperties, newChanges);
};

export const formToLaytimeCalculationCargoTerms = (source: LaytimeCalculationCargoTermsForm, original: LaytimeCalculationCargoTerms): Partial<LaytimeCalculationCargoTerms> => {
    const mapForm = (form: LaytimeCalculationCargoTermsLocationForm, cargoTermsLocation: LaytimeCalculationCargoTermsLocation) => {
        const allowance = unbox(form.allowance);
        return {
            ...cargoTermsLocation,
            allowance: allowance.value && String(allowance.value),
            allowanceUnit: allowance.unit as AllowanceUnit,
            extraHours: form.extraHours && String(form.extraHours),
            reversible: form.reversible
        };
    };

    return {
        productId: original.productId,
        loadLocations: source.loadLocations.controls.map((l, i) => mapForm(l.value, original.loadLocations[i])),
        dischargeLocations: source.dischargeLocations.controls.map((l, i) => mapForm(l.value, original.dischargeLocations[i]))
    };
};

export const cargoTermsLocationFormKey = "cargoTermsLocationForms";
const createCargoTermsLocationFormsState = (cargoTermsId: CargoTermsId, locationsType: "load" | "discharge", cargoTermsLocations: LaytimeCalculationCargoTermsLocation[]) =>
    createFormArrayState(`${cargoTermsId}.${locationsType}.${cargoTermsLocationFormKey}`, cargoTermsLocations.map(laytimeCalculationCargoTermsLocationToForm));
