import { Component, EventEmitter, Input, Output } from "@angular/core";

import { BerthSummary } from "../../state";

@Component({
    selector: "ops-berth-header",
    templateUrl: "./berth-header.component.html",
    styleUrls: ["./berth-header.component.scss"]
})
export class BerthHeaderComponent {
    @Input() summary: BerthSummary;
    @Input() expanded: boolean;

    @Input() canOrder: boolean;
    @Input() canClone: boolean;
    @Input() canRemove: boolean;

    @Input() cloneEnabled: boolean;
    @Input() removeEnabled: boolean;
    @Input() moveUpEnabled: boolean;
    @Input() moveDownEnabled: boolean;
    @Input() showInvalid: boolean;

    @Output() readonly expand = new EventEmitter();
    @Output() readonly collapse = new EventEmitter();
    @Output() readonly moveUp = new EventEmitter();
    @Output() readonly moveDown = new EventEmitter();
    @Output() readonly clone = new EventEmitter();
    @Output() readonly remove = new EventEmitter();

    // TODO: (NGRX SB) BERTHS unit tests
    toggle() {
        if (this.expanded) {
            this.collapse.emit();
        } else {
            this.expand.emit();
        }
    }
}
