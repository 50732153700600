import { createAction, on, On, props } from "@ngrx/store";

import { FixtureId, FixturesState } from "../model";

// TODO: (JC NGRX) Unit tests

/* ACTIONS */

/**
 * @internal
 */
export const routerSetCurrentFixtureAction = createAction("[Router] Set Current Fixture", props<{ fixtureId: FixtureId }>());

/* REDUCERS */
export const routerSetCurrentFixtureReducer: On<FixturesState> = on(routerSetCurrentFixtureAction, (state, action) => {
    if (state.currentFixtureId !== action.fixtureId) {
        return {
            ...state,
            currentFixtureId: action.fixtureId
        };
    }

    return state;
});
