import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ConfirmationService } from "primeng/api";

import { FixtureLaytimeCalculation, LtcId } from "../../state";

@Component({
    selector: "ops-laytime-calculation-list-item",
    templateUrl: "./laytime-calculation-list-item.component.html",
    styleUrls: ["./laytime-calculation-list-item.component.scss"],
    providers: [ConfirmationService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationListItemComponent {
    @Input() calculation: FixtureLaytimeCalculation;

    @Output() readonly remove = new EventEmitter<LtcId>();

    constructor(private confirmationService: ConfirmationService) {}

    handleClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (target.tagName === "BUTTON" || target.parentElement.classList.contains("more-menu-item")) {
            // don't navigate to the ltc if the element clicked is the options menu, or a menu item
            event.stopPropagation();
            event.preventDefault();
        }
    }

    onDeleteButtonClick() {
        this.confirmationService.confirm({
            header: `Deleting ${this.calculation.name}`,
            message: `Are you sure you want to delete ${this.calculation.name}?`,
            acceptLabel: "OK",
            rejectLabel: "CANCEL",
            acceptIcon: "none",
            rejectIcon: "none",
            accept: () => this.remove.emit(this.calculation.id)
        });
    }
}
