<ng-container *ngIf="activityLocation$ | async; else noActivityLocation">
    <div class="ops-ltc-location-container" *ngIf="calculationResult$ | async as result">
        <div class="ops-summary-content-wrapper">
            <ops-laytime-calculation-activity-location-summary
                [calculationResult]="calculationResult$ | async"
                [durationUnit]="durationUnit$ | async"
                [currency]="currency$ | async"
                [isFixed]="isFixed$ | async"
                [hasNonReversibleCargoes]="activityLocationHasNonReversibleCargoes$ | async"
                [hasReversibleCargoes]="activityLocationHasReversibleCargoes$ | async"
            ></ops-laytime-calculation-activity-location-summary>
            <ops-laytime-calculation-activity-location-form
                *ngIf="form$ | async as form"
                [form]="form"
                [sector]="sector$ | async"
                [isAlwaysOnDemurrage]="isAlwaysOnDemurrage$ | async"
                [hasNonReversibleCargoes]="activityLocationHasNonReversibleCargoes$ | async"
                [isFixed]="isFixed$ | async"
                [laytimeEventRange]="laytimeEventRange$ | async"
                (prefillLaytimeRange)="prefillLaytimeRange($event)"
            ></ops-laytime-calculation-activity-location-form>
        </div>
        <div class="delete-activity-location-wrapper">
            <ops-icon-button type="delete" tooltip="Delete Location" tooltipContainer="body" (click)="onDeleteLocationButtonClick()"
                data-cy="remove-activity-location"> </ops-icon-button>
            <p-confirmDialog data-cy="activity-location-delete-confirmation-dialog"></p-confirmDialog>
        </div>
    </div>

    <ops-laytime-calculation-activity-location-cargoes
        *ngIf="showCargoes"
        [cargoes]="activityLocationCargoForms$ | async"
        [activityType]="activityLocationType$ | async"
        [sector]="sector$ | async"
        [durationUnit]="durationUnit$ | async"
        [isFixed]="isFixed$ | async"
        (add)="addCargo()"
        (remove)="removeCargo($event)"
        (importTerms)="importCargoTerms($event)"
    ></ops-laytime-calculation-activity-location-cargoes>

    <ops-laytime-calculation-laytime-events
        class="p-d-block p-mt-4"
        [laytimeEvents]="laytimeEvents$ | async"
        [timezone]="timezone$ | async"
        [firstLaytimeEventIndex]="firstLaytimeEventIndex$ | async"
        [lastLaytimeEventIndex]="lastLaytimeEventIndex$ | async"
        [cargoes]="laytimeEventCargoes$ | async"
        [laytimeEventsOrderingEnabled]="laytimeEventsOrderingEnabled$ | async"
        [durationUnit]="durationUnit$ | async"
        [isVoyageLoading]="isVoyageLoading$ | async"
        (addLaytimeEvent)="addLaytimeEvent($event)"
        (addDeduction)="addDeduction($event)"
        (remove)="removeLaytimeEvent($event)"
        (order)="orderLaytimeEvent()"
        (clear)="clearLaytimeEvents()"
        (importPortTimes)="importPortTimes()"
        (enterDeductions)="enterDeductions()"
    ></ops-laytime-calculation-laytime-events>
</ng-container>

<ng-template #noActivityLocation>
    <ops-no-data title="Location not found." description="The location does not exist." data-cy="location-not-found"></ops-no-data>
</ng-template>

