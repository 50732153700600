import { createSelector } from "@ngrx/store";
import { Duration } from "luxon";
import { FormArrayState, FormGroupState } from "ngrx-forms";
import * as R from "ramda";

import { DateRange } from "../../../../../fixture/shared/models";
import { IntervalPart } from "../../../../../fixture/state";
import { ActivityLocationResult } from "../../../../calculator";
import { ActivityLocation, LaytimeEvent, LaytimeEventForm, LaytimeEventId } from "../../../model";
import { selectCurrentFixedOrReversibleDemurrageLaytimeEventId, selectCurrentLaytimeCalculationSector, selectCurrentLaytimeCalculationState } from "../../selectors";
import { selectCurrentActivityLocation, selectCurrentActivityLocationResult } from "../selectors";
import { laytimeEventsSort } from "./form/order-laytime-events";

export type LaytimeEventState = Readonly<{
    id: LaytimeEventId;
    form: FormGroupState<LaytimeEventForm>;
    fromDate?: string;
    laytimeUsed?: Duration;
    laytimeAllowed?: Duration;
    cargoName?: string;
    intervalPart?: IntervalPart;
    /**
     * Indicates whether this laytime event is the first that causes a claim to incur ("vessel on demurrage").
     */
    isDemurrageEvent?: boolean;
    offsetDiffWithPrevEvent?: Duration;
}>;

export const selectCurrentLaytimeEventForms = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.laytimeEventForms);
export const selectCurrentIntervalPart = createSelector(selectCurrentLaytimeEventForms, selectCurrentActivityLocationResult, (forms, activityLocationResult) => {
    let intervalPartStart = -1;
    let intervalPartStop = -1;
    if (forms?.controls) {
        const eventIsCommencedArray: Array<boolean> = forms.controls.map(
            (form) => +form.value.percentage !== 0 && !!activityLocationResult.laytimeEvents?.find((x) => x.id === form.value.id)?.laytimeUsed
        );
        intervalPartStart = eventIsCommencedArray.findIndex((x) => x);
        intervalPartStop = eventIsCommencedArray.lastIndexOf(true);
    }
    return forms?.controls.map((form: FormGroupState<LaytimeEventForm>, i: number) => {
        const laytimeUsed = activityLocationResult.laytimeEvents?.find((x) => x.id === form.value.id)?.laytimeUsed;
        return getIntervalPart(i, intervalPartStart, intervalPartStop, form.value.percentage, laytimeUsed);
    });
});

export const selectCurrentLaytimeEvents = createSelector(
    selectCurrentLaytimeEventForms,
    selectCurrentActivityLocation,
    selectCurrentFixedOrReversibleDemurrageLaytimeEventId,
    selectCurrentActivityLocationResult,
    selectCurrentIntervalPart,
    (forms, activityLocation, demurrageLaytimeEventId, activityLocationResult, intervalPart) =>
        forms && activityLocation && activityLocationResult && toLaytimeEventStates(forms, activityLocation, demurrageLaytimeEventId, activityLocationResult, intervalPart)
);

export const selectCurrentLaytimeEventCargoes = createSelector(
    selectCurrentLaytimeCalculationSector,
    selectCurrentActivityLocation,
    (sector, activityLocation) => sector && activityLocation && ["Specialised Products", "PCG"].includes(sector) && activityLocation.cargoes
);

export const selectCurrentLaytimeEventsOrderingEnabled = createSelector(selectCurrentLaytimeEventForms, (forms) => forms && isLaytimeEventsOrderingEnabled(forms));

export const toLaytimeEventStates = (
    forms: FormArrayState<LaytimeEventForm>,
    activityLocation: ActivityLocation,
    fixedOrReversibleDemurrageLaytimeEventId: LaytimeEventId,
    activityLocationResult: ActivityLocationResult,
    intervalPart: Array<IntervalPart>
) =>
    forms?.controls.map((form: FormGroupState<LaytimeEventForm>, i: number) => {
        const laytimeEventId = form.value.id;
        const isDemurrageEventNonReversible = activityLocationResult.demurrageLaytimeEventId === laytimeEventId;
        const isDemurrageEventFixedOrReversible = fixedOrReversibleDemurrageLaytimeEventId === laytimeEventId;
        const activityLocationResultLaytimeEvent = activityLocationResult.laytimeEvents?.find((x) => x.id === form.value.id);

        return <LaytimeEventState>{
            id: form.value.id,
            form,
            fromDate: forms.controls[i - 1]?.value.date,
            cargoName: form.value.cargoId && activityLocation.cargoes?.find((c) => c.id === form.value.cargoId)?.name,
            laytimeUsed: activityLocationResultLaytimeEvent?.laytimeUsed,
            laytimeAllowed: activityLocationResultLaytimeEvent?.laytimeAllowed,
            offsetDiffWithPrevEvent: activityLocationResultLaytimeEvent?.offsetDiffWithPrevEvent,
            intervalPart: intervalPart[i],
            isDemurrageEvent: isDemurrageEventNonReversible || isDemurrageEventFixedOrReversible
        };
    });

export const getIntervalPart = (i: number, intervalPartStart: number, intervalPartStop: number, percentage: string, laytimeUsed: Duration): IntervalPart => {
    if (i === intervalPartStart) {
        return IntervalPart.Start;
    } else if (i === intervalPartStop) {
        return IntervalPart.Stop;
    } else if (i < intervalPartStart || i > intervalPartStop) {
        return IntervalPart.Outside;
    } else if (+percentage === 0) {
        return IntervalPart.ImplicitStop;
    } else if (laytimeUsed) {
        return IntervalPart.During;
    }
};

export const isLaytimeEventsOrderingEnabled = (laytimeEvents: FormArrayState<LaytimeEventForm>) =>
    laytimeEvents.isValid && laytimeEvents.value.length && !R.equals(laytimeEvents.value, R.sort(laytimeEventsSort, laytimeEvents.value));

export const getLaytimeEventRange = (laytimeEvents: ReadonlyArray<LaytimeEvent>): DateRange => {
    if (!R.equals(laytimeEvents, R.sort(laytimeEventsSort, laytimeEvents))) {
        return null;
    }

    let from: string = null;
    let to: string = null;

    laytimeEvents.forEach((le, index) => {
        if (index && (!le.percentage || +le.percentage > 0)) {
            if (!from && laytimeEvents[index - 1].date) {
                from = laytimeEvents[index - 1].date;
            }

            if (le.date) {
                to = le.date;
            }
        }
    });

    return from && to && to >= from && { from, to };
};

export const selectCurrentLaytimeEventRange = createSelector(selectCurrentActivityLocation, (state) => state && getLaytimeEventRange(state.laytimeEvents));
