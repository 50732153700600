import { createSelector } from "@ngrx/store";

import { selectCoasFeature } from "../coa.selectors";
import { CoaFeatureState, CoaId, CoasState, CoaState, getCurrentCoaId } from "../model";
import { getCoaSummary } from "./coa-summary";

export const selectCurrentCoaId = createSelector(selectCoasFeature, getCurrentCoaId);
export const selectCoaState = createSelector<CoaFeatureState, { coaId: CoaId }, CoasState, CoaState>(selectCoasFeature, (state: CoasState, { coaId }) =>
    coaId ? state.coas.byId[coaId] : null
);

export const selectCurrentCoaState = createSelector<CoaFeatureState, CoasState, CoaId, CoaState>(selectCoasFeature, selectCurrentCoaId, (state, coaId) =>
    coaId ? state.coas.byId[coaId] : null
);
export const selectCurrentCoa = createSelector(selectCurrentCoaState, (state) => state?.coa);
export const selectCurrentCoaSummary = createSelector(selectCurrentCoa, (coa) => (coa ? getCoaSummary(coa) : null));

export const selectCurrentCoaBusy = createSelector(selectCurrentCoaState, (state) => !state || state.loadStatus === "loading" || state.persistenceStatus === "persisting");

export const selectCurrentCoaLiftingCreating = createSelector(selectCurrentCoaState, (state) => state?.liftingCreationPending);

export const selectCurrentCoaSector = createSelector(selectCurrentCoa, (coa) => coa?.sector);

export const selectCurrentCoaIndexedLiftings = createSelector(selectCurrentCoaState, (state) => state?.indexedLiftings);

export const selectCurrentCoaIndexedLiftingsLoading = createSelector(selectCurrentCoaState, (state) => !state || state.indexedLiftingsLoadStatus === "loading");

export const selectCurrentCoaIndexedLiftingsLoadFailed = createSelector(selectCurrentCoaState, (state) => !state || state.indexedLiftingsLoadStatus === "failed");

export const selectCurrentCoaDriver = createSelector(selectCurrentCoa, (state) => state?.driver);
