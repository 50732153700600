import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { AppConfigService } from "@ops/core";
import { Currency, DayOfWeek, IndexResponse, isNullOrUndefined, roundAndStringifyNumber, Sector } from "@ops/shared";
import { LaytimeType } from "@ops/shared/reference-data";
import { SearchToken, Sorting, Suggestion, SuggestionTerm, toSearchToken } from "@ops/state";

import { Fixture } from "../../fixture/shared/models";
import { FixtureId } from "../../fixture/state";
import {
    ActivityCargo,
    ActivityCargoId,
    ActivityLocation,
    ActivityLocationId,
    ActivityType,
    AllowanceUnit,
    LaytimeCalculation,
    LaytimeCalculationIndex,
    LaytimeCalculationRounding,
    LaytimeCalculationTerms,
    LaytimeCalculationDurationUnit,
    LtcId,
    OnceOnDemurrage,
    QuantityUnit,
    Reversible,
    TimeAllowance,
    TimeSaved,
    User,
    VoyageActivityId,
    LaytimeEventType,
    LaytimeEventRemark,
    LaytimeEventId,
    CargoTermsId,
    LaytimeCalculationCargoTerms,
    LaytimeEvent,
    WorkingDay,
    CargoId
} from "../state";
import { ExportFormat } from "../state/calculations/export/calculation-export";
import { LTC_PRECISION, toHours } from "../state/utils";

export type CreateLaytimeCalculation = Readonly<{
    id: LtcId;
    tenantId: number;
    configurationRoles: ReadonlyArray<string>;
    name: string;
    sector: Sector;
    currency: Currency;
    fixtureId: FixtureId;
    timeAllowance: TimeAllowance;
    fixedAllowanceHours?: string;
    durationUnit?: LaytimeCalculationDurationUnit;
    rounding?: LaytimeCalculationRounding;
    timeSaved?: TimeSaved;
    onceOnDemurrage?: OnceOnDemurrage;
    demurrageBank?: boolean;
    demurrageRate?: string;
    detentionRate?: string;
    despatchRate?: string;
}>;

export type CloneLaytimeCalculation = Readonly<{
    cloneId: LtcId;
}>;

/*
    Recalculates rate based on duration unit, taking into account in Fixture rates are always kept in days
 */
const recalculateRate = (value: string, unit: LaytimeCalculationDurationUnit) => (unit === "Days" ? value : toHours(value));

export const toCreateLaytimeCalculation = (id: LtcId, fixture: Fixture): CreateLaytimeCalculation => {
    const durationUnit = fixture.laytimeUnit?.name as LaytimeCalculationDurationUnit;
    return {
        id,
        tenantId: fixture.tenantId,
        configurationRoles: fixture.configurationRoles,
        name: null,
        sector: <Sector>fixture.division.name,
        currency: <Currency>fixture.currency.code,
        fixtureId: fixture.fixtureId as FixtureId,
        timeAllowance: fixture.laytimeType?.id === LaytimeType.Fixed.id ? "Fixed" : "Non Fixed",
        fixedAllowanceHours: roundAndStringifyNumber(fixture.fixedLaytime, LTC_PRECISION),
        durationUnit,
        rounding: fixture.laytimeRounding?.name as LaytimeCalculationRounding,
        timeSaved: (fixture.timeSavedType?.name as TimeSaved) ?? "All Time Saved",
        onceOnDemurrage: isNullOrUndefined(fixture.isOnDemurrage) ? null : fixture.isOnDemurrage ? "Always On Demurrage" : "Not Always On Demurrage",
        demurrageBank: fixture.demurrageBankEnabled,
        demurrageRate: recalculateRate(roundAndStringifyNumber(fixture.demurrage?.rate, LTC_PRECISION), durationUnit),
        detentionRate: recalculateRate(roundAndStringifyNumber(fixture.detentionRate, LTC_PRECISION), durationUnit),
        despatchRate: recalculateRate(roundAndStringifyNumber(fixture.despatchRate, LTC_PRECISION), durationUnit)
    };
};

export const toLaytimeCalculation = (createLaytimeCalculation: CreateLaytimeCalculation, user: User): LaytimeCalculation => ({
    ...createLaytimeCalculation,
    lastUpdatedAt: DateTime.utc().toISO(),
    lastUpdatedBy: user,
    comments: null,
    activityLocations: [],
    cargoTerms: []
});

export type AddActivityCargo = Readonly<{
    id: ActivityCargoId;
    cargoId: CargoId;
    productId: number;
    allowance?: string;
    allowanceUnit?: AllowanceUnit;
    extraHours?: string;
    reversible?: Reversible;
    quantity?: string;
    quantityUnit: QuantityUnit;
    orderId?: string;
}>;

export type AddLaytimeEvent = Readonly<{
    id: LaytimeEventId;
    type?: LaytimeEventType;
    date?: string;
    percentage?: string;
    cargoId?: ActivityCargoId;
    remarks?: LaytimeEventRemark;
    comments?: string;
}>;

export type AddActivityLocation = Readonly<{
    id: ActivityLocationId;
    locationId: string;
    name: string;
    activity: ActivityType;
    voyageActivityId: VoyageActivityId;
    workingDay?: WorkingDay;
    exclusionStartDay?: DayOfWeek;
    exclusionStartTime?: string;
    exclusionEndDay?: DayOfWeek;
    exclusionEndTime?: string;
    cargoes?: ReadonlyArray<AddActivityCargo>;
    laytimeEvents?: ReadonlyArray<AddLaytimeEvent>;
}>;

@Injectable({
    providedIn: "root"
})
export class LaytimeCalculationHttpService {
    private readonly ltcServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.ltcServiceUrl = appConfigService.config.ltcServiceUrl;
    }

    get(ltcId: LtcId): Observable<LaytimeCalculation> {
        return this.httpClient.get<LaytimeCalculation>(`${this.ltcServiceUrl}/v3/ltc/${ltcId}`);
    }

    updateTerms(ltcId: LtcId, terms: LaytimeCalculationTerms): Observable<unknown> {
        return this.httpClient.patch(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/terms`, terms);
    }

    updateActivityLocation(ltcId: LtcId, activityLocationId: ActivityLocationId, activityLocation: Partial<ActivityLocation>): Observable<unknown> {
        return this.httpClient.patch(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/activity-locations/${activityLocationId}`, activityLocation);
    }

    addActivityCargo(ltcId: LtcId, activityLocationId: ActivityLocationId, activityCargo: ActivityCargo): Observable<unknown> {
        return this.httpClient.put(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/activity-locations/${activityLocationId}/cargoes`, [activityCargo]);
    }

    updateActivityCargo(ltcId: LtcId, activityLocationId: ActivityLocationId, activityCargoId: ActivityCargoId, activityCargo: Partial<ActivityCargo>): Observable<unknown> {
        return this.httpClient.patch(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/activity-locations/${activityLocationId}/cargoes/${activityCargoId}`, activityCargo);
    }

    removeActivityCargo(ltcId: LtcId, activityLocationId: ActivityLocationId, activityCargoId: ActivityCargoId): Observable<unknown> {
        return this.httpClient.delete(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/activity-locations/${activityLocationId}/cargoes/${activityCargoId}`);
    }

    addLaytimeEvent(ltcId: LtcId, activityLocationId: ActivityLocationId, laytimeEvent: LaytimeEvent, index?: number): Observable<unknown> {
        return this.httpClient.put(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/activity-locations/${activityLocationId}/laytime-events`, { ...laytimeEvent, index });
    }

    addLaytimeEvents(ltcId: LtcId, activityLocationId: ActivityLocationId, laytimeEvents: ReadonlyArray<LaytimeEvent>): Observable<unknown> {
        return this.httpClient.put(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/activity-locations/${activityLocationId}/laytime-events`, [...laytimeEvents]);
    }

    orderLaytimeEvents(ltcId: LtcId, activityLocationId: ActivityLocationId): Observable<unknown> {
        return this.httpClient.post(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/activity-locations/${activityLocationId}/laytime-events/sort`, {});
    }

    updateLaytimeEvent(ltcId: LtcId, activityLocationId: ActivityLocationId, laytimeEventId: LaytimeEventId, laytimeEvent: Partial<LaytimeEvent>): Observable<unknown> {
        return this.httpClient.patch(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/activity-locations/${activityLocationId}/laytime-events/${laytimeEventId}`, laytimeEvent);
    }

    removeLaytimeEvent(ltcId: LtcId, activityLocationId: ActivityLocationId, laytimeEventId: LaytimeEventId): Observable<unknown> {
        return this.httpClient.delete(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/activity-locations/${activityLocationId}/laytime-events/${laytimeEventId}`);
    }

    clearLaytimeEvents(ltcId: LtcId, activityLocationId: ActivityLocationId): Observable<unknown> {
        return this.httpClient.delete(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/activity-locations/${activityLocationId}/laytime-events`);
    }

    upsertCargoTerms(ltcId: LtcId, cargoTermsId: CargoTermsId, cargoTerms: Partial<LaytimeCalculationCargoTerms>): Observable<unknown> {
        return this.httpClient.put(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/terms/cargoes/${cargoTermsId}`, cargoTerms);
    }

    updateLaytimeCalculationName(ltcId: LtcId, name: string): Observable<unknown> {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        return this.httpClient.put(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/name`, JSON.stringify(name), { headers });
    }

    removeCargoTerms(ltcId: LtcId, cargoTermsId: CargoTermsId): Observable<unknown> {
        return this.httpClient.delete(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/terms/cargoes/${cargoTermsId}`);
    }

    createLaytimeCalculation(createLaytimeCalculation: CreateLaytimeCalculation): Observable<unknown> {
        return this.httpClient.post(`${this.ltcServiceUrl}/v3/ltc`, createLaytimeCalculation);
    }

    cloneLaytimeCalculation(sourceId: LtcId, cloneId: LtcId): Observable<unknown> {
        return this.httpClient.post(`${this.ltcServiceUrl}/v3/ltc/${sourceId}/clone`, <CloneLaytimeCalculation>{ cloneId });
    }

    removeLaytimeCalculation(ltcId: LtcId): Observable<unknown> {
        return this.httpClient.delete(`${this.ltcServiceUrl}/v3/ltc/${ltcId}`);
    }

    addActivityLocations(ltcId: LtcId, addActivityLocations: ReadonlyArray<AddActivityLocation>): Observable<unknown> {
        return this.httpClient.post(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/activity-locations`, addActivityLocations);
    }

    removeActivityLocation(ltcId: LtcId, activityLocationId: ActivityLocationId): Observable<unknown> {
        return this.httpClient.delete(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/activity-locations/${activityLocationId}`);
    }

    exportCalculation(ltcId: LtcId, format: ExportFormat): Observable<Blob> {
        return this.httpClient.get(`${this.ltcServiceUrl}/v3/ltc/${ltcId}/export/${format}`, { responseType: "blob" });
    }

    searchCalculationsForFixture(fixtureId: FixtureId): Observable<ReadonlyArray<LaytimeCalculationIndex>> {
        return this.searchCalculations([toSearchToken("Fixture-Id", fixtureId)]).pipe(map((response) => response.documents));
    }

    searchCalculations(criteria: ReadonlyArray<SearchToken>, skip?: number, take?: number, sorting?: Sorting): Observable<IndexResponse<LaytimeCalculationIndex>> {
        let filterQuery = "";
        if (criteria?.length > 0) {
            filterQuery = criteria.map((token) => `q=${encodeURIComponent(token)}`).join("&") + "&";
        }

        let sortingQuery = "";
        if (sorting?.column) {
            sortingQuery = `&sort=${sorting.order === "desc" ? "-" : ""}${sorting.column}`;
        }

        const url = `${this.ltcServiceUrl}/v3/ltc/search?${filterQuery}skip=${skip ?? 0}&take=${take ?? 100}${sortingQuery}`;
        return this.httpClient.get<IndexResponse<LaytimeCalculationIndex>>(url);
    }

    getCalculationSuggestions(suggestionTerm: SuggestionTerm): Observable<ReadonlyArray<Suggestion>> {
        return !suggestionTerm ? of([]) : this.httpClient.get<ReadonlyArray<Suggestion>>(`${this.ltcServiceUrl}/v3/ltc/suggestions?q=${suggestionTerm}`);
    }
}
