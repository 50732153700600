<div class="header row no-gutters">
    <div class="col align-self-center title">{{ title }}</div>
    <button *ngIf="actionToEdit.actionId" type="button" (click)="delete()" class="btn-delete has-icon icon--delete" data-test-delete></button>
</div>

<form [formGroup]="actionForm" [ngClass]="{ 'invalid-on-save': isInvalidOnSave }" autocomplete="off" data-test-new-action>
    <div class="row no-gutters">
        <div class="form-group col required">
            <label for="summary">Summary</label>
            <input id="summary" class="form-control" type="text" formControlName="summary" opsFocus data-test-summary />
            <ops-validation [form]="actionForm.get('summary')" [showBeforeTouch]="false"></ops-validation>
        </div>
    </div>

    <div class="row no-gutters">
        <div class="form-group col required">
            <label for="priority">Priority</label>
            <ng-select
                class="ops-select"
                [items]="priorities"
                [searchable]="false"
                formControlName="priority"
                [clearable]="false"
                bindLabel="name"
                data-test-priority
                [selectOnTab]="true"
            >
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    <div [ngClass]="item.name.toLowerCase()" class="priority">
                        {{ item.name }}
                    </div>
                </ng-template>
            </ng-select>
        </div>

        <div class="form-group col required">
            <label for="dueDate">Due Date</label>
            <ops-date-input
                formControlName="dueDate"
                [enableTime]="true"
                timeZone="local"
                container="body"
                [defaultTime]="defaultSelectTime"
                data-test-due-date
            ></ops-date-input>
            <ops-validation [form]="actionForm.get('dueDate')" [showBeforeTouch]="false"></ops-validation>
        </div>
    </div>

    <div class="row no-gutters">
        <div class="form-group col">
            <label for="description">Description</label>
            <textarea id="description" class="form-control" type="text" rows="6" formControlName="description" data-test-description></textarea>
        </div>
    </div>

    <div class="row no-gutters">
        <div class="form-group col required">
            <label>Assigned To ({{ assignedToCount }})</label>
            <ops-user-autosuggest
                [multiple]="true"
                [fixtureId]="fixtureId"
                [fixtureSource]="fixtureSource"
                formControlName="assignedTo"
                data-test-assigned-to
                [lead]="actionForm.controls['assignedTo'].value[0]"
            ></ops-user-autosuggest>
            <ops-validation [form]="actionForm.get('assignedTo')" [showBeforeTouch]="false"></ops-validation>
        </div>
    </div>

    <div class="notifications">
        <div class="notification-header">
            <button
                type="button"
                (click)="toggleNotifications()"
                class="has-icon"
                data-test-toggle-notification
                [ngClass]="{ 'icon--arrow-drop-down': isNotificationsExpanded, 'icon--arrow-drop-right': !isNotificationsExpanded }"
            ></button>
            <span>Notification Date ({{ notificationDateCount }})</span>
        </div>

        <div *ngIf="isNotificationsExpanded" class="notification-body">
            <div *ngIf="notificationDateCount > 0; else noNotifications" class="col p-0">
                <div formArrayName="notificationDates" *ngFor="let notificationDates of actionForm.get('notificationDates').controls; let i = index">
                    <div [formGroupName]="i" class="row no-gutters notification-date">
                        <ops-date-input
                            formControlName="date"
                            [enableTime]="true"
                            [defaultTime]="defaultSelectTime"
                            [defaultFocusDate]="getDefaultFocusDate(i)"
                            timeZone="local"
                            container="body"
                            data-test-notification-date
                            class="col-6"
                            [ngbPopover]="notificationDateContent"
                            #pt="ngbPopover"
                            triggers="manual"
                            (blur)="pt.close()"
                            (focus)="pt.open()"
                        >
                        </ops-date-input>
                        <ng-template #notificationDateContent>
                            <div
                                *ngIf="notificationDates.controls.date.hasError('required') && notificationDates.controls.date.touched"
                                class="row no-gutters display"
                            >
                                <span class="has-icon icon--warning col-1"></span>
                                <span class="content col-11" data-test-period-to-date-required> <b>Notification date</b> date is required.</span>
                            </div>
                        </ng-template>
                        <button type="button" class="btn-delete has-icon icon--delete m-0" (click)="deleteNotification(i)"
                            data-test-delete-notification-date></button>
                    </div>
                </div>

                <div class="row no-gutters">
                    <button type="button" (click)="addNotification()" class="btn btn-link" data-test-add-notification-date>
                        <span>Add new notification</span>
                    </button>
                </div>

                <div class="row no-gutters">
                    <div class="form-group col required">
                        <label>Notification Recipients ({{ recipientCount }})</label>
                        <ops-user-autosuggest
                            dropdownPosition="top"
                            [multiple]="true"
                            [returnAll]="true"
                            [fixtureId]="fixtureId"
                            [fixtureSource]="fixtureSource"
                            formControlName="notificationRecipients"
                            data-test-recipients
                        ></ops-user-autosuggest>
                        <ops-validation [form]="actionForm.get('notificationRecipients')" [showBeforeTouch]="false"></ops-validation>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #noNotifications>
            <ops-no-data-panel
                title="No notifications have been added to this action"
                description="To create a notification, click the button"
                [useNewButton]="true"
                newButtonText="NOTIFICATION"
                (addNew)="addNotification()"
            >
            </ops-no-data-panel>
        </ng-template>
    </div>
</form>

<div class="row no-gutters buttons">
    <div class="col d-flex justify-content-start p-0">
        <button type="button" (click)="cancel()" class="btn btn-link m-0" data-test-cancel>CANCEL</button>
    </div>
    <div class="col d-flex justify-content-end p-0">
        <button type="button" (click)="save()" class="btn btn-primary m-0" data-test-save>SAVE ACTION</button>
    </div>
</div>
