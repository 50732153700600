import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { WorksheetHttpService } from "../../left-bar/worksheets/service/worksheet-http.service";
import { WorksheetLocalStorageService } from "../../left-bar/worksheets/service/worksheet-local-storage.service";
import { renameSavedWorksheetEffect$ } from "./form/list-rename";
import { deleteWorksheetEffect$ } from "./functionality/delete-worksheet";
import { getAllWorksheetsEffect$ } from "./functionality/load-worksheets";
import { saveNewWorksheetEffect$, updateWorksheetEffect$ } from "./functionality/save-worksheets";
import { getInitialCurrentWorksheetEffect$, setLocalStorageInitialWorksheetEffect$, setLocalStorageWorksheetEffect$ } from "./functionality/set-worksheets";
import { WorksheetFeatureState } from "./state";

@Injectable()
export class WorksheetEffects {
    getAllWorksheets$ = getAllWorksheetsEffect$(this.actions$, this.store, this.worksheetHttpService);

    getInitialCurrentWorksheet$ = getInitialCurrentWorksheetEffect$(this.actions$, this.store, this.localStorage);

    setLocalStorageInitialWorksheet$ = setLocalStorageInitialWorksheetEffect$(this.actions$, this.localStorage);

    setLocalStorageWorksheet$ = setLocalStorageWorksheetEffect$(this.actions$, this.localStorage);

    saveNewWorksheet$ = saveNewWorksheetEffect$(this.actions$, this.store, this.worksheetHttpService);

    updateWorksheet$ = updateWorksheetEffect$(this.actions$, this.store, this.worksheetHttpService);

    deleteWorksheet$ = deleteWorksheetEffect$(this.actions$, this.worksheetHttpService);

    renameSavedWorksheet$ = renameSavedWorksheetEffect$(this.actions$, this.store, this.worksheetHttpService);

    constructor(
        private actions$: Actions,
        private store: Store<WorksheetFeatureState>,
        private worksheetHttpService: WorksheetHttpService,
        private localStorage: WorksheetLocalStorageService
    ) {}
}
