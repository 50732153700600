import { Injectable } from "@angular/core";

import { FixtureStatus, FixtureStatusType } from "./models";

@Injectable()
export class FixtureStatusService {
    getFixtureStatuses(): FixtureStatus[] {
        return [
            {
                type: FixtureStatusType.Open,
                name: "Open",
                displayOrder: 0
            },
            {
                type: FixtureStatusType.VoyageComplete,
                name: "Voyage Complete",
                displayOrder: 1
            },
            {
                type: FixtureStatusType.OpsClosed,
                name: "Ops Closed",
                displayOrder: 2
            },
            {
                type: FixtureStatusType.PendingCommission,
                name: "Pending Commission",
                displayOrder: 3
            },
            {
                type: FixtureStatusType.Closed,
                name: "Closed",
                displayOrder: 4
            },
            {
                type: FixtureStatusType.Cancelled,
                name: "Cancelled",
                displayOrder: 5
            }
        ];
    }
}
