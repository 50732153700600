<div *ngIf="(filterOptions$ | async) as filterOptions" class="lifting-history-filter">
    <div ngbDropdown role="group" placement="bottom-right" class="p-0" autoClose="outside">
        <button
            [ngClass]="(isAnyFilterOptionSelected$ | async) ? 'has-icon icon--filter float-right' : 'has-icon icon--empty-filter float-right'"
            ngbDropdownToggle [ngbTooltip]="filterTooltip$ | async" container="body" placement="left"></button>
        <div class="dropdown-menu rounded-0 p-0 m-0" ngbDropdownMenu>
            <div class="form-group d-flex dropdown-item m-0">
                <input type="checkbox" name="all" [ngModel]="filterOptions.all" />
                <span>All</span>
                <div class="w-100 h-100 mask" (click)="toggleAll()"></div>
            </div>
            <div class="form-group d-flex dropdown-item m-0">
                <input type="checkbox" name="active" [ngModel]="filterOptions.laycan" />
                <span>Laycan</span>
                <div class="w-100 h-100 mask" (click)="toggleLaycan()"></div>
            </div>
            <div class="form-group d-flex dropdown-item m-0">
                <input type="checkbox" name="dismissed" [ngModel]="filterOptions.cargoNomination" />
                <span>Cargo Nomination</span>
                <div class="w-100 h-100 mask" (click)="toggleCargoNomination()"></div>
            </div>
            <div class="form-group d-flex dropdown-item m-0">
                <input type="checkbox" name="dismissed" [ngModel]="filterOptions.vesselNomination" />
                <span>Vessel Nomination</span>
                <div class="w-100 h-100 mask" (click)="toggleVesselNomination()"></div>
            </div>
        </div>
    </div>
</div>