import { reduceObjectMap } from "../forms-reducer";
import { WorksheetId } from "../model";
import { getSelectedWorksheetId, WorksheetsState, WorksheetState } from "./state";

export const worksheetStateReducer = (
    state: WorksheetsState,
    worksheetId: WorksheetId,
    partialOrReducer: Partial<WorksheetState> | ((objectState: WorksheetState) => WorksheetState)
) => reduceObjectMap(state, (s) => s.worksheets, worksheetId, partialOrReducer);

export const selectedWorksheetStateReducer = (state: WorksheetsState, partialOrReducer: Partial<WorksheetState> | ((objectState: WorksheetState) => WorksheetState)) =>
    worksheetStateReducer(state, getSelectedWorksheetId(state), partialOrReducer);
