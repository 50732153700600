export enum DemurrageClaimType {
    FullClaim = 1,
    Demurrage = 2,
    Despatch = 3,
    Detention = 4,
    OnAccount = 5,
    LoadPort = 6,
    DisPort = 7,
    BalanceDemurrage = 8,
    CreditDemurrage = 9,
    NilDemurrage = 10
}

export const DemurrageClaimTypeName = new Map<number, string>([
    [DemurrageClaimType.FullClaim, "Full Claim"],
    [DemurrageClaimType.Demurrage, "Demurrage"],
    [DemurrageClaimType.Despatch, "Despatch"],
    [DemurrageClaimType.Detention, "Detention"],
    [DemurrageClaimType.OnAccount, "OnAccount"]
]);
