import * as R from "ramda";
import { v4 as uuid } from "uuid";

import { Identity, isNullOrUndefined } from "@ops/shared";
import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { Berth, Destination, Division, FixtureType } from "../../shared/models";
import { activityForm, ActivityForm, activityToForm, createActivityId, formToActivity } from "./activity";
import { LaytimeEventForm } from "./laytime-event";

export type BerthId = Identity<string, "BerthId">;
export const createBerthId = (): BerthId => uuid() as BerthId;

export type BerthForm = Readonly<{
    id: BerthId;
    berthId: number;
    activities: ReadonlyArray<ActivityForm>;
    name?: string;
    etb?: string;
}>;

export const berthForm = (division: Division, id: BerthId, berthId: number): BerthForm => ({
    id,
    berthId,
    etb: null,
    name: null,
    activities: [activityForm(division, createActivityId(), 1)]
});

export const berthToForm = (fixtureType: FixtureType, division: Division, destination: Destination) => (source: Berth): BerthForm => ({
    id: source.id,
    berthId: source.berthId,
    name: source.name,
    etb: source.etb,
    activities: source.cargoBerthActivities.map(activityToForm(fixtureType, division, destination, source))
});

export const formToBerth = (source: BerthForm, current: Berth): Berth => ({
    ...current,
    id: source.id,
    berthId: source.berthId,
    name: source.name,
    etb: source.etb,
    cargoBerthActivities: source.activities.map((activity) => formToActivity(activity, current ? current.cargoBerthActivities.find((x) => x.id === activity.activityId) : null))
});

/**
 * Returns true if the berth has activities of the specified type(s)
 */
export const berthHasActivityTypes = (berth: BerthForm, ...types: ReadonlyArray<CargoBerthActivityType>): boolean =>
    berth.activities.map((a) => a.type).some((type) => type && type.value && types.some((t) => type.value.id === t.id));

/**
 * Returns true if the berth has one or more laytime events with the event date set.
 */
export const berthLastLaytimeEvent = (berth: BerthForm): LaytimeEventForm =>
    R.pipe(
        R.last,
        R.prop("laytimeEvents"),
        R.ifElse(isNullOrUndefined, () => null, R.last)
    )(berth.activities);
