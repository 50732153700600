import { addVoyageFailReducer, addVoyageReducer, addVoyageSuccessReducer } from "./form/add-voyage";
import {
    saveVoyageFailReducer,
    updateVoyagePersistenceStatusReducer,
    saveVoyageSuccessReducer,
    sortDestinationsReducer,
    sortBerthsReducer,
    sortLaytimeEventsReducer
} from "./form/save-voyage";
import { interopVoyageReducer } from "./interop";
import { routerSetCurrentVoyageReducer } from "./routing";

export default [
    addVoyageFailReducer,
    addVoyageReducer,
    addVoyageSuccessReducer,
    interopVoyageReducer,
    routerSetCurrentVoyageReducer,
    saveVoyageFailReducer,
    updateVoyagePersistenceStatusReducer,
    sortDestinationsReducer,
    sortBerthsReducer,
    sortLaytimeEventsReducer,
    saveVoyageSuccessReducer
];
