import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { ChangeReason, ResponsibleForChange, responsibleForChange } from "../../../state/model";

@Component({
    selector: "ops-change-reason",
    templateUrl: "./change-reason.component.html",
    styleUrls: ["./change-reason.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeReasonComponent {
    readonly responsibleForChange = responsibleForChange;

    reason: string;
    responsible: ResponsibleForChange = "Charterer";

    constructor(public dialogRef: MatDialogRef<ChangeReasonComponent, ChangeReason>, @Inject(MAT_DIALOG_DATA) public title: string) {}

    submit() {
        this.dialogRef.close({ reason: this.reason, responsibleForChange: this.responsible });
    }
}
