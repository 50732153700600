<div class="berth-header-section form-row m-0">
    <div class="form-group col-3">
        <label>Berth</label>
        <input type="text" class="form-control" [ngrxFormControlState]="form.controls.name" ngrxUpdateOn="blur" [readonly]="readonly" data-test-berth />
    </div>
    <div class="form-group col-2">
        <label>ETB</label>
        <ops-verified-timestamp [verified]="etbVerifiedAudit"> </ops-verified-timestamp>
        <ops-date-input
            [enableTime]="true"
            [timeZone]="locationTimezone"
            [ngrxFormControlState]="form.controls.etb"
            [readonly]="readonly"
            [defaultFocusDate]="etbDefaultFocusDate"
            [ngrxValueConverter]="dateValueConverter"
            data-test-etb
        ></ops-date-input>
    </div>
    <div>
        <label></label>
        <div>
            <ops-action-button
                type="action"
                icon="tag-tick"
                tooltip="Verify ETB"
                [disabled]="!etbVerifyEnabled"
                (click)="verifyEtb.emit()">
            </ops-action-button>
        </div>
    </div>
    <div class="col"></div>
    <div class="laytime-email col-5" *opsFixtureType="'Voyage'">
        <div>
            <ops-action-button icon="file-email" (click)="generatePortTimesEmail.emit()">PORT TIMES EMAIL</ops-action-button>
        </div>
        <span *ngIf="portTimesEmailAudit"
            >Last Generated: {{ portTimesEmailAudit.date | timezoneDate: "DATETIME" }} by {{ portTimesEmailAudit.user?.fullName }}</span
        >
    </div>
</div>
