// adapted from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
export function deepFreeze<T>(obj: T): T {
    const properties = Object.getOwnPropertyNames(obj);

    properties.forEach(function(name) {
        const property = (obj as any)[name];

        if (typeof property === "object" && property !== null) {
            deepFreeze(property);
        }
    });

    return Object.freeze(obj);
}
