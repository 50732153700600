export enum LegacyWorksheetActionType {
    GetAll = "[Legacy Worksheets] Get All",
    GetAllSuccess = "[Legacy Worksheets] Get All Success",
    GetAllFail = "[Legacy Worksheets] Get All Fail",
    Save = "[Legacy Worksheets] Save",
    SaveSuccess = "[Legacy Worksheets] SaveSuccess",
    SaveFail = "[Legacy Worksheets] Save Fail",
    Update = "[Legacy Worksheets] Update",
    UpdateSuccess = "[Legacy Worksheets] Update Success",
    UpdateFail = "[Legacy Worksheets] Update Fail",
    Remove = "[Legacy Worksheets] Remove",
    RemoveSuccess = "[Legacy Worksheets] Remove Success",
    RemoveFail = "[Legacy Worksheets] Remove Fail",
    Rename = "[Legacy Worksheets] Rename",
    RenameSuccess = "[Legacy Worksheets] Rename Success",
    RenameFail = "[Legacy Worksheets] Rename Fail",
    Select = "[Legacy Worksheets] Select",
    SelectSuccess = "[Legacy Worksheets] Select Success",
    Deselect = "[Legacy Worksheets] Deselect",
    DeselectSuccess = "[Legacy Worksheets] Deselect Success",
    SetDefaultWorksheetGridOptions = "[Legacy Worksheets] Set Default Worksheet Grid Options",
    SetDefaultWorksheetQueries = "[Legacy Worksheets] Set Default Worksheet Queries",
    UpdateCurrentWorksheetColumns = "[Legacy Worksheets] Update Current Worksheet Columns",
    UpdateCurrentWorksheetSort = "[Legacy Worksheets] Update Current Worksheet Sort",
    UpdateCurrentWorksheetQueries = "[Legacy Worksheets] Update Current Worksheet Queries",
    UpdateCurrentWorksheetSaveable = "[Legacy Worksheets] Update Current Worksheet Saveable",
    SetRename = "[Legacy Worksheets] Set Rename",
    CancelRename = "[Legacy Worksheets] Cancel Rename",
    RemoveErroredWorksheet = "[Legacy Worksheets] Remove Errored Worksheet",
    PerformGridSetup = "[Legacy Worksheets] Perform Grid Setup",
    LocalStorageQueryPerformed = "[Legacy Worksheets] Local Storage Query Performed",
    EditedSavedWorksheetLoadCompleted = "[Legacy Worksheets] Edited Saved Worksheet Load Completed"
}
