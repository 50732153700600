<div class="card embedded-card-1">
    <div class="card-header row no-gutters align-items-center">
        {{ title }}
    </div>
    <div [formGroup]="parentForm" class="bunker-consumption">
        <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let column of (columnDefs$ | async)" [class]="column.class">{{ column.headerName }}</th>
                    <th scope="col" class="d-none d-xl-table-cell button-column-large text-right p-0"></th>
                    <th scope="col" class="d-xl-none d-table-cell button-column-small"></th>
                </tr>
            </thead>
            <tbody [formArrayName]="formName">
                <tr *ngFor="let row of formArray.controls; let i = index" [formGroupName]="i" class="form-group">
                    <td>
                        <ng-select
                            class="ops-select"
                            [items]="bunkerTypes"
                            formControlName="bunkerType"
                            [clearable]="true"
                            bindLabel="name"
                            [compareWith]="referenceDataService.compare"
                            triggers="manual"
                            data-test-bunker-type
                            #bunkerTypeElement
                            [selectOnTab]="true"
                        >
                        </ng-select>
                    </td>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="ballast"
                            [opsNumber]="{ precision: 3, minValue: 0 }"
                            data-test-ballast
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="laden"
                            [opsNumber]="{ precision: 3, minValue: 0 }"
                            data-test-laden
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="atSea"
                            [opsNumber]="{ precision: 3, minValue: 0 }"
                            data-test-at-sea
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="portIdle"
                            [opsNumber]="{ precision: 3, minValue: 0 }"
                            data-test-port-idle
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="portWorking"
                            [opsNumber]="{ precision: 3, minValue: 0 }"
                            data-test-port-working
                        />
                    </td>
                    <td class="d-none d-xl-table-cell text-right p-0">
                        <button
                            *ngIf="i === formArray.controls.length - 1"
                            [disabled]="formArray.disabled"
                            (click)="add()"
                            type="button"
                            class="btn-add has-icon icon--add-circle-outline"
                            data-test-add-bunker-consumption
                        ></button>
                        <button
                            [disabled]="formArray.disabled"
                            (click)="copy(i, row)"
                            type="button"
                            class="btn-clone has-icon icon--content-copy"
                            data-test-clone-bunker-consumption
                        ></button>
                        <button
                            [disabled]="formArray.disabled || formArray.controls.length === 1"
                            (click)="delete(i, row)"
                            type="button"
                            class="btn-delete has-icon icon--delete"
                            data-test-remove-bunker-consumption
                        ></button>
                    </td>
                    <td class="d-xl-none d-table-cell text-right p-0">
                        <button
                            *ngIf="i === formArray.controls.length - 1"
                            [disabled]="parentForm.disabled"
                            (click)="add()"
                            type="button"
                            class="btn-add has-icon icon--add-circle-outline"
                            data-test-add-bunker-consumption
                        ></button>
                        <button
                            type="button"
                            [disabled]="formArray.disabled"
                            [ngbPopover]="moreMenuContent"
                            #moreMenu="ngbPopover"
                            (click)="moreMenu.open()"
                            placement="left"
                            triggers="manual"
                            class="btn-add has-icon icon--more-vert cargo-more-btn"
                        ></button>
                        <ng-template #moreMenuContent>
                            <div class="more-menu list-group" (mouseleave)="moreMenu.close()">
                                <div class="more-menu-item list-group-item list-group-item-action" (click)="copy(i, row)">
                                    <span class="has-icon icon--content-copy"></span>
                                    <span>Duplicate</span>
                                </div>
                                <div *ngIf="formArray.controls.length" class="more-menu-item list-group-item list-group-item-action" (click)="delete(i, row)">
                                    <span class="has-icon icon--delete"></span>
                                    <span>Delete</span>
                                </div>
                            </div>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
