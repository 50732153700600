<div *ngIf="fixtureHeader; else loading" class="p-d-flex">
    <div class="p-pr-3 ops-vessel">
        <a href="{{fixtureHeader.vesselUrl}}" target="_blank" data-test="vessel">{{ fixtureHeader.vesselName }}</a>
    </div>
    <div class="p-pl-3 ops-header-details">
        <div class="p-d-flex">
            <a [routerLink]="['/fixture', fixtureHeader.fixtureId, 'voyage', fixtureHeader.voyageId]" queryParamsHandling="merge" data-test="fixture-reference">{{ fixtureHeader.fixtureReference }}</a>
            <div *ngIf="fixtureHeader.description" [ngbTooltip]="fixtureHeader.description" placement="bottom" data-test="voyage-description">&nbsp;|&nbsp;{{ fixtureHeader.description | truncate: 80:"..." }}</div>
        </div>
        <div data-test="owner-and-charterer">Owner: {{ fixtureHeader.owner }} | Charterer: {{ fixtureHeader.charterer }}</div>
    </div>
</div>

<ng-template #loading>
    <ops-load-animation size="small" class="light-background"></ops-load-animation>
</ng-template>
