<div class="card-header pl-0 row no-gutters align-items-center" [class.expanded]="expanded" [class.collapsed]="!expanded">
    <button
        data-cy="toggle-activity-button"
        type="button"
        class="has-icon toggle-button"
        [class.icon--arrow-drop-right]="!expanded"
        [class.icon--arrow-drop-down]="expanded"
        [class.discharge-activity]="isDischargeActivity"
        (click)="toggle()"
        data-test-toggle-activity-button
    ></button>

    <div *ngIf="!expanded" class="collapsed-activity d-flex align-items-center">
        <span class="activity-value">{{ activityTypeText }}</span>
    </div>

    <div *ngIf="canOrder || canRemove" class="button-container ml-auto" [class.expanded]="expanded">
        <div *ngIf="canOrder" class="btn-group" role="group">
            <button
                [disabled]="!moveUpEnabled"
                type="button"
                class="has-icon icon--chevron-up"
                (click)="moveUp.emit()"
                data-test-move-activity-up-button
            ></button>
            <button
                [disabled]="!moveDownEnabled"
                type="button"
                class="has-icon icon--chevron-down"
                (click)="moveDown.emit()"
                data-test-move-activity-down-button
            ></button>
        </div>

        <div class="delete-container d-flex" *ngIf="canRemove">
            <button
                [disabled]="!removeEnabled"
                (click)="remove.emit()"
                type="button"
                class="btn-delete has-icon icon--delete ml-auto"
                data-test-delete-activity-button
            ></button>
        </div>
    </div>
</div>
