import { Enumeration } from "./enumeration";

export class WeekDaysList extends Enumeration {
    static Monday: WeekDaysList = new WeekDaysList(1, "Monday");
    static Tuesday: WeekDaysList = new WeekDaysList(2, "Tuesday");
    static Wednesday: WeekDaysList = new WeekDaysList(3, "Wednesday");
    static Thursday: WeekDaysList = new WeekDaysList(4, "Thursday");
    static Friday: WeekDaysList = new WeekDaysList(5, "Friday");
    static Saturday: WeekDaysList = new WeekDaysList(6, "Saturday");
    static Sunday: WeekDaysList = new WeekDaysList(0, "Sunday");
}
