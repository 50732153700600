import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as R from "ramda";

import { GridType } from "../../fixture/shared/models/enums/grid-type";
import { WorksheetId } from "../model";
import { getSelectedWorksheetId, WorksheetFeatureState, WorksheetsState, WorksheetState } from "./state";
import { toComparableWorksheet, toListItem } from "./utils";

export const worksheetsFeatureKey = "worksheets";
export const selectWorksheetsFeature = createFeatureSelector<WorksheetFeatureState, WorksheetsState>(worksheetsFeatureKey);

export const selectAllWorksheetStates = createSelector(selectWorksheetsFeature, (state) => state.worksheets);

export const selectWorksheetsOfSelectedType = createSelector(selectWorksheetsFeature, (state) =>
    state.worksheets.allIds
        .map((id) => state.worksheets.byId[id])
        .filter((w) => w.worksheet.worksheetType === state.worksheetTypeToShow)
        .map(toListItem)
);

export const selectSelectedWorksheetId = createSelector(selectWorksheetsFeature, getSelectedWorksheetId);

export const selectWorksheetState = createSelector<WorksheetFeatureState, { worksheetId: WorksheetId }, WorksheetsState, WorksheetState>(
    selectWorksheetsFeature,
    (state: WorksheetsState, { worksheetId }) => (worksheetId ? state.worksheets.byId[worksheetId] : null)
);

export const selectSelectedWorksheetState = createSelector<WorksheetFeatureState, WorksheetsState, WorksheetId, WorksheetState>(
    selectWorksheetsFeature,
    selectSelectedWorksheetId,
    (state, worksheetId) => (worksheetId ? state.worksheets.byId[worksheetId] : null)
);

export const selectSelectedWorksheet = createSelector(selectSelectedWorksheetState, (state) => state?.worksheet);

export const selectCurrentWorksheet = createSelector(selectWorksheetsFeature, (state) => state?.currentWorksheet);

export const selectSelectDefaultWorksheet = createSelector(selectWorksheetsFeature, (state) => state?.defaultWorksheet);

export const selectWorksheetTypeToShow = createSelector(selectWorksheetsFeature, (state) => state?.worksheetTypeToShow);

export const selectWorksheetTypeToShowTitle = createSelector(selectWorksheetTypeToShow, (gridType) => {
    const title = "Worksheets - ";
    switch (gridType) {
        case GridType.Coa:
            return title + "Contracts";
        case GridType.Action:
            return title + "Actions";
        case GridType.Claim:
            return title + "Demurrage/Claims";
        default:
            return title + "Fixtures";
    }
});

export const selectAreWorksheetsLoading = createSelector(selectWorksheetsFeature, (state) => state?.loadStatus === "loading");

export const selectWorksheetsLoadFailed = createSelector(selectWorksheetsFeature, (state) => state?.loadStatus === "failed");

export const selectCurrentWorksheetSavable = createSelector(
    selectCurrentWorksheet,
    selectSelectDefaultWorksheet,
    selectSelectedWorksheet,
    (currentWorksheet, defaultWorksheet, selectedWorksheet) => !R.equals(toComparableWorksheet(currentWorksheet), toComparableWorksheet(selectedWorksheet ?? defaultWorksheet))
);

export const selectGridRenameForm = createSelector(selectWorksheetsFeature, (state) => state?.gridRenameForm);
export const selectGridRenameFormSaving = createSelector(selectWorksheetsFeature, (state) => state?.gridRenameFormSaveStatus === "persisting");
export const selectGridRenameFormSaveFailed = createSelector(selectWorksheetsFeature, (state) => state?.gridRenameFormSaveStatus === "failed");
