import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { DateTime } from "luxon";

import { AppConfigService } from "../../../core";
import { LaytimeCalculationUnit, LaytimeType } from "../../../shared/reference-data";
import { CargoBerthActivityType } from "../../../shared/reference-data/cargo-berth-activity-type";
import { Enumeration } from "../../../shared/reference-data/enumeration";
import { ReferenceDataService } from "../../../shared/reference-data/reference-data.service";
import { EstimatedClaimValueCalculator } from "../../services/estimated-claim-value-calculator";
import { LaytimeCalculationService } from "../../services/laytime-calculation.service";
import { Cargo, CargoBerthActivity, Destination, Fixture, SeaNetLocation } from "../../shared/models";
import { DemurrageClaimType } from "../../shared/models/enums/demurrage-claim-type";
import { BerthSummaryModel, DestinationSummaryModel, LaytimeSummaryModel } from "../../shared/models/form-models/laytime-summary-aggregate.model";
import { CargoId, getCargoName } from "../../state/model";
import { LaytimeEventSortingUtility } from "../laytime-event-sorting-utility";

@Component({
    selector: "ops-laytime-summary",
    templateUrl: "./laytime-summary.component.html",
    styleUrls: ["./laytime-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeSummaryComponent implements OnInit, OnChanges {
    static componentName = "LaytimeSummaryComponent";

    private readonly clarksonsCdnUrl: string;

    allLaytimeEventTypes: Enumeration[];
    laytimeSummary: LaytimeSummaryModel;
    DemurrageClaimType = DemurrageClaimType;

    @Input() destinations: Destination[];
    @Input() cargoes: Cargo[];
    @Input() fixture: Fixture;
    @Input() parentForm: UntypedFormGroup;
    @Input() displayUnitType: LaytimeCalculationUnit;
    @Output() claimValueUpdated = new EventEmitter();

    constructor(
        appConfigService: AppConfigService,
        public referenceDataService: ReferenceDataService,
        private ecvCalculator: EstimatedClaimValueCalculator,
        private laytimeCalculationService: LaytimeCalculationService
    ) {
        this.clarksonsCdnUrl = appConfigService.config.clarksonsCdnUrl;
    }

    get isFixed() {
        return this.fixture.laytimeType && this.fixture.laytimeType.id === LaytimeType.Fixed.id;
    }

    ngOnInit() {
        this.referenceDataService.getLaytimeEventTypes().subscribe((laytimeEventTypes: Enumeration[]) => {
            this.allLaytimeEventTypes = laytimeEventTypes;
        });

        this.laytimeSummary = this.setLaytimeSummary();
        this.laytimeCalculationService.setLaytimeSummary(this.laytimeSummary);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.cargoes && changes.destinations && changes.cargoes.firstChange && changes.destinations.firstChange) {
            return;
        }
        this.laytimeSummary = this.setLaytimeSummary();
        this.laytimeCalculationService.setLaytimeSummary(this.laytimeSummary);
    }

    setLaytimeSummary() {
        const laytimeSummary = this.ecvCalculator.createLaytimeSummaryModel(this.fixture, this.destinations);

        let hasClaim = false;
        if (laytimeSummary && laytimeSummary.claimValue) {
            if (this.fixture.demurrageBankEnabled) {
                hasClaim = laytimeSummary.claimValue !== 0;
            } else {
                hasClaim = laytimeSummary.claimValue > 0;
            }
        }

        this.claimValueUpdated.emit(hasClaim);
        return laytimeSummary;
    }

    getCargoes(cargoActivity: CargoBerthActivity): string {
        if (cargoActivity && cargoActivity.associatedCargoes && cargoActivity.associatedCargoes.length > 0) {
            return cargoActivity.associatedCargoes.map((a) => this.getCargoName(a.cargoId).trim()).join(", ");
        }
        return "";
    }

    getFlagImageUrl(location: SeaNetLocation) {
        if (location && location.countryUnCode) {
            return this.clarksonsCdnUrl + `/Data/PublicArtificats/flags/ALPHA2/32/${location.countryUnCode}.png`;
        }
        return null;
    }

    displayDestination(destination: DestinationSummaryModel): boolean {
        let displayDestination = false;

        if (destination && destination.berths) {
            destination.berths.forEach((berth) => {
                if (berth.cargoBerthActivities) {
                    const activity = berth.cargoBerthActivities.find(
                        (c) => c.type && (c.type.id === CargoBerthActivityType.Load.id || c.type.id === CargoBerthActivityType.Discharge.id)
                    );
                    if (activity) {
                        displayDestination = true;
                    }
                }
            });
        }
        return displayDestination;
    }

    isLoadOrDischarge(cargoActivity: CargoBerthActivity): boolean {
        let isLoadOrDischarge = false;

        if (cargoActivity && cargoActivity.type && (cargoActivity.type.id === CargoBerthActivityType.Load.id || cargoActivity.type.id === CargoBerthActivityType.Discharge.id)) {
            isLoadOrDischarge = true;
        }
        return isLoadOrDischarge;
    }

    getLatestEvent(cargoActivity: CargoBerthActivity, destination: DestinationSummaryModel, berth: BerthSummaryModel): string {
        const timeZone = destination && destination.location ? destination.location.timeZone : "utc";

        if (cargoActivity && cargoActivity.laytimeEvents && cargoActivity.laytimeEvents.length > 0) {
            const orderedLaytimeEvents = LaytimeEventSortingUtility.sortLaytimeEvents(Array.from(cargoActivity.laytimeEvents)).filter((l) => l.eventDate);
            if (orderedLaytimeEvents.length > 0) {
                const lastEvent = orderedLaytimeEvents[orderedLaytimeEvents.length - 1];
                if (this.allLaytimeEventTypes && lastEvent.type && lastEvent.eventDate) {
                    const event = this.allLaytimeEventTypes.find((e) => e.id === lastEvent.type.id);
                    return `${event ? event.name : ""}: ${this.formatDate(lastEvent.eventDate, timeZone)}`;
                }
            }
        }

        if (berth && berth.etb) {
            return `ETB: ${this.formatDate(berth.etb, timeZone)}`;
        }

        if (destination && destination.arrivalDateTime) {
            return `Arrival Date: ${this.formatDate(destination.arrivalDateTime, timeZone)}`;
        }

        if (destination && destination.etaRange && destination.etaRange.from) {
            let etaDate = `ETA: ${this.formatDate(destination.etaRange.from, timeZone)}`;
            if (destination.etaRange.to && destination.etaRange.from !== destination.etaRange.to) {
                etaDate += ` - ${this.formatDate(destination.etaRange.to, timeZone)}`;
            }
            return etaDate;
        }

        return "";
    }

    setLoadOrDischarge(activityType: Enumeration) {
        if (activityType) {
            if (activityType.id === CargoBerthActivityType.Load.id) {
                return "L";
            }
            if (activityType.id === CargoBerthActivityType.Discharge.id) {
                return "D";
            }
        }
        return "";
    }

    private getCargoName(cargoId: CargoId): string {
        let cargoName = "";
        if (this.cargoes && cargoId) {
            const cargo = this.cargoes.find((c) => c.id === cargoId);
            cargoName = getCargoName(cargo) || "";
        }

        return cargoName;
    }

    private formatDate(date: string, timeZone: string): string {
        return DateTime.fromISO(date)
            .setZone(timeZone || "utc")
            .toFormat("dd MMM yy, HH:mm");
    }
}
