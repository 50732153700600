import { formatNumber } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { merge, Observable, Subject, Subscription } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { parseISODate, toMaritimeDateRange } from "../../../../shared/date-utils/date-utilities";
import { Enumeration } from "../../../../shared/reference-data/enumeration";
import { ReferenceDataService } from "../../../../shared/reference-data/reference-data.service";
import { RangeInValidator } from "../../../../shared/validators/range-in.validator";
import { RangeValidator } from "../../../../shared/validators/range.validator";
import { Command } from "../../../mediator/commands/command";
import { Period } from "../../../shared/models/dtos/period.dto";
import { OffHireGridRow } from "../../../shared/models/form-models/offhire-gridrow.model";
import { OffHireFormModel } from "../../../shared/models/form-models/offhire.model";
import { FixtureWarningPathMapper } from "../../../shared/warnings/fixture-warning-path-mapper";
import { AddOffHireCommand } from "../../../time-charter/hire-tab/offhire/commands/add-offhire.command";
import { DeleteOffHireCommand } from "../../../time-charter/hire-tab/offhire/commands/delete-offhire.command";
import { UpdateOffHireCommand } from "./commands/update-offhire.command";

@Component({
    selector: "ops-offhire",
    templateUrl: "./offhire.component.html",
    styleUrls: ["./offhire.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OffHireComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    static componentName = "OffHireComponent";

    private _formSubscription: Subscription;
    private fixtureSubscription: Subscription;
    private expandedRows: boolean[] = [];
    private rowIndexToExpand: number;
    private deregisterPathTransforms: (() => void)[];
    private ngSelectElementsCount = 0;
    private readonly destroy$ = new Subject();

    offHiresForm: UntypedFormGroup;
    offHires: OffHireGridRow[] = [];
    offHirePeriods: Enumeration[];
    expenseTypes$: Observable<Enumeration[]>;

    @Input() periods: Period[];
    @Input() parentForm: UntypedFormGroup;
    @Output() offHireUpdated = new EventEmitter<Command>();

    @ViewChildren("offHirePeriodsElement", { read: NgSelectComponent }) ngSelectElements: QueryList<NgSelectComponent>;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private warningPathMapper: FixtureWarningPathMapper,
        private cd: ChangeDetectorRef,
        public referenceDataService: ReferenceDataService,
        @Inject(LOCALE_ID) private locale: string
    ) {}

    ngOnInit() {
        this.createForm();
        this.parentForm.registerControl("offHires", this.offHiresForm);
        this.subscribeToFormChanges();

        this.expenseTypes$ = this.referenceDataService.getExpenseTypes();
        this.setForm();

        this.deregisterPathTransforms = [
            this.warningPathMapper.registerPathTransform(this.pathTransform),
            this.warningPathMapper.registerPathTransform(this.dateRangePathTransform)
        ];
    }

    ngOnDestroy(): void {
        this.destroy$.next();

        if (this.fixtureSubscription) {
            this.fixtureSubscription.unsubscribe();
            this.fixtureSubscription = null;
        }

        if (this.deregisterPathTransforms) {
            this.deregisterPathTransforms.forEach((fn) => fn());
            this.deregisterPathTransforms = null;
        }
    }

    ngAfterViewInit() {
        this.ngSelectElements.changes
            .pipe(
                takeUntil(this.destroy$),
                filter((elements: QueryList<NgSelectComponent>) => elements.length !== this.ngSelectElementsCount)
            )
            .subscribe((elements) => {
                this.ngSelectElementsCount = elements.length;
                if (!this.offHiresForm.disabled && elements.length) {
                    elements.last.focus();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes || !this.offHiresForm || (changes.periods && changes.periods.firstChange)) {
            return;
        }

        this.setForm();
    }

    pathTransform = (path: string[]): string[] => {
        // Transforms ["offHires", "offHires", "[x]", ...] into ["periods", "[x]", "offHires", "[x]", ...]
        if (path[0] === "offHires") {
            const formIndex = Number(path[2].substring(1, path[2].length - 1));
            const formRow = this.offHires[formIndex];

            const offHireIndex = formRow.offHireIndex;
            const periodIndex = this.periods.findIndex((period) => period.hireId === formRow.hireId);

            return ["periods", `[${periodIndex}]`, "offHires", `[${offHireIndex}]`, ...path.slice(3)];
        }

        return path;
    };

    dateRangePathTransform = (path: string[]): string[] => {
        if (path[0] === "periods" && path.length > 4) {
            let replacement;

            if (path[4] === "offHireFrom") {
                replacement = "from";
            } else if (path[4] === "offHireTo") {
                replacement = "to";
            }

            if (replacement) {
                return [...path.slice(0, 4), "offHireDateRange", replacement];
            }
        }

        return path;
    };

    get invalid() {
        return this.offHiresForm.invalid;
    }

    get touched() {
        return this.offhiresFormArray.some((period: UntypedFormGroup) => {
            const controlKeys = Object.keys(period.controls);
            const isTouched = controlKeys.some((key) => period.controls[key].invalid && period.controls[key].touched);
            return isTouched;
        });
    }

    addNew() {
        this.rowIndexToExpand = this.offHires.length;
        this.offHireUpdated.next(new AddOffHireCommand());
    }

    deleteOffHire(index: number) {
        this.expandedRows.splice(index, 1);
        this.offHireUpdated.next(new DeleteOffHireCommand(this.offHires[index]));
    }

    get offhiresFormArray() {
        const data = <UntypedFormArray>this.offHiresForm.get("offHires");
        return data.controls;
    }

    toggleCommentsSection(index: number): void {
        this.expandedRows[index] = !this.expandedRows[index];
    }

    isCommentsSectionVisible(index: number): boolean {
        return this.expandedRows[index];
    }

    private createForm() {
        this.offHiresForm = this.formBuilder.group(
            {
                offHires: this.formBuilder.array([])
            },
            { updateOn: "blur" }
        );

        if (this.parentForm.disabled) {
            this.offHiresForm.disable();
        } else {
            this.offHiresForm.enable();
        }
    }

    private setForm() {
        const dataModel = {
            formModel: this.mapFixturePeriodToOffHireViews(this.periods)
        };

        this.offHirePeriods = dataModel.formModel.offHirePeriods;

        this.removeFormSubscription();
        const offHiresControl = <UntypedFormArray>this.offHiresForm.controls.offHires;
        this.resizeOffHiresFormArray(offHiresControl, dataModel.formModel.offHires.length);
        this.offHiresForm.patchValue(dataModel.formModel, { emitEvent: false });
        this.subscribeToFormChanges();

        this.cd.markForCheck();
    }

    private mapFixturePeriodToOffHireViews(periods: Period[]): OffHireFormModel {
        const offHires: OffHireGridRow[] = [];
        const offHirePeriods = this.mapFixturePeriodsToOffHirePeriodViewModels(periods);

        periods.forEach((period) => {
            period.offHires.forEach((offHire, offHireIndex) => {
                offHires.push({
                    offHireIndex: offHireIndex,
                    hireId: period.hireId,
                    originalHireId: period.hireId,
                    periodFrom: parseISODate(period.periodRange.from),
                    periodTo: parseISODate(period.periodRange.to),
                    durationInDays: offHire.durationInDays,
                    hireRate: offHire.hireRate,
                    offHireFrom: offHire.offHireDateRange === null ? null : parseISODate(offHire.offHireDateRange.from),
                    offHireTo: offHire.offHireDateRange === null ? null : parseISODate(offHire.offHireDateRange.to),
                    value: offHire.value,
                    sortOrder: offHire.sortOrder,
                    comments: offHire.comments,
                    reasonType: offHire.reasonType
                });
            });
        });

        const offHireSorter = (ohvm1: OffHireGridRow, ohvm2: OffHireGridRow) => ohvm1.sortOrder - ohvm2.sortOrder;

        offHires.sort(offHireSorter);
        this.setExpandedRows(offHires);

        const offHireForm: OffHireFormModel = {
            offHirePeriods: offHirePeriods,
            offHires: offHires
        };

        this.offHires = offHires;

        return offHireForm;
    }

    private setExpandedRows(offHireRows: OffHireGridRow[]): void {
        offHireRows.forEach((_: OffHireGridRow, index: number) => {
            this.expandedRows[index] = this.offHiresForm.enabled && (this.expandedRows[index] || index === this.rowIndexToExpand || false);
        });

        this.rowIndexToExpand = null;
    }

    private mapFixturePeriodsToOffHirePeriodViewModels(periods: Period[]): Enumeration[] {
        const offHirePeriods: Enumeration[] = [];

        let index = 1;
        periods.forEach((period) => {
            const periodRange = toMaritimeDateRange(period.periodRange);
            const name = `Hire ${formatNumber(index++, this.locale, "2.0")}`;

            const offHirePeriod: Enumeration = {
                id: period.hireId,
                name: periodRange?.isValid ? `${name} - ${periodRange.toMaritimeString()}` : name
            };
            offHirePeriods.push(offHirePeriod);
        });

        return offHirePeriods;
    }

    private resizeOffHiresFormArray(offHireControl: UntypedFormArray, offHireCount: number): void {
        if (offHireControl.length === offHireCount) {
            return;
        }

        if (offHireControl.length > offHireCount) {
            offHireControl.removeAt(0);
        } else if (offHireControl.length < offHireCount) {
            offHireControl.push(this.createOffHireRow());
        }

        this.resizeOffHiresFormArray(offHireControl, offHireCount);

        return;
    }

    private createOffHireRow(): UntypedFormGroup {
        const group = this.formBuilder.group(
            {
                offHireIndex: [],
                hireId: [],
                originalHireId: [],
                periodFrom: [],
                periodTo: [],
                offHireFrom: ["", Validators.required],
                offHireTo: ["", Validators.required],
                durationInDays: ["", Validators.required],
                hireRate: ["", Validators.required],
                value: [],
                reasonType: [],
                comments: ["", { updateOn: "blur" }]
            },
            {
                validator: Validators.compose([
                    RangeValidator.validate("offHireFrom", "offHireTo"),
                    RangeInValidator.validate("offHireFrom", "periodFrom", "periodTo"),
                    RangeInValidator.validate("offHireTo", "periodFrom", "periodTo")
                ])
            }
        );

        if (this.offHiresForm.disabled) {
            group.disable();
        } else {
            group.enable();
        }
        return group;
    }

    private subscribeToFormChanges() {
        const valueChangesObservables = this.offhiresFormArray.map((offHire) => offHire.valueChanges);
        const mergedObservables = merge(...valueChangesObservables);
        this._formSubscription = mergedObservables.subscribe((value: any) => {
            // Check the form for validation
            const control = this.offhiresFormArray.find((f) => f.value === value);
            if (control) {
                const offHireViewModel = this.offHires.find((f) => f.originalHireId === value.originalHireId && f.offHireIndex === value.offHireIndex);

                offHireViewModel.hireId = value.hireId;
                offHireViewModel.durationInDays = value.durationInDays;
                offHireViewModel.hireRate = value.hireRate;
                offHireViewModel.offHireFrom = value.offHireFrom;
                offHireViewModel.offHireTo = value.offHireTo;
                offHireViewModel.comments = value.comments;
                offHireViewModel.reasonType = value.reasonType;

                this.offHireUpdated.emit(new UpdateOffHireCommand(offHireViewModel));
            }
        });
    }

    private removeFormSubscription() {
        if (this._formSubscription) {
            this._formSubscription.unsubscribe();
            this._formSubscription = null;
        }
    }
}
