import { createAction, on, On, props } from "@ngrx/store";
import { createFormGroupState } from "ngrx-forms";
import { currentCoaStateReducer } from "../../coa/reducer";
import { CoasState, getCurrentCoaState } from "../../model";
import { NominationTaskId } from "../../model/nomination-task";
import { getNewNominationTaskId, getNominationTaskFormGroupId, nominationTaskToForm } from "../utils";

/* ACTIONS */
export const cloneNominationTaskAction = createAction("[Coa Nomination Tasks Form] Clone Nomination Task", props<{ nominationTaskId: NominationTaskId }>());

/* REDUCERS */
export const cloneNominationTaskReducer: On<CoasState> = on(cloneNominationTaskAction, (state, { nominationTaskId }) => {
    const currentCoa = getCurrentCoaState(state);
    const task = currentCoa.coa.nominationTasks.find((t) => t.nominationTaskId === nominationTaskId);

    if (!task) {
        return state;
    }

    const form = { ...nominationTaskToForm(task), nominationTaskId: getNewNominationTaskId() };

    return currentCoaStateReducer(state, (coaState) => ({
        ...coaState,
        nominationTaskForm: createFormGroupState(getNominationTaskFormGroupId(form.nominationTaskId), form)
    }));
});
