import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { FormArrayState, removeArrayControl } from "ngrx-forms";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, map, tap, withLatestFrom } from "rxjs/operators";

import { update } from "@ops/shared";

import { LaytimeCalculationHttpService } from "../../../../../services";
import { ActivityLocationId, createActivityLocationLaytimeEventFormsState, idEq, LaytimeEventForm, LaytimeEventId, LtcFeatureState, LtcId, LtcState } from "../../../../model";
import { calculationStateReducer, currentCalculationStateReducer } from "../../../reducer";
import { selectCurrentCalculationId } from "../../../selectors";
import { UpdateQueue } from "../../../update-queue";
import { selectCurrentActivityLocationId } from "../../selectors";

/* ACTIONS */
const REMOVE_ACTION_NAME = "[LTC Laytime Events Form] Remove Laytime Event";
export const removeLaytimeEventFormAction = createAction(`${REMOVE_ACTION_NAME} Form`, props<{ laytimeEventId: LaytimeEventId }>());
export const removeLaytimeEventAction = createAction(REMOVE_ACTION_NAME, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; laytimeEventId: LaytimeEventId }>());
export const removeLaytimeEventSuccessAction = createAction(
    `${REMOVE_ACTION_NAME} Success`,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; laytimeEventId: LaytimeEventId }>()
);
export const removeLaytimeEventFailAction = createAction(
    `${REMOVE_ACTION_NAME} Fail`,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; laytimeEventId: LaytimeEventId; error: Error }>()
);

const CLEAR_ACTION_NAME = "[LTC Laytime Events Form] Clear Laytime Events";
export const clearLaytimeEventsFormAction = createAction(`${CLEAR_ACTION_NAME} Form`);
export const clearLaytimeEventsAction = createAction(CLEAR_ACTION_NAME, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId }>());
export const clearLaytimeEventsSuccessAction = createAction(`${CLEAR_ACTION_NAME} Success`, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId }>());
export const clearLaytimeEventsFailAction = createAction(`${CLEAR_ACTION_NAME} Fail`, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; error: Error }>());

/* REDUCERS */
export const removeLaytimeEventFormReducer: On<LtcState> = on(removeLaytimeEventFormAction, (state, { laytimeEventId }) => {
    const updateFns: Evolver = {
        laytimeEventForms: (forms: FormArrayState<LaytimeEventForm>) => removeArrayControl(forms.value.findIndex((x) => x.id === laytimeEventId))(forms)
    };

    return currentCalculationStateReducer(state, R.evolve(updateFns));
});

export const removeLaytimeEventFailReducer: On<LtcState> = on(removeLaytimeEventFailAction, clearLaytimeEventsFailAction, (state, { activityLocationId, ltcId }) => {
    const laytimeEvents = state.calculations.byId[ltcId].calculation.activityLocations.find((x) => x.id === activityLocationId).laytimeEvents;
    const updateFns: Evolver = {
        laytimeEventForms: R.always(createActivityLocationLaytimeEventFormsState(activityLocationId, laytimeEvents))
    };

    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

export const removeLaytimeEventReducer: On<LtcState> = on(removeLaytimeEventAction, (state, { laytimeEventId, activityLocationId, ltcId }) => {
    const updateFns: Evolver = {
        calculation: { activityLocations: update(idEq(activityLocationId), R.evolve({ laytimeEvents: R.reject(idEq(laytimeEventId)) })) }
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

export const clearLaytimeEventsFormReducer: On<LtcState> = on(clearLaytimeEventsFormAction, (state) =>
    currentCalculationStateReducer(state, (ltcState) => ({
        ...ltcState,
        laytimeEventForms: createActivityLocationLaytimeEventFormsState(ltcState.currentActivityLocationId, [])
    }))
);

export const clearLaytimeEventsReducer: On<LtcState> = on(clearLaytimeEventsAction, (state, { activityLocationId, ltcId }) => {
    const updateFns: Evolver = {
        calculation: { activityLocations: update(idEq(activityLocationId), R.evolve({ laytimeEvents: () => [] })) }
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

/* EFFECTS */
export const removeLaytimeEventFormEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeLaytimeEventFormAction),
            withLatestFrom(store.select(selectCurrentCalculationId), store.select(selectCurrentActivityLocationId)),
            map(([{ laytimeEventId }, ltcId, activityLocationId]) => removeLaytimeEventAction({ ltcId, activityLocationId, laytimeEventId })),
            tap((action) => updateQueue.enqueue(action, removeLaytimeEvent, (latest) => (action.laytimeEventId === latest.laytimeEventId ? action : [latest, action])))
        )
    );

export const clearLaytimeEventsFormEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(clearLaytimeEventsFormAction),
            withLatestFrom(store.select(selectCurrentCalculationId), store.select(selectCurrentActivityLocationId)),
            map(([, ltcId, activityLocationId]) => clearLaytimeEventsAction({ ltcId, activityLocationId })),
            tap((action) => updateQueue.enqueue(action, clearLaytimeEvents, "replace"))
        )
    );

export const removeLaytimeEvent = (
    { ltcId, activityLocationId, laytimeEventId }: ReturnType<typeof removeLaytimeEventAction>,
    laytimeCalculationHttpService: LaytimeCalculationHttpService
) =>
    laytimeCalculationHttpService.removeLaytimeEvent(ltcId, activityLocationId, laytimeEventId).pipe(
        map(() => removeLaytimeEventSuccessAction({ ltcId, activityLocationId, laytimeEventId })),
        catchError((error) => of(removeLaytimeEventFailAction({ ltcId, activityLocationId, laytimeEventId, error })))
    );

export const clearLaytimeEvents = ({ ltcId, activityLocationId }: ReturnType<typeof clearLaytimeEventsAction>, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    laytimeCalculationHttpService.clearLaytimeEvents(ltcId, activityLocationId).pipe(
        map(() => clearLaytimeEventsSuccessAction({ ltcId, activityLocationId })),
        catchError((error) => of(clearLaytimeEventsFailAction({ ltcId, activityLocationId, error })))
    );
