import { Notification, NotificationType } from "@ops/shared";

export class ErrorMapppings {
    private static functionalSystemError = "Functional System Error";
    private static anErrorOccurredText = "An error within the system has occurred.";
    private static pleaseTryAgainText = "Please try again shortly or contact the help desk for further information.";

    private static message1 = `${ErrorMapppings.anErrorOccurredText} Ability to select cargo is not available at this time. ${
        ErrorMapppings.pleaseTryAgainText
    }`;
    private static message2 = `${ErrorMapppings.anErrorOccurredText} Ability to select location is not available at this time. ${
        ErrorMapppings.pleaseTryAgainText
    }`;
    private static message3 = `${ErrorMapppings.anErrorOccurredText} Ability to display reference data is not available at this time. ${
        ErrorMapppings.pleaseTryAgainText
    }`;
    private static message4 = `${ErrorMapppings.anErrorOccurredText} Ability to 'Save' is not available at this time. ${ErrorMapppings.pleaseTryAgainText}`;
    private static message5 = `${ErrorMapppings.anErrorOccurredText} Ability to verify 'ETB' is not available at this time. ${
        ErrorMapppings.pleaseTryAgainText
    }`;
    private static message6 = `${ErrorMapppings.anErrorOccurredText} Ability to verify 'ETA' is not available at this time. ${
        ErrorMapppings.pleaseTryAgainText
    }`;
    private static message7 = `${ErrorMapppings.anErrorOccurredText} Ability to 'Edit' is not available at this time. ${ErrorMapppings.pleaseTryAgainText}`;
    private static message8 = `${ErrorMapppings.anErrorOccurredText} Ability to 'Cancel' is not available at this time. ${ErrorMapppings.pleaseTryAgainText}`;
    private static message9 = `${ErrorMapppings.anErrorOccurredText} Ability to load page is not available at this time. ${ErrorMapppings.pleaseTryAgainText}`;
    private static message10 = "You do not have authority to open this application. Please contact your administrator to gain access.";
    private static message11 = "Something went wrong. Please refresh and try again. If the error continues please contact support@sea.live";
    private static message12 = `${ErrorMapppings.anErrorOccurredText} Ability to update 'Last Contacted Date' is not available at this time. ${
        ErrorMapppings.pleaseTryAgainText
    }`;

    static notificationMappings: any = {
        1: new Notification(`${ErrorMapppings.functionalSystemError}:`, ErrorMapppings.message1, NotificationType.Warn),
        2: new Notification(`${ErrorMapppings.functionalSystemError}:`, ErrorMapppings.message2, NotificationType.Warn),
        3: new Notification(`${ErrorMapppings.functionalSystemError}:`, ErrorMapppings.message3, NotificationType.Warn),
        4: new Notification(`${ErrorMapppings.functionalSystemError}:`, ErrorMapppings.message4, NotificationType.Warn),
        5: new Notification(`${ErrorMapppings.functionalSystemError}:`, ErrorMapppings.message5, NotificationType.Warn),
        6: new Notification(`${ErrorMapppings.functionalSystemError}:`, ErrorMapppings.message6, NotificationType.Warn),
        7: new Notification(`${ErrorMapppings.functionalSystemError}:`, ErrorMapppings.message7, NotificationType.Warn),
        8: new Notification(`${ErrorMapppings.functionalSystemError}:`, ErrorMapppings.message8, NotificationType.Warn),
        9: new Notification(`${ErrorMapppings.functionalSystemError}:`, ErrorMapppings.message9, NotificationType.Warn),
        10: new Notification("Access to this application is denied.", ErrorMapppings.message10, NotificationType.Warn),
        11: new Notification("Error occurred", ErrorMapppings.message11, NotificationType.Error),
        12: new Notification(`${ErrorMapppings.functionalSystemError}:`, ErrorMapppings.message12, NotificationType.Warn)
    };

    static urlErrorMappings: UrlErrorMapping[] = [
        { relativeUrlTemplate: "cargoes/search/", statusCode: 400, errorMessageId: 1 },
        { relativeUrlTemplate: "location/", statusCode: 404, errorMessageId: 2 },
        { relativeUrlTemplate: "referenceData/", statusCode: 404, errorMessageId: 3 },
        { relativeUrlTemplate: "fixture/.*/destination/.*/berth/.*/verifyetb", statusCode: 400, errorMessageId: 5 },
        { relativeUrlTemplate: "fixture/.*/destination/.*/verifyEta", statusCode: 400, errorMessageId: 6 },
        { relativeUrlTemplate: "fixture/.*/demurrage/claimNotication/updateLastContacted", statusCode: 400, errorMessageId: 12 },
        { relativeUrlTemplate: "fixture/.*/lock", statusCode: 400, errorMessageId: 7 },
        { relativeUrlTemplate: "fixture/.*/unlock", statusCode: 400, errorMessageId: 8 },
        { relativeUrlTemplate: "fixture/.*", statusCode: 404, errorMessageId: 9 },
        { relativeUrlTemplate: "fixture/", statusCode: 400, errorMessageId: 4 },
        { relativeUrlTemplate: ".*", statusCode: 403, errorMessageId: 10 },
        { relativeUrlTemplate: ".*", statusCode: 500, errorMessageId: 11 }
    ];
}

export class UrlErrorMapping {
    constructor(public relativeUrlTemplate: string, public statusCode: number, public errorMessageId: number) {}
}
