import { FormGroupState, updateGroup, validate } from "ngrx-forms";
import { maxLength, required } from "ngrx-forms/validation";

import { LaytimeCalculationNameForm } from "../../..";

export const validateLaytimeCalculationNameForm = (form: FormGroupState<LaytimeCalculationNameForm>) =>
    updateGroup<LaytimeCalculationNameForm>({
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        name: validate(required, maxLength(250))
    })(form);
