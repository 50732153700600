import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { filter, map, tap, withLatestFrom } from "rxjs/operators";

import { FavoriteDataService } from "../../left-bar/shared/favorite-data.service";
import { AppState, LeftBarState } from "../model";
import { selectLeftBarIsCollapsed, selectLeftBarCurrentPanel } from "./selectors";

/* ACTIONS */
export const interopLeftBarCollapseToggleAction = createAction("[LeftBarStateService Interop] Collapse Toggled", props<{ isCollapsed: boolean }>());
export const interopLeftBarSetCurrentPanelAction = createAction("[LeftBarStateService Interop] Set Current Panel", props<{ panel: string | undefined }>());
export const leftBarSetCurrentPanelAction = createAction("[Left Bar] Set Current Panel", props<{ panel: string | undefined }>());

/* REDUCERS */
export const interopLeftBarCollapseToggleReducer: On<LeftBarState> = on(
    interopLeftBarCollapseToggleAction,
    (state, { isCollapsed }) =>
        <LeftBarState>{
            ...state,
            isCollapsed
        }
);
export const interopLeftBarSetCurrentPanelReducer: On<LeftBarState> = on(
    interopLeftBarSetCurrentPanelAction,
    (state, { panel }) =>
        <LeftBarState>{
            ...state,
            currentPanel: panel
        }
);

/* EFFECTS */
export const currentPanelEffect$ = (actions$: Actions, store: Store<AppState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType(interopLeftBarCollapseToggleAction, interopLeftBarSetCurrentPanelAction),
            withLatestFrom(store.select(selectLeftBarIsCollapsed), store.select(selectLeftBarCurrentPanel)),
            map(([, isCollapsed, currentPanel]) => leftBarSetCurrentPanelAction({ panel: isCollapsed ? undefined : currentPanel }))
        )
    );

export const leftBarSetCurrentPanelEffect$ = (actions$: Actions, favoriteDataService: FavoriteDataService) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(leftBarSetCurrentPanelAction),
                filter(({ panel }) => panel === "favorites"),
                tap(() => favoriteDataService.loadFavourites())
            ),
        { dispatch: false }
    );
