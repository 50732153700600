import { Injectable } from "@angular/core";
import { validate as validateUuid } from "uuid";

import { CommandHandler } from "../../../../mediator";
import { Voyage } from "../../../../shared/models";
import { ActivityId, BerthId, DestinationId } from "../../../../state/model";
import { DeleteBunkerCommand } from "./delete-bunker.command";

@Injectable({
    providedIn: "root"
})
export class DeleteBunkerCommandHandler implements CommandHandler {
    handle(voyage: Voyage, command: DeleteBunkerCommand) {
        const destinationId = command.payload["destinationId"] as DestinationId;
        if (!destinationId || !validateUuid(destinationId)) {
            throw new Error("A given destination ID is invalid.");
        }

        if (!voyage.destinations) {
            throw new Error("Unable to update activity because no destinations exist.");
        }

        const destinationToUpdate = voyage.destinations.find((d) => d.id === destinationId);
        if (!destinationToUpdate) {
            throw new Error("A destination with given ID does not exist.");
        }

        const berthId = command.payload["berthId"] as BerthId;
        if (!berthId || !validateUuid(berthId)) {
            throw new Error("A given berth ID is invalid.");
        }

        if (!destinationToUpdate.berths) {
            throw new Error("Unable to update activity because no berths exist.");
        }

        const berthToUpdate = destinationToUpdate.berths.find((b) => b.id === berthId);
        if (!berthToUpdate) {
            throw new Error("A berth with given ID does not exist.");
        }

        const berthActivityId = command.payload["berthActivityId"] as ActivityId;
        if (!berthActivityId || !validateUuid(berthActivityId)) {
            throw new Error("A given berth activity ID is invalid.");
        }

        if (!berthToUpdate.cargoBerthActivities) {
            throw new Error("Unable to update activity because no cargo berth activities exist.");
        }

        const activityToUpdate = berthToUpdate.cargoBerthActivities.find((a) => a.id === berthActivityId);
        if (!activityToUpdate) {
            throw new Error("Unable to update activity because it does not exist.");
        }

        if (!activityToUpdate.bunkersRemainingOnboard) {
            throw new Error("Unable to update activity because no berths exist.");
        }

        const index = command.payload["index"];
        if (index === null || isNaN(index)) {
            throw new Error("A given index is invalid.");
        }

        activityToUpdate.bunkersRemainingOnboard.splice(index, 1);
    }
}
