import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from "rxjs/operators";

import { VoyageStatus } from "@ops/shared/reference-data";

import { Router } from "@angular/router";
import { addToObjectMap } from "@ops/state";
import { navigateToVoyage } from "../../../routing";
import { VoyageCreate, VoyageHttpService } from "../../../services";
import { FixtureType, Voyage } from "../../../shared/models";
import { selectCurrentFixtureId } from "../../fixture";
import { currentFixtureStateReducer, fixtureStateReducer } from "../../fixture/reducer";
import {
    FixtureFeatureState,
    FixtureId,
    FixturesState, getDivision,
    getFixtureState, getFixtureType,
    initialVoyageState
} from "../../model";
import { getNextId } from "../../utils";
import { selectCurrentFixtureVoyages } from "../selectors";

/* ACTIONS */
const ACTION_NAME = "[TC Voyage LHP] Add Voyage";

export const addVoyageAction = createAction(ACTION_NAME);
export const addVoyageSuccessAction = createAction(`${ACTION_NAME} Success`, props<{ fixtureId: FixtureId; voyage: Voyage }>());
export const addVoyageFailAction = createAction(`${ACTION_NAME} Fail`, props<{ fixtureId: FixtureId; error: Error }>());

/* REDUCERS */
export const addVoyageReducer: On<FixturesState> = on(addVoyageAction, (state) =>
    currentFixtureStateReducer(state, { voyageCreationPending: true })
);

export const addVoyageSuccessReducer: On<FixturesState> = on(addVoyageSuccessAction, (state, action) => {
    const fixtureState = getFixtureState(state, action.fixtureId);
    const fixtureType = getFixtureType(fixtureState);
    const division = getDivision(fixtureState);

    return <FixturesState>{
        ...fixtureStateReducer(state, action.fixtureId, {
            voyageIds: [...fixtureState.voyageIds, action.voyage.voyageId],
            voyageCreationPending: false
        }),
        voyages: addToObjectMap(state.voyages, action.voyage, "voyageId", (voyage) => initialVoyageState(voyage, fixtureType, division))
    };
});

export const addVoyageFailReducer: On<FixturesState> = on(addVoyageFailAction, (state, action) =>
    fixtureStateReducer(state, action.fixtureId, { voyageCreationPending: false }));

/* EFFECTS */
export const addVoyageEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, voyageService: VoyageHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(addVoyageAction),
            withLatestFrom(
                store.select(selectCurrentFixtureId),
                store.select(selectCurrentFixtureVoyages)
            ),
            // exhaustMap - ignore requests whilst processing
            exhaustMap(([, fixtureId, voyages]) => {
                // TODO: Should this be done server side or trigger another action
                const nextVoyageNumber = getNextId(voyages, "voyageNumber");
                const request = <VoyageCreate>{
                    fixtureId: fixtureId,
                    voyageNumber: nextVoyageNumber,
                    voyageStatus: VoyageStatus.Open
                };

                return voyageService.create(request).pipe(
                    switchMap((voyageId) => voyageService.get(fixtureId, voyageId)),
                    map((voyage) => addVoyageSuccessAction({ fixtureId: fixtureId, voyage: voyage })),
                    catchError((err) => of(addVoyageFailAction({ fixtureId: fixtureId, error: err })))
                );
            })
        )
    );

export const showNewVoyageEffect$ = (actions$: Actions, router: Router) =>
    createEffect(() =>
            actions$.pipe(
                ofType(addVoyageSuccessAction),
                tap(({ fixtureId, voyage: { voyageId } }) => navigateToVoyage(router, fixtureId, voyageId))
            ),
        { dispatch: false }
    );
