import { DateTime, Duration, Interval } from "luxon";

import { DayOfWeek, toLuxonWeekday } from "../models";
import { zeroDuration } from "./duration-utils";

export interface ExclusionInterval {
    exclusionStartDay: DayOfWeek;
    exclusionStartTime: string;
    exclusionEndDay: DayOfWeek;
    exclusionEndTime: string;
}

const dateWithISOTime = (date: DateTime, isoTime: string, zone?: string): DateTime => DateTime.fromISO(`${date.toISODate()}T${isoTime}`, { zone });

/**
 * @param dateTimeToStartComputation DateTime to start computation
 * @param dayOfWeek Day of week on new DateTime
 * @param time Time of new DateTime
 * @returns Closest DateTime to dateTimeToStartComputation, which is earlier than dateTimeToStartComputation
 * and which day of week and time equal to dayOfWeek and time from arguments
 */
const getClosestEarlierDateTime = (dateTimeToStartComputation: DateTime, dayOfWeek: DayOfWeek, time: string) => {
    let dateTime = dateWithISOTime(dateTimeToStartComputation, time, dateTimeToStartComputation.zoneName);
    const day = toLuxonWeekday(dayOfWeek);

    while (dateTime.weekday !== day || dateTime >= dateTimeToStartComputation) {
        dateTime = dateTime.minus({ days: 1 });
    }

    return dateTime;
};

/**
 * If we have
 *
 * @param interval "03 May 2023 00:00 - 17 May 2023 00:00"
 * @param exclusionStartDay "Friday"
 * @param exclusionStartTime "18:00"
 * @param exclusionEndDay "Monday"
 * @param exclusionEndTime "08:00"
 * @returns Array of intervals ["05 May 2023 18:00 - 8 May 2023 08:00", "12 May 2023 18:00 - 15 May 2023 08:00"]
 * @example Calendar
 * May 2023
 * Mo| To| We| Th| Fr| Sa| Sn|
 * 01| 02| 03| 04| 05| 06| 07|
 * 08| 09| 10| 11| 12| 13| 14|
 * 15| 16| 17| 18| 19| 20| 21|
 */
const getTimeOffIntervals = (interval: Interval, { exclusionStartDay, exclusionStartTime, exclusionEndDay, exclusionEndTime }: ExclusionInterval) => {
    const timeOffIntervals: Interval[] = [];
    const { start, end } = interval;

    let timeOffEnd = getClosestEarlierDateTime(start, exclusionEndDay, exclusionEndTime);
    let timeOffStart = getClosestEarlierDateTime(timeOffEnd, exclusionStartDay, exclusionStartTime);

    while (timeOffEnd < end) {
        timeOffStart = timeOffStart.plus({ weeks: 1 });
        timeOffEnd = timeOffEnd.plus({ weeks: 1 });
        timeOffIntervals.push(Interval.fromDateTimes(timeOffStart, timeOffEnd));
    }

    return timeOffIntervals.filter((timeOffInterval) => interval.overlaps(timeOffInterval));
};

/**
 * <b>Determines whether the interval falls within the exclusion range.</b>
 *
 * @param interval The interval to intersect.
 * @param exclusionInterval Period of exclusion.
 * @returns true if there is an intersection; otherwise false
 */
export const hasIntersection = (interval: Interval, exclusionInterval: ExclusionInterval): boolean => getTimeOffIntervals(interval, exclusionInterval).length > 0;

/**
 * <b>Determines the duration that occurs for an interval within the given weekday period in the specified timezone.</b>
 * <br>
 * For example an interval of Mon 17 May 10:00 to Sat 22 May 12:00 with a weekday period of Friday 17:00 to Monday 10:00
 * will return the 19 hours which occur between Fri 21 May 17:00 and Sat 22 May 12:00.
 *
 * @param interval The interval to intersect.
 * @param exclusionInterval Period of exclusion.
 * @returns Duration The elapsed duration within the weekday period.
 */
export const intersectionDuration = (interval: Interval, exclusionInterval: ExclusionInterval): Duration => {
    const timeOffIntervals = getTimeOffIntervals(interval, exclusionInterval);

    return timeOffIntervals.reduce((sumDuration, timeOffInterval) => {
        const duration = interval.intersection(timeOffInterval)?.toDuration() ?? zeroDuration();
        return sumDuration.plus(duration);
    }, zeroDuration());
};
