import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, props, Store } from "@ngrx/store";
import { from, Observable } from "rxjs";
import { tap, withLatestFrom } from "rxjs/operators";

import { navigateToFixture } from "../../../fixture/state/router";
import { LtcFeatureState, LtcId } from "../model";
import { selectCurrentLaytimeCalculationFixtureId } from "./selectors";

export type LtcNavItem = "Summary" | "Terms" | "Activity Location" | "Add Locations";

/* ACTIONS */
export const laytimeCalculationNavItemClickAction = createAction("[LTC] Nav Item Click", props<{ item: LtcNavItem }>());
export const navigateToLaytimeCalculationAction = createAction("[LTC] Navigate To Laytime Calculation", props<{ ltcId: LtcId }>());
export const closeLaytimeCalculationAction = createAction("[LTC] Close Calculation");

/* EFFECTS */
export const navigateToLaytimeCalculationEffect$ = (actions$: Actions, router: Router) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(navigateToLaytimeCalculationAction),
                tap(({ ltcId }) => navigateToLaytimeCalculation(router, ltcId))
            ),
        { dispatch: false }
    );

export const closeLaytimeCalculationEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, router: Router) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(closeLaytimeCalculationAction),
                withLatestFrom(store.select(selectCurrentLaytimeCalculationFixtureId)),
                tap(([, fixtureId]) => navigateToFixture(router, fixtureId))
            ),
        { dispatch: false }
    );

/* FUNCTIONS */
export const navigateToLaytimeCalculation = (router: Router, ltcId: LtcId): Observable<boolean> =>
    from(
        router.navigate([{ outlets: { primary: ["ltc", ltcId], toolbar: null } }], {
            replaceUrl: true,
            queryParamsHandling: "merge"
        })
    );
