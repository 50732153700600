import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { formStateReducer, MarkAsTouchedAction, removeArrayControl, SetValueAction } from "ngrx-forms";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, filter, map, tap, withLatestFrom } from "rxjs/operators";

import { update } from "@ops/shared";

import { LaytimeCalculationHttpService } from "../../../../../services";
import {
    ActivityType,
    CargoTermsId,
    cargoTermsLocationFormKey,
    formToLaytimeCalculationCargoTerms,
    idEq,
    LaytimeCalculationCargoTerms,
    LaytimeCalculationCargoTermsForm,
    LaytimeCalculationState,
    LocationId,
    LtcFeatureState,
    LtcId,
    LtcState
} from "../../../../model";
import { calculationStateReducer, currentCalculationStateReducer } from "../../../reducer";
import { selectCurrentCalculationId, selectCurrentLaytimeCalculation } from "../../../selectors";
import { UpdateQueue } from "../../../update-queue";
import { selectCargoTermsForm, selectCurrentCargoTermsId } from "../selectors";

/* ACTIONS */
const UPDATE_ACTION_NAME = "[Laytime Calculation] Save Cargo Terms";
export const updateCargoTermsFormAction = createAction(UPDATE_ACTION_NAME);
export const updateCargoTermsSuccessAction = createAction(
    `${UPDATE_ACTION_NAME} Success`,
    props<{ ltcId: LtcId; cargoTermsId: CargoTermsId; cargoTerms: Partial<LaytimeCalculationCargoTerms> }>()
);
export const updateCargoTermsFailAction = createAction(`${UPDATE_ACTION_NAME} Fail`, props<{ ltcId: LtcId; cargoTermsId: CargoTermsId; error: Error }>());

const REMOVE_ACTION_NAME = "[Laytime Calculation] Remove Cargo Terms";
export const removeCargoTermsAction = createAction(REMOVE_ACTION_NAME, props<{ cargoTermsId: CargoTermsId }>());
export const removeCargoTermsSuccessAction = createAction(`${REMOVE_ACTION_NAME} Success`, props<{ ltcId: LtcId; cargoTermsId: CargoTermsId }>());
export const removeCargoTermsFailAction = createAction(`${REMOVE_ACTION_NAME} Fail`, props<{ ltcId: LtcId; cargoTermsId: CargoTermsId; error: Error }>());

export const removeCargoTermsLocationAction = createAction(`${REMOVE_ACTION_NAME} Location Form`, props<{ activityType: ActivityType; locationId: LocationId }>());

// These action names need to be compile time constants so that they work with the switch statement in queue-updates.ts
export const updateCargoTermsAction = createAction(
    "[Laytime Calculation] Queue Cargo Terms Save",
    props<{ ltcId: LtcId; cargoTermsId: CargoTermsId; cargoTerms: Partial<LaytimeCalculationCargoTerms> }>()
);
export const queueRemoveCargoTermsAction = createAction("[Laytime Calculation] Queue Cargo Terms Remove", props<{ ltcId: LtcId; cargoTermsId: CargoTermsId }>());

/* REDUCERS */
export const updateCargoTermsReducer: On<LtcState> = on(updateCargoTermsAction, (state, { ltcId, cargoTermsId, cargoTerms }) => {
    const updateFns: Evolver<LaytimeCalculationState> = {
        calculation: { cargoTerms: update(idEq(cargoTermsId), cargoTerms) },
        currentCargoTermsId: () => null,
        cargoTermsForm: () => null
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

export const removeCargoTermsReducer: On<LtcState> = on(removeCargoTermsAction, (state, { cargoTermsId }) => {
    const updateFns: Evolver<LaytimeCalculationState> = {
        calculation: { cargoTerms: R.filter<LaytimeCalculationCargoTerms, "array">((c) => c.id !== cargoTermsId) }
    };
    return currentCalculationStateReducer(state, R.evolve(updateFns));
});

export const removeCargoTermsLocationReducer: On<LtcState> = on(removeCargoTermsLocationAction, (state, { activityType, locationId }) =>
    currentCalculationStateReducer(state, (ltcState) => {
        const key = <keyof LaytimeCalculationCargoTermsForm>(activityType.toLocaleLowerCase() + "Locations");
        const index = ltcState.cargoTermsForm[key].value.findIndex((f) => f.locationId === locationId);

        return index < 0
            ? ltcState
            : {
                  ...ltcState,
                  cargoTermsForm: {
                      ...ltcState.cargoTermsForm,
                      [key]: removeArrayControl(index)(ltcState.cargoTermsForm[key])
                  }
              };
    })
);

export const cargoTermsLocationFormValueReducer: On<LtcState> = {
    types: [SetValueAction.TYPE],
    reducer: (state: LtcState, action: SetValueAction<string>): LtcState => {
        const controlPath = action.controlId.split(".");
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (controlPath.length !== 5 || controlPath[2] !== cargoTermsLocationFormKey) {
            return state;
        }

        return currentCalculationStateReducer(state, (ltcState) => {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            const formArrayId = controlPath.slice(0, 3).join(".");
            const key = <keyof LaytimeCalculationCargoTermsForm>(controlPath[1] + "Locations");
            const touched = formStateReducer(ltcState.cargoTermsForm[key], new MarkAsTouchedAction(formArrayId));
            return {
                ...ltcState,
                cargoTermsForm: {
                    ...ltcState.cargoTermsForm,
                    [key]: formStateReducer(touched, action)
                }
            };
        });
    }
};

/* EFFECTS */
export const queueUpdateCargoTermsEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(updateCargoTermsFormAction),
            withLatestFrom(store.select(selectCurrentLaytimeCalculation), store.select(selectCurrentCargoTermsId), store.select(selectCargoTermsForm)),
            filter(([, , , form]) => form.loadLocations.isValid && form.dischargeLocations.isValid),
            map(([, calculation, cargoTermsId, form]) =>
                updateCargoTermsAction({
                    ltcId: calculation.id,
                    cargoTermsId,
                    cargoTerms: formToLaytimeCalculationCargoTerms(
                        form,
                        calculation.cargoTerms.find((c) => c.id === cargoTermsId)
                    )
                })
            ),
            tap((action) => updateQueue.enqueue(action, updateCargoTerms, (latest) => (action.cargoTermsId === latest.cargoTermsId ? action : [latest, action])))
        )
    );

export const queueRemoveCargoTermsEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeCargoTermsAction),
            withLatestFrom(store.select(selectCurrentCalculationId)),
            map(([{ cargoTermsId }, ltcId]) => queueRemoveCargoTermsAction({ cargoTermsId, ltcId })),
            tap((action) => updateQueue.enqueue(action, removeCargoTerms, (latest) => (action.cargoTermsId === latest.cargoTermsId ? action : [latest, action])))
        )
    );

export const updateCargoTerms = ({ ltcId, cargoTermsId, cargoTerms }: ReturnType<typeof updateCargoTermsAction>, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    laytimeCalculationHttpService.upsertCargoTerms(ltcId, cargoTermsId, cargoTerms).pipe(
        map(() => updateCargoTermsSuccessAction({ ltcId, cargoTermsId, cargoTerms })),
        catchError((error) => of(updateCargoTermsFailAction({ ltcId, cargoTermsId, error })))
    );

export const removeCargoTerms = ({ ltcId, cargoTermsId }: ReturnType<typeof queueRemoveCargoTermsAction>, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    laytimeCalculationHttpService.removeCargoTerms(ltcId, cargoTermsId).pipe(
        map(() => removeCargoTermsSuccessAction({ ltcId, cargoTermsId })),
        catchError((error) => of(removeCargoTermsFailAction({ ltcId, cargoTermsId, error })))
    );
