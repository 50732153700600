<div class="coa-panel" *ngIf="(coaFixtures$ | async) as coaFixtures">
    <div class="panel-title" data-test-panel-title>
        Contracts
        <div class="left-toolbar-expander" (click)="collapseLeftBar()">
            <div class="has-icon icon--close" ></div>
        </div>
    </div>
    <div class="coa-detail" data-test-coa-detail>
        <div *ngIf="coaFixtures">
            <div class="coa-number">COA - {{ coaNumber }}</div>
            <div>{{ coaDescription }}</div>
        </div>
        <div *ngIf="!coaFixtures">
            <div>There are no COA details on this fixture.</div>
        </div>
    </div>
    <div class="scroll-box" data-test-scroll-box>
        <div *ngFor="let fixture of coaFixtures" class="fixture-panel" [ngClass]="{ selected: fixture.selected }">
            <a [routerLink]="['/fixture/' + fixture.fixtureId]" (click)="handleFixtureClick()" queryParamsHandling="merge">
                <div class="title">{{ fixture.vesselName }}</div>
                <div class="fixture-number">{{ fixture.fixtureNumber }}</div>
                <div class="fixture-detail">
                    <span>Laycan: </span>
                    <span>{{ fixture.laycanFrom | date: "dd MMM yy" }} - {{ fixture.laycanTo | date: "dd MMM yy" }}</span>
                    <span>&nbsp;|&nbsp;</span>
                    <span>Lifting: </span>
                    <span>{{ fixture.liftingDate | date: "dd MMM yy" }}</span>
                    <span *ngIf="!!fixture.liftingDate && fixture.liftingNumber !== null && fixture.liftingNumber !== ''">
                        -
                    </span>
                    <span>{{ fixture.liftingNumber }}</span>
                </div>
            </a>
        </div>
    </div>

    <div *ngIf="isAddNewCoaEnabled" class="row no-gutters buttons">
        <div class="col d-flex justify-content-end p-0">
            <button type="button" (click)="handleAddNewCoa()" class="btn btn-primary m-0" data-test-save>
                <span class="has-icon icon--add-circle-outline"></span>
                LIFTING
            </button>
        </div>
    </div>
</div>
