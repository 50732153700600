import { createAction, On, on, props } from "@ngrx/store";

import { UpdateSearchTokens, updateSearchTokensReducer } from "../../../state/grid.reducer";
import { CoasState } from "../model/state";

/* ACTIONS */
export const updateCoaGridSearchTokensAction = createAction("[Coa Grid] Update Search Tokens", props<UpdateSearchTokens>());

/* REDUCERS */
export const updateCoaGridSearchTokensReducer: On<CoasState> = on(updateCoaGridSearchTokensAction, (state, action) => updateSearchTokensReducer(state, action, "grid"));
