<ng-template #optionsTemplate let-item="item">
    <div>{{ item }}</div>
</ng-template>

<ops-grid-search-autocomplete
    [selection]="selection"
    (selectionChange)="onSelectionChange($event)"
    (save)="saveWorksheet()"
    (saveAsNew)="saveAsNewWorksheet()"
    [namingNewWorksheet]="namingNewWorksheet"
    [suggestedItems]="suggestedItems$"
    [optionsTemplate]="optionsTemplate"
    [isLoading]="suggestionsLoading$"
    [suggestionSubject]="suggestionSubject"
    data-test-calculations-gridsearchbox
>
</ops-grid-search-autocomplete>
