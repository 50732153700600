import * as R from "ramda";

/**
 * Capitalizes the first letter of a string.
 *
 * @param text The string value
 */
export const capitalize = (text: string) => (text ? text[0].toUpperCase() + text.substr(1) : text);

export type NamedType = { name: string };

/**
 * Adds all name values of the elements of an array separated by the specified separator string. Removes falsy and duplicate names.
 *
 * @param array The array
 * @param separator A string used to separate one element of an array from the next in the resulting String.
 * If omitted, the array elements are separated with a comma.
 */
export const joinNamedTypeArray = (array: ReadonlyArray<NamedType>, separator: string = ", ") =>
    R.pipe(R.map(R.prop("name")), R.reject(R.isNil), R.uniq, R.join(separator))(array ?? []) ?? "";
