import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, Store } from "@ngrx/store";
import { disable, enable, setValue, SetValueAction, updateGroup } from "ngrx-forms";
import { filter, map, withLatestFrom } from "rxjs/operators";

import { CoaFeatureState, createLiftingCommentsFormState, LiftingCommentsForm } from "../../../model";
import { CoasState } from "../../../model/state";
import { currentLiftingStateReducer } from "../../reducer";
import { selectCurrentLiftingSummaryCommentsForm } from "../selectors";

/* ACTIONS */
export const editLiftingSummaryCommentsAction = createAction("[Lifting Shell] Edit Lifting Summary Comments");
export const cancelEditLiftingSummaryCommentsAction = createAction("[Lifting Shell] Cancel Editing Lifting Summary Comments");

export const disableCommentsImportanceToggleAction = createAction("[Lifting Shell] Disable Lifting Summary Comment Importance Toggle");
export const enableCommentsImportanceToggleAction = createAction("[Lifting Shell] Enable Lifting Summary Comment Importance Toggle");

/* REDUCERS */
export const editLiftingSummaryCommentsReducer: On<CoasState> = on(editLiftingSummaryCommentsAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => ({
        ...liftingState,
        liftingSummaryCommentsForm: updateGroup<LiftingCommentsForm>({ isEditing: setValue(<boolean>true) })(liftingState.liftingSummaryCommentsForm)
    }))
);

export const cancelLiftingSummaryCommentsReducer: On<CoasState> = on(cancelEditLiftingSummaryCommentsAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => ({
        ...liftingState,
        liftingSummaryCommentsForm: createLiftingCommentsFormState(liftingState.lifting)
    }))
);

export const disableCommentsImportanceToggleReducer: On<CoasState> = on(disableCommentsImportanceToggleAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => {
        const notImportant = updateGroup<LiftingCommentsForm>({ areCommentsImportant: setValue(<boolean>false) })(liftingState.liftingSummaryCommentsForm);
        return {
            ...liftingState,
            liftingSummaryCommentsForm: updateGroup<LiftingCommentsForm>({ areCommentsImportant: disable })(notImportant)
        };
    })
);

export const enableCommentsImportanceToggleReducer: On<CoasState> = on(enableCommentsImportanceToggleAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => ({
        ...liftingState,
        liftingSummaryCommentsForm: updateGroup<LiftingCommentsForm>({ areCommentsImportant: enable })(liftingState.liftingSummaryCommentsForm)
    }))
);

/* EFFECTS */
export const changeLiftingSummaryCommentTextEffect$ = (actions$: Actions, store: Store<CoaFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<LiftingCommentsForm>>(SetValueAction.TYPE),
            map((action) => action.controlId.split(".")),
            filter(
                // tslint:disable-next-line:no-magic-numbers
                (controlPath) => controlPath.length === 3 && controlPath[1] === "liftingSummaryCommentsForm" && controlPath[2] === "comments"
            ),
            withLatestFrom(store.select(selectCurrentLiftingSummaryCommentsForm)),
            filter(([, form]) => !form.value.comments || (form.value.comments.length === 1 && form.controls.areCommentsImportant.isDisabled)),
            map(([, form]) => (!form.value.comments ? disableCommentsImportanceToggleAction() : enableCommentsImportanceToggleAction()))
        )
    );
