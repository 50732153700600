<td (click)="handleSelect()" data-cy="cargo">{{ cargoTerm.name }}</td>
<td (click)="handleSelect()">
    <ops-laytime-calculation-cargo-term-location
        *ngFor="let location of getVisibleLocations(cargoTerm.loadLocations, 'Load'); let i = index; trackBy: trackBy"
        [location]="location"
        [form]="form?.loadLocations.controls[i]"
        [isEditing]="isEditing"
        [isLoadLaytime]="true"
        (remove)="removeLocation.emit({ activityType: 'Load', locationId: $event })"
    ></ops-laytime-calculation-cargo-term-location>
</td>
<td (click)="handleSelect()">
    <ops-laytime-calculation-cargo-term-location
        *ngFor="let location of getVisibleLocations(cargoTerm.dischargeLocations, 'Discharge'); let i = index; trackBy: trackBy"
        [location]="location"
        [form]="form?.dischargeLocations.controls[i]"
        [isEditing]="isEditing"
        [isLoadLaytime]="false"
        (remove)="removeLocation.emit({ activityType: 'Discharge', locationId: $event })"
    ></ops-laytime-calculation-cargo-term-location>
</td>
<td>
    <div *ngIf="!isEditing" class="p-d-flex justify-content-end">
        <ops-icon-button
            icon="more-vert"
            type="more"
            [ngbPopover]="moreMenuContent"
            #moreMenu="ngbPopover"
            (click)="moreMenu.open()"
            placement="bottom-right"
            triggers="manual"
            data-cy="cargo-kebab"
        ></ops-icon-button>
    </div>
    <ng-template #moreMenuContent>
        <div class="more-menu list-group">
            <div class="more-menu-item list-group-item list-group-item-action" (click)="remove.emit()">
                <span class="has-icon icon--delete"></span>
                <span data-cy="delete">Delete</span>
            </div>
        </div>
    </ng-template>
</td>
