import { Injectable } from "@angular/core";
import { Fixture } from "src/app/fixture/shared/models";
import { dateToISOString } from "src/app/shared/date-utils/date-utilities";
import { CommandHandler } from "../../../mediator/command-handlers/command-handler";
import { EstimatedClaimValueCalculator } from "../../../services/estimated-claim-value-calculator";
import { DemurrageClaim, Destination } from "../../../shared/models";
import { AddDemurrageClaimCommand } from "./add-demurrage-claim.command";

@Injectable({
    providedIn: "root"
})
export class AddDemurrageClaimCommandHandler implements CommandHandler {
    constructor(
        private ecvCalculator: EstimatedClaimValueCalculator
    ) { }

    handle(fixture: Fixture, command: AddDemurrageClaimCommand) {
        if (!fixture.demurrage.claims) {
            fixture.demurrage.claims = [];
        }

        const maxId = fixture.demurrage.claims.reduce((max, current) => (current.demurrageClaimId > max ? current.demurrageClaimId : max), 0);

        fixture.demurrage.claims.push({
            demurrageClaimId: maxId + 1,
            gainId: null,
            comments: null,
            type: null,
            currency: fixture.currency,
            receivedFromOwnerDate: null,
            sentToChartererDate: null,
            chartererAcknowledgedReceiptDate: null,
            estimatedClaimValue: this.getEstimatedClaimValue(fixture, command.destinations),
            initialClaimValue: null,
            interimClaimValue: null,
            finalClaimValue: null,
            destinationId: null,
            berthId: null,
            agreedDate: null,
            datePaid: null,
            lastContactedDateTime: null,
            commissionDate: null,
            ownerInvoiceNumber: null,
            awaitingHardCopy: false,
            awaitingDocuments: false,
            complete: false,
            lastUpdatedByUser: command.currentUser,
            lastUpdatedDate: dateToISOString(new Date()),
            claimHandledBy: null
        });
    }

    private getEstimatedClaimValue(fixture: Fixture, destinations: Destination[]) {
        const placeholderClaim = <DemurrageClaim>(<any>{ destinationId: null, berthId: null, type: null });

        return this.ecvCalculator.calculateCurrentECV(fixture, destinations, placeholderClaim);
    }
}
