<div [formGroup]="periodsForm">
    <div *ngIf="periods && periods.length > 0; else noPeriods">
        <div class="card embedded-card">
            <div class="card-header row no-gutters align-items-center">Periods</div>
            <div class="card-body" >
                <table class="table m-0">
                    <thead>
                        <tr>
                            <th scope="col" class="py-0 align-middle small-size text-right">Hire</th>
                            <th scope="col" class="py-0 align-middle large-size"><span class="required">Period From</span></th>
                            <th scope="col" class="py-0 align-middle large-size"><span class="required">Period To</span></th>
                            <th scope="col" class="py-0 align-middle medium-size text-right"><span class="required">Duration (Days)</span></th>
                            <th scope="col" class="py-0 align-middle small-size text-right">Min</th>
                            <th scope="col" class="py-0 align-middle small-size text-right">Max</th>
                            <th scope="col" class="py-0 align-middle medium-size">Duration Type</th>
                            <th scope="col" class="py-0 align-middle medium-size text-right">Tolerance (Days)</th>
                            <th scope="col" class="py-0 align-middle medium-size text-right"><span [ngClass]="{'required':isOpsFixtureSource}">Fixing Hire Rate</span></th>
                            <th scope="col" class="py-0 align-middle medium-size text-right">Actual Hire Rate</th>
                            <th scope="col" class="py-0 align-middle medium-size"><span [ngClass]="{'required':isOpsFixtureSource}">Rate Unit</span></th>
                            <th scope="col" class="py-0 align-middle medium-large-size">Invoices Paid Up To</th>
                            <th scope="col" class="button-column-fixed" *ngIf="isOpsFixtureSource"></th>
                            <th scope="col" class="button-column-fixed" *ngIf="isOpsFixtureSource"></th>
                            <th scope="col" class="button-column-fixed" *ngIf="isOpsFixtureSource"></th>
                         </tr>
                    </thead>
                    <tbody formArrayName="periods">
                        <tr *ngFor="let period of periodsFormControls; let i = index" [formGroupName]="i" class="form-group"
                            data-test-period-row>
                            <input type="hidden" formControlName="hireId" data-test-hire-id />
                            <td class="p-0">
                                <span class="form-control input-sm text-right" readonly>{{ i + 1 | number: "2.0" }} </span>
                            </td>
                            <td class="p-0">
                                <ops-date-input
                                    timeZone="utc"
                                    [enableTime]="true"
                                    formControlName="periodFrom"
                                    [ngbPopover]="periodFromContent"
                                    #pf="ngbPopover"
                                    triggers="manual"
                                    (blur)="pf.close()"
                                    (focus)="pf.open()"
                                    [defaultFocusDate]="i === 0 ? laycanToDate : null"
                                    data-test-period-from>
                                </ops-date-input>
                                <ng-template #periodFromContent>
                                    <div *ngIf="period.controls.periodFrom.hasError('required') && period.controls.periodFrom.touched"
                                        class="row no-gutters display">
                                        <span class="has-icon icon--warning col-1"></span>
                                        <span class="content col-11" data-test-period-from-date-required> <b>Period From</b> date is required.</span>
                                    </div>
                                    <div *ngIf="period.controls.periodFrom.hasError('invalidRange') && period.controls.periodFrom.touched"
                                        class="row no-gutters display">
                                        <span class="has-icon icon--warning col-1"></span>
                                        <span class="content col-11" data-test-period-from-cannot-be-later>
                                            <b>Period From</b> cannot be later than <b>Period To</b>.</span>
                                    </div>
                                </ng-template>
                            </td>

                            <td class="p-0">
                                <ops-date-input
                                    timeZone="utc"
                                    [enableTime]="true"
                                    formControlName="periodTo"
                                    [ngbPopover]="periodToContent"
                                    #pt="ngbPopover"
                                    triggers="manual"
                                    (blur)="pt.close()"
                                    (focus)="pt.open()"
                                    data-test-period-to>
                                </ops-date-input>
                                <ng-template #periodToContent>
                                    <div *ngIf="period.controls.periodTo.hasError('required') && period.controls.periodTo.touched"
                                        class="row no-gutters display">
                                        <span class="has-icon icon--warning col-1"></span>
                                        <span class="content col-11" data-test-period-to-date-required> <b>Period To</b> date is required.</span>
                                    </div>
                                    <div *ngIf="period.controls.periodTo.hasError('invalidRange') && period.controls.periodTo.touched"
                                        class="row no-gutters display">
                                        <span class="has-icon icon--warning col-1"></span>
                                        <span class="content col-11" data-test-period-to-cannot-be-earlier>
                                            <b>Period To</b> cannot be earlier than <b>Period From</b>.</span>
                                    </div>
                                </ng-template>
                            </td>
                            <td class="p-0">
                                <input
                                type="text"
                                class="form-control input-sm text-right"
                                formControlName="durationInDays"
                                [opsNumber]="{ precision: 6, showTrailingZeros: false }"
                                [ngbPopover]="duration"
                                #d="ngbPopover"
                                placement="bottom-left"
                                triggers="manual"
                                (blur)="d.close()"
                                (focus)="d.open()"
                                [autoClose]="false"
                                data-test-duration-in-days />
                                <ng-template #duration>
                                    <div *ngIf="!period.controls.durationInDays.valid && period.controls.durationInDays.touched"
                                        class="row no-gutters display">
                                        <span class="has-icon icon--warning col-1"></span>
                                        <span class="content col-11" data-test-duration-in-days-required> Please specify <b>Duration (Days)</b>.</span>
                                    </div>
                                </ng-template>
                            </td>
                            <td class="p-0">
                                <input
                                type="text"
                                class="form-control input-sm text-right"
                                formControlName="minDuration"
                                [opsNumber]="{ precision: 0 }"
                                [ngbPopover]="minDurationContent"
                                data-test-min-duration
                                #min="ngbPopover"
                                (blur)="min.close()"
                                (focus)="min.open()"
                                triggers="manual"
                                placement="bottom-left"
                                [autoClose]="false" />
                                <ng-template #minDurationContent>
                                    <div *ngIf="!period.controls.minDuration.valid && period.controls.minDuration.touched"
                                        class="row no-gutters display">
                                        <span class="has-icon icon--warning col-1"></span>
                                        <span class="content col-11" data-test-specify-min-value>Please specify a <b>Min</b> value.</span>
                                    </div>
                                </ng-template>
                            </td>
                            <td class="p-0">
                                <input
                                type="text"
                                class="form-control input-sm text-right"
                                formControlName="maxDuration"
                                [opsNumber]="{ precision: 0 }"
                                    [ngbPopover]="maxDurationContent"
                                    #max="ngbPopover"
                                    (blur)="max.close()"
                                    (focus)="max.open()"
                                    [autoClose]="false"
                                    triggers="manual"
                                    placement="bottom-left"
                                    data-test-max-duration/>
                                <ng-template #maxDurationContent>
                                    <div *ngIf="!period.controls.maxDuration.valid && period.controls.maxDuration.touched" class="row no-gutters display">
                                        <span class="has-icon icon--warning col-1"></span>
                                        <span class="content col-11" data-test-specify-max-value>Please specify a <b>Max</b> value.</span>
                                    </div>
                                </ng-template>
                            </td>
                            <td class="p-0">
                                <ng-select
                                    class="ops-select form-control"
                                    [items]="durationTypes$ | async"
                                    placeholder="Select"
                                    formControlName="durationType"
                                    [clearable]="true"
                                    [ngbPopover]="durationType"
                                    bindLabel="name"
                                    [compareWith]="referenceDataService.compare"
                                    #dt="ngbPopover"
                                    (blur)="dt.close()"
                                    (focus)="dt.open()"
                                    [selectOnTab]="true"
                                    data-test-invoiceallocatedstatus>
                                </ng-select>
                                <ng-template #durationType>
                                    <div *ngIf="!period.controls.durationType.valid && period.controls.durationType.touched" class="row no-gutters display">
                                        <span class="has-icon icon--warning col-1"></span>
                                        <span class="content col-11" data-test-specify-duration-type>Please specify a <b>Min/Max Duration Type</b> e.g. Months.</span>
                                    </div>
                                </ng-template>
                            </td>
                            <td class="p-0">
                                <input
                                type="text"
                                class="form-control input-sm text-right"
                                formControlName="toleranceInDays"
                                [opsNumber]="{ precision: 0 }"
                                    data-test-tolerance-in-days />
                            </td>
                            <td class="p-0">
                                <input
                                type="text"
                                class="form-control input-sm text-right"
                                formControlName="hireRate"
                                [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                                [ngbPopover]="hireRate"
                                #d="ngbPopover"
                                placement="bottom-left"
                                triggers="manual"
                                (blur)="d.close()"
                                (focus)="d.open()"
                                [autoClose]="false"
                                data-test-hire-rate
                                [readonly]="!isOpsFixtureSource" />
                                <ng-template #hireRate>
                                    <div *ngIf="!period.controls.hireRate.valid && period.controls.hireRate.touched"
                                        class="row no-gutters display">
                                        <span class="has-icon icon--warning col-1"></span>
                                        <span class="content col-11" data-test-hire-rate-required> Please specify <b>Fixing Hire Rate</b>.</span>
                                    </div>
                                </ng-template>
                            </td>
                            <td class="p-0">
                                <input
                                type="text"
                                class="form-control input-sm text-right"
                                formControlName="actualHireRate"
                                [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                                data-test-actual-hire-rate
                                readonly />
                            </td>
                            <td class="p-0">
                                <input
                                type="text"
                                *ngIf="!isOpsFixtureSource"
                                class="form-control input-sm text-left"
                                formControlName="hireRateUnitName"
                                data-test-hire-rate-unit-name
                                readonly />

                                <ng-select
                                *ngIf="isOpsFixtureSource"
                                    class="ops-select form-control"
                                    [items]="hireRateUnits$ | async"
                                    placeholder="Select"
                                    formControlName="hireRateUnit"
                                    [clearable]="true"
                                    [ngbPopover]="hireRateUnitPop"
                                    bindLabel="name"
                                    [compareWith]="referenceDataService.compare"
                                    data-test-hire-rate-unit-name-select
                                    #dt="ngbPopover"
                                    (blur)="dt.close()"
                                    (focus)="dt.open()"
                                    [selectOnTab]="true"
                                    >
                                </ng-select>
                                <ng-template #hireRateUnitPop>
                                    <div *ngIf="!period.controls.hireRateUnit.valid && period.controls.hireRateUnit.touched && isOpsFixtureSource"
                                        class="row no-gutters display">
                                        <span class="has-icon icon--warning col-1"></span>
                                        <span class="content col-11" data-test-specify-hire-rate><b>Rate Unit</b> is required.
                                        </span>
                                    </div>
                                </ng-template>

                            </td>
                            <td class="p-0">
                                <ops-date-input timeZone="utc" formControlName="invoicesPaidUpTo"
                                    data-test-invoices-paid-up-to></ops-date-input>
                            </td>
                            <td *ngIf="isOpsFixtureSource">
                                <button *ngIf="i === periods.length - 1"
                                    [disabled]="periodsForm.disabled"
                                    (click)="addPeriod()"
                                    type="button"
                                    class="btn-add has-icon icon--add-circle-outline"
                                    data-test-add-period-button></button>
                            </td>
                            <td *ngIf="isOpsFixtureSource">
                                <button
                                    [disabled]="periodsForm.disabled"
                                    (click)="clonePeriod(i)"
                                    type="button"
                                    class="btn-clone has-icon icon--content-copy"
                                    data-test-cloneperiod></button>
                            </td>
                            <td *ngIf="isOpsFixtureSource">
                                    <button *ngIf="isDeleteAllowed(i)"
                                    [disabled]="periodsForm.disabled"
                                    (click)="deletePeriod(i)"
                                    type="button"
                                    class="btn-delete has-icon icon--delete"
                                    data-test-remove-period-button
                                ></button>
                          </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="isOpsFixtureSource">
            <span class="has-icon icon--lightbulb-outline"></span>
            <span class="message-warning">Deleting a period will unassociate any related invoices. Periods with associated off hires cannot be deleted.</span>
        </div>
    </div>

    <ng-template #noPeriods>
        <ops-no-data-panel
            #panel="noDataPanel"
            *ngIf="isOpsFixtureSource && (!periods || periods.length == 0)"
            heading="Periods"
            title="No periods have been added"
            description="To add a period, click the button"
            [useNewButton]="true" newButtonText="PERIOD"
            [disabled]="periodsForm.disabled"
            (addNew)="addPeriod()"
            warningActive
            (warningActivated)="panel.focusNewButton()">
        </ops-no-data-panel>
    </ng-template>
</div>
