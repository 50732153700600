import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import * as R from "ramda";
import { of } from "rxjs";
import { catchError, exhaustMap, map, take, tap, withLatestFrom } from "rxjs/operators";

import { saveNewWorksheetSuccessAction } from "./save-worksheets";
import { updateCoaGridColumnsAction } from "../../../coa/state/grid/update-columns";
import { updateCoaGridSearchTokensAction } from "../../../coa/state/grid/update-search-tokens";
import { updateCoaGridSortingAction } from "../../../coa/state/grid/update-sorting";
import { GridType } from "../../../fixture/shared/models/enums/grid-type";
import { WorksheetLocalStorageService } from "../../../left-bar/worksheets/service/worksheet-local-storage.service";
import { ColumnDefinition, ColumnSort, WorksheetId, WorksheetLocalData } from "../../model/worksheet";
import { selectWorksheetTypeToShow } from "../selectors";
import { WorksheetFeatureState, WorksheetsState } from "../state";
import { getWorksheetTypeLocalStorageIdentifier, toColumnSort, toCreateWorksheet, toLocalData } from "../utils";

/* ACTIONS */
const SET_INITIAL_GRID_DISPLAY_NAME = "[Worksheets] Set Initial Grid Display";
const SELECT_WORKSHEET_NAME = "[Worksheets] Select";

export const setDefaultWorksheetAction = createAction(
    "[Worksheets] Set Default Worksheet",
    props<{ columns: ReadonlyArray<ColumnDefinition>; sort: ColumnSort; queries: ReadonlyArray<string> }>()
);
export const setInitialGridDisplayAction = createAction(SET_INITIAL_GRID_DISPLAY_NAME, props<{ localData: WorksheetLocalData }>());
export const setInitialGridDisplayAndLocalStorageAction = createAction(`${SET_INITIAL_GRID_DISPLAY_NAME} And Local Storage`, props<{ localData: WorksheetLocalData }>());

export const selectWorksheetAction = createAction(SELECT_WORKSHEET_NAME, props<{ selectedWorksheetId: WorksheetId }>());
export const selectDefaultWorksheetAction = createAction(`${SELECT_WORKSHEET_NAME} Default`);

export const localDataWorksheetLoadFailAction = createAction("[Worksheets] Load Local Data Worksheet Fail", props<{ error: Error }>());

/* REDUCERS */
export const setDefaultWorksheetReducer: On<WorksheetsState> = on(setDefaultWorksheetAction, (state, { columns, sort, queries }) => ({
    ...state,
    defaultWorksheet: {
        ...state.defaultWorksheet,
        columns,
        sort,
        queries,
        worksheetType: state.worksheetTypeToShow
    }
}));

export const setInitialGridDisplayReducer: On<WorksheetsState> = on(setInitialGridDisplayAction, setInitialGridDisplayAndLocalStorageAction, (state, { localData }) => ({
    ...state,
    selectedWorksheetId: localData.selectedWorksheetId ?? state.selectedWorksheetId,
    currentWorksheet: {
        ...state.currentWorksheet,
        ...localData.currentWorksheet
    }
}));

export const updateCurrentWorksheetColumnsReducer: On<WorksheetsState> = on(updateCoaGridColumnsAction, (state, { columns }) =>
    state.currentWorksheet && !R.equals(state.currentWorksheet.columns, columns)
        ? {
              ...state,
              currentWorksheet: { ...state.currentWorksheet, columns }
          }
        : state
);

export const updateCurrentWorksheetSortReducer: On<WorksheetsState> = on(updateCoaGridSortingAction, (state, { sorting }) => {
    const sort: ColumnSort = toColumnSort(sorting);
    return state.currentWorksheet && !R.equals(state.currentWorksheet.sort, sort)
        ? {
              ...state,
              currentWorksheet: { ...state.currentWorksheet, sort }
          }
        : state;
});

export const updateCurrentWorksheetQueriesReducer: On<WorksheetsState> = on(updateCoaGridSearchTokensAction, (state, { searchTokens }) =>
    state.currentWorksheet && !R.equals(state.currentWorksheet.queries, searchTokens)
        ? {
              ...state,
              currentWorksheet: { ...state.currentWorksheet, queries: searchTokens }
          }
        : state
);

export const selectWorksheetReducer: On<WorksheetsState> = on(selectWorksheetAction, (state, { selectedWorksheetId }) => ({
    ...state,
    selectedWorksheetId,
    gridRenameForm: null,
    gridRenameFormSaveStatus: null,
    currentWorksheet: toCreateWorksheet(state.worksheets.byId[selectedWorksheetId].worksheet)
}));

export const selectDefaultWorksheetReducer: On<WorksheetsState> = on(selectDefaultWorksheetAction, (state) => ({
    ...state,
    selectedWorksheetId: null,
    gridRenameForm: null,
    gridRenameFormSaveStatus: null,
    currentWorksheet: { ...state.defaultWorksheet }
}));

/* EFFECTS */
export const setLocalStorageInitialWorksheetEffect$ = (actions$: Actions, localStorage: WorksheetLocalStorageService) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(setInitialGridDisplayAndLocalStorageAction),
                tap(({ localData }) => localStorage.set(getWorksheetTypeLocalStorageIdentifier(localData.currentWorksheet.worksheetType), localData))
            ),
        { dispatch: false }
    );

export const setLocalStorageWorksheetEffect$ = (actions$: Actions, localStorage: WorksheetLocalStorageService) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(saveNewWorksheetSuccessAction),
                map(({ worksheet }) => toLocalData(worksheet)),
                tap((localData) => localStorage.set(getWorksheetTypeLocalStorageIdentifier(localData.currentWorksheet.worksheetType), localData))
            ),
        { dispatch: false }
    );

export const getInitialCurrentWorksheetEffect$ = (actions$: Actions, store: Store<WorksheetFeatureState>, localStorage: WorksheetLocalStorageService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(setDefaultWorksheetAction),
            withLatestFrom(store.select(selectWorksheetTypeToShow)),
            exhaustMap(([{ columns, sort, queries }, gridType]) =>
                localStorage.get(getWorksheetTypeLocalStorageIdentifier(gridType as GridType)).pipe(
                    take(1),
                    map((data: unknown) => {
                        let localData: WorksheetLocalData;
                        if (data) {
                            localData = data as WorksheetLocalData;
                            return setInitialGridDisplayAction({ localData });
                        } else {
                            localData = {
                                currentWorksheet: {
                                    worksheetType: gridType,
                                    sort,
                                    queries,
                                    columns,
                                    name: null
                                },
                                selectedWorksheetId: null
                            };
                            return setInitialGridDisplayAndLocalStorageAction({ localData });
                        }
                    }),
                    catchError((error) => of(localDataWorksheetLoadFailAction({ error })))
                )
            )
        )
    );
