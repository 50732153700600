import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";

import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { Fixture } from "../../fixture/shared/models";
import { FixtureSource } from "../../fixture/shared/models/enums/fixture-source";
import { CoAFixtureSummaryModel } from "../../fixture/shared/models/form-models/coa-fixture-summary.model";
import { LeftBarStateService } from "../../left-bar/left-bar-state.service";
import { CoADataService } from "./coa-data.service";

@Component({
    selector: "ops-coa",
    templateUrl: "./coa.component.html",
    styleUrls: ["./coa.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoaComponent implements OnInit {
    static componentName = "CoaComponent";

    private fixture: Fixture;

    coaFixtures$: Observable<CoAFixtureSummaryModel[]>;
    coaDescription: string;
    coaNumber: string;
    isAddNewCoaEnabled = false;

    constructor(private coaService: CoADataService, private fixtureDataService: FixtureDataService, private leftBarStateService: LeftBarStateService, private router: Router) {}

    ngOnInit(): void {
        this.coaFixtures$ = this.coaService.currentCoaFixtureSummaries$.pipe(
            tap((data) => {
                this.resetCoAData(data);
            })
        );

        this.fixtureDataService.currentFixture$.pipe(filter((fixture) => !!fixture)).subscribe((fixture: Fixture) => {
            this.fixture = fixture;
            this.isAddNewCoaEnabled = this.fixture.fixtureSource.id === FixtureSource.Ops;
        });
    }

    handleFixtureClick(): void {
        this.leftBarStateService.collapseIfSmallWindow();
    }

    handleAddNewCoa() {
        if (this.fixture.fixtureSource.id === FixtureSource.Ops) {
            this.router.navigate([{ outlets: { toolbar: "create-fixture/coa" } }], { queryParamsHandling: "merge" });
        }
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }

    private resetCoAData(data: CoAFixtureSummaryModel[]): void {
        if (data) {
            this.coaDescription = data[0].coAName;
            this.coaNumber = data[0].coANumber;
        } else {
            this.coaDescription = null;
            this.coaNumber = null;
        }
    }
}
