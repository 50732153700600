<div>
    <div class="header">
        Lifting History
        <div class="left-toolbar-expander" (click)="collapseLeftBar()">
            <div class="has-icon icon--close"></div>
        </div>
    </div>
    <ops-lifting-history-filter></ops-lifting-history-filter>
    <div class="spacer line"></div>
</div>
<div *ngIf="(placeholderText$ | async) as placeholderText; else historyEvents" class="placeholder">
    <ops-placeholder [title]="placeholderText"></ops-placeholder>
</div>
<ng-template #historyEvents>
    <ops-lifting-history-events [historyEvents]="filteredHistoryEvents$ | async"></ops-lifting-history-events>
</ng-template>