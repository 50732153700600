import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";

import { GridType } from "../../../fixture/shared/models/enums/grid-type";
import { ColumnDefinition, ColumnSort, CreateWorksheetModel, WorksheetView } from "../shared/worksheet.model";
import { LegacyWorksheetActionType } from "./legacy-worksheet-action-type";

export class GetAllWorksheetsAction implements Action {
    readonly type = LegacyWorksheetActionType.GetAll;

    constructor(public payload: { type: GridType }) {}
}

export class GetAllWorksheetsSuccessAction implements Action {
    readonly type = LegacyWorksheetActionType.GetAllSuccess;

    constructor(public payload: { worksheets: WorksheetView[] }) {}
}

export class GetAllWorksheetsFailAction implements Action {
    readonly type = LegacyWorksheetActionType.GetAllFail;

    constructor(public payload: { error: Error }) {}
}

export class SaveWorksheetAction implements Action {
    readonly type = LegacyWorksheetActionType.Save;

    constructor(public payload: { name: string }) {}
}

export class SaveWorksheetSuccessAction implements Action {
    readonly type = LegacyWorksheetActionType.SaveSuccess;

    constructor(public payload: { worksheetId: string; worksheet: CreateWorksheetModel }) {}
}

export class SaveWorksheetFailAction implements Action {
    readonly type = LegacyWorksheetActionType.SaveFail;

    constructor(public payload: { error: Error; worksheet: CreateWorksheetModel }) {}
}

export class UpdateWorksheetAction implements Action {
    readonly type = LegacyWorksheetActionType.Update;
}

export class UpdateWorksheetSuccessAction implements Action {
    readonly type = LegacyWorksheetActionType.UpdateSuccess;

    constructor(public payload: { columns: ColumnDefinition[]; queries: string[]; sort: ColumnSort; worksheetType: GridType }) {}
}

export class UpdateWorksheetFailAction implements Action {
    readonly type = LegacyWorksheetActionType.UpdateFail;

    constructor(public payload: { error: Error; id: string }) {}
}

export class RemoveWorksheetAction implements Action {
    readonly type = LegacyWorksheetActionType.Remove;

    constructor(public payload: { id: string }) {}
}

export class RemoveWorksheetSuccessAction implements Action {
    readonly type = LegacyWorksheetActionType.RemoveSuccess;

    constructor(public payload: { id: string }) {}
}

export class RemoveWorksheetFailAction implements Action {
    readonly type = LegacyWorksheetActionType.RemoveFail;

    constructor(public payload: { error: Error; id: string }) {}
}

export class RenameWorksheetAction implements Action {
    readonly type = LegacyWorksheetActionType.Rename;

    constructor(public payload: { id: string; name: string }) {}
}

export class RenameWorksheetSuccessAction implements Action {
    readonly type = LegacyWorksheetActionType.RenameSuccess;

    constructor(public payload: { update: Update<WorksheetView> }) {}
}

export class RenameWorksheetFailAction implements Action {
    readonly type = LegacyWorksheetActionType.RenameFail;

    constructor(public payload: { error: Error; id: string }) {}
}

export class SelectWorksheetAction implements Action {
    readonly type = LegacyWorksheetActionType.Select;

    constructor(public payload: { id: string }) {}
}

export class SelectWorksheetSuccessAction implements Action {
    readonly type = LegacyWorksheetActionType.SelectSuccess;

    constructor(public payload: { id: string }) {}
}

export class DeselectWorksheetAction implements Action {
    readonly type = LegacyWorksheetActionType.Deselect;
}

export class DeselectWorksheetSuccessAction implements Action {
    readonly type = LegacyWorksheetActionType.DeselectSuccess;
}

export class SetDefaultWorksheetGridOptionsAction implements Action {
    readonly type = LegacyWorksheetActionType.SetDefaultWorksheetGridOptions;

    constructor(public payload: { columns: ColumnDefinition[]; sort: ColumnSort }) {}
}

export class SetDefaultWorksheetQueriesAction implements Action {
    readonly type = LegacyWorksheetActionType.SetDefaultWorksheetQueries;

    constructor(public payload: { queries: string[] }) {}
}

export class UpdateCurrentWorksheetColumnsAction implements Action {
    readonly type = LegacyWorksheetActionType.UpdateCurrentWorksheetColumns;

    constructor(public payload: { columns: ColumnDefinition[] }) {}
}

export class UpdateCurrentWorksheetSortAction implements Action {
    readonly type = LegacyWorksheetActionType.UpdateCurrentWorksheetSort;

    constructor(public payload: { sort: ColumnSort }) {}
}

export class UpdateCurrentWorksheetQueriesAction implements Action {
    readonly type = LegacyWorksheetActionType.UpdateCurrentWorksheetQueries;

    constructor(public payload: { queries: string[] }) {}
}

export class UpdateCurrentWorksheetSavableAction implements Action {
    readonly type = LegacyWorksheetActionType.UpdateCurrentWorksheetSaveable;

    constructor(public payload: { savable: boolean }) {}
}

export class SetRenameAction implements Action {
    readonly type = LegacyWorksheetActionType.SetRename;

    constructor(public payload: { id: string }) {}
}

export class CancelRenameAction implements Action {
    readonly type = LegacyWorksheetActionType.CancelRename;

    constructor(public payload: { id: string }) {}
}

export class RemoveErroredWorksheetAction implements Action {
    readonly type = LegacyWorksheetActionType.RemoveErroredWorksheet;

    constructor(public payload: { id: string }) {}
}

export class PerformGridSetupAction implements Action {
    readonly type = LegacyWorksheetActionType.PerformGridSetup;
}

export class LocalStorageQueryPerformedAction implements Action {
    readonly type = LegacyWorksheetActionType.LocalStorageQueryPerformed;

    constructor(public payload: { worksheetId: string; currentWorksheet: CreateWorksheetModel }) {}
}

export class EditedSavedWorksheetLoadCompletedAction implements Action {
    readonly type = LegacyWorksheetActionType.EditedSavedWorksheetLoadCompleted;
}

export type WorksheetAction =
    | GetAllWorksheetsAction
    | GetAllWorksheetsSuccessAction
    | GetAllWorksheetsFailAction
    | SaveWorksheetAction
    | SaveWorksheetSuccessAction
    | SaveWorksheetFailAction
    | UpdateWorksheetAction
    | UpdateWorksheetSuccessAction
    | UpdateWorksheetFailAction
    | RemoveWorksheetAction
    | RemoveWorksheetSuccessAction
    | RemoveWorksheetFailAction
    | RenameWorksheetAction
    | RenameWorksheetSuccessAction
    | RenameWorksheetFailAction
    | SelectWorksheetAction
    | SelectWorksheetSuccessAction
    | DeselectWorksheetAction
    | DeselectWorksheetSuccessAction
    | SetDefaultWorksheetGridOptionsAction
    | SetDefaultWorksheetQueriesAction
    | UpdateCurrentWorksheetColumnsAction
    | UpdateCurrentWorksheetSortAction
    | UpdateCurrentWorksheetSavableAction
    | UpdateCurrentWorksheetQueriesAction
    | SetRenameAction
    | CancelRenameAction
    | RemoveErroredWorksheetAction
    | PerformGridSetupAction
    | LocalStorageQueryPerformedAction
    | EditedSavedWorksheetLoadCompletedAction;
