import { Component, Input, TemplateRef, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "ops-placeholder",
    templateUrl: "./placeholder.component.html",
    styleUrls: ["./placeholder.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class PlaceholderComponent {
    static componentName = "PlaceholderComponent";

    @Input() icon: TemplateRef<void>;
    @Input() title: string;
    @Input() description: string;
}
