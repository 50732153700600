import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import {
    CoaFeatureState,
    Company,
    CompanyForm,
    CompanyRoleOption,
    focusLiftingCompanyRole,
    initLiftingContactsPanel,
    selectLiftingCompanyForm,
    selectCurrentLiftingCompanies,
    selectCurrentLiftingCompanyRoles,
    removeLiftingCompany
} from "../../../coa/state";
import { LeftBarStateService } from "../../left-bar-state.service";

@Component({
    selector: "ops-lifting-contacts-shell",
    templateUrl: "./lifting-contacts-shell.component.html",
    styleUrls: ["./lifting-contacts-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiftingContactsShellComponent implements OnInit, OnDestroy {
    static componentName = "LiftingContactsComponent";
    private readonly destroy$ = new Subject();

    form$: Observable<FormGroupState<CompanyForm>>;
    companies$: Observable<ReadonlyArray<Company>>;
    companyRoles$: Observable<ReadonlyArray<CompanyRoleOption>>;

    constructor(private leftBarStateService: LeftBarStateService, private store: Store<CoaFeatureState>) {
        this.form$ = this.store.select(selectLiftingCompanyForm);
        this.companies$ = this.store.select(selectCurrentLiftingCompanies);
        this.companyRoles$ = this.store.select(selectCurrentLiftingCompanyRoles);
    }

    ngOnInit() {
        this.store.dispatch(initLiftingContactsPanel());

        this.leftBarStateService.isCollapsed$
            .pipe(
                takeUntil(this.destroy$),
                filter((isCollapsed) => !isCollapsed)
            )
            .subscribe(() => {
                this.store.dispatch(focusLiftingCompanyRole());
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    removeCompany(company: Company) {
        this.store.dispatch(removeLiftingCompany({ company }));
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }
}
