import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    selector: "ops-expense-summary",
    templateUrl: "./expense-summary.component.html",
    styleUrls: ["./expense-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpenseSummaryComponent {
    static componentName = "ExpenseSummaryComponent";

    @Input() expenseType: string;
    @Input() receivedFromOwnerDate: string;
    @Input() currency: string;
    @Input() value: string;
    @Input() paidDate: string;
    @Input() commissionable: boolean;
}
