import { addBerthReducer } from "./form/add-berth";
import { cloneBerthReducer } from "./form/clone-berth";
import { collapseBerthReducer, expandBerthReducer } from "./form/expand-berth";
import { auditGeneratePortTimesEmailSuccessReducer } from "./form/generate-port-times-email";
import { moveBerthDownReducer, moveBerthUpReducer } from "./form/move-berth";
import { removeBerthReducer } from "./form/remove-berth";
import { verifyEtbSuccessReducer } from "./form/verify-etb";

export default [
    addBerthReducer,
    cloneBerthReducer,

    collapseBerthReducer,
    expandBerthReducer,

    auditGeneratePortTimesEmailSuccessReducer,

    moveBerthUpReducer,
    moveBerthDownReducer,

    removeBerthReducer,

    verifyEtbSuccessReducer
];
