import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AppConfigService, AppInsightsService } from "@ops/core";

import { ActionDto } from "../models/action.dto";

export type FixtureId = Readonly<{ fixtureId: string; source: "Fixture" }>;
export type CoaId = Readonly<{ coaId: string; source: "Coa" }>;
export type LiftingId = Readonly<{ coaId: string; liftingId: string; source: "Lifting" }>;
export type OriginId = FixtureId | CoaId | LiftingId;

@Injectable({
    providedIn: "root"
})
export class ActionHttpService {
    constructor(private httpClient: HttpClient, private appInsightsMonitoringService: AppInsightsService, private appConfigService: AppConfigService) {
        // replaced actionsServiceUrl with lazily initialized appConfigService (https://github.com/ngrx/platform/issues/931)
        // Using APP_INITIALIZER together with ngstore and effects does not work as expected. Effects are initialized
        // before the promise via APP_INITIALIZER is completed. APP_INITIALIZER in this case is used to provide
        // a at-runtime environment config via a get call to a JSON file.
        // We need to migrate AppConfigService to use ngstore.
    }

    getV2(origin: OriginId): Observable<ActionDto[]> {
        return this.httpClient.get<ActionDto[]>(this.getUrlPrefix(origin));
    }

    getActionV2(origin: OriginId, actionId: string): Observable<ActionDto> {
        return this.httpClient.get<ActionDto>(`${this.getUrlPrefix(origin)}/${actionId}`);
    }

    createV2(origin: OriginId, action: ActionDto): Observable<string[]> {
        return this.httpClient.post<string[]>(this.getUrlPrefix(origin), action);
    }

    updateV2(origin: OriginId, action: ActionDto): Observable<string[]> {
        return this.httpClient.put<string[]>(`${this.getUrlPrefix(origin)}/${action.actionId}`, action);
    }

    deleteV2(origin: OriginId, actionId: string): Observable<unknown> {
        return this.httpClient.delete(`${this.getUrlPrefix(origin)}/${actionId}`);
    }

    patchIsCompleteV2(origin: OriginId, actionId: string, isComplete: boolean): Observable<ActionDto> {
        const request = { isComplete };
        return this.httpClient.patch<ActionDto>(`${this.getUrlPrefix(origin)}/${actionId}/is-complete`, request);
    }

    patchDueDateV2(origin: OriginId, actionId: string, dueDate: Date): Observable<ActionDto> {
        const request = { dueDate };
        return this.httpClient.patch<ActionDto>(`${this.getUrlPrefix(origin)}/${actionId}/due-date`, request);
    }

    private getUrlPrefix(origin: OriginId): string {
        if (!origin) {
            throw Error("ActionHttpService: origin not specified");
        }

        switch (origin.source) {
            case "Fixture":
                return `${this.appConfigService.config.actionsServiceUrl}/api/v2.0/fixtures/${origin.fixtureId}/actions`;
            case "Coa":
                return `${this.appConfigService.config.actionsServiceUrl}/api/v2.0/coa/${origin.coaId}/actions`;
            case "Lifting":
                return `${this.appConfigService.config.actionsServiceUrl}/api/v2.0/coa/${origin.coaId}/lifting/${origin.liftingId}/actions`;
        }
    }
}
