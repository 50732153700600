import { createAction, on, On } from "@ngrx/store";
import { createFormGroupState, focus, updateGroup } from "ngrx-forms";

import { currentCoaStateReducer } from "../../coa/reducer";
import { CoasState, companyForm, CompanyForm, getFormGroupId } from "../../model";

/* ACTIONS */
export const initContactsPanelAction = createAction("[Coa Contacts Panel] Open Panel");
export const focusCompanyRoleAction = createAction("[Coa Contacts Panel] Focus Company Role");

/* REDUCERS */
export const initContactsPanelReducer: On<CoasState> = on(initContactsPanelAction, (state) =>
    currentCoaStateReducer(state, (coaState) => {
        const initialForm = coaState.companyForm || createFormGroupState(getFormGroupId(state.currentCoaId), companyForm());
        const form = updateGroup<CompanyForm>({ companyRole: focus })(initialForm);

        return {
            ...coaState,
            companyForm: form
        };
    })
);

export const focusCompanyRoleReducer: On<CoasState> = on(focusCompanyRoleAction, (state) =>
    currentCoaStateReducer(state, (coaState) => ({
        ...coaState,
        companyForm: updateGroup<CompanyForm>({ companyRole: focus })(coaState.companyForm)
    }))
);
