import { Injectable } from "@angular/core";

import { CommandHandler } from "../../../mediator";
import { Fixture } from "../../../shared/models";
import { CpSpeedAndConsumptionModel } from "../../../shared/models/form-models/cp-speed-and-consumption.model";
import { UpdateCpSpeedAndConsumptionCommand } from "./update-cp-speed-and-consumption.command";

@Injectable({
    providedIn: "root"
})
export class UpdateCpSpeedAndConsumptionCommandCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateCpSpeedAndConsumptionCommand) {
        const cpSpeedAndConsumptionForm = command.payload as CpSpeedAndConsumptionModel;
        if (!cpSpeedAndConsumptionForm) {
            throw new Error("Given CpSpeedAndConsumptionForm form model is invalid.");
        }

        fixture.cpSpeedAndConsumption.maxBeaufortForce = (cpSpeedAndConsumptionForm.maxBeaufortForce && Number(cpSpeedAndConsumptionForm.maxBeaufortForce)) || null;
        fixture.cpSpeedAndConsumption.comments = cpSpeedAndConsumptionForm.comments;
    }
}
