<div class="ops-ltc-card">
    <p-table [value]="deductions" [formGridReadonly]="false" dataKey="id" [rowTrackBy]="trackBy"
        tableStyleClass="ops-form-grid ops-ltc-form-grid ops-enter-deductions-grid">
        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th>Time From</th>
                <th>Time To</th>
                <th>Remarks</th>
                <th></th>
                <th class="text-right">%</th>
                <th>Duration</th>
                <th class="pl-0">
                    <ops-icon-button data-cy="clear-deduction" type="delete" tooltip="Clear All"
                        *ngIf="deductions?.length" (click)="clear.emit()" (keyup.enter)="clear.emit()">
                    </ops-icon-button>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr class="p-grid" formGridRow [attr.rowkey]="rowData.form.value.id" data-cy="deduction-row">
                <ng-template #tooltip>
                    <div class="d-flex flex-column" data-cy="deduction-warning-message">
                        <span *ngIf="rowData.beforeFirstLaytimeEvent">Deduction start date is before the first laytime event</span>
                        <span *ngIf="rowData.afterLastLaytimeEvent">Deduction end date is after the last laytime event</span>
                        <span *ngIf="rowData.overridesLaytimeEvent">Deduction duration will overwrite existing laytime events</span>
                    </div>
                </ng-template>
                <td class="ops-fg-cell-readonly" data-cy="deduction-warning">
                    <div class="d-flex w-100 justify-content-center">
                        <span class="has-icon icon--flag" *ngIf="hasTooltip(rowData)" [ngbTooltip]="tooltip"
                            placement="right"></span>
                    </div>
                </td>
                <td pEditableColumn [formGridCell]="rowData.form.controls.timeFrom" data-cy="deduction-time-from">
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ops-date-input [enableTime]="true" tabbingBehaviour="time"
                                [ngrxFormControlState]="rowData.form.controls.timeFrom"
                                [ngrxValueConverter]="dateValueConverter" timeZone="local" formGridInput
                                [ngbPopover]="timeFromValidation"
                                (focus)="onDateFocus(rowData.form.controls.timeFrom.id, $event)"
                                (blur)="onDateBlur(rowData.form.controls.timeFrom.id, $event)"
                                (closed)="onDateBlur(rowData.form.controls.timeFrom.id, $event)">
                            </ops-date-input>
                            <ng-template #timeFromValidation>
                                <ops-field-validation
                                    *ngIf="rowData.form.controls.timeFrom.errors.valueRequired && rowData.form.controls.timeFrom.isTouched"
                                    fieldDisplayName="Time From" errorMessage="is required">
                                </ops-field-validation>
                                <ops-field-validation *ngIf="rowData.form.controls.timeFrom.errors.exceedsTimeTo" fieldDisplayName="Time From"
                                    errorMessage="should be no later than Time To">
                                </ops-field-validation>
                                <ops-field-validation *ngIf="rowData.form.controls.timeFrom.errors.overlapsDeduction" fieldDisplayName="Time From"
                                    errorMessage="overlaps another deduction">
                                </ops-field-validation>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{rowData.form.value.timeFrom | maritimeDate:true}}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>

                <td pEditableColumn [formGridCell]="rowData.form.controls.timeTo" data-cy="deduction-time-to">
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ops-date-input [enableTime]="true" tabbingBehaviour="time"
                                [ngrxFormControlState]="rowData.form.controls.timeTo"
                                [ngrxValueConverter]="dateValueConverter" timeZone="local" formGridInput
                                [ngbPopover]="timeToValidation"
                                (focus)="onDateFocus(rowData.form.controls.timeTo.id, $event)"
                                (blur)="onDateBlur(rowData.form.controls.timeTo.id, $event)"
                                (closed)="onDateBlur(rowData.form.controls.timeTo.id, $event)">
                            </ops-date-input>
                            <ng-template #timeToValidation>
                                <ops-field-validation
                                    *ngIf="rowData.form.controls.timeTo.errors.valueRequired && rowData.form.controls.timeTo.isTouched"
                                    fieldDisplayName="Time To" errorMessage="is required">
                                </ops-field-validation>
                                <ops-field-validation
                                    *ngIf="rowData.form.controls.timeFrom.errors.overlapsDeduction"
                                    fieldDisplayName="Time To" errorMessage="overlaps another deduction">
                                </ops-field-validation>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{rowData.form.value.timeTo | maritimeDate:true}}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>

                <td pEditableColumn [formGridCell]="rowData.form.controls.remarks" data-cy="deduction-remarks">
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ng-select formGridInput class="ops-select" [ngrxFormControlState]="rowData.form.controls.remarks"
                                [items]="laytimeEventRemarks" [clearable]="true" [selectOnTab]="true" placeholder="Select">
                            </ng-select>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{rowData.form.value.remarks}}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>

                <td class="ops-fg-cell-readonly">
                    <div class="d-flex w-100 justify-content-center">
                        <span class="has-icon icon--stop-outline"></span>
                    </div>
                </td>

                <td class="ops-fg-cell-readonly text-right">
                    <span class="ops-fg-cell-value">0</span>
                </td>

                <td class="ops-fg-cell-readonly">
                    <span class="ops-fg-cell-value">{{rowData.duration | durationFormat: durationUnit }}</span>
                </td>

                <td pEditableColumn pFocusCellSelector="button" class="ops-fg-action-cell">
                    <ops-icon-button type="delete" (click)="remove.emit(rowData.form.value.id)"
                        (keyup.enter)="remove.emit(rowData.form.value.id)"></ops-icon-button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="footer">
            <a class="ops-fg-add-row" (click)="add.emit()" (keydown.enter)="add.emit()" (keydown.space)="add.emit()"
                tabindex="0" data-cy="add-deduction">Enter Deductions</a>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr class="ops-fg-no-data ops-fg-readonly">
                <td class="ops-fg-no-content-cell">
                    <div>
                        <span class="has-icon icon--lightbulb-outline"></span>
                        <span data-cy="no-deductions">No deductions are currently added</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
