import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { VoyageNumberService } from "src/app/fixture/services/voyage-number.service";
import { TcVoyageModel } from "../shared/tc-voyage.model";

@Component({
    selector: "ops-tc-voyage",
    templateUrl: "./tc-voyage.component.html",
    styleUrls: ["./tc-voyage.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TcVoyageComponent implements OnDestroy {
    static componentName = "TcVoyageComponent";
    private readonly destroy$ = new Subject();

    @Input() voyage: TcVoyageModel;
    @Output() voyageSelected = new EventEmitter<any>();

    elementRef: ElementRef;
    voyageNumber$: Observable<string>;

    get Voyage(): TcVoyageModel {
        return this.voyage;
    }

    constructor(elementRef: ElementRef, voyageNumberService: VoyageNumberService) {
        this.elementRef = elementRef;
        this.voyageNumber$ = voyageNumberService.voyageNumbers$.pipe(
            takeUntil(this.destroy$),
            filter((voyageNumbers) => !!voyageNumbers.length),
            map((voyageNumbers) => voyageNumbers.find((voyageItem) => voyageItem.voyageId === this.voyage.voyageId)),
            filter((voyageItem) => !!voyageItem),
            map((voyageItem) => voyageItem.voyageNumber)
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    voyageClicked(event$: any) {
        this.voyageSelected.emit(this.voyage);
    }
}
