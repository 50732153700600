import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props } from "@ngrx/store";
import {
    UpdateSuggestionTerm,
    UpdateSuggestionTermFail,
    updateSuggestionTermFailReducer,
    updateSuggestionTermReducer,
    UpdateSuggestionTermSuccess,
    updateSuggestionTermSuccessReducer
} from "@ops/state";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { CoaHttpService } from "../../services/coa-http.service";
import { CoasState } from "../model/state";

/* ACTIONS */
const ACTION_NAME = "[Coa Grid] Update Suggestion Term";

export const updateCoaGridSuggestionTermAction = createAction(ACTION_NAME, props<UpdateSuggestionTerm>());
export const updateCoaGridSuggestionTermSuccessAction = createAction(`${ACTION_NAME} Success`, props<UpdateSuggestionTermSuccess>());
export const updateCoaGridSuggestionTermFailAction = createAction(`${ACTION_NAME} Fail`, props<UpdateSuggestionTermFail>());

/* REDUCERS */
export const updateCoaGridSuggestionTermReducer: On<CoasState> = on(updateCoaGridSuggestionTermAction, (state, action) => updateSuggestionTermReducer(state, action, "grid"));
export const updateCoaGridSuggestionTermSuccessReducer: On<CoasState> = on(updateCoaGridSuggestionTermSuccessAction, (state, action) =>
    updateSuggestionTermSuccessReducer(state, action, "grid")
);
export const updateCoaGridSuggestionTermFailReducer: On<CoasState> = on(updateCoaGridSuggestionTermFailAction, (state, action) =>
    updateSuggestionTermFailReducer(state, action, "grid")
);

/* EFFECTS */
export const updateSuggestionTermEffect$ = (actions$: Actions, coaHttpService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(updateCoaGridSuggestionTermAction),
            switchMap(({ suggestionTerm }) =>
                coaHttpService.getSuggestions(suggestionTerm).pipe(
                    map((items) => updateCoaGridSuggestionTermSuccessAction({ items: items })),
                    catchError((error) => of(updateCoaGridSuggestionTermFailAction({ error: error })))
                )
            )
        )
    );
