import { box, Boxed, disable, enable, FormGroupState, setValue, StateUpdateFns, unbox, updateArray, updateGroup, ValidationErrors } from "ngrx-forms";
import { greaterThanOrEqualTo } from "ngrx-forms/validation";

import { deepCopy, isNullOrUndefined } from "@ops/shared";
import { FreightRateUnit, FreightType, QuantityUnit } from "@ops/shared/reference-data";
import { hasValue, validateRequired, validateRequiredIf } from "@ops/state";

import { Division, FixtureType, FreightUnit } from "../../../shared/models";
import { ToleranceUnit } from "../../../shared/models/enums/tolerance-unit";
import {
    BaseFreightRateUnit,
    CargoForm,
    CargoId,
    FixtureState,
    isDivision,
    isFixtureFreightType,
    isFixtureType,
    Location,
    ToleranceForm,
    ToleranceFormUnit,
    VoyageForm,
    VoyageState
} from "../../model";

export interface QuantityUnitIncompatibleError<T> {
    quantityUnit: QuantityUnit;
    actual: T;
}

// @ts-ignore
declare module "ngrx-forms/src/state" {
    export interface ValidationErrors {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        quantityUnitIncompatible?: QuantityUnitIncompatibleError<any>;
    }
}

const cargoHasAssociatedCargoWithActualFreightRateSet = (voyageForm: VoyageForm, cargoId: CargoId): boolean => {
    for (const destination of voyageForm.destinations) {
        for (const berth of destination.berths) {
            for (const activity of berth.activities) {
                for (const associatedCargo of activity.associatedCargoes) {
                    if (associatedCargo.cargoId === cargoId && !isNullOrUndefined(associatedCargo.freightRate)) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
};

const incompatibleToleranceUnitsMap: Map<QuantityUnit["id"], ToleranceUnit[]> = new Map([
    [QuantityUnit.LumpSum.id, [ToleranceUnit.CBM, ToleranceUnit.MT]],
    [QuantityUnit.MT.id, [ToleranceUnit.CBM]],
    [QuantityUnit.CBM.id, [ToleranceUnit.MT]]
]);

/**
 * Validates the tolerance unit must be compatible with the quantity unit.
 */
const compatibleToleranceUnit = (quantityUnit: QuantityUnit | Boxed<QuantityUnit>) => (value: Boxed<ToleranceFormUnit>): ValidationErrors => {
    const quantityUnitValue = unbox(quantityUnit);

    if (!quantityUnitValue) {
        return {};
    }

    const toleranceUnit = unbox(value);

    if (!toleranceUnit) {
        return {};
    }

    const incompatibleToleranceUnits = incompatibleToleranceUnitsMap.get(quantityUnitValue.id);
    if (incompatibleToleranceUnits?.some(toleranceUnitId => toleranceUnitId === toleranceUnit.id)) {
        return {
            quantityUnitIncompatible: {
                quantityUnit: quantityUnitValue as QuantityUnit,
                actual: toleranceUnit
            }
        };
    }

    return {};
};

/**
 * Validates the base freight rate unit must be lump sum if the quantity unit is lump sum.
 */
const compatibleBaseFreightRateUnit = (quantityUnit: QuantityUnit | Boxed<QuantityUnit>) => (value: Boxed<BaseFreightRateUnit>): ValidationErrors => {
    const quantityUnitValue = unbox(quantityUnit);

    if (!quantityUnitValue) {
        return {};
    }

    const baseFreightRateUnit = unbox(value);

    if (!baseFreightRateUnit) {
        return {};
    }

    if (quantityUnitValue.id === QuantityUnit.LumpSum.id && baseFreightRateUnit.id !== FreightUnit.LumpSum) {
        return {
            quantityUnitIncompatible: {
                quantityUnit: quantityUnitValue as QuantityUnit,
                actual: baseFreightRateUnit
            }
        };
    }

    return {};
};

const validateTolerance = (tolerance: FormGroupState<ToleranceForm>, parent: FormGroupState<CargoForm>) => {
    const hasMinValue = hasValue(tolerance.value.min);
    const hasMaxValue = hasValue(tolerance.value.max);
    const anySpecified = hasValue(tolerance.value.unit) || hasMinValue || hasMaxValue;
    const quantityUnit = unbox(parent.value.quantityUnit);

    return updateGroup<ToleranceForm>(tolerance, {
        min: validateRequiredIf(anySpecified),
        max: validateRequiredIf(anySpecified),
        unit: validateRequiredIf(anySpecified, compatibleToleranceUnit(quantityUnit))
    });
};

export const validateCargoForm = (voyageState: VoyageState, fixtureState: FixtureState) => (form: FormGroupState<CargoForm>) => {
    const conditionalUpdateFns: StateUpdateFns<CargoForm>[] = [];

    if (isDivision(fixtureState, Division.tankers)) {
        const baseFreightRateUnit = unbox(form.value.baseFreightRateUnit);
        const isWorldscale = baseFreightRateUnit && baseFreightRateUnit.id === FreightUnit.Worldscale;

        conditionalUpdateFns.push({
            worldscaleRate: validateRequiredIf(isWorldscale, greaterThanOrEqualTo(0))
        });
    }

    if (isDivision(fixtureState, Division.specialisedProducts)) {
        const locationRequired = (cargo: CargoForm, isLoadActivity: boolean): boolean => {
            const location = isLoadActivity ? cargo.loadLocation : cargo.dischargeLocation;
            const etaRange = unbox(location && location.eta);

            return !!etaRange && !!etaRange.to;
        };
        conditionalUpdateFns.push({
            loadLocation: updateGroup<Location>({
                location: validateRequiredIf(locationRequired(form.value, true))
            }),
            dischargeLocation: updateGroup<Location>({
                location: validateRequiredIf(locationRequired(form.value, false))
            })
        });
    }

    if (form.controls.tolerance) {
        conditionalUpdateFns.push({
            tolerance: validateTolerance
        });
    }

    // Lump Sum disable/setValue logic also handled here (it's a kind of validation) - validation runs every reduction
    // so most efficient here until fixture is migrated to ngrx-forms
    const isLumpSumFixture = isFixtureFreightType(fixtureState, FreightType.LumpSum);
    if (isLumpSumFixture) {
        conditionalUpdateFns.push({
            baseFreightRate: setValue<number>(null),
            baseFreightRateUnit: setValue(box(deepCopy(FreightRateUnit.LumpSum)))
        });
    } else {
        // If the fields aren't disabled, we didn't just switch from lump sum to per cargo
        if (form.controls.baseFreightRate.isDisabled) {
            conditionalUpdateFns.push({
                baseFreightRateUnit: setValue(box(deepCopy(FreightRateUnit.PerMT)))
            });
        }
    }

    if (isFixtureType(fixtureState, FixtureType.Voyage)) {
        conditionalUpdateFns.push({
            cargoProduct: validateRequired()
        });
    }

    const hasCargoWithActualFreightRate = !isLumpSumFixture && cargoHasAssociatedCargoWithActualFreightRateSet(voyageState.form.value, form.value.cargoId);

    return updateGroup<CargoForm>(
        form,
        {
            quantity: validateRequiredIf(hasValue(form.value.quantityUnit), greaterThanOrEqualTo(0)),
            quantityUnit: validateRequiredIf(hasValue(form.value.quantity)),
            baseFreightRate: validateRequiredIf(!isLumpSumFixture && (hasCargoWithActualFreightRate || hasValue(form.value.baseFreightRateUnit)), greaterThanOrEqualTo(0)),
            baseFreightRateUnit: validateRequiredIf(
                !isLumpSumFixture && (hasCargoWithActualFreightRate || hasValue(form.value.baseFreightRateUnit)),
                compatibleBaseFreightRateUnit(form.value.quantityUnit)
            )
        },
        {
            baseFreightRate: isLumpSumFixture ? disable : enable,
            baseFreightRateUnit: isLumpSumFixture ? disable : enable
        },
        ...conditionalUpdateFns
    );
};

export function validateCargoesForm(voyageState: VoyageState, fixtureState: FixtureState) {
    return updateArray<CargoForm>(validateCargoForm(voyageState, fixtureState));
}
