import { ColDef, ColumnApi, ColumnState, GridApi } from "@ag-grid-community/all-modules";
import { Directive, OnDestroy } from "@angular/core";
import { FormGroupState } from "ngrx-forms";
import * as R from "ramda";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { ColumnId, CreateWorksheet, toColumnState, WorksheetRenameForm } from "@ops/state";

import { SortOrder } from "../../../fixture/shared/models";
import { GridType } from "../../../fixture/shared/models/enums/grid-type";
import { WorksheetService } from "../../../state/worksheets/services/worksheet.service";
import { createHasChangedFn } from "../../utils/utils";
import { GridDatasource } from "../datasource/grid-datasource";
import { ISearchService } from "../datasource/search.service";
import { IGridConfigurationService } from "../grid-configuration.service";
import { GridRecordNumbers } from "../grid-record-numbers";
import { GridSettings } from "../settings/grid-settings";

@Directive() // eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class GridComponent implements OnDestroy {
    private readonly destroy$ = new Subject();
    private readonly columnStateHasChanged = createHasChangedFn<ColumnState[]>([]);

    protected gridApi: GridApi;
    protected columnApi: ColumnApi;
    protected datasource: GridDatasource;

    readonly gridSettings: GridSettings = new GridSettings();
    readonly columnDefs: ColDef[];
    readonly columnTypes: unknown;
    readonly gridRecordNumbers$: Observable<GridRecordNumbers>;
    readonly currentWorksheet$: Observable<CreateWorksheet>;
    readonly currentWorksheetSavable$: Observable<boolean>;
    readonly gridRenameForm$: Observable<FormGroupState<WorksheetRenameForm>>;
    readonly gridRenameFormSaving$: Observable<boolean>;
    readonly gridRenameFormSaveFailed$: Observable<boolean>;

    protected constructor(
        private gridType: GridType,
        protected gridConfigurationService: IGridConfigurationService,
        protected searchService: ISearchService,
        protected worksheetService: WorksheetService
    ) {
        this.columnTypes = this.gridConfigurationService.getColumnTypes();
        this.columnDefs = this.gridConfigurationService.getColumnDefinitions();
        this.gridRecordNumbers$ = this.searchService.gridRecordNumbers$.pipe(map((x) => (x.total > 0 ? x : null)));
        this.currentWorksheet$ = this.worksheetService.currentWorksheet$;
        this.currentWorksheetSavable$ = this.worksheetService.currentWorksheetSavable$;
        this.gridRenameForm$ = this.worksheetService.gridRenameForm$;
        this.gridRenameFormSaving$ = this.worksheetService.gridRenameFormSaving$;
        this.gridRenameFormSaveFailed$ = this.worksheetService.gridRenameFormSaveFailed$;
        this.worksheetService.getAllWorksheets(gridType);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.datasource?.removeSubscriptions();
    }

    onGridReady(params: any) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.worksheetService.setDefaultGridOptions(this.columnApi);
        this.manageGridDisplayFromWorksheet();
        this.datasource = new GridDatasource(this.searchService, this.gridApi);
    }

    onSortChanged() {
        const sortModel = this.gridApi.getSortModel();
        this.searchService.updateSorting(sortModel[0]?.colId ?? "", sortModel[0]?.sort ?? "");
    }

    onColumnsChanged() {
        const columnState = this.columnApi?.getColumnState();
        if (this.columnApi && this.columnStateHasChanged(columnState)) {
            this.searchService.updateColumns(columnState);
        }
    }

    cancelNameNewWorksheet() {
        this.worksheetService.cancelNameNewWorksheet();
    }

    saveWorksheetName() {
        this.worksheetService.saveNewWorksheet();
    }

    private manageGridDisplayFromWorksheet() {
        this.currentWorksheet$.pipe(takeUntil(this.destroy$)).subscribe((worksheet) => {
            let colState: ColumnState[];
            let sortModel: { colId: ColumnId; sort: string }[] = [];

            if (worksheet) {
                if (worksheet.columns) {
                    const defaultColumnsIds = new Set(this.columnApi.getColumnState().map((column) => column.colId));
                    colState = R.reject((column) => !defaultColumnsIds.has(column.colId), worksheet.columns.map(toColumnState));
                    // Insert the new columns according to their position in the columns list
                    this.columnApi.getColumnState().forEach((column, index) => {
                        if (!worksheet.columns.some((c) => c.columnId === column.colId)) {
                            const columnToInsert: ColumnState = {
                                colId: column.colId,
                                width: column.width,
                                hide: true,
                                pinned: null
                            };
                            colState.splice(Math.min(index, colState.length), 0, columnToInsert);
                        }
                    });
                }
                if (worksheet.sort) {
                    sortModel = [{ colId: worksheet.sort.columnId, sort: worksheet.sort.order === SortOrder.Ascending ? "asc" : "desc" }];
                }
            }

            if (colState) {
                this.columnApi.applyColumnState({ state: colState, applyOrder: true });
            }

            this.gridApi.setSortModel(sortModel);
        });
    }
}
