import { DateTime } from "luxon";
import { box, Boxed, unbox } from "ngrx-forms";
import { v4 as uuid } from "uuid";

import { deepCopy, Identity } from "@ops/shared";
import { CargoBerthActivityType, DemurrageReason, Enumeration, LaytimeEventType } from "@ops/shared/reference-data";

import { ActivityType } from "./activity";
import { CargoId } from "./cargo";
import { LaytimeEvent, LaytimeEventFact } from "../../shared/models";
import { Division } from "../../shared/models/enums/division";
import { FixtureType } from "../../shared/models/enums/fixture-type";

export type LaytimeEventId = Identity<string, "LaytimeEventId">;
export const createLaytimeEventId = (): LaytimeEventId => uuid() as LaytimeEventId;

export type LaytimeEventForm = Readonly<{
    laytimeEventId: LaytimeEventId;
    type?: Boxed<Readonly<LaytimeEventType>>;
    eventDate?: string;
    percentage?: number;
    isStartOrStop: boolean;
    cargoId?: CargoId;
    demurrageReason?: Boxed<Readonly<DemurrageReason>>;
    comments?: string;
}>;

export const laytimeEventForm = (division: Division, laytimeEventId: LaytimeEventId): LaytimeEventForm => {
    const form: LaytimeEventForm = {
        laytimeEventId,
        type: null,
        eventDate: null,
        percentage: null,
        isStartOrStop: false,
        demurrageReason: null,
        comments: null
    };

    if (division === Division.specialisedProducts || division === Division.pcg) {
        return {
            ...form,
            cargoId: null
        };
    }

    return form;
};

export const laytimeEventToForm =
    (division: Division) =>
    (source: LaytimeEvent): LaytimeEventForm => {
        const form: LaytimeEventForm = {
            laytimeEventId: source.id,
            type: box(deepCopy(source.type)),
            eventDate: source.eventDate,
            percentage: source.percentage,
            isStartOrStop: source.isStartOrStop,
            demurrageReason: box(deepCopy(source.demurrageReason)),
            comments: source.comments
        };

        if (division === Division.specialisedProducts || division === Division.pcg) {
            return {
                ...form,
                cargoId: source.cargoId
            };
        }

        return form;
    };

export const formToLaytimeEvent = (source: LaytimeEventForm, current: LaytimeEvent): LaytimeEvent => ({
    ...current,
    id: source.laytimeEventId,
    type: unbox(source.type),
    eventDate: source.eventDate,
    percentage: source.percentage,
    isStartOrStop: source.isStartOrStop,
    cargoId: source.cargoId,
    demurrageReason: unbox(source.demurrageReason),
    comments: source.comments
});

export type LaytimeEventFactForm = Readonly<{
    laytimeEventId: LaytimeEventId;
    type: Boxed<Readonly<LaytimeEventType>>;
    fromDate?: string;
    toDate?: string;
    comments: string;
    percentage?: number;
    cargoId?: CargoId;
    demurrageReason?: Boxed<Readonly<Enumeration>>;
}>;

export const laytimeEventFactForm = (division: Division, laytimeEventId: LaytimeEventId): LaytimeEventFactForm => ({
    laytimeEventId,
    type: null,
    comments: null
    // TODO: (NGRX)
});

export const laytimeEventFactToForm = (source: LaytimeEventFact): LaytimeEventFactForm => ({
    laytimeEventId: source.id,
    type: box(source.type),
    fromDate: source.fromDate,
    toDate: source.toDate,
    comments: source.comments,
    percentage: source.percentage,
    cargoId: source.cargoId,
    demurrageReason: box(source.demurrageReason)
});

export const formToLaytimeEventFact = (source: LaytimeEventFactForm, current: LaytimeEventFact): LaytimeEventFact => ({
    ...current,
    id: source.laytimeEventId,
    type: unbox(source.type),
    fromDate: source.fromDate,
    toDate: source.toDate,
    comments: source.comments,
    percentage: source.percentage,
    cargoId: source.cargoId,
    demurrageReason: unbox(source.demurrageReason)
    // TODO: (NGRX)
});

const defaultLaytimeEvents = createLaytimeEventsMap([
    // Division = Dry Cargo
    [
        [
            `${Division.dryCargo}_${CargoBerthActivityType.Load.id}`,
            `${Division.dryCargo}_${CargoBerthActivityType.Discharge.id}`,
            `${Division.dryCargo}_${CargoBerthActivityType.Provisional.id}`,
            `${Division.dryCargo}_${CargoBerthActivityType.ProvisionalLoad.id}`,
            `${Division.dryCargo}_${CargoBerthActivityType.ProvisionalDischarge.id}`
        ],
        [LaytimeEventType.NORTendered, LaytimeEventType.CargoCommenced, LaytimeEventType.CargoCompleted, LaytimeEventType.Sailed]
    ],
    // Division = Gas
    [
        [
            `${Division.gas}_${CargoBerthActivityType.Load.id}`,
            `${Division.gas}_${CargoBerthActivityType.Discharge.id}`,
            `${Division.gas}_${CargoBerthActivityType.Provisional.id}`,
            `${Division.gas}_${CargoBerthActivityType.ProvisionalLoad.id}`,
            `${Division.gas}_${CargoBerthActivityType.ProvisionalDischarge.id}`
        ],
        [
            LaytimeEventType.NORTendered,
            LaytimeEventType.Berthed,
            LaytimeEventType.HosesConnected,
            LaytimeEventType.CargoCommenced,
            LaytimeEventType.CargoCompleted,
            LaytimeEventType.HosesDisconnected,
            LaytimeEventType.DocsOnBoard,
            LaytimeEventType.Sailed
        ]
    ],
    [
        [
            `${FixtureType.TimeCharter}_${Division.gas}_${CargoBerthActivityType.Load.id}`,
            `${FixtureType.TimeCharter}_${Division.gas}_${CargoBerthActivityType.Discharge.id}`,
            `${FixtureType.TimeCharter}_${Division.gas}_${CargoBerthActivityType.Provisional.id}`,
            `${FixtureType.TimeCharter}_${Division.gas}_${CargoBerthActivityType.ProvisionalLoad.id}`,
            `${FixtureType.TimeCharter}_${Division.gas}_${CargoBerthActivityType.ProvisionalDischarge.id}`
        ],
        [LaytimeEventType.NORTendered, LaytimeEventType.CargoCommenced, LaytimeEventType.CargoCompleted, LaytimeEventType.Sailed]
    ],
    // Division = Specialised Products
    [
        [`${Division.specialisedProducts}_${CargoBerthActivityType.Load.id}`, `${Division.specialisedProducts}_${CargoBerthActivityType.Discharge.id}`],
        [
            LaytimeEventType.NORTendered,
            LaytimeEventType.AllFast,
            LaytimeEventType.HosesConnected,
            LaytimeEventType.CargoCommenced,
            LaytimeEventType.CargoCompleted,
            LaytimeEventType.HosesDisconnected
        ]
    ],
    // Division = PCG
    [
        [
            `${Division.pcg}_${CargoBerthActivityType.Load.id}`,
            `${Division.pcg}_${CargoBerthActivityType.Provisional.id}`,
            `${Division.pcg}_${CargoBerthActivityType.ProvisionalLoad.id}`
        ],
        [
            LaytimeEventType.NORTendered,
            LaytimeEventType.NORPlus6Hrs,
            LaytimeEventType.StartShifting,
            LaytimeEventType.StopShifting,
            LaytimeEventType.AllFast,
            LaytimeEventType.HosesConnected,
            LaytimeEventType.CargoCommenced,
            LaytimeEventType.LoadingSuspended,
            LaytimeEventType.LoadingCommenced,
            LaytimeEventType.CargoCompleted,
            LaytimeEventType.HosesDisconnected,
            LaytimeEventType.DocsOnBoard,
            LaytimeEventType.Sailed
        ]
    ],
    [
        [`${Division.pcg}_${CargoBerthActivityType.Discharge.id}`, `${Division.pcg}_${CargoBerthActivityType.ProvisionalDischarge.id}`],
        [
            LaytimeEventType.NORTendered,
            LaytimeEventType.NORPlus6Hrs,
            LaytimeEventType.StartShifting,
            LaytimeEventType.StopShifting,
            LaytimeEventType.AllFast,
            LaytimeEventType.HosesConnected,
            LaytimeEventType.CargoCommenced,
            LaytimeEventType.DischargeSuspended,
            LaytimeEventType.DischargeCommenced,
            LaytimeEventType.CargoCompleted,
            LaytimeEventType.HosesDisconnected,
            LaytimeEventType.DocsOnBoard,
            LaytimeEventType.Sailed
        ]
    ]
]);

export function shouldPrefillLaytimeEventDate(isFirstBerthAndActivity: boolean, type: LaytimeEventType, division: Division) {
    const excludedEventTypes = [LaytimeEventType.Sailed, LaytimeEventType.CargoCompleted, LaytimeEventType.HosesDisconnected];
    const presetDate = isFirstBerthAndActivity;

    return division === Division.dryCargo || division === Division.gas ? presetDate && !excludedEventTypes.some((t) => t.id === type.id) : false;
}

export function getDefaultLaytimeEventDate(arrivalDateTime: string, timeZone: string): string {
    if (arrivalDateTime) {
        const localTime = DateTime.fromISO(arrivalDateTime).setZone(timeZone || "utc");
        const midnight = localTime.startOf("day");

        return midnight.toISO();
    }

    return arrivalDateTime;
}

export function getDefaultLaytimeEvents(
    fixtureType: FixtureType,
    division: Division,
    activityType: ActivityType,
    presetEventDates: boolean,
    arrivalDateTime: string,
    timeZone: string
): LaytimeEventForm[] {
    const events: LaytimeEventForm[] = [];

    if (activityType) {
        const laytimeEventTypes: LaytimeEventType[] =
            defaultLaytimeEvents.get(`${fixtureType}_${division}_${activityType.id}`) ||
            defaultLaytimeEvents.get(`${division}_${activityType.id}`) ||
            defaultLaytimeEvents.get(division.toString()) ||
            [];

        const eventDate = getDefaultLaytimeEventDate(arrivalDateTime, timeZone);

        laytimeEventTypes.forEach((laytimeEventType) => {
            const shouldPrefill = shouldPrefillLaytimeEventDate(presetEventDates, laytimeEventType, division);

            events.push({
                ...laytimeEventForm(division, createLaytimeEventId()),
                type: box(deepCopy(laytimeEventType)),
                eventDate: eventDate && shouldPrefill ? eventDate : null
            });
        });
    }

    return events;
}

function createLaytimeEventsMap(input: [string[], LaytimeEventType[]][]): Map<string, LaytimeEventType[]> {
    const result = new Map<string, LaytimeEventType[]>();
    input.forEach((item) => {
        item[0].forEach((key) => {
            result.set(key, item[1]);
        });
    });
    return result;
}
