import { Inject, Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { Division } from "src/app/fixture/shared/models/enums/division";
import { ComponentValidator } from "src/app/shared/validators/component-validators/component-validator";
import { FixtureDataService } from "../../services/fixture-data.service";

/**
 * @deprecated
 */
@Injectable({ providedIn: "root" })
export class ValidatorProviderService {
    constructor(@Inject("ComponentValidator") private componentValidators: ComponentValidator[]) {}

    getComponentValidators(componentName: string): ComponentValidator[] {
        return this.componentValidators.filter((x) => x.supportedComponentName === componentName);
    }
}
