import { User } from "@ops/state";

export type Audit = Readonly<{
   dateModified: string,
   modifiedByUser: User
}>;

export const auditModel = (dateModified: string, modifiedByUser: User): Audit => ({
    dateModified: dateModified,
    modifiedByUser: modifiedByUser
});
