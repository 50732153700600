<div class="delete-confirmation-container">
    <div #confirmationWrapper class="confirmation-button-wrapper" (transitionend)="animationEnded()" [class.display-confirmation]="displayConfirmation">
        <button
            *ngIf="!animating && displayConfirmation"
            [disabled]="disabled"
            (click)="confirmDelete()"
            (keydown.tab)="handleTab($event)"
            type="button"
            class="confirmation-button"
            data-test-confirm-delete-button
            data-cy="confirm-delete"
        >
            OK
        </button>
        <!-- Do not remove this div! Absolute positioned confirmation-button-wrapper is not visible if
        it is empty when animating -->
        <div class="animation-container" *ngIf="animating">&nbsp;</div>
    </div>
    <div class="toggle-button-wrapper" [class.show-bg]="showBg">
        <button
            [disabled]="disabled"
            (click)="toggleConfirmation()"
            (clickOutside)="clickOutside()"
            (keydown.tab)="handleTab($event)"
            type="button"
            [ngClass]="buttonClass"
            [class.icon--delete]="!showBg"
            [class.icon--close]="showBg"
            [class.confirmation-button]="showBg"
            class="has-icon"
            data-test-delete-confirmation-toggle-button
            #toggle
            data-cy="delete"
        ></button>
    </div>
</div>
