import { AbstractControlState, Boxed, FormState, setUserDefinedProperty, setValue, unbox, Unboxed, validate, ValidationErrors } from "ngrx-forms";
import { ValidationFn } from "ngrx-forms/src/update-function/validate";
import { required } from "ngrx-forms/validation";

import { QuantityModel } from "../fixture/shared/models/form-models";

export const REQUIRED_USER_PROPERTY = "required";
export const markRequired = (isRequired: boolean = true) => setUserDefinedProperty(REQUIRED_USER_PROPERTY, isRequired);
export const getIsRequired = <TValue>(state: AbstractControlState<TValue> | AbstractControlState<Boxed<TValue>>) => !!state.userDefinedProperties[REQUIRED_USER_PROPERTY];

export const IS_VISIBLE_USER_PROPERTY = "isVisible";
export const setIsVisible = (isVisible: boolean = true) => setUserDefinedProperty(IS_VISIBLE_USER_PROPERTY, isVisible);
export const getIsVisible = <TValue>(state: AbstractControlState<TValue> | AbstractControlState<Boxed<TValue>>) => !!state.userDefinedProperties[IS_VISIBLE_USER_PROPERTY];

/**
 * Validates a property is required and also sets the user defined property to enable
 * awareness of the field being required.
 *
 * This should be used when the condition will NOT change based on other input values.
 */
export const validateRequired = () => <TValue>(state: AbstractControlState<TValue>): FormState<TValue> => {
    const updatedState = validate(required)(state);
    return <FormState<TValue>>markRequired()(updatedState);
};

/**
 * Validates a property is required and also sets the user defined property to enable
 * awareness of the field being required.
 *
 * This should be used when the condition is likely to change based on other input values.
 */
export const validateRequiredIf = <TValue>(condition: boolean | (() => boolean), ...otherFns: ValidationFn<TValue>[]) => (
    state: AbstractControlState<TValue>
): FormState<TValue> => {
    const evaluatedCondition = typeof condition === "function" ? condition() : condition;

    const updatedState = validate(requiredIf(condition), ...otherFns)(state);
    return <FormState<TValue>>markRequired(evaluatedCondition)(updatedState);
};

export const requiredIf = <T>(condition: boolean | (() => boolean)): ((value: T | Boxed<T> | null | undefined) => ValidationErrors) => {
    const evaluatedCondition = typeof condition === "function" ? condition() : condition;

    return evaluatedCondition ? required : (_) => ({});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requiredIfDependants = <T>(dependants: AbstractControlState<any>[]) => (value: T | Boxed<T> | null | undefined): ValidationErrors => {
    value = unbox(value) as T | null | undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (value !== undefined && value !== null && (value as any).length !== 0) {
        return {};
    }

    if (dependants.some((c) => hasValue(getControlValue(c)))) {
        return required(value);
    }
    return {};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateRequiredIfDependants = <TValue>(dependants: AbstractControlState<any>[], ...otherFns: ValidationFn<TValue>[]) => (
    state: AbstractControlState<TValue>
): FormState<TValue> => {
    const updatedState = validate(requiredIfDependants(dependants), ...otherFns)(state);
    return <FormState<TValue>>markRequired(!!updatedState.errors.required)(updatedState);
};

export const getControlValue = <TValue>(control: AbstractControlState<TValue> | AbstractControlState<Boxed<TValue>>): TValue | Unboxed<TValue> =>
    !control ? null : unbox(control.value);

export const hasValue = <TValue>(value: TValue | Boxed<TValue> | TValue[] | null | undefined) => {
    const unboxedValue = value && unbox(value);
    return unboxedValue !== undefined && unboxedValue !== null && (unboxedValue as TValue[]).length !== 0;
};

export const replaceValue = <TValue>(valueFn: (value: TValue) => TValue): ((state: AbstractControlState<TValue>) => FormState<TValue>) => (state) =>
    setValue<TValue>(valueFn(state.value))(state);

export const requiredNotEmptyOrWhitespace = <T extends string | Boxed<string> | null | undefined>(value: T): ValidationErrors => {
    const valueToCheck = unbox(value) as string | null | undefined;
    const isEmptyOrWhitespace = !valueToCheck?.trim().length;

    if (!isEmptyOrWhitespace) {
        return {};
    }

    return {
        required: {
            actual: valueToCheck
        }
    };
};

export const requiredNotEmptyOrWhitespaceIf = <T extends string | Boxed<string> | null | undefined>(condition: boolean | (() => boolean)): ((value: T) => ValidationErrors) => {
    const evaluatedCondition = typeof condition === "function" ? condition() : condition;

    return evaluatedCondition ? requiredNotEmptyOrWhitespace : (_) => ({});
};

export const validateRequiredNotEmptyOrWhitespaceIf = <T extends string | Boxed<string> | null | undefined>(
    condition: boolean | (() => boolean),
    ...otherFns: ValidationFn<T>[]
) => (state: AbstractControlState<T>): FormState<T> => {
    const evaluatedCondition = typeof condition === "function" ? condition() : condition;

    const updatedState = validate(requiredNotEmptyOrWhitespaceIf(condition), ...otherFns)(state);
    return <FormState<T>>markRequired(evaluatedCondition)(updatedState);
};

export const validQuantityModel = (state: Boxed<QuantityModel>): ValidationErrors => {
    if (!state) {
        return {};
    }

    const quantityWithUnit = unbox(state);
    if ((!quantityWithUnit.value && !quantityWithUnit.unit) || (!!quantityWithUnit.value && !!quantityWithUnit.unit)) {
        return {};
    }

    return !!quantityWithUnit.value ? { valueWithoutUnit: true } : { unitWithoutValue: true };
};

export const validNominatedQuantityModel = (state: Boxed<QuantityModel>): ValidationErrors => {
    if (!state) {
        return {};
    }

    const quantityWithUnit = unbox(state);
    return quantityWithUnit.value < 0 ? { valueIsNegative: true } : validQuantityModel(state);
};

/**
 * Wraps a number validation function with a conversion from a string.
 *
 * @param fn The validation function.
 */
export const numberString = (fn: ValidationFn<number>): ValidationFn<string> => (value) => {
    const number = Number(value);

    return !isNaN(number) ? fn(number) : {};
};
