import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroupState } from "ngrx-forms";
import { CompanyForm, CompanyRoleOption } from "src/app/coa/state";

@Component({
    selector: "ops-coa-company-search",
    templateUrl: "./company-search.component.html",
    styleUrls: ["./company-search.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoaCompanySearchComponent {
    @Input() form: FormGroupState<CompanyForm>;
    @Input() roles: CompanyRoleOption[];
}
