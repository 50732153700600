<tr>
    <td class="p-0 cargo-component-td">
        <div class="d-flex w-100" data-test-togglecargocomments>
            <button
                type="button"
                class="has-icon"
                [class.icon--arrow-drop-right]="!expanded"
                [class.icon--arrow-drop-down]="expanded"
                (click)="toggle()"
                data-test-toggle-location-button
            ></button>
        </div>
    </td>
    <td class="p-0 cargo-component-td">
        <div class="ops-select form-group">
            <ops-cargo-autosuggest
                [ngrxFormControlState]="form.controls.cargoProduct"
                opsNgrxWarningFormControl
                [ngbPopover]="productValidation"
                #productPopover="ngbPopover"
                [readonly]="readonly"
                (blur)="productPopover.close()"
                (focus)="productPopover.open()"
                triggers="manual"
                [autoClose]="false"
                data-test-cargo
            >
            </ops-cargo-autosuggest>
        </div>
        <ng-template #productValidation>
            <ops-field-validation *ngIf="form.controls.cargoProduct.errors.required && form.controls.cargoProduct.isTouched" fieldDisplayName="Cargo" errorMessage="is required">
            </ops-field-validation>
        </ng-template>
    </td>
    <td class="p-0 cargo-component-td">
        <div class="form-group">
            <input
                [opsNumber]="{ precision: 2 }"
                type="text"
                class="form-control text-right"
                [ngrxFormControlState]="form.controls.quantity"
                [ngbPopover]="quantityValidation"
                #quantity="ngbPopover"
                (blur)="quantity.close()"
                (focus)="quantity.open()"
                [autoClose]="false"
                triggers="manual"
                [readonly]="readonly"
                data-test-cargoquantity
            />
        </div>
        <ng-template #quantityValidation>
            <ops-field-validation
                *ngIf="form.controls.quantity.errors.required && form.controls.quantity.isTouched"
                fieldDisplayName="Quantity"
                errorMessage="is required">
            </ops-field-validation>
            <ops-field-validation
                *ngIf="form.controls.quantity.errors.greaterThanOrEqualTo && form.controls.quantity.isTouched"
                fieldDisplayName="Quantity"
                errorMessage="must be greater than or equal to {{form.controls.quantity.errors.greaterThanOrEqualTo.comparand}}">
            </ops-field-validation>
        </ng-template>
    </td>
    <td class="p-0 cargo-component-td">
        <div class="form-group">
            <ops-refdata-dropdown
                type="QuantityUnit"
                [readonly]="readonly"
                placeholder="Select"
                opsNgrxWarningFormControl
                [ngrxFormControlState]="form.controls.quantityUnit"
                allowClear="true"
                [ngbPopover]="quantityUnitValidation"
                #quantityUnitPopover="ngbPopover"
                data-test-quantityunit
                (blur)="quantityUnitPopover.close()"
                (focus)="quantityUnitPopover.open()"
                triggers="manual"
                [autoClose]="false"
            >
            </ops-refdata-dropdown>
        </div>
        <ng-template #quantityUnitValidation>
            <ops-field-validation
                *ngIf="form.controls.quantityUnit.errors.required && form.controls.quantityUnit.isTouched"
                fieldDisplayName="Quantity Unit"
                errorMessage="is required"
            >
            </ops-field-validation>
        </ng-template>
    </td>

    <td *ngIf="form.controls.tolerance" class="p-0 cargo-component-td">
        <div class="form-group" [class.ngrx-form-group-touched]="toleranceForm.isTouched">
            <input
                [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                type="text"
                class="form-control text-right"
                [ngrxFormControlState]="toleranceForm.controls.min"
                [ngbPopover]="tolMinValidation"
                #tolMinPopover="ngbPopover"
                (blur)="tolMinPopover.close()"
                (focus)="tolMinPopover.open()"
                triggers="manual"
                [autoClose]="false"
                [readonly]="readonly"
                data-test-tolerance-min
            />
        </div>
        <ng-template #tolMinValidation>
            <div *ngIf="toleranceForm.controls.min.errors.required && toleranceForm.controls.min.isTouched"
                class="row no-gutters display">
                <span class="has-icon icon--warning col-1"></span>
                <span class="content col-11" data-test-tolerance-min-required><b>Tol. Min</b> is required.</span>
            </div>
        </ng-template>
    </td>
    <td *ngIf="form.controls.tolerance" class="p-0 cargo-component-td">
        <div class="form-group" [class.ngrx-form-group-touched]="toleranceForm.isTouched">
            <input
                [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                type="text"
                class="form-control text-right"
                [ngrxFormControlState]="toleranceForm.controls.max"
                [ngbPopover]="tolMaxValidation"
                #tolMaxPopover="ngbPopover"
                (blur)="tolMaxPopover.close()"
                (focus)="tolMaxPopover.open()"
                triggers="manual"
                [readonly]="readonly"
                [autoClose]="false"
                data-test-tolerance
            />
        </div>
        <ng-template #tolMaxValidation>
            <div *ngIf="toleranceForm.controls.max.errors.required && toleranceForm.controls.max.isTouched"
                class="row no-gutters display">
                <span class="has-icon icon--warning col-1"></span>
                <span class="content col-11" data-test-tolerance-max-required><b>Tol. Max</b> is required.</span>
            </div>
            <div *ngIf="toleranceForm.controls.max.errors.greaterThanOrEqualTo && toleranceForm.isTouched"
                class="row no-gutters display">
                <span class="has-icon icon--warning col-1"></span>
                <span class="content col-11" data-test-tolerance-max-invalid-range>Invalid range.</span>
            </div>
        </ng-template>
    </td>
    <td *ngIf="form.controls.tolerance" class="p-0 cargo-component-td">
        <div class="form-group" [class.ngrx-form-group-touched]="toleranceForm.isTouched">
            <ops-refdata-dropdown
                type="ToleranceUnit"
                [readonly]="readonly"
                placeholder="Select"
                opsNgrxWarningFormControl
                [ngrxFormControlState]="toleranceForm.controls.unit"
                allowClear="true"
                [ngbPopover]="tolUnitsValidation"
                #tolUnitsPopover="ngbPopover"
                (blur)="tolUnitsPopover.close()"
                (focus)="tolUnitsPopover.open()"
                triggers="manual"
                [autoClose]="false"
                data-test-toleranceunit
            >
            </ops-refdata-dropdown>
        </div>
        <ng-template #tolUnitsValidation>
            <div *ngIf="toleranceForm.controls.unit.errors.required && toleranceForm.isTouched" class="row no-gutters display">
                <span class="has-icon icon--warning col-1"></span>
                <span class="content col-11" data-test-toleranceUnit-required><b>Tolerance Unit</b> is required.</span>
            </div>
            <div *ngIf="toleranceForm.controls.unit.errors.quantityUnitIncompatible && toleranceForm.controls.unit.isTouched" class="row no-gutters display">
                <span class="has-icon icon--warning col-1"></span>
                <span class="content col-11" data-test-toleranceUnit-invalid-unit-combination>Invalid unit combination.</span>
            </div>
        </ng-template>
    </td>
    <td *ngIf="form.controls.tolerance" class="p-0 cargo-component-td">
        <div class="form-group" [class.ngrx-form-group-touched]="toleranceForm.isTouched">
            <ops-refdata-dropdown
                type="ToleranceOption"
                [readonly]="readonly"
                placeholder="Select"
                opsNgrxWarningFormControl
                [ngrxFormControlState]="toleranceForm.controls.option"
                allowClear="true"
                [ngbPopover]="tolOptionsValidation"
                #tolOptionsPopover="ngbPopover"
                (blur)="tolOptionsPopover.close()"
                (focus)="tolOptionsPopover.open()"
                triggers="manual"
                [autoClose]="false"
                data-test-toleranceoption
            ></ops-refdata-dropdown>
        </div>
        <ng-template #tolOptionsValidation>
            <div *ngIf="toleranceForm.controls.option.errors.required && toleranceForm.controls.option.isTouched" class="row no-gutters display">
                <span class="has-icon icon--warning col-1"></span>
                <span class="content col-11" data-test-toleranceOption-required><b>Tolerance Option</b> is required.</span>
            </div>
        </ng-template>
    </td>

    <td class="p-0 cargo-component-td">
        <div class="form-group" [class.ngrx-form-group-touched]="baseFreightRateIsTouched">
            <input
                data-cy="cargo-freight-rate"
                [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                type="text"
                class="form-control text-right"
                [ngrxFormControlState]="form.controls.baseFreightRate"
                [ngbPopover]="baseFreightRateValidation"
                #baseFreightRatePopover="ngbPopover"
                (blur)="baseFreightRatePopover.close()"
                (focus)="baseFreightRatePopover.open()"
                triggers="manual"
                [autoClose]="false"
                [readonly]="readonly"
                data-test-basefreightrate
            />
        </div>
        <ng-template #baseFreightRateValidation>
            <ops-field-validation
                *ngIf="form.controls.baseFreightRate.errors.required && form.controls.baseFreightRate.isTouched"
                fieldDisplayName="Freight Rate"
                errorMessage="is required">
            </ops-field-validation>
            <ops-field-validation
                *ngIf="form.controls.baseFreightRate.errors.greaterThanOrEqualTo && form.controls.baseFreightRate.isTouched"
                fieldDisplayName="Freight Rate"
                errorMessage="must be greater than or equal to {{form.controls.baseFreightRate.errors.greaterThanOrEqualTo.comparand}}">
            </ops-field-validation>
        </ng-template>
    </td>
    <td class="p-0 cargo-component-td">
        <div class="form-group" [class.ngrx-form-group-touched]="baseFreightRateIsTouched">
            <ops-refdata-dropdown
                data-cy="cargo-freight-rate-unit"
                type="FreightRateUnit"
                [readonly]="readonly"
                placeholder="Select"
                opsNgrxWarningFormControl
                [ngrxFormControlState]="form.controls.baseFreightRateUnit"
                allowClear="true"
                [ngbPopover]="baseFreightRateUnitValidation"
                #baseFreightRateUnitPopover="ngbPopover"
                (blur)="baseFreightRateUnitPopover.close()"
                (focus)="baseFreightRateUnitPopover.open()"
                triggers="manual"
                [autoClose]="false"
                data-test-basefreightrateunit
            ></ops-refdata-dropdown>
        </div>

        <ng-template #baseFreightRateUnitValidation>
            <div *ngIf="form.controls.baseFreightRateUnit.errors.required && form.controls.baseFreightRateUnit.isTouched" class="row no-gutters display">
                <span class="has-icon icon--warning col-1"></span>
                <span class="content col-11" data-test-base-freight-unit-required><b>Freight Unit</b> is required.</span>
            </div>
            <div *ngIf="form.controls.baseFreightRateUnit.errors.quantityUnitIncompatible && form.controls.baseFreightRateUnit.isTouched" class="row no-gutters display">
                <span class="has-icon icon--warning col-1"></span>
                <span class="content col-11" data-test-base-freight-unit-lumpsum-unit>Must be Lump Sum.</span>
            </div>
        </ng-template>
    </td>

    <td *ngIf="form.controls.worldscaleRate" class="p-0 cargo-component-td">
        <div class="form-group">
            <input
                [opsNumber]="{ precision: 2 }"
                type="text"
                class="form-control text-right"
                [ngbPopover]="worldscaleRateValidation"
                #worldscaleRatePopover="ngbPopover"
                (blur)="worldscaleRatePopover.close()"
                (focus)="worldscaleRatePopover.open()"
                triggers="manual"
                [autoClose]="false"
                [readonly]="readonly"
                [ngrxFormControlState]="form.controls.worldscaleRate"
                data-test-worldscalerate
            />
        </div>
        <ng-template #worldscaleRateValidation>
            <ops-field-validation
                *ngIf="form.controls.worldscaleRate.errors.required && form.controls.worldscaleRate.isTouched"
                fieldDisplayName="Worldscale Rate"
                errorMessage="is required">
            </ops-field-validation>
            <ops-field-validation
                *ngIf="form.controls.worldscaleRate.errors.greaterThanOrEqualTo && form.controls.worldscaleRate.isTouched"
                fieldDisplayName="Worldscale Rate"
                errorMessage="must be greater than or equal to {{form.controls.worldscaleRate.errors.greaterThanOrEqualTo.comparand}}">
            </ops-field-validation>
        </ng-template>
    </td>
    <td *ngIf="form.controls.orderId" class="p-0 cargo-component-td">
        <div class="form-group">
            <input type="text" class="form-control" [ngrxFormControlState]="form.controls.orderId" ngrxUpdateOn="blur" [readonly]="readonly" data-test-orderId />
        </div>
    </td>

    <td class="text-right p-0 cargo-component-td" *ngIf="!readonly">
        <button *ngIf="showAddButton" [disabled]="!canAdd" (click)="add.emit()" type="button" class="btn-add has-icon icon--add-circle-outline" data-test-addcargo></button>
        <button
            data-cy="cargo-kebab-menu"
            type="button"
            [disabled]="!canClone && !canRemove && !canApplyCargoFreightRate"
            [ngbPopover]="moreMenuContent"
            #moreMenu="ngbPopover"
            (click)="moreMenu.open()"
            placement="bottom-right"
            triggers="manual"
            class="btn-add has-icon icon--more-vert cargo-more-btn"
        ></button>
        <ng-template #moreMenuContent>
            <div class="more-menu list-group">
                <div class="more-menu-item list-group-item list-group-item-action" (click)="clone.emit()" data-cy="duplicate-cargo-action">
                    <span class="has-icon icon--content-copy"></span>
                    <span>Duplicate</span>
                </div>
                <div class="more-menu-item list-group-item list-group-item-action" (click)="remove.emit()" data-cy="delete-cargo-action">
                    <span class="has-icon icon--delete"></span>
                    <span>Delete</span>
                </div>
                <div class="more-menu-item list-group-item list-group-item-action" (click)="applyCargoFreightRate.emit()" data-cy="apply-freight-cargo-action">
                    <span class="has-icon icon--apply-to-all"></span>
                    <span>Apply Freight</span>
                </div>
            </div>
        </ng-template>
    </td>
</tr>
<tr *ngIf="expanded">
    <td [attr.colspan]="colCount" *opsDivisionsExclude="Division.specialisedProducts">
        <div class="row no-gutters details-container">
            <div class="col form-group details-container-item">
                <label for="details">Cargo Details</label>
                <textarea
                    id="details"
                    [ngrxFormControlState]="form.controls.details"
                    [readonly]="readonly"
                    class="form-control"
                    maxlength="3000"
                    rows="2"
                    data-test-cargodetails
                ></textarea>
            </div>
            <div class="col form-group details-container-item">
                <label for="itinerary">Itinerary</label>
                <textarea
                    id="itinerary"
                    [ngrxFormControlState]="form.controls.itinerary"
                    [readonly]="readonly"
                    class="form-control"
                    maxlength="3000"
                    rows="2"
                    data-test-cargoitinerary
                >
                </textarea>
            </div>
        </div>
    </td>
    <td *ngIf="showSpecialisedCargoControls" class="specialised-cargo-container" ops-specialised-cargo-shell [attr.colspan]="colCount" [form]="form" [readonly]="readonly"></td>
</tr>
