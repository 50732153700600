<div class="company-container row">
    <div class="col-10 pl-0">
        <div class="company-header">
            <span class="company-title" data-test-company-name>{{ company.name }}</span>
        </div>
        <div class="company-subtitle" data-test-company-role-in-fixture>{{ company.role }}</div>
    </div>
    <div class="delete-column col-1 pr-0 pl-0" *ngIf="showDeleteButton">
        <ops-deletion-confirmation (deleteConfirmed)="removeCompany.emit(company)" data-test-removecompany></ops-deletion-confirmation>
    </div>
</div>
