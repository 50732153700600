import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { ActivityId, ActivityState } from "../../state";

@Component({
    selector: "ops-activities",
    templateUrl: "./activities.component.html",
    styleUrls: ["./activities.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivitiesComponent {
    @Input() activities: ReadonlyArray<ActivityState>;
    @Input() readonly: boolean;

    @Output() readonly expand = new EventEmitter<ActivityId>();
    @Output() readonly collapse = new EventEmitter<ActivityId>();
    @Output() readonly moveUp = new EventEmitter<ActivityId>();
    @Output() readonly moveDown = new EventEmitter<ActivityId>();
    @Output() readonly add = new EventEmitter();
    @Output() readonly remove = new EventEmitter<ActivityId>();

    get canAdd() {
        return !this.readonly;
    }

    trackBy(index: number, item: ActivityState) {
        return item.activityId;
    }
}
