import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AppConfigService } from "@ops/core";

import { FixtureId } from "../../fixture/state";
import { Voyage } from "../state";

@Injectable({ providedIn: "root" })
export class VoyageHttpService {
    private readonly voyageServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.voyageServiceUrl = appConfigService.config.voyageServiceUrl;
    }

    getAll(fixtureId: FixtureId): Observable<ReadonlyArray<Voyage>> {
        return this.httpClient.get<ReadonlyArray<Voyage>>(`${this.voyageServiceUrl}/api/v2.0/fixtures/${fixtureId}/voyages`);
    }
}
