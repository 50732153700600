import { Maritime, toDateTime } from "@ops/shared";
import { Lifting } from "../model/lifting";
import { getChartererNameOrTbn, getOwnerNameOrTbn } from "../utils";

export type LiftingSummary = Readonly<{
    reference: string;
    liftingNumber: string;
    description: string;
    owner: string;
    charterer: string;
}>;

export const getLiftingSummary = (lifting: Lifting): LiftingSummary => {
    return {
        reference: lifting.reference,
        liftingNumber: lifting.liftingNumber,
        description: [
            lifting.operators.length ? `Operators: ${lifting.operators.map((x) => x.name).join(", ")}` : "",
            lifting.brokers.map((x) => x.userCode).join(", "),
            lifting.declarationDate ? `Lifting Dec: ${toDateTime(lifting.declarationDate).toLocaleString(Maritime.DATETIME_SHORT)}` : ""
        ]
            .filter((x) => !!x)
            .join(" | "),
        owner: getOwnerNameOrTbn(lifting),
        charterer: getChartererNameOrTbn(lifting)
    };
};
