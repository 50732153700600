<div class="card embedded-card-1">
    <div class="card-header row no-gutters align-items-center">
        {{ title }}
    </div>
    <div [formGroup]="parentForm">
        <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let column of (columnDefs$ | async)" [ngClass]="column.class">{{ column.headerName }}</th>
                    <th scope="col" class="button-column-small"></th>
                </tr>
            </thead>
            <tbody [formArrayName]="formName">
                <tr *ngFor="let row of formArray.controls; let i = index" [formGroupName]="i" class="form-group">
                    <td>
                        <ng-select
                            class="ops-select"
                            [items]="bunkerTypes"
                            formControlName="type"
                            [clearable]="true"
                            bindLabel="name"
                            [compareWith]="referenceDataService.compare"
                            triggers="manual"
                            data-test-bunker-type
                            #bunkerTypeElement
                            [selectOnTab]="true"
                        >
                        </ng-select>
                    </td>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="quantityConsumed"
                            [opsNumber]="{ precision: 3, minValue: 0 }"
                            data-test-quantity-consumed
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="quantityRemaining"
                            [opsNumber]="{ precision: 3, minValue: 0 }"
                            data-test-quantity-remaining
                        />
                    </td>
                    <td class="text-right p-0">
                        <button
                            *ngIf="isLast(i)"
                            [disabled]="parentForm.disabled"
                            (click)="add()"
                            type="button"
                            class="btn-add has-icon icon--add-circle-outline"
                            data-test-add-engine-consumption
                        ></button>
                        <button
                            type="button"
                            [disabled]="formArray.disabled"
                            [ngbPopover]="moreMenuContent"
                            #moreMenu="ngbPopover"
                            (click)="moreMenu.open()"
                            placement="left"
                            triggers="manual"
                            class="btn-add has-icon icon--more-vert cargo-more-btn"
                            data-test-btn-more
                        ></button>
                        <ng-template #moreMenuContent>
                            <div class="more-menu list-group" (mouseleave)="moreMenu.close()">
                                <div class="more-menu-item list-group-item list-group-item-action" (click)="copy(i, row)">
                                    <span class="has-icon icon--content-copy"></span>
                                    <span>Duplicate</span>
                                </div>
                                <div
                                    *ngIf="formArray.controls.length > 1"
                                    class="more-menu-item list-group-item list-group-item-action"
                                    (click)="delete(i, row)"
                                >
                                    <span class="has-icon icon--delete"></span>
                                    <span>Delete</span>
                                </div>
                            </div>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="total">Totals</td>
                    <td>
                        <input
                            readonly
                            type="text"
                            class="form-control text-right"
                            [value]="totalQuantityConsumed | opsNumber: true:2:3"
                            data-test-quantity-consumed-total
                        />
                    </td>
                    <td>
                        <input
                            readonly
                            type="text"
                            class="form-control text-right"
                            [value]="totalQuantityRemaining | opsNumber: true:2:3"
                            data-test-quantity-remaining-total
                        />
                    </td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
