import { Injectable } from "@angular/core";

import { isNullOrUndefined } from "@ops/shared";

import { DemurrageClaim } from "../shared/models";

@Injectable({
    providedIn: "root"
})
export class DemurrageClaimValueService {
    resolveClaimValue(demurrageClaim: DemurrageClaim, estimatedClaimValue: number) {
        if (!demurrageClaim) {
            return null;
        }

        if (!isNullOrUndefined(demurrageClaim.finalClaimValue)) {
            return demurrageClaim.finalClaimValue;
        }

        if (isNullOrUndefined(demurrageClaim.interimClaimValue) && isNullOrUndefined(demurrageClaim.initialClaimValue)) {
            return estimatedClaimValue;
        }

        if (isNullOrUndefined(demurrageClaim.interimClaimValue) && !isNullOrUndefined(estimatedClaimValue)) {
            return demurrageClaim.initialClaimValue;
        }

        if (!isNullOrUndefined(estimatedClaimValue) && !isNullOrUndefined(demurrageClaim.initialClaimValue)) {
            return demurrageClaim.interimClaimValue;
        }

        return null;
    }
}
