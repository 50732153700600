import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, tap, withLatestFrom } from "rxjs/operators";
import { VoyageDataService } from "src/app/fixture/services/voyage-data.service";
import { dateToISOString, parseISODate } from "src/app/shared/date-utils/date-utilities";
import { deepFreeze } from "src/app/shared/deep-freeze";
import { NoonReportHttpService } from "./noon-report-http.service";
import { NoonReport } from "./noon-report.dto";

@Injectable({
    providedIn: "root"
})
export class NoonReportDataService {
    private fixtureId: string = null;
    private voyageId: string = null;
    private _noonReports$ = new BehaviorSubject<NoonReport[]>([]);
    private _loading$ = new BehaviorSubject<boolean>(false);

    get noonReports$() {
        return this._noonReports$.asObservable();
    }

    get loading$() {
        return this._loading$.asObservable();
    }

    constructor(private noonReportHttpService: NoonReportHttpService, private voyageDataService: VoyageDataService) {
        this.voyageDataService.current$.subscribe((voyage) => {
            if (voyage) {
                if (voyage.voyageId !== this.voyageId) {
                    this.voyageId = voyage.voyageId;
                    this.fixtureId = voyage.fixtureId;
                    this.getAll();
                }
            } else {
                this.fixtureId = null;
                this.voyageId = null;
                this.publish([]);
            }
        });
    }

    add(): Observable<NoonReport> {
        const noonReport = <NoonReport>{
            noonReportId: Guid.create().toString(),
            inPort: false,
            averageSpeed: null,
            averageBeaufort: null,
            date: dateToISOString(new Date()),
            mainEngineConsumption: [{ type: null, quantityConsumed: null, quantityRemaining: null }],
            auxiliaryEngineConsumption: [{ type: null, quantityConsumed: null, quantityRemaining: null }]
        };

        return of(noonReport).pipe(
            withLatestFrom(this._noonReports$),
            tap(([current, noonReports]) => this.publish([...noonReports, current])),
            map(([current, noonReports]) => current)
        );
    }

    persist(noonReport: NoonReport): Observable<NoonReport> {
        this._loading$.next(true);

        return this.noonReportHttpService.update(this.fixtureId, this.voyageId, noonReport.noonReportId, noonReport).pipe(
            withLatestFrom(this._noonReports$),
            tap(([current, noonReports]) => {
                this._loading$.next(false);
                this.publish([...noonReports.filter((x) => x.noonReportId !== current.noonReportId), current]);
            }),
            map(([current, noonReports]) => current)
        );
    }

    update(noonReport: NoonReport): Observable<NoonReport> {
        return of(noonReport).pipe(
            withLatestFrom(this._noonReports$),
            tap(([current, noonReports]) => {
                this.publish([...noonReports.filter((x) => x.noonReportId !== current.noonReportId), current]);
            }),
            map(([current, noonReports]) => current)
        );
    }

    get(noonReportId: string): Observable<NoonReport> {
        this._loading$.next(true);

        return this.noonReportHttpService.get(this.fixtureId, this.voyageId, noonReportId).pipe(
            withLatestFrom(this._noonReports$),
            tap(([current, noonReports]) => {
                this._loading$.next(false);
                this.publish([...noonReports.filter((x) => x.noonReportId !== current.noonReportId), current]);
            }),
            map(([current, noonReports]) => current)
        );
    }

    remove(noonReportId: string): Observable<any> {
        return of({}).pipe(
            withLatestFrom(this._noonReports$),
            tap(([_, noonReports]) => this.publish([...noonReports.filter((x) => x.noonReportId !== noonReportId)])),
            map(([_, noonReports]) => _)
        );
    }

    private publish(noonReports: NoonReport[]) {
        const comparer = (n1: NoonReport, n2: NoonReport) => parseISODate(n1.date).getTime() - parseISODate(n2.date).getTime();
        this._noonReports$.next(deepFreeze(noonReports.sort(comparer)));
    }

    private getAll() {
        this._loading$.next(true);

        this.noonReportHttpService.getAll(this.fixtureId, this.voyageId).subscribe((noonReports: NoonReport[]) => {
            this._loading$.next(false);
            this.publish(noonReports);
        });
    }
}
