<div class="document" *ngIf="isVisible" data-test-document>
    <div class="document-main-content d-flex">
        <div class="icon-container col-1">
            <span class="has-icon" [ngClass]="fileIconClass" *ngIf="!document.error"></span>
            <span class="has-icon icon--warning validation-icon" *ngIf="document.error"></span>
        </div>
        <div class="meta-data-container col-12">
            <div class="top">
                <span class="file-name">{{ document.name | truncate: 30:".." }}{{ document.file.extension }}</span>
                <span class="file-size" *ngIf="!document.error">{{ document.file.size | fileSize }}</span>
                <button
                    type="button"
                    class="btn-close has-icon icon--close"
                    title="Remove"
                    *ngIf="document.error"
                    (click)="remove()"
                    data-remove-errored-document
                ></button>
            </div>
            <div class="middle" *ngIf="!document.error">
                <span class="uploaded-by">{{ document.uploadedBy.fullName }}</span>
                <span class="uploaded-date">{{ document.uploadDate | timezoneDate: "dd MMM yy, HH:mm:ss" }}</span>
            </div>
            <div class="bottom" *ngIf="document.error || showFailureWrapper || (document.loadPercentage$ | async) !== null">
                <div class="validation-wrapper" *ngIf="document.error">
                    <span class="error-message">File upload failed. {{ document.error }}</span>
                </div>
                <div class="load-progress-wrapper" *ngIf="!document.error && (document.loadPercentage$ | async) !== null">
                    <ngb-progressbar class="upload-progress-bar" [value]="document.loadPercentage$ | async" type="info"></ngb-progressbar>
                    <span class="load-progress-percentage">{{ document.loadPercentage$ | async }}%</span>
                </div>
                <div class="failure-wrapper" *ngIf="showFailureWrapper">
                    <div class="icon-container col-1">
                        <span class="has-icon icon--warning validation-icon"></span>
                    </div>
                    <div class="failure-container col-11">
                        <span *ngIf="gatewayVisibleToggleFailed">Show/hide in Gateway failed</span>
                        <span *ngIf="downloadFailed">File cannot be downloaded</span>
                        <span *ngIf="deleteFailed">File deletion failed</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="document-action-bar" *ngIf="!document.error && (document.loadPercentage$ | async) === null">
        <div class="gateway-wrapper">
            <input
                type="checkbox"
                class="show-in-gateway-input"
                [checked]="(gatewayVisibility$ | async).value"
                title="Display in Gateway"
                (click)="toggleGatewayVisible()"
                data-display-document-in-gateway
            />
            <span class="show-in-gateway-text">Show in Gateway</span>
        </div>
        <div class="actions-wrapper">
            <button type="button" class="btn-download has-icon icon--file-download" title="Download" (click)="download()" data-download-document></button>
            <button type="button" class="btn-delete has-icon icon--delete" title="Delete" (click)="delete()" data-test-delete-document></button>
        </div>
    </div>
</div>
