<div [formGroup]="parentForm" class="card embeded-card">
    <div class="card-header pl-0 row no-gutters align-items-center">
        <button
            type="button"
            class="has-icon btn-inverted"
            [class.icon--arrow-drop-right]="accordion?.isClosed"
            [class.icon--arrow-drop-down]="accordion?.isOpen"
            (click)="accordionToggle()"
            data-test-toggle-location-button
        ></button>

        <div>{{ destination.location?.displayName }}</div>
    </div>

    <div class="card-body" [hidden]="accordion.isClosed">
        <div>
            <div *ngIf="activities && activities.length > 0; else noActivities">
                <div *ngFor="let activityForm of activitiesForm.controls; let i = index" class="row activity-row">
                    <div class="col">
                        <ops-voyage-bunker-consumption-activity
                            [activity]="activities[i].activity"
                            [berthId]="activities[i].berthId"
                            [activityForm]="activityForm"
                            [readonly]="activityForm.disabled"
                            [destinationId]="destination.id"
                            (voyageBunkerConsumptionActivityUpdated)="handleUpdateCommand($event)"
                            [maxBeaufortForce]="cpSpeedAndConsumption.maxBeaufortForce"
                            [showDaysAboveForce]="i === 0"
                        >
                        </ops-voyage-bunker-consumption-activity>
                    </div>
                </div>
            </div>
            <ng-template #noActivities>
                <div>No Activities</div>
            </ng-template>
        </div>
    </div>
</div>
