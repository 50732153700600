import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { CoaLoggingEffects } from "./coa-logging.effects";
import { CoaNotificationEffects } from "./coa-notification.effects";
import { CoaRoutingEffects } from "./coa-routing.effects";
import { CoaEffects } from "./coa.effects";
import { reducer } from "./coa.reducer";
import { coasFeatureKey } from "./coa.selectors";

@NgModule({
    imports: [StoreModule.forFeature(coasFeatureKey, reducer), EffectsModule.forFeature([CoaEffects, CoaLoggingEffects, CoaRoutingEffects, CoaNotificationEffects])]
})
export class CoaStoreModule {}
