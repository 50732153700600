import { Maritime, toDateTime } from "@ops/shared";
import { DateTime } from "luxon";
import { Coa, CoaStatus } from "../model";
import { getChartererNameOrTbn, getOwnerNameOrTbn } from "../utils";

export type CoaSummary = Readonly<{
    reference: string;
    name: string;
    lastUpdatedAt: DateTime;
    lastUpdatedBy: string;
    description: string
    contractStatus: CoaStatus;
    owner: string;
    charterer: string;
}>;

export const getCoaSummary = (coa: Coa): CoaSummary => {
    return {
        reference: coa.reference,
        name: coa.name,
        lastUpdatedAt: toDateTime(coa.lastUpdatedAt),
        lastUpdatedBy: coa.lastUpdatedBy?.name,
        description: [
            coa.cancellationReason, 
            toDateTime(coa.charterpartyDate)?.toLocaleString(Maritime.DATETIME_SHORT),
            coa.brokers.map((x) => x.userCode).join(", "), 
            `${coa.driver} Driven`, 
            coa.currency
            ].filter(x => !!x).join(" | "),
        contractStatus: coa.contractStatus,
        owner: getOwnerNameOrTbn(coa),
        charterer: getChartererNameOrTbn(coa)
    };
};