<div [formGroup]="parentForm" class="cargo-rates">
    <div class="apply-button">
        <div *ngIf="selectedRows.length > 0" class="text-right">
            <ops-action-button (click)="openModal()">
                <span class="badge count">{{selectedRows.length}}</span>
                EDIT SELECTED ROWS
            </ops-action-button>
        </div>
    </div>
    <p-table 
        [columns]="(columnDefs$ | async)"
        [value]="formArray.controls"
        selectionMode="multiple"
        [(selection)]="selectedRows"
        [metaKeySelection]="true"
        [rowTrackBy]="trackBy"
        #multiselectTable
        tableStyleClass="table">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" class="{{ col.class }}">{{ col.headerName }}</th>
                <th class="more-menu-btn">
                    <button
                        type="button"
                        class="has-icon icon--more-vert p-0"
                        [disabled]="parentForm.disabled"
                        [ngbPopover]="tableMoreMenuContent"
                        #tableMoreMenu="ngbPopover"
                        (click)="tableMoreMenu.open()"
                        placement="bottom-right"
                        triggers="manual"
                    ></button>
                    <ng-template #tableMoreMenuContent>
                        <div class="more-menu">
                            <button
                                class="btn btn-action"
                                type="button"
                                [disabled]="parentForm.disabled"
                                (click)="handleApplyToAllTables(formArray.controls)"
                            >
                                APPLY ALL CARGO RATES TO CALCULATION
                            </button>
                        </div>
                    </ng-template>
                </th>
            </tr>
        </ng-template>
        <ng-template [formArrayName]="formName" pTemplate="body" let-control let-rowIndex="rowIndex">
            <tr [opsMultiselect]="control" [index]="rowIndex" [isReadonly]="control.get('cpLoadRate').disabled" [formGroupName]="rowIndex" class="form-group">
                <td>
                    <input type="hidden" formControlName="cargoId" />
                    <span [ngbTooltip]="control.value.cargoProductName">
                        <input type="text" class="form-control" formControlName="cargoProductName" (mouseover)="toggleTooltip(dischargeElement)" data-test-cargo-product-name readonly />
                    </span>
                </td>
                <td #loadElement (mouseover)="toggleTooltip(loadElement)">
                    <span *ngIf="isActiveTooltip"
                        type="text"
                        class="form-control overflow-text"
                        [ngbTooltip]="control.get('loadLocation').value"
                        placement="right"
                        data-test-cargo-load-location readonly >
                        {{ control.get('loadLocation').value }}
                    </span>
                    <span *ngIf="!isActiveTooltip"
                        type="text"
                        class="form-control overflow-text"
                        data-test-cargo-load-location readonly >
                        {{ control.get('loadLocation').value }}
                    </span>
                </td>
                <td>
                    <ops-quantity-input
                        type="CpRateUnit"
                        placeholder="Select"
                        formControlName="cpLoadRate"
                        [readonly]="isFixedLaytimeType"
                        [inputNumberFormat]="cpRateNumberFormat"
                        dropdownBindValue="name"
                        container="body"
                        data-test-cp-load-rate
                        data-test-cp-load-rate-unit
                    >
                    </ops-quantity-input>
                </td>
                <td>
                    <ng-select
                        class="ops-select form-control"
                        [items]="reversibleLaytimeType$ | async"
                        placeholder="Select"
                        formControlName="loadReversibleLaytimeType"
                        [clearable]="true"
                        bindLabel="name"
                        data-test-cp-load-reversible-type
                        [selectOnTab]="true"
                        [readonly]="isFixedLaytimeType"
                    ></ng-select>
                </td>
                <td>
                    <input
                        type="text"
                        [opsNumber]="cpRateNumberFormat"
                        class="form-control text-right"
                        formControlName="loadExtraHours"
                        [readonly]="isFixedLaytimeType"
                        data-test-load-extra-hours
                    />
                </td>
                <td #dischargeElement (mouseover)="toggleTooltip(dischargeElement)">
                    <span *ngIf="isActiveTooltip"
                        type="text"
                        class="form-control overflow-text"
                        [ngbTooltip]="control.get('dischargeLocation').value"
                        placement="right"
                        data-test-cargo-discharge-location readonly >
                        {{ control.get('dischargeLocation').value }}
                    </span>
                    <span *ngIf="!isActiveTooltip"
                        type="text"
                        class="form-control overflow-text"
                        data-test-cargo-discharge-location readonly >
                        {{ control.get('dischargeLocation').value }}
                    </span>
                </td>
                <td>
                    <ops-quantity-input
                        type="CpRateUnit"
                        placeholder="Select"
                        formControlName="cpDischargeRate"
                        [readonly]="isFixedLaytimeType"
                        [inputNumberFormat]="cpRateNumberFormat"
                        dropdownBindValue="name"
                        container="body"
                        data-test-cp-discharge-rate
                        data-test-cp-discharge-rate-unit
                    >
                    </ops-quantity-input>
                </td>
                <td>
                    <ng-select
                        class="ops-select form-control"
                        [items]="reversibleLaytimeType$ | async"
                        placeholder="Select"
                        formControlName="dischargeReversibleLaytimeType"
                        [clearable]="true"
                        bindLabel="name"
                        data-test-cp-discharge-reversible-type
                        [selectOnTab]="true"
                        [readonly]="this.isFixedLaytimeType"
                    ></ng-select>
                </td>
                <td>
                    <input
                        type="text"
                        [opsNumber]="{ precision: 3, minValue: 0, showTrailingZeros: false }"
                        class="form-control text-right"
                        formControlName="dischargeExtraHours"
                        [readonly]="isFixedLaytimeType"
                        data-test-discharge-extra-hours
                    />
                </td>
                <td class="more-menu-btn">
                    <button
                        type="button"
                        class="has-icon icon--more-vert"
                        [disabled]="parentForm.disabled"
                        [ngbPopover]="rowMoreMenuContent"
                        #rowMoreMenu="ngbPopover"
                        (click)="rowMoreMenu.open()"
                        placement="bottom-right"
                        triggers="manual"
                    ></button>
                    <ng-template #rowMoreMenuContent>
                        <div class="more-menu">
                            <button
                                class="btn btn-action"
                                type="button"
                                [disabled]="parentForm.disabled"
                                (click)="handleApplyToAllTables([control])"
                            >
                                APPLY CARGO RATES TO CALCULATION
                            </button>
                        </div>
                    </ng-template>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
