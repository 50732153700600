import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "ops-select-button",
    template: ' <p-selectButton [options]="options" [(ngModel)]="selectedOption" (onChange)="changed($event)" [disabled]="disabled"></p-selectButton> ',
    encapsulation: ViewEncapsulation.None
})
export class SelectButtonComponent {
    @Input() options: string[];
    @Input() selectedOption: string;
    @Input() disabled: boolean;

    @Output() readonly change = new EventEmitter<string>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changed(event: any) {
        this.selectedOption = event.value;
        this.change.emit(this.selectedOption);
    }
}
