import { HttpErrorResponse } from "@angular/common/http";

import { LegacyDuplicateWorksheetNameError } from "./legacy-duplicate-name.error";
import { CreateWorksheetModel, Worksheet } from "./worksheet.model";

export class WorksheetUtilities {
    static getErrorMessage(error: Error) {
        const duplicateNameMessage = "Duplicate worksheet name. Please rename and save.";
        const errorMessage = "Unable to save worksheet";
        const httpError = error as HttpErrorResponse;

        if (httpError.status) {
            switch (httpError.status) {
                case 409: {
                    return duplicateNameMessage;
                }
                default: {
                    return errorMessage;
                }
            }
        }

        return error instanceof LegacyDuplicateWorksheetNameError ? duplicateNameMessage : errorMessage;
    }

    static areWorksheetsSame(a: Worksheet | CreateWorksheetModel, b: Worksheet | CreateWorksheetModel) {
        const aJson = JSON.stringify({
            columns: a.columns,
            queries: a.queries,
            sort: a.sort
        });
        const bJson = JSON.stringify({
            columns: b.columns,
            queries: b.queries,
            sort: b.sort
        });

        return aJson === bJson;
    }
}
