<div class="no-data-panel">
    <div class="no-data-inner-container d-flex flex-column">
        <div class="no-data-panel-heading">
            {{ heading }}
        </div>
        <div class="d-flex flex-wrap justify-content-between no-data-panel-content">
            <div class="d-flex">
                <div class="no-data-panel-icon">
                    <span class="has-icon icon--lightbulb-outline"></span>
                </div>
                <div>
                    <span class="no-data-panel-title">{{ title }}</span>
                    <p class="no-data-panel-description mb-0">{{ description }}</p>
                </div>
            </div>
            <div class="d-flex ml-auto no-data-panel-new-button" *ngIf="useNewButton">
                <button type="button"
                        class="btn btn-primary btn-add m-0"
                        [disabled]="disabled"
                        (click)="raiseAddNew()"
                        #newButton
                        data-test-add-first-row>
                    <span class="has-icon icon--add-circle-outline"></span>
                    {{ newButtonText }}
                </button>
            </div>

            <ng-content></ng-content>
        </div>
    </div>
</div>
