import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { Boxed, createFormGroupState, SetValueAction } from "ngrx-forms";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { CoaHttpService } from "../../../services/coa-http.service";
import { selectCurrentCoaId } from "../../coa";
import { coaStateReducer, currentCoaStateReducer } from "../../coa/reducer";
import { CoaFeatureState, CoaId, CoasState, Company, companyForm, CompanyForm, getFormGroupId, mapCompanySearchResultToCoaCompany } from "../../model";
import { selectCompanyForm } from "../selectors";

/* ACTIONS */
const ADD_COMPANY_ACTION_NAME = "[Coa Contacts Panel] Add Company";
export const addCompanyAction = createAction(ADD_COMPANY_ACTION_NAME, props<{ company: Company }>());
export const addCompanySuccessAction = createAction(`${ADD_COMPANY_ACTION_NAME} Success`, props<{ coaId: CoaId; company: Company }>());
export const addCompanyFailAction = createAction(`${ADD_COMPANY_ACTION_NAME} Fail`, props<{ coaId: CoaId; company: Company; error: Error }>());

/* REDUCERS */
export const addCompanyReducer: On<CoasState> = on(addCompanyAction, (state, { company }) =>
    currentCoaStateReducer(state, (coaState) => ({
        ...coaState,
        companyForm: createFormGroupState(getFormGroupId(state.currentCoaId), companyForm()),
        coa: {
            ...coaState.coa,
            contacts: [...coaState.coa.contacts, company]
        }
    }))
);

export const addCompanyFailReducer: On<CoasState> = on(addCompanyFailAction, (state, { coaId, company, error }) =>
    coaStateReducer(state, coaId, (coaState) => ({
        ...coaState,
        coa: {
            ...coaState.coa,
            contacts: coaState.coa.contacts.filter((c) => !(c.accountId === company.accountId && c.role === company.role))
        },
        erroredItems: [...(coaState.erroredItems || []), { itemType: "contacts", id: company.accountId, error }]
    }))
);

/* EFFECTS */
export const addCompanyEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, coaService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(addCompanyAction),
            withLatestFrom(store.select(selectCurrentCoaId)),
            mergeMap(([{ company }, coaId]) =>
                coaService.addCompany(coaId, company).pipe(
                    map(() => addCompanySuccessAction({ coaId, company })),
                    catchError((err) => of(addCompanyFailAction({ coaId, company, error: err })))
                )
            )
        )
    );

export const addCompanyOnCompanyChangeEffect$ = (actions$: Actions, store: Store<CoaFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<CompanyForm>>(SetValueAction.TYPE),
            withLatestFrom(store.select(selectCurrentCoaId)),
            filter(([action, coaId]) => {
                const controlPath = action.controlId.split(".");
                // eslint-disable-next-line no-magic-numbers
                const boxedCompany = controlPath[0] === coaId && controlPath[1] === "CompanyForm" && controlPath[2] === "company" ? <Boxed<Company>>(<unknown>action.value) : null;
                return !!boxedCompany?.value;
            }),
            withLatestFrom(store.select(selectCompanyForm)),
            map(([, form]) => addCompanyAction({ company: { ...mapCompanySearchResultToCoaCompany(form.value.company.value), role: form.value.companyRole } }))
        )
    );
