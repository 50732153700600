import { Injectable } from "@angular/core";
import { CommandHandler } from "../../../../mediator/command-handlers/command-handler";
import { Fixture } from "../../../../shared/models";
import { AddBunkerCommand } from "./add-bunker.command";

@Injectable({
    providedIn: "root"
})
export class AddBunkerCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: AddBunkerCommand) {
        fixture.redelivery.bunkers.push({
            bunkerType: null,
            quantity: null,
            price: null
        });
    }
}
