<ops-destination-form
    [form]="form$ | async"
    [readonly]="readonly"
    [isLocationAndEtaReadonly]="isLocationAndEtaReadonly$ | async"
    [notesExpanded]="notesExpanded$ | async"
    [etaVerifiedAudit]="etaVerifiedAudit$ | async"
    [etaVerifyEnabled]="!(isTransient$ | async)"
    [showEtaWarning]="hasEtaWarning$ | async"
    [etaDefaultFocusDate]="defaultEtaFocusDate$ | async"
    [canDate]="(layCan$ | async)?.can"
    (expandNotes)="expandNotes()"
    (collapseNotes)="collapseNotes()"
    (verifyEta)="verifyEta()"
>
</ops-destination-form>

<ops-berths
    [berths]="berths$ | async"
    [readonly]="readonly"
    (expand)="expandBerth($event)"
    (collapse)="collapseBerth($event)"
    (moveUp)="moveBerthUp($event)"
    (moveDown)="moveBerthDown($event)"
    (add)="addBerth()"
    (clone)="cloneBerth($event)"
    (remove)="removeBerth($event)"
></ops-berths>
