<div *ngIf="loaded$ | async; else loading" class="ops-ltc-shell-container">
    <div class="ops-ltc-header-container p-d-flex p-ai-center">
        <div class="ops-ltc-calculation-name">{{calculationName$ | async}} &nbsp;>&nbsp; Add Locations</div>
        <span
            *ngIf="hasCargoesWithoutCargoProduct$ | async"
            class="has-icon icon--warning"
            ngbTooltip="Cargoes without linked product are not displayed"
        ></span>
    </div>
    <div class="ops-ltc-content">
        <ops-add-activity-locations-filter-shell class="ops-ltc-filter"></ops-add-activity-locations-filter-shell>
        <ops-add-activity-locations-selection-shell class="ops-ltc-selection"></ops-add-activity-locations-selection-shell>
    </div>
    <div class="ops-ltc-actions" *ngIf="{ addingActivityLocations: isAddingActivityLocations$ | async } as data">
        <div class="p-field p-field-checkbox p-mb-0">
            <input id="importPortTimes" type="checkbox" name="importPortTimes" class="p-checkbox"
                   [disabled]="data.addingActivityLocations" [ngrxFormControlState]="importPortTimes$ | async"
            data-cy="import-port-times"/>
            <label for="importPortTimes">Import Port Times</label>
        </div>
        <ops-action-button class="p-mx-3" (click)="cancel()" [disabled]="data.addingActivityLocations" data-cy="cancel">CANCEL
        </ops-action-button>
        <ops-action-button type="primary" [disabled]="(addActivityLocationsEnabled$ | async) !== true"
                           [busy]="data.addingActivityLocations" (click)="addToCalculation()" data-cy="add-to-calculation">ADD TO CALCULATION
        </ops-action-button>
    </div>
</div>

<ng-template #loading>
    <div class="ops-ltc-loading-panel">
        <ops-load-animation size="x-large" class="light-background"></ops-load-animation>
    </div>
</ng-template>
