import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { SetValueAction } from "ngrx-forms";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { selectCurrentUser, User } from "@ops/state";

import { VoyageHttpService } from "../../../services";
import { Voyage } from "../../../shared/models";
import { selectCurrentFixtureId } from "../../fixture";
import { BerthForm, BerthId, DestinationId, FixtureFeatureState, FixtureId, FixturesState, VoyageId } from "../../model";
import { selectCurrentVoyageFormValue, selectCurrentVoyageId } from "../../voyage";
import { voyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
const ACTION_NAME = "[Voyage Form] Verify ETB";

export const verifyEtbAction = createAction(ACTION_NAME, props<{ destinationId: DestinationId; berthId: BerthId }>());
export const verifyEtbSuccessAction = createAction(
    `${ACTION_NAME} Success`,
    props<{ fixtureId: FixtureId; voyageId: VoyageId; destinationId: DestinationId; berthId: BerthId; date: string; user: User }>()
);
export const verifyEtbFailAction = createAction(
    `${ACTION_NAME} Fail`,
    props<{ fixtureId: FixtureId; voyageId: VoyageId; destinationId: DestinationId; berthId: BerthId; error: Error }>()
);

export const markEtbVerifiedAction = createAction(
    "[Voyage Form] Mark ETB Verified",
    props<{ fixtureId: FixtureId; voyageId: VoyageId; destinationId: DestinationId; berthId: BerthId; date: string; user: User }>()
);

/* REDUCERS */
export const verifyEtbSuccessReducer: On<FixturesState> = on(verifyEtbSuccessAction, markEtbVerifiedAction, (state, { voyageId, destinationId, berthId, date, user }) => {
    function updateVoyage(voyage: Voyage) {
        return {
            ...voyage,
            destinations: voyage.destinations.map((destination) => {
                if (destination.id === destinationId) {
                    return {
                        ...destination,
                        berths: destination.berths.map((berth) => {
                            if (berth.id === berthId) {
                                return {
                                    ...berth,
                                    etbLastVerifiedDate: date,
                                    etbLastVerifiedBy: user
                                };
                            }

                            return berth;
                        })
                    };
                }

                return destination;
            })
        };
    }

    return voyageStateReducer(state, voyageId, (voyageState) => {
        // Handle slow request, voyage being removed from state
        if (!voyageState) {
            return voyageState;
        }

        return {
            ...voyageState,
            voyage: updateVoyage(voyageState.voyage),
            workingVoyage: voyageState.workingVoyage ? updateVoyage(voyageState.workingVoyage) : voyageState.workingVoyage
        };
    });
});

/* EFFECTS */
export const verifyEtbEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, voyageService: VoyageHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(verifyEtbAction),
            withLatestFrom(store.select(selectCurrentFixtureId), store.select(selectCurrentVoyageId), store.select(selectCurrentUser)),
            mergeMap(([{ destinationId, berthId }, fixtureId, voyageId, user]) =>
                voyageService.verifyEtb(fixtureId, voyageId, destinationId, berthId).pipe(
                    map((date) =>
                        verifyEtbSuccessAction({
                            fixtureId: fixtureId,
                            voyageId: voyageId,
                            destinationId: destinationId,
                            berthId: berthId,
                            date: date,
                            user: user
                        })
                    ),
                    catchError((err) => of(verifyEtbFailAction({ fixtureId: fixtureId, voyageId: voyageId, destinationId: destinationId, berthId: berthId, error: err })))
                )
            )
        )
    );

export const markEtbVerifiedOnEtbChangeEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<BerthForm>>(SetValueAction.TYPE),
            map((action) => action.controlId.split(".")),
            filter(
                // eslint-disable-next-line no-magic-numbers
                (controlPath) => controlPath.length === 6 && controlPath[1] === "destinations" && controlPath[3] === "berths" && controlPath[5] === "etb"
            ),
            withLatestFrom(store.select(selectCurrentFixtureId), store.select(selectCurrentVoyageFormValue), store.select(selectCurrentUser)),
            map(([[, , destinationIndex, , berthIndex], fixtureId, { voyageId, destinations }, user]) => {
                const destination = destinations[Number(destinationIndex)];
                return markEtbVerifiedAction({
                    fixtureId: fixtureId,
                    voyageId: voyageId,
                    destinationId: destination.id,
                    berthId: destination.berths[Number(berthIndex)].id,
                    date: DateTime.utc().toISO(),
                    user: user
                });
            })
        )
    );
