import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";

import { update } from "@ops/shared";
import { persisted, selectCurrentUser } from "@ops/state";

import { LiftingHttpService } from "../../../services";
import { coaLiftingsStateReducer } from "../../coa/reducer";
import {
    CoaFeatureState,
    CoaId,
    CoasState,
    CoaState,
    isPreferred,
    LiftingId,
    setAccepted,
    setPreferred,
    setRejected,
    setUnderReview,
    toUser,
    User,
    VesselId,
    vesselIdEq
} from "../../model";
import { currentLiftingStateReducer, liftingStateReducer } from "../reducer";
import { selectCurrentLifting } from "../selectors";

/* ACTIONS */
const ACCEPT_ACTION_NAME = "[Vessel Nomination Grid] Accept Vessel Nomination";
export const acceptVesselNominationAction = createAction(ACCEPT_ACTION_NAME, props<{ vesselId: VesselId }>());
export const acceptVesselNominationSuccessAction = createAction(`${ACCEPT_ACTION_NAME} Success`, props<{ coaId: CoaId; liftingId: LiftingId; vesselId: VesselId; user: User }>());
export const acceptVesselNominationFailAction = createAction(`${ACCEPT_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

const REJECT_ACTION_NAME = "[Vessel Nomination Grid] Reject Vessel Nomination";
export const rejectVesselNominationAction = createAction(REJECT_ACTION_NAME, props<{ vesselId: VesselId; reason: string }>());
export const rejectVesselNominationSuccessAction = createAction(
    `${REJECT_ACTION_NAME} Success`,
    props<{ coaId: CoaId; liftingId: LiftingId; vesselId: VesselId; user: User; reason: string }>()
);
export const rejectVesselNominationFailAction = createAction(`${REJECT_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

const REVIEW_ACTION_NAME = "[Vessel Nomination Grid] Review Vessel Nomination";
export const reviewVesselNominationAction = createAction(REVIEW_ACTION_NAME, props<{ vesselId: VesselId }>());
export const reviewVesselNominationSuccessAction = createAction(`${REVIEW_ACTION_NAME} Success`, props<{ coaId: CoaId; liftingId: LiftingId; vesselId: VesselId; user: User }>());
export const reviewVesselNominationFailAction = createAction(`${REVIEW_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

const PREFER_ACTION_NAME = "[Vessel Nomination Grid] Prefer Vessel Nomination";
export const preferVesselNominationAction = createAction(PREFER_ACTION_NAME, props<{ vesselId: VesselId }>());
export const preferVesselNominationSuccessAction = createAction(
    `${PREFER_ACTION_NAME} Success`,
    props<{ coaId: CoaId; liftingId: LiftingId; vesselId: VesselId; user: User; reason?: string }>()
);
export const preferVesselNominationFailAction = createAction(`${PREFER_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

const RENOMINATE_ACTION_NAME = "[Vessel Nomination Grid] Renominate Vessel";
export const renominateVesselAction = createAction(RENOMINATE_ACTION_NAME, props<{ vesselId: VesselId; reason: string }>());
export const renominateVesselSuccessAction = createAction(
    `${RENOMINATE_ACTION_NAME} Success`,
    props<{ coaId: CoaId; liftingId: LiftingId; vesselId: VesselId; user: User; reason: string }>()
);
export const renominateVesselFailAction = createAction(`${RENOMINATE_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

/* REDUCERS */
export const updateVesselNominationStatusReducer: On<CoasState> = on(
    acceptVesselNominationAction,
    rejectVesselNominationAction,
    reviewVesselNominationAction,
    preferVesselNominationAction,
    renominateVesselAction,
    (state) => currentLiftingStateReducer(state, { vesselNominationStatusSaveStatus: "persisting" })
);

export const acceptVesselNominationSuccessReducer: On<CoasState> = on(acceptVesselNominationSuccessAction, (state, { coaId, vesselId, liftingId }) => {
    const updateFns: Evolver = {
        vesselNominationStatusSaveStatus: persisted,
        lifting: { vessels: update(vesselIdEq(vesselId), setAccepted) }
    };
    const withUpdatedLiftingState = liftingStateReducer(state, liftingId, R.evolve(updateFns));

    const getLiftings = (coaState: CoaState) =>
        coaState.fetchedLiftings.map((l) => (l.documentId !== liftingId ? l : { ...l, vessels: withUpdatedLiftingState.liftings.byId[liftingId].lifting.vessels }));

    return coaLiftingsStateReducer(withUpdatedLiftingState, coaId, getLiftings);
});

export const rejectVesselNominationSuccessReducer: On<CoasState> = on(rejectVesselNominationSuccessAction, (state, { vesselId, liftingId }) => {
    const updateFns: Evolver = {
        vesselNominationStatusSaveStatus: persisted,
        lifting: { vessels: update(vesselIdEq(vesselId), setRejected) }
    };
    return liftingStateReducer(state, liftingId, R.evolve(updateFns));
});

export const reviewVesselNominationSuccessReducer: On<CoasState> = on(reviewVesselNominationSuccessAction, (state, { coaId, vesselId, liftingId }) => {
    const updateFns: Evolver = {
        vesselNominationStatusSaveStatus: persisted,
        lifting: { vessels: update(vesselIdEq(vesselId), setUnderReview) }
    };
    const withUpdatedLiftingState = liftingStateReducer(state, liftingId, R.evolve(updateFns));

    const getLiftings = (coaState: CoaState) =>
        coaState.fetchedLiftings.map((l) => (l.documentId !== liftingId ? l : { ...l, vessels: withUpdatedLiftingState.liftings.byId[liftingId].lifting.vessels }));

    return coaLiftingsStateReducer(withUpdatedLiftingState, coaId, getLiftings);
});

export const preferVesselNominationSuccessReducer: On<CoasState> = on(
    preferVesselNominationSuccessAction,
    renominateVesselSuccessAction,
    (state, { coaId, vesselId, liftingId }) => {
        const updateFns: Evolver = {
            vesselNominationStatusSaveStatus: persisted,
            lifting: { vessels: update(R.either(vesselIdEq(vesselId), isPreferred), R.ifElse(vesselIdEq(vesselId), setPreferred, setAccepted)) }
        };
        const withUpdatedLiftingState = liftingStateReducer(state, liftingId, R.evolve(updateFns));

        const getLiftings = (coaState: CoaState) =>
            coaState.fetchedLiftings.map((l) => (l.documentId !== liftingId ? l : { ...l, vessels: withUpdatedLiftingState.liftings.byId[liftingId].lifting.vessels }));

        return coaLiftingsStateReducer(withUpdatedLiftingState, coaId, getLiftings);
    }
);

export const updateVesselNominationStatusFailReducer: On<CoasState> = on(
    acceptVesselNominationFailAction,
    rejectVesselNominationFailAction,
    reviewVesselNominationFailAction,
    preferVesselNominationFailAction,
    renominateVesselFailAction,
    (state, { liftingId }) => liftingStateReducer(state, liftingId, { vesselNominationStatusSaveStatus: "failed" })
);

/* EFFECTS */
export const acceptVesselNominationEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(acceptVesselNominationAction),
            withLatestFrom(store.select(selectCurrentLifting), store.select(selectCurrentUser)),
            switchMap(([{ vesselId }, { coaId, liftingId }, appUser]) =>
                liftingHttpService.acceptVessel(coaId, liftingId, vesselId).pipe(
                    map(() => acceptVesselNominationSuccessAction({ coaId, liftingId, vesselId, user: toUser(appUser) })),
                    catchError((error) => of(acceptVesselNominationFailAction({ liftingId, error })))
                )
            )
        )
    );

export const rejectVesselNominationEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(rejectVesselNominationAction),
            withLatestFrom(store.select(selectCurrentLifting), store.select(selectCurrentUser)),
            switchMap(([{ vesselId, reason }, { coaId, liftingId }, appUser]) =>
                liftingHttpService.rejectVessel(coaId, liftingId, vesselId, reason).pipe(
                    map(() => rejectVesselNominationSuccessAction({ coaId, liftingId, vesselId, user: toUser(appUser), reason })),
                    catchError((error) => of(rejectVesselNominationFailAction({ liftingId, error })))
                )
            )
        )
    );

export const reviewVesselNominationEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(reviewVesselNominationAction),
            withLatestFrom(store.select(selectCurrentLifting), store.select(selectCurrentUser)),
            switchMap(([{ vesselId }, { coaId, liftingId }, appUser]) =>
                liftingHttpService.reviewVessel(coaId, liftingId, vesselId).pipe(
                    map(() => reviewVesselNominationSuccessAction({ coaId, liftingId, vesselId, user: toUser(appUser) })),
                    catchError((error) => of(reviewVesselNominationFailAction({ liftingId, error })))
                )
            )
        )
    );

export const preferVesselNominationEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(preferVesselNominationAction),
            withLatestFrom(store.select(selectCurrentLifting), store.select(selectCurrentUser)),
            switchMap(([{ vesselId }, { coaId, liftingId }, appUser]) =>
                liftingHttpService.preferVessel(coaId, liftingId, vesselId).pipe(
                    map(() => preferVesselNominationSuccessAction({ coaId, liftingId, vesselId, user: toUser(appUser) })),
                    catchError((error) => of(preferVesselNominationFailAction({ liftingId, error })))
                )
            )
        )
    );

export const renominateVesselEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(renominateVesselAction),
            withLatestFrom(store.select(selectCurrentLifting), store.select(selectCurrentUser)),
            switchMap(([{ vesselId, reason }, { coaId, liftingId }, appUser]) =>
                liftingHttpService.renominateVessel(coaId, liftingId, vesselId, reason).pipe(
                    map(() => renominateVesselSuccessAction({ coaId, liftingId, vesselId, user: toUser(appUser), reason })),
                    catchError((error) => of(renominateVesselFailAction({ liftingId, error })))
                )
            )
        )
    );
