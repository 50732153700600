import { Injectable } from "@angular/core";

import { hasValue } from "@ops/shared";

import { additionalFreightExpenseToTotal, calculateTotals } from "../../../../state/totals/selectors";
import { Fixture, FixtureType, Voyage } from "../../../models";
import { CargoTotalsViewModel } from "../../../models/common/cargo-totals.model";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class FreightMissingFreightSpendRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    private readonly warningMessage = "Missing Freight Spend";

    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();
        if (fixture.fixtureType?.id !== FixtureType.Voyage) {
            return warnings;
        }

        voyages.forEach((voyage) => {
            const totals = calculateTotals(voyage.cargoes, voyage.destinations);
            const additionalFreightExpenses = voyage.additionalFreightExpenses.map((expense) => additionalFreightExpenseToTotal(expense));
            totals.concat(additionalFreightExpenses).forEach((item, index) => {
                if (!hasValue(item.freightSpend)) {
                    const message = "Missing Freight Spend";
                    const path = [
                        ...this.getVoyagePathSegment(fixture.fixtureType, voyage),
                        new FixtureWarningPathSegment("totals", "Totals"),
                        FixtureWarningPathSegment.fromIndex(index, "Cargo", item.description),
                        new FixtureWarningPathSegment("freightSpend", "Freight Spend")
                    ];
                    const dismissingRecord = dismissedWarningRecord(this.getWarningHash(voyage, item));
                    warnings.push(new FixtureWarning("freight", message, path, dismissingRecord));
                }
            });
        });

        return warnings;
    }

    getWarningHash(voyage: Voyage, model: CargoTotalsViewModel) {
        const warningHashBuilder = new WarningHashBuilder().withVoyage(voyage.voyageId).withCategory("freight").withMessage(this.warningMessage);
        if (model.additionalFreightExpenseId) {
            return warningHashBuilder.withAdditionalFreightExpense(model.legacyAdditionalFreightExpenseId).build();
        } else {
            return warningHashBuilder.withCargo(model.legacyCargoId).build();
        }
    }
}
