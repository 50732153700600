import {
    AbstractControlState,
    Boxed,
    FormGroupState,
    setUserDefinedProperty,
    setValue,
    StateUpdateFns,
    unbox,
    updateArray,
    updateGroup,
    validate,
    ValidationErrors
} from "ngrx-forms";

import { hasValue, validateRequired, validateRequiredIf, validQuantityModel, validNominatedQuantityModel } from "@ops/state";

import { QuantityModel } from "../../../../../fixture/shared/models/form-models";
import { compatibleCoaToleranceUnit } from "../../../cargo/form/validation";
import {
    CargoId,
    isFirmedCargoPlanStatus,
    isTentativeCargoPlanStatus,
    Lifting,
    LiftingCargoForm,
    LiftingCargoLaycanForm,
    LiftingCargoLocationForm,
    LiftingState,
    quantityUnitToCoaType,
    validateLiftingCargoes
} from "../../../model";

// @ts-ignore
declare module "ngrx-forms/src/state" {
    export interface ValidationErrors {
        cargoes?: ReadonlyArray<CargoId>;
    }
}

const FIRM_ATTEMPTED_USER_PROPERTY = "firmAttempted";
export const setFirmAttempted = (firmAttempted: boolean = true) => setUserDefinedProperty(FIRM_ATTEMPTED_USER_PROPERTY, firmAttempted);
export const getFirmAttempted = <TValue>(state: AbstractControlState<TValue> | AbstractControlState<Boxed<TValue>>): boolean | undefined =>
    state.userDefinedProperties[FIRM_ATTEMPTED_USER_PROPERTY];

export const validateCargoPlanStatus = (form: FormGroupState<LiftingCargoLaycanForm>, lifting: Lifting) => {
    const conditionalUpdateFns: StateUpdateFns<LiftingCargoLaycanForm>[] = [];

    const firmAttempt = isTentativeCargoPlanStatus(lifting) && isFirmedCargoPlanStatus(form.value);
    const firmAttempted = getFirmAttempted(form.controls.cargoPlanStatus);

    if (firmAttempt || firmAttempted) {
        conditionalUpdateFns.push({ cargoPlanStatus: setFirmAttempted() });

        const validationResult = validateLiftingCargoes(lifting);
        if (validationResult.isInvalid) {
            conditionalUpdateFns.push({ cargoPlanStatus: setValue("Tentative") });
            conditionalUpdateFns.push({ cargoPlanStatus: validate(() => ({ cargoes: validationResult.invalidCargoes })) });
        } else {
            conditionalUpdateFns.push({ cargoPlanStatus: validate(() => ({})) });
        }
    }

    const tentativeAttempt = isFirmedCargoPlanStatus(lifting) && isTentativeCargoPlanStatus(form.value);
    if (tentativeAttempt) {
        conditionalUpdateFns.push({ cargoPlanStatus: validate(() => ({})) });
        conditionalUpdateFns.push({ cargoPlanStatus: setFirmAttempted(false) });
    }

    return conditionalUpdateFns;
};

export const validateLiftingCargoLaycanForm = (form: FormGroupState<LiftingCargoLaycanForm>, liftingState: LiftingState) =>
    updateGroup<LiftingCargoLaycanForm>(validateCargoPlanStatus(form, liftingState.lifting))(form);

export const validateLiftingCargoForm = (form: FormGroupState<LiftingCargoForm>) => {
    const hasMinToleranceValue = hasValue(form.value.minTolerance);
    const hasMaxToleranceValue = hasValue(form.value.maxTolerance);
    const anyToleranceFieldSpecified = hasValue(form.value.toleranceOption) || hasValue(form.value.toleranceUnit) || hasMinToleranceValue || hasMaxToleranceValue;
    const quantityUnit = unbox(form.value.quantity).unit;

    return updateGroup<LiftingCargoForm>({
        cargoId: validateRequired(),
        cargoProduct: validateRequired(),
        quantity: validate(validNominatedQuantityModel),
        minTolerance: validateRequiredIf(anyToleranceFieldSpecified),
        maxTolerance: validateRequiredIf(anyToleranceFieldSpecified),
        toleranceUnit: validateRequiredIf(anyToleranceFieldSpecified, compatibleCoaToleranceUnit(quantityUnit)),
        toleranceOption: validateRequiredIf(anyToleranceFieldSpecified),
        freightRate: validate(validQuantityModel, compatibleFreightRateUnit(quantityUnit)),
        loadLocations: updateArray<LiftingCargoLocationForm>((loc) => validateLiftingCargoLocationForm(loc)),
        dischargeLocations: updateArray<LiftingCargoLocationForm>((loc) => validateLiftingCargoLocationForm(loc))
    })(form);
};

export const validateLiftingCargoLocationForm = (form: FormGroupState<LiftingCargoLocationForm>) => form;

const compatibleFreightRateUnit = (quantityUnit: string) => (value: Boxed<QuantityModel>): ValidationErrors => {
    if (!quantityUnit) {
        return {};
    }

    const coaQuantityUnit = quantityUnitToCoaType(quantityUnit);
    const freightRate = unbox(value);

    if (!freightRate) {
        return {};
    }

    if (coaQuantityUnit === "Lump Sum" && freightRate.unit !== "Lump Sum") {
        return {
            coaQuantityUnitIncompatible: {
                quantityUnit,
                actual: freightRate.unit
            }
        };
    }

    return {};
};
