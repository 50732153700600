import { createSelector } from "@ngrx/store";

import { selectCurrentCoaState } from "../coa";
import { nominationTaskToListItem } from "./utils";

export const selectCurrentCoaNominationTaskListItems = createSelector(selectCurrentCoaState, (state) => state?.coa?.nominationTasks.map(nominationTaskToListItem));

export const selectNominationTaskForm = createSelector(selectCurrentCoaState, (state) => state?.nominationTaskForm);

export const selectNominationTaskFormValue = createSelector(selectNominationTaskForm, (form) => form?.value);

export const selectNominationTaskFormSaving = createSelector(selectCurrentCoaState, (state) => !state || state.nominationTaskFormSaveStatus === "persisting");

export const selectNominationTaskFormSaveFailed = createSelector(selectCurrentCoaState, (state) => !state || state.nominationTaskFormSaveStatus === "failed");

export const selectErroredNominationTasks = createSelector(selectCurrentCoaState, (state) =>
    state.erroredItems?.filter((e) => e.itemType === "nominationTasks").map((e) => ({ id: e.id, error: e.error }))
);

export const selectCurrentNominationTaskFormLeadAssignee = createSelector(selectNominationTaskFormValue, (formValue) => {
    const users = formValue?.assignedUsers.value;
    return users && users.length ? users[0] : null;
});
