import { Injectable } from "@angular/core";

import { toStringTime } from "../../../../shared/date-utils/date-utilities";
import { WorkingDayType } from "../../../../shared/reference-data/workingday-type";
import { CommandHandler } from "../../../mediator/command-handlers/command-handler";
import { Voyage } from "../../../shared/models";
import { UpdateStatementOfFactsCommand } from "./update-statement-of-facts.command";

@Injectable({
    providedIn: "root"
})
export class UpdateStatementOfFactsCommandHandler implements CommandHandler {
    handle(voyage: Voyage, command: UpdateStatementOfFactsCommand) {
        if (!command || !command.statementsOfFact || !command.destinationId) {
            throw new Error("Given voyage form model is invalid.");
        }

        const destinationtoUpdate = voyage.destinations.find((c) => c.id === command.destinationId);

        destinationtoUpdate.workingDayType = command.statementsOfFact.workingDayType;

        if (command.statementsOfFact.workingDayType && command.statementsOfFact.workingDayType.id === WorkingDayType.Shinc.id) {
            destinationtoUpdate.excludingFromDay = null;
            destinationtoUpdate.excludingFromTime = null;
            destinationtoUpdate.excludingToDay = null;
            destinationtoUpdate.excludingToTime = null;
            destinationtoUpdate.unlessUsed = null;
        } else {
            destinationtoUpdate.excludingFromDay = command.statementsOfFact.excludingFromDay;
            destinationtoUpdate.excludingFromTime = toStringTime(command.statementsOfFact.excludingFromTime);
            destinationtoUpdate.excludingToDay = command.statementsOfFact.excludingToDay;
            destinationtoUpdate.excludingToTime = toStringTime(command.statementsOfFact.excludingToTime);
            destinationtoUpdate.unlessUsed = command.statementsOfFact.unlessUsed;
        }
    }
}
