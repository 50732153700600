import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { filter, tap, withLatestFrom, map } from "rxjs/operators";

import { AppInsightsService, MixpanelService } from "@ops/core";

import { leftBarSetCurrentPanel } from "../../state";
import { removeCargoSuccess, saveCargoSuccess, saveNewCargoSuccess } from "./cargo/actions";
import { addLiftingSuccess, routerLoadCoaSuccess } from "./coa/actions";
import { selectCurrentCoa } from "./coa/selectors";
import { searchCoaGridSuccess } from "./grid/actions";
import { saveLiftingHeaderSuccess } from "./lifting-header/actions";
import { createFixtureSuccess, routerLoadLiftingSuccess } from "./lifting/actions";
import { saveLiftingCargoSuccess, saveNewLiftingCargoSuccess, saveCargoPlanStatusSuccess, saveLaycanSuccess, emailCargoPlan } from "./lifting/cargo/actions";
import { initLiftingContactsPanel } from "./lifting/company/actions";
import {
    acceptVesselNominationSuccess,
    rejectVesselNominationSuccess,
    reviewVesselNominationSuccess,
    renominateNewVesselSuccess,
    saveVesselNominationSuccess,
    updateVesselNominationSuccess
} from "./lifting/vessel-nomination/actions";
import { addDischargeLocationSuccess, addLoadLocationSuccess, removeDischargeLocation, removeLoadLocation } from "./locations/actions";
import { CoaFeatureState } from "./model/state";
import { removeNominationTaskSuccess, saveNewNominationTaskSuccess, saveNominationTaskSuccess } from "./nomination-tasks/actions";

export enum MixpanelEvents {
    CoaGridLoad = "CoA Grid Load",
    CoaPageLoad = "CoA Page Load",
    CoaCargoAdd = "CoA Cargo Add",
    CoaCargoSave = "CoA Cargo Save",
    CoaCargoDelete = "CoA Delete",
    CoaLocationAdd = "CoA Location Add",
    CoaLocationSave = "CoA Location Save",
    CoaLocationDelete = "CoA Location Delete",
    CoaLiftingClick = "CoA Lifting Click",
    CoaCreateLifting = "CoA Create Lifting",
    CoaCloneLifting = "CoA Clone Lifting",
    CoaNominationAdd = "CoA Nomination Add",
    CoaNominationEdit = "CoA Nomination Edit",
    CoaNominationDelete = "CoA Nomination Delete",
    CoaLHPanelActionOpen = "CoA LH Panel Action Open",
    LiftingCargoTabClick = "CoA Lifting Cargo Tab Click",
    LiftingVesselTabClick = "CoA Lifting Vessel Tab Click",
    LiftingCargoAdd = "CoA Lifting Cargo Add Click",
    LiftingCargoSave = "CoA Lifting Cargo Save Click",
    LiftingCargoEdit = "CoA Lifting Cargo Edit Click",
    LiftingCargoLaycanChange = "CoA Lifting Cargo Laycan Change",
    LiftingCargoFirm = 'CoA Lifting Cargo Plan "Firm" Click',
    LiftingCargoTentative = 'CoA Lifting Cargo Plan "Tentative" Click',
    LiftingVesselAdd = "CoA Lifting Vessel Nomination Add",
    LiftingVesselSave = "CoA Lifting Vessel Nomination Save",
    LiftingVesselAccept = "CoA Lifting Vessel Accept Click",
    LiftingVesselReject = "CoA Lifting Vessel Reject Click",
    LiftingVesselRenominate = "CoA Lifting Vessel Renominate Click",
    LiftingVesselSetToReview = "CoA Lifting Vessel Set to Review Click",
    LiftingHeaderEdit = "CoA Lifting Header Edit",
    LiftingHeaderSave = "CoA Lifting Header Save",
    LiftingCreateFixture = "CoA Lifting Create Fixture Click",
    LiftingCreateCargoPlan = "CoA Lifting Generate Cargo Plan Email",
    LiftingLHPanelOpen = "Ops: COA View Lifting History"
}

@Injectable()
export class CoaLoggingEffects {
    coaLoaded$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(routerLoadCoaSuccess),
                tap(({ coa }) => {
                    if (coa) {
                        this.appInsights.setGlobalProperty("coaId", coa.coaId);
                        this.mixpanel.track(MixpanelEvents.CoaPageLoad, {
                            Sector: coa?.sector,
                            "CoA Status": coa?.contractStatus
                        });
                    } else {
                        this.appInsights.removeGlobalProperty("coaId");
                    }
                })
            ),
        { dispatch: false }
    );

    contractsGridLoaded$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(searchCoaGridSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.CoaGridLoad);
                })
            ),
        { dispatch: false }
    );

    coaCargoAdd$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveNewCargoSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.CoaCargoAdd);
                })
            ),
        { dispatch: false }
    );

    coaCargoSave$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveCargoSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.CoaCargoSave);
                })
            ),
        { dispatch: false }
    );

    coaCargoDelete$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(removeCargoSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.CoaCargoDelete);
                })
            ),
        { dispatch: false }
    );

    coaLoadLocationAdd$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addLoadLocationSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.CoaLocationAdd, { "Location Type": "Load" });
                })
            ),
        { dispatch: false }
    );

    coaDischargeLocationAdd$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addDischargeLocationSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.CoaLocationAdd, { "Location Type": "Discharge" });
                })
            ),
        { dispatch: false }
    );

    coaLocationRemove$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(removeLoadLocation, removeDischargeLocation),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.CoaLocationDelete);
                })
            ),
        { dispatch: false }
    );

    coaLiftingClick$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routerLoadLiftingSuccess),
            tap((action) => {
                this.mixpanel.track(MixpanelEvents.CoaLiftingClick, {
                    Sector: action?.lifting?.liftingId
                });
            }),
            map(() => initLiftingContactsPanel())
        )
    );

    coaCreateLifting$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addLiftingSuccess),
                withLatestFrom(this.store.select(selectCurrentCoa)),
                tap(([, coa]) => {
                    this.mixpanel.track(MixpanelEvents.CoaCreateLifting, {
                        Sector: coa?.sector
                    });
                })
            ),
        { dispatch: false }
    );

    coaNominationAdd$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveNewNominationTaskSuccess),
                withLatestFrom(this.store.select(selectCurrentCoa)),
                tap(([action, coa]) => {
                    this.mixpanel.track(MixpanelEvents.CoaNominationAdd, {
                        Sector: coa?.sector,
                        "Nomination Event Type": action.addNominationTask.binding.trigger
                    });
                })
            ),
        { dispatch: false }
    );

    coaNominationEdit$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveNominationTaskSuccess),
                withLatestFrom(this.store.select(selectCurrentCoa)),
                tap(([action, coa]) => {
                    this.mixpanel.track(MixpanelEvents.CoaNominationEdit, {
                        Sector: coa?.sector,
                        "Nomination Event Type": action.updateNominationTask.binding.trigger
                    });
                })
            ),
        { dispatch: false }
    );

    coaNominationRemove$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(removeNominationTaskSuccess),
                withLatestFrom(this.store.select(selectCurrentCoa)),
                tap(([action, coa]) => {
                    this.mixpanel.track(MixpanelEvents.CoaNominationDelete, {
                        Sector: coa?.sector,
                        "Nomination Event Type": action.nominationTask.binding.trigger
                    });
                })
            ),
        { dispatch: false }
    );

    liftingCargoAdd$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveNewLiftingCargoSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingCargoAdd);
                })
            ),
        { dispatch: false }
    );

    liftingCargoSave$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveLiftingCargoSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingCargoSave);
                })
            ),
        { dispatch: false }
    );

    liftingCargoLaycanChange$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveLaycanSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingCargoLaycanChange);
                })
            ),
        { dispatch: false }
    );

    liftingCargoTentative$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveCargoPlanStatusSuccess),
                filter((action) => action.status === "Tentative"),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingCargoTentative);
                })
            ),
        { dispatch: false }
    );

    liftingCargoFirmed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveCargoPlanStatusSuccess),
                filter((action) => action.status === "Firmed"),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingCargoFirm);
                })
            ),
        { dispatch: false }
    );

    liftingVesselAdd$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveVesselNominationSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingVesselAdd);
                })
            ),
        { dispatch: false }
    );

    liftingVesselSave$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateVesselNominationSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingVesselSave);
                })
            ),
        { dispatch: false }
    );

    liftingVesselAccept$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(acceptVesselNominationSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingVesselAccept);
                })
            ),
        { dispatch: false }
    );

    liftingVesselReject$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(rejectVesselNominationSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingVesselReject);
                })
            ),
        { dispatch: false }
    );

    liftingVesselRenominate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(renominateNewVesselSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingVesselRenominate);
                })
            ),
        { dispatch: false }
    );

    liftingVesselReview$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(reviewVesselNominationSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingVesselSetToReview);
                })
            ),
        { dispatch: false }
    );

    liftingHeaderEdit$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveLiftingHeaderSuccess),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingHeaderEdit);
                })
            ),
        { dispatch: false }
    );

    liftingCreateFixture$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(createFixtureSuccess),
                withLatestFrom(this.store.select(selectCurrentCoa)),
                tap(([, coa]) => {
                    this.mixpanel.track(MixpanelEvents.LiftingCreateFixture, {
                        Sector: coa?.sector
                    });
                })
            ),
        { dispatch: false }
    );

    liftingGenerateCargoPlanEmail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(emailCargoPlan),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingCreateCargoPlan);
                })
            ),
        { dispatch: false }
    );

    liftingLHPanelOpen$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(leftBarSetCurrentPanel),
                filter(({ panel }) => panel === "lifting-history"),
                tap(() => {
                    this.mixpanel.track(MixpanelEvents.LiftingLHPanelOpen);
                })
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private appInsights: AppInsightsService, private mixpanel: MixpanelService, private store: Store<CoaFeatureState>) {}
}
