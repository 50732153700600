import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { WarningHttpService } from "../../../services/warning-http.service";
import { FixtureFeatureState, FixtureId } from "../../../state/model";
import { saveFixtureAction } from "../../fixture/save-fixture";
import { selectCurrentFixtureDismissedWarnings, selectCurrentFixtureId } from "../../fixture/selectors";

/* ACTIONS */
const SAVE_ACTION_NAME = "[Warnings] Save Dismissed Warnings";

export const saveDismissedWarningsSuccessAction = createAction(`${SAVE_ACTION_NAME} Success`, props<{ fixtureId: FixtureId }>());
export const saveDismissedWarningsFailAction = createAction(`${SAVE_ACTION_NAME} Fail`, props<{ fixtureId: FixtureId; error: Error }>());

/* EFFECTS */
export const saveDismissedWarningsEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, warningHttpService: WarningHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveFixtureAction),
            withLatestFrom(store.select(selectCurrentFixtureId), store.select(selectCurrentFixtureDismissedWarnings)),
            mergeMap(([, fixtureId, dismissedWarnings]) =>
                warningHttpService.saveWarningHashes(fixtureId, dismissedWarnings?.map((dw) => dw.warningHash) || []).pipe(
                    map(() => saveDismissedWarningsSuccessAction({ fixtureId })),
                    catchError((err) => of(saveDismissedWarningsFailAction({ fixtureId, error: err })))
                )
            )
        )
    );
