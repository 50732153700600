import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import { SeaNetLocation } from "../shared/models";
import { DeliveryHeaderDate } from "./delivery-header-date";

@Injectable({ providedIn: "root" })
export class DeliveryHeaderDateService {
    private utc = "UTC";

    getHeaderDate(
        actualDeliveryDate: string,
        estimatedDeliveryDate: string,
        actualDeliveryDateLabel: string,
        estimatedDeliveryDateLabel: string,
        locations: SeaNetLocation[]
    ): DeliveryHeaderDate {
        let headerDate = null;
        let tooltipDate = null;

        const dateToFormat = actualDeliveryDate || estimatedDeliveryDate;
        if (dateToFormat) {
            const dateType = actualDeliveryDate ? actualDeliveryDateLabel : estimatedDeliveryDateLabel;
            const timezone = this.getLocationsTimezone(locations);
            const localDate = this.getLocalDate(dateToFormat, timezone);

            const dateFormat = this.getFormattedDate(localDate);
            tooltipDate = timezone !== this.utc ? this.getTooltipDate(localDate, timezone) : null;
            headerDate = `${dateType}: ${dateFormat}`;
        }

        return {
            header: headerDate,
            tooltip: tooltipDate
        };
    }

    private getLocalDate(date: string, timezone: string) {
        return moment(date).tz(timezone);
    }

    private getTooltipDate(momentDate: moment.Moment, timezone: string) {
        return `${momentDate.format("DD MMM YY, HH:mm")} ${timezone}`;
    }

    private getFormattedDate(momentDate: moment.Moment) {
        return momentDate.format("DD MMM YY, HH:mm z");
    }

    private getLocationsTimezone(locations: SeaNetLocation[]): string {
        if (locations && locations.length > 1) {
            return this.utc;
        }

        return (locations && locations[0] && locations[0].timeZone) || this.utc;
    }
}
