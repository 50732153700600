import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { SetValueAction } from "ngrx-forms";
import { debounceTime, filter, tap, withLatestFrom } from "rxjs/operators";

import { deepCopy } from "@ops/shared";
import { CargoBerthActivityType, LaytimeEventType, TimeBarType } from "@ops/shared/reference-data";

import { FixtureDataService } from "../../../services/fixture-data.service";
import { Demurrage, Division, TimeBar } from "../../../shared/models";
import { selectCurrentFixtureDivision } from "../../fixture";
import { FixtureFeatureState, getLatestLaytimeEventDate, VoyageForm } from "../../model";
import { selectCurrentVoyageFormValue } from "../../voyage";

function ensureTimeBars(demurrage: Demurrage) {
    for (const type of [TimeBarType.DemurrageNotify, TimeBarType.DemurragePresent, TimeBarType.ExpenseNotify, TimeBarType.ExpensePresent]) {
        if (!demurrage.timeBars.some((t) => t.type && t.type.id === type.id)) {
            demurrage.timeBars.push(<TimeBar>{ type: type, fromDate: null });
        }
    }

    return demurrage;
}

function updateTimeBars(voyage: VoyageForm, division: Division, demurrage: Demurrage) {
    const latestLaytimeEventDate = getLatestLaytimeEventDate(voyage, CargoBerthActivityType.Discharge, LaytimeEventType.CargoCompleted);
    if (!latestLaytimeEventDate) {
        return demurrage;
    }

    demurrage = ensureTimeBars(deepCopy(demurrage));

    if (latestLaytimeEventDate) {
        if (division === Division.dryCargo) {
            const demurrageNotify = demurrage.timeBars.find((t) => t.type && t.type.id === TimeBarType.DemurrageNotify.id);
            if (demurrageNotify) {
                demurrageNotify.fromDate = latestLaytimeEventDate;
            }
        } else  {
            for (const timeBar of demurrage.timeBars) {
                timeBar.fromDate = latestLaytimeEventDate;
            }

        }
    }

    return demurrage;
}

/* EFFECTS */
export const interopUpdateDemurrageTimeBarsEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, fixtureDataService: FixtureDataService) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<string>>(SetValueAction.TYPE),
            filter(({ controlId }) => {
                const controlPath = controlId.split(".");
                if (controlPath.length < 3 || controlPath[controlPath.length - 3] !== "laytimeEvents") {
                    return false;
                }

                return (controlPath[controlPath.length - 1] === "type" || controlPath[controlPath.length - 1] === "eventDate");
            }),
            withLatestFrom(
                store.select(selectCurrentFixtureDivision),
                store.select(selectCurrentVoyageFormValue)
            ),
            debounceTime(50),
            tap(([, division, voyageForm]) => {
                const demurrage = updateTimeBars(
                    voyageForm,
                    division,
                    fixtureDataService.currentFixtureSnapshot.demurrage
                );

                fixtureDataService.updateDemurrage(demurrage);
            })
        ),
        { dispatch: false }
    );
