<div *ngIf="addActivityLocationsFilter$ | async as filter" class="ops-ltc-shell-container">
    <div class="ops-ltc-filter">
        <div class="p-field p-col-5">
            <ng-select class="ops-select"
                       [clearable]="false" [selectOnTab]="true" [items]="addActivityLocationsFilter"
                       [ngrxFormControlState]="filter.filter" data-cy="cargo-location">
            </ng-select>
        </div>
        <div class="p-field p-field-checkbox p-mb-1">
            <input id="filter-all-selected" type="checkbox" class="p-checkbox"
                   [checked]="filter.selectAll" (change)="selectAll()" data-cy="select-all"/>
            <label for="filter-all-selected">Select All</label>
        </div>
    </div>
    <div class="ops-scrollable-container-wrapper">
        <div class="ops-scrollable-container">
            <div [ngSwitch]="filter.filter.value" class="ops-scrollable-content">
                <ops-voyage-activities-filter *ngSwitchCase="'Locations'"
                                               [voyageActivities]="filter.voyageActivities"></ops-voyage-activities-filter>
                <ops-cargoes-filter *ngSwitchCase="'Cargoes'" [cargoes]="filter.cargoes"></ops-cargoes-filter>
            </div>
        </div>
    </div>
</div>
