import { MIN_DATE, MAX_DATE, MaritimeDateRange } from "@maritech/maritime-date";
import { NgbDateStruct, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { DateTime } from "luxon";

import { NgbDateRangeStruct, NgbDateTimeStruct } from "./date-picker.component";

export type TimeObject = { hour: number; minute: number };
export type DateObject = { year: number; month: number; day: number };
export type DateInput = string | Date | DateTime | DateObject;

export function toNgbDateTimeStruct(model: DateTime): NgbDateTimeStruct {
    if (!model || !model.isValid) {
        return;
    }

    return {
        date: {
            year: model.year,
            month: model.month,
            day: model.day
        },
        time: {
            hour: model.hour,
            minute: model.minute,
            second: model.second
        }
    };
}

export function toNgbDateRangeStruct(model: DateTime | MaritimeDateRange): NgbDateRangeStruct | undefined {
    if (model instanceof MaritimeDateRange) {
        return { from: toNgbDateTimeStruct(model.start), to: toNgbDateTimeStruct(model.end) };
    }

    const singleDate = model && model.isValid && toNgbDateTimeStruct(model);

    if (singleDate) {
        return { from: singleDate, to: singleDate };
    }
}

export function fromNgbDateTime(date: NgbDateStruct, time: NgbTimeStruct, zone: string): DateTime {
    return DateTime.fromObject({
        year: date.year,
        month: date.month,
        day: date.day,
        hour: time?.hour,
        minute: time?.minute,
        second: time?.second,
        zone: zone
    });
}

export function fromNgbDateRangeStruct(model: NgbDateRangeStruct, zone: string, isRange: boolean): DateTime | MaritimeDateRange | undefined {
    if (!model || (!model.from && !model.to)) {
        return;
    }

    if (isRange) {
        const start = model.from ?? model.to;
        const end = model.to ?? model.from;

        // Split into start/end as the picker can return an end datetime without a date (just a time) when reselecting
        return MaritimeDateRange.exact(fromNgbDateTime(start.date, start.time, zone), fromNgbDateTime(end.date ?? start.date, end.time, zone));
    }

    const singleDate = model.to ?? model.from;

    return fromNgbDateTime(singleDate.date, singleDate.time, zone);
}

export function dateInputToNgbDateStruct(input: DateInput): NgbDateStruct | undefined {
    if (!input) {
        return;
    }

    const dateTime = dateInputToDateTime(input);

    return { year: dateTime.year, month: dateTime.month, day: dateTime.day };
}

export function dateInputToDateTime(input: DateInput): DateTime | undefined {
    if (!input) {
        return;
    }

    if (DateTime.isDateTime(input)) {
        return input;
    }

    if (input instanceof Date) {
        return DateTime.fromJSDate(input);
    }

    if (typeof input === "string") {
        return DateTime.fromISO(input);
    }
}

export const RANGE_DEFAULT_START_TIME: TimeObject = { hour: 0, minute: 0 };
export const RANGE_DEFAULT_END_TIME: TimeObject = { hour: 23, minute: 59 };

export const MIN_SUPPORTED_DATE = MIN_DATE;
export const MAX_SUPPORTED_DATE = MAX_DATE;
