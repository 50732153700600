import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { FormGroupState } from "ngrx-forms";

import { Division } from "../../shared/models";
import { CargoForm, ToleranceForm } from "../../state";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "tbody[ops-cargo-form]",
    templateUrl: "./cargo-form.component.html",
    styleUrls: ["./cargo-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CargoFormComponent {
    readonly Division = Division;

    @Input() form: FormGroupState<CargoForm>;
    @Input() expanded: boolean;
    @Input() canAdd: boolean;
    @Input() canClone: boolean;
    @Input() canRemove: boolean;
    @Input() canApplyCargoFreightRate: boolean;
    @Input() readonly: boolean;
    @Input() showAddButton: boolean;
    @Input() showSpecialisedCargoControls: boolean;

    @Output() readonly expand = new EventEmitter();
    @Output() readonly collapse = new EventEmitter();
    @Output() readonly add = new EventEmitter();
    @Output() readonly clone = new EventEmitter();
    @Output() readonly remove = new EventEmitter();
    @Output() readonly applyCargoFreightRate = new EventEmitter();

    constructor(private elementRef: ElementRef) {}

    get toleranceForm(): FormGroupState<ToleranceForm> {
        return this.form.controls.tolerance;
    }

    get baseFreightRateIsTouched(): boolean {
        return this.form.controls.baseFreightRate.isTouched || this.form.controls.baseFreightRateUnit.isTouched;
    }

    get colCount(): number {
        return this.elementRef.nativeElement.querySelectorAll(".cargo-component-td").length;
    }

    toggle(): void {
        if (this.expanded) {
            this.collapse.emit();
        } else {
            this.expand.emit();
        }
    }
}
