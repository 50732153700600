import { AtSeaBunkerConsumption } from "src/app/fixture/shared/models/dtos/at-sea-bunker-consumption";
import { Command } from "../../../../mediator/commands/command";
import { AtSeaConsumptionFormModel } from "../../../../shared/models/form-models/at-sea-consumption.model";
import { UpdateAtSeaConsumptionCommandHandler } from "./update-at-sea-consumption.command-handler";

export class UpdateAtSeaConsumptionCommand extends Command {
    constructor(readonly model: AtSeaConsumptionFormModel, readonly value: AtSeaBunkerConsumption) {
        super(UpdateAtSeaConsumptionCommandHandler, null);
    }
}
