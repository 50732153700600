import { AbstractControl, UntypedFormArray, ValidatorFn } from "@angular/forms";

export class RequiredIfValidator {
    static validate(...fields: string[]): ValidatorFn {
        const errorKey = "required";

        function setErrorsOnEmptyFields(controls: AbstractControl[]) {
            controls
                .filter((control) => (control.value instanceof Array ? control.value.length === 0 : !control.value && control.value !== 0))
                .forEach((control) => {
                    control.setErrors({ [errorKey]: true });
                });
        }

        function clearErrors(controls: AbstractControl[]) {
            controls
                .filter((control) => control.errors)
                .forEach((control) => {
                    if (control instanceof UntypedFormArray && Object.keys(control.errors).length === 1) {
                        control.setErrors(null);
                    } else {
                        if (control.errors) {
                            delete control.errors[errorKey];
                            if (!Object.keys(control.errors).length) {
                                control.setErrors(null);
                            } else {
                                control.setErrors(control.errors);
                            }
                        }
                    }
                });
        }

        return (control: AbstractControl): null => {
            const controls = fields.map((field) => control.get(field));

            const allFieldsMustBeSet = controls.some((c) => c && ((c instanceof UntypedFormArray && c.length > 0) || c.value instanceof Array ? c.value.length > 0 : !!c.value));

            if (allFieldsMustBeSet) {
                setErrorsOnEmptyFields(controls);
            } else {
                clearErrors(controls);
            }

            return null;
        };
    }

    static validateOnlyIfHasValue(requiredField: string, checkField: string): ValidatorFn {
        const errorKey = "required";

        return (control: AbstractControl): null => {
            const requiredControl = control.get(requiredField);
            const checkControl = control.get(checkField);

            if (checkControl && checkControl.value && (requiredControl.value === undefined || requiredControl.value === null || requiredControl.value === "")) {
                requiredControl.setErrors({ [errorKey]: true });
            } else if (requiredControl.errors) {
                delete requiredControl.errors[errorKey];
            }

            return null;
        };
    }
}
