import { DateTime } from "luxon";

import { SortOrder } from "../../../fixture/shared/models";
import { GridColumnPinDirection } from "../../../fixture/shared/models/enums/grid-column-pin-direction";
import { GridType } from "../../../fixture/shared/models/enums/grid-type";
import { LegacyWorksheetActionType } from "../store/legacy-worksheet-action-type";

export class Worksheet {
    constructor(
        public worksheetId: string,
        public userId: number,
        public name: string,
        public queries: string[],
        public columns: ColumnDefinition[],
        public sort: ColumnSort,
        public updatedDate: DateTime,
        public worksheetType: GridType
    ) {}
}

export class ColumnDefinition {
    constructor(public columnId: string, public hidden: boolean, public width: number, public pin: GridColumnPinDirection) {}
}

export class ColumnSort {
    constructor(public columnId: string, public order: SortOrder) {}
}

export class WorksheetView extends Worksheet {
    error?: { source: LegacyWorksheetActionType; error: Error } = null;
    saving = false;
    renaming = false;

    constructor(worksheet: Worksheet) {
        super(worksheet.worksheetId, worksheet.userId, worksheet.name, worksheet.queries, worksheet.columns, worksheet.sort, worksheet.updatedDate, worksheet.worksheetType);
    }
}

export class CreateWorksheetModel {
    constructor(public name: string, public queries: string[], public columns: ColumnDefinition[], public sort: ColumnSort, public worksheetType: GridType) {}
}
