import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";

import { AppInsightsService } from "@ops/core";

import { EmailPreviewService } from "../../shared/email";
import { VoyageHttpService } from "../services";
import { FixtureDataService } from "../services/fixture-data.service";
import { InvoiceDataService } from "../services/invoice-data.service";
import { WarningHttpService } from "../services/warning-http.service";
import { EmailGenerationService } from "../shared/email";
import { TabNavigationService } from "../shared/navigation/tab-navigation.service";
import { auditGeneratePortTimesEmailEffect$, generatePortTimesEmailEffect$ } from "./berths/form/generate-port-times-email";
import { interopRemoveBerthFromDemurrageEffect$ } from "./berths/form/remove-berth";
import { markEtbVerifiedOnEtbChangeEffect$, verifyEtbEffect$ } from "./berths/form/verify-etb";
import { interopRemoveSpecialisedDestinationsFromDemurrageEffect$ } from "./cargoes/form/remove-cargo";
import { markSpecialisedCargoEtaVerifiedOnEtaChangeEffect$ } from "./cargoes/form/specialised/mark-specialised-cargo-eta-verified";
import { specialisedCargoFormChangeEffect$ } from "./cargoes/form/specialised/specialised-cargo-change-handler";
import { auditGenerateEtaEmailEffect$, generateEtaEmailEffect$, interopRemoveDestinationFromDemurrageEffect$, verifyEtaEffect$ } from "./destinations/effects";
import { markEtaVerifiedOnEtaChangeEffect$ } from "./destinations/form/verify-eta";
import { lockFixtureSuccess, reloadVoyages, unlockFixtureSuccess } from "./fixture";
import { loadDismissedWarningsOnFixtureLoadEffect$, loadVoyagesOnFixtureLoadEffect$ } from "./fixture/interop";
import { loadVoyagesEffect$, reloadVoyagesEffect$ } from "./fixture/load-voyages";
import { saveFixtureOnVoyagesSaveEffect$, saveFixtureVoyagesEffect$, saveVoyageEffect$ } from "./fixture/save-fixture.effects";
import { interopUpdateDemurrageTimeBarsEffect$ } from "./laytime-events/form/update-demurrage-timebars";
import { FixtureFeatureState } from "./model";
import { generateFreightInvoiceEffect } from "./totals/effects";
import { resetValidationOnFixtureLoadEffect$ } from "./validation";
import { addVoyageEffect$ } from "./voyage/form/add-voyage";
import { loadDismissedWarningsEffect$ } from "./warnings/dismiss/load-dismissed-warnings";
import { saveDismissedWarningsEffect$ } from "./warnings/dismiss/save-dismissed-warnings";

@Injectable()
export class FixtureEffects {
    loadVoyagesOnFixtureLoad$ = loadVoyagesOnFixtureLoadEffect$(this.actions$);

    loadVoyages$ = loadVoyagesEffect$(this.actions$, this.voyageHttpService);

    /**
     * When the fixture is locked/unlocked, reload the latest voyages from the server.
     */
    reloadVoyagesOnFixtureLock$ = createEffect(() =>
        this.actions$.pipe(
            ofType(lockFixtureSuccess, unlockFixtureSuccess),
            map(({ fixtureId }) => reloadVoyages({ fixtureId: fixtureId }))
        )
    );

    reloadVoyages$ = reloadVoyagesEffect$(this.actions$, this.voyageHttpService);

    /**
     * Translates a fixture save into a save action per voyage.
     */
    saveFixtureVoyages$ = saveFixtureVoyagesEffect$(this.actions$, this.store);

    /**
     * Persists an individual voyage.
     */
    saveVoyage$ = saveVoyageEffect$(this.actions$, this.store, this.voyageHttpService);

    /**
     * Interop - triggers fixture save on all voyages saved.
     */
    saveFixtureOnVoyagesSave$ = saveFixtureOnVoyagesSaveEffect$(this.actions$, this.store, this.fixtureService);

    addVoyage$ = addVoyageEffect$(this.actions$, this.store, this.voyageHttpService);

    generateEtaEmail$ = generateEtaEmailEffect$(this.actions$, this.store, this.emailGenerator, this.emailPreviewer, this.appInsightsMonitoringService);

    auditGenerateEtaEmail$ = auditGenerateEtaEmailEffect$(this.actions$, this.store, this.voyageHttpService);

    generatePortTimesEmailEffect$ = generatePortTimesEmailEffect$(this.actions$, this.store, this.emailGenerator, this.emailPreviewer);

    auditGeneratePortTimesEmailEffect$ = auditGeneratePortTimesEmailEffect$(this.actions$, this.store, this.voyageHttpService);

    verifyEta$ = verifyEtaEffect$(this.actions$, this.store, this.voyageHttpService);

    markEtaVerifiedOnEtaChange$ = markEtaVerifiedOnEtaChangeEffect$(this.actions$, this.store);

    verifyEtb$ = verifyEtbEffect$(this.actions$, this.store, this.voyageHttpService);

    markEtbVerifiedOnEtbChange$ = markEtbVerifiedOnEtbChangeEffect$(this.actions$, this.store);

    interopRemoveDestinationFromDemurrage$ = interopRemoveDestinationFromDemurrageEffect$(this.actions$, this.store, this.fixtureService);

    interopRemoveBerthFromDemurrage$ = interopRemoveBerthFromDemurrageEffect$(this.actions$, this.store, this.fixtureService);

    interopRemoveSpecialisedDestinationsFromDemurrageEffect$ = interopRemoveSpecialisedDestinationsFromDemurrageEffect$(this.actions$, this.store, this.fixtureService);

    interopUpdateDemurrageTimeBars$ = interopUpdateDemurrageTimeBarsEffect$(this.actions$, this.store, this.fixtureService);

    resetValidationOnFixtureLoadEffect$ = resetValidationOnFixtureLoadEffect$(this.actions$, this.store);

    generateFreightInvoice$ = generateFreightInvoiceEffect(this.actions$, this.invoiceDataService, this.tabNavigationService);

    markSpecialisedCargoEtaVerifiedOnEtaChange$ = markSpecialisedCargoEtaVerifiedOnEtaChangeEffect$(this.actions$, this.store);

    specialisedCargoFormChange$ = specialisedCargoFormChangeEffect$(this.actions$, this.store);

    loadDismissedWarningsOnFixtureLoadEffect$ = loadDismissedWarningsOnFixtureLoadEffect$(this.actions$);

    loadDismissedWarningsEffect$ = loadDismissedWarningsEffect$(this.actions$, this.warningHttpService);

    saveDismissedWarningsEffect$ = saveDismissedWarningsEffect$(this.actions$, this.store, this.warningHttpService);

    constructor(
        private actions$: Actions,
        private store: Store<FixtureFeatureState>,
        private fixtureService: FixtureDataService,
        private voyageHttpService: VoyageHttpService,
        private warningHttpService: WarningHttpService,
        private invoiceDataService: InvoiceDataService,
        private tabNavigationService: TabNavigationService,
        private emailGenerator: EmailGenerationService,
        private emailPreviewer: EmailPreviewService,
        private appInsightsMonitoringService: AppInsightsService
    ) {}
}
