import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from "@angular/core";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[clickOutside]"
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
    private disposer: () => void;

    @Input() ignoreSelectors: string[];
    @Output() clickOutside = new EventEmitter();

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.disposer = this.renderer.listen(document, "click", (event: Event) => {
            this.handleClick(event);
        });
    }

    ngOnDestroy() {
        if (this.disposer) {
            this.disposer();
        }
    }

    private handleClick(event: Event) {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        const ignoreElement = this.ignoreSelectors && this.ignoreElement(event.target);

        if (!clickedInside && !ignoreElement) {
            this.clickOutside.emit(event);
        }
    }

    private ignoreElement(element: any) {
        for (; element && element !== document; element = element.parentNode) {
            if (this.ignoreSelectors.some((x) => element.matches(x))) {
                return true;
            }
        }

        return false;
    }
}
