import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ExportScheduler {
    private _scheduled$ = new BehaviorSubject<boolean>(false);

    get scheduled$() {
        return this._scheduled$.asObservable();
    }

    schedule() {
        this._scheduled$.next(true);
    }

    completed() {
        this._scheduled$.next(false);
    }
}
