import { Injectable } from "@angular/core";

import { CargoBerthActivityType } from "../../../../../shared/reference-data/cargo-berth-activity-type";
import { Enumeration } from "../../../../../shared/reference-data/enumeration";
import { CargoId } from "../../../../state/model";
import { Cargo, Division, Fixture, FixtureType, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class ExternalVisibilitySpecialisedLoadDischargeLocationRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    readonly requiredLocationActivityTypes = [CargoBerthActivityType.Load, CargoBerthActivityType.Discharge];

    shouldEvaluate(fixture: Fixture) {
        return fixture.division.id === Division.specialisedProducts;
    }

    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        voyages.forEach((voyage) =>
            voyage.cargoes.forEach((cargo, cargoIndex) =>
                warnings.push(
                    ...this.requiredLocationActivityTypes
                        .map((activityType) => this.getWarning(fixture.fixtureType, voyage, cargo, cargoIndex, activityType))
                        .filter((warning) => !!warning)
                )
            )
        );

        return warnings;
    }

    private getDestination(voyage: Voyage, cargoId: CargoId, activityType: CargoBerthActivityType) {
        for (const destination of voyage.destinations) {
            for (const berth of destination.berths) {
                for (const activity of berth.cargoBerthActivities) {
                    if (activity.type && activity.type.id === activityType.id) {
                        for (const associatedCargo of activity.associatedCargoes) {
                            if (associatedCargo.cargoId === cargoId) {
                                return destination;
                            }
                        }
                    }
                }
            }
        }

        return null;
    }

    private getWarning(fixtureType: Enumeration, voyage: Voyage, cargo: Cargo, cargoIndex: number, activityType: CargoBerthActivityType) {
        const destination = this.getDestination(voyage, cargo.id, activityType);

        if (!destination || !destination.location) {
            const message = `Missing ${activityType.name} Location`;
            const path = [
                ...this.getVoyagePathSegment(fixtureType, voyage),
                FixtureWarningPathSegment.fromProperty<Voyage>("cargoes", "Cargoes"),
                FixtureWarningPathSegment.fromIndex(cargoIndex, "Cargo", cargo.cargoProduct ? cargo.cargoProduct.name : null),
                new FixtureWarningPathSegment(`${activityType.name.toLowerCase()}Location`, `${activityType.name} Location`),
                new FixtureWarningPathSegment("location", null)
            ];
            const dismissingRecord =
                fixtureType.id !== FixtureType.Voyage
                    ? dismissedWarningRecord(new WarningHashBuilder().withVoyage(voyage.voyageId).withCargo(cargo.cargoId).withCategory("data").withMessage(message).build())
                    : null;
            return new FixtureWarning("data", message, path, dismissingRecord);
        }

        return null;
    }
}
