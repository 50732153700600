import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TableModule } from "primeng/table";

import { OpsButtonModule } from "../components/buttons";
import { FormGridCellEditorComponent } from "./form-grid-cell-editor/form-grid-cell-editor.component";
import { FormGridCellDirective } from "./form-grid-cell.directive";
import { FormGridInputDirective } from "./form-grid-input.directive";
import { FormGridRowDirective } from "./form-grid-row.directive";
import { FormGridDirective } from "./form-grid.directive";
import { TableInsertOverlayComponent } from "./table-insert-overlay/table-insert-overlay.component";

@NgModule({
    imports: [CommonModule, OpsButtonModule, TableModule],
    declarations: [FormGridCellDirective, FormGridDirective, FormGridInputDirective, FormGridRowDirective, FormGridCellEditorComponent, TableInsertOverlayComponent],
    exports: [FormGridCellDirective, FormGridDirective, FormGridInputDirective, FormGridRowDirective, FormGridCellEditorComponent, TableInsertOverlayComponent]
})
export class OpsFormGridModule {}
