<div class="overlay-container">
  <div #wrapTable class="wrap-table">
    <ng-content></ng-content>
  </div>
  <div #plusOverlay class="plus-overlay">
    <ops-action-button type="action" class="plus-overlay-plus">
      <span #overlayPlusButton class="has-icon icon--add-circle plus-icon plus-action"></span>
    </ops-action-button>
    <div #plusOverlayPlank class="plus-overlay-plank"></div>
  </div>
</div>