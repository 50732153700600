import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, Store, props } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaustMap, withLatestFrom, map } from "rxjs/operators";

import { selectCurrentUser } from "@ops/state";

import { Email, EmailPreviewService } from "../../../../shared/email";
import { LiftingHttpService } from "../../../services";
import { selectCurrentCoaId } from "../../coa";
import { CoaFeatureState, LiftingId, toUser, User } from "../../model";
import { selectCurrentLiftingId, selectCurrentLiftingCargoPlanEmail } from "../selectors";

/* ACTIONS */
export const emailCargoPlanAction = createAction("[Lifting] Email Cargo Plan");
export const emailCargoPlanActionSuccess = createAction("[Lifting] Email Cargo Plan Success");
export const auditCargoPlanEmailActionSuccess = createAction("[Lifting] Audit Cargo Plan Email Success", props<{ user: User; liftingId: LiftingId }>());
export const auditCargoPlanEmailActionFail = createAction("[Lifting] Audit Cargo Plan Email Fail");

/* EFFECTS */
export const cargoPlanEmailEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, emailPreviewer: EmailPreviewService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(emailCargoPlanAction),
            withLatestFrom(store.select(selectCurrentLiftingCargoPlanEmail)),
            exhaustMap(([, cargoPlan]) => {
                const email = new Email(cargoPlan.subject, cargoPlan.body, "", "");
                emailPreviewer.preview(email, ["Cargo Plan"], true);
                return of(emailCargoPlanActionSuccess());
            })
        )
    );

export const cargoPlanEmailAuditEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(emailCargoPlanActionSuccess),
            withLatestFrom(store.select(selectCurrentCoaId), store.select(selectCurrentLiftingId), store.select(selectCurrentUser)),
            exhaustMap(([, coaId, liftingId, currentUser]) =>
                liftingHttpService.auditCargoPlanEmailGenerated(coaId, liftingId).pipe(
                    map(() => auditCargoPlanEmailActionSuccess({ user: toUser(currentUser), liftingId })),
                    catchError(() => of(auditCargoPlanEmailActionFail()))
                )
            )
        )
    );
