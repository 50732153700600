import { DateTime } from "luxon";
import { FormArrayState, FormGroupState, updateArray, updateGroup, validate } from "ngrx-forms";

import { validateRequired } from "@ops/state";

import { Deduction } from "../../../../model";

const validateDeductionForm = (form: FormGroupState<Deduction>, forms: FormArrayState<Deduction>) =>
    updateGroup<Deduction>({
        id: validateRequired(),
        timeFrom: validate((value) => {
            const timeFrom = DateTime.fromISO(value);
            if (!value) {
                return { valueRequired: true };
            } else if (form.value.timeTo && timeFrom > DateTime.fromISO(form.value.timeTo)) {
                return { exceedsTimeTo: true };
            } else if (
                forms.value.find(
                    (item) => item.timeFrom && item.timeTo && DateTime.fromISO(item.timeFrom) <= timeFrom && timeFrom < DateTime.fromISO(item.timeTo) && form.value.id !== item.id
                )
            ) {
                return { overlapsDeduction: true };
            }
        }),
        timeTo: validate((value) => {
            const timeTo = DateTime.fromISO(value);
            if (!value) {
                return { valueRequired: true };
            } else if (
                forms.value.find(
                    (item) => item.timeFrom && item.timeTo && DateTime.fromISO(item.timeFrom) < timeTo && timeTo <= DateTime.fromISO(item.timeTo) && form.value.id !== item.id
                )
            ) {
                return { overlapsDeduction: true };
            }
        })
    })(form);

export const validateDeductionForms = (forms: FormArrayState<Deduction>) => updateArray<Deduction>(validateDeductionForm)(forms);
