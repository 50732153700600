import { MaritimeDateRange } from "@maritech/maritime-date";
import { DateTime } from "luxon";
import { box, Boxed, NgrxValueConverter, unbox } from "ngrx-forms";

import { fromMaritimeDateRange, toMaritimeDateRange } from "../shared/date-utils/date-utilities";

const toLocalDate = (date: Date | DateTime) => (date ? (DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date)).toISODate() : null);
const toLocalDateTime = (date: Date | DateTime) => (date ? (DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date)).toISO({ includeOffset: false }) : null);
const toLocalDateRange = (dateRange: MaritimeDateRange) => (dateRange ? { from: toLocalDateTime(dateRange.start), to: toLocalDateTime(dateRange.end) } : null);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CustomNgrxValueConverters = {
    dateToISOString: {
        convertViewToStateValue: (date) => (date ? date.toISO() : null),
        convertStateToViewValue: (s) => (s ? DateTime.fromISO(s) : null)
    } as NgrxValueConverter<DateTime | null, string | null>,

    dateRangeToISOString: {
        convertViewToStateValue: (range) => box(range ? fromMaritimeDateRange(range) : null),
        convertStateToViewValue: (s) => (s ? toMaritimeDateRange(unbox(s)) : null)
    } as NgrxValueConverter<MaritimeDateRange | null, Boxed<{ from: string; to: string }> | null>,

    dateToLocalDateString: {
        convertViewToStateValue: (date) => (date ? toLocalDate(date) : null),
        convertStateToViewValue: (s) => (s ? DateTime.fromISO(s) : null)
    } as NgrxValueConverter<DateTime | null, string | null>,

    /**
     * This is used to serialize/deserialize NodaTime LocalDateTime segments, which does not allow for a zone
     * in the specification.
     * https://nodatime.org/3.0.x/userguide/serialization
     * LocalDateTime: ISO-8601 date/time pattern with no time zone specifier, extended to handle fractional seconds: yyyy'-'MM'-'dd'T'HH':'mm':'ss.FFFFFFF
     *
     * Supplying a serialized offset will result in a BadRequest, this converter allows for the correct format to be serialized from
     * the client.
     */
    dateToLocalDateTimeString: {
        convertViewToStateValue: (date) => (date ? toLocalDateTime(date) : null),
        convertStateToViewValue: (s) => (s ? DateTime.fromISO(s) : null)
    } as NgrxValueConverter<DateTime | null, string | null>,

    /**
     * This is used to serialize/deserialize NodaTime LocalDateTime segments, which does not allow for a zone
     * in the specification.
     * https://nodatime.org/3.0.x/userguide/serialization
     * LocalDateTime: ISO-8601 date/time pattern with no time zone specifier, extended to handle fractional seconds: yyyy'-'MM'-'dd'T'HH':'mm':'ss.FFFFFFF
     *
     * Supplying a serialized offset will result in a BadRequest, this converter allows for the correct format to be serialized from
     * the client.
     */
    dateRangeToLocalDateTimeString: {
        convertViewToStateValue: (range) => (range ? box(toLocalDateRange(range)) : null),
        convertStateToViewValue: (s) => (s ? toMaritimeDateRange(unbox(s)) : null)
    } as NgrxValueConverter<MaritimeDateRange | null, Boxed<{ from: string; to: string }> | null>
};
