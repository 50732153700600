import { Injectable } from "@angular/core";
import { FreightType } from "src/app/shared/reference-data/freight-type";
import { dateToISOString } from "../../../../shared/date-utils/date-utilities";
import { CommandHandler } from "../../../mediator/command-handlers/command-handler";
import { Fixture, Laycan, VoyageHeadingFormModel } from "../../../shared/models";
import { UpdateVoyageHeadingCommand } from "./update-voyage-heading.command";

@Injectable({
    providedIn: "root"
})
export class UpdateVoyageHeadingCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateVoyageHeadingCommand) {
        const value = command.payload as VoyageHeadingFormModel;

        fixture.operators = [...(value.operators || [])];
        [fixture.leadOperator] = fixture.operators || null;
        fixture.claims = [...(value.claims || [])];
        fixture.charterParty.charterPartyType = value.charterPartyType;
        fixture.charterParty.cpmLink = value.cpmLink;
        fixture.ownerReference = value.ownerReference;
        fixture.chartererReference = value.chartererReference;
        fixture.voyageReference = value.voyageReference;
        fixture.fixtureStatus = value.fixtureStatus;
        fixture.cancellationReason = value.cancellationReason;
        if (!fixture.laycan?.date) {
            fixture.laycan = new Laycan(null, null);
        }
        fixture.laycan.date.from = dateToISOString(value.laycanFrom);
        fixture.laycan.date.to = dateToISOString(value.laycanTo);
        fixture.comments = value.comments;
        fixture.liftingNumber = value.liftingNumber;
        fixture.liftingDate = dateToISOString(value.liftingDate);
        fixture.vesselNominationDate = dateToISOString(value.vesselNominationDate);
        fixture.fixedLaytime = value.fixedLaytime;
        fixture.isCommentsImportant = value.isCommentsImportant;
        fixture.demurrage.status = value.demurrageStatus;
        fixture.demurrage.rate = value.demurrageRate;
        fixture.despatchRate = value.despatchRate;
        fixture.lastCargoes = [...(value.lastCargoes || [])];
        fixture.loiStatus = value.loiStatus;
        fixture.loiStatusNotes = value.loiStatusNotes;
        fixture.speedLaden = value.speedLaden;
        fixture.laycan.extensionDate = {
            from: dateToISOString(value.laycanExtFrom),
            to: dateToISOString(value.laycanExtTo)
        };
        fixture.laycan.extensionReason = value.laycanExtensionReason;
        fixture.vessel = !value.vessel ? null : { name: value.vessel.name, imo: value.vessel.imoNumber, cvn: value.vessel.cvn };
        fixture.vesselId = value.vessel ? value.vessel.cvn : null;
        fixture.displayBroker = value.displayBroker;
        fixture.charterParty.charterPartyDate = dateToISOString(value.cpDate);
        fixture.isVesselTbn = value.isVesselTbn;
        fixture.currency = value.currency;
        fixture.chartererReference2 = value.chartererReference2;
        fixture.coANumber = value.coANumber;
        fixture.freightType = value.freightType;
        fixture.lumpsumValue = fixture.freightType && fixture.freightType.id !== FreightType.LumpSum.id ? null : value.lumpsumValue;
        fixture.brokerCommission = value.brokerCommission;
        fixture.addressCommission = value.addressCommission;
        fixture.presentationType = value.presentationType;
        fixture.includePresentationInKpi = value.includePresentationInKpi;
        fixture.includeCancelledInKpi = value.includeCancelledInKpi;
        fixture.numberOfVesselChanges = value.numberOfVesselChanges;
    }
}
