export function scrollIntoView(element: HTMLElement, offset: number, behavior: ScrollBehavior = "smooth") {
    // Timeout to ensure this is at the end of the event loop so tab switching etc occurs first else
    // the element will not have an offset parent
    setTimeout(() => {
        const scrollParent = getScrollParent(element);
        const offsetY = getOffsetYTo(element, scrollParent);

        window.requestAnimationFrame(() => {
            scrollParent.scrollTo({
                top: offsetY + (offset || 0),
                behavior: behavior
            });
        });
    });
}

function getScrollParent(node: Node): HTMLElement {
    if (node instanceof HTMLElement) {
        const overflowY = window.getComputedStyle(node).overflowY;
        const isScrollable = overflowY !== "visible" && overflowY !== "hidden";

        if (isScrollable && node.scrollHeight >= node.clientHeight) {
            return node;
        }
    }
    else {
        return null;
    }

    return getScrollParent(node.parentNode) || document.body;
}

function getOffsetYTo(element: HTMLElement, parent: HTMLElement) {
    if (window.getComputedStyle(parent).position === "static") {
        console.warn("getOffsetYTo: parent must be an offset parent, position: static is not valid");
    }

    let y = 0;
    let el: any = element;

    while (el && el !== parent) {
        y += el.offsetTop - el.scrollTop + el.clientTop;
        el = el.offsetParent;
    }

    return y;
}
