import { Injectable } from "@angular/core";

import { CargoBerthActivityType } from "../../../../shared/reference-data";
import { CommandHandler } from "../../../mediator";
import { AssociatedCargo, Berth, CargoBerthActivity, Voyage } from "../../../shared/models";
import { CargoAllowedRate } from "../../../shared/models/dtos/cargo-allowed-rate.dto";
import { CargoRateActivity } from "../../../shared/models/dtos/cargo-rate-activity.dto";
import { CascadeCargoAllowedRatesCommand } from "./cascade-cargo-allowed-rates.command";

@Injectable({
    providedIn: "root"
})
export class CascadeCargoAllowedRatesCommandHandler implements CommandHandler {
    handle(voyage: Voyage, command: CascadeCargoAllowedRatesCommand): void {
        if (!command || !command.cargoAllowedRates) {
            throw new Error("Given form model is invalid.");
        }

        if (!voyage.destinations) {
            throw new Error("Unable to update the rates for associated cargo because there are no destinations.");
        }

        const berths = voyage.destinations.reduce<Berth[]>((allBerths, destination) => allBerths.concat(destination.berths), []);
        if (!berths) {
            throw new Error("Unable to update associated cargo because there are no berths.");
        }

        const activities = berths.reduce<CargoBerthActivity[]>((allActivities, berth) => allActivities.concat(berth.cargoBerthActivities), []);
        if (!activities) {
            throw new Error("Unable to update associated cargo because there are no activities.");
        }

        activities.forEach((activity) => {
            activity.associatedCargoes.forEach((associatedCargo) => {
                const cargoAllowedRate = command.cargoAllowedRates.find((rate) => rate.cargoId === associatedCargo.cargoId);
                if (cargoAllowedRate) {
                    this.updateAssociatedCargo(associatedCargo, activity.type, cargoAllowedRate);
                }
            });
        });
    }

    private updateAssociatedCargo(associatedCargo: AssociatedCargo, activityType: CargoBerthActivityType, cargoAllowedRate: CargoAllowedRate) {
        let activityRate: CargoRateActivity;
        switch (activityType.name) {
            case "Load":
                activityRate = cargoAllowedRate.loadCargoRateActivity;
                break;
            case "Discharge":
                activityRate = cargoAllowedRate.dischargeCargoRateActivity;
                break;
            default:
                // in case of unsupported activity type, return associatedCargo unmodified.
                return associatedCargo;
        }
        associatedCargo.cpRate = activityRate.cpRate;
        associatedCargo.cpRateUnit = activityRate.cpRateUnit;
        associatedCargo.reversibleLaytimeType = activityRate.reversibleLaytimeType;
        associatedCargo.extraHours = activityRate.extraHours;
    }
}
