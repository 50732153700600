<div>
    <p-table
        [tableStyleClass]="readonly ? 'expenses-table ops-form-grid ops-fg-readonly' : 'expenses-table ops-form-grid'"
        [value]="expenses"
        dataKey="associatedCargoExpenseId"
        [rowTrackBy]="trackBy"
        [formGridReadonly]="readonly">
        <ng-template pTemplate="header">
            <tr>
                <th>Rate Description</th>
                <th class="text-right">Value</th>
                <th>Unit</th>
                <th class="text-right">Freight Rate</th>
                <th class="text-right">Total Value</th>
                <th *ngIf="!readonly"></th>
                <th *ngIf="!readonly"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-associatedCargoExpense let-rowIndex="rowIndex">
            <tr class="form-group"
                [attr.rowkey]="associatedCargoExpense.associatedCargoExpenseId"
                formGridRow>
                <td pEditableColumn
                    warningActive
                    [ngrxFormControlId]="associatedCargoExpense.form.controls.rateDescription"
                    ngrxFormControlIdMatch="equals"
                    [formGridCell]="associatedCargoExpense.form.controls.rateDescription"
                    data-test-rate-description>
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ops-refdata-dropdown
                                type="rateDescription"
                                class="ops-select"
                                [readonly]="readonly"
                                placeholder="Select"
                                [ngrxFormControlState]="associatedCargoExpense.form.controls.rateDescription"
                                [ngrxEnableFocusTracking]="true"
                                [ngbPopover]="rateDescription"
                                #rateDescriptionPopover="ngbPopover"
                                (blur)="rateDescriptionPopover.close()"
                                (focus)="rateDescriptionPopover.open()"
                                formGridInput
                                triggers="manual"
                                [autoClose]="false"
                                data-test-rate-description>
                            </ops-refdata-dropdown>
                            <ng-template #rateDescription>
                                <div
                                    *ngIf="associatedCargoExpense.form.controls.rateDescription.errors.required"
                                    class="row no-gutters display"
                                >
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-rate-description-required><b>Rate Description</b> is required.</span>
                                </div>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{ getValue(associatedCargoExpense.form.value.rateDescription)?.name }}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>
                <td
                    pEditableColumn
                    [formGridCell]="associatedCargoExpense.form.controls.value"
                    class="text-right"
                    data-test-value>
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <input
                                [ngrxFormControlState]="associatedCargoExpense.form.controls.value"
                                [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                                [readonly]="readonly"
                                type="text"
                                class="form-control text-right"
                                [ngbPopover]="value"
                                #valuePopover="ngbPopover"
                                (blur)="valuePopover.close()"
                                (focus)="valuePopover.open()"
                                formGridInput
                                triggers="manual"
                                [autoClose]="false"
                                placement="top-left"
                                data-test-value
                            />
                            <ng-template #value>
                                <div
                                    *ngIf="associatedCargoExpense.form.controls.value.errors.required"
                                    class="row no-gutters display"
                                >
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-value-required><b>Value</b> is required.</span>
                                </div>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{ associatedCargoExpense.form.value.value | opsNumber: false:2:4 }}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>
                <td
                    pEditableColumn
                    warningActive
                    [ngrxFormControlId]="associatedCargoExpense.form.controls.unit"
                    ngrxFormControlIdMatch="equals"
                    class="rate-unit"
                    [formGridCell]="associatedCargoExpense.form.controls.unit"
                    data-test-unit>
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ops-refdata-dropdown
                                type="associatedCargoExpenseUnit"
                                class="ops-select"
                                [readonly]="readonly"
                                placeholder="Select"
                                [ngrxFormControlState]="associatedCargoExpense.form.controls.unit"
                                [ngbPopover]="unit"
                                #unitPopover="ngbPopover"
                                (blur)="unitPopover.close()"
                                (focus)="unitPopover.open()"
                                formGridInput
                                triggers="manual"
                                [autoClose]="false"
                                data-test-unit>
                            </ops-refdata-dropdown>
                            <ng-template #unit>
                                <div
                                    *ngIf="associatedCargoExpense.form.controls.unit.errors.required"
                                    class="row no-gutters display"
                                >
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-unit-required><b>Unit</b> is required.</span>
                                </div>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{ getValue(associatedCargoExpense.form.value.unit)?.name }}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>
                <td pEditableColumn
                    [formGridCell]="associatedCargoExpense.form.controls.freightRate"
                    class="text-right"
                    data-test-freight-rate>
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <input
                                [ngrxFormControlState]="associatedCargoExpense.form.controls.freightRate"
                                [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                                type="text"
                                class="form-control text-right"
                                [ngbPopover]="freightRate"
                                #freightRatePopover="ngbPopover"
                                (blur)="freightRatePopover.close()"
                                (focus)="freightRatePopover.open()"
                                formGridInput
                                triggers="manual"
                                [autoClose]="false"
                                placement="top-left"
                                data-test-freight-rate
                                [readonly]="readonly"
                            />
                            <ng-template #freightRate>
                                <div
                                    *ngIf="associatedCargoExpense.form.controls.freightRate.errors.required"
                                    class="row no-gutters display"
                                >
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-freight-rate-required><b>Freight Rate</b> is required.</span>
                                </div>
                                <div
                                    *ngIf="associatedCargoExpense.form.controls.freightRate.errors.greaterThan"
                                    class="row no-gutters display"
                                >
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-freight-rate-greater-than><b>Freight Rate</b> must be greater than {{associatedCargoExpense.form.controls.freightRate.errors.greaterThan.comparand}}.</span>
                                </div>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{ associatedCargoExpense.form.value.freightRate | opsNumber: false:2:4 }}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>
                <td class="ops-fg-cell-readonly text-right">
                    <span class="ops-fg-cell-value">{{ associatedCargoExpense.freightSpend | opsNumber: false:2:2 }}</span>
                </td>
                <td *ngIf="!readonly" class="ops-fg-action-cell">
                    <ops-icon-button
                        *ngIf="rowIndex === expenses.length - 1"
                        [disabled]="!canAdd || isTemplate(rowIndex)"
                        (click)="addExpense(associatedCargoId, $event)"
                        type="button"
                        icon="add-circle-outline"
                        data-test-add-associated-cargo-expense-button
                    ></ops-icon-button>
                </td>
                <td *ngIf="!readonly" class="ops-fg-action-cell">
                    <ops-icon-button
                        type="delete"
                        [disabled]="!canRemove || isTemplate(rowIndex)"
                        (click)="remove.emit({index: rowIndex, associatedCargoId: associatedCargoId})"
                        data-test-remove-associated-cargo-expense-button
                    ></ops-icon-button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate='footer'>
            <tr>
                <td class="total">Total Value</td>
                <td class="text-right">{{ totalFreightSpend | opsNumber: false:2:2 }}</td>
                <td *ngIf="!readonly"></td>
                <td *ngIf="!readonly"></td>
            </tr>
        </ng-template>
    </p-table>
</div>
