import { box, Boxed } from "ngrx-forms";

import { Currency } from "@ops/shared";
import { User } from "@ops/state";

import { Coa, CancellationReason, CoaDriver, CoaStatus } from "./coa";

export type CoaHeaderForm = Readonly<{
    driver: CoaDriver;
    operators: Boxed<ReadonlyArray<User>>;
    contractStatus: CoaStatus;
    cancellationReason?: CancellationReason;
    charterpartyDate: string;
    currency: Currency;
}>;

export const coaToForm = (source: Coa): CoaHeaderForm => ({
    driver: source.driver,
    operators: box(source.operators.map((x) => <User>{ userId: x.userId, userCode: x.userCode, fullName: x.name })),
    contractStatus: source.contractStatus,
    cancellationReason: source.cancellationReason,
    charterpartyDate: source.charterpartyDate,
    currency: source.currency
});
