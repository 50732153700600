import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { merge, Observable, Subscription } from "rxjs";

import { Enumeration } from "@ops/shared/reference-data";

import { parseISODate } from "../../../../shared/date-utils/date-utilities";
import { ReferenceDataService } from "../../../../shared/reference-data";
import { RangeValidator } from "../../../../shared/validators/range.validator";
import { RequiredIfValidator } from "../../../../shared/validators/required-if.validator";
import { InvoiceDataService } from "../../../services/invoice-data.service";
import { getLatestLaycanTo } from "../../../shared/fixture-destination.utils";
import { FormComponentBase } from "../../../shared/form-component-base";
import { FixtureSource, Invoice, Laycan, Period } from "../../../shared/models";
import { FixtureInvoices } from "../../../shared/models/dtos/fixture-invoices.dto";
import { PeriodFormModel } from "../../../shared/models/form-models/period.model";
import { AddPeriodCommand } from "./commands/add-period.command";
import { ClonePeriodCommand } from "./commands/clone-period.command";
import { DeletePeriodCommand } from "./commands/delete-period.command";
import { UpdatePeriodCommand } from "./commands/update-period.command";
import { sortPeriodFormsByFromDate } from "./periods-sorter";

@Component({
    selector: "ops-periods",
    templateUrl: "./periods.component.html",
    styleUrls: ["./periods.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeriodsComponent extends FormComponentBase implements OnInit, OnChanges, OnDestroy {
    static componentName = "PeriodsComponent";

    private subscription: Subscription;
    private invoices: FixtureInvoices;

    periodsForm: UntypedFormGroup;
    durationTypes$: Observable<Enumeration[]>;
    hireRateUnits$: Observable<Enumeration[]>;

    @Input() parentForm: UntypedFormGroup;
    @Input() periods: Period[];
    @Input() laycan: Laycan;
    @Input() fixtureSource: FixtureSource;

    @Output() periodUpdated = new EventEmitter<UpdatePeriodCommand>();
    @Output() periodAdded = new EventEmitter<AddPeriodCommand>();
    @Output() periodCloned = new EventEmitter<ClonePeriodCommand>();
    @Output() periodDeleted = new EventEmitter<DeletePeriodCommand>();

    get laycanToDate(): Date {
        return getLatestLaycanTo(this.laycan);
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        public referenceDataService: ReferenceDataService,
        private invoiceDataService: InvoiceDataService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.durationTypes$ = this.referenceDataService.getDurationTypes();
        this.hireRateUnits$ = this.referenceDataService.getHireRateUnits();
        this.createForm();

        this.subscription = this.invoiceDataService.currentInvoices$.subscribe((invoices) => {
            this.invoices = invoices;
            this.setForm();

            this.parentForm.registerControl("periods", this.periodsForm);
            this.cd.markForCheck();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes || !this.periodsForm || (changes.periods && changes.periods.firstChange)) {
            return;
        }
        this.setForm();
    }

    ngOnDestroy(): void {
        this.removeFormSubscriptions();
        this.parentForm.removeControl("periods");

        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    addPeriod(): void {
        this.periodAdded.emit(new AddPeriodCommand());
    }

    clonePeriod(index: number): void {
        this.periodCloned.emit(new ClonePeriodCommand(index));
    }

    deletePeriod(index: number): void {
        this.periodDeleted.emit(new DeletePeriodCommand(index));
    }

    isDeleteAllowed(index: number): boolean {
        return !this.periods[index].offHires || this.periods[index].offHires.length === 0;
    }

    protected subscribeToFormValueChanges() {
        const valueChangesObservables = this.periodsFormControls.map((period) => period.valueChanges);
        const mergedObservables = merge(...valueChangesObservables);
        this.formValueSubscription = mergedObservables.subscribe((value: any) => {
            this.periodUpdated.emit(new UpdatePeriodCommand(value));
        });
    }

    get invalid() {
        return this.periodsForm.invalid;
    }

    get touched() {
        return this.periodsFormControls.some((period: UntypedFormGroup) => {
            const controlKeys = Object.keys(period.controls);
            const isTouched = controlKeys.some((key) => period.controls[key].invalid && period.controls[key].touched);
            return isTouched;
        });
    }

    get periodsFormControls() {
        const data = <UntypedFormArray>this.periodsForm.get("periods");
        return data.controls;
    }

    private createForm() {
        this.periodsForm = this.formBuilder.group(
            {
                periods: this.formBuilder.array([])
            },
            { updateOn: "blur" }
        );

        if (this.parentForm.disabled) {
            this.periodsForm.disable();
        } else {
            this.periodsForm.enable();
        }
    }

    private setForm() {
        const dataModel = {
            periods: this.periods.map(
                (p: Period) =>
                    <PeriodFormModel>{
                        hireId: p.hireId,
                        periodFrom: p.periodRange ? parseISODate(p.periodRange.from) : null,
                        periodTo: p.periodRange ? parseISODate(p.periodRange.to) : null,
                        toleranceInDays: p.toleranceInDays,
                        hireRate: p.hireRate,
                        durationInDays: p.durationInDays,
                        minDuration: p.minDuration,
                        maxDuration: p.maxDuration,
                        invoicesPaidUpTo: parseISODate(p.invoicesPaidUpTo),
                        hireRateUnitName: p.hireRateUnit ? p.hireRateUnit.name : null,
                        hireRateUnit: p.hireRateUnit,
                        actualHireRate: this.getActualHireRateForPeriod(p, this.invoices),
                        durationType: p.durationType
                    }
            )
        };

        dataModel.periods.sort(sortPeriodFormsByFromDate);

        this.removeFormSubscriptions();
        const periodsControl = <UntypedFormArray>this.periodsForm.controls.periods;
        this.resizePeriods(periodsControl, this.periods.length);
        this.periodsForm.patchValue(dataModel, { emitEvent: false });
        this.subscribeToFormValueChanges();
    }

    private resizePeriods(periodsControl: UntypedFormArray, periodCount: number): void {
        if (periodsControl.length === periodCount) {
            return;
        } else if (periodsControl.length > periodCount) {
            periodsControl.removeAt(0);
        } else if (periodsControl.length < periodCount) {
            periodsControl.push(this.createPeriodRow());
        }

        return this.resizePeriods(periodsControl, periodCount);
    }

    private createPeriodRow(): UntypedFormGroup {
        const group = this.formBuilder.group(
            {
                hireId: [],
                periodFrom: [null, Validators.required],
                periodTo: [null, Validators.required],
                toleranceInDays: [],
                hireRate: [null, Validators.required],
                actualHireRate: [],
                durationInDays: [null, Validators.required],
                minDuration: [],
                maxDuration: [],
                invoicesPaidUpTo: [],
                hireRateUnitName: [],
                hireRateUnit: [null, Validators.required],
                durationType: []
            },
            {
                validator: Validators.compose([RangeValidator.validate("periodFrom", "periodTo"), RequiredIfValidator.validate("minDuration", "maxDuration", "durationType")])
            }
        );

        if (this.periodsForm.disabled) {
            group.disable();
        } else {
            group.enable();
        }

        return group;
    }

    private getActualHireRateForPeriod(period: Period, fixtureInvoices: FixtureInvoices): number {
        let actualHireRate = period.hireRate;

        if (fixtureInvoices && fixtureInvoices.invoices) {
            const periodInvoices = fixtureInvoices.invoices
                .filter((invoice: Invoice) => invoice.associatedPeriodHireId === period.hireId && invoice.coveringFromDate && invoice.coveringToDate)
                .sort((invoiceA: Invoice, invoiceB: Invoice) => new Date(invoiceB.coveringToDate).getTime() - new Date(invoiceA.coveringToDate).getTime());

            actualHireRate = periodInvoices && periodInvoices.length >= 1 ? periodInvoices[0].actualHireRate || period.hireRate : period.hireRate;
        }

        return actualHireRate;
    }

    get isOpsFixtureSource() {
        return this.fixtureSource === FixtureSource.Ops;
    }
}
