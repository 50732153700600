import * as R from "ramda";
import { Evolver } from "ramda";

import { Null } from "../shared/more-ramda";
import { GridPageState, GridState, SearchToken, Sorting, Suggestion, SuggestionTerm } from "./model/grid";
import { ColumnDefinition } from "./model/worksheet";
import { ObjectReducer } from "./utils";

function gridReducer<TState>(state: TState, gridStateKey: keyof TState, partialOrReducer: Partial<GridState> | ObjectReducer<GridState>): TState {
    const reducer = typeof partialOrReducer === "function" ? partialOrReducer : R.mergeDeepLeft(partialOrReducer);
    const updateFns: Evolver = { [gridStateKey]: reducer };

    return R.evolve(updateFns, state);
}

/*  UPDATE SUGGESTION TERM */
export type UpdateSuggestionTerm = Readonly<{
    suggestionTerm: SuggestionTerm;
}>;

export function updateSuggestionTermReducer<TState>(state: TState, action: UpdateSuggestionTerm, gridStateKey: keyof TState) {
    return gridReducer(state, gridStateKey, {
        suggestionTerm: action.suggestionTerm,
        suggestions: { loadStatus: "loading" }
    });
}

export type UpdateSuggestionTermSuccess = Readonly<{
    items: ReadonlyArray<Suggestion>;
}>;
export function updateSuggestionTermSuccessReducer<TState>(state: TState, action: UpdateSuggestionTermSuccess, gridStateKey: keyof TState) {
    return gridReducer(state, gridStateKey, {
        suggestions: { loadStatus: "loaded", items: action.items }
    });
}

export type UpdateSuggestionTermFail = Readonly<{
    error: Error;
}>;
export function updateSuggestionTermFailReducer<TState>(state: TState, action: UpdateSuggestionTermFail, gridStateKey: keyof TState) {
    return gridReducer(state, gridStateKey, {
        suggestions: { loadStatus: "failed", error: action.error }
    });
}

/*  UPDATE SORTING */
export type UpdateSorting = Readonly<{
    sorting: Sorting;
}>;
export function updateSortingReducer<TState>(state: TState, data: UpdateSorting, gridStateKey: keyof TState) {
    return gridReducer(state, gridStateKey, {
        sorting: data.sorting
    });
}

/* UPDATE COLUMNS */
export type UpdateColumns = Readonly<{
    columns: ReadonlyArray<ColumnDefinition>;
}>;
export function updateColumnsReducer<TState>(state: TState, data: UpdateColumns, gridStateKey: keyof TState) {
    return gridReducer(state, gridStateKey, {
        columns: data.columns
    });
}

/*  UPDATE SEARCH TOKENS */
export type UpdateSearchTokens = Readonly<{
    searchTokens: ReadonlyArray<SearchToken>;
}>;
export function updateSearchTokensReducer<TState>(state: TState, action: UpdateSearchTokens, gridStateKey: keyof TState) {
    return gridReducer(state, gridStateKey, {
        criteria: action.searchTokens
    });
}

/*  SEARCH */
export type Search = Readonly<{
    requestId: string;
    skip: number;
    take: number;
}>;
export function searchReducer<TState>(state: TState, action: Search, gridStateKey: keyof TState) {
    return gridReducer(state, gridStateKey, {
        currentRequestId: action.requestId,
        data: {
            [action.requestId]: {
                loadStatus: "loading",
                skip: action.skip,
                take: action.take
            }
        }
    });
}

export type SearchSuccess<TItem> = Readonly<{
    requestId: string;
    items: ReadonlyArray<TItem>;
    total: number;
}>;
export function searchSuccessReducer<TState>(state: TState, action: SearchSuccess<unknown>, gridStateKey: keyof TState) {
    return gridReducer(state, gridStateKey, {
        data: {
            [action.requestId]: <GridPageState<unknown>>{
                loadStatus: "loaded",
                items: action.items,
                total: action.total
            }
        }
    });
}

export type SearchFail = Readonly<{
    requestId: string;
    error: Error;
}>;
export function searchFailReducer<TState>(state: TState, action: SearchFail, gridStateKey: keyof TState) {
    return gridReducer(state, gridStateKey, {
        data: {
            [action.requestId]: <GridPageState<unknown>>{
                loadStatus: "failed",
                error: action.error
            }
        }
    });
}

/*  REMOVE REQUEST */
export type RemoveRequest = Readonly<{
    requestId: string;
}>;
export function removeRequestReducer<TState>(state: TState, action: RemoveRequest, gridStateKey: keyof TState) {
    const updateFns: Evolver<GridState> = {
        currentRequestId: R.when(R.equals(action.requestId), Null),
        data: R.dissoc(action.requestId)
    };

    return gridReducer(state, gridStateKey, R.evolve(updateFns));
}
