import { FormGroupState, setValue, updateGroup, validate } from "ngrx-forms";
import { greaterThanOrEqualTo } from "ngrx-forms/validation";

import { Sector } from "@ops/shared";
import { numberString, setIsVisible } from "@ops/state";

import { LaytimeCalculationState, LaytimeCalculationTermsForm, TimeAllowance } from "../../../model";

export type VisibilityKey = keyof Pick<LaytimeCalculationTermsForm, "despatchRate" | "detentionRate" | "fixedAllowanceHours" | "timeSaved" | "demurrageBank">;

export const getLaytimeCalculationTermsVisibility = (sector: Sector, timeAllowance: TimeAllowance): { [key in VisibilityKey]: boolean } => ({
    ["despatchRate"]: sector === "Dry Cargo",
    ["detentionRate"]: sector === "Dry Cargo" && timeAllowance === "Non Fixed",
    ["fixedAllowanceHours"]: timeAllowance === "Fixed",
    ["timeSaved"]: sector === "Dry Cargo",
    ["demurrageBank"]: sector === "PCG"
});

export const validateLaytimeCalculationTermsForm = (form: FormGroupState<LaytimeCalculationTermsForm>, laytimeCalculationState: LaytimeCalculationState) => {
    const visibility = getLaytimeCalculationTermsVisibility(laytimeCalculationState.calculation.sector, form.value.timeAllowance);
    const visibilityFns = Object.keys(visibility).reduce(
        (s, key: VisibilityKey) => ({
            ...s,
            [key]: setIsVisible(visibility[key])
        }),
        {}
    );

    const valueFns = Object.keys(visibility)
        .filter((key: VisibilityKey) => !visibility[key])
        .reduce(
            (s, key: VisibilityKey) => ({
                ...s,
                [key]: setValue(null)
            }),
            {}
        );

    const validationFns = {
        demurrageRate: validate(numberString(greaterThanOrEqualTo(0))),
        detentionRate: validate(numberString(greaterThanOrEqualTo(0))),
        despatchRate: validate(numberString(greaterThanOrEqualTo(0))),
        fixedAllowanceHours: validate(numberString(greaterThanOrEqualTo(0)))
    };

    return updateGroup<LaytimeCalculationTermsForm>([visibilityFns, valueFns, validationFns])(form);
};
