import { createSelector } from "@ngrx/store";

import { getLiftingHistory } from "../../../left-bar/lifting-history/lifting-history";
import { selectCurrentCoa } from "../coa";
import { selectCoasFeature } from "../coa.selectors";
import { CoaFeatureState, CoasState, getCurrentLiftingId, isReadonlyLifting, LiftingId, LiftingState } from "../model";
import { getCargoNominationSummary } from "./cargo-nomination-summary";
import { getCargoPlanEmail } from "./cargo-plan-email";
import { getCargoPlanEmailSummary } from "./cargo-plan-email-summary";
import { getFilteredHistoryEvents, getFilterOptionsTooltip, getLiftingHistoryPlaceholderText, isAnyFilterOptionSelected } from "./lifting-history";
import { getLiftingInfo, liftingInfo } from "./lifting-info";
import { getLiftingProgression } from "./lifting-progression";
import { getLiftingSummary } from "./lifting-summary";
import { getVesselNominationSummary } from "./vessel-nomination-summary";

export const selectCurrentLiftingId = createSelector(selectCoasFeature, getCurrentLiftingId);
export const selectLiftingState = createSelector<CoaFeatureState, { liftingId: LiftingId }, CoasState, LiftingState>(selectCoasFeature, (state: CoasState, { liftingId }) =>
    liftingId ? state.liftings.byId[liftingId] : null
);

export const selectCurrentLiftingState = createSelector<CoaFeatureState, CoasState, LiftingId, LiftingState>(selectCoasFeature, selectCurrentLiftingId, (state, liftingId) =>
    liftingId ? state.liftings.byId[liftingId] : null
);

export const selectCurrentVesselNominationHistory = createSelector(selectCurrentLiftingState, (state) => state?.vesselNominationHistory);
export const selectCurrentCargoNominationHistory = createSelector(selectCurrentLiftingState, (state) => state?.cargoNominationHistory);

export const selectCurrentLifting = createSelector(selectCurrentLiftingState, (state) => state?.lifting);
export const selectCurrentLiftingSector = createSelector(selectCurrentLifting, (state) => state?.sector);

export const selectCurrentLiftingBusy = createSelector(selectCurrentLiftingState, (state) => !state || state.loadStatus === "loading" || state.persistenceStatus === "persisting");

export const selectCurrentLiftingSummary = createSelector(selectCurrentLifting, (lifting) => (lifting ? getLiftingSummary(lifting) : null));

export const selectCurrentLiftingBasicInfo = createSelector(selectCurrentLifting, selectCurrentCoa, (lifting, coa) =>
    lifting && coa ? getLiftingInfo(lifting, coa) : liftingInfo()
);

export const selectCurrentLiftingProgression = createSelector(selectCurrentLifting, (lifting) => (lifting ? getLiftingProgression(lifting) : null));

export const selectCurrentLiftingFixtureCreating = createSelector(selectCurrentLiftingState, (state) => state?.fixtureCreationPending);
export const selectCurrentLiftingFixtureId = createSelector(selectCurrentLifting, (lifting) => lifting?.fixtureId);

export const selectCurrentVesselNominationSummary = createSelector(
    selectCurrentLifting,
    selectCurrentVesselNominationHistory,
    (lifting, vesselNominationHistory) => lifting && getVesselNominationSummary(lifting, vesselNominationHistory)
);

export const selectCurrentCargoNominationSummary = createSelector(
    selectCurrentLifting,
    selectCurrentCargoNominationHistory,
    (lifting, cargoNominationHistory) => lifting && getCargoNominationSummary(lifting, cargoNominationHistory)
);

export const selectCurrentLiftingHistoryEvents = createSelector(
    selectCurrentLifting,
    selectCurrentCargoNominationHistory,
    selectCurrentVesselNominationHistory,
    (lifting, cargoNominationHistory, vesselNominationHistory) => lifting && getLiftingHistory(cargoNominationHistory, vesselNominationHistory)
);

export const selectCurrentLiftingReadonly = createSelector(selectCurrentLifting, isReadonlyLifting);

export const selectCurrentLiftingCargoPlanEmail = createSelector(selectCurrentCoa, selectCurrentLifting, (coa, lifting) => getCargoPlanEmail(coa, lifting));

export const selectCurrentLiftingCargoPlanEmailSummary = createSelector(selectCurrentCargoNominationHistory, (cargoNominationHistory) =>
    getCargoPlanEmailSummary(cargoNominationHistory)
);

export const selectCurrentLiftingHistoryFilterOptions = createSelector(selectCurrentLiftingState, (state) => state?.liftingHistoryFilterOptions);

export const selectIsAnyLiftingHistoryFilterOptionSelected = createSelector(selectCurrentLiftingState, (state) => isAnyFilterOptionSelected(state?.liftingHistoryFilterOptions));

export const selectCurrentLiftingHistoryFilterTooltip = createSelector(selectCurrentLiftingState, (state) => getFilterOptionsTooltip(state?.liftingHistoryFilterOptions));

export const selectCurrentFilteredLiftingHistoryEvents = createSelector(
    selectCurrentLiftingHistoryEvents,
    selectCurrentLiftingHistoryFilterOptions,
    (historyEvents, filterOptions) => getFilteredHistoryEvents(historyEvents, filterOptions)
);

export const selectCurrentLiftingHistoryPlaceholderText = createSelector(
    selectCurrentLiftingHistoryEvents,
    selectCurrentFilteredLiftingHistoryEvents,
    (historyEvents, filterHistoryEvents) => getLiftingHistoryPlaceholderText(historyEvents, filterHistoryEvents)
);
