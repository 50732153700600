import { box, Boxed, unbox } from "ngrx-forms";
import * as R from "ramda";
import { v4 as uuid } from "uuid";

import { DateRange, SeaNetLocation } from "../../../../fixture/shared/models";
import { VesselData } from "../../../../fixture/shared/models/common/vessel-data";
import { CargoProduct } from "../../../../fixture/state";
import { Nullable } from "../../../../shared";
import { nameOrTbn } from "../../utils";
import { CargoProductId } from "../cargo";
import { Location, toLocation, toSeaNetLocation } from "../location";
import { Lifting } from "./lifting";

export type VesselId = string;
export type CargoItem = Readonly<{ productId: CargoProductId; name: string }>;

export const vesselNominationStatuses = ["Under Review", "Rejected", "Accepted", "Preferred", "Renomination Pending"] as const;
export type VesselNominationStatus = typeof vesselNominationStatuses[number];

const isInStatus = R.propEq("vesselNominationStatus");
export const isAccepted = isInStatus("Accepted");
export const isPreferred = isInStatus("Preferred");
export const isRejected = isInStatus("Rejected");
export const isUnderReview = isInStatus("Under Review");
export const isRenominationPending = isInStatus("Renomination Pending");
export const isAcceptedOrPreferred = R.either(isAccepted, isPreferred);

const setStatus = R.assoc("vesselNominationStatus");
export const setAccepted = setStatus("Accepted");
export const setPreferred = setStatus("Preferred");
export const setRejected = setStatus("Rejected");
export const setUnderReview = setStatus("Under Review");
export const setRenominationPending = setStatus("Renomination Pending");

export const vesselIdEq = R.propEq("vesselId");
export const hasPreferredVessel = (lifting: Lifting) => lifting.vessels.some(isPreferred);
export const hasNoPreferredVessel = R.complement(hasPreferredVessel);
export const hasMoreThanOneAcceptedVessel = (lifting: Lifting) => lifting.vessels.filter(isAccepted).length > 1;

export type VesselNomination = Readonly<{
    vesselId: VesselId;
    name: string;
    cvn: number;
    laycan: Nullable<DateRange>;
    etd: string;
    cargoComments: string;
    locationPriorToVoyage: Location;
    lastCargoes: ReadonlyArray<CargoItem>;
    vesselNominationStatus: VesselNominationStatus;
}>;

export type VesselNominationForm = Readonly<{
    vesselId: VesselId;
    vessel: Boxed<VesselData>;
    laycan: Boxed<DateRange>;
    etd: string;
    cargoComments: string;
    locationPriorToVoyage: Boxed<SeaNetLocation>;
    lastCargoes: Boxed<ReadonlyArray<CargoProduct>>;
}>;

export const createVesselId = (): VesselId => uuid();
export const toCargoProduct = (cargoItem: CargoItem): CargoProduct => ({ id: cargoItem.productId, name: cargoItem.name });
export const toCargoItem = (cargoProduct: CargoProduct): CargoItem => ({ productId: cargoProduct.id, name: cargoProduct.name });
export const toVesselData = (vesselNomination: VesselNomination): VesselData => <VesselData>{ name: vesselNomination.name, cvn: vesselNomination.cvn };

export const vesselNominationForm = (): VesselNominationForm => ({
    vesselId: createVesselId(),
    vessel: box(null),
    laycan: box(null),
    etd: null,
    cargoComments: null,
    locationPriorToVoyage: box(null),
    lastCargoes: box([])
});

export const vesselNominationToForm = (source: VesselNomination): VesselNominationForm => ({
    vesselId: source.vesselId,
    vessel: box(toVesselData(source)),
    laycan: box(source.laycan),
    etd: source.etd,
    cargoComments: source.cargoComments,
    locationPriorToVoyage: box(source.locationPriorToVoyage ? toSeaNetLocation(source.locationPriorToVoyage) : null),
    lastCargoes: box(source.lastCargoes.map(toCargoProduct))
});

export const formToVesselNomination = (source: VesselNominationForm): Partial<VesselNomination> => {
    const vessel = unbox(source.vessel);
    const seaNetLocation = unbox(source.locationPriorToVoyage);

    return {
        vesselId: source.vesselId,
        cvn: vessel.cvn,
        name: vessel.name,
        laycan: unbox(source.laycan),
        etd: source.etd,
        cargoComments: source.cargoComments,
        locationPriorToVoyage: seaNetLocation ? toLocation(seaNetLocation) : null,
        lastCargoes: unbox(source.lastCargoes).map(toCargoItem)
    };
};

export const getNominatedVessel = (vessels: ReadonlyArray<VesselNomination>) => vessels.find(isPreferred) ?? vessels.filter(isAccepted)[0];
export const getNominatedVesselNameOrTbn = (vessels: ReadonlyArray<VesselNomination>) => nameOrTbn(getNominatedVessel(vessels)) as string;
