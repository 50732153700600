import { createSelector } from "@ngrx/store";

import { formatNumber } from "../../../shared/number-format";
import { VoyageCost } from "../../services/voyage-cost";
import { selectCurrentVoyageCost, selectCurrentVoyageExpandedSections } from "../voyage";

export type VoyageCostBreakdown = Readonly<{
    id: string;
    type: string;
    description: string;
    cost: number;
}>;

export const selectCurrentVoyageCostsExpanded = createSelector(selectCurrentVoyageExpandedSections, (expandedSections) => expandedSections && expandedSections.costs);

export const selectCurrentVoyageCostBreakdown = createSelector(selectCurrentVoyageCost, (voyageCost) => {
    if (voyageCost) {
        return voyageCostToVoyageCostBreakdown(voyageCost);
    }

    return null;
});

const voyageCostToVoyageCostBreakdown = (voyageCost: VoyageCost): VoyageCostBreakdown[] => [
    ...voyageCost.hireCosts.map((x, i) => ({
        id: `HireCost-${i}`,
        type: "Hire Cost for Voyage",
        description: `${formatNumber(x.days, false, 2, 4)} Days at ${formatNumber(x.rate, false, 2, 4)}`,
        cost: x.days * x.rate
    })),
    ...voyageCost.offHireCosts.map((x, i) => ({
        id: `OffHireCost-${i}`,
        type: "Off Hire Cost for Voyage",
        description: `${formatNumber(x.days, false, 2, 4)} Days at ${formatNumber(x.rate, false, 2, 4)}`,
        cost: -(x.days * x.rate)
    })),
    ...voyageCost.portCosts.map((x, i) => ({
        id: `PortCost-${i}`,
        type: "Voyage Port Costs",
        description: `${x.port} (${x.activities.join(", ")})`,
        cost: x.cost
    })),
    ...voyageCost.fuelCosts.map((x, i) => ({
        id: `FuelCost-${i}`,
        type: "Fuel Costs",
        description: `${x.bunkerType}: ${formatNumber(x.quantity, false, 2, 4)} MT consumed at ${formatNumber(x.price, false, 2, 4)}`,
        cost: x.price * x.quantity
    }))
];
