<div class="tab-header">
    <div class="tab-title" [ngbTooltip]="tab.title" container="body" [overflowOnlyTooltip]="true">
        {{ tab.title }}
    </div>
    <ops-fixture-popup-destination-tab-header-form
        [form]="form$ | async"
        [isReadOnly]="tab.isReadOnly"
        [timeZone]="timeZone$ | async"
        [hasEtaWarning]="hasEtaWarning$ | async"
        [etaVerifiedAudit]="etaVerifiedAudit$ | async"
        [etaVerifyEnabled]="(isTransient$ | async) === false"
        [etaDefaultFocusDate]="etaDefaultFocusDate$ | async"
        (verifyEta)="verifyEta()"
    >
    </ops-fixture-popup-destination-tab-header-form>
</div>