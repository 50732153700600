import { createAction, On, on, props } from "@ngrx/store";
import { FormGroupState, setValue, updateArray, updateGroup } from "ngrx-forms";

import { isNullOrUndefined } from "../../../../shared";
import { AssociatedCargoForm, BaseFreightRate, CargoId, FixturesState } from "../../model";
import { updateActivityForms } from "../../voyage/form/form-updaters";
import { currentVoyageStateReducer } from "../../voyage/reducer";

type CargoesFreightRateMap = { [id: string]: BaseFreightRate };

/* ACTIONS */
const APPLY_CARGO_FREIGHT_RATE_NAME = "[Voyage Form] Apply Cargo Freight Rate";
const APPLY_ALL_CARGO_FREIGHT_RATE_ALL_NAME = "[Voyage Form] Apply All Cargo Freight Rate";

export const applyCargoFreightRateAction = createAction(APPLY_CARGO_FREIGHT_RATE_NAME, props<{ cargoId: CargoId }>());
export const applyAllCargoFreightRateAction = createAction(APPLY_ALL_CARGO_FREIGHT_RATE_ALL_NAME);

/* REDUCERS */
export const applyCargoFreightRateReducer: On<FixturesState> = on(applyCargoFreightRateAction, (state, { cargoId }) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const cargo = voyageState.form.value.cargoes.find((c) => c.cargoId === cargoId);
        if (!cargo) {
            return voyageState;
        }

        const cargoFreightRateMap: CargoesFreightRateMap = { [cargo.cargoId]: cargo.baseFreightRate };

        return {
            ...voyageState,
            form: updateActivityForms({
                associatedCargoes: updateArray(updateAssociatedCargo(cargoFreightRateMap))
            })(voyageState.form)
        };
    })
);

export const applyAllCargoFreightRateReducer: On<FixturesState> = on(applyAllCargoFreightRateAction, (state) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const cargoFreightRateMap: CargoesFreightRateMap = {};
        voyageState.form.value.cargoes.forEach((c) => (cargoFreightRateMap[c.cargoId] = c.baseFreightRate));

        return {
            ...voyageState,
            form: updateActivityForms({
                associatedCargoes: updateArray(updateAssociatedCargo(cargoFreightRateMap))
            })(voyageState.form)
        };
    })
);

/* FUNCTIONS */
const updateAssociatedCargo = (cargoFreightRateMap: CargoesFreightRateMap) => (cargo: FormGroupState<AssociatedCargoForm>) => {
    const cargoId = cargo.value.cargoId;

    if (isNullOrUndefined(cargoId)) {
        return cargo;
    }

    const freightRate = cargoFreightRateMap[cargoId as CargoId];

    if (isNullOrUndefined(freightRate)) {
        return cargo;
    }

    return updateGroup<AssociatedCargoForm>({
        freightRate: setValue(freightRate)
    })(cargo);
};
