import { RouterReducerState } from "@ngrx/router-store";
import { createFeatureSelector, createSelector } from "@ngrx/store";

import { AppState } from "./model";
import { OpsRouterStateUrl } from "./router";

// TODO: (NGRX JC) Tests
export const selectRouter = createFeatureSelector<AppState, RouterReducerState<OpsRouterStateUrl>>("router");

export const selectRoute = createSelector(selectRouter, (state) => state?.state);
export const selectRouteParam = createSelector<AppState, { name: string }, OpsRouterStateUrl, string>(selectRoute, (state, { name }) => state?.params[name]);
export const selectRouteUrl = createSelector(selectRoute, (state) => state?.url?.split("(")[0]);
