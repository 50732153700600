import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AppConfigService } from "@ops/core";
import { IndexResponse } from "@ops/shared";
import { SearchToken, toSearchToken, toQueriesParam } from "@ops/state";

import { FixtureId } from "../../fixture/state";
import { FixtureIndex } from "../state";

@Injectable({
    providedIn: "root"
})
export class FixtureHttpService {
    private readonly searchServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.searchServiceUrl = appConfigService.config.searchServiceUrl;
    }

    get(fixtureId: FixtureId): Observable<FixtureIndex> {
        const tokens: ReadonlyArray<SearchToken> = [toSearchToken("Fixture-Id", fixtureId), toSearchToken("Type", "Voyage")];
        const filterQuery = tokens.map(toQueriesParam).join("&");

        const url = `${this.searchServiceUrl}/api/v1.0/fixtures/search?${filterQuery}&skip=0&take=1`;
        return this.httpClient.get<IndexResponse<FixtureIndex>>(url).pipe(map((x) => x.documents[0]));
    }
}
