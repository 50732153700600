import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { MixpanelService } from "../../../core/mixpanel-service";
import { FixtureWarningState } from "../../../fixture/shared/warnings/fixture-warning.model";
import { MixpanelEvents } from "../../../fixture/state/fixture-logging.effects";

@Component({
    selector: "ops-fixture-warning",
    templateUrl: "./fixture-warning.component.html",
    styleUrls: ["./fixture-warning.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixtureWarningComponent {
    @Input() warningState: FixtureWarningState;

    @Output() readonly activate = new EventEmitter();
    @Output() readonly dismiss = new EventEmitter();
    @Output() readonly setCurrentWarning = new EventEmitter();

    constructor(private mixpanelService: MixpanelService) {}

    onSetCurrentWarning(event: Event) {
        event.preventDefault();

        this.setCurrentWarning.emit();
    }

    onActivate() {
        this.mixpanelService.track(`Ops: ${MixpanelEvents.FixtureWarningActivate}`);
        this.activate.emit();
    }

    onDismiss() {
        this.mixpanelService.track(`Ops: ${MixpanelEvents.FixtureWarningDismiss}`);
        this.dismiss.emit();
    }

    get isEditMode(): boolean {
        return this.warningState.isEditMode;
    }

    get category(): string {
        return this.warningState.warning.category;
    }

    get message(): string {
        return this.warningState.warning.message;
    }

    get path(): string {
        return this.warningState.warning.path
            .filter((x) => x.name)
            .reduce((curr, segment) => `${curr} ${curr ? ">" : ""} ${segment.name}`, "")
            .trim();
    }

    get canDismiss(): boolean {
        return !!this.warningState.warning.record && !this.warningState.isDismissed;
    }

    get isDismissed(): boolean {
        return !!this.warningState.isDismissed;
    }
}
