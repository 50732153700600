import { CompanySearchResult } from "src/app/fixture/shared/models/common/company-search-result.model";
import { VesselData } from "src/app/fixture/shared/models/common/vessel-data";
import { SharingGroupsViewModel } from "src/app/left-bar/shared/models/sharing-group.model";
import { Enumeration } from "src/app/shared/reference-data/enumeration";
import { FixtureTemplateType } from "src/app/shared/reference-data/fixture-template-type";

export class FixtureCreationFormModel {
    fixtureSector: Enumeration;
    fixtureType: FixtureTemplateType;
    charterPartyDate: Date;
    laycanFrom: Date;
    laycanTo: Date;
    vessel: VesselData;
    isVesselTbn: boolean;
    coANumber: string;
    companyRole: Enumeration;
    company: CompanySearchResult;
    sharingGroups: SharingGroupsViewModel[];
}
