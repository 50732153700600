import { Injectable } from "@angular/core";

import { CpRateUnit, Division } from "../../../../../shared/reference-data";
import { hasItems } from "../../../../../shared/utils";
import { AssociatedCargo, Berth, Cargo, Fixture, LaytimeEventFact, StatementOfFactsModel } from "../../../../shared/models";
import { CargoTotalLaytime } from "../../../../shared/models/form-models";
import { AssociatedCargoAllowedRatesAggregate } from "../../../../shared/models/form-models/associated-cargo-allowed-rates.model";
import { TotalActualLaytimeService } from "../../../../shared/totals/total-actual-laytime.service";
import { getCargoName } from "../../../../state/model";

@Injectable({
    providedIn: "root"
})
export class StatementOfFactsService {
    constructor(private totalActualLaytimeService: TotalActualLaytimeService) {}

    createStatementOfFactsModel(berth: Berth, cargoes: Cargo[], fixture: Fixture): StatementOfFactsModel {
        const statementOfFactsModel: StatementOfFactsModel = <StatementOfFactsModel>{
            cargoAllowedRates: []
        };

        let laytimeEventFacts: LaytimeEventFact[] = [];
        const allowedTimes: number[] = [];
        let totalLaytimeAtBerth = 0;
        let isLaytimeTypeReversible = false;
        let associatedCargoAllowedRateId = 1;

        if (berth && hasItems(berth.cargoBerthActivities)) {
            isLaytimeTypeReversible = this.totalActualLaytimeService.isLaytimeTypeReversible(berth);

            totalLaytimeAtBerth = this.totalActualLaytimeService.calculateTimeUsedAtBerth(berth.cargoBerthActivities, false, fixture.laytimeRounding, fixture.laytimeUnit);

            berth.cargoBerthActivities.forEach((cargoBerthActivity) => {
                laytimeEventFacts = [...laytimeEventFacts, ...(cargoBerthActivity.laytimeEventFacts || [])];
                const cargoLaytimes = this.totalActualLaytimeService.calculateCargoLaytimes(cargoBerthActivity, fixture.division);

                if (hasItems(cargoBerthActivity.associatedCargoes)) {
                    cargoBerthActivity.associatedCargoes.forEach((associatedCargo) => {
                        const timeAllowed = this.totalActualLaytimeService.calculateTimeAllowedForAssociatedCargo(associatedCargo);

                        allowedTimes.push(timeAllowed);

                        const cargoTimeUsed = cargoLaytimes.find((laytime) => laytime.cargoId === associatedCargo.cargoId);

                        const actualLoadAndDischargeRate = this.getActualLoadAndDischargeRate(
                            cargoBerthActivity.laytimeEventFacts,
                            associatedCargo,
                            cargoLaytimes,
                            fixture.division
                        );

                        const cargoAllowedRateModel = <AssociatedCargoAllowedRatesAggregate>{
                            associatedCargoAllowedRateId: associatedCargoAllowedRateId++,
                            berthId: berth.id,
                            activityId: cargoBerthActivity.id,
                            associatedCargoId: associatedCargo.id,
                            activity: cargoBerthActivity.type.name,
                            cargoProductName: "",
                            blQuantity: associatedCargo.mt || associatedCargo.quantity,
                            actualLoadAndDischargeRate,
                            cpRate: associatedCargo.cpRate,
                            cpRateUnit: associatedCargo.cpRateUnit,
                            extraHours: associatedCargo.extraHours,
                            timeAllowed,
                            cargoTimeUsed: cargoTimeUsed?.totalLaytime,
                            reversibleLaytimeType: associatedCargo.reversibleLaytimeType,
                            customaryQuickDespatch: associatedCargo.customaryQuickDespatch
                        };

                        if (hasItems(cargoes)) {
                            const cargo = cargoes.find((c) => c.id === associatedCargo.cargoId);
                            cargoAllowedRateModel.cargoProductName = getCargoName(cargo) || "";
                        }

                        statementOfFactsModel.cargoAllowedRates.push(cargoAllowedRateModel);
                    });
                }
            });
        }

        this.setLaytimeWindow(laytimeEventFacts, statementOfFactsModel);
        this.setAllowedTime(allowedTimes, statementOfFactsModel, isLaytimeTypeReversible);
        this.setRemainingTime(allowedTimes, totalLaytimeAtBerth, statementOfFactsModel, isLaytimeTypeReversible);

        statementOfFactsModel.usedTime = totalLaytimeAtBerth;
        return statementOfFactsModel;
    }

    private getActualLoadAndDischargeRate(
        laytimeEventFacts: LaytimeEventFact[],
        associatedCargo: AssociatedCargo,
        cargoTotalLaytimes: CargoTotalLaytime[],
        division: Division
    ): string {
        let timeUsed = this.totalActualLaytimeService.calculateTotalLaytimeForStatementOfFacts(laytimeEventFacts);

        if (division && division.id === Division.Specialised.id && hasItems(cargoTotalLaytimes)) {
            const cargoLaytime = cargoTotalLaytimes.find((x) => x.cargoId === associatedCargo.cargoId);
            timeUsed = cargoLaytime ? cargoLaytime.totalLaytime : timeUsed;
        }

        if (timeUsed && timeUsed !== 0 && associatedCargo.cpRateUnit) {
            const blQuantity = associatedCargo.mt || associatedCargo.quantity;
            const actualLoadAndDischargeRate = CpRateUnit.isMtPerHour(associatedCargo.cpRateUnit)
                ? blQuantity / timeUsed
                : CpRateUnit.isMtPerDay(associatedCargo.cpRateUnit)
                ? (blQuantity * 24) / timeUsed
                : timeUsed;

            if (actualLoadAndDischargeRate) {
                return `${actualLoadAndDischargeRate.toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })} ${associatedCargo.cpRateUnit.name}`;
            }
        }
    }

    private setRemainingTime(allowedTimes: number[], totalActualLaytime: number, statementOfFactsModel: StatementOfFactsModel, isLaytimeTypeReversible: boolean) {
        let sumAllowedTimes = 0;
        if (isLaytimeTypeReversible) {
            statementOfFactsModel.remainingTime = sumAllowedTimes;
        } else {
            sumAllowedTimes = allowedTimes.reduce((acc, cur) => acc + cur, 0);
            statementOfFactsModel.remainingTime = sumAllowedTimes - totalActualLaytime;
        }
    }

    private setAllowedTime(allowedTimes: number[], statementOfFactsModel: StatementOfFactsModel, isLaytimeTypeReversible: boolean) {
        let sumAllowedTimes = 0;

        if (!isLaytimeTypeReversible) {
            sumAllowedTimes = allowedTimes.reduce((acc, cur) => acc + cur, 0);
        }
        statementOfFactsModel.allowedTime = sumAllowedTimes;
    }

    private setLaytimeWindow(laytimeEvents: LaytimeEventFact[], statementOfFactsModel: StatementOfFactsModel) {
        const fromDateLaytimeEvents = laytimeEvents.filter((laytimeEvent: LaytimeEventFact) => laytimeEvent.fromDate);
        const toDateLaytimeEvents = laytimeEvents.filter((laytimeEvent: LaytimeEventFact) => laytimeEvent.toDate);
        statementOfFactsModel.laytimeCommenced = fromDateLaytimeEvents.length > 0 ? fromDateLaytimeEvents[0].fromDate : "";
        statementOfFactsModel.laytimeCompleted = toDateLaytimeEvents.length > 0 ? toDateLaytimeEvents[toDateLaytimeEvents.length - 1].toDate : "";
    }
}
