import { createAction, On, on, props } from "@ngrx/store";

import { UpdateColumns, updateColumnsReducer } from "@ops/state";

import { LtcState } from "../../model";

/* ACTIONS */
export const updateLaytimeCalculationGridColumnsAction = createAction("[Laytime Calculation Grid] Update Columns", props<UpdateColumns>());

/* REDUCERS */
export const updateLaytimeCalculationGridColumnsReducer: On<LtcState> = on(updateLaytimeCalculationGridColumnsAction, (state, action) =>
    updateColumnsReducer(state, action, "grid")
);
