import { Boxed } from "ngrx-forms";
import { SeaNetLocation } from "src/app/fixture/shared/models";

export type ActivityType = "load" | "discharge";
export type LocationId = string;

export type Location = Readonly<{
    locationId: LocationId;
    name: string;
    countryName: string;
    countryUnCode: string;
}>;

export type LocationForm = Readonly<{
    location?: Boxed<SeaNetLocation>;
}>;

export const toLocation = (seaNetLocation: SeaNetLocation): Location => {
    return {
        locationId: seaNetLocation.locationId,
        name: seaNetLocation.displayName,
        countryName: seaNetLocation.countryName,
        countryUnCode: seaNetLocation.countryUnCode
    };
};

export const toSeaNetLocation = (location: Location): SeaNetLocation => {
    return <SeaNetLocation>{
        locationId: location.locationId,
        displayName: location.name,
        countryName: location.countryName,
        countryUnCode: location.countryUnCode
    };
};
