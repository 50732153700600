import { box, unbox } from "ngrx-forms";

import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { DateRange, Division, FixtureType, SeaNetLocation } from "../../../../shared/models";
import {
    activityForm,
    associatedCargoForm,
    BerthForm,
    berthForm,
    CargoId,
    createActivityId,
    createAssociatedCargoId,
    createBerthId,
    DestinationForm,
    destinationForm,
    DestinationId,
    getDefaultLaytimeEvents
} from "../../../model";

export const getNewDestination = (
    id: DestinationId,
    destinationId: number,
    cargoId: CargoId,
    activityType: CargoBerthActivityType,
    fixtureType: FixtureType,
    location?: SeaNetLocation,
    etaRange?: DateRange
): DestinationForm => {
    const associatedCargo = associatedCargoForm(Division.specialisedProducts, createAssociatedCargoId(), 1, cargoId);
    const defaultLaytimeEvents = getDefaultLaytimeEvents(fixtureType, Division.specialisedProducts, activityType, true, null, location?.timeZone || "utc");
    const activity = {
        ...activityForm(Division.specialisedProducts, createActivityId(), 1),
        type: box({ ...activityType, longName: null }),
        associatedCargoes: [associatedCargo],
        laytimeEvents: defaultLaytimeEvents
    };
    const berth = { ...berthForm(Division.specialisedProducts, createBerthId(), 1), activities: [activity] };

    return {
        ...destinationForm(Division.specialisedProducts, id, destinationId),
        berths: [berth],
        location: box(location),
        etaRange: box(etaRange)
    };
};

export const findAllSpecialisedCargoDestinationElements = (cargoId: CargoId, activityType: CargoBerthActivityType, destinations: Readonly<DestinationForm[]>) => {
    for (const destination of destinations) {
        const destinationElements = findAllSpecialisedCargoBerthElements(cargoId, activityType, destination.berths);
        if (destinationElements) {
            return {
                destination,
                ...destinationElements
            };
        }
    }
};

export const findAllSpecialisedCargoBerthElements = (cargoId: CargoId, activityType: CargoBerthActivityType, berths: Readonly<BerthForm[]>) => {
    for (const berth of berths) {
        for (const activity of berth.activities) {
            const activityActivityType = unbox(activity.type);

            if (!activityActivityType || activityActivityType.id !== activityType.id) {
                continue;
            }

            for (const associatedCargo of activity.associatedCargoes) {
                if (associatedCargo.cargoId === cargoId) {
                    return {
                        berth,
                        activity,
                        associatedCargo
                    };
                }
            }
        }
    }
};
