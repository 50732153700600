<div [formGroup]="parentForm" class="card">
    <div class="card-header  row pl-0 no-gutters align-items-center">
        <div class="title-container">
            <span class="p-2">Laytime Calculation</span>
        </div>
        <div class="button-container">
            <button
                [disabled]="parentForm.disabled"
                (click)="syncLaytimeEventData()"
                type="button"
                class="btn-add has-icon icon--feed"
                [class.all-imported]="allImported"
                [ngbTooltip]="importButtonTooltip"
                data-test-remove-all-laytime-events-button
            ></button>
            <button
                [disabled]="parentForm.disabled"
                (click)="removeAllLaytimeEventFacts()"
                type="button"
                class="btn-delete has-icon icon--delete"
                ngbTooltip="Clear all port times"
                data-test-remove-all-laytime-events-button
            ></button>
        </div>
    </div>

    <div class="card-body p-0">
        <table class="table table-striped" *ngIf="model && model.length > 0; else noLaytimeEvents">
            <thead>
                <tr>
                    <th class="large-size">Time From</th>
                    <th class="large-size">Time To</th>
                    <th class="button-column-fixed"></th>
                    <th class="table-space extra-small-size"></th>
                    <th class="small-size">%</th>
                    <th class="small-size">Days</th>
                    <th class="small-size">Hrs</th>
                    <th class="small-size">Mins</th>
                    <th class="large-size">Event</th>
                    <th *opsDivisions="[Division.specialisedProducts,Division.pcg]" class="large-size">Cargo</th>
                    <th class="large-size">Remarks</th>
                    <th class="large-size">Additional Comments</th>
                    <th class="button-column-fixed"></th>
                </tr>
            </thead>
            <tbody [formArrayName]="formName">
                <tr
                    *ngFor="let row of formArray.controls; let i = index"
                    [formGroupName]="i"
                    class="form-group"
                    [class.selected-event]="isDemurrageEvent(model[i].id)"
                    [ngbTooltip]="getTitle(model[i].laytimeEventId)"
                    #tooltip="ngbTooltip"
                    (click)="tooltip.close()"
                >
                    <td>
                        <ops-date-input
                            formControlName="fromDate"
                            [timeZone]="locationTimezone"
                            [enableTime]="true"
                            [displayWeekday]="true"
                            [ngbPopover]="fromDateError"
                            #fromDate="ngbPopover"
                            triggers="manual"
                            (blur)="fromDate.close()"
                            (focus)="fromDate.open()"
                            data-test-event-date
                            [attr.id]="getEventDateId(i, 'from-date')"
                        ></ops-date-input>
                        <ng-template #fromDateError>
                            <div *ngIf="!row.controls.fromDate.valid && row.controls.fromDate.touched" class="row no-gutters display">
                                <span class="has-icon icon--warning col-1"></span>
                                <span class="content col-11" data-test-event-type-required><b>Invalid Date range</b></span>
                            </div>
                        </ng-template>
                    </td>
                    <td>
                        <ops-date-input
                            [timeZone]="locationTimezone"
                            [enableTime]="true"
                            [displayWeekday]="true"
                            [ngbPopover]="toDateError"
                            [defaultFocusDate]="row.controls.fromDate.value"
                            formControlName="toDate"
                            #toDate="ngbPopover"
                            triggers="manual"
                            (blur)="toDate.close()"
                            (focus)="toDate.open(); setDefaultToDate(i)"
                            [attr.id]="getEventDateId(i, 'to-date')"
                            data-test-event-date
                        ></ops-date-input>
                        <ng-template #toDateError>
                            <div *ngIf="!row.controls.toDate.valid && row.controls.toDate.touched" class="row no-gutters display">
                                <span class="has-icon icon--warning col-1"></span>
                                <span class="content col-11" data-test-event-type-required><b>Invalid Date range</b></span>
                            </div>
                        </ng-template>
                    </td>
                    <td>
                        <button
                            [disabled]="formArray.disabled"
                            (click)="addLaytimeEvent(i)"
                            type="button"
                            class="btn-add has-icon icon--add-circle-outline"
                            data-test-add-laytime-event-button
                        ></button>
                    </td>
                    <td class="table-space"></td>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right"
                            [opsNumber]="{ precision: 2, showTrailingZeros: false, minValue: 0, maxValue: 100 }"
                            formControlName="percentage"
                            data-test-event-percentage
                        />
                    </td>
                    <td>
                        <input [readonly]="true" type="text" formControlName="timePassedDays" class="form-control text-right" data-test-event-percentage />
                    </td>
                    <td>
                        <input [readonly]="true" type="text" formControlName="timePassedHrs" class="form-control text-right" data-test-event-percentage />
                    </td>
                    <td>
                        <input [readonly]="true" type="text" formControlName="timePassedMins" class="form-control text-right" data-test-event-percentage />
                    </td>
                    <td>
                        <ops-refdata-dropdown
                            #laytimeEvent
                            placeholder="Select Laytime"
                            formControlName="type"
                            type="LaytimeEvent"
                            [allowClear]="true"
                            data-test-event-type
                            (focus)="tooltip.close()"
                            (mouseover)="mouseover(laytimeEvent, tooltip)">
                        </ops-refdata-dropdown>
                    </td>
                    <td *opsDivisions="[Division.specialisedProducts,Division.pcg]">
                        <ng-select
                            class="ops-select form-control"
                            [items]="cargoes"
                            formControlName="cargoId"
                            [clearable]="true"
                            bindLabel="name"
                            bindValue="cargoId"
                            data-test-cargo
                            [selectOnTab]="true"
                            (open)="tooltip.close()"
                            [ngbTooltip]="getCargoNameToolTip(row.value.cargoId)">
                            <ng-template ng-option-tmp let-item="item">
                                <span title="{{item.name}}">{{item.name}}</span>
                            </ng-template>
                        </ng-select>
                    </td>
                    <td>
                        <ops-refdata-dropdown
                            formControlName="demurrageReason"
                            type="DemurrageReason"
                            [allowClear]="true"
                            data-test-demurrage-reason>
                        </ops-refdata-dropdown>
                    </td>
                    <td>
                        <input type="text" class="form-control" formControlName="comments" data-test-comments />
                    </td>
                    <td>
                        <button
                            [disabled]="formArray.disabled"
                            (click)="removeLaytimeEvent(i)"
                            type="button"
                            class="btn-delete has-icon icon--delete"
                            data-test-remove-laytime-event-button
                        ></button>
                    </td>
                </tr>

                <tr class="summary-row" *ngIf="model && model.length > 1">
                    <td></td>
                    <td></td>
                    <td>
                        <button
                            [disabled]="formArray.disabled"
                            type="button"
                            (click)="orderLaytimeEventFacts()"
                            class="btn-reorder has-icon icon--in-negotiation ml-auto"
                            data-test-order-laytime-events-button
                        ></button>
                    </td>
                    <td class="table-space"></td>
                    <td></td>
                    <td>
                        <input [readonly]="true" type="text" value="{{ totalDays }}" class="form-control text-right" data-test-event-total-days />
                    </td>
                    <td>
                        <input [readonly]="true" type="text" value="{{ totalHours }}" class="form-control text-right" data-test-event-total-hours />
                    </td>
                    <td>
                        <input [readonly]="true" type="text" value="{{ totalMins }}" class="form-control text-right" data-test-event-total-mins />
                    </td>
                    <td></td>
                    <td *opsDivisions="[Division.specialisedProducts,Division.pcg]"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        <ng-template #noLaytimeEvents>
            <ops-no-data-panel
                #panel="noDataPanel"
                title="No events are currently added"
                [useNewButton]="true"
                newButtonText="EVENT"
                [disabled]="formArray.disabled"
                (addNew)="addLaytimeEvent(0)"
            >
            </ops-no-data-panel>
        </ng-template>
    </div>
</div>
