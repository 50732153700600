import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import * as R from "ramda";
import { map, withLatestFrom } from "rxjs/operators";

import { FixtureTabsValidationInfo, TabValidationInfo } from "../../shared/tab-validation/tab-validation-info";
import { allowSaveIfInvalidTabs } from "../../shared/tab-validation/tab-validation-utils";
import { interopFixtureLoadSuccessAction, interopFixtureReloadSuccessAction } from "../fixture/interop";
import { currentFixtureStateReducer } from "../fixture/reducer";
import { FixturesState } from "../model";
import { selectCurrentFixtureValidationTabs } from "./selectors";

/* ACTIONS */
export const resetValidationAction = createAction("[Validation] Reset Validation Tabs", props<{ currentTabs?: FixtureTabsValidationInfo }>());

/* REDUCERS */
export const resetValidationReducer: On<FixturesState> = on(resetValidationAction, (state, { currentTabs }) => {
    const validationByTabs = R.mapObjIndexed((tab: TabValidationInfo) => (allowSaveIfInvalidTabs.includes(tab.tabName) ? tab : { ...tab, isValid: true }))(currentTabs ?? {});

    return currentFixtureStateReducer(state, { validationByTabs });
});

/* EFFECTS */
export const resetValidationOnFixtureLoadEffect$ = (actions$: Actions, store: Store) =>
    createEffect(() =>
        actions$.pipe(
            ofType(interopFixtureLoadSuccessAction, interopFixtureReloadSuccessAction),
            withLatestFrom(store.select(selectCurrentFixtureValidationTabs)),
            map(([, currentTabs]) => resetValidationAction({ currentTabs }))
        )
    );
