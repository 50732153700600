import { Injectable } from "@angular/core";
import { DateTime } from "luxon";

import { hasItems, isNullOrUndefined, durationInWindow } from "@ops/shared";
import { Division, LaytimeEventType, LaytimeType, ReversibleLaytimeType, TimeSavedType } from "@ops/shared/reference-data";

import { toNgbTimeStruct } from "../../shared/date-utils/date-utilities";
import { mapToLaytimeEventFactModels } from "../shared/mappers/laytime-event-fact-mapper";
import { Berth, DemurrageClaim, Destination, Fixture, LaytimeEventFact } from "../shared/models";
import { DemurrageClaimType } from "../shared/models/enums/demurrage-claim-type";
import { BerthSummaryModel, DestinationSummaryModel, LaytimeSummaryModel, LaytimeSummaryTotalTimeModel } from "../shared/models/form-models/laytime-summary-aggregate.model";
import { TotalActualLaytimeService } from "../shared/totals/total-actual-laytime.service";
import { ActivityId, BerthId, DestinationId, LaytimeEventId } from "../state/model";
import { LaytimeRoundingService } from "./laytime-rounding.service";

@Injectable({
    providedIn: "root"
})
export class EstimatedClaimValueCalculator {
    private hours = 24;
    constructor(private totalActualLaytimeService: TotalActualLaytimeService, private laytimeRoundingService: LaytimeRoundingService) {}

    private static hasClaim(currentDestination: Destination, currentBerth: Berth, destinationId: DestinationId, berthId: BerthId): boolean {
        return currentDestination.id === destinationId && currentBerth.id === berthId;
    }

    private static locationNonFixedNonReversibleECV(berths: BerthSummaryModel[]): number {
        let total = 0;
        if (berths && berths.length > 0) {
            total = berths.reduce((acc, current) => acc + (current.claimValue || 0), 0);
        }
        return total;
    }

    private static fixtureNonFixedNonReversibleECV(destinations: DestinationSummaryModel[]): number {
        let total = 0;
        if (destinations && destinations.length > 0) {
            total = destinations.reduce((acc, current) => acc + (current.claimValue || 0), 0);
        }
        return total;
    }

    private static isCustomaryQuickDespatch(berth: Berth) {
        return (
            berth &&
            berth.cargoBerthActivities &&
            berth.cargoBerthActivities.find((a) => a.associatedCargoes && a.associatedCargoes.length > 0 && a.associatedCargoes.find((c) => c.customaryQuickDespatch === true))
        );
    }

    calculateTotalTimeForFixtureECV(fixture: Fixture, destinations: Destination[]): LaytimeSummaryTotalTimeModel {
        const isLaytimeFixed = this.isLaytimeFixed(fixture);

        const allowedTime = isLaytimeFixed ? fixture.fixedLaytime : this.totalActualLaytimeService.calculateTotalTimeAllowedForReversibleCargoes(destinations);
        const timeUsedForFixture = this.totalActualLaytimeService.calculateTimeUsedAtFixture(destinations, !isLaytimeFixed, fixture.laytimeRounding, fixture.laytimeUnit);

        return <LaytimeSummaryTotalTimeModel>{
            isFixed: isLaytimeFixed,
            hasReversible: !isLaytimeFixed && this.totalActualLaytimeService.hasReversibleCargoes(destinations),
            allowedTime,
            timeUsedForFixture,
            remainingTime: allowedTime - timeUsedForFixture
        };
    }

    calculateFixtureECV(fixture: Fixture, destinations: Destination[]) {
        let destinationClaim = {
            destinationId: null as DestinationId,
            berthId: null as BerthId,
            berthActivityId: null as ActivityId,
            laytimeEventFactId: null as LaytimeEventId,
            laytimeEventType: null as LaytimeEventType
        };
        let fixtureClaim = {
            claimValue: 0,
            claimType: null as DemurrageClaimType,
            destinationId: null as DestinationId,
            berthId: null as BerthId,
            berthActivityId: null as ActivityId,
            laytimeEventFactId: null as LaytimeEventId,
            laytimeEventType: null as LaytimeEventType
        };
        let allowedTime = 0;
        let timeUsedForFixture = 0;

        if (this.isLaytimeFixed(fixture)) {
            allowedTime = fixture.fixedLaytime;
            timeUsedForFixture = this.totalActualLaytimeService.calculateTimeUsedAtFixture(destinations, false, fixture.laytimeRounding, fixture.laytimeUnit);
            destinationClaim = this.getDestinationWithClaim(destinations, allowedTime, false);
        } else if (this.isLaytimeNonFixed(fixture)) {
            allowedTime = this.totalActualLaytimeService.calculateTotalTimeAllowedForReversibleCargoes(destinations);
            if (allowedTime > 0) {
                timeUsedForFixture = this.totalActualLaytimeService.calculateTimeUsedAtFixture(destinations, true, fixture.laytimeRounding, fixture.laytimeUnit);
                destinationClaim = this.getDestinationWithClaim(destinations, allowedTime, true);
            }
        }

        if (allowedTime > 0) {
            if (timeUsedForFixture > allowedTime || fixture.demurrageBankEnabled) {
                if (fixture.demurrage?.rate) {
                    const estimatedClaimValue = (timeUsedForFixture - allowedTime) * (fixture.demurrage.rate / this.hours);
                    const claimType = fixture.division.id === Division.DryCargo.id ? DemurrageClaimType.Demurrage : DemurrageClaimType.FullClaim;

                    fixtureClaim = {
                        claimValue: estimatedClaimValue,
                        claimType,
                        destinationId: destinationClaim.destinationId,
                        berthId: destinationClaim.berthId,
                        berthActivityId: destinationClaim.berthActivityId,
                        laytimeEventFactId: destinationClaim.laytimeEventFactId,
                        laytimeEventType: destinationClaim.laytimeEventType
                    };
                }
            } else {
                if (fixture.division.id === Division.DryCargo.id) {
                    let remainingTime = allowedTime - timeUsedForFixture;
                    let rate = fixture.despatchRate;
                    let claimType = DemurrageClaimType.Despatch;

                    if (fixture.timeSavedType && fixture.timeSavedType.id === TimeSavedType.WorkingTimeSaved.id) {
                        const excludingTime = this.calculateExcludingTimeForFixture(destinations);
                        remainingTime -= excludingTime;
                    }

                    if (remainingTime < 0) {
                        rate = fixture.demurrage.rate;
                        claimType = DemurrageClaimType.Demurrage;
                    }

                    const estimatedClaimValue = rate ? Math.abs(remainingTime) * (rate / this.hours) : 0;
                    fixtureClaim = {
                        claimValue: estimatedClaimValue,
                        claimType,
                        destinationId: destinationClaim.destinationId,
                        berthId: destinationClaim.berthId,
                        berthActivityId: destinationClaim.berthActivityId,
                        laytimeEventFactId: destinationClaim.laytimeEventFactId,
                        laytimeEventType: destinationClaim.laytimeEventType
                    };
                }
            }
        }

        return fixtureClaim;
    }

    isLaytimeFixed(fixture: Fixture) {
        return fixture.laytimeType && fixture.laytimeType.id === LaytimeType.Fixed.id;
    }

    isLaytimeNonFixed(fixture: Fixture) {
        return isNullOrUndefined(fixture.laytimeType) || fixture.laytimeType.id === LaytimeType.NonFixed.id;
    }

    getDestinationWithClaim(destinations: Destination[], totalTimeAllowed: number, isReversible: boolean) {
        let totalTimeUsed = 0;
        let destinationBerth = {
            destinationId: null as DestinationId,
            berthId: null as BerthId,
            berthActivityId: null as ActivityId,
            laytimeEventFactId: null as LaytimeEventId,
            laytimeEventType: null as LaytimeEventType
        };
        let isClaimFound = false;

        if (destinations && destinations.length > 0) {
            for (const destination of destinations) {
                if (destination.berths && destination.berths.length > 0) {
                    for (const berth of destination.berths) {
                        if (hasItems(berth.cargoBerthActivities)) {
                            berth.cargoBerthActivities.forEach((berthActivity) => {
                                if (hasItems(berthActivity.laytimeEventFacts)) {
                                    const isReversibleCargo = berthActivity.associatedCargoes.find(
                                        (c) => c.reversibleLaytimeType && c.reversibleLaytimeType.id === ReversibleLaytimeType.Reversible.id
                                    );
                                    const isFixedOrReversible = !isReversible || isReversibleCargo;
                                    if (isFixedOrReversible) {
                                        const laytimeEventsList = mapToLaytimeEventFactModels(berthActivity.laytimeEventFacts);
                                        laytimeEventsList.forEach((l) => {
                                            totalTimeUsed += l.timePassedTotalHours;
                                            if (totalTimeUsed > totalTimeAllowed && !isClaimFound) {
                                                destinationBerth = {
                                                    destinationId: destination.id,
                                                    berthId: berth.id,
                                                    berthActivityId: berthActivity.id,
                                                    laytimeEventFactId: l.laytimeEventId,
                                                    laytimeEventType: l.type
                                                };
                                                isClaimFound = true;
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    }
                }
            }

            if (totalTimeAllowed && totalTimeUsed < totalTimeAllowed && !isClaimFound) {
                const { destinationId, berthId } = this.getLastDestinationAndBerthIds(destinations, isReversible);
                destinationBerth = {
                    destinationId,
                    berthId,
                    berthActivityId: null as ActivityId,
                    laytimeEventFactId: null as LaytimeEventId,
                    laytimeEventType: null as LaytimeEventType
                };
            }
        }
        return destinationBerth;
    }

    getLastDestinationAndBerthIds(destinations: Destination[], isReversible: boolean) {
        if (isReversible) {
            for (let i = destinations.length - 1; i >= 0; i--) {
                const destination = destinations[i];
                let destinationBerth: Berth = null;
                for (let j = destination.berths.length - 1; j >= 0; j--) {
                    const berth = destination.berths[j];
                    const found = berth.cargoBerthActivities.some((cba) =>
                        cba.associatedCargoes.some((c) => c.reversibleLaytimeType && c.reversibleLaytimeType.id === ReversibleLaytimeType.Reversible.id)
                    );
                    if (found) {
                        destinationBerth = berth;
                        break;
                    }
                }
                if (destinationBerth) {
                    return {
                        destinationId: destination.id,
                        berthId: destinationBerth.id
                    };
                }
            }
            //not found
            return { destinationId: null as DestinationId, berthId: null as BerthId };
        } else {
            const dest = destinations[destinations.length - 1];
            const berthId = dest.berths ? dest.berths[dest.berths.length - 1].id : null;
            return { destinationId: dest.id, berthId };
        }
    }

    calculateBerthNonFixedNonReversibleECV(berth: Berth, fixture: Fixture, destination: Destination) {
        let claim = {
            claimValue: 0,
            claimType: null as DemurrageClaimType,
            berthActivityId: null as ActivityId,
            laytimeEventFactId: null as LaytimeEventId,
            timeUsed: 0,
            timeAllowed: 0,
            laytimeEventType: null as LaytimeEventType
        };
        let remainingTime = 0;
        let isClaimFound = false;
        let berthActivityId = null as ActivityId;
        let laytimeEventFactId = null as LaytimeEventId;
        let timeUsedAtBerth = 0;
        let timeAllowedAtBerth = 0;
        let laytimeEventType = null as LaytimeEventType;

        if (berth && berth.cargoBerthActivities && berth.cargoBerthActivities.length > 0) {
            timeAllowedAtBerth = this.totalActualLaytimeService.calculateTimeAllowedAtBerth(berth, ReversibleLaytimeType.NonReversible);
            if (hasItems(berth.cargoBerthActivities)) {
                const nonReversibleCargoBerthActivities = berth.cargoBerthActivities.filter((x) =>
                    x.associatedCargoes.some((a) => ReversibleLaytimeType.isNonReversible(a.reversibleLaytimeType))
                );
                nonReversibleCargoBerthActivities.forEach((berthActivity) => {
                    if (hasItems(berthActivity.laytimeEventFacts)) {
                        const laytimeEventsList = mapToLaytimeEventFactModels(berthActivity.laytimeEventFacts);
                        laytimeEventsList.forEach((l) => {
                            timeUsedAtBerth += l.timePassedTotalHours;
                            if (timeUsedAtBerth > timeAllowedAtBerth && !isClaimFound) {
                                berthActivityId = berthActivity.id;
                                laytimeEventFactId = l.laytimeEventId;
                                laytimeEventType = l.type;
                                isClaimFound = true;
                            }
                        });
                    }
                });
            }

            timeUsedAtBerth = this.laytimeRoundingService.round(timeUsedAtBerth, fixture.laytimeRounding, fixture.laytimeUnit);

            if (timeAllowedAtBerth !== 0) {
                remainingTime = timeAllowedAtBerth - timeUsedAtBerth;
            }
        }

        if (this.isLaytimeNonFixed(fixture)) {
            if (remainingTime < 0 || fixture.demurrageBankEnabled) {
                claim = this.setClaimForVesselOnDemurrage(
                    fixture,
                    berth,
                    remainingTime,
                    berthActivityId,
                    laytimeEventFactId,
                    timeUsedAtBerth,
                    timeAllowedAtBerth,
                    laytimeEventType
                );
                claim.berthActivityId = claim.claimValue ? berthActivityId : null;
                claim.laytimeEventFactId = claim.claimValue ? laytimeEventFactId : null;
                claim.laytimeEventType = claim.laytimeEventType ? laytimeEventType : <LaytimeEventType>null;
            }

            if (remainingTime > 0 && fixture.division.id === Division.DryCargo.id) {
                const excludingTime = this.calculateExcludingTimeForBerth(destination, berth);
                remainingTime = TimeSavedType.isWorkingTimeSave(fixture.timeSavedType) ? remainingTime - excludingTime : remainingTime;

                if (TimeSavedType.isWorkingTimeSave(fixture.timeSavedType) && remainingTime < 0) {
                    claim = this.setClaimForVesselOnDemurrage(fixture, berth, remainingTime, null, null, timeUsedAtBerth, timeAllowedAtBerth, laytimeEventType);
                } else if (fixture.despatchRate) {
                    claim.claimValue = (remainingTime * fixture.despatchRate) / this.hours;
                    claim.claimType = DemurrageClaimType.Despatch;
                }
            }
        }
        return claim;
    }

    createLaytimeSummaryModel(fixture: Fixture, destinations: Destination[]): LaytimeSummaryModel {
        let laytimeSummary = <LaytimeSummaryModel>{
            destinations: [],
            claimValue: null
        };

        if (destinations && destinations.length > 0) {
            const destinationsSummary: DestinationSummaryModel[] = [];

            const fixtureClaim = this.calculateFixtureECV(fixture, destinations);

            destinations.forEach((destination) => {
                const destinationSummary = <DestinationSummaryModel>{
                    id: destination.id,
                    destinationId: destination.destinationId,
                    location: destination.location,
                    etaRange: destination.etaRange,
                    arrivalDateTime: destination.arrivalDateTime,
                    berths: []
                };

                if (destination.berths && destination.berths.length > 0) {
                    destination.berths.forEach((berth) => {
                        let berthSummary = this.setBerth(berth, 0, null, false, 0, 0, 0, null, null, null);
                        let timeUsedAtBerth = this.totalActualLaytimeService.calculateTimeUsedAtBerth(
                            berth.cargoBerthActivities,
                            false,
                            fixture.laytimeRounding,
                            fixture.laytimeUnit
                        );

                        if (this.isLaytimeFixed(fixture)) {
                            if (EstimatedClaimValueCalculator.hasClaim(destination, berth, fixtureClaim.destinationId, fixtureClaim.berthId)) {
                                berthSummary = this.setBerth(
                                    berth,
                                    fixtureClaim.claimValue,
                                    fixtureClaim.claimType,
                                    true,
                                    timeUsedAtBerth,
                                    fixture.fixedLaytime,
                                    0,
                                    fixtureClaim.berthActivityId,
                                    fixtureClaim.laytimeEventFactId,
                                    fixtureClaim.laytimeEventType
                                );
                            }
                            berthSummary.timeUsed = timeUsedAtBerth;
                            destinationSummary.berths.push(berthSummary);
                        } else if (this.isLaytimeNonFixed(fixture)) {
                            const berthClaim = this.calculateBerthNonFixedNonReversibleECV(berth, fixture, destination);

                            timeUsedAtBerth = berthClaim.timeUsed || timeUsedAtBerth;
                            const timeAllowedAtBerth = berthClaim.timeAllowed || this.totalActualLaytimeService.calculateTimeAllowedAtBerth(berth, null);
                            const timeRemainingAtBerth = timeAllowedAtBerth !== 0 ? timeAllowedAtBerth - timeUsedAtBerth : 0;
                            berthSummary = this.setBerth(
                                berth,
                                berthClaim.claimValue,
                                berthClaim.claimType,
                                false,
                                timeUsedAtBerth,
                                timeAllowedAtBerth,
                                timeRemainingAtBerth,
                                berthClaim.berthActivityId,
                                berthClaim.laytimeEventFactId,
                                berthClaim.laytimeEventType
                            );

                            if (
                                fixtureClaim &&
                                fixtureClaim.claimValue !== 0 &&
                                EstimatedClaimValueCalculator.hasClaim(destination, berth, fixtureClaim.destinationId, fixtureClaim.berthId)
                            ) {
                                const reversibleBerth = this.setBerth(
                                    berth,
                                    fixtureClaim.claimValue,
                                    fixtureClaim.claimType,
                                    false,
                                    timeUsedAtBerth,
                                    timeAllowedAtBerth,
                                    timeRemainingAtBerth,
                                    fixtureClaim.berthActivityId,
                                    fixtureClaim.laytimeEventFactId,
                                    fixtureClaim.laytimeEventType
                                );
                                destinationSummary.berths.push(reversibleBerth);

                                if (this.totalActualLaytimeService.hasNoneReversible(berth)) {
                                    destinationSummary.berths.push(berthSummary);
                                }
                            } else {
                                destinationSummary.berths.push(berthSummary);
                            }
                        }
                    });
                }

                destinationSummary.claimValue = EstimatedClaimValueCalculator.locationNonFixedNonReversibleECV(destinationSummary.berths);
                destinationsSummary.push(destinationSummary);
            });
            laytimeSummary = <LaytimeSummaryModel>{
                destinations: destinationsSummary,
                claimValue: EstimatedClaimValueCalculator.fixtureNonFixedNonReversibleECV(destinationsSummary)
            };
        }

        return laytimeSummary;
    }

    calculateCurrentECV(fixture: Fixture, destinations: Destination[], currentClaim: DemurrageClaim): number {
        let estimatedClaimValue = 0;
        const laytimeSummary = this.createLaytimeSummaryModel(fixture, destinations);

        if (this.isLaytimeFixed(fixture)) {
            estimatedClaimValue = laytimeSummary.claimValue;
        } else if (this.isLaytimeNonFixed(fixture)) {
            if (fixture.division.id === Division.Specialised.id || fixture.division.id === Division.Gas.id || fixture.division.id === Division.Pcg.id) {
                estimatedClaimValue = laytimeSummary.claimValue;
            }

            if (fixture.division.id === Division.DryCargo.id) {
                if (laytimeSummary && laytimeSummary.destinations && laytimeSummary.destinations.length > 0) {
                    const reversibleClaim = laytimeSummary.destinations.find(
                        (d) =>
                            (!currentClaim.destinationId || (currentClaim.destinationId && d.destinationId === currentClaim.destinationId)) &&
                            d.berths.find((b) => (!currentClaim.berthId || currentClaim.berthId === b.berthId) && b.reversible === true)
                    );
                    if (reversibleClaim) {
                        const berthReversibleClaim = reversibleClaim.berths.find((b) => (!currentClaim.berthId || currentClaim.berthId === b.berthId) && b.reversible === true);
                        if (berthReversibleClaim && berthReversibleClaim.claimType && berthReversibleClaim.claimType === currentClaim.type.id) {
                            estimatedClaimValue = berthReversibleClaim.claimValue;
                        }
                    }

                    const destination = laytimeSummary.destinations.find((d) => d.destinationId === currentClaim.destinationId);

                    if (destination && destination.berths && destination.berths.length > 0) {
                        const berth = destination.berths.find(
                            (b) => b.berthId === currentClaim.berthId && currentClaim.type && b.claimType && b.claimType === currentClaim.type.id && !b.reversible
                        );

                        if (berth) {
                            estimatedClaimValue = berth.claimValue;
                        }
                    }
                }
            }
        }

        if (fixture.demurrageBankEnabled) {
            return estimatedClaimValue;
        } else {
            return estimatedClaimValue > 0 ? estimatedClaimValue : 0;
        }
    }

    calculateExcludingTimeForFixture(destinations: Destination[]) {
        let totalExcludingTime = 0;
        if (destinations && destinations.length > 0) {
            destinations.forEach((destination) => {
                totalExcludingTime += this.calculateExcludingTimeForLocation(destination);
            });
        }
        return totalExcludingTime;
    }

    calculateExcludingTimeForLocation(destination: Destination): number {
        let excludingTime = 0;
        if (destination && destination.excludingFromDay && destination.excludingToDay && destination.excludingFromTime && destination.excludingToTime) {
            const fromTime = toNgbTimeStruct(destination.excludingFromTime);
            const toTime = toNgbTimeStruct(destination.excludingToTime);

            const fromDate = DateTime.local().set({ weekday: destination.excludingFromDay.id, hour: fromTime.hour, minute: fromTime.minute });
            let toDate = DateTime.local().set({ weekday: destination.excludingToDay.id, hour: toTime.hour, minute: toTime.minute });

            if (destination.excludingFromDay.id > destination.excludingToDay.id) {
                toDate = toDate.plus({ days: 7 });
            }

            excludingTime = toDate.diff(fromDate, "hours").hours;
        }

        return +excludingTime.toFixed(2);
    }

    calculateExcludingTimeForBerth(destination: Destination, berth: Berth): number {
        let excludingTime = 0;
        if (destination && destination.excludingFromDay && destination.excludingToDay && destination.excludingFromTime && destination.excludingToTime) {
            const facts = berth.cargoBerthActivities.reduce((a, b) => a.concat(b.laytimeEventFacts), <LaytimeEventFact[]>[]);
            for (const laytimeFact of facts) {
                const fromDate = DateTime.fromISO(laytimeFact.fromDate);
                const toDate = DateTime.fromISO(laytimeFact.toDate);
                const percentage = isNaN(laytimeFact.percentage) ? 100 : laytimeFact.percentage;

                const diff = durationInWindow(
                    fromDate,
                    toDate,
                    destination.excludingFromDay.id,
                    destination.excludingToDay.id,
                    destination.excludingFromTime,
                    destination.excludingToTime
                );

                const diffHours = +diff.hours.toFixed(2);
                excludingTime += diffHours * (percentage / 100);
            }
        }

        return +excludingTime.toFixed(2);
    }

    private setBerth(
        berth: Berth,
        claimValue: number,
        claimType: DemurrageClaimType,
        fixed: boolean,
        timeUsed: number,
        timeAllowed: number,
        timeRemaining: number,
        berthActivityId: ActivityId,
        laytimeEventFactId: LaytimeEventId,
        laytimeEventType: LaytimeEventType
    ): BerthSummaryModel {
        const hasReversible = this.totalActualLaytimeService.isLaytimeTypeReversible(berth);
        const hasNonReversible = this.totalActualLaytimeService.hasNoneReversible(berth);

        return <BerthSummaryModel>{
            id: berth.id,
            berthId: berth.berthId,
            name: berth.name,
            etb: berth.etb,
            cargoBerthActivities: berth.cargoBerthActivities,
            claimValue,
            claimType,
            fixed,
            reversible: !hasReversible && !hasNonReversible ? null : hasReversible,
            hasMixedLaytimeTypes: hasReversible && hasNonReversible,
            timeUsed,
            timeAllowed,
            timeRemaining,
            demurrageBerthActivityId: berthActivityId,
            demurrageLaytimeEventFactId: laytimeEventFactId,
            demurrageLaytimeEventType: laytimeEventType
        };
    }

    private setClaimForVesselOnDemurrage(
        fixture: Fixture,
        berth: Berth,
        remainingTime: number,
        berthActivityId: ActivityId,
        laytimeEventFactId: LaytimeEventId,
        timeUsed: number,
        timeAllowed: number,
        laytimeEventType: LaytimeEventType
    ) {
        const claim = {
            claimValue: 0,
            claimType: null as DemurrageClaimType,
            berthActivityId,
            laytimeEventFactId,
            timeUsed,
            timeAllowed,
            laytimeEventType
        };
        const isCustomaryQuickDespatch = EstimatedClaimValueCalculator.isCustomaryQuickDespatch(berth);

        if (isCustomaryQuickDespatch && fixture.detentionRate) {
            claim.claimValue = (Math.abs(remainingTime) * fixture.detentionRate) / this.hours;
            claim.claimType = fixture.division.id === Division.DryCargo.id ? DemurrageClaimType.Detention : DemurrageClaimType.FullClaim;
        }

        if (!isCustomaryQuickDespatch && fixture.demurrage && fixture.demurrage.rate) {
            const claimValue = (remainingTime * fixture.demurrage.rate) / this.hours;

            if (fixture.demurrageBankEnabled && remainingTime > 0) {
                claim.claimValue = claimValue * -1;
            } else if (remainingTime < 0) {
                claim.claimValue = Math.abs(claimValue);
            }

            claim.claimType = fixture.division.id === Division.DryCargo.id ? DemurrageClaimType.Demurrage : DemurrageClaimType.FullClaim;
        }

        return claim;
    }
}
