<div *ngIf="(fixture$ | async) as fixture; else loading" class="d-flex flex-fill">
    <form novalidate [formGroup]="fixtureForm" (ngSubmit)="save()" [ngSwitch]="fixture.fixtureType.id" class="d-flex flex-column flex-fill" autocomplete="off">
        <div class="fixture-summary row">
            <ops-summary [fixture]="fixture$ | async" [voyage]="voyage$ | async" class="col"></ops-summary>
            <div class="d-flex ml-auto">
                <div class="row no-gutters" *ngIf="fixture.lockedBy !== null">
                    <div class="user-name">
                        <span>{{ fixture.lockedBy?.fullName }}</span>
                    </div>
                    <div class="lock">
                        <span class="has-icon icon--fully-fixed show-lock" [class.user-lock]="isLockedByCurrentUser" [class.disabled]="!isLockedByCurrentUser"></span>
                        <span>|</span>
                    </div>
                </div>
                <div class="actions">
                    <button
                        class="btn btn-link mr-0 ml-0"
                        type="button"
                        (click)="cancel()"
                        [hidden]="!isLockedByCurrentUser || animatingSaveButton"
                        [disabled]="animatingCancelButton"
                        data-test-cancel
                    >
                        <span *ngIf="!animatingCancelButton">CANCEL</span>
                        <ops-load-animation class="light-background" *ngIf="animatingCancelButton" size="small"></ops-load-animation>
                    </button>
                    <button #saveButton class="btn btn-primary" (click)="save()" type="button" [hidden]="!isLockedByCurrentUser" [disabled]="animatingSaveButton" data-test-save>
                        <span *ngIf="!animatingSaveButton">SAVE</span>
                        <ops-load-animation *ngIf="animatingSaveButton" size="small"></ops-load-animation>
                    </button>
                    <button
                        class="btn btn-primary"
                        (click)="edit()"
                        type="button"
                        [hidden]="isLockedByCurrentUser"
                        data-test-edit
                        [disabled]="animatingEditButton || (fixture.lockedBy && !isLockedByCurrentUser)"
                    >
                        <span *ngIf="!animatingEditButton">EDIT</span>
                        <ops-load-animation *ngIf="animatingEditButton" size="small"></ops-load-animation>
                    </button>
                </div>
                <div *ngIf="(isFavourite$ | async) as isFavourite; else isNotFavourite" class="favourite" [hidden]="isFavouriteHidden">
                    <span>|</span>
                    <button
                        type="button"
                        class="fav-button has-icon icon--star-filled"
                        (click)="toggleFavouriteStatus()"
                        [disabled]="favouritesLoading"
                    ></button>
                </div>
                <ng-template #isNotFavourite>
                    <div class="favourite" [hidden]="isFavouriteHidden">
                        <span>|</span>
                        <button
                            type="button"
                            class="fav-button has-icon icon--star-outline"
                            (click)="toggleFavouriteStatus()"
                            [disabled]="favouritesLoading"
                        ></button>
                    </div>
                </ng-template>
                <div class="kebab-panel" [hidden]="!isKebabPanelVisible">
                    <span>|</span>
                    <button
                        type="button"
                        [disabled]="animatingEditButton || (fixture.lockedBy !== null && !isLockedByCurrentUser && !isAdmin) "
                        [ngbPopover]="moreMenuContent"
                        [autoClose]="'outside'"
                        #moreMenu="ngbPopover"
                        (click)="moreMenu.open()"
                        placement="bottom-right"
                        triggers="manual"
                        class="kebab has-icon icon--more-vert"
                    ></button>
                </div>
                <ng-template #moreMenuContent>
                    <div class="more-menu list-group" >
                        <div class="more-menu-item list-group-item list-group-item-action"
                            (click)="toggleFavouriteStatus()"
                            [hidden]="favouritesLoading">
                            <span [className]="(isFavourite$ | async) ? 'has-icon icon--star-filled' : 'has-icon icon--star-outline'"></span>
                            <span>Favourite</span>
                        </div>
                        <div class="more-menu-item list-group-item list-group-item-action" (click)="cloneFixture()" [hidden]="!canCloneFixture || fixtureForm.enabled">
                            <span class="has-icon icon--content-copy"></span>
                            <span>Duplicate</span>
                        </div>
                        <div class="more-menu-item list-group-item list-group-item-action" (click)="unlockFixture()" [hidden]="!isAdmin || fixture.lockedBy === null">
                            <span class="has-icon icon--lock-open"></span>
                            <span>Unlock</span>
                        </div>
                        <div class="more-menu-item list-group-item list-group-item-action" [hidden]="!isAdmin">
                            <span class="has-icon icon--delete"></span>
                            <span [hidden]="!this.hideDeleteConfirmation" (click)="delete(deletionComponent)">Delete</span>
                            <span class="deletion-confirmation-wrapper" [hidden]="this.hideDeleteConfirmation">
                                <ops-deletion-confirmation
                                    #deletionComponent
                                    buttonClass="has-icon icon--delete"
                                    (deleteConfirmed)="deleteFixture()"
                                    (displayStatus)="setDeleteConfirmationStatus($event)"
                                    data-test-invoicedelete
                                ></ops-deletion-confirmation>
                            </span>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>

        <div class="fixture">
            <ops-voyage
                *ngSwitchCase="fixtureTypes.Voyage"
                [fixture$]="fixture$"
                [voyage]="voyage$ | async"
                [voyages$]="voyages$"
                [fixtureSave$]="fixtureSave$"
                [voyageSave$]="voyageSave$"
                [parentForm]="fixtureForm"
                [fixtureSource]="fixture?.fixtureSource?.id"
                (voyageUpdated)="handleUpdateCommand($event)"
            >
            </ops-voyage>

            <ops-time-charter
                *ngSwitchCase="fixtureTypes.TimeCharter"
                [fixture$]="fixture$"
                [voyage$]="voyage$"
                [voyages$]="voyages$"
                [fixtureSave$]="fixtureSave$"
                [voyageSave$]="voyageSave$"
                [parentForm]="fixtureForm"
                [fixtureSource]="fixture?.fixtureSource?.id"
                (timeCharterUpdated)="handleUpdateCommand($event)"
            >
            </ops-time-charter>
        </div>
    </form>
</div>
<ng-template #loading>
    <div class="loading-panel">
        <ops-load-animation size="x-large" class="light-background"></ops-load-animation>
    </div>
</ng-template>
