import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ProfitAndLossReport, ReportLine } from "../../services/profit-and-loss";

@Component({
    selector: "ops-profit-and-loss",
    templateUrl: "./profit-and-loss.component.html",
    styleUrls: ["./profit-and-loss.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfitAndLossComponent {
    @Input() report: ProfitAndLossReport;

    trackBy(lineItem: ReportLine) {
        return lineItem.name;
    }

    getInvalidTooltip(lineItem: ReportLine) {
        if (lineItem.invalid?.currencyConversion) {
            return `One or more ${lineItem.name} items have been excluded as they have a non-fixture currency.`;
        }
    }
}
