import { Currency, Sector } from "@ops/shared";

import { Cargo } from "./cargo";
import { Company } from "./company";
import { Location } from "./location";
import { NominationTask } from "./nomination-task";
import { User } from "./user";

export type CoaId = string;
export type CoaControlledProperty = keyof Coa;
export type CoaDriver = "Vessel" | "Cargo";

export type CoaSource = "Ops" | string;

export const coaStatuses = ["Open", "Closed", "Cancelled"] as const;
export type CoaStatus = typeof coaStatuses[number];

export const cancellationReasons = [
    "Charterers cancel - no cost",
    "Charterers cancel - cost",
    "Owners cancel - no cost",
    "Owners cancel - cost",
    "Mutual cancellation agreed"
] as const;
export type CancellationReason = typeof cancellationReasons[number];

export type LocationsKey = keyof Pick<Coa, "loadLocations" | "dischargeLocations">;

export type Coa = Readonly<{
    coaId: CoaId;
    tenantId: number;
    source: CoaSource;
    reference: string;
    name: string;
    comments: string;
    lastUpdatedAt: string;
    lastUpdatedBy: User;
    lastEventNumber: number;
    controlledProperties: ReadonlyArray<CoaControlledProperty>;
    charterpartyDate: string;
    sector: Sector;
    driver: CoaDriver;
    contractStatus: CoaStatus;
    cancellationReason: CancellationReason;
    currency: Currency;
    brokers: ReadonlyArray<User>;
    operators: ReadonlyArray<User>;
    contacts: ReadonlyArray<Company>;
    cargoes: ReadonlyArray<Cargo>;
    loadLocations: ReadonlyArray<Location>;
    dischargeLocations: ReadonlyArray<Location>;
    nominationTasks: ReadonlyArray<NominationTask>;
    configurationRoles: ReadonlyArray<string>;
}>;
