import { Command } from "../../../../mediator/commands/command";
import { VoyageBunkerFormModel } from "../../../../shared/models/form-models/voyage-bunker-form.model";
import { ActivityId, BerthId, DestinationId } from "../../../../state/model";
import { UpdateVoyageBunkerCommandHandler } from "./update-voyage-bunker.command-handler";

export class UpdateVoyageBunkerCommand extends Command {
    constructor(destinationId: DestinationId, berthId: BerthId, berthActivityId: ActivityId, formModel: VoyageBunkerFormModel) {
        super(UpdateVoyageBunkerCommandHandler, {
            destinationId,
            berthId,
            berthActivityId,
            formModel
        });
    }
}
