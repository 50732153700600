<div [formGroup]="personSearchForm" class="container">
    <div class="row">
        <div class="col">
            <div class="row no-gutters">
                <div class="form-group col">
                    <label class="search-label">Search</label>
                    <ops-person-autosuggest formControlName="person" (selectionChange)="onSelectionChange($event)" data-test-person></ops-person-autosuggest>
                </div>
            </div>
        </div>
    </div>
</div>
