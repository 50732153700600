import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroupState, NgrxValueConverters } from "ngrx-forms";

import { VerifiedTimestamp } from "../../../shared/components/timestamp/verified-timestamp.model"; // TODO: (NGRX JC) Move
import { EmailGenerationAudit, FixtureType } from "../../shared/models";
import { BerthForm } from "../../state";

@Component({
    selector: "ops-berth-form",
    templateUrl: "./berth-form.component.html",
    styleUrls: ["./berth-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BerthFormComponent {
    readonly dateValueConverter = NgrxValueConverters.dateToISOString;
    FixtureType = FixtureType;

    @Input() form: FormGroupState<BerthForm>;
    @Input() readonly: boolean;
    @Input() etbVerifiedAudit: VerifiedTimestamp;
    @Input() locationTimezone: string;
    @Input() portTimesEmailAudit: Readonly<EmailGenerationAudit>;
    @Input() etbDefaultFocusDate: string;
    @Input() etbVerifyEnabled: boolean;

    @Output() readonly verifyEtb = new EventEmitter();
    @Output() readonly generatePortTimesEmail = new EventEmitter();
}
