import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { box, createFormGroupState, focus, updateGroup } from "ngrx-forms";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { CoaHttpService } from "src/app/coa/services/coa-http.service";
import { SeaNetLocation } from "src/app/fixture/shared/models";
import { coaStateReducer, currentCoaStateReducer } from "../../coa/reducer";
import { selectCurrentCoaId } from "../../coa/selectors";
import { CoaId, LocationsKey } from "../../model/coa";
import { ActivityType, LocationForm, toLocation } from "../../model/location";
import { CoaFeatureState, CoasState, LocationFormKey } from "../../model/state";
import { selectCurrentDischargeLocationFormValue, selectCurrentLoadLocationFormValue } from "../selectors";

/* ACTIONS */
export const addingLoadLocationAction = createAction("[Coa Locations] Adding Load Location");
export const addingDischargeLocationAction = createAction("[Coa Locations] Adding Discharge Location");

export const cancelLoadLocationAction = createAction("[Coa Location Form] Cancel Load Location");
export const cancelDischargeLocationAction = createAction("[Coa Location Form] Cancel Discharge Location");

const ADD_LOAD_LOCATION_ACTION_NAME = "[Coa Location Form] Add Load Location";
export const addLoadLocationAction = createAction(ADD_LOAD_LOCATION_ACTION_NAME);
export const addLoadLocationSuccessAction = createAction(`${ADD_LOAD_LOCATION_ACTION_NAME} Success`, props<{ coaId: CoaId; location: SeaNetLocation }>());
export const addLoadLocationFailAction = createAction(`${ADD_LOAD_LOCATION_ACTION_NAME} Fail`, props<{ coaId: CoaId; error: Error }>());

const ADD_DISCHARGE_LOCATION_ACTION_NAME = "[Coa Location Form] Add Discharge Location";
export const addDischargeLocationAction = createAction(ADD_DISCHARGE_LOCATION_ACTION_NAME);
export const addDischargeLocationSuccessAction = createAction(`${ADD_DISCHARGE_LOCATION_ACTION_NAME} Success`, props<{ coaId: CoaId; location: SeaNetLocation }>());
export const addDischargeLocationFailAction = createAction(`${ADD_DISCHARGE_LOCATION_ACTION_NAME} Fail`, props<{ coaId: CoaId; error: Error }>());

/* REDUCERS */
export const addingLocationReducer: On<CoasState> = on(addingLoadLocationAction, addingDischargeLocationAction, (state, { type }) => {
    const formKey: LocationFormKey = type === addingLoadLocationAction.type ? "loadLocationForm" : "dischargeLocationForm";

    return currentCoaStateReducer(state, (coaState) => {
        const initialForm = createFormGroupState(`${coaState.coa.coaId}.${formKey}`, { location: box(null) });
        const form = updateGroup<LocationForm>({ location: focus })(initialForm);

        return { ...coaState, [formKey]: form };
    });
});

export const cancelLocationReducer: On<CoasState> = on(cancelLoadLocationAction, cancelDischargeLocationAction, (state, { type }) => {
    const formKey: LocationFormKey = type === cancelLoadLocationAction.type ? "loadLocationForm" : "dischargeLocationForm";

    return currentCoaStateReducer(state, (coaState) => {
        return { ...coaState, [formKey]: null };
    });
});

export const addLocationSuccessReducer: On<CoasState> = on(addLoadLocationSuccessAction, addDischargeLocationSuccessAction, (state, { type, coaId, location }) => {
    const formKey: LocationFormKey = type === addLoadLocationSuccessAction.type ? "loadLocationForm" : "dischargeLocationForm";
    const locationsKey: LocationsKey = type === addLoadLocationSuccessAction.type ? "loadLocations" : "dischargeLocations";

    return coaStateReducer(state, coaId, (coaState) => {
        return {
            ...coaState,
            [formKey]: null,
            coa: {
                ...coaState.coa,
                [locationsKey]: [...coaState.coa[locationsKey], toLocation(location)]
            }
        };
    });
});

/* EFFECTS */
export const addLocationEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, coaHttpService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(addLoadLocationAction, addDischargeLocationAction),
            withLatestFrom(store.select(selectCurrentCoaId), store.select(selectCurrentLoadLocationFormValue), store.select(selectCurrentDischargeLocationFormValue)),
            switchMap(([{ type }, coaId, loadForm, dischargeForm]) => {
                const activityType: ActivityType = type === addLoadLocationAction.type ? "load" : "discharge";
                const successAction = type === addLoadLocationAction.type ? addLoadLocationSuccessAction : addDischargeLocationSuccessAction;
                const failAction = type === addLoadLocationAction.type ? addLoadLocationFailAction : addDischargeLocationFailAction;
                const location = type === addLoadLocationAction.type ? loadForm.location.value : dischargeForm.location.value;

                return coaHttpService.addLocation(coaId, activityType, location.locationId).pipe(
                    map(() => successAction({ coaId: coaId, location: location })),
                    catchError((error) => of(failAction({ coaId: coaId, error: error })))
                );
            })
        )
    );
