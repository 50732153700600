import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { combineLatest, BehaviorSubject, Subject } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";

import { Enumeration } from "../../reference-data/enumeration";
import { isNullOrUndefined } from "../../utils";

export class FieldEnumeration extends Enumeration {
    disabled: boolean;
}

@Component({
    selector: "ops-field-editor-dropdown",
    templateUrl: "./field-editor-dropdown.component.html",
    styleUrls: ["./field-editor-dropdown.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FieldEditorDropdownComponent,
            multi: true
        }
    ]
})
export class FieldEditorDropdownComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
    private readonly destroy$ = new Subject();
    private fields$ = new BehaviorSubject<Enumeration[]>([]);
    private prohibitedFieldIds$ = new BehaviorSubject<string[]>([]);

    disabled = false;
    fieldsInternal$ = new BehaviorSubject<FieldEnumeration[]>([]);
    selectedValue: number | string | null;

    @Input() set fields(value: Enumeration[]) {
        this.fields$.next(value);
    }

    @Input() set prohibitedFieldIds(value: string[]) {
        this.prohibitedFieldIds$.next(value);
    }

    @Input() readonly: boolean;
    @Input() placeholder: string;
    @Input() allowClear: boolean;
    @Input() bindValue: string;

    @Output() readonly focus = new EventEmitter();
    @Output() readonly blur = new EventEmitter();
    @Output() readonly change = new EventEmitter<Enumeration>();

    @ViewChild(NgSelectComponent, { static: true }) ngSelect: NgSelectComponent;

    get showClearButton() {
        // Display the clear button if allowClear is explicitly true or the ngrx form control is not marked as required
        return !this.disabled && !this.readonly && (!!this.allowClear || isNullOrUndefined(this.allowClear));
    }

    constructor(private el: ElementRef) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.el.nativeElement.focus = () => setTimeout(() => this.ngSelect.focus());

        if (!this.bindValue) {
            this.bindValue = "id";
        }

        combineLatest([this.fields$, this.prohibitedFieldIds$])
            .pipe(
                takeUntil(this.destroy$),
                map(([fields, prohibited]) =>
                    fields?.map(
                        (f) =>
                            <FieldEnumeration>{
                                id: f.id,
                                name: f.name,
                                disabled: !(this.selectedValue && f.id === this.selectedValue) && prohibited?.some((p) => p === f.id)
                            }
                    )
                ),
                tap((fields) => this.fieldsInternal$.next(fields))
            )
            .subscribe();
    }

    ngAfterViewInit() {
        this.ngSelect.focusEvent.subscribe(() => this.focus.emit());
        this.ngSelect.blurEvent.subscribe(() => this.blur.emit());
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    writeValue(value: any) {
        this.selectedValue = value && value[this.bindValue];
        this.ngSelect.writeValue(value ? this.selectedValue || value : value);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any) {
        this.ngSelect.registerOnChange((id: number | string | null) => {
            this.selectedValue = id;
            fn(id);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: any) {
        this.ngSelect.registerOnTouched(fn);
    }

    setDisabledState?(isDisabled: boolean) {
        this.ngSelect.setDisabledState(isDisabled);
    }
}
