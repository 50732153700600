import { isAcceptedOrPreferred, isFirmedVesselPlanStatus, Lifting, toSummaryDate, VesselHistoricalEvent, vesselIdEq } from "../model";

export type VesselNominationSummaryStatus = "Not Started" | "Tentative" | "Firmed";
export type VesselNominationSummaryDate = "Initial Nomination" | "Vessel Accepted";

export type VesselNominationSummary = Readonly<{
    status: VesselNominationSummaryStatus;
    dates: Readonly<{ [date in VesselNominationSummaryDate]: string }>;
}>;

export const getVesselNominationSummary = (lifting: Lifting, vesselNominationHistory: ReadonlyArray<VesselHistoricalEvent>): VesselNominationSummary => {
    const vesselIds = lifting.vessels.map((x) => x.vesselId);

    const initialNomination = vesselNominationHistory?.find((x) => x.name === "LiftingVesselNominationAddedV1" && vesselIds.includes(x.entityId));
    const vesselAccepted = vesselNominationHistory?.find(
        (x) => x.name === "LiftingVesselNominationAcceptedV1" && vesselIds.includes(x.entityId) && isAcceptedOrPreferred(lifting.vessels.find(vesselIdEq(x.entityId)))
    );

    return {
        status: !lifting.vessels.length ? "Not Started" : isFirmedVesselPlanStatus(lifting) ? "Firmed" : "Tentative",
        dates: {
            ["Initial Nomination"]: toSummaryDate(initialNomination),
            ["Vessel Accepted"]: toSummaryDate(vesselAccepted)
        }
    };
};
