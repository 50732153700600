import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { FixtureLoggingEffects } from "./fixture-logging.effects";
import { FixtureNotificationEffects } from "./fixture-notification.effects";
import { FixtureRoutingEffects } from "./fixture-routing.effects";
import { FixtureEffects } from "./fixture.effects";
import { reducer } from "./fixture.reducer";
import { fixturesFeatureKey } from "./fixture.selectors";

@NgModule({
    imports: [
        StoreModule.forFeature(fixturesFeatureKey, reducer),
        EffectsModule.forFeature([FixtureEffects, FixtureLoggingEffects, FixtureNotificationEffects, FixtureRoutingEffects]
    )]
})
export class FixtureStoreModule {
}
