import {
    FreightRateUnit as FreightRateUnitEnum,
    QuantityUnit as QuantityUnitEnum,
    ToleranceOption as ToleranceOptionEnum,
    ToleranceUnit as ToleranceUnitEnum
} from "@ops/shared/reference-data";

/* TYPES */
export const cargoQuantityUnits = ["Unspecified", "MT", "JAS CBM", "Lump Sum", "BBLS", "CBM"];
export type CoaCargoQuantityUnit = typeof cargoQuantityUnits[number];

export const cargoToleranceUnits = ["Unspecified", "Percentage" , "MT" , "CBM"];
export type CoaCargoToleranceUnit = typeof cargoToleranceUnits[number];

export const cargoToleranceOptions = ["Unspecified", "Owner" , "Charterer"];
export type CoaCargoToleranceOption = typeof cargoToleranceOptions[number];

export const cargoFreightRateUnits = ["Unspecified", "Per MT", "World Scale", "Lump Sum"] as const;
export type LiftingCargoFreightRateUnit = typeof cargoFreightRateUnits[number];

/* MAPPINGS */
const quantityUnitMap: { coaName: CoaCargoQuantityUnit, displayName: string }[] = [
    { coaName: "Unspecified", displayName: null },
    { coaName: "Unspecified", displayName: undefined },
    { coaName: "MT", displayName: QuantityUnitEnum.MT.name },
    { coaName: "JAS CBM", displayName: QuantityUnitEnum.JasCbm.name },
    { coaName: "Lump Sum", displayName: "Lump Sum" },
    { coaName: "BBLS", displayName: QuantityUnitEnum.BBLS.name },
    { coaName: "CBM", displayName: QuantityUnitEnum.CBM.name }
];
const toleranceUnitMap: { coaName: CoaCargoToleranceUnit, displayName: string }[] = [
    { coaName: "Unspecified", displayName: null },
    { coaName: "Unspecified", displayName: undefined },
    { coaName: "Percentage", displayName: ToleranceUnitEnum.Percentage.name },
    { coaName: "MT", displayName: ToleranceUnitEnum.MT.name },
    { coaName: "CBM", displayName: ToleranceUnitEnum.CBM.name }
];
const toleranceOptionMap: { coaName: CoaCargoToleranceOption, displayName: string }[] = [
    { coaName: "Unspecified", displayName: null },
    { coaName: "Unspecified", displayName: undefined },
    { coaName: "Owner", displayName: ToleranceOptionEnum.InOwnersOption.name },
    { coaName: "Charterer", displayName: ToleranceOptionEnum.InCharterersOption.name }
];
const freightRateUnitMap: { coaName: LiftingCargoFreightRateUnit, displayName: string }[] = [
    { coaName: "Unspecified", displayName: null },
    { coaName: "Unspecified", displayName: undefined },
    { coaName: "Per MT", displayName: FreightRateUnitEnum.PerMT.name },
    { coaName: "World Scale", displayName: FreightRateUnitEnum.Worldscale.name },
    { coaName: "Lump Sum", displayName: FreightRateUnitEnum.LumpSum.name }
];

const getDisplayName = <T>(map: { coaName: T, displayName: string }[], source: T): string => map.find((x) => x.coaName === source)?.displayName;
const getMappedCoaName = <T>(map: { coaName: T, displayName: string }[], source: string): T => map.find((x) => x.displayName === source)?.coaName;

export const quantityUnitToCoaType = (source: string): CoaCargoQuantityUnit => getMappedCoaName(quantityUnitMap, source);
export const coaTypeToQuantityUnit = (source: CoaCargoQuantityUnit): string => getDisplayName(quantityUnitMap, source);
export const toleranceUnitToCoaType = (source: string): CoaCargoToleranceUnit => getMappedCoaName(toleranceUnitMap, source);
export const coaTypeToToleranceUnit = (source: CoaCargoToleranceUnit): string => getDisplayName(toleranceUnitMap, source);
export const toleranceOptionToCoaType = (source: string): CoaCargoToleranceOption => getMappedCoaName(toleranceOptionMap, source);
export const coaTypeToToleranceOption = (source: CoaCargoToleranceOption): string => getDisplayName(toleranceOptionMap, source);
export const freightRateUnitToCoaType = (source: string): LiftingCargoFreightRateUnit => getMappedCoaName(freightRateUnitMap, source);
export const coaTypeToFreightRateUnit = (source: LiftingCargoFreightRateUnit): string => getDisplayName(freightRateUnitMap, source);
