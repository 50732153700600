<div class="person-container row" [ngClass]="{ 'expanded' : !hidePersonExtraInfo }">
    <div class="col-1 pl-0">
        <button
            (click)="togglePersonExtraInfo()"
            type="button"
            class="has-icon"
            [ngClass]="{ 'icon--arrow-drop-right': hidePersonExtraInfo, 'icon--arrow-drop-down': !hidePersonExtraInfo }"
            data-test-togglepeople
        ></button>
    </div>
    <div class="col-10 pl-0">
        <div class="person-header" (click)="togglePersonExtraInfo()">
            <span class="person-title" data-test-person-fullname
                >{{ person.firstName }}&nbsp;{{ person.lastName }}&nbsp;<span *ngIf="person.comments && person.comments.length > 0" class="pipe">|</span
                >&nbsp;<span *ngIf="person.comments && person.comments.length > 0" class="has-icon icon--note"></span></span
            >&nbsp;
        </div>
        <div class="person-subtitle" data-test-person-role>{{ personUtilities.getRoles(person) }}</div>
        <div class="person-subtitle-email" data-test-person-primary-email>
            <a href="mailto:{{ personUtilities.getPrimaryEmail(person) }}?subject={{ emailSubject }}" target="_top">{{
                personUtilities.getPrimaryEmail(person)
            }}</a>
        </div>
        <div class="person-subtitle" data-test-person-primary-phone>
            <a href="tel:{{ personUtilities.getPrimaryPhone(person) }}">{{ personUtilities.getPrimaryPhone(person) }}</a>
        </div>
    </div>
    <div class="delete-column col-1 pr-0 pl-0">
        <button (click)="removePerson(person)" type="button" class="btn-delete has-icon icon--delete float-right" data-test-removeperson></button>
    </div>
</div>

<div [hidden]="hidePersonExtraInfo" class="person-container-extra-info row">
    <div class="col-12 pl-0">
        <ng-container *ngIf="personUtilities.getPhoneNumberGroups(person).length > 0">
            <hr class="horizontal-line" />
            <div class="row" *ngFor="let phoneGroup of personUtilities.getPhoneNumberGroups(person)">
                <div class="col-6" *ngFor="let phone of phoneGroup">
                    <label>{{ phone.type }}</label>
                    <div class="phone-number" data-test-other-phones>
                        <a href="tel:{{ phone.number }}">{{ phone.number }}</a>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="row">
            <div class="col">
                <label>Personal Email</label>
                <div class="email" data-test-personal-email>
                    <a href="mailto:{{ personUtilities.getPersonalEmail(person) }}?subject={{ emailSubject }}" target="_top">{{
                        personUtilities.getPersonalEmail(person)
                    }}</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Other Email</label>
                <div class="email" *ngFor="let otherEmail of personUtilities.getOtherEmail(person)" data-test-other-email>
                    <a href="mailto:{{ otherEmail }}?subject={{ emailSubject }}" target="_top">{{ otherEmail }}</a>
                </div>
            </div>
        </div>
        <hr class="horizontal-line-clearer" />
        <div class="row">
            <div class="col-6">
                <label>ICE</label>
                <div class="email" *ngFor="let iceId of personUtilities.getIceIds(person)" data-test-ice-ids>{{ iceId }}</div>
            </div>
            <div class="col-6">
                <label>Skype</label>
                <div class="email" *ngFor="let skypeId of personUtilities.getSkypeIds(person)" data-test-skype-ids>{{ skypeId }}</div>
            </div>
        </div>
        <hr class="horizontal-line-clearer" />
        <div class="row comments-form" [formGroup]="commentsForm">
            <label>Notes</label>
            <textarea class="form-control" id="comments" name="comments" formControlName="comments" maxlength="3000" data-test-comments></textarea>
        </div>
    </div>
</div>
