import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { CoaHttpService, formToUpdateCoaDetails } from "src/app/coa/services/coa-http.service";
import { coaStateReducer, currentCoaStateReducer } from "../../coa/reducer";
import { selectCurrentCoaId } from "../../coa/selectors";
import { CoaHeaderForm, toUser } from "../../model";
import { Coa, CoaId } from "../../model/coa";
import { CoaFeatureState, CoasState } from "../../model/state";
import { selectCurrentCoaHeaderFormValue } from "../selectors";

/* ACTIONS */
const SAVE_ACTION_NAME = "[Coa Header Form] Save Coa Header";

export const saveCoaHeaderAction = createAction(SAVE_ACTION_NAME);
export const saveCoaHeaderSuccessAction = createAction(`${SAVE_ACTION_NAME} Success`, props<{ coaId: CoaId; data: CoaHeaderData }>());
export const saveCoaHeaderFailAction = createAction(`${SAVE_ACTION_NAME} Fail`, props<{ coaId: CoaId; error: Error }>());

/* REDUCERS */
export const saveCoaHeaderReducer: On<CoasState> = on(saveCoaHeaderAction, (state) => currentCoaStateReducer(state, { persistenceStatus: "persisting" }));
export const saveCoaHeaderSuccessReducer: On<CoasState> = on(saveCoaHeaderSuccessAction, (state, { data, coaId }) =>
    coaStateReducer(state, coaId, (coaState) => {
        return {
            ...coaState,
            persistenceStatus: "persisted",
            coaHeaderForm: null,
            coa: { ...coaState.coa, ...data }
        };
    })
);
export const saveCoaHeaderFailReducer: On<CoasState> = on(saveCoaHeaderFailAction, (state, { coaId }) => coaStateReducer(state, coaId, { persistenceStatus: "failed" }));

/* EFFECTS */
export type CoaHeaderData = Pick<Coa, "contractStatus" | "cancellationReason" | "currency" | "charterpartyDate" | "operators" | "driver">;

export const formToCoaHeaderData = (source: CoaHeaderForm): CoaHeaderData => ({
    charterpartyDate: source.charterpartyDate,
    driver: source.driver,
    contractStatus: source.contractStatus,
    currency: source.currency,
    cancellationReason: source.cancellationReason,
    operators: source.operators.value.map(toUser)
});

export const saveCoaHeaderEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, coaHttpService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveCoaHeaderAction),
            withLatestFrom(store.select(selectCurrentCoaId), store.select(selectCurrentCoaHeaderFormValue)),
            switchMap(([, coaId, form]) =>
                coaHttpService.updateDetails(coaId, formToUpdateCoaDetails(form)).pipe(
                    map(() => saveCoaHeaderSuccessAction({ coaId: coaId, data: formToCoaHeaderData(form) })),
                    catchError((error) => of(saveCoaHeaderFailAction({ coaId: coaId, error: error })))
                )
            )
        )
    );
