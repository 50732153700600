import { Component, Input } from "@angular/core";

import { MaritechIcon } from "@ops/shared";

@Component({
    selector: "ops-icon-button",
    template: `
        <button
            class="has-icon icon--{{ icon || type }}"
            type="button"
            [disabled]="disabled"
            [ngbTooltip]="tooltip"
            [placement]="tooltipPlacement"
            [container]="tooltipContainer"
            [class.toggled]="toggled"
        >
            <ng-content></ng-content>
        </button>
    `,
    styleUrls: ["icon-button.component.scss"]
})
export class IconButtonComponent {
    @Input() icon: MaritechIcon;
    @Input() tooltip: string;
    @Input() tooltipPlacement = "auto";
    @Input() disabled = false;
    @Input() tooltipContainer: "" | "body" = "";
    @Input() type: "edit" | "clone" | "done" | "cancel" | "feed" | "delete" = "edit";
    /**
     * Sets an active/inactive state which can influence the presentation of the component with the 'toggled' class
     */
    @Input() toggled = false;
}
