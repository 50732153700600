import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    selector: "ops-invoice-summary",
    templateUrl: "./invoice-summary.component.html",
    styleUrls: ["./invoice-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceSummaryComponent {
    static componentName = "InvoiceSummaryComponent";

    @Input() invoiceNumber: string;
    @Input() receivedFromOwnerDate: Date;
    @Input() coveringPeriod: string;
    @Input() showCoveringPeriod: boolean;
    @Input() value: string;
    @Input() status: string;
}
