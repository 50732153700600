<div class="voyage-tile-container" [ngClass]="{ invalid: voyage.isValid === false, closed: voyage.isClosed, selected: voyage.isSelected == true }" (click)="voyageClicked($event)">
    <div class="row ">
        <span class="col-12 title-voyageNumber">
            <span *ngIf="voyage.isValid === false" class="has-icon icon--warning" ngbTooltip="There are validation errors on this voyage" container="body" placement="left"> </span>
            <span class="voyage-ref"> {{ voyageNumber$ | async }}<span *ngIf="voyage.isClosed">- Closed</span> </span>
        </span>
    </div>
    <hr />
    <div class="row">
        <div class="ellipsis-text voyage-cargo" placement="bottom" [ngbTooltip]="voyage.cargoNames && voyage.cargoNames.length > 50 ? voyage.cargoNames : ''">
            {{ voyage.cargoNames }}
        </div>
    </div>
    <div class="row">
        <span class="col-6 ellipsis-text" [ngbTooltip]="voyage.loads && voyage.loads.length > 20 ? voyage.loads : ''" *ngIf="voyage.loads">(L) {{ voyage.loads }} </span>
        <span class="col-6 ellipsis-text" [ngbTooltip]="voyage.discharges && voyage.discharges.length > 20 ? voyage.discharges : ''" *ngIf="voyage.discharges"
            >(D) {{ voyage.discharges }}
        </span>
    </div>
    <div class="row" *ngIf="voyage.nextEtaDate && voyage.nextEtaPort">
        <span class="col-5 event-location"
            ><span class="event-title">ETA</span> <span>({{ voyage.nextEtaPort }}): </span></span
        >
        <span>{{ voyage.nextEtaDate }} </span>
    </div>
    <div class="row" *ngIf="voyage.nextEtbDate && voyage.nextEtbPort">
        <span class="col-5 event-location">
            <span class="event-title">ETB</span> <span>({{ voyage.nextEtbPort }}): </span></span
        >
        <span> {{ voyage.nextEtbDate }} </span>
    </div>
    <div class="scroll-anchor"></div>
</div>
