import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { FormState, ValidationErrors } from "ngrx-forms";
import * as R from "ramda";
import { merge, Subject } from "rxjs";
import { distinctUntilChanged, map, takeUntil } from "rxjs/operators";

export type ValidationTextFn = (validationError?: ValidationErrors) => string;
export type ValidationTextFns = Record<string, ValidationTextFn>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Control = FormState<any> | AbstractControl;

@Component({
    selector: "ops-validation",
    templateUrl: "./validation.component.html",
    styleUrls: ["./validation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationComponent implements OnInit, OnDestroy, DoCheck {
    private readonly destroy$ = new Subject();
    private control: Control;
    private textFns: ValidationTextFns = {
        // ngrx and ng forms errors
        required: () => "Required",
        email: () => "Invalid email address",
        // ngrx forms errors
        $date: (errors) => `Invalid ${errors?.$date}`,
        date: (errors) => `Invalid ${errors?.date}`,
        greaterThan: (errors) => `Must be greater than ${errors?.greaterThan?.comparand}`,
        greaterThanOrEqualTo: (errors) => `Must be greater than or equal to ${errors?.greaterThanOrEqualTo?.comparand}`,
        lessThan: (errors) => `Must be less than ${errors?.lessThan?.comparand}`,
        lessThanOrEqualTo: (errors) => `Must be less than or equal to ${errors?.lessThan?.comparand}`,
        inclusiveBetween: (errors) => `Must be between ${errors?.inclusiveBetween?.min} and ${errors?.inclusiveBetween?.max}`,
        // ng forms errors
        min: (errors) => `Must be greater than or equal to ${errors?.min.min}`,
        max: (errors) => `Must be less than or equal to ${errors?.max.max}`,
        maxlength: (errors) => `Text is too long. Max characters: ${errors?.maxlength.requiredLength}`,
        minlength: (errors) => `Text is too short. Min characters: ${errors?.minlength.requiredLength}`,
        // custom errors
        invalidRange: () => "Invalid date range",
        // no errors
        noErrors: () => ""
    };

    validationText = "";

    @Input() showBeforeTouch = true;
    @Input() set form(form: Control) {
        this.control = form;
        this.updateText();
    }
    @Input() set upsertTextFns(validationTextFns: ValidationTextFns) {
        this.textFns = { ...this.textFns, ...validationTextFns };
    }

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngDoCheck() {
        // ng forms does not have functionality to observe form "touch" changes
        // that's why we need to update this component together with parent component
        // to synchronize field styles(e.g. red borders) with validation text
        if (this.control instanceof AbstractControl && !this.showBeforeTouch) {
            this.changeDetector.detectChanges();
        }
    }

    ngOnInit() {
        if (this.control instanceof AbstractControl) {
            merge(this.control.statusChanges, this.control.valueChanges)
                .pipe(
                    map(() => this.control.errors),
                    distinctUntilChanged(R.equals),
                    takeUntil(this.destroy$)
                )
                .subscribe(() => this.updateText());
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    updateText() {
        const errors = this.control?.errors ?? {};
        const errorName = Object.keys(errors).find((key) => this.textFns.hasOwnProperty(key));
        this.validationText = this.textFns[errorName ?? "noErrors"](errors);
        this.changeDetector.detectChanges();
    }

    get showError() {
        return !!this.validationText && this.isValid === false && (this.showBeforeTouch || this.isTouched);
    }

    private get isValid() {
        if (!this.control) {
            return;
        }

        return this.control instanceof AbstractControl ? this.control.valid : this.control.isValid;
    }

    private get isTouched() {
        if (!this.control) {
            return;
        }

        return this.control instanceof AbstractControl ? this.control.touched : this.control.isTouched;
    }
}
