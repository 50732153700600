import { Injectable } from "@angular/core";
import { CommandHandler } from "src/app/fixture/mediator";
import { Voyage } from "src/app/fixture/shared/models";
import { getActivity } from "src/app/shared/voyage-utils/voyage-utilities";
import { RemoveLaytimeEventFactCommand } from "./remove-laytime-event-fact.command";

@Injectable({
    providedIn: "root"
})
export class RemoveLaytimeEventFactCommandHandler implements CommandHandler {
    handle(voyage: Voyage, command: RemoveLaytimeEventFactCommand) {
        const activityToUpdate = getActivity(voyage, command.destinationId, command.berthId, command.berthActivityId);

        activityToUpdate.laytimeEventFacts.splice(command.index, 1);
        voyage.isDirty = true;
    }
}
