import { ColorType } from "./color-type.model";
import { Color } from "./color.model";

export class ColorService {
    static getColors(): Color[] {
        return [
            {
                type: ColorType.Pink,
                colorClass: "color-clarksons-pink"
            },
            {
                type: ColorType.Blue,
                colorClass: "color-clarksons-blue"
            },
            {
                type: ColorType.Purple,
                colorClass: "color-clarksons-purple"
            },
            {
                type: ColorType.Teal,
                colorClass: "color-clarksons-teal"
            },
            {
                type: ColorType.PinkDarker,
                colorClass: "color-clarksons-pink-darker"
            },
            {
                type: ColorType.Yellow,
                colorClass: "color-clarksons-yellow"
            },
            {
                type: ColorType.BlueDarker,
                colorClass: "color-clarksons-blue-darker"
            }
        ];
    }
}
