import moize from "moize";
import { disable, enable, FormGroupState, setValue, StateUpdateFns, unbox, updateArray, updateGroup, validate } from "ngrx-forms";
import { greaterThanOrEqualTo } from "ngrx-forms/validation";

import { isNumber } from "@ops/shared";
import { FreightType, QuantityUnit } from "@ops/shared/reference-data";
import { hasValue, validateRequired, validateRequiredIf, validateRequiredIfDependants } from "@ops/state";

import { FixtureType } from "../../../shared/models";
import { AssociatedCargoExpenseUnit } from "../../../shared/models/enums/associated-cargo-expense-unit";
import {
    ActivityType,
    AssociatedCargoExpenseForm,
    AssociatedCargoForm,
    FixtureState,
    isFixtureFreightType,
    isFixtureType,
    isTemplateExpenseRecord,
    VoyageForm,
    VoyageState
} from "../../model";

export const validateAssociatedCargoExpenseForm = (form: FormGroupState<AssociatedCargoExpenseForm>) => {
    const conditionalUpdateFns: StateUpdateFns<AssociatedCargoExpenseForm>[] = [];
    const isMT = isExpenseUnit(form, AssociatedCargoExpenseUnit.PerMT);
    const isLumpSum = isExpenseUnit(form, AssociatedCargoExpenseUnit.LumpSum);

    if (isLumpSum) {
        conditionalUpdateFns.push({
            freightRate: setValue<number>(null)
        });
    }

    return updateGroup<AssociatedCargoExpenseForm>(
        {
            unit: validateRequiredIfDependants([form.controls.value]),
            value: validateRequiredIfDependants([form.controls.unit]),
            rateDescription: validateRequiredIfDependants([form.controls.value, form.controls.unit, form.controls.freightRate]),
            freightRate: validateRequiredIf(isMT)
        },
        {
            freightRate: isLumpSum ? disable : enable
        },
        ...conditionalUpdateFns
    )(form);
};

export const validateAssociatedCargoForm = (voyageState: VoyageState, fixtureState: FixtureState, activityType: ActivityType) => (form: FormGroupState<AssociatedCargoForm>) => {
    const conditionalUpdateFns: StateUpdateFns<AssociatedCargoForm>[] = [];

    const isMT = isQuantityUnit(form, QuantityUnit.MT);
    const isCbm = !isMT && isQuantityUnit(form, QuantityUnit.CBM);
    const isJasCbm = !isCbm && isQuantityUnit(form, QuantityUnit.JasCbm);
    const isLumpSum = !isJasCbm && isQuantityUnit(form, QuantityUnit.LumpSum);

    // TODO: (NGRX JC) Pattern for fields calculations that don't live in validation functions
    if (isMT || isLumpSum) {
        conditionalUpdateFns.push({
            mt: setValue(isMT ? form.value.quantity : null)
        });
    } else if (form.controls.specificGravity) {
        const mt =
            hasValue(form.value.specificGravity) && isCbm
                ? // eslint-disable-next-line no-magic-numbers
                  (form.value.quantity * form.value.specificGravity) / 1000
                : null;

        conditionalUpdateFns.push({
            mt: setValue(mt)
        });
    }

    if (isFixtureFreightType(fixtureState, FreightType.LumpSum) && isNumber(fixtureState.workingFixture?.lumpsumValue)) {
        const totalQuantity = getTotalQuantity(voyageState.form.value, activityType);
        const calculatedFreightRate = totalQuantity ? (getQuantity(form.value) / totalQuantity) * fixtureState.workingFixture.lumpsumValue : null;
        conditionalUpdateFns.push({
            freightRate: setValue(calculatedFreightRate)
        });
    }

    if (!(form.value.associatedCargoExpenses.length === 1 && isTemplateExpenseRecord(form.value.associatedCargoExpenses[0]))) {
        conditionalUpdateFns.push({
            associatedCargoExpenses: updateArray<AssociatedCargoExpenseForm>(validateAssociatedCargoExpenseForm)
        });
    }

    if (isFixtureType(fixtureState, FixtureType.Voyage)) {
        conditionalUpdateFns.push({
            cargoId: validateRequired()
        });
    }

    return updateGroup<AssociatedCargoForm>(
        form,
        {
            quantityUnit: validateRequiredIfDependants([form.controls.quantity]),
            specificGravity: validateRequiredIf(isQuantityUnit(form, QuantityUnit.CBM), greaterThanOrEqualTo(0)),
            mt: validateRequiredIf(isJasCbm),
            quantity: validate(greaterThanOrEqualTo(0))
        },
        {
            specificGravity: isCbm ? enable : disable,
            mt: isJasCbm ? enable : disable
        },
        ...conditionalUpdateFns
    );
};

export const validateAssociatedCargoesForm = (voyageState: VoyageState, fixtureState: FixtureState, activityType: ActivityType) =>
    updateArray<AssociatedCargoForm>(validateAssociatedCargoForm(voyageState, fixtureState, activityType));

const isQuantityUnit = (form: FormGroupState<AssociatedCargoForm>, quantityUnit: QuantityUnit): boolean =>
    form.value.quantityUnit && form.value.quantityUnit.value && form.value.quantityUnit.value.id === quantityUnit.id;

const isExpenseUnit = (expense: FormGroupState<AssociatedCargoExpenseForm>, expenseUnit: AssociatedCargoExpenseUnit): boolean =>
    expense.value.unit && expense.value.unit.value && expense.value.unit.value.id === expenseUnit;

const getQuantity = (x: AssociatedCargoForm) => (isNumber(x.mt) ? x.mt : x.quantity);

const getTotalQuantity = moize((voyageForm: VoyageForm, activityType: ActivityType) => {
    let total = 0;

    for (const destination of voyageForm.destinations) {
        for (const berth of destination.berths) {
            for (const activity of berth.activities) {
                const activityActivityType = unbox(activity.type);

                if (!activityActivityType || activityActivityType.id !== activityType.id) {
                    continue;
                }

                for (const cargo of activity.associatedCargoes) {
                    const hasMt = isNumber(cargo.mt);
                    const hasQuantity = isNumber(cargo.quantity);

                    if (hasMt || hasQuantity) {
                        total += hasMt ? cargo.mt : cargo.quantity;
                    }
                }
            }
        }
    }

    return total;
});
