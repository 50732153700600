import { hasValue, validateRequired, validateRequiredIf, validQuantityModel } from "@ops/state";
import { FormGroupState, unbox, updateGroup, validate, ValidationErrors } from "ngrx-forms";
import { greaterThanOrEqualTo } from "ngrx-forms/validation";
import { CargoForm, quantityUnitToCoaType, toleranceUnitToCoaType } from "../../model";

export interface CoaQuantityUnitIncompatibleError {
    quantityUnit: string;
    actual: string;
}

// @ts-ignore
declare module "ngrx-forms/src/state" {
    export interface ValidationErrors {
        coaQuantityUnitIncompatible?: CoaQuantityUnitIncompatibleError;
    }
}

export const validateCoaCargoForm = (form: FormGroupState<CargoForm>) => {
    const hasMinToleranceValue = hasValue(form.value.minTolerance);
    const hasMaxToleranceValue = hasValue(form.value.maxTolerance);
    const anyToleranceFieldSpecified = hasValue(form.value.toleranceOption) || hasValue(form.value.toleranceUnit) || hasMinToleranceValue || hasMaxToleranceValue;
    const quantityUnit = unbox(form.value.quantity).unit;

    return updateGroup<CargoForm>({
        cargoId: validateRequired(),
        cargoProduct: validateRequired(),
        quantity: validate(validQuantityModel),
        minTolerance: validateRequiredIf(anyToleranceFieldSpecified, greaterThanOrEqualTo(0)),
        maxTolerance: validateRequiredIf(anyToleranceFieldSpecified, greaterThanOrEqualTo(0)),
        toleranceUnit: validateRequiredIf(anyToleranceFieldSpecified, compatibleCoaToleranceUnit(quantityUnit)),
        toleranceOption: validateRequiredIf(anyToleranceFieldSpecified)
    })(form);
};

/**
 * Validates the tolerance unit must be compatible with the quantity unit.
 */
export const compatibleCoaToleranceUnit = (quantityUnit: string) => (toleranceUnit: string): ValidationErrors => {
    if (!quantityUnit || !toleranceUnit) {
        return {};
    }

    const coaQuantityUnit = quantityUnitToCoaType(quantityUnit);
    const coaToleranceUnit = toleranceUnitToCoaType(toleranceUnit);

    if (
        (coaQuantityUnit === "Lump Sum" && coaToleranceUnit === "CBM") ||
        (coaQuantityUnit === "Lump Sum" && coaToleranceUnit === "MT") ||
        (coaQuantityUnit === "MT" && coaToleranceUnit === "CBM")
    ) {
        return {
            coaQuantityUnitIncompatible: {
                quantityUnit: quantityUnit,
                actual: toleranceUnit
            }
        };
    }

    return {};
};
