import { createAction, On, on, props } from "@ngrx/store";
import { moveArrayControl, updateGroup } from "ngrx-forms";

import { Voyage } from "../../../shared/models";
import { DestinationId, FixturesState, formToVoyage, VoyageForm } from "../../model";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const moveDestinationUpAction = createAction("[Voyage Form] Move Destination Up", props<{ destinationId: DestinationId }>());
export const moveDestinationDownAction = createAction("[Voyage Form] Move Destination Down", props<{ destinationId: DestinationId }>());

/* REDUCERS */
const updateAtSeaConsumptions = (voyage: Voyage, index: number, offset: number) => {
    const fromId = voyage.destinations[offset < 0 ? index - 1 : index].id;
    const toId = voyage.destinations[offset > 0 ? index + 1 : index].id;

    if (!voyage.atSeaBunkersConsumption) {
        return voyage;
    }

    const atSeaBunkersConsumption = [...voyage.atSeaBunkersConsumption];

    atSeaBunkersConsumption.forEach((bunkerConsumption, i) => {
        // before
        if (bunkerConsumption.destinationToId === fromId) {
            atSeaBunkersConsumption[i] = {
                ...bunkerConsumption,
                destinationToId: toId
            };
        }

        // middle
        if (bunkerConsumption.destinationFromId === fromId && bunkerConsumption.destinationToId === toId) {
            atSeaBunkersConsumption[i] = {
                ...bunkerConsumption,
                destinationFromId: toId,
                destinationToId: fromId
            };
        }

        // after
        if (bunkerConsumption.destinationFromId === toId) {
            atSeaBunkersConsumption[i] = {
                ...bunkerConsumption,
                destinationFromId: fromId
            };
        }
    });

    return {
        ...voyage,
        atSeaBunkersConsumption
    };
};

const moveDestination = (state: FixturesState, destinationId: DestinationId, offset: number) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const destinations = voyageState.form.value.destinations;
        const fromIndex = destinations.map((x) => x.id).indexOf(destinationId);
        const toIndex = fromIndex + offset;

        if (toIndex < 0 || toIndex > destinations.length - 1) {
            return voyageState;
        }

        const workingVoyage = formToVoyage(voyageState.form.value, voyageState.workingVoyage || voyageState.voyage);

        return {
            ...voyageState,
            workingVoyage: updateAtSeaConsumptions(workingVoyage, fromIndex, offset),
            form: updateGroup<VoyageForm>({
                destinations: moveArrayControl(fromIndex, toIndex)
            })(voyageState.form)
        };
    });

export const moveDestinationUpReducer: On<FixturesState> = on(moveDestinationUpAction, (state, { destinationId }) => moveDestination(state, destinationId, -1));

export const moveDestinationDownReducer: On<FixturesState> = on(moveDestinationDownAction, (state, { destinationId }) => moveDestination(state, destinationId, 1));
