import { ColDef } from "@ag-grid-community/all-modules";
import { Injectable } from "@angular/core";

import { IGridConfigurationService } from "../../shared/grid";
import { AnchorRendererComponent } from "../components/calculation-grid/cell-renderers/anchor-renderer.component";

@Injectable({
    providedIn: "root"
})
export class LaytimeCalculationGridConfigurationService implements IGridConfigurationService {
    getColumnDefinitions(): ColDef[] {
        return [
            { headerName: "Fixture Reference", field: "fixtureReference", type: "anchor" },
            { headerName: "CP Date", field: "charterPartyDate", type: "anchor" },
            { headerName: "Name", field: "name", type: "anchor" },
            { headerName: "Last Updated At", field: "lastUpdatedAt", type: "anchor" },
            { headerName: "Last Updated By", field: "lastUpdatedBy", type: "anchor" },
            { headerName: "Operator", field: "operators", type: "anchor" },
            { headerName: "Vessel Name", field: "vesselName", type: "anchor" },
            { headerName: "Charterer", field: "chartererName", type: "anchor" },
            { headerName: "Cargoes", field: "cargoNames", type: "anchor" },
            { headerName: "Locations", field: "activityLocationNames", type: "anchor" },
            { headerName: "Currency", field: "currency", type: "anchor" },
            { headerName: "Comments", field: "comments", type: "anchor" }
        ];
    }

    getColumnTypes() {
        return {
            anchor: {
                cellRendererFramework: AnchorRendererComponent
            }
        };
    }
}
