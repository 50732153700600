import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ChangeDetectionStrategy, Component } from "@angular/core";

import { LaytimeCalculationGridRow } from "../../../state";

@Component({
    template: '<a [routerLink]="link" queryParamsHandling="merge" class="grid-link">{{ text }}&nbsp;</a>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnchorRendererComponent implements ICellRendererAngularComp {
    link: unknown[];
    text: string;

    agInit(params: ICellRendererParams) {
        const gridRow: LaytimeCalculationGridRow = params.data;

        this.link = ["/", { outlets: { primary: ["ltc", gridRow.id], toolbar: null } }];
        this.text = params.value ?? "";
    }

    refresh() {
        return false;
    }
}
