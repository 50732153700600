<div class="ops-card">
    <div class="ops-card-body">
        <table class="table ops-table">
            <thead>
                <tr>
                    <th class="location-column">Port</th>
                    <th class="cargo-name-column">Cargo</th>
                    <th class="activity-column">Activity</th>
                    <th class="event-column">Event</th>
                    <th class="laytime-column">Laytime{{isFixed ? " Used" : ""}}</th>
                    <th *ngIf="hasDemurrage && !isFixed">Demurrage</th>
                    <th *ngIf="hasDespatch && !isFixed">Despatch</th>
                    <th *ngIf="hasDetention && !isFixed">Detention</th>
                </tr>
            </thead>
            <tbody *ngIf="locations.length; else noLocations" class="form-group">
                <a *ngFor="let location of locations"
                   [routerLink]="['/', { outlets: { primary: ['ltc', ltcId, 'locations', location.id], toolbar: null } }]"
                   queryParamsHandling="merge"
                   class="link-row hover-indicator-row"
                >
                    <td>
                        <div class="location-info">
                            <img class="ops-location-flag" *ngIf="location.flagImgUrl" [src]="location.flagImgUrl" data-test-flag-image  [attr.alt]="location.countryName + ' flag image'"/>
                            <span class="location-name" data-cy="port">{{ location.locationName }}</span>
                        </div>
                    </td>
                    <td data-cy="cargo">{{location.cargoName}}</td>
                    <td data-cy="activity-type">{{location.activity}}</td>
                    <td>
                        <div class="laytime-event" *ngIf="location.startStopLaytimeEvents.start as start">
                            <span class="name" data-cy="event-type">{{start.type}}</span>
                            <span *ngIf="start.type && start.date"> : </span>
                            <span class="date" data-cy="event-date">{{start.date | maritimeDate}}</span>
                        </div>
                        <div class="laytime-event" *ngIf="location.startStopLaytimeEvents.stop as stop">
                            <span class="name" data-cy="event-type">{{stop.type}}</span>
                            <span *ngIf="stop.type && stop.date"> : </span>
                            <span class="date" data-cy="event-date">{{stop.date | maritimeDate}}</span>
                        </div>
                    </td>
                    <td class="laytime-cell">
                        <div data-cy="fixed-totals" class="ops-values-wrapper" *ngIf="isFixed">
                            <div class="ops-ltc-result-value" *ngIf="hasValue(location.laytimeUsed)">
                                <span class="value" data-cy="laytime-used-location">{{location.laytimeUsed | durationFormat: durationUnit}}</span>
                            </div>
                        </div>
                        <div data-cy="non-reversible-totals" class="ops-values-wrapper" *ngIf="!isFixed && location.hasNonReversible">
                            <div data-cy="reversible" class="ops-icon-wrapper">
                                <ops-description-icon *ngIf="location.activity !== 'Transit'" type="Non Reversible"></ops-description-icon>
                            </div>
                            <div data-cy="ltc-result" class="ops-ltc-result">
                                <div class="ops-ltc-result-value" *ngIf="hasValue(location.laytimeAllowed)">
                                    <span class="label">Allowed:</span>
                                    <span class="value" data-cy="laytime-allowed-location">{{location.laytimeAllowed | durationFormat: durationUnit}}</span>
                                </div>
                                <div class="ops-ltc-result-value" *ngIf="hasValue(location.laytimeUsed)">
                                    <span class="label">Used:</span>
                                    <span class="value" data-cy="laytime-used-location">{{location.laytimeUsed | durationFormat: durationUnit}}</span>
                                </div>
                                <div class="ops-ltc-result-value" *ngIf="hasValue(location.laytimeRemaining)">
                                    <span class="label">Remaining:</span>
                                    <span class="value" [class.negative]="+location.laytimeRemaining < 0" data-cy="laytime-remaining-location">{{location.laytimeRemaining | durationFormat: durationUnit}}</span>
                                </div>
                            </div>
                        </div>
                        <div data-cy="reversible-totals" class="ops-values-wrapper" *ngIf="!isFixed && location.hasReversible">
                            <div data-cy="reversible" class="ops-icon-wrapper">
                                <ops-description-icon *ngIf="location.activity !== 'Transit'" type="Reversible"></ops-description-icon>
                            </div>
                            <div data-cy="ltc-result" class="ops-ltc-result">
                                <div class="ops-ltc-result-value" *ngIf="hasValue(location.claimValue) && !location.hasNonReversible">
                                    <span class="label">{{location.claimType}} Value:</span>
                                    <span class="value" data-cy="claim-value-location">{{location.claimValue | currency: currency}}</span>
                                </div>
                                <div class="ops-ltc-result-value" *ngIf="hasValue(location.reversibleLaytimeAllowed)">
                                    <span class="label">Allowed:</span>
                                    <span class="value" data-cy="laytime-allowed-location">{{location.reversibleLaytimeAllowed | durationFormat: durationUnit}}</span>
                                </div>
                                <div class="ops-ltc-result-value" *ngIf="hasValue(location.reversibleLaytimeUsed)">
                                    <span class="label">Used:</span>
                                    <span class="value" data-cy="laytime-used-location">{{location.reversibleLaytimeUsed | durationFormat: durationUnit}}</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="hasDemurrage && !isFixed" class="demurrage-cell" data-cy="demurrage-column">
                        <div *ngIf="location.hasNonReversible" class="ops-values-wrapper non-reversible-block">
                            <div>
                                <span *ngIf="location.claimType === 'Demurrage'">
                                    {{location.claimValue | currency: currency}}
                                </span>
                            </div>
                            <div></div>
                            <div></div>
                        </div>
                        <div *ngIf="location.hasReversible" class="ops-values-wrapper reversible-block">
                            <div>
                                <span *ngIf="claimType === 'Demurrage' && location.id === lastReversibleLocationId">
                                    {{claimValue | currency: currency}}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="hasDespatch && !isFixed" class="despatch-cell"  data-cy="despatch-column">
                        <div *ngIf="location.hasNonReversible" class="ops-values-wrapper non-reversible-block">
                            <div>
                                <span *ngIf="location.claimType === 'Despatch'">
                                    {{location.claimValue | currency: currency}}
                                </span>
                            </div>
                            <div></div>
                            <div></div>
                        </div>
                        <div *ngIf="location.hasReversible" class="ops-values-wrapper reversible-block">
                            <div>
                                <span *ngIf="claimType === 'Despatch' && location.id === lastReversibleLocationId">
                                    {{claimValue | currency: currency}}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="hasDetention && !isFixed" class="detention-cell">
                        <div *ngIf="location.hasNonReversible" class="ops-values-wrapper non-reversible-block">
                            <div>
                                <span *ngIf="location.claimType === 'Detention'">
                                    {{location.claimValue | currency: currency}}
                                </span>
                            </div>
                            <div></div>
                            <div></div>
                        </div>
                        <div *ngIf="location.hasReversible" class="ops-values-wrapper"></div>
                    </td>
                </a>
            </tbody>
        </table>
    </div>
</div>

<ng-template #noLocations>
    <tbody class="form-group">
    <tr class="no-locations">
        <td colspan="5" class="no-locations-text">
            <div class="left">
                <span class="has-icon icon--lightbulb-outline"></span>
                <span data-cy="empty-summary-message">No locations are currently added to this calculation</span>
            </div>
        </td>
    </tr>
    </tbody>
</ng-template>
