<ng-select
    [items]="suggestions$ | async"
    [readonly]="readonly || disabled"
    [loading]="isLoading$ | async"
    [(ngModel)]="selection"
    [selectOnTab]="true"
    bindLabel="name"
    (change)="onSelectionChange($event)">
    <ng-template ng-label-tmp let-item="item">
        <span container="body" [ngbTooltip]="item.name" class="ng-value-label">
            {{ item.name }}
        </span>
        <button
            type="button"
            class="ng-value-icon has-icon icon--cancel"
            (click)="onClear(item)"
            (keyup.enter)="onClear(item)"
            aria-hidden="true"
        ></button>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <span [ngOptionHighlight]="search">{{ item.name }}</span>
    </ng-template>
</ng-select>
