import { Injectable } from "@angular/core";
import { DateTime } from "luxon";

import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { FixtureStatusType } from "../../../../../left-bar/shared/models";
import { Fixture, FixtureType, Voyage } from "../../../models";
import { FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class EtaOpenFixtureRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();
        if (fixture.fixtureStatus.id === FixtureStatusType.Open && fixture.fixtureType.id === FixtureType.Voyage) {
            const daysDifferenceAllowed = 90;

            voyages.forEach((voyage) => {
                const dischargeLocations = voyage.destinations.filter(
                    (d) => d.berths.filter((b) => b.cargoBerthActivities.filter((a) => a.type && a.type.id === CargoBerthActivityType.Discharge.id).length).length
                );
                if (dischargeLocations.length) {
                    const lastDischargeLocation = dischargeLocations[dischargeLocations.length - 1];
                    const timezone = lastDischargeLocation.location && lastDischargeLocation.location.timeZone ? lastDischargeLocation.location.timeZone : "utc";
                    const eta = lastDischargeLocation.etaRange && lastDischargeLocation.etaRange.to && DateTime.fromISO(lastDischargeLocation.etaRange.to, { zone: timezone });
                    const now = DateTime.local();

                    if (eta && now.diff(eta, "days").days >= daysDifferenceAllowed) {
                        const message =
                            "It has been longer than 90 days since the ETA of the last discharge port and the fixture is still open. " +
                            "Please check and close the fixture if necessary.";
                        const path = [FixtureWarningPathSegment.fromProperty<Fixture>("fixtureStatus", "Fixture Status")];
                        // Intentionally not set dismissing record here because the warning cannot be dismissed for Voyage and doesn't appear for TimeCharter
                        warnings.push(new FixtureWarning("eta", message, path));
                    }
                }
            });
        }
        return warnings;
    }
}
