import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { DateTime } from "luxon";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { AppConfigService, FeatureToggleService } from "@ops/core";
import { SimpleChanges } from "@ops/shared";

import { ContactService } from "../../../app/left-bar/contacts";
import { CargoBerthActivityType, FixtureType } from "../../shared/reference-data";
import { CompanyType, Fixture, FixtureSource, Voyage } from "../shared/models";

@Component({
    selector: "ops-summary",
    templateUrl: "./summary.component.html",
    styleUrls: ["./summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryComponent implements OnInit, OnChanges, OnDestroy {
    static componentName = "SummaryComponent";

    private readonly destroy$ = new Subject();
    private readonly fixture$ = new BehaviorSubject<Fixture>(null);

    readonly FixtureSource = FixtureSource;

    brokers: string;
    blDate: DateTime;
    owner$: Observable<string>;
    charterer$: Observable<string>;
    gainFixtureUrl: string;
    seaNetLink$: Observable<string>;

    @Input() fixture: Fixture;
    @Input() voyage: Voyage;

    constructor(private appConfigService: AppConfigService, private featureToggleService: FeatureToggleService, private contactService: ContactService) {
        this.gainFixtureUrl = appConfigService.config.gainFixtureUrl;
    }

    ngOnInit() {
        this.setupOwner();
        this.setupCharterer();
        this.setupSeatNetLink();
    }

    ngOnChanges(changes: SimpleChanges<SummaryComponent>) {
        if (changes.fixture) {
            this.fixture$.next(changes.fixture.currentValue);

            this.setBrokers(changes.fixture.currentValue);
        }

        if (changes.fixture || changes.voyage) {
            const fixture = (changes.fixture ? changes.fixture.currentValue : null) || this.fixture;
            const voyage = (changes.voyage ? changes.voyage.currentValue : null) || this.voyage;

            this.setBlDate(fixture, voyage);
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    private setupOwner() {
        this.owner$ = this.contactService.companies$.pipe(
            takeUntil(this.destroy$),
            map((companies) => {
                const owner = companies.find((c) => c.typeId === CompanyType.Owner);
                return owner ? owner.name : "TBN";
            })
        );
    }

    private setupCharterer() {
        this.charterer$ = this.contactService.companies$.pipe(
            takeUntil(this.destroy$),
            map((companies) => {
                const charterer = companies.find((c) => c.typeId === CompanyType.Charterer);
                return charterer ? charterer.name : "TBN";
            })
        );
    }

    private setBrokers(fixture: Fixture) {
        this.brokers = fixture ? fixture.brokers.map((broker) => broker.userCode).join(", ") : "";
    }

    private setBlDate(fixture: Fixture, voyage: Voyage) {
        if (!fixture || !voyage || fixture.fixtureType.id !== FixtureType.Voyage.id) {
            return;
        }

        let blDate: DateTime;
        let timeZone: string;

        for (const destination of voyage.destinations) {
            for (const berth of destination.berths) {
                for (const activity of berth.cargoBerthActivities) {
                    const activityType = activity.type;

                    if (!activityType || (activityType.id !== CargoBerthActivityType.Load.id && activityType.id !== CargoBerthActivityType.InterimLoad.id)) {
                        continue;
                    }

                    for (const associatedCargo of activity.associatedCargoes) {
                        const date = associatedCargo.date ? DateTime.fromISO(associatedCargo.date) : null;

                        if (date && date.isValid && (!blDate || date > blDate)) {
                            blDate = date;
                            timeZone = destination.location ? destination.location.timeZone : null;
                        }
                    }
                }
            }
        }

        if (blDate && timeZone) {
            blDate = blDate.setZone(timeZone);
        }

        this.blDate = blDate;
    }

    private setupSeatNetLink() {
        const dateFormat = "yyyyMMdd";
        const date = DateTime.utc().toFormat(dateFormat);

        this.seaNetLink$ = this.fixture$.pipe(
            takeUntil(this.destroy$),
            map((fixture) => {
                if (!fixture.vessel) {
                    return null;
                }

                const formattedCharterPartyDate = DateTime.fromISO(fixture.charterParty.charterPartyDate).toFormat(dateFormat);
                const id = fixture.vessel.imo > 0 ? fixture.vessel.imo : fixture.vessel.cvn;
                return `${this.appConfigService.config.seaNetUrl}/voyage/${id}/${formattedCharterPartyDate}-${date}/false/false/false`;
            })
        );
    }
}
