import { GridState, StateObjectMap } from "./model";

export function emptyObjectMap<TObject>(): StateObjectMap<TObject> {
    return {
        allIds: [],
        byId: {}
    };
}

export function emptyGridState<TItem>(): GridState<TItem> {
    return {
        suggestionTerm: null,
        suggestions: null,
        criteria: null,
        sorting: null,
        columns: null,
        currentRequestId: null,
        data: {}
    };
}

export function toObjectMap<TSource, TProjection>(items: ReadonlyArray<TSource>, idProp: keyof TSource, projection: (item: TSource) => TProjection): StateObjectMap<TProjection> {
    const allIds = new Array<string>();
    const map: {
        [id: string]: Readonly<TProjection>;
    } = {};

    for (const item of items) {
        const id = <string>(<unknown>item[idProp]);

        allIds.push(id);
        map[id] = projection(item);
    }

    return {
        allIds,
        byId: map
    };
}

export function addToObjectMap<TSource, TProjection>(
    map: StateObjectMap<TProjection>,
    item: TSource,
    idProp: keyof TSource,
    projection: (item: TSource) => TProjection
): StateObjectMap<TProjection> {
    const id = <string>(<unknown>item[idProp]);

    return {
        allIds: [...map.allIds, id],
        byId: {
            ...map.byId,
            [id]: projection(item)
        }
    };
}

export function removeFromObjectMap<TProjection>(map: StateObjectMap<TProjection>, id: string): StateObjectMap<TProjection> {
    const { [id]: removed, ...filtered } = map.byId;

    return {
        allIds: map.allIds.filter((i) => i !== id),
        byId: filtered
    };
}

export type ObjectReducer<TObject> = (objectState: TObject) => TObject;
