import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { Invoice } from "src/app/fixture/shared/models";
import { CommissionGridRow } from "src/app/fixture/shared/models/form-models/commission-gridrow.model";
import { parseISODate } from "src/app/shared/date-utils/date-utilities";
import { InvoiceType } from "src/app/shared/reference-data/invoice-type";
import { InvoiceDataService } from "../../services/invoice-data.service";

@Component({
    selector: "ops-commission",
    templateUrl: "./commission.component.html",
    styleUrls: ["./commission.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommissionComponent implements OnInit {
    static componentName = "CommissionComponent";

    commissionRows$: Observable<CommissionGridRow[]>;

    constructor(private invoiceDataService: InvoiceDataService) {}

    ngOnInit() {
        this.commissionRows$ = this.invoiceDataService.currentInvoices$.pipe(map((invoices) => this.mapInvoicesToViewModel(invoices.invoices)));
    }

    private mapInvoicesToViewModel(invoices: Invoice[]): CommissionGridRow[] {
        if (invoices === null || invoices.length === 0) {
            return null;
        }
        const comparer = (c1: CommissionGridRow, c2: CommissionGridRow) => {
            return c1.invoiceDate.getTime() - c2.invoiceDate.getTime();
        };

        return invoices
            .filter((i) => i.isCommission)
            .map(
                (i: Invoice) =>
                    <CommissionGridRow>{
                        allocatedStatus: i.allocatedStatus ? i.allocatedStatus.name : "",
                        allocatedValue: i.amountPaid,
                        credited: i.credited,
                        details: i.detail,
                        invoiceCreditNoteNumber: i.invoiceNumber,
                        invoiceDate: parseISODate(i.invoiceDate),
                        ownersInvoiceNumber: i.externalInvoiceNumber,
                        grossValue: i.grossValue,
                        commissionPercent: i.commissionPercent,
                        commissionValue: i.commissionValue
                    }
            )
            .sort(comparer);
    }
}
