import { FormArrayState, FormGroupState, updateArray, updateGroup, validate } from "ngrx-forms";
import { greaterThanOrEqualTo, lessThanOrEqualTo } from "ngrx-forms/validation";

import { numberString, validateRequired } from "@ops/state";

import { LaytimeEventForm } from "../../../../model";

const validateLaytimeEventForm = (form: FormGroupState<LaytimeEventForm>) =>
    updateGroup<LaytimeEventForm>({
        id: validateRequired(),
        percentage: validate(numberString(greaterThanOrEqualTo(0)), numberString(lessThanOrEqualTo(100)))
    })(form);

export const validateLaytimeEventForms = (forms: FormArrayState<LaytimeEventForm>) => updateArray<LaytimeEventForm>(validateLaytimeEventForm)(forms);
