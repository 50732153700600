import { Directive, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControlState } from "ngrx-forms";
import { AutoSizeInputDirective } from "ngx-autosize-input";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";

@Directive({
    selector: "[ngrxFormControlState][autoSizeInput]"
})
export class NgrxAutoSizeInputDirective implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();
    private inputStateSubject: Subject<string> = new Subject();

    @Input() set ngrxFormControlState(newState: FormControlState<string>) {
        if (!newState) {
            throw new Error("The control state must not be undefined");
        }

        this.inputStateSubject.next(newState.value);
    }

    constructor(private autoSizeInput: AutoSizeInputDirective) { }

    ngOnInit(): void {
        const delay = 50;
        this.inputStateSubject.pipe(
            distinctUntilChanged(),
            debounceTime(delay),
            takeUntil(this.destroy$)
        ).subscribe(() => {
            this.autoSizeInput.updateWidth();
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
