import { DateTime } from "luxon";

import { CargoBerthActivityType, LaytimeEventType } from "@ops/shared/reference-data";

import { DateUtilities } from "../../shared/date-utils/date-utilities";
import { Destination, Laycan, Period } from "./models";

export function getDefaultFocusDateForDemurrage(destinations: Destination[]): Date {
    if (destinations && destinations.length) {
        const dischargeLocations = destinations.filter(
            (d) => d.berths.filter((b) => b.cargoBerthActivities.filter((a) => a.type && a.type.id === CargoBerthActivityType.Discharge.id).length).length
        );
        if (dischargeLocations.length) {
            const lastDischargeLocation = dischargeLocations[dischargeLocations.length - 1];
            const cargoCompleted = lastDischargeLocation.berths
                .map((b) =>
                    b.cargoBerthActivities
                        .filter((a) => a.laytimeEvents)
                        .map((a) => a.laytimeEvents.find((e) => e.eventDate && e.type && e.type.id === LaytimeEventType.CargoCompleted.id))
                )
                .reduce((a, b) => a.concat(b), [])
                .find((e) => e !== undefined);

            const timezone = lastDischargeLocation.location && lastDischargeLocation.location.timeZone ? lastDischargeLocation.location.timeZone : "utc";

            if (cargoCompleted !== undefined) {
                return new Date(DateUtilities.formatISODate(cargoCompleted.eventDate, timezone, "YYYY-MM-DD"));
            }

            const dateToUse = lastDischargeLocation.arrivalDateTime
                ? lastDischargeLocation.arrivalDateTime
                : lastDischargeLocation.etaRange
                ? lastDischargeLocation.etaRange.from
                : null;

            if (dateToUse) {
                return new Date(DateUtilities.formatISODate(dateToUse, timezone, "YYYY-MM-DD"));
            }
        }
    }
    return null;
}

export function getLatestLaycanTo(laycan: Laycan): Date {
    if (laycan) {
        const date = laycan.extensionDate && laycan.extensionDate.to ? laycan.extensionDate.to : laycan.date && laycan.date.to ? laycan.date.to : null;
        if (date) {
            return DateTime.fromISO(date).toJSDate();
        }
    }
    return null;
}

export function getLastPeriodTo(periods: Period[]): Date {
    if (!periods || !periods.length) {
        return null;
    }

    const lastPeriod = periods[periods.length - 1];
    if (lastPeriod && lastPeriod.periodRange) {
        const date = DateTime.fromISO(lastPeriod.periodRange.to);
        if (date) {
            return date.toJSDate();
        }
    }

    return null;
}
