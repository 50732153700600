<div class="activity-form d-flex">
    <div class="type-container" [class.discharge-activity]="isDischargeActivity">
        <ng-select
            class="ops-select"
            [ngrxFormControlState]="form.controls.type"
            opsNgrxWarningFormControl
            placeholder="Select Activity"
            [compareWith]="enumerationCompare"
            [items]="activityDropdownTypes"
            [clearable]="false"
            [readonly]="readonly || isTypeDropdownReadonly"
            bindLabel="name"
            [selectOnTab]="true"
            data-test-activity-type
        >
        </ng-select>
        <ops-validation [form]="form.controls.type"></ops-validation>
    </div>
    <div *ngIf="isDischargeActivity" class="delivery-window-container">
        <label>Delivery Window</label>
        <ops-date-input
            mode="range"
            [enableTime]="true"
            [timeZone]="locationTimezone"
            [ngrxFormControlState]="form.controls.deliveryWindow"
            [ngrxValueConverter]="dateRangeValueConverter"
            [readonly]="readonly"
            data-test-delivery-window
        ></ops-date-input>
        <ops-validation [form]="form.controls.deliveryWindow"></ops-validation>
    </div>
    <div *ngIf="isBlAtDisportVisible" class="d-flex" [class.discharge-activity]="isDischargeActivity">
        <input id="blAtDisport" class="bl-at-disport-checkbox" type="checkbox" [ngrxFormControlState]="form.controls.blAtDisport" [class.inactive]="readonly" />
        <label for="blAtDisport" class="bl-at-disport-label">B/L at Disport</label>
    </div>
</div>
<div class="spacer" [class.discharge-activity]="isDischargeActivity"></div>
