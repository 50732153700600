import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { FixtureDataService } from "../../../fixture/services/fixture-data.service";
import { CompanyType } from "../../../fixture/shared/models";
import { CompanySearchResult } from "../../../fixture/shared/models/common/company-search-result.model";
import { FixtureSource } from "../../../fixture/shared/models/enums/fixture-source";
import { Enumeration } from "../../../shared/reference-data/enumeration";
import { ReferenceDataService } from "../../../shared/reference-data/reference-data.service";
import { ContactService } from "../contact.service";
import { Company } from "../models/company.model";

export class CompanyRoleOption implements Enumeration {
    disabled = false;

    constructor(public id: number, public name: string, enabled: boolean = true) {
        this.disabled = !enabled;
    }
}

@Component({
    selector: "ops-company-search",
    templateUrl: "./company-search.component.html",
    styleUrls: ["./company-search.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanySearchComponent implements OnInit {
    static componentName = "CompanySearchComponent";
    companyRoles$: Observable<CompanyRoleOption[]>;

    searchForm: UntypedFormGroup;
    @ViewChild("roleSelect", { static: true }) roleSelectElement: NgSelectComponent;

    constructor(
        private fb: UntypedFormBuilder,
        private contactService: ContactService,
        private fixtureDataService: FixtureDataService,
        public referenceDataService: ReferenceDataService
    ) {}

    ngOnInit() {
        this.searchForm = new UntypedFormGroup({
            role: this.fb.control(null),
            company: this.fb.control({ value: null, disabled: true })
        });

        const fixtureSource = this.fixtureDataService.fixtureSource;

        this.companyRoles$ = combineLatest([this.referenceDataService.getCompanyRoles(), this.contactService.companies$]).pipe(
            map(([roles, companies]: [Enumeration[], Company[]]) =>
                roles.map((r) => {
                    const enabled = !(fixtureSource === FixtureSource.Gain && (r.id === CompanyType.Owner || r.id === CompanyType.Charterer));
                    const companyFound = companies.find((c) => c.typeId === r.id);
                    const visible = !(companyFound && (r.id === CompanyType.Owner || r.id === CompanyType.Charterer));
                    return new CompanyRoleOption(r.id, r.name, enabled && visible);
                })
            )
        );

        this.searchForm.get("role").valueChanges.subscribe((data: Enumeration) => {
            if (data && data.id > 0) {
                this.searchForm.get("company").enable();
            } else {
                this.searchForm.get("company").disable();
            }
        });
    }

    onSelectionChange(selection: CompanySearchResult): void {
        if (selection) {
            const role = this.searchForm.get("role").value;
            this.contactService.addCompany(selection.accountId, selection.companyName, selection.groupName, role);
            this.searchForm.get("role").setValue(null);
        }
    }

    setSelectFocus() {
        this.roleSelectElement.focus();
    }
}
