import { AbstractControl } from "@angular/forms";
import { ValidatorFn } from "@angular/forms";

export class RangeRequiredIfValidator {
    static validate(fromField: string, toField: string): ValidatorFn {
        const errorKey = "invalidDateRange";

        return (control: AbstractControl): null => {
            const fromControl = control.get(fromField);
            const toControl = control.get(toField);

            if (fromControl && fromControl.value && (toControl.value === undefined || toControl.value === null || toControl.value === "")) {
                toControl.setErrors({ [errorKey]: true });
            } else if (toControl && toControl.value && (fromControl.value === undefined || fromControl.value === null || fromControl.value === "")) {
                fromControl.setErrors({ [errorKey]: true });
            } else if (fromControl && toControl && fromControl.value && toControl.value && fromControl.value > toControl.value) {
                fromControl.setErrors({ [errorKey]: true });
                toControl.setErrors({ [errorKey]: true });
            } else {
                fromControl.setErrors(null);
                toControl.setErrors(null);
            }

            return null;
        };
    }
}
