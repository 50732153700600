<ops-voyage-heading [parentForm]="voyageForm" *ngIf="(fixture$ | async) as fixture" [fixture]="fixture" (headingUpdated)="handleFixtureUpdateCommand($event)"></ops-voyage-heading>

<div class="voyage-tabs" *ngIf="(fixture$ | async) as fixture">
    <ngb-tabset class="tabs-sticky" justify="justified" #tabset (tabChange)="tabChange($event)" [destroyOnHide]="false">
        <ngb-tab id="cargoTab">
            <ng-template ngbTabTitle>
                <div class="wrapped-title" data-test-voyagecargo-tab>
                    <span
                        class="validation-indicator pl-0 has-icon icon--warning"
                        [class.invalid]="!tabsValidity.cargoTab"
                        container="body"
                        ngbTooltip="There are validation errors on this tab"
                        data-test-cargo-tab-warning
                    ></span>
                    Cargo
                </div>
            </ng-template>
            <ng-template ngbTabContent>
                <ops-voyage-shell *ngIf="tabset.activeId === 'cargoTab'" [tabName]="'cargoTab'"></ops-voyage-shell>
            </ng-template>
        </ngb-tab>
        <ngb-tab id="speedAndConsTab" *opsRole="'SpeedAndConsumption'">
            <ng-template ngbTabTitle>
                <div class="wrapped-title" data-test-speed-tab>
                    <span
                        class="validation-indicator pl-0 has-icon icon--warning"
                        [class.invalid]="!tabsValidity.speedAndConsTab"
                        container="body"
                        ngbTooltip="There are validation errors on this tab"
                        data-test-speed-tab-warning
                    ></span>
                    Speed and Consumption
                </div>
            </ng-template>
            <ng-template ngbTabContent>
                <div *ngIf="tabset.activeId === 'speedAndConsTab'" class="loading-panel-container fill" data-test-loading-panel-container>
                    <div class="loading-panel" *ngIf="busy$ | async">
                        <ops-load-animation size="x-large" class="loading-animation"></ops-load-animation>
                    </div>
                    <div class="row no-gutters" *ngIf="voyage">
                        <ops-speed-and-consumption-tab
                            class="col-12"
                            [readonly]="readonly$ | async"
                            [tabName]="'speedAndConsTab'"
                            [parentForm]="voyageForm"
                            [delivery]="fixture?.delivery"
                            [ownerEmail]="fixture?.ownerEmail"
                            [destinations]="voyage?.destinations"
                            [atSeaBunkerConsumptionMode]="voyage?.atSeaBunkerConsumptionMode"
                            [atSeaBunkersConsumption]="voyage?.atSeaBunkersConsumption"
                            [cpSpeedAndConsumption]="fixture?.cpSpeedAndConsumption"
                            (cpSpeedAndConsumptionTabUpdated)="handleFixtureUpdateCommand($event)"
                            (voyageBunkerConsumptionTabUpdated)="handleVoyageUpdateCommand($event)"
                            formName="speedAndConsumptionVoyageTab"
                            [voyages$]="voyages$"
                            [voyageId]="voyage?.voyageId"
                        ></ops-speed-and-consumption-tab>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab id="laytimeTab" data-test-voyagelaytime-tab>
            <ng-template ngbTabTitle>
                <div class="wrapped-title">
                    <span
                        class="validation-indicator pl-0 has-icon icon--warning"
                        [class.invalid]="!tabsValidity.laytimeTab"
                        container="body"
                        ngbTooltip="There are validation errors on this tab"
                        data-test-laytime-tab-warning
                    ></span>
                    Laytime
                </div>
            </ng-template>
            <ng-template ngbTabContent>
                <div *ngIf="tabset.activeId === 'laytimeTab'" class="loading-panel-container fill" data-test-loading-panel-container>
                    <div *ngIf="voyage" [formGroup]="voyageForm">
                        <ops-laytime-tab
                            (laytimeTabUpdated)="handleFixtureUpdateCommand($event)"
                            (cargoLaytimeTabUpdated)="handleVoyageUpdateCommand($event)"
                            [parentForm]="voyageForm"
                            [fixture]="fixture"
                            [cargoAllowedRates]="voyage?.cargoAllowedRates"
                            [cargoes]="voyage?.cargoes"
                            [destinations]="voyage?.destinations"
                            [displayUnit]="fixture?.laytimeUnit"
                            controlName="laytimeTab"
                        ></ops-laytime-tab>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab id="demurrageTab" data-test-demurrage-tab>
            <ng-template ngbTabTitle>
                <div class="wrapped-title" data-test-demurrage-tab>
                    <span
                        class="validation-indicator pl-0 has-icon icon--warning"
                        [class.invalid]="!tabsValidity.demurrageTab"
                        container="body"
                        ngbTooltip="There are validation errors on this tab"
                        data-test-demurrage-tab-warning
                    ></span>
                    Demurrage <span class="count-badge">({{ numberOfDemurrageClaims | async }})</span>
                </div>
            </ng-template>
            <ng-template ngbTabContent>
                <div *ngIf="voyage" [formGroup]="voyageForm">
                    <ops-demurrage-tab
                        (demurrageTabUpdated)="handleFixtureUpdateCommand($event)"
                        [fixture]="fixture"
                        [parentForm]="voyageForm"
                        [destinations]="voyage?.destinations"
                    ></ops-demurrage-tab>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab id="expensesTab">
            <ng-template ngbTabTitle>
                <div class="wrapped-title" data-test-expenses-tab>
                    <span
                        class="validation-indicator pl-0 has-icon icon--warning"
                        [class.invalid]="!tabsValidity.expensesTab"
                        container="body"
                        ngbTooltip="There are validation errors on this tab"
                        data-test-expenses-tab-warning
                    ></span>
                    Expenses <span class="count-badge">({{ !!expenseTabComponent?.expensesCount ? expenseTabComponent?.expensesCount : 0 }})</span>
                </div>
            </ng-template>
            <ng-template ngbTabContent>
                <div *ngIf="voyage" [formGroup]="voyageForm">
                    <ops-expense-tab [destinations]="voyage?.destinations"></ops-expense-tab>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab id="invoicesTab">
            <ng-template ngbTabTitle>
                <div class="wrapped-title" data-test-invoices-tab>
                    <span
                        class="validation-indicator pl-0 has-icon icon--warning"
                        [class.invalid]="!tabsValidity.invoicesTab"
                        container="body"
                        ngbTooltip="There are validation errors on this tab"
                        data-test-invoices-tab-warning
                    ></span>
                    Invoices <span class="count-badge">({{ !!freightInvoicesComponent?.invoiceCount ? freightInvoicesComponent.invoiceCount : 0 }})</span>
                </div>
            </ng-template>
            <ng-template ngbTabContent>
                <div class="loading-panel-container" data-test-loading-panel-container>
                    <div class="loading-panel" *ngIf="freightInvoicesComponent && freightInvoicesComponent.invoicesForm.disabled && freightInvoicesComponent.invoiceCount === 0">
                        <ops-load-animation size="x-large" class="loading-animation"></ops-load-animation>
                    </div>
                    <div *ngIf="voyage" class="row no-gutters">
                        <ops-freight-invoices class="col-12" [fixture$]="fixture$" [destinations]="voyage?.destinations"></ops-freight-invoices>
                    </div>
                    <div class="row no-gutters" *ngIf="isCommissionActive">
                        <ops-commission class="col-12"></ops-commission>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab id="profitLossTab" *opsRole="'ProfitAndLoss'">
            <ng-template ngbTabTitle>
                <div class="wrapped-title">Profit and Loss</div>
            </ng-template>
            <ng-template ngbTabContent>
                <ops-profit-and-loss-shell *ngIf="tabset.activeId === 'profitLossTab'" (goToCargoTabClick)="tabset.select('cargoTab')"> </ops-profit-and-loss-shell>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>
