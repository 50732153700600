import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";

import { CoaHttpService } from "../../../services";
import { selectCurrentCoaId } from "../../coa";
import { coaStateReducer } from "../../coa/reducer";
import { CoaId, LocationsKey, CoaFeatureState, CoasState, ActivityType, LocationId } from "../../model";

const REMOVE_LOAD_LOCATION_ACTION_NAME = "[Coa Locations] Remove Load Location";
export const removeLoadLocationAction = createAction(REMOVE_LOAD_LOCATION_ACTION_NAME, props<{ locationId: LocationId }>());
export const removeLoadLocationSuccessAction = createAction(`${REMOVE_LOAD_LOCATION_ACTION_NAME} Success`, props<{ coaId: CoaId; locationId: LocationId }>());
export const removeLoadLocationFailAction = createAction(`${REMOVE_LOAD_LOCATION_ACTION_NAME} Fail`, props<{ coaId: CoaId; error: Error }>());

const REMOVE_DISCHARGE_LOCATION_ACTION_NAME = "[Coa Locations] Remove Discharge Location";
export const removeDischargeLocationAction = createAction(REMOVE_DISCHARGE_LOCATION_ACTION_NAME, props<{ locationId: LocationId }>());
export const removeDischargeLocationSuccessAction = createAction(`${REMOVE_DISCHARGE_LOCATION_ACTION_NAME} Success`, props<{ coaId: CoaId; locationId: LocationId }>());
export const removeDischargeLocationFailAction = createAction(`${REMOVE_DISCHARGE_LOCATION_ACTION_NAME} Fail`, props<{ coaId: CoaId; error: Error }>());

/* REDUCERS */
export const removeLocationSuccessReducer: On<CoasState> = on(removeLoadLocationSuccessAction, removeDischargeLocationSuccessAction, (state, { type, coaId, locationId }) => {
    const locationsKey: LocationsKey = type === removeLoadLocationSuccessAction.type ? "loadLocations" : "dischargeLocations";

    return coaStateReducer(state, coaId, (coaState) => ({
        ...coaState,
        coa: {
            ...coaState.coa,
            [locationsKey]: coaState.coa[locationsKey].filter((x) => x.locationId !== locationId)
        }
    }));
});

/* EFFECTS */
export const removeLocationEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, coaHttpService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeLoadLocationAction, removeDischargeLocationAction),
            withLatestFrom(store.select(selectCurrentCoaId)),
            switchMap(([{ type, locationId }, coaId]) => {
                const activityType: ActivityType = type === removeLoadLocationAction.type ? "load" : "discharge";
                const successAction = type === removeLoadLocationAction.type ? removeLoadLocationSuccessAction : removeDischargeLocationSuccessAction;
                const failAction = type === removeLoadLocationAction.type ? removeLoadLocationFailAction : removeDischargeLocationFailAction;

                return coaHttpService.removeLocation(coaId, activityType, locationId).pipe(
                    map(() => successAction({ coaId, locationId })),
                    catchError((error) => of(failAction({ coaId, error })))
                );
            })
        )
    );
