import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, props, select, Store } from "@ngrx/store";
import { of } from "rxjs";
import { mergeMap, withLatestFrom } from "rxjs/operators";

import { navigateToVoyage } from "../../../routing";
import { FixtureFeatureState, FixtureId, VoyageId } from "../../model";
import { selectVoyage } from "../selectors";

/* ACTIONS */
export const formSelectVoyageAction = createAction("[TC Voyage Form] Select Voyage", props<{ voyageId: VoyageId }>());

/* EFFECTS */
export const selectVoyageEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, router: Router) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(formSelectVoyageAction),
                mergeMap(({ voyageId }) => of(voyageId).pipe(withLatestFrom(store.pipe(select(selectVoyage, { voyageId }))))),
                mergeMap(([, voyage]) => navigateToVoyage(router, voyage.fixtureId as FixtureId, voyage.voyageId))
            ),
        { dispatch: false }
    );
