import * as R from "ramda";

import { joinNamedTypeArray } from "@ops/shared";

import { DateRange } from "../../../fixture/shared/models";
import { compareValues } from "../../../fixture/state/utils";
import { toMaritimeDateRange } from "../../../shared/date-utils/date-utilities";
import {
    CoaDriver,
    getNominatedVessel,
    getNominatedVesselNameOrTbn,
    Lifting,
    LiftingCargo,
    LiftingGridRow,
    LiftingIndexItem,
    LiftingIndexItemWithLaycan,
    PlannedLifting
} from "../model";

const mapLocations = (liftingCargoes: ReadonlyArray<LiftingCargo>, locationKey: keyof Pick<LiftingCargo, "loadLocations" | "dischargeLocations">): string =>
    R.pipe(R.map(R.prop(locationKey)), R.flatten, joinNamedTypeArray)(liftingCargoes);

const getEarliestLoadEtaFromDateString = (lifting: LiftingIndexItemWithLaycan): string =>
    lifting.cargoes
        ?.map((c) => c.loadLocations.map((l) => l.eta))
        .reduce((a, b) => a.concat(b), [])
        .sort(compareValues)[0]?.from;

const sortLiftingsByLaycan = (liftings: ReadonlyArray<LiftingIndexItem>, driver: CoaDriver): ReadonlyArray<LiftingIndexItemWithLaycan> =>
    liftings
        .map((lifting) => ({ ...lifting, relevantLaycan: getLiftingLaycan(lifting, driver) }))
        .sort(
            (l1: LiftingIndexItemWithLaycan, l2: LiftingIndexItemWithLaycan) =>
                compareValues(l1.relevantLaycan?.from, l2.relevantLaycan?.from) || compareValues(getEarliestLoadEtaFromDateString(l1), getEarliestLoadEtaFromDateString(l2))
        );

export const sortAndMapLiftingIndexItemsToGridRows = (liftings: ReadonlyArray<LiftingIndexItem>, driver: CoaDriver): ReadonlyArray<LiftingGridRow> =>
    sortLiftingsByLaycan(liftings, driver).map((lifting) => ({
        liftingId: lifting.documentId,
        coaId: lifting.coaId,
        liftingNumber: lifting.liftingNumber,
        liftingReference: lifting.reference,
        laycan: toMaritimeDateRange(lifting.relevantLaycan)?.toMaritimeString(),
        vesselName: getNominatedVesselNameOrTbn(lifting.vessels),
        cargoes: joinNamedTypeArray(lifting.cargoes),
        loadDestinations: mapLocations(lifting.cargoes, "loadLocations"),
        dischargeDestinations: mapLocations(lifting.cargoes, "dischargeLocations"),
        cargoPlanStatus: lifting.cargoPlanStatus,
        vesselPlanStatus: lifting.vesselPlanStatus,
        liftingStatus: lifting.liftingStatus
    }));

export const getEarliestPlannedLifting = (liftings: ReadonlyArray<LiftingIndexItem>, driver: CoaDriver): PlannedLifting => {
    const earliestLifting = sortLiftingsByLaycan(liftings, driver)[0];

    return (
        earliestLifting && {
            liftingId: earliestLifting.documentId,
            coaId: earliestLifting.coaId,
            liftingNumber: earliestLifting.liftingNumber,
            fixtureReference: earliestLifting.reference,
            vesselName: getNominatedVesselNameOrTbn(earliestLifting.vessels),
            laycan: earliestLifting.relevantLaycan,
            loadDestinations: mapLocations(earliestLifting.cargoes, "loadLocations"),
            dischargeDestinations: mapLocations(earliestLifting.cargoes, "dischargeLocations"),
            cargoes: joinNamedTypeArray(earliestLifting.cargoes)
        }
    );
};

export const getLiftingLaycan = (lifting: LiftingIndexItem | Lifting, driver: CoaDriver): DateRange => {
    switch (driver) {
        case "Vessel": {
            return getNominatedVessel(lifting.vessels)?.laycan;
        }
        case "Cargo": {
            return lifting.cargoLaycan;
        }
        default: {
            return null;
        }
    }
};
