import * as R from "ramda";

/**
 * Returns function which deeply compares argument from previous call with current call argument
 */
export const createHasChangedFn = <T>(initialValue: T) => {
    let previousValue = R.clone(initialValue);
    return (currentValue: T) => {
        const hasChanged = !R.equals(previousValue, currentValue);
        previousValue = hasChanged ? R.clone(currentValue) : previousValue;
        return hasChanged;
    };
};

/**
 * Returns function which compares to objects using provided keys of this objects
 */
export const equalsByProps =
    <T>(keys: Array<keyof T>) =>
    (a: T, b: T) =>
        keys.every((key) => R.equals(a?.[key], b?.[key]));
