import { Injectable } from "@angular/core";

// eslint-disable-next-line @typescript-eslint/naming-convention
function _window(): Window {
    // return the global native browser window object
    return window;
}

@Injectable({
    providedIn: "root"
})
export class WindowRefService {
    get nativeWindow(): Window {
        return _window();
    }
}
