import { ChangeDetectorRef, Directive, Input, OnChanges, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";

import { AbstractSimpleGridComponent } from "../../../fixture/speed-and-consumption-tab/cp-speed-and-consumption/abstract-simple-grid/abstract-simple-grid.component";
import { EngineConsumption } from "../services/engine-consumption.dto";
import { EngineConsumptionModel } from "../services/engine-consumption.model";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class EngineConsumptionComponentBase extends AbstractSimpleGridComponent<EngineConsumptionModel> implements OnChanges {
    private readonly columnDefs = [
        { headerName: "Type", field: "type", class: "type-column-fixed" },
        { headerName: "Consumed", field: "quantityConsumed", class: "quantity-column-fixed text-right" },
        { headerName: "Bunker ROB", field: "quantityRemaining", class: "quantity-column-fixed text-right" }
    ];

    @Input() model: EngineConsumption[] = [];

    constructor(public changeDetectorRef: ChangeDetectorRef, public formBuilder: UntypedFormBuilder) {
        super("engineConsumptionId", changeDetectorRef, formBuilder);

        this.columnDefs$.next(this.columnDefs);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.model) {
            return;
        }

        const dataModel = changes.model.currentValue as EngineConsumption[];
        if (!dataModel || !dataModel.length) {
            return;
        }

        const rowModel = dataModel.map((x, i) => ({ ...x, engineConsumptionId: i }));
        this.rowData$.next(rowModel);
    }

    get totalQuantityConsumed(): number {
        return this.model.filter((x) => x.quantityConsumed).reduce((total, current) => total + current.quantityConsumed, 0);
    }

    get totalQuantityRemaining(): number {
        return this.model.filter((x) => x.quantityRemaining).reduce((total, current) => total + current.quantityRemaining, 0);
    }

    protected handleEmptyModel() {}
}
