import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";

import { dateRangeEquals } from "@ops/shared";

import { LocationSuggestionService } from "./location-suggestion.service";
import { AppConfigService } from "../../../../../core/app-config.service";
import { AutosuggestComponent } from "../../../../../shared/components/autosuggest/autosuggest.component";
import { FixtureDataService } from "../../../../services/fixture-data.service";
import { Division, SeaNetLocation } from "../../../models";
import { SuggestedExistingLocation } from "../../../models/common/suggested-existing-location";

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LocationAutosuggestComponent),
    multi: true
};

@Component({
    selector: "ops-location-autosuggest",
    templateUrl: "./location-autosuggest.component.html",
    styleUrls: ["../../../../../shared/components/autosuggest/autosuggest.component.scss", "./location-autosuggest.component.scss"],
    providers: [VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationAutosuggestComponent extends AutosuggestComponent<SeaNetLocation> {
    static componentName = "LocationAutosuggestComponent";

    private readonly clarksonsCdnUrl: string;
    private searchingSubscription: Subscription;

    displayingExistingLocationSuggestions = false;

    @Input() division: Division;
    @Input() readonly: boolean;

    constructor(
        private fixtureDataService: FixtureDataService,
        protected locationSuggestionService: LocationSuggestionService,
        protected appConfigService: AppConfigService,
        protected element: ElementRef,
        protected changeDetector: ChangeDetectorRef
    ) {
        super(locationSuggestionService, element, changeDetector);
        this.clarksonsCdnUrl = appConfigService.config.clarksonsCdnUrl;
    }

    onFocus(): void {
        if (!this.division) {
            const suggestions = this.locationSuggestionService.getExistingLocations();
            if (suggestions.length > 0) {
                this.displayingExistingLocationSuggestions = true;
                this.suggestionsSubject.next(suggestions);
                this.searchingSubscription = this.searchTermSubject.subscribe(() => {
                    // wait for the user to start typing, then clear the suggestions so that the "Loading..." message shows
                    this.displayingExistingLocationSuggestions = false;
                    this.suggestionsSubject.next([]);
                    this.searchingSubscription.unsubscribe();
                });
            }
        }
        super.onFocus();
    }

    getIndexOfSuggestion(item: SuggestedExistingLocation): number {
        return this.locationSuggestionService.getExistingLocations().findIndex((l) => l.locationId === item.locationId && dateRangeEquals(l.etaRange, item.etaRange));
    }

    getFlagImageUrl(location: SeaNetLocation) {
        if (!location.countryUnCode) {
            return null;
        }

        return this.clarksonsCdnUrl + `/Data/PublicArtificats/flags/ALPHA2/32/${location.countryUnCode}.png`;
    }

    protected getSuggestions(term: string) {
        return this.locationSuggestionService.getSuggestions(term, this.division || this.fixtureDataService.division);
    }
}
