import { On } from "@ngrx/store";
import { setValue, SetValueAction } from "ngrx-forms";

import { Division } from "../../../shared/models";
import { FixturesState, isDivision } from "../../model";
import { updateAssociatedCargoForms } from "../../voyage/form/form-updaters";
import { voyageStateReducer } from "../../voyage/reducer";

/**
 * Setting one freight rate sets them all, if Gas.
 */
export const setFreightRatesGasReducer: On<FixturesState> = {
    types: [SetValueAction.TYPE],
    reducer: (state: FixturesState, action: SetValueAction<number>): FixturesState => {
        const controlPath = action.controlId.split(".");
        if (controlPath.length < 2 || controlPath[controlPath.length - 3] !== "associatedCargoes" || controlPath[controlPath.length - 1] !== "freightRate") {
            return state;
        }

        return voyageStateReducer(state, controlPath[0], (voyageState, fixtureState) => {
            if (!isDivision(fixtureState, Division.gas)) {
                return voyageState;
            }

            return {
                ...voyageState,
                form: updateAssociatedCargoForms({
                    freightRate: setValue(action.value)
                })(voyageState.form)
            };
        });
    }
};
