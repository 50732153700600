import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { SetValueAction } from "ngrx-forms";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, filter, map, withLatestFrom } from "rxjs/operators";

import { update } from "@ops/shared";

import { LaytimeCalculationHttpService } from "../../../../../services";
import {
    ActivityCargo,
    ActivityCargoForm,
    ActivityCargoId,
    ActivityLocation,
    activityCargoFormsKey,
    ActivityLocationId,
    idEq,
    LtcFeatureState,
    LtcId,
    LtcState,
    toActivityCargo,
    toActivityCargoDiff
} from "../../../../model";
import { calculationStateReducer } from "../../../reducer";
import { selectCurrentLaytimeCalculation } from "../../../selectors";
import { UpdateQueue } from "../../../update-queue";
import { selectCurrentActivityLocationId } from "../../selectors";
import { selectActivityLocationCargoesForms } from "../selectors";
import { addActivityCargo, addActivityCargoAction } from "./add-activity-cargo";

/* ACTIONS */
const UPDATE_ACTION_NAME = "[Laytime Calculation Activity Location Cargoes Form] Save Activity Location Cargo";
export const updateActivityCargoAction = createAction(
    UPDATE_ACTION_NAME,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; activityCargoId: ActivityCargoId; activityCargo: Partial<ActivityCargo> }>()
);
export const updateActivityCargoSuccessAction = createAction(
    `${UPDATE_ACTION_NAME} Success`,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; activityCargoId: ActivityCargoId; activityCargo: Partial<ActivityCargo> }>()
);
export const updateActivityCargoFailAction = createAction(
    `${UPDATE_ACTION_NAME} Fail`,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; activityCargoId: ActivityCargoId; error: Error }>()
);

/* REDUCERS */
export const updateActivityCargoReducer: On<LtcState> = on(updateActivityCargoAction, (state, { activityCargo, activityCargoId, activityLocationId, ltcId }) => {
    const updateFns: Evolver = {
        calculation: {
            activityLocations: update(idEq(activityLocationId), (al: ActivityLocation) => ({
                ...al,
                cargoes: update(idEq(activityCargoId), R.mergeDeepLeft(activityCargo), al.cargoes)
            }))
        }
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

/* EFFECTS */
export const updateActivityCargoFormEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<ActivityCargoForm>>(SetValueAction.TYPE),
            filter((action) => action.controlId.split(".")[1] === activityCargoFormsKey),
            withLatestFrom(store.select(selectCurrentLaytimeCalculation), store.select(selectCurrentActivityLocationId), store.select(selectActivityLocationCargoesForms)),
            map(([action, ltc, activityLocationId, forms]) => {
                const formIndex = Number(action.controlId.split(".")[2]);
                const form = forms.controls[formIndex];
                const activityCargo = ltc.activityLocations.find((a) => a.id === activityLocationId).cargoes.find((c) => c.id === form.value.id);

                return { ltcId: ltc.id, activityLocationId, activityCargo, form };
            }),
            filter(({ form }) => form.isValid),
            map(({ ltcId, activityLocationId, activityCargo, form }) => {
                if (!activityCargo) {
                    const addAction = addActivityCargoAction({ ltcId, activityLocationId, activityCargo: toActivityCargo(form.value) });

                    updateQueue.enqueue(addAction, addActivityCargo);

                    return addAction;
                }

                const updateAction = updateActivityCargoAction({
                    ltcId,
                    activityLocationId,
                    activityCargoId: activityCargo.id,
                    activityCargo: toActivityCargoDiff(form.value, activityCargo)
                });

                updateQueue.enqueue(updateAction, updateActivityCargo, (latest) =>
                    updateAction.activityCargoId === latest.activityCargoId
                        ? (R.mergeDeepLeft(updateAction, latest) as ReturnType<typeof updateActivityCargoAction>)
                        : [latest, updateAction]
                );

                return updateAction;
            })
        )
    );

export const updateActivityCargo = (
    { ltcId, activityLocationId, activityCargoId, activityCargo }: ReturnType<typeof updateActivityCargoAction>,
    laytimeCalculationHttpService: LaytimeCalculationHttpService
) =>
    laytimeCalculationHttpService.updateActivityCargo(ltcId, activityLocationId, activityCargoId, activityCargo).pipe(
        map(() => updateActivityCargoSuccessAction({ ltcId, activityLocationId, activityCargoId, activityCargo })),
        catchError((error) => of(updateActivityCargoFailAction({ ltcId, activityLocationId, activityCargoId, error })))
    );
