import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./core";
import { PageNotFoundComponent } from "./core/page-not-found/page-not-found.component";

const routes: Routes = [
    {
        path: "fixture",
        loadChildren: () => import("./fixture/fixture.module").then((m) => m.FixtureModule),
        canActivate: [AuthGuard]
    },
    {
        path: "fixtures",
        loadChildren: () => import("./fixtures/fixtures.module").then((m) => m.FixturesModule),
        canActivate: [AuthGuard],
        data: { title: "Fixtures" }
    },
    {
        path: "",
        redirectTo: "fixtures",
        pathMatch: "full"
    },
    {
        path: "actions",
        loadChildren: () => import("./actions/actions.module").then((m) => m.ActionsModule),
        canActivate: [AuthGuard],
        data: { title: "Actions" }
    },
    {
        path: "claims",
        loadChildren: () => import("./claims/claims.module").then((m) => m.ClaimsModule),
        canActivate: [AuthGuard],
        data: { title: "Demurrage/Claims" }
    },
    {
        path: "admin",
        loadChildren: () => import("./admin/admin.module").then((m) => m.AdminModule),
        canActivate: [AuthGuard]
    },
    {
        path: "coa",
        loadChildren: () => import("./coa/coa.module").then((m) => m.CoaModule),
        canActivate: [AuthGuard]
    },
    {
        path: "coas",
        loadChildren: () => import("./coa/coa.module").then((m) => m.CoaModule),
        canActivate: [AuthGuard],
        data: { title: "Contracts" }
    },
    {
        path: "ltc",
        loadChildren: () => import("./ltc/ltc.module").then((m) => m.LtcModule),
        canActivate: [AuthGuard],
        data: { title: "Calculations" }
    },
    {
        path: "**",
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: false })],
    providers: [],
    exports: [RouterModule]
})
export class AppRoutingModule {}
