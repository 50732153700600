import { Injectable } from "@angular/core";
import { Action, ActionsSubject, createAction, on, On, props, Store } from "@ngrx/store";
import { DateTime } from "luxon";
import * as R from "ramda";
import { Evolver } from "ramda";
import { merge, Observable, of, Subject } from "rxjs";
import { concatMap, debounceTime, skipWhile, take, tap, withLatestFrom } from "rxjs/operators";

import { persisted, persisting, selectCurrentUser, User } from "@ops/state";

import { LaytimeCalculationHttpService } from "../../services";
import { LaytimeCalculationState, LtcId, LtcState, toUser } from "../model";
import { calculationStateReducer } from "./reducer";

declare type LtcAction = Action & { ltcId: LtcId };

/* ACTIONS */
const QUEUE_UPDATE_ACTION_NAME = "[Laytime Calculation] Queue Update";
export const queueUpdateAction = createAction(QUEUE_UPDATE_ACTION_NAME, props<{ action: LtcAction; queueLength: number }>());
export const queueUpdateSuccessAction = createAction(`${QUEUE_UPDATE_ACTION_NAME} Success`, props<{ action: LtcAction; queueLength: number; user: User }>());
export const queueUpdateFailAction = createAction(`${QUEUE_UPDATE_ACTION_NAME} Fail`, props<{ action: LtcAction & { error?: Error }; queueLength: number }>());

/* REDUCERS */
export const queueUpdateReducer: On<LtcState> = on(queueUpdateAction, (state, { action }) => {
    const updateFns: Evolver<LaytimeCalculationState> = {
        calculationPersistenceStatus: persisting
    };
    return calculationStateReducer(state, action.ltcId, R.evolve(updateFns));
});

export const queueUpdateSuccessReducer: On<LtcState> = on(queueUpdateSuccessAction, (state, { action: { ltcId }, queueLength, user }) => {
    const updateFns: Evolver<LaytimeCalculationState> = {
        calculationPersistenceStatus: queueLength === 0 ? persisted : persisting,
        calculation: {
            lastUpdatedAt: () => DateTime.utc().toISO(),
            lastUpdatedBy: () => toUser(user)
        }
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

export const queueUpdateFailReducer: On<LtcState> = on(queueUpdateFailAction, (state, { action: { ltcId, error } }) =>
    calculationStateReducer(state, ltcId, { calculationPersistenceStatus: "failed", calculationPersistenceError: error })
);

export type AddOrMergeFunction<A extends Action> = (latest: A) => A | [A, A];
export type PersistenceFunction<A extends Action> = (action: A, laytimeCalculationHttpService: LaytimeCalculationHttpService) => Observable<Action>;

const DEFAULT_DEBOUNCE_TIME = 1500; // 1.5s

/**
 * A universal queue for Laytime Calculations to ensure that the update events generated by the user are submitted
 * in the order that they occur in, allowing for debounce to reduce the number of events generated.
 */
@Injectable({
    providedIn: "root"
})
export class UpdateQueue {
    readonly #actions = new Array<{ action: LtcAction; persist: PersistenceFunction<Action> }>();
    readonly #enqueued$ = new Subject();
    readonly #dequeued$ = new Subject();
    readonly #flush$ = new Subject();

    debounceTime = DEFAULT_DEBOUNCE_TIME;

    get length() {
        return this.#actions.length;
    }

    constructor(private actionsSubject: ActionsSubject, store: Store, laytimeCalculationHttpService: LaytimeCalculationHttpService) {
        merge(this.#enqueued$.pipe(debounceTime(this.debounceTime)), this.#flush$)
            .pipe(
                concatMap(() => of(...this.#actions)),
                concatMap(({ action, persist }) =>
                    persist(action, laytimeCalculationHttpService).pipe(
                        tap((newAction) => this.actionsSubject.next(newAction)),
                        withLatestFrom(store.select(selectCurrentUser)),
                        tap(([newAction, user]) => {
                            if (newAction.type.includes("Success")) {
                                const index = this.#actions.findIndex((a) => a.action === action);
                                if (index >= 0) {
                                    this.#actions.splice(index, 1);
                                    this.#dequeued$.next();
                                    this.actionsSubject.next(queueUpdateSuccessAction({ action: <LtcAction>newAction, queueLength: this.length, user }));
                                }
                            } else if (newAction.type.includes("Fail")) {
                                this.actionsSubject.next(queueUpdateFailAction({ action: <LtcAction>newAction, queueLength: this.length }));
                            } else {
                                throw Error(
                                    `Update queue requires persistence functions to return an action with a type containing either 'Success' or 'Fail', got '${newAction.type}'`
                                );
                            }
                        })
                    )
                )
            )
            .subscribe();
    }

    /**
     * Adds, merges or replaces an action onto the end of the update queue.
     *
     * @param action
     * The action that requires persisting.
     * @param persist
     * The function to persist the changes contained within the action.
     * @param addMergeOrReplace
     * The function to determine whether the action is added, merged or replaced.
     * This is only called when the latest (most recently added) action on the queue is of the same type.
     * - When not specified, the given `action` will always be appended to the end of the queue.
     * - `"replace"` specifies that the given `action` will always replace actions of the same type.
     * - A function returning a single action (or in an array) will always replace the latest action. Use this for merging.
     * - A function returning an array (one or more actions) will append those actions to the end of the queue, replacing the latest action on the queue. Use this for adding.
     * @param flush
     * When true, immediately calls the function to persist the changes after all actions in the queue are flushed.
     */
    enqueue<A extends LtcAction>(action: A, persist: PersistenceFunction<A>, addMergeOrReplace?: AddOrMergeFunction<A> | "replace", flush?: boolean) {
        const lastAction = this.#actions[this.#actions.length - 1];

        if (lastAction && action.type === lastAction.action.type && addMergeOrReplace) {
            const actions = addMergeOrReplace === "replace" ? action : addMergeOrReplace(lastAction.action as A);

            if (Array.isArray(actions)) {
                const [latest, next] = actions;

                if (lastAction.action === latest) {
                    this.#actions.push({ action: next, persist });
                    this.actionsSubject.next(queueUpdateAction({ action: next, queueLength: this.length }));
                } else {
                    this.#actions.splice(this.#actions.length - 1, 1, ...actions.map((a) => ({ action: a, persist })));

                    for (const a of actions) {
                        this.actionsSubject.next(queueUpdateAction({ action: a, queueLength: this.length }));
                    }
                }
            } else {
                const mergedAction = actions;
                this.#actions[this.#actions.length - 1] = { action: mergedAction, persist };
                this.actionsSubject.next(queueUpdateAction({ action: mergedAction, queueLength: this.length }));
            }
        } else {
            // If the action type is different, flush it as we don't need to keep it around to merge it.
            // Note we are calling this prior to enqueueing the action so we don't flush the new action.
            if (lastAction && action.type !== lastAction.action.type) {
                this.#flush$.next();
            }

            this.#actions.push({ action, persist });
            this.actionsSubject.next(queueUpdateAction({ action, queueLength: this.length }));
        }

        this.#enqueued$.next();

        if (flush) {
            this.#flush$.next();
        }
    }

    /**
     * Flushes the queue returning an observable that completes when the queue is empty.
     *
     * Note the returning observable will not complete if any queue updates error.
     */
    flush() {
        if (this.length === 0) {
            return of();
        }

        this.#flush$.next();

        return this.#dequeued$.pipe(
            skipWhile(() => this.length > 0),
            take(1)
        );
    }
}
