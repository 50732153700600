import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { Fixture } from "src/app/fixture/shared/models";
import { FixtureSource } from "src/app/fixture/shared/models/enums/fixture-source";
import { FixtureDataService } from "../../../fixture/services/fixture-data.service";
import { ContactService } from "../contact.service";
import { Company } from "../models/company.model";

@Component({
    selector: "ops-contacts-list",
    templateUrl: "./contacts-list.component.html",
    styleUrls: ["./contacts-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsListComponent implements OnInit, OnDestroy {
    static componentName = "ContactsListComponent";    
    companies$: Observable<Company[]>;
    private subscription: Subscription;
    fixtureSource: FixtureSource;

    constructor(private contactService: ContactService, private fixtureDataService: FixtureDataService) {}

    ngOnInit() {
        this.companies$ = this.contactService.companies$;
        this.subscription = this.fixtureDataService.currentFixture$
            .pipe(
                filter((x) => x !== null),
                distinctUntilChanged((x: Fixture, y: Fixture) => x.fixtureId === y.fixtureId)
            )
            .subscribe((fixture) => {
                this.fixtureSource = (fixture.fixtureSource && fixture.fixtureSource.id) || FixtureSource.Gain;
                if (this.fixtureSource !== FixtureSource.Gain) {
                    this.contactService.getSharingGroupsForFixture(fixture.fixtureId);
                }
            });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
