import { FormControlState } from "ngrx-forms";

export const getSheetRenameErrorMessage = (control: FormControlState<string>) => {
    if (control.isValid) {
        return "";
    }

    if (control.errors.required) {
        return "is required";
    }

    if (control.errors.duplicateName) {
        return "cannot be a duplicate of an existing worksheet";
    }

    throw new Error("Message for such error type was not implemented");
}