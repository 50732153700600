import { CargoHistoricalEvent, isFirmedCargoPlanStatus, Lifting, toSummaryDate } from "../model";

export type CargoNominationSummaryStatus = "Not Started" | "Tentative" | "Firmed";
export type CargoNominationSummaryDate = "Initial Nomination" | "Final Confirmation";

export type CargoNominationSummary = Readonly<{
    status: CargoNominationSummaryStatus;
    dates: Readonly<{ [date in CargoNominationSummaryDate]: string }>;
}>;

export const getCargoNominationSummary = (lifting: Lifting, cargoNominationHistory: ReadonlyArray<CargoHistoricalEvent>): CargoNominationSummary => {
    const cargoIds = lifting.cargoes.map((x) => x.cargoId);

    const initialNomination = cargoNominationHistory?.find((x) => x.name === "LiftingCargoOrderAddedV1" && cargoIds.includes(x.entityId));
    const finalConfirmation =
        isFirmedCargoPlanStatus(lifting) && cargoNominationHistory?.filter((x) => x.name === "LiftingCargoPlanStatusChangedV1" && x.changes["/cargoPlanStatus"] === "Firmed").pop();

    return {
        status: !lifting.cargoes.length ? "Not Started" : isFirmedCargoPlanStatus(lifting) ? "Firmed" : "Tentative",
        dates: {
            ["Initial Nomination"]: toSummaryDate(initialNomination),
            ["Final Confirmation"]: toSummaryDate(finalConfirmation)
        }
    };
};
