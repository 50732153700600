import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { VoyageActivityFilter } from "../../../state";

@Component({
    selector: "ops-voyage-activities-filter",
    templateUrl: "./voyage-activities-filter.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoyageActivitiesFilterComponent {
    @Input() voyageActivities: ReadonlyArray<VoyageActivityFilter>;

    trackBy(index: number, item: VoyageActivityFilter) {
        return item.voyageActivityId;
    }
}
