<ng-select
    [items]="suggestions$ | async"
    [loading]="isLoading$ | async"
    [(ngModel)]="selection"
    bindLabel="fullName"
    (change)="onSelectionChange($event)"
    [selectOnTab]="true"
    [readonly]="readonly || disabled"
>
    <ng-template ng-label-tmp let-item="item" let-index="index" let-clear="clear">
        <div container="body" [ngbTooltip]="item.fullName" class="ng-value-label">
            <span class="user-pill">
                <img [src]="getUserImageUrl(item.userCode)" />
            </span>
            <span class="user-name">
                {{ item.fullName }}
            </span>
            <span class="lead" *ngIf="lead && lead.userId === item.userId">
                Lead
            </span>
            <button type="button" class="ng-value-icon has-icon icon--cancel" (click)="clear(item)" aria-hidden="true"></button>
        </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div class="d-inline-flex no-gutters w-100 user-option">
            <span class="user-avatar">
                <img [src]="getUserImageUrl(item.userCode)" />
            </span>
            <div class="user-description">
                <span [ngOptionHighlight]="search">{{ item.userCode }}</span>
                <span [ngOptionHighlight]="search">{{ item.fullName }}</span>
            </div>
        </div>
    </ng-template>
</ng-select>
