import { addLaytimeEventReducer } from "./form/add-laytime-event";
import { importPortTimesFailReducer, importPortTimesReducer, importPortTimesSuccessReducer, removeImportPortTimesAwaiterReducer } from "./form/import-port-times";
import { orderLaytimeEventFormsReducer, orderLaytimeEventsSuccessReducer } from "./form/order-laytime-events";
import {
    clearLaytimeEventsFormReducer,
    clearLaytimeEventsReducer,
    removeLaytimeEventFailReducer,
    removeLaytimeEventFormReducer,
    removeLaytimeEventReducer
} from "./form/remove-laytime-event";
import { updateLaytimeEventFormReducer, processedLaytimeEventUpdateReducer, updateLaytimeEventReducer } from "./form/update-laytime-event";

export default [
    addLaytimeEventReducer,
    removeLaytimeEventFormReducer,
    removeLaytimeEventReducer,
    updateLaytimeEventReducer,
    orderLaytimeEventFormsReducer,
    orderLaytimeEventsSuccessReducer,
    removeLaytimeEventFailReducer,
    clearLaytimeEventsFormReducer,
    clearLaytimeEventsReducer,
    removeImportPortTimesAwaiterReducer,
    importPortTimesReducer,
    importPortTimesSuccessReducer,
    importPortTimesFailReducer,
    processedLaytimeEventUpdateReducer,
    updateLaytimeEventFormReducer
];
