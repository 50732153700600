import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnChanges, Output, QueryList, ViewChildren } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { takeUntil } from "rxjs/operators";

import { Enumeration } from "../../../shared/reference-data/enumeration";
import { ReferenceDataService } from "../../../shared/reference-data/reference-data.service";
import { EngineConsumptionComponentBase } from "../engine-consumption/engine-consumption-base.component";
import { EngineConsumptionModel } from "../services/engine-consumption.model";

@Component({
    selector: "ops-edit-engine-consumption",
    templateUrl: "./edit-engine-consumption.component.html",
    styleUrls: ["./edit-engine-consumption.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditEngineConsumptionComponent extends EngineConsumptionComponentBase implements OnChanges, AfterViewInit {
    static componentName = "EditEngineConsumptionComponent";

    bunkerTypes: Enumeration[];

    @Output() updated = new EventEmitter();
    @ViewChildren("bunkerTypeElement", { read: NgSelectComponent }) ngSelectElements: QueryList<NgSelectComponent>;

    constructor(public referenceDataService: ReferenceDataService, public changeDetectorRef: ChangeDetectorRef, public formBuilder: UntypedFormBuilder) {
        super(changeDetectorRef, formBuilder);

        this.rowChanged$.pipe(takeUntil(this.destroy$)).subscribe((row) => this.onRowUpdated(row));
        this.add$.pipe(takeUntil(this.destroy$)).subscribe(() => this.onAdd());
        this.copy$.pipe(takeUntil(this.destroy$)).subscribe(({ index }) => this.onClone(index));
        this.delete$.pipe(takeUntil(this.destroy$)).subscribe(({ index }) => this.onDelete(index));

        this.referenceDataService
            .getBunkerTypes()
            .pipe(takeUntil(this.destroy$))
            .subscribe((bunkerTypes: Enumeration[]) => {
                this.bunkerTypes = bunkerTypes;
                this.changeDetectorRef.markForCheck();
            });
    }

    isLast(index: number) {
        return index === this.formArray.controls.length - 1;
    }

    ngAfterViewInit() {
        this.ngSelectElements.changes.pipe(takeUntil(this.destroy$)).subscribe((elements: QueryList<NgSelectComponent>) => {
            if (!this.formArray.disabled && elements.length) {
                elements.last.focus();
            }
        });
    }

    private onRowUpdated(rowModel: EngineConsumptionModel) {
        this.updated.emit([...this.model.slice(0, rowModel.engineConsumptionId), { ...rowModel }, ...this.model.slice(rowModel.engineConsumptionId + 1)]);
    }

    private onAdd() {
        this.updated.emit([...this.model, { type: null, quantityConsumed: null, quantityRemaining: null }]);
    }

    private onClone(index: number) {
        const source = this.model.find((_, i) => i === index);
        this.updated.emit([...this.model, { ...source }]);
    }

    private onDelete(index: number) {
        this.updated.emit([...this.model.filter((_, i) => i !== index)]);
    }
}
