import { v4 as uuid } from "uuid";

import { Identity } from "@ops/shared";
import { AtSeaVesselStatus } from "@ops/shared/reference-data";

import { DestinationId } from "../../../state/model";
import { BunkerConsumed } from "./bunker-consumed";

export type AtSeaBunkerConsumptionId = Identity<string, "AtSeaBunkerConsumptionId">;
export const createAtSeaBunkerConsumptionId = (): AtSeaBunkerConsumptionId => uuid() as AtSeaBunkerConsumptionId;

export interface AtSeaBunkerConsumption {
    id: AtSeaBunkerConsumptionId;
    destinationFromId: DestinationId;
    destinationToId: DestinationId;
    daysAboveForce?: number;
    comments?: string;
    bunkersConsumed: BunkerConsumed[];
    vesselStatus?: AtSeaVesselStatus;
    distance?: number;
}

export const atSeaBunkerConsumption = (id: AtSeaBunkerConsumptionId, destinationFromId: DestinationId, destinationToId: DestinationId, vesselStatus?: AtSeaVesselStatus) =>
    <AtSeaBunkerConsumption>{
        id,
        destinationFromId,
        destinationToId,
        bunkersConsumed: [],
        vesselStatus
    };
