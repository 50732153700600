import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    selector: "ops-warning",
    templateUrl: "./warning.component.html",
    styleUrls: ["./warning.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarningComponent {
    static componentName = "WarningComponent";
}
