import { Boxed, FormGroupState, updateGroup, validate, ValidationErrors } from "ngrx-forms";
import { greaterThanOrEqualTo, lessThanOrEqualTo } from "ngrx-forms/validation";

import { validateRequired, validateRequiredIf } from "@ops/state";

import { NominationTaskForm } from "../../model/nomination-task";

export const validateNominationTaskForm = (form: FormGroupState<NominationTaskForm>) => {
    const isFixedDateNominationTask = form.value.type === "FixedDate";
    const isRecurringDateNominationTask = form.value.type === "RecurringDate";
    const isEventBasedNominationTask = form.value.type === "EventBased";

    return updateGroup<NominationTaskForm>({
        nominationTaskId: validateRequired(),
        type: validateRequired(),
        name: validateRequired(),
        dueDate: validateRequiredIf(isFixedDateNominationTask || isRecurringDateNominationTask),
        responsible: validateRequired(),
        assignedUsers: validateRequired(),
        reminderDaysBefore: validate(greaterThanOrEqualTo(0)),
        // eslint-disable-next-line no-magic-numbers
        recurrenceDayOfMonth: validateRequiredIf(isRecurringDateNominationTask, greaterThanOrEqualTo(1), lessThanOrEqualTo(31)),
        recurrenceEndDate: validateRequiredIf(
            isRecurringDateNominationTask,
            validNominationTaskRecurrenceEndDate(isRecurringDateNominationTask, form.value.nextDueDate, form.value.recurrenceDayOfMonth)
        ),
        eventOffset: validateRequiredIf(isEventBasedNominationTask, greaterThanOrEqualTo(0)),
        eventOffsetIsPositive: validateRequiredIf(isEventBasedNominationTask),
        eventType: validateRequiredIf(isEventBasedNominationTask)
    })(form);
};

const validNominationTaskRecurrenceEndDate = <T extends string | Boxed<string> | null | undefined>(
    isRecurringDateNominationTask: boolean,
    nextDueDate: string,
    recurrenceDay: number
) => (_endDate: T): ValidationErrors => (isRecurringDateNominationTask && !nextDueDate && recurrenceDay ? { endDateBeforeFirstDueDate: true } : {});
