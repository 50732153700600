import { Injectable } from "@angular/core";

import { CommandHandler } from "../../../../mediator/command-handlers/command-handler";
import { Voyage } from "../../../../shared/models";
import { AtSeaBunkerConsumption } from "../../../../shared/models/dtos/at-sea-bunker-consumption";
import { UpdateAtSeaConsumptionCommand } from "./update-at-sea-consumption.command";

@Injectable({
    providedIn: "root"
})
export class UpdateAtSeaConsumptionCommandHandler implements CommandHandler {
    handle(voyage: Voyage, command: UpdateAtSeaConsumptionCommand) {
        const atSeaConsumptionModel = command.model;
        if (!atSeaConsumptionModel) {
            throw new Error("Invalid command payload to update at sea consumption");
        }

        const atSeaBunkerConsumption = voyage.atSeaBunkersConsumption.find((x: AtSeaBunkerConsumption) => x.id === atSeaConsumptionModel.atSeaBunkerConsumptionId);

        if (!atSeaBunkerConsumption) {
            throw new Error("Cannot find matching At Sea for ID: " + atSeaConsumptionModel.atSeaBunkerConsumptionId);
        }

        atSeaBunkerConsumption.comments = atSeaConsumptionModel.comments;
        atSeaBunkerConsumption.daysAboveForce = atSeaConsumptionModel.daysAboveForce;
        atSeaBunkerConsumption.distance = atSeaConsumptionModel.distance;
    }
}
