import { AbstractControl, ValidatorFn } from "@angular/forms";

import { LocationFormModel } from "../../../../shared/models/form-models/location.model";

export class RedeliveryLocatoinsValidator {
    static validate(): ValidatorFn {
        return (control: AbstractControl): null => {
            const redeliveryLocationsControl = control.get("redeliveryLocations");
            const actualRedeliveryDateControl = control.get("actualRedeliveryDate");

            if (redeliveryLocationsControl && actualRedeliveryDateControl) {
                const locations = <LocationFormModel[]>redeliveryLocationsControl.value;
                const actualRedeliveryDate = actualRedeliveryDateControl.value;

                if (actualRedeliveryDate && locations && locations.length > 1) {
                    redeliveryLocationsControl.setErrors({ exactPortIsNotSpecified: true });
                }
            }

            return null;
        };
    }
}
