import { createAction, on, On, props } from "@ngrx/store";

import { FixtureId, FixturesState } from "../model";
import { currentFixtureStateReducer } from "./reducer";

/* ACTIONS */
const SAVE_ACTION_NAME = "[Fixture Form] Save Fixture";

export const saveFixtureAction = createAction(SAVE_ACTION_NAME);
export const saveFixtureSuccessAction = createAction(`${SAVE_ACTION_NAME} Success`, props<{ fixtureId: FixtureId, unlock: boolean }>());
export const saveFixtureFailAction = createAction(`${SAVE_ACTION_NAME} Fail`, props<{ fixtureId: FixtureId; error: Error }>());

/* REDUCERS */
export const saveFixtureReducer: On<FixturesState> = on(saveFixtureAction, (state, action) =>
    currentFixtureStateReducer(state, { persistenceStatus: "persisting" })
);
export const savedFixtureReducer: On<FixturesState> = on(saveFixtureSuccessAction, (state, action) =>
    currentFixtureStateReducer(state, { persistenceStatus: "persisted" })
);
