import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MaritimeDateRange } from "@maritech/maritime-date";
import { Store } from "@ngrx/store";
import { Observable, Subject, Subscription } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";

import { Enumeration, InvoiceType, ReferenceDataService } from "@ops/shared/reference-data";

import { Accordion } from "../../../shared/accordion";
import { parseISODate, toMaritimeDateRange } from "../../../shared/date-utils/date-utilities";
import { FixtureType } from "../../../shared/reference-data";
import { InvoiceDataService } from "../../services/invoice-data.service";
import { getDefaultFocusDateForDemurrage } from "../fixture-destination.utils";
import { FormComponentBase } from "../form-component-base";
import { Currency, Destination, Fixture, FixtureInvoices, Invoice, Period } from "../models";
import { AssociatedPeriod, InvoiceFormModel, InvoicesFormModel } from "../models/form-models";
import { FixtureTabName } from "../tab-validation/tab-validation-info";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractInvoicesComponent extends FormComponentBase implements OnInit, OnDestroy {
    private dataModel: InvoicesFormModel;
    private _defaultCurrency: Currency;
    private fixtureType: FixtureType;
    private invoicesLoaded = false;
    private fixtureLoaded = false;
    private fixtureSubscription: Subscription | null;
    private invoicesSubscription: Subscription | null;
    protected readonly destroy$ = new Subject();

    readonly tabName: FixtureTabName = "invoicesTab";

    currencies$: Observable<Currency[]>;
    allocatedStatuses: Enumeration[];
    periods: AssociatedPeriod[];
    invoicesForm: UntypedFormGroup;
    invoicesExist = false;
    invoiceCount = 0;
    commentsMaxLength = 6000;

    @Input() fixture$: Observable<Fixture>;
    @Input() destinations: Destination[];

    get defaultFocusDate(): Date {
        return getDefaultFocusDateForDemurrage(this.destinations);
    }

    protected constructor(
        private invoiceType: InvoiceType,
        private cd: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        public referenceDataService: ReferenceDataService,
        private datePipe: DatePipe,
        private invoiceDataService: InvoiceDataService,
        protected store: Store
    ) {
        super(store);
    }

    get invalid() {
        return this.invoicesForm.invalid;
    }

    get touched() {
        const rows = this.invoices;
        return rows.some((row) => row.invalid && row.touched);
    }

    get invoices() {
        const data = <UntypedFormArray>this.invoicesForm.get("invoices");
        return data.controls;
    }

    ngOnInit() {
        this.currencies$ = this.referenceDataService.getCurrencies();
        this.referenceDataService.getAllocatedStatuses().subscribe((allocatedStatuses: Enumeration[]) => {
            this.allocatedStatuses = allocatedStatuses;
            this.cd.markForCheck();
        });

        this.createForm();
        this.invoicesForm.disable({ emitEvent: false });

        this.invoicesSubscription = this.invoiceDataService.currentInvoices$.subscribe((invoices) => {
            this.invoiceCount = invoices.invoices.filter((x) => x.invoiceType.id === this.invoiceType.id && !x.isCommission).length;
            this.setForm(invoices);
            this.cd.markForCheck();

            if (!this.invoicesLoaded) {
                this.subscribeToFormValueChanges();
                this.invoicesForm.enable({ emitEvent: false });
                this.invoicesLoaded = true;
            }
        });

        this.fixtureSubscription = this.fixture$.subscribe((fixture) => {
            this._defaultCurrency = fixture.currency;
            this.fixtureType = fixture.fixtureType;
            this.periods = this.generatePeriodDescriptions(fixture.periods);
            this.setFormForPeriods();

            if (!this.fixtureLoaded && fixture.fixtureId) {
                this.fixtureLoaded = true;
                this.invoiceDataService.load(fixture.fixtureId);
            }
        });

        this.subscribeToFormStatusChanges([this.invoicesForm], this.tabName);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.removeStatusChangesSubscrition();
        this.removeFormSubscriptions();
        this.removeInvoicesSubscription();
        this.removeFixtureSubscription();
    }

    addInvoice(): void {
        const invoicesControl = <UntypedFormArray>this.invoicesForm.controls.invoices;
        invoicesControl.push(this.createRow());
        this.invoicesExist = !!invoicesControl.length;
    }

    getCoveringPeriodFromLastInvoice(): MaritimeDateRange | null {
        const invoicesControl = <UntypedFormArray>this.invoicesForm.controls.invoices;
        if (this.fixtureType.id === FixtureType.TimeCharter.id && invoicesControl.controls.length >= 1) {
            const lastInvoice = invoicesControl.controls[invoicesControl.controls.length - 1];
            const lastCoveringPeriod = lastInvoice.get("coveringPeriod").value as MaritimeDateRange;
            if (lastCoveringPeriod) {
                return MaritimeDateRange.exact(lastCoveringPeriod.end, lastCoveringPeriod.end);
            }
        }
        return null;
    }

    deleteInvoice(currentIndex: number): void {
        const invoicesControl = <UntypedFormArray>this.invoicesForm.controls.invoices;
        invoicesControl.removeAt(currentIndex);
        this.invoicesExist = !!invoicesControl.length;
    }

    toggleAccordion(invoice: UntypedFormArray) {
        const accordion = (<any>invoice).accordion;

        if (accordion.isOpen) {
            invoice.markAsTouched({ onlySelf: true });
        }
        accordion.toggle();
    }

    protected subscribeToFormValueChanges() {
        this.formValueSubscription = this.invoicesForm.valueChanges
            .pipe(
                debounceTime(50),
                switchMap(() => {
                    const models = this.getFormModels();
                    return this.invoiceDataService.updateInvoices(models, this.invoiceType);
                })
            )
            .subscribe(() => {
                this.invoices.forEach((x) => x.markAsPristine());
            });
    }

    private removeInvoicesSubscription() {
        if (this.invoicesSubscription) {
            this.invoicesSubscription.unsubscribe();
            this.invoicesSubscription = null;
        }
    }

    private removeFixtureSubscription() {
        if (this.fixtureSubscription) {
            this.fixtureSubscription.unsubscribe();
            this.fixtureSubscription = null;
        }
    }

    private createForm() {
        this.invoicesForm = new UntypedFormGroup(
            {
                invoices: this.formBuilder.array([])
            },
            { updateOn: "blur" }
        );
    }

    private setForm(fixtureInvoices: FixtureInvoices) {
        const indexedInvoices = fixtureInvoices?.invoices.map((invoice, index) => ({ invoice, index })) ?? [];
        const filteredInvoices = indexedInvoices.filter((i) => i.invoice.invoiceType.id === this.invoiceType.id && !i.invoice.isCommission);

        this.dataModel = {
            invoices: filteredInvoices.map((i: { invoice: Invoice; index: number }) => {
                const associatedPeriod = this.periods.find((period) => period.hireId === i.invoice.associatedPeriodHireId);

                return <InvoiceFormModel>{
                    originalInvoiceIndex: i.index,
                    invoiceId: i.invoice.invoiceId,
                    invoiceType: i.invoice.invoiceType,
                    currency: i.invoice.currency,
                    externalInvoiceNumber: i.invoice.externalInvoiceNumber,
                    invoiceDate: parseISODate(i.invoice.invoiceDate),
                    grossValue: i.invoice.grossValue,
                    receivedFromOwnerDate: parseISODate(i.invoice.receivedFromOwnerDate),
                    sentToChartererDate: parseISODate(i.invoice.sentToChartererDate),
                    sentToAccountsDate: parseISODate(i.invoice.sentToAccountsDate),
                    associatedPeriod,
                    associatedPeriodHireId: i.invoice.associatedPeriodHireId,
                    allocatedStatus: i.invoice.allocatedStatus,
                    amountPaid: i.invoice.amountPaid,
                    datePaid: parseISODate(i.invoice.datePaid),
                    comments: i.invoice.comments,
                    lastUpdatedByUser: i.invoice.lastUpdatedByUser,
                    lastUpdatedDate: i.invoice.lastUpdatedDate,
                    coveringPeriod: i.invoice.coveringFromDate || i.invoice.coveringToDate ? MaritimeDateRange.exact(i.invoice.coveringFromDate, i.invoice.coveringToDate) : null,
                    actualHireRate: i.invoice.actualHireRate,
                    baseHireRate: associatedPeriod?.hireRate
                };
            })
        };
        this.invoicesExist = !!this.dataModel.invoices.length;
        const invoicesControl = <UntypedFormArray>this.invoicesForm.controls.invoices;
        this.resizeInvoices(invoicesControl, this.dataModel.invoices);
        this.invoicesForm.patchValue(this.dataModel, { emitEvent: false });
        this.updateValidationTab();

        this.cd.markForCheck();
    }

    private setFormForPeriods() {
        if (this.dataModel) {
            this.dataModel.invoices.forEach((inv) => {
                const associatedPeriod = this.periods.find((period) => period.hireId === inv.associatedPeriodHireId);
                inv.associatedPeriod = associatedPeriod;
                inv.baseHireRate = associatedPeriod?.hireRate;
            });

            this.invoicesForm.patchValue(this.dataModel, { emitEvent: false });
            this.updateValidationTab();
        }
    }

    private generatePeriodDescriptions(periods: Period[]): AssociatedPeriod[] {
        return periods.map((period) => {
            const periodRange = toMaritimeDateRange(period.periodRange);

            return {
                hireId: period.hireId,
                description: periodRange?.isValid ? periodRange.toMaritimeString() : null,
                hireRate: period.hireRate,
                period: periodRange
            };
        });
    }

    private resizeInvoices(invoicesControl: UntypedFormArray, invoicesModel: InvoiceFormModel[]): void {
        if (invoicesControl.length === invoicesModel.length) {
            return;
        } else if (invoicesControl.length > invoicesModel.length) {
            invoicesControl.removeAt(0);
        } else if (invoicesControl.length < invoicesModel.length) {
            invoicesControl.push(this.createRow(invoicesModel[invoicesControl.length], invoicesControl.length));
        }

        return this.resizeInvoices(invoicesControl, invoicesModel);
    }

    private createRow(invoice?: InvoiceFormModel, index?: number): UntypedFormGroup {
        const group = <any>this.formBuilder.group({
            originalInvoiceIndex: index ? [index] : [],
            invoiceId: [invoice?.invoiceId ?? `temp_${Math.random()}`],
            invoiceType: [invoice?.invoiceType ?? this.invoiceType],
            currency: [invoice?.currency ?? this._defaultCurrency],
            externalInvoiceNumber: invoice?.externalInvoiceNumber ? [invoice.externalInvoiceNumber] : [],
            invoiceDate: invoice?.invoiceDate ? [invoice.invoiceDate] : [],
            grossValue: [invoice?.grossValue ?? "", { validators: [Validators.required] }],
            receivedFromOwnerDate: invoice?.receivedFromOwnerDate ? [invoice.receivedFromOwnerDate] : [],
            sentToChartererDate: invoice?.sentToChartererDate ? [invoice.sentToChartererDate] : [],
            sentToAccountsDate: invoice?.sentToAccountsDate ? [invoice.sentToAccountsDate] : [],
            associatedPeriod: invoice?.associatedPeriod ? [invoice?.associatedPeriod] : [],
            associatedPeriodHireId: invoice?.associatedPeriodHireId ? [invoice?.associatedPeriodHireId] : [],
            allocatedStatus: invoice?.allocatedStatus ? [invoice.allocatedStatus] : [],
            amountPaid: [invoice?.amountPaid ?? ""],
            datePaid: invoice?.datePaid ? [invoice.datePaid] : [],
            comments: [invoice?.comments ?? "", { validators: [Validators.maxLength(this.commentsMaxLength)] }],
            lastUpdatedByUser: invoice?.lastUpdatedByUser ? [invoice.lastUpdatedByUser] : [],
            lastUpdatedDate: invoice?.lastUpdatedDate ? [invoice.lastUpdatedDate] : [],
            coveringPeriod: [invoice?.coveringPeriod ?? this.getCoveringPeriodFromLastInvoice()],
            actualHireRate: invoice?.actualHireRate ? [invoice.actualHireRate] : [],
            baseHireRate: [invoice?.baseHireRate ?? ""]
        });

        group.accordion = new Accordion(true);

        if (this.invoicesForm.disabled) {
            group.disable();
        } else {
            group.enable();
        }

        return group;
    }

    private getFormModels(): InvoiceFormModel[] {
        return this.invoices.map((control) => {
            const model = <InvoiceFormModel>control.value;
            model.valid = control.valid;
            model.dirty = control.dirty;
            return model;
        });
    }
}
