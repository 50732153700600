import { Enumeration } from "./enumeration";

export class LaytimeType extends Enumeration {
    static Fixed: LaytimeType = new LaytimeType(1, "Fixed", 1);
    static NonFixed: LaytimeType = new LaytimeType(2, "Non-Fixed", 2);

    static isFixedLaytimeType(type: Enumeration): boolean {
        return type && type.id === LaytimeType.Fixed.id;
    }
}
