import { GridApi, IServerSideDatasource, IServerSideGetRowsParams } from "@ag-grid-community/all-modules";
import { BehaviorSubject, of, Subject } from "rxjs";
import { catchError, filter, map, mergeMap, takeUntil, tap } from "rxjs/operators";

import { ISearchService } from "./search.service";

/**
 * This is the new Grid Datasource (NGRX version) that will replace GridServerSideDatasource and will be used
 * in conjunction with ISearchService.
 */
export class GridDatasource implements IServerSideDatasource {
    private readonly destroy$ = new Subject();
    private readonly params$ = new BehaviorSubject<IServerSideGetRowsParams>(null);

    constructor(private readonly searchService: ISearchService, private readonly gridApi: GridApi) {
        this.searchService.criteria$
            .pipe(
                takeUntil(this.destroy$),
                filter((criteria) => !!criteria),
                tap(() => {
                    this.gridApi.setServerSideDatasource(this);
                })
            )
            .subscribe();

        this.params$
            .pipe(
                takeUntil(this.destroy$),
                filter((params) => !!params),
                tap(() => this.setLoading(true)),
                mergeMap((params) =>
                    this.searchService.search(params.request.startRow, params.request.endRow - params.request.startRow).pipe(
                        map((rowData: any[]) => {
                            this.onSearchSuccess(rowData, params);
                        }),
                        catchError((error) => {
                            console.error(`GridDatasource: Failed to load rows ${params.request.startRow} to ${params.request.endRow} with error:`, error);
                            params.failCallback();
                            return of({ error });
                        })
                    )
                )
            )
            .subscribe();
    }

    removeSubscriptions() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getRows(params: IServerSideGetRowsParams) {
        this.params$.next(params);
    }

    private setLoading(loading: boolean) {
        if (!this.destroy$.isStopped) {
            if (loading) {
                this.gridApi.showLoadingOverlay();
            } else {
                this.gridApi.hideOverlay();
            }
        }
    }

    private onSearchSuccess(rowData: any[], params: IServerSideGetRowsParams) {
        const startRow = params.request.startRow;
        const endRow = params.request.endRow;
        const totalRowsRequested = endRow - startRow;

        let lastRow = rowData.length < totalRowsRequested ? endRow - (totalRowsRequested - rowData.length) : -1;
        if (startRow === 0 && lastRow > rowData.length) {
            lastRow = rowData.length;
        }

        setTimeout(() => {
            this.setLoading(false);
            params.successCallback(rowData, lastRow || rowData.length);

            if (rowData.length === 0) {
                this.gridApi.showNoRowsOverlay();
            }
        }, 0);
    }
}
