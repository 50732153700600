import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { DateTime } from "luxon";
import * as R from "ramda";
import { Evolver, F } from "ramda";
import { of } from "rxjs";
import { catchError, map, tap, withLatestFrom } from "rxjs/operators";

import { roundAndStringifyNumber } from "@ops/shared";
import { hasValue } from "@ops/state";

import { FixtureType, SeaNetLocation } from "../../../../../fixture/shared/models";
import { getDefaultLaytimeEvents } from "../../../../../fixture/state";
import { CargoBerthActivityType } from "../../../../../shared/reference-data";
import { AddActivityCargo, AddActivityLocation, AddLaytimeEvent, LaytimeCalculationHttpService } from "../../../../services";
import {
    ActivityCargo,
    ActivityCargoId,
    ActivityLocation,
    ActivityLocationId,
    AddActivityLocationsForm,
    AllowanceUnit,
    AssociatedCargo,
    Cargo,
    CargoId,
    CargoRateActivity,
    createActivityLocationId,
    createAddActivityLocationsFormState,
    Destination,
    FixtureIndex,
    LaytimeCalculation,
    LaytimeEvent,
    LaytimeEventId,
    LaytimeEventRemark,
    LaytimeEventType,
    LtcFeatureState,
    LtcId,
    LtcState,
    Reversible,
    Voyage,
    VoyageLaytimeEvent
} from "../../../model";
import { getLaytimeEventPercentage, hasExclusion, isLaytimeEventAfterDemurrageEvent } from "../../../model/calculations/utils";
import { LTC_PRECISION } from "../../../utils";
import { calculationStateReducer, currentCalculationStateReducer } from "../../reducer";
import { navigateToLaytimeCalculationAction } from "../../router";
import { selectCurrentCalculationId, selectCurrentFixture, selectCurrentLaytimeCalculation } from "../../selectors";
import { UpdateQueue } from "../../update-queue";
import { selectCurrentVoyage } from "../../voyage";
import { isAwaitingVoyageLoad, loadVoyageFailAction, loadVoyageSuccessAction, removeAwaitingVoyageLoadEntry, VoyageLoadAwaiter } from "../../voyage/load-voyage";
import { selectCurrentAddActivityLocationsForm, toVoyageActivities, VoyageActivity } from "../selectors";

/* ACTIONS */
const ADD_ACTIVITY_LOCATIONS_ACTION_NAME = "[LTC Add Activity Locations] Add Activity Locations";
export const addActivityLocationsToCalculationAction = createAction(`${ADD_ACTIVITY_LOCATIONS_ACTION_NAME} To Calculation`);
export const addActivityLocationsAction = createAction(ADD_ACTIVITY_LOCATIONS_ACTION_NAME, props<{ ltcId: LtcId; addActivityLocations: ReadonlyArray<AddActivityLocation> }>());
export const addActivityLocationsSuccessAction = createAction(
    `${ADD_ACTIVITY_LOCATIONS_ACTION_NAME} Success`,
    props<{ ltcId: LtcId; addActivityLocations: ReadonlyArray<AddActivityLocation> }>()
);
export const addActivityLocationsFailAction = createAction(`${ADD_ACTIVITY_LOCATIONS_ACTION_NAME} Fail`, props<{ ltcId: LtcId; error: Error }>());
export const addActivityLocationsCancelAction = createAction(`${ADD_ACTIVITY_LOCATIONS_ACTION_NAME} Cancel`);

/* REDUCERS */
export const addActivityLocationsReducer: On<LtcState> = on(addActivityLocationsAction, (state) => currentCalculationStateReducer(state, { isAddingActivityLocations: true }));
export const addActivityLocationsSuccessReducer: On<LtcState> = on(addActivityLocationsSuccessAction, (state, { ltcId, addActivityLocations }) => {
    const updateFns: Evolver = {
        isAddingActivityLocations: F,
        calculation: mergeActivityLocations(addActivityLocations, state.calculations.byId[ltcId].voyage)
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});
export const addActivityLocationsFailReducer: On<LtcState> = on(addActivityLocationsFailAction, (state, { ltcId }) =>
    calculationStateReducer(state, ltcId, { isAddingActivityLocations: false })
);

const VOYAGE_LOAD_AWAITER_TYPE: VoyageLoadAwaiter = "AddActivityLocations";
export const loadVoyageForAddActivityLocationsSuccessReducer: On<LtcState> = on(loadVoyageSuccessAction, (state, { ltcId, voyage }) => {
    if (isAwaitingVoyageLoad(state, ltcId, VOYAGE_LOAD_AWAITER_TYPE)) {
        return calculationStateReducer(state, ltcId, (ltcState) => ({
            ...ltcState,
            addActivityLocationsForm: createAddActivityLocationsFormState(voyage, ltcState.calculation)
        }));
    }

    return state;
});

export const loadVoyageForAddActivityLocationsReducer: On<LtcState> = on(loadVoyageSuccessAction, loadVoyageFailAction, (state, { ltcId }) =>
    isAwaitingVoyageLoad(state, ltcId, VOYAGE_LOAD_AWAITER_TYPE) ? removeAwaitingVoyageLoadEntry(state, ltcId, VOYAGE_LOAD_AWAITER_TYPE) : state
);

/* EFFECTS */
export const addActivityLocationsEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(addActivityLocationsToCalculationAction),
            withLatestFrom(
                store.select(selectCurrentLaytimeCalculation),
                store.select(selectCurrentAddActivityLocationsForm),
                store.select(selectCurrentVoyage),
                store.select(selectCurrentFixture)
            ),
            map(([, calculation, form, voyage, fixture]) =>
                addActivityLocationsAction({
                    ltcId: calculation.id,
                    addActivityLocations: toAddActivityLocations(voyage, form.value, calculation, fixture)
                })
            ),
            tap((action) => updateQueue.enqueue(action, addActivityLocationsToCalculation))
        )
    );

export const addActivityLocationsNavigateEffect$ = (actions$: Actions, store: Store<LtcFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType(addActivityLocationsCancelAction, addActivityLocationsSuccessAction),
            withLatestFrom(store.select(selectCurrentCalculationId)),
            map(([, ltcId]) => navigateToLaytimeCalculationAction({ ltcId }))
        )
    );

export const addActivityLocationsToCalculation = (
    { ltcId, addActivityLocations }: ReturnType<typeof addActivityLocationsAction>,
    laytimeCalculationHttpService: LaytimeCalculationHttpService
) =>
    laytimeCalculationHttpService.addActivityLocations(ltcId, addActivityLocations).pipe(
        map(() => addActivityLocationsSuccessAction({ ltcId, addActivityLocations })),
        catchError((error) => of(addActivityLocationsFailAction({ ltcId, error })))
    );

/* FUNCTIONS */
export const mergeActivityLocations = R.curry((addActivityLocations: ReadonlyArray<AddActivityLocation>, voyage: Voyage, calculation: LaytimeCalculation) => {
    const cargoNames = R.pipe(
        R.map((c: Cargo) => ({ [c.cargoProduct.id]: c.cargoProduct.name })),
        R.mergeAll
    )(voyage.cargoes.filter((x) => x.cargoProduct));

    const locations = R.pipe(
        R.map((d: Destination) => ({ [d.location.locationId]: d.location })),
        R.mergeAll
    )(voyage.destinations.filter((v) => v.location));

    const newActivityLocations = toNewActivityLocations(calculation.activityLocations, addActivityLocations, cargoNames, locations);
    const updatedActivityLocations = toUpdatedActivityLocations(calculation.activityLocations, addActivityLocations, cargoNames);

    return { ...calculation, activityLocations: [...updatedActivityLocations, ...newActivityLocations] };
});

const toNewActivityLocations = (
    activityLocations: ReadonlyArray<ActivityLocation>,
    addActivityLocations: ReadonlyArray<AddActivityLocation>,
    cargoNames: { [name: number]: string },
    locations: { [name: string]: SeaNetLocation }
): ReadonlyArray<ActivityLocation> =>
    R.pipe(
        R.differenceWith((addActivityLocation, activityLocation: ActivityLocation) => activityLocation.id === addActivityLocation.id, addActivityLocations),
        R.map((addActivityLocation) => {
            const { cargoes: addCargoes, laytimeEvents: addLaytimeEvents, locationId: addLocationId } = addActivityLocation;

            const cargoes = addCargoes?.map((addCargo) => ({ ...addCargo, name: cargoNames[addCargo.productId] })) ?? [];
            const laytimeEvents = addLaytimeEvents?.map((addLaytimeEvent) => setCargoFields(addLaytimeEvent, cargoes)) ?? [];

            return {
                ...addActivityLocation,
                timeZone: locations[addLocationId].timeZone,
                cargoes,
                laytimeEvents,
                countryUnCode: locations[addLocationId].countryUnCode
            };
        })
    )(activityLocations);

const toUpdatedActivityLocations = (
    activityLocations: ReadonlyArray<ActivityLocation>,
    addActivityLocations: ReadonlyArray<AddActivityLocation>,
    cargoNames: { [name: number]: string }
) =>
    activityLocations.map((activityLocation) => {
        const addActivityLocation = addActivityLocations.find((x) => x.id === activityLocation.id);
        if (!addActivityLocation) {
            return activityLocation;
        }

        const workingDay = activityLocation.workingDay ?? addActivityLocation.workingDay;
        const exclusionStartDay = activityLocation.exclusionStartDay ?? addActivityLocation.exclusionStartDay;
        const exclusionStartTime = activityLocation.exclusionStartTime ?? addActivityLocation.exclusionStartTime;
        const exclusionEndDay = activityLocation.exclusionEndDay ?? addActivityLocation.exclusionEndDay;
        const exclusionEndTime = activityLocation.exclusionEndTime ?? addActivityLocation.exclusionEndTime;

        const cargoes = R.pipe(
            R.differenceWith((addActivityCargo, activityCargo: ActivityCargo) => addActivityCargo.id === activityCargo.id, addActivityLocation.cargoes ?? []),
            R.map((addActivityCargo) => ({ ...addActivityCargo, name: cargoNames[addActivityCargo.productId] })),
            R.concat(activityLocation.cargoes)
        )(activityLocation.cargoes);

        const laytimeEvents = R.pipe(
            R.differenceWith((addLaytimeEvent, laytimeEvent: LaytimeEvent) => addLaytimeEvent.id === laytimeEvent.id, addActivityLocation.laytimeEvents ?? []),
            R.map((addLaytimeEvent) => setCargoFields(addLaytimeEvent, cargoes)),
            R.concat(activityLocation.laytimeEvents)
        )(activityLocation.laytimeEvents);

        return {
            ...activityLocation,
            cargoes,
            laytimeEvents,
            workingDay,
            exclusionStartDay,
            exclusionStartTime,
            exclusionEndDay,
            exclusionEndTime
        };
    });

const setCargoFields = (laytimeEvent: LaytimeEvent, cargoes: Readonly<{ id: ActivityCargoId; name?: string }>[]) => {
    const cargoName = laytimeEvent.cargoId && cargoes.find((c) => c.id)?.name;
    const cargoId = cargoName && laytimeEvent.cargoId;
    return { ...laytimeEvent, cargoId, cargoName };
};

export const toAddActivityLocations = (
    voyage: Voyage,
    form: AddActivityLocationsForm,
    calculation: LaytimeCalculation,
    fixture: FixtureIndex
): ReadonlyArray<AddActivityLocation> => {
    const addActivityLocations: { [voyageActivityId: string]: AddActivityLocation } = {};
    const activityLocationIds = R.pipe(
        R.map((al: ActivityLocation) => ({ [al.voyageActivityId]: al.id })),
        R.mergeAll
    )(calculation.activityLocations);
    const voyageActivities = R.pipe(
        R.map((va: VoyageActivity) => ({ [va.voyageActivityId]: va })),
        R.mergeAll
    )(toVoyageActivities(voyage));

    // this is to cover the case when Locations filter is active and user has selected a voyage activity (LHS),
    // but there are no associated cargoes on the RHS (Transit / Interim, no associated cargoes yet for Load / Discharge, or just no selection at all)
    form.voyageActivities
        .filter((x) => x.selected)
        .forEach((voyageActivityFilter) => {
            const voyageActivity = voyageActivities[voyageActivityFilter.voyageActivityId];
            addActivityLocations[voyageActivity.voyageActivityId] = toAddActivityLocation(
                voyageActivity,
                activityLocationIds[voyageActivity.voyageActivityId] ?? createActivityLocationId()
            );
        });

    form.associatedCargoes
        .filter((x) => x.selected)
        .forEach((associatedCargoSelection) => {
            const voyageActivity = voyageActivities[associatedCargoSelection.voyageActivityId];

            let addActivityLocation = addActivityLocations[voyageActivity.voyageActivityId];
            if (!addActivityLocation) {
                addActivityLocation = toAddActivityLocation(voyageActivity, activityLocationIds[voyageActivity.voyageActivityId] ?? createActivityLocationId());
                addActivityLocations[voyageActivity.voyageActivityId] = addActivityLocation;
            }

            const associatedCargo = voyageActivity.associatedCargoes.find((x) => x.id === associatedCargoSelection.associatedCargoId);
            const cargoAllowedRate = voyage.cargoAllowedRates.find((x) => x.cargoId === associatedCargoSelection.cargoId);
            const cargoRateActivity = voyageActivity.activity === "Load" ? cargoAllowedRate?.loadCargoRateActivity : cargoAllowedRate?.dischargeCargoRateActivity;
            const cargo = voyage.cargoes.find((x) => x.id === associatedCargoSelection.cargoId);

            addActivityLocations[voyageActivity.voyageActivityId] = {
                ...addActivityLocation,
                cargoes: [...(addActivityLocation.cargoes ?? []), toAddActivityCargo(associatedCargo, cargo, cargoRateActivity)]
            };
        });

    if (form.importPortTimes) {
        importPortTimesToAddActivityLocations(addActivityLocations, voyageActivities, voyage, calculation, fixture);
    }

    return R.values(addActivityLocations);
};

export const importPortTimesToAddActivityLocations = (
    addActivityLocations: { [voyageActivityId: string]: AddActivityLocation },
    voyageActivities: { [voyageActivityId: string]: VoyageActivity },
    voyage: Voyage,
    calculation: LaytimeCalculation,
    fixture: FixtureIndex
) => {
    const addedLaytimeEventIdsByVoyageActivityId: { [voyageActivityId: string]: string[] } = {};

    R.values(addActivityLocations).forEach((addActivityLocation) => {
        const calcActivityLocation = calculation.activityLocations.find((a) => a.voyageActivityId === addActivityLocation.voyageActivityId);
        const voyageActivityById = voyageActivities[addActivityLocation.voyageActivityId];
        const voyageActivity = {
            ...voyageActivityById,
            workingDay: voyageActivityById.workingDay ?? calcActivityLocation?.workingDay,
            exclusionStartDay: voyageActivityById.exclusionStartDay ?? calcActivityLocation?.exclusionStartDay,
            exclusionStartTime: voyageActivityById.exclusionStartTime ?? calcActivityLocation?.exclusionStartTime,
            exclusionEndDay: voyageActivityById.exclusionEndDay ?? calcActivityLocation?.exclusionEndDay,
            exclusionEndTime: voyageActivityById.exclusionEndTime ?? calcActivityLocation?.exclusionEndTime
        };
        const laytimeEvents = toAddLaytimeEvents(voyageActivity, fixture, voyage);
        addActivityLocations[voyageActivity.voyageActivityId] = {
            ...addActivityLocation,
            laytimeEvents
        };
        addedLaytimeEventIdsByVoyageActivityId[voyageActivity.voyageActivityId] = laytimeEvents.map((l) => l.id);
    });

    const mergedCalculation = mergeActivityLocations(R.values(addActivityLocations), voyage)(calculation);

    R.keys(addedLaytimeEventIdsByVoyageActivityId)
        .filter((voyageActivityId) => addedLaytimeEventIdsByVoyageActivityId[voyageActivityId].length > 0)
        .forEach((voyageActivityId) => {
            const activityLocation = mergedCalculation.activityLocations.find((a) => a.voyageActivityId === voyageActivityId);

            addedLaytimeEventIdsByVoyageActivityId[voyageActivityId].forEach((leId) => {
                const laytimeEvent = activityLocation.laytimeEvents.find((l) => l.id === leId);
                const index = activityLocation.laytimeEvents.indexOf(laytimeEvent);

                if (index > 0 && !hasValue(laytimeEvent.percentage)) {
                    const previousEvent = activityLocation.laytimeEvents[index - 1];
                    const laytimeEvents = addActivityLocations[voyageActivityId].laytimeEvents;
                    const li = laytimeEvents.findIndex((l) => l.id === laytimeEvent.id);
                    let percentage = !hasExclusion(activityLocation) || isLaytimeEventAfterDemurrageEvent(mergedCalculation, activityLocation, laytimeEvent) ? 100 : null;

                    if (!hasValue(percentage) && hasValue(laytimeEvent.date) && hasValue(previousEvent.date)) {
                        percentage = getLaytimeEventPercentage(laytimeEvent.type, previousEvent.date, laytimeEvent.date, activityLocation);
                    }

                    addActivityLocations[voyageActivityId] = {
                        ...addActivityLocations[voyageActivityId],
                        laytimeEvents: R.assocPath([li, "percentage"], roundAndStringifyNumber(percentage, 2), laytimeEvents)
                    };
                }
            });
        });
};

export const toAddActivityCargo = (associatedCargo: AssociatedCargo, cargo: Cargo, cargoRateActivity?: CargoRateActivity): AddActivityCargo => ({
    id: associatedCargo.id as ActivityCargoId,
    cargoId: cargo.id as CargoId,
    productId: cargo.cargoProduct.id,
    allowance: roundAndStringifyNumber(cargoRateActivity?.cpRate, LTC_PRECISION),
    allowanceUnit: cargoRateActivity?.cpRateUnit?.name as AllowanceUnit,
    extraHours: roundAndStringifyNumber(cargoRateActivity?.extraHours, LTC_PRECISION),
    reversible: cargoRateActivity?.reversibleLaytimeType?.name as Reversible,
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    quantity: roundAndStringifyNumber(associatedCargo.mt, 4),
    quantityUnit: "MT",
    orderId: cargo.orderId
});

export const toAddActivityLocation = (voyageActivity: VoyageActivity, activityLocationId: ActivityLocationId): AddActivityLocation => ({
    id: activityLocationId,
    locationId: voyageActivity.locationId,
    name: voyageActivity.name,
    activity: voyageActivity.activity,
    voyageActivityId: voyageActivity.voyageActivityId,
    workingDay: voyageActivity.workingDay,
    exclusionStartDay: voyageActivity.exclusionStartDay,
    exclusionStartTime: voyageActivity.exclusionStartTime,
    exclusionEndDay: voyageActivity.exclusionEndDay,
    exclusionEndTime: voyageActivity.exclusionEndTime
});

export const getDefaultLaytimeEventsForActivity = (voyageActivity: VoyageActivity, fixture: FixtureIndex, voyage: Voyage): ReadonlyArray<VoyageLaytimeEvent> => {
    const fixtureType = fixture.type !== "Voyage" ? FixtureType.TimeCharter : FixtureType.Voyage;
    const division = fixture.divisionId;
    const timeZone = voyageActivity?.timeZone;

    let arrivalDateTime = "";
    let presetEventDates = false;
    let activityType: CargoBerthActivityType;

    voyage.destinations.forEach((destination) => {
        destination.berths.forEach((berth, berthIndex) => {
            berth.cargoBerthActivities.forEach((cargoBerthActivity, activityIndex) => {
                if (cargoBerthActivity.id === voyageActivity?.voyageActivityId) {
                    arrivalDateTime = destination.arrivalDateTime;
                    activityType = cargoBerthActivity.type;

                    if (berthIndex === 0 && activityIndex === 0) {
                        presetEventDates = true;
                    }
                }
            });
        });
    });

    const defaultLaytimeEvents = getDefaultLaytimeEvents(fixtureType, division, activityType, presetEventDates, arrivalDateTime, timeZone);

    return defaultLaytimeEvents.map((laytimeEvent) => ({
        id: laytimeEvent.laytimeEventId,
        type: laytimeEvent.type.value,
        eventDate: laytimeEvent.eventDate
    }));
};

export const toAddLaytimeEvents = (voyageActivity: VoyageActivity, fixture: FixtureIndex, voyage: Voyage): ReadonlyArray<AddLaytimeEvent> => {
    const laytimeEvents = voyageActivity.laytimeEvents || getDefaultLaytimeEventsForActivity(voyageActivity, fixture, voyage);
    const addLaytimeEvents: AddLaytimeEvent[] = [];
    const activityHasStartStop = !!voyageActivity.laytimeEvents?.filter((a) => a.isStartOrStop).length;

    let isCalculationStarted = false;
    laytimeEvents.forEach((laytimeEvent, index) => {
        const date = DateTime.fromISO(laytimeEvent.eventDate, { zone: voyageActivity.timeZone }).toISO({ includeOffset: false });
        const type = laytimeEvent.type?.name as LaytimeEventType;

        let percentage = index === 0 ? 0 : null;
        if (percentage === null && activityHasStartStop) {
            percentage = isCalculationStarted ? laytimeEvent.percentage ?? null : 0;
        }

        addLaytimeEvents.push({
            id: laytimeEvent.id as LaytimeEventId,
            type,
            date,
            percentage: roundAndStringifyNumber(percentage, 2),
            cargoId: laytimeEvent.cargoId ? (voyageActivity.associatedCargoes.find((x) => x.cargoId === laytimeEvent.cargoId)?.id as ActivityCargoId) : null,
            comments: laytimeEvent.comments,
            remarks: laytimeEvent.demurrageReason?.name as LaytimeEventRemark
        });

        isCalculationStarted = isCalculationStarted ? !laytimeEvent.isStartOrStop : !!laytimeEvent.isStartOrStop;
    });

    return addLaytimeEvents;
};
