import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { FormGroupState } from "ngrx-forms";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { VerifiedTimestamp } from "../../../../shared/components/timestamp/verified-timestamp.model";
import {
    CargoForm,
    DestinationId,
    FixtureFeatureState,
    selectCurrentFixtureLayCan,
    selectCurrentVoyageDestinationEtaVerified,
    selectCurrentVoyageDestinationHasEtaWarning,
    selectCurrentVoyageDestinationIsTransient,
    selectCurrentVoyageSpecialisedCargoDestinationId,
    verifyEta
} from "../../../state";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "td[ops-specialised-cargo-shell]",
    templateUrl: "./specialised-cargo-shell.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpecialisedCargoShellComponent {
    readonly form$ = new BehaviorSubject<FormGroupState<CargoForm>>(null);
    readonly laycanToDate$: Observable<DateTime>;

    readonly loadDestinationId$: Observable<DestinationId>;
    readonly loadEtaVerifyEnabled$: Observable<boolean>;
    readonly loadEtaVerifiedAudit$: Observable<VerifiedTimestamp>;
    readonly hasLoadEtaWarning$: Observable<boolean>;

    readonly dischargeDestinationId$: Observable<DestinationId>;
    readonly dischargeEtaVerifyEnabled$: Observable<boolean>;
    readonly dischargeEtaVerifiedAudit$: Observable<VerifiedTimestamp>;
    readonly hasDischargeEtaWarning$: Observable<boolean>;

    @Input()
    set form(value: FormGroupState<CargoForm>) {
        this.form$.next(value);
    }
    get form() {
        return this.form$.value;
    }

    @Input() readonly: boolean;

    constructor(private store: Store<FixtureFeatureState>) {
        this.laycanToDate$ = this.store.select(selectCurrentFixtureLayCan).pipe(map((laycan) => laycan.can));

        this.loadDestinationId$ = this.form$.pipe(
            filter((form) => !!(form && form.value)),
            switchMap((form) => this.store.select(selectCurrentVoyageSpecialisedCargoDestinationId, { cargoId: form.value.cargoId, activityType: CargoBerthActivityType.Load }))
        );

        this.loadEtaVerifyEnabled$ = this.loadDestinationId$.pipe(
            filter((destinationId) => !!destinationId),
            switchMap(this.selectVerifyEnabled)
        );
        this.loadEtaVerifiedAudit$ = this.loadDestinationId$.pipe(
            filter((destinationId) => !!destinationId),
            switchMap(this.selectVerifiedAudit)
        );
        this.hasLoadEtaWarning$ = this.loadDestinationId$.pipe(
            filter((destinationId) => !!destinationId),
            switchMap(this.selectEtaWarning)
        );

        this.dischargeDestinationId$ = this.form$.pipe(
            filter((form) => !!(form && form.value)),
            switchMap((form) =>
                this.store.select(selectCurrentVoyageSpecialisedCargoDestinationId, {
                    cargoId: form.value.cargoId,
                    activityType: CargoBerthActivityType.Discharge
                })
            )
        );
        this.dischargeEtaVerifyEnabled$ = this.dischargeDestinationId$.pipe(
            filter((destinationId) => !!destinationId),
            switchMap(this.selectVerifyEnabled)
        );
        this.dischargeEtaVerifiedAudit$ = this.dischargeDestinationId$.pipe(
            filter((destinationId) => !!destinationId),
            switchMap(this.selectVerifiedAudit)
        );
        this.hasDischargeEtaWarning$ = this.dischargeDestinationId$.pipe(
            filter((destinationId) => !!destinationId),
            switchMap(this.selectEtaWarning)
        );
    }

    verifyEta(destinationId: DestinationId) {
        this.store.dispatch(verifyEta({ destinationId }));
    }

    private selectVerifyEnabled = (destinationId: DestinationId) =>
        this.store.select(selectCurrentVoyageDestinationIsTransient, { destinationId }).pipe(map((isTransient) => !isTransient));

    private selectVerifiedAudit = (destinationId: DestinationId) => this.store.select(selectCurrentVoyageDestinationEtaVerified, { destinationId });

    private selectEtaWarning = (destinationId: DestinationId) => this.store.select(selectCurrentVoyageDestinationHasEtaWarning, { destinationId });
}
