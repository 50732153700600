import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

export class Time {
    static from(time: NgbTimeStruct): Time {
        if (time instanceof Time) {
            return time;
        }
        return time ? new Time(time.hour, time.minute, time.second) : null;
    }

    constructor(public hour: number, public minute: number, public second: number) {}

    equals(other: NgbTimeStruct): boolean {
        return other && this.hour === other.hour && this.minute === other.minute && this.second === other.second;
    }

    before(other: NgbTimeStruct): boolean {
        if (!other) {
            return false;
        }

        if (this.hour === other.hour) {
            if (this.minute === other.minute) {
                return this.second === other.second ? false : this.second < other.second;
            } else {
                return this.minute < other.minute;
            }
        } else {
            return this.hour < other.hour;
        }
    }

    after(other: NgbTimeStruct): boolean {
        if (!other) {
            return false;
        }
        if (this.hour === other.hour) {
            if (this.minute === other.minute) {
                return this.second === other.second ? false : this.second > other.second;
            } else {
                return this.minute > other.minute;
            }
        } else {
            return this.hour > other.hour;
        }
    } 
}
