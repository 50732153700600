type KeyWithType<T, TK> = keyof Pick<T, { [K in keyof T]: T[K] extends TK ? K : never }[keyof T]>;

/**
 * Returns the next id to use for a collection with integer identifiers.
 *
 * @param items  The existing items.
 * @param key    The identifier property.
 */
export function getNextId<T>(items: ReadonlyArray<T>, key: KeyWithType<T, number>) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Math.max(...items.map((x) => <any>x[key]), 0) + 1;
}

/**
 * Compares values given and returns sort function values (-1, 0, 1).
 *
 * @param a  First value.
 * @param b  Second value.
 */
export function compareValues<T>(a: T, b: T) {
    if (a && b) {
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
    } else if (a) {
        return -1;
    } else if (b) {
        return 1;
    }

    return 0;
}
