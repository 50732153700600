import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";

import { ValidationTextFns } from "../../../../shared/components/validation/validation.component";
import { parseISODate } from "../../../../shared/date-utils/date-utilities";
import { Command } from "../../../mediator";
import { DeliveryHeaderDate } from "../../../services/delivery-header-date";
import { DeliveryHeaderDateService } from "../../../services/delivery-header-date.service";
import { getLastPeriodTo } from "../../../shared/fixture-destination.utils";
import { FormComponentBase } from "../../../shared/form-component-base";
import { Period } from "../../../shared/models";
import { Bunker } from "../../../shared/models/dtos/bunker.dto";
import { Notice } from "../../../shared/models/dtos/notice.dto";
import { Redelivery } from "../../../shared/models/dtos/redelivery.dto";
import { NoticeFormModel } from "../../../shared/models/form-models/notice.model";
import { PeriodFormModel } from "../../../shared/models/form-models/period.model";
import { RedeliveryFormModel } from "../../../shared/models/form-models/redelivery.model";
import { AddBunkerCommand } from "./commands/add-bunker.command";
import { DeleteBunkerCommand } from "./commands/delete-bunker.command";
import { UpdateBunkerCommand } from "./commands/update-bunker.command";
import { UpdateRedeliveryCommand } from "./commands/update-redelivery.command";
import { RedeliveryLocatoinsValidator } from "./validators/redelivery-locations.validator";
import { RedeliveryNoticesValidator } from "./validators/redelivery-notice-validator";

@Component({
    selector: "ops-redelivery",
    templateUrl: "./redelivery.component.html",
    styleUrls: ["./redelivery.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedeliveryComponent extends FormComponentBase implements OnInit, OnChanges, OnDestroy {
    private fixtureSubscription: Subscription;

    readonly locationsValidationFns: ValidationTextFns = {
        exactPortIsNotSpecified: () => "Specify the exact port for Redelivery"
    };

    headerDate: DeliveryHeaderDate;
    redeliveryForm: UntypedFormGroup;
    redeliveryLocationCount: number;
    hideBunkers: boolean;
    hideComments: boolean;
    toleranceUnit = "days";

    earliestRedeliveryDate: UntypedFormControl;
    latestRedeliveryDate: UntypedFormControl;

    estimatedRedeliveryDate: UntypedFormControl;
    actualRedeliveryDate: UntypedFormControl;

    @Input() redelivery: Redelivery;
    @Input() periods: Period[];
    @Input() parentForm: UntypedFormGroup;

    @Output() redeliveryUpdated = new EventEmitter<Command>();

    get lastPeriodTo(): Date {
        return getLastPeriodTo(this.periods);
    }

    constructor(private formBuilder: UntypedFormBuilder, private deliveryHeaderDateService: DeliveryHeaderDateService) {
        super();
    }

    private static getLatestRedeliveryDate(latestPeriod: PeriodFormModel): Date {
        if (this.periodIsInvalid(latestPeriod)) {
            return null;
        }

        let latestRedeliveryDate = latestPeriod.periodTo;
        if (latestPeriod.durationType) {
            const durationType = latestPeriod.durationType.name[0].toLowerCase() + latestPeriod.durationType.name.substr(1);

            if (latestPeriod.maxDuration && latestPeriod.maxDuration > latestPeriod.minDuration) {
                latestRedeliveryDate = DateTime.fromJSDate(latestRedeliveryDate)
                    .plus({ [durationType]: latestPeriod.maxDuration - latestPeriod.minDuration })
                    .toJSDate();
            }
        }

        if (latestPeriod.toleranceInDays) {
            latestRedeliveryDate = DateTime.fromJSDate(latestRedeliveryDate).plus({ days: latestPeriod.toleranceInDays }).toJSDate();
        }

        return latestRedeliveryDate;
    }

    private static getEarliestRedeliveryDate(latestPeriod: PeriodFormModel): Date {
        if (this.periodIsInvalid(latestPeriod)) {
            return null;
        }

        let earliestRedeliveryDate = latestPeriod.periodTo;

        if (latestPeriod.toleranceInDays) {
            earliestRedeliveryDate = DateTime.fromJSDate(earliestRedeliveryDate).minus({ days: latestPeriod.toleranceInDays }).toJSDate();
        }

        return earliestRedeliveryDate;
    }

    private static periodIsInvalid(period: PeriodFormModel) {
        return !period || ((period.minDuration || period.maxDuration || period.durationType) && !(period.minDuration && period.maxDuration && period.durationType));
    }

    private static sortPeriods(periods: PeriodFormModel[]) {
        return periods.sort((a, b) => {
            if (a === null || b === null || a.periodTo === null || b.periodTo === null) {
                return 0;
            }

            const aDate = a.periodTo.getTime();
            const bDate = b.periodTo.getTime();

            return aDate - bDate;
        });
    }

    get totalQuantity() {
        const sum = (total: number, currentValue: number) => total + (currentValue || 0);
        return this.redelivery.bunkers.map((bunker) => bunker.quantity).reduce(sum, 0);
    }

    ngOnInit(): void {
        this.createForm();
        this.parentForm.registerControl("redelivery", this.redeliveryForm);
        if (this.parentForm.disabled) {
            this.redeliveryForm.disable();
        } else {
            this.redeliveryForm.enable();
        }

        this.headerDate = {
            header: null,
            tooltip: null
        };

        this.setForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes || !this.redeliveryForm || !changes.redelivery || changes.redelivery.firstChange) {
            return;
        }
        this.setForm();
    }

    ngOnDestroy(): void {
        this.removeFormSubscriptions();
        this.parentForm.removeControl("redelivery");

        if (this.fixtureSubscription) {
            this.fixtureSubscription.unsubscribe();
            this.fixtureSubscription = null;
        }
    }

    bunkerUpdated(event: Bunker[]) {
        this.redeliveryUpdated.next(new UpdateBunkerCommand(event));
    }

    bunkerAdded() {
        this.redeliveryUpdated.next(new AddBunkerCommand());
    }

    bunkerRemoved(index: number): void {
        this.redeliveryUpdated.next(new DeleteBunkerCommand(index));
    }

    getLatestAvailablePeriod(periods: Period[]) {
        const formPeriods = periods.map(
            (p) =>
                ({
                    hireId: p.hireId,
                    toleranceInDays: p.toleranceInDays,
                    minDuration: p.minDuration,
                    maxDuration: p.maxDuration,
                    periodTo: parseISODate(p.periodRange.to),
                    periodFrom: parseISODate(p.periodRange.from),
                    hireRate: p.hireRate,
                    durationInDays: p.durationInDays,
                    invoicesPaidUpTo: parseISODate(p.invoicesPaidUpTo),
                    hireRateUnitName: p.hireRateUnit !== null ? p.hireRateUnit.name : null,
                    durationType: p.durationType
                } as PeriodFormModel)
        );

        const sortedPeriods = RedeliveryComponent.sortPeriods(formPeriods);

        return sortedPeriods[sortedPeriods.length - 1];
    }

    private createForm() {
        this.earliestRedeliveryDate = new UntypedFormControl();
        this.earliestRedeliveryDate.disable();

        this.latestRedeliveryDate = new UntypedFormControl();
        this.latestRedeliveryDate.disable();

        this.estimatedRedeliveryDate = new UntypedFormControl();
        this.actualRedeliveryDate = new UntypedFormControl();

        this.redeliveryForm = this.formBuilder.group(
            {
                redeliveryLocations: [],
                redeliveryNotices: [],
                comments: [],
                estimatedRedeliveryDate: [],
                actualRedeliveryDate: []
            },
            {
                validator: Validators.compose([RedeliveryLocatoinsValidator.validate(), RedeliveryNoticesValidator.validate()])
            }
        );
    }

    get invalid() {
        return this.redeliveryForm.invalid;
    }

    get touched() {
        const controlKeys = Object.keys(this.redeliveryForm.controls);
        return controlKeys.some((key) => this.redeliveryForm.controls[key].invalid && this.redeliveryForm.controls[key].touched);
    }

    private getRedeliveryLocationCount() {
        if (this.redelivery === undefined || this.redelivery === null || this.redelivery.redeliveryLocations === null) {
            return 0;
        }

        return this.redelivery.redeliveryLocations.length;
    }

    private setForm() {
        const latestPeriod = this.getLatestAvailablePeriod(this.periods);
        this.removeFormSubscriptions();

        this.earliestRedeliveryDate.setValue(RedeliveryComponent.getEarliestRedeliveryDate(latestPeriod), { emitEvent: false });
        this.latestRedeliveryDate.setValue(RedeliveryComponent.getLatestRedeliveryDate(latestPeriod), { emitEvent: false });

        const redeliveryNotices = this.redelivery.redeliveryNotices.map(
            (notice: Notice) =>
                ({
                    days: notice.days,
                    noticeType: notice.noticeType
                } as NoticeFormModel)
        );

        const estimatedRedeliveryDate = DateTime.fromISO(this.redelivery.estimatedRedeliveryDate);
        const actualRedeliveryDate = DateTime.fromISO(this.redelivery.actualRedeliveryDate);
        const dataModel: RedeliveryFormModel = {
            redeliveryLocations: this.redelivery.redeliveryLocations,
            redeliveryNotices,
            comments: this.redelivery.comments,
            estimatedRedeliveryDate: estimatedRedeliveryDate.isValid ? estimatedRedeliveryDate.toJSDate() : null,
            actualRedeliveryDate: actualRedeliveryDate.isValid ? actualRedeliveryDate.toJSDate() : null
        };

        this.redeliveryForm.patchValue(dataModel, { emitEvent: false });
        this.redeliveryLocationCount = this.getRedeliveryLocationCount();
        this.headerDate = this.getHeaderDate();
        this.subscribeToFormChanges();
    }

    private getHeaderDate(): DeliveryHeaderDate {
        const { actualRedeliveryDate, estimatedRedeliveryDate, redeliveryLocations } = this.redelivery;
        return this.deliveryHeaderDateService.getHeaderDate(actualRedeliveryDate, estimatedRedeliveryDate, "Actual Redelivery", "Estimated Redelivery", redeliveryLocations);
    }

    private subscribeToFormChanges() {
        this.subscribeToFormValueChanges(this.redeliveryForm, (value: RedeliveryFormModel) => {
            this.redeliveryUpdated.next(new UpdateRedeliveryCommand(value));
        });
    }
}
