<div class="side-panel" data-test-warnings-panel>
    <span class="side-panel-title">
        Fixture Warnings
        <div class="left-toolbar-expander" (click)="collapseLeftBar()">
            <div class="has-icon icon--close"></div>
        </div>
    </span>

    <div *ngIf="(warningsFilterOptions$ | async) as filterOptions" class="warnings-filter-panel">
        <div ngbDropdown role="group" placement="bottom-right" class="p-0" autoClose="outside">
            <button
                [ngClass]="anyFilterOptionSelected ? 'has-icon icon--filter float-right' : 'has-icon icon--empty-filter float-right'"
                ngbDropdownToggle [ngbTooltip]="tooltipContent$ | async" container="body" placement="left"></button>
            <div class="dropdown-menu rounded-0 p-0 m-0" ngbDropdownMenu>
                <div class="form-group d-flex dropdown-item m-0">
                    <input type="checkbox" name="all" [ngModel]="allWarningsSelected" />
                    <span>{{filterOptions.all}}</span>
                    <div class="w-100 h-100 mask" (click)="toggleAllWarnings()"></div>
                </div>
                <div class="form-group d-flex dropdown-item m-0">
                    <input type="checkbox" name="active" [ngModel]="activeWarningsSelected" />
                    <span>{{filterOptions.active}}</span>
                    <div class="w-100 h-100 mask" (click)="toggleActiveWarnings()"></div>
                </div>
                <div class="form-group d-flex dropdown-item m-0">
                    <input type="checkbox" name="dismissed" [ngModel]="dismissedWarningsSelected" />
                    <span>{{filterOptions.dismissed}}</span>
                    <div class="w-100 h-100 mask" (click)="toggleDismissedWarnings()"></div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="(isVoyageFixture$ | async)" class="voyage-panel">
        <div class="scrollable-content" *ngIf="(warningStates$ | async) as warningStates; else loadingPanel">
            <ng-container *ngFor="let warningState of warningStates">
                <ops-fixture-warning [warningState]="warningState"
                    (setCurrentWarning)="onSetCurrentWarning(warningState.warning)" (dismiss)="onDismiss(warningState.warning)"
                    (activate)="onActivate(warningState.warning)"></ops-fixture-warning>
            </ng-container>

            <ops-placeholder *ngIf="!warningStates.length" title="No warnings for this fixture" data-cy="no-fixture-warnings"
                data-test-no-warnings-placeholder></ops-placeholder>
        </div>

        <ng-template #loadingPanel>
            <div class="warnings-loading-panel">
                <ops-load-animation size="x-large" class="light-background"></ops-load-animation>
            </div>
        </ng-template>
    </div>

    <div *ngIf="!(isVoyageFixture$ | async)" class="tc-panel">
        <ngb-tabset justify="fill" [destroyOnHide]="false" #tabset class="fixture-warning-tabs">
            <ngb-tab>
                <ng-template ngbTabTitle><div class="wrapped-title">Time Charter</div></ng-template>
                <ng-template ngbTabContent>
                    <div class="scrollable-content" *ngIf="(parentTimeCharterWarningStates$ | async) as warningStates; else loadingPanel">
                        <ng-container *ngFor="let warningState of warningStates">
                            <ops-fixture-warning [warningState]="warningState"
                                (setCurrentWarning)="onSetCurrentWarning(warningState.warning)" (dismiss)="onDismiss(warningState.warning)"
                                (activate)="onActivate(warningState.warning)"></ops-fixture-warning>
                        </ng-container>

                        <ops-placeholder *ngIf="!warningStates.length" title="No warnings for this fixture" data-test-no-warnings-placeholder>
                        </ops-placeholder>
                    </div>

                    <ng-template #loadingPanel>
                        <div class="warnings-loading-panel">
                            <ops-load-animation size="x-large" class="light-background"></ops-load-animation>
                        </div>
                    </ng-template>
                </ng-template>
            </ngb-tab>
            <ngb-tab>
                <ng-template ngbTabTitle><div class="wrapped-title">Time Charter Voyage</div></ng-template>
                <ng-template ngbTabContent>
                    <div class="voyage-number-selector" *ngIf="(voyageNumbers$ | async) as voyageNumbers">
                        <ng-select
                            [items]="voyageNumbers"
                            [multiple]="true"
                            bindLabel="voyageNumber"
                            bindValue="voyageId"
                            placeholder="(0) Voyages Selected"
                            [searchable]="false"
                            [closeOnSelect]="false"
                            [selectableGroup]="true"
                            [(ngModel)]="selectedVoyageIds"
                            (change)="onSelectionChange()"
                        >
                            <ng-template ng-header-tmp>
                                <div class="select-all-container">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        [(ngModel)]="allSelected"
                                        (change)="toggleSelectAll($event.target.checked)"
                                        id="selectAll"
                                    />
                                    <label class="form-check-label" for="selectAll">Select all</label>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
                                <span class="voyage-number">{{ item.voyageNumber }}</span>
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value">
                                    <span class="ng-value-label">{{ voyagesSelectedText }}</span>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="scrollable-content"
                        *ngIf="(filteredTimeCharterVoyageWarningStates$ | async) as warningStates; else loadingPanel">
                        <ng-container *ngFor="let warningState of warningStates">
                            <ops-fixture-warning [warningState]="warningState"
                                (setCurrentWarning)="onSetCurrentWarning(warningState.warning)" (dismiss)="onDismiss(warningState.warning)"
                                (activate)="onActivate(warningState.warning)"></ops-fixture-warning>
                        </ng-container>

                        <ops-placeholder
                            *ngIf="!warningStates.length"
                            title="No warnings for this fixture/voyage selection"
                            data-test-no-warnings-placeholder
                        ></ops-placeholder>
                    </div>

                    <ng-template #loadingPanel>
                        <div class="warnings-loading-panel">
                            <ops-load-animation size="x-large" class="light-background"></ops-load-animation>
                        </div>
                    </ng-template>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>
    </div>
</div>
