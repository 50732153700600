import { createAction, on, On, props } from "@ngrx/store";
import { updateGroup } from "ngrx-forms";

import { opsAddArrayControl } from "@ops/state";

import { ActivityId, associatedCargoExpenseForm, AssociatedCargoId, BerthId, createAssociatedCargoExpenseId, DestinationId, FixturesState } from "../../model";
import { getNextId } from "../../utils";
import { currentVoyageStateReducer } from "../../voyage/reducer";
import { updateAssociatedCargoForm } from "./shared";

/* ACTIONS */
export const addAssociatedCargoExpenseAction = createAction(
    "[Voyage Form] Add Associated Cargo Expense",
    props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId; associatedCargoId: AssociatedCargoId }>()
);

/* REDUCERS */
export const addAssociatedCargoExpenseReducer: On<FixturesState> = on(addAssociatedCargoExpenseAction, (state, action) =>
    currentVoyageStateReducer(state, (voyageState) => ({
        ...voyageState,
        form: updateAssociatedCargoForm(action, (associatedCargoForm) => {
            const associatedCargoExpenses = associatedCargoForm.value.associatedCargoExpenses;
            const newAssociatedCargoExpense = associatedCargoExpenseForm(createAssociatedCargoExpenseId(), getNextId(associatedCargoExpenses, "legacyAssociatedCargoExpenseId"));

            return updateGroup(associatedCargoForm, {
                associatedCargoExpenses: opsAddArrayControl(newAssociatedCargoExpense, { markAsEditing: true, focusControlName: "rateDescription" })
            });
        })(voyageState.form)
    }))
);
