import {
    FormArrayState,
    FormGroupState,
    StateUpdateFns,
    unbox,
    updateArray,
    updateGroup,
    validate
} from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import * as R from "ramda";

import { CargoBerthActivityType, LaytimeEventType } from "@ops/shared/reference-data";
import { validateRequiredIf } from "@ops/state";

import { FixtureStatusType } from "../../../../left-bar/shared/models";
import { Division } from "../../../shared/models";
import {
    BerthForm,
    DestinationForm,
    FixtureState,
    isDivision,
    isFixtureStatus,
    LaytimeEventForm,
    VoyageState
} from "../../model";

const EVENT_DATE_REQUIRED_TYPES = [LaytimeEventType.HosesDisconnected, LaytimeEventType.NORTendered];

function isDischargeWithLaytimeEvent(laytimeEvent: LaytimeEventType) {
    return (b: FormGroupState<BerthForm>) => b.value.activities.some(
        (cba) =>
            cba.type && cba.type.value && cba.type.value.id === CargoBerthActivityType.Discharge.id &&
            cba.laytimeEvents.some((l) => l.type && l.type.value && l.type.value.id === laytimeEvent.id)
    );
}

function lastDischargeWithLaytimeType(destinations: FormArrayState<DestinationForm>, laytimeEvent: LaytimeEventType): string {
    return R.pipe(
        R.map((d: FormGroupState<DestinationForm>) => d.controls.berths.controls.filter(isDischargeWithLaytimeEvent(laytimeEvent))),
        R.flatten,
        R.pluck("id"),
        R.last
    )(destinations.controls) as string;
}

function shouldValidateEventDateRequired(voyageState: VoyageState, fixtureState: FixtureState, form: FormGroupState<LaytimeEventForm>) {
    const type = unbox(form.value.type);

    if (!type) { return false; }
    if (!isFixtureStatus(fixtureState, FixtureStatusType.Closed)) { return false; }
    if (!EVENT_DATE_REQUIRED_TYPES.some((t) => t.id === type.id)) { return false; }

    const lastBerthId = lastDischargeWithLaytimeType(voyageState.form.controls.destinations, type);

    return form.id.startsWith(lastBerthId);
}

export const validateLaytimeEventForm = (voyageState: VoyageState, fixtureState: FixtureState) => (form: FormGroupState<LaytimeEventForm>) => {
    const conditionalValidation: StateUpdateFns<LaytimeEventForm>[] = [];

    if (isDivision(fixtureState, Division.specialisedProducts)) {
        conditionalValidation.push({
            eventDate: validateRequiredIf(shouldValidateEventDateRequired(voyageState, fixtureState, form))
        });
    }

    return updateGroup<LaytimeEventForm>({
        laytimeEventId: validate(required),
        type: validate(required)
    }, ...conditionalValidation)(form);
};

export const validateLaytimeEventsForm = (voyageState: VoyageState, fixtureState: FixtureState) =>
    updateArray<LaytimeEventForm>(validateLaytimeEventForm(voyageState, fixtureState));
