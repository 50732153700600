import { Command } from "../../../mediator/commands/command";
import { Division } from "../../../shared/models";
import { CargoId } from "../../../state/model";
import { SyncCargoAllowedRatesCommandHandler } from "./sync-cargo-allowed-rates.command-handler";

export class SyncCargoAllowedRatesCommand extends Command {
    constructor(readonly cargoIds: CargoId[], readonly division: Division) {
        super(SyncCargoAllowedRatesCommandHandler, null);
    }
}
