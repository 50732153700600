<div class="destination-form">
    <div class="eta-verified-row">
        <div>
            <ops-warning *ngIf="hasEtaWarning">
                <span ngbTooltip="Later than Laycan To" container="body" class="has-icon icon--flag"></span>
            </ops-warning>
        </div>
        <ops-verified-timestamp [verified]="etaVerifiedAudit"></ops-verified-timestamp>
    </div>
    <div class="eta-row">
        <div class="eta-label-title">ETA:</div>
        <div class="eta-label-container" (mouseleave)="onMouseLeave()">
            <div class="eta-label" [ngClass]="{ 'eta-label--hidden': showDatePicker$ | async }">
                <span *ngIf="hasValue" (mouseenter)="onMouseEnter()">
                    {{ form.value.etaRange.value | maritimeDate:true:timeZone }}
                </span>
                <ops-action-button
                    type="action"
                    icon="tag-tick"
                    ngbTooltip="Verify ETA"
                    container="body"
                    [disabled]="!etaVerifyEnabled"
                    (click)="verifyEta.emit()">
                </ops-action-button>            
            </div>
            <ops-date-input *ngIf="showDatePicker$ | async"
                [mode]="dynamic"
                [enableTime]="true"
                [timeZone]="timeZone"
                [ngrxFormControlState]="form.controls.etaRange"
                [ngrxValueConverter]="dateRangeValueConverter"
                [startDate]="etaDefaultFocusDate"
                (opened)="onOpen()"
                (closed)="onClose()"
                (focus)="onFocus()"
                (blur)="onBlur()"
                [readonly]="isReadOnly"
                [ngClass] = "{ 'form-invalid': isInvalid }"
            >
            </ops-date-input>
        </div>
    </div>
</div>