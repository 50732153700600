import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

import { FixtureType } from "../../shared/models";
import { ColorType } from "./color-type.model";
import { Color } from "./color.model";
import { ColorService } from "./color.service";

@Component({
    selector: "ops-favorite-color-picker",
    templateUrl: "./favorite-color-picker.component.html",
    styleUrls: ["./favorite-color-picker.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteColorPickerComponent implements OnInit {
    static componentName = "FavoriteColorPickerComponent";
    favoriteIcon: string;
    colors: Color[];
    currentColor: Color;
    showColorSelector: boolean;

    @ViewChild("colorPickerSelector", { static: true }) colorPickerSelector: ElementRef;
    @Output() colorSelected: EventEmitter<ColorType> = new EventEmitter();
    @Input() set fixtureType(fixtureType: FixtureType) {
        this.favoriteIcon = FixtureType[fixtureType].toString().substring(0, 1);
    }
    @Input() currentColorType: ColorType = ColorType.Teal;

    ngOnInit() {
        this.colors = ColorService.getColors();
        this.currentColor = this.colors.find((c) => c.type === this.currentColorType);
    }

    onColorPickerClick(event: MouseEvent): void {
        this.colorPickerSelector.nativeElement.style.top = `${event.pageY}px`;
        this.colorPickerSelector.nativeElement.style.left = `${event.pageX}px`;
        this.showColorSelector = true;
    }

    onColorPickerMouseLeave(): void {
        this.showColorSelector = false;
    }

    onColorSelected(event: MouseEvent, favoriteColorType: ColorType): void {
        event.stopPropagation();
        this.colorSelected.emit(favoriteColorType);
        this.showColorSelector = false;
    }
}
