import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

import { FeatureToggleService } from "@ops/core";

import { FeatureName } from "../../core/feature-toggle.service";

@Directive({
    selector: "[opsFeature]"
})
export class FeatureDirective {
    private hasView = false;

    constructor(private templateRef: TemplateRef<unknown>, private viewContainer: ViewContainerRef, private featureToggleService: FeatureToggleService) {}

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("opsFeature") set opsFeature(feature: FeatureName) {
        const enabled = feature && this.featureToggleService.isFeatureEnabled(feature);
        if (enabled && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!enabled && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}
