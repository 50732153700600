<div class="d-flex w-100 heading">
    <button
        type="button"
        (click)="toggle()"
        class="has-icon btn-toggle"
        [class.icon--arrow-drop-right]="!expanded"
        [class.icon--arrow-drop-down]="expanded"
        data-test-toggle-locations-button
    ></button>
    Locations
</div>
<ng-container *opsFixtureType="'Voyage'">
    <div class="eta-email" *ngIf="canGenerateEtaEmail">
        <div>
            <ops-action-button icon="file-email" (click)="generateEtaEmail.emit()">ETA EMAIL</ops-action-button>
        </div>
        <span *ngIf="etaEmailAudit" >Last Generated: {{ etaEmailAudit.date | timezoneDate: "DATETIME" }} by {{ etaEmailAudit.user?.fullName }}</span>
    </div>
</ng-container>
