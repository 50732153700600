import { createAction, on, On, props } from "@ngrx/store";

import { FixtureWarning } from "../../../shared/warnings/fixture-warning.model";
import { FixturesState } from "../../../state";
import { currentFixtureStateReducer } from "../../../state/fixture/reducer";

/* ACTIONS */
const RECALCULATE_ACTION_NAME = "[Warnings] Recalculate Dismissed Warnings";

export const recalculateDismissedWarningsAction = createAction(RECALCULATE_ACTION_NAME, props<{ warnings: FixtureWarning[] }>());

/* REDUCERS */
export const recalculateDismissedWarningsReducer: On<FixturesState> = on(recalculateDismissedWarningsAction, (state, { warnings }) =>
    currentFixtureStateReducer(state, (fixtureState) => ({
        ...fixtureState,
        dismissedWarnings: fixtureState?.dismissedWarnings
            ?.filter((item) => {
                const warning = warnings.find((w) => w.record?.warningHash === item.warningHash);
                return warning?.record && (warning.record.fieldValue === item.fieldValue || item.fieldValue === undefined);
            })
            .map((item) => {
                const warning = warnings.find((w) => w.record?.warningHash === item.warningHash);
                return { ...item, fieldValue: warning.record.fieldValue };
            })
    }))
);
