import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { CpRateUnit, Division, LaytimeCalculationUnitEnum, ReversibleLaytimeType } from "../../../shared/reference-data";
import { LaytimeRounding } from "../../../shared/reference-data/laytime-rounding";
import { hasItems } from "../../../shared/utils";
import { LaytimeRoundingService } from "../../services/laytime-rounding.service";
import { ActivityId, BerthId, CargoId, DestinationId } from "../../state/model";
import { mapToLaytimeEventFactModels } from "../mappers/laytime-event-fact-mapper";
import { AssociatedCargo, Berth, CargoBerthActivity, Destination } from "../models";
import { LaytimeEventFact } from "../models/dtos/laytime-event-fact.dto";
import { LaytimeEventFactModel } from "../models/form-models";
import { CargoTotalLaytime } from "../models/form-models";

@Injectable({
    providedIn: "root"
})
export class TotalActualLaytimeService {
    private totalLaytimeSubject = new Subject<Array<{ destinationId: DestinationId; totalLaytime: number }>>();
    private totalLaytimes: Array<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId; totalLaytime: number }> = [];
    private hours = 24;

    totalLaytime: number;
    totalLaytimes$ = this.totalLaytimeSubject.asObservable();

    constructor(private laytimeRoundingService: LaytimeRoundingService) {}

    clear() {
        this.totalLaytime = 0;
        this.totalLaytimes.length = 0;
        this.totalLaytimeSubject.next([]);
    }

    publishTotalLaytime(destinationId: DestinationId, berthId: BerthId, activityId: ActivityId, total: number) {
        this.updateTotalLaytimes(destinationId, berthId, activityId, total);

        const totalLaytimesByDestination = this.calculateTotalLaytimesForDestinations();

        const sum = (accumulator: number, currentValue: number) => accumulator + (currentValue || 0);
        this.totalLaytime = totalLaytimesByDestination.map((totalLaytimeByDestination) => totalLaytimeByDestination.totalLaytime).reduce(sum, 0);

        this.totalLaytimeSubject.next(totalLaytimesByDestination);
    }

    calculateTotalLaytimeForStatementOfFacts(laytimeEvents: LaytimeEventFact[]): number {
        const laytimeEventsList = mapToLaytimeEventFactModels(laytimeEvents);
        const sum = (accumulator: number, laytimeEvent: LaytimeEventFactModel) => accumulator + (laytimeEvent.timePassedTotalHours || 0);
        return laytimeEventsList.reduce(sum, 0);
    }

    calculateTimeUsedAtBerth(
        berthActivities: CargoBerthActivity[],
        isReversible: boolean,
        laytimeRounding: LaytimeRounding,
        laytimeCalculationUnit: LaytimeCalculationUnitEnum
    ): number {
        let berthTimeUsed = 0;
        if (berthActivities) {
            berthActivities.forEach((berthActivity) => {
                if (hasItems(berthActivity.laytimeEventFacts)) {
                    if (isReversible) {
                        const isReversibleCargo = berthActivity.associatedCargoes.find(
                            (c) => c.reversibleLaytimeType && c.reversibleLaytimeType.id === ReversibleLaytimeType.Reversible.id
                        );
                        if (isReversibleCargo) {
                            const cargoes = berthActivity.associatedCargoes;
                            const laytimeModels = mapToLaytimeEventFactModels(berthActivity.laytimeEventFacts);
                            const noCargoLaytime = this.calculateNoCargoLaytime(laytimeModels);
                            let cargoLaytime = 0;
                            if (cargoes.length > 0) {
                                cargoes.forEach((cargo) => {
                                    if (cargo.reversibleLaytimeType && cargo.reversibleLaytimeType.id === ReversibleLaytimeType.Reversible.id) {
                                        cargoLaytime += this.calculateCargoLaytime(laytimeModels, cargo.cargoId);
                                    }
                                });
                            }
                            berthTimeUsed += cargoLaytime + noCargoLaytime;
                        }
                    } else {
                        berthTimeUsed += this.calculateTotalLaytimeForStatementOfFacts(berthActivity.laytimeEventFacts);
                    }
                }
            });
        }

        return this.laytimeRoundingService.round(berthTimeUsed, laytimeRounding, laytimeCalculationUnit);
    }

    calculateTimeUsedAtLocation(berths: Berth[], isReversible: boolean, laytimeRounding: LaytimeRounding, laytimeCalculationUnit: LaytimeCalculationUnitEnum): number {
        let locationTimeUsed = 0;
        if (berths) {
            berths.forEach((berth) => {
                if (berth.cargoBerthActivities && berth.cargoBerthActivities.length > 0) {
                    locationTimeUsed += this.calculateTimeUsedAtBerth(berth.cargoBerthActivities, isReversible, laytimeRounding, laytimeCalculationUnit);
                }
            });
        }
        return locationTimeUsed;
    }

    calculateTimeUsedAtFixture(destinations: Destination[], isReversible: boolean, laytimeRounding: LaytimeRounding, laytimeCalculationUnit: LaytimeCalculationUnitEnum): number {
        let timeUsed = 0;
        if (destinations) {
            destinations.forEach((destination) => {
                if (destination.berths && destination.berths.length > 0) {
                    timeUsed += this.calculateTimeUsedAtLocation(destination.berths, isReversible, laytimeRounding, laytimeCalculationUnit);
                }
            });
        }

        return +timeUsed;
    }

    calculateTimeAllowedForAssociatedCargo(associatedCargo: AssociatedCargo): number {
        let timeAllowed = 0;

        if (associatedCargo && associatedCargo.cpRate && associatedCargo.cpRateUnit) {
            const blQuantity = associatedCargo.mt ? associatedCargo.mt : associatedCargo.quantity;

            if (blQuantity && (associatedCargo.cpRateUnit.id === CpRateUnit.MtPerDay.id || associatedCargo.cpRateUnit.id === CpRateUnit.MtPerHour.id)) {
                timeAllowed = blQuantity / associatedCargo.cpRate;
                if (associatedCargo.cpRateUnit.id === CpRateUnit.MtPerDay.id) {
                    timeAllowed = timeAllowed * this.hours;
                }
            }

            if (associatedCargo.cpRateUnit.id === CpRateUnit.Hours.id) {
                timeAllowed = associatedCargo.cpRate;
            }
        }

        if (!!associatedCargo.extraHours) {
            timeAllowed += associatedCargo.extraHours;
        }

        return timeAllowed;
    }

    calculateTotalTimeAllowedForReversibleCargoes(destinations: Destination[]): number {
        let totalReversibleTimeAllowed = 0;
        if (destinations && destinations.length > 0) {
            destinations.forEach((destination) => {
                if (destination.berths && destination.berths.length > 0) {
                    destination.berths.forEach((berth) => {
                        const timeAllowedAtBerth = this.calculateTimeAllowedAtBerth(berth, ReversibleLaytimeType.Reversible);
                        totalReversibleTimeAllowed += timeAllowedAtBerth;
                    });
                }
            });
        }

        return totalReversibleTimeAllowed;
    }

    hasReversibleCargoes(destinations: Destination[]): boolean {
        let hasReversible = false;
        if (destinations && destinations.length > 0) {
            destinations.forEach((destination) => {
                if (destination.berths && destination.berths.length > 0) {
                    destination.berths.forEach((berth) => {
                        if (this.isLaytimeTypeReversible(berth)) {
                            hasReversible = true;
                        }
                    });
                }
            });
        }
        return hasReversible;
    }

    calculateRemainingTimeAtBerth(
        berth: Berth,
        reversibleLaytimeType: ReversibleLaytimeType,
        laytimeRounding: LaytimeRounding,
        laytimeCalculationUnit: LaytimeCalculationUnitEnum
    ) {
        let remainingTime = 0;
        if (berth && berth.cargoBerthActivities && berth.cargoBerthActivities.length > 0) {
            const timeUsedAtBerth = this.calculateTimeUsedAtBerth(berth.cargoBerthActivities, false, laytimeRounding, laytimeCalculationUnit);
            const timeAllowedAtBerth = this.calculateTimeAllowedAtBerth(berth, reversibleLaytimeType);

            if (timeAllowedAtBerth !== 0) {
                remainingTime = timeAllowedAtBerth - timeUsedAtBerth;
            }
        }
        return remainingTime;
    }

    calculateTimeAllowedAtBerth(berth: Berth, reversibleLaytimeType: ReversibleLaytimeType): number {
        let timeAllowedAtBerth = 0;

        if (berth && berth.cargoBerthActivities && berth.cargoBerthActivities.length > 0) {
            berth.cargoBerthActivities.forEach((cargoBerthActivity) => {
                if (cargoBerthActivity.associatedCargoes && cargoBerthActivity.associatedCargoes.length > 0) {
                    cargoBerthActivity.associatedCargoes.forEach((ac) => {
                        // if no laytime type is specified, return time allowed regardless of the associated cargo laytime type
                        if (ac.reversibleLaytimeType && (!reversibleLaytimeType || ac.reversibleLaytimeType.id === reversibleLaytimeType.id)) {
                            const timeAllowedForActivity = this.calculateTimeAllowedForAssociatedCargo(ac);
                            timeAllowedAtBerth += timeAllowedForActivity;
                        }
                    });
                }
            });
        }

        return timeAllowedAtBerth;
    }

    calculateCargoLaytimes(cargoBerthActivity: CargoBerthActivity, division: Division): CargoTotalLaytime[] {
        const laytimeEvents = cargoBerthActivity.laytimeEventFacts;
        const cargoes = cargoBerthActivity.associatedCargoes;
        const cargoTotalLaytime: CargoTotalLaytime[] = [];
        if (division.id === Division.Specialised.id && cargoes.length > 1) {
            const laytimeModels = mapToLaytimeEventFactModels(laytimeEvents);
            const noCargoLaytime = this.calculateNoCargoLaytime(laytimeModels);

            cargoes.forEach((cargo) => {
                const cargoLaytime = this.calculateCargoLaytime(laytimeModels, cargo.cargoId);
                cargoTotalLaytime.push({ cargoId: cargo.cargoId, totalLaytime: cargoLaytime + noCargoLaytime });
            });
        }
        return cargoTotalLaytime;
    }

    calculateCargoLaytime(laytimeEvents: LaytimeEventFactModel[], cargoId?: CargoId): number {
        const sum = (accumulator: number, laytimeEvent: LaytimeEventFactModel) =>
            laytimeEvent.cargoId === cargoId ? accumulator + (laytimeEvent.timePassedTotalHours || 0) : accumulator;
        return laytimeEvents.reduce(sum, 0);
    }

    calculateNoCargoLaytime(laytimeEvents: LaytimeEventFactModel[]): number {
        const sum = (accumulator: number, laytimeEvent: LaytimeEventFactModel) =>
            !laytimeEvent.cargoId || laytimeEvent.cargoId === null ? accumulator + (laytimeEvent.timePassedTotalHours || 0) : accumulator;
        return laytimeEvents.reduce(sum, 0);
    }

    isLaytimeTypeReversible(berth: Berth): boolean {
        let reversibleLaytime = false;

        if (berth && berth.cargoBerthActivities && berth.cargoBerthActivities.length > 0) {
            berth.cargoBerthActivities.forEach((activity) => {
                if (activity.associatedCargoes && activity.associatedCargoes.length > 0) {
                    const reversibleCargo = activity.associatedCargoes.find(
                        (cargo) => cargo.reversibleLaytimeType && cargo.reversibleLaytimeType.id === ReversibleLaytimeType.Reversible.id
                    );
                    if (reversibleCargo) {
                        reversibleLaytime = true;
                    }
                }
            });
        }

        return reversibleLaytime;
    }

    hasNoneReversible(berth: Berth): boolean {
        if (berth && hasItems(berth.cargoBerthActivities)) {
            for (const activity of berth.cargoBerthActivities) {
                const hasNoneReversible =
                    hasItems(activity.associatedCargoes) &&
                    activity.associatedCargoes.some((cargo) => cargo.reversibleLaytimeType && cargo.reversibleLaytimeType.id === ReversibleLaytimeType.NonReversible.id);

                if (hasNoneReversible) {
                    return true;
                }
            }
        }
        return false;
    }

    private updateTotalLaytimes(destinationId: DestinationId, berthId: BerthId, activityId: ActivityId, total: number) {
        const existing = this.totalLaytimes.find((tl) => tl.destinationId === destinationId && tl.berthId === berthId && tl.activityId === activityId);
        if (existing) {
            existing.totalLaytime = total;
        } else {
            this.totalLaytimes.push({ destinationId, berthId, activityId, totalLaytime: total });
        }
    }

    private calculateTotalLaytimesForDestinations() {
        const totals: { destinationId: DestinationId; totalLaytime: number }[] = [];

        this.totalLaytimes.forEach((tl) => {
            const existing = totals.find((t) => t.destinationId === tl.destinationId);
            if (existing) {
                existing.totalLaytime += tl.totalLaytime;
            } else {
                totals.push({ destinationId: tl.destinationId, totalLaytime: tl.totalLaytime });
            }
        });

        return totals;
    }
}
