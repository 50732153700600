import { ChangeDetectionStrategy, Component } from "@angular/core";

import { GridType } from "../../../fixture/shared/models/enums/grid-type";
import { GridComponent } from "../../../shared/grid";
import { WorksheetService } from "../../../state/worksheets/services/worksheet.service";
import { LaytimeCalculationGridConfigurationService, LaytimeCalculationSearchService } from "../../services";
import { LaytimeCalculationGridRow } from "../../state";

@Component({
    selector: "ops-laytime-calculation-grid",
    templateUrl: "./laytime-calculation-grid.component.html",
    styleUrls: ["./laytime-calculation-grid.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [WorksheetService]
})
export class LaytimeCalculationGridComponent extends GridComponent {
    constructor(gridConfigurationService: LaytimeCalculationGridConfigurationService, searchService: LaytimeCalculationSearchService, worksheetService: WorksheetService) {
        super(GridType.Ltc, gridConfigurationService, searchService, worksheetService);
    }

    getRowNodeId(data: unknown) {
        const gridRow = data as LaytimeCalculationGridRow;
        return gridRow.id;
    }
}
