import { Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControlState, FormControlValueTypes } from "ngrx-forms";
import { DomHandler } from "primeng/dom";
import { EditableColumn } from "primeng/table";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FormGridDirective } from "./form-grid.directive";

@Directive({ selector: "[pEditableColumn][formGridCell]" })
export class FormGridCellDirective<TStateValue extends FormControlValueTypes> implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();
    private isInitialized: boolean;
    private isReadonly: boolean;
    private state: FormControlState<TStateValue>;
    private changes$ = new Subject();

    get tableAndCellChanges$() {
        return this.changes$.asObservable();
    }

    @Input()
    set formGridCell(newState: FormControlState<TStateValue>) {
        const oldState = this.state;
        this.state = newState;

        if (this.isInitialized && newState) {
            this.openCellIfFocusChanged(newState, oldState);
            this.setEditableColumnDisabled();
        }

        this.notifyAboutChanges();
    }

    @Input()
    set formGridCellReadonly(value: boolean) {
        this.isReadonly = value;
        this.setEditableColumnDisabled();
        this.notifyAboutChanges();
    }

    @HostBinding("class.ops-fg-cell-focused")
    get isFocused() {
        return this.state?.isFocused;
    }

    @HostBinding("class.ops-fg-cell-invalid")
    get isInvalid() {
        return this.state?.isInvalid;
    }

    @HostBinding("class.ops-fg-cell-readonly")
    get isDisabled() {
        return this.state?.isDisabled || this.isReadonly;
    }

    @HostBinding("class.ops-fg-cell-editing")
    get isEditing(): boolean {
        return (
            this.formGrid?.editingCell === this.el.nativeElement || this.isFocused // Enables focus when new row is added with focus on element
        );
    }

    constructor(public formGrid: FormGridDirective, public editableColumn: EditableColumn, public el: ElementRef) {
        formGrid.tableChanges$.pipe(takeUntil(this.destroy$)).subscribe(this.notifyAboutChanges);

        // Monkey patch PrimeNG's implementation to prevent columns becoming editable when form grid is readonly
        const openCell = this.editableColumn.openCell;
        this.editableColumn.openCell = () => {
            if (!this.formGrid?.isReadonly) {
                openCell.call(this.editableColumn);
            }
        };

        //Monkey patch for primeNg breaking the tab order when a disabled editable column with button is present
        const readonlyCellClassName = "ops-fg-cell-readonly";
        const findNextEditableColumn = this.editableColumn.findNextEditableColumn;
        this.editableColumn.findNextEditableColumn = (cell: Element) => {
            const nextCell = cell.nextElementSibling;

            if (nextCell && DomHandler.hasClass(nextCell, readonlyCellClassName)) {
                return findNextEditableColumn.call(this.editableColumn, nextCell);
            } else {
                return findNextEditableColumn.call(this.editableColumn, cell);
            }
        };

        const findPreviousEditableColumn = this.editableColumn.findPreviousEditableColumn;
        this.editableColumn.findPreviousEditableColumn = (cell: Element) => {
            const previousCell = cell.previousElementSibling;

            if (previousCell && DomHandler.hasClass(previousCell, readonlyCellClassName)) {
                return findPreviousEditableColumn.call(this.editableColumn, previousCell);
            } else {
                return findPreviousEditableColumn.call(this.editableColumn, cell);
            }
        };
    }

    ngOnInit() {
        this.isInitialized = true;

        if (this.state) {
            this.openCellIfFocusChanged(this.state, undefined);
            this.setEditableColumnDisabled();
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    private openCellIfFocusChanged(newState: FormControlState<TStateValue>, oldState: FormControlState<TStateValue> | undefined) {
        if (oldState && newState.isFocused === oldState.isFocused) {
            return;
        }

        if (newState.isFocused) {
            // setTimeout to push to end of event loop
            setTimeout(() => this.editableColumn.openCell());
        }
    }

    private setEditableColumnDisabled() {
        this.editableColumn.pEditableColumnDisabled = this.isDisabled;
    }

    private notifyAboutChanges = () => {
        this.changes$.next(true);
    };
}
