import { Duration } from "luxon";

import { ActivityLocationId, LaytimeEventId } from "../state";

/**
 * The result of a calculation.
 */
export type LaytimeCalculationResult = Readonly<{
    /**
     * The value of the calculation level claim. This is the reversible claim value for non fixed calculations.
     */
    claimValue?: number;
    /**
     * The type of the calculation level claim. This is the reversible claim type for non fixed calculations.
     */
    claimType?: ClaimType;
    /**
     * The laytime allowed for the calculation. This is the reversible laytime allowed for non fixed calculations.
     */
    laytimeAllowed?: Duration;
    /**
     * The laytime used for the calculation. This is the reversible laytime used for non fixed calculations.
     */
    laytimeUsed?: Duration;
    /**
     * The laytime remaining for the calculation. This is the reversible laytime used for non fixed calculations.
     */
    laytimeRemaining?: Duration;
    /**
     * The activity location results.
     */
    activityLocations: ReadonlyArray<ActivityLocationResult>;
    /**
     * The identifier of the activity location on which the vessel becomes on demurrage for fixed / reversible.
     */
    demurrageActivityLocationId?: ActivityLocationId;
    /**
     * The identifier of the laytime event on which the vessel becomes on demurrage for fixed / reversible.
     */
    demurrageLaytimeEventId?: LaytimeEventId;
}>;
export const createLaytimeCalculationResult = (
    activityLocations: ReadonlyArray<ActivityLocationResult>,
    values?: Partial<Omit<LaytimeCalculationResult, "activityLocations" | "laytimeRemaining">>
) => ({
    ...values,
    activityLocations,
    laytimeRemaining: values?.laytimeAllowed && values?.laytimeUsed ? values.laytimeAllowed.minus(values.laytimeUsed) : null
});

export type ActivityLocationResult = Readonly<{
    id: string;
    claimValue?: number;
    claimType?: ClaimType;
    laytimeAllowed?: Duration;
    laytimeUsed?: Duration;
    laytimeRemaining?: Duration;
    reversibleLaytimeAllowed?: Duration;
    reversibleLaytimeUsed?: Duration;
    /**
     * The identifier of the laytime event on which the vessel becomes on demurrage for this location (non reversible)
     * for non fixed calculations.
     */
    demurrageLaytimeEventId?: LaytimeEventId;
    /**
     * Null for fixed time allowance.
     */
    cargoes: ReadonlyArray<ActivityCargoResult> | null;
    laytimeEvents: ReadonlyArray<LaytimeEventResult>;
}>;
export const createActivityLocationResult = (
    id: string,
    cargoes: ReadonlyArray<ActivityCargoResult> | null,
    laytimeEvents: ReadonlyArray<LaytimeEventResult>,
    values?: Partial<Omit<ActivityLocationResult, "id" | "laytimeRemaining" | "cargoes" | "laytimeEvents">>
): ActivityLocationResult => ({
    ...values,
    id,
    cargoes,
    laytimeEvents,
    laytimeRemaining: values?.laytimeAllowed && values?.laytimeUsed ? values.laytimeAllowed.minus(values.laytimeUsed) : null
});

export type ActivityCargoResult = Readonly<{
    id: string;
    laytimeAllowed?: Duration;
    laytimeUsed: Duration;
}>;
export const createActivityCargoResult = (id: string, laytimeAllowed?: Duration, laytimeUsed?: Duration): ActivityCargoResult => ({ id, laytimeAllowed, laytimeUsed });

export type LaytimeEventResult = Readonly<{
    id: string;
    /**
     * The laytime used. Will be null if the date is not set or the event is out of order.
     */
    laytimeUsed?: Duration;
    laytimeAllowed?: Duration;
    offsetDiffWithPrevEvent?: Duration;
}>;
export const createLaytimeEventResult = (id: string, laytimeUsed?: Duration): LaytimeEventResult => ({ id, laytimeUsed });

// Note the previous implementation had we used to have "Full Claim" in order to create a single claim from
// Full Claim is a construct of claims, not the calculator. By using Full Claim we lose context of the values used.
export const claimTypes = ["Demurrage", "Despatch", "Detention"] as const;
export type ClaimType = typeof claimTypes[number];
