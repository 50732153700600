import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroupState } from "ngrx-forms";

import { WorksheetRenameForm } from "@ops/state";

import { getSheetRenameErrorMessage } from "../../state/worksheets/utils/error-to-message-converters";

@Component({
    selector: "ops-worksheet-rename-form",
    templateUrl: "./worksheet-rename-form.component.html",
    styleUrls: ["./worksheet-rename-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorksheetRenameFormComponent {
    @Input() form: FormGroupState<WorksheetRenameForm>;

    @Output() readonly cancel = new EventEmitter();
    @Output() readonly save = new EventEmitter();

    get errorMessage() {
        return getSheetRenameErrorMessage(this.form.controls.name);
    }

    get showValidationMessage() {
        const control = this.form.controls.name;
        return control.isInvalid && (control.isDirty || control.isSubmitted);
    }

    processWorksheetNameKeypress(event: KeyboardEvent) {
        if (event.key === "Escape") {
            this.cancel.emit();
        }
    }

    onSubmit() {
        if (this.form.isValid) {
            this.save.emit();
        }
    }
}
