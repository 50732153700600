import { Injectable } from "@angular/core";
import { DateTime } from "luxon";

import { CargoBerthActivityType, LaytimeEventType } from "@ops/shared/reference-data";

import { LaytimeEvent } from "../../../../../fixtures/models/laytime-event.model";
import { CargoBerthActivity, Division, Fixture, FixtureType, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class DataNorTenderedDateIsInTheFutureRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    readonly requiredLocationActivityTypes = [CargoBerthActivityType.Load.id, CargoBerthActivityType.Discharge.id];

    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();
        if (
            fixture.division.id === Division.specialisedProducts ||
            fixture.division.id === Division.dryCargo ||
            fixture.division.id === Division.gas ||
            fixture.division.id === Division.pcg
        ) {
            voyages.forEach((voyage) =>
                voyage.destinations.forEach((destination, destinationIndex) => {
                    if (!destination.etaRange) {
                        return;
                    }

                    destination.berths.forEach((berth, berthIndex) =>
                        berth.cargoBerthActivities.forEach((activity, activityIndex) => {
                            if (
                                !activity.type ||
                                !this.requiredLocationActivityTypes.includes(activity.type?.id) ||
                                !activity.laytimeEvents ||
                                !activity.associatedCargoes?.length
                            ) {
                                return;
                            }
                            const norTenderedEventIndex = activity.laytimeEvents.findIndex((ev) => ev.type?.id === LaytimeEventType.NORTendered.id);
                            const norTenderedEvent = norTenderedEventIndex !== -1 ? activity.laytimeEvents[norTenderedEventIndex] : null;
                            if (norTenderedEvent && norTenderedEvent.eventDate) {
                                const destinationTimezone = destination.location && destination.location.timeZone ? destination.location.timeZone : "utc";
                                const eventDate = DateTime.fromISO(norTenderedEvent.eventDate, { zone: destinationTimezone });
                                const now = DateTime.local();
                                if (+eventDate > +now) {
                                    const message = "NOR Tendered is in the future";
                                    const path = [
                                        ...this.getActivityPath(fixture, voyage, destinationIndex, destination, berthIndex, berth, activityIndex, activity),
                                        FixtureWarningPathSegment.fromProperty<CargoBerthActivity>("laytimeEvents", null),
                                        FixtureWarningPathSegment.fromIndex(norTenderedEventIndex, "laytimeEvents", norTenderedEvent.type.name),
                                        FixtureWarningPathSegment.fromProperty<LaytimeEvent>("eventDate", null)
                                    ];
                                    const dismissingRecord =
                                        fixture.fixtureType.id !== FixtureType.Voyage
                                            ? dismissedWarningRecord(
                                                  new WarningHashBuilder()
                                                      .withVoyage(voyage.voyageId)
                                                      .withDestination(destination.destinationId)
                                                      .withBerth(berth.berthId)
                                                      .withBerthActivity(activity.berthActivityId)
                                                      .withCategory("data")
                                                      .withMessage(message)
                                                      .build(),
                                                  +eventDate
                                              )
                                            : null;
                                    const warning = new FixtureWarning("data", message, path, dismissingRecord);
                                    warnings.push(warning);
                                }
                            }
                        })
                    );
                })
            );
        }

        return warnings;
    }
}
