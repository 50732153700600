import { createAction, on, On } from "@ngrx/store";
import { Evolver } from "ramda";
import * as R from "ramda";
import { v4 as uuid } from "uuid";

import { opsAddArrayControl } from "../../../../../../state";
import { createDeduction, LtcState } from "../../../../model";
import { currentCalculationStateReducer } from "../../../reducer";

/* ACTIONS */
const ADD_ACTION_NAME = "[Enter Deductions Form] Add Deduction";
export const addDeductionAction = createAction(ADD_ACTION_NAME);

/* REDUCERS */
export const addDeductionReducer: On<LtcState> = on(addDeductionAction, (state) => {
    const updateFns: Evolver = {
        deductionForms: opsAddArrayControl(createDeduction(uuid()), { focusControlName: "timeFrom" })
    };
    return currentCalculationStateReducer(state, R.evolve(updateFns));
});
