import { On } from "@ngrx/store";
import { Boxed, setValue, SetValueAction, updateArrayWithFilter, updateGroup } from "ngrx-forms";

import { isNullOrUndefined } from "@ops/shared";
import { Enumeration } from "@ops/shared/reference-data";

import { AssociatedCargoExpenseUnit } from "../../../shared/models/enums/associated-cargo-expense-unit";
import { AssociatedCargoExpenseForm, AssociatedCargoForm, FixturesState, VoyageState } from "../../model";
import { voyageStateReducer } from "../../voyage/reducer";
import { updateAssociatedCargoForm } from "./shared";

/**
 * Prefills the Freight Rate on an associated cargo expense from the associated cargo Actual Freight Rate when it is not set.
 */
export const prefillAssociatedCargoExpenseFreightRateReducer: On<FixturesState> = {
    types: [SetValueAction.TYPE],
    reducer: (state: FixturesState, action: SetValueAction<string>): FixturesState => {
        const controlPath = action.controlId.split(".");
        if (controlPath.length !== 12 || controlPath[controlPath.length - 3] !== "associatedCargoExpenses") {
            return state;
        }

        return voyageStateReducer(state, controlPath[0], (voyageState) => {
            const changedField = controlPath[controlPath.length - 1];

            const berthIndex = Number(controlPath[4]);
            const activityIndex = Number(controlPath[6]);
            const associatedCargoIndex = Number(controlPath[8]);
            const expenseIndex = Number(controlPath[10]);

            const voyageForm = voyageState.form.value;
            const destinationForm = voyageForm.destinations[Number(controlPath[2])];
            const berthForm = destinationForm.berths[berthIndex];
            const activityForm = berthForm.activities[activityIndex];
            const associatedCargoForm = activityForm.associatedCargoes[associatedCargoIndex];
            const actualFreightRate = associatedCargoForm.freightRate;
            const expenseForm = associatedCargoForm.associatedCargoExpenses[expenseIndex];

            let lumpsumException = expenseForm.unit?.value?.id === AssociatedCargoExpenseUnit.LumpSum && changedField !== "unit";
            if (changedField === "unit") {
                const val = <Boxed<Enumeration>>(<any>action.value);
                lumpsumException = lumpsumException || val?.value?.id === AssociatedCargoExpenseUnit.LumpSum;
            }
            if (isNullOrUndefined(actualFreightRate) || changedField === "freightRate" || !isNullOrUndefined(expenseForm.freightRate) || lumpsumException) {
                return voyageState;
            }

            return <VoyageState>{
                ...voyageState,
                form: updateAssociatedCargoForm(
                    {
                        destinationId: destinationForm.id,
                        activityId: activityForm.activityId,
                        berthId: berthForm.id,
                        associatedCargoId: associatedCargoForm.associatedCargoId
                    },
                    updateGroup<AssociatedCargoForm>({
                        associatedCargoExpenses: updateArrayWithFilter(
                            ({ value: { associatedCargoExpenseId } }) => associatedCargoExpenseId === expenseForm.associatedCargoExpenseId,
                            updateGroup<AssociatedCargoExpenseForm>({
                                freightRate: setValue(actualFreightRate)
                            })
                        )
                    })
                )(voyageState.form)
            };
        });
    }
};
