import { Injectable } from "@angular/core";
import { AllowedFileTypeGroup } from "../shared/allowed-file-type-group";

@Injectable({
    providedIn: "root"
})
export class DocumentHelperService {
    private allowedFileTypeGroups: AllowedFileTypeGroup[] = [
        {
            groupName: "sheet",
            extensions: [".csv", ".xls", ".xlsx", ".xlsm"],
            icon: "icon--file-sheet"
        },
        {
            groupName: "text",
            extensions: [".txt", ".doc", ".docx"],
            icon: "icon--file-text"
        },
        {
            groupName: "presentation",
            extensions: [".pptm", ".ppt", ".pps", ".pptx"],
            icon: "icon--file-presentation"
        },
        {
            groupName: "pdf",
            extensions: [".pdf"],
            icon: "icon--file-pdf"
        },
        {
            groupName: "others",
            extensions: [".eml", ".avi", ".cad", ".gif", ".jpeg",
                         ".jpg", ".m2v", ".mkv", ".mov", ".mp4", ".mpeg", 
                         ".mpg", ".msg", ".ogg", ".ogv", ".rar", ".tif",
                         ".tiff", ".vsd", ".vsdx", ".wmv", ".zip", ".png"],
            icon: "icon--file"
        }
    ];

    getFileIconClass(extension: string): string {
        const group = this.getGroup(extension);
        return group ? group.icon : "icon--file";
    }

    isSupportedExtension(extension: string): boolean {
        return !!this.getGroup(extension);
    }

    splitFileName(filename: string): string[] {
        if (filename && filename.length) {
            let extension = "";
            let nameOnly = filename;
            const extStart = filename.lastIndexOf(".");

            if (extStart > -1) {
                extension = filename.substring(extStart);
                nameOnly = filename.substring(0, extStart);
            }

            return [nameOnly, extension];
        }
        return null;
    }

    private getGroup(extension: string): AllowedFileTypeGroup {
        if (!extension) {
            return;
        }
        return this.allowedFileTypeGroups.find((g) => !!g.extensions.find((e) => e === extension.toLowerCase()));
    }
}
