import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import { dateToISOString, DateUtilities, parseISODate } from "../../../../../shared/date-utils/date-utilities";
import { CommandHandler } from "../../../../mediator/command-handlers/command-handler";
import { Fixture, Period } from "../../../../shared/models";
import { DeliveryFormModel } from "../../../../shared/models/form-models/delivery.model";
import { sortPeriods } from "../../periods/periods-sorter";
import { UpdateDeliveryCommand } from "./update-delivery.command";

@Injectable({
    providedIn: "root"
})
export class UpdateDeliveryCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateDeliveryCommand) {
        const value = <DeliveryFormModel>command.payload;

        if (this.shoudUpdateFirstPeriod(fixture, value)) {
            this.updateFirstPeriodFromDateToActualDeliveryDate(fixture, value);
        }

        fixture.delivery.estimatedDeliveryDate = dateToISOString(value.estimatedDeliveryDate);
        fixture.delivery.actualDeliveryDate = dateToISOString(value.actualDeliveryDate);
        fixture.delivery.deliveryDate = dateToISOString(value.estimatedDeliveryDate);
        fixture.delivery.deliveryLocations = value.deliveryLocations || [];
        fixture.delivery.deliveryNotices = value.deliveryNotices || [];
        fixture.delivery.comments = value.comments || "";
    }

    private shoudUpdateFirstPeriod(fixture: Fixture, value: DeliveryFormModel): boolean {
        return (
            value.actualDeliveryDate &&
            DateUtilities.notEquals(parseISODate(fixture.delivery.actualDeliveryDate), value.actualDeliveryDate) &&
            fixture.periods &&
            fixture.periods.length > 0
        );
    }

    private updateFirstPeriodFromDateToActualDeliveryDate(fixture: Fixture, value: DeliveryFormModel) {      
        fixture.periods = sortPeriods(fixture.periods);

        if (fixture.periods.length > 1 && fixture.periods[1].periodRange.from !== null) {
            const secondPeriodFromDate = parseISODate(fixture.periods[1].periodRange.from);
            const isActualDeliveryDateAfterSecondPeriodFromDate =
                secondPeriodFromDate && value.actualDeliveryDate && value.actualDeliveryDate.getTime() > secondPeriodFromDate.getTime();

            if (isActualDeliveryDateAfterSecondPeriodFromDate) {
                return;
            }
        }
        const [durationInSeconds, durationInDays] = this.getDurations(fixture.periods[0]);
        fixture.periods[0].periodRange.from = dateToISOString(value.actualDeliveryDate);
        fixture.periods[0].periodRange.to = dateToISOString(
            moment(value.actualDeliveryDate)
                .add(durationInSeconds, "seconds")
                .toDate()
        );
        fixture.periods[0].durationInDays = durationInDays;
    }

    private getDurations(period: Period): [number, number] {
        if (period.durationInDays) {
            return [period.durationInDays * DateUtilities.secondsInADay, period.durationInDays];
        }

        if (period.periodRange.to) {
            const durationInDays = DateUtilities.calculateDurationInDays(parseISODate(period.periodRange.to), parseISODate(period.periodRange.from));
            return [durationInDays * DateUtilities.secondsInADay, durationInDays];
        }

        return [0, 0];
    }
}
