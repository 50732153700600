import { AbstractControl, ControlContainer } from "@angular/forms";
import * as SparkMD5 from "spark-md5";

import { isNullOrUndefined } from "../../../shared/utils";
import { FixtureWarningPath } from "./fixture-warning.model";

export const getFullPath = (controlContainer: ControlContainer) => {
    const fullPath: string[] = [];
    let parent = controlContainer.control;

    while (parent) {
        if (parent.parent) {
            fullPath.push(getControlName(parent));
        }

        parent = parent.parent;
    }

    return fullPath.reverse();
};

const getControlName = (c: AbstractControl): string | null => {
    const formGroup = <any>c.parent.controls;

    if (Array.isArray(formGroup)) {
        return `[${(<AbstractControl[]>formGroup).indexOf(c)}]`;
    } else {
        return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
    }
};

export const pathToControlId = (path: FixtureWarningPath): string => {
    if (path[0].segment === "voyages") {
        path = path.concat();
        path.splice(0, 1);
    }

    return path
        .filter((x) => x.segment)
        .map((x) => x.segment.replace(/\[(\d+)\]/, "$1").replace("cargoBerthActivities", "activities"))
        .join(".");
};

export const getVoyageId = (path: FixtureWarningPath): string => {
    if (!isNullOrUndefined(path) && path.length >= 2 && path[0].segment === "voyages") {
        return path[1].segment;
    }
    return null;
};

export const computeMD5 = (value: string) => SparkMD5.hash(value);
