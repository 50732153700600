import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { CoaFeatureState, selectCurrentFilteredLiftingHistoryEvents, selectCurrentLiftingHistoryEvents, selectCurrentLiftingHistoryPlaceholderText } from "../../coa/state";
import { LeftBarStateService } from "../left-bar-state.service";
import { LiftingHistoryEvent } from "./lifting-history";

@Component({
    selector: "ops-lifting-history-shell",
    templateUrl: "./lifting-history-shell.component.html",
    styleUrls: ["./lifting-history-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiftingHistoryShellComponent {
    static componentName = "LiftingHistoryComponent";

    historyEvents$: Observable<LiftingHistoryEvent[]>;
    filteredHistoryEvents$: Observable<LiftingHistoryEvent[]>;
    placeholderText$: Observable<string>;

    constructor(private leftBarStateService: LeftBarStateService, store: Store<CoaFeatureState>) {
        this.historyEvents$ = store.select(selectCurrentLiftingHistoryEvents);
        this.filteredHistoryEvents$ = store.select(selectCurrentFilteredLiftingHistoryEvents);
        this.placeholderText$ = store.select(selectCurrentLiftingHistoryPlaceholderText);
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }
}
