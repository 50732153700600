import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { isObservable, Observable, of } from "rxjs";
import { flatMap, map } from "rxjs/operators";

import { isNullOrUndefined } from "../../../shared/utils";
import { FixtureWarningPath } from "./fixture-warning.model";
import { FixtureWarningPathTransform } from "./path-transforms/fixture-warning-path-transform";

export const FIXTURE_WARNING_PATH_TRANSFORM = new InjectionToken<string>("FIXTURE_WARNING_PATH_TRANSFORM");

@Injectable({
    providedIn: "root"
})
export class FixtureWarningPathMapper {
    private readonly pathSegmentMappings = new Map<string, string>();
    private readonly ignoredPathSegments = new Set<string>(["heading", "voyage", "timeCharter"]);
    private readonly pathTransforms = new Set<FixtureWarningPathTransform>();

    constructor(@Inject(FIXTURE_WARNING_PATH_TRANSFORM) @Optional() transforms: FixtureWarningPathTransform[]) {
        if (transforms) {
            this.pathTransforms = new Set<FixtureWarningPathTransform>(transforms);
        }
    }

    registerPathSegmentMapping(modelSegment: string, formSegment: string) {
        this.pathSegmentMappings.set(formSegment, modelSegment);
    }

    registerPathTransform(transformFn: (path: string[]) => string[]): () => void {
        const transform: FixtureWarningPathTransform = { transform: transformFn };

        this.pathTransforms.add(transform);

        return () => {
            this.pathTransforms.delete(transform);
        };
    }

    ignoreFormPathSegment(segment: string) {
        this.ignoredPathSegments.add(segment);
    }

    pathMatch(modelPath: FixtureWarningPath, formPath: string[]): Observable<boolean> {
        if (isNullOrUndefined(modelPath) || isNullOrUndefined(formPath)) {
            return of(false);
        }

        const sanitizedModelPath = modelPath.filter((x) => x.segment).map((x) => x.segment);

        return this.mapFormPath(formPath).pipe(
            map((formModelPath) => {
                if (sanitizedModelPath === formModelPath) {
                    return true;
                }

                if (sanitizedModelPath.length !== formModelPath.length) {
                    return false;
                }

                for (let i = 0; i < formModelPath.length; i++) {
                    if (sanitizedModelPath[i] !== formModelPath[i]) {
                        return false;
                    }
                }

                return true;
            })
        );
    }

    pathStartsWith(modelPath: FixtureWarningPath, formPath: string[]): Observable<boolean> {
        if (isNullOrUndefined(modelPath) || isNullOrUndefined(formPath)) {
            return of(false);
        }

        const sanitizedModelPath = modelPath.filter((x) => x.segment).map((x) => x.segment);

        return this.mapFormPath(formPath).pipe(
            map((formModelPath) => {
                for (let i = 0; i < formModelPath.length; i++) {
                    if (sanitizedModelPath[i] !== formModelPath[i]) {
                        return false;
                    }
                }

                return true;
            })
        );
    }

    private mapFormPath(path: string[]): Observable<string[]> {
        const mappedPath: string[] = [];

        for (const segment of path) {
            if (!this.ignoredPathSegments.has(segment)) {
                mappedPath.push(this.pathSegmentMappings.get(segment) || segment);
            }
        }

        return Array.from(this.pathTransforms).reduce(
            (prev, curr) =>
                prev.pipe(
                    flatMap((prevPath) => {
                        const transformedPath = curr.transform(prevPath);

                        return isObservable(transformedPath) ? <Observable<string[]>>transformedPath : of(<string[]>transformedPath);
                    })
                ),
            of(mappedPath)
        );
    }
}
