import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { FixtureWarningService } from "../../shared/warnings/fixture-warning.service";
import { pathToControlId } from "../../shared/warnings/utils";
import { FixtureFeatureState } from "../model";
import { interopCurrentWarningChange } from "../warnings";

@Injectable({
    providedIn: "root"
})
export class FixtureWarningsInterop {
    private readonly destroy$ = new Subject<void>();

    constructor(private fixtureWarnings: FixtureWarningService, private store: Store<FixtureFeatureState>) {
    }

    init() {
        this.fixtureWarnings.currentWarning$.pipe(
            filter(x => !!x),
            takeUntil(this.destroy$)
        ).subscribe((warning) => {
            const controlId = pathToControlId(warning.path);

            this.store.dispatch(interopCurrentWarningChange({ controlId }));
        });
    }

    destroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
