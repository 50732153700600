import { Duration } from "luxon";
import * as R from "ramda";

import { isNullOrUndefined, sumDuration, zeroDuration } from "@ops/shared";

import { ActivityCargo, ActivityLocation } from "../laytime-calculation";

type AllowedLaytime = Readonly<{
    activityLocations: { [activityLocationId: string]: ActivityLocationAllowedLaytime };
    /**
     * The total non reversible (not shared) allowed laytime for this calculation.
     */
    nonReversible: Duration;
    /**
     * The total reversible (shared) allowed laytime for this this calculation.
     */
    reversible: Duration;
}>;

type ActivityLocationAllowedLaytime = Readonly<{
    cargoes: { [cargoId: string]: Duration };
    /**
     * The total non reversible (not shared) allowed laytime for this activity location.
     */
    nonReversible: Duration;
    /**
     * The total reversible (shared) allowed laytime for this activity location.
     */
    reversible: Duration;
}>;

/* eslint-disable prefer-arrow/prefer-arrow-functions */
export function calculateAllowedLaytime(locations: ReadonlyArray<ActivityLocation>): AllowedLaytime;
export function calculateAllowedLaytime(location: ActivityLocation): ActivityLocationAllowedLaytime;
export function calculateAllowedLaytime(cargo: ActivityCargo): Duration | null;
export function calculateAllowedLaytime(
    input: ReadonlyArray<ActivityLocation> | ActivityLocation | ActivityCargo
): AllowedLaytime | ActivityLocationAllowedLaytime | Duration | null {
    if (Array.isArray(input)) {
        const allowedLaytime = R.pipe(R.indexBy<ActivityLocation>(R.prop("id")), R.mapObjIndexed(calculateAllowedLaytimeLocation))(input);

        return <AllowedLaytime>{
            activityLocations: allowedLaytime,
            nonReversible: Object.values(allowedLaytime)
                .map((x) => x.nonReversible)
                .reduce(sumDuration, zeroDuration())
                .normalize(),
            reversible: Object.values(allowedLaytime)
                .map((x) => x.reversible)
                .reduce(sumDuration, zeroDuration())
                .normalize()
        };
    } else if ("cargoes" in input) {
        return calculateAllowedLaytimeLocation(input);
    } else if ("allowanceUnit" in input) {
        return calculateAllowedLaytimeCargo(input);
    }
}

const calculateAllowedLaytimeLocation = (location: ActivityLocation): ActivityLocationAllowedLaytime => {
    const cargoes = new Map<string, Duration>();
    let nonReversible = zeroDuration();
    let reversible = zeroDuration();

    for (const cargo of location.cargoes) {
        const allowanceDuration = calculateAllowedLaytimeCargo(cargo);

        if (!allowanceDuration) {
            continue;
        }

        if (cargo.reversible === "Reversible") {
            reversible = reversible.plus(allowanceDuration);
        } else {
            nonReversible = nonReversible.plus(allowanceDuration);
        }

        cargoes.set(cargo.id, allowanceDuration);
    }

    return {
        cargoes: Object.fromEntries(cargoes),
        nonReversible: nonReversible.normalize(),
        reversible: reversible.normalize()
    };
};

const calculateAllowedLaytimeCargo = (cargo: ActivityCargo): Duration => {
    let allowanceDuration = zeroDuration();

    const { allowance, allowanceUnit, quantity, quantityUnit, extraHours } = cargo;

    if (allowance && allowanceUnit) {
        switch (allowanceUnit) {
            case "Hours":
                allowanceDuration = Duration.fromObject({ hours: allowance });
                break;
            case "MT/Hour":
                allowanceDuration = !isNullOrUndefined(quantity) && quantityUnit === "MT" ? Duration.fromObject({ hours: quantity / allowance }) : zeroDuration();
                break;
            case "MT/Day":
                allowanceDuration = !isNullOrUndefined(quantity) && quantityUnit === "MT" ? Duration.fromObject({ days: quantity / allowance }) : zeroDuration();
                break;
            default:
                throw Error(`Unknown allowance unit '${allowanceUnit}'`);
        }
    }

    if (cargo.extraHours) {
        allowanceDuration = allowanceDuration.plus({ hours: extraHours });
    }

    return allowanceDuration.equals(zeroDuration()) ? null : allowanceDuration?.shiftTo("days", "hours", "minutes");
};
