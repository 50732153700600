import { EmailType } from "src/app/fixture/shared/models/enums/email-type";
import { MessengerType } from "src/app/fixture/shared/models/enums/messenger-type";
import { PhoneType } from "src/app/fixture/shared/models/enums/phone-type";
import { Person, PhoneNumber } from "../models/person.model";

export class PersonUtilities {
    getPrimaryEmail(person: Person): string {
        if (person.emails && person.emails.filter((x) => x.type === EmailType.Primary).length > 0) {
            return person.emails.filter((x) => x.type === EmailType.Primary)[0].email;
        }
        return null;
    }

    getPersonalEmail(person: Person): string {
        if (person.emails && person.emails.filter((x) => x.type === EmailType.Personal).length > 0) {
            return person.emails.filter((x) => x.type === EmailType.Personal)[0].email;
        }
        return null;
    }

    getOtherEmail(person: Person): string[] {
        let restOfPrimary: string[] = [];
        if (person.emails && person.emails.filter((x) => x.type === EmailType.Primary).length > 1) {
            const length = person.emails.filter((x) => x.type === EmailType.Primary).length;
            restOfPrimary = person.emails
                .filter((x) => x.type === EmailType.Primary)
                .slice(1, length)
                .map((m) => m.email);
        }
        let restOfPersonal: string[] = [];
        if (person.emails && person.emails.filter((x) => x.type === EmailType.Personal).length > 1) {
            const length = person.emails.filter((x) => x.type === EmailType.Personal).length;
            restOfPersonal = person.emails
                .filter((x) => x.type === EmailType.Personal)
                .slice(1, length)
                .map((m) => m.email);
        }
        let restOfRest: string[] = [];
        if (person.emails && person.emails.filter((x) => x.type !== EmailType.Primary && x.type !== EmailType.Personal).length > 0) {
            restOfRest = person.emails.filter((x) => x.type !== EmailType.Primary && x.type !== EmailType.Personal).map((m) => m.email);
        }
        return [...restOfPrimary, ...restOfPersonal, ...restOfRest];
    }

    getPrimaryPhone(person: Person): string {
        if (person.phoneNumbers && person.phoneNumbers.filter((x) => x.type === PhoneType.Primary).length > 0) {
            return person.phoneNumbers.filter((x) => x.type === PhoneType.Primary)[0].number;
        }
        return null;
    }

    getAlternativePhone(person: Person): string {
        if (person.phoneNumbers && person.phoneNumbers.filter((x) => x.type === PhoneType.Alternative).length > 0) {
            return person.phoneNumbers.filter((x) => x.type === PhoneType.Alternative)[0].number;
        }
        return null;
    }

    getPhoneNumberGroups(person: Person): PhoneNumber[][] {
        const numbers = (person.phoneNumbers || []).filter((n) => n.type !== PhoneType.Primary);
        const groupSize = 2;
        const group: PhoneNumber[][] = [];
        for (let i = 0, j = numbers.length; i < j; i += groupSize) {
            const chunk = numbers.slice(i, i + groupSize);
            if (chunk && chunk.length > 0) {
                group.push(chunk);
            }
        }
        return group;
    }

    getRoles(person: Person): string {
        if (person.roles && person.roles.length > 0) {
            return person.roles.join(", ");
        }
        return null;
    }

    private getMessagingIdsByType(messagingType: string, person: Person): string[] {
        if (person.messengers && person.messengers.filter((x) => x.type === messagingType).length > 0) {
            return person.messengers.filter((x) => x.type === messagingType).map((m) => m.id);
        }
        return [];
    }

    getIceIds(person: Person): string[] {
        return this.getMessagingIdsByType(MessengerType.Ice, person);
    }

    getSkypeIds(person: Person): string[] {
        return this.getMessagingIdsByType(MessengerType.Skype, person);
    }
}
