import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: "ops-no-data-panel",
    templateUrl: "./no-data-panel.component.html",
    styleUrls: ["./no-data-panel.component.scss"],
    exportAs: "noDataPanel"
})
export class NoDataPanelComponent {
    static componentName = "NoDataPanelComponent";

    @Input() title: string;
    @Input() heading: string;
    @Input() description: string;
    @Input() useNewButton: boolean;
    @Input() newButtonText: string;
    @Input() disabled: boolean;

    @Output() addNew = new EventEmitter();

    @ViewChild("newButton") newButton: ElementRef<HTMLButtonElement>;

    raiseAddNew() {
        if (!this.disabled) {
            this.addNew.emit();
        }
    }

    focusNewButton() {
        if (this.newButton && this.newButton.nativeElement) {
            this.newButton.nativeElement.focus();
        }
    }
}
