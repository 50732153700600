import { Injectable } from "@angular/core";

import { Command } from "../../../fixture/mediator/commands/command";
import { CommandHandler } from "../../mediator";
import { Fixture } from "../../shared/models";

@Injectable({
    providedIn: "root"
})
export class UpdateOwnerEmailCommandCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateOwnerEmailCommand) {
        const ownerEmail = command.payload as string;

        fixture.ownerEmail = ownerEmail || null;
    }
}

export class UpdateOwnerEmailCommand extends Command {
    constructor(model: string) {
        super(UpdateOwnerEmailCommandCommandHandler, model);
    }
}
