import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";

import { FixturePopupTab } from "./models/fixture-popup-tab";
import { FIXTURE_POPUP_TAB_CREATOR, FixturePopupTabCreator } from "./tab-creators";
import { AuthService } from "../../core";
import { saveFixturePopupAction, selectAnimatingSaveButton, FixturePopupData, FixturePopupFeatureState, unlockFixturePopupAction } from "../../state/fixture-popup";
import { VoyageDataService } from "../services/voyage-data.service";

@Component({
    selector: "ops-fixture-popup",
    templateUrl: "./fixture-popup.component.html",
    styleUrls: ["./fixture-popup.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupComponent {
    header: string;
    tabs: FixturePopupTab[];
    isReadOnly: boolean;
    lockedBy: string | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: FixturePopupData,
        private store: Store<FixturePopupFeatureState>,
        private authService: AuthService,
        private voyageDataService: VoyageDataService,
        @Inject(FIXTURE_POPUP_TAB_CREATOR) private tabCreators: FixturePopupTabCreator[]
    ) {
        this.header = data.header;
        this.isReadOnly = (data.fixture.lockedBy && data.fixture.lockedBy.userId !== this.authService.user.userId) ?? false;
        this.lockedBy = data.fixture.lockedBy?.fullName;
        this.tabs = this.tabCreators.map((tc) => tc.createTabs(data.fixture, data.voyage, data.field, this.isReadOnly)).flat();
    }

    get animatingSaveButton$() {
        return this.store.select(selectAnimatingSaveButton);
    }

    onSave() {
        this.voyageDataService.currentVoyageForm$.pipe(take(1)).subscribe((form) => {
            if (form?.isValid && this.tabs.every((t) => !t.form || t.form.valid)) {
                this.store.dispatch(saveFixturePopupAction());
            }
        });
    }

    onCancel() {
        this.store.dispatch(unlockFixturePopupAction());
    }
}
