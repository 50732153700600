import { Injectable } from "@angular/core";

import { DemurrageClaimValueService } from "../../../../services/demurrage-claim-value.service";
import { Fixture } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class ClaimsMissingClaimValueRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    constructor(private claimValueService: DemurrageClaimValueService) {
        super();
    }

    evaluate(fixture: Fixture): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        fixture.demurrage?.claims.forEach((claim, index) => {
            if (!this.claimValueService.resolveClaimValue(claim, claim.estimatedClaimValue)) {
                const message = "Missing Claim Value";
                const path = [
                    new FixtureWarningPathSegment(null, "Voyage"),
                    new FixtureWarningPathSegment("demurrage", "Demurrage"),
                    FixtureWarningPathSegment.fromIndex(index, "Claim", `Claim ${index + 1}`),
                    new FixtureWarningPathSegment("claimValue", "Claim Value")
                ];
                const warningHash = new WarningHashBuilder()
                    .withFixture(fixture.fixtureId)
                    .withDemurrageClaim(claim.demurrageClaimId)
                    .withCategory("claim")
                    .withMessage(message)
                    .build();
                warnings.push(new FixtureWarning("claim", "Missing Claim Value", path, dismissedWarningRecord(warningHash)));
            }
        });

        return warnings;
    }
}
