import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, filter, map, switchMap, withLatestFrom } from "rxjs/operators";

import { isNullOrUndefined } from "@ops/shared";
import { emptyObjectMap } from "@ops/state";

import { liftingStateReducer } from "./reducer";
import { IndexResponse } from "../../../shared";
import { retryWithBackoff } from "../../../shared/utils/rxjs-utils";
import { LiftingHttpService } from "../../services";
import { selectCurrentCoa, selectCurrentCoaId } from "../coa";
import { routerLoadCoaLiftingsSuccessAction, routerLoadCoaLiftingsFailAction } from "../coa/routing";
import { CargoHistoricalEvent, CoaFeatureState, CoasState, initialLiftingState, Lifting, LiftingId, LiftingIndexItem, VesselHistoricalEvent } from "../model";

/* ACTIONS */
const LOAD_ACTION_NAME = "[Router] Load Lifting";
export const routerLoadLiftingAction = createAction(LOAD_ACTION_NAME, props<{ liftingId: LiftingId }>());
export const routerLoadLiftingSuccessAction = createAction(`${LOAD_ACTION_NAME} Success`, props<{ lifting: Lifting }>());
export const routerLoadLiftingFailAction = createAction(`${LOAD_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

const LOAD_VESSEL_NOMINATION_HISTORY_ACTION_NAME = "[Router] Load Vessel Nomination History";
export const routerLoadVesselNominationHistorySuccessAction = createAction(
    `${LOAD_VESSEL_NOMINATION_HISTORY_ACTION_NAME} Success`,
    props<{ liftingId: LiftingId; vesselNominationHistory: ReadonlyArray<VesselHistoricalEvent> }>()
);
export const routerLoadVesselNominationHistoryFailAction = createAction(`${LOAD_VESSEL_NOMINATION_HISTORY_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

const LOAD_CARGO_NOMINATION_HISTORY_ACTION_NAME = "[Router] Load Cargo Nomination History";
export const routerLoadCargoNominationHistorySuccessAction = createAction(
    `${LOAD_CARGO_NOMINATION_HISTORY_ACTION_NAME} Success`,
    props<{ liftingId: LiftingId; cargoNominationHistory: ReadonlyArray<CargoHistoricalEvent> }>()
);
export const routerLoadCargoNominationHistoryFailAction = createAction(`${LOAD_CARGO_NOMINATION_HISTORY_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

/* REDUCERS */
export const routerLoadLiftingReducer: On<CoasState> = on(routerLoadLiftingAction, (state, { liftingId }) => {
    if (isNullOrUndefined(liftingId)) {
        return {
            ...state,
            liftings: emptyObjectMap(),
            currentLiftingId: null
        };
    } else if (state.currentLiftingId !== liftingId) {
        return {
            ...state,
            ...liftingStateReducer(state, liftingId, {
                loadStatus: "loading",
                vesselNominationHistoryLoadStatus: "loading",
                cargoNominationHistoryLoadStatus: "loading"
            }),
            currentLiftingId: liftingId
        };
    }
    return state;
});

export const routerLoadLiftingSuccessReducer: On<CoasState> = on(routerLoadLiftingSuccessAction, (state, action) =>
    liftingStateReducer(state, action.lifting.liftingId, initialLiftingState(action.lifting))
);

export const routerLoadLiftingFailReducer: On<CoasState> = on(routerLoadLiftingFailAction, (state, action) =>
    liftingStateReducer(state, action.liftingId, { loadStatus: "failed" })
);

export const routerLoadVesselNominationHistorySuccessReducer: On<CoasState> = on(routerLoadVesselNominationHistorySuccessAction, (state, { liftingId, vesselNominationHistory }) =>
    liftingStateReducer(state, liftingId, { vesselNominationHistory, vesselNominationHistoryLoadStatus: "loaded" })
);

export const routerLoadVesselNominationHistoryFailReducer: On<CoasState> = on(routerLoadVesselNominationHistoryFailAction, (state, { liftingId }) =>
    liftingStateReducer(state, liftingId, { vesselNominationHistoryLoadStatus: "failed" })
);

export const routerLoadCargoNominationHistorySuccessReducer: On<CoasState> = on(routerLoadCargoNominationHistorySuccessAction, (state, { liftingId, cargoNominationHistory }) =>
    liftingStateReducer(state, liftingId, { cargoNominationHistory, cargoNominationHistoryLoadStatus: "loaded" })
);

export const routerLoadCargoNominationHistoryFailReducer: On<CoasState> = on(routerLoadCargoNominationHistoryFailAction, (state, { liftingId }) =>
    liftingStateReducer(state, liftingId, { cargoNominationHistoryLoadStatus: "failed" })
);

/* EFFECTS */
export const routerLoadLiftingEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(routerLoadLiftingAction),
            filter(({ liftingId }) => !isNullOrUndefined(liftingId)),
            withLatestFrom(store.select(selectCurrentCoaId)),
            switchMap(([{ liftingId }, coaId]) =>
                liftingHttpService.get(coaId, liftingId).pipe(
                    retryWithBackoff(),
                    map((lifting: Lifting) => routerLoadLiftingSuccessAction({ lifting })),
                    catchError((error) => of(routerLoadLiftingFailAction({ liftingId, error })))
                )
            )
        )
    );

export const routerLoadLiftingSuccessEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(routerLoadLiftingSuccessAction),
            withLatestFrom(store.select(selectCurrentCoa)),
            filter(([, coa]) => !isNullOrUndefined(coa)),
            switchMap(([, coa]) =>
                liftingHttpService.getLiftingsForCoa(coa.coaId).pipe(
                    map((liftings: IndexResponse<LiftingIndexItem>) =>
                        routerLoadCoaLiftingsSuccessAction({ coaId: coa.coaId, driver: coa.driver, liftings, fetchedLiftings: liftings.documents })
                    ),
                    catchError((error) => of(routerLoadCoaLiftingsFailAction({ coaId: coa.coaId, error })))
                )
            )
        )
    );

export const routerLoadVesselNominationHistoryEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(routerLoadLiftingSuccessAction),
            filter(({ lifting }) => !isNullOrUndefined(lifting.liftingId)),
            withLatestFrom(store.select(selectCurrentCoaId)),
            switchMap(([{ lifting }, coaId]) =>
                liftingHttpService.getVesselNominationHistory(coaId, lifting.liftingId).pipe(
                    map((vesselNominationHistory) => routerLoadVesselNominationHistorySuccessAction({ liftingId: lifting.liftingId, vesselNominationHistory })),
                    catchError((error) => of(routerLoadVesselNominationHistoryFailAction({ liftingId: lifting.liftingId, error })))
                )
            )
        )
    );

export const routerLoadCargoNominationHistoryEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(routerLoadLiftingSuccessAction),
            filter(({ lifting }) => !isNullOrUndefined(lifting.liftingId)),
            withLatestFrom(store.select(selectCurrentCoaId)),
            switchMap(([{ lifting }, coaId]) =>
                liftingHttpService.getCargoNominationHistory(coaId, lifting.liftingId).pipe(
                    map((cargoNominationHistory) => routerLoadCargoNominationHistorySuccessAction({ liftingId: lifting.liftingId, cargoNominationHistory })),
                    catchError((error) => of(routerLoadCargoNominationHistoryFailAction({ liftingId: lifting.liftingId, error })))
                )
            )
        )
    );
