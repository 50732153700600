import { Guid } from "guid-typescript";
import { box, Boxed, unbox } from "ngrx-forms";
import * as R from "ramda";

import { QuantityModel } from "../../../fixture/shared/models/form-models";
import { CargoProduct } from "../../../fixture/state";
import {
    CoaCargoQuantityUnit,
    CoaCargoToleranceOption,
    CoaCargoToleranceUnit,
    coaTypeToQuantityUnit,
    coaTypeToToleranceOption,
    coaTypeToToleranceUnit,
    quantityUnitToCoaType,
    toleranceOptionToCoaType,
    toleranceUnitToCoaType
} from "./cargo-type-mappings";

export type CargoId = string;
export type CargoProductId = number;

export const responsibleForChange = ["Charterer", "Owner"] as const;
export type ResponsibleForChange = typeof responsibleForChange[number];

export type ChangeReason = Readonly<{
    reason: string;
    responsibleForChange: ResponsibleForChange;
}>;

export const cargoIdEq = R.propEq("cargoId");

export type Cargo = Readonly<{
    cargoId: CargoId;
    productId: CargoProductId;
    name: string;
    quantity: string;
    quantityUnit: CoaCargoQuantityUnit;
    minTolerance: string;
    maxTolerance: string;
    toleranceUnit: CoaCargoToleranceUnit;
    toleranceOption: CoaCargoToleranceOption;
}>;

export type CargoForm = Readonly<{
    cargoId: CargoId;
    cargoProduct: Boxed<CargoProduct>;
    quantity: Boxed<QuantityModel>;
    minTolerance?: number;
    maxTolerance?: number;
    toleranceUnit: string;
    toleranceOption: string;
}>;

export type CargoListItem = Readonly<{
    cargoId: CargoId;
    productName: string;
    quantity?: number;
    quantityUnit: string;
    minTolerance?: number;
    maxTolerance?: number;
    toleranceUnit: string;
    toleranceOption: string;
    isVisible: boolean;
    error?: Error;
}>;

export type LiftingCargoTotals = Readonly<{
    nominatedQuantityTotal: number;
    nominatedQuantityUnit: CoaCargoQuantityUnit;
}>;

export const cargoForm = (): CargoForm => ({
    cargoId: getNewCargoId(),
    cargoProduct: box(null),
    quantity: box({ value: null, unit: null }),
    minTolerance: null,
    maxTolerance: null,
    toleranceUnit: null,
    toleranceOption: null
});

export const cargoToForm = (source: Cargo): CargoForm => ({
    cargoId: source.cargoId,
    cargoProduct: box(source.productId && source.name && { id: source.productId, name: source.name }),
    quantity: box({ value: source.quantity && Number(source.quantity), unit: coaTypeToQuantityUnit(source.quantityUnit) }),
    minTolerance: source.minTolerance && Number(source.minTolerance),
    maxTolerance: source.maxTolerance && Number(source.maxTolerance),
    toleranceUnit: coaTypeToToleranceUnit(source.toleranceUnit),
    toleranceOption: coaTypeToToleranceOption(source.toleranceOption)
});

export const cargoToListItem = (source: Cargo): CargoListItem => ({
    cargoId: source.cargoId,
    productName: source.name,
    quantity: source.quantity && Number(source.quantity),
    quantityUnit: coaTypeToQuantityUnit(source.quantityUnit),
    minTolerance: source.minTolerance && Number(source.minTolerance),
    maxTolerance: source.maxTolerance && Number(source.maxTolerance),
    toleranceUnit: coaTypeToToleranceUnit(source.toleranceUnit)?.replace("+- ", ""),
    toleranceOption: coaTypeToToleranceOption(source.toleranceOption),
    isVisible: true
});

export const formToCargo = (source: CargoForm): Cargo => {
    const cargoProduct = unbox(source.cargoProduct);
    const quantity = unbox(source.quantity);

    return {
        cargoId: source.cargoId,
        productId: cargoProduct?.id,
        name: cargoProduct?.name,
        quantity: quantity.value && String(quantity.value),
        quantityUnit: quantityUnitToCoaType(quantity.unit),
        minTolerance: source.minTolerance && String(source.minTolerance),
        maxTolerance: source.maxTolerance && String(source.maxTolerance),
        toleranceUnit: toleranceUnitToCoaType(source.toleranceUnit),
        toleranceOption: toleranceOptionToCoaType(source.toleranceOption)
    };
};

export const getNewCargoId = (): CargoId => Guid.create().toString();
