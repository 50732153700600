import { DOCUMENT } from "@angular/common";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewContainerRef
} from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from "@angular/forms";
import { ContextualParsing, MaritimeDate, MaritimeDateRange } from "@maritech/maritime-date";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DateTime } from "luxon";
import * as execWithIndices from "regexp-match-indices";
import { Subject } from "rxjs";
import { takeUntil, throttleTime } from "rxjs/operators";

import type { NgbDateRangeStruct, NgbDateTimeStruct } from "./date-picker.component";
import { DatePickerComponent } from "./date-picker.component";
import type { DateInput, TimeObject } from "./utils";
import {
    dateInputToDateTime,
    dateInputToNgbDateStruct,
    fromNgbDateRangeStruct,
    MAX_SUPPORTED_DATE,
    MIN_SUPPORTED_DATE,
    RANGE_DEFAULT_END_TIME,
    RANGE_DEFAULT_START_TIME,
    toNgbDateRangeStruct
} from "./utils";
import { selectWord } from "../../directives/select-words/select-words.handler";
import { Nullable } from "../../models";
import { SimpleChanges } from "../../simple-changes";
import { autoClose, focusTrap, Key, measureTextWidth } from "../../utils";
import { PlacementArray, positionElements } from "../../utils/positioning";

const DATE_INPUT_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateInputComponent),
    multi: true
};

const DATE_INPUT_VALIDATOR = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DateInputComponent),
    multi: true
};

type DatePart = "day" | "month" | "year" | "hour" | "minute";
type DatePartIndices = {
    [c in DatePart]?: [start: number, end: number];
};

type DateModel = DateTime | MaritimeDateRange;

const findDatePart = (formatIndices: DatePartIndices, selectionStart: number, selectionEnd: number): DatePart =>
    (<DatePart[]>Object.keys(formatIndices)).find((key: DatePart) => {
        const indices = formatIndices[key];

        if (indices) {
            const [start, end] = indices;
            return start <= selectionStart && selectionEnd <= end;
        }
    });

@Component({
    selector: "ops-date-input",
    templateUrl: "date-input.component.html",
    styleUrls: ["./date-input.component.scss"],
    providers: [DATE_INPUT_VALUE_ACCESSOR, DATE_INPUT_VALIDATOR],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateInputComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
    private readonly destroy$ = new Subject();

    private inputElement: HTMLInputElement;
    private pickerComponentRef: Nullable<ComponentRef<DatePickerComponent>> = null;

    /**
     * The number of keystrokes since last focus.
     *
     * @private
     */
    private keyStrokes = 0;

    private model: Nullable<DateModel>;
    /**
     * Used to track the last model for autocompletion tabbing + preventing model updates (`updateModel`).
     *
     * @private
     */
    private lastModel: DateModel;
    /**
     * Used to track the last form model (ControlValueAccessor) set by the control (`updateModel`) or given to the control (`writeValue`).
     *
     * @private
     */
    private lastFormModel: Date | DateModel;
    private formatIndices: DatePartIndices | { start: DatePartIndices; end: DatePartIndices };

    autoComplete?: { offset: number; text: string; partial: boolean; overflow: boolean };

    @HostBinding("class.ops-di-disabled")
    isDisabled = false;

    @HostBinding("class.ops-di-display-weekday")
    weekday?: string | null;

    /**
     * Specifies the allowed input type. Defaults to single.
     *
     * Specify 'dynamic' to allow both single (default) and range in both parsing and the date picker. The model
     * for this is always `MaritimeDateRange`.
     */
    @Input() mode: "single" | "range" | "dynamic" = "single";

    /**
     * True to enable time to be parsed, also displays the timepicker in the picker. Defaults to false.
     */
    @Input() enableTime = false;

    /**
     * Interprets the parsed in the specified zone. Required - control will error when unspecified.
     *
     * Specify 'local' to use the browser time zone.
     */
    @Input() timeZone: string | "local";

    /**
     * Text to display in the field when empty.
     */
    @Input() placeholder = "";

    /**
     * Time to use when parsing dates without a time specified. Only applicable when `mode='single' and enableTime=true`.
     */
    @Input() defaultTime?: TimeObject;

    /**
     * True to display the week day in front of the input. Only applicable when `mode='single'. Defaults to false.
     */
    @Input() displayWeekday = false;

    /**
     * True to disable the picker. Defaults to false.
     */
    @Input() disablePicker = false;

    /**
     * Specifies how past times, days, day/months and quarters are treated. When `'future'` is specified
     * they will be moved by the next largest unit. Note this also works for ranges.
     *
     * Note this behavior differs between units:
     * - Time and days are an inclusive check of the current day.
     * - Day/months are an exclusive check of the **previous** month.
     * - Quarters are an exclusive check of the current quarter.
     *
     * For example:
     * - `'3pm'` specified on or after the current time is `'3pm'` will become 3pm on the following day
     * - `'20'` specified when the current day is `'20'` or greater will become the 20th of the following month
     * - `'20/9'` specified when the current month is `'October'` or greater will become the 20/9 of the following year
     * - `'Q1'` specified when the current date after Q1 ends greater will become Q1 of the following year
     */
    @Input() contextualParsing?: ContextualParsing = "default";

    @Input()
    @HostBinding("class.ops-di-readonly")
    readonly: boolean;

    @Input()
    get disabled() {
        return this.isDisabled;
    }

    set disabled(value: string | boolean) {
        this.isDisabled = value === "" || (value && value !== "false");
    }

    @HostBinding("class.ops-di-invalid")
    get invalid() {
        return this.model ? !this.model.isValid : false;
    }

    @HostBinding("title")
    get title() {
        // Don't display if UTC as some inputs in Ops use UTC as floating local time
        if (((!this.isDisabled && !this.readonly) || this.inputElement?.value) && this.timeZone && this.timeZone !== "local" && this.timeZone !== "utc") {
            return `Time zone is ${this.timeZone}`;
        }

        return "";
    }

    @Input() placement: PlacementArray = ["bottom-left", "top-left", "bottom-right", "top-right"];
    @Input() container: string | "body" = "body";
    @Input() positionTarget: string | HTMLElement;

    /**
     * Specifies the number of months to display in the picker. Defaults to 2 when `type='range'`.
     */
    @Input() displayMonths?: number;

    /**
     * Specifies the navigation type. Defaults to arrows for range, select for single.
     *
     * - `'select'` - select boxes for month and navigation arrows
     * - `'arrows'` - only navigation arrows
     * - `'none'` - no navigation visible at all
     */
    @Input() navigation?: "select" | "arrows" | "none";

    /**
     * The first day of the week to display in the picker.
     */
    @Input() firstDayOfWeek: number;

    /**
     * The date focused on the picker when the model is undefined.
     */
    @Input() defaultFocusDate: DateInput;

    /**
     * Specifies the minimum allowed date (solely used in the picker).
     */
    @Input() minDate: DateInput;

    /**
     * Specifies the maximum allowed date (solely used in the picker).
     */
    @Input() maxDate: DateInput;

    /**
     * The first date shown on the picker if the model is undefined.
     */
    @Input() startDate: { year: number; month: number; day?: number };

    /**
     * Specifies whether week day names are shown on the picker.
     */
    @Input() showWeekdays: boolean;

    /**
     * Specifies whether week numbers are shown on the picker.
     */
    @Input() showWeekNumbers: boolean;

    /**
     * Specifies the visibility of the days outside the month in the picker.
     */
    @Input() outsideDays: "visible" | "collapsed" | "hidden";

    /**
     * Specifies how the picker should auto close.
     */
    @Input() autoClose: boolean | "inside" | "outside" = true;

    /**
     * Specifies how the tabbing affects selection. Default will select the next text component, time will move from any
     * date component to the next hours. Only applicable when `enableTime=true`.
     *
     * For example, if I have the day selected and I tab with default behaviour, the month will become selected.
     * With the behaviour set to `'time'`, the hours will become selected.
     */
    @Input() tabbingBehaviour: "default" | "time" = "default";

    @Output() readonly change = new EventEmitter<MaritimeDateRange | DateTime>();
    @Output() readonly opened = new EventEmitter();
    @Output() readonly closed = new EventEmitter();
    @Output() readonly focus = new EventEmitter();
    @Output() readonly blur = new EventEmitter();

    get showPickerButton() {
        return !(this.disabled || this.readonly || this.disablePicker || this.keyStrokes > 0);
    }

    /**
     * Returns true if the datepicker is open.
     */
    get isPickerOpen() {
        return !!this.pickerComponentRef;
    }

    /**
     * Gets the current mode of the input, derived when `mode='dynamic'`.
     */
    get currentMode(): "single" | "range" {
        switch (this.mode) {
            case "single":
                return "single";
            case "range":
                return "range";
            default: {
                if (!this.model) {
                    return "single";
                }

                const range = <MaritimeDateRange>this.model;

                return !range.isValid || range.start.hasSame(range.end, "minute") ? "single" : "range";
            }
        }
    }

    get supportsRange() {
        return this.mode !== "single";
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private viewContainerRef: ViewContainerRef,
        private changeDetector: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private renderer: Renderer2,
        private ngZone: NgZone,
        @Inject(DOCUMENT) private document: HTMLDocument
    ) {}

    ngOnInit() {
        this.inputElement = this.elementRef.nativeElement.querySelector("input");
        this.elementRef.nativeElement.focus = () => this.inputElement.focus();
        this.elementRef.nativeElement.blur = () => this.inputElement.blur();
    }

    ngOnChanges(changes: SimpleChanges<DateInputComponent>) {
        if (changes.readonly) {
            this.setDisabledState(this.isDisabled);
        }

        if (!this.timeZone) {
            throw Error("ops-date-input: timeZone must be specified");
        }

        if (changes.timeZone) {
            this.writeModel();
        }
    }

    ngOnDestroy() {
        this.closePicker();
        this.destroy$.next();
        this.destroy$.complete();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: (value: any) => any): void {
        this._onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: () => any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.changeDetector.markForCheck();
    }

    writeValue(value: Date | DateTime | MaritimeDateRange) {
        if (value === this.lastFormModel) {
            return;
        }

        let model: DateTime | MaritimeDateRange = null;

        if (value && this.mode === "single") {
            model = !DateTime.isDateTime(value) ? DateTime.fromJSDate(<Date>value) : value;
        } else if (value instanceof MaritimeDateRange) {
            model = value;
        }

        if (this.model && model?.equals(this.model as DateTime & MaritimeDateRange)) {
            return;
        }

        this.model = model;

        this.writeModel();

        // We must set the last form and model here to ensure the value has changed in updateModel (especially when clearing
        // the input and the form reverts the value).
        this.lastFormModel = value;
        this.lastModel = model;
    }

    validate(_c: AbstractControl): Record<string, unknown> | null {
        const value = this.model;

        if (!value || (value.isValid && !this.minDate && !this.maxDate)) {
            return null;
        }

        if (!value.isValid) {
            return {
                date: {
                    invalid: value.invalidReason
                },
                // Backwards compat
                dateRange: {
                    invalid: value.invalidReason === "end before the start"
                }
            };
        }

        const start = this.model instanceof MaritimeDateRange ? this.model.start : this.model;
        const end = this.model instanceof MaritimeDateRange ? this.model.end : this.model;

        if (this.minDate) {
            const min = dateInputToDateTime(this.minDate);

            if (+start < +min) {
                return {
                    minDate: {
                        actual: start,
                        comparand: min
                    }
                };
            }
        }

        if (this.maxDate) {
            const max = dateInputToDateTime(this.maxDate);

            if (+max < +end) {
                return {
                    maxDate: {
                        actual: end,
                        comparand: max
                    }
                };
            }
        }

        return null;
    }

    onInputFocus() {
        this.focus.emit();
    }

    onInputBlur() {
        this.autoComplete = undefined;
        this.keyStrokes = 0;

        if (this.readonly) {
            return;
        }

        if (!this.inputElement.value) {
            this.weekday = null;
        }

        // Required to handle copy + paste scenarios
        this.updateModel();
        this.writeModel();
        this.updateModelForm();
        this.handleBlur();
    }

    onButtonBlur() {
        this.handleBlur();
    }

    onInputKeyDown(event: KeyboardEvent) {
        if (event.key === Key.ArrowUp || event.key === Key.ArrowDown) {
            this.handleArrowUpDownKeys(event);
        }

        if ((event.key === Key.Tab || event.key === Key.Enter) && !event.shiftKey) {
            this.commitAutoCompleteHandleTimeTabbing(event);
        }
    }

    onInputKeyUp(event: KeyboardEvent) {
        // Ignore non character keys (eg. Tab) but handle deletions
        if (event.key.length === 1 || event.key === Key.Backspace || event.key === Key.Delete) {
            this.keyStrokes++;
            this.autoCompleteInput();
        }

        if (!isNaN(Number(event.key))) {
            this.selectMinutes();
        }
    }

    /* Picker */
    openPicker() {
        if (this.isPickerOpen) {
            return;
        }

        this.pickerComponentRef = this.viewContainerRef.createComponent(DatePickerComponent);

        const picker = this.pickerComponentRef.instance;

        if (!this.model) {
            this.selectCurrentDate();
        }

        this.applyPickerStyling(this.pickerComponentRef.location.nativeElement);
        this.applyPickerInputs(picker);
        this.writePickerModel(picker);
        picker.setDisabledState(this.isDisabled);

        picker.dateSelect.pipe(takeUntil(this.destroy$)).subscribe((model: NgbDateRangeStruct) => {
            this.model = fromNgbDateRangeStruct(model, this.timeZone, this.supportsRange);
            this.writeModel();

            this.updateModelForm();
            this.changeDetector.markForCheck();

            // close picker when date range selected and time disabled
            if (!this.enableTime && model.to && (this.autoClose === true || this.autoClose === "inside")) {
                this.closePicker();
            }
        });
        picker.close.pipe(takeUntil(this.destroy$)).subscribe(() => this.closePicker());

        if (this.container === "body") {
            window.document.querySelector(this.container).appendChild(this.pickerComponentRef.location.nativeElement);
            this.document.addEventListener("scroll", this.updatePickerPosition, true);
        }

        this.ngZone.onStable.pipe(throttleTime(30), takeUntil(this.closed)).subscribe(() => this.updatePickerPosition());

        this.pickerComponentRef.changeDetectorRef.detectChanges();

        // focus handling
        focusTrap(this.pickerComponentRef.location.nativeElement, this.closed);
        picker.focus();

        autoClose(this.ngZone, this.document, this.autoClose, () => this.closePicker(), this.closed, [], [this.inputElement, this.pickerComponentRef.location.nativeElement]);

        this.opened.emit();
        this.changeDetector.markForCheck();
    }

    closePicker() {
        if (this.isPickerOpen) {
            this._onTouched();

            this.document.removeEventListener("scroll", this.updatePickerPosition, true);
            this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.pickerComponentRef.hostView));
            this.pickerComponentRef = null;
            this.closed.emit();
            this.changeDetector.markForCheck();
        }
    }

    togglePicker() {
        if (this.isPickerOpen) {
            this.closePicker();
        } else {
            this.openPicker();
        }
    }

    private handleBlur() {
        if (!this.elementRef.nativeElement.contains(document.activeElement) && !this.isPickerOpen) {
            this.blur.emit();
        }
    }

    private handleArrowUpDownKeys(event: KeyboardEvent) {
        // Only allow increment / decrement when not autocompleting
        if (!this.formatIndices || this.autoComplete) {
            return;
        }

        const selectionStart = this.inputElement.selectionStart;
        const selectionEnd = this.inputElement.selectionEnd;
        const change = event.key === Key.ArrowUp ? 1 : -1;

        if (this.supportsRange && this.model instanceof MaritimeDateRange) {
            const rangeDelimiterPosition = this.inputElement.value.indexOf("-");
            const { start: startIndices, end: endIndices } = <{ start: DatePartIndices; end: DatePartIndices }>this.formatIndices;
            const isStart = rangeDelimiterPosition < 0 || selectionEnd < rangeDelimiterPosition;

            const part = findDatePart(isStart ? startIndices : endIndices, selectionStart, selectionEnd);
            if (!part) {
                return;
            }

            event.preventDefault();

            const isSingleDate = this.model.start.hasSame(this.model.end, "day");
            const isSingleDateTime = this.model.start.hasSame(this.model.end, "minute");
            const incrementBoth = (isSingleDate && part !== "hour" && part !== "minute") || isSingleDateTime;
            const startDate = isStart || incrementBoth ? this.model.start.plus({ [part]: change }) : this.model.start;
            const endDate = !isStart || incrementBoth ? this.model.end.plus({ [part]: change }) : this.model.end;
            const model = MaritimeDateRange.exact(startDate, endDate);

            // If we've turned the date range invalid, we don't support correction (or displaying invalid formats) so bail
            if (!model.isValid) {
                return;
            }

            this.model = model;

            this.writeModel();
            this.updateModelForm();

            // formatIndices is updated on writeModel, we then attempt to get the indices or the part we just changed,
            // falling back to the start indices if it is no longer displayed as a range. This allows for the formats to
            // change (eg. from 29 - |30| Nov 20 to 28 Nov - |01| Dec 20) and also accounts for time.
            const { start: updatedStartIndices, end: updatedEndIndices } = <{ start: DatePartIndices; end: DatePartIndices }>this.formatIndices;
            const selectionIndices = (isStart ? updatedStartIndices : updatedEndIndices)[part] ?? updatedStartIndices[part];

            if (selectionIndices) {
                this.inputElement.setSelectionRange(selectionIndices[0], selectionIndices[1]);
            }
        } else if (DateTime.isDateTime(this.model)) {
            const part = findDatePart(<DatePartIndices>this.formatIndices, selectionStart, selectionEnd);
            if (!part) {
                return;
            }

            event.preventDefault();

            this.model = this.model.plus({ [part]: change });

            this.writeModel();
            this.updateModelForm();

            this.inputElement.setSelectionRange(selectionStart, selectionEnd);
        }
    }

    /**
     * @description
     * Handles tabbing and selection to hours when autocompleting a date, when `enableTime=true` and the time has not
     * been specified in the parsed string.
     *
     * Note this relies on the user not specifying the default time in the parsed string.
     *
     * ```
     * '01 Aug' -> 01 Aug 20, 00:00
     *                        ↑↑
     * '01 Aug 7pm' -> nothing
     * '01 Aug' -> 01 Aug 20, 00:00 - 23:59
     *                        ↑↑
     * '01 Aug - 01 Aug 7pm' -> 01 Aug 20, 00:00 - 07:00
     *                                     ↑↑
     * '01 Aug 7pm - 01 Aug' -> 01 Aug 20, 07:00 - 23:59
     *                                             ↑↑
     * '01 Aug 7pm - 01 Aug 8pm' -> nothing
     * ```
     * @param event The keyboard event.
     * @private
     */
    private commitAutoCompleteHandleTimeTabbing(event: KeyboardEvent) {
        // We always format in 24 hour
        const timeRegex = /\d{2}:\d{2}/g;
        const isAutoComplete = !!this.autoComplete;

        const target = this.inputElement;
        const lastText = this.inputElement.value;
        const lastModel = this.lastModel;
        // Must get cursor position before writing model, also using selection end to handle word selection
        const cursorPosition = target.selectionEnd;

        if (isAutoComplete) {
            this.writeModel();
            this.updateModelForm();
            this.autoComplete = undefined;
        }

        if (!this.enableTime || event.key !== Key.Tab) {
            return;
        }

        const model = this.model;
        const text = target.value;

        if (isAutoComplete) {
            // This handles a scenario where I have a date time (eg. 01 Nov 20, 08:45) and I change part (eg. to 2 Nov 20, 08:45)
            // and hit tab, when we want to select the month (except when tabbing behaviour is time)
            if (cursorPosition < lastText.length && this.tabbingBehaviour !== "time") {
                //Stoppropagation is needed for making tabbing work with PrimeNg table
                event.stopPropagation();
                event.preventDefault();
                selectWord(this.inputElement, cursorPosition);
                return;
            }

            if (model instanceof MaritimeDateRange) {
                let startTimeComparator = RANGE_DEFAULT_START_TIME;
                let endTimeComparator = RANGE_DEFAULT_END_TIME;

                if (lastModel instanceof MaritimeDateRange) {
                    startTimeComparator = lastModel.start ?? RANGE_DEFAULT_START_TIME;
                    endTimeComparator = lastModel.end ?? RANGE_DEFAULT_END_TIME;
                }

                if (model.start.hour === startTimeComparator.hour && model.start.minute === startTimeComparator.minute) {
                    // Do nothing
                } else if (model.end.hour === endTimeComparator.hour && model.end.minute === endTimeComparator.minute) {
                    // Set the start index of the regex search to the range delimiter
                    timeRegex.lastIndex = text.indexOf("-");
                } else {
                    // Bail if both start/end time were specified
                    return;
                }
            } else {
                const timeComparator = (lastModel as DateTime) ?? this.defaultTime ?? { hour: 0, minute: 0 };

                // Bail if the time was specified
                if (model.hour !== timeComparator.hour || model.minute !== timeComparator.minute) {
                    return;
                }
            }
        } else {
            timeRegex.lastIndex = cursorPosition;
        }

        if (isAutoComplete || this.tabbingBehaviour === "time") {
            const timeMatch = timeRegex.exec(text);

            if (timeMatch) {
                const hourStartIndex = timeMatch?.index;
                // eslint-disable-next-line no-magic-numbers
                const minuteStartIndex = timeMatch.index + 3;

                if (cursorPosition < hourStartIndex) {
                    //Stoppropagation is needed for making tabbing work with PrimeNg table
                    event.stopPropagation();
                    event.preventDefault();
                    selectWord(target, hourStartIndex);
                } else if (cursorPosition < minuteStartIndex) {
                    //Stoppropagation is needed for making tabbing work with PrimeNg table
                    event.stopPropagation();
                    event.preventDefault();
                    selectWord(target, minuteStartIndex);
                }
            }
        }
    }

    private autoCompleteInput() {
        this.updateModel();
        this.writePickerModel();

        const model = this.model;

        if (!this.inputElement.value) {
            this.weekday = null;
        }

        if (!model?.isValid) {
            this.autoComplete = undefined;
            this.weekday = null;
            return;
        }

        if (this.weekday !== this.formatWeekday(model)) {
            this.weekday = null;
        }

        const target = this.inputElement;
        const text = target.value.toLowerCase();
        let dateText = this.formatModel(model);
        let partial: boolean;

        if (dateText?.[0] === "0" && text[0] !== "0") {
            const pos = dateText.toLowerCase().indexOf(text);
            partial = 0 <= pos && pos <= 1;

            if (partial) {
                dateText = dateText.substr(1);
            }
        } else {
            partial = dateText.toLowerCase().indexOf(text) === 0;
        }

        const autoCompleteText = partial ? dateText.substr(text.length) : dateText;
        const inputStyle = getComputedStyle(target, null);
        const font = inputStyle.getPropertyValue("font");

        const weekdayWidth = this.weekday ? (<HTMLElement>this.elementRef.nativeElement.querySelector(".weekday"))?.offsetWidth ?? 0 : 0;
        const inputValueWidth = measureTextWidth(target.value, font); // Note measuring target.value to preserve case when measuring
        const autoCompleteWidth = measureTextWidth(autoCompleteText, font);
        const inputXPaddingLeft = parseFloat(inputStyle.getPropertyValue("padding-left"));
        const inputInnerWidth = target.clientWidth - inputXPaddingLeft * 2;
        const requiredWidth = inputValueWidth + autoCompleteWidth;
        const spacing = inputXPaddingLeft / 2;

        this.autoComplete = {
            offset: partial ? inputValueWidth + weekdayWidth : weekdayWidth,
            text: autoCompleteText,
            partial,
            overflow: inputInnerWidth - requiredWidth < spacing
        };
    }

    /**
     * When the user has typed the 2nd hour digit into preformatted 24 hour time, select the minutes.
     *
     * @private
     */
    private selectMinutes() {
        const HOUR_DIGITS = 2;
        const target = this.inputElement;
        const cursorPosition = target.selectionStart;
        const regex = /\d{2}:\d{2}/g;
        regex.lastIndex = cursorPosition - HOUR_DIGITS;

        const timeIndex = regex.exec(target.value)?.index;

        if (-1 < timeIndex && timeIndex === cursorPosition - HOUR_DIGITS) {
            const hourEnd = timeIndex + HOUR_DIGITS;
            const minuteStart = hourEnd + 1;

            target.setSelectionRange(minuteStart, minuteStart + HOUR_DIGITS);
        }
    }

    private updateModel() {
        this.model = this.parseInput(this.inputElement.value);
    }

    private parseInput(text: Nullable<string>) {
        if (!text) {
            return null;
        }

        return this.supportsRange ? this.parseDateRange(text) : this.parseDate(text);
    }

    private parseDateRange(text: string) {
        return MaritimeDateRange.tryParse(text, {
            fourDigitParsingBehaviour: "day_month",
            parseTime: this.enableTime,
            zone: this.timeZone,
            defaultEndTime: {
                single: this.mode !== "range" ? { hour: 0, minute: 0 } : RANGE_DEFAULT_END_TIME,
                range: RANGE_DEFAULT_END_TIME
            },
            contextualParsing: this.contextualParsing
        });
    }

    private parseDate(text: string) {
        return MaritimeDate.tryParse(text, {
            parseTime: this.enableTime,
            zone: this.timeZone,
            defaultTime: this.defaultTime,
            contextualParsing: this.contextualParsing
        });
    }

    /**
     * Returns the formatted model, if it is valid.
     *
     * @param model The model to format.
     * @private
     */
    private formatModel(model: DateTime | MaritimeDateRange): string | undefined {
        if (model?.isValid) {
            if (this.timeZone !== "local") {
                model = model.setZone(this.timeZone);
            }

            return model instanceof MaritimeDateRange
                ? this.enableTime
                    ? model.toMaritimeString()
                    : model.toMaritimeDateString()
                : model.toLocaleString(this.enableTime ? MaritimeDate.DATETIME_FORMAT : MaritimeDate.DATE_FORMAT);
        }

        return undefined;
    }

    private selectCurrentDate() {
        const currentDate = new Date();
        const date = {
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            day: currentDate.getDate()
        } as NgbDateStruct;
        const currentDateNgbRange = {
            from: this.getNgbDateTimeStructWithDefaultTime(date, RANGE_DEFAULT_START_TIME),
            to: this.getNgbDateTimeStructWithDefaultTime(date, RANGE_DEFAULT_END_TIME)
        } as NgbDateRangeStruct;
        this.model = fromNgbDateRangeStruct(currentDateNgbRange, this.timeZone, this.supportsRange);
        this.writeModel();
        this.updateModelForm();
        this.changeDetector.markForCheck();
    }

    private getNgbDateTimeStructWithDefaultTime(date: NgbDateStruct, defaultRangeTime: TimeObject) {
        return {
            date,
            time: {
                ...(this.defaultTime ?? (this.mode === "range" ? defaultRangeTime : { hour: 0, minute: 0 })),
                second: 0
            }
        } as NgbDateTimeStruct;
    }

    /**
     * Formats and writes the model to the input.
     *
     * @private
     */
    private writeModel() {
        if (!this.inputElement) return;

        if (!this.model) {
            this.inputElement.value = null;
            return;
        }

        if (this.model?.isValid) {
            const text = this.formatModel(this.model);
            this.inputElement.value = text;

            const regex = /^(\d{2}) ?(\w{3})? ?(\d{2})?(?:, (\d{2}):(\d{2}))?(?: - (?:(\d{2}) (\w{3}) (\d{2}))?(?:, )?(?:(\d{2}):(\d{2}))?)?$/i;
            const match = execWithIndices(regex, text);
            if (!match) {
                return;
            }

            const [, second, third, fourth, fifth, sixth, seventh, eighth, ninth, tenth, eleventh] = match.indices;

            if (this.supportsRange) {
                this.formatIndices = {
                    start: {
                        day: second,
                        month: third,
                        year: fourth,
                        hour: fifth,
                        minute: sixth
                    },
                    end: {
                        day: seventh,
                        month: eighth,
                        year: ninth,
                        hour: tenth,
                        minute: eleventh
                    }
                };
            } else {
                this.formatIndices = {
                    day: second,
                    month: third,
                    year: fourth,
                    hour: fifth,
                    minute: sixth
                };
            }
        }

        this.weekday = this.formatWeekday(this.model);
    }

    private formatWeekday(model: DateTime | MaritimeDateRange | null) {
        if (this.timeZone && this.timeZone !== "local" && model?.isValid) {
            model = model?.setZone(this.timeZone);
        }

        return this.mode === "single" && this.displayWeekday && model?.isValid ? (<DateTime>model).toLocaleString({ weekday: "short" }) : null;
    }

    /**
     * Notifies ControlValueAccessor the model has changed.
     *
     * @private
     */
    private updateModelForm() {
        if ((!this.model && !this.lastModel) || (this.model && this.lastModel && this.model.equals(this.lastModel as DateTime & MaritimeDateRange))) {
            return;
        }

        let formModel: Date | MaritimeDateRange = null;

        if (this.model) {
            if (!this.supportsRange) {
                // TODO: Move all components over to luxon
                formModel = this.model.isValid ? (<DateTime>this.model).toJSDate() : null;
            } else if (this.model instanceof MaritimeDateRange) {
                formModel = this.model;
            }
        }

        this.lastModel = this.model;
        this.lastFormModel = formModel;

        this.change.emit(this.model);
        this._onChange(formModel);
        this._onTouched();
    }

    private writePickerModel(picker?: DatePickerComponent) {
        picker ??= this.pickerComponentRef?.instance;

        if (this.timeZone === "local") {
            picker?.writeValue(toNgbDateRangeStruct(this.model));
        } else if (this.model?.isValid) {
            picker?.writeValue(toNgbDateRangeStruct(this.model?.setZone(this.timeZone)));
        }
    }

    private applyPickerInputs(picker: DatePickerComponent) {
        picker.timeZone = this.timeZone;
        // eslint-disable-next-line no-magic-numbers
        picker.mode = this.mode;
        picker.displayMode = this.currentMode;
        picker.defaultTime = this.defaultTime ? { ...this.defaultTime, second: 0 } : undefined;
        picker.displayMonths = this.displayMonths;
        picker.navigation = this.navigation;
        picker.firstDayOfWeek = this.firstDayOfWeek;
        picker.showWeekNumbers = this.showWeekNumbers;

        let startDate = this.startDate ?? dateInputToNgbDateStruct(this.defaultFocusDate);

        if (!startDate && this.model) {
            startDate = toNgbDateRangeStruct(this.model)?.from?.date;
        }

        picker.useTimepicker = this.enableTime;
        picker.startDate = startDate;
        picker.minDate = dateInputToNgbDateStruct(this.minDate ?? MIN_SUPPORTED_DATE);
        picker.maxDate = dateInputToNgbDateStruct(this.maxDate ?? MAX_SUPPORTED_DATE);
    }

    private applyPickerStyling(nativeElement: HTMLElement) {
        this.renderer.addClass(nativeElement, "dropdown-menu");
        this.renderer.addClass(nativeElement, "show");

        if (this.container === "body") {
            this.renderer.addClass(nativeElement, "ops-dp-body");
        }
    }

    private updatePickerPosition() {
        if (!this.pickerComponentRef) {
            return;
        }

        let hostElement: HTMLElement;
        if (typeof this.positionTarget === "string") {
            hostElement = window.document.querySelector(this.positionTarget);
        } else if (this.positionTarget instanceof HTMLElement) {
            hostElement = this.positionTarget;
        } else {
            hostElement = this.elementRef.nativeElement;
        }

        if (this.positionTarget && !hostElement) {
            throw new Error("opsDateInput could not find element declared in [positionTarget] to position against.");
        }

        positionElements(hostElement, this.pickerComponentRef.location.nativeElement, this.placement, this.container === "body");
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _onChange = (_: any) => {};
    private _onTouched = () => {};
}
