<div [formGroup]="searchForm" class="container">
    <div class="form-group px-0">
        <label for="companyRole" class="search-label">Search</label>
        <ng-select
            #roleSelect
            class="ops-select"
            [items]="companyRoles$ | async"
            [compareWith]="referenceDataService.compare"
            formControlName="role"
            [clearable]="false"
            bindLabel="name"
            placeholder="Select Company Role"
            data-test-company-role
            [selectOnTab]="true"
        >
        </ng-select>
    </div>
    <ops-company-autosuggest formControlName="company" (selectionChange)="onSelectionChange($event)" data-test-company></ops-company-autosuggest>
</div>
