import { createAction, on, On, props } from "@ngrx/store";
import { opsAddArrayControl } from "@ops/state";
import { removeArrayControl, updateGroup } from "ngrx-forms";
import { CoasState, LiftingCargoForm, liftingCargoLocationForm } from "../../../model";
import { currentLiftingStateReducer } from "../../reducer";

/* ACTIONS */
export const addLoadLocationAction = createAction("[Lifting Cargo Form] Add Load Location");
export const addDischargeLocationAction = createAction("[Lifting Cargo Form] Add Discharge Location");
export const removeLoadLocationAction = createAction("[Lifting Cargo Form] Remove Load Location", props<{ index: number }>());
export const removeDischargeLocationAction = createAction("[Lifting Cargo Form] Remove Discharge Location", props<{ index: number }>());

/* REDUCERS */
export const addLoadLocationReducer: On<CoasState> = on(addLoadLocationAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => ({
        ...liftingState,
        liftingCargoForm: updateGroup<LiftingCargoForm>({
            loadLocations: opsAddArrayControl(liftingCargoLocationForm())
        })(liftingState.liftingCargoForm)
    })));


export const addDischargeLocationReducer: On<CoasState> = on(addDischargeLocationAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => ({
        ...liftingState,
        liftingCargoForm: updateGroup<LiftingCargoForm>({
            dischargeLocations: opsAddArrayControl(liftingCargoLocationForm())
        })(liftingState.liftingCargoForm)
    })));

export const removeLoadLocationReducer: On<CoasState> = on(removeLoadLocationAction, (state, { index }) =>
    currentLiftingStateReducer(state, (liftingState) => (
        liftingState.liftingCargoForm.value.loadLocations.length > 1
        ? {
            ...liftingState,
            liftingCargoForm: updateGroup<LiftingCargoForm>({
                loadLocations: removeArrayControl(index)
            })(liftingState.liftingCargoForm)
        }
        : liftingState)
    ));

export const removeDischargeLocationReducer: On<CoasState> = on(removeDischargeLocationAction, (state, { index }) =>
    currentLiftingStateReducer(state, (liftingState) => (
        liftingState.liftingCargoForm.value.dischargeLocations.length > 1
        ? {
            ...liftingState,
            liftingCargoForm: updateGroup<LiftingCargoForm>({
                dischargeLocations: removeArrayControl(index)
            })(liftingState.liftingCargoForm)
        }
        : liftingState)
    ));
