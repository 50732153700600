<ops-activity-form
    [form]="form$ | async"
    [locationTimezone]="locationTimezone$ | async"
    [isBlAtDisportVisible]="isBlAtDisportVisible$ | async"
    [isTypeDropdownReadonly]="isTypeDropdownReadonly$ | async"
    [activityDropdownTypes]="activityDropdownTypes$ | async"
    [readonly]="readonly"
>
</ops-activity-form>

<ops-associated-cargoes
    *ngIf="(showAssociatedCargoes$ | async)"
    [associatedCargoes]="associatedCargoes$ | async"
    [cargoes]="allCargoes$ | async"
    [berths]="berths$ | async"
    [freightType]="freightType$ | async"
    [blDefaultFocusDate]="arrivalDateTime$ | async"
    [activityType]="activityType$ | async"
    [currentVoyage]="currentVoyage$ | async"
    [destinationId]="destinationId$ | async"
    [berthId]="berthId$ | async"
    [readonly]="readonly"
    [timeZone]="locationTimezone$ | async"
    [canAdd]="!readonly"
    [canRemove]="!readonly"
    [division]="division$ | async"
    (add)="addAssociatedCargo()"
    (remove)="removeAssociatedCargo($event)"
    (addExpense)="addAssociatedCargoExpense($event)"
    (removeExpense)="removeAssociatedCargoExpense($event)"
    (expand)="expandAssociatedCargo($event)"
    (collapse)="collapseAssociatedCargo($event)"
    (move)="moveAssociatedCargo($event)"
>
</ops-associated-cargoes>

<ng-container *ngIf="activityType$ | async">
    <ops-laytime-events
        [laytimeEvents]="laytimeEvents$ | async"
        [locationTimezone]="locationTimezone$ | async"
        [totalLaytime]="totalLaytime$ | async"
        [laytimeEventsOrdered]="laytimeEventsOrdered$ | async"
        [cargoes]="cargoes$ | async"
        [readonly]="readonly"
        (add)="addLaytimeEvent($event)"
        (order)="orderLaytimeEvents()"
        (remove)="removeLaytimeEvent($event)"
    >
    </ops-laytime-events>
</ng-container>
