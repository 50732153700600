import { ILoadingOverlayAngularComp } from "@ag-grid-community/angular";
import { Component } from "@angular/core";

@Component({
    templateUrl: "./loading-overlay.component.html",
    styleUrls: ["./loading-overlay.component.scss"]
})
export class LoadingOverlayComponent implements ILoadingOverlayAngularComp {
    static componentName = "LoadingOverlayComponent";
    agInit(): void {}
}
