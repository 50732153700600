import { createAction, On, on } from "@ngrx/store";
import { createFormGroupState, focus, updateGroup } from "ngrx-forms";

import { LiftingHeaderForm, liftingToForm } from "../model/lifting/lifting-header";
import { CoasState } from "../model/state";
import { currentLiftingStateReducer } from "./reducer";

/* ACTIONS */
export const editLiftingHeaderAction = createAction("[Lifting Shell] Edit Lifting Header");
export const editLiftingDeclarationDateAction = createAction("[Lifting Shell] Edit Lifting Declaration Date");

/* REDUCERS */
export const editLiftingHeaderReducer: On<CoasState> = on(editLiftingHeaderAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => {
        const initialForm = createFormGroupState(liftingState.lifting.liftingId, liftingToForm(liftingState.lifting));
        const form = updateGroup<LiftingHeaderForm>({ operators: focus })(initialForm);

        return { ...liftingState, liftingHeaderForm: form };
    })
);

export const editLiftingDeclarationDateReducer: On<CoasState> = on(editLiftingDeclarationDateAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => {
        const initialForm = createFormGroupState(liftingState.lifting.liftingId, liftingToForm(liftingState.lifting));
        const form = updateGroup<LiftingHeaderForm>({ declarationDate: focus })(initialForm);

        return { ...liftingState, liftingHeaderForm: form };
    })
);
