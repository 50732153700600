import { Router } from "@angular/router";
import { from, Observable } from "rxjs";

import { FixtureId } from "./model";

export const navigateToFixture = (router: Router, fixtureId: FixtureId): Observable<boolean> =>
    from(
        router.navigate(["fixture", fixtureId, { outlets: { toolbar: null } }], {
            replaceUrl: true
        })
    );
