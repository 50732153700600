import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { filter, first, map, switchMap, takeUntil, tap } from "rxjs/operators";

import { parseISODate } from "../../shared/date-utils/date-utilities";
import { ExpenseDataService } from "../services/expense-data.service";
import { FixtureDataService } from "../services/fixture-data.service";
import { FormComponentBase } from "../shared/form-component-base";
import { Currency, Destination, ExpenseClaim } from "../shared/models";
import { ExpenseClaimFormModel } from "../shared/models/form-models/expense.model";
import { FixtureTabName } from "../shared/tab-validation/tab-validation-info";

@Component({
    selector: "ops-expense-tab",
    templateUrl: "./expense-tab.component.html",
    styleUrls: ["./expense-tab.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpenseTabComponent extends FormComponentBase implements OnInit, OnDestroy {
    static componentName = "ExpenseTabComponent";
    static commentsMaxLength = 6000;

    private removeFixtureSubscription$ = new Subject();
    private fixtureCurrency: Currency;
    private expensesLoaded: boolean;

    readonly tabName: FixtureTabName = "expensesTab";

    expensesForm: UntypedFormArray;
    expensesCount = 0;
    expenses$: Observable<ExpenseClaim[]>;

    @Input() destinations: Destination[];

    constructor(private formBuilder: UntypedFormBuilder, private expenseDataService: ExpenseDataService, private fixtureDataService: FixtureDataService, protected store: Store) {
        super(store);
    }

    ngOnInit() {
        this.expensesLoaded = false;
        this.expensesForm = new UntypedFormArray([], { updateOn: "blur" });
        this.expensesForm.disable({ emitEvent: false });

        this.expenses$ = this.expenseDataService.currentExpenses$.pipe(
            map((x) => x?.expenses),
            tap((expenses) => (this.expensesCount = expenses?.length ?? 0))
        );

        this.expenses$
            .pipe(
                filter(() => this.expensesLoaded),
                first()
            )
            .subscribe((expenses) => {
                this.removeFixtureSubscription();
                expenses?.forEach((expense) => this.expensesForm.push(this.createRow(false, expense)));
                this.subscribeToFormValueChanges();
                this.expensesForm.enable({ emitEvent: false });
            });

        this.fixtureDataService.currentFixture$.pipe(takeUntil(this.removeFixtureSubscription$)).subscribe((fixture) => {
            if (!this.expensesLoaded && !!fixture.fixtureId) {
                this.expenseDataService.load(fixture.fixtureId);
                this.fixtureCurrency = fixture.currency;
                this.expensesLoaded = true;
            }
        });

        this.subscribeToFormStatusChanges([this.expensesForm], this.tabName);
    }

    ngOnDestroy() {
        this.removeStatusChangesSubscrition();
        this.removeFormSubscriptions();
        this.removeFixtureSubscription();
    }

    addExpense(): void {
        this.expensesForm.push(this.createRow(true));
    }

    removeExpense(index: number): void {
        this.expensesForm.removeAt(index);
    }

    get expensesModel() {
        return this.expensesForm.controls;
    }

    protected subscribeToFormValueChanges() {
        this.formValueSubscription = this.expensesForm.valueChanges
            .pipe(
                switchMap(() => {
                    const models = this.getFormModels();
                    this.disableExpenses();
                    return this.expenseDataService.updateExpenses(models);
                })
            )
            .subscribe(
                () => {
                    this.expensesModel.forEach((x) => x.markAsPristine());
                    this.expensesForm.enable({ emitEvent: false });
                },
                () => this.expensesForm.enable({ emitEvent: false })
            );
    }

    private createRow(isNew: boolean, expense?: ExpenseClaim): UntypedFormGroup {
        return this.formBuilder.group(
            {
                expenseClaimId: [expense?.expenseClaimId ?? `temp_${Math.random()}`],
                type: [expense?.type ?? "", { validators: [Validators.required] }],
                currency: [this.fixtureCurrency, { validators: [Validators.required] }],
                ownerInvoiceNumber: [expense?.ownerInvoiceNumber ?? null],
                invoiceDate: [parseISODate(expense?.invoiceDate) ?? null],
                finalClaimValue: [expense?.finalClaimValue ?? "", { validators: [Validators.min(0)] }],
                receivedFromOwnerDate: [parseISODate(expense?.receivedFromOwnerDate) ?? null],
                sentToChartererDate: [parseISODate(expense?.sentToChartererDate) ?? null],
                paidDate: [expense?.paidDate ?? null],
                sentToAccountsDate: [expense?.sentToAccountsDate ?? null],
                chartererAcknowledgedReceiptDate: [parseISODate(expense?.chartererAcknowledgedReceiptDate) ?? null],
                agreedDate: [parseISODate(expense?.agreedDate) ?? null],
                commissionable: [expense?.commissionable ?? false],
                comments: [expense?.comments ?? "", { validators: [Validators.maxLength(ExpenseTabComponent.commentsMaxLength)] }],
                claimTypeDescription: [expense?.claimTypeDescription ?? null],
                initialClaimValue: [expense?.initialClaimValue ?? "", { validators: [Validators.required, Validators.min(0)] }],
                grossCommissionableAmount: [expense?.grossCommissionableAmount ?? "", { validators: [Validators.min(0)] }],
                lastContacted: [parseISODate(expense?.lastContacted) ?? null],
                awaitingHardCopy: [expense?.awaitingHardCopy ?? false],
                awaitingDocuments: [expense?.awaitingDocuments ?? false],
                complete: [expense?.complete ?? false],
                lastUpdatedByUser: [expense?.lastUpdatedByUser ?? null],
                lastUpdatedDate: [parseISODate(expense?.lastUpdatedDate) ?? null],
                commissionDate: [expense?.commissionDate ?? null],
                isNew: [isNew],
                claimHandledBy: [expense?.claimHandledBy ?? null]
            },
            { updateOn: "blur" }
        );
    }

    private disableExpenses() {
        this.expensesModel.filter((x) => x.valid && x.value.expenseClaimId.startsWith("temp")).forEach((x) => x.disable({ emitEvent: false }));
    }

    private getFormModels(): ExpenseClaimFormModel[] {
        return this.expensesModel.map((control) => {
            const model = <ExpenseClaimFormModel>control.value;
            model.valid = control.valid;
            model.dirty = control.dirty;
            return model;
        });
    }

    private removeFixtureSubscription() {
        this.removeFixtureSubscription$.next();
        this.removeFixtureSubscription$.complete();
    }

    get invalid() {
        return this.expensesForm.invalid || this.expensesForm.invalid;
    }

    get touched() {
        return this.expensesForm.touched || this.expensesForm.touched;
    }
}
