import { createSelector } from "@ngrx/store";
import { DateTime } from "luxon";

import { stripOffset } from "@ops/shared";
import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { selectCurrentFixture } from "../fixture";
import { selectCurrentVoyage } from "../voyage";

export type LayCan = {
    readonly lay?: DateTime;
    readonly can?: DateTime;
};

export const selectCurrentFixtureLayCanTimeZone = createSelector(selectCurrentVoyage, (voyage) => {
    if (!voyage) {
        return "utc";
    }

    // Only the first destination is applicable for LayCan
    const destination = voyage.destinations[0];
    if (!destination) {
        return "utc";
    }

    const activity = voyage.destinations[0]?.berths[0]?.cargoBerthActivities[0];
    if (activity?.type?.id !== CargoBerthActivityType.Load.id) {
        return "utc";
    }

    return destination.location?.timeZone;
});

/**
 * We currently store LayCan in UTC (or +00:00 offset), despite it being specified as local time of the origin load destination.
 *
 * This also applies to the Gain integration where we have a local DateTime that is serialized as UTC.
 *
 * This selector strips any offset specifier from the ISO date string and parses it using the origin load destination's timezone.
 */
export const selectCurrentFixtureLayCan = createSelector(selectCurrentFixture, selectCurrentFixtureLayCanTimeZone, (fixture, zone) => {
    if (!fixture) {
        return null;
    }

    const laycan = fixture.laycan;
    const lay = stripOffset(laycan?.extensionDate?.from ?? laycan?.date?.from);
    const can = stripOffset(laycan?.extensionDate?.to ?? laycan?.date?.to);

    return <LayCan>{
        lay: lay ? DateTime.fromISO(lay, { zone }) : null,
        can: can ? DateTime.fromISO(can, { zone }) : null
    };
});
