import { DateTime, Interval } from "luxon";
import * as R from "ramda";

import { parseNumber } from "@ops/shared";
import { hasValue } from "@ops/state";

import { createActivityCargo, createActivityLocation, createLaytimeEvent, LaytimeCalculationResult, LaytimeCalculationTerms, LaytimeCalculator } from "../../../calculator";
import { intersectionPercentage } from "../../../calculator/impl/interval-utils";
import { VoyageActivity } from "../../calculations";
import { ActivityLocation } from "./activity-location";
import { LaytimeCalculation } from "./laytime-calculation";
import { LaytimeEvent } from "./laytime-event";
import { LaytimeEventType, UNLESS_USED_LAYTIME_EVENT_TYPES } from "./laytime-event-types";

export const getDiff = <T>(original: T, changed: T): Partial<T> => {
    const changedProperties = Object.getOwnPropertyNames(changed)
        .map((p) => <keyof T>p)
        .filter((p) => changed[p] !== original[p]);
    return <Partial<T>>R.pick(changedProperties, changed);
};

export const getCalculationResult = (calculation: LaytimeCalculation): LaytimeCalculationResult => {
    if (!calculation || !calculation.timeAllowance) {
        return null;
    }

    const terms: LaytimeCalculationTerms = {
        timeAllowance: calculation.timeAllowance,
        durationUnit: calculation.durationUnit,
        rounding: calculation.rounding ?? "Exact",
        fixedAllowanceHours: parseNumber(calculation.fixedAllowanceHours),
        timeSaved: calculation.timeSaved,
        demurrageBank: calculation.demurrageBank,
        demurrageRate: parseNumber(calculation.demurrageRate),
        detentionRate: parseNumber(calculation.detentionRate),
        despatchRate: parseNumber(calculation.despatchRate)
    };

    const activityLocations = calculation.activityLocations.map((x) => {
        const shouldMapExclusion = x.workingDay === "SHEX" || x.workingDay === "SHEX UU";

        return createActivityLocation(
            x.id,
            x.cargoes.map((c) =>
                createActivityCargo(c.id, c.reversible ?? "Non Reversible", {
                    allowance: parseNumber(c.allowance),
                    allowanceUnit: c.allowanceUnit,
                    quantity: parseNumber(c.quantity),
                    extraHours: parseNumber(c.extraHours)
                })
            ),
            x.laytimeEvents.map((l) => createLaytimeEvent(l.id, l.date ? DateTime.fromISO(l.date, { zone: x.timeZone }) : null, parseNumber(l.percentage), l.cargoId)),
            {
                timeZone: x.timeZone,
                exclusionStartDay: shouldMapExclusion ? x.exclusionStartDay : null,
                exclusionStartTime: shouldMapExclusion ? x.exclusionStartTime : null,
                exclusionEndDay: shouldMapExclusion ? x.exclusionEndDay : null,
                exclusionEndTime: shouldMapExclusion ? x.exclusionEndTime : null,
                customaryQuickDespatch: x.customaryQuickDespatch,
                activity: x.activity
            }
        );
    });

    return LaytimeCalculator.calculate(calculation.sector, terms, activityLocations);
};

export const hasExclusion = (activity: ActivityLocation | VoyageActivity) =>
    ["SHEX", "SHEX UU"].includes(activity.workingDay) && activity.exclusionStartDay && activity.exclusionStartTime && activity.exclusionEndDay && activity.exclusionEndTime;

export const getLaytimeEventPercentage = (
    eventType: LaytimeEventType | undefined,
    fromTime: string,
    toTime: string,
    activity: ActivityLocation | VoyageActivity
): number | null => {
    const prevEventDate = DateTime.fromISO(fromTime, { zone: activity.timeZone ?? "utc" });
    const currEventDate = DateTime.fromISO(toTime, { zone: activity.timeZone ?? "utc" });

    if (currEventDate < prevEventDate) {
        return null;
    }

    if (activity.workingDay === "SHEX UU" && eventType && UNLESS_USED_LAYTIME_EVENT_TYPES.includes(eventType)) {
        return 100;
    }

    const interval = Interval.fromDateTimes(prevEventDate, currEventDate);

    const { exclusionStartDay, exclusionStartTime, exclusionEndDay, exclusionEndTime } = activity;
    const intersect = intersectionPercentage(interval, { exclusionStartDay, exclusionStartTime, exclusionEndDay, exclusionEndTime });

    return 100 - intersect;
};

export const isLaytimeEventAfterDemurrageEvent = (calculation: LaytimeCalculation, activityLocation: ActivityLocation, laytimeEvent: LaytimeEvent): boolean => {
    if (calculation.onceOnDemurrage !== "Always On Demurrage") {
        return false;
    }

    const result = getCalculationResult(calculation);

    const activityLocationIndex = calculation.activityLocations.findIndex((a) => a.id === activityLocation.id);
    const laytimeEventIndex = activityLocation.laytimeEvents.findIndex((l) => l.id === laytimeEvent.id);
    const demurrageActivityLocationIndex = calculation.activityLocations.findIndex((a) => a.id === result.demurrageActivityLocationId);
    const demurrageLaytimeEventIndex = activityLocation.laytimeEvents.findIndex((l) => l.id === result.demurrageLaytimeEventId);

    const activityLocationDemurrageLaytimeEventId = result.activityLocations.find((a) => a.id === activityLocation.id).demurrageLaytimeEventId;
    const activityLocationDemurrageLaytimeEventIndex = activityLocation.laytimeEvents.findIndex((l) => l.id === activityLocationDemurrageLaytimeEventId);

    const isFixedOrReversible =
        calculation.timeAllowance === "Fixed" ||
        (activityLocation.cargoes.some((c) => c.reversible === "Reversible") && !laytimeEvent.cargoId) ||
        (hasValue(laytimeEvent.cargoId) && activityLocation.cargoes.find((c) => c.id === laytimeEvent.cargoId).reversible === "Reversible");

    const isFixedOrReversibleDemurrage =
        isFixedOrReversible &&
        demurrageActivityLocationIndex > -1 &&
        ((demurrageActivityLocationIndex === activityLocationIndex && demurrageLaytimeEventIndex < laytimeEventIndex) || demurrageActivityLocationIndex < activityLocationIndex);

    const isActivityLocationDemurrage =
        calculation.timeAllowance === "Non Fixed" && activityLocationDemurrageLaytimeEventIndex > -1 && activityLocationDemurrageLaytimeEventIndex < laytimeEventIndex;

    return isFixedOrReversibleDemurrage || isActivityLocationDemurrage;
};

export const equalByCargoAndProductIds = (item1: { cargoId?: string; productId: number }, item2: { cargoId?: string; productId: number }) =>
    item1.productId === item2.productId && (!item1.cargoId || !item2.cargoId || item1.cargoId === item2.cargoId);
