<div *ngIf="addActivityLocationsSelection$ | async as selection" class="ops-ltc-shell-container">
    <ng-container *ngIf="selection.associatedCargoesByCargoes.length || selection.associatedCargoesByVoyageActivities.length; else noAssociatedCargoes">
        <div class="p-field p-field-checkbox">
            <input id="selection-all-selected" type="checkbox" class="p-checkbox"
                   [checked]="selection.selectAll" (change)="selectAll()"/>
            <label for="selection-all-selected">Select All</label>
        </div>
        <div class="ops-scrollable-container-wrapper">
            <div class="ops-scrollable-container">
                <div [ngSwitch]="selection.filter" class="ops-scrollable-content">
                    <ops-associated-cargoes-by-voyage-activities *ngSwitchCase="'Locations'"
                                                                 [associatedCargoesByVoyageActivities]="selection.associatedCargoesByVoyageActivities"
                                                                 (selectAllByVoyageActivity)="selectAllByVoyageActivity($event)">
                    </ops-associated-cargoes-by-voyage-activities>
                    <ops-associated-cargoes-by-cargoes *ngSwitchCase="'Cargoes'"
                                                       [associatedCargoesByCargoes]="selection.associatedCargoesByCargoes"
                                                       (selectAllByCargo)="selectAllByCargo($event)">
                    </ops-associated-cargoes-by-cargoes>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #noAssociatedCargoes>
        <div class="ops-ltc-no-content">
            <span class="has-icon icon--lightbulb-outline"></span>
            <span class="p-ml-2">No {{selection.filter | lowercase}} selected. Select {{selection.filter | lowercase}} to the left.</span>
        </div>
    </ng-template>
</div>
