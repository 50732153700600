import { createSelector } from "@ngrx/store";

import { selectCurrentLaytimeCalculation, selectCurrentLaytimeCalculationResult, selectCurrentLaytimeCalculationState } from "../selectors";

export const selectCurrentCalculationActivityLocations = createSelector(selectCurrentLaytimeCalculation, (calculation) => calculation?.activityLocations);
export const selectCurrentActivityLocationId = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.currentActivityLocationId);

export const selectCurrentActivityLocation = createSelector(selectCurrentLaytimeCalculation, selectCurrentActivityLocationId, (calculation, activityLocationId) =>
    calculation?.activityLocations.find((l) => l.id === activityLocationId)
);

export const selectCurrentActivityTimezone = createSelector(selectCurrentActivityLocation, (state) => state?.timeZone ?? "utc");

export const selectCurrentActivityLocationName = createSelector(selectCurrentActivityLocation, (state) => state?.name ?? "Unnamed Location");

export const selectCurrentActivityLocationForm = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.activityLocationForm);

export const selectCurrentActivityLocationType = createSelector(selectCurrentActivityLocation, (activityLocation) => activityLocation?.activity);
export const selectCurrentActivityLocationHasNonReversibleCargoes = createSelector(
    selectCurrentActivityLocation,
    (activityLocation) =>
        activityLocation?.cargoes.some((c) => !c.reversible || c.reversible === "Non Reversible") || (!activityLocation?.cargoes.length && activityLocation?.activity !== "Transit")
);
export const selectCurrentActivityLocationHasReversibleCargoes = createSelector(
    selectCurrentActivityLocation,
    (activityLocation) => activityLocation?.cargoes.some((c) => c.reversible === "Reversible") || activityLocation?.activity === "Transit"
);
export const selectCurrentActivityLocationResult = createSelector(selectCurrentLaytimeCalculationResult, selectCurrentActivityLocationId, (result, activityLocationId) => ({
    ...result?.activityLocations.find((x) => x.id === activityLocationId),
    fixedOrReversibleClaimType: result?.claimType,
    fixedOrReversibleClaimValue: result?.claimValue,
    fixedOrReversibleLaytimeAllowed: result?.laytimeAllowed,
    fixedOrReversibleLaytimeUsed: result?.laytimeUsed,
    fixedOrReversibleLaytimeRemaining: result?.laytimeRemaining
}));
