<ngb-accordion
    #favoriteAccordion="ngbAccordion"
    class="favorite-accordion-panel"
    [activeIds]="openTabIds$ | async"
    [closeOthers]="true"
    *ngIf="(favoriteCategories$ | async) as favoriteCategories"
    (panelChange)="onPanelChange($event)"
>
    <ngb-panel
        id="{{ favoriteCategory.name }}"
        *ngFor="let favoriteCategory of favoriteCategories; let index = index; trackBy: categoryTrackBy"
        class="favorite-accordion-panel"
        [id]="favoriteCategory.name"
        data-test-category
    >
        <ng-template ngbPanelTitle>
            <div class="row">
                <div class="form-check favorite-select-checkbox-container">
                    <input
                        type="checkbox"
                        class="favorite-select-checkbox-header-item"
                        [(ngModel)]="favoriteCategory.selected"
                        (click)="onToggleCategory($event, favoriteCategory.fixtureStatusType)"
                        data-test-statuscheckbox
                    />
                </div>
                <div class="favorite-category-name-label">
                    {{ favoriteCategory.name }}
                    <span class="favorite-category-item-count" data-test-fixurescount>({{ favoriteCategory.favorites.length }})</span>
                </div>
                <div class="favorite-expand-category">
                    <div
                        class="has-icon"
                        [ngClass]="
                            (panelChange$ | async)?.id === favoriteCategory.name && (panelChange$ | async)?.isOpened
                                ? 'icon--arrow-drop-down'
                                : 'icon--arrow-drop-right'
                        "
                        data-test-expandstatus
                    ></div>
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <ul class="list-group favorite-list">
                <li class="list-group-item favorite-list-item" *ngFor="let favorite of favoriteCategory.favorites" data-test-fixture>
                    <div class="row">
                        <div class="col-1 form-check favorite-select-checkbox-container">
                            <input
                                type="checkbox"
                                class="favorite-select-checkbox-list-item"
                                [(ngModel)]="favorite.selected"
                                (click)="onToggleFavorite($event, favorite.fixtureId)"
                                data-test-fixturecheckbox
                            />
                        </div>
                        <div class="favorite-lock-button-container">
                            <button
                                type="button"
                                class="favorite-lock-unlock-button has-icon"
                                [disabled]="favorite.isLockedByOtherUser"
                                [ngClass]="
                                    favorite.isLockedByCurrentUser
                                        ? 'icon--fully-fixed user-lock'
                                        : favorite.isLockedByOtherUser
                                        ? 'icon--fully-fixed'
                                        : 'icon--lock-open'
                                "
                                data-test-islocked
                            ></button>
                        </div>
                        <div class="col-1 favorite-color-picker-container">
                            <ops-favorite-color-picker
                                (colorSelected)="onColorSelected($event, favorite.fixtureId)"
                                [fixtureType]="favorite.fixtureType"
                                [currentColorType]="favorite.color"
                            >
                            </ops-favorite-color-picker>
                        </div>
                        <a
                            [routerLink]="['/fixture/' + favorite.fixtureId]"
                            queryParamsHandling="merge"
                            container="body"
                            [ngbTooltip]="favorite.tooltipContent"
                            class="col favorite-fixture-name-label"
                            (click)="onFavouriteClick()"
                            data-test-fixturevessel
                            >{{ favorite.fixtureName }}
                        </a>
                        <div class="favorite-remove-button-container">
                            <button
                                type="button"
                                class="favorite-remove-button has-icon icon--close"
                                (click)="removeFavorite(favorite.fixtureId)"
                                [disabled]="!favorite.canRemove"
                                data-test-removefixture
                            ></button>
                        </div>
                    </div>
                </li>
            </ul>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
