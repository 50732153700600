import { createAction, on, On, props } from "@ngrx/store";
import { createFormGroupState } from "ngrx-forms";
import { CargoId, CoasState, getCurrentLiftingState, getNewCargoId, liftingCargoToForm } from "../../../model";
import { currentLiftingStateReducer } from "../../reducer";

/* ACTIONS */
export const cloneLiftingCargoAction = createAction("[Lifting Cargo Form] Clone Cargo", props<{ cargoId: CargoId }>());

/* REDUCERS */
export const cloneLiftingCargoReducer: On<CoasState> = on(cloneLiftingCargoAction, (state, { cargoId }) => {
    const currentLifting = getCurrentLiftingState(state);
    const cargo = currentLifting.lifting.cargoes.find((c) => c.cargoId === cargoId);

    if (!cargo) {
        return state;
    }

    const form = { ...liftingCargoToForm(cargo), cargoId: getNewCargoId() };

    return currentLiftingStateReducer(state, (liftingState) => ({
        ...liftingState,
        liftingCargoForm: createFormGroupState(form.cargoId + ".liftingCargoForm", form)
    }));
});
