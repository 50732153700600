import { createFormGroupState, disable, FormGroupState, updateGroup } from "ngrx-forms";

import { Lifting } from "./lifting";

export type LiftingCommentsForm = Readonly<{
    comments: string;
    areCommentsImportant: boolean;
    isEditing: boolean;
}>;

export const createLiftingCommentsFormState = (source: Lifting, isEditing = false): FormGroupState<LiftingCommentsForm> => {
    const form: LiftingCommentsForm = {
        comments: source.summaryComments,
        areCommentsImportant: source.areSummaryCommentsImportant,
        isEditing: isEditing
    };
    let formState = createFormGroupState(source.liftingId + ".liftingSummaryCommentsForm", form);
    if (!formState.value.comments) {
        formState = updateGroup<LiftingCommentsForm>({ areCommentsImportant: disable })(formState);
    }

    return formState;
};
