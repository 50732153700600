import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { LoadAnimationComponent } from "./load-animation.component";

@NgModule({
    imports: [CommonModule],
    declarations: [LoadAnimationComponent],
    exports: [LoadAnimationComponent]
})
export class OpsLoadAnimationModule {}
