import { MaritimeDate } from "@maritech/maritime-date";

import { toDateTime } from "@ops/shared";

import { compareValues } from "../../../fixture/state/utils";
import { Coa, Lifting } from "../model";
import { getChartererNameOrTbn } from "../utils";
import { getLiftingLaycan } from "./liftings-index-helper";

export type CargoPlanEmail = Readonly<{
    subject: string;
    body: string;
}>;

export const getCargoPlanEmail = (coa: Coa, lifting: Lifting): CargoPlanEmail => {
    if (!coa || !lifting) {
        return null;
    }

    const dashes = 90;
    const charterer = getChartererNameOrTbn(coa);
    const cpDate = toDateTime(coa.charterpartyDate).toLocaleString(MaritimeDate.DATE_FORMAT);
    const laycan = getLiftingLaycan(lifting, coa.driver);

    let body = "";

    const cargoes = lifting.cargoes?.concat() ?? [];

    for (const cargo of cargoes) {
        const quantity = cargo.quantity?.length && cargo.quantityUnit ? `${cargo.quantity} ${cargo.quantityUnit} ` : "";
        const tolerance =
            quantity.length > 0 && cargo.minTolerance?.length > 0 && cargo.maxTolerance?.length > 0 && cargo.toleranceUnit?.length > 0
                ? `(${cargo.minTolerance}/${cargo.maxTolerance} ${cargo.toleranceUnit}) `
                : "";
        const locations =
            cargo.loadLocations[0]?.name && cargo.dischargeLocations?.length > 0
                ? ` + ${cargo.loadLocations[0].name} - ${cargo.dischargeLocations[cargo.dischargeLocations.length - 1].name}`
                : "";
        body += `${cargo.orderId?.length > 0 ? cargo.orderId : " -"} : ${(quantity + tolerance + cargo.name + locations).trim()}\r\n`;
    }

    if (laycan && laycan.from && laycan.to) {
        body += `\r\nCargo Laycan ${toDateTime(laycan.from).toLocaleString(MaritimeDate.DATETIME_FORMAT)} - ${toDateTime(laycan.to).toLocaleString(
            MaritimeDate.DATETIME_FORMAT
        )}\r\n`;
    }
    body += "-".repeat(dashes) + "\r\n";

    const allLocations = cargoes
        .reduce((acc, c) => acc.concat(c.loadLocations).concat(c.dischargeLocations), [])
        .filter((c) => !!c.name)
        .sort((c1, c2) => compareValues(c1.eta?.from, c2.eta?.from));

    for (const location of allLocations) {
        const eta = location.eta?.from
            ? `${toDateTime(location.eta?.from).toLocaleString(MaritimeDate.DATETIME_FORMAT)} - ${toDateTime(location.eta?.to).toLocaleString(MaritimeDate.DATETIME_FORMAT)}`
            : "-";
        body += `ETA ${location.name}: ${eta}\r\n`;
    }

    return {
        subject: `Cargo Nomination / ${charterer} - Lifting - ${cpDate}${lifting.liftingNumber ? " - " + lifting.liftingNumber : ""}`.trim(),
        body: body.trim()
    };
};
