import { addActivityReducer } from "./form/add-activity";
import { collapseActivityReducer, expandActivityReducer } from "./form/expand-activity";
import { moveActivityDownReducer, moveActivityUpReducer } from "./form/move-activity";
import { prefillLaytimeEventsReducer } from "./form/prefill-laytime-events";
import { removeActivityReducer } from "./form/remove-activity";

export default [
    addActivityReducer,
    collapseActivityReducer,
    expandActivityReducer,
    moveActivityUpReducer,
    moveActivityDownReducer,
    prefillLaytimeEventsReducer,
    removeActivityReducer
];
