<div class="ops-ltc-location-summary-wrapper">
    <div class="ops-ltc-result" *ngIf="!isFixed && hasNonReversibleCargoes">
        <div class="ops-ltc-result-value">
            <span class="label" data-cy="claim-label">{{calculationResult.claimType || 'Claim'}} Value:</span>
            <span class="value" data-cy="claim-value">{{calculationResult.claimValue | currency: currency }}</span>
        </div>
        <div class="ops-ltc-result-value">
            <span class="label">Allowed:</span>
            <span class="value" data-cy="allowed">{{calculationResult.laytimeAllowed | durationFormat: durationUnit }}</span>
        </div>
        <div class="ops-ltc-result-value">
            <span class="label">Used:</span>
            <span class="value" data-cy="used">{{calculationResult.laytimeUsed | durationFormat: durationUnit }}</span>
        </div>
        <div class="ops-ltc-result-value">
            <span class="label">Remaining:</span>
            <span class="value" [class.negative]="+calculationResult.laytimeRemaining < 0" data-cy="remaining">{{calculationResult.laytimeRemaining | durationFormat: durationUnit }}</span>
        </div>
    </div>
    <div class="ops-ltc-result" [class.reversible]="hasReversibleCargoes" [class.fixed]="isFixed" *ngIf="isFixed || hasReversibleCargoes">
        <div class="ops-ltc-result-value">
            <span class="label" data-cy="reversible-claim-label">{{reversibleText}} {{calculationResult.fixedOrReversibleClaimType || 'Claim'}} Value:</span>
            <span class="value" data-cy="reversible-claim-value">{{calculationResult.fixedOrReversibleClaimValue | currency: currency }}</span>
        </div>
        <div class="ops-ltc-result-value">
            <span class="label">{{reversibleText}} Allowed:</span>
            <span class="value" data-cy="reversible-allowed">{{calculationResult.fixedOrReversibleLaytimeAllowed | durationFormat: durationUnit }}</span>
        </div>
        <div class="ops-ltc-result-value">
            <span class="label">{{reversibleText}} Used:</span>
            <span class="value" data-cy="reversible-used">{{calculationResult.fixedOrReversibleLaytimeUsed | durationFormat: durationUnit }}</span>
        </div>
        <div class="ops-ltc-result-value">
            <span class="label">{{reversibleText}} Remaining:</span>
            <span class="value" [class.negative]="+calculationResult.fixedOrReversibleLaytimeRemaining < 0" data-cy="reversible-remaining">{{calculationResult.fixedOrReversibleLaytimeRemaining | durationFormat: durationUnit }}</span>
        </div>
    </div>
</div>
