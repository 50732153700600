import { Enumeration } from "../../../../shared/reference-data/enumeration";

export type SeaNetLocationId = string;

export interface SeaNetLocation {
    locationId: SeaNetLocationId;
    displayName: string;
    unCode?: string;
    timeZone?: string;
    isCountry?: boolean;
    isPort?: boolean;
    isZone?: boolean;
    countryName?: string;
    countryUnCode?: string;
    locationType: Enumeration;
    aliases: string[];
}

export function getTimeZone(location: SeaNetLocation | undefined) {
    return (location ? location.timeZone : null) || "utc";
}
