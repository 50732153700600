import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { FixtureSource } from "../../fixture/shared/models/enums/fixture-source";
import { LeftBarStateService } from "../left-bar-state.service";
import { CompanySearchComponent } from "./company-search/company-search.component";

@Component({
    selector: "ops-contacts",
    templateUrl: "./contacts.component.html",
    styleUrls: ["./contacts.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsComponent implements OnInit, OnDestroy, AfterViewInit {
    static componentName = "ContactsComponent";

    private fixtureDataServiceSubscription: Subscription;
    private leftBarStateServiceSubscription: Subscription;
    private _companySearchComponent: CompanySearchComponent;

    selectSearchForm: UntypedFormGroup;
    fixtureSource = FixtureSource.Gain;
    FixtureSource = FixtureSource;

    @ViewChild(CompanySearchComponent)
    set companySearchComponent(component: CompanySearchComponent) {
        if (component) {
            component.setSelectFocus();
        }
        this._companySearchComponent = component;
    }

    constructor(private fb: UntypedFormBuilder, private fixtureDataService: FixtureDataService, private leftBarStateService: LeftBarStateService) {}

    ngOnInit() {
        this.selectSearchForm = this.fb.group({
            searchType: "personSearch"
        });

        this.fixtureDataServiceSubscription = this.fixtureDataService.currentFixture$.subscribe(() => {
            this.fixtureSource = this.fixtureDataService.fixtureSource;
            if (this.fixtureSource !== FixtureSource.Gain) {
                this.selectSearchForm.setValue({ searchType: "companySearch" });
            }
        });

        this.leftBarStateServiceSubscription = this.leftBarStateService.isCollapsed$.subscribe(() => {
            if (this._companySearchComponent) {
                this._companySearchComponent.setSelectFocus();
            }
        });
    }

    ngAfterViewInit() {}

    ngOnDestroy() {
        this.fixtureDataServiceSubscription.unsubscribe();
        this.leftBarStateServiceSubscription.unsubscribe();
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }
}
