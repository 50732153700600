<div class="side-panel" data-test-voyages-panel>
    <span class="side-panel-title">
        Time Charter Voyages
        <div class="left-toolbar-expander" (click)="collapseLeftBar()">
            <div class="has-icon icon--close"></div>
        </div>
    </span>
    <div class="voyages-container d-flex flex-column">
        <div class="voyages-summary-title">
            <div class="row no-gutters">
                <div ngbDropdown role="group" placement="bottom-right" class="col-12 align-self-center" autoClose="outside">
                    <button
                        [ngClass]="isOpen || isClosed ? 'has-icon icon--filter float-right' : 'has-icon icon--empty-filter float-right'"
                        ngbDropdownToggle
                        date-test-filter-menu
                        [ngbTooltip]="tooltipContent"
                        container="body"
                        placement="left"
                    ></button>
                    <div class="dropdown-menu rounded-0 p-0 m-0" ngbDropdownMenu>
                        <div class="form-group d-flex dropdown-item m-0">
                            <input #open type="checkbox" name="open" [(ngModel)]="isOpen" data-test-open-voyage />
                            <span>Open</span>
                            <div class="w-100 h-100 mask" (click)="open.click()"></div>
                        </div>
                        <div class="form-group d-flex dropdown-item m-0">
                            <input #closed type="checkbox" name="closed" [(ngModel)]="isClosed" data-test-closed-voyage />
                            <span>Closed</span>
                            <div class="w-100 h-100 mask" (click)="closed.click()"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="loading-panel-container" data-test-loading-panel-container>
            <div class="loading-panel" *ngIf="!isLoaded || (savingVoyages$ | async)">
                <ops-load-animation size="x-large" class="loading-animation"></ops-load-animation>
            </div>
            <div class="scrollable-content" *ngIf="isLoaded">
                <ng-container *ngIf="(voyages$ | async) as voyages; else noVoyages">
                    <ng-container #voyagesContainer *ngFor="let voyage of (voyages | voyageStatus: isOpen:isClosed)">
                        <ops-tc-voyage [voyage]="voyage" (voyageSelected)="voyageSelected($event)"></ops-tc-voyage>
                    </ng-container>
                </ng-container>
                <ng-template #noVoyages>
                    <div class="row no-voyages-placeholder" *ngIf="isLoaded" data-test-no-voyages-placeholder>
                        <div class="col-3">
                            <div class="circle">
                                <span class="has-icon icon--lightbulb-outline"></span>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="title">No voyages against this fixture.</div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="row no-gutters buttons">
        <div class="col d-flex justify-content-end p-0">
            <button
                type="button"
                [disabled]="!(isLockedByCurrentUser$ | async) || (creatingVoyage$ | async) || (savingVoyages$ | async)"
                class="btn btn-primary m-0"
                (click)="createVoyage()"
                data-test-add-voyage
            >
                <span class="has-icon icon--add-circle-outline"></span>
                TC Voyage
            </button>
        </div>
    </div>
</div>
