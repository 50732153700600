import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { OnChanges } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { SimpleChanges } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { UntypedFormBuilder } from "@angular/forms";
import { UntypedFormArray } from "@angular/forms";
import { UntypedFormControl } from "@angular/forms";
import { Validators } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { Enumeration } from "../../../../shared/reference-data/enumeration";
import { ReferenceDataService } from "../../../../shared/reference-data/reference-data.service";
import { FormComponentBase } from "../../../shared/form-component-base";
import { Bunker } from "../../../shared/models/dtos/bunker.dto";

@Component({
    selector: "ops-bunkers",
    templateUrl: "./bunkers.component.html",
    styleUrls: ["./bunkers.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BunkersComponent extends FormComponentBase implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    static componentName = "BunkersComponent";

    private readonly destroy$ = new Subject();
    private ngSelectElementsCount = 0;

    bunkersForm: UntypedFormArray;
    allBunkerTypes: Enumeration[];
    hideBunkers: boolean;

    @Output() bunkerRemoved = new EventEmitter();
    @Output() bunkerUpdated = new EventEmitter();
    @Output() bunkerAdded = new EventEmitter();
    @Input() bunkers: Bunker[];
    @Input() title: string;
    @Input() parentForm: UntypedFormGroup;

    @ViewChildren("bunkerTypeElement", { read: NgSelectComponent }) ngSelectElements: QueryList<NgSelectComponent>;

    constructor(private changeDetectorRef: ChangeDetectorRef, private formBuilder: UntypedFormBuilder, public referenceDataService: ReferenceDataService) {
        super();
    }

    ngOnInit(): void {
        this.referenceDataService.getBunkerTypes().subscribe((bunkerTypes: Enumeration[]) => {
            this.allBunkerTypes = bunkerTypes;
            this.changeDetectorRef.markForCheck();
        });

        this.createForm();
        this.parentForm.registerControl("bunkers", this.bunkersForm);
        if (this.parentForm.disabled) {
            this.bunkersForm.disable();
        } else {
            this.bunkersForm.enable();
        }
        this.setForm(this.bunkers);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.bunkers && !changes.bunkers.firstChange) {
            this.setForm(this.bunkers);
        }
    }

    ngAfterViewInit() {
        this.ngSelectElements.changes
            .pipe(
                takeUntil(this.destroy$),
                filter((elements: QueryList<NgSelectComponent>) => elements.length !== this.ngSelectElementsCount)
            )
            .subscribe((elements) => {
                this.ngSelectElementsCount = elements.length;
                if (!this.bunkersForm.disabled && elements.length) {
                    elements.last.focus();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    deleteBunker(index: number) {
        this.bunkerRemoved.emit(index);
    }

    addNew() {
        this.bunkerAdded.emit();
    }

    private createForm() {
        this.bunkersForm = this.formBuilder.array([]);
    }

    private setForm(bunkers: Bunker[]) {
        const dataModel = bunkers.map((m) => ({
            bunkerType: m.bunkerType,
            quantity: m.quantity,
            price: m.price
        }));

        this.removeFormSubscriptions();
        this.resizeBunkerList(bunkers.length);

        this.bunkersForm.setValue(dataModel, { emitEvent: false });
        this.subscribeToFormValueChanges(this.bunkersForm, (data) => {
            this.bunkerUpdated.emit(data);
        });
    }

    private resizeBunkerList(bunkerCount: number): void {
        const bunkersArray = <UntypedFormArray>this.bunkersForm;

        if (bunkersArray.length === bunkerCount) {
            return;
        } else if (bunkersArray.length > bunkerCount) {
            bunkersArray.removeAt(0);
        } else if (bunkersArray.length < bunkerCount) {
            bunkersArray.push(this.createBunkerRow(this.bunkersForm.disabled));
        }

        return this.resizeBunkerList(bunkerCount);
    }

    private createBunkerRow(disabled: boolean): UntypedFormGroup {
        const group = this.formBuilder.group({
            bunkerType: [],
            quantity: new UntypedFormControl({ updateOn: "blur" }, Validators.min(0)),
            price: new UntypedFormControl({ updateOn: "blur" }, Validators.min(0))
        });

        if (disabled) {
            group.disable();
        }

        return group;
    }

    get bunkersArray() {
        return this.bunkersForm.controls;
    }

    get totalQuantity() {
        const sum = (total: number, currentValue: number) => total + (currentValue || 0);
        return this.bunkers.map((bunker) => bunker.quantity).reduce(sum, 0);
    }
}
