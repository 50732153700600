<a [routerLink]="['/', { outlets: { primary: ['ltc', calculation.id], toolbar: null } }]" queryParamsHandling="merge">
    <div class="ops-ltc-card p-p-2" (click)="handleClick($event)">
        <div class="ops-ltc-card-header">
            <div class="ops-ltc-name" data-cy="calculation-name">{{calculation.name}}</div>
            <ops-icon-button
                icon="more-vert"
                type="more"
                *ngIf="!calculation.isPersisting"
                [ngbPopover]="moreMenuContent"
                #moreMenu="ngbPopover"
                (click)="moreMenu.open()"
                placement="bottom-right"
                triggers="manual"
                data-cy="calculation-kebab-menu"
            ></ops-icon-button>
            <ng-template #moreMenuContent>
                <div class="more-menu list-group">
                    <div class="more-menu-item list-group-item list-group-item-action" (click)="onDeleteButtonClick()">
                        <span class="has-icon icon--delete"></span>
                        <span>Delete</span>
                    </div>
                </div>
            </ng-template>
            <div *ngIf="calculation.isPersisting">
                <ops-load-animation class="light-background" size="small"></ops-load-animation>
            </div>
        </div>
        <div class="ops-ltc-modified" data-cy="calculation-modified">Last updated by: {{calculation.lastUpdatedBy}} {{calculation.lastUpdatedAt | maritimeDate:true}}</div>
    </div>
</a>

<p-confirmDialog data-cy="laytime-calculation-delete-confirmation-dialog"></p-confirmDialog>
