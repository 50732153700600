import { Injectable } from "@angular/core";
import { CommandHandler } from "src/app/fixture/mediator/command-handlers/command-handler";
import { Fixture } from "src/app/fixture/shared/models";
import { DateRange } from "src/app/fixture/shared/models/dtos/date-range.dto";
import { Enumeration } from "src/app/shared/reference-data/enumeration";
import { AddOptionCommand } from "./add-option.command";

@Injectable({
    providedIn: "root"
})
export class AddOptionCommandHandler implements CommandHandler {
    static defaultExerciseOption = new Enumeration(3, "Pending");

    handle = (fixture: Fixture, command: AddOptionCommand) => {
        fixture.options = fixture.options || [];

        let periodRange = <DateRange>null;
        if (fixture.options.length) {
            const previousPeriodRange = fixture.options[fixture.options.length - 1].periodRange;
            if (previousPeriodRange) {
                periodRange = { from: previousPeriodRange.to, to: null };
            }
        }

        fixture.options.push({
            periodRange: periodRange,
            hireRate: null,
            durationInDays: null,
            hireRateUnit: null,
            declarationDueDate: null,
            exerciseOption: AddOptionCommandHandler.defaultExerciseOption,
            exercised: false
        });
    };
}
