import { ChangeDetectorRef, Component, OnChanges } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";

import { EngineConsumptionComponentBase } from "./engine-consumption-base.component";

@Component({
    selector: "ops-engine-consumption",
    templateUrl: "./engine-consumption.component.html",
    styleUrls: ["./engine-consumption.component.scss"]
})
export class EngineConsumptionComponent extends EngineConsumptionComponentBase implements OnChanges {
    static componentName = "EngineConsumptionComponent";
    constructor(public changeDetectorRef: ChangeDetectorRef, public formBuilder: UntypedFormBuilder) {
        super(changeDetectorRef, formBuilder);
    }
}
