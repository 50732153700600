import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ROUTER_NAVIGATED } from "@ngrx/router-store";
import { pipe } from "rxjs";
import { distinctUntilChanged, filter, map, tap } from "rxjs/operators";

import { routeParamChange } from "@ops/state";

import { MixpanelService } from "../../core";
import { LiftingTab } from "../coa-routing.module";
import { MixpanelEvents } from "./coa-logging.effects";
import { routerLoadCoaAction } from "./coa/routing";
import { routerLoadLiftingAction } from "./lifting/routing";
import { CoaId, LiftingId } from "./model";

export const routeCoaIdChange = () => routeParamChange("coaId");
export const routeLiftingIdChange = () => routeParamChange("liftingId");
const routeLiftingTabChange = (activatedRoute: ActivatedRoute, liftingTabName: LiftingTab) =>
    pipe(
        ofType(ROUTER_NAVIGATED),
        filter(() => {
            let route: ActivatedRoute | null;
            for (route = activatedRoute; route; route = route.firstChild) {
                if (route.snapshot.data.liftingTab === liftingTabName) {
                    return true;
                }
            }
            return false;
        }),
        distinctUntilChanged()
    );

@Injectable()
export class CoaRoutingEffects {
    loadCoa$ = createEffect(() =>
        this.actions$.pipe(
            routeCoaIdChange(),
            map((coaId: CoaId) => routerLoadCoaAction({ coaId }))
        )
    );

    loadLifting$ = createEffect(() =>
        this.actions$.pipe(
            routeLiftingIdChange(),
            map((liftingId: LiftingId) => routerLoadLiftingAction({ liftingId }))
        )
    );

    liftingSetCargoTab$ = createEffect(
        () =>
            this.actions$.pipe(
                routeLiftingTabChange(this.route, "cargo"),
                tap(() => this.mixpanel.track(MixpanelEvents.LiftingCargoTabClick))
            ),
        { dispatch: false }
    );

    liftingSetVesselTab$ = createEffect(
        () =>
            this.actions$.pipe(
                routeLiftingTabChange(this.route, "vessel"),
                tap(() => this.mixpanel.track(MixpanelEvents.LiftingVesselTabClick))
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private route: ActivatedRoute, private mixpanel: MixpanelService) {}
}
