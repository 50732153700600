import { createAction, On, on, props } from "@ngrx/store";
import { moveArrayControl, updateGroup } from "ngrx-forms";

import { updateBerthForm } from "../../berths/shared";
import { ActivityId, BerthId, DestinationId, FixturesState } from "../../model";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const moveActivityUpAction = createAction("[Voyage Form] Move Activity Up", props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId }>());
export const moveActivityDownAction = createAction("[Voyage Form] Move Activity Down", props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId }>());

/* REDUCERS */
const moveActivity = (state: FixturesState, destinationId: DestinationId, berthId: BerthId, activityId: ActivityId, offset: number) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const destination = voyageState.form.controls.destinations.controls.find((d) => d.value.id === destinationId);
        if (!destination) {
            return voyageState;
        }
        const berth = destination.controls.berths.controls.find((b) => b.value.id === berthId);
        if (!berth) {
            return voyageState;
        }

        const activities = berth.controls.activities;
        const fromIndex = activities.value.findIndex((a) => a.activityId === activityId);
        const toIndex = fromIndex + offset;

        if (toIndex < 0 || toIndex > activities.value.length - 1) {
            return voyageState;
        }

        return {
            ...voyageState,
            form: updateBerthForm({ destinationId, berthId }, (berthForm) =>
                updateGroup(berthForm, {
                    activities: moveArrayControl(fromIndex, toIndex)
                })
            )(voyageState.form)
        };
    });

export const moveActivityUpReducer: On<FixturesState> = on(moveActivityUpAction, (state, { destinationId, berthId, activityId }) =>
    moveActivity(state, destinationId, berthId, activityId, -1)
);

export const moveActivityDownReducer: On<FixturesState> = on(moveActivityDownAction, (state, { destinationId, berthId, activityId }) =>
    moveActivity(state, destinationId, berthId, activityId, 1)
);
