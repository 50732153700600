import { createFeatureSelector, createSelector } from "@ngrx/store";

import { LegacyWorksheetState } from "./legacy-worksheet.reducer";
import * as fromWorksheet from "./legacy-worksheet.reducer";

export const legacyWorksheetsFeatureKey = "legacyWorksheets";
export const selectLegacyWorksheetState = createFeatureSelector<LegacyWorksheetState>(legacyWorksheetsFeatureKey);

export const selectWorksheetIds = createSelector(selectLegacyWorksheetState, fromWorksheet.selectWorksheetIds);
export const selectWorksheetEntities = createSelector(selectLegacyWorksheetState, fromWorksheet.selectWorksheetEntities);
export const selectAllWorksheets = createSelector(selectLegacyWorksheetState, fromWorksheet.selectAllWorksheets);
export const selectWorksheetTotal = createSelector(selectLegacyWorksheetState, fromWorksheet.selectWorksheetTotal);
export const selectSelectedWorksheetId = createSelector(selectLegacyWorksheetState, fromWorksheet.getSelectedWorksheetId);
export const selectLoadingStatus = createSelector(selectLegacyWorksheetState, fromWorksheet.getloadingStatus);
export const selectError = createSelector(selectLegacyWorksheetState, fromWorksheet.getError);
export const selectCurrentWorksheetSaveable = createSelector(selectLegacyWorksheetState, fromWorksheet.getCurrentWorksheetSaveable);
export const selectCurrentWorksheet = createSelector(selectLegacyWorksheetState, fromWorksheet.getCurrentWorksheet);
export const selectFullLoadComplete = createSelector(selectLegacyWorksheetState, fromWorksheet.getFullLoadComplete);
export const selectSavingWorksheetError = createSelector(selectLegacyWorksheetState, fromWorksheet.getSavingWorksheetError);
export const selectSelectedWorksheet = createSelector(
    selectWorksheetEntities,
    selectSelectedWorksheetId,
    (entities, selectedWorksheetId) => entities && entities[selectedWorksheetId]
);
export const selectCurrentWorksheetName = createSelector(
    selectSelectedWorksheet,
    (worksheet) => worksheet?.name
);
export const selectWorksheetTypeToShow = createSelector(selectLegacyWorksheetState, fromWorksheet.getWorksheetTypeToShow);
export const selectWorksheetsToShow = createSelector(
    selectWorksheetEntities,
    selectWorksheetTypeToShow,
    (worksheets, worksheetTypeToShow) => Object.values(worksheets).filter(x => x?.worksheetType === worksheetTypeToShow)
);
export const selectWorksheetsNamesToShow = createSelector(
    selectWorksheetsToShow,
    (worksheets) => worksheets.map(worksheet => worksheet?.name)
);
export const selectDefaultWorksheetSet = createSelector(selectLegacyWorksheetState, fromWorksheet.getDefaultWorksheetSet);
export const selectLoadingEditedSavedWorksheetStatus = createSelector(selectLegacyWorksheetState, fromWorksheet.getLoadingEditedSavedWorksheetStatus);
