import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/core";
import { Invoice } from "../shared/models/dtos/invoice.dto";

@Injectable({
    providedIn: "root"
})
export class InvoiceHttpService {
    private invoicesServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.invoicesServiceUrl = appConfigService.config.invoicesServiceUrl;
    }

    get(fixtureId: string): Observable<Invoice[]> {
        return this.httpClient.get<Invoice[]>(`${this.invoicesServiceUrl}/api/v1.0/fixture/${fixtureId}/invoice`);
    }

    create(fixtureId: string, invoice: Invoice): Observable<string> {
        return this.httpClient.post<string>(`${this.invoicesServiceUrl}/api/v1.0/fixture/${fixtureId}/invoice`, invoice);
    }

    update(fixtureId: string, invoice: Invoice): Observable<any> {
        return this.httpClient.put(`${this.invoicesServiceUrl}/api/v1.0/fixture/${fixtureId}/invoice/${invoice.invoiceId}`, invoice);
    }

    delete(fixtureId: string, invoiceId: string): Observable<any> {
        return this.httpClient.delete(`${this.invoicesServiceUrl}/api/v1.0/fixture/${fixtureId}/invoice/${invoiceId}`);
    }
}
