import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { VesselSuggestionService } from "./vessel-autosuggest.service";
import { AppConfigService } from "../../../../core";
import { VesselData } from "../../../../fixture/shared/models/common/vessel-data";
import { AutosuggestComponent } from "../autosuggest.component";

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VesselAutosuggestComponent),
    multi: true
};

@Component({
    selector: "ops-vessel-autosuggest",
    templateUrl: "./vessel-autosuggest.component.html",
    styleUrls: ["../../../../shared/components/autosuggest/autosuggest.component.scss", "./vessel-autosuggest.component.scss"],
    providers: [VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VesselAutosuggestComponent extends AutosuggestComponent<VesselData> {
    static componentName = "VesselAutosuggestComponent";
    readonly bargeFleetTypes: string[];
    clarksonsCdnUrl: string;

    @Input() readonly: boolean;

    constructor(
        protected vesselSuggestionService: VesselSuggestionService,
        protected appConfigService: AppConfigService,
        protected element: ElementRef,
        protected changeDetector: ChangeDetectorRef
    ) {
        super(vesselSuggestionService, element, changeDetector);

        this.bargeFleetTypes = appConfigService.config.bargeFleetTypes ? appConfigService.config.bargeFleetTypes.split(",") : [];
        this.clarksonsCdnUrl = appConfigService.config.clarksonsCdnUrl;
    }

    isBarge(vessel: VesselData) {
        if (vessel.fleetTypes && vessel.fleetTypes.length > 0) {
            return vessel.fleetTypes.some((ft) => this.bargeFleetTypes.some((bft) => bft === ft.id.toString()));
        }
        return false;
    }

    getFleetType(vessel: VesselData) {
        return this.isBarge(vessel) ? "Barge" : "Vessel";
    }

    getVesselIdentifier(vessel: VesselData) {
        if (this.isBarge(vessel) && !vessel.imoNumber) {
            return vessel.eniNumber;
        } else if (vessel.imoNumber) {
            return vessel.imoNumber.toString();
        }
        return "";
    }
}
