<div [formGroup]="demurrageClaimForm" class="card embedded-card-1">
    <div class="card-header row pl-0 no-gutters align-items-center">
        <div>
            <button
                type="button"
                (click)="toggleDemurrageClaim()"
                class="has-icon btn-inverted"
                [ngClass]="{ 'icon--arrow-drop-right': hideDemurrageClaim, 'icon--arrow-drop-down': !hideDemurrageClaim }"
                data-test-toggledemurrageclaim
            ></button>
        </div>
        <div
            class="validation-indicator has-icon icon--warning"
            [class.invalid]="demurrageClaimForm.invalid"
            [class.touched]="demurrageClaimForm.touched"
            container="body"
            ngbTooltip="There are validation errors on this claim"
            data-test-demurrage-claim-header
        ></div>
        <ops-demurrage-claim-summary
            [demurrageClaim]="demurrageClaims[index]"
            [estimatedClaimValue]="estimatedClaimValue"
            [onAccountIndex]="onAccountIndex"
            [index]="index"
            data-test-demurrage-warning
        ></ops-demurrage-claim-summary>
        <button
            [disabled]="demurrageClaimForm.disabled"
            class="btn-delete ml-auto has-icon icon--delete btn-inverted"
            type="button"
            (click)="demurrageClaimForm.enabled && removeDemurrageClaim($event)"
            data-test-deletedemurrageclaim
        ></button>
    </div>
    <div class="card-body" [hidden]="hideDemurrageClaim">
        <div class="row no-gutters">
            <div class="col-8 full-claim">
                <div class="row no-gutters">
                    <div class="form-group col-6 col-xl-3 required">
                        <label for="type">Claim Type</label>
                        <ng-select
                            id="type"
                            class="ops-select"
                            formControlName="type"
                            [compareWith]="referenceDataService.compare"
                            [items]="demurrageClaimTypes"
                            [clearable]="false"
                            bindLabel="name"
                            [selectOnTab]="true"
                            data-test-claimtype
                        >
                        </ng-select>
                    </div>
                    <div class="form-group col-6 col-xl">
                        <label>Location</label>
                        <ng-select
                            class="ops-select"
                            formControlName="berth"
                            [compareWith]="compareBerth"
                            [items]="availableBerths"
                            [clearable]="true"
                            bindLabel="description"
                            [selectOnTab]="true"
                            data-test-claim-location
                        >
                        </ng-select>
                    </div>
                    <div class="form-group col-4 col-xl pl-0 pl-xl-2 claim-padding">
                        <label>Received From Owner</label>
                        <ops-date-input
                            formControlName="receivedFromOwnerDate"
                            warningActive
                            ngrxFormControlId="demurrage.{{index}}.receivedFromOwnerDate"
                            timeZone="local"
                            data-test-fullclaimreceivedfromownerdate
                        ></ops-date-input>
                    </div>
                    <div class="form-group col-4 col-xl">
                        <label>Sent To Charterer</label>
                        <ops-date-input
                            formControlName="sentToChartererDate"
                            timeZone="local"
                            data-test-fullclaimsenttochartererdate
                        ></ops-date-input>
                    </div>
                    <div class="form-group col-4 col-xl pr-2">
                        <label>Charterer Ack. Receipt</label>
                        <ops-date-input
                            formControlName="chartererAcknowledgedReceiptDate"
                            timeZone="local"
                            data-test-fullclaimchartereracknowledgedreceiptdate
                        ></ops-date-input>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="form-group col-4 col-xl-3" *opsRole="'Laytime'">
                        <label>Est. Claim Value</label>
                        <input
                            [value]="estimatedClaimValue | opsNumber:true:2:2"
                            class="form-control text-right"
                            type="text"
                            readonly
                            data-test-estimatedclaimvalue
                        />
                    </div>
                    <div class="form-group col-4 col-xl">
                        <label>Initial Claim Value</label>
                        <input
                            formControlName="initialClaimValue"
                            class="form-control text-right"
                            name="initialClaimValue"
                            type="text"
                            [opsNumber]="{ precision: 2 }"
                            data-test-initialclaimvalue
                        />
                        <ops-validation [form]="demurrageClaimForm.get('initialClaimValue')"></ops-validation>
                    </div>
                    <div class="form-group col-4 col-xl pr-2">
                        <label>Interim Claim Value</label>
                        <input
                            formControlName="interimClaimValue"
                            class="form-control text-right"
                            type="text"
                            [opsNumber]="{ precision: 2 }"
                            data-test-interimclaimvalue
                        />
                        <ops-validation [form]="demurrageClaimForm.get('interimClaimValue')"></ops-validation>
                    </div>
                    <div class="form-group col-4 col-xl pl-0 pl-xl-2">
                        <label>Final Claim Value</label>
                        <input
                            formControlName="finalClaimValue"
                            class="form-control text-right"
                            type="text"
                            [opsNumber]="{ precision: 2 }"
                            data-test-finalclaimvalue
                        />
                        <ops-validation [form]="demurrageClaimForm.get('finalClaimValue')"></ops-validation>
                    </div>
                    <div class="form-group col-4 col-xl pr-2">
                        <label for="currency">Currency Type</label>
                        <ng-select
                            id="currency"
                            class="ops-select"
                            formControlName="currency"
                            [compareWith]="referenceDataService.compareCurrencies"
                            [items]="currencies$ | async"
                            [clearable]="false"
                            bindLabel="code"
                            [selectOnTab]="true"
                            data-test-claimcurrency
                        >
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="form-group col-4 d-flex flex-column claim-padding">
                <label>Comments</label>
                <textarea formControlName="comments" class="form-control flex-grow-1" data-test-claimcomments></textarea>
                <ops-validation [form]="demurrageClaimForm.get('comments')"></ops-validation>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-12 full-claim">
                <div class="row no-gutters">
                    <div class="form-group col-4 col-xl-2">
                        <label>Agreed Date</label>
                        <ops-date-input
                            formControlName="agreedDate"
                            timeZone="local"
                            data-test-agreed-date
                        ></ops-date-input>
                    </div>
                    <div class="form-group col-4 col-xl">
                        <label>Date Paid</label>
                        <ops-date-input
                            formControlName="datePaid"
                            timeZone="local"
                            data-test-date-paid
                        ></ops-date-input>
                    </div>
                    <div class="form-group col-4 col-xl pr-2">
                        <label>Commission Date</label>
                        <ops-date-input
                            formControlName="commissionDate"
                            timeZone="local"
                            data-test-commission-date
                        ></ops-date-input>
                    </div>
                    <div class="form-group col-4 col-xl pr-2">
                        <label>Last Contacted</label>
                        <div class="form-control p-0 d-flex justify-content-between" readonly>
                            <span class="update-last-contacted-content" data-test-update-last-contacted>{{
                                demurrageClaimForm.controls.lastContactedDateTime.value | date: "dd MMM yy"
                            }}</span>
                            <button class="update-last-contacted-button" (click)="updateLastContacted()" type="button" data-test-update-last-contacted-button>
                                <span class="has-icon icon--tag-tick"></span>
                            </button>
                        </div>
                    </div>
                    <div class="form-group col-4 col-xl pr-2">
                        <label>Owner Invoice Number</label>
                        <input formControlName="ownerInvoiceNumber" class="form-control" type="text" data-test-ownerinvoicenumber />
                    </div>
                    <div class="form-group col-4 col-xl-4 pr-2">
                        <label>Claim Handled By</label>
                        <input formControlName="claimHandledBy" class="form-control" type="text" data-test-claimhandledby />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr class="horizontal-line" />
            </div>
        </div>

        <div class="row flex-row no-gutters justify-content-start">
            <div class="form-group d-flex align-items-end checkbox-container">
                <input
                    type="checkbox"
                    id="awaitingHardCopy_{{ index }}"
                    name="awaitingHardCopy"
                    formControlName="awaitingHardCopy"
                    data-test-awaiting-hard-copy
                />
                <label for="awaitingHardCopy_{{ index }}">Awaiting Hard Copy</label>
            </div>
            <div class="form-group d-flex align-items-end checkbox-container">
                <input
                    type="checkbox"
                    id="awaitingDocuments_{{ index }}"
                    name="awaitingDocuments"
                    formControlName="awaitingDocuments"
                    data-test-awaiting-documents
                />
                <label for="awaitingDocuments_{{ index }}">Awaiting Documents</label>
            </div>
            <div class="form-group d-flex align-items-end checkbox-container">
                <input type="checkbox" id="complete_{{ index }}" name="complete" formControlName="complete" data-test-complete />
                <label for="complete_{{ index }}">Complete</label>
            </div>
        </div>
        <div class="row no-gutters" *ngIf="lastUpdatedByUser">
            <div class="col text-right last-updated">Updated by:&nbsp;</div>
            <div class="col-auto pull-right last-updated" data-test-lastupdated>{{ lastUpdatedByUser }} {{ lastUpdatedDate | date: "dd MMM yy" }}</div>
        </div>
    </div>
</div>
<div #scrollToPlaceholder></div>
