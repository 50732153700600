import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "ops-fixture-popup-footer",
    templateUrl: "./fixture-popup-footer.component.html",
    styleUrls: ["./fixture-popup-footer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupFooterComponent {
    @Input() isReadOnly: boolean;
    @Input() animatingSaveButton: boolean;

    @Output() readonly save = new EventEmitter();
    @Output() readonly cancel = new EventEmitter();
}
