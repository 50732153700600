import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { CommandHandler } from "../../../../mediator/command-handlers/command-handler";
import { Fixture } from "../../../../shared/models";
import { Period } from "../../../../shared/models/dtos/period.dto";
import { sortPeriods } from "../periods-sorter";
import { AddPeriodCommand } from "./add-period.command";

@Injectable({
    providedIn: "root"
})
export class AddPeriodCommandHandler implements CommandHandler {
    handle = (fixture: Fixture, command: AddPeriodCommand) => {
        let lastToDate = null;
        if (fixture.periods === null) {
            fixture.periods = [];
        }
        if (fixture.periods.length > 0) {
            lastToDate = fixture.periods[fixture.periods.length - 1].periodRange.to;
        }
        const period: Period = {
            hireId: Guid.create().toString(),
            hireRateUnit: null,
            hireRate: null,
            durationInDays: null,
            minDuration: null,
            maxDuration: null,
            durationType: null,
            toleranceInDays: null,
            periodRange: {
                from: lastToDate,
                to: null
            },
            invoicesPaidUpTo: null,
            offHires: []
        };
        fixture.periods.push(period);
        fixture.periods = sortPeriods(fixture.periods);
    }
}
