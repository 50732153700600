import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { Currency } from "@ops/shared";

import { ActivityLocationSummary, LaytimeCalculationDurationUnit } from "../../state";

@Component({
    selector: "ops-laytime-calculation-activity-location-summary",
    templateUrl: "./laytime-calculation-activity-location-summary.component.html",
    styleUrls: ["./laytime-calculation-activity-location-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationActivityLocationSummaryComponent {
    @Input() calculationResult: ActivityLocationSummary;
    @Input() currency: Currency;
    @Input() durationUnit: LaytimeCalculationDurationUnit;
    @Input() hasReversibleCargoes: boolean;
    @Input() hasNonReversibleCargoes: boolean;
    @Input() isFixed: boolean;

    get reversibleText() {
        return this.isFixed ? "Total" : "Reversible";
    }

    get fixedOrReversibleClaimValueText() {
        return (!this.isFixed ? "Reversible " : "") + "Claim Value";
    }
}
