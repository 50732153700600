<div>
    <div class="header">
        Contacts
        <div class="left-toolbar-expander" (click)="collapseLeftBar()">
            <div class="has-icon icon--close"></div>
        </div>
    </div>
    <div *ngIf="fixtureSource === FixtureSource.Gain" [formGroup]="selectSearchForm" class="container spacer">
        <div class="spacer padding"></div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="searchType" id="personSearch" value="personSearch" />
            <label for="personSearch" class="form-check-label">People</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="searchType" id="companySearch" value="companySearch" />
            <label for="companySearch" class="form-check-label">Company</label>
        </div>
    </div>
    <ops-person-search *ngIf="selectSearchForm.get('searchType').value === 'personSearch'"></ops-person-search>
    <ops-company-search *ngIf="selectSearchForm.get('searchType').value === 'companySearch'"></ops-company-search>
    <div class="spacer"></div>

    <div class="clearfix">
        <a
            href="mailto:support@sea.live?subject=New Company Request&body=Please%20add%20the%20following%20company%3A%0A%0AFull%20Legal%20Corporate%20Name%0A%0A%3CPlease%20provide%20the%20Full%20Legal%20Corporate%20Name%3E%0A%0A%0AFull%20address%20including%20country%0A%0A%3CPlease%20provide%20the%20Full%20address%20including%20country%3E%0A%0A%0AGroup%20Name%20(or%20affiliated%20company)%0A%0A%3CPlease%20provide%20the%20Group%20Name%20(or%20affiliated%20company)%3E"
            >Request New Company</a
        >
    </div>

    <div class="spacer line"></div>

    <div *ngIf="fixtureSource !== FixtureSource.Gain" class="container info">
        <div class="info-panel">
            <div class="info-inner-container d-flex flex-column">
                <div class="d-flex flex-wrap justify-content-between">
                    <div class="d-flex">
                        <div class="info-panel-icon">
                            <span class="has-icon icon--info-outline"></span>
                        </div>
                        <div class="info-title">
                            <span class="info-panel-title">In order to share this fixture, please find the companies and select from their sharing groups</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ops-contacts-list></ops-contacts-list>
