import { Component, HostBinding, Input } from "@angular/core";

import { MaritechIcon } from "@ops/shared";

@Component({
    selector: "ops-action-button",
    template: `
        <button *ngIf="type" class="btn btn-{{ type }}" type="button" [disabled]="disabled" [ngbTooltip]="tooltip" [placement]="tooltipPlacement">
            <span *ngIf="icon" class="has-icon icon--{{ icon }}"></span>
            <span *ngIf="!busy">
                <ng-content></ng-content>
            </span>
            <ops-load-animation *ngIf="busy" size="small"></ops-load-animation>
        </button>
    `,
    styleUrls: ["../../../../assets/styles/clarksons-button.scss", "./action-button.component.scss"]
})
export class ActionButtonComponent {
    @Input() icon: MaritechIcon;
    @Input() tooltip: string;
    @Input() tooltipPlacement = "auto";

    @HostBinding("class.ops-action-button-disabled")
    @Input()
    disabled = false;

    @Input() type: "action" | "add" | "clone" | "delete" | "primary" = "action";

    /**
     * When true, replaces the content of the button with a loading animation.
     */
    @Input() busy = false;
}
