import { createAction, on, On, props } from "@ngrx/store";

import { voyageStateReducer } from "./reducer";
import { Voyage } from "../../shared/models";
import { FixturesState, formToVoyage, VoyageState } from "../model";

/* Support for non ngrx-forms migrated voyage components */
function projectVoyage(state: VoyageState): Voyage {
    if (!state) {
        return null;
    }

    let voyage = state.workingVoyage ?? state.voyage;

    if (voyage && state.form) {
        voyage = formToVoyage(state.form.value, voyage);
        voyage.isValid = state.form.isValid;
        voyage.isDirty = voyage.isDirty || state.form.isDirty;
    }

    return voyage;
}

/* ACTIONS */
export const interopVoyageDataServicePublishAction = createAction("[VoyageDataService Interop] Voyage Publish", props<{ voyage: Voyage; command: string }>());

/* REDUCERS */
export const interopVoyageReducer: On<FixturesState> = on(interopVoyageDataServicePublishAction, (state, { voyage }) =>
    voyageStateReducer(state, voyage.voyageId, {
        workingVoyage: voyage
    })
);

/**
 * Reducer to update the working voyage within the state if the form value has changed.
 *
 * This was previously implemented as a selector, however, we have some reducers which
 * update values on the voyage/working voyage.
 *
 * @param initialState  The state before any reducers run.
 * @param state         The state after all form and ngrx-form reducers have been run.
 */
export const formToVoyageReducer = (initialState: FixturesState, state: FixturesState): FixturesState => {
    // NOTE: This is written by hand as opposed to immer JS as it runs every cycle and takes approx .1ms vs 2-3ms with immer
    const initialVoyagesState = initialState?.voyages;
    let voyagesState = state.voyages;

    if (!voyagesState?.allIds?.length) {
        return state;
    }

    for (const voyageId of state.voyages.allIds) {
        const initialVoyageState = initialVoyagesState?.byId[voyageId];

        const voyageState = state.voyages.byId[voyageId];

        if (!voyageState?.form?.value || initialVoyageState?.form?.value === voyageState?.form?.value) {
            continue;
        }

        const workingVoyage = projectVoyage(voyageState);

        if (workingVoyage === initialVoyageState?.workingVoyage) {
            continue;
        }

        const updatedVoyageState = {
            ...voyageState,
            workingVoyage: workingVoyage
        };

        voyagesState = {
            ...voyagesState,
            byId: {
                ...voyagesState.byId,
                [voyageId]: updatedVoyageState
            }
        };
    }

    if (voyagesState !== state.voyages) {
        return {
            ...state,
            voyages: voyagesState
        };
    }

    return state;
};
