import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DateTime } from "luxon";
import { fromEvent, merge, Observable, of, Subject, timer } from "rxjs";
import { debounceTime, filter, map, switchMap } from "rxjs/operators";

import { AppConfigService, AuthService, FeatureToggleService } from "@ops/core";

import { ActionDto, LiveUpdatesHubService } from "../../action";
import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { Division, Fixture } from "../../fixture/shared/models";
import { FixtureWarningService } from "../../fixture/shared/warnings/fixture-warning.service";
import { LeftBarInterop } from "../../state/_interop/left-bar-interop.service";
import { CoADataService } from "../coa/coa-data.service";
import { LeftBarStateService } from "../left-bar-state.service";
import { FixtureType } from "../shared/models";

const isUpdatedAction = (action: ActionDto): boolean => {
    if (action && action.updatedDate) {
        const maxSecondsAllowed = -10;
        const startDate = DateTime.fromISO(action.updatedDate);
        const currentTime = DateTime.utc();
        return startDate.diff(currentTime, "seconds").seconds > maxSecondsAllowed;
    }
    return false;
};

@Component({
    selector: "ops-left-toolbar",
    templateUrl: "./left-toolbar.component.html",
    styleUrls: ["./left-toolbar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftToolbarComponent implements OnInit {
    static componentName = "LeftToolbarComponent";

    private resizeSubject = new Subject();
    private toolbar = "toolbar";
    private fixture: Fixture;
    private pageUrl: string;

    isCollapsed$: Observable<boolean>;
    displayContactsMenuItem: boolean;
    isContractsVisible = false;
    coACount = 0;

    readonly hasTcVoyageRole$ = this.authService.hasRole("TCVoyages");
    readonly hasLtcRole$ = this.authService.hasRole("Laytime");
    readonly hasCreateFixtureRole$ = this.authService.hasRole("AddFixture");

    hasWarnings$: Observable<boolean>;
    hasUnseenActions$: Observable<boolean>;
    isCreateFixtureVisible$: Observable<boolean>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private changeDetector: ChangeDetectorRef,
        @Inject("Window") private window: Window,
        appConfigService: AppConfigService,
        private coADataService: CoADataService,
        private fixtureDataService: FixtureDataService,
        private leftBarStateService: LeftBarStateService,
        private featureToggleService: FeatureToggleService,
        private authService: AuthService,
        private fixtureWarningService: FixtureWarningService,
        private liveUpdatesHubService: LiveUpdatesHubService,
        private leftBarInterop: LeftBarInterop
    ) {
        this.displayContactsMenuItem = appConfigService.config.displayContactsMenuItem === "true";
    }

    @HostListener("window:resize", ["$event"])
    onWindowResize(event: any) {
        this.resizeSubject.next(event);
    }

    ngOnInit() {
        const debounceValue = 100;
        const timerValue = 10000;
        this.pageUrl = window.location.href;

        this.leftBarInterop.init();
        this.setActiveToolbar();

        this.coADataService.currentCoaFixtureSummaries$.subscribe((coASummaries) => {
            if (coASummaries) {
                this.coACount = coASummaries.length;
                this.isContractsVisible = true;
            } else {
                this.coACount = null;
                this.isContractsVisible = false;
            }
            this.changeDetector.markForCheck();
        });

        this.fixtureDataService.currentFixture$.subscribe((fixture) => {
            this.fixture = fixture;
            this.changeDetector.markForCheck();
        });

        this.resizeSubject.pipe(debounceTime(debounceValue)).subscribe((event: any) => {
            if (event.target.innerWidth <= this.leftBarStateService.overlayScreenSizeBreakpoint && this.leftBarStateService.isCollapsedSnapshot === false) {
                this.leftBarStateService.collapse();
                this.changeDetector.markForCheck();
            }
        });

        this.isCollapsed$ = this.leftBarStateService.isCollapsed$;

        this.buildLeftBarToggler().subscribe(() => this.leftBarStateService.collapseIfSmallWindow());
        this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.pageUrl = event.urlAfterRedirects || event.url;
            const isToolbarInNewUrl = this.pageUrl.includes(this.toolbar);

            this.setActiveToolbar();
            if (isToolbarInNewUrl && this.leftBarStateService.isCollapsedSnapshot) {
                this.toggleCollapse();
            }
            this.changeDetector.markForCheck();
        });

        this.hasWarnings$ = this.fixtureWarningService.warningStates$.pipe(map((warnings) => !!warnings?.filter((w) => !w.isDismissed).length));

        this.hasUnseenActions$ = this.liveUpdatesHubService.actionsUpdated$.pipe(
            filter((action) => isUpdatedAction(action)),
            switchMap(() => merge(of(true), timer(timerValue).pipe(map(() => false))))
        );

        this.isCreateFixtureVisible$ = this.hasCreateFixtureRole$.pipe(map((hasRole) => hasRole && !this.isCoaToolbarVisible && !this.isLtcToolbarVisible));
    }

    navigate(toolbarSection: string) {
        const currentToolbarRoute = this.activatedRoute.children.find((child) => child.routeConfig.outlet === this.toolbar);
        if (currentToolbarRoute?.routeConfig?.path === toolbarSection) {
            this.toggleCollapse();
        } else {
            this.router.navigate([{ outlets: { toolbar: toolbarSection } }], { queryParamsHandling: "merge" });

            if (this.leftBarStateService.isCollapsedSnapshot) {
                this.toggleCollapse();
            }
        }
    }

    toggleCollapse() {
        this.leftBarStateService.toggle();
    }

    get isTemperatureLogActive() {
        const { fixtureType, division } = this.fixtureDataService;
        return fixtureType === FixtureType.Voyage && division === Division.pcg;
    }

    get isTcVoyagesActive() {
        const { fixtureType } = this.fixtureDataService;
        return fixtureType === FixtureType.TimeCharter;
    }

    get isTcNoonReportsActive() {
        const { fixtureType } = this.fixtureDataService;
        return fixtureType === FixtureType.TimeCharter;
    }

    get isVoyageFixtureType() {
        const { fixtureType } = this.fixtureDataService;
        return fixtureType === FixtureType.Voyage;
    }

    get isFixtureToolbarVisible() {
        return this.fixture && this.pageUrl.includes("fixture/");
    }

    get isCoaToolbarVisible() {
        return this.pageUrl.includes("coa/");
    }

    get isLtcToolbarVisible() {
        return this.pageUrl.includes("ltc/");
    }

    get isLiftingActive() {
        return this.pageUrl.includes("lifting/");
    }

    private buildLeftBarToggler(): Observable<Event> {
        return merge(
            fromEvent(this.window.document.querySelectorAll(".app-router-overlay")[0], "click"),
            fromEvent(this.window.document.querySelectorAll(".grid-nav")[0], "click")
        ).pipe(filter(() => !this.leftBarStateService.isCollapsedSnapshot));
    }

    private setActiveToolbar() {
        const currentToolbarRoute = this.activatedRoute.children.find((child) => child.routeConfig.outlet === this.toolbar);
        if (!currentToolbarRoute?.routeConfig?.path) {
            this.leftBarStateService.collapse();
        }
        this.leftBarInterop.setCurrentPanel(currentToolbarRoute?.routeConfig?.path);
    }
}
