import { Injectable } from "@angular/core";

import { isNullOrUndefined } from "@ops/shared";

import { CargoBerthActivityType } from "../../../../../shared/reference-data/cargo-berth-activity-type";
import { AssociatedCargo, Berth, CargoBerthActivity, Destination, Fixture, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class FreightBlDischargeQuantityRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        voyages.forEach((voyage) => {
            voyage.destinations.forEach((destination, destinationIndex) =>
                destination.berths.forEach((berth, berthIndex) =>
                    berth.cargoBerthActivities.forEach((activity, activityIndex) => {
                        if (!activity.type || (activity.type.id !== CargoBerthActivityType.Load.id && activity.type.id !== CargoBerthActivityType.Discharge.id)) {
                            return;
                        }

                        activity.associatedCargoes.forEach((associatedCargo, associatedCargoIndex) => {
                            if (associatedCargo.quantity > 500_000) {
                                const quantityName = this.getQuantityName(activity.type);
                                const quantityUnit = associatedCargo.quantityUnit ? associatedCargo.quantityUnit.name : "";
                                const message = `The ${quantityName} entered is more than 500,000 ${quantityUnit}`.trim();
                                const path = [
                                    ...this.getAssociatedCargoPath(
                                        fixture,
                                        voyage,
                                        destinationIndex,
                                        destination,
                                        berthIndex,
                                        berth,
                                        activityIndex,
                                        activity,
                                        associatedCargoIndex,
                                        associatedCargo
                                    ),
                                    FixtureWarningPathSegment.fromProperty<AssociatedCargo>("quantity", quantityName)
                                ];
                                const dismissingRecord = dismissedWarningRecord(
                                    new WarningHashBuilder()
                                        .withVoyage(voyage.voyageId)
                                        .withDestination(destination.destinationId)
                                        .withBerth(berth.berthId)
                                        .withBerthActivity(activity.berthActivityId)
                                        .withAssociatedCargo(associatedCargo.associatedCargoId)
                                        .withCategory("freight")
                                        .withMessage(message)
                                        .build(),
                                    associatedCargo.quantity
                                );
                                warnings.push(new FixtureWarning("freight", message, path, dismissingRecord));
                            }
                        });
                    })
                )
            );

            voyage.cargoes
                .filter((cargo) => !isNullOrUndefined(cargo.quantity))
                .forEach((cargo) => {
                    [CargoBerthActivityType.Load, CargoBerthActivityType.Discharge].forEach((activityType) => {
                        const quantityName = this.getQuantityName(activityType);

                        let totalQuantity = 0;
                        let hasQuantitySet = false;

                        const destinations = new Set<string>();
                        let pathDestinationIndex: number;
                        let pathBerthIndex: number;
                        let pathActivityIndex: number;
                        let pathAssociatedCargoIndex: number;

                        voyage.destinations.forEach((destination, destinationIndex) =>
                            destination.berths.forEach((berth, berthIndex) =>
                                berth.cargoBerthActivities.forEach((activity, activityIndex) => {
                                    if (!activity.type || activity.type.id !== activityType.id) {
                                        return;
                                    }

                                    activity.associatedCargoes.forEach((associatedCargo, associatedCargoIndex) => {
                                        if (associatedCargo.cargoId === cargo.id) {
                                            if (!isNullOrUndefined(associatedCargo.quantity)) {
                                                totalQuantity += associatedCargo.quantity;
                                                hasQuantitySet = true;
                                            }

                                            destinations.add(destination.location ? destination.location.displayName : null);

                                            if (pathDestinationIndex === undefined) {
                                                pathDestinationIndex = destinationIndex;
                                                pathBerthIndex = berthIndex;
                                                pathActivityIndex = activityIndex;
                                                pathAssociatedCargoIndex = associatedCargoIndex;
                                            }
                                        }
                                    });
                                })
                            )
                        );

                        const buildAssociatedCargoPath = () => {
                            const berthPathSegment =
                                destinations.size > 1
                                    ? FixtureWarningPathSegment.fromIndex(pathBerthIndex, null)
                                    : FixtureWarningPathSegment.fromIndex(pathBerthIndex, "Berth", voyage.destinations[pathDestinationIndex].berths[pathBerthIndex].name);

                            return [
                                ...this.getVoyagePathSegment(fixture.fixtureType, voyage),
                                FixtureWarningPathSegment.fromProperty<Voyage>("destinations", "Locations"),
                                FixtureWarningPathSegment.fromIndex(pathDestinationIndex, "Location", Array.from(destinations).join(", ")),
                                FixtureWarningPathSegment.fromProperty<Destination>("berths", null),
                                berthPathSegment,
                                FixtureWarningPathSegment.fromProperty<Berth>("cargoBerthActivities"),
                                FixtureWarningPathSegment.fromIndex(pathActivityIndex, "Activity", activityType.name),
                                FixtureWarningPathSegment.fromProperty<CargoBerthActivity>("associatedCargoes"),
                                FixtureWarningPathSegment.fromIndex(pathAssociatedCargoIndex, "Cargo", cargo.cargoProduct ? cargo.cargoProduct.name : null),
                                FixtureWarningPathSegment.fromProperty<AssociatedCargo>("quantity", quantityName)
                            ];
                        };

                        const createDismissingRecord = (message: string) =>
                            dismissedWarningRecord(
                                new WarningHashBuilder()
                                    .withVoyage(voyage.voyageId)
                                    .withCargo(cargo.cargoId)
                                    .withBerthActivity(activityType.id)
                                    .withCategory("freight")
                                    .withMessage(message)
                                    .build(),
                                totalQuantity
                            );

                        if (hasQuantitySet && totalQuantity > 2 * cargo.quantity) {
                            const message = `The sum of all ${quantityName} entered is more than 200% of the Estimated Quantity`;
                            warnings.push(new FixtureWarning("freight", message, buildAssociatedCargoPath(), createDismissingRecord(message)));
                        }

                        if (hasQuantitySet && totalQuantity < 0.5 * cargo.quantity) {
                            const message = `The sum of all ${quantityName} entered is less than 50% of the Estimated Quantity`;
                            warnings.push(new FixtureWarning("freight", message, buildAssociatedCargoPath(), createDismissingRecord(message)));
                        }
                    });
                });
        });

        return warnings;
    }

    private getQuantityName(activityType: CargoBerthActivityType) {
        return (activityType.id === CargoBerthActivityType.Load.id ? "B/L" : "Discharge") + " Quantity";
    }
}
