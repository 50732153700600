import { createSelector } from "@ngrx/store";

import { selectCurrentCoaState } from "../coa";
import { companyRoles } from "../model";
import { isCharterer, isOwner, isOwnerOrCharterer } from "../utils";

export const selectCurrentCoaCompanies = createSelector(selectCurrentCoaState, (state) => {
    const coa = state.coa;
    return coa
        ? coa.contacts.map((c, i) => ({
              ...c,
              isReadonly: !!coa.controlledProperties.find((cp) => cp === `contacts[${i}]`)
          }))
        : [];
});

export const selectCurrentCoaOwner = createSelector(selectCurrentCoaCompanies, (companies) => companies.find((c) => isOwner(c)));
export const selectCurrentCoaCharterer = createSelector(selectCurrentCoaCompanies, (companies) => companies.find((c) => isCharterer(c)));

export const selectCurrentCoaCompanyRoles = createSelector(selectCurrentCoaCompanies, (companies) =>
    companies?.length
        ? companyRoles.map((r) => {
              const company = companies.find((c) => c.role === r);
              return { name: r, disabled: !!company && isOwnerOrCharterer(company) };
          })
        : []
);

export const selectCompanyForm = createSelector(selectCurrentCoaState, (state) => state?.companyForm);
