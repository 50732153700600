import { createSelector } from "@ngrx/store";
import { Duration } from "luxon";
import * as R from "ramda";

import { Currency } from "@ops/shared";

import { getFlagImageUrl } from "../../../../coa/state";
import { ClaimType, LaytimeCalculationResult } from "../../../calculator";
import { ActivityLocationId, ActivityType, LaytimeCalculation, LaytimeEvent } from "../../model";
import { selectCurrentLaytimeCalculation, selectCurrentLaytimeCalculationResult } from "../selectors";

export type StartStopLaytimeEvents = Readonly<{
    start?: LaytimeEvent;
    stop?: LaytimeEvent;
}>;

export type LaytimeCalculationSummary = Readonly<{
    currency?: Currency;
    claimType?: ClaimType;
    claimValue?: number;
    laytimeAllowed?: Duration;
    laytimeUsed?: Duration;
    laytimeRemaining?: Duration;
    hasReversible: boolean;
    isFixed: boolean;
    locations: ReadonlyArray<LaytimeCalculationSummaryLocation>;
    hasDemurrage: boolean;
    hasDespatch: boolean;
    lastReversibleLocationId: ActivityLocationId;
}>;

export type LaytimeCalculationSummaryLocation = Readonly<{
    id: ActivityLocationId;
    locationName: string;
    countryUnCode?: string;
    countryName?: string;
    flagImgUrl: string;
    cargoName: string;
    activity: ActivityType;
    startStopLaytimeEvents: StartStopLaytimeEvents;
    hasReversible: boolean;
    hasNonReversible: boolean;
    claimType?: ClaimType;
    claimValue?: number;
    laytimeAllowed?: Duration;
    laytimeUsed?: Duration;
    laytimeRemaining?: Duration;
    reversibleLaytimeAllowed?: Duration;
    reversibleLaytimeUsed?: Duration;
}>;

export const selectCurrentLaytimeCalculationSummary = createSelector(
    selectCurrentLaytimeCalculation,
    selectCurrentLaytimeCalculationResult,
    (calculation: LaytimeCalculation, result: LaytimeCalculationResult, { cdnUrl }: { cdnUrl: string }) => {
        if (!calculation) {
            return null;
        }

        const isFixed = calculation.timeAllowance === "Fixed";
        const claimType = result?.claimType;

        const locations = calculation.activityLocations.map((al) => {
            const activityLocationResult = result?.activityLocations.find((r) => r.id === al.id);
            const startStopLaytimeEvents = getStartStopLaytimeEvents(al.laytimeEvents);

            return <LaytimeCalculationSummaryLocation>{
                id: al.id,
                locationName: al.name,
                countryUnCode: al.countryUnCode,
                countryName: al.countryName,
                flagImgUrl: getFlagImageUrl(cdnUrl, al.countryUnCode),
                cargoName: al.cargoes.map((c) => c.name + (c.orderId ? ` - ${c.orderId}` : "")).join(", "),
                activity: al.activity,
                startStopLaytimeEvents,
                hasReversible: (!isFixed && al.cargoes.some((c) => c.reversible === "Reversible")) || al.activity === "Transit",
                hasNonReversible: al.cargoes.some((c) => !c.reversible || c.reversible === "Non Reversible") || (!al.cargoes.length && al.activity !== "Transit"),
                claimValue: activityLocationResult?.claimValue,
                claimType: activityLocationResult?.claimType,
                laytimeAllowed: activityLocationResult?.laytimeAllowed,
                laytimeUsed: activityLocationResult?.laytimeUsed,
                laytimeRemaining: activityLocationResult?.laytimeRemaining,
                reversibleLaytimeAllowed: activityLocationResult?.reversibleLaytimeAllowed,
                reversibleLaytimeUsed: activityLocationResult?.reversibleLaytimeUsed
            };
        });

        return {
            currency: calculation.currency,
            claimType,
            claimValue: result?.claimValue,
            laytimeAllowed: result?.laytimeAllowed,
            laytimeUsed: result?.laytimeUsed,
            laytimeRemaining: result?.laytimeRemaining,
            hasReversible: locations.some((l) => l.hasReversible),
            isFixed,
            locations,
            hasDemurrage: hasClaimType(locations, result, "Demurrage"),
            hasDespatch: hasClaimType(locations, result, "Despatch"),
            hasDetention: hasClaimType(locations, result, "Detention"),
            lastReversibleLocationId: getLastReversibleLocationId(locations)
        };
    }
);

export const hasClaimType = (locations: LaytimeCalculationSummaryLocation[], result: LaytimeCalculationResult, requiredClaimType: ClaimType) => {
    if (result?.claimValue && requiredClaimType === result?.claimType) {
        return true;
    }

    if (locations.some(({ claimType, claimValue }) => claimValue && claimType === requiredClaimType)) {
        return true;
    }

    return false;
};

export const getLastReversibleLocationId = (locations: LaytimeCalculationSummaryLocation[]) => R.findLast((location) => location.hasReversible, locations)?.id;

export const getStartStopLaytimeEvents = (laytimeEvents: ReadonlyArray<LaytimeEvent>): StartStopLaytimeEvents => {
    const startEventIdx = laytimeEvents.findIndex((laytimeEvent, i) => laytimeEvent.percentage !== "0" && i !== 0);

    if (startEventIdx === -1) {
        return { start: null, stop: null };
    }

    const startEvent = { ...laytimeEvents[startEventIdx], date: laytimeEvents[startEventIdx - 1].date };
    const stopEvent = R.findLast((laytimeEvent: LaytimeEvent) => {
        const id = laytimeEvent.id;
        if (laytimeEvent.percentage !== "0" && id !== startEvent.id && id !== laytimeEvents[0].id) {
            return true;
        }

        return false;
    })(laytimeEvents);

    return { start: startEvent ?? null, stop: stopEvent ?? null };
};
