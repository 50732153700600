<div [ngClass]="classList" class="striped-loader">
  <div class="stripe">
      <div class="stripe-inner"></div>
  </div>
  <div class="stripe">
      <div class="stripe-inner"></div>
  </div>
  <div class="stripe">
      <div class="stripe-inner"></div>
  </div>
  <div class="stripe">
      <div class="stripe-inner"></div>
  </div>
  <div class="stripe">
      <div class="stripe-inner"></div>
  </div>
</div>
