import { cancelLiftingFailReducer, cancelLiftingReducer, cancelLiftingSuccessReducer } from "./cancel-lifting";
import {
    updateCargoNominationHistoryByCargoPlanStatusReducer,
    updateCargoNominationHistoryByLaycanReducer,
    updateCargoPlanEmailAuditReducer,
    updateCargoNominationHistoryOnCargoRemovalReducer,
    updateCargoNominationHistoryByCargoReducer
} from "./cargo-nomination-history";
import cargoReducers from "./cargo/reducers";
import contactReducers from "./company/reducers";
import { createFixtureFailReducer, createFixtureReducer, createFixtureSuccessReducer } from "./create-fixture";
import { editLiftingDeclarationDateReducer, editLiftingHeaderReducer } from "./edit-header";
import liftingNominationTaskActionsReducers from "./lifting-actions/reducers";
import { liftingHistoryToggleAllReducer, liftingHistoryToggleKeyReducer } from "./lifting-history";
import {
    routerLoadCargoNominationHistoryFailReducer,
    routerLoadCargoNominationHistorySuccessReducer,
    routerLoadLiftingFailReducer,
    routerLoadLiftingReducer,
    routerLoadLiftingSuccessReducer,
    routerLoadVesselNominationHistoryFailReducer,
    routerLoadVesselNominationHistorySuccessReducer
} from "./routing";
import summaryCommentReducers from "./summary-comments/reducers";
import { updateTimestampReducer } from "./update-timestamp";
import {
    saveVesselNominationHistoryByVesselReducer,
    updateVesselNominationHistoryByVesselIdReducer,
    updateVesselNominationHistoryByVesselReducer,
    updateVesselNominationHistoryByVesselRejectionReducer,
    updateVesselNominationHistoryByVesselRemovalReducer,
    updateVesselNominationHistoryByVesselRenominationReducer,
    updateVesselNominationHistoryWithPreferredVesselReducer
} from "./vessel-nomination-history";

export default [
    routerLoadLiftingReducer,
    routerLoadLiftingSuccessReducer,
    routerLoadLiftingFailReducer,
    routerLoadVesselNominationHistorySuccessReducer,
    routerLoadVesselNominationHistoryFailReducer,
    routerLoadCargoNominationHistorySuccessReducer,
    routerLoadCargoNominationHistoryFailReducer,
    editLiftingHeaderReducer,
    editLiftingDeclarationDateReducer,
    updateTimestampReducer,
    ...cargoReducers,
    createFixtureReducer,
    createFixtureSuccessReducer,
    createFixtureFailReducer,
    updateVesselNominationHistoryByVesselRemovalReducer,
    updateVesselNominationHistoryByVesselIdReducer,
    updateVesselNominationHistoryByVesselRejectionReducer,
    updateVesselNominationHistoryWithPreferredVesselReducer,
    updateVesselNominationHistoryByVesselReducer,
    saveVesselNominationHistoryByVesselReducer,
    updateCargoNominationHistoryByLaycanReducer,
    updateCargoNominationHistoryByCargoPlanStatusReducer,
    updateCargoNominationHistoryByCargoReducer,
    updateCargoNominationHistoryOnCargoRemovalReducer,
    updateVesselNominationHistoryByVesselRenominationReducer,
    ...summaryCommentReducers,
    cancelLiftingReducer,
    cancelLiftingSuccessReducer,
    cancelLiftingFailReducer,
    ...liftingNominationTaskActionsReducers,
    updateCargoPlanEmailAuditReducer,
    ...contactReducers,
    liftingHistoryToggleAllReducer,
    liftingHistoryToggleKeyReducer
];
