import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import {
    cancelEditSavedWorksheetName,
    deleteWorksheet,
    editSavedWorksheetName,
    renameSavedWorksheet,
    selectAreWorksheetsLoading,
    selectDefaultWorksheet,
    selectSelectedWorksheetId,
    selectWorksheet,
    selectWorksheetsLoadFailed,
    selectWorksheetsOfSelectedType,
    selectWorksheetTypeToShowTitle,
    WorksheetFeatureState,
    WorksheetId,
    WorksheetListItem
} from "../../state";
import { LeftBarStateService } from "../left-bar-state.service";

@Component({
    selector: "ops-worksheets-shell",
    templateUrl: "./worksheets-shell.component.html",
    styleUrls: ["./styles/worksheets.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorksheetsShellComponent {
    readonly worksheetsOfSelectedType$: Observable<ReadonlyArray<WorksheetListItem>>;
    readonly selectedWorksheetId$: Observable<WorksheetId>;
    readonly selectedWorksheetTypeTitle$: Observable<string>;
    readonly areWorksheetsLoading$: Observable<boolean>;
    readonly worksheetsLoadFailed$: Observable<boolean>;

    constructor(private store: Store<WorksheetFeatureState>, private leftBarStateService: LeftBarStateService) {
        this.worksheetsOfSelectedType$ = this.store.select(selectWorksheetsOfSelectedType);
        this.selectedWorksheetId$ = this.store.select(selectSelectedWorksheetId);
        this.selectedWorksheetTypeTitle$ = this.store.select(selectWorksheetTypeToShowTitle);
        this.areWorksheetsLoading$ = this.store.select(selectAreWorksheetsLoading);
        this.worksheetsLoadFailed$ = this.store.select(selectWorksheetsLoadFailed);
    }

    trackBy(_index: number, item: WorksheetListItem) {
        return item.id;
    }

    performDefaultLayoutSearch() {
        this.store.dispatch(selectDefaultWorksheet());
    }

    selectWorksheet(selectedWorksheetId: WorksheetId) {
        this.store.dispatch(selectWorksheet({ selectedWorksheetId }));
    }

    editName(worksheetId: WorksheetId) {
        this.store.dispatch(editSavedWorksheetName({ worksheetId }));
    }

    cancelEditName(worksheetId: WorksheetId) {
        this.store.dispatch(cancelEditSavedWorksheetName({ worksheetId }));
    }

    confirmRename(worksheetId: WorksheetId) {
        this.store.dispatch(renameSavedWorksheet({ worksheetId }));
    }

    delete(worksheetId: WorksheetId) {
        this.store.dispatch(deleteWorksheet({ worksheetId }));
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }
}
