import { Injectable } from "@angular/core";
import * as moment from "moment";
import { CommandHandler } from "src/app/fixture/mediator/command-handlers/command-handler";
import { Fixture } from "src/app/fixture/shared/models";
import { OffHire } from "src/app/fixture/shared/models/dtos/offhire.dto";
import { Period } from "src/app/fixture/shared/models/dtos/period.dto";
import { HireRateUnit } from "src/app/fixture/shared/models/enums/hire-rate-unit";
import { OffHireGridRow } from "src/app/fixture/shared/models/form-models/offhire-gridrow.model";
import { DateUtilities } from "src/app/shared/date-utils/date-utilities";
import { UpdateOffHireCommand } from "./update-offhire.command";

@Injectable({
    providedIn: "root"
})
export class UpdateOffHireCommandHandler implements CommandHandler {
    private secondsInADay = 86400;

    handle = (fixture: Fixture, command: UpdateOffHireCommand) => {
        const updatedOffhire = <OffHireGridRow>command.payload;

        let matchingOffHire: OffHire = null;
        let matchingPeriod: Period = null;

        if (updatedOffhire.hireId === updatedOffhire.originalHireId) {
            matchingPeriod = fixture.periods.find((p) => p.hireId === updatedOffhire.hireId);
            matchingOffHire = matchingPeriod.offHires[updatedOffhire.offHireIndex];

            const existingOffHireFrom = matchingOffHire.offHireDateRange ? DateUtilities.parseISODate(matchingOffHire.offHireDateRange.from) : null;
            const existingOffHireTo = matchingOffHire.offHireDateRange ? DateUtilities.parseISODate(matchingOffHire.offHireDateRange.to) : null;

            let hireRatePerDay = updatedOffhire.hireRate;
            if (matchingPeriod.hireRateUnit !== null && matchingPeriod.hireRateUnit.id === HireRateUnit.LumpSum) {
                hireRatePerDay = updatedOffhire.hireRate / matchingPeriod.durationInDays;
            }

            matchingOffHire.comments = updatedOffhire.comments;
            matchingOffHire.reasonType = updatedOffhire.reasonType;

            if (DateUtilities.notEquals(existingOffHireFrom, updatedOffhire.offHireFrom)) {
                this.handleOffHireFromChange(matchingOffHire, existingOffHireTo, updatedOffhire.offHireFrom, hireRatePerDay);
            } else if (DateUtilities.notEquals(existingOffHireTo, updatedOffhire.offHireTo)) {
                this.handleOffHireToChange(matchingOffHire, existingOffHireFrom, updatedOffhire.offHireTo, hireRatePerDay);
            } else if (matchingOffHire.durationInDays !== updatedOffhire.durationInDays) {
                this.handleDurationChange(matchingOffHire, existingOffHireFrom, existingOffHireTo, updatedOffhire.durationInDays, hireRatePerDay);
            } else if (matchingOffHire.hireRate !== updatedOffhire.hireRate) {
                matchingOffHire.hireRate = updatedOffhire.hireRate;
                this.handleDurationChange(matchingOffHire, existingOffHireFrom, existingOffHireTo, updatedOffhire.durationInDays, hireRatePerDay);
            }
        } else {
            // Get OffHire from original period
            const originalPeriod = fixture.periods.find((p) => p.hireId === updatedOffhire.originalHireId);
            matchingOffHire = originalPeriod.offHires[updatedOffhire.offHireIndex];

            // Remove from original period
            originalPeriod.offHires.splice(updatedOffhire.offHireIndex, 1);

            // Add to selected period
            matchingPeriod = fixture.periods.find((p) => p.hireId === updatedOffhire.hireId);
            matchingOffHire.hireRate = matchingPeriod.hireRate;
            matchingOffHire.durationInDays = 0;
            matchingOffHire.offHireDateRange = null;
            matchingOffHire.value = 0;
            matchingPeriod.offHires.push(matchingOffHire);
        }
    }

    private handleOffHireFromChange(offHire: OffHire, existingOffHireTo: Date, newOffHireFrom: Date, hireRatePerDay: number) {
        if (offHire.offHireDateRange === null) {
            offHire.offHireDateRange = {
                from: "",
                to: ""
            };
        }

        offHire.offHireDateRange.from = DateUtilities.dateToISOString(newOffHireFrom);

        if (newOffHireFrom && existingOffHireTo) {
            offHire.durationInDays = DateUtilities.calculateDurationInDays(existingOffHireTo, newOffHireFrom);
            hireRatePerDay = hireRatePerDay || offHire.hireRate;
            offHire.value = offHire.durationInDays ? offHire.durationInDays * hireRatePerDay : 0;
        }
    }

    private handleOffHireToChange(offHire: OffHire, existingOffHireFrom: Date, newOffHireTo: Date, hireRatePerDay: number) {
        if (offHire.offHireDateRange === null) {
            offHire.offHireDateRange = {
                from: "",
                to: ""
            };
        }

        offHire.offHireDateRange.to = DateUtilities.dateToISOString(newOffHireTo);

        if (newOffHireTo && existingOffHireFrom) {
            offHire.durationInDays = DateUtilities.calculateDurationInDays(newOffHireTo, existingOffHireFrom);
            hireRatePerDay = hireRatePerDay || offHire.hireRate;
            offHire.value = offHire.durationInDays ? offHire.durationInDays * hireRatePerDay : 0;
        }
    }

    private handleDurationChange(offHire: OffHire, existingOffHireFrom: Date, existingOffHireTo: Date, updatedDuration: number, hireRatePerDay: number) {
        if (offHire.offHireDateRange === null) {
            offHire.offHireDateRange = {
                from: "",
                to: ""
            };
        }

        offHire.durationInDays = updatedDuration;

        if (offHire.durationInDays !== null && offHire.durationInDays !== undefined) {
            const durationInSeconds = offHire.durationInDays * this.secondsInADay;

            if (existingOffHireFrom) {
                offHire.offHireDateRange.to = DateUtilities.dateToISOString(
                    moment(existingOffHireFrom)
                        .add(durationInSeconds, "seconds")
                        .toDate()
                );
            } else if (existingOffHireTo) {
                offHire.offHireDateRange.from = DateUtilities.dateToISOString(
                    moment(existingOffHireTo)
                        .subtract(durationInSeconds, "seconds")
                        .toDate()
                );
            }

            hireRatePerDay = hireRatePerDay || offHire.hireRate;
            offHire.value = offHire.durationInDays ? offHire.durationInDays * hireRatePerDay : 0;
        }
    }
}
