import { reduceObjectMap } from "@ops/state";

import { getEarliestPlannedLifting, sortAndMapLiftingIndexItemsToGridRows } from "../lifting/liftings-index-helper";
import { CoaId, CoasState, CoaState, getCurrentCoaId, LiftingIndexItem } from "../model";

export const coaStateReducer = (state: CoasState, coaId: CoaId, partialOrReducer: Partial<CoaState> | ((objectState: CoaState) => CoaState)) =>
    reduceObjectMap(state, (s) => s.coas, coaId, partialOrReducer);

export const currentCoaStateReducer = (state: CoasState, partialOrReducer: Partial<CoaState> | ((objectState: CoaState) => CoaState)) =>
    coaStateReducer(state, getCurrentCoaId(state), partialOrReducer);

export const coaLiftingsStateReducer = (state: CoasState, coaId: CoaId, getLiftings: (coaState: CoaState) => LiftingIndexItem[]) =>
    coaStateReducer(state, coaId, (coaState) => ({
        ...coaState,
        indexedLiftings: {
            ...coaState.indexedLiftings,
            documents: sortAndMapLiftingIndexItemsToGridRows(getLiftings(coaState), coaState.coa.driver)
        },
        earliestPlannedLifting: getEarliestPlannedLifting(
            getLiftings(coaState).filter((l) => l.liftingStatus === "Planning"),
            coaState.coa.driver
        ),
        fetchedLiftings: getLiftings(coaState)
    }));
