<div class="card" *ngIf="(commissionRows$ | async) as commissionRows; else noCommissionRows">
    <div class="card-header row no-gutters align-items-center">
        <span class="title">Commissions</span>
    </div>
    <div class="card-body">
        <table class="table m-0">
            <thead>
                <tr>
                    <th scope="col" class="py-0 align-middle medium-size">Invoice date</th>
                    <th scope="col" class="py-0 align-middle medium-size">Invoice Number</th>
                    <th scope="col" class="py-0 align-middle medium-size">Details</th>
                    <th scope="col" class="py-0 align-middle medium-size text-right">Gross Value</th>
                    <th scope="col" class="py-0 align-middle medium-size text-right">Commission %</th>
                    <th scope="col" class="py-0 align-middle medium-size text-right">Invoice Value</th>
                    <th scope="col" class="py-0 align-middle medium-size">Owner Inv. No.</th>
                    <th scope="col" class="py-0 align-middle medium-size">Allocation Status</th>
                    <th scope="col" class="py-0 align-middle medium-size text-right">Allocated Value</th>
                    <th scope="col" class="py-0 align-middle small-size text-center">Credited</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of commissionRows" class="form-group disabled">
                    <td data-test-commission-invoice-date>{{ row.invoiceDate | date: "dd MMM yy":"utc" }}</td>
                    <td data-test-invoice-credit-no>{{ row.invoiceCreditNoteNumber }}</td>
                    <td data-test-details>{{ row.details }}</td>
                    <td class="text-right" data-test-invoice-gross-value>{{ row.grossValue | number: "1.2" }}</td>
                    <td class="text-right" data-test-invoice-commission-percent>{{ row.commissionPercent | number: "1.2" }}</td>
                    <td class="text-right" data-test-invoice-value>{{ row.commissionValue | number: "1.2" }}</td>
                    <td data-test-owner-invoice-no>{{ row.ownersInvoiceNumber }}</td>
                    <td data-test-allocation-status>{{ row.allocatedStatus }}</td>
                    <td class="text-right" data-test-allocated-value>{{ row.allocatedValue | number: "1.2" }}</td>
                    <td class="text-center" data-test-credited>
                        <span *ngIf="row.credited" class="has-icon icon--done"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ng-template #noCommissionRows>
    <ops-no-data-panel
        heading="Commission"
        title="No commission invoices have been added"
        description="Commission invoices are raised in Gain Invoicing"
        [useNewButton]="false"
    >
    </ops-no-data-panel>
</ng-template>
