<div class="card">
    <div class="card-header pl-0 row divider no-gutters">
        <div class="d-flex w-100">
            <button
                *opsRole="'Laytime'"
                type="button"
                (click)="toggleLaytimeTermsSection()"
                class="has-icon"
                [class.icon--arrow-drop-right]="hideLaytimeTermsSection"
                [class.icon--arrow-drop-down]="!hideLaytimeTermsSection"
                data-test-toggle-laytime-terms
            ></button>
            Laytime Terms
        </div>
        <div class="display-format">
            <label>Display In:</label>
            <br />
            <ops-select-button
                [options]="dateFormatTypes"
                [selectedOption]="displayUnitType"
                [disabled]="laytimeTabForm.disabled"
                (change)="toggleUnit($event)"
            ></ops-select-button>
        </div>
    </div>
    <div class="card-body" [hidden]="hideLaytimeTermsSection">
        <div class="row laytime-terms">
            <div class="col">
                <ops-laytime-container
                    [parentForm]="laytimeTabForm"
                    [fixture]="fixture"
                    controlName="laytimeForm"
                    [displayUnitType]="displayUnitType"
                    (laytimeTermsUpdated)="updateLaytimeTab($event)"
                ></ops-laytime-container>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <ops-cargo-allowed-rates
                    [parentForm]="laytimeTabForm"
                    [formName]="'cargoForm'"
                    [model]="cargoAllowedRates"
                    [cargoes]="cargoes"
                    [fixture]="fixture"
                    [destinations]="currentDestinations"
                    (cargoAllowedRatesUpdated)="updateCargoLaytimeTab($event)"
                ></ops-cargo-allowed-rates>
            </div>
        </div>
    </div>
</div>

<div *ngIf="directToLaytimeCalculatorV2">
    <div class="card" *opsRole="'Laytime'">
        <div class="divider"></div>
        <div class="card-body">
            <div>Laytime Calculator has moved.</div>
            <button
                type="button"
                class="btn btn-primary mt-2 ml-0"
                (click)="navigateToV2LaytimeCalculations()">
                VIEW LAYTIME CALCULATIONS
            </button>
        </div>
    </div>
</div>

<div *ngIf="!directToLaytimeCalculatorV2">
    <div class="card" *opsRole="'Laytime'" data-test-laytime-summary>
        <div class="card-header pl-0 row divider laytime-summary">
            <div class="d-flex w-100">
                <div class="d-flex p-0">
                    <button
                        type="button"
                        (click)="toggleLaytimeSummarySection()"
                        class="has-icon"
                        [class.icon--arrow-drop-right]="hideLaytimeSummarySection"
                        [class.icon--arrow-drop-down]="!hideLaytimeSummarySection"
                        data-test-toggle-laytime-summary
                    ></button>
                    Laytime Summary
                </div>
                <div class="laytime-summary-spacer"></div>
                <div class="card-header-time col-3" *ngIf="ecvTotalTime.isFixed || ecvTotalTime.hasReversible">
                    <div class="row no-gutters d-table">
                        <div class="d-table-row">
                            <span class="card-header-time-title d-table-cell">Allowed:</span>
                            <span class="card-header-time-value d-table-cell">{{ ecvTotalTime?.allowedTime | durationFormat: displayUnitType }}</span>
                            <div class="d-table-cell laytime-type" *ngIf="!ecvTotalTime.isFixed && ecvTotalTime.hasReversible">
                                <span class="has-icon icon--info-outline" ngbTooltip="Times displayed here are for Reversible laytime." container="body"></span>
                            </div>
                        </div>
                        <div class="d-table-row">
                            <span class="card-header-time-title summary-label d-table-cell">Used:</span>
                            <span class="card-header-time-value d-table-cell">{{ ecvTotalTime?.timeUsedForFixture | durationFormat: displayUnitType }}</span>
                        </div>
                        <div class="d-table-row">
                            <span class="card-header-time-title summary-label d-table-cell">Remaining:</span>
                            <span class="card-header-time-value d-table-cell">{{ ecvTotalTime?.remainingTime | durationFormat: displayUnitType }}</span>
                        </div>
                    </div>
                </div>

                <div class="col d-flex justify-content-end p-0">
                    <button type="button" (click)="createClaim()" [disabled]="isCreateClaimDisabled$ | async" class="btn btn-primary m-0"
                        data-test-save>
                        CREATE CLAIM
                    </button>
                </div>
                <div class="col-1">
                    <button
                        *ngIf="{ value: loading$ | async } as loading"
                        type="button"
                        class="btn btn-action btn-export m-0"
                        (click)="export()"
                        [ngbTooltip]="!laytimeTabForm.disabled ? 'Save the fixture to export the latest Claim' : ''"
                        container="body"
                        data-test-export
                        [disabled]="loading.value"
                    >
                        <span class="has-icon icon--export"></span>
                        <span *ngIf="!loading.value">EXPORT</span>
                        <ops-load-animation class="light-background" *ngIf="loading.value" size="small"></ops-load-animation>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body" [hidden]="hideLaytimeSummarySection">
            <div class="row laytime-terms">
                <div class="col">
                    <ops-laytime-summary
                        (claimValueUpdated)="claimValueUpdated($event)"
                        [destinations]="currentDestinations"
                        [cargoes]="cargoes"
                        [fixture]="fixture"
                        [parentForm]="laytimeTabForm"
                        [displayUnitType]="displayUnitType"
                    >
                    </ops-laytime-summary>
                </div>
            </div>
        </div>
    </div>

    <div class="card" *opsRole="'Laytime'" data-test-laytime-calculation>
        <div class="card-header pl-0 row divider">
            <div class="card-header-wrap d-flex w-100">
                <div class="title-wrapper">
                    <button
                        type="button"
                        (click)="toggleLaytimeCalculationSection()"
                        class="has-icon"
                        [class.icon--arrow-drop-right]="hideLaytimeCalculationSection"
                        [class.icon--arrow-drop-down]="!hideLaytimeCalculationSection"
                        data-test-toggle-laytime-calculation
                    ></button>
                    Laytime Calculation
                </div>
                <div class="button-wrapper">
                    <button
                        type="button"
                        class="btn btn-primary btn-add m-0"
                        [disabled]="parentForm.disabled"
                        (click)="syncLaytimeEventData()"
                        [class.all-imported]="allImported"
                        [ngbTooltip]="importButtonTooltip"
                        data-test-import-all-port-times
                    >
                        IMPORT TIMES FROM ALL PORTS
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body" [hidden]="hideLaytimeCalculationSection">
            <div *ngIf="currentDestinations && currentDestinations.length > 0; else noDestinations">
                <div *ngFor="let control of destinationsForm.controls; let i = index" class="laytime-calculation-row">
                    <ops-laytime-calculation
                        [parentForm]="control"
                        [destination]="currentDestinations[i]"
                        [destinations]="currentDestinations"
                        [cargoes]="cargoes"
                        [fixture]="fixture"
                        [cargoAllowedRates]="cargoAllowedRates"
                        [displayUnitType]="displayUnitType"
                        (laytimeCalculationUpdated)="updateCargoLaytimeTab($event)"
                    >
                    </ops-laytime-calculation>
                </div>
            </div>
            <ng-template #noDestinations>
                <ops-no-data-panel #panel="noDataPanel" heading="Laytime Calculation" title="No locations have been added." description="Add locations on the Cargo tab.">
                </ops-no-data-panel>
            </ng-template>
        </div>
    </div>
</div>
