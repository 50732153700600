import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, exhaustMap, map, withLatestFrom } from "rxjs/operators";

import { update } from "@ops/shared";

import { FixtureId } from "../../../../fixture/state";
import { LaytimeCalculationHttpService } from "../../../services";
import { idEq, LtcFeatureState, LtcId, LtcState } from "../../model";
import { selectCurrentFixtureId } from "../fixture-calculation";
import { currentFixtureCalculationsStateReducer, fixtureCalculationsStateReducer } from "../reducer";

const REMOVE_LAYTIME_CALCULATION_ACTION_NAME = "[Laytime Calculation List] Remove Laytime Calculation";
export const removeLaytimeCalculationAction = createAction(REMOVE_LAYTIME_CALCULATION_ACTION_NAME, props<{ ltcId: LtcId }>());
export const removeLaytimeCalculationSuccessAction = createAction(`${REMOVE_LAYTIME_CALCULATION_ACTION_NAME} Success`, props<{ ltcId: LtcId; fixtureId: FixtureId }>());
export const removeLaytimeCalculationFailAction = createAction(`${REMOVE_LAYTIME_CALCULATION_ACTION_NAME} Fail`, props<{ ltcId: LtcId; fixtureId: FixtureId; error: Error }>());

/* REDUCERS */
export const removeLaytimeCalculationReducer: On<LtcState> = on(removeLaytimeCalculationAction, (state, { ltcId }) => {
    const updateFns: Evolver = {
        calculations: update(idEq(ltcId), { persistenceStatus: "persisting" })
    };

    return currentFixtureCalculationsStateReducer(state, R.evolve(updateFns));
});

export const removeLaytimeCalculationSuccessReducer: On<LtcState> = on(removeLaytimeCalculationSuccessAction, (state, { ltcId, fixtureId }) =>
    fixtureCalculationsStateReducer(state, fixtureId, (fixtureState) => ({ ...fixtureState, calculations: fixtureState.calculations.filter((c) => c.id !== ltcId) }))
);

export const removeLaytimeCalculationFailReducer: On<LtcState> = on(removeLaytimeCalculationFailAction, (state, { ltcId, fixtureId }) => {
    const updateFns: Evolver = {
        calculations: update(idEq(ltcId), { persistenceStatus: "failed" })
    };

    return fixtureCalculationsStateReducer(state, fixtureId, R.evolve(updateFns));
});

/* EFFECTS */
export const removeLaytimeCalculationEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeLaytimeCalculationAction),
            withLatestFrom(store.select(selectCurrentFixtureId)),
            exhaustMap(([{ ltcId }, fixtureId]) =>
                laytimeCalculationHttpService.removeLaytimeCalculation(ltcId).pipe(
                    map(() => removeLaytimeCalculationSuccessAction({ ltcId, fixtureId })),
                    catchError((error) => of(removeLaytimeCalculationFailAction({ ltcId, fixtureId, error })))
                )
            )
        )
    );
