import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

@Component({
    selector: "ops-worksheets-version-switcher",
    templateUrl: "./worksheets-version-switcher.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorksheetsVersionSwitcherComponent implements OnDestroy {
    private destroy$ = new Subject();
    private pageUrl: string;

    constructor(@Inject("Window") private window: Window, private router: Router, private changeDetector: ChangeDetectorRef) {
        this.pageUrl = this.window.location.href;
        this.router.events
            .pipe(
                takeUntil(this.destroy$),
                filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd)
            )
            .subscribe((data) => {
                this.pageUrl = data.url;
                this.changeDetector.markForCheck();
            });
    }

    get useNewComponent(): boolean {
        return this.pageUrl.includes("/coas");
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
