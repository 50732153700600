/* ACTIONS */
import { createAction, on, On } from "@ngrx/store";
import { FixturesState } from "../model";
import { currentVoyageStateExpansionReducer } from "../voyage/reducer";

export const expandVoyageCostBreakdownAction = createAction("[Fixture Form] Expand Voyage Cost Breakdown");
export const collapseVoyageCostBreakdownAction = createAction("[Fixture Form] Collapse Voyage Cost Breakdown");

const VOYAGE_COST_BREAKDOWN_EXPANDED_KEY = "costs";

/* REDUCERS */
export const expandVoyageCostBreakdownReducer: On<FixturesState> = on(expandVoyageCostBreakdownAction, (state) =>
    currentVoyageStateExpansionReducer(state, VOYAGE_COST_BREAKDOWN_EXPANDED_KEY, true)
);
export const collapseVoyageCostBreakdownReducer: On<FixturesState> = on(collapseVoyageCostBreakdownAction, (state) =>
    currentVoyageStateExpansionReducer(state, VOYAGE_COST_BREAKDOWN_EXPANDED_KEY, false)
);
