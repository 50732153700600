import { FormGroupState, markAsTouched, StateUpdateFns, updateGroup, validate } from "ngrx-forms";

import { validateRequired, validateRequiredIfDependants } from "@ops/state";

import { CoasState, LiftingState, VesselNominationForm } from "../../../model";

export const validateVesselNominationForm = (form: FormGroupState<VesselNominationForm>, liftingState: LiftingState, coasState: CoasState) => {
    const updateFns: StateUpdateFns<VesselNominationForm>[] = [];
    if (form.controls.locationPriorToVoyage.isTouched) {
        updateFns.push({ etd: markAsTouched });
    }

    return updateGroup<VesselNominationForm>(
        {
            vessel: validateRequired(),
            etd: validateRequiredIfDependants([form.controls.locationPriorToVoyage])
        },
        ...updateFns
    )(form);
};
