<div *ngIf="optionsFormArray.length" class="card embedded-card" [formGroup]="parentForm">
    <div class="card-header row no-gutters align-items-center">
        Options
    </div>
    <div class="card-body">
        <table class="table m-0">
            <thead>
                <tr>
                    <th scope="col" class="py-0 align-middle option-column text-right">Option</th>
                    <th scope="col" class="py-0 align-middle large-size"><span class="required">From Date</span></th>
                    <th scope="col" class="py-0 align-middle large-size"><span class="required">To Date</span></th>
                    <th scope="col" class="py-0 align-middle medium-size text-right"><span class="required">Duration (Days)</span></th>
                    <th scope="col" class="py-0 align-middle medium-size text-right"><span class="required">Hire Rate</span></th>
                    <th scope="col" class="py-0 align-middle medium-size"><span class="required">Rate Unit</span></th>
                    <th scope="col" class="py-0 align-middle medium-size"><span class="required">Declaration Due</span></th>
                    <th scope="col" class="py-0 align-middle medium-size"><span class="required">Status</span></th>
                    <th scope="col" class="py-0 medium-small-size" *ngIf="canAddOption"></th>
                </tr>
            </thead>
            <tbody formArrayName="optionsFormArray">
                <tr *ngFor="let optionsForm of optionsFormArray.controls; let i = index" [formGroupName]="i" class="form-group">
                    <td class="p-0">
                        <input type="text" class="form-control text-right" formControlName="id" data-test-option-id readonly />
                    </td>
                    <td class="p-0">
                        <ops-date-input
                            #periodFromPicker
                            formControlName="periodFrom"
                            timeZone="utc"
                            [enableTime]="true"
                            [defaultFocusDate]="lastPeriodTo"
                            data-test-from-date
                        ></ops-date-input>
                    </td>
                    <td class="p-0">
                        <ops-date-input
                            formControlName="periodTo"
                            timeZone="utc"
                            [enableTime]="true"
                            [defaultFocusDate]="lastPeriodTo"
                            data-test-to-date
                        ></ops-date-input>
                    </td>
                    <td class="p-0 text-right">
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="durationInDays"
                            [opsNumber]="{ precision: 6, showTrailingZeros: false, minValue: 0 }"
                            data-test-duration-days
                        />
                    </td>
                    <td class="p-0 text-right">
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="hireRate"
                            [opsNumber]="{ precision: 2, minValue: 0 }"
                            data-test-hire-rate
                        />
                    </td>
                    <td class="p-0">
                        <ng-select
                            class="ops-select form-control"
                            [items]="hireRateUnits"
                            formControlName="hireRateUnit"
                            [clearable]="true"
                            bindLabel="name"
                            [compareWith]="referenceDataService.compare"
                            triggers="manual"
                            data-test-hire-rate-unit
                            [selectOnTab]="true"
                        >
                        </ng-select>
                    </td>
                    <td class="p-0">
                        <ops-date-input
                            formControlName="declarationDueDate"
                            timeZone="utc"
                            [defaultFocusDate]="lastPeriodTo"
                            data-test-declaration-due
                        ></ops-date-input>
                    </td>
                    <td class="p-0">
                        <ng-select
                            class="ops-select form-control"
                            [items]="exerciseOptions"
                            placeholder="Select"
                            formControlName="exerciseOption"
                            [clearable]="true"
                            bindLabel="name"
                            [compareWith]="referenceDataService.compare"
                            triggers="manual"
                            data-test-status
                            [selectOnTab]="true"
                        >
                        </ng-select>
                    </td>

                    <td class="p-0 float-right" *ngIf="canAddOption">
                        <button
                            *ngIf="optionsFormArray.length === i + 1"
                            [disabled]="optionsFormArray.disabled"
                            (click)="addOption(optionsForm.get('id').value)"
                            type="button"
                            class="btn-add has-icon icon--add-circle-outline"
                            data-test-add-option
                        ></button>
                        <button
                            [disabled]="optionsFormArray.disabled"
                            (click)="cloneOption(optionsForm.get('id').value)"
                            type="button"
                            class="btn-clone has-icon icon--content-copy"
                            data-test-clone-option
                        ></button>
                        <button
                            [disabled]="optionsFormArray.disabled"
                            (click)="removeOption(optionsForm.get('id').value)"
                            type="button"
                            class="btn-delete has-icon icon--delete"
                            data-test-remove-option
                        ></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ops-no-data-panel
    *ngIf="!optionsFormArray.length"
    heading="Options"
    title="No Options have been added"
    [description]="noDataPanelDescription"
    newButtonText="OPTION"
    [useNewButton]="canAddOption"
    (addNew)="addOption()"
    [disabled]="optionsFormArray.disabled"
>
</ops-no-data-panel>
