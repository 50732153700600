import { Injectable } from "@angular/core";
import { CommandHandler } from "src/app/fixture/mediator/command-handlers/command-handler";
import { Fixture } from "src/app/fixture/shared/models";
import { Period } from "src/app/fixture/shared/models/dtos/period.dto";
import { AddOffHireCommand } from "src/app/fixture/time-charter/hire-tab/offhire/commands/add-offhire.command";
import { OffHire } from "../../../../shared/models/dtos/offhire.dto";

@Injectable({
    providedIn: "root"
})
export class AddOffHireCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: AddOffHireCommand) {
        let maxSortOrder = 0;

        fixture.periods.forEach((period: Period) => {
            period.offHires.forEach((offHire: OffHire) => {
                if (maxSortOrder < offHire.sortOrder) {
                    maxSortOrder = offHire.sortOrder;
                }
            });
        });

        const lastPeriod = fixture.periods[fixture.periods.length - 1];

        lastPeriod.offHires.push({
            offHireDateRange: null,
            durationInDays: 0,
            hireRate: lastPeriod.hireRate,
            value: 0,
            reasonType: null,
            comments: null,
            sortOrder: ++maxSortOrder
        });
    }
}
