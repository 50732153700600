import { isNumber } from "./utils";

export function parseNumber(numberAsString: string, precision?: number): number | null {
    if (numberAsString === undefined || numberAsString === null || numberAsString === "") {
        return null;
    }

    const parsedNumber = parseFloat(numberAsString.replace(/,/g, ""));
    return precision ? normaliseNumber(parsedNumber, precision) : parsedNumber;
}

/**
 * Converts a number to a string.
 *
 * @param value
 * The number to format
 * @param showTrailingZeros
 * True to show trailing fraction zeros.
 * @param minimumFractionDigits
 * The minimum number of fraction digits to use. Possible values are from 0 to 20.
 * @param precision
 * The maximum number of fraction digits to use, or the exact number to use when showTrailingZeros=true.
 * Possible values are from 0 to 20.
 * @param useGrouping
 * True to include grouping of thousands.
 * @param style
 * In many locales, accounting format means to wrap the number with parentheses instead of appending a minus sign.
 * You can enable this formatting by setting the currencySign option to "accounting". The default value is "standard".
 */
export function formatNumber(
    value: number,
    showTrailingZeros: boolean,
    minimumFractionDigits: number,
    precision: number,
    useGrouping?: boolean,
    style?: "standard" | "accounting"
): string {
    let normalisedNumber = normaliseNumber(value, precision);

    if (normalisedNumber === undefined) {
        return "";
    }

    if (style === "accounting") {
        normalisedNumber = Math.abs(normalisedNumber);
    }

    const formattedNumber = normalisedNumber.toLocaleString("en", {
        minimumFractionDigits: showTrailingZeros ? precision : minimumFractionDigits,
        maximumFractionDigits: precision,
        useGrouping: useGrouping
    });

    if (style === "accounting" && value < 0) {
        return `(${formattedNumber})`;
    }

    return formattedNumber;
}

export function normaliseNumber(num: number, precision: number): number {
    if (!isNumber(num) || num + "" === "") {
        return undefined;
    }

    const multiplier = Math.pow(10, precision);
    const rounded = Math.round(num * multiplier) / multiplier;

    return rounded;
}
