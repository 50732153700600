import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";

import { routeParamChange } from "@ops/state";

import { routerSetCurrentFixtureAction } from "./fixture/routing";
import { showInvalidVoyageOnFixtureSaveEffect$ } from "./fixture/save-fixture.effects";
import { FixtureFeatureState, FixtureId, VoyageId } from "./model";
import { showNewVoyageEffect$ } from "./voyage/form/add-voyage";
import { selectVoyageEffect$ } from "./voyage/form/select-voyage";
import { routerSetCurrentVoyageAction } from "./voyage/routing";

export const routeFixtureIdChange = () => routeParamChange("fixtureId");
export const routeVoyageIdChange = () => routeParamChange("voyageId");

@Injectable()
export class FixtureRoutingEffects {
    setCurrentFixtureId$ = createEffect(() =>
        this.actions$.pipe(
            routeFixtureIdChange(),
            map((fixtureId: FixtureId) => routerSetCurrentFixtureAction({ fixtureId }))
        )
    );

    setCurrentVoyageId$ = createEffect(() =>
        this.actions$.pipe(
            routeVoyageIdChange(),
            map((voyageId: VoyageId) => routerSetCurrentVoyageAction({ voyageId }))
        )
    );

    /**
     * Shows the first invalid voyage if save attempted with invalid voyage(s).
     */
    showInvalidVoyageOnFixtureSave$ = showInvalidVoyageOnFixtureSaveEffect$(this.actions$, this.store, this.router);

    selectVoyage$ = selectVoyageEffect$(this.actions$, this.store, this.router);

    /**
     * Shows a newly created voyage on success.
     */
    showNewVoyage$ = showNewVoyageEffect$(this.actions$, this.router);

    constructor(private actions$: Actions, private store: Store<FixtureFeatureState>, private router: Router) {}
}
