import { createSelector } from "@ngrx/store";

import { FixtureId } from "../../../../fixture/state";
import { selectLtcFeature } from "../../ltc.selectors";
import { FixtureLaytimeCalculationsState, getCurrentFixtureId, LtcFeatureState, LtcState } from "../../model";
import { getFixtureLaytimeCalculation } from "./fixture-calculation";

export const selectCurrentFixtureId = createSelector(selectLtcFeature, getCurrentFixtureId);

export const selectCurrentFixtureLaytimeCalculationsState = createSelector<LtcFeatureState, LtcState, FixtureId, FixtureLaytimeCalculationsState>(
    selectLtcFeature,
    selectCurrentFixtureId,
    (state, fixtureId) => fixtureId && state.fixtureCalculations.byId[fixtureId]
);

export const selectCurrentFixtureLaytimeCalculations = createSelector(selectCurrentFixtureLaytimeCalculationsState, (state) =>
    state?.calculations?.map(getFixtureLaytimeCalculation)
);
