import { Injectable } from "@angular/core";

import { Email } from "../../../shared/email";

/**
 * @deprecated Prefer use of `EmailGenerationService` and individual `EmailGenerator`s.
 */
@Injectable({
    providedIn: "root"
})
export class EmailFactoryService {
    requestNewVessel(mailTo: string): Email {
        const subject = "New Vessel Request";
        const body = "Please add the following vessel to the system: \n" + "<Please provide as much detail as possible if available>";
        const bcc = "";

        return new Email(subject, body, bcc, mailTo);
    }
}
