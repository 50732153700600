import { NgModule } from "@angular/core";

import { ClaimsMissingClaimValueRule } from "./claims/claims-missing-claim-value-rule";
import { ClaimsMissingReceivedFromOwnerDateRule } from "./claims/claims-missing-received-from-owner-date-rule";
import { DataBLDateDischargeDateInTheFutureRule } from "./data/data-bl-date-discharge-date-in-the-future-rule";
import { DataDuplicatedClaimRule } from "./data/data-duplicated-claim-rule";
import { DataMissingNorTenderedDateRule } from "./data/data-missing-nor-tendered-date-rule";
import { DataNorTenderedDateIsInTheFutureRule } from "./data/data-tendered-date-is-in-the-future-rule";
import { EtaMissingDischargeEventRule } from "./eta/eta-missing-discharge-event-rule";
import { EtaMissingLoadEventRule } from "./eta/eta-missing-load-event-rule";
import { EtaOpenFixtureRule } from "./eta/eta-open-fixture-rule";
import { EtaTimeBetweenPortsRule } from "./eta/eta-time-between-ports-rule";
import { ExternalVisibilityCargoActivityTypeRule } from "./external-visibility/external-visibility-cargo-activity-type-rule";
import { ExternalVisibilityCargoQuantityUnitRule } from "./external-visibility/external-visibility-cargo-quantity-unit-rule";
import { ExternalVisibilityDeliveryRule } from "./external-visibility/external-visibility-delivery-rule";
import { ExternalVisibilityDemurrageStatusRule } from "./external-visibility/external-visibility-demurrage-status-rule";
import { ExternalVisibilityDestinationLocationRule } from "./external-visibility/external-visibility-destination-location-rule";
import { ExternalVisibilityHirePeriodRule } from "./external-visibility/external-visibility-hire-period-rule";
import { ExternalVisibilityLoadDischargeActivityRule } from "./external-visibility/external-visibility-load-discharge-activity-rule";
import { ExternalVisibilityMissingCargoRule } from "./external-visibility/external-visibility-missing-cargo-rule";
import { ExternalVisibilityOffHiresRule } from "./external-visibility/external-visibility-off-hires-rule";
import { ExternalVisibilitySpecialisedLoadDischargeLocationRule } from "./external-visibility/external-visibility-st-load-discharge-location-rule";
import { FIXTURE_WARNING_RULE } from "./fixture-warning-rule";
import { FreightBlDischargeQuantityRule } from "./freight/freight-bl-discharge-quantity-rule";
import { FreightLargeActualFreightRateRule } from "./freight/freight-large-actual-freight-rate-rule";
import { FreightLargeFreightSpendRule } from "./freight/freight-large-freight-spend-rule";
// import { FreightMissingActualFreightRateRule } from "./freight/freight-missing-actual-freight-rate-rule";
import { FreightMissingBlRule } from "./freight/freight-missing-bl-rule";
import { FreightMissingFreightSpendRule } from "./freight/freight-missing-freight-spend-rule";
import { FreightZeroFreightSpendRule } from "./freight/freight-zero-freight-spend-rule";

@NgModule({
    providers: [
        { provide: FIXTURE_WARNING_RULE, useClass: ExternalVisibilityMissingCargoRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: ExternalVisibilityCargoQuantityUnitRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: ExternalVisibilityDestinationLocationRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: ExternalVisibilitySpecialisedLoadDischargeLocationRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: ExternalVisibilityLoadDischargeActivityRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: ExternalVisibilityCargoActivityTypeRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: ExternalVisibilityDeliveryRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: ExternalVisibilityHirePeriodRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: ExternalVisibilityDemurrageStatusRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: ExternalVisibilityOffHiresRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: FreightMissingBlRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: FreightLargeActualFreightRateRule, multi: true },
        // old implementation switched off for now, should be revisited in
        // https://dev.azure.com/clarksonscloud/Operations/_workitems/edit/124236
        // { provide: FIXTURE_WARNING_RULE, useClass: FreightMissingActualFreightRateRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: FreightMissingFreightSpendRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: FreightZeroFreightSpendRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: FreightLargeFreightSpendRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: FreightBlDischargeQuantityRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: EtaTimeBetweenPortsRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: EtaMissingLoadEventRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: EtaMissingDischargeEventRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: EtaOpenFixtureRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: DataMissingNorTenderedDateRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: DataNorTenderedDateIsInTheFutureRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: DataBLDateDischargeDateInTheFutureRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: DataDuplicatedClaimRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: ClaimsMissingReceivedFromOwnerDateRule, multi: true },
        { provide: FIXTURE_WARNING_RULE, useClass: ClaimsMissingClaimValueRule, multi: true }
    ]
})
export class FixtureWarningRulesModule {}
