import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";

import { Command } from "../mediator";
import { FormComponentBase } from "../shared/form-component-base";
import { Destination, Fixture } from "../shared/models";
import { FixtureTabName } from "../shared/tab-validation/tab-validation-info";

@Component({
    selector: "ops-demurrage-tab",
    templateUrl: "./demurrage-tab.component.html",
    styleUrls: ["./demurrage-tab.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemurrageTabComponent extends FormComponentBase implements OnInit, OnDestroy {
    static componentName = "DemurrageTabComponent";

    private readonly destroy$ = new Subject();

    readonly tabName: FixtureTabName = "demurrageTab";

    demurrageTabForm: UntypedFormGroup;

    @Input() parentForm: UntypedFormGroup;
    @Input() fixture: Fixture;
    @Input() destinations: Destination[];

    @Output() demurrageTabUpdated = new EventEmitter();

    constructor(private formBuilder: UntypedFormBuilder, protected store: Store) {
        super(store);
    }

    ngOnInit() {
        this.demurrageTabForm = this.formBuilder.group({});
        this.parentForm.registerControl("demurrageTab", this.demurrageTabForm);
        this.demurrageTabForm[this.parentForm.disabled ? "disable" : "enable"]();

        this.subscribeToFormStatusChanges([this.demurrageTabForm], this.tabName);
    }

    ngOnDestroy() {
        delete this.parentForm.controls.demurrageTab;
        this.removeStatusChangesSubscrition();
        this.destroy$.next();
        this.destroy$.complete();
    }

    updateDemurrageTab(event: Command) {
        this.demurrageTabUpdated.emit(event);
    }
}
