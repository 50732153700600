import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Navigation, NavigationEnd, Router } from "@angular/router";
import { NgbTabChangeEvent, NgbTabset } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, take, takeUntil } from "rxjs/operators";

import { AppInsightsService } from "@ops/core";

import { ExpenseTabComponent } from "../expense-tab/expense-tab.component";
import { Command } from "../mediator/commands/command";
import { FixtureDataService } from "../services/fixture-data.service";
import { VoyageCost } from "../services/voyage-cost";
import { VoyageCostCalculator } from "../services/voyage-cost-calculator";
import { VoyageDataService } from "../services/voyage-data.service";
import { DataSourceCommand } from "../shared/datasource-command";
import { Fixture, Voyage } from "../shared/models";
import { FixtureSource } from "../shared/models/enums/fixture-source";
import { FixtureTabName } from "../shared/tab-validation/tab-validation-info";
import { FixtureWarningService } from "../shared/warnings/fixture-warning.service";
import { FixtureFeatureState } from "../state/model";
import { selectCurrentFixtureTabsValidity, updateValidationTabAction } from "../state/validation";
import { selectCurrentVoyageBusy, selectCurrentVoyageFormInvalid, selectCurrentVoyageReadonly } from "../state/voyage";
import { DeliveryComponent } from "./hire-tab/delivery/delivery.component";
import { OffHireComponent } from "./hire-tab/offhire/offhire.component";
import { PeriodsComponent } from "./hire-tab/periods/periods.component";
import { RedeliveryComponent } from "./hire-tab/redelivery/redelivery.component";
import { HireInvoicesComponent } from "./invoices-tab/hire-invoices/hire-invoices.component";

@Component({
    selector: "ops-time-charter",
    templateUrl: "./time-charter.component.html",
    styleUrls: ["./time-charter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeCharterComponent implements OnInit, OnDestroy, AfterViewInit {
    static componentName = "TimeCharterComponent";

    private readonly destroy$ = new Subject();

    readonly voyageInvalid$ = this.store.select(selectCurrentVoyageFormInvalid);
    readonly voyageReadonly$ = this.store.select(selectCurrentVoyageReadonly);
    readonly voyageBusy$ = this.store.select(selectCurrentVoyageBusy);

    timeCharterForm: UntypedFormGroup;
    hideHire: boolean;
    hideDeliveryNotes: boolean;
    creatingVoyage$: Observable<boolean>;
    loading$: Observable<boolean>;
    voyageCost: VoyageCost;
    voyageId: string;
    setTabOnLoad = false;

    tabsValidity: Partial<Record<FixtureTabName, boolean>> = {
        timeCharterHeader: true,
        hireTab: true,
        voyageTab: true,
        speedAndConsumptionTab: true,
        expensesTab: true,
        invoicesTab: true
    };

    @Input() parentForm: UntypedFormGroup;
    @Input() fixture$: Observable<Fixture | Voyage>;
    @Input() voyage$: Observable<Voyage>;
    @Input() voyages$: Observable<Voyage[]>;
    @Input() fixtureSave$: Observable<any>;
    @Input() voyageSave$: Observable<any>;
    @Input() fixtureSource: FixtureSource;
    @Output() timeCharterUpdated = new EventEmitter<DataSourceCommand>();

    @ViewChild("tabset") tabset: NgbTabset;
    @ViewChild(HireInvoicesComponent) hireInvoicesComponent: HireInvoicesComponent;
    @ViewChild(ExpenseTabComponent) expenseTabComponent: ExpenseTabComponent;
    @ViewChild(DeliveryComponent) deliveryComponent: DeliveryComponent;
    @ViewChild(RedeliveryComponent) redeliveryComponent: RedeliveryComponent;
    @ViewChild(PeriodsComponent) periodsComponent: PeriodsComponent;
    @ViewChild(OffHireComponent) offHireComponent: OffHireComponent;

    constructor(
        private store: Store<FixtureFeatureState>,
        private formBuilder: UntypedFormBuilder,
        private voyageDataService: VoyageDataService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private warningService: FixtureWarningService,
        private fixtureDataService: FixtureDataService,
        private voyageCostCalculator: VoyageCostCalculator,
        private applicationInsights: AppInsightsService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.timeCharterForm = this.formBuilder.group({});
        this.parentForm.registerControl("timeCharter", this.timeCharterForm);
        this.timeCharterForm[this.parentForm.disabled ? "disable" : "enable"]();
        this.creatingVoyage$ = this.voyageDataService.creatingVoyage$;
        this.loading$ = this.voyageDataService.loading$;
        this.voyageId = this.activatedRoute.snapshot.params["voyageId"];

        this.subscribeToTabsValidityChanges();

        this.parentForm.statusChanges
            .pipe(
                map(() => !this.isHireTabInvalid),
                distinctUntilChanged(),
                takeUntil(this.destroy$)
            )
            .subscribe((isValid: boolean) => {
                const tabName = "hireTab";
                this.store.dispatch(updateValidationTabAction({ tabName, isValid }));
            });
    }

    ngAfterViewInit() {
        if (this.voyageId && !this.setTabOnLoad) {
            setTimeout(() => {
                this.tabset.select("voyageTab");
                this.setTabOnLoad = true;
            });
        }

        this.router.events
            .pipe(
                takeUntil(this.destroy$),
                filter((event) => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                if (this.tabset.activeId === "voyageTab" || this.tabset.activeId === "speedAndConsumptionTab") {
                    return;
                }

                const getVoyageId = (nav: Navigation) => nav?.finalUrl.root.children["primary"]?.segments[3]?.path;
                const currentNavigation = this.router.getCurrentNavigation();
                const previousVoyageId = getVoyageId(currentNavigation.previousNavigation);
                const newVoyageId = getVoyageId(currentNavigation);

                if (previousVoyageId !== newVoyageId) {
                    this.tabset.select("voyageTab");
                }
            });

        const warningPathTabMap = new Map<string, string>([
            ["delivery", "hireTab"],
            ["redelivery", "hireTab"],
            ["periods", "hireTab"],
            ["cargoes", "voyageTab"],
            ["destinations", "voyageTab"],
            ["expenses", "expensesTab"]
        ]);

        this.warningService.currentWarning$.pipe(takeUntil(this.destroy$)).subscribe((warning) => {
            if (!warning) {
                return;
            }

            // Check voyage property as opposed to "voyages" as some exist on speed and cons
            const mappingSegmentIndex = warning.modelPath[0] === "voyages" ? 2 : 0;
            const tab = warningPathTabMap.get(warning.modelPath[mappingSegmentIndex]);

            if (tab) {
                this.tabset.select(tab);
            }
        });

        combineLatest([this.fixtureDataService.currentFixture$, this.voyageDataService.current$])
            .pipe(
                takeUntil(this.destroy$),
                filter(([fixture, voyage]) => fixture && voyage && fixture.fixtureId === voyage.fixtureId)
            )
            .subscribe(([fixture, voyage]) => {
                this.voyageCost = this.voyageCostCalculator.calcVoyageCost(voyage, fixture.periods);
            });

        this.voyage$
            .pipe(
                takeUntil(this.destroy$),
                filter((voyage) => !!voyage)
            )
            .subscribe((voyage) => {
                this.applicationInsights.setGlobalProperty("voyageNumber", voyage.voyageNumberDisplay);
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.applicationInsights.removeGlobalProperty("voyageNumber");

        if (this.parentForm.controls) {
            delete this.parentForm.controls.timeCharter;
        }
    }

    subscribeToTabsValidityChanges() {
        this.store
            .select(selectCurrentFixtureTabsValidity)
            .pipe(takeUntil(this.destroy$))
            .subscribe((tabsValidity) => {
                this.tabsValidity = { ...this.tabsValidity, ...tabsValidity };
                this.changeDetectorRef.markForCheck();
            });
    }

    tabChange(event: NgbTabChangeEvent) {
        this.applicationInsights.logPageView(`${TimeCharterComponent.componentName}#${event.activeId}`);
    }

    handleFixtureUpdateCommand(event: Command): void {
        this.timeCharterUpdated.emit(new DataSourceCommand(event, "fixture"));
    }

    handleVoyageUpdateCommand(event: Command): void {
        this.voyageInvalid$.pipe(take(1)).subscribe(({ isInvalid }) => this.timeCharterUpdated.emit(new DataSourceCommand(event, "voyage", !isInvalid)));
    }

    get isHireTabInvalid() {
        return (
            (this.offHireComponent && this.offHireComponent.invalid) ||
            (this.periodsComponent && this.periodsComponent.invalid) ||
            (this.deliveryComponent && this.deliveryComponent.invalid) ||
            (this.redeliveryComponent && this.redeliveryComponent.invalid)
        );
    }

    get isHireSectionInvalid() {
        return (this.offHireComponent && this.offHireComponent.invalid) || (this.periodsComponent && this.periodsComponent.invalid);
    }

    get isHireSectionTouched() {
        return (this.offHireComponent && this.offHireComponent.touched) || (this.periodsComponent && this.periodsComponent.touched);
    }

    get isCommissionActive() {
        return this.fixtureSource !== FixtureSource.Ops;
    }
}
