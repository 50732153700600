<div class="ops-ltc-header-container">
    <div class="name-form-container">
        <form [ngrxFormState]="form"
              [opsValidationPopover]="nameValidation"
              [validationPopoverEnabled]="form.isDirty"
              (submit)="updateName()"
              (clickOutside)="updateName()"
              (keydown.Tab)="updateName()"
              (keydown.Esc)="cancelRename.emit()"
              class="name-form"
              [class.name-required]="form.isDirty && form.controls.name.errors.required"
              [class.placeholder-visible]="!form.value.name && !form.controls.name.isFocused"
              autocomplete="off">
            <input
                type="text"
                class="ops-ltc-calculation-name"
                [class.name-notification]="showCloneNotification"
                maxlength="250"
                autoSizeInput
                [extraWidth]="10"
                [ngrxFormControlState]="form.controls.name"
                (keyup)="onKeyup($event)"
                data-cy="laytime-calculation-name" />
            <span class="ops-ltc-calculation-name-placeholder">Untitled Calculation</span>
        </form>
        <ng-template #nameValidation>
            <ops-field-validation
                *ngIf="form.controls.name.errors.required"
                fieldDisplayName="Name"
                errorMessage="is required">
            </ops-field-validation>
        </ng-template>
    </div>
    <div class="status-container">
        <span *ngIf="showPersistingIndicator" ngbTooltip="Saving changes...">
            <span class="has-icon icon-after icon--autorenew persisting-indicator" data-cy='status-pending'></span>
        </span>
        <span *ngIf="showPersistedIndicator" ngbTooltip="Changes saved. {{lastUpdated}}">
            <span class="has-icon icon-after icon--done persisted-indicator" data-cy='status-success'></span>
        </span>
        <span *ngIf="showFailedIndicator" ngbTooltip="Failed to save changes" #t="ngbTooltip" [autoClose]="false">
            <span class="has-icon icon-after icon--alert-note failed-indicator"
                  data-cy='status-failure'></span>{{t.open()}}
        </span>
        <div class="clone-notification-wrapper">
            <div *ngIf="showCloneNotification" [class.clone-notification]="showCloneNotification">
                <span>Calculation has been copied successfully</span>
            </div>
        </div>
    </div>
    <div class="buttons-container">
        <div class="button-group">
            <ops-icon-button
                *ngIf="!isExportLoading"
                icon="file-sheet"
                (click)="exportExcel.emit()"
                tooltip="Export Excel Spreadsheet"
                data-cy="export-xlsx">
            </ops-icon-button>
            <ops-icon-button
                *ngIf="!isExportLoading"
                icon="file-pdf"
                (click)="exportPdf.emit()"
                tooltip="Export PDF"
                data-cy="export-pdf">
            </ops-icon-button>
            <ops-load-animation
                *ngIf="isExportLoading"
                class="light-background loading-animation"
                size="medium">
            </ops-load-animation>
        </div>
        <div class="button-group">
            <ops-icon-button
                icon="clone"
                (click)="clone()"
                tooltip="Clone calculation"
                data-cy="clone">
            </ops-icon-button>
        </div>
        <div class="button-group">
            <ops-icon-button icon="close" (click)="closeClick.emit()" tooltip="Close" data-cy="close"></ops-icon-button>
        </div>
    </div>
</div>
