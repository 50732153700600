import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroupState } from "ngrx-forms";

import { CargoTotalType } from "../../shared/models";
import { VoyageForm } from "../../state";
import { CargoTotalState } from "../../state/totals";

@Component({
    selector: "ops-totals-form",
    templateUrl: "./totals-form.component.html",
    styleUrls: ["./totals-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotalsFormComponent {
    CargoTotalType = CargoTotalType;

    @Input() form: FormGroupState<VoyageForm>;
    @Input() totals: CargoTotalState[];
    @Input() readonly: boolean;
    @Input() canAddTotal: boolean;

    @Output() readonly add = new EventEmitter();
    @Output() readonly remove = new EventEmitter<number>();
    @Output() readonly generateInvoice = new EventEmitter<number>();

    get blTotal(): number {
        return this.totals.filter((t) => t.total.bl).length ? this.totals.reduce((a, b) => a + (isNaN(b.total.bl) ? null : b.total.bl), 0) : null;
    }

    get freightSpendTotal(): number {
        return this.totals.filter((t) => t.total.freightSpend).length ? this.totals.reduce((a, b) => a + (isNaN(b.total.freightSpend) ? null : b.total.freightSpend), 0) : null;
    }

    getControlId(id: number | string, field: string) {
        return `${this.form?.id}.totals.${id}.${field}`;
    }

    generateFreightInvoice() {
        this.generateInvoice.emit(this.freightSpendTotal);
    }

    trackBy(index: number, item: CargoTotalState) {
        return item.total.cargoId;
    }
}
