import { cancelVesselNominationEditReducer, editVesselNominationReducer } from "./form/edit-vessel-nomination";
import { removeVesselNominationFailReducer, removeVesselNominationReducer, removeVesselNominationSuccessReducer } from "./form/remove-vessel-nomination";
import { renominateNewVesselSuccessReducer, saveVesselNominationFailReducer, saveVesselNominationReducer, saveVesselNominationSuccessReducer } from "./form/save-vessel-nomination";
import {
    acceptVesselNominationSuccessReducer,
    preferVesselNominationSuccessReducer,
    rejectVesselNominationSuccessReducer,
    reviewVesselNominationSuccessReducer,
    updateVesselNominationStatusFailReducer,
    updateVesselNominationStatusReducer
} from "./update-status";
import { updateVesselPlanStatusReducer } from "./update-vessel-plan-status";

export default [
    saveVesselNominationReducer,
    saveVesselNominationFailReducer,
    saveVesselNominationSuccessReducer,
    editVesselNominationReducer,
    removeVesselNominationReducer,
    removeVesselNominationSuccessReducer,
    removeVesselNominationFailReducer,
    cancelVesselNominationEditReducer,
    updateVesselNominationStatusReducer,
    acceptVesselNominationSuccessReducer,
    rejectVesselNominationSuccessReducer,
    reviewVesselNominationSuccessReducer,
    preferVesselNominationSuccessReducer,
    updateVesselNominationStatusFailReducer,
    renominateNewVesselSuccessReducer,
    updateVesselPlanStatusReducer
];
