import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaustMap, map, tap, withLatestFrom } from "rxjs/operators";

import { navigateToLifting } from "../../routing";
import { LiftingHttpService } from "../../services";
import { CoaFeatureState, CoaId, LiftingId } from "../model";
import { selectCurrentLifting } from "./selectors";

/* ACTIONS */
const ACTION_NAME = "[Lifting] Clone Lifting";

export const cloneLiftingAction = createAction(ACTION_NAME, props<{ cloneId: LiftingId }>());
export const cloneLiftingSuccessAction = createAction(`${ACTION_NAME} Success`, props<{ coaId: CoaId; cloneId: LiftingId }>());
export const cloneLiftingFailAction = createAction(`${ACTION_NAME} Fail`, props<{ coaId: CoaId; cloneId: LiftingId; error: Error }>());

/* EFFECTS */
export const cloneLiftingEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(cloneLiftingAction),
            withLatestFrom(store.select(selectCurrentLifting)),
            exhaustMap(([{ cloneId }, { coaId, liftingId }]) =>
                liftingHttpService.cloneLifting(coaId, liftingId, cloneId).pipe(
                    map(() => cloneLiftingSuccessAction({ coaId, cloneId })),
                    catchError((error) => of(cloneLiftingFailAction({ coaId, cloneId, error })))
                )
            )
        )
    );

export const cloneLiftingSuccessEffect$ = (actions$: Actions, router: Router) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(cloneLiftingSuccessAction),
                tap(({ coaId, cloneId }) => navigateToLifting(router, coaId, cloneId))
            ),
        { dispatch: false }
    );
