import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { CoaHttpService } from "../../../services";
import { currentCoaStateReducer } from "../../coa/reducer";
import { selectCurrentCoa } from "../../coa/selectors";
import { CargoId, CoaFeatureState, CoasState, getCurrentCoaState } from "../../model";

/* ACTIONS */
const REMOVE_CARGO_ACTION_NAME = "[Coa Cargo Form] Remove Cargo";
export const removeCargoAction = createAction(REMOVE_CARGO_ACTION_NAME, props<{ cargoId: CargoId }>());
export const removeCargoSuccessAction = createAction(`${REMOVE_CARGO_ACTION_NAME} Success`, props<{ cargoId: CargoId }>());
export const removeCargoFailAction = createAction(`${REMOVE_CARGO_ACTION_NAME} Fail`, props<{ cargoId: CargoId; error: Error }>());

/* REDUCERS */
export const removeCargoSuccessReducer: On<CoasState> = on(removeCargoSuccessAction, (state, { cargoId }) => {
    const currentCoa = getCurrentCoaState(state);
    const cargo = currentCoa.coa.cargoes?.find((c) => c.cargoId === cargoId);

    return !cargo
        ? state
        : currentCoaStateReducer(state, (coaState) => ({
              ...coaState,
              coa: {
                  ...coaState.coa,
                  cargoes: currentCoa.coa.cargoes.filter((c) => c.cargoId !== cargoId)
              }
          }));
});

export const removeCargoFailReducer: On<CoasState> = on(removeCargoFailAction, (state, { cargoId, error }) => {
    const currentCoa = getCurrentCoaState(state);
    const cargo = currentCoa.coa.cargoes?.find((c) => c.cargoId === cargoId);

    return !cargo
        ? state
        : currentCoaStateReducer(state, (coaState) => ({
              ...coaState,
              erroredItems: [...(coaState.erroredItems || []), { itemType: "cargoes", id: cargoId, error }]
          }));
});

/* EFFECTS */
export const removeCargoEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, coaService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeCargoAction),
            withLatestFrom(store.select(selectCurrentCoa)),
            mergeMap(([action, coa]) =>
                coaService.removeCargo(coa.coaId, action.cargoId).pipe(
                    map(() => removeCargoSuccessAction(action)),
                    catchError((error) => of(removeCargoFailAction({ ...action, error })))
                )
            )
        )
    );
