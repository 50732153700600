import * as R from "ramda";

import { hasValue, isArray, rFilter } from "@ops/shared";
import { CargoBerthActivityType, FreightType } from "@ops/shared/reference-data";

import { DemurrageClaim, ExpenseClaim, Fixture, Voyage } from "../shared/models";
import { DemurrageClaimType } from "../shared/models/enums/demurrage-claim-type";
import { getAssociatedCargoes, getBaseFreightSpend, getExpenseValue, getFreightSpend } from "../state/model";

// NOTE there is no rounding here, only at display level which may result in discrepancies between the totals and items

/**
 * Value to use when name of item is missing.
 */
const UNSPECIFIED_NAME = "Unspecified";

export type ReportView = "charterer" | "owner";

export type ReportInvalid = Readonly<{
    /**
     * True if there is insufficient data to generate the report or report line. This will only be true if all child
     * items have insufficient data - the report is not considered invalid if there are any valid report lines.
     */
    insufficientData?: boolean;
    /**
     * True if there are any monetary values in the report that require currency conversion to for the report currency.
     *
     * This is currently a limitation of the implementation / available data.
     */
    currencyConversion?: boolean;
}>;

export type ReportLine = Readonly<{
    name: string;
    /**
     * The three-letter ISO code of the currency code the report line is in.
     */
    currency?: string;
    estimated?: number | null;
    actual?: number | null;
    /**
     * The different between estimated and actual (estimated - actual).
     */
    variance?: number | null;
    items?: ReadonlyArray<ReportLine>;
    invalid?: ReportInvalid;
}>;

export type ProfitAndLossReport = Readonly<{
    /**
     * The party of the fixture is the report for. Possible values "charterer" or "owner".
     *
     * @experimental
     */
    view: "charterer" | "owner";
    /**
     * The three-letter ISO code of the currency code the report is in.
     */
    currency: string;
    /**
     * `ReportLine` items for revenue. Currently not implemented.
     *
     * @experimental
     */
    revenue?: readonly ReportLine[];
    /**
     * `ReportLine` items for expenses.
     */
    expenses: readonly ReportLine[];
    /**
     * Sum of expenses estimated.
     */
    netEstimated: number;
    /**
     * Sum of expenses actual.
     */
    netActual: number;
    /**
     * Sum of expenses variance.
     */
    netVariance: number;
    /**
     * Indicates whether the report was generated with any invalid or missing data.
     */
    invalid?: ReportInvalid;
}>;

export function reportLine(name: string, currency: string, items: readonly ReportLine[]): ReportLine;
export function reportLine(name: string, currency: string, estimated: number, actual: number): ReportLine;
export function reportLine(name: string, currency: string, estimatedOrItems?: number | readonly ReportLine[], actual?: number): ReportLine {
    let estimated: number = null;
    let items: ReportLine[];
    let currencyConversion = false;

    if (isArray(estimatedOrItems)) {
        items = estimatedOrItems.concat();

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (item.currency !== currency) {
                currencyConversion = true;
                items.splice(i, 1);
                i--;
                continue;
            }

            if (hasValue(item.estimated)) {
                estimated = (estimated ?? 0) + item.estimated;
            }
            if (hasValue(item.actual)) {
                actual = (actual ?? 0) + item.actual;
            }
        }
    } else {
        estimated = estimatedOrItems;
    }

    const hasActual = hasValue(actual);
    const hasEstimated = hasValue(estimated);

    actual = hasActual ? actual : null;

    const variance = hasActual && hasEstimated ? actual - estimated : null;
    const insufficientData = !hasActual && !hasEstimated;
    const invalid = reduceReportLineInvalid(insufficientData || currencyConversion ? { insufficientData, currencyConversion } : undefined, items);

    return {
        name,
        currency,
        estimated,
        actual,
        variance,
        items,
        invalid
    };
}

export function report(view: ReportView, currency: string, expenses: readonly ReportLine[]): ProfitAndLossReport {
    const netEstimated = R.pipe(R.pluck("estimated"), rFilter(hasValue), R.sum)(expenses);
    const netActual = R.pipe(R.pluck("actual"), rFilter(hasValue), R.sum)(expenses);
    const netVariance = netActual - netEstimated;
    const invalid = reduceReportLineInvalid(undefined, expenses);

    return {
        view,
        currency,
        expenses,
        netEstimated,
        netActual,
        netVariance,
        invalid
    };
}

/**
 * Takes a given collection, reduces by the result of the provided `nameFn` by summing the results of the `estimatedFn`
 * and `actualFn`.
 *
 * @param nameFn The value of `ReportLine.name`.
 * @param currencyFn The value of `ReportLine.currency`.
 * @param estimatedFn Summed to `ReportLine.estimated`.
 * @param actualFn Summed to `ReportLine.actual`.
 */
const reduceToReportLine = <T>(
    nameFn: (value: T) => string,
    currencyFn: (value: T) => string,
    estimatedFn: (value: T) => number | null,
    actualFn: (value: T) => number | null
): ((items: readonly T[]) => readonly ReportLine[]) =>
    R.pipe(
        R.reduceBy(
            (acc: { name: string; currency: string; estimated: number; actual: number }, next: T) => {
                acc = acc ?? { name: nameFn(next), currency: currencyFn(next), estimated: null, actual: null };

                const estimated = estimatedFn(next);
                const actual = actualFn(next);

                if (hasValue(estimated)) {
                    acc.estimated = (acc.estimated ?? 0) + estimated;
                }

                if (hasValue(actual)) {
                    acc.actual = (acc.actual ?? 0) + actual;
                }

                return acc;
            },
            null,
            (value: T) => `${nameFn(value)}::${currencyFn(value)}`
        ),
        R.values,
        R.sortBy(R.prop("name")),
        R.map(({ name, currency, estimated, actual }) => reportLine(name, currency, estimated, actual))
    );

const reduceReportLineInvalid = (invalid?: ReportInvalid, items?: readonly ReportLine[]): ReportInvalid | undefined => {
    if (!items) {
        return invalid;
    }

    const insufficientData = items.every((item) => item.invalid?.insufficientData);
    const currencyConversion = items.some((item) => item.invalid?.currencyConversion);

    if (insufficientData || currencyConversion) {
        return {
            ...invalid,
            insufficientData: insufficientData || invalid?.insufficientData,
            currencyConversion: currencyConversion || invalid?.currencyConversion
        };
    }

    return invalid;
};

function selectFreight(fixture: Fixture, voyage: Voyage): ReportLine {
    if (fixture.freightType?.id === FreightType.LumpSum.id) {
        return reportLine("Freight", fixture.currency?.code, fixture.lumpsumValue, fixture.lumpsumValue);
    }

    const cargoes = voyage.cargoes;
    const loadAssociatedCargoes = getAssociatedCargoes(CargoBerthActivityType.Load)(voyage.destinations);

    let estimated: number = null;
    let actual: number = null;

    for (const cargo of cargoes) {
        const associatedCargoes = loadAssociatedCargoes.filter((associatedCargo) => associatedCargo.cargoId === cargo.id && hasValue(associatedCargo.freightRate));

        if (hasValue(cargo.baseFreightRate)) {
            const cargoEstimated = getBaseFreightSpend(cargo);

            if (hasValue(cargoEstimated)) {
                estimated = (estimated ?? 0) + cargoEstimated;
            }
        }

        if (associatedCargoes.length) {
            const cargoActual = associatedCargoes.reduce((acc, next) => {
                const freightSpend = getFreightSpend(next, cargo.baseFreightRateUnit);

                return freightSpend !== null ? (acc ?? 0) + freightSpend : acc;
            }, null as number);

            if (hasValue(cargoActual)) {
                actual = (actual ?? 0) + cargoActual;
            }
        }
    }

    return reportLine("Freight", fixture.currency?.code, estimated, actual);
}

function selectCommission(freight: ReportLine, addressCommission: number, brokerCommission: number): ReportLine | undefined {
    const commissionLineItems = new Array<ReportLine>();

    if (addressCommission) {
        // eslint-disable-next-line no-magic-numbers
        addressCommission /= -100;

        commissionLineItems.push(reportLine("Address", freight.currency, freight.estimated * addressCommission, freight.actual * addressCommission));
    }

    if (brokerCommission) {
        // eslint-disable-next-line no-magic-numbers
        brokerCommission /= 100;

        commissionLineItems.push(reportLine("Broker", freight.currency, freight.estimated * brokerCommission, freight.actual * brokerCommission));
    }

    if (commissionLineItems.length) {
        return reportLine("Commission", freight.currency, commissionLineItems);
    }
}

function selectAdditionalRates(voyage: Voyage, currency: string): ReportLine {
    const associatedCargoes = getAssociatedCargoes()(voyage.destinations);

    const additionalRateLineItems = R.pipe(
        R.flatten,
        R.map(({ associatedCargoExpenses }) => associatedCargoExpenses ?? []),
        R.flatten,
        reduceToReportLine(
            (e) => e.rateDescription?.name ?? UNSPECIFIED_NAME,
            () => currency,
            () => null,
            getExpenseValue
        )
    )(associatedCargoes);

    return reportLine("Additional Cargo Rates", currency, additionalRateLineItems);
}

function selectAdditionalFreight(voyage: Voyage, currency: string): ReportLine {
    const additionalFreightLineItems = R.pipe(
        rFilter(({ freightSpend }) => hasValue(freightSpend)),
        R.map(({ description, freightSpend }) => reportLine(description, currency, null, freightSpend)),
        R.sortBy(R.prop("name"))
    )(voyage.additionalFreightExpenses);

    return reportLine("Additional Freight", currency, additionalFreightLineItems);
}

function selectExpenseClaims(expenses: readonly ExpenseClaim[], currency: string): ReportLine {
    const expenseClaimLineItems = R.pipe(
        rFilter((e: ExpenseClaim) => hasValue(e.initialClaimValue) || hasValue(e.finalClaimValue)),
        reduceToReportLine(
            (e) => e.type?.name ?? UNSPECIFIED_NAME,
            (e) => e.currency?.code,
            (e) => e.initialClaimValue,
            (e) => e.finalClaimValue
        )
    )(expenses);

    return reportLine("Expenses", currency, expenseClaimLineItems);
}

function selectDemurrage(demurrage: readonly DemurrageClaim[], currency: string): readonly ReportLine[] {
    const getName = (claim: DemurrageClaim) => {
        switch (claim.type?.id) {
            case DemurrageClaimType.Despatch:
                return "Despatch";
            case DemurrageClaimType.Detention:
                return "Detention";
            default:
                return "Demurrage";
        }
    };
    const getValue = (claim: DemurrageClaim, value: number): number => {
        switch (claim.type?.id) {
            case DemurrageClaimType.Despatch:
                return value ? -value : value;
            default:
                return value;
        }
    };

    return R.pipe(
        rFilter((d: DemurrageClaim) => hasValue(d.estimatedClaimValue) || hasValue(d.initialClaimValue) || hasValue(d.finalClaimValue)),
        reduceToReportLine(
            getName,
            (d) => d.currency?.code,
            (d) => getValue(d, d.estimatedClaimValue || d.initialClaimValue),
            (d) => getValue(d, d.finalClaimValue)
        ),
        R.groupBy((l) => l.name),
        R.values,
        R.map((lineItems) => {
            const validItem = lineItems.find((x) => x.currency === currency);

            if (validItem && lineItems.length === 1) {
                return validItem;
            }

            const item = validItem ?? reportLine(lineItems[0].name, null, null, null);

            return {
                ...item,
                invalid: {
                    ...item.invalid,
                    currencyConversion: true
                }
            };
        })
    )(demurrage);
}

export function generateProfitAndLoss(view: ReportView, fixture: Fixture, voyage: Voyage, expenses: ExpenseClaim[]) {
    if (view === "owner") {
        throw Error("owner view currently unsupported");
    }

    const currency = fixture.currency.code;

    const freight = selectFreight(fixture, voyage);
    const commission = selectCommission(freight, fixture.addressCommission, fixture.brokerCommission);
    const additionalRates = selectAdditionalRates(voyage, currency);
    const additionalFreight = selectAdditionalFreight(voyage, currency);
    const expenseClaims = selectExpenseClaims(expenses ?? [], currency);
    const demurrage = selectDemurrage(fixture.demurrage?.claims ?? [], currency);

    const lineItems = [
        freight,
        // addressCommission inserted below
        additionalRates,
        additionalFreight,
        expenseClaims,
        ...demurrage
    ];

    if (commission) {
        lineItems.splice(1, 0, commission);
    }

    return report(view, currency, lineItems);
}
