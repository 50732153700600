<div class="ops-ltc-nav">
    <ul class="ops-ltc-nav-list">
        <li>
            <a
                [routerLink]="['/', { outlets: { primary: ['ltc', ltcId] } }]"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active"
                (click)="navItemOnClick('Summary')"
                class="ops-ltc-nav-link"
                data-cy="summary"
            >Summary</a>
        </li>
        <li>
            <a routerLink="terms"
               routerLinkActive="active"
               (click)="navItemOnClick('Terms')"
               class="ops-ltc-nav-link"
               data-cy="terms">Terms</a>
        </li>
        <li *ngFor="let activityLocation of activityLocations">
            <a
                [routerLink]="['locations', activityLocation.id]"
                [class.active]="currentActivityLocationId === activityLocation.id"
                (click)="navItemOnClick('Activity Location')"
                class="ops-ltc-nav-link"
                data-cy="location"
            >
                <span class="ops-ltc-nav-link-text" [title]="activityLocation.name">{{activityLocation.name}}</span>
                <ops-description-icon class="p-ml-auto" [type]="activityLocation.activity"
                                      data-cy="activity"></ops-description-icon>
            </a>
        </li>
        <li>
            <a routerLink="add-locations"
               (click)="navItemOnClick('Add Locations')"
               class="ops-ltc-nav-link"
               data-cy="add-location">Add Locations +</a>
        </li>
    </ul>
</div>

<div class="ops-ltc-nav-content">
    <router-outlet></router-outlet>
</div>
