<div class="header clearfix">
    <div class="favourite-list">Favourites list</div>
    <div class="left-toolbar-expander" (click)="collapseLeftBar()">
        <div class="has-icon icon--close"></div>
    </div>
    <div class="buid-version">v.{{ buildVersionNumber }}</div>
</div>

<ops-favorite-toolbar
    class="ops-favorite-toolbar"
    [ngClass]="anySelected || allSelected ? '' : 'hide'"
    [allSelected]="allSelected"
    [anySelected]="anySelected"
    (removed)="onRemoved()"
    (openAll)="openAll()"
></ops-favorite-toolbar>

<ng-container *ngIf="anyFavorites; else noFavorites">
    <div class="row favorites-select-all-row">
        <div class="col-1">
            <div class="form-check">
                <input type="checkbox" [(ngModel)]="allSelected" (click)="onToggleSelectAll($event)" data-test-selectall />
            </div>
        </div>
        <div class="col">
            <label class="favorite-select-checkbox-label" (click)="onToggleSelectAll($event)">Select all</label>
        </div>
    </div>

    <ops-favorite-list (openFavorite)="openFavoriteInNewTab($event)" class="ops-favorite-list"></ops-favorite-list>
</ng-container>

<ng-template #noFavorites>
    <div *ngIf="(favouritesLoading$ | async) === false" class="row favorites-placeholder" data-test-favorites-placeholder>
        <div class="col-3">
            <div class="circle">
                <span class="has-icon icon--lightbulb-outline"></span>
            </div>
        </div>
        <div class="col-9">
            <div class="title">No Favourite fixtures</div>
            <div class="description">Click on the star icon, or edit the fixture to favourite</div>
        </div>
    </div>
    <div *ngIf="favouritesLoading$ | async">
        <ops-load-animation size="x-large" class="light-background"></ops-load-animation>
    </div>
</ng-template>
