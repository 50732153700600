import { Injectable } from "@angular/core";
import { v4 as uuid } from "uuid";

import { getActivity } from "../../../../../../shared/voyage-utils/voyage-utilities";
import { CommandHandler } from "../../../../../mediator";
import { LaytimeEventFact, Voyage } from "../../../../../shared/models";
import { AddLaytimeEventFactCommand } from "./add-laytime-event-fact.command";

@Injectable({
    providedIn: "root"
})
export class AddLaytimeEventFactCommandHandler implements CommandHandler {
    handle(voyage: Voyage, command: AddLaytimeEventFactCommand) {
        const activityToUpdate = getActivity(voyage, command.destinationId, command.berthId, command.berthActivityId);

        let fromDateForAdded = null;

        if (activityToUpdate.laytimeEventFacts && activityToUpdate.laytimeEventFacts.length > 0) {
            fromDateForAdded = activityToUpdate.laytimeEventFacts[command.index - 1].toDate;
        } else {
            activityToUpdate.laytimeEventFacts = [];
        }

        const newLaytimeFact = <LaytimeEventFact>{
            id: uuid(),
            fromDate: fromDateForAdded,
            toDate: null,
            comments: null,
            percentage: null,
            type: null
        };

        activityToUpdate.laytimeEventFacts.splice(command.index, 0, newLaytimeFact);
        voyage.isDirty = true;
    }
}
