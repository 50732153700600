import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { SelectItem } from "primeng/api";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, map, takeUntil } from "rxjs/operators";

import { Company } from "./../../left-bar/contacts/models/company.model";
import { CreateClaimCommand } from "./commands/create-claim.command";
import { SyncAllDestinationLaytimeEventsCommand } from "./commands/sync-all-destination-laytime-events-command";
import { UpdateLaytimeUnitEnumCommand } from "./statement-of-facts-container/berth-statement-of-facts/laytime-event-facts/commands/update-laytime-unit-enum.command";
import { AuthService, FeatureToggleService, MixpanelService } from "../../core";
import { ContactService } from "../../left-bar/contacts";
import { LeftBarStateService } from "../../left-bar/left-bar-state.service";
import { ExportService } from "../../left-bar/shared/export-service";
import { CargoBerthActivityType, LaytimeCalculationUnit, LaytimeCalculationUnitEnum } from "../../shared/reference-data";
import { Command } from "../mediator/commands/command";
import { EstimatedClaimValueCalculator } from "../services/estimated-claim-value-calculator";
import { FormComponentBase } from "../shared/form-component-base";
import { Berth, Cargo, CompanyType, Destination, Fixture, LaytimeEvent } from "../shared/models";
import { CargoAllowedRate } from "../shared/models/dtos/cargo-allowed-rate.dto";
import { LaytimeSummaryTotalTimeModel } from "../shared/models/form-models/laytime-summary-aggregate.model";
import { FixtureTabName } from "../shared/tab-validation/tab-validation-info";
import { laytimeCreateClaim } from "../state/fixture";

@Component({
    selector: "ops-laytime-tab",
    templateUrl: "./laytime-tab.component.html",
    styleUrls: ["./laytime-tab.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeTabComponent extends FormComponentBase implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    static componentName = "LaytimeTabComponent";

    private readonly daysFormat = <SelectItem>{ label: LaytimeCalculationUnitEnum.Days.name, value: LaytimeCalculationUnitEnum.Days.name };
    private readonly hoursFormat = <SelectItem>{ label: LaytimeCalculationUnitEnum.Hours.name, value: LaytimeCalculationUnitEnum.Hours.name };
    private readonly isClaimCalculated$ = new BehaviorSubject<boolean>(false);
    private readonly isLaytimeFormDisabled$ = new BehaviorSubject<boolean>(false);
    private readonly destroy$: Subject<boolean> = new Subject<boolean>();

    readonly tabName: FixtureTabName = "laytimeTab";

    destinationsForm: UntypedFormArray;
    laytimeTabForm: UntypedFormGroup;
    hideLaytimeTermsSection: boolean;
    hideLaytimeSummarySection: boolean;
    hideLaytimeCalculationSection: boolean;
    directToLaytimeCalculatorV2: boolean;
    currentDestinations: Destination[];
    currrentCompanies: Company[];
    displayUnitType: LaytimeCalculationUnit;
    ecvTotalTime: LaytimeSummaryTotalTimeModel;
    dateFormatTypes = <SelectItem[]>[];
    isCreateClaimDisabled$: Observable<boolean>;

    readonly loading$ = new BehaviorSubject<boolean>(false);

    @Input() parentForm: UntypedFormGroup;
    @Input() controlName: string;
    @Input() fixture: Fixture;
    @Input() cargoes: Cargo[];
    @Input() destinations: Destination[];
    @Input() cargoAllowedRates: CargoAllowedRate[];
    @Input() displayUnit: LaytimeCalculationUnitEnum;
    @Output() laytimeTabUpdated = new EventEmitter<Command>();
    @Output() cargoLaytimeTabUpdated = new EventEmitter<Command>();

    constructor(
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        private exportService: ExportService,
        private featureToggleService: FeatureToggleService,
        private contactService: ContactService,
        private leftBarStateService: LeftBarStateService,
        private ecvCalculator: EstimatedClaimValueCalculator,
        protected store: Store,
        private mixpanelService: MixpanelService
    ) {
        super(store);
        this.isCreateClaimDisabled$ = combineLatest([this.isLaytimeFormDisabled$, this.isClaimCalculated$]).pipe(
            takeUntil(this.destroy$),
            map(([isLaytimeFormDisabled, isClaimCalculated]) => isLaytimeFormDisabled || !isClaimCalculated),
            distinctUntilChanged()
        );
    }

    get allImported() {
        if (!this.currentDestinations.length) {
            return true;
        }

        let laytimeEvents: LaytimeEvent[] = [];

        this.currentDestinations.forEach((d) => {
            d.berths.forEach((b) => {
                b.cargoBerthActivities.forEach((a) => {
                    laytimeEvents = [...laytimeEvents, ...(a.laytimeEvents || [])];
                });
            });
        });

        return !laytimeEvents.some((e) => !e.isSynced && e.type && e.eventDate);
    }

    get importButtonTooltip() {
        return this.allImported ? "No additional port times to be imported" : "";
    }

    ngOnInit() {
        this.destinationsForm = this.formBuilder.array([]);
        this.laytimeTabForm = this.formBuilder.group({});
        this.parentForm.registerControl(this.controlName, this.laytimeTabForm);
        this.laytimeTabForm.registerControl("destinationsForm", this.destinationsForm);
        this.laytimeTabForm.statusChanges.subscribe(() => {
            this.isLaytimeFormDisabled$.next(this.laytimeTabForm.disabled);
        });
        if (this.parentForm.disabled) {
            this.laytimeTabForm.disable();
            this.destinationsForm.disable();
        } else {
            this.laytimeTabForm.enable();
            this.destinationsForm.enable();
        }
        this.filterDestinations();
        this.setDestinationsForm();
        this.setEcvTotalTime();
        this.setDateFormatTypes();
        this.setDisplayType();
        this.contactService.companies$.pipe(takeUntil(this.destroy$)).subscribe((companies) => {
            this.currrentCompanies = companies;
        });
        this.directToLaytimeCalculatorV2 = this.featureToggleService.isFeatureEnabled("LaytimeCalculatorV2") && !this.featureToggleService.isFeatureEnabled("LaytimeCalculator");

        this.subscribeToFormStatusChanges([this.laytimeTabForm], this.tabName);
    }

    ngAfterViewInit() {
        // triggers statusChanges with actual info
        this.laytimeTabForm.updateValueAndValidity({ emitEvent: true });
    }

    ngOnDestroy() {
        delete this.parentForm.controls.laytimeTab;
        delete this.parentForm.controls.locationsForm;
        this.removeStatusChangesSubscrition();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes || !this.fixture || !this.destinationsForm) {
            return;
        }
        this.filterDestinations();
        this.setDestinationsForm();
        this.setEcvTotalTime();
        this.setDisplayType();
    }

    updateLaytimeTab(event: Command): void {
        this.laytimeTabUpdated.emit(event);
    }

    updateCargoLaytimeTab(event: Command): void {
        this.cargoLaytimeTabUpdated.emit(event);
    }

    toggleLaytimeTermsSection(): void {
        this.hideLaytimeTermsSection = !this.hideLaytimeTermsSection;
    }

    toggleLaytimeSummarySection(): void {
        this.hideLaytimeSummarySection = !this.hideLaytimeSummarySection;
    }

    toggleLaytimeCalculationSection(): void {
        this.hideLaytimeCalculationSection = !this.hideLaytimeCalculationSection;
    }

    toggleUnit(value: LaytimeCalculationUnit): void {
        this.displayUnitType = value;
        this.laytimeTabUpdated.emit(new UpdateLaytimeUnitEnumCommand(LaytimeCalculationUnitEnum[this.displayUnitType]));
    }

    createClaim() {
        this.store.dispatch(laytimeCreateClaim());
        this.laytimeTabUpdated.emit(new CreateClaimCommand(this.authService.user, this.destinations));
    }

    claimValueUpdated(isClaimCalculated: boolean) {
        this.isClaimCalculated$.next(isClaimCalculated);
    }

    syncLaytimeEventData() {
        if (!this.parentForm.disabled && !this.allImported) {
            this.cargoLaytimeTabUpdated.emit(new SyncAllDestinationLaytimeEventsCommand(this.currentDestinations));
            this.mixpanelService.track("Ops: Import Times for All Ports (Old LTC)");
        }
    }

    export() {
        const charterer = this.currrentCompanies.find((c) => c.typeId === CompanyType.Charterer);
        const vesselName = this.fixture.vessel ? this.fixture.vessel.name : "";
        const chartererName = charterer ? charterer.name : "";
        const cpDate = this.fixture.charterParty ? this.fixture.charterParty.charterPartyDate : "";

        this.loading$.next(true);
        this.exportService
            .exportLaytimeSummary(this.fixture.fixtureId, vesselName, chartererName, cpDate, "LaytimeSummaryReport")
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.loading$.next(false);
            });
    }

    navigateToV2LaytimeCalculations() {
        if (this.leftBarStateService.isCollapsedSnapshot) {
            this.leftBarStateService.open();
        }

        this.router.navigate([{ outlets: { toolbar: "ltc" } }], {
            replaceUrl: true,
            queryParamsHandling: "merge"
        });
    }

    private setDestinationsForm(): void {
        const formArray = <UntypedFormArray>this.destinationsForm;
        const count = this.currentDestinations ? this.currentDestinations.length : 0;

        while (formArray.length !== count) {
            if (formArray.length > count) {
                formArray.removeAt(0);
            } else if (formArray.length < count) {
                const newGroup = this.formBuilder.group({}, { updateOn: "blur" });
                if (this.parentForm.disabled) {
                    newGroup.disable();
                } else {
                    newGroup.enable();
                }
                formArray.push(newGroup);
            }
        }
    }

    private setEcvTotalTime() {
        this.ecvTotalTime = this.ecvCalculator.calculateTotalTimeForFixtureECV(this.fixture, this.destinations);
    }

    private setDisplayType() {
        this.displayUnitType = <LaytimeCalculationUnit>this.displayUnit?.name ?? "Days";
    }

    private setDateFormatTypes() {
        this.dateFormatTypes.push(this.daysFormat);
        this.dateFormatTypes.push(this.hoursFormat);
    }

    private filterDestinations() {
        const isLoadOrDischargeBerth = (berth: Berth) => berth.cargoBerthActivities.some((ac) => CargoBerthActivityType.isLoadOrDischarge(ac.type));
        const isLoadOrDischargeDestination = (destination: Destination) => destination.berths && destination.berths.some(isLoadOrDischargeBerth);

        this.currentDestinations = (this.destinations || []).filter(isLoadOrDischargeDestination).map((d) => ({ ...d, berths: d.berths.filter(isLoadOrDischargeBerth) }));
    }
}
