/**
 * Laytime Calculator
 *
 * Note this implementation is intended to be standalone from the state/rest of the app
 * and be as close to the C# implementation as possible whilst using Typescript idioms.
 */

import { Duration } from "luxon";

import { absDuration, hasValue, roundToMinutes, Sector, zeroDuration } from "@ops/shared";

import { ActivityLocationId, LaytimeCalculationDurationUnit, LaytimeEventId } from "../state";
import { getTimeCounted, LaytimeEventTimeCounted } from "./impl/activity-location-time-counter";
import { calculateAllowedLaytime } from "./impl/allowed-laytime-calculator";
import { roundDuration } from "./impl/duration-utils";
import { ActivityLocation, LaytimeCalculationTerms } from "./laytime-calculation";
import { ActivityCargoResult, createActivityLocationResult, ActivityLocationResult, ClaimType, LaytimeCalculationResult, LaytimeEventResult } from "./laytime-calculation-result";

type Claim = { type: ClaimType; value?: number };

export class LaytimeCalculator {
    static calculate(sector: Sector, terms: LaytimeCalculationTerms, locations: ReadonlyArray<ActivityLocation>): LaytimeCalculationResult {
        switch (terms.timeAllowance) {
            case "Fixed":
                return calculateFixed(sector, terms, locations);
            case "Non Fixed":
                return calculateNonFixed(sector, terms, locations);
            default:
                throw Error(`Unknown time allowance '${terms.timeAllowance}'`);
        }
    }
}

const calculateFixed = (sector: Sector, terms: LaytimeCalculationTerms, locations: ReadonlyArray<ActivityLocation>): LaytimeCalculationResult => {
    const timeAllowed = hasValue(terms.fixedAllowanceHours) ? Duration.fromObject({ hours: terms.fixedAllowanceHours }) : null;

    const locationResults = new Array<ActivityLocationResult>();

    let timeUsed = zeroDuration();
    let timeExcluded = zeroDuration();
    let demurrageActivityLocationId = null;
    let demurrageLaytimeEventId = null;

    for (const location of locations) {
        // Fixed does not have the concept of non reversible vs reversible, but we can reuse the time counted logic
        // by ensuring there are no cargoes
        const locationTimeCounted = getTimeCounted({ ...location, cargoes: [] });
        let locationTimeUsed: Duration;

        // Only calculate if we could calculate the time used for every laytime event
        if (locationTimeCounted.laytimeEvents.every((x) => !!x.timeUsed)) {
            const timeUsedBeforeLocation = timeUsed;

            // Fixed applies rounding at location level (non fixed reversible is at calc level)
            locationTimeUsed = roundDuration(
                location.activity === "Transit" ? locationTimeCounted.reversibleTimeUsed : locationTimeCounted.nonReversibleTimeUsed,
                terms.durationUnit,
                terms.rounding
            );
            timeUsed = timeUsed.plus(locationTimeUsed);
            timeExcluded = timeExcluded.plus(location.activity === "Transit" ? locationTimeCounted.reversibleTimeExcluded : locationTimeCounted.nonReversibleTimeExcluded);

            if (!demurrageActivityLocationId && timeAllowed && +timeUsed > +timeAllowed) {
                demurrageActivityLocationId = location.id;
                demurrageLaytimeEventId = findDemurrageLaytimeEventId(locationTimeCounted.laytimeEvents, timeUsedBeforeLocation, timeAllowed);
            }
        }

        const laytimeEvents = toLaytimeEventResults(locationTimeCounted.laytimeEvents);

        locationResults.push(
            createActivityLocationResult(location.id, null, laytimeEvents, {
                laytimeUsed: roundToMinutes(locationTimeUsed)
            })
        );
    }

    const claim = timeAllowed ? calculateClaim(sector, terms, false, timeAllowed, timeUsed, timeExcluded) : null;

    return {
        claimType: claim?.type ?? null,
        claimValue: claim?.value ?? null,
        laytimeAllowed: roundToMinutes(timeAllowed),
        laytimeUsed: roundToMinutes(timeUsed),
        laytimeRemaining: hasValue(timeUsed) && hasValue(timeAllowed) ? roundToMinutes(timeAllowed.minus(timeUsed)) : null,
        activityLocations: locationResults,
        demurrageActivityLocationId,
        demurrageLaytimeEventId
    };
};

const calculateNonFixed = (sector: Sector, terms: LaytimeCalculationTerms, locations: ReadonlyArray<ActivityLocation>): LaytimeCalculationResult => {
    const allowedLaytime = calculateAllowedLaytime(locations);
    const reversibleTimeAllowed = allowedLaytime.reversible;

    const locationResults = new Array<ActivityLocationResult>();

    let hasReversible = false;
    let reversibleTimeUsed = zeroDuration();
    let reversibleTimeExcluded = zeroDuration();
    let reversibleDemurrageActivityLocationId: ActivityLocationId;
    let reversibleDemurrageLaytimeEventId: LaytimeEventId;

    for (const location of locations) {
        const locationTimeAllowed = allowedLaytime.activityLocations[location.id];
        const locationTimeCounted = getTimeCounted(location);

        let nonReversibleTimeUsed: Duration;
        let demurrageLaytimeEventId: LaytimeEventId;
        let claim: Claim;

        // Only calculate if we could calculate the time used for every laytime event
        if (locationTimeCounted.laytimeEvents.every((x) => !!x.timeUsed)) {
            if (locationTimeCounted.hasReversible) {
                hasReversible = true;

                const reversibleTimeUsedBeforeLocation = reversibleTimeUsed;

                reversibleTimeUsed = reversibleTimeUsed.plus(locationTimeCounted.reversibleTimeUsed);
                reversibleTimeExcluded = reversibleTimeExcluded.plus(locationTimeCounted.reversibleTimeExcluded);

                if (!reversibleDemurrageActivityLocationId && +reversibleTimeUsed > +reversibleTimeAllowed) {
                    reversibleDemurrageActivityLocationId = location.id;
                    reversibleDemurrageLaytimeEventId = findReversibleDemurrageLaytimeEventId(
                        locationTimeCounted.laytimeEvents,
                        reversibleTimeUsedBeforeLocation,
                        reversibleTimeAllowed
                    );
                }
            }

            if (locationTimeCounted.hasNonReversible) {
                nonReversibleTimeUsed = roundDuration(locationTimeCounted.nonReversibleTimeUsed, terms.durationUnit, terms.rounding);

                // NOTE, we should calculate each claim against the cargo, but the current implementation does not do this, so retaining the existing
                // quick despatch check (which assumes only 1 non reversible cargo per berth or all customary quick despatch)
                const customaryQuickDespatch = location.customaryQuickDespatch === true;
                claim = nonReversibleTimeUsed
                    ? calculateClaim(sector, terms, customaryQuickDespatch, locationTimeAllowed.nonReversible, nonReversibleTimeUsed, locationTimeCounted.nonReversibleTimeExcluded)
                    : null;

                if (+nonReversibleTimeUsed > +locationTimeAllowed.nonReversible) {
                    demurrageLaytimeEventId = findDemurrageLaytimeEventId(locationTimeCounted.laytimeEvents, zeroDuration(), locationTimeAllowed.nonReversible);
                }
            }
        }

        const cargoes = locationTimeCounted.cargoes.map(
            (c) =>
                <ActivityCargoResult>{
                    id: c.id,
                    laytimeAllowed: roundToMinutes(locationTimeAllowed.cargoes[c.id]),
                    laytimeUsed: roundToMinutes(c.timeUsed)
                }
        );

        const laytimeEvents = toLaytimeEventResults(locationTimeCounted.laytimeEvents);

        // We use the exact time allowed / used values for calculating the claim, but round to minutes for the resulting value
        locationResults.push(
            createActivityLocationResult(location.id, cargoes, laytimeEvents, {
                claimValue: claim?.value ?? null,
                claimType: claim?.type ?? null,
                laytimeAllowed: roundToMinutes(locationTimeAllowed.nonReversible),
                laytimeUsed: roundToMinutes(nonReversibleTimeUsed),
                reversibleLaytimeAllowed: roundToMinutes(locationTimeAllowed.reversible),
                reversibleLaytimeUsed: roundToMinutes(locationTimeCounted.reversibleTimeUsed),
                demurrageLaytimeEventId
            })
        );
    }

    let reversibleClaim: Claim;

    if (hasReversible) {
        reversibleTimeUsed = roundDuration(reversibleTimeUsed, terms.durationUnit, terms.rounding);

        reversibleClaim = calculateClaim(sector, terms, false, reversibleTimeAllowed, reversibleTimeUsed, reversibleTimeExcluded);
    }

    return {
        claimType: reversibleClaim?.type ?? null,
        claimValue: reversibleClaim?.value ?? null,
        laytimeAllowed: roundToMinutes(reversibleTimeAllowed),
        laytimeUsed: roundToMinutes(reversibleTimeUsed),
        laytimeRemaining: hasReversible ? roundToMinutes(reversibleTimeAllowed.minus(reversibleTimeUsed)) : null,
        activityLocations: locationResults,
        demurrageActivityLocationId: reversibleDemurrageActivityLocationId,
        demurrageLaytimeEventId: reversibleDemurrageLaytimeEventId
    };
};

const calculateClaimValue = (durationUnit: LaytimeCalculationDurationUnit, duration?: Duration, rate?: number): number | null => {
    if (!hasValue(rate)) {
        return null;
    }

    const claimValue = durationUnit === "Days" ? duration.as("days") * rate : duration.as("hours") * rate;

    return Math.round(claimValue * 100) / 100; // 2dp
};

const calculateDemurrageClaim = (terms: LaytimeCalculationTerms, customaryQuickDespatch: boolean, timeRemaining: Duration): { type: ClaimType; value?: number } | null => {
    if (customaryQuickDespatch) {
        return {
            type: "Detention",
            value: calculateClaimValue(terms.durationUnit, absDuration(timeRemaining), terms.detentionRate)
        };
    }

    const claimValue = calculateClaimValue(terms.durationUnit, timeRemaining, terms.demurrageRate);

    if (terms.demurrageBank === true && +timeRemaining > 0) {
        return { type: "Demurrage", value: claimValue * -1 };
    }

    if (+timeRemaining < 0) {
        return { type: "Demurrage", value: hasValue(claimValue) ? Math.abs(claimValue) : null };
    }

    return null;
};

const calculateClaim = (
    sector: Sector,
    terms: LaytimeCalculationTerms,
    customaryQuickDespatch: boolean,
    timeAllowed: Duration,
    timeUsed: Duration,
    timeExcluded: Duration
): Claim | null => {
    let claim: { type: ClaimType; value?: number } = null;

    if (+timeAllowed < +timeUsed || terms.demurrageBank === true) {
        claim = calculateDemurrageClaim(terms, customaryQuickDespatch, timeAllowed.minus(timeUsed));
    }

    if (sector !== "Dry Cargo" || +timeAllowed <= +timeUsed) {
        return claim;
    }

    const isWorkingTimeSaved = terms.timeSaved === "Working Time Saved";
    let timeRemaining = timeAllowed.minus(timeUsed);
    timeRemaining = isWorkingTimeSaved ? timeRemaining.minus(timeExcluded) : timeRemaining;

    if (isWorkingTimeSaved && +timeRemaining < 0) {
        claim = calculateDemurrageClaim(terms, customaryQuickDespatch, timeRemaining);
    } else {
        claim = { type: "Despatch", value: calculateClaimValue(terms.durationUnit, timeRemaining, terms.despatchRate) };
    }

    return claim;
};

const findDemurrageLaytimeEventId = (laytimeEvents: ReadonlyArray<LaytimeEventTimeCounted>, offset: Duration, timeAllowed: Duration) =>
    laytimeEvents.find((x) => +offset.plus(x.cumulativeNonReversibleTimeUsed) > +timeAllowed)?.id;
const findReversibleDemurrageLaytimeEventId = (laytimeEvents: ReadonlyArray<LaytimeEventTimeCounted>, offset: Duration, timeAllowed: Duration) =>
    laytimeEvents.find((x) => +offset.plus(x.cumulativeReversibleTimeUsed) > +timeAllowed)?.id;

const toLaytimeEventResults = (laytimeEvents: ReadonlyArray<LaytimeEventTimeCounted>) =>
    laytimeEvents.map(
        (c) =>
            <LaytimeEventResult>{
                id: c.id,
                laytimeUsed: roundToMinutes(c.timeUsed),
                laytimeAllowed: roundToMinutes(c.timeAllowed),
                offsetDiffWithPrevEvent: c.offsetDiffWithPrevEvent
            }
    );
