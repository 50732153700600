import * as moment from "moment";
import { Voyage } from "src/app/fixture/shared/models";
import { Email } from "src/app/shared/email";
import { Company } from "../../../left-bar/contacts/models/company.model";
import { CompanyType, Fixture } from "../models";

export interface EmailGenerator<TOptions = {}> {
    generate(fixture: Fixture, voyages: readonly Voyage[], companies: readonly Company[], extras?: TOptions): Email;
}

export abstract class AbstractEmailGenerator<TOptions = {}> implements EmailGenerator<TOptions> {
    readonly DATE_FORMAT = "DD MMM YY";
    readonly DATE_TIME_FORMAT = "DD MMM YY, HH:mm";

    abstract generate(fixture: Fixture, voyages: readonly Voyage[], companies: readonly Company[], extras?: TOptions): Email;

    protected generateSubject(fixture: Fixture, voyage: Voyage, companies: readonly Company[]) {
        const isCoaLifting = !!fixture.coANumber;
        const vesselName = this.getVesselName(fixture);
        const chartererName = this.getChartererName(companies);
        const formattedCpDate = this.getFormattedCPDate(fixture);
        const cpDatePrefix = `${chartererName ? "- " : ""}${isCoaLifting ? "Lifting -" : "CP Date"}`;

        let locations = voyage.destinations
            .filter((destination) => destination.location)
            .map((destination) => destination.location.displayName)
            .join(" / ");

        if (locations) {
            locations = `- ${locations}`;
        }

        return `${vesselName} / ${chartererName} ${cpDatePrefix} ${formattedCpDate} ${locations}`;
    }

    protected generateBodySubject(fixture: Fixture, voyage: Voyage, companies: readonly Company[]) {
        const vesselName = this.getVesselName(fixture);
        const chartererName = this.getChartererName(companies);
        const formattedCpDate = this.getFormattedCPDate(fixture);

        return `${vesselName} / ${chartererName} - CP ${formattedCpDate}`;
    }

    private getVesselName(fixture: Fixture) {
        if (fixture.isVesselTbn) {
            return "TBN";
        }

        if (fixture.vessel && fixture.vessel.name) {
            return fixture.vessel.name.toUpperCase();
        }

        return "";
    }

    private getChartererName(companies: readonly Company[]) {
        const charterer = companies ? companies.find((company) => company.typeId === CompanyType.Charterer) : null;

        return (charterer ? charterer.name : null) || "TBN";
    }

    private getFormattedCPDate(fixture: Fixture) {
        if (fixture.charterParty) {
            const cpDate = moment(fixture.charterParty.charterPartyDate);

            if (cpDate.isValid()) {
                return cpDate.utc().format(this.DATE_FORMAT);
            }
        }

        return "";
    }
}
