import { Duration, DurationOptions, DurationUnit } from "luxon";
import * as R from "ramda";

export declare type CustomDurationUnits = "years" | "months" | "weeks" | "days" | "hours" | "minutes" | "seconds" | "milliseconds";
// Years and months have inconsistent number of days, hours etc. Please do not include them in safeLengthOfTimeUnits array
export const safeDurationUnits: ReadonlyArray<CustomDurationUnits> = ["weeks", "days", "hours", "minutes", "seconds", "milliseconds"];
export const defaultDurationUnits: ReadonlyArray<CustomDurationUnits> = ["years", "months", ...safeDurationUnits];

/**
 * Returns a zero `Duration` object with days/hours/minutes units.
 */
export const zeroDuration = () => Duration.fromObject({ days: 0, hours: 0, minutes: 0 });

export const sumDuration = R.curry((prev: Duration, curr: Duration) => prev.plus(curr));

export const roundToMinutes = (duration: Duration): Duration =>
    duration ? Duration.fromObject({ days: 0, hours: 0, minutes: Math.round(duration.as("minutes")) }).normalize() : duration;

export const absDuration = (duration: Duration): Duration => {
    const durationUnits = <DurationUnit[]>(
        Object.keys(duration.toObject()).filter((x) => !(<DurationOptions[]>["locale", "numberingSystem", "conversionAccuracy"]).includes(x as unknown))
    );

    return Duration.fromMillis(Math.abs(+duration)).shiftTo(...durationUnits);
};

export const normalizeDuration = (duration: Duration, minDurationUnit: CustomDurationUnits = "milliseconds", maxDurationUnit: CustomDurationUnits = "weeks") => {
    duration ||= zeroDuration();
    const durationKeys = Object.keys(duration.toObject());

    const indexOfMinUnit = defaultDurationUnits.findIndex((unit) => unit === minDurationUnit);
    const indexOfMaxUnit = defaultDurationUnits.findIndex((unit) => unit === maxDurationUnit || durationKeys.includes(unit));
    const allowedUnits = defaultDurationUnits.filter((unit, i) => i >= indexOfMaxUnit && i <= indexOfMinUnit);

    const shiftedDuration = duration.shiftTo(...allowedUnits);
    const valuableUnits = allowedUnits.filter((unit) => shiftedDuration[unit]);

    return valuableUnits.length ? shiftedDuration.shiftTo(...valuableUnits) : shiftedDuration.shiftTo(minDurationUnit);
};

export const stringifyDuration = (duration: Duration) => {
    duration ||= zeroDuration();
    const normalizedDuration = absDuration(normalizeDuration(duration, "milliseconds", "years"));

    const string = defaultDurationUnits.reduce((acc: string, unit: CustomDurationUnits) => {
        const durationByUnit = normalizedDuration[unit];
        if (durationByUnit) {
            return (acc += durationByUnit === 1 ? `${durationByUnit} ${unit.slice(0, -1)}, ` : `${durationByUnit} ${unit}, `);
        }
        return acc;
    }, "");

    return string.slice(0, -2) || "0 seconds";
};
