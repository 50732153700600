import { createSelector } from "@ngrx/store";
import { FormArrayState, FormControlState, FormGroupState } from "ngrx-forms";
import * as R from "ramda";

import { DayOfWeek, formatNumber, isNullOrUndefined } from "@ops/shared";

import {
    VoyageActivityFilterForm,
    ActivityType,
    AddActivityLocationsFilter,
    AddActivityLocationsForm,
    AssociatedCargo,
    AssociatedCargoSelectionForm,
    CargoFilterForm,
    LtcFeatureState,
    Voyage,
    CargoId,
    VoyageActivityId,
    AssociatedCargoId,
    VoyageLaytimeEvent,
    Cargo,
    activityTypes,
    WorkingDay,
    Enumeration
} from "../../model";
import { selectCurrentLaytimeCalculationState } from "../selectors";
import { selectCurrentVoyage } from "../voyage";

export const selectCurrentAddActivityLocationsForm = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.addActivityLocationsForm);
export const selectCurrentAddActivityLocationsFilterState = createSelector(
    selectCurrentAddActivityLocationsForm,
    selectCurrentVoyage,
    (form, voyage) => form && voyage && toAddActivityLocationsFilterState(form, voyage)
);
export const selectCurrentAddActivityLocationsSelectionState = createSelector<
    LtcFeatureState,
    { cdnUrl: string },
    FormGroupState<AddActivityLocationsForm>,
    Voyage,
    AddActivityLocationsSelectionState
>(selectCurrentAddActivityLocationsForm, selectCurrentVoyage, (form, voyage, { cdnUrl }) => form && voyage && toAddActivityLocationsSelectionState(form, voyage, cdnUrl));

export const selectCurrentAddActivityLocationsImportPortTimes = createSelector(selectCurrentAddActivityLocationsForm, (form) => form?.controls.importPortTimes);
export const selectCurrentIsAddingActivityLocations = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.isAddingActivityLocations);
export const selectAddActivityLocationsEnabled = createSelector(selectCurrentAddActivityLocationsForm, (state) => state?.value && isAddActivityLocationsEnabled(state?.value));

export type CargoFilter = Readonly<{
    cargoId: CargoId;
    name: string;
    selected: FormControlState<boolean>;
    imported: boolean;
}>;

export type VoyageActivityFilter = Readonly<{
    voyageActivityId: VoyageActivityId;
    name: string;
    activity: ActivityType;
    selected: FormControlState<boolean>;
    imported: boolean;
}>;

export type AddActivityLocationsFilterState = Readonly<{
    filter: FormControlState<AddActivityLocationsFilter>;
    cargoes: ReadonlyArray<CargoFilter>;
    voyageActivities: ReadonlyArray<VoyageActivityFilter>;
    selectAll: boolean;
}>;

export type AssociatedCargoSelection = Readonly<{
    associatedCargoId?: AssociatedCargoId;
    cargoId?: CargoId;
    cargoName?: string;
    voyageActivityId?: VoyageActivityId;
    voyageActivityName?: string;
    flagImageUrl?: string;
    activity?: ActivityType;
    quantity?: string;
    selected?: FormControlState<boolean>;
    imported?: boolean;
}>;

export type AssociatedCargoesByCargo = Readonly<{
    cargoId: CargoId;
    cargoName: string;
    associatedCargoes: ReadonlyArray<AssociatedCargoSelection>;
    selectAll: boolean;
}>;

export type AssociatedCargoesByVoyageActivity = Readonly<{
    voyageActivityId: VoyageActivityId;
    voyageActivityName: string;
    flagImageUrl: string;
    activity: ActivityType;
    associatedCargoes: ReadonlyArray<AssociatedCargoSelection>;
    selectAll: boolean;
}>;

export type AddActivityLocationsSelectionState = Readonly<{
    filter: AddActivityLocationsFilter;
    associatedCargoesByCargoes: ReadonlyArray<AssociatedCargoesByCargo>;
    associatedCargoesByVoyageActivities: ReadonlyArray<AssociatedCargoesByVoyageActivity>;
    selectAll: boolean;
}>;

const getCargoName = (cargo: Cargo) => (cargo.orderId ? `${cargo.cargoProduct.name} - ${cargo.orderId}` : cargo.cargoProduct.name);

export const toCargoFilters = (formArray: FormArrayState<CargoFilterForm>, voyage: Voyage): ReadonlyArray<CargoFilter> =>
    voyage.cargoes
        .filter((x) => x.cargoProduct)
        .map((x) => {
            const form = formArray.controls.find((c) => c.value.cargoId === x.id);
            return {
                cargoId: x.id as CargoId,
                name: getCargoName(x),
                selected: form.controls.selected,
                imported: form.value.imported
            };
        });

export type VoyageActivity = Readonly<{
    voyageActivityId: VoyageActivityId;
    name: string;
    countryUnCode: string;
    locationId: string;
    timeZone: string;
    activity: ActivityType;
    workingDay?: WorkingDay;
    exclusionStartDay?: DayOfWeek;
    exclusionStartTime?: string;
    exclusionEndDay?: DayOfWeek;
    exclusionEndTime?: string;
    laytimeEvents: ReadonlyArray<VoyageLaytimeEvent>;
    associatedCargoes: ReadonlyArray<AssociatedCargo>;
}>;

export const mapWorkingDay = (workingDayType?: Enumeration, unlessUsed?: boolean): WorkingDay => {
    switch (workingDayType?.name) {
        case "SHINC":
            return "SHINC";
        case "SHEX":
            return unlessUsed ? "SHEX UU" : "SHEX";
        default:
            return undefined;
    }
};

export const toVoyageActivities = (voyage: Voyage): ReadonlyArray<VoyageActivity> => {
    const voyageActivities: VoyageActivity[] = [];
    voyage.destinations
        .filter((x) => x.location)
        .forEach((destination) => {
            const generateBerthName = destination.berths.filter((x) => !x.name).length > 1;

            destination.berths.forEach((berth, index) => {
                const berthName = generateBerthName ? `Berth ${index + 1}` : berth.name;

                berth.cargoBerthActivities.forEach((cargoBerthActivity) => {
                    const activityType: ActivityType = cargoBerthActivity.type.name === "Transit / Interim" ? "Transit" : (cargoBerthActivity.type.name as ActivityType);
                    if (activityTypes.includes(activityType)) {
                        voyageActivities.push({
                            voyageActivityId: cargoBerthActivity.id,
                            name: `${destination.location.displayName} (${destination.location.countryName})${berthName ? " : " + berthName : ""}`,
                            countryUnCode: destination.location.countryUnCode,
                            locationId: destination.location.locationId,
                            timeZone: destination.location.timeZone,
                            activity: activityType,
                            workingDay: mapWorkingDay(destination.workingDayType, destination.unlessUsed),
                            exclusionStartDay: destination.excludingFromDay?.name as DayOfWeek,
                            exclusionStartTime: destination.excludingFromTime,
                            exclusionEndDay: destination.excludingToDay?.name as DayOfWeek,
                            exclusionEndTime: destination.excludingToTime,
                            laytimeEvents: cargoBerthActivity.laytimeEvents,
                            associatedCargoes: cargoBerthActivity.associatedCargoes
                        });
                    }
                });
            });
        });
    return voyageActivities;
};

export const toVoyageActivityFilters = (formArray: FormArrayState<VoyageActivityFilterForm>, voyage: Voyage): ReadonlyArray<VoyageActivityFilter> =>
    toVoyageActivities(voyage).map((x) => {
        const form = formArray.controls.find((c) => c.value.voyageActivityId === x.voyageActivityId);
        return {
            voyageActivityId: x.voyageActivityId,
            name: x.name,
            activity: x.activity,
            selected: form.controls.selected,
            imported: form.value.imported
        };
    });

const getFlagImageUrl = (cdnUrl: string, countryUnCode: string) => (countryUnCode ? `${cdnUrl}/Data/PublicArtificats/flags/ALPHA2/32/${countryUnCode}.png` : null);

export const toAssociatedCargoSelections = (formArray: FormArrayState<AssociatedCargoSelectionForm>, voyage: Voyage, cdnUrl: string): ReadonlyArray<AssociatedCargoSelection> => {
    const associatedCargoes: AssociatedCargoSelection[] = [];
    const cargoes = voyage.cargoes.filter((x) => x.cargoProduct);

    toVoyageActivities(voyage).forEach((voyageActivity) => {
        let associatedCargoesForActivity = false;

        voyageActivity.associatedCargoes.forEach((associatedCargo) => {
            const cargo = cargoes.find((x) => x.id === associatedCargo.cargoId);
            if (cargo) {
                const form = formArray.controls.find((x) => x.value.associatedCargoId === associatedCargo.id);

                associatedCargoes.push({
                    voyageActivityId: voyageActivity.voyageActivityId,
                    cargoId: associatedCargo.cargoId as CargoId,
                    cargoName: getCargoName(cargo),
                    voyageActivityName: voyageActivity.name,
                    flagImageUrl: getFlagImageUrl(cdnUrl, voyageActivity.countryUnCode),
                    activity: voyageActivity.activity,
                    associatedCargoId: associatedCargo.id,
                    quantity: !isNullOrUndefined(associatedCargo.mt)
                        ? `${formatNumber(associatedCargo.mt, false, 0, 3)} MT`
                        : !isNullOrUndefined(cargo.quantity)
                        ? `${formatNumber(cargo.quantity, false, 0, 3)} ${cargo.quantityUnit.name}`
                        : "",
                    selected: form.controls.selected,
                    imported: form.value.imported
                });

                associatedCargoesForActivity = true;
            }
        });

        if (!associatedCargoesForActivity) {
            associatedCargoes.push({
                voyageActivityId: voyageActivity.voyageActivityId,
                voyageActivityName: voyageActivity.name,
                flagImageUrl: getFlagImageUrl(cdnUrl, voyageActivity.countryUnCode),
                activity: voyageActivity.activity
            });
        }
    });

    const cargoesWithNoAssociatedCargoes = R.pipe(
        R.differenceWith((c, ac: AssociatedCargoSelection) => c.id === ac.cargoId, cargoes),
        R.map((x) => ({ cargoName: getCargoName(x), cargoId: x.id as CargoId }))
    )(associatedCargoes);

    return associatedCargoes.concat(cargoesWithNoAssociatedCargoes);
};

export const toAddActivityLocationsFilterState = (form: FormGroupState<AddActivityLocationsForm>, voyage: Voyage): AddActivityLocationsFilterState => {
    const items =
        form.value.filter === "Locations"
            ? toVoyageActivityFilters(form.controls.voyageActivities, voyage).map((x) => ({ selected: x.selected.value, imported: x.imported }))
            : [...form.value.cargoes];

    return {
        filter: form.controls.filter as FormControlState<AddActivityLocationsFilter>,
        cargoes: toCargoFilters(form.controls.cargoes, voyage),
        voyageActivities: toVoyageActivityFilters(form.controls.voyageActivities, voyage),
        selectAll: getSelectAllState(items)
    };
};

export const toAddActivityLocationsSelectionState = (form: FormGroupState<AddActivityLocationsForm>, voyage: Voyage, cdnUrl: string): AddActivityLocationsSelectionState => {
    const associatedCargoes = toAssociatedCargoSelections(form.controls.associatedCargoes, voyage, cdnUrl);
    const filteredCargoIds = form.value.cargoes.filter((x) => x.selected).map((x) => x.cargoId);
    const filteredVoyageActivityIds = form.value.voyageActivities.filter((x) => x.selected).map((x) => x.voyageActivityId);
    const filteredAssociatedCargoes = form.value.associatedCargoes.filter((x) =>
        form.value.filter === "Locations" ? filteredVoyageActivityIds.includes(x.voyageActivityId) : filteredCargoIds.includes(x.cargoId)
    );

    const associatedCargoesByCargoes = R.pipe(
        R.groupBy((x: AssociatedCargoSelection) => x.cargoName),
        R.values,
        R.map((values) => ({
            cargoId: values[0].cargoId,
            cargoName: values[0].cargoName,
            associatedCargoes: values[0].associatedCargoId ? values : null,
            selectAll: getSelectAllState(values.map((x) => ({ selected: x.selected?.value, imported: x.imported })))
        })),
        R.filter((x: AssociatedCargoesByCargo) => filteredCargoIds.includes(x.cargoId))
    )(associatedCargoes);

    const associatedCargoesByVoyageActivities = R.pipe(
        R.groupBy((x: AssociatedCargoSelection) => x.voyageActivityId),
        R.values,
        R.map((values) => ({
            voyageActivityName: values[0].voyageActivityName,
            voyageActivityId: values[0].voyageActivityId,
            flagImageUrl: values[0].flagImageUrl,
            activity: values[0].activity,
            associatedCargoes: values[0].associatedCargoId ? values : null,
            selectAll: getSelectAllState(values.map((x) => ({ selected: x.selected?.value, imported: x.imported })))
        })),
        R.filter((x: AssociatedCargoesByVoyageActivity) => filteredVoyageActivityIds.includes(x.voyageActivityId))
    )(associatedCargoes);

    return {
        filter: form.value.filter,
        associatedCargoesByCargoes,
        associatedCargoesByVoyageActivities,
        selectAll: getSelectAllState(filteredAssociatedCargoes)
    };
};

export const getSelectAllState = (items: ReadonlyArray<{ selected: boolean; imported: boolean }>) => {
    const applicableItems = items.filter((x) => !x.imported);
    const selected = applicableItems.filter((x) => x.selected).length;

    return selected === 0 ? null : selected === applicableItems.length;
};

export const isAddActivityLocationsEnabled = (form: AddActivityLocationsForm) =>
    (form.filter === "Locations" && form.voyageActivities.some((x) => x.selected)) || form.associatedCargoes.some((x) => x.selected);

export const isSelectAllEnabled = (associatedCargoes: ReadonlyArray<AssociatedCargoSelection>) => associatedCargoes?.filter((x) => !x.imported).length > 1;
