import { Injectable } from "@angular/core";

import { Fixture, Voyage } from "../../../models";
import { QuantityUnit } from "../../../models/enums/quantity-unit";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class ExternalVisibilityCargoQuantityUnitRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        voyages.forEach((voyage) =>
            voyage.cargoes.forEach((cargo, cargoIndex) => {
                if (cargo.quantityUnit && cargo.quantityUnit.id === QuantityUnit.LumpSum) {
                    const message = "Estimated Quantity Unit";
                    const path = [
                        ...this.getVoyagePathSegment(fixture.fixtureType, voyage),
                        new FixtureWarningPathSegment("cargoes", "Cargoes"),
                        FixtureWarningPathSegment.fromIndex(cargoIndex, "Cargo", cargo.cargoProduct ? cargo.cargoProduct.name : null),
                        new FixtureWarningPathSegment("quantityUnit", "Quantity Unit")
                    ];
                    const warningHash = new WarningHashBuilder().withVoyage(voyage.voyageId).withCargo(cargo.cargoId).withCategory("data").withMessage(message).build();
                    warnings.push(new FixtureWarning("data", message, path, dismissedWarningRecord(warningHash)));
                }
            })
        );

        return warnings;
    }
}
