<div class="card embedded-card-1">
    <div class="card-header row no-gutters align-items-center">
        <span class="title">Voyage Bunker Consumption</span>
    </div>
    <div class="card-body">
        <div [formGroup]="parentForm">
            <div *ngIf="destinations && destinations.length > 0; else noDestinations">
                <div *ngFor="let formRow of form.controls; let i = index" class="location-block">
                    <div class="row location-row" *ngIf="!isSimpleMode">
                        <div class="col">
                            <ops-voyage-bunker-consumption-location
                                [destination]="destinations[i]"
                                [parentForm]="formRow"
                                [accordion]="accordions.get(destinations[i].id)"
                                (voyageBunkerConsumptionLocationUpdated)="handleUpdateCommand($event)"
                                [cpSpeedAndConsumption]="cpSpeedAndConsumption"
                            >
                            </ops-voyage-bunker-consumption-location>
                        </div>
                    </div>
                    <div class="row location-row" *ngIf="isSimpleMode || i + 1 !== form.controls.length">
                        <div class="col" *ngIf="getAtSeaConsumption(i) as atSea">
                            <ops-at-sea-consumption
                                [readonly]="readonly"
                                [isSimpleMode]="isSimpleMode"
                                [parentForm]="formRow"
                                [atSea]="atSea"
                                (atSeaUpdated)="handleUpdateCommand($event)"
                                [cpSpeedAndConsumption]="cpSpeedAndConsumption"
                            ></ops-at-sea-consumption>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #noDestinations>
    <ops-no-data-panel heading="Voyage Bunker Consumption" title="No Locations have been added"></ops-no-data-panel>
</ng-template>
