import { Duration } from "luxon";
import { box, Boxed, createFormGroupState, unbox } from "ngrx-forms";
import { v4 as uuid } from "uuid";

import { DayOfWeek, Identity } from "@ops/shared";

import { DateRange } from "../../../../fixture/shared/models";
import { SeaNetLocationId } from "../../../../fixture/shared/models/common/sea-net-location";
import { ClaimType } from "../../../calculator";
import { ActivityCargo } from "./activity-cargo";
import { ActivityType, WorkingDay } from "./laytime-calculation";
import { LaytimeEvent } from "./laytime-event";
import { getDiff } from "./utils";
import { VoyageActivityId } from "./voyage";

export type ActivityLocationId = Identity<string, "ActivityLocationId">;
export const createActivityLocationId = (): ActivityLocationId => uuid() as ActivityLocationId;

export type ActivityLocation = Readonly<{
    id: ActivityLocationId;
    locationId: SeaNetLocationId;
    voyageActivityId: VoyageActivityId;
    activity: ActivityType;
    claimValue?: string;
    laytimeAllowed?: string;
    laytimeUsed?: string;
    laytimeRemaining?: string;
    name?: string;
    countryUnCode?: string;
    countryName?: string;
    timeZone?: string;
    workingDay?: WorkingDay;
    exclusionStartDay?: DayOfWeek;
    exclusionStartTime?: string;
    exclusionEndDay?: DayOfWeek;
    exclusionEndTime?: string;
    laytimeRange?: string;
    customaryQuickDespatch?: boolean;
    cargoes: ReadonlyArray<ActivityCargo>;
    laytimeEvents: ReadonlyArray<LaytimeEvent>;
}>;
export const createActivityLocation = (
    id: string,
    locationId: SeaNetLocationId,
    voyageActivityId: VoyageActivityId,
    activity: ActivityType,
    cargoes?: ReadonlyArray<ActivityCargo>,
    laytimeEvents?: ReadonlyArray<LaytimeEvent>,
    values?: Partial<Omit<ActivityLocation, "id" | "cargoes" | "laytimeEvents">>
): ActivityLocation => ({
    ...values,
    id: id as ActivityLocationId,
    locationId,
    voyageActivityId,
    activity,
    cargoes: cargoes ?? [],
    laytimeEvents: laytimeEvents ?? []
});

export type ActivityLocationSummary = Readonly<{
    claimType?: ClaimType;
    claimValue?: number;
    laytimeAllowed?: Duration;
    laytimeUsed?: Duration;
    laytimeRemaining?: Duration;
    fixedOrReversibleClaimType?: ClaimType;
    fixedOrReversibleClaimValue?: number;
    fixedOrReversibleLaytimeAllowed?: Duration;
    fixedOrReversibleLaytimeUsed?: Duration;
    fixedOrReversibleLaytimeRemaining?: Duration;
}>;

export type ActivityLocationForm = Readonly<{
    workingDay: WorkingDay;
    exclusionStartDay?: DayOfWeek;
    exclusionStartTime?: string;
    exclusionEndDay?: DayOfWeek;
    exclusionEndTime?: string;
    laytimeRange?: Boxed<DateRange>;
    customaryQuickDespatch?: boolean;
}>;

export const toActivityLocationForm = (source: ActivityLocation): ActivityLocationForm => {
    const laytimeRange = source.laytimeRange?.split("/") || [null, null];
    const laytimeDateRange = (laytimeRange[0] || laytimeRange[1]) && { from: laytimeRange[0], to: laytimeRange[1] };

    return {
        workingDay: source.workingDay,
        exclusionStartDay: source.exclusionStartDay,
        exclusionStartTime: source.exclusionStartTime,
        exclusionEndDay: source.exclusionEndDay,
        exclusionEndTime: source.exclusionEndTime,
        laytimeRange: box(laytimeDateRange),
        customaryQuickDespatch: source.customaryQuickDespatch
    };
};

export const toActivityLocationDiff = (source: ActivityLocationForm, original: Partial<ActivityLocation>): Partial<ActivityLocation> => {
    const laytimeDateRange = unbox(source.laytimeRange);
    const laytimeRange = laytimeDateRange && `${laytimeDateRange.from}/${laytimeDateRange.to}`;

    const newChanges: Partial<ActivityLocation> = {
        workingDay: source.workingDay,
        exclusionStartDay: source.exclusionStartDay,
        exclusionStartTime: source.exclusionStartTime,
        exclusionEndDay: source.exclusionEndDay,
        exclusionEndTime: source.exclusionEndTime,
        customaryQuickDespatch: source.customaryQuickDespatch,
        laytimeRange
    };

    return getDiff(original, newChanges);
};

export const activityLocationFormKey = "activityLocationForm";
export const createActivityLocationFormState = (activityLocation: ActivityLocation) =>
    createFormGroupState(`${activityLocation.id}.${activityLocationFormKey}`, toActivityLocationForm(activityLocation));
