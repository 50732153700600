import { createAction, on, On, props } from "@ngrx/store";
import { removeArrayControl, updateGroup } from "ngrx-forms";
import * as R from "ramda";

import { updateActivityForm } from "../../activities/shared";
import { ActivityId, associatedCargoExpandedKey, BerthId, DestinationId, FixturesState, VoyageExpandedSections } from "../../model";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
const REMOVE_ASSOCIATED_CARGO = "[Voyage Form] Remove Associated Cargo";

export const removeAssociatedCargoAction = createAction(
    REMOVE_ASSOCIATED_CARGO,
    props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId; index: number }>()
);

/* REDUCER */
export const removeAssociatedCargoReducer: On<FixturesState> = on(removeAssociatedCargoAction, (state, { destinationId, berthId, activityId, index }) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const destination = voyageState.form.controls.destinations.controls.find((d) => d.value.id === destinationId);
        const berth = destination && destination.controls.berths.controls.find((b) => b.value.id === berthId);
        const activity = berth && berth.controls.activities.controls.find((a) => a.value.activityId === activityId);
        const associatedCargo = activity && activity.value.associatedCargoes[index];

        if (!associatedCargo) {
            return voyageState;
        }

        return {
            ...voyageState,
            form: updateActivityForm({ destinationId, berthId, activityId }, (activityForm) =>
                updateGroup(activityForm, {
                    associatedCargoes: removeArrayControl(index)
                })
            )(voyageState.form),
            expandedSections: <VoyageExpandedSections>(
                R.omit([associatedCargoExpandedKey(destinationId, berthId, activityId, associatedCargo.associatedCargoId)], voyageState.expandedSections)
            )
        };
    })
);
