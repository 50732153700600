import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, ViewChild } from "@angular/core";
import { ActionsSubject } from "@ngrx/store";
import { FormControlState, SetValueAction } from "ngrx-forms";
import { fromEvent, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "ops-textarea-editor",
    templateUrl: "./textarea-editor.component.html",
    styleUrls: ["./textarea-editor.component.scss"]
})
export class TextareaEditorComponent implements AfterViewInit, OnDestroy {
    private readonly destroy$ = new Subject();

    @Input() maxLength: number;
    @Input() state: FormControlState<string>;

    @ViewChild("textareaEl") textareaEl: ElementRef<HTMLTextAreaElement>;
    @ViewChild("textareaWrapperEl") textareaWrapperEl: ElementRef<HTMLDivElement>;

    constructor(public el: ElementRef, @Inject(ActionsSubject) private actionsSubject: ActionsSubject | null) {}

    ngAfterViewInit() {
        fromEvent(this.el.nativeElement, "mouseover")
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.resizeY());

        fromEvent(this.textareaEl.nativeElement, "input")
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.resizeY());
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    blur(e: Event) {
        const value = (e.target as HTMLTextAreaElement).value;
        this.actionsSubject.next(new SetValueAction(this.state.id, value));
    }

    private resizeY() {
        const maxHeight = this.getMaxHeight();
        this.textareaEl.nativeElement.style.height = "0px";
        const height = this.textareaEl.nativeElement.scrollHeight;
        this.textareaEl.nativeElement.style.overflowY = height > maxHeight ? "scroll" : "hidden";
        this.setInputHeight(height, maxHeight);
    }

    private getMaxHeight() {
        const textareaElRectTop = this.textareaEl.nativeElement.getBoundingClientRect().top;
        return this.getMaxBottom(this.textareaEl.nativeElement) - textareaElRectTop;
    }

    private getMaxBottom(el: HTMLElement) {
        let result = 0;
        for (let currentEl = el; !!currentEl; currentEl = currentEl.parentElement) {
            if (currentEl.nodeName === "DIV") {
                const bottom = this.getBottom(currentEl as HTMLDivElement);
                if (bottom > result) {
                    result = bottom;
                }
            }
        }

        return result;
    }

    private getBottom(el: HTMLDivElement) {
        return el.getBoundingClientRect().top + el.scrollHeight - el.scrollTop;
    }

    private setInputHeight(height: number, maxHeight: number) {
        this.textareaEl.nativeElement.style.maxHeight = maxHeight + "px";
        this.textareaEl.nativeElement.style.height = height + "px";
    }
}
