import { removeCoaGridRequestReducer } from "./remove-request";
import { searchCoaGridFailReducer, searchCoaGridReducer, searchCoaGridSuccessReducer } from "./search";
import { updateCoaGridColumnsReducer } from "./update-columns";
import { updateCoaGridSearchTokensReducer } from "./update-search-tokens";
import { updateCoaGridSortingReducer } from "./update-sorting";
import { updateCoaGridSuggestionTermFailReducer, updateCoaGridSuggestionTermReducer, updateCoaGridSuggestionTermSuccessReducer } from "./update-suggestion-term";

export default [
    updateCoaGridSuggestionTermReducer,
    updateCoaGridSuggestionTermSuccessReducer,
    updateCoaGridSuggestionTermFailReducer,
    updateCoaGridSearchTokensReducer,
    updateCoaGridSortingReducer,
    updateCoaGridColumnsReducer,
    searchCoaGridReducer,
    searchCoaGridSuccessReducer,
    searchCoaGridFailReducer,
    removeCoaGridRequestReducer
];
