import { Action } from "@ngrx/store";
import {
    AbstractControlState,
    addArrayControl,
    ALL_NGRX_FORMS_ACTION_TYPES,
    Boxed,
    focus,
    FormArrayState,
    FormGroupControls,
    FormGroupState,
    FormState,
    formStateReducer,
    InferenceWrapper,
    InferredFormState,
    isArrayState,
    isGroupState,
    setUserDefinedProperty,
    updateArray,
    updateArrayWithFilter,
    updateGroup,
    updateRecursive
} from "ngrx-forms";

import { StateObjectMap } from "./model";
import { ObjectReducer } from "./utils";
import { isNullOrUndefined } from "../shared/utils";

// TODO: (NGRX JC) Tests
declare type FormReducer<TValue> = (state: FormState<TValue> | AbstractControlState<TValue>) => FormState<TValue>;

type ByKeyReducer<TState, TKey extends keyof TState> = (state: TState, key: TKey) => TState[TKey];

export type ObjectUpdateFns<TState> = {
    [Key in keyof Partial<TState>]: (state: TState, key: Key) => TState[Key]
};

export type ObjectMapUpdateFns<TState, TObject> = {
    [Key in keyof Partial<TObject>]: (formState: TObject[Key], object: TObject, state: TState) => TObject[Key]
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RecordWithForms = Record<string, AbstractControlState<any>> | Record<string, NonNullable<any>>;

const isFormState = <TValue>(state: FormState<TValue>) =>
    !!state && state.hasOwnProperty("id") && state.hasOwnProperty("value") && state.hasOwnProperty("errors");

const reduceFormState = <TState extends RecordWithForms, TValue>(state: TState, key: keyof TState, reducer: FormReducer<TValue>): TState => {
    const value = state[key];

    if (!isFormState(value)) {
        return state;
    }

    const reducedState = reducer(value);

    if (reducedState === (state[key])) {
        return state;
    }

    return { ...state, [key]: reducedState };
}

const reduceByKey = <TState extends object, TKey extends keyof TState>(
    state: TState,
    key: TKey,
    objectReducer: ByKeyReducer<TState, TKey>
): TState => {
    const propState = state[key];
    const redusedPropState = objectReducer(state, key);

    if (propState === redusedPropState) {
        return state;
    }

    return { ...state, [key]: redusedPropState };
}

const reduceFormStates = <TState extends RecordWithForms, TValue>(state: TState, reducer: FormReducer<TValue>): TState =>
    Object.keys(state).reduce((s, key) => reduceFormState(s, key as keyof TState, reducer), state);

const reduceObjectMapFormStates = <TState extends object, TObject>(state: TState, objectMapLocator: (state: TState) => StateObjectMap<TObject>, objectReducer: ObjectReducer<TObject>): TState => {
    const objectMap = objectMapLocator(state);
    const objectMapStateKey = Object.keys(state).find((key) => state[key as keyof TState] === objectMap);

    const byIdMap = objectMap.byId;
    let reducedByIdMap = byIdMap;

    for (const id of objectMap.allIds) {
        const objectState = objectMap.byId[id];
        const reducedObjectState = objectReducer(objectState);

        if (objectState === reducedObjectState) {
            continue;
        }

        reducedByIdMap = {
            ...reducedByIdMap,
            [id]: reducedObjectState
        };
    }

    if (byIdMap === reducedByIdMap) {
        return state;
    }

    return {
        ...state,
        [objectMapStateKey]: {
            ...objectMap,
            byId: reducedByIdMap
        }
    };
}

export const onObjectMapForms = <TState extends object, TObject extends RecordWithForms>(objectMapLocator: (state: TState) => StateObjectMap<TObject>) => ({
    reducer: (state: TState, action: Action) => {
        const formReducer: FormReducer<unknown> = (form) => formStateReducer(form, action);
        const objectReducer: ObjectReducer<TObject> = (objectState) => reduceFormStates(objectState, formReducer);

        return reduceObjectMapFormStates(state, objectMapLocator, objectReducer);
    },
    types: ALL_NGRX_FORMS_ACTION_TYPES
});

export const onObjectForms = ({
    reducer: <TState extends object>(state: TState, action: Action) => {
        const formReducer: FormReducer<unknown> = (form) => formStateReducer(form, action);
        const objectReducer: ObjectReducer<TState> = (objectState) => reduceFormStates(objectState, formReducer);

        return objectReducer(state);
    },
    types: ALL_NGRX_FORMS_ACTION_TYPES
});

export const wrapReducerWithObjectFormStatesUpdate = <TState extends object>(
    objectUpdateFns: ObjectUpdateFns<TState>
) => (state: TState) => {
    const objectReducer = (objectState: TState) =>
        (Object.keys(objectUpdateFns) as Array<keyof TState>).reduce(
            (s, key) =>
                reduceByKey(
                    s,
                    key,
                    objectUpdateFns[key]
                ),
            objectState
        );

    return objectReducer(state);
}

export const wrapReducerWithObjectMapFormStatesUpdate = <TState extends object, TObject extends RecordWithForms>(
    objectMapLocator: (state: TState) => StateObjectMap<TObject>,
    objectUpdateFns: ObjectMapUpdateFns<TState, TObject>
) => (state: TState) => {

    const objectReducer = (objectState: TObject) =>
        (Object.keys(objectUpdateFns) as Array<keyof TObject>).reduce(
            (s, key) =>
                reduceFormState(
                    s,
                    key,
                    (form) => objectUpdateFns[key]((form as TObject[keyof TObject]), s, state)
                ),
            objectState
        );

    return reduceObjectMapFormStates(state, objectMapLocator, objectReducer);
}

export const reduceObjectMap = <TState extends object, TObject>(
    state: TState,
    objectMapLocator: (state: TState) => StateObjectMap<TObject>,
    id: string,
    partialOrReducer: Partial<TObject> | ObjectReducer<TObject>
): TState => {
    const objectMap = objectMapLocator(state);
    const objectMapStateKey = Object.keys(state).find((key) => (state[key as keyof TState] as unknown) === objectMap);

    const objectState = objectMap.byId[id];

    let updatedObjectState = objectState;

    if (typeof partialOrReducer === "function") {
        updatedObjectState = partialOrReducer(objectState);
    } else if (objectState && Object.getOwnPropertyNames(partialOrReducer).some((prop) => (<never>objectState)[prop] !== (<never>partialOrReducer)[prop])) {
        updatedObjectState = { ...objectState, ...partialOrReducer };
    } else if (!objectState) {
        updatedObjectState = partialOrReducer as TObject;
    }

    if (objectState === updatedObjectState) {
        return state;
    }

    if (isNullOrUndefined(updatedObjectState)) {
        throw Error("reduceObjectMap: updated object state must not be null or undefined");
    }

    return {
        ...state,
        [objectMapStateKey]: {
            ...objectMap,
            allIds: objectMap.allIds.includes(id) ? objectMap.allIds : [...objectMap.allIds, id],
            byId: {
                ...objectMap.byId,
                [id]: updatedObjectState
            }
        }
    };
};

export const EDITING_ID_USER_PROPERTY = "editingId";
export const setEditingId = (id: string) => setUserDefinedProperty(EDITING_ID_USER_PROPERTY, id);
export const getEditingId = <TValue>(state: FormArrayState<TValue> | FormArrayState<Boxed<TValue>>) => state.userDefinedProperties[EDITING_ID_USER_PROPERTY];

export const TRANSIENT_USER_PROPERTY = "transient";
export const markAsTransient = (isTransient: boolean = true) => updateRecursive(setUserDefinedProperty(TRANSIENT_USER_PROPERTY, isTransient));
export const getIsTransient = <TValue>(state: AbstractControlState<TValue> | AbstractControlState<Boxed<TValue>>) => !!state.userDefinedProperties[TRANSIENT_USER_PROPERTY];

export const SCROLL_INTO_VIEW_USER_PROPERTY = "scrollIntoView";
export const setScrollIntoView = setUserDefinedProperty(SCROLL_INTO_VIEW_USER_PROPERTY, true);
export const getScrollIntoView = <TValue>(state: AbstractControlState<TValue> | AbstractControlState<Boxed<TValue>>): boolean | undefined =>
    state.userDefinedProperties[TRANSIENT_USER_PROPERTY];

declare type AddArrayControlOptions<TValue> = {
    markAsEditing?: boolean;
    markAsTransient?: boolean;
    scrollIntoView?: boolean;
    focusControlName?: keyof TValue;
};

/* eslint-disable @typescript-eslint/unified-signatures, prefer-arrow/prefer-arrow-functions */
export function opsAddArrayControl<TValue>(value: TValue, opts?: AddArrayControlOptions<TValue>): (state: FormArrayState<TValue>) => FormArrayState<TValue>;
export function opsAddArrayControl<TValue>(value: TValue, index: number, opts?: AddArrayControlOptions<TValue>): (state: FormArrayState<TValue>) => FormArrayState<TValue>;
export function opsAddArrayControl<TValue>(value: TValue, ...input: (number | AddArrayControlOptions<TValue>)[]): (state: FormArrayState<TValue>) => FormArrayState<TValue> {
    let index = typeof input[0] === "number" ? <number>input[0] : undefined;
    const opts = (typeof input[0] === "number" ? input[1] : input[0]) as AddArrayControlOptions<TValue>;

    return (state: FormArrayState<TValue>) => {
        let updatedState = addArrayControl(state, value, index);

        if (!opts) {
            return updatedState;
        }

        index = index >= 0 ? index : updatedState.controls.length - 1;

        if (opts.markAsEditing) {
            updatedState = setEditingId(updatedState.controls[index].id)(updatedState);
        }

        return updateArrayWithFilter<TValue>(
            updatedState,
            (_, i) => i === index,
            (itemState) => {
                let updatedItemState = itemState;

                if (opts.markAsTransient) {
                    updatedItemState = markAsTransient()(updatedItemState) as InferredFormState<InferenceWrapper<TValue>>;
                }

                if (opts.scrollIntoView) {
                    updatedItemState = setScrollIntoView(updatedItemState) as InferredFormState<InferenceWrapper<TValue>>;
                }

                if (opts.focusControlName) {
                    updatedItemState = updateGroup<TValue>(
                        updatedItemState as FormGroupState<TValue>,
                        {
                            [opts.focusControlName]: focus
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } as any
                    ) as InferredFormState<InferenceWrapper<TValue>>;
                }

                return updatedItemState;
            }
        );
    };
}

// TODO: (NGRX JC) Copied from NGRX to support sort, PR inbound...
export function updateIdRecursiveForGroup<TValue>(state: FormGroupState<TValue>, newId: string): FormGroupState<TValue> {
    const controls: FormGroupControls<TValue> = Object.keys(state.controls).reduce(
        (agg, key) =>
            Object.assign(agg, {
                [key]: updateIdRecursive<TValue[keyof TValue]>(state.controls[key as keyof TValue], `${newId}.${key}`)
            }),
        {} as FormGroupControls<TValue>
    );

    return {
        ...state,
        id: newId,
        controls
    };
}

export function updateIdRecursiveForArray<TValue>(state: FormArrayState<TValue>, newId: string): FormArrayState<TValue> {
    const controls = state.controls.map((c, i) => updateIdRecursive(c, `${newId}.${i}`));

    return {
        ...state,
        id: newId,
        controls
    };
}

export function updateIdRecursive<TValue>(state: FormState<TValue>, newId: string): FormState<TValue> {
    if (state.id === newId) {
        return state;
    }

    if (isGroupState<TValue>(state)) {
        return updateIdRecursiveForGroup<TValue>(state, newId) as FormState<TValue>;
    }

    if (isArrayState<TValue>(state)) {
        return updateIdRecursiveForArray<TValue>(state, newId) as any;
    }

    return {
        ...(state as any),
        id: newId
    };
}

export function sortArrayControls<TValue>(compareFn: (a: FormState<TValue>, b: FormState<TValue>) => number) {
    return (state: FormArrayState<TValue>): FormArrayState<TValue> => {
        let controls = state.controls.concat().sort(compareFn);

        controls = controls.map((c, i) => updateIdRecursive(c, `${state.id}.${i}`));

        let index = 0;

        // This is a bit of a hack, we should be using computeArrayState but it's not exported/easy to copy
        return updateArray<TValue>(state, () => {
            const r = controls[index];
            index++;
            return r;
        });

        // return computeArrayState(
        //     state.id,
        //     controls,
        //     state.value,
        //     state.errors,
        //     state.pendingValidations,
        //     state.userDefinedProperties,
        //     {
        //         wasOrShouldBeDirty: true,
        //         wasOrShouldBeEnabled: state.isEnabled,
        //         wasOrShouldBeTouched: state.isTouched,
        //         wasOrShouldBeSubmitted: state.isSubmitted,
        //     }
        // );
    };
}
