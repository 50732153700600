import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";

import { GridSearchComponent } from "../../../shared/grid";
import { WorksheetService } from "../../../state/worksheets/services/worksheet.service";
import { LaytimeCalculationSearchService } from "../../services";

@Component({
    selector: "ops-laytime-calculation-grid-search",
    templateUrl: "./laytime-calculation-grid-search.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationGridSearchComponent extends GridSearchComponent {
    constructor(searchService: LaytimeCalculationSearchService, worksheetService: WorksheetService, changeDetector: ChangeDetectorRef) {
        super(searchService, worksheetService, changeDetector);
    }
}
