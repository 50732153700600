import { Component, OnInit } from "@angular/core";
import { LogEvent } from "../log-event";
import { LoggerService } from "../logger.service";

@Component({
    templateUrl: "./page-not-found.component.html"
})
export class PageNotFoundComponent implements OnInit {
    static componentName = "PageNotFoundComponent";

    constructor(private logger: LoggerService) {}

    ngOnInit(): void {
        this.logger.log(LogEvent.Information, "Navigated to an empty page.");
    }
}
