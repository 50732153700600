import { Injectable } from "@angular/core";

import { MixpanelService } from "../../../../../../core/mixpanel-service";
import { dateToISOString, isRangeOverWeekend, isWithinOuterRange } from "../../../../../../shared/date-utils/date-utilities";
import { Enumeration, LaytimeEventType } from "../../../../../../shared/reference-data";
import { DemurrageReason } from "../../../../../../shared/reference-data/demurrage-reason";
import { WorkingDayType } from "../../../../../../shared/reference-data/workingday-type";
import { hasItems, isNullOrUndefined } from "../../../../../../shared/utils";
import { getDestination, getLaytimeEvent } from "../../../../../../shared/voyage-utils/voyage-utilities";
import { CommandHandler } from "../../../../../mediator/command-handlers/command-handler";
import { EstimatedClaimValueCalculator } from "../../../../../services/estimated-claim-value-calculator";
import { Destination, Voyage } from "../../../../../shared/models";
import { ActivityId, BerthId, DestinationId, LaytimeEventId } from "../../../../../state/model";
import { UpdateLaytimeEventFactsCommand } from "./update-laytime-event-facts.command";

@Injectable({
    providedIn: "root"
})
export class UpdateLaytimeEventFactsCommandHandler implements CommandHandler {
    private readonly raisedLaytimeEvents: string[];

    constructor(private estimatedClaimValueCalculator: EstimatedClaimValueCalculator, private mixpanelService: MixpanelService) {
        this.raisedLaytimeEvents = [];
    }

    handle(voyage: Voyage, command: UpdateLaytimeEventFactsCommand) {
        const destination = getDestination(voyage, command.destinationId);
        const laytimeEventToUpdate = getLaytimeEvent(voyage, command.destinationId, command.berthId, command.berthActivityId, command.laytimeEventFact.laytimeEventId);
        this.setPercentage(voyage, destination, command);
        laytimeEventToUpdate.comments = command.laytimeEventFact.comments;
        laytimeEventToUpdate.cargoId = command.laytimeEventFact.cargoId;
        laytimeEventToUpdate.demurrageReason = command.laytimeEventFact.demurrageReason;
        laytimeEventToUpdate.fromDate = dateToISOString(command.laytimeEventFact.fromDate);
        laytimeEventToUpdate.toDate = dateToISOString(command.laytimeEventFact.toDate);
        laytimeEventToUpdate.percentage = command.laytimeEventFact.percentage;
        laytimeEventToUpdate.type = command.laytimeEventFact.type;
        voyage.isDirty = true;

        if (!this.raisedLaytimeEvents.includes(command.laytimeEventFact.laytimeEventId)) {
            this.raisedLaytimeEvents.push(command.laytimeEventFact.laytimeEventId);
            this.mixpanelService.track("Ops: Laytime Events Edit (Old LTC)");
        }
    }

    private setPercentage(voyage: Voyage, destination: Destination, command: UpdateLaytimeEventFactsCommand): void {
        const isPercentageSettingRequired = command.laytimeEventFact.fromDate && command.laytimeEventFact.toDate && isNullOrUndefined(command.laytimeEventFact.percentage);

        if (isPercentageSettingRequired) {
            const percentage = this.setPercentageIfShex(destination, command);
            command.laytimeEventFact.percentage = this.setPercentageIfClaim(command, voyage) || percentage;
            const isWeekendOrHoliday =
                !command.laytimeEventFact.demurrageReason && command.laytimeEventFact.percentage === 0 && !this.isExceptionalEvent(command.laytimeEventFact.type);
            command.laytimeEventFact.demurrageReason = isWeekendOrHoliday ? DemurrageReason.WeekendHoliday : command.laytimeEventFact.demurrageReason;
        }
    }

    private setPercentageIfClaim(command: UpdateLaytimeEventFactsCommand, voyage: Voyage): number {
        let result = null;
        if (command.fixture.isOnDemurrage && isRangeOverWeekend(command.laytimeEventFact.fromDate, command.laytimeEventFact.toDate, command.timeZone)) {
            let firstDemurrageBerthId: BerthId = null;
            let firstDemurrageDestinationId: DestinationId = null;
            let firstLaytimeEventId: LaytimeEventId = null;
            let firstBerthActivityId: ActivityId = null;
            const laytimeSummaryModel = this.estimatedClaimValueCalculator.createLaytimeSummaryModel(command.fixture, voyage.destinations);
            if (hasItems(laytimeSummaryModel.destinations)) {
                laytimeSummaryModel.destinations.forEach((d) => {
                    if (hasItems(d.berths)) {
                        d.berths.forEach((b) => {
                            const isEditingBerth = b.id === command.berthId && d.id === command.destinationId;
                            const isRepeatedBerth = firstDemurrageBerthId === b.id && d.id === firstDemurrageDestinationId;
                            if (firstDemurrageBerthId && !isRepeatedBerth && isEditingBerth) {
                                result = 100;
                            } else if (b.demurrageBerthActivityId !== null) {
                                firstDemurrageBerthId = b.id;
                                firstDemurrageDestinationId = d.id;
                                if (isEditingBerth && hasItems(b.cargoBerthActivities)) {
                                    b.cargoBerthActivities.forEach((c) => {
                                        const isEditingBerthActivity = c.id === command.berthActivityId;
                                        if (firstBerthActivityId && isEditingBerthActivity) {
                                            result = 100;
                                        } else if (b.demurrageBerthActivityId === c.id) {
                                            firstBerthActivityId = c.id;
                                            if (hasItems(c.laytimeEventFacts)) {
                                                c.laytimeEventFacts.forEach((l) => {
                                                    const isEditingLaytime = command.laytimeEventFact.laytimeEventId === l.id;
                                                    if (firstLaytimeEventId && isEditingLaytime) {
                                                        result = 100;
                                                    } else if (b.demurrageLaytimeEventFactId === l.id) {
                                                        firstLaytimeEventId = l.id;
                                                    }
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
            }
        }
        return result;
    }

    private setPercentageIfShex(destination: Destination, command: UpdateLaytimeEventFactsCommand): any {
        let percentage = 100;
        if (
            WorkingDayType.isSheX(destination.workingDayType) &&
            destination.excludingFromDay &&
            destination.excludingToDay &&
            isWithinOuterRange(
                command.laytimeEventFact.fromDate,
                command.laytimeEventFact.toDate,
                destination.excludingFromDay.id,
                destination.excludingToDay.id,
                destination.excludingFromTime,
                destination.excludingToTime,
                command.timeZone
            )
        ) {
            percentage = destination.unlessUsed && this.isExceptionalEvent(command.laytimeEventFact.type) ? 100 : 0;
        }
        return percentage;
    }

    private isExceptionalEvent(type: Enumeration): boolean {
        const exceptionalEvents = [
            LaytimeEventType.CargoCommenced.id,
            LaytimeEventType.HosesConnected.id,
            LaytimeEventType.LaytimeResumed.id,
            LaytimeEventType.LaytimeCommenced.id,
            LaytimeEventType.StartShifting.id,
            LaytimeEventType.OperationsResumed.id,
            LaytimeEventType.Shifting.id,
            LaytimeEventType.LoadingCommenced.id
        ];

        return type && exceptionalEvents.includes(type.id);
    }
}
