import { FixtureStatusType } from "./fixture-status-type.model";
import { Fixture } from "../../../fixture/shared/models";
import { FixtureType } from "../../../fixture/shared/models/enums/fixture-type";
import { FixtureRow } from "../../../fixtures/models/fixture-row.model";

export interface Favorite {
    id: string;
    userId: number;
    color: string;
    fixtureNumber?: string;
    voyageReference?: string;
    vesselName: string | null;
    fixtureTypeId: FixtureType;
    fixtureStatusId: FixtureStatusType;
    lockedUserId?: number | null;
    lockedUserFullName?: string | null;
    cpDate: string;
    laycanDateStart: string;
    laycanDateEnd: string;
}

export const createFavoriteFromFixture = (fixture: Fixture, userId: number): Favorite => ({
    id: fixture.fixtureId,
    userId,
    color: "Teal",
    cpDate: fixture.charterParty?.charterPartyDate,
    fixtureNumber: fixture.fixtureNumber,
    voyageReference: fixture.voyageReference,
    fixtureStatusId: fixture.fixtureStatus ? fixture.fixtureStatus.id : null,
    fixtureTypeId: fixture.fixtureType ? fixture.fixtureType.id : null,
    laycanDateEnd: fixture.laycan?.date?.to,
    laycanDateStart: fixture.laycan?.date?.from,
    lockedUserFullName: fixture.lockedBy ? fixture.lockedBy.fullName : null,
    lockedUserId: fixture.lockedBy ? fixture.lockedBy.userId : null,
    vesselName: fixture.vessel ? fixture.vessel.name : null
});

export const createFavoriteFromFixtureRow = (fixtureRow: FixtureRow, userId: number): Favorite => {
    const status = fixtureRow.status.replace(" ", "") as keyof typeof FixtureStatusType;
    const fixtureTypeId = fixtureRow.type === "TC Voyage" ? FixtureType.TimeCharter : FixtureType[fixtureRow.type.replace(" ", "") as keyof typeof FixtureType];

    return {
        id: fixtureRow.fixtureId,
        userId,
        color: "Teal",
        cpDate: toISOString(fixtureRow.charterPartyDate),
        fixtureNumber: fixtureRow.fixtureNumber,
        voyageReference: fixtureRow.voyageReference,
        fixtureStatusId: FixtureStatusType[status],
        fixtureTypeId,
        laycanDateEnd: toISOString(fixtureRow.laycanToDate),
        laycanDateStart: toISOString(fixtureRow.laycanFromDate),
        lockedUserFullName: fixtureRow.lockedByFullName,
        lockedUserId: fixtureRow.lockedByUserId,
        vesselName: fixtureRow.vesselName
    };
};

const toISOString = (formattedDate: string) => {
    const date = new Date(`${formattedDate} UTC`);
    return !isNaN(date.getTime()) ? date.toISOString() : "";
};
