import { Injectable } from "@angular/core";

import { isNullOrUndefined } from "@ops/shared";

import { ExpenseClaim, Voyage, Fixture, FixtureType } from "../../../models";
import { FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

// Section rule
@Injectable()
export class ExternalVisibilityHirePeriodRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture, _voyages: Voyage[], _expenses: ExpenseClaim[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        if (fixture.fixtureType.id !== FixtureType.TimeCharter) {
            return warnings;
        }

        if (isNullOrUndefined(fixture.periods) || !fixture.periods.length) {
            const message = "Missing Hire Period";
            const path = [new FixtureWarningPathSegment(null, "Hire"), new FixtureWarningPathSegment("periods", "Periods")];
            warnings.push(new FixtureWarning("data", message, path));
        }

        return warnings;
    }
}
