import { Action, createReducer } from "@ngrx/store";

import { interopLeftBarCollapseToggleReducer, interopLeftBarSetCurrentPanelReducer } from "./left-bar/interop";
import { LeftBarState } from "./model";

export const initialLeftBarState: LeftBarState = {
    isCollapsed: true
};

const reducer = createReducer(initialLeftBarState, interopLeftBarCollapseToggleReducer, interopLeftBarSetCurrentPanelReducer);

// Required for AOT compile
export function leftBarReducer(state: LeftBarState | undefined, action: Action) {
    return reducer(state, action);
}
