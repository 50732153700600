import { Injectable } from "@angular/core";
import { Vessel } from "src/app/fixture/shared/models";
import { VesselData } from "src/app/fixture/shared/models/common/vessel-data";
import { CharterParty } from "src/app/fixture/shared/models/dtos/charter-party.dto";
import { dateToISOString } from "src/app/shared/date-utils/date-utilities";
import { FixtureTemplateType } from "src/app/shared/reference-data/fixture-template-type";
import { FixtureType } from "src/app/shared/reference-data/fixture-type";
import { TimeCharterDurationType } from "src/app/shared/reference-data/time-charter-duration-type";
import { FixtureCreation } from "../shared/create-fixture.dto";
import { FixtureCreationFormModel } from "../shared/create-fixture.model";

@Injectable({
    providedIn: "root"
})
export class FixtureCreationMapper {
    constructor() { }

    map(model: FixtureCreationFormModel): FixtureCreation {
        const fixtureCreation = <FixtureCreation>{
            division: model.fixtureSector,
            coANumber: model.coANumber,
            vesselId: model.vessel ? model.vessel.cvn : null,
            vessel: !model.isVesselTbn ? this.mapVessel(model.vessel) : null,
            isVesselTbn: model.isVesselTbn,
            charterParty: <CharterParty>{
                charterPartyDate: dateToISOString(model.charterPartyDate)
            },
            laycan: {
                date: {
                    from: dateToISOString(model.laycanFrom),
                    to: dateToISOString(model.laycanTo)
                }
            },
            companyRole: model.companyRole,
            company: {
                id: undefined,
                accountId: model.company.accountId,
                fixtureId: undefined,
                name: model.company.companyName,
                groupName: model.company.groupName,
                role: model.companyRole.name,
                typeName: model.companyRole.name,
                typeId: model.companyRole.id,
                people: [],
                imported: false,
                isCreator: true
            },
            sharingGroups: model.sharingGroups && model.sharingGroups.filter((sg) => sg.isSelected).map((sg) => sg.code)
        };

        this.applyFixtureType(fixtureCreation, model.fixtureType);

        return fixtureCreation;
    }

    private applyFixtureType(fixture: FixtureCreation, fixtureType: FixtureTemplateType) {
        switch (fixtureType.id) {
            case FixtureTemplateType.VoyageCoAType.id:
            case FixtureTemplateType.VoyageSpotType.id: {
                fixture.fixtureType = FixtureType.Voyage;
                break;
            }
            case FixtureTemplateType.TimeCharterPeriodType.id: {
                fixture.fixtureType = FixtureType.TimeCharter;
                fixture.timeCharterDurationType = TimeCharterDurationType.Period;
                break;
            }
            case FixtureTemplateType.TimeCharterTripType.id: {
                fixture.fixtureType = FixtureType.TimeCharter;
                fixture.timeCharterDurationType = TimeCharterDurationType.Trip;
                break;
            }
        }
    }

    private mapVessel(vesselData: VesselData): Vessel {
        let vessel: Vessel;
        if (vesselData) {
            vessel = {
                name: vesselData.name,
                imo: vesselData.imoNumber,
                cvn: vesselData.cvn
            };
        }

        return vessel;
    }
}
