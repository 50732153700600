import { Injectable } from "@angular/core";
import { ComponentValidator, IComponentValidatorParams } from "../component-validator";
import { RangeRequiredIfValidator } from "./../../range-required-if-validator";

@Injectable({ providedIn: "root" })
export class LaytimeEventFactsComponentValidator implements ComponentValidator {
    supportedComponentName = "LaytimeEventFactsComponent";

    validate(params: IComponentValidatorParams): void {
        params.form.setValidators([RangeRequiredIfValidator.validate("fromDate", "toDate")]);
    }
}
