let textMeasurementCanvas: HTMLCanvasElement;

export function measureTextWidth(text: string, font: string): number;
export function measureTextWidth(el: HTMLInputElement): number;
export function measureTextWidth(textOrEl: string | HTMLInputElement, font?: string): number {
    const text = typeof textOrEl === "string" ? textOrEl : textOrEl.value;
    font ??= textOrEl instanceof HTMLInputElement && getComputedStyle(textOrEl, null).getPropertyValue("font");

    // re-use canvas object for better performance
    const canvas = textMeasurementCanvas ??= document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;

    return context.measureText(text).width;
}
