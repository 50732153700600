import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { routerNavigatedAction } from "@ngrx/router-store";
import { Action, ActionCreator, Store } from "@ngrx/store";
import { uniq } from "ramda";
import { distinctUntilChanged, filter, map, tap, withLatestFrom } from "rxjs/operators";

import { AppInsightsService, MixpanelProperties, MixpanelService } from "@ops/core";
import { deepEqual, hasValue } from "@ops/shared";
import { leftBarSetCurrentPanel, selectRouteUrl } from "@ops/state";

import { Fixture, User } from "../../fixture/shared/models";
import { selectCurrentFixture } from "../../fixture/state";
import {
    addActivityLocationsCancel,
    addActivityLocationsToCalculation,
    laytimeCalculationNavItemClick,
    removeCargoTerms,
    routerLoadFixtureSuccessAction,
    routerLoadLaytimeCalculationSuccess,
    selectCurrentAddActivityLocationsImportPortTimes,
    selectCurrentFixture as selectCurrentLtcFixture,
    selectCurrentLaytimeCalculation,
    updateCargoTerms
} from "./calculations";
import { addActivityCargoAction } from "./calculations/activity-location/cargoes/form/add-activity-cargo";
import { removeActivityCargoAction } from "./calculations/activity-location/cargoes/form/remove-activity-cargo";
import { updateActivityCargoAction } from "./calculations/activity-location/cargoes/form/update-activity-cargo";
import { updateActivityLocationAction } from "./calculations/activity-location/form/update-activity-location";
import { addLaytimeEventAction } from "./calculations/activity-location/laytime-events/form/add-laytime-event";
import { importPortTimesAction } from "./calculations/activity-location/laytime-events/form/import-port-times";
import { removeLaytimeEventAction } from "./calculations/activity-location/laytime-events/form/remove-laytime-event";
import { updateLaytimeEventAction } from "./calculations/activity-location/laytime-events/form/update-laytime-event";
import { createLaytimeCalculation } from "./calculations/create-calculation";
import { exportLaytimeCalculation } from "./calculations/export";
import { closeLaytimeCalculationAction, LtcNavItem } from "./calculations/router";
import { updateTermsAction } from "./calculations/terms/form/update-terms";
import { FixtureIndex, LaytimeCalculation, LtcFeatureState, UserIndex } from "./model";

export enum LtcEvent {
    GridLoad = "Grid Load",
    ListOpen = "List Open",
    NewCalculation = "New Calculation",
    CloneCalculation = "Clone Calculation",
    SummaryDisplay = "Summary Display",
    TermsDisplay = "Terms Display",
    ActivityLocationDisplay = "Activity Location Display",
    AddLocationsDisplay = "Add Locations Display",
    SummaryNavItemClick = "Summary Nav Item Click",
    TermsNavItemClick = "Terms Nav Item Click",
    ActivityLocationNavItemClick = "Activity Location Nav Item Click",
    AddLocationsNavItemClick = "Add Locations Nav Item Click",
    Delete = "Delete",
    Export = "Export",
    AddLocationsCancelClick = "Add Locations Cancel Click",
    AddToCalculationClick = "Add To Calculation Click",
    TermsEdit = "Terms Edit",
    CargoTermsEdit = "Cargo Terms Edit",
    CargoTermsDelete = "Cargo Terms Delete",
    ActivityCargoAdd = "Activity Cargo Add",
    ActivityCargoEdit = "Activity Cargo Edit",
    ActivityCargoDelete = "Activity Cargo Delete",
    LaytimeEventAdd = "Laytime Event Add",
    LaytimeEventInsert = "Laytime Event Insert",
    LaytimeEventEdit = "Laytime Event Edit",
    LaytimeEventDelete = "Laytime Event Delete",
    LaytimeEventsCleared = "Laytime Events Cleared",
    ImportPortTimes = "Import Port Times",
    ActivityLocationTermsEdit = "Activity Location Terms Edit",
    CloseCalculationClick = "Close Calculation Click"
}

const navItemToClickEvent = (navItem: LtcNavItem): LtcEvent => {
    switch (navItem) {
        case "Summary":
            return LtcEvent.SummaryNavItemClick;
        case "Terms":
            return LtcEvent.TermsNavItemClick;
        case "Activity Location":
            return LtcEvent.ActivityLocationNavItemClick;
        case "Add Locations":
            return LtcEvent.AddLocationsNavItemClick;
        default:
            throw Error(`Unknown nav item '${navItem}'`);
    }
};

const urlToDisplayEvent = (url: string): LtcEvent => {
    if (url.endsWith("/terms")) {
        return LtcEvent.TermsDisplay;
    } else if (url.includes("/locations/")) {
        return LtcEvent.ActivityLocationDisplay;
    } else if (url.includes("/add-locations")) {
        return LtcEvent.AddLocationsDisplay;
    }

    return LtcEvent.SummaryDisplay;
};

const getMixpanelProperties = (calculation: LaytimeCalculation, fixture: FixtureIndex): MixpanelProperties => {
    const getUserNames = (users: ReadonlyArray<UserIndex>) => (users ? uniq(users.map((x) => x.name)).join(",") : null);

    return {
        "Fixture ID": fixture?.fixtureId,
        "Fixture Source": fixture?.fixtureSource,
        "Fixture Status": fixture?.status,
        "Fixture Office Location": fixture?.location,
        "Fixture Operator": fixture ? getUserNames(fixture.operators) : null,
        "Fixture Claims": fixture ? getUserNames(fixture.claims) : null,
        Sector: calculation?.sector ?? fixture?.division
    };
};

const getFixtureMixpanelProperties = (fixture: Fixture): MixpanelProperties => {
    const getUserNames = (users: ReadonlyArray<User>) => (users ? uniq(users.map((x) => x.fullName)).join(",") : null);

    return {
        "Fixture ID": fixture.fixtureId,
        "Fixture Source": fixture.fixtureSource?.name,
        "Fixture Status": fixture.fixtureStatus?.name,
        "Fixture Office Location": fixture.office?.name,
        "Fixture Operator": getUserNames(fixture.operators),
        "Fixture Claims": getUserNames(fixture.claims),
        Sector: fixture.division.name
    };
};

@Injectable()
export class LtcLoggingEffects {
    // TODO: LTC Grid load when story merged

    listOpen$ = this.createFixtureTrackEffect(leftBarSetCurrentPanel, LtcEvent.ListOpen, ({ panel }) => panel === "ltc");
    newCalculation$ = this.createFixtureTrackEffect(createLaytimeCalculation, LtcEvent.NewCalculation);

    // TODO: LTC Clone Calculation when story merged
    // cloneCalculation$ = this.createTrackEffect(cloneLaytimeCalculation, LtcEvents.CloneCalculation);
    navItemClick$ = this.createTrackEffect(laytimeCalculationNavItemClick, ({ item }) => navItemToClickEvent(item));
    exportExcel$ = this.createTrackEffect(exportLaytimeCalculation, LtcEvent.Export, null, () => ({ "Export Type": "Excel" }));
    exportPdf$ = this.createTrackEffect(exportLaytimeCalculation, LtcEvent.Export, null, () => ({ "Export Type": "PDF" }));
    addLocationsCancelClick$ = this.createTrackEffect(addActivityLocationsCancel, LtcEvent.AddLocationsCancelClick);
    termsEdit$ = this.createTrackEffect(updateTermsAction, LtcEvent.TermsEdit);
    cargoTermsEdit$ = this.createTrackEffect(updateCargoTerms, LtcEvent.CargoTermsEdit);
    cargoTermsDelete$ = this.createTrackEffect(removeCargoTerms, LtcEvent.CargoTermsDelete);
    activityCargoAdd$ = this.createTrackEffect(addActivityCargoAction, LtcEvent.ActivityCargoAdd);
    activityCargoEdit$ = this.createTrackEffect(updateActivityCargoAction, LtcEvent.ActivityCargoEdit);
    activityCargoDelete$ = this.createTrackEffect(removeActivityCargoAction, LtcEvent.ActivityCargoDelete);
    laytimeEventAdd$ = this.createTrackEffect(addLaytimeEventAction, LtcEvent.LaytimeEventAdd, ({ index }) => !hasValue(index));
    laytimeEventInsert$ = this.createTrackEffect(addLaytimeEventAction, LtcEvent.LaytimeEventInsert, ({ index }) => hasValue(index));
    laytimeEventEdit$ = this.createTrackEffect(updateLaytimeEventAction, LtcEvent.LaytimeEventEdit);
    laytimeEventDelete$ = this.createTrackEffect(removeLaytimeEventAction, LtcEvent.LaytimeEventDelete);
    importPortTimes$ = this.createTrackEffect(importPortTimesAction, LtcEvent.ImportPortTimes);
    activityLocationTermsEdit$ = this.createTrackEffect(updateActivityLocationAction, LtcEvent.ActivityLocationTermsEdit);
    closeCalculationClick$ = this.createTrackEffect(closeLaytimeCalculationAction, LtcEvent.CloseCalculationClick);

    calculationLoaded$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(routerLoadLaytimeCalculationSuccess, routerLoadFixtureSuccessAction, routerNavigatedAction),
                filter((action) => action.type !== routerNavigatedAction.type || (action as ReturnType<typeof routerNavigatedAction>).payload.event.url.includes("/ltc/")),
                withLatestFrom(this.store.select(selectCurrentLaytimeCalculation), this.store.select(selectCurrentLtcFixture), this.store.select(selectRouteUrl)),
                map(([action, calculation, fixture, url]) => {
                    calculation = calculation ?? (action as ReturnType<typeof routerLoadLaytimeCalculationSuccess>).calculation;
                    fixture = fixture ?? (action as ReturnType<typeof routerLoadFixtureSuccessAction>).fixture;

                    if (calculation) {
                        this.appInsights.setGlobalProperty("ltcId", calculation.id);
                        this.appInsights.setGlobalProperty("fixtureId", calculation.fixtureId);
                    } else {
                        this.appInsights.removeGlobalProperty("ltcId");
                        this.appInsights.removeGlobalProperty("fixtureId");
                    }

                    if (calculation && fixture) {
                        const event = urlToDisplayEvent(url);
                        return [calculation, fixture, event] as [LaytimeCalculation, FixtureIndex, LtcEvent];
                    }

                    return null;
                }),
                filter(hasValue),
                distinctUntilChanged(deepEqual),
                tap(([calculation, fixture, event]) => this.trackMixpanelEvent(event, calculation, fixture))
            ),
        { dispatch: false }
    );

    addToCalculationClick$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addActivityLocationsToCalculation),
                withLatestFrom(
                    this.store.select(selectCurrentLaytimeCalculation),
                    this.store.select(selectCurrentLtcFixture),
                    this.store.select(selectCurrentAddActivityLocationsImportPortTimes)
                ),
                tap(([, calculation, fixture, importPortTimes]) =>
                    this.trackMixpanelEvent(LtcEvent.AddToCalculationClick, calculation, fixture, { "Import Port Times": importPortTimes?.value })
                )
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private appInsights: AppInsightsService, private mixpanel: MixpanelService, private store: Store<LtcFeatureState>) {}

    /**
     * Creates an effect to track LTC events on the calculation screen.
     */
    private createTrackEffect<
        E extends Extract<
            U,
            {
                type: T1;
            }
        >,
        AC extends ActionCreator,
        T1 extends string | AC,
        U extends Action = Action,
        V = T1 extends string ? E : ReturnType<Extract<T1, AC>>
    >(
        action: T1,
        event: LtcEvent | ((action: V) => LtcEvent),
        predicate?: (action: V) => boolean,
        additionalProps?: (calculation: LaytimeCalculation, fixture: FixtureIndex) => Record<string, string>
    ) {
        return createEffect(
            () =>
                this.actions$.pipe(
                    ofType(action),
                    filter<V>(predicate ?? (() => true)),
                    withLatestFrom(this.store.select(selectCurrentLaytimeCalculation), this.store.select(selectCurrentLtcFixture)),
                    tap(([a, calculation, fixture]) => {
                        const eventName = (event = typeof event === "function" ? event(a) : event);
                        this.trackMixpanelEvent(eventName, calculation, fixture, additionalProps ? additionalProps(calculation, fixture) : null);
                    })
                ),
            { dispatch: false }
        );
    }

    /**
     * Creates an effect to track LTC events on the fixture screen.
     */
    private createFixtureTrackEffect<
        E extends Extract<
            U,
            {
                type: T1;
            }
        >,
        AC extends ActionCreator,
        T1 extends string | AC,
        U extends Action = Action,
        V = T1 extends string ? E : ReturnType<Extract<T1, AC>>
    >(action: T1, event: LtcEvent, predicate?: (action: V) => boolean) {
        return createEffect(
            () =>
                this.actions$.pipe(
                    ofType(action),
                    filter<V>(predicate ?? (() => true)),
                    withLatestFrom(this.store.select(selectCurrentFixture), this.store.select(selectCurrentLtcFixture)),
                    filter(([, fixture, fixtureIndex]) => hasValue(fixture) || hasValue(fixtureIndex)),
                    tap(([, fixture, fixtureIndex]) => this.trackFixtureMixpanelEvent(event, fixture, fixtureIndex))
                ),
            { dispatch: false }
        );
    }

    private trackMixpanelEvent(event: LtcEvent, calculation: LaytimeCalculation, fixture: FixtureIndex, additionalProps?: MixpanelProperties) {
        this.mixpanel.track(`Ops: LTC ${event}`, {
            ...getMixpanelProperties(calculation, fixture),
            ...additionalProps
        });
    }

    private trackFixtureMixpanelEvent(event: LtcEvent, fixture: Fixture, fixtureIndex: FixtureIndex) {
        const properties = fixture ? getFixtureMixpanelProperties(fixture) : getMixpanelProperties(null, fixtureIndex);

        this.mixpanel.track(`Ops: LTC ${event}`, properties);
    }
}
