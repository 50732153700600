import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { VoyageActivityFilter } from "../../../state";

@Component({
    selector: "ops-voyage-activity-filter",
    templateUrl: "./voyage-activity-filter.component.html",
    styleUrls: ["./filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoyageActivityFilterComponent {
    @Input() voyageActivity: VoyageActivityFilter;
}
