import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { routerLoadLaytimeCalculationEffect$, routerLoadFixtureEffect$, UpdateQueue } from "./calculations";
import { importActivityCargoTermsButtonClickEffect$, importActivityCargoTermsEffect$ } from "./calculations/activity-location/cargoes/form/import-activity-cargo-terms";
import { removeActivityCargoFormEffect$ } from "./calculations/activity-location/cargoes/form/remove-activity-cargo";
import { updateActivityCargoFormEffect$ } from "./calculations/activity-location/cargoes/form/update-activity-cargo";
import { applyDeductionsEffect$ } from "./calculations/activity-location/deductions/apply-deductions";
import { navigateOnActivityLocationRemovalSuccessEffect$, removeActivityLocationEffect$ } from "./calculations/activity-location/form/remove-activity-location";
import { updateActivityLocationFormEffect$ } from "./calculations/activity-location/form/update-activity-location";
import { addLaytimeEventFormEffect$, preAddLaytimeEventEffect$ } from "./calculations/activity-location/laytime-events/form/add-laytime-event";
import { processVoyageLoadSuccessForImportPortTimesEffect$ } from "./calculations/activity-location/laytime-events/form/import-port-times";
import { orderLaytimeEventFormsEffect$ } from "./calculations/activity-location/laytime-events/form/order-laytime-events";
import { clearLaytimeEventsFormEffect$, removeLaytimeEventFormEffect$ } from "./calculations/activity-location/laytime-events/form/remove-laytime-event";
import {
    processLaytimeEventPercentageEffect$,
    processedPercentageLaytimeEventUpdateEffect$,
    updateLaytimeEventFormEffect$
} from "./calculations/activity-location/laytime-events/form/update-laytime-event";
import { addActivityLocationsEffect$, addActivityLocationsNavigateEffect$ } from "./calculations/add-activity-locations/form/add-activity-locations";
import { addActivityLocationsChangeFilterEffect$, addActivityLocationsChangeFilterSelectionEffect$ } from "./calculations/add-activity-locations/form/change-filter";
import { upsertCargoTermsOnAddActivityLocationsEffect$, upsertCargoTermsActionEffect$ } from "./calculations/add-activity-locations/form/upsert-cargo-terms";
import { cloneLaytimeCalculationEffect$ } from "./calculations/clone/clone-calculation";
import { createLaytimeCalculationEffect$ } from "./calculations/create-calculation/create-calculation";
import { enterDeductionsEffect$ } from "./calculations/deductions/enter-deductions";
import { exportLaytimeCalculationEffect$, exportLaytimeCalculationSuccessEffect$ } from "./calculations/export/calculation-export";
import { loadFixtureLaytimeCalculationsEffect$ } from "./calculations/fixture-calculation/load-fixture-calculations";
import { searchCalculationsEffect$ } from "./calculations/grid/search";
import { getCalculationSuggestionsEffect$ } from "./calculations/grid/update-suggestion-term";
import { queueUpdateLaytimeCalculationNameEffect$ } from "./calculations/name/form/update-calculation-name";
import { removeLaytimeCalculationEffect$ } from "./calculations/remove-calculation/remove-calculation";
import { closeLaytimeCalculationEffect$, navigateToLaytimeCalculationEffect$ } from "./calculations/router";
import { queueRemoveCargoTermsEffect$, queueUpdateCargoTermsEffect$ } from "./calculations/terms/cargo-terms/form/update-cargo-terms";
import { updateTermsFormEffect$ } from "./calculations/terms/form/update-terms";
import { loadVoyageEffect$, loadVoyageForAddActivityLocationsEffect$, loadVoyageForImportPortTimesEffect$ } from "./calculations/voyage/load-voyage";
import { LtcFeatureState } from "./model";
import { LaytimeCalculationHttpService, FixtureHttpService, VoyageHttpService } from "../services";

@Injectable()
export class LtcEffects {
    loadCalculation$ = routerLoadLaytimeCalculationEffect$(this.actions$, this.store, this.laytimeCalculationHttpService);

    routerLoadFixture$ = routerLoadFixtureEffect$(this.actions$, this.fixtureHttpService);

    loadFixtureLaytimeCalculations$ = loadFixtureLaytimeCalculationsEffect$(this.actions$, this.laytimeCalculationHttpService);

    createLaytimeCalculation$ = createLaytimeCalculationEffect$(this.actions$, this.store, this.laytimeCalculationHttpService);

    cloneLaytimeCalculationEffect$ = cloneLaytimeCalculationEffect$(this.actions$, this.store, this.laytimeCalculationHttpService);

    removeLaytimeCalculation$ = removeLaytimeCalculationEffect$(this.actions$, this.store, this.laytimeCalculationHttpService);

    navigateToLaytimeCalculation$ = navigateToLaytimeCalculationEffect$(this.actions$, this.router);

    closeLaytimeCalculation$ = closeLaytimeCalculationEffect$(this.actions$, this.store, this.router);

    exportLaytimeCalculation$ = exportLaytimeCalculationEffect$(this.actions$, this.store, this.laytimeCalculationHttpService);

    enterDeductions$ = enterDeductionsEffect$(this.actions$, this.dialog);

    exportLaytimeCalculationSuccess$ = exportLaytimeCalculationSuccessEffect$(this.actions$, this.store);

    loadVoyage$ = loadVoyageEffect$(this.actions$, this.store, this.voyageHttpService);

    loadVoyageForAddActivityLocations$ = loadVoyageForAddActivityLocationsEffect$(this.actions$, this.store);

    loadVoyageForImportPortTimes$ = loadVoyageForImportPortTimesEffect$(this.actions$, this.store);

    addActivityLocationsNavigate$ = addActivityLocationsNavigateEffect$(this.actions$, this.store);

    addActivityLocationsChangeFilter$ = addActivityLocationsChangeFilterEffect$(this.actions$);

    addActivityLocationsChangeFilterSelection$ = addActivityLocationsChangeFilterSelectionEffect$(this.actions$, this.store);

    addActivityLocations$ = addActivityLocationsEffect$(this.actions$, this.store, this.updateQueue);

    updateTermsForm$ = updateTermsFormEffect$(this.actions$, this.store, this.updateQueue);

    updateActivityLocationForm$ = updateActivityLocationFormEffect$(this.actions$, this.store, this.updateQueue);

    queueUpdateCargoTerms$ = queueUpdateCargoTermsEffect$(this.actions$, this.store, this.updateQueue);

    queueRemoveCargoTerms$ = queueRemoveCargoTermsEffect$(this.actions$, this.store, this.updateQueue);

    removeActivityCargoForm$ = removeActivityCargoFormEffect$(this.actions$, this.store, this.updateQueue);

    updateLocationCargoForm$ = updateActivityCargoFormEffect$(this.actions$, this.store, this.updateQueue);

    queueUpdateLaytimeCalculationNameForm$ = queueUpdateLaytimeCalculationNameEffect$(this.actions$, this.store, this.updateQueue);

    removeLaytimeEventForm$ = removeLaytimeEventFormEffect$(this.actions$, this.store, this.updateQueue);

    clearLaytimeEventsForm$ = clearLaytimeEventsFormEffect$(this.actions$, this.store, this.updateQueue);

    processLaytimeEventPercentage$ = processLaytimeEventPercentageEffect$(this.actions$, this.store);

    updateLaytimeEventForm$ = processedPercentageLaytimeEventUpdateEffect$(this.actions$, this.updateQueue);

    updateLaytimeEventFormEffect$ = updateLaytimeEventFormEffect$(this.actions$, this.updateQueue);

    preAddLaytimeEventEffect$ = preAddLaytimeEventEffect$(this.actions$, this.store);

    addLaytimeEventForm$ = addLaytimeEventFormEffect$(this.actions$, this.store, this.updateQueue);

    orderLaytimeEventFormsEffect$ = orderLaytimeEventFormsEffect$(this.actions$, this.store, this.updateQueue);

    removeActivityLocation$ = removeActivityLocationEffect$(this.actions$, this.store, this.updateQueue);

    processVoyageLoadSuccessForImportPortTimes$ = processVoyageLoadSuccessForImportPortTimesEffect$(this.actions$, this.store, this.updateQueue);

    navigateOnActivityLocationRemovalSuccess$ = navigateOnActivityLocationRemovalSuccessEffect$(this.actions$, this.router);

    searchCalculations$ = searchCalculationsEffect$(this.actions$, this.store, this.laytimeCalculationHttpService);

    getCalculationSuggestions$ = getCalculationSuggestionsEffect$(this.actions$, this.laytimeCalculationHttpService);

    importActivityCargoTermsButtonClick$ = importActivityCargoTermsButtonClickEffect$(this.actions$, this.store);

    importActivityCargoTerms$ = importActivityCargoTermsEffect$(this.actions$, this.updateQueue);

    applyDeductionsEffect$ = applyDeductionsEffect$(this.actions$, this.store);

    upsertCargoTermsOnAddActivityLocationsEffect$ = upsertCargoTermsOnAddActivityLocationsEffect$(this.actions$, this.store);

    upsertCargoTermsActionEffect$ = upsertCargoTermsActionEffect$(this.actions$, this.updateQueue);

    constructor(
        private actions$: Actions,
        private store: Store<LtcFeatureState>,
        private laytimeCalculationHttpService: LaytimeCalculationHttpService,
        private fixtureHttpService: FixtureHttpService,
        private voyageHttpService: VoyageHttpService,
        private router: Router,
        private updateQueue: UpdateQueue,
        private dialog: MatDialog
    ) {}
}
