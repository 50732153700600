import { Pipe, PipeTransform } from "@angular/core";
import { Duration } from "luxon";

import { LaytimeCalculationUnit } from "@ops/shared/reference-data";

const ROUNDING_NUMBER = 100;

@Pipe({
    name: "durationFormat"
})
export class DurationFormatPipe implements PipeTransform {
    transform(value: number | Duration, displayType: LaytimeCalculationUnit): string {
        if (!value) {
            return "";
        }

        if (displayType === "Hours") {
            const hours = Duration.isDuration(value) ? value.as("hours") : value;
            return Math.round((hours + Number.EPSILON) * ROUNDING_NUMBER) / ROUNDING_NUMBER + " hours";
        }

        if (!Duration.isDuration(value)) {
            value = Duration.fromObject({ hours: value });
        }

        // Luxon formats each segment with a negative number, we only want to prefix the first
        const isNegative = +value < 0;
        if (isNegative) {
            value = value.negate();
        }

        return (isNegative ? "-" : "") + value.toFormat("dd'd' hh'h' mm'm'");
    }
}
