import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { markAsTouched } from "ngrx-forms";
import * as R from "ramda";
import { Evolver } from "ramda";
import { combineLatest, of } from "rxjs";
import { catchError, exhaustMap, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { Null, update } from "@ops/shared";
import { persisted } from "@ops/state";

import { getChangeReason, userCancelled } from "./change-reason";
import { selectCurrentUser } from "../../../../../state";
import { LiftingHttpService } from "../../../../services";
import { selectCurrentCoaId } from "../../../coa";
import { coaLiftingsStateReducer } from "../../../coa/reducer";
import { cargoIdEq, ChangeReason, CoaFeatureState, CoaId, CoasState, CoaState, formToLiftingCargo, LiftingCargo, LiftingId, toUser, User } from "../../../model";
import { currentLiftingStateReducer, liftingStateReducer } from "../../reducer";
import { selectCurrentLifting, selectCurrentLiftingId } from "../../selectors";
import { selectLiftingCargoForm } from "../selectors";

/* ACTIONS */
const SAVE_CARGO_ACTION_NAME = "[Lifting Cargo Form] Save Cargo";
export const saveCargoAction = createAction(SAVE_CARGO_ACTION_NAME, props<{ changeReason: ChangeReason }>());
export const saveCargoSuccessAction = createAction(
    `${SAVE_CARGO_ACTION_NAME} Success`,
    props<{ coaId: CoaId; liftingId: LiftingId; cargo: LiftingCargo; user: User; changeReason?: ChangeReason }>()
);
export const savingCargoAction = createAction(`${SAVE_CARGO_ACTION_NAME} Pending`);

const SAVE_NEW_CARGO_ACTION_NAME = "[Lifting Cargo Form] Save New Cargo";
export const saveNewCargoAction = createAction(SAVE_NEW_CARGO_ACTION_NAME, props<{ changeReason: ChangeReason }>());
export const saveNewCargoSuccessAction = createAction(
    `${SAVE_NEW_CARGO_ACTION_NAME} Success`,
    props<{ coaId: CoaId; liftingId: LiftingId; cargo: LiftingCargo; user: User; changeReason?: ChangeReason }>()
);
export const savingNewCargoAction = createAction(`${SAVE_NEW_CARGO_ACTION_NAME} Pending`);

export const saveCargoFailAction = createAction(`${SAVE_CARGO_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

/* REDUCERS */
export const savingCargoReducer: On<CoasState> = on(savingCargoAction, savingNewCargoAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => ({ ...liftingState, liftingCargoForm: markAsTouched(liftingState.liftingCargoForm) }))
);

export const saveCargoReducer: On<CoasState> = on(saveCargoAction, saveNewCargoAction, (state) => currentLiftingStateReducer(state, { liftingCargoFormSaveStatus: "persisting" }));

export const saveCargoFailReducer: On<CoasState> = on(saveCargoFailAction, (state, { liftingId }) =>
    liftingStateReducer(state, liftingId, (liftingState) => ({ ...liftingState, liftingCargoFormSaveStatus: "failed" }))
);

export const saveCargoSuccessReducer: On<CoasState> = on(saveCargoSuccessAction, saveNewCargoSuccessAction, (state, { coaId, type, cargo, liftingId }) => {
    const updateFns: Evolver = {
        liftingCargoFormSaveStatus: persisted,
        liftingCargoForm: Null,
        lifting: { cargoes: type === saveNewCargoSuccessAction.type ? R.append(cargo) : update(cargoIdEq(cargo.cargoId), cargo) }
    };
    const withUpdatedLiftingState = liftingStateReducer(state, liftingId, R.evolve(updateFns));

    const getLiftings = (coaState: CoaState) =>
        coaState.fetchedLiftings.map((l) => (l.documentId !== liftingId ? l : { ...l, cargoes: withUpdatedLiftingState.liftings.byId[liftingId].lifting.cargoes }));

    return coaLiftingsStateReducer(withUpdatedLiftingState, coaId, getLiftings);
});

/* EFFECTS */
export const saveLiftingCargoEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveCargoAction, saveNewCargoAction),
            withLatestFrom(store.select(selectCurrentCoaId), store.select(selectCurrentLiftingId), store.select(selectLiftingCargoForm), store.select(selectCurrentUser)),
            mergeMap(([{ type, changeReason }, coaId, liftingId, form, user]) => {
                const cargo: LiftingCargo = formToLiftingCargo(form.value);
                if (type === SAVE_CARGO_ACTION_NAME) {
                    return liftingService.updateCargo(coaId, liftingId, { ...cargo, changeReason }).pipe(
                        map(() => saveCargoSuccessAction({ coaId, liftingId, cargo, user: toUser(user), changeReason })),
                        catchError((error) => of(saveCargoFailAction({ liftingId, error })))
                    );
                }
                return liftingService.addCargo(coaId, liftingId, { ...cargo, changeReason }).pipe(
                    map(() => saveNewCargoSuccessAction({ coaId, liftingId, cargo, user: toUser(user), changeReason })),
                    catchError((error) => of(saveCargoFailAction({ liftingId, error })))
                );
            })
        )
    );

export const savingLiftingCargoEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, dialog: MatDialog) =>
    createEffect(() =>
        actions$.pipe(
            ofType(savingCargoAction, savingNewCargoAction),
            withLatestFrom(store.select(selectCurrentLifting), store.select(selectLiftingCargoForm)),
            filter(([, , form]) => form.isValid),
            exhaustMap(([{ type }, { cargoPlanStatus, liftingId }]) =>
                combineLatest([getChangeReason(cargoPlanStatus, dialog, type === savingCargoAction.type ? "Update Cargo" : "Add Cargo"), of({ type, liftingId })])
            ),
            map(([changeReason, { type, liftingId }]) =>
                changeReason !== undefined
                    ? type === savingCargoAction.type
                        ? saveCargoAction({ changeReason })
                        : saveNewCargoAction({ changeReason })
                    : saveCargoFailAction({ liftingId, error: userCancelled() })
            )
        )
    );
