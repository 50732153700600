import { createSelector } from "@ngrx/store";

import { selectCurrentCoa, selectCurrentCoaState } from "../coa/selectors";
import { Coa, CoaFeatureState } from "../model";
import { LocationId } from "../model/location";
import { Location } from "../model/location";
import { getFlagImageUrl } from "../utils";

export const selectCurrentLoadLocationForm = createSelector(selectCurrentCoaState, (state) => state?.loadLocationForm);
export const selectCurrentLoadLocationFormValue = createSelector(selectCurrentLoadLocationForm, (form) => form?.value);

export const selectCurrentDischargeLocationForm = createSelector(selectCurrentCoaState, (state) => state?.dischargeLocationForm);
export const selectCurrentDischargeLocationFormValue = createSelector(selectCurrentDischargeLocationForm, (form) => form?.value);

export type LocationState = Readonly<{
    locationId: LocationId;
    name: string;
    flagImageUrl: string;
}>;

export const getLocationState = (location: Location, cdnUrl: string): LocationState => ({
    locationId: location.locationId,
    name: location.countryName ? `${location.name} (${location.countryName})` : location.name,
    flagImageUrl: getFlagImageUrl(cdnUrl, location.countryUnCode)
});

export const selectCurrentCoaLoadLocations = createSelector<CoaFeatureState, { cdnUrl: string }, Coa, ReadonlyArray<LocationState>>(selectCurrentCoa, (coa, { cdnUrl }) =>
    coa?.loadLocations?.map((x) => getLocationState(x, cdnUrl))
);

export const selectCurrentCoaDischargeLocations = createSelector<CoaFeatureState, { cdnUrl: string }, Coa, ReadonlyArray<LocationState>>(selectCurrentCoa, (coa, { cdnUrl }) =>
    coa?.dischargeLocations?.map((x) => getLocationState(x, cdnUrl))
);
