import { createAction, On, on, props } from "@ngrx/store";
import * as R from "ramda";

import { LiftingHistoryEvent, LiftingHistoryFilterType } from "../../../left-bar/lifting-history/lifting-history";
import { CoasState, LiftingState } from "../model";
import { currentLiftingStateReducer } from "./reducer";

export type LiftingHistoryFilterOptions = {
    all: boolean;
    laycan: boolean;
    cargoNomination: boolean;
    vesselNomination: boolean;
};

export const isAnyFilterOptionSelected = (filterOptions: LiftingHistoryFilterOptions) =>
    filterOptions && (filterOptions.laycan || filterOptions.cargoNomination || filterOptions.vesselNomination);

export const getFilterOptionsTooltip = (filterOptions: LiftingHistoryFilterOptions) => {
    if (!filterOptions) {
        return "";
    }
    if (!filterOptions.laycan && !filterOptions.cargoNomination && !filterOptions.vesselNomination) {
        return "No filters applied";
    }
    if (filterOptions.all) {
        return "Filters Applied: All";
    }
    return `Filters Applied: ${getFilterTypes(filterOptions).join(", ")}`;
};

export const getLiftingHistoryPlaceholderText = (historyEvents: LiftingHistoryEvent[] | undefined, filteredHistoryEvents: LiftingHistoryEvent[]) => {
    if (!historyEvents || !filteredHistoryEvents || filteredHistoryEvents.length > 0) {
        return null;
    }
    return historyEvents.length > 0 && filteredHistoryEvents.length === 0 ? "No events against this lifting for this filter option" : "No events against this lifting";
};

export const getFilteredHistoryEvents = (historyEvents: LiftingHistoryEvent[] | undefined, filterOptions: LiftingHistoryFilterOptions) => {
    const filterTypes = getFilterTypes(filterOptions);
    return historyEvents?.filter((event) => filterTypes.includes(event.filterType));
};

export const getFilterTypes = (filterOptions: LiftingHistoryFilterOptions) => {
    const result: LiftingHistoryFilterType[] = [];
    if (filterOptions?.laycan) {
        result.push("Laycan");
    }
    if (filterOptions?.cargoNomination) {
        result.push("Cargo Nomination");
    }
    if (filterOptions?.vesselNomination) {
        result.push("Vessel Nomination");
    }
    return result;
};

/* ACTIONS */
const ACTION_NAME = "[Lifting Actions] Toggle Lifting History";

export const liftingHistoryToggleAllAction = createAction(`${ACTION_NAME} All`);
export const liftingHistoryToggleKeyAction = createAction(
    `${ACTION_NAME} Key`,
    props<{ key: keyof Pick<LiftingHistoryFilterOptions, "laycan" | "cargoNomination" | "vesselNomination"> }>()
);

/* REDUCERS */
export const liftingHistoryToggleAllReducer: On<CoasState> = on(liftingHistoryToggleAllAction, (state) =>
    currentLiftingStateReducer(state, (liftingState: LiftingState) => {
        const currentValue = liftingState.liftingHistoryFilterOptions;
        return {
            ...liftingState,
            liftingHistoryFilterOptions: Object.fromEntries(Object.entries(currentValue).map(([key, _]) => [key, !currentValue.all])) as LiftingHistoryFilterOptions
        };
    })
);

export const liftingHistoryToggleKeyReducer: On<CoasState> = on(liftingHistoryToggleKeyAction, (state, { key }) =>
    currentLiftingStateReducer(state, (liftingState: LiftingState) => {
        const currentValue = {
            ...liftingState.liftingHistoryFilterOptions,
            [key]: !liftingState.liftingHistoryFilterOptions[key]
        };

        return {
            ...liftingState,
            liftingHistoryFilterOptions: {
                ...currentValue,
                all: Object.values(R.omit(["all"], currentValue)).every(Boolean)
            }
        };
    })
);
