import { addDestinationReducer } from "./form/add-destination";
import { cloneDestinationReducer } from "./form/clone-destination";
import {
    collapseDestinationNotesReducer,
    collapseDestinationReducer,
    collapseDestinationsReducer,
    expandDestinationNotesReducer,
    expandDestinationReducer,
    expandDestinationsReducer
} from "./form/expand-destination";
import { auditGenerateEtaEmailSuccessReducer } from "./form/generate-eta-email";
import { moveDestinationDownReducer, moveDestinationUpReducer } from "./form/move-destination";
import { prefillLaytimeEventDatesReducer } from "./form/prefill-laytime-event-dates";
import { removeDestinationReducer } from "./form/remove-destination";
import { setDestinationLocationReducer } from "./form/update-timezone";
import { verifyEtaSuccessReducer } from "./form/verify-eta";

export default [
    addDestinationReducer,
    cloneDestinationReducer,

    collapseDestinationNotesReducer,
    collapseDestinationReducer,
    collapseDestinationsReducer,
    expandDestinationNotesReducer,
    expandDestinationReducer,
    expandDestinationsReducer,

    auditGenerateEtaEmailSuccessReducer,

    moveDestinationUpReducer,
    moveDestinationDownReducer,

    prefillLaytimeEventDatesReducer,

    removeDestinationReducer,

    setDestinationLocationReducer,

    verifyEtaSuccessReducer
];
