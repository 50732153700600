import { createAction } from "@ngrx/store";

/* ACTIONS */
const ACTION_GRID_ROW_SELECT = "[Action Grid] Row Select";
const ACTION_GRID_SEARCH_SELECT = "[Action Grid] Search Select";
const ACTION_CALENDAR_VIEW_SELECT = "[Action Calendar] Select";
const ACTION_GRID_VIEW_SELECT = "[Action Grid] Select";
const ACTION_CALENDAR_GO_TO_FIXTURE_SELECT = "[Action Calendar] Go To Fixture Select";
const ACTION_CALENDAR_GO_TO_COA_SELECT = "[Action Calendar] Go To CoA Select";

export const actionGridRowSelectAction = createAction(ACTION_GRID_ROW_SELECT);
export const actionGridSearchSelectAction = createAction(ACTION_GRID_SEARCH_SELECT);
export const actionCalendarViewSelectAction = createAction(ACTION_CALENDAR_VIEW_SELECT);
export const actionGridViewSelectAction = createAction(ACTION_GRID_VIEW_SELECT);
export const actionCalendarGoToFixtureSelectAction = createAction(ACTION_CALENDAR_GO_TO_FIXTURE_SELECT);
export const actionCalendarGoToCoASelectAction = createAction(ACTION_CALENDAR_GO_TO_COA_SELECT);
