import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, map, switchMap, tap, withLatestFrom } from "rxjs/operators";

import { isNumber, update } from "@ops/shared";
import { opsAddArrayControl } from "@ops/state";

import { roundAndStringifyNumber } from "../../../../../../shared";
import { LaytimeCalculationHttpService } from "../../../../../services";
import { ActivityLocationId, idEq, createLaytimeEvent, LaytimeEvent, LtcFeatureState, LtcId, LtcState, toLaytimeEventForm, ActivityLocation } from "../../../../model";
import { getLaytimeEventPercentage } from "../../../../model/calculations/utils";
import { currentCalculationStateReducer } from "../../../reducer";
import { selectCurrentCalculationId } from "../../../selectors";
import { UpdateQueue } from "../../../update-queue";
import { selectCurrentActivityLocation } from "../../selectors";
import { selectCurrentLaytimeEvents } from "../selectors";
import { updateEventFormPercentageAction } from "./update-laytime-event";

/* ACTIONS */
export const ADD_ACTION_NAME = "[LTC Laytime Events Form] Add Laytime Event";
export const preAddLaytimeEventAction = createAction(
    "[LTC Laytime Events Form] Pre Add Laytime Event",
    props<{ laytimeEvent?: LaytimeEvent; index?: number; setFocus?: boolean }>()
);
export const addLaytimeEventFormAction = createAction(`${ADD_ACTION_NAME} Form`, props<{ laytimeEvent?: LaytimeEvent; index?: number; setFocus?: boolean }>());
export const addLaytimeEventAction = createAction(
    ADD_ACTION_NAME,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; laytimeEvent: LaytimeEvent; index?: number; setFocus?: boolean }>()
);

export const addLaytimeEventSuccessAction = createAction(
    `${ADD_ACTION_NAME} Success`,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; laytimeEvent: LaytimeEvent }>()
);
export const addLaytimeEventFailAction = createAction(`${ADD_ACTION_NAME} Fail`, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; error: Error }>());

/* REDUCERS */
export const addLaytimeEventReducer: On<LtcState> = on(addLaytimeEventAction, (state, { laytimeEvent, activityLocationId, index, setFocus }) => {
    const updateFns: Evolver = {
        calculation: { activityLocations: update(idEq(activityLocationId), R.evolve({ laytimeEvents: isNumber(index) ? R.insert(index, laytimeEvent) : R.append(laytimeEvent) })) },
        laytimeEventForms: isNumber(index)
            ? opsAddArrayControl(toLaytimeEventForm(laytimeEvent), index, setFocus ? { focusControlName: "date" } : {})
            : opsAddArrayControl(toLaytimeEventForm(laytimeEvent), setFocus ? { focusControlName: "date" } : {})
    };
    return currentCalculationStateReducer(state, R.evolve(updateFns));
});

/* EFFECTS */
export const preAddLaytimeEventEffect$ = (actions$: Actions, store: Store<LtcFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType(preAddLaytimeEventAction),
            withLatestFrom(store.select(selectCurrentLaytimeEvents)),
            switchMap(([{ laytimeEvent, index, setFocus }, laytimeEvents]) => {
                if (index === 0 && !!laytimeEvents?.[0]) {
                    return [updateEventFormPercentageAction({ controlId: laytimeEvents?.[0].form.id as string }), addLaytimeEventFormAction({ laytimeEvent, index, setFocus })];
                }

                return [addLaytimeEventFormAction({ laytimeEvent, index, setFocus })];
            })
        )
    );

export const addLaytimeEventFormEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(addLaytimeEventFormAction),
            withLatestFrom(store.select(selectCurrentCalculationId), store.select(selectCurrentActivityLocation)),
            map(([{ laytimeEvent, index, setFocus }, ltcId, activityLocation]) => {
                if (!laytimeEvent) {
                    const percentage = getNewLaytimeEventPercentage(activityLocation, index);
                    laytimeEvent = createLaytimeEvent(activityLocation.laytimeEvents, index, percentage, undefined);
                }
                return addLaytimeEventAction({
                    ltcId,
                    activityLocationId: activityLocation.id,
                    laytimeEvent,
                    index,
                    setFocus
                });
            }),
            tap((action) => updateQueue.enqueue(action, addLaytimeEvent))
        )
    );

const getNewLaytimeEventPercentage = (activityLocation: ActivityLocation | undefined, index: number | undefined) => {
    if (!activityLocation?.laytimeEvents) {
        return undefined;
    }
    const laytimeEvent = isNumber(index) ? activityLocation.laytimeEvents[index - 1] : R.last(activityLocation.laytimeEvents);
    const eventDate = laytimeEvent?.date;
    if (!eventDate) {
        return undefined;
    }
    const percentage = getLaytimeEventPercentage(undefined, eventDate, eventDate, activityLocation);
    return roundAndStringifyNumber(percentage, 2);
};

export const addLaytimeEvent = (
    { ltcId, activityLocationId, laytimeEvent, index }: ReturnType<typeof addLaytimeEventAction>,
    laytimeCalculationHttpService: LaytimeCalculationHttpService
) =>
    laytimeCalculationHttpService.addLaytimeEvent(ltcId, activityLocationId, laytimeEvent, index).pipe(
        map(() => addLaytimeEventSuccessAction({ ltcId, activityLocationId, laytimeEvent })),
        catchError((error) => of(addLaytimeEventFailAction({ ltcId, activityLocationId, error })))
    );
