import { On } from "@ngrx/store";
import { Boxed, setValue, SetValueAction, unbox, updateGroup } from "ngrx-forms";

import { CargoBerthActivityType, QuantityUnit } from "@ops/shared/reference-data";

import { updateAssociatedCargoForm } from "./shared";
import { Division } from "../../../shared/models";
import { findAllSpecialisedCargoDestinationElements } from "../../cargoes/form/specialised/specialised-cargo-functions";
import { AssociatedCargoForm, FixturesState, isDivision, VoyageState } from "../../model";
import { voyageStateReducer } from "../../voyage/reducer";

/**
 * Specialised - prefills the discharge quantity + unit from a load quantity + unit when the load quantity/quantity unit is set.
 */
export const prefillAssociatedCargoDischargeSpecialisedReducer: On<FixturesState> = {
    types: [SetValueAction.TYPE],
    reducer: (state: FixturesState, action: SetValueAction<number | Boxed<QuantityUnit>>): FixturesState => {
        const controlPath = action.controlId.split(".");
        if (
            controlPath.length < 2 ||
            controlPath[controlPath.length - 3] !== "associatedCargoes" ||
            (controlPath[controlPath.length - 1] !== "quantity" && controlPath[controlPath.length - 1] !== "quantityUnit")
        ) {
            return state;
        }

        return voyageStateReducer(state, controlPath[0], (voyageState, fixtureState) => {
            if (!isDivision(fixtureState, Division.specialisedProducts)) {
                return voyageState;
            }

            const berthIndex = Number(controlPath[4]);
            const activityIndex = Number(controlPath[6]);
            const associatedCargoIndex = Number(controlPath[8]);

            const voyageForm = voyageState.form.value;
            const destinationForm = voyageForm.destinations[Number(controlPath[2])];
            const berthForm = destinationForm.berths[berthIndex];
            const activityForm = berthForm.activities[activityIndex];
            const associatedCargoForm = activityForm.associatedCargoes[associatedCargoIndex];

            const activityType = unbox(activityForm.type);

            if (!activityType || activityType.id !== CargoBerthActivityType.Load.id || !associatedCargoForm.cargoId) {
                return voyageState;
            }

            const dischargeCargoElements = findAllSpecialisedCargoDestinationElements(associatedCargoForm.cargoId, CargoBerthActivityType.Discharge, voyageForm.destinations);
            const dischargeAssociatedCargoForm = dischargeCargoElements?.associatedCargo;

            // We have to take the action value as the forms reducer runs after this one
            const field = controlPath[controlPath.length - 1] as "quantity" | "quantityUnit";
            const newQuantity = (field === "quantity" ? action.value : associatedCargoForm.quantity) as number;
            const newQuantityUnit = (field === "quantityUnit" ? action.value : associatedCargoForm.quantityUnit) as Boxed<QuantityUnit>;

            const updateProps = {
                destinationId: dischargeCargoElements?.destination.id,
                berthId: dischargeCargoElements?.berth.id,
                activityId: dischargeCargoElements?.activity.activityId,
                associatedCargoId: dischargeAssociatedCargoForm?.associatedCargoId
            };

            return <VoyageState>{
                ...voyageState,
                form: updateAssociatedCargoForm(
                    updateProps,
                    updateGroup<AssociatedCargoForm>({
                        quantity: setValue<number | undefined>(newQuantity),
                        quantityUnit: setValue<Boxed<Readonly<QuantityUnit>> | undefined>(newQuantityUnit)
                    })
                )(voyageState.form)
            };
        });
    }
};
