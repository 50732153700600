import * as moment from "moment";
import { User } from "src/app/fixture/shared/models";
import { Enumeration } from "src/app/shared/reference-data/enumeration";

export class ActionFormModel {
    actionId?: string;
    fixtureId?: string;
    coaId?: string;
    type: Enumeration;
    summary?: string;
    description?: string;
    charterer?: string;
    dueDate?: Date;
    priority: Enumeration;
    assignedTo: User[] = [];
    updatedDate?: string;
    lastUpdatedBy?: User;
    isComplete = false;
    notificationDates?: Date[] = [];
    notificationRecipients?: User[] = [];
    laycanTo?: string;
    laycanFrom?: string;
    vesselName?: string;
    fixtureNumber?: string;
    cpDate?: string;
    defaultRecipients?: User[];
    isNew = false;
    isFirstNew = false;
    fixtureSource: Enumeration;

    get isOverdue(): boolean {
        if (this.isComplete) {
            return false;
        }

        const now = moment();
        const due = moment(this.dueDate);

        return due.isBefore(now);
    }

    get isPending(): boolean {
        if (this.isComplete) {
            return false;
        }

        const now = moment();
        const due = moment(this.dueDate);

        return due.isAfter(now);
    }
}
