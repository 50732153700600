<ops-specialised-cargo-form
    [form]="form"
    [laycanToDate]="laycanToDate$ | async"
    [loadEtaVerifyEnabled]="loadEtaVerifyEnabled$ | async"
    [loadEtaVerifiedAudit]="loadEtaVerifiedAudit$ | async"
    [showLoadEtaWarning]="hasLoadEtaWarning$ | async"
    [dischargeEtaVerifyEnabled]="dischargeEtaVerifyEnabled$ | async"
    [dischargeEtaVerifiedAudit]="dischargeEtaVerifiedAudit$ | async"
    [showDischargeEtaWarning]="hasDischargeEtaWarning$ | async"
    [loadDestinationId]="loadDestinationId$ | async"
    [dischargeDestinationId]="dischargeDestinationId$ | async"
    [readonly]="readonly"
    (verifyEta)="verifyEta($event)"
>
</ops-specialised-cargo-form>
