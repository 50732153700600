import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment-timezone";
import { DateRange } from "../../date-utils/date-range";

/**
 * @deprecated Use MaritimeDatePipe
 */
@Pipe({
    name: "timezoneDateRange"
})
export class TimezoneDateRangePipe implements PipeTransform {
    transform(value: DateRange, format: string, timezone: string): string {
        if (!value || !value.from || !value.to) {
            return null;
        }

        const from = timezone ? moment(value.from).tz(timezone) : moment(value.from);
        const formattedFrom = from.format(format);
        const to = timezone ? moment(value.to).tz(timezone) : moment(value.to);
        let formattedTo = to.format(format);

        if (from.isSame(to)) {
            return formattedFrom;
        } else if (from.isSame(to, "day")) {
            formattedTo = to.format("HH:mm");
        }
        return `${formattedFrom} - ${formattedTo}`;
    }
}
