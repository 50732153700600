import { User } from "src/app/fixture/shared/models";
import { Command } from "../../../mediator/commands/command";
import { Destination } from "../../../shared/models";
import { AddDemurrageClaimCommandHandler } from "./add-demurrage-claim.command-handler";

export class AddDemurrageClaimCommand extends Command {
    constructor(readonly currentUser: User, readonly destinations: Destination[]) {
        super(AddDemurrageClaimCommandHandler, currentUser);
    }
}
