import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { CommandHandler } from "../../../../mediator/command-handlers/command-handler";
import { Fixture, Period } from "../../../../shared/models";
import { sortPeriods } from "../periods-sorter";
import { ClonePeriodCommand } from "./clone-period.command";

@Injectable({
    providedIn: "root"
})
export class ClonePeriodCommandHandler implements CommandHandler {
    handle = (fixture: Fixture, command: ClonePeriodCommand) => {

        const index = command.payload.index;
        const currentPeriod = fixture.periods[index];
        const periodClone: Period = JSON.parse(JSON.stringify(currentPeriod));
        periodClone.hireId = Guid.create().toString();
        periodClone.offHires = [];
        fixture.periods.push(periodClone);
        fixture.periods = sortPeriods(fixture.periods);
    }
}
