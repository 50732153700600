import { Injectable } from "@angular/core";

import { hasValue } from "@ops/shared";

import { Fixture } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class ClaimsMissingReceivedFromOwnerDateRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        fixture.demurrage?.claims.forEach((claim, index) => {
            if (!hasValue(claim.receivedFromOwnerDate)) {
                const message = "Missing Received From Owner date";
                const path = [
                    new FixtureWarningPathSegment(null, "Voyage"),
                    new FixtureWarningPathSegment("demurrage", "Demurrage"),
                    FixtureWarningPathSegment.fromIndex(index, "Claim", `Claim ${index + 1}`),
                    new FixtureWarningPathSegment("receivedFromOwnerDate", "Received From Owner")
                ];
                const warningHash = new WarningHashBuilder()
                    .withFixture(fixture.fixtureId)
                    .withDemurrageClaim(claim.demurrageClaimId)
                    .withCategory("claim")
                    .withMessage(message)
                    .build();
                warnings.push(new FixtureWarning("claim", message, path, dismissedWarningRecord(warningHash)));
            }
        });

        return warnings;
    }
}
