import { Injectable } from "@angular/core";
import { CommandHandler } from "src/app/fixture/mediator/command-handlers/command-handler";
import { Fixture } from "src/app/fixture/shared/models";
import { CpSpeedFormModel } from "src/app/fixture/shared/models/form-models/cp-speed.model";
import { UpdateCpSpeedCommand } from "./update-cp-speed.command";

@Injectable({
    providedIn: "root"
})
export class UpdateCpSpeedCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateCpSpeedCommand) {
        const cpSpeedModelFull = command.payload.full as CpSpeedFormModel;
        const cpSpeedModelEco = command.payload.eco as CpSpeedFormModel;

        if (!cpSpeedModelFull || !cpSpeedModelEco) {
            throw new Error("Given CP Speed form models are invalid.");
        }

        fixture.cpSpeedAndConsumption.ballastFull = cpSpeedModelFull.ballast;
        fixture.cpSpeedAndConsumption.ladenFull = cpSpeedModelFull.laden;
        fixture.cpSpeedAndConsumption.ballastEco = cpSpeedModelEco.ballast;
        fixture.cpSpeedAndConsumption.ladenEco = cpSpeedModelEco.laden;
    }
}
