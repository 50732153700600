import { createSelector } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";

import { CargoBerthActivityType } from "@ops/shared/reference-data";
import { getIsTransient } from "@ops/state";

import { VerifiedTimestamp } from "../../../shared/components/timestamp/verified-timestamp.model";
import { Berth, Destination, Division } from "../../shared/models";
import { selectCurrentVoyageCargoItems } from "../cargoes";
import { selectCurrentVoyageDestination, selectCurrentVoyageDestinationFormState } from "../destinations";
import { selectCurrentFixtureDivision } from "../fixture";
import { berthExpandedKey, BerthForm, berthHasActivityTypes, BerthId, DestinationForm, DestinationId, FixtureFeatureState } from "../model";
import { selectCurrentVoyageExpandedSections } from "../voyage";
import { BerthSummary, getBerthSummary } from "./berth-summary";

export declare type BerthState = Readonly<{
    berthId: BerthId;
    destinationId: DestinationId;
    summary: BerthSummary;
    expanded: boolean;
    canOrder: boolean;
    canCloneOrRemove: boolean;
}>;

export { BerthSummary };

export const selectCurrentVoyageBerths = createSelector(
    selectCurrentVoyageDestinationFormState,
    selectCurrentVoyageCargoItems,
    selectCurrentVoyageExpandedSections,
    selectCurrentFixtureDivision,
    (destinationForm, cargoes, expandedSections, division) => {
        if (!destinationForm) {
            return null;
        }

        const destinationId = destinationForm.value.id;

        return destinationForm.controls.berths.controls.map(({ value: berth, isInvalid }, i, berths) => {
            const canCloneOrRemove = division !== Division.specialisedProducts || !berthHasActivityTypes(berth, CargoBerthActivityType.Load, CargoBerthActivityType.Discharge);

            return <BerthState>{
                berthId: berth.id,
                destinationId,
                summary: getBerthSummary(cargoes, berth, i, berths.length),
                expanded: expandedSections[berthExpandedKey(destinationId, berth.id)],
                isInvalid,
                canCloneOrRemove,
                canOrder: !berth.etb
            };
        });
    }
);

export const selectCurrentDestinationBerthFormState = createSelector<
    FixtureFeatureState,
    { destinationId: DestinationId; berthId: BerthId },
    FormGroupState<DestinationForm>,
    FormGroupState<BerthForm>
>(selectCurrentVoyageDestinationFormState, (destinationForm, { berthId }) =>
    destinationForm ? destinationForm.controls.berths.controls.find((x) => x.value.id === berthId) : null
);

export const selectCurrentVoyageBerth = createSelector<FixtureFeatureState, { destinationId: DestinationId; berthId: BerthId }, Destination, Berth>(
    selectCurrentVoyageDestination,
    (destination, { berthId }) => destination && destination.berths.find((berth) => berth.id === berthId)
);

export const selectCurrentVoyageBerthEtbVerified = createSelector(selectCurrentVoyageBerth, (berth) => {
    if (!berth || (!berth.etbLastVerifiedBy && !berth.etbLastVerifiedDate)) {
        return null;
    }

    return <VerifiedTimestamp>{ user: berth.etbLastVerifiedBy, date: berth.etbLastVerifiedDate };
});

export const selectCurrentVoyageBerthPortTimesEmailAudit = createSelector(selectCurrentVoyageBerth, (berth) => (berth ? berth.laytimeEmailGenerated : null));

export const selectCurrentVoyageBerthIsTransient = createSelector(selectCurrentDestinationBerthFormState, (berthForm) => berthForm && getIsTransient(berthForm));
