import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { AppConfigService } from "src/app/core/app-config.service";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private appConfigService: AppConfigService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.authService.token && req.url.indexOf(this.appConfigService.config.apiGatewayUrl) !== -1) {
            return EMPTY;
        }

        if (!!this.authService.token) {
            req = req.clone({
                headers: req.headers.set("X-Clarksons-Security-Cloud", this.authService.token)
            });
        }

        return next.handle(req);
    }
}
