import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { combineLatest, Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";

import { AuthService, DATA_ACCESS_ERROR_MESSAGE, FeatureToggleService, NotificationService } from "@ops/core";

@Injectable({
    providedIn: "root"
})
export class LtcAuthGuard implements CanActivate {
    constructor(private authService: AuthService, private featureToggleService: FeatureToggleService, private notificationService: NotificationService) {}

    canActivate(): Observable<boolean> {
        return combineLatest([of(this.featureToggleService.isFeatureEnabled("LaytimeCalculatorV2")), this.authService.hasRole("Laytime")]).pipe(
            map(([isFeatureEnabled, hasRole]) => isFeatureEnabled && hasRole),
            tap((canActivate) => {
                if (!canActivate) {
                    this.notificationService.error("You do not have access to laytime calculations", DATA_ACCESS_ERROR_MESSAGE, true);
                }
            })
        );
    }
}
