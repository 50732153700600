import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { EmailPreviewModel } from "./email-preview.model";

@Component({
    selector: "ops-email-preview",
    templateUrl: "./email-preview.component.html",
    styleUrls: ["./email-preview.component.scss"]
})
export class EmailPreviewComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public model: EmailPreviewModel) {}

    copyToClipboard(value: string) {
        const textarea = document.createElement("textarea");
        textarea.style.position = "fixed";
        textarea.style.left = "0";
        textarea.style.top = "0";
        textarea.style.opacity = "0";
        textarea.value = value;
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
    }
}
