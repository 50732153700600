import { v4 as uuid } from "uuid";

import { Identity } from "@ops/shared";

import { CargoId } from "../../../state/model";
import { CargoRateActivity } from "./cargo-rate-activity.dto";

export type CargoAllowedRateId = Identity<string, "CargoAllowedRateId">;
export const createCargoAllowedRateId = (): CargoAllowedRateId => uuid() as CargoAllowedRateId;

export interface CargoAllowedRate {
    id: CargoAllowedRateId;
    cargoId: CargoId;
    loadCargoRateActivity: CargoRateActivity;
    dischargeCargoRateActivity: CargoRateActivity;
}
