import { createAction, on, On, props } from "@ngrx/store";
import { FormArrayState, removeArrayControl } from "ngrx-forms";
import { Evolver } from "ramda";
import * as R from "ramda";

import { createDeductionFormsState, Deduction, LtcState } from "../../../../model";
import { currentCalculationStateReducer } from "../../../reducer";

/* ACTIONS */
const REMOVE_ACTION_NAME = "[LTC Deductions Form] Remove Deduction";
export const removeDeductionAction = createAction(REMOVE_ACTION_NAME, props<{ id: string }>());

const CLEAR_ACTION_NAME = "[LTC Deductions Form] Clear Deduction";
export const clearDeductionsAction = createAction(CLEAR_ACTION_NAME);

/* REDUCERS */
export const removeDeductionReducer: On<LtcState> = on(removeDeductionAction, (state, { id }) => {
    const updateFns: Evolver = {
        deductionForms: (forms: FormArrayState<Deduction>) => removeArrayControl(forms.value.findIndex((x) => x.id === id))(forms)
    };

    return currentCalculationStateReducer(state, R.evolve(updateFns));
});

export const clearDeductionsReducer: On<LtcState> = on(clearDeductionsAction, (state) =>
    currentCalculationStateReducer(state, (ltcState) => ({
        ...ltcState,
        deductionForms: createDeductionFormsState(ltcState.currentActivityLocationId, [])
    }))
);
