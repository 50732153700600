import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "ops-worksheet-save-button",
    templateUrl: "./worksheet-save-button.component.html",
    styleUrls: ["./worksheet-save-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorksheetSaveButtonComponent {
    @Input() disable: boolean;
    @Input() showDropdown: boolean;

    @Output() readonly save = new EventEmitter();
    @Output() readonly saveAsNew = new EventEmitter();
}
