import { createAction, on, On, props } from "@ngrx/store";
import { createFormGroupState, focus, updateGroup } from "ngrx-forms";
import {
    CoasState,
    VesselId,
    VesselNominationForm,
    vesselNominationForm,
    vesselNominationToForm
} from "../../../model";
import { currentLiftingStateReducer } from "../../reducer";

/* ACTIONS */
export const addVesselNominationAction = createAction("[Vessel Nomination Form] Add Vessel Nomination");
export const editVesselNominationAction = createAction("[Vessel Nomination Form] Edit Vessel Nomination", props<{ vesselId: VesselId }>());
export const cancelVesselNominationEditAction = createAction("[Vessel Nomination Form] Cancel Vessel Nomination Edit");

/* REDUCERS */
export const editVesselNominationReducer: On<CoasState> = on(addVesselNominationAction, editVesselNominationAction, (state, action) =>
    currentLiftingStateReducer(state, (liftingState) => {
        const form =
            action.type === addVesselNominationAction.type
                ? vesselNominationForm()
                : vesselNominationToForm(liftingState.lifting.vessels.find((x) => x.vesselId === action.vesselId));

        const initialFormState = createFormGroupState(form.vesselId, form);
        const formState = updateGroup<VesselNominationForm>({ vessel: focus })(initialFormState);
        return { ...liftingState, vesselNominationForm: formState };
    })
);

export const cancelVesselNominationEditReducer: On<CoasState> = on(cancelVesselNominationEditAction, (state) => currentLiftingStateReducer(state, { vesselNominationForm: null }));
