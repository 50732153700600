import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Company, isOwnerOrCharterer } from "../../../coa/state";

@Component({
    selector: "ops-coa-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoaContactComponent {
    @Input() company: Company;

    @Output() readonly removeCompany = new EventEmitter<Company>();

    get showDeleteButton(): boolean {
        return !this.company.isReadonly && !isOwnerOrCharterer(this.company);
    }
}
