import { Injectable } from "@angular/core";
import { Voyage } from "src/app/fixture/shared/models";
import { CommandHandler } from "../../../mediator/command-handlers/command-handler";
import { UpdateCargoAllowedRatesCommand } from "./update-cargo-allowed-rates.command";

@Injectable({
    providedIn: "root"
})
export class UpdateCargoAllowedRatesCommandHandler implements CommandHandler {
    handle(voyage: Voyage, command: UpdateCargoAllowedRatesCommand) {
        if (!command || !command.cargoAllowedRates) {
            throw new Error("Given voyage form model is invalid.");
        }
        const value = command.cargoAllowedRates;

        const cargoAllowedRateToUpdate = voyage.cargoAllowedRates.find((c) => c.cargoId === value.cargoId);
        cargoAllowedRateToUpdate.loadCargoRateActivity = value.loadCargoRateActivity;
        cargoAllowedRateToUpdate.dischargeCargoRateActivity = value.dischargeCargoRateActivity;
    }
}
