<div *ngIf="bunkersConsumedArray && bunkersConsumedArray.length > 0; else noBunkers" [formGroup]="parentForm" class="card embedded-card-1">
    <div class="card-header row no-gutters align-items-center">Bunker Consumed at Sea</div>
    <div class="bunkers-at-sea">
        <table class="table table-striped table-compact">
            <thead>
                <tr>
                    <th scope="col" class="bunker-type align-middle">Type</th>
                    <th scope="col" class="bunker-quantity align-middle text-right">Quantity Consumed</th>
                    <th scope="col" class="bunker-price align-middle text-right">Price per MT</th>
                    <th *ngIf="isSimpleMode" scope="col" class="button-column-fixed"></th>
                    <th *ngIf="isSimpleMode" scope="col" class="button-column-fixed"></th>
                </tr>
            </thead>
            <tbody formArrayName="bunkersConsumed">
                <tr *ngFor="let bunkersConsumed of bunkersConsumedArray; index as i; last as isLast" [formGroupName]="i" class="form-group">
                    <td>
                        <input
                            *ngIf="!isSimpleMode"
                            type="text"
                            class="form-control input-sm text-left"
                            readonly
                            formControlName="bunkerTypeName" />
                        <ops-refdata-dropdown
                            *ngIf="isSimpleMode"
                            type="BunkerType"
                            formControlName="bunkerType"
                            data-test-associated-cargo-unit
                        ></ops-refdata-dropdown>
                    </td>
                    <td>
                        <input
                            type="text"
                            class="form-control input-sm text-right"
                            [readonly]="!isSimpleMode"
                            formControlName="quantityConsumed"
                            [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                        />
                    </td>
                    <td>
                        <input type="text" class="form-control input-sm text-right" [opsNumber]="{ precision: 3 }" formControlName="pricePerMt" />
                    </td>
                    <td *ngIf="isSimpleMode">
                        <button
                            *ngIf="isLast"
                            [disabled]="bunkersConsumed.disabled"
                            (click)="addBunkerConsumed()"
                            type="button"
                            class="btn-add has-icon icon--add-circle-outline"
                            data-test-add-bunker-consumed-button
                        ></button>
                    </td>
                    <td *ngIf="isSimpleMode">
                        <button
                            [disabled]="bunkersConsumed.disabled"
                            (click)="deleteBunkerConsumed(i)"
                            type="button"
                            class="btn-delete has-icon icon--delete"
                            data-test-remove-bunker-consumed-button
                        ></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #noBunkers>
    <ops-no-data-panel
        heading="Bunkers"
        title="No bunkers have been added"
        [useNewButton]="isSimpleMode"
        newButtonText="BUNKER"
        [disabled]="readonly"
        (addNew)="addBunkerConsumed()"
    ></ops-no-data-panel>
</ng-template>
