import { AbstractControl, ValidatorFn } from "@angular/forms";

export class RangeInValidator {
    static validate(dateField: string, rangeFromField: string, rangeToField: string): ValidatorFn {
        const errorKey = "notInRange";

        return (control: AbstractControl): null => {
            const dateControl = control.get(dateField);
            const rangeFromControl = control.get(rangeFromField);
            const rangeToControl = control.get(rangeToField);

            if (
                !!dateControl &&
                !!rangeFromControl &&
                !!rangeToControl &&
                !!dateControl.value &&
                !!rangeFromControl.value &&
                !!rangeToControl.value &&
                (dateControl.value < rangeFromControl.value || dateControl.value > rangeToControl.value)
            ) {
                dateControl.setErrors({ [errorKey]: true });
            } else {
                if (dateControl.errors) {
                    delete dateControl.errors[errorKey];
                }
            }

            return null;
        };
    }
}
