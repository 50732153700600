<div class="location-container">
    <div class="allowance-row" *ngIf="!isEditing">
        <div>
            <span data-cy="allowance" *ngIf="location.allowance; else unset">
                <span  [innerHtml]="location.allowance | opsNumberStyledDecimal: false:2:3"></span>
                {{ location.allowanceUnit }}
            </span>
            <span data-cy="extra-hours" class="extra-hours" *ngIf="location.extraHours">
                +<span [innerHtml]="location.extraHours | opsNumberStyledDecimal: false:0:3"></span>
                {{ hoursText }}
            </span>
        </div>
        <div class="reversible" *ngIf="location.reversible">
            <ops-description-icon [type]="location.reversible" data-cy="reversible"></ops-description-icon>
        </div>
    </div>
    <div class="location-form p-grid" *ngIf="isEditing">
        <div class="p-field p-col-5">
            <label data-cy="allowance-unit-label">{{ cpAllowanceText }}</label>
            <ops-quantity-input
                placeholder="Select"
                appendTo="body"
                [dropdownValues]="allowanceUnits"
                [inputNumberFormat]="{ precision: 3, minimumFractionDigits: 2, showTrailingZeros: false }"
                [allowClear]="true"
                [ngrxFormControlState]="form.controls.allowance"
                data-cy="cp-allowance"
            ></ops-quantity-input>
        </div>
        <div class="p-field p-col-2">
            <label>Extra Hours</label>
            <input type="text" class="p-inputtext text-right"
                   [opsNumber]="{ precision: 3, minimumFractionDigits: 0, showTrailingZeros: false }"
                   [ngrxFormControlState]="form.controls.extraHours"  data-cy="extra-hours" />
        </div>
        <div class="p-field p-col-3">
            <label>Reversible</label>
            <ng-select class="ops-select" [ngrxFormControlState]="form.controls.reversible" [items]="reversibleLaytimeTypes"
                       [clearable]="false" [selectOnTab]="true" appendTo="body"  data-cy="reversible">
            </ng-select>
        </div>
        <div class="p-field p-col-1 delete-col">
            <ops-icon-button
                type="delete"
                (click)="remove.emit(location.locationId)"
                data-cy="remove-cargo-terms-location"
            ></ops-icon-button>
        </div>
    </div>
    <div>
        <div data-cy="location" class="name-row" *ngIf="location.name; else unset">
            <img class="ops-location-flag" *ngIf="location.flagImgUrl" [src]="location.flagImgUrl" data-test-flag-image  [attr.alt]="location.countryName + ' flag image'"/>
            {{ location.name }}<ng-container *ngIf="location.countryName"> ({{ location.countryName }})</ng-container>
        </div>
    </div>
</div>

<ng-template #unset>
    <span class="unset">-</span>
</ng-template>
