import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { createFormGroupState, focus, updateGroup } from "ngrx-forms";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, withLatestFrom } from "rxjs/operators";

import { WorksheetHttpService } from "../../../left-bar/worksheets/service/worksheet-http.service";
import { WorksheetId, WorksheetRenameForm } from "../../model";
import { worksheetStateReducer } from "../reducer";
import { selectWorksheetsFeature } from "../selectors";
import { getWorksheetState, WorksheetFeatureState, WorksheetsState } from "../state";
import { getWorksheetListRenameFormId, worksheetRenameForm } from "../utils";

/* ACTIONS */
export const editSavedWorksheetNameAction = createAction("[Worksheets] Edit Saved Worksheet Name", props<{ worksheetId: WorksheetId }>());
export const cancelEditSavedWorksheetNameAction = createAction("[Worksheets] Cancel Edit Saved Worksheet Name", props<{ worksheetId: WorksheetId }>());

const RENAME_SAVED_WORKSHEET_ACTION_NAME = "[Worksheets] Rename Saved Worksheet";
export const renameSavedWorksheetAction = createAction(RENAME_SAVED_WORKSHEET_ACTION_NAME, props<{ worksheetId: WorksheetId }>());
export const renameSavedWorksheetSuccessAction = createAction(`${RENAME_SAVED_WORKSHEET_ACTION_NAME} Success`, props<{ worksheetId: WorksheetId; name: string }>());
export const renameSavedWorksheetFailAction = createAction(`${RENAME_SAVED_WORKSHEET_ACTION_NAME} Fail`, props<{ error: Error; worksheetId: WorksheetId }>());

/* REDUCERS */
export const editSavedWorksheetNameReducer: On<WorksheetsState> = on(editSavedWorksheetNameAction, (state, { worksheetId }) => {
    const worksheetState = getWorksheetState(state, worksheetId);
    const groupState = createFormGroupState(
        getWorksheetListRenameFormId(worksheetState.worksheet.worksheetId),
        worksheetRenameForm(worksheetState.worksheet.name, worksheetState.worksheet.worksheetId)
    );
    const focused = updateGroup<WorksheetRenameForm>({ name: focus })(groupState);

    return worksheetStateReducer(state, worksheetId, { renameForm: focused });
});

export const cancelEditSavedWorksheetNameReducer: On<WorksheetsState> = on(cancelEditSavedWorksheetNameAction, (state, { worksheetId }) =>
    worksheetStateReducer(state, worksheetId, { renameForm: null })
);

export const renameSavedWorksheetReducer: On<WorksheetsState> = on(renameSavedWorksheetAction, (state, { worksheetId }) =>
    worksheetStateReducer(state, worksheetId, { saveStatus: "persisting" })
);

export const renameSavedWorksheetSuccessReducer: On<WorksheetsState> = on(renameSavedWorksheetSuccessAction, (state, { worksheetId, name }) => {
    const worksheetState = getWorksheetState(state, worksheetId);

    let updatedState = worksheetStateReducer(state, worksheetId, { saveStatus: "persisted", renameForm: null, error: null, worksheet: { ...worksheetState.worksheet, name } });

    if (state.selectedWorksheetId === worksheetId) {
        updatedState = {
            ...updatedState,
            currentWorksheet: { ...updatedState.currentWorksheet, name }
        };
    }

    return updatedState;
});

export const renameSavedWorksheetFailReducer: On<WorksheetsState> = on(renameSavedWorksheetFailAction, (state, { error, worksheetId }) =>
    worksheetStateReducer(state, worksheetId, { saveStatus: "failed", error: { error, message: "Unable to rename worksheet", isRenameError: true } })
);

/* EFFECTS */
export const renameSavedWorksheetEffect$ = (actions$: Actions, store: Store<WorksheetFeatureState>, worksheetHttpService: WorksheetHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(renameSavedWorksheetAction),
            withLatestFrom(store.select(selectWorksheetsFeature)),
            map(([{ worksheetId }, state]) => {
                const worksheetState = getWorksheetState(state, worksheetId);
                return worksheetState?.renameForm?.isValid && { worksheetId, oldName: worksheetState.worksheet.name, name: worksheetState.renameForm.value.name };
            }),
            filter((obj) => !!obj),
            exhaustMap(({ worksheetId, oldName, name }) => {
                if (oldName === name) {
                    return of(renameSavedWorksheetSuccessAction({ worksheetId, name }));
                }

                return worksheetHttpService.rename(worksheetId, name).pipe(
                    map(() => renameSavedWorksheetSuccessAction({ worksheetId, name })),
                    catchError((error) => of(renameSavedWorksheetFailAction({ error, worksheetId })))
                );
            })
        )
    );
