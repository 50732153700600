import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfigService } from ".";
import { ErrorMapppings, UrlErrorMapping } from "./error-mappings";

@Injectable({
    providedIn: "root"
})
export class HttpErrorResponseAnalyzer {
    private apiGatewayUrl: string;

    constructor(appConfigService: AppConfigService) {
        this.apiGatewayUrl = appConfigService.config.apiGatewayUrl;
    }

    getErrorMessageId(httpErrorResponse: HttpErrorResponse): number {
        const foundMapping = ErrorMapppings.urlErrorMappings.filter(
            (x: UrlErrorMapping) =>
                x.statusCode === httpErrorResponse.status &&
                new RegExp(this.apiGatewayUrl.toLowerCase() + "/api/v1.0/" + x.relativeUrlTemplate.toLowerCase()).test(httpErrorResponse.url.toLowerCase())
        )[0];

        if (foundMapping) {
            return foundMapping.errorMessageId;
        }

        return 0;
    }
}
