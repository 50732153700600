<div>
    <div class="p-d-flex">
        <ops-voyage-activity
            [name]="associatedCargoesByVoyageActivity.voyageActivityName"
            [activity]="associatedCargoesByVoyageActivity.activity"
            [flagImageUrl]="associatedCargoesByVoyageActivity.flagImageUrl"
            [type]="'header'"
        ></ops-voyage-activity>
        <div class="p-field p-field-checkbox" *ngIf="isSelectAllEnabled">
            <input id="{{associatedCargoesByVoyageActivity.voyageActivityId}}_all_selected" type="checkbox"
                   [checked]="associatedCargoesByVoyageActivity.selectAll" class="p-checkbox"
                   (change)="selectAllByVoyageActivity.emit(associatedCargoesByVoyageActivity.voyageActivityId)"
                    data-cy="select-all-cargoes"/>
            <label for="{{associatedCargoesByVoyageActivity.voyageActivityId}}_all_selected">Select All</label>
        </div>
    </div>
    <div *ngIf="associatedCargoesByVoyageActivity.associatedCargoes; else noAssociatedCargoes"
         class="ops-ltc-associated-cargoes-container">
        <div *ngFor="let associatedCargo of associatedCargoesByVoyageActivity.associatedCargoes; trackBy: trackBy"
             (click)="selected.click()"
             class="ops-ltc-card" [class.ops-ltc-imported]="associatedCargo.imported" data-cy="location-cargo-in-calculation-panel">
            <input  type="checkbox" class="p-checkbox"
                   [ngrxFormControlState]="associatedCargo.selected" #selected (click)="$event.stopPropagation()" data-cy="select-cargo"/>
            <div class="ops-ltc-name" [title]="associatedCargo.cargoName" data-cy="cargo">{{associatedCargo.cargoName}}</div>
            <div data-cy="quantity">{{associatedCargo.quantity}}</div>
        </div>
    </div>
    <ng-template #noAssociatedCargoes>
        <div class="p-p-3">
            <span class="has-icon icon--lightbulb-outline"></span>
            <span class="p-ml-2">This location does not have any associated cargoes.</span>
            <span *ngIf="associatedCargoesByVoyageActivity.activity !== 'Transit'"> Please add them on the cargo tab.</span>
        </div>
    </ng-template>
</div>
