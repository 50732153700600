import { Injectable, Type } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { Email } from "src/app/shared/email";
import { ContactService } from "../../../left-bar/contacts/contact.service";
import { FixtureDataService } from "../../services/fixture-data.service";
import { VoyageDataService } from "../../services/voyage-data.service";
import { EmailGenerator } from "./email-generator";

@Injectable({
    providedIn: "root"
})
export class EmailGenerationService {
    constructor(private fixtureDataService: FixtureDataService, private voyageDataService: VoyageDataService, private contactService: ContactService) {}

    generate(generator: Type<EmailGenerator>): Observable<Email>;
    generate<TGenerator extends EmailGenerator<TOptions>, TOptions>(generator: Type<TGenerator>, options: TOptions): Observable<Email>;
    generate<TGenerator extends EmailGenerator<TOptions>, TOptions>(generator: Type<TGenerator>, options?: TOptions): Observable<Email> {
        const generatorInstance = new generator();

        return combineLatest([this.fixtureDataService.currentFixture$, this.voyageDataService.voyages$, this.contactService.companies$]).pipe(
            take(1),
            map(([fixture, voyages, companies]) => generatorInstance.generate(fixture, voyages, companies, options))
        );
    }
}
