import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroupState } from "ngrx-forms";
import { BehaviorSubject } from "rxjs";

import { Enumeration } from "../../../shared/reference-data";
import { ActivityForm } from "../../state";

@Component({
    selector: "ops-fixture-popup-activity-tab-header-form",
    templateUrl: "./fixture-popup-activity-tab-header-form.component.html",
    styleUrls: ["./fixture-popup-activity-tab-header-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupActivityTabHeaderFormComponent {
    private isHovered = false;
    private hasMouseEnteredDropdown = false;
    private isOpened = false;

    readonly enumerationCompare = Enumeration.compare;

    showLabel$ = new BehaviorSubject<boolean>(true);

    @Input() form: FormGroupState<ActivityForm>;
    @Input() isReadOnly: boolean;
    @Input() activityDropdownTypes: Enumeration[];

    onMouseEnter() {
        if (!this.isReadOnly) {
            this.isHovered = true;
            this.updateShowLabel();
            this.hasMouseEnteredDropdown = false;
            // if the user moves mouse too fast, sometimes mouseenter triggers,
            // but mouseleave does not so we need additional check
            setTimeout(() => {
                if (!this.hasMouseEnteredDropdown) {
                    this.onMouseLeave();
                }
            }, 100);
        }
    }

    onMouseEnterDropdown() {
        setTimeout(() => {
            this.hasMouseEnteredDropdown = true;
        });
    }

    onMouseLeave() {
        this.isHovered = false;
        this.updateShowLabel();
    }

    onOpen() {
        this.isOpened = true;
        this.updateShowLabel();
    }

    onClose() {
        this.isOpened = false;
        this.updateShowLabel();
    }

    private updateShowLabel() {
        this.showLabel$.next(!this.isHovered && !this.isOpened);
    }
}
