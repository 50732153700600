import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Store } from "@ngrx/store";
import * as R from "ramda";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { LegacyWorksheetRenameFormComponent } from "./legacy-worksheet-rename-form.component";
import { GridType } from "../../../fixture/shared/models/enums/grid-type";
import { Nullable } from "../../../shared";
import { LeftBarStateService } from "../../left-bar-state.service";
import { WorksheetView } from "../shared/worksheet.model";
import { WorksheetUtilities } from "../shared/worksheet.utils";
import { LegacyWorksheetActionType } from "../store/legacy-worksheet-action-type";
import {
    CancelRenameAction,
    DeselectWorksheetAction,
    RemoveErroredWorksheetAction,
    RemoveWorksheetAction,
    RenameWorksheetAction,
    SelectWorksheetAction,
    SetRenameAction
} from "../store/legacy-worksheet.actions";
import { LegacyWorksheetState } from "../store/legacy-worksheet.reducer";
import {
    selectWorksheetsNamesToShow,
    selectError,
    selectLoadingStatus,
    selectSelectedWorksheetId,
    selectWorksheetTypeToShow,
    selectWorksheetsToShow
} from "../store/legacy-worksheet.selectors";

@Component({
    selector: "ops-legacy-worksheets",
    templateUrl: "./worksheets-legacy.component.html",
    styleUrls: ["../styles/worksheets.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorksheetsLegacyComponent implements OnInit, OnDestroy {
    static componentName = "WorksheetsLegacyComponent";

    private readonly destroy$ = new Subject();
    private selectedWorksheetTypeToShow$ = new BehaviorSubject<Nullable<GridType>>(null);

    readonly nameInputId = "worksheetNameInput";

    loading$: Observable<boolean>;
    error$: Observable<Nullable<Error>>;
    selectedWorksheetId$: Observable<string>;

    worksheetsToShow$: Observable<Array<Nullable<WorksheetView>>>;
    worksheetNamesToShow$: Observable<Array<Nullable<string>>>;

    @ViewChildren(LegacyWorksheetRenameFormComponent) renameComponents: QueryList<LegacyWorksheetRenameFormComponent>;

    constructor(private store: Store<LegacyWorksheetState>, private leftBarStateService: LeftBarStateService) {}

    get worksheetTypeDisplayName() {
        switch (this.selectedWorksheetTypeToShow$.value) {
            case GridType.Claim: {
                return "Demurrage/Claims";
            }
            case GridType.Action: {
                return "Actions";
            }
            default: {
                return "Fixtures";
            }
        }
    }

    ngOnInit() {
        this.loading$ = this.store.select(selectLoadingStatus);
        this.error$ = this.store.select(selectError);
        this.selectedWorksheetId$ = this.store.select(selectSelectedWorksheetId);
        this.worksheetsToShow$ = this.store.select(selectWorksheetsToShow);
        this.worksheetNamesToShow$ = this.store.select(selectWorksheetsNamesToShow);

        this.store
            .select(selectWorksheetTypeToShow)
            .pipe(takeUntil(this.destroy$))
            .subscribe((type) => this.selectedWorksheetTypeToShow$.next(type));
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    selectWorksheet(worksheet: WorksheetView) {
        this.store.dispatch(new SelectWorksheetAction({ id: worksheet.worksheetId }));
    }

    performDefaultLayoutSearch() {
        this.store.dispatch(new DeselectWorksheetAction());
    }

    getErrorMessage(error: { source: LegacyWorksheetActionType; error: Error }) {
        switch (error.source) {
            case LegacyWorksheetActionType.SaveFail:
            case LegacyWorksheetActionType.RenameFail: {
                return WorksheetUtilities.getErrorMessage(error.error);
            }
            case LegacyWorksheetActionType.RemoveFail: {
                return "Unable to delete worksheet. Please try again later";
            }

            default: {
                return "";
            }
        }
    }

    hasSaveError(worksheet: WorksheetView) {
        return this.worksheetHasErrorType(worksheet, LegacyWorksheetActionType.SaveFail);
    }

    hasDeleteError(worksheet: WorksheetView) {
        return this.worksheetHasErrorType(worksheet, LegacyWorksheetActionType.RemoveFail);
    }

    startRenaming(worksheetId: string) {
        this.store.dispatch(new SetRenameAction({ id: worksheetId }));
        this.renameComponents?.find(R.propEq("worksheetId", worksheetId))?.focusInput();
    }

    cancelRenaming(cancelWorksheetData: { id: string }) {
        this.store.dispatch(new CancelRenameAction(cancelWorksheetData));
    }

    completeRenaming(renameWorksheetData: { id: string; name: string }) {
        this.store.dispatch(new RenameWorksheetAction(renameWorksheetData));
    }

    delete(worksheetId: string) {
        this.store.dispatch(new RemoveWorksheetAction({ id: worksheetId }));
    }

    remove(worksheetId: string) {
        this.store.dispatch(new RemoveErroredWorksheetAction({ id: worksheetId }));
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }

    private worksheetHasErrorType(worksheet: WorksheetView, type: LegacyWorksheetActionType) {
        return worksheet.error && worksheet.error.source === type;
    }
}
