import { AbstractControl, ValidatorFn } from "@angular/forms";

export class RedeliveryNoticesValidator {
    static validate(): ValidatorFn {
        return (control: AbstractControl): null => {
            const redeliveryNoticesControl = control.get("redeliveryNotices");
            checkValid(redeliveryNoticesControl);
            return null;
        };
    }
}

function checkValid(control: AbstractControl) {
    if (control) {
        const notices = control.value;

        if (notices) {
            for (const notice of notices) {
                const days = notice.days;

                if (!days || days <= 0) {
                    control.setErrors({ required: true });
                }
            }
        }
    }
}
