import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    OnDestroy,
    QueryList,
    TemplateRef,
    ViewEncapsulation
} from "@angular/core";
import { PrimeTemplate } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FormGridCellDirective } from "../form-grid-cell.directive";
import { FormGridDirective } from "../form-grid.directive";

@Component({
    selector: "ops-cellEditor",
    templateUrl: "./form-grid-cell-editor.component.html",
    styleUrls: ["./form-grid-cell-editor.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormGridCellEditorComponent implements AfterContentInit, OnDestroy {
    private readonly destroy$ = new Subject();

    inputTemplate: TemplateRef<unknown>;
    outputTemplate: TemplateRef<unknown>;
    currentCell: HTMLTableCellElement;

    @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;

    constructor(public formGridDirective: FormGridDirective, public editableColumn: FormGridCellDirective<never>, public el: ElementRef, public cd: ChangeDetectorRef) {
        this.currentCell = this.el.nativeElement.parentElement;
    }

    get isDisabled() {
        return this.formGridDirective.isReadonly;
    }

    get isEditing() {
        return this.editableColumn.isEditing;
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case "input":
                    this.inputTemplate = item.template;
                    break;
                case "output":
                    this.outputTemplate = item.template;
                    break;
            }
        });

        this.editableColumn.tableAndCellChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => this.cd.detectChanges());
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
