import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { unbox } from "ngrx-forms";
import { Observable } from "rxjs";

import { AppConfigService } from "@ops/core";
import { Currency, IndexResponse, Sector } from "@ops/shared";
import { SearchToken, toSearchToken, toQueriesParam } from "@ops/state";

import { DateRange } from "../../fixture/shared/models";
import { CargoId, CargoProductId, ChangeReason } from "../state/model/cargo";
import { CancellationReason, Coa, CoaId, CoaSource } from "../state/model/coa";
import { AddCompany, Company } from "../state/model/company";
import { LiftingCargo } from "../state/model/lifting/cargo";
import { CargoHistoricalEvent, VesselHistoricalEvent } from "../state/model/lifting/historical-event";
import { createLiftingId, Lifting, LiftingCargoPlanStatus, LiftingId } from "../state/model/lifting/lifting";
import { LiftingHeaderForm } from "../state/model/lifting/lifting-header";
import { LiftingIndexItem } from "../state/model/lifting/lifting-index-item";
import { VesselId, VesselNominationForm } from "../state/model/lifting/vessel-nomination";
import { UserId } from "../state/model/user";

export type AddLifting = Readonly<{
    coaId: CoaId;
    liftingId: LiftingId;
    tenantId: number;
    contractSource: CoaSource;
    contractReference: string;
    currency: Currency;
    sector: Sector;
    brokers: ReadonlyArray<number>;
    operators: ReadonlyArray<number>;
    contacts: ReadonlyArray<AddCompany>;
    configurationRoles: ReadonlyArray<string>;
}>;

export const coaToAddLifting = (coa: Coa): AddLifting => ({
    coaId: coa.coaId,
    liftingId: createLiftingId(),
    tenantId: coa.tenantId,
    contractSource: coa.source,
    contractReference: coa.reference,
    currency: coa.currency,
    sector: coa.sector,
    brokers: coa.brokers.map((x) => x.userId),
    operators: coa.operators.map((x) => x.userId),
    contacts: coa.contacts.map((x) => ({ accountId: x.accountId, role: x.role })),
    configurationRoles: coa.configurationRoles
});

export type SaveVesselNomination = Readonly<{
    vesselId: VesselId;
    cvn: number;
    laycan: DateRange;
    etd: string;
    cargoComments: string;
    locationPriorToVoyage: string;
    lastCargoes: ReadonlyArray<CargoProductId>;
}>;

export type RenominateNewVessel = Readonly<{
    vessel: SaveVesselNomination;
    reason: string;
}>;

export const formToSaveVesselNomination = (source: VesselNominationForm): SaveVesselNomination => ({
    vesselId: source.vesselId,
    cvn: unbox(source.vessel).cvn,
    laycan: unbox(source.laycan),
    etd: source.etd,
    cargoComments: source.cargoComments,
    locationPriorToVoyage: unbox(source.locationPriorToVoyage)?.locationId,
    lastCargoes: unbox(source.lastCargoes).map((x) => x.id)
});

export type UpdateLiftingDetails = Readonly<{
    declarationDate: string;
    liftingNumber: string;
    operators: ReadonlyArray<UserId>;
}>;

export const formToUpdateLiftingDetails = (source: LiftingHeaderForm): UpdateLiftingDetails => ({
    declarationDate: source.declarationDate,
    liftingNumber: source.liftingNumber,
    operators: source.operators.value.map((x) => x.userId)
});

export type UpdateLiftingCargo = LiftingCargo & Readonly<{ changeReason: ChangeReason }>;
export type RemoveLiftingCargo = Readonly<{ changeReason: ChangeReason }>;
export type UpdateLiftingCargoLaycan = Readonly<{ cargoLaycan: DateRange; changeReason: ChangeReason }>;

export type UpdateSummaryCommentsImportance = Readonly<{ areCommentsImportant: boolean }>;
export type UpdateSummaryComments = UpdateSummaryCommentsImportance & Readonly<{ comments: string }>;

export type CancelLifting = Readonly<{
    reason: CancellationReason;
    comment: string;
}>;

@Injectable({
    providedIn: "root"
})
export class LiftingHttpService {
    private readonly coaServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.coaServiceUrl = appConfigService.config.coaServiceUrl;
    }

    addLifting(addLifting: AddLifting): Observable<unknown> {
        return this.httpClient.post(`${this.coaServiceUrl}/api/v1/coa/${addLifting.coaId}/liftings`, addLifting);
    }

    get(coaId: CoaId, liftingId: LiftingId): Observable<Lifting> {
        return this.httpClient.get<Lifting>(`${this.getLiftingApiUrl(coaId, liftingId)}`);
    }

    updateDetails(coaId: CoaId, liftingId: LiftingId, details: UpdateLiftingDetails) {
        return this.httpClient.put(`${this.getLiftingApiUrl(coaId, liftingId)}/details`, details);
    }

    addVessel(coaId: CoaId, liftingId: LiftingId, vessel: SaveVesselNomination): Observable<unknown> {
        return this.httpClient.post(`${this.getLiftingApiUrl(coaId, liftingId)}/vessels`, vessel);
    }

    updateVessel(coaId: CoaId, liftingId: LiftingId, vessel: SaveVesselNomination): Observable<unknown> {
        return this.httpClient.put(`${this.getLiftingApiUrl(coaId, liftingId)}/vessels/${vessel.vesselId}`, vessel);
    }

    removeVessel(coaId: CoaId, liftingId: LiftingId, vesselId: VesselId): Observable<unknown> {
        return this.httpClient.delete(`${this.getLiftingApiUrl(coaId, liftingId)}/vessels/${vesselId}`);
    }

    acceptVessel(coaId: CoaId, liftingId: LiftingId, vesselId: VesselId): Observable<unknown> {
        return this.httpClient.put(`${this.getLiftingApiUrl(coaId, liftingId)}/vessels/${vesselId}/accept`, {});
    }

    rejectVessel(coaId: CoaId, liftingId: LiftingId, vesselId: VesselId, reason: string): Observable<unknown> {
        return this.httpClient.put(`${this.getLiftingApiUrl(coaId, liftingId)}/vessels/${vesselId}/reject`, { reason });
    }

    preferVessel(coaId: CoaId, liftingId: LiftingId, vesselId: VesselId): Observable<unknown> {
        return this.httpClient.put(`${this.getLiftingApiUrl(coaId, liftingId)}/vessels/${vesselId}/prefer`, {});
    }

    reviewVessel(coaId: CoaId, liftingId: LiftingId, vesselId: VesselId): Observable<unknown> {
        return this.httpClient.put(`${this.getLiftingApiUrl(coaId, liftingId)}/vessels/${vesselId}/review`, {});
    }

    renominateVessel(coaId: CoaId, liftingId: LiftingId, vesselId: VesselId, reason: string): Observable<unknown> {
        return this.httpClient.put(`${this.getLiftingApiUrl(coaId, liftingId)}/vessels/${vesselId}/renominate`, { reason });
    }

    renominateNewVessel(coaId: CoaId, liftingId: LiftingId, vessel: RenominateNewVessel): Observable<unknown> {
        return this.httpClient.post(`${this.getLiftingApiUrl(coaId, liftingId)}/vessels/renominate`, vessel);
    }

    updateLiftingCargoLaycan(coaId: CoaId, liftingId: LiftingId, laycan: UpdateLiftingCargoLaycan): Observable<unknown> {
        return this.httpClient.put(`${this.getLiftingApiUrl(coaId, liftingId)}/cargo-laycan`, laycan);
    }

    updateLiftingCargoPlanStatus(coaId: CoaId, liftingId: LiftingId, status: LiftingCargoPlanStatus): Observable<unknown> {
        return this.httpClient.put(`${this.getLiftingApiUrl(coaId, liftingId)}/cargoes/status/${status}`, {});
    }

    addCargo(coaId: CoaId, liftingId: LiftingId, cargo: UpdateLiftingCargo): Observable<unknown> {
        return this.httpClient.post(`${this.getLiftingApiUrl(coaId, liftingId)}/cargoes`, cargo);
    }

    updateCargo(coaId: CoaId, liftingId: LiftingId, cargo: UpdateLiftingCargo): Observable<unknown> {
        return this.httpClient.put(`${this.getLiftingApiUrl(coaId, liftingId)}/cargoes/${cargo.cargoId}`, cargo);
    }

    removeCargo(coaId: CoaId, liftingId: LiftingId, cargoId: CargoId, cargo: RemoveLiftingCargo): Observable<unknown> {
        return this.httpClient.request("DELETE", `${this.getLiftingApiUrl(coaId, liftingId)}/cargoes/${cargoId}`, { body: cargo });
    }

    auditCargoPlanEmailGenerated(coaId: CoaId, liftingId: LiftingId): Observable<unknown> {
        return this.httpClient.post(`${this.getLiftingApiUrl(coaId, liftingId)}/cargoes/track-email`, {});
    }

    getPlannedLiftingsForCoa(coaId: CoaId): Observable<IndexResponse<LiftingIndexItem>> {
        const tokens: ReadonlyArray<SearchToken> = [toSearchToken("Lifting-Status", "Planning"), toSearchToken("Coa-Id", coaId)];

        return this.searchLiftings(tokens);
    }

    getLiftingsForCoa(coaId: CoaId): Observable<IndexResponse<LiftingIndexItem>> {
        return this.searchLiftings([toSearchToken("Coa-Id", coaId)]);
    }

    fixLifting(coaId: CoaId, liftingId: LiftingId, declarationDate: string): Observable<unknown> {
        return this.httpClient.post(`${this.getLiftingApiUrl(coaId, liftingId)}/fix`, { declarationDate });
    }

    cloneLifting(coaId: CoaId, liftingId: LiftingId, cloneId: LiftingId): Observable<unknown> {
        return this.httpClient.post(`${this.getLiftingApiUrl(coaId, liftingId)}/clone`, { cloneId });
    }

    getVesselNominationHistory(coaId: CoaId, liftingId: LiftingId): Observable<ReadonlyArray<VesselHistoricalEvent>> {
        return this.httpClient.get<ReadonlyArray<VesselHistoricalEvent>>(`${this.getLiftingApiUrl(coaId, liftingId)}/vessels/history`);
    }

    updateSummaryComments(coaId: CoaId, liftingId: LiftingId, update: UpdateSummaryComments): Observable<unknown> {
        return this.httpClient.put(`${this.getLiftingApiUrl(coaId, liftingId)}/summary-comments`, update);
    }

    updateSummaryCommentsImportance(coaId: CoaId, liftingId: LiftingId, update: UpdateSummaryCommentsImportance): Observable<unknown> {
        return this.httpClient.put(`${this.getLiftingApiUrl(coaId, liftingId)}/summary-comments-importance`, update);
    }

    getCargoNominationHistory(coaId: CoaId, liftingId: LiftingId): Observable<ReadonlyArray<CargoHistoricalEvent>> {
        return this.httpClient.get<ReadonlyArray<CargoHistoricalEvent>>(`${this.getLiftingApiUrl(coaId, liftingId)}/cargoes/history`);
    }

    cancelLifting(coaId: CoaId, liftingId: LiftingId, cancellation: CancelLifting): Observable<unknown> {
        return this.httpClient.post(`${this.getLiftingApiUrl(coaId, liftingId)}/cancel`, cancellation);
    }

    addCompany(coaId: CoaId, liftingId: LiftingId, company: Company): Observable<unknown> {
        return this.httpClient.post(`${this.getLiftingApiUrl(coaId, liftingId)}/contacts`, company);
    }

    removeCompany(coaId: CoaId, liftingId: LiftingId, company: Company): Observable<unknown> {
        return this.httpClient.request("delete", `${this.getLiftingApiUrl(coaId, liftingId)}/contacts`, { body: company });
    }

    private getLiftingApiUrl = (coaId: CoaId, liftingId: LiftingId): string => `${this.coaServiceUrl}/api/v1/coa/${coaId}/liftings/${liftingId}`;

    private searchLiftings(tokens: ReadonlyArray<SearchToken>): Observable<IndexResponse<LiftingIndexItem>> {
        const filterQuery = tokens.map(toQueriesParam).join("&");

        return this.httpClient.get<IndexResponse<LiftingIndexItem>>(`${this.coaServiceUrl}/api/v1/liftings/search?${filterQuery}&skip=0&take=1000&sortOrder=desc`);
    }
}
