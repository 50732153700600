export class Enumeration {
    // tslint:disable-next-line:no-any
    constructor(readonly id: any, readonly name: string, readonly displayOrder?: number) {
        Object.freeze(this);
    }

    static compare(e1: Enumeration, e2: Enumeration): boolean {
        return e1 && e2 ? e1.id === e2.id : e1 === e2;
    }

    toString() {
        return this.name;
    }
}
