import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import { map, take } from "rxjs/operators";

import { favoriteFixtureSelect } from "../../../fixture/state/fixture";
import { LeftBarStateService } from "../../left-bar-state.service";
import { FavoriteDataService } from "../../shared/favorite-data.service";
import { FavoriteCategoryViewModel, FavoriteViewModel } from "../../shared/models";

@Component({
    selector: "ops-favorite-list",
    templateUrl: "./favorite-list.component.html",
    styleUrls: ["./favorite-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteListComponent implements OnInit, OnDestroy {
    static componentName = "FavoriteListComponent";

    private _subscriptions: Subscription[] = [];

    favoriteCategories$: Observable<FavoriteCategoryViewModel[]>;
    accordionStatus: { id: string; isOpened: boolean } = { id: "", isOpened: false };
    panelChange$: BehaviorSubject<{ id: string; isOpened: boolean }> = new BehaviorSubject<{ id: string; isOpened: boolean }>({
        id: "",
        isOpened: false
    });
    openTabIds$: BehaviorSubject<string | string[]> = new BehaviorSubject<string | string[]>([]);

    @Output() openFavorite: EventEmitter<FavoriteViewModel> = new EventEmitter<FavoriteViewModel>();

    constructor(private favoriteService: FavoriteDataService, private leftBarStateService: LeftBarStateService, private store: Store) {}

    ngOnInit() {
        this.favoriteCategories$ = this.favoriteService.favoriteCategories$.pipe(map((x) => x.filter((c) => c.favorites && c.favorites.length > 0)));

        this._subscriptions.push(
            this.leftBarStateService.isCollapsed$.subscribe((collapsed) => {
                if (!collapsed) {
                    this.setDefaultPanelOpenState();
                }
            })
        );
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    removeFavorite(fixtureId: string): void {
        this.favoriteService.removeFavorite(fixtureId);
    }
    openFavoriteInNewTab(fixture: FavoriteViewModel) {
        this.openFavorite.emit(fixture);
    }

    onFavouriteClick() {
        this.store.dispatch(favoriteFixtureSelect());
        this.leftBarStateService.collapseIfSmallWindow();
    }

    onPanelChange(event: any) {
        this.panelChange$.next({ id: event.panelId, isOpened: event.nextState });
    }

    onToggleCategory(event: MouseEvent, fixtureStatusType: number): void {
        event.stopPropagation();
        this.favoriteService.toggleSelectCategory(fixtureStatusType);
    }

    onToggleFavorite(event: MouseEvent, fixtureId: string): void {
        event.stopPropagation();
        this.favoriteService.toggleSelectFavorite(fixtureId);
    }

    onColorSelected(color: string, fixtureId: string): void {
        this.favoriteService.updateFavoriteColor(color, fixtureId);
    }

    categoryTrackBy(index: number, item: FavoriteCategoryViewModel): string {
        return item.name;
    }

    private setDefaultPanelOpenState() {
        const openFavorite$ = this.favoriteCategories$.pipe(
            map((categoryies) => {
                const openCategory = categoryies.find((cat) => cat.name === "Open");
                return openCategory ? openCategory.favorites.length > 0 : false;
            })
        );

        this._subscriptions.push(
            combineLatest([openFavorite$, this.panelChange$])
                .pipe(take(1))
                .subscribe((result) => {
                    if (result[0] && !result[1].isOpened) {
                        this.panelChange$.next({ id: "Open", isOpened: true });
                        this.openTabIds$.next(["Open"]);
                    }
                })
        );
    }
}
