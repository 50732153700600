import { Command } from "../../../../../mediator/commands/command";
import { LaytimeEventFactModel } from "../../../../../shared/models/form-models";
import { ActivityId, BerthId, DestinationId } from "../../../../../state/model";
import { Fixture } from "./../../../../../shared/models/dtos/fixture.dto";
import { UpdateLaytimeEventFactsCommandHandler } from "./update-laytime-event-facts.command-handler";

export class UpdateLaytimeEventFactsCommand extends Command {
    constructor(
        readonly laytimeEventFact: LaytimeEventFactModel,
        readonly destinationId: DestinationId,
        readonly berthId: BerthId,
        readonly berthActivityId: ActivityId,
        readonly fixture: Fixture,
        readonly timeZone?: string
    ) {
        super(UpdateLaytimeEventFactsCommandHandler, null);
    }
}
