import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { ContactService } from "../contact.service";
import { Person } from "../models/person.model";

@Component({
    selector: "ops-person-search",
    templateUrl: "./person-search.component.html",
    styleUrls: ["./person-search.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonSearchComponent implements OnInit {
    static componentName = "PersonSearchComponent";

    personSearchForm: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder, private contactService: ContactService) {}

    ngOnInit() {
        this.personSearchForm = new UntypedFormGroup({
            person: this.fb.control(null)
        });
    }

    onSelectionChange(selection: Person): void {
        if (selection) {
            this.contactService.addPerson(selection);
        }
    }
}
