import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";

@Component({
    selector: "ops-lifting-history-expandable-text",
    templateUrl: "./lifting-history-expandable-text.component.html",
    styleUrls: ["./lifting-history-expandable-text.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiftingHistoryExpandableTextComponent implements AfterViewInit {
    canToggle = false;
    isExpanded = false;

    @ViewChild("expandableTextWrapper") expandableTextWrapper: ElementRef<HTMLDivElement>;

    @Input() name: string;
    @Input() text: string;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngAfterViewInit() {
        const expandableTextWrapperEl = this.expandableTextWrapper?.nativeElement;
        if (expandableTextWrapperEl?.offsetHeight < expandableTextWrapperEl?.scrollHeight) {
            this.canToggle = true;
            this.changeDetector.detectChanges();
        }
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
        this.changeDetector.detectChanges();
    }
}
