import { reduceObjectMap } from "@ops/state";
import {
    FixturesState,
    FixtureState,
    getCurrentFixtureId,
    getCurrentVoyageId,
    VoyageExpandedSections,
    VoyageId,
    VoyageState
} from "../model";

export const voyageStateReducer = (
    state: FixturesState,
    voyageId: VoyageId,
    partialOrReducer: Partial<VoyageState> | ((voyageState: VoyageState, fixtureState: FixtureState) => VoyageState)
) => {
    if (typeof partialOrReducer === "function") {
        const fixtureId = getCurrentFixtureId(state);

        return reduceObjectMap(
            state,
            (s) => s.voyages,
            voyageId,
            (voyageState) => partialOrReducer(voyageState, state.fixtures.byId[fixtureId])
        );
    }

    return reduceObjectMap(state, (s) => s.voyages, voyageId, partialOrReducer);
};

export const currentVoyageStateReducer = (
    state: FixturesState,
    partialOrReducer: Partial<VoyageState> | ((voyageState: VoyageState, fixtureState: FixtureState) => VoyageState)
) => voyageStateReducer(state, getCurrentVoyageId(state), partialOrReducer);

export const currentVoyageStateExpansionReducer = (
    state: FixturesState,
    key: keyof VoyageExpandedSections,
    expanded: boolean
) => currentVoyageStateReducer(state, (voyageState) => {
    if (voyageState.expandedSections[key] === expanded) {
        return voyageState;
    }

    return <VoyageState>{
        ...voyageState,
        expandedSections: {
            ...voyageState.expandedSections,
            [key]: expanded
        }
    };
});
