import { ChangeDetectionStrategy, Component, Input, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { filter, startWith, takeUntil } from "rxjs/operators";

import {
    ActivityLocation,
    ActivityLocationId,
    laytimeCalculationNavItemClick,
    LtcId,
    selectCurrentActivityLocationName,
    selectCurrentLaytimeCalculationSummaryTitle
} from "../../state";
import { LtcNavItem } from "../../state/calculations/router";

@Component({
    selector: "ops-laytime-calculation-nav",
    templateUrl: "./laytime-calculation-nav.component.html",
    styleUrls: ["./laytime-calculation-nav.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationNavComponent implements OnDestroy {
    private readonly destroy$ = new Subject();

    private currentLaytimeCalculationName$: Observable<string>;
    private currentActivityLocationName$: Observable<string>;

    @Input() ltcId: LtcId;
    @Input() activityLocations: ReadonlyArray<ActivityLocation>;
    @Input() currentActivityLocationId: ActivityLocationId;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private store: Store, private titleService: Title) {
        this.currentLaytimeCalculationName$ = this.store.select(selectCurrentLaytimeCalculationSummaryTitle);
        this.currentActivityLocationName$ = this.store.select(selectCurrentActivityLocationName);

        combineLatest([
            this.currentLaytimeCalculationName$,
            this.currentActivityLocationName$,
            this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).pipe(startWith([null]))
        ])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([calculationName, activityLocationName]) => this.setTitle(calculationName, activityLocationName));
    }

    navItemOnClick(item: LtcNavItem) {
        this.store.dispatch(laytimeCalculationNavItemClick({ item }));
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    private setTitle(calculationName: string, activityLocationName: string) {
        const page = this.activatedRoute.firstChild.snapshot?.data?.title;
        switch (page) {
            case "Summary":
                this.titleService.setTitle(calculationName);
                break;
            case "Terms":
                this.titleService.setTitle(`Terms - ${calculationName}`);
                break;
            case "Location":
                this.titleService.setTitle(`${activityLocationName} - ${calculationName}`);
                break;
        }
    }
}
