import { createSelector } from "@ngrx/store";
import { DateTime } from "luxon";
import { FormGroupState } from "ngrx-forms";

import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { cargoExpandedKey, CargoForm, CargoId, CargoItem, DestinationId, FixtureFeatureState, getCargoName, VoyageForm, VoyageState } from "../model";
import { selectCurrentVoyageExpandedSections, selectCurrentVoyageForm, selectCurrentVoyageFormValue, selectCurrentVoyageState } from "../voyage";
import { findAllSpecialisedCargoDestinationElements } from "./form/specialised/specialised-cargo-functions";

export declare type CargoState = Readonly<{
    cargoId: CargoId;
    form: FormGroupState<CargoForm>;
    etaVerifiedAt: DateTime;
    expanded: boolean;
}>;

export const selectCurrentVoyageCargoesExpanded = createSelector(selectCurrentVoyageExpandedSections, (expandedSections) => expandedSections && expandedSections.cargoes);

export const selectCurrentVoyageCargoForms = createSelector(selectCurrentVoyageForm, (voyageForm) => (voyageForm ? voyageForm.controls.cargoes : null));

export const selectCurrentVoyageCargoFormState = createSelector<FixtureFeatureState, { cargoId: CargoId }, VoyageState, FormGroupState<CargoForm>>(
    selectCurrentVoyageState,
    (voyage, { cargoId }) => voyage.form.controls.cargoes.controls.find((x) => x.value.cargoId === cargoId)
);

export const selectCurrentVoyageCargoFormValues = createSelector(selectCurrentVoyageCargoForms, (cargoForms) => (cargoForms ? cargoForms.value : null));

export const selectCurrentVoyageCargoItems = createSelector(selectCurrentVoyageCargoFormValues, (cargoes) => {
    if (cargoes === null) {
        return null;
    }

    return cargoes.map(
        (cargo) =>
            <CargoItem>{
                cargoId: cargo.cargoId,
                name: getCargoName(cargo)
            }
    );
});

export const selectCurrentVoyageCargoes = createSelector(selectCurrentVoyageCargoForms, selectCurrentVoyageExpandedSections, (cargoesForm, expandedSections) => {
    if (!cargoesForm) {
        return null;
    }

    return cargoesForm.controls.map(
        (cargo) =>
            <CargoState>{
                cargoId: cargo.value.cargoId,
                form: cargo,
                etaVerifiedAt: null,
                expanded: expandedSections[cargoExpandedKey(cargo.value.cargoId)]
            }
    );
});

export const selectCurrentVoyageSpecialisedCargoDestinationId = createSelector<
    FixtureFeatureState,
    { cargoId: CargoId; activityType: CargoBerthActivityType },
    VoyageForm,
    DestinationId
>(selectCurrentVoyageFormValue, (voyage, { cargoId, activityType }) => {
    if (!voyage) {
        return null;
    }

    const destination = findAllSpecialisedCargoDestinationElements(cargoId, activityType, voyage.destinations)?.destination;
    return destination?.id;
});
