import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { isNullOrUndefined } from "../../../../shared";
import { Enumeration } from "../../../../shared/reference-data/enumeration";
import { ReferenceDataService } from "../../../../shared/reference-data/reference-data.service";
import { isBunkered } from "../../../services/voyage-cost-calculator";
import { FormComponentBase } from "../../../shared/form-component-base";
import { BunkerRemainingOnboard } from "../../../shared/models/dtos/bunker-remaining-onboard";
import { BunkerRemainingOnboardFormModel } from "../../../shared/models/form-models/bunker-remaining-onboard.model";

@Component({
    selector: "ops-voyage-bunkers-remaining",
    templateUrl: "./voyage-bunkers-remaining.component.html",
    styleUrls: ["./voyage-bunkers-remaining.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoyageBunkersRemainingComponent extends FormComponentBase implements OnInit, OnChanges {
    static componentName = "VoyageBunkersRemainingComponent";

    bunkersForm: UntypedFormArray;
    allBunkerTypes: Enumeration[];

    @Input() bunkers: BunkerRemainingOnboard[];
    @Input() activityForm: UntypedFormGroup;
    @Input() activityName: string;

    @Output() bunkerRemoved = new EventEmitter();
    @Output() bunkerUpdated = new EventEmitter();
    @Output() bunkerAdded = new EventEmitter();
    @Output() initialBunkersAdded = new EventEmitter();

    constructor(public changeDetectorRef: ChangeDetectorRef, private formBuilder: UntypedFormBuilder, public referenceDataService: ReferenceDataService) {
        super();
    }

    ngOnInit() {
        this.referenceDataService.getBunkerTypes().subscribe((bunkerTypes: Enumeration[]) => {
            this.allBunkerTypes = bunkerTypes;
            this.changeDetectorRef.markForCheck();
        });

        this.createForm();
        if (this.activityForm.disabled) {
            this.bunkersForm.disable();
        } else {
            this.bunkersForm.enable();
        }

        if (this.bunkers) {
            this.setForm(this.bunkers);
        }
        this.activityForm.registerControl("bunkers", this.bunkersForm);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.bunkers && !changes.bunkers.firstChange && changes.bunkers.currentValue) {
            this.setForm(changes.bunkers.currentValue);
        }
    }

    onBunkeredClicked(index: number) {
        const currentRow = this.bunkersForm.controls[index] as UntypedFormGroup;
        currentRow.patchValue({
            bunkered: !currentRow.controls.bunkered.value,
            isBunkeredSelectedByUser: true
        });
    }

    deleteBunker(index: number) {
        this.bunkerRemoved.emit(index);
    }

    addInitialBunkers() {
        this.initialBunkersAdded.emit();
    }

    addBunker() {
        this.bunkerAdded.emit();
    }

    private createForm() {
        this.bunkersForm = new UntypedFormArray([]);
    }

    private setForm(bunkers: BunkerRemainingOnboard[]) {
        const dataModel: BunkerRemainingOnboardFormModel[] = bunkers.map((x) => {
            const bunkered = isBunkered(x);
            const quantityConsumed = bunkered ? 0 : this.calculateConsumed(x.quantityAtArrival, x.quantityAtSailing);
            return {
                type: x.type,
                quantityAtArrival: x.quantityAtArrival,
                quantityAtSailing: x.quantityAtSailing,
                quantityConsumed,
                pricePerMt: x.pricePerMt,
                bunkered,
                isBunkeredSelectedByUser: !isNullOrUndefined(x.isBunkered)
            };
        });

        this.removeFormSubscriptions();
        this.resizeBunkerList(bunkers.length);

        this.bunkersForm.patchValue(dataModel, { emitEvent: false });
        this.subscribeToFormValueChanges(this.bunkersForm, (data) => {
            this.bunkerUpdated.emit(data);
        });
    }

    private calculateConsumed(quantityAtArrival: number, quantityAtSailing: number): number {
        // User Story 23891: Speed and Cons - when user enters nothing (as opposed to zero)
        if (quantityAtSailing === null || quantityAtSailing === undefined || quantityAtArrival === null || quantityAtArrival === undefined) {
            return null;
        }
        return quantityAtArrival - quantityAtSailing;
    }

    private resizeBunkerList(bunkerCount: number): void {
        const bunkersArray = <UntypedFormArray>this.bunkersForm;

        if (bunkersArray.length === bunkerCount) {
            return;
        } else if (bunkersArray.length > bunkerCount) {
            bunkersArray.removeAt(0);
        } else if (bunkersArray.length < bunkerCount) {
            bunkersArray.push(this.createBunkerRow(this.bunkersForm.disabled));
        }

        return this.resizeBunkerList(bunkerCount);
    }

    private createBunkerRow(disabled: boolean): UntypedFormGroup {
        const group = new UntypedFormGroup(
            {
                type: new UntypedFormControl({ updateOn: "change" }),
                quantityAtArrival: new UntypedFormControl(),
                quantityAtSailing: new UntypedFormControl(),
                quantityConsumed: new UntypedFormControl(),
                pricePerMt: new UntypedFormControl(),
                bunkered: new UntypedFormControl(),
                isBunkeredSelectedByUser: new UntypedFormControl()
            },
            { updateOn: "change" }
        );

        if (disabled) {
            group.disable({ emitEvent: false });
        }

        return group;
    }

    get bunkersArray() {
        return this.bunkersForm.controls;
    }
}
