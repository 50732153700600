import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { CoaHttpService } from "../../../services";
import { selectCurrentCoa } from "../../coa";
import { currentCoaStateReducer } from "../../coa/reducer";
import { CoaFeatureState, CoasState, getCurrentCoaState, NominationTask, NominationTaskId } from "../../model";

/* ACTIONS */
const REMOVE_NOMINATIONTASK_ACTION_NAME = "[Coa Nomination Task Form] Remove Nomination Task";
export const removeNominationTaskAction = createAction(REMOVE_NOMINATIONTASK_ACTION_NAME, props<{ nominationTaskId: NominationTaskId }>());
export const removeNominationTaskSuccessAction = createAction(
    `${REMOVE_NOMINATIONTASK_ACTION_NAME} Success`,
    props<{ nominationTaskId: NominationTaskId; nominationTask: NominationTask }>()
);
export const removeNominationTaskFailAction = createAction(`${REMOVE_NOMINATIONTASK_ACTION_NAME} Fail`, props<{ nominationTaskId: NominationTaskId; error: Error }>());

/* REDUCERS */
export const removeNominationTaskSuccessReducer: On<CoasState> = on(removeNominationTaskSuccessAction, (state, { nominationTaskId }) => {
    const currentCoa = getCurrentCoaState(state);
    const task = currentCoa.coa.nominationTasks?.find((c) => c.nominationTaskId === nominationTaskId);

    return !task
        ? state
        : currentCoaStateReducer(state, (coaState) => ({
              ...coaState,
              coa: {
                  ...coaState.coa,
                  nominationTasks: currentCoa.coa.nominationTasks.filter((c) => c.nominationTaskId !== nominationTaskId)
              }
          }));
});

export const removeNominationTaskFailReducer: On<CoasState> = on(removeNominationTaskFailAction, (state, { nominationTaskId, error }) => {
    const currentCoa = getCurrentCoaState(state);
    const task = currentCoa.coa.nominationTasks?.find((c) => c.nominationTaskId === nominationTaskId);

    return !task
        ? state
        : currentCoaStateReducer(state, (coaState) => ({
              ...coaState,
              erroredItems: [
                  ...(coaState.erroredItems || []),
                  {
                      itemType: "nominationTasks",
                      id: nominationTaskId,
                      error
                  }
              ]
          }));
});

/* EFFECTS */
export const removeNominationTaskEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, coaService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeNominationTaskAction),
            withLatestFrom(store.select(selectCurrentCoa)),
            mergeMap(([action, coa]) => {
                const nominationTask = coa.nominationTasks?.find((c) => c.nominationTaskId === action.nominationTaskId);
                return coaService.removeNominationTask(coa.coaId, action.nominationTaskId).pipe(
                    map(() => removeNominationTaskSuccessAction({ nominationTaskId: action.nominationTaskId, nominationTask })),
                    catchError((err) => of(removeNominationTaskFailAction({ ...action, error: err })))
                );
            })
        )
    );
