import { cancelCargoTermsEditReducer, editCargoTermsReducer } from "./form/edit-cargo-terms";
import { cargoTermsLocationFormValueReducer, removeCargoTermsReducer, removeCargoTermsLocationReducer, updateCargoTermsReducer } from "./form/update-cargo-terms";

export default [
    editCargoTermsReducer,
    cancelCargoTermsEditReducer,
    updateCargoTermsReducer,
    removeCargoTermsReducer,
    cargoTermsLocationFormValueReducer,
    removeCargoTermsLocationReducer
];
