import { On } from "@ngrx/store";
import { FormArrayState, setValue, SetValueAction, unbox, updateArray, updateGroup } from "ngrx-forms";

import { Division } from "../../../shared/models";
import { getTimeZone } from "../../../shared/models/common/sea-net-location";
import { forEachLaytimeEvent } from "../../laytime-events/laytime-calculation";
import { FixturesState, getDefaultLaytimeEventDate, getDivision, isDivision, LaytimeEventForm, shouldPrefillLaytimeEventDate, VoyageForm } from "../../model";
import { updateActivityForms } from "../../voyage/form/form-updaters";
import { voyageStateReducer } from "../../voyage/reducer";

const getLaytimeEventIdsToPrefill = (voyageForm: VoyageForm, destinationId: string) => {
    const laytimeEventIdsToPrefill: string[] = [];

    forEachLaytimeEvent(voyageForm, (lE, { berth, destination, activity }) => {
        if (destination.id !== destinationId) {
            return;
        }
        if (destination.berths[0].id !== berth.id) {
            return;
        }
        if (berth.activities[0].activityId !== activity.activityId) {
            return;
        }

        laytimeEventIdsToPrefill.push(lE.laytimeEventId);
    });

    return laytimeEventIdsToPrefill;
};

const prefillLaytimeEventDates = (eventIdsToPrefill: string[], defaultEventDate: string, division: Division) => (laytimeEventForms: FormArrayState<LaytimeEventForm>) => {
    if (laytimeEventForms.value.some((e) => !!e.eventDate)) {
        return laytimeEventForms;
    }

    return updateArray<LaytimeEventForm>((laytimeEventForm) => {
        const isFromFirstBerthAndActivity = eventIdsToPrefill.some((id) => id === laytimeEventForm.value.laytimeEventId);
        const shouldPrefillEventDate = shouldPrefillLaytimeEventDate(isFromFirstBerthAndActivity, unbox(laytimeEventForm.value.type), division);

        if (!shouldPrefillEventDate) {
            return laytimeEventForm;
        }

        return updateGroup<LaytimeEventForm>({
            eventDate: setValue<string | undefined>(defaultEventDate)
        })(laytimeEventForm);
    })(laytimeEventForms);
};

/**
 * Prefills laytime event dates when the arrival date time set.
 */
export const prefillLaytimeEventDatesReducer: On<FixturesState> = {
    types: [SetValueAction.TYPE],
    reducer: (state: FixturesState, action: SetValueAction<string>): FixturesState => {
        const controlPath = action.controlId.split(".");
        if (controlPath.length !== 4 || controlPath[1] !== "destinations" || controlPath[3] !== "arrivalDateTime") {
            return state;
        }

        return voyageStateReducer(state, controlPath[0], (voyageState, fixtureState) => {
            const updateDivisions = [Division.dryCargo, Division.gas];
            if (!updateDivisions.some((d) => isDivision(fixtureState, d))) {
                return voyageState;
            }

            const destinationForm = voyageState.form.value.destinations[Number(controlPath[2])];

            if (destinationForm.arrivalDateTime || !action.value) {
                return voyageState;
            }

            const eventIdsToPrefill: string[] = getLaytimeEventIdsToPrefill(voyageState.form.value, destinationForm.id);
            const defaultEventDate = getDefaultLaytimeEventDate(action.value, getTimeZone(unbox(destinationForm.location)));

            return {
                ...voyageState,
                form: updateActivityForms({
                    laytimeEvents: prefillLaytimeEventDates(eventIdsToPrefill, defaultEventDate, getDivision(fixtureState))
                })(voyageState.form)
            };
        });
    }
};
