import { Injectable } from "@angular/core";
import { Fixture } from "src/app/fixture/shared/models";
import { OffHire } from "src/app/fixture/shared/models/dtos/offhire.dto";
import { Period } from "src/app/fixture/shared/models/dtos/period.dto";
import { parseISODate } from "src/app/shared/date-utils/date-utilities";
import { BunkerConsumption } from "./shared/models/dtos/bunker-consumption.dto";

@Injectable({
    providedIn: "root"
})
export class FixtureMapper {
    updateFixture(fixture: Fixture): Fixture {
        fixture.periods.sort(this.sortByPeriod);

        let sortOrder = 0;

        fixture.periods.forEach((period: Period) => {
            period.offHires.sort(this.sortByOffHire);
            period.offHires.forEach((offHire: OffHire) => {
                offHire.sortOrder = sortOrder++;
            });
        });

        if (fixture.cpSpeedAndConsumption.cpBunkersConsumption) {
            fixture.cpSpeedAndConsumption.cpBunkersConsumption.forEach((bunkersConsumption: BunkerConsumption, index: number) => {
                bunkersConsumption.bunkerConsumptionId = index + 1;
            });
        }

        if (!fixture.displayBroker) {
            fixture.displayBroker = fixture.brokers.find((broker) => broker.isDisplayBroker);
        }

        fixture.vesselId = fixture.vessel ? fixture.vessel.cvn : null;

        return fixture;
    }

    private sortByPeriod(p1: Period, p2: Period) {
        const period1Time = parseISODate(p1.periodRange.from).getTime();
        const period2Time = parseISODate(p2.periodRange.from).getTime();

        const fromDifference = period1Time - period2Time;

        return fromDifference;
    }

    private sortByOffHire(oh1: OffHire, oh2: OffHire) {
        const offHire1Time = parseISODate(oh1.offHireDateRange.from).getTime();
        const offHire2Time = parseISODate(oh2.offHireDateRange.from).getTime();

        return offHire1Time - offHire2Time;
    }

    removeEmptyNotices(fixture: Fixture): Fixture {
        fixture.delivery.deliveryNotices = fixture.delivery.deliveryNotices.filter((notice) => notice !== null);
        fixture.redelivery.redeliveryNotices = fixture.redelivery.redeliveryNotices.filter((notice) => notice !== null);

        return fixture;
    }
}
