<form [formGroup]="noonReportForm" class="noon-report-container" autocomplete="off" data-test-noon-report>
    <div class="row form-group no-gutters noon-report-header">
        <div class="col-4">
            <ops-date-input formControlName="date"  timeZone="utc" data-test-date></ops-date-input>
        </div>
        <div class="col d-flex justify-content-end">
            <button type="button" (click)="cancel()" class="btn btn-link" data-test-cancel-button>Cancel</button>
            <button type="button" (click)="save()" class="btn-add has-icon icon--save" [disabled]="isSaveDisabled" data-test-save-button></button>
        </div>
    </div>

    <div class="row form-group no-gutters">
        <label for="averageSpeed" class="col-5 ml-2 col-form-label">Av. Daily Speed (Knots):</label>
        <div class="col-3">
            <input
                id="averageSpeed"
                class="form-control text-right"
                formControlName="averageSpeed"
                [opsNumber]="{ precision: 2, minimumFractionDigits: 0, showTrailingZeros: false }"
                data-test-average-speed
            />
        </div>
        <div class="col-3 d-flex checkbox-container">
            <input id="inPort_{{ noonReport.noonReportId }}" type="checkbox" name="inPort" formControlName="inPort" data-test-in-port />
            <label for="inPort_{{ noonReport.noonReportId }}">In Port</label>
        </div>
    </div>

    <div class="row form-group no-gutters">
        <label for="averageBeaufort" class="col-5 ml-2 col-form-label">Av. Daily Beaufort value:</label>
        <div class="col-3">
            <ng-select
                id="averageBeaufort"
                class="ops-select"
                [items]="beaufortForces$ | async"
                placeholder="Select"
                formControlName="averageBeaufort"
                [clearable]="true"
                data-test-average-beaufortforce
                [selectOnTab]="true"
            >
            </ng-select>
        </div>
    </div>

    <div class="row form-group no-gutters">
        <ops-edit-engine-consumption
            class="ops-edit-engine-consumption"
            title="Main Engine Consumption (MT)"
            formName="mainEngineConsumptionForm"
            [parentForm]="noonReportForm"
            [model]="noonReport.mainEngineConsumption"
            (updated)="updateMainEngineConsumption($event)"
        ></ops-edit-engine-consumption>
    </div>

    <div class="row form-group no-gutters">
        <ops-edit-engine-consumption
            class="ops-edit-engine-consumption"
            title="Auxiliary Engine Consumption (MT)"
            formName="auxiliaryEngineConsumptionForm"
            [parentForm]="noonReportForm"
            [model]="noonReport.auxiliaryEngineConsumption"
            (updated)="updateAuxiliaryEngineConsumption($event)"
        ></ops-edit-engine-consumption>
    </div>
</form>
