import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { FormComponentBase } from "../../../fixture/shared/form-component-base";
import { Fixture, TimeCharterHeadingFormModel, User } from "../../../fixture/shared/models";
import { VesselNameValidator } from "../../../left-bar/create-fixture/validators/vessel-name-validator";
import { Enumeration } from "../../../shared/reference-data/enumeration";
import { ReferenceDataService } from "../../../shared/reference-data/reference-data.service";
import { parseISODateAsUtc } from "../../../shared/utils";
import { RangeValidator } from "../../../shared/validators/range.validator";
import { RequiredIfValidator } from "../../../shared/validators/required-if.validator";
import { Division } from "../../shared/models";
import { VesselData } from "../../shared/models/common/vessel-data";
import { Currency } from "../../shared/models/dtos/currency.dto";
import { FixtureSource } from "../../shared/models/enums/fixture-source";
import { UpdateTimeCharterHeadingCommand } from "./commands/update-time-charter-heading.command";

@Component({
    selector: "ops-time-charter-heading",
    templateUrl: "./time-charter-heading.component.html",
    styleUrls: ["./time-charter-heading.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeCharterHeadingComponent extends FormComponentBase implements OnInit, OnDestroy, OnChanges {
    static componentName = "TimeCharterHeadingComponent";

    private _cpmLinkRegEx = String.raw`https://cpmanager\.cloud/.+|https://contracts\.sea\.live/.+|https://www\.recapmanager\.co\.uk/.+`;
    private patchedValues: TimeCharterHeadingFormModel;

    readonly tabName = "voyageHeader";

    fixtureStatuses$: Observable<Enumeration[]>;
    Division = Division;
    FixtureSource = FixtureSource;
    cpTypes$: Observable<Enumeration[]>;
    managedByTypes$: Observable<Enumeration[]>;
    headingForm: UntypedFormGroup;
    operatorCount: number;
    lastCargoCount: number;
    leadOperator: User;
    currencies$: Observable<Currency[]>;
    presentationTypes$: Observable<Enumeration[]>;
    readonly pumpingRateDivisions = [Division.gas, Division.pcg, Division.dryCargo, Division.specialisedProducts];

    get invalid() {
        return this.headingForm.invalid;
    }

    @Input() fixture: Fixture;
    @Input() parentForm: UntypedFormGroup;

    @Output() headingUpdated = new EventEmitter<UpdateTimeCharterHeadingCommand>();

    constructor(protected store: Store, private formBuilder: UntypedFormBuilder, public referenceDataService: ReferenceDataService) {
        super(store);
    }

    ngOnInit(): void {
        this.fixtureStatuses$ = this.referenceDataService.getFixtureStatuses();
        this.cpTypes$ = this.referenceDataService.getCpTypes();
        this.managedByTypes$ = this.referenceDataService.getManagedByTypes();
        this.currencies$ = this.referenceDataService.getCurrencies();
        this.presentationTypes$ = this.referenceDataService.getPresentationTypes();

        this.createForm();
        this.parentForm.registerControl("heading", this.headingForm);
        this.setForm(this.fixture);
        this.subscribeToFormValueChanges();
        this.subscribeToFormStatusChanges([this.headingForm], this.tabName);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fixture.firstChange) {
            return;
        }

        this.removeFormSubscriptions();
        this.setForm(changes.fixture.currentValue);
        this.subscribeToFormValueChanges();
    }

    ngOnDestroy(): void {
        this.removeStatusChangesSubscrition();
        this.removeFormSubscriptions();
        delete this.parentForm.controls.heading;
    }

    removeIsVesselTbn(): void {
        const vessel = this.headingForm.controls["vessel"].value;
        if (vessel) {
            this.headingForm.patchValue({ isVesselTbn: false });
        }
    }

    removeVessel(): void {
        const isVesselTbn = this.headingForm.controls["isVesselTbn"].value;
        if (isVesselTbn) {
            this.headingForm.patchValue({ vessel: null });
        }
    }

    get isPcgFixture() {
        return this.fixture.division.id === Division.pcg;
    }

    protected subscribeToFormValueChanges() {
        this.formValueSubscription = this.headingForm.valueChanges.pipe(debounceTime(10)).subscribe((value) => {
            this.headingUpdated.emit(new UpdateTimeCharterHeadingCommand({ ...this.patchedValues, ...value }));
        });
    }

    private createForm() {
        this.headingForm = this.formBuilder.group(
            {
                operators: [],
                charterPartyType: [],
                cpmLink: ["", Validators.pattern(this._cpmLinkRegEx)],
                voyageReference: new UntypedFormControl({ updateOn: "blur" }),
                fixtureStatus: [],
                laycanFrom: new UntypedFormControl({ updateOn: "blur" }, Validators.required),
                laycanTo: new UntypedFormControl({ updateOn: "blur" }, Validators.required),
                laycanExtFrom: new UntypedFormControl({ updateOn: "blur" }),
                laycanExtTo: [],
                comments: new UntypedFormControl({ updateOn: "blur" }),
                isCommentsImportant: [],
                tradingLimits: new UntypedFormControl("", { updateOn: "blur" }),
                cargo: [],
                lastCargoes: [],
                managedBy: new UntypedFormControl({ updateOn: "blur" }, Validators.required),
                displayBroker: new UntypedFormControl({ updateOn: "blur" }),
                isVesselTbn: [],
                cpDate: new UntypedFormControl({ updateOn: "blur" }, Validators.required),
                vessel: new UntypedFormControl({ updateOn: "blur" }),
                currency: new UntypedFormControl({ value: [] }, Validators.required),
                brokerCommission: [],
                addressCommission: [],
                ballastBonus: [],
                presentationType: [],
                chartererReference: []
            },
            {
                validator: Validators.compose([
                    RequiredIfValidator.validate("laycanExtFrom", "laycanExtTo"),
                    VesselNameValidator.validate(),
                    RangeValidator.validate("laycanFrom", "laycanTo"),
                    RangeValidator.validate("laycanExtFrom", "laycanExtTo")
                ])
            }
        );

        if (this.parentForm.disabled) {
            this.headingForm.disable();
        } else {
            this.headingForm.enable();
        }
    }

    private setForm(fixture: Fixture) {
        let operators: User[] = [];

        if (fixture.operators && fixture.operators.length) {
            operators = [fixture.leadOperator, ...fixture.operators.filter((operator) => operator.userId !== fixture.leadOperator.userId)];
        }

        this.leadOperator = fixture.leadOperator;

        const dataModel: TimeCharterHeadingFormModel = {
            operators,
            charterPartyType: fixture.charterParty.charterPartyType,
            cpmLink: fixture.charterParty.cpmLink,
            voyageReference: fixture.voyageReference,
            fixtureStatus: fixture.fixtureStatus,
            laycanFrom: parseISODateAsUtc(fixture.laycan?.date?.from),
            laycanTo: parseISODateAsUtc(fixture.laycan?.date?.to),
            laycanExtFrom: parseISODateAsUtc(fixture.laycan?.extensionDate?.from),
            laycanExtTo: parseISODateAsUtc(fixture.laycan?.extensionDate?.to),
            comments: fixture.comments,
            isCommentsImportant: fixture.isCommentsImportant,
            tradingLimits: fixture.tradingLimits,
            cargo: fixture.cargo,
            lastCargoes: fixture.lastCargoes,
            managedBy: fixture.managedBy,
            vessel: !fixture.vessel ? null : <VesselData>{ name: fixture.vessel.name, imoNumber: fixture.vessel.imo, cvn: fixture.vessel.cvn },
            displayBroker: fixture.displayBroker,
            cpDate: parseISODateAsUtc(fixture.charterParty.charterPartyDate),
            isVesselTbn: fixture.isVesselTbn,
            currency: fixture.currency,
            brokerCommission: fixture.brokerCommission,
            addressCommission: fixture.addressCommission,
            ballastBonus: fixture.ballastBonus,
            presentationType: fixture.presentationType,
            chartererReference: fixture.chartererReference
        };

        this.operatorCount = fixture.operators ? fixture.operators.length : 0;
        this.lastCargoCount = fixture.lastCargoes ? fixture.lastCargoes.length : 0;

        if (this.fixture.fixtureSource.id === FixtureSource.Gain) {
            this.headingForm.controls.cpDate.disable();
            this.headingForm.controls.currency.disable();
        }

        this.headingForm.patchValue(dataModel, { emitEvent: false });
        this.patchedValues = dataModel;
    }
}
