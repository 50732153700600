import { Injectable } from "@angular/core";

import { isNullOrUndefined } from "../../../../../shared/utils";
import { Fixture, FixtureType } from "../../../models";
import { FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class ExternalVisibilityOffHiresRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        if (fixture.fixtureType.id === FixtureType.Voyage) {
            return warnings;
        }

        fixture.periods.forEach((period, periodIndex) => {
            period.offHires.forEach((offHire, offHireIndex) => {
                const offHirePathSegment = this.getOffHirePathSegment(periodIndex, offHireIndex);

                if (!offHire.offHireDateRange || !offHire.offHireDateRange.from) {
                    const message = "Missing From Date";
                    const path = [...offHirePathSegment, new FixtureWarningPathSegment("offHireDateRange", "Off Hire From"), new FixtureWarningPathSegment("from", null)];
                    warnings.push(new FixtureWarning("data", message, path));
                }

                if (!offHire.offHireDateRange || !offHire.offHireDateRange.to) {
                    const message = "Missing To Date";
                    const path = [...offHirePathSegment, new FixtureWarningPathSegment("offHireDateRange", "Off Hire To"), new FixtureWarningPathSegment("to", null)];
                    warnings.push(new FixtureWarning("data", message, path));
                }

                if (isNullOrUndefined(offHire.hireRate)) {
                    const message = "Missing Hire Rate";
                    const path = [...offHirePathSegment, new FixtureWarningPathSegment("hireRate", "Hire Rate")];
                    warnings.push(new FixtureWarning("data", message, path));
                }
            });
        });

        return warnings;
    }

    private getOffHirePathSegment(periodIndex: number, offHireIndex: number) {
        return [
            new FixtureWarningPathSegment("periods", "Hire"),
            FixtureWarningPathSegment.fromIndex(periodIndex, "Hire", "Hire"),
            new FixtureWarningPathSegment("offHires", "Off Hires"),
            FixtureWarningPathSegment.fromIndex(offHireIndex, null)
        ];
    }
}
