import { Injectable } from "@angular/core";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { tap } from "rxjs/operators";

import { AuthService } from "../../core";
import { dateToISOString, parseISODate } from "../../shared/date-utils/date-utilities";
import { deepFreeze } from "../../shared/deep-freeze";
import { InvoiceType } from "../../shared/reference-data";
import { deepCopy } from "../../shared/utils";
import { Currency, Fixture, Invoice } from "../shared/models";
import { FixtureInvoices } from "../shared/models/dtos/fixture-invoices.dto";
import { InvoiceFormModel } from "../shared/models/form-models";
import { FixtureDataService } from "./fixture-data.service";
import { InvoiceHttpService } from "./invoice-http.service";
import { InvoiceSortingUtility } from "./invoice-sorting-utility";
import { WebStorageService, WebStorageServiceFactory } from "./webstorage.service";

@Injectable({
    providedIn: "root"
})
export class InvoiceDataService {
    private _workingInvoices: FixtureInvoices;
    private _currentInvoices$: Subject<FixtureInvoices>;
    private _webStorageService: WebStorageService<FixtureInvoices>;
    private _invalidInvoices: Invoice[];

    private currency: Currency;

    get invalidInvoices(): Invoice[] {
        return this._invalidInvoices;
    }

    get currentInvoices$(): Observable<FixtureInvoices> {
        return this._currentInvoices$.asObservable();
    }

    constructor(
        private invoiceHttpService: InvoiceHttpService,
        private authService: AuthService,
        webStorageServiceFactory: WebStorageServiceFactory,
        private fixtureDataService: FixtureDataService
    ) {
        this._currentInvoices$ = new Subject<FixtureInvoices>();
        this._webStorageService = webStorageServiceFactory.createStorage<FixtureInvoices>("unsaved-invoices-0.1.1");
        this.fixtureDataService.currentFixture$.subscribe((fixture: Fixture) => {
            if (fixture) {
                this.currency = fixture.currency;
            }
        });
    }

    addInvoice(grossValue: number, invoiceType: InvoiceType) {
        const invoice = <Invoice>{};
        invoice.lastUpdatedByUser = this.authService.user;
        invoice.lastUpdatedDate = dateToISOString(new Date());
        invoice.invoiceType = invoiceType;
        invoice.currency = this.currency;
        invoice.grossValue = grossValue;

        this.invoiceHttpService
            .create(this._workingInvoices.fixtureId, invoice)
            .pipe(
                tap((newId: string) => {
                    invoice.invoiceId = newId;
                    this._workingInvoices.invoices.push(invoice);
                    this.publishInvoices(this._workingInvoices);
                })
            )
            .subscribe();
    }

    updateInvoices(invoiceModels: InvoiceFormModel[], invoiceType: InvoiceType): Observable<string[]> {
        const newInvoiceIds$: Observable<string>[] = [of("")];
        const untouchedStoredInvalidInvoices: Invoice[] = [];

        this.handleDeletedInvoices(invoiceModels, invoiceType);

        invoiceModels.forEach((invoiceModel) => {
            const invoice = this.mapInvoice(invoiceModel);
            const idIsTemporary = invoice.invoiceId.startsWith("temp");
            const isStoredInvalidInvoice = this.invalidInvoices?.some((invalidInvoice) => invalidInvoice.invoiceId === invoiceModel.invoiceId);
            const modelIsDirtyAndValid = invoiceModel.valid && invoiceModel.dirty;

            if (!invoiceModel.valid && !invoiceModel.dirty && isStoredInvalidInvoice) {
                untouchedStoredInvalidInvoices.push(invoice);
            }

            if (modelIsDirtyAndValid) {
                if (idIsTemporary) {
                    const newInvoiceId$ = this.invoiceHttpService.create(this._workingInvoices.fixtureId, invoice).pipe(tap((newId) => (invoice.invoiceId = newId)));
                    newInvoiceIds$.push(newInvoiceId$);
                } else {
                    this.invoiceHttpService.update(this._workingInvoices.fixtureId, invoice).subscribe();
                }
            }
        });

        // storeInvalidInvoices is a part of some legacy requirements
        // now we should not add new invalid invoices to storeInvalidInvoices,
        // but we want to keep each old invalid invoice until it was changed by user
        this.storeInvalidInvoices(untouchedStoredInvalidInvoices);
        return forkJoin(newInvoiceIds$).pipe(tap(() => this.publishInvoices(this._workingInvoices)));
    }

    load(fixtureId: string) {
        const fromServer$ = this.invoiceHttpService.get(fixtureId);
        const fromStorage$ = this._webStorageService.get(fixtureId);
        const fetchBoth$ = forkJoin([fromServer$, fromStorage$]);

        fetchBoth$.subscribe(([fromServer, fromStorage]) => {
            let invoiceArray: Invoice[] = [];

            if (fromStorage) {
                invoiceArray = fromStorage.invoices;
                this._invalidInvoices = [...fromStorage.invoices];
            }

            fromServer.forEach((serverInvoice) => {
                const invoice = invoiceArray.find((localInvoice) => localInvoice.invoiceId === serverInvoice.invoiceId);

                if (!invoice) {
                    invoiceArray.push(serverInvoice);
                } else if (parseISODate(invoice.lastUpdatedDate).getTime() < parseISODate(serverInvoice.lastUpdatedDate).getTime()) {
                    const index = invoiceArray.indexOf(invoice);
                    invoiceArray[index] = serverInvoice;
                }
            });

            const orderedInvoices = InvoiceSortingUtility.sortInvoices(invoiceArray);

            const fixtureInvoices = {
                fixtureId: fixtureId,
                invoices: orderedInvoices
            };

            this.publishInvoices(fixtureInvoices);
        });
    }

    private handleDeletedInvoices(invoiceModels: InvoiceFormModel[], invoiceType: InvoiceType) {
        const currentIds = this._workingInvoices.invoices.filter((x) => x.invoiceType.id === invoiceType.id).map((x) => x.invoiceId);
        const newIds = invoiceModels.map((x) => x.invoiceId);
        const deleted = currentIds.filter((x) => !newIds.includes(x));

        deleted.forEach((id) => {
            const indexToRemove = this._workingInvoices.invoices.findIndex((x) => x.invoiceId === id);
            this._workingInvoices.invoices.splice(indexToRemove, 1);
            if (!id.startsWith("temp")) {
                this.invoiceHttpService.delete(this._workingInvoices.fixtureId, id).subscribe();
            }
        });
    }

    private storeInvalidInvoices(invalidInvoices: Invoice[]) {
        if (invalidInvoices.length > 0) {
            const invoices = {
                fixtureId: this._workingInvoices.fixtureId,
                invoices: invalidInvoices
            };

            this._webStorageService.set(invoices.fixtureId, invoices);
        } else {
            this._webStorageService.remove(this._workingInvoices.fixtureId);
        }
    }

    private publishInvoices(fixtureInvoices: FixtureInvoices) {
        this._workingInvoices = fixtureInvoices;
        const immutableInvoices = deepFreeze(deepCopy(fixtureInvoices)) as FixtureInvoices;
        this._currentInvoices$.next(immutableInvoices);
    }

    private mapInvoice(invoiceModel: InvoiceFormModel): Invoice {
        let invoice = this._workingInvoices.invoices.find((x) => x.invoiceId === invoiceModel.invoiceId);

        if (!invoice) {
            invoice = <Invoice>{};
            invoiceModel.lastUpdatedByUser = this.authService.user;
            invoiceModel.lastUpdatedDate = dateToISOString(new Date());
            this._workingInvoices.invoices.push(invoice);
        }

        if (invoiceModel.dirty) {
            invoiceModel.lastUpdatedByUser = this.authService.user;
            invoiceModel.lastUpdatedDate = dateToISOString(new Date());
        }

        invoice.invoiceId = invoiceModel.invoiceId;
        invoice.invoiceType = invoiceModel.invoiceType;
        invoice.currency = invoiceModel.currency;
        invoice.externalInvoiceNumber = invoiceModel.externalInvoiceNumber;
        invoice.invoiceDate = dateToISOString(invoiceModel.invoiceDate);
        invoice.grossValue = invoiceModel.grossValue;
        invoice.receivedFromOwnerDate = dateToISOString(invoiceModel.receivedFromOwnerDate);
        invoice.sentToChartererDate = dateToISOString(invoiceModel.sentToChartererDate);
        invoice.sentToAccountsDate = dateToISOString(invoiceModel.sentToAccountsDate);
        invoice.associatedPeriodHireId = invoiceModel.associatedPeriodHireId;
        invoice.allocatedStatus = invoiceModel.allocatedStatus;
        invoice.amountPaid = invoiceModel.amountPaid;
        invoice.datePaid = dateToISOString(invoiceModel.datePaid);
        invoice.comments = invoiceModel.comments;
        invoice.lastUpdatedByUser = invoiceModel.lastUpdatedByUser;
        invoice.lastUpdatedDate = invoiceModel.lastUpdatedDate;
        invoice.coveringFromDate = invoiceModel.coveringPeriod?.start.toISO();
        invoice.coveringToDate = invoiceModel.coveringPeriod?.end.toISO();
        invoice.actualHireRate = invoiceModel.actualHireRate;

        return invoice;
    }
}
