import { createSelector } from "@ngrx/store";

import { companyRoles } from "../../model";
import { isCharterer, isOwner, isOwnerOrCharterer } from "../../utils";
import { selectCurrentLiftingState } from "../selectors";

export const selectCurrentLiftingCompanies = createSelector(selectCurrentLiftingState, (state) => {
    const lifting = state.lifting;
    return lifting
        ? lifting.contacts.map((c, i) => ({
              ...c,
              isReadonly: !!lifting.controlledProperties.find((cp) => cp === `contacts[${i}]`)
          }))
        : [];
});

export const selectCurrentLiftingOwner = createSelector(selectCurrentLiftingCompanies, (companies) => companies.find((c) => isOwner(c)));
export const selectCurrentLiftingCharterer = createSelector(selectCurrentLiftingCompanies, (companies) => companies.find((c) => isCharterer(c)));

export const selectCurrentLiftingCompanyRoles = createSelector(selectCurrentLiftingCompanies, (companies) =>
    companies?.length
        ? companyRoles.map((r) => {
              const company = companies.find((c) => c.role === r);
              return { name: r, disabled: !!company && isOwnerOrCharterer(company) };
          })
        : []
);

export const selectLiftingCompanyForm = createSelector(selectCurrentLiftingState, (state) => state?.companyForm);
