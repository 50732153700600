import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { DateUtilities } from "../../../../shared/date-utils/date-utilities";
import { CargoBerthActivityType, LaytimeCalculationUnit } from "../../../../shared/reference-data";
import { hasItems } from "../../../../shared/utils";
import { Command } from "../../../mediator/commands/command";
import { EstimatedClaimValueCalculator } from "../../../services/estimated-claim-value-calculator";
import { FormComponentBase } from "../../../shared/form-component-base";
import { Berth, Cargo, CargoBerthActivity, Destination, Fixture, StatementOfFactsModel } from "../../../shared/models";
import { CargoAllowedRate } from "../../../shared/models/dtos/cargo-allowed-rate.dto";
import { DemurrageClaimType } from "../../../shared/models/enums/demurrage-claim-type";
import { CargoItem, DestinationId, getCargoName } from "../../../state/model";
import { LaytimeType } from "./../../../../shared/reference-data/laytime-type";
import { StatementOfFactsService } from "./services/statement-of-facts.service";

@Component({
    selector: "ops-berth-statement-of-facts",
    templateUrl: "./berth-statement-of-facts.component.html",
    styleUrls: ["./berth-statement-of-facts.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BerthStatementOfFactsComponent extends FormComponentBase implements OnInit, OnDestroy, OnChanges {
    static componentName = "BerthStatementOfFactsComponent";

    statementOfFactsModel: StatementOfFactsModel;
    hideStatementOfFactsBerth: boolean;
    berthStatementOfFactsForm: UntypedFormGroup;
    activitiesForm: UntypedFormArray;
    claim = { claimValue: 0, claimType: <DemurrageClaimType>null };
    DemurrageClaimType = DemurrageClaimType;
    loadAndDischargeActivities: CargoBerthActivity[] = [];
    laytimeCommencedDate: string;
    laytimeCompletedDate: string;
    associatedCargoCargoes: CargoItem[];

    @Input() parentForm: UntypedFormGroup;
    @Input() berth: Berth;
    @Input() cargoes: Cargo[];
    @Input() destinationId: DestinationId;
    @Input() destination: Destination;
    @Input() locationTimezone: string;
    @Input() fixture: Fixture;
    @Input() destinations: Destination[];
    @Input() presetEventStartDates: boolean;
    @Input() defaultEventStartDate: string;
    @Input() cargoAllowedRates: CargoAllowedRate[];
    @Input() displayUnitType: LaytimeCalculationUnit;
    @Output() berthStatementOfFactsUpdated = new EventEmitter<Command>();

    constructor(protected formBuilder: UntypedFormBuilder, private ecvCalculator: EstimatedClaimValueCalculator, private statementOfFactsService: StatementOfFactsService) {
        super();
    }

    ngOnInit() {
        this.setInitialActivityFormValues();
        this.parentForm.registerControl("berthStatementOfFactsForm", this.berthStatementOfFactsForm);
        if (this.parentForm.disabled) {
            this.berthStatementOfFactsForm.disable();
        } else {
            this.berthStatementOfFactsForm.enable();
        }
        this.setInitialFormValues();
        this.claim = this.ecvCalculator.calculateBerthNonFixedNonReversibleECV(this.berth, this.fixture, this.destination);
        this.setAssociatedCargoCargoes();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && ((changes.berth && !changes.berth.firstChange) || (changes.fixture && !changes.fixture.firstChange))) {
            this.setInitialFormValues();
            this.setActivitiesForm();
            this.claim = this.ecvCalculator.calculateBerthNonFixedNonReversibleECV(this.berth, this.fixture, this.destination);
            this.setAssociatedCargoCargoes();
        }
    }

    ngOnDestroy() {
        this.removeFormSubscriptions();
    }

    get isFixedLaytimeType(): boolean {
        return this.fixture && this.fixture.laytimeType && this.fixture.laytimeType.id === LaytimeType.Fixed.id;
    }

    toggleStatementOfFactsBerth(): void {
        this.hideStatementOfFactsBerth = !this.hideStatementOfFactsBerth;
    }

    updateStatementOfFacts(event: Command): void {
        this.berthStatementOfFactsUpdated.emit(event);
    }

    private setInitialFormValues(): void {
        this.statementOfFactsModel = this.statementOfFactsService.createStatementOfFactsModel(this.berth, this.cargoes, this.fixture);

        this.laytimeCommencedDate = DateUtilities.formatISODate(this.statementOfFactsModel.laytimeCommenced, this.locationTimezone || "utc", "ddd DD MMM YY HH:mm");
        this.laytimeCompletedDate = DateUtilities.formatISODate(this.statementOfFactsModel.laytimeCompleted, this.locationTimezone || "utc", "ddd DD MMM YY HH:mm");
        const data = {
            laytimeCommenced: this.laytimeCommencedDate,
            laytimeCompleted: this.laytimeCompletedDate
        };

        this.berthStatementOfFactsForm.patchValue(data);
    }

    private setInitialActivityFormValues(): void {
        this.berthStatementOfFactsForm = this.formBuilder.group({
            laytimeCommenced: [],
            laytimeCompleted: []
        });

        this.activitiesForm = this.formBuilder.array([]);

        this.berthStatementOfFactsForm.registerControl("cargoBerthActivities", this.activitiesForm);
        if (this.parentForm.disabled) {
            this.activitiesForm.disable();
        } else {
            this.activitiesForm.enable();
        }
        this.setActivitiesForm();
    }

    private setActivitiesForm(): void {
        const formArray = <UntypedFormArray>this.activitiesForm;
        this.loadAndDischargeActivities = hasItems(this.berth.cargoBerthActivities)
            ? this.berth.cargoBerthActivities.filter((c) => CargoBerthActivityType.isLoadOrDischarge(c.type))
            : [];
        const activitiesLength = this.loadAndDischargeActivities.length;

        while (formArray.length !== activitiesLength) {
            if (formArray.length > activitiesLength) {
                formArray.removeAt(0);
            } else if (formArray.length < activitiesLength) {
                const newGroup = this.formBuilder.group({}, { updateOn: "blur" });
                formArray.push(newGroup);
            }
        }
    }

    private setAssociatedCargoCargoes(): void {
        this.associatedCargoCargoes = this.cargoes
            .filter((c) => this.loadAndDischargeActivities.some((lda) => lda.associatedCargoes && lda.associatedCargoes.some((a) => c.id === a.cargoId)))
            .map((c) => ({ cargoId: c.id, name: getCargoName(c) }));
    }
}
