import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { VoyageCostBreakdown } from "../../state/voyage-cost-breakdown";

 @Component({
     selector: "ops-voyage-cost-breakdown",
     templateUrl: "./voyage-cost-breakdown.component.html",
     styleUrls: ["./voyage-cost-breakdown.component.scss"],
     changeDetection: ChangeDetectionStrategy.OnPush
 })
export class VoyageCostBreakdownComponent {
    @Input() overallCost: number;
    @Input() breakdown: ReadonlyArray<VoyageCostBreakdown>;

    trackBy(index: number, item: VoyageCostBreakdown) {
        return item.id;
    }
}
