<div class="ops-ltc-terms-container" *ngIf="laytimeCalculationTermsForm$ | async as form">
    <div class="p-grid p-pb-3" *ngIf="rateUnitLabel$ | async as rateUnitLabel">
        <div class="p-field p-col-2">
            <label data-cy="demurrage-rate-label">Demurrage Rate ({{rateUnitLabel}})</label>
            <input [opsNumber]="{ precision: 3, type: 'string' }" type="text" class="p-inputtext text-right"
                   [ngrxFormControlState]="form.controls.demurrageRate" data-cy="demurrage-rate" />
        </div>
        <div class="p-field p-col-2" *opsNgrxVisibility="form.controls.detentionRate">
            <label data-cy="detention-rate-label">Detention Rate ({{rateUnitLabel}})</label>
            <input [opsNumber]="{ precision: 3, type: 'string' }" type="text" class="p-inputtext text-right"
                   [ngrxFormControlState]="form.controls.detentionRate" data-cy="detention-rate" />
        </div>
        <div class="p-field p-col-2" *opsNgrxVisibility="form.controls.despatchRate">
            <label data-cy="despatch-rate-label">Despatch Rate ({{rateUnitLabel}})</label>
            <input [opsNumber]="{ precision: 3, type: 'string' }" type="text" class="p-inputtext text-right"
                   [ngrxFormControlState]="form.controls.despatchRate" data-cy="despatch-rate" />
        </div>
        <div class="p-field p-col-2">
            <label>Calculation Unit</label>
            <ng-select class="ops-select" [ngrxFormControlState]="form.controls.durationUnit" [items]="laytimeDurationUnits"
                       [clearable]="false" [selectOnTab]="true" data-cy="calculation-unit">
            </ng-select>
        </div>
        <div class="p-field p-col-2">
            <label>Time Allowance</label>
            <ng-select class="ops-select" [ngrxFormControlState]="form.controls.timeAllowance" [items]="timeAllowances"
                       [clearable]="false" [selectOnTab]="true" data-cy="time-allowance">
            </ng-select>
        </div>
        <div class="p-field p-col-2" *opsNgrxVisibility="form.controls.fixedAllowanceHours">
            <label>Total Allowed Laytime (hours)</label>
            <input [opsNumber]="{ precision: 2, type: 'string' }" type="text" class="p-inputtext text-right"
                   [ngrxFormControlState]="form.controls.fixedAllowanceHours" data-cy="total-allowed-laytime" />
        </div>
    </div>
    <div class="p-grid p-pb-3">
        <div class="p-field p-col-2" *opsNgrxVisibility="form.controls.timeSaved">
            <label>Time Saved</label>
            <ng-select class="ops-select" [ngrxFormControlState]="form.controls.timeSaved" [items]="timesSaved"
                       [clearable]="false" [selectOnTab]="true" data-cy="time-saved">
            </ng-select>
        </div>
        <div class="p-field p-col-2">
            <label>Rounding</label>
            <ng-select class="ops-select" [ngrxFormControlState]="form.controls.rounding"
                       [items]="laytimeCalculationRoundings" [clearable]="false" [selectOnTab]="true"
                       data-cy="rounding">
            </ng-select>
        </div>
        <div class="p-col-5 p-d-flex">
            <div class="p-field p-field-checkbox">
                <input id="alwaysOnDemurrage" type="checkbox" name="alwaysOnDemurrage" class="p-checkbox"
                       [ngrxFormControlState]="form.controls.alwaysOnDemurrage" data-cy="always-on-demurrage" />
                <label for="alwaysOnDemurrage">Once on demurrage, always on demurrage</label>
            </div>
            <div class="p-field p-field-checkbox" *opsNgrxVisibility="form.controls.demurrageBank">
                <input id="demurrageBank" type="checkbox" name="demurrageBank" class="p-checkbox"
                       [ngrxFormControlState]="form.controls.demurrageBank" data-cy="demurrage-bank" />
                <label for="demurrageBank">Demurrage Bank</label>
            </div>
        </div>
    </div>
    <ops-laytime-calculation-cargo-terms
        *ngIf="form.value.timeAllowance !== 'Fixed' && (cargoTerms$ | async)?.length"
        [cargoTerms]="(cargoTerms$ | async)"
        [currentCargoTermId]="currentCargoTermsId$ | async"
        [cargoTermsForm]="cargoTermsForm$ | async"
        (edit)="editCargoTerms($event)"
        (cancelEdit)="cancelEditCargoTerms()"
        (save)="saveCargoTerms()"
        (remove)="removeCargoTerms($event)"
        (removeLocation)="removeCargoTermsLocation($event)"
    ></ops-laytime-calculation-cargo-terms>
    <div class="p-grid">
        <div class="p-field p-col-6">
            <div class="p-d-flex p-jc-between">
                <label>Comments</label>
                <ops-length-counter [length]="form.controls.comments.value?.length"
                                    [maxLength]="maxLength"></ops-length-counter>
            </div>
            <textarea class="p-inputtext p-inputtextarea" [ngrxFormControlState]="form.controls.comments"
                      [attr.maxlength]="maxLength"
                      rows="3" data-cy="comments"></textarea>
        </div>
    </div>
</div>
