import { box, Boxed, unbox } from "ngrx-forms";
import { v4 as uuid } from "uuid";

import { deepCopy, Identity, Nullable } from "@ops/shared";
import { Enumeration } from "@ops/shared/reference-data";

import { AssociatedCargoExpense } from "../../shared/models";
import { AssociatedCargoExpenseUnit } from "../../shared/models/enums/associated-cargo-expense-unit";

export type AssociatedCargoExpenseId = Identity<string, "AssociatedCargoExpenseId">;
export const createAssociatedCargoExpenseId = (): AssociatedCargoExpenseId => uuid() as AssociatedCargoExpenseId;

export type AssociatedCargoExpenseForm = Readonly<{
    associatedCargoExpenseId: AssociatedCargoExpenseId;
    legacyAssociatedCargoExpenseId: number;
    freightRate: Nullable<number>;
    rateDescription?: Boxed<Enumeration>;
    value?: number;
    unit?: Boxed<Enumeration>;
}>;

export const associatedCargoExpenseForm = (associatedCargoExpenseId: AssociatedCargoExpenseId, legacyAssociatedCargoExpenseId: number): AssociatedCargoExpenseForm => ({
    associatedCargoExpenseId,
    legacyAssociatedCargoExpenseId,
    freightRate: null,
    unit: box(null),
    value: null,
    rateDescription: box(null)
});

export const associatedCargoExpenseToForm = (source: AssociatedCargoExpense): AssociatedCargoExpenseForm => ({
    associatedCargoExpenseId: source.id,
    legacyAssociatedCargoExpenseId: source.associatedCargoExpenseId,
    rateDescription: box(deepCopy(source.rateDescription)),
    value: source.value,
    unit: box(deepCopy(source.unit)),
    freightRate: source.freightRate
});

export const formToAssociatedCargoExpense = (source: AssociatedCargoExpenseForm): AssociatedCargoExpense => ({
    id: source.associatedCargoExpenseId,
    associatedCargoExpenseId: source.legacyAssociatedCargoExpenseId,
    rateDescription: unbox(source.rateDescription),
    value: source.value,
    unit: unbox(source.unit),
    freightRate: source.freightRate
});

export const templateExpenseId: AssociatedCargoExpenseId = "{00000000-0000-0000-0000-000000000000}" as AssociatedCargoExpenseId;

//Checks if Associated Cargo Expense is a template record which should not be saved on a server
export const isTemplateExpenseRecord = (expense: AssociatedCargoExpenseForm): boolean =>
    expense.associatedCargoExpenseId === templateExpenseId &&
    expense.freightRate === null &&
    expense.value === null &&
    unbox(expense.unit) === null &&
    unbox(expense.rateDescription) === null;

/**
 * Calculates the monetary value of the expense. If the expense does not have a unit, the returned value will be `null`.
 */
export const getExpenseValue = (expense: AssociatedCargoExpense): number | null => {
    if (!expense.unit) {
        return null;
    }

    if (expense.unit.id === AssociatedCargoExpenseUnit.LumpSum) {
        return expense.value;
    }

    if (expense.freightRate) {
        return expense.value * expense.freightRate;
    }

    return 0;
};
