import { Directive, Input, OnDestroy } from "@angular/core";
import { Table } from "primeng/table";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({ selector: "p-table[formGridReadonly]" })
export class FormGridDirective implements OnDestroy {
    private readonly destroy$ = new Subject();
    private changes$ = new Subject();
    isReadonly: boolean;

    @Input() set formGridReadonly(value: boolean) {
        this.isReadonly = value;
        this.notifyAboutChanges();
    }

    get tableChanges$() {
        return this.changes$.asObservable();
    }

    get editingCell() {
        return this.table.editingCell;
    }

    constructor(public table: Table) {
        this.table.onEditInit.pipe(takeUntil(this.destroy$)).subscribe(this.notifyAboutChanges);
        this.table.onEditCancel.pipe(takeUntil(this.destroy$)).subscribe(this.notifyAboutChanges);
        this.table.onEditComplete.pipe(takeUntil(this.destroy$)).subscribe(this.notifyAboutChanges);
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    private notifyAboutChanges = () => {
        this.changes$.next(true);
    };
}
