import { addActivityCargoFormReducer, addActivityCargoReducer } from "./form/add-activity-cargo";
import { importActivityCargoTermsReducer } from "./form/import-activity-cargo-terms";
import { removeActivityCargoFormButtonClickedReducer, removeActivityCargoReducer } from "./form/remove-activity-cargo";
import { updateActivityCargoReducer } from "./form/update-activity-cargo";

export default [
    addActivityCargoFormReducer,
    addActivityCargoReducer,
    removeActivityCargoFormButtonClickedReducer,
    removeActivityCargoReducer,
    updateActivityCargoReducer,
    importActivityCargoTermsReducer
];
