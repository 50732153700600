import { createAction } from "@ngrx/store";

/* ACTIONS */
const FIXTURE_GRID_ROW_SELECT = "[Fixture Grid] Row Select";
const FIXTURE_GRID_SEARCH_SELECT = "[Fixture Grid] Search Select";
const FIXTURE_BOARD_BUTTON_SELECT = "[Fixture Board] Button Select";
const FIXTURE_GRID_BUTTON_SELECT = "[Fixture Grid] Button Select";
const FIXTURE_BOARD_CARD_SELECT = "[Fixture Board] Card Select";

export const fixtureGridRowSelectAction = createAction(FIXTURE_GRID_ROW_SELECT);
export const fixtureGridSearchSelectAction = createAction(FIXTURE_GRID_SEARCH_SELECT);
export const fixtureBoardButtonSelectAction = createAction(FIXTURE_BOARD_BUTTON_SELECT);
export const fixtureGridButtonSelectAction = createAction(FIXTURE_GRID_BUTTON_SELECT);
export const fixtureBoardCardSelectAction = createAction(FIXTURE_BOARD_CARD_SELECT);
