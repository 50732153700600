import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { saveAs } from "file-saver";
import { BehaviorSubject, of, Subscription } from "rxjs";
import { catchError, debounceTime, filter, shareReplay, skip, switchMap } from "rxjs/operators";

import { DocumentDataService } from "../services/document-data.service";
import { DocumentHelperService } from "../services/document-helper.service";
import { DocumentView } from "../shared/document-view.model";

@Component({
    selector: "ops-document",
    templateUrl: "./document.component.html",
    styleUrls: ["./document.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentComponent implements OnInit, OnDestroy {
    static componentName = "DocumentComponent";

    private gatewayToggleSub: Subscription;
    private readonly gatewayVisiblePermissionName = "Ops.GatewayVisible";

    gatewayVisibility$: BehaviorSubject<any>;
    isVisible = true;
    fileIconClass: string;
    downloadFailed = false;
    gatewayVisibleToggleFailed = false;
    deleteFailed = false;

    @Input() document: DocumentView;
    @Output() removeAction = new EventEmitter<string>();

    get showFailureWrapper(): boolean {
        return this.downloadFailed || this.gatewayVisibleToggleFailed || this.deleteFailed;
    }

    constructor(private changeDetectorRef: ChangeDetectorRef, private documentHelperService: DocumentHelperService, private documentDataService: DocumentDataService) {}

    ngOnInit(): void {
        this.gatewayVisibility$ = new BehaviorSubject<any>({ value: this.document.permissions.some((p) => p === this.gatewayVisiblePermissionName) });
        this.fileIconClass = this.documentHelperService.getFileIconClass(this.document.file.extension);

        this.gatewayToggleSub = this.gatewayVisibility$
            .pipe(
                skip(1),
                debounceTime(10),
                filter((x) => !x.failed),
                switchMap((setVisible) => {
                    this.gatewayVisibleToggleFailed = false;
                    const request = setVisible.value
                        ? this.documentDataService.addPermission(this.document, this.gatewayVisiblePermissionName)
                        : this.documentDataService.deletePermission(this.document, this.gatewayVisiblePermissionName);

                    return request.pipe(
                        catchError(() => {
                            this.gatewayVisibleToggleFailed = true;
                            this.changeDetectorRef.markForCheck();
                            this.gatewayVisibility$.next({ failed: true, value: !setVisible.value });
                            return of({});
                        })
                    );
                }),
                shareReplay(1)
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.gatewayToggleSub.unsubscribe();
    }

    remove(): void {
        this.removeAction.emit(this.document.documentId);
    }

    delete(): void {
        this.isVisible = false;
        this.documentDataService.deleteDocument(this.document).subscribe(
            () => this.remove(),
            () => {
                this.changeDetectorRef.markForCheck();
                this.deleteFailed = true;
                this.isVisible = true;
            }
        );
    }

    download(): void {
        this.document.loadPercentage$.next(0);
        this.documentDataService.downloadDocument(this.document).subscribe(
            (res) => {
                switch (res.status) {
                    case "progress":
                        this.document.loadPercentage$.next(res.message);
                        break;
                    case "complete":
                        this.downloadFailed = false;
                        this.document.loadPercentage$.next(null);
                        saveAs(res.message, this.document.file.name);
                        break;
                    default:
                        break;
                }
            },
            () => {
                this.downloadFailed = true;
                this.document.loadPercentage$.next(null);
            }
        );
    }

    toggleGatewayVisible(): void {
        this.gatewayVisibility$.next({ value: !this.gatewayVisibility$.getValue().value });
    }
}
