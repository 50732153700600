import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { FieldEditorModel } from "./field-editor.model";
import { FieldEditorRowModel } from "../../../fixture/shared/models/form-models/field-editor-row.model";

@Component({
    selector: "ops-field-editor-modal",
    templateUrl: "./field-editor-modal.component.html",
    styleUrls: ["./field-editor-modal.component.scss"]
})
export class FieldEditorModalComponent {
    editorRows: FieldEditorRowModel[] = [new FieldEditorRowModel()];
    selectedFields: string[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public model: FieldEditorModel, private dialogRef: MatDialogRef<FieldEditorModalComponent>) {}

    onAdd(index: number) {
        if (this.model.fields.length > this.editorRows.length) {
            this.editorRows.splice(index + 1, 0, new FieldEditorRowModel());
        }
    }

    onRemove(index: number) {
        if (this.editorRows.length > 1) {
            this.editorRows.splice(index, 1);
            this.updateSelectedFields();
        }
    }

    fieldChange(row: FieldEditorRowModel) {
        row.field = this.model.fields.find((f) => f.id === row.id);
        row.quantity = { unit: undefined, value: undefined };
        this.updateSelectedFields();
    }

    updateSelectedFields() {
        this.selectedFields = this.editorRows.map((r) => r.id);
    }

    cancel() {
        this.dialogRef.close();
    }

    next() {
        if (this.enableNext()) {
            this.dialogRef.close(this.editorRows);
        }
    }

    enableNext(): boolean {
        return this.editorRows.filter((x) => x.id).length > 0;
    }
}
