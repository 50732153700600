import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { AppConfigService } from "./core";
import { InitialParamsService } from "./core/initial-params.service";
import { LumpSumFreightRateService } from "./fixture/services/lumpsum-freight-rate.service";
import { LeftBarStateService } from "./left-bar/left-bar-state.service";

@Component({
    selector: "ops-app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
    static componentName = "AppComponent";

    private readonly destroy$ = new Subject();

    isLeftToolbarCollapsed$: Observable<boolean>;
    showSeaHeader = true;

    fixturesLink = this.getLink("fixtures");
    claimsLink = this.getLink("claims");
    actionsLink = this.getLink("actions");
    coasLink = this.getLink("coas");
    ltcLink = this.getLink("ltc");

    @HostBinding("class.ops-lhp--active")
    leftPanelExpanded: boolean;

    constructor(
        private leftBarStateService: LeftBarStateService,
        private appConfigService: AppConfigService,
        private router: Router,
        private route: ActivatedRoute,
        @Inject("Window") private window: Window,
        private changeDetectorRef: ChangeDetectorRef,
        _lumpsumFreightRateService: LumpSumFreightRateService, // don't remove (alex)
        private titleService: Title,
        private initialParamsService: InitialParamsService
    ) {}

    ngOnInit() {
        this.isLeftToolbarCollapsed$ = this.leftBarStateService.isCollapsed$;
        this.isLeftToolbarCollapsed$.pipe(takeUntil(this.destroy$)).subscribe((collapsed) => {
            this.leftPanelExpanded = !collapsed;
            this.changeDetectorRef.detectChanges();
        });

        this.manageSeaHeaderVisibility();

        this.router.events
            .pipe(
                takeUntil(this.destroy$),
                filter((e) => e instanceof NavigationEnd)
            )
            .subscribe(() => {
                this.fixturesLink = this.getLink("fixtures");
                this.claimsLink = this.getLink("claims");
                this.actionsLink = this.getLink("actions");
                this.coasLink = this.getLink("coas");
                this.ltcLink = this.getLink("ltc");
                this.changeDetectorRef.detectChanges();
                const page = this.route.firstChild.snapshot?.data?.title;
                if (page) {
                    this.titleService.setTitle(page);
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    onOverlayClick() {
        this.leftBarStateService.collapse();
    }

    private manageSeaHeaderVisibility() {
        const hideSeaHeaderParam = this.initialParamsService.hasQueryParam("hideSeaHeader");
        this.showSeaHeader = !(hideSeaHeaderParam && this.inIframe());
        this.changeDetectorRef.detectChanges();
    }

    private inIframe() {
        try {
            return this.window.self !== this.window.top;
        } catch (e) {
            return true;
        }
    }

    private getLink(primary: string) {
        const toolbarRoute = this.route.root.children.find((r) => r.outlet === "toolbar");

        if (toolbarRoute) {
            const toolbarPath = toolbarRoute.routeConfig.path;

            if (toolbarPath && !["favorites", "worksheets"].includes(toolbarPath)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return [{ outlets: { primary: [primary], toolbar: null as any } }];
            }
        }

        return [{ outlets: { primary: [primary] } }];
    }
}
