import { Injectable } from "@angular/core";
import { CommandHandler } from "src/app/fixture/mediator/command-handlers/command-handler";
import { Fixture } from "src/app/fixture/shared/models";
import { OffHireGridRow } from "src/app/fixture/shared/models/form-models/offhire-gridrow.model";
import { DeleteOffHireCommand } from "src/app/fixture/time-charter/hire-tab/offhire/commands/delete-offhire.command";

@Injectable({
    providedIn: "root"
})
export class DeleteOffHireCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: DeleteOffHireCommand) {
        const updatedOffhire = <OffHireGridRow>command.payload;
        const existingPeriod = fixture.periods.find((f) => f.hireId === updatedOffhire.hireId);
        existingPeriod.offHires.splice(updatedOffhire.offHireIndex, 1);
    }
}
