import { createAction, on, On, props } from "@ngrx/store";
import {
    ActivityId,
    associatedCargoExpandedKey,
    AssociatedCargoId, BerthId,
    DestinationId,
    FixturesState
} from "../../model";
import { currentVoyageStateExpansionReducer } from "../../voyage/reducer";

/* ACTIONS */
export const expandAssociatedCargoAction = createAction("[Voyage Form] Expand Associated Cargo", props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId, associatedCargoId: AssociatedCargoId }>());
export const collapseAssociatedCargoAction = createAction("[Voyage Form] Collapse Associated Cargo", props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId, associatedCargoId: AssociatedCargoId }>());

/* REDUCERS */
export const expandAssociatedCargoReducer: On<FixturesState> = on(expandAssociatedCargoAction, (state, action) =>
    currentVoyageStateExpansionReducer(state, associatedCargoExpandedKey(action.destinationId, action.berthId, action.activityId, action.associatedCargoId), true)
);
export const collapseAssociatedCargoReducer: On<FixturesState> = on(collapseAssociatedCargoAction, (state, action) =>
    currentVoyageStateExpansionReducer(state, associatedCargoExpandedKey(action.destinationId, action.berthId, action.activityId, action.associatedCargoId), false)
);
