import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { AppInsightsService, AuthService } from "@ops/core";
import { isNullOrUndefined } from "@ops/shared";
import { Enumeration } from "@ops/shared/reference-data";

import { ActionDataService } from "./services/action-data.service";
import { ActionExpandStateService } from "./services/action-expand-state.service";
import { ActionIsCompleteRequest } from "./shared/action-is-complete-request";
import { ActionType } from "./shared/action-type";
import { ActionDto, ActionFormModel } from "../../action";
import { dateToISOString, parseISODate } from "../../shared/date-utils/date-utilities";
import { LeftBarStateService } from "../left-bar-state.service";

const NEW_ACTIONS_LABEL_RESET_TIMEOUT = 3000;

@Component({
    selector: "ops-actions",
    templateUrl: "./actions.component.html",
    styleUrls: ["./actions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsComponent implements OnInit, OnDestroy, AfterViewChecked {
    static componentName = "ActionsComponent";

    private readonly destroy$ = new Subject<void>();
    private needToScrollToNew = false;

    readonly placeholderDescription = "Click on Create Action to start adding actions against this fixture.";

    // We only use the fixture to pass the type/id to ops-action/ops-user-autosuggest which filters by either
    // GAIN users via the FixtureId or by Sharing Groups.
    // If we have a COA and not a Fixture, it'll pass nulls to the autosuggest component, meaning it will look up
    // via Sharing Group
    //fixture: Fixture;
    coaId: string = null;
    fixtureId: string = null;
    fixtureSource: Enumeration = null;

    actions: ActionFormModel[] = [];
    newActionIds: string[] = [];
    editingAction = false;
    editActionTitle: string;
    actionToEdit: ActionFormModel;
    isOverdue = false;
    isPending = false;
    isCompleted = false;
    priorities: Enumeration[] = [
        { id: 1, name: "High" },
        { id: 2, name: "Medium" },
        { id: 3, name: "Low" }
    ];
    isLoaded = false;

    @ViewChild("scrollToPlaceholder")
    scrollToPlaceholder: ElementRef;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private route: ActivatedRoute,
        private actionExpandStateService: ActionExpandStateService,
        private leftBarStateService: LeftBarStateService,
        private actionDataService: ActionDataService,
        private authService: AuthService,
        private appInsightsMonitoringService: AppInsightsService
    ) {}

    ngOnInit() {
        // The ActionDataService tracks the Coa/Fixture changes and reports them to this component.
        this.actionDataService.source$.pipe(takeUntil(this.destroy$)).subscribe((origin) => {
            if (origin) {
                this.isOverdue = true;
                this.isPending = true;
                this.isCompleted = false;
            }

            this.fixtureId = null;
            this.fixtureSource = null;
            this.coaId = null;

            if (origin) {
                if ("fixtureSource" in origin) {
                    this.fixtureId = origin.fixtureId;
                    this.fixtureSource = origin.fixtureSource;
                } else if ("coaId" in origin) {
                    this.coaId = origin.coaId;
                }
            }
        });

        this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
            const selectedActionId = params["actionId"];
            if (selectedActionId) {
                this.actionExpandStateService.collapseAll();
                this.expandAction(selectedActionId);
                this.leftBarStateService.open();
            }
        });

        this.actionDataService.actions$
            .pipe(
                filter((x) => !isNullOrUndefined(x)),
                takeUntil(this.destroy$)
            )
            .subscribe((actionDtos) => {
                this.mergeNewActionIds(this.actionDataService.getNewActionIds());
                this.actions = this.mapActionDtosToFormModels(actionDtos, this.newActionIds);
                this.changeDetectorRef.markForCheck();
                this.needToScrollToNew = this.newActionIds && this.newActionIds.length > 0;
                this.isLoaded = true;
            });

        this.actionDataService.editingActionId$
            .pipe(
                filter((x) => !!x),
                takeUntil(this.destroy$)
            )
            .subscribe((actionId) => {
                if (this.editingAction) {
                    this.editingAction = false;
                    this.changeDetectorRef.markForCheck();
                }
                // setTimeout is necessary for when an action is already editing when a new editing id is selected.
                // we have to allow time for the edit window to close and re-render.
                setTimeout(() => {
                    this.editAction(actionId);
                    this.changeDetectorRef.markForCheck();
                });
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewChecked() {
        if (this.needToScrollToNew) {
            this.scrollToFirstNew();
            this.needToScrollToNew = false;
        }
    }

    addAction() {
        this.actionToEdit = new ActionFormModel();
        this.actionToEdit.type = ActionType.UserCreated;
        this.actionToEdit.priority = this.priorities[1];
        this.actionToEdit.assignedTo = this.actionDataService.getDefaultAssignedTo();

        this.editActionTitle = "Create Action";
        this.editingAction = true;
    }

    cancelEditAction() {
        this.editingAction = false;
        this.actionDataService.setEditingActionIdValue(null);
    }

    updateActionComplete(request: ActionIsCompleteRequest) {
        this.appInsightsMonitoringService.logInfo(
            `Action isComplete: ${request.isComplete}, From LH Panel, actionId: ${request.actionId}, Fixture/CoaId: ${this.fixtureId || this.coaId}`
        );
        this.actionDataService.updateActionComplete(request.actionId, request.isComplete);
    }

    deleteAction(action: ActionDto) {
        this.actionDataService.deleteAction(action.actionId);
        this.editingAction = false;
        this.actionDataService.setEditingActionIdValue(null);
    }

    saveAction(action: ActionDto) {
        action.updatedDate = dateToISOString(new Date());
        action.lastUpdatedBy = this.authService.user;
        if (action.actionId) {
            this.actionDataService.updateAction(action);
        } else {
            this.actionDataService.createAction(action).subscribe(() => {});
        }
        this.editingAction = false;
    }

    editAction(actionId: string) {
        this.actionToEdit = this.actions.find((action) => action.actionId === actionId);
        this.editActionTitle = "Edit Action";
        this.editingAction = true;
    }

    cloneAction(actionId: string) {
        const actionToClone = this.actions.find((action) => action.actionId === actionId);

        if (actionToClone) {
            this.actionToEdit = {
                ...actionToClone,
                actionId: null,
                summary: `${actionToClone.summary} (Clone)`,
                type: ActionType.UserCreated,
                updatedDate: null,
                lastUpdatedBy: null,
                isOverdue: false,
                isPending: false
            };

            this.editActionTitle = "Clone Action";
            this.editingAction = true;
        }
    }

    get tooltipContent(): string {
        let appliedFilters = "Filters Applied:";

        if (this.isOverdue) {
            appliedFilters += " Overdue,";
        }
        if (this.isPending) {
            appliedFilters += " Pending,";
        }
        if (this.isCompleted) {
            appliedFilters += " Completed,";
        }

        if (!this.isOverdue && !this.isPending && !this.isCompleted) {
            appliedFilters = "No filters applied ";
        }

        return appliedFilters.substr(0, appliedFilters.length - 1);
    }

    scrollToTop() {
        this.scrollToPlaceholder.nativeElement.scrollTo(0, 0);
    }

    isExpanded(actionId: string): boolean {
        return this.actionExpandStateService.isExpanded(actionId);
    }

    expandAll() {
        this.actionExpandStateService.expand(this.actions.map((action) => action.actionId));
    }

    collapseAll() {
        this.actionExpandStateService.collapseAll();
    }

    allCompleted(isCompleted: boolean): boolean {
        if (!isCompleted && this.actions.length > 0) {
            return !this.actions.some((a) => a.isComplete === false);
        }
    }

    toggleExpansion(actionId: string) {
        this.actionExpandStateService.toggleExpansion(actionId);
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }

    private expandAction(actionId: string) {
        this.actionExpandStateService.expandAction(actionId);
    }

    private mergeNewActionIds(newActionIds: string[]) {
        newActionIds.forEach((id) => {
            if (!this.newActionIds.includes(id)) {
                this.newActionIds.push(id);
                this.scheduleIsNewReset(id);
            }
        });
    }

    private scheduleIsNewReset(id: string) {
        setTimeout(() => {
            const index = this.newActionIds.indexOf(id);
            if (index >= 0) {
                this.newActionIds.splice(index, 1);
                const action = this.actions.find((a) => a.actionId === id);
                if (action) {
                    action.isNew = false;
                    action.isFirstNew = false;
                }
                this.changeDetectorRef.detectChanges();
            }
        }, NEW_ACTIONS_LABEL_RESET_TIMEOUT);
    }

    private mapActionDtosToFormModels(actionDtos: ActionDto[], newActionIds: string[]): ActionFormModel[] {
        const actions = actionDtos.map((action) => this.mapToFormModel(action, newActionIds));

        for (const action of actions) {
            if (action.isNew) {
                action.isFirstNew = true;
                break;
            }
        }

        return actions;
    }

    private mapToFormModel(actionDto: ActionDto, newActionIds: string[]): ActionFormModel {
        const actionFormModel = new ActionFormModel();
        actionFormModel.actionId = actionDto.actionId;
        actionFormModel.assignedTo = actionDto.assignedTo;
        actionFormModel.description = actionDto.description;
        actionFormModel.dueDate = parseISODate(actionDto.dueDate);
        actionFormModel.priority = actionDto.priority;
        actionFormModel.summary = actionDto.summary;
        actionFormModel.updatedDate = actionDto.updatedDate;
        actionFormModel.lastUpdatedBy = actionDto.lastUpdatedBy;
        actionFormModel.isComplete = actionDto.isComplete;
        actionFormModel.notificationRecipients = actionDto.notificationRecipients;
        actionFormModel.type = actionDto.type;
        actionFormModel.isNew = newActionIds.find((a) => a === actionDto.actionId) !== undefined;

        if (actionDto.notificationDates) {
            actionFormModel.notificationDates = actionDto.notificationDates.map((date) => parseISODate(date));
        }

        return actionFormModel;
    }

    private scrollToFirstNew() {
        const element = document.getElementById("firstnew");
        if (element) {
            element.scrollIntoView({
                behavior: "smooth"
            });
        }
    }
}
