import { AbstractControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import * as R from "ramda";
import { combineLatest, merge, Observable, Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { updateValidationTabAction } from "../state/validation";
import { FixtureTabName } from "./tab-validation/tab-validation-info";

export class FormComponentBase {
    private formStatusChangesUnsubscribe$ = new Subject();
    private forms: AbstractControl[];
    private fixtureTabName: FixtureTabName;
    private isValid: boolean;
    protected formValueSubscription: Subscription | null;

    constructor(protected store?: Store) {}

    protected removeFormSubscriptions() {
        if (this.formValueSubscription) {
            this.formValueSubscription.unsubscribe();
            this.formValueSubscription = null;
        }
    }

    protected removeStatusChangesSubscrition() {
        this.formStatusChangesUnsubscribe$.next();
        this.formStatusChangesUnsubscribe$.complete();
    }

    protected subscribeToFormValueChanges(form: AbstractControl, handler: (_: any) => void): void {
        if (this.formValueSubscription) {
            throw Error("There is already a subscription");
        }

        this.formValueSubscription = form.valueChanges.subscribe((data) => {
            handler(data);
        });
    }

    // We need this function in case we have our form updated without triggering statusChanges event
    protected updateValidationTab() {
        if (this.forms && this.fixtureTabName) {
            const everyFormIsValid = this.forms.every((form) => !form.invalid);
            this.formChangesHandler(this.fixtureTabName)(everyFormIsValid);
        }
    }

    protected subscribeToFormStatusChanges(forms: AbstractControl[], tabName: FixtureTabName) {
        this.forms = forms;
        this.fixtureTabName = tabName;
        this.getFormStatusChanges$(forms).pipe(takeUntil(this.formStatusChangesUnsubscribe$)).subscribe(this.formChangesHandler(tabName));
    }

    protected subscribeToFormStatusChangesAndNgrxFormChanges(forms: AbstractControl[], ngrxFormsIsValid$: Observable<boolean>, tabName: FixtureTabName) {
        this.forms = forms;
        this.fixtureTabName = tabName;
        const ngFormsIsValid$ = this.getFormStatusChanges$(forms);

        combineLatest([ngFormsIsValid$, ngrxFormsIsValid$])
            .pipe(map(R.all(R.equals<boolean>(true))), takeUntil(this.formStatusChangesUnsubscribe$))
            .subscribe(this.formChangesHandler(tabName));
    }

    private getFormStatusChanges$(forms: AbstractControl[]) {
        const everyFormIsValidMapper = () => forms.every((form) => !form.invalid);
        return merge(...forms.map((form) => form.statusChanges)).pipe(map(everyFormIsValidMapper));
    }

    private formChangesHandler(tabName: FixtureTabName) {
        return (isValid: boolean) => {
            if (this.isValid !== isValid) {
                this.isValid = isValid;
                this.store?.dispatch(updateValidationTabAction({ tabName, isValid }));
            }
        };
    }
}
