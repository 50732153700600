import { Injectable } from "@angular/core";
import { CommandHandler } from "../../../../mediator/command-handlers/command-handler";
import { Fixture } from "../../../../shared/models";
import { BunkerFormModel } from "../../../../shared/models/form-models/bunker.model";
import { UpdateBunkerCommand } from "./update-bunker.command";

@Injectable({
    providedIn: "root"
})
export class UpdateBunkerCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateBunkerCommand) {
        const bunkers = <BunkerFormModel[]>command.payload;
        fixture.redelivery.bunkers = bunkers || [];
    }
}
