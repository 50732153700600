<ops-popup [title]="model.title">
    <div class="row no-gutters">
        <div class="form-group col pt-0">
            <label for="subject">Subject</label>
            <div class="ops-text-row">
                <input class="form-control" readonly="true" type="text" data-test-subject [value]="model.subject" />
                <div class="copy-btn-container">
                    <ops-action-button
                        type="clone"
                        icon="content-copy"
                        tooltip="Copy to clipboard"
                        tooltipPlacement="left"
                        (click)="copyToClipboard(model.subject)"
                    ></ops-action-button>
                </div>
            </div>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="form-group col pb-0">
            <label for="body">Body</label>
            <div class="ops-text-row">
                <textarea class="form-control" readonly="true" type="text" rows="10" data-test-body [value]="model.body"> </textarea>
                <div class="copy-btn-container">
                    <ops-action-button
                        type="clone"
                        icon="content-copy"
                        tooltip="Copy to clipboard"
                        tooltipPlacement="left"
                        (click)="copyToClipboard(model.body)"
                    ></ops-action-button>
                </div>
            </div>
        </div>
    </div>
</ops-popup>
