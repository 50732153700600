<div [formGroup]="redeliveryForm" class="card h-100">
    <div class="card-header row no-gutters align-items-center">
        <span class="title">Redelivery</span> <span ngbTooltip="{{ headerDate.tooltip }}">{{ headerDate.header }}</span>
    </div>
    <div class="card-body">
        <div class="row no-gutters">
            <div class="form-group col">
                <label>Redelivery ({{ redeliveryLocationCount }})</label>
                <ops-location-autosuggest
                    [multiple]="true"
                    formControlName="redeliveryLocations"
                    warningFormControl
                    data-test-redelivery-locations
                ></ops-location-autosuggest>
                <ops-validation [form]="redeliveryForm.get('redeliveryLocations')" [upsertTextFns]="locationsValidationFns"></ops-validation>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="form-group col-6">
                <ops-notices formControlName="redeliveryNotices" title="Redelivery Notice" [parentForm]="redeliveryForm"></ops-notices>
                <ops-validation [form]="redeliveryForm.get('redeliveryNotices')"></ops-validation>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="form-group col-3">
                <label for="earliestRedeliveryDate">Earliest Redelivery (UTC)</label>
                <ops-date-input
                    [enableTime]="true"
                    timeZone="utc"
                    [formControl]="earliestRedeliveryDate"
                    data-test-earliest-redelivery-date
                ></ops-date-input>
            </div>
            <div class="form-group col-3 pr-0">
                <label for="latestRedeliveryDate">Latest Redelivery (UTC)</label>
                <ops-date-input [enableTime]="true" timeZone="utc" [formControl]="latestRedeliveryDate" data-test-latest-redelivery-date></ops-date-input>
            </div>
            <div class="form-group col-3">
                <label for="deliveryDate">Estimated Redelivery (UTC)</label>
                <ops-date-input
                    timeZone="utc"
                    [enableTime]="true"
                    formControlName="estimatedRedeliveryDate"
                    [defaultFocusDate]="lastPeriodTo"
                    data-test-estimatedRedelivery-date
                ></ops-date-input>
            </div>
            <div class="form-group col-3">
                <label for="deliveryDate">Actual Redelivery (UTC)</label>
                <ops-date-input
                    timeZone="utc"
                    [enableTime]="true"
                    formControlName="actualRedeliveryDate"
                    [defaultFocusDate]="lastPeriodTo"
                    data-test-actualRedelivery-date
                ></ops-date-input>
            </div>
        </div>
        <div class="bunkers-redelivery">
            <ops-bunkers
                title="Bunker On Redelivery"
                [bunkers]="redelivery.bunkers"
                [parentForm]="redeliveryForm"
                (bunkerUpdated)="bunkerUpdated($event)"
                (bunkerRemoved)="bunkerRemoved($event)"
                (bunkerAdded)="bunkerAdded()"
            ></ops-bunkers>
        </div>
        <div class="comments">
            <div class="row no-gutters align-items-center toggle-comments">
                <div class="form-group col d-flex flex-column p-0">
                    <label class="row no-gutters" for="comments" [ngClass]="{ 'mb-0': hideComments }">
                        <button
                            type="button"
                            (click)="hideComments = !hideComments"
                            class="has-icon"
                            [ngClass]="{ 'icon--arrow-drop-right': hideComments, 'icon--arrow-drop-down': !hideComments }"
                        ></button>
                        <span class="col">Redelivery Comments</span>
                    </label>
                    <textarea
                        formControlName="comments"
                        *ngIf="!hideComments"
                        class="form-control"
                        rows="2"
                        cols="75"
                        maxlength="3000"
                        data-test-redelivery-comments
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
