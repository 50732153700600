import { Directive, EventEmitter, Host, Input, OnDestroy, OnInit, Optional, Output } from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { FormState } from "ngrx-forms";
import { Observable, of, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";

import { FixtureWarningPathMapper } from "./fixture-warning-path-mapper";
import { FixtureWarning } from "./fixture-warning.model";
import { FixtureWarningService } from "./fixture-warning.service";
import { getFullPath, pathToControlId } from "./utils";

@Directive({ selector: "[warningActivated]" }) // eslint-disable-line  @angular-eslint/directive-selector
export class FixtureWarningActivatedDirective implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: FormState<any>;

    @Output() warningActivated = new EventEmitter<FixtureWarning>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() set ngrxFormControlState(newState: FormState<any>) {
        this.state = newState;
    }

    @Input() ngrxFormControlId: string;

    @Input() ngrxFormControlIdMatch: "equals" | "startsWith" | "includes" | "endsWith" = "startsWith";

    constructor(@Optional() @Host() private parent: ControlContainer, private warningService: FixtureWarningService, private warningPathMapper: FixtureWarningPathMapper) {}

    ngOnInit() {
        if (!this.parent && !this.state && !this.ngrxFormControlId) {
            throw new Error("warningActive directive must have ngrxFormControlState, ngrxFormControlId or parent ControlContainer");
        }

        this.warningService.currentWarning$
            .pipe(
                takeUntil(this.destroy$),
                switchMap((warning) => {
                    if (!warning) {
                        return of({ warning, pathStartsWith: false });
                    }

                    let match: Observable<boolean>;

                    if (this.ngrxFormControlId || this.state) {
                        const controlId = pathToControlId(warning.path);
                        const matchControlId = this.ngrxFormControlId || this.state.id;

                        match = of(this.ngrxFormControlIdMatch === "equals" ? controlId === matchControlId : controlId[this.ngrxFormControlIdMatch](matchControlId));
                    } else {
                        const fullPath = getFullPath(this.parent);

                        match = this.warningPathMapper.pathStartsWith(warning.path, fullPath);
                    }

                    return match.pipe(
                        map((pathStartsWith) => ({
                            warning,
                            pathStartsWith
                        }))
                    );
                })
            )
            .subscribe(({ warning, pathStartsWith }) => {
                if (pathStartsWith) {
                    this.warningActivated.emit(warning);
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
