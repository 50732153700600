<div [formGroup]="parentForm" class="card">
    <div class="card-header pl-0 row no-gutters align-items-center">
        <button
            type="button"
            class="has-icon btn-inverted"
            [class.icon--arrow-drop-right]="accordion.isClosed"
            [class.icon--arrow-drop-down]="accordion.isOpen"
            (click)="accordionToggle()"
        ></button>
        <div>{{title}}</div>
    </div>
    <div class="card-body" [hidden]="accordion.isClosed" formGroupName="atSeaForm">
        <div class="row">
            <div class="col-5">
                <div>
                    <ops-at-sea-bunker-consumed
                        [readonly]="readonly"
                        [isSimpleMode]="isSimpleMode"
                        [parentForm]="parentForm"
                        [atSeaBunkerConsumptionId]="atSea.id"
                        [bunkersConsumed]="atSea.bunkersConsumed"
                        (bunkerConsumedUpdated)="handleAtSeaBunkerConsumedUpdate($event)"
                    ></ops-at-sea-bunker-consumed>
                </div>
            </div>
            <div class=" col-7 form-group">
                <div class="row">
                    <div class="col-3">
                        <label>Days Above Force {{ maxBeaufortForce }}</label>
                        <input
                            class="form-control text-right"
                            formControlName="daysAboveForce"
                            [opsNumber]="{ precision: 6, minimumFractionDigits: 2, showTrailingZeros: false }"
                            data-test-daysAboveForce
                        />
                    </div>
                    <div class="col-3">
                        <label>Time Taken (Days)<span class="has-icon icon--info-outline" [ngbTooltip]="durationTooltip"></span></label>
                        <input
                            class="form-control text-right"
                            [value]="durationInDays | opsNumber:false:2:6"
                            data-test-time-taken
                            readonly
                        />
                    </div>

                    <div class="col-3">
                        <label>Distance (Nautical Miles)</label>
                        <input
                            class="form-control text-right"
                            formControlName="distance"
                            [opsNumber]="{ precision: 3, minimumFractionDigits: 0, showTrailingZeros: false }"
                            data-test-distance
                        />
                    </div>
                    <div class="col-3">
                        <label>Average Speed (Knots)</label>
                        <input
                            class="form-control text-right"
                            [value]="averageSpeed | opsNumber:false:0:2"
                            data-test-average-speed
                            readonly
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col input-comments col-6">
                        <label>Comments</label>
                        <textarea class="form-control" formControlName="comments" rows="1" cols="75" maxlength="1000" data-test-comments></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
