import { isNullOrUndefined } from "@ops/shared";

import { CargoHistoricalEvent } from "../model";

export type CargoPlanEmailSummary = Readonly<{
    userName: string;
    date: string;
}>;

export const getCargoPlanEmailSummary = (cargoHistory: ReadonlyArray<CargoHistoricalEvent>): CargoPlanEmailSummary => {
    const emailGeneratedEvent = [...(cargoHistory ?? [])].reverse().find((x) => x.name === "LiftingCargoPlanEmailGeneratedV1");

    return !isNullOrUndefined(emailGeneratedEvent)
        ? {
              userName: emailGeneratedEvent.user.name,
              date: emailGeneratedEvent.date
          }
        : null;
};
