import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "../../core";
import { CompanySearchResult } from "../../fixture/shared/models/common/company-search-result.model";
import { SuggestionService } from "../../shared/components/autosuggest/suggestion.service";

@Injectable({
    providedIn: "root"
})
export class CompanyAutosuggestService implements SuggestionService<CompanySearchResult> {
    private companyServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.companyServiceUrl = appConfigService.config.apiGatewayUrl;
    }

    getSuggestions(term: string): Observable<CompanySearchResult[]> {
        return this.httpClient.get<CompanySearchResult[]>(`${this.companyServiceUrl}/api/v1.0/company/search?searchTerm=${term}&skip=0&take=40`);
    }
}
