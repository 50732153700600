import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfigService } from "src/app/core";
import { SharingGroupsViewModel } from "src/app/left-bar/shared/models/sharing-group.model";
import { SharingGroups } from "src/app/left-bar/shared/models/sharing-groups.dto";

@Injectable({
    providedIn: "root"
})
export class SharingGroupsHttpService {
    private apiGatewayUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.apiGatewayUrl = appConfigService.config.apiGatewayUrl;
    }

    get(companyId: number): Observable<SharingGroups[]> {
        return this.httpClient.get<SharingGroups[]>(`${this.apiGatewayUrl}/api/v1.0/sharinggroups?accountId=${companyId}`);
    }

    getForFixture(fixtureId: string): Observable<string[]> {
        return this.httpClient.get<string[]>(`${this.apiGatewayUrl}/api/v1.0/sharinggroups/fixture/${fixtureId}`);
    }

    updateForFixture(fixtureId: string, companyId: string, sharingGroups: SharingGroupsViewModel[]): Observable<any> {
        return this.httpClient.put(`${this.apiGatewayUrl}/api/v1.0/sharinggroups/fixture/${fixtureId}/company/${companyId}`, sharingGroups);
    }
}
