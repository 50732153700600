import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { CargoFilter } from "../../../state";

@Component({
    selector: "ops-cargo-filter",
    templateUrl: "./cargo-filter.component.html",
    styleUrls: ["./filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CargoFilterComponent {
    @Input() cargo: CargoFilter;
}
