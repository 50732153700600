import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isNotFound } from "@ops/shared";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AppConfigService } from "src/app/core";
import { TemperatureLog } from "./temperature-log.dto";

@Injectable({ providedIn: "root" })
export class TemperatureLogHttpService {
    private readonly voyageServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.voyageServiceUrl = appConfigService.config.voyageServiceUrl;
    }

    get(fixtureId: string, voyageId: string): Observable<TemperatureLog | null> {
        return this.httpClient.get<TemperatureLog>(`${this.voyageServiceUrl}/api/v1.0/fixture/${fixtureId}/voyages/${voyageId}/temperaturelog`).pipe(
            catchError((err) => isNotFound(err) ? of(null) : throwError(err))
        );
    }

    update(fixtureId: string, voyageId: string, temperatureLog: TemperatureLog): Observable<TemperatureLog> {
        return this.httpClient.put<TemperatureLog>(`${this.voyageServiceUrl}/api/v1.0/fixture/${fixtureId}/voyages/${voyageId}/temperaturelog`, temperatureLog).pipe(
            map(_ => temperatureLog)
        );
    }
}
