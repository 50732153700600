<div class="ops-dialog">
    <h5>Reasons for change - {{title}}</h5>
    <div class="row no-gutters form-group px-0">
        <textarea class="form-control" [(ngModel)]="reason" (change)="reason=reason?.trim()" type="text" rows="10"
                  required maxlength="3000" data-test-reason>
        </textarea>
    </div>
    <div class="row no-gutters form-group px-0 align-items-baseline">
        <label for="responsibleForChange">Responsible for change</label>
        <ng-select id="responsibleForChange" class="ops-select" [(ngModel)]="responsible" [items]="responsibleForChange"
            [clearable]="false" [selectOnTab]="true" dropdownPosition="top" data-test-responsible-for-change>
        </ng-select>
    </div>
    <div class="row no-gutters justify-content-end">
        <button class="btn btn-link" type="button" (click)="dialogRef.close()" data-test-cancel>
            <span>CANCEL</span>
        </button>
        <button class="btn btn-primary mr-0" type="button" [disabled]="!reason?.trim().length" (click)="submit()"
            data-test-submit>
            <span>SUBMIT</span>
        </button>
    </div>
</div>
