import { createAction, on, On } from "@ngrx/store";
import { FixturesState } from "../model";
import { currentVoyageStateExpansionReducer } from "../voyage/reducer";

const PUBLIC_NOTES_EXPANDED_KEY = "notes";

/* ACTIONS */
export const expandPublicNotesAction = createAction("[Fixture Form] Expand Public Notes");
export const collapsePublicNotesAction = createAction("[Fixture Form] Collapse Public Notes");

/* REDUCERS */
export const expandPublicNotesReducer: On<FixturesState> = on(expandPublicNotesAction, (state) =>
    currentVoyageStateExpansionReducer(state, PUBLIC_NOTES_EXPANDED_KEY, true)
);
export const collapsePublicNotesReducer: On<FixturesState> = on(collapsePublicNotesAction, (state) =>
    currentVoyageStateExpansionReducer(state, PUBLIC_NOTES_EXPANDED_KEY, false)
);
