import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { AppConfigService } from "@ops/core";

import {
    LaytimeCalculationDurationUnit,
    LaytimeCalculationSummary,
    LtcFeatureState,
    LtcId,
    selectCurrentCalculationId,
    selectCurrentLaytimeCalculationDurationUnit,
    selectCurrentLaytimeCalculationSummary
} from "../../state";

@Component({
    selector: "ops-laytime-calculation-summary-shell",
    templateUrl: "./laytime-calculation-summary-shell.component.html",
    styleUrls: ["./laytime-calculation-summary-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationSummaryShellComponent {
    readonly ltcId$: Observable<LtcId>;
    readonly summary$: Observable<LaytimeCalculationSummary>;
    readonly durationUnit$: Observable<LaytimeCalculationDurationUnit>;

    constructor(private store: Store<LtcFeatureState>, appConfigService: AppConfigService) {
        this.ltcId$ = this.store.select(selectCurrentCalculationId);
        this.summary$ = this.store.select(selectCurrentLaytimeCalculationSummary, { cdnUrl: appConfigService.config.clarksonsCdnUrl });
        this.durationUnit$ = this.store.select(selectCurrentLaytimeCalculationDurationUnit);
    }
}
