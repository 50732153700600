import { createAction, on, On, props } from "@ngrx/store";

import { FixturesState } from "../../../state";
import { currentFixtureStateReducer } from "../../../state/fixture/reducer";

/* ACTIONS */
const ACTIVATE_ACTION_NAME = "[Warnings] Activate Warning";

export const activateWarningAction = createAction(ACTIVATE_ACTION_NAME, props<{ warningHash: string }>());

/* REDUCERS */
export const activateWarningReducer: On<FixturesState> = on(activateWarningAction, (state, { warningHash }) =>
    currentFixtureStateReducer(state, (fixtureState) => ({
        ...fixtureState,
        dismissedWarnings: fixtureState.dismissedWarnings?.filter((item) => item.warningHash !== warningHash)
    }))
);
