import { On } from "@ngrx/store";
import { FocusAction, formGroupReducer, SetValueAction } from "ngrx-forms";

import { FixturesState, VoyageState } from "../../model";
import { voyageStateReducer } from "../../voyage/reducer";

/**
 * Prefills laytime event date from the last focused one
 */
export const prefillLaytimeEventDateReducer: On<FixturesState> = {
    types: [FocusAction.TYPE],
    reducer: (state: FixturesState, action: FocusAction): FixturesState => {
        /* eslint-disable @typescript-eslint/no-magic-numbers */
        const controlPath = action.controlId.split(".");
        if (controlPath.length < 2 || controlPath[controlPath.length - 1] !== "eventDate" || controlPath[controlPath.length - 3] !== "laytimeEvents") {
            return state;
        }

        return voyageStateReducer(state, controlPath[0], (voyageState) => {
            const destinationForm = voyageState.form.value.destinations[Number(controlPath[2])];
            const berthForm = destinationForm.berths[Number(controlPath[4])];
            const activityForm = berthForm.activities[Number(controlPath[6])];
            const laytimeEventForms = activityForm.laytimeEvents;

            let laytimeEventIndex = Number(controlPath[8]);

            if (laytimeEventForms[laytimeEventIndex].eventDate) {
                return voyageState;
            }

            let eventDate: string = null;

            while (!eventDate && --laytimeEventIndex >= 0) {
                eventDate = laytimeEventForms[laytimeEventIndex].eventDate;
            }

            if (!eventDate) {
                return voyageState;
            }

            return <VoyageState>{
                ...voyageState,
                form: formGroupReducer(voyageState.form, new SetValueAction(action.controlId, eventDate))
            };
        });
    }
};
