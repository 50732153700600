<div class="side-panel" data-test-create-fixture-panel>
    <span class="side-panel-title">
        Create New Fixture
        <div class="left-toolbar-expander" (click)="collapseLeftBar()">
            <div class="has-icon icon--close"></div>
        </div>
    </span>
    <form [formGroup]="fixtureCreationForm" autocomplete="off" data-test-create-fixture>
        <div class="row no-gutters">
            <div class="form-group col" [ngClass]="{ required: true }">
                <label for="fixtureSector">Fixture Sector</label>
                <ng-select
                    class="ops-select"
                    [items]="fixtureSectors$ | async"
                    [searchable]="false"
                    formControlName="fixtureSector"
                    [clearable]="false"
                    bindLabel="name"
                    [compareWith]="referenceDataService.compare"
                    placeholder="Select"
                    data-test-fixture-sector
                    [selectOnTab]="true"
                >
                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                        <div [ngClass]="item.name.toLowerCase()">
                            {{ item.name }}
                        </div>
                    </ng-template>
                </ng-select>
                <ops-validation [form]="fixtureCreationForm.get('fixtureSector')" [showBeforeTouch]="false"></ops-validation>
            </div>

            <div class="form-group col">
                <label for="fixtureType">Fixture Type</label>
                <ng-select
                    class="ops-select"
                    [items]="fixtureTypes"
                    [searchable]="false"
                    formControlName="fixtureType"
                    [clearable]="false"
                    bindLabel="name"
                    (change)="enableCoaReferenceNumber()"
                    [compareWith]="referenceDataService.compare"
                    data-test-fixture-sector
                    [selectOnTab]="true"
                >
                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                        <div [ngClass]="item.name.toLowerCase()">
                            {{ item.name }}
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="form-group col" [ngClass]="{ required: isCoANumberRequired }">
                <label for="coANumber">CoA Reference</label>
                <input id="coANumber" class="form-control" type="text" formControlName="coANumber" maxlength="250" data-test-coa-reference />
            </div>
        </div>

        <div class="row vessel-header no-gutters">
            <div class="form-group vessel-header col required">
                <label>Vessel Name</label>
                <ops-vessel-autosuggest [multiple]="false" [selection]="fixture.vessel" formControlName="vessel"
                    (blur)="removeIsVesselTbn()" data-test-vessel-search>
                </ops-vessel-autosuggest>
                <ops-validation [form]="fixtureCreationForm.get('vessel')" [showBeforeTouch]="false"
                    [upsertTextFns]="vessselNameValidationFns">
                </ops-validation>
                <div class="col vessel">
                    <div class="d-flex checkbox-container vessel-tbn">
                        <input
                            id="isVesselTbn"
                            type="checkbox"
                            name="isVesselTbn"
                            formControlName="isVesselTbn"
                            (change)="removeVessel()"
                            data-test-vessel-tbn
                        />
                        <label for="isVesselTbn">TBN</label>
                    </div>

                    <div class="vessel-request">
                        <a [href]="email.mailTo" class="btn btn-link" data-test-request-vessel>
                            <span>Request New Vessel</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="form-group col required">
                <label for="charterPartyDate">CP Date</label>
                <ops-date-input formControlName="charterPartyDate" timeZone="utc" container="body" data-test-cp-date></ops-date-input>
                <ops-validation [form]="fixtureCreationForm.get('charterPartyDate')" [showBeforeTouch]="false"></ops-validation>
            </div>
            <div class="form-group col"></div>
        </div>

        <div class="row no-gutters">
            <div class="form-group col required">
                <label for="laycanFrom">Laycan From</label>
                <ops-date-input
                    formControlName="laycanFrom"
                    [enableTime]="true"
                    timeZone="utc"
                    container="body"
                    data-test-laycan-from-date
                ></ops-date-input>
                <ops-validation [form]="fixtureCreationForm.get('laycanFrom')" [showBeforeTouch]="false"></ops-validation>
            </div>
            <div class="form-group col required">
                <label for="laycanTo">Laycan To</label>
                <ops-date-input formControlName="laycanTo" [enableTime]="true" timeZone="utc" container="body" data-test-laycan-to-date></ops-date-input>
                <ops-validation [form]="fixtureCreationForm.get('laycanTo')" [showBeforeTouch]="false"></ops-validation>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="form-group col required">
                <label for="company">Your company (acting on the behalf of)</label>
                <ops-external-company-autosuggest
                    [multiple]="false"
                    [selection]="fixture.company"
                    formControlName="company"
                    (selectionChange)="onSelectionChange($event)"
                    data-test-company
                >
                </ops-external-company-autosuggest>
                <ops-validation [form]="fixtureCreationForm.get('company')" [showBeforeTouch]="false"></ops-validation>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="form-group col required">
                <label for="companyRole">Your company's role for this fixture</label>
                <ng-select
                    class="ops-select"
                    [items]="companyRoles"
                    formControlName="companyRole"
                    [clearable]="true"
                    bindLabel="name"
                    placeholder="Select Company Role"
                    data-test-company-role
                    [selectOnTab]="true"
                >
                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                        <div [ngClass]="item.name.toLowerCase()">
                            {{ item.name }}
                        </div>
                    </ng-template>
                </ng-select>
                <ops-validation [form]="fixtureCreationForm.get('companyRole')" [showBeforeTouch]="false"></ops-validation>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="form-group col required">
                <label>Your company's sharing group(s)</label>
                <div formArrayName="sharingGroups" class="sharing-groups">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="small-size"></th>
                                <th scope="col">Sharing Group Name</th>
                                <th scope="col" class="small-size"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="noSharingGroups">
                                <th></th>
                                <td class="no-sharing-groups">No sharing groups available</td>
                                <td></td>
                            </tr>
                            <tr *ngFor="let sharingGroupForm of sharingGroupsFormArray.controls; let i = index" [formGroupName]="i">
                                <th
                                    scope="row"
                                    [class.child-sharing-group-row]="!sharingGroupForm.get('isGroupAll').value"
                                    ngbTooltip="You cannot remove the last sharing group that you belong to"
                                    placement="top-left"
                                    container="body"
                                    [disableTooltip]="!sharingGroupsFormArray.controls[i].disabled"
                                >
                                    <input
                                        formControlName="isSelected"
                                        type="checkbox"
                                        (change)="sharingGroupSelected($event, sharingGroupForm.get('isGroupAll').value)"
                                        data-test-selected-sharing-group
                                    />
                                </th>
                                <td [ngClass]="sharingGroupForm.get('isGroupAll').value ? 'p-0' : 'sharing-group-name'">
                                    {{ sharingGroupForm.get("name").value }}
                                </td>
                                <td class="person-icon">
                                    <span *ngIf="sharingGroupForm.get('isCurrentUserPresent').value" class="has-icon icon--person"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ops-validation [form]="sharingGroupsFormArray" [showBeforeTouch]="false"
                        [upsertTextFns]="sharingGroupsValidationFns">
                    </ops-validation>
                </div>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="form-group col">
                <div class="info-panel">
                    <div class="info-inner-container d-flex flex-column">
                        <div class="d-flex flex-wrap justify-content-between">
                            <div class="d-flex">
                                <div class="info-panel-icon">
                                    <span class="has-icon icon--info-outline"></span>
                                </div>
                                <div class="info-title">
                                    <span class="info-panel-title"> Click Create to share the fixture with more companies and their sharing groups</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="row no-gutters buttons">
        <div class="col d-flex justify-content-start p-0">
            <button type="button" (click)="cancel()" [disabled]="animatingCreateButton" class="btn btn-link m-0" data-test-cancel>CANCEL</button>
        </div>
        <div class="col d-flex justify-content-end p-0">
            <button type="button" (click)="createFixture()" [disabled]="animatingCreateButton" class="btn btn-primary m-0" data-test-save>
                <span *ngIf="!animatingCreateButton">CREATE</span>
                <ops-load-animation *ngIf="animatingCreateButton" size="small"></ops-load-animation>
            </button>
        </div>
    </div>
</div>
