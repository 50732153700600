import { createAction, On, on } from "@ngrx/store";
import { addArrayControl, box, updateGroup } from "ngrx-forms";

import { deepCopy } from "@ops/shared";
import { CargoBerthActivityType, FreightRateUnit, FreightType } from "@ops/shared/reference-data";
import { opsAddArrayControl } from "@ops/state";

import { Division, FixtureType } from "../../../shared/models";
import {
    activityExpandedKey,
    berthExpandedKey,
    cargoExpandedKey,
    cargoForm,
    CargoId,
    createCargoId,
    createDestinationId,
    defaultCargoExpanded,
    destinationExpandedKey,
    DestinationForm,
    FixturesState,
    getDivision,
    getFixtureType,
    isDivision,
    isFixtureFreightType,
    VoyageForm
} from "../../model";
import { getNextId } from "../../utils";
import { currentVoyageStateReducer } from "../../voyage/reducer";
import { getNewDestination } from "./specialised/specialised-cargo-functions";

/* ACTIONS */
const ADD_CARGO_NAME = "[Voyage Form] Add Cargo";

export const addCargoAction = createAction(ADD_CARGO_NAME);

/* REDUCERS */
export const addCargoReducer: On<FixturesState> = on(addCargoAction, (state) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        const cargoes = voyageState.form.value.cargoes;
        const fixtureType = getFixtureType(fixtureState);
        const division = getDivision(fixtureState);
        let destinations = voyageState.form.controls.destinations;

        let baseFreightRate: number = null;
        if (division === Division.gas && cargoes.length > 0) {
            baseFreightRate = cargoes[0].baseFreightRate;
        }

        const newCargoId = createCargoId();
        const newCargo = {
            ...cargoForm(fixtureType, division, newCargoId, getNextId(cargoes, "legacyCargoId")),
            baseFreightRate,
            baseFreightRateUnit: isFixtureFreightType(fixtureState, FreightType.LumpSum) ? box(deepCopy(FreightRateUnit.LumpSum)) : null
        };
        let updatedExpandedSections = {
            ...voyageState.expandedSections,
            [cargoExpandedKey(newCargoId)]: defaultCargoExpanded(getDivision(fixtureState))
        };

        if (isDivision(fixtureState, Division.specialisedProducts)) {
            const { load, discharge } = getNewSpecialisedCargoDestinations(newCargoId, voyageState.form.value.destinations, fixtureType);
            destinations = opsAddArrayControl(load, { markAsTransient: true })(destinations);
            destinations = opsAddArrayControl(discharge, { markAsTransient: true })(destinations);

            updatedExpandedSections = {
                ...updatedExpandedSections,
                [destinationExpandedKey(load.id)]: false,
                [berthExpandedKey(load.id, load.berths[0].id)]: true,
                [activityExpandedKey(load.id, load.berths[0].id, load.berths[0].activities[0].activityId)]: true,
                [destinationExpandedKey(discharge.id)]: false,
                [berthExpandedKey(discharge.id, discharge.berths[0].id)]: true,
                [activityExpandedKey(discharge.id, discharge.berths[0].id, discharge.berths[0].activities[0].activityId)]: true
            };
        }

        return {
            ...voyageState,
            form: updateGroup<VoyageForm>({
                cargoes: addArrayControl(newCargo),
                destinations: () => destinations
            })(voyageState.form),
            expandedSections: updatedExpandedSections
        };
    })
);

/* FUNCTIONS */
const getNewSpecialisedCargoDestinations = (cargoId: CargoId, currentDestinations: Readonly<DestinationForm[]>, fixtureType: FixtureType) => {
    const loadDestinationId = getNextId(currentDestinations, "destinationId");
    const dischargeDestinationId = loadDestinationId + 1;

    const loadDestination = getNewDestination(createDestinationId(), loadDestinationId, cargoId, CargoBerthActivityType.Load, fixtureType);
    const dischargeDestination = getNewDestination(createDestinationId(), dischargeDestinationId, cargoId, CargoBerthActivityType.Discharge, fixtureType);

    return {
        load: loadDestination,
        discharge: dischargeDestination
    };
};
