<div class="save-button-container" data-test-grid-save-button>
    <button
        [disabled]="disable"
        type="button"
        class="btn btn-default save-button"
        [ngbPopover]="saveMenuContent"
        #saveMenu="ngbPopover"
        (click)="showDropdown ? saveMenu.open() : saveAsNew.emit()"
        placement="bottom"
        triggers="manual"
    >
        <span>SAVE</span>
        <span *ngIf="showDropdown" class="has-icon icon--arrow-drop-down"></span>
    </button>
    <ng-template #saveMenuContent>
        <div class="save-menu list-group" (mouseleave)="saveMenu.close()">
            <div class="save-menu-item list-group-item list-group-item-action" [class.disabled]="disable" (click)="save.emit()">
                <span>Save</span>
            </div>
            <div class="save-menu-item list-group-item list-group-item-action" (click)="saveAsNew.emit()">
                <span>Save as new</span>
            </div>
        </div>
    </ng-template>
</div>
