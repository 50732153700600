import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { NgrxFormControlId } from "ngrx-forms";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, map, tap, withLatestFrom } from "rxjs/operators";

import { toDateTime, update } from "@ops/shared";
import { sortArrayControls } from "@ops/state";

import { compareValues } from "../../../../../../fixture/state/utils";
import { LaytimeCalculationHttpService } from "../../../../../services";
import { ActivityLocationId, idEq, LaytimeEventForm, LaytimeEventId, LtcFeatureState, LtcId, LtcState } from "../../../../model";
import { calculationStateReducer, currentCalculationStateReducer } from "../../../reducer";
import { selectCurrentCalculationId } from "../../../selectors";
import { UpdateQueue } from "../../../update-queue";
import { selectCurrentActivityLocationId } from "../../selectors";
import { LaytimeEventState } from "../selectors";

/* ACTIONS */
const ORDER_ACTION_NAME = "[LTC Laytime Events Form] Order Laytime Events";
export const orderLaytimeEventFormsAction = createAction(`${ORDER_ACTION_NAME} Form`, props<{ laytimeEvents: ReadonlyArray<LaytimeEventState> }>());
export const orderLaytimeEventsAction = createAction(ORDER_ACTION_NAME, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId }>());
export const orderLaytimeEventsSuccessAction = createAction(`${ORDER_ACTION_NAME} Success`, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId }>());
export const orderLaytimeEventsFailAction = createAction(`${ORDER_ACTION_NAME} Fail`, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; error: Error }>());
export const orderLaytimeEventsUpdatePercentageAction = createAction(`${ORDER_ACTION_NAME} Processed Percentage Update`, props<{ controlId: NgrxFormControlId }>());

/* REDUCERS */
export const orderLaytimeEventFormsReducer: On<LtcState> = on(orderLaytimeEventFormsAction, (state) => {
    const updateFns: Evolver = {
        laytimeEventForms: sortArrayControls<LaytimeEventForm>((a, b) => laytimeEventsSort(a.value, b.value))
    };
    return currentCalculationStateReducer(state, R.evolve(updateFns));
});

export const orderLaytimeEventsSuccessReducer: On<LtcState> = on(orderLaytimeEventsSuccessAction, (state, { activityLocationId, ltcId }) => {
    const updateFns: Evolver = {
        calculation: { activityLocations: update(idEq(activityLocationId), R.evolve({ laytimeEvents: R.sort(laytimeEventsSort) })) }
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

/* EFFECTS */
export const orderLaytimeEventFormsEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(orderLaytimeEventFormsAction),
            withLatestFrom(store.select(selectCurrentCalculationId), store.select(selectCurrentActivityLocationId)),
            tap(([{ laytimeEvents }]) => {
                laytimeEvents.forEach((laytimeEvent) => store.dispatch(orderLaytimeEventsUpdatePercentageAction({ controlId: laytimeEvent.form.id })));
            }),
            map(([, ltcId, activityLocationId]) => orderLaytimeEventsAction({ ltcId, activityLocationId })),
            tap((action) => updateQueue.enqueue(action, orderLaytimeEvents, "replace"))
        )
    );

export const orderLaytimeEvents = ({ ltcId, activityLocationId }: ReturnType<typeof orderLaytimeEventsAction>, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    laytimeCalculationHttpService.orderLaytimeEvents(ltcId, activityLocationId).pipe(
        map(() => orderLaytimeEventsSuccessAction({ ltcId, activityLocationId })),
        catchError((error) => of(orderLaytimeEventsFailAction({ ltcId, activityLocationId, error })))
    );

export const laytimeEventsSort = (a: { id: LaytimeEventId; date?: string }, b: { id: LaytimeEventId; date?: string }) => compareValues(toDateTime(a.date), toDateTime(b.date));
