<div *ngIf="warningState" class="ops-tile warning-tile">
    <div class="ops-tile-row">
        <div>
            <span class="ops-badge warning-category--{{category}} {{isDismissed ? 'warning-dismissed' : ''}}"
                data-cy="warning-category">{{category}}</span>
            <span *ngIf="isDismissed" class="warning-dismissed-label">DISMISSED</span>
        </div>
        <div class="buttons-container">
            <span *ngIf="isDismissed && isEditMode" class="activate-button has-icon icon--done" ngbTooltip="Activate"
                placement="left"
                (click)="onActivate()"></span>
            <span *ngIf="canDismiss && isEditMode" class="dismiss-button has-icon icon--cancel" ngbTooltip="Dismiss"
                placement="left"
                (click)="onDismiss()"></span>
        </div>
    </div>

    <p class="ops-tile-title" data-cy="warning-title">{{message}}</p>

    <div class="ops-tile-footer">
        <p class="ops-tile-body" data-cy="warning-body">{{path}}</p>

        <button class="ops-tile-btn" type="button" (click)="onSetCurrentWarning($event)">
            <span class="has-icon icon--arrow-forward"></span>
            <span>GO TO</span>
        </button>
    </div>
</div>
