import { addLiftingReducer, addLiftingSuccessAndFailReducer } from "./add-lifting";
import { editCoaHeaderReducer } from "./edit-header";
import {
    routerLoadCoaFailReducer,
    routerLoadCoaReducer,
    routerLoadCoaSuccessReducer,
    routerLoadEarliestPlannedLiftingFailReducer,
    routerLoadEarliestPlannedLiftingSuccessReducer,
    routerLoadCoaLiftingsFailReducer,
    routerLoadCoaLiftingsSuccessReducer,
    routerLoadLatestCompletedVoyageFailReducer,
    routerLoadLatestCompletedVoyageSuccessReducer
} from "./routing";

export default [
    routerLoadCoaReducer,
    routerLoadCoaSuccessReducer,
    routerLoadCoaFailReducer,
    editCoaHeaderReducer,
    addLiftingReducer,
    addLiftingSuccessAndFailReducer,
    routerLoadLatestCompletedVoyageSuccessReducer,
    routerLoadLatestCompletedVoyageFailReducer,
    routerLoadEarliestPlannedLiftingSuccessReducer,
    routerLoadEarliestPlannedLiftingFailReducer,
    routerLoadCoaLiftingsSuccessReducer,
    routerLoadCoaLiftingsFailReducer
];
