import { createAction, on, On, props } from "@ngrx/store";
import {
    CARGOES_EXPANDED_KEY,
    cargoExpandedKey,
    CargoId,
    FixturesState
} from "../../model";
import { currentVoyageStateExpansionReducer } from "../../voyage/reducer";

/* ACTIONS */
export const expandCargoesAction = createAction("[Voyage Form] Expand Cargoes");
export const collapseCargoesAction = createAction("[Voyage Form] Collapse Cargoes");

export const expandCargoAction = createAction("[Voyage Form] Expand Cargo", props<{ cargoId: CargoId }>());
export const collapseCargoAction = createAction("[Voyage Form] Collapse Cargo", props<{ cargoId: CargoId }>());

/* REDUCERS */
export const expandCargoesReducer: On<FixturesState> = on(expandCargoesAction, (state) =>
    currentVoyageStateExpansionReducer(state, CARGOES_EXPANDED_KEY, true)
);
export const collapseCargoesReducer: On<FixturesState> = on(collapseCargoesAction, (state) =>
    currentVoyageStateExpansionReducer(state, CARGOES_EXPANDED_KEY, false)
);

export const expandCargoReducer: On<FixturesState> = on(expandCargoAction, (state, action) =>
    currentVoyageStateExpansionReducer(state, cargoExpandedKey(action.cargoId), true)
);
export const collapseCargoReducer: On<FixturesState> = on(collapseCargoAction, (state, action) =>
    currentVoyageStateExpansionReducer(state, cargoExpandedKey(action.cargoId), false)
);

