import { createSelector } from "@ngrx/store";

import { isSpecialisedSector } from "@ops/shared";

import { CoaFeatureState, Lifting, LiftingCargoListItem, liftingCargoToListItem } from "../../model";
import { selectCurrentLifting, selectCurrentLiftingState } from "../selectors";

export const selectCurrentLiftingCargoListItems = createSelector<CoaFeatureState, { cdnUrl: string }, Lifting, ReadonlyArray<LiftingCargoListItem>>(
    selectCurrentLifting,
    (lifting, { cdnUrl }) => lifting?.cargoes.map(liftingCargoToListItem(cdnUrl))
);

export const selectLiftingCargoLaycanForm = createSelector(selectCurrentLiftingState, (state) => state?.liftingCargoLaycanForm);

export const selectLiftingCargoLaycanFormSaving = createSelector(selectCurrentLiftingState, (state) => !state || state.liftingCargoLaycanFormSaveStatus === "persisting");

export const selectLiftingCargoLaycanFormSaveFailed = createSelector(selectCurrentLiftingState, (state) => !state || state.liftingCargoLaycanFormSaveStatus === "failed");

export const selectLiftingCargoForm = createSelector(selectCurrentLiftingState, (state) => state?.liftingCargoForm);

export const selectLiftingCargoFormSaving = createSelector(selectCurrentLiftingState, (state) => !state || state.liftingCargoFormSaveStatus === "persisting");

export const selectLiftingCargoFormSaveFailed = createSelector(selectCurrentLiftingState, (state) => !state || state.liftingCargoFormSaveStatus === "failed");

export const selectErroredLiftingCargoes = createSelector(selectCurrentLiftingState, (state) =>
    state.erroredItems
        ?.filter((e) => e.itemType === "cargoes")
        .map((e) => ({
            id: e.id,
            error: e.error
        }))
);

export const selectInvalidLiftingCargoes = createSelector(selectLiftingCargoLaycanForm, (state) => state?.controls.cargoPlanStatus.errors.cargoes);

export const selectAllowAdditionalLocations = createSelector(selectCurrentLiftingState, (state) => state?.lifting && !isSpecialisedSector(state.lifting));

export const selectLiftingCargoTotals = createSelector(selectCurrentLifting, (state) => ({
    nominatedQuantityTotal: state?.cargoes.reduce((acc, cargo) => (acc += +cargo.quantity), 0),
    nominatedQuantityUnit: state?.cargoes.every((cargo) => cargo.quantityUnit === state.cargoes[0]?.quantityUnit) ? state.cargoes[0]?.quantityUnit : null
}));
