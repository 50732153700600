import { Injectable } from "@angular/core";
import { RedeliveryFormModel } from "src/app/fixture/shared/models/form-models/redelivery.model";
import { dateToISOString } from "src/app/shared/date-utils/date-utilities";
import { CommandHandler } from "../../../../mediator/command-handlers/command-handler";
import { Fixture } from "../../../../shared/models";
import { UpdateRedeliveryCommand } from "./update-redelivery.command";

@Injectable({
    providedIn: "root"
})
export class UpdateRedeliveryCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateRedeliveryCommand) {
        const value = <RedeliveryFormModel>command.payload;

        fixture.redelivery.redeliveryLocations = value.redeliveryLocations;
        fixture.redelivery.redeliveryNotices = value.redeliveryNotices || [];
        fixture.redelivery.comments = value.comments || "";
        fixture.redelivery.actualRedeliveryDate = dateToISOString(value.actualRedeliveryDate);
        fixture.redelivery.estimatedRedeliveryDate = dateToISOString(value.estimatedRedeliveryDate);
    }
}
