import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { filter, tap } from "rxjs/operators";

import { AuthService } from "@ops/core";
import { deepCopy, deepFreeze } from "@ops/shared";

import { dateToISOString, parseISODate } from "../../shared/date-utils/date-utilities";
import { ExpenseClaim } from "../shared/models";
import { FixtureExpenses } from "../shared/models/dtos/fixture-expenses.dto";
import { ExpenseClaimFormModel } from "../shared/models/form-models/expense.model";
import { ExpenseHttpService } from "./expense-http.service";
import { ExpenseSortingUtility } from "./expense-sorting-utility";
import { WebStorageService, WebStorageServiceFactory } from "./webstorage.service";

@Injectable({
    providedIn: "root"
})
export class ExpenseDataService {
    private _workingExpenses: FixtureExpenses;
    private _webStorageService: WebStorageService<FixtureExpenses>;
    private _invalidExpenses: ExpenseClaim[];

    private readonly _currentExpenses$ = new BehaviorSubject<FixtureExpenses>(undefined);

    get currentExpenses$(): Observable<FixtureExpenses> {
        return this._currentExpenses$.pipe(filter((x) => !!x));
    }

    get invalidExpenses(): ExpenseClaim[] {
        return this._invalidExpenses;
    }

    constructor(private expenseHttpService: ExpenseHttpService, private authService: AuthService, webStorageServiceFactory: WebStorageServiceFactory) {
        this._webStorageService = webStorageServiceFactory.createStorage<FixtureExpenses>("unsaved-expenses-0.1.1");
    }

    updateExpenses(expenseModels: ExpenseClaimFormModel[]): Observable<string[]> {
        const newExpenseIds$: Observable<string>[] = [of("")];
        const untouchedStoredInvalidExpenses: ExpenseClaim[] = [];

        this.handleDeletedExpenses(expenseModels);

        expenseModels.forEach((expenseModel) => {
            const expense = this.mapExpense(expenseModel);
            const idIsTemporary = expense.expenseClaimId.startsWith("temp");
            const isStoredInvalidExpense = this.invalidExpenses?.some((invalidExpense) => invalidExpense.expenseClaimId === expenseModel.expenseClaimId);
            const modelIsDirtyAndValid = expenseModel.valid && expenseModel.dirty;

            if (!expenseModel.valid && !expenseModel.dirty && isStoredInvalidExpense) {
                untouchedStoredInvalidExpenses.push(expense);
            }

            if (modelIsDirtyAndValid) {
                if (idIsTemporary) {
                    const newExpenseId$ = this.expenseHttpService.create(this._workingExpenses.fixtureId, expense).pipe(tap((newId) => (expense.expenseClaimId = newId)));
                    newExpenseIds$.push(newExpenseId$);
                } else {
                    this.expenseHttpService.update(this._workingExpenses.fixtureId, expense).subscribe();
                }
            }
        });

        // storeInvalidExpenses is a part of some legacy requirements
        // now we should not add new invalid expenses to storeInvalidExpenses,
        // but we want to keep each old invalid expense until it was changed by user
        this.storeInvalidExpenses(untouchedStoredInvalidExpenses);
        return forkJoin(newExpenseIds$).pipe(tap(() => this.publishExpenses(this._workingExpenses)));
    }

    load(fixtureId: string) {
        const fromServer$ = this.expenseHttpService.get(fixtureId);
        const fromStorage$ = this._webStorageService.get(fixtureId);
        const fetchBoth$ = forkJoin([fromServer$, fromStorage$]);

        if (this._currentExpenses$.value && this._currentExpenses$.value.fixtureId !== fixtureId) {
            this.clearExpenses();
        }

        fetchBoth$.subscribe(([fromServer, fromStorage]) => {
            let expenseArray: ExpenseClaim[] = [];

            if (fromStorage) {
                expenseArray = fromStorage.expenses;
                this._invalidExpenses = [...fromStorage.expenses];
            }

            fromServer.forEach((serverExpense) => {
                const expense = expenseArray.find((localExpense) => localExpense.expenseClaimId === serverExpense.expenseClaimId);

                if (!expense) {
                    expenseArray.push(serverExpense);
                } else if (parseISODate(expense.lastUpdatedDate).getTime() < parseISODate(serverExpense.lastUpdatedDate).getTime()) {
                    const index = expenseArray.indexOf(expense);
                    expenseArray[index] = serverExpense;
                }
            });

            const orderedExpenses = ExpenseSortingUtility.sortExpenses(expenseArray);

            const fixtureExpenses = {
                fixtureId,
                expenses: orderedExpenses
            };

            this.publishExpenses(fixtureExpenses);
        });
    }

    private handleDeletedExpenses(expenseModels: ExpenseClaimFormModel[]) {
        const currentIds = this._workingExpenses.expenses.map((x) => x.expenseClaimId);
        const newIds = expenseModels.map((x) => x.expenseClaimId);
        const deleted = currentIds.filter((x) => !newIds.includes(x));

        deleted.forEach((id) => {
            const indexToRemove = this._workingExpenses.expenses.findIndex((x) => x.expenseClaimId === id);
            this._workingExpenses.expenses.splice(indexToRemove, 1);
            if (!id.startsWith("temp")) {
                this.expenseHttpService.delete(this._workingExpenses.fixtureId, id).subscribe();
            }
        });
    }

    private storeInvalidExpenses(invalidExpenses: ExpenseClaim[]) {
        if (invalidExpenses.length > 0) {
            const expenses = {
                fixtureId: this._workingExpenses.fixtureId,
                expenses: invalidExpenses
            };

            this._webStorageService.set(expenses.fixtureId, expenses);
        } else {
            this._webStorageService.remove(this._workingExpenses.fixtureId);
        }
    }

    private publishExpenses(fixtureExpenses: FixtureExpenses) {
        this._workingExpenses = fixtureExpenses;
        const immutableExpenses = deepFreeze(deepCopy(fixtureExpenses)) as FixtureExpenses;
        this._currentExpenses$.next(immutableExpenses);
    }

    private clearExpenses() {
        this._currentExpenses$.next(undefined);
    }

    private mapExpense(expenseModel: ExpenseClaimFormModel): ExpenseClaim {
        let expense = this._workingExpenses.expenses.find((x) => x.expenseClaimId === expenseModel.expenseClaimId);

        if (!expense) {
            expense = <ExpenseClaim>{};
            expenseModel.lastUpdatedByUser = this.authService.user;
            expenseModel.lastUpdatedDate = new Date();
            this._workingExpenses.expenses.push(expense);
        }

        if (expenseModel.dirty) {
            expenseModel.lastUpdatedByUser = this.authService.user;
            expenseModel.lastUpdatedDate = new Date();
        }

        expense.expenseClaimId = expenseModel.expenseClaimId;
        expense.type = expenseModel.type;
        expense.ownerInvoiceNumber = expenseModel.ownerInvoiceNumber;
        expense.invoiceDate = dateToISOString(expenseModel.invoiceDate);
        expense.finalClaimValue = expenseModel.finalClaimValue;
        expense.currency = expenseModel.currency;
        expense.receivedFromOwnerDate = dateToISOString(expenseModel.receivedFromOwnerDate);
        expense.sentToChartererDate = dateToISOString(expenseModel.sentToChartererDate);
        expense.paidDate = dateToISOString(expenseModel.paidDate);
        expense.sentToAccountsDate = dateToISOString(expenseModel.sentToAccountsDate);
        expense.chartererAcknowledgedReceiptDate = dateToISOString(expenseModel.chartererAcknowledgedReceiptDate);
        expense.agreedDate = dateToISOString(expenseModel.agreedDate);
        expense.commissionable = expenseModel.commissionable;
        expense.commissionDate = dateToISOString(expenseModel.commissionDate);
        expense.comments = expenseModel.comments;
        expense.claimTypeDescription = expenseModel.claimTypeDescription;
        expense.initialClaimValue = expenseModel.initialClaimValue;
        expense.grossCommissionableAmount = expenseModel.grossCommissionableAmount;
        expense.lastContacted = dateToISOString(expenseModel.lastContacted);
        expense.awaitingHardCopy = expenseModel.awaitingHardCopy;
        expense.awaitingDocuments = expenseModel.awaitingDocuments;
        expense.complete = expenseModel.complete;
        expense.lastUpdatedByUser = expenseModel.lastUpdatedByUser;
        expense.lastUpdatedDate = dateToISOString(expenseModel.lastUpdatedDate);
        expense.claimHandledBy = expenseModel.claimHandledBy;

        return expense;
    }
}
