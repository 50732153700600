import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Output } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { ExternalCompanySuggestionService } from "./external-company-autosuggest.service";
import { CompanySearchResult } from "../../../fixture/shared/models/common/company-search-result.model";
import { AutosuggestComponent } from "../../../shared/components/autosuggest/autosuggest.component";

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ExternalCompanyAutosuggestComponent),
    multi: true
};

@Component({
    selector: "ops-external-company-autosuggest",
    templateUrl: "./external-company-autosuggest.component.html",
    styleUrls: ["../../../shared/components/autosuggest/autosuggest.component.scss", "./external-company-autosuggest.component.scss"],
    providers: [VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalCompanyAutosuggestComponent extends AutosuggestComponent<CompanySearchResult> {
    static componentName = "ExternalCompanyAutosuggestComponent";

    @Output() selectionChange = new EventEmitter<any[]>();

    constructor(protected externalCompanySuggestionService: ExternalCompanySuggestionService, protected element: ElementRef, protected changeDetector: ChangeDetectorRef) {
        super(externalCompanySuggestionService, element, changeDetector);
    }

    onSelectionChange(selection: any): void {
        super.onSelectionChange(selection);
        this.selectionChange.emit(selection);
    }
}
