<div class="activity-location-cargo-container">
    <div class="ops-ltc-card">
        <div class="ops-ltc-card-header">
            <span class="ops-ltc-card-header-title">Cargoes</span>
        </div>
        <p-table
            data-cy="grid"
            [value]="cargoes"
            [formGridReadonly]="false"
            dataKey="id"
            [rowTrackBy]="trackBy"
            [tableStyleClass]="gridClasses"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th class="text-right">{{activityTypeDisplayName}}Quantity</th>
                    <th *ngIf="!isFixed" class="text-right">Allowed Laytime</th>
                    <th *ngIf="!isFixed" class="text-right">Actual Laytime</th>
                    <th *ngIf="!isFixed">Laytime Type</th>
                    <th *ngIf="!isFixed" class="text-right">Extra Hours</th>
                    <th *ngIf="!isFixed" class="text-right">Allowed Time</th>
                    <th *ngIf="!isFixed"></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr data-cy="cargo-row" class="p-grid"
                    formGridRow
                    [attr.rowkey]="rowData.id">
                    <td data-cy="cargo" pEditableColumn [formGridCell]="rowData.form.controls.cargoProduct"
                        [formGridCellReadonly]="!!rowData.form.value.cargoId">
                        <ops-cellEditor>
                            <ng-template pTemplate="input">
                                <ops-cargo-autosuggest
                                    formGridInput
                                    [ngrxFormControlState]="rowData.form.controls.cargoProduct"
                                    [ngrxEnableFocusTracking]="true"
                                    [opsValidationPopover]="cargoValidation"
                                    [sector]="sector"
                                    [readonly]="!!rowData.form.value.cargoId"
                                ></ops-cargo-autosuggest>
                                <ng-template #cargoValidation>
                                    <ops-field-validation
                                        *ngIf="rowData.form.controls.cargoProduct.errors.required"
                                        fieldDisplayName="Cargo"
                                        errorMessage="is required">
                                    </ops-field-validation>
                                </ng-template>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="ops-fg-cell-value">{{rowData.form.value.cargoProduct?.value?.name}}</span>
                            </ng-template>
                        </ops-cellEditor>
                    </td>
                    <td data-cy="quantity" pEditableColumn [formGridCell]="rowData.form.controls.quantity">
                        <ops-cellEditor>
                            <ng-template pTemplate="input">
                                <input formGridInput type="text" class="p-inputtext text-right"
                                       [opsNumber]="{ precision: 3, minimumFractionDigits: 2, showTrailingZeros: false, type: 'string' }"
                                       [ngrxFormControlState]="rowData.form.controls.quantity" />
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="ops-fg-cell-value text-right" *ngIf="rowData.form.value.quantity">
                                    <span class="value" [innerHtml]="rowData.form.value.quantity | opsNumberStyledDecimal: false:2:3"></span>
                                    MT
                                </span>
                            </ng-template>
                        </ops-cellEditor>
                    </td>
                    <td data-cy="allowed-rate" pEditableColumn [formGridCell]="rowData.form.controls.allowance" *ngIf="!isFixed">
                        <ops-cellEditor>
                            <ng-template pTemplate="input">
                                <ops-quantity-input
                                    formGridInput
                                    placeholder="Select"
                                    [dropdownValues]="allowanceUnits"
                                    [inputNumberFormat]="{ precision: 3, minimumFractionDigits: 2, showTrailingZeros: false, type: 'string' }"
                                    [allowClear]="true"
                                    [ngrxEnableFocusTracking]="true"
                                    [ngrxFormControlState]="rowData.form.controls.allowance"
                                    [opsValidationPopover]="allowanceValidation"
                                    data-cy="cp-quatity"
                                ></ops-quantity-input>
                                <ng-template #allowanceValidation>
                                    <ops-field-validation
                                        *ngIf="rowData.form.controls.allowance.errors.valueWithoutUnit"
                                        fieldDisplayName="Unit"
                                        errorMessage="is required">
                                    </ops-field-validation>
                                </ng-template>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="ops-fg-cell-value text-right" *ngIf="hasValue(rowData.form.value.allowance?.value?.value) || rowData.form.value.allowance?.value?.unit">
                                    <span class="value" [innerHtml]="rowData.form.value.allowance.value.value | opsNumberStyledDecimal: false:2:3"></span>
                                    {{rowData.form.value.allowance.value.unit}}
                                </span>
                            </ng-template>
                        </ops-cellEditor>
                    </td>
                    <td data-cy="actualRateUnit" class="ops-fg-cell-readonly" *ngIf="!isFixed">
                        <span class="ops-fg-cell-value text-right" *ngIf="hasValue(rowData.actual) && rowData.form.value.allowance?.value?.unit">
                            <span class="value" [innerHtml]="rowData.actual | opsNumberStyledDecimal: false:2:3"></span>
                            {{rowData.form.value.allowance.value.unit}}
                        </span>
                    </td>
                    <td data-cy="reversible" pEditableColumn [formGridCell]="rowData.form.controls.reversible" *ngIf="!isFixed">
                        <ops-cellEditor>
                            <ng-template pTemplate="input">
                                <ng-select formGridInput class="ops-select" [ngrxFormControlState]="rowData.form.controls.reversible" [items]="reversibleLaytimeTypes"
                                           [clearable]="false" [selectOnTab]="true" placeholder="Select">
                                </ng-select>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="ops-fg-cell-value">{{rowData.form.value.reversible}}</span>
                            </ng-template>
                        </ops-cellEditor>
                    </td>
                    <td data-cy="extra-hours" pEditableColumn [formGridCell]="rowData.form.controls.extraHours" *ngIf="!isFixed">
                        <ops-cellEditor>
                            <ng-template pTemplate="input">
                                <input formGridInput type="text" class="p-inputtext text-right"
                                       [opsNumber]="{ precision: 3, minimumFractionDigits: 0, showTrailingZeros: false, type:'string' }"
                                       [ngrxFormControlState]="rowData.form.controls.extraHours"/>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="ops-fg-cell-value text-right" *ngIf="rowData.form.value.extraHours">
                                    <span class="value" [innerHtml]="rowData.form.value.extraHours | opsNumberStyledDecimal: false:0:3"></span>
                                    {{ getHoursText(rowData.form.value.extraHours) }}
                                </span>
                            </ng-template>
                        </ops-cellEditor>
                    </td>
                    <td data-cy="allowed-time" class="ops-fg-cell-readonly" *ngIf="!isFixed">
                        <span class="ops-fg-cell-value text-right">{{ rowData.laytimeAllowed | durationFormat:durationUnit }}</span>
                    </td>
                    <td data-cy="import" pEditableColumn pFocusCellSelector="button" class="ops-fg-action-cell" *ngIf="!isFixed">
                        <ops-icon-button type="feed" tooltip="Import cargo terms" [disabled]="!rowData.canImportRateTerms" (click)="importTerms.emit(rowData.id)" (keyup.enter)="importTerms.emit(rowData.id)" data-cy="import-cargo-terms"></ops-icon-button>
                    </td>
                    <td data-cy="delete" pEditableColumn pFocusCellSelector="button" class="ops-fg-action-cell">
                        <ops-icon-button type="delete" (click)="remove.emit(rowData.id)" (keyup.enter)="remove.emit(rowData.id)"></ops-icon-button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <a data-cy="add-cargo" class="ops-fg-add-row" (click)="add.emit()" (keydown.enter)="add.emit()" (keydown.space)="add.emit()" tabindex="0">Add Cargo</a>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr class="no-cargoes">
                    <td colspan="8" class="no-cargoes-text ops-fg-readonly ops-fg-no-content-cell">
                        <div class="add-cargo-button-container">
                            <div class="left">
                                <span class="has-icon icon--lightbulb-outline"></span>
                                <span>No cargoes are currently added</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
