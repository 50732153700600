import { createAction, On, on, props } from "@ngrx/store";
import { updateGroup } from "ngrx-forms";

import { opsAddArrayControl } from "@ops/state";

import { updateDestinationForm } from "../../destinations/shared";
import { activityExpandedKey, berthExpandedKey, berthForm, createBerthId, DestinationId, FixturesState, getDivision } from "../../model";
import { getNextId } from "../../utils";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const addBerthAction = createAction("[Voyage Form] Add Berth", props<{ destinationId: DestinationId }>());

/* REDUCERS */
export const addBerthReducer: On<FixturesState> = on(addBerthAction, (state, action) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        const destination = voyageState.form.controls.destinations.controls.find((d) => d.value.id === action.destinationId);
        if (!destination) {
            return voyageState;
        }
        const division = getDivision(fixtureState);
        const newBerthId = createBerthId();
        const newBerth = berthForm(division, newBerthId, getNextId(destination.value.berths, "berthId"));
        const newActivityId = newBerth.activities[0].activityId;

        return {
            ...voyageState,
            form: updateDestinationForm(action, (destinationForm) =>
                updateGroup(destinationForm, {
                    berths: opsAddArrayControl(newBerth, { markAsTransient: true, scrollIntoView: true })
                })
            )(voyageState.form),
            expandedSections: {
                ...voyageState.expandedSections,
                [berthExpandedKey(action.destinationId, newBerthId)]: true,
                [activityExpandedKey(action.destinationId, newBerthId, newActivityId)]: true
            }
        };
    })
);
