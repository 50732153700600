import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    selector: "ops-load-animation",
    templateUrl: "./load-animation.component.html",
    styleUrls: ["./load-animation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadAnimationComponent {
    /**
     * The size of the animation to render.
     */
    @Input() size: "x-small" | "small" | "medium" | "large" | "x-large";
    /**
     * The foreground color of the animation stripes. Defaults to `"light"`.
     */
    @Input() stripeColor: "dark" | "light";

    get classList() {
        return [
            this.size ?? "large",
            `${this.stripeColor ?? "light"}-stripe`
        ];
    }
}
