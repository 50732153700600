<div class="sharing-groups-container row">
    <form [formGroup]="sharingGroupsForm" [class.isloading]="isLoading" autocomplete="off" data-test-company-sharing-groups>
        <div *ngIf="!isLoading" formArrayName="sharingGroups" class="sharing-groups">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" class="small-size"></th>
                        <th scope="col">Sharing Groups</th>
                        <th scope="col" class="small-size"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="noSharingGroups()">
                        <th></th>
                        <td class="no-sharing-groups">No sharing groups available</td>
                        <td></td>
                    </tr>
                    <tr *ngFor="let sharingGroupForm of sharingGroupsFormArray.controls; let i = index" [formGroupName]="i">
                        <th
                            scope="row"
                            [class.child-sharing-group-row]="!sharingGroupForm.get('isGroupAll').value"
                            ngbTooltip="You cannot remove the last sharing group that you belong to"
                            placement="top-left"
                            container="body"
                            [disableTooltip]="!sharingGroupsFormArray.controls[i].disabled"
                        >
                            <input
                                formControlName="isSelected"
                                type="checkbox"
                                (change)="sharingGroupSelected($event, sharingGroupForm.get('isGroupAll').value)"
                                data-test-selected-sharing-group
                            />
                        </th>
                        <td [ngClass]="sharingGroupForm.get('isGroupAll').value ? 'p-0' : 'sharing-group-name'">
                            {{ sharingGroupForm.get("name").value }}
                        </td>
                        <td class="person-icon">
                            <span *ngIf="sharingGroupForm.get('isCurrentUserPresent').value" class="has-icon icon--person"></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ops-load-animation *ngIf="isLoading" size="x-small" class="loading-animation"></ops-load-animation>
    </form>

    <div class="validation-message" *ngIf="sharingGroupsControlWarnings.invalid || sharingGroupsControlWarnings.warnings">
        <ops-validation [form]="sharingGroupsFormArray" [showBeforeTouch]="false" [upsertTextFns]="sharingGroupsValidationFns">
        </ops-validation>
        <div *ngIf="!sharingGroupsControlWarnings.warnings?.lastSharingGroupForUser" class="warning">
            <span class="has-icon icon--lightbulb-outline"></span>
            <span>One sharing group must be selected for the current user</span>
        </div>
        <div *ngIf="!sharingGroupsControlWarnings.warnings?.newCompanyInstructions" class="warning">
            <span class="has-icon icon--lightbulb-outline"></span>
            <span>To share this fixture with this Company, select one or more sharing groups</span>
        </div>
    </div>
</div>
