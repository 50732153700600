<div class="card embedded-card-1">
    <div class="card-header row no-gutters align-items-center">
        {{ title }}
    </div>
    <div [formGroup]="parentForm" class="engine-consumption">
        <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let column of (columnDefs$ | async)" [class]="column.class" scope="col">{{ column.headerName }}</th>
                </tr>
            </thead>
            <tbody [formArrayName]="formName">
                <tr *ngFor="let row of formArray.controls; let i = index; trackBy: trackByFn" [formGroupName]="i" class="form-group">
                    <th
                        *ngIf="row.value.seaStatus.name === bunkerSeaStatus.Full.name"
                        [ngClass]="{ 'is-laden': row.value.cargoStatus.name === atSeaCargoStatus.Laden.name }"
                        class="cargo-status-column-fixed"
                        rowspan="2"
                        scope="row"
                    >
                        <label formControlName="cargoStatus" data-test-total></label>
                    </th>
                    <th class="sea-status-column-fixed" scope="row">
                        <label formControlName="seaStatus" data-test-total></label>
                    </th>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="mainEngine"
                            [opsNumber]="{ precision: 3, minValue: 0 }"
                            data-test-main-engine
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            class="form-control text-right"
                            formControlName="auxiliaryEngine"
                            [opsNumber]="{ precision: 3, minValue: 0 }"
                            data-test-auxiliary-engine
                        />
                    </td>
                    <td class="total-column-fixed" scope="row">
                        <input
                            type="text"
                            readonly
                            class="form-control text-right"
                            formControlName="total"
                            [opsNumber]="{ precision: 3, minValue: 0 }"
                            data-test-total
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
