import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";

import { hasItems } from "@ops/shared";
import { ReferenceDataService } from "@ops/shared/reference-data";

import { MixpanelService } from "../../../../../core/mixpanel-service";
import { ReferenceDataDropdownComponent } from "../../../../../shared/components/reference-data-dropdown/reference-data-dropdown.component";
import { DateUtilities } from "../../../../../shared/date-utils/date-utilities";
import { Command } from "../../../../mediator";
import { LaytimeCalculationService } from "../../../../services/laytime-calculation.service";
import { mapToLaytimeEventFactModel } from "../../../../shared/mappers/laytime-event-fact-mapper";
import { Division, Fixture, LaytimeEvent, LaytimeEventFact } from "../../../../shared/models";
import { LaytimeEventFactModel } from "../../../../shared/models/form-models";
import { LaytimeSummaryModel } from "../../../../shared/models/form-models/laytime-summary-aggregate.model";
import { ValidatorProviderService } from "../../../../shared/validation/validator-provider.service";
import { AbstractSimpleGridComponent } from "../../../../speed-and-consumption-tab/cp-speed-and-consumption/abstract-simple-grid/abstract-simple-grid.component";
import { ActivityId, BerthId, CargoId, CargoItem, DestinationId, LaytimeEventId } from "../../../../state/model";
import { SyncLaytimeEventsCommand } from "../../../commands/sync-laytime-events-command";
import { AddLaytimeEventFactCommand } from "./commands/add-laytime-event-fact.command";
import { OrderLaytimeEventFactsCommand } from "./commands/order-laytime-events.command";
import { RemoveAllLaytimeEventFactsCommand } from "./commands/remove-all-laytime-event-facts.command";
import { RemoveLaytimeEventFactCommand } from "./commands/remove-laytime-event-fact.command";
import { UpdateLaytimeEventFactsCommand } from "./commands/update-laytime-event-facts.command";

@Component({
    selector: "ops-laytime-event-facts",
    templateUrl: "./laytime-event-facts.component.html",
    styleUrls: ["./laytime-event-facts.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeEventFactsComponent extends AbstractSimpleGridComponent<LaytimeEventFactModel> implements OnChanges {
    private readonly columnDefs = <any>[
        { field: "laytimeEventId" },
        { field: "fromDate" },
        { field: "toDate" },
        { field: "timePassedDays" },
        { field: "timePassedHrs" },
        { field: "timePassedMins" },
        { field: "comments" },
        { field: "percentage" },
        { field: "type" },
        { field: "cargoId" },
        { field: "demurrageReason" }
    ];

    readonly Division = Division;

    demurrageLaytimeEventFactId: LaytimeEventId;
    totalDays: number;
    totalHours: number;
    totalMins: number;
    currentLaytimeSummary: LaytimeSummaryModel;

    @Input() destinationId: DestinationId;
    @Input() berthId: BerthId;
    @Input() berthActivityId: ActivityId;
    @Input() locationTimezone: string;
    @Input() model: LaytimeEventFact[];
    @Input() cargoTabLaytimeEvents: LaytimeEvent[];
    @Input() parentForm: UntypedFormGroup;
    @Input() cargoes: CargoItem[];
    @Input() fixture: Fixture;
    @Output() laytimeEventsUpdated = new EventEmitter();

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        protected formBuilder: UntypedFormBuilder,
        public referenceDataService: ReferenceDataService,
        validatorProviderService: ValidatorProviderService,
        private laytimeCalculationService: LaytimeCalculationService,
        private mixpanelService: MixpanelService
    ) {
        super("laytimeEventId", changeDetectorRef, formBuilder);
        this.columnDefs$.next(this.columnDefs);
        this.validators = validatorProviderService.getComponentValidators("LaytimeEventFactsComponent");
        this.rowChanged$.pipe(takeUntil(this.destroy$)).subscribe((row) => {
            this.laytimeEventsUpdated.emit(new UpdateLaytimeEventFactsCommand(row, this.destinationId, this.berthId, this.berthActivityId, this.fixture, this.locationTimezone));
        });

        this.laytimeCalculationService.currentLaytimeSummary$.subscribe((summary) => {
            this.currentLaytimeSummary = summary;
            this.setDemurrageLaytimeEventFactId();
        });
    }

    setDemurrageLaytimeEventFactId() {
        this.demurrageLaytimeEventFactId = null;
        if (this.currentLaytimeSummary) {
            const destination = this.currentLaytimeSummary.destinations.find((dest) => dest.id === this.destinationId);
            if (destination) {
                const berth = destination.berths.find((br) => br.id === this.berthId && br.demurrageBerthActivityId === this.berthActivityId);
                if (berth) {
                    this.demurrageLaytimeEventFactId = berth.demurrageLaytimeEventFactId;
                }
            }
        }
    }

    get allImported() {
        return this.cargoTabLaytimeEvents === null || !this.cargoTabLaytimeEvents.some((e) => !e.isSynced && e.type && e.eventDate);
    }

    get importButtonTooltip() {
        return this.allImported ? "No additional port times to be imported" : "Import port times";
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes["model"]) {
            return;
        }

        const currentValue = changes["model"].currentValue as LaytimeEventFact[];
        if (currentValue) {
            const data = currentValue.map((item) => mapToLaytimeEventFactModel(item));

            this.rowData$.next(data);
            this.setTotalTimePassed(data);
        }
        this.setDemurrageLaytimeEventFactId();
    }

    getEventDateId(index: number, controlName: string) {
        return `event-${controlName}-${this.destinationId}-${this.berthId}-${this.berthActivityId}-${index}`;
    }

    setDefaultToDate(index: number) {
        const row = this.formArray.controls[index];
        if (!row.get("toDate").value) {
            row.patchValue({ toDate: row.get("fromDate").value });
            this.focusOnEventDateElement(index, "to-date");
        }
    }

    addLaytimeEvent(index: number): void {
        this.mixpanelService.track("Ops: Add Laytime Event (Old LTC)");
        this.laytimeEventsUpdated.emit(new AddLaytimeEventFactCommand(index + 1, this.destinationId, this.berthId, this.berthActivityId));
        this.focusOnEventDateElement(index + 1, "from-date");
    }

    removeLaytimeEvent(index: number): void {
        const command =
            this.model.length === 1
                ? new RemoveAllLaytimeEventFactsCommand(this.destinationId, this.berthId, this.berthActivityId)
                : new RemoveLaytimeEventFactCommand(index, this.destinationId, this.berthId, this.berthActivityId);
        this.laytimeEventsUpdated.emit(command);
    }

    updateLaytimeEvent(command: Command): void {
        this.laytimeEventsUpdated.emit(command);
    }

    orderLaytimeEventFacts(): void {
        this.laytimeEventsUpdated.emit(new OrderLaytimeEventFactsCommand(this.destinationId, this.berthId, this.berthActivityId));
    }

    syncLaytimeEventData() {
        if (!this.parentForm.disabled && !this.allImported) {
            this.mixpanelService.track("Ops: Import Port Times (Old LTC)");
            this.laytimeEventsUpdated.emit(new SyncLaytimeEventsCommand(this.destinationId, this.berthId));
        }
    }

    removeAllLaytimeEventFacts() {
        if (!this.parentForm.disabled) {
            this.laytimeEventsUpdated.emit(new RemoveAllLaytimeEventFactsCommand(this.destinationId, this.berthId, this.berthActivityId));
        }
    }

    getTitle(eventId: LaytimeEventId) {
        if (this.isDemurrageEvent(eventId)) {
            return "Vessel on demurrage";
        } else {
            return "";
        }
    }

    getCargoNameToolTip(cargoId: CargoId) {
        return (cargoId && this.cargoes && this.cargoes.find((x) => x.cargoId === cargoId)?.name) ?? "";
    }

    isDemurrageEvent(eventId: LaytimeEventId) {
        return eventId === this.demurrageLaytimeEventFactId;
    }

    mouseover(laytimeEvent: ReferenceDataDropdownComponent, tooltip: NgbTooltip) {
        if (laytimeEvent.isOpen) {
            tooltip.close();
        }
    }

    private setTotalTimePassed(laytimeEvents: LaytimeEventFactModel[]) {
        if (hasItems(laytimeEvents)) {
            const sum = (accumulator: number, laytimeEvent: LaytimeEventFactModel) => accumulator + (laytimeEvent.timePassedTotalHours || 0);
            const totalHours = +laytimeEvents.reduce(sum, 0).toFixed(2);
            this.totalDays = DateUtilities.getDays(totalHours);
            this.totalHours = DateUtilities.getHours(totalHours);
            this.totalMins = DateUtilities.getMinutes(totalHours);
        }
    }

    private focusOnEventDateElement(index: number, controlName: string) {
        setTimeout(() => {
            const id = this.getEventDateId(index, controlName);
            const element = document.getElementById(id);
            if (element) {
                element.focus();
            }
        }, 0);
    }
}
