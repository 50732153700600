import { ColumnState } from "@ag-grid-community/core/dist/cjs/columns/columnModel";
import { Observable } from "rxjs";

import { SearchToken, Suggestion, SuggestionTerm } from "@ops/state";

import { GridRecordNumbers } from "../grid-record-numbers";

/**
 * This is the new Search Service that will replace IGridSearchService and will be used
 * in conjunction with GridDatasource (NGRX version)
 */
export abstract class ISearchService<T = unknown> {
    readonly criteria$: Observable<ReadonlyArray<SearchToken>>;
    readonly gridRecordNumbers$: Observable<GridRecordNumbers>;
    readonly suggestions$: Observable<ReadonlyArray<Suggestion>>;
    readonly suggestionsLoading$: Observable<boolean>;

    abstract search(skip: number, take: number): Observable<ReadonlyArray<T>>;
    abstract updateSearchTokens(suggestions: ReadonlyArray<Suggestion>): void;
    abstract updateSorting(sortColumn: string, sortOrder: string): void;
    abstract updateSuggestionTerm(suggestionTerm: SuggestionTerm): void;
    abstract updateColumns(columnState: ColumnState[]): void;
}
