import { createAction, On, on } from "@ngrx/store";
import { updateGroup } from "ngrx-forms";

import { opsAddArrayControl } from "@ops/state";

import { activityExpandedKey, berthExpandedKey, createDestinationId, destinationExpandedKey, destinationForm, FixturesState, getDivision, VoyageForm } from "../../model";
import { getNextId } from "../../utils";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const addDestinationAction = createAction("[Voyage Form] Add Destination");

/* REDUCERS */
export const addDestinationReducer: On<FixturesState> = on(addDestinationAction, (state) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        const division = getDivision(fixtureState);
        const newDestinationId = createDestinationId();
        const newDestination = destinationForm(division, newDestinationId, getNextId(voyageState.form.value.destinations, "destinationId"));
        const newBerth = newDestination.berths[0];
        const newBerthId = newBerth.id;
        const newActivityId = newBerth.activities[0].activityId;

        return {
            ...voyageState,
            form: updateGroup<VoyageForm>({
                destinations: opsAddArrayControl(newDestination, { markAsTransient: true, scrollIntoView: true })
            })(voyageState.form),
            expandedSections: {
                ...voyageState.expandedSections,
                [destinationExpandedKey(newDestinationId)]: true,
                [berthExpandedKey(newDestinationId, newBerthId)]: true,
                [activityExpandedKey(newDestinationId, newBerthId, newActivityId)]: true
            }
        };
    })
);
