import { ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject, queueScheduler, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

import { ComponentValidator } from "../../../../shared/validators/component-validators/component-validator";

export interface IColumnDef {
    headerName: string;
    field: string;
    class: string;
}

export interface IRowChange<T> {
    index: number;
    model: T;
}

export interface IChangeSummary<T> {
    added: IRowChange<T>[];
    deleted: IRowChange<T>[];
    rowCount: number;
    changeCount: number;
}

/**
 * @deprecated Use NGRX / PrimeNG table
 */
@Directive() // eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractSimpleGridComponent<TFormModel> implements OnInit, OnChanges, OnDestroy {
    protected rowData$: BehaviorSubject<TFormModel[]> = new BehaviorSubject<TFormModel[]>([]);
    protected rowChanged$: Subject<TFormModel> = new Subject<TFormModel>();

    protected add$: Subject<void> = new Subject<void>();
    protected copy$: Subject<{ index: number; key: any }> = new Subject<{ index: number; key: any }>();
    protected delete$: Subject<{ index: number; key: any }> = new Subject<{ index: number; key: any }>();

    protected destroy$: Subject<boolean> = new Subject<boolean>();
    protected formValueDestroy$: Subject<boolean> = new Subject<boolean>();
    protected validators: ComponentValidator[] = [];

    formArray: UntypedFormArray = this.formBuilder.array([], { updateOn: "blur" });
    formUpdate$: Subject<IChangeSummary<TFormModel>> = new Subject<IChangeSummary<TFormModel>>();
    columnDefs$: BehaviorSubject<IColumnDef[]> = new BehaviorSubject<IColumnDef[]>([]);

    @Input() title: string;
    @Input() formName: string;
    @Input() parentForm: UntypedFormGroup;
    @Output() formChanged = new EventEmitter();

    protected constructor(protected keyField: string, public changeDetectorRef: ChangeDetectorRef, protected formBuilder: UntypedFormBuilder) {}

    ngOnInit() {
        const columnDefs = this.columnDefs$.getValue();
        if (this.parentForm.disabled) {
            this.formArray.disable();
        } else {
            this.formArray.enable();
        }
        this.parentForm.registerControl(this.formName, this.formArray);
        this.updateFormArray(columnDefs, []);
        this.rowData$.pipe(takeUntil(this.destroy$)).subscribe((data) => this.updateFormArray(columnDefs, data || []));
    }

    ngOnDestroy() {
        this.formValueDestroy$.next(true);
        delete this.parentForm.controls[this.formName];
        this.destroy$.next(true);
    }

    get invalid() {
        if (this.formArray.invalid) {
            return true;
        }

        for (const control of this.formArray.controls) {
            if (control.invalid) {
                return true;
            }
        }

        return false;
    }

    get disabled() {
        if (this.formArray.disabled) {
            return true;
        }

        for (const control of this.formArray.controls) {
            if (control.disabled) {
                return true;
            }
        }

        return false;
    }

    get enabled() {
        if (this.formArray.enabled) {
            return true;
        }

        for (const control of this.formArray.controls) {
            if (control.enabled) {
                return true;
            }
        }

        return false;
    }

    get touched() {
        if (this.formArray.touched) {
            return true;
        }

        for (const control of this.formArray.controls) {
            if (control.touched) {
                return true;
            }
        }

        return false;
    }

    add(): void {
        this.add$.next();
    }

    copy(index: number, row: UntypedFormGroup): void {
        const key = row.controls[this.keyField].value;
        this.copy$.next({ index: index, key: key });
    }

    delete(index: number, row: UntypedFormGroup): void {
        const key = row.controls[this.keyField].value;
        this.delete$.next({ index: index, key: key });
    }

    private updateFormArray(columnDefs: IColumnDef[], rowData: TFormModel[]) {
        const keyField = this.keyField;
        const disabled = this.parentForm.disabled;

        this.formValueDestroy$.next(true);

        const currentIds: number[] = rowData.map((d) => (d as any)[keyField]);
        const previousIds: number[] = this.formArray.controls.map((x) => x.value[keyField]);
        const addedIds = currentIds.filter((currentId) => previousIds.indexOf(currentId) === -1);
        const removedIds = previousIds.filter((previousId) => currentIds.indexOf(previousId) === -1);

        const added: IRowChange<TFormModel>[] = [];
        const deleted: IRowChange<TFormModel>[] = [];

        // https://github.com/angular/angular/issues/23336
        this.formArray.setParent(null);

        for (const id of addedIds) {
            const rowModel = rowData.find((x) => (x as any)[keyField] === id);
            const rowGroup = this.createRow(keyField, columnDefs, rowModel, disabled);
            this.formArray.push(rowGroup);
            added.push({ index: this.formArray.length, model: rowModel });
        }

        for (const id of removedIds) {
            const index = this.formArray.controls.findIndex((f) => f.value[keyField] === id);
            const model = this.formArray.controls[index].value as TFormModel;
            if (index !== -1) {
                this.formArray.removeAt(index);
                deleted.push({ index: index, model: model });
            }
        }

        this.formArray.setParent(this.parentForm);
        this.formArray.patchValue(rowData, { emitEvent: false });

        // Hack - manually re-register
        for (const rowGroup of this.formArray.controls) {
            rowGroup.valueChanges.pipe(takeUntil(this.formValueDestroy$), debounceTime(1, queueScheduler)).subscribe((data) => this.rowChanged$.next(data));
        }

        this.formUpdate$.next({
            added: added,
            deleted: deleted,
            rowCount: this.formArray.length,
            changeCount: added.length + deleted.length
        });
    }

    private createRow(keyField: string, columnDefs: IColumnDef[], rowModel: TFormModel, disabled: boolean): UntypedFormGroup {
        const controls: { [index: string]: UntypedFormControl } = {};

        const key = (rowModel as any)[keyField];
        controls[keyField] = this.formBuilder.control(key, { updateOn: "blur" });

        for (const columnDef of columnDefs) {
            const field = columnDef.field;
            const value = (rowModel as any)[field];
            controls[field] = this.formBuilder.control(value, { updateOn: "blur" });
        }

        const rowGroup = this.formBuilder.group(controls, { updateOn: "blur" });

        if (disabled) {
            rowGroup.disable({ emitEvent: false });
        }

        this.validators.forEach((validator) => {
            validator.validate({ form: rowGroup });
        });

        return rowGroup;
    }

    abstract ngOnChanges(changes: SimpleChanges): void;
}
