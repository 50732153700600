import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";

import { DATA_ACCESS_ERROR_MESSAGE, DATA_VALIDATION_ERROR_MESSAGE, GENERIC_ERROR_MESSAGE, isBadRequest, isForbidden, NotificationService } from "@ops/shared";

import { routerLoadCoaFailAction } from "./coa/routing";
import { cloneLiftingFailAction } from "./lifting/clone-lifting";
import { routerLoadLiftingFailAction } from "./lifting/routing";

const errorToMessage = (error: Error) => (isBadRequest(error) ? DATA_VALIDATION_ERROR_MESSAGE : isForbidden(error) ? DATA_ACCESS_ERROR_MESSAGE : GENERIC_ERROR_MESSAGE);

@Injectable()
export class CoaNotificationEffects {
    coaLoadFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(routerLoadCoaFailAction),
                tap(({ error }) => this.notificationService.error("Failed to load contract.", errorToMessage(error), true))
            ),
        { dispatch: false }
    );

    liftingLoadFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(routerLoadLiftingFailAction),
                tap(({ error }) => this.notificationService.error("Failed to load lifting.", errorToMessage(error), true))
            ),
        { dispatch: false }
    );

    cloneLiftingFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(cloneLiftingFailAction),
                tap(({ error }) => this.notificationService.error("Failed to clone lifting.", errorToMessage(error), true))
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private notificationService: NotificationService) {}
}
