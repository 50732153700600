import { Injectable } from "@angular/core";
import { DateTime } from "luxon";

import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { AssociatedCargo, Division, Fixture, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class DataBLDateDischargeDateInTheFutureRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    readonly requiredLocationActivityTypes = [CargoBerthActivityType.Load.id, CargoBerthActivityType.Discharge.id];

    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();
        if (
            fixture.division.id === Division.specialisedProducts ||
            fixture.division.id === Division.dryCargo ||
            fixture.division.id === Division.gas ||
            fixture.division.id === Division.pcg
        ) {
            voyages.forEach((voyage) =>
                voyage.destinations.forEach((destination, destinationIndex) =>
                    destination.berths.forEach((berth, berthIndex) =>
                        berth.cargoBerthActivities.forEach((activity, activityIndex) => {
                            if (
                                !activity.type ||
                                !this.requiredLocationActivityTypes.includes(activity.type?.id) ||
                                !activity.laytimeEvents ||
                                !activity.associatedCargoes?.length
                            ) {
                                return;
                            }
                            activity.associatedCargoes.forEach((associatedCargo, associatedCargoIndex) => {
                                const destinationTimezone = destination.location && destination.location.timeZone ? destination.location.timeZone : "utc";
                                const cargoDate = associatedCargo.date && DateTime.fromISO(associatedCargo.date, { zone: destinationTimezone });
                                const now = DateTime.local();
                                if (+cargoDate > +now) {
                                    const dateFieldName = activity.type.id === CargoBerthActivityType.Load.id ? "B/L Date" : "Discharge Date";
                                    const message = `${dateFieldName} is in the future`;
                                    const path = [
                                        ...this.getAssociatedCargoPath(
                                            fixture,
                                            voyage,
                                            destinationIndex,
                                            destination,
                                            berthIndex,
                                            berth,
                                            activityIndex,
                                            activity,
                                            associatedCargoIndex,
                                            associatedCargo
                                        ),
                                        FixtureWarningPathSegment.fromProperty<AssociatedCargo>("date", null)
                                    ];
                                    const warningHash = new WarningHashBuilder()
                                        .withVoyage(voyage.voyageId)
                                        .withDestination(destination.destinationId)
                                        .withBerth(berth.berthId)
                                        .withBerthActivity(activity.berthActivityId)
                                        .withAssociatedCargo(associatedCargo.associatedCargoId)
                                        .withCategory("data")
                                        .withMessage(message)
                                        .build();
                                    const warning = new FixtureWarning("data", message, path, dismissedWarningRecord(warningHash));
                                    warnings.push(warning);
                                }
                            });
                        })
                    )
                )
            );
        }

        return warnings;
    }
}
