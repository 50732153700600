<div *ngIf="laytimeCalculation$ | async as laytimeCalculation; else loading" class="ops-ltc-shell-container">
    <ops-fixture-header class="p-px-4" [fixtureHeader]="fixtureHeader$ | async"></ops-fixture-header>
    <div class="ops-ltc-card">
        <router-outlet></router-outlet>
    </div>
</div>

<ng-template #loading>
    <div class="ops-ltc-loading-panel">
        <ops-load-animation size="x-large" class="light-background"></ops-load-animation>
    </div>
</ng-template>
