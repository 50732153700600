import { createAction, on, On, props } from "@ngrx/store";
import { removeArrayControl, updateGroup } from "ngrx-forms";

import { opsAddArrayControl } from "@ops/state";

import { ActivityId, associatedCargoExpenseForm, AssociatedCargoId, BerthId, DestinationId, FixturesState, templateExpenseId } from "../../model";
import { currentVoyageStateReducer } from "../../voyage/reducer";
import { updateAssociatedCargoForm } from "./shared";

/* ACTIONS */
export const removeAssociatedCargoExpenseAction = createAction(
    "[Voyage Form] Remove Associated Cargo Expense",
    props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId; associatedCargoId: AssociatedCargoId; index: number }>()
);

/* REDUCER */
export const removeAssociatedCargoExpenseReducer: On<FixturesState> = on(removeAssociatedCargoExpenseAction, (state, action) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const voyageForm = updateAssociatedCargoForm(action, (associatedCargoForm) => {
            if (action.index === undefined || action.index === null || isNaN(action.index)) {
                throw new Error("Given associated cargo expense index is invalid");
            }

            const expenses = associatedCargoForm.value.associatedCargoExpenses;

            if (action.index < 0 || expenses.length === 0) {
                return associatedCargoForm;
            }

            const updatedForm = updateGroup(associatedCargoForm, {
                associatedCargoExpenses: removeArrayControl(action.index)
            });

            if (action.index === 0 && expenses.length === 1) {
                const templateExpense = associatedCargoExpenseForm(templateExpenseId, 1);

                return updateGroup(updatedForm, {
                    associatedCargoExpenses: opsAddArrayControl(templateExpense)
                });
            }
            return updatedForm;
        })(voyageState.form);

        if (voyageForm === voyageState.form) {
            return voyageState;
        }

        return {
            ...voyageState,
            form: voyageForm
        };
    })
);
