import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";

import { VoyageHttpService } from "../../../services";
import { getLaytimeCalculationState, LtcFeatureState, LtcId, LtcState, Voyage } from "../../model";
import { selectCurrentActivityLocationId } from "../activity-location";
import { calculationStateReducer } from "../reducer";
import { selectCurrentLaytimeCalculation, selectCurrentLaytimeCalculationId } from "../selectors";

export const voyageLoadAwaiterTypes = ["AddActivityLocations", "ImportPortTimes"];
export type VoyageLoadAwaiter = typeof voyageLoadAwaiterTypes[number];

/* ACTIONS */
const LOAD_VOYAGE_ACTION_NAME = "[LTC] Load Voyage";
export const loadVoyageForAddActivityLocationsAction = createAction(`${LOAD_VOYAGE_ACTION_NAME} For Add Activity Locations`);
export const loadVoyageForImportPortTimesAction = createAction(`${LOAD_VOYAGE_ACTION_NAME} For Import Port Times`);
export const loadVoyageAction = createAction(LOAD_VOYAGE_ACTION_NAME, props<{ ltcId: LtcId; awaiter: string }>());
export const loadVoyageSuccessAction = createAction(`${LOAD_VOYAGE_ACTION_NAME} Success`, props<{ ltcId: LtcId; voyage: Voyage }>());
export const loadVoyageFailAction = createAction(`${LOAD_VOYAGE_ACTION_NAME} Fail`, props<{ ltcId: LtcId; error: Error }>());

/* REDUCERS */
export const loadVoyageReducer: On<LtcState> = on(loadVoyageAction, (state, { ltcId, awaiter }) =>
    calculationStateReducer(state, ltcId, (ltcState) => ({
        ...ltcState,
        awaitingVoyageLoad: awaiter && !ltcState.awaitingVoyageLoad.includes(awaiter) ? [...ltcState.awaitingVoyageLoad, awaiter] : ltcState.awaitingVoyageLoad,
        voyageLoadStatus: "loading"
    }))
);

export const loadVoyageSuccessReducer: On<LtcState> = on(loadVoyageSuccessAction, (state, { ltcId, voyage }) =>
    calculationStateReducer(state, ltcId, { voyage, voyageLoadStatus: "loaded" })
);

export const loadVoyageFailReducer: On<LtcState> = on(loadVoyageFailAction, (state, { ltcId }) => calculationStateReducer(state, ltcId, { voyageLoadStatus: "failed" }));

/* EFFECTS */
export const loadVoyageEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, voyageHttpService: VoyageHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(loadVoyageForAddActivityLocationsAction, loadVoyageForImportPortTimesAction),
            withLatestFrom(store.select(selectCurrentLaytimeCalculation)),
            switchMap(([, { fixtureId, id }]) =>
                voyageHttpService.getAll(fixtureId).pipe(
                    map((voyages) => loadVoyageSuccessAction({ ltcId: id, voyage: voyages[0] })),
                    catchError((error) => of(loadVoyageFailAction({ ltcId: id, error })))
                )
            )
        )
    );

export const loadVoyageForAddActivityLocationsEffect$ = (actions$: Actions, store: Store<LtcFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType(loadVoyageForAddActivityLocationsAction),
            withLatestFrom(store.select(selectCurrentLaytimeCalculationId)),
            map(([, ltcId]) => loadVoyageAction({ ltcId, awaiter: "AddActivityLocations" }))
        )
    );

export const loadVoyageForImportPortTimesEffect$ = (actions$: Actions, store: Store<LtcFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType(loadVoyageForImportPortTimesAction),
            withLatestFrom(store.select(selectCurrentLaytimeCalculationId), store.select(selectCurrentActivityLocationId)),
            map(([, ltcId, activityLocationId]) => loadVoyageAction({ ltcId, awaiter: `ImportPortTimes::${activityLocationId}` }))
        )
    );

/* FUNCTIONS */
export const isAwaitingVoyageLoad = (state: LtcState, calculationId: LtcId, awaiterCode: string) =>
    getLaytimeCalculationState(state, calculationId).awaitingVoyageLoad.some((a) => a.startsWith(awaiterCode));

export const removeAwaitingVoyageLoadEntry = (state: LtcState, calculationId: LtcId, awaiterCode: string) =>
    calculationStateReducer(state, calculationId, (ltcState) => ({
        ...ltcState,
        awaitingVoyageLoad: ltcState.awaitingVoyageLoad.filter((a) => !a.startsWith(awaiterCode))
    }));
