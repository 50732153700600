import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { evolve, Evolver, F } from "ramda";
import { of } from "rxjs";
import { catchError, exhaustMap, map, withLatestFrom } from "rxjs/operators";

import { selectCurrentUser } from "@ops/state";

import { currentLiftingStateReducer, liftingStateReducer } from "./reducer";
import { selectCurrentLifting } from "./selectors";
import { CancelLifting, LiftingHttpService } from "../../services/lifting-http.service";
import { coaLiftingsStateReducer } from "../coa/reducer";
import { CoaFeatureState, CoaId, CoasState, CoaState, LiftingId, setCancelled, toUser, User } from "../model";

/* ACTIONS */
const ACTION_NAME = "[Lifting Actions] Cancel Lifting";

export const cancelLiftingAction = createAction(ACTION_NAME, props<{ cancellation: CancelLifting }>());
export const cancelLiftingSuccessAction = createAction(`${ACTION_NAME} Success`, props<{ coaId: CoaId; liftingId: LiftingId; user: User }>());
export const cancelLiftingFailAction = createAction(`${ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

/* REDUCERS */
export const cancelLiftingReducer: On<CoasState> = on(cancelLiftingAction, (state) => currentLiftingStateReducer(state, { liftingCancellationPending: true }));

export const cancelLiftingSuccessReducer: On<CoasState> = on(cancelLiftingSuccessAction, (state, { coaId, liftingId }) => {
    const updateFns: Evolver = {
        liftingCancellationPending: F,
        lifting: setCancelled
    };
    const withUpdatedLiftingState = liftingStateReducer(state, liftingId, evolve(updateFns));

    const getLiftings = (coaState: CoaState) =>
        coaState.fetchedLiftings.map((l) => (l.documentId !== liftingId ? l : { ...l, liftingStatus: withUpdatedLiftingState.liftings.byId[liftingId].lifting.liftingStatus }));

    return coaLiftingsStateReducer(withUpdatedLiftingState, coaId, getLiftings);
});

export const cancelLiftingFailReducer: On<CoasState> = on(cancelLiftingFailAction, (state, { liftingId }) =>
    liftingStateReducer(state, liftingId, { liftingCancellationPending: false })
);

/* EFFECTS */
export const cancelLiftingEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(cancelLiftingAction),
            withLatestFrom(store.select(selectCurrentLifting), store.select(selectCurrentUser)),
            exhaustMap(([{ cancellation }, { coaId, liftingId }, appUser]) =>
                liftingHttpService.cancelLifting(coaId, liftingId, cancellation).pipe(
                    map(() => cancelLiftingSuccessAction({ coaId, liftingId, user: toUser(appUser) })),
                    catchError((error) => of(cancelLiftingFailAction({ liftingId, error })))
                )
            )
        )
    );
