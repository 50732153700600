import { On } from "@ngrx/store";
import { setValue, SetValueAction, unbox, updateArrayWithFilter, updateGroup } from "ngrx-forms";

import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { isNullOrUndefined } from "../../../../shared/utils";
import { Division } from "../../../shared/models/enums/division";
import { updateActivityForm } from "../../activities/shared";
import { ActivityForm, AssociatedCargoForm, CargoId, FixturesState, getActivities, getActivitiesWithCargo, getDestinationsWithCargo, isDivision, VoyageState } from "../../model";
import { voyageStateReducer } from "../../voyage/reducer";

/**
 * Prefills the discharge quantity + unit from a load quantity + unit when the load quantity/quantity unit is set or cargo is set on discharge activity.
 */
export const prefillAssociatedCargoDischargeReducer: On<FixturesState> = {
    types: [SetValueAction.TYPE],
    reducer: (state: FixturesState, action: SetValueAction<CargoId>): FixturesState => {
        const controlPath = action.controlId.split(".");
        if (
            controlPath.length < 2 ||
            controlPath[controlPath.length - 3] !== "associatedCargoes" ||
            (controlPath[controlPath.length - 1] !== "quantity" && controlPath[controlPath.length - 1] !== "cargoId")
        ) {
            return state;
        }

        return voyageStateReducer(state, controlPath[0], (voyageState, fixtureState) => {
            if (isDivision(fixtureState, Division.specialisedProducts)) {
                return voyageState;
            }

            const berthIndex = Number(controlPath[4]);
            const activityIndex = Number(controlPath[6]);
            const associatedCargoIndex = Number(controlPath[8]);

            const voyageForm = voyageState.form.value;
            const destinationForm = voyageForm.destinations[Number(controlPath[2])];
            const berthForm = destinationForm.berths[berthIndex];
            const activityForm = berthForm.activities[activityIndex];
            const associatedCargoForm = activityForm.associatedCargoes[associatedCargoIndex];

            if (isNullOrUndefined(action.value)) {
                return voyageState;
            }

            let cargoIdToMatch: CargoId;
            let activityTypeId: number;

            if (controlPath[controlPath.length - 1] === "cargoId") {
                //The cargo on associated cargo form has been updated
                //Set the correct cargo and activity values to ensure that we process only Discharge activity form changes
                //We have to take the action value as the forms reducer runs after this one
                cargoIdToMatch = action.value;
                activityTypeId = CargoBerthActivityType.Discharge.id;
            } else {
                //The Quantity or Quantity Unit of associated cargo form has been updated
                //so set the correct cargo and activity values to ensure that we process only Load activiy form changes.
                cargoIdToMatch = associatedCargoForm.cargoId;
                activityTypeId = CargoBerthActivityType.Load.id;
            }

            const activityType = unbox(activityForm.type);
            //Make sure we are processing the correct activity changes and there is a cargo id to match
            if (!activityType || activityType.id !== activityTypeId || !cargoIdToMatch) {
                return voyageState;
            }

            const loadActivities = getActivitiesWithCargo(voyageForm, cargoIdToMatch, CargoBerthActivityType.Load);

            if (activityType.id === CargoBerthActivityType.Discharge.id) {
                const dischargeActivities = getActivities(voyageForm, CargoBerthActivityType.Discharge);

                if (
                    loadActivities.length !== 1 ||
                    loadActivities[0].associatedCargoes.filter((c) => c.cargoId === cargoIdToMatch).length > 1 ||
                    dischargeActivities.length !== 1 ||
                    dischargeActivities[0].associatedCargoes.filter((c) => c.cargoId === cargoIdToMatch).length > 0
                ) {
                    return voyageState;
                }

                //Set discharge quantity for cargo same as load activity B/L quantity
                const loadAssociatedCargoForm = loadActivities[0].associatedCargoes.find((c) => c.cargoId === cargoIdToMatch);

                return <VoyageState>{
                    ...voyageState,
                    form: updateActivityForm(
                        { destinationId: destinationForm.id, activityId: activityForm.activityId, berthId: berthForm.id },
                        updateGroup<ActivityForm>({
                            associatedCargoes: updateArrayWithFilter(
                                ({ value: { cargoId } }) => cargoId === null,
                                updateGroup<AssociatedCargoForm>({
                                    quantity: setValue(loadAssociatedCargoForm.quantity),
                                    quantityUnit: setValue(loadAssociatedCargoForm.quantityUnit)
                                })
                            )
                        })
                    )(voyageState.form)
                };
            }

            const dischargeActivitiesWithCargo = getActivitiesWithCargo(voyageForm, cargoIdToMatch, CargoBerthActivityType.Discharge);

            // There must only be one load/discharge activity and one associated cargo per cargo to prefill
            if (
                loadActivities.length !== 1 ||
                loadActivities[0].associatedCargoes.filter((c) => c.cargoId === cargoIdToMatch).length > 1 ||
                dischargeActivitiesWithCargo.length !== 1 ||
                dischargeActivitiesWithCargo[0].associatedCargoes.filter((c) => c.cargoId === cargoIdToMatch).length > 1
            ) {
                return voyageState;
            }

            //Process Load activity quantity changes
            const dischargeDestinations = getDestinationsWithCargo(voyageForm, cargoIdToMatch, CargoBerthActivityType.Discharge);
            const dischargeAssociatedCargoForm = dischargeDestinations?.associatedCargo;

            // Don't apply B/L if the quantity/unit has already been set
            if (!dischargeAssociatedCargoForm || dischargeAssociatedCargoForm.quantity) {
                return voyageState;
            }

            // We have to take the action value as the forms reducer runs after this one
            const newQuantity = Number(action.value);
            const newQuantityUnit = unbox(dischargeAssociatedCargoForm.quantityUnit) ? dischargeAssociatedCargoForm.quantityUnit : associatedCargoForm.quantityUnit;

            const updateProps = {
                destinationId: dischargeDestinations.destination.id,
                berthId: dischargeDestinations.berth.id,
                activityId: dischargeDestinations.activity.activityId
            };

            return <VoyageState>{
                ...voyageState,
                form: updateActivityForm(
                    updateProps,
                    updateGroup<ActivityForm>({
                        associatedCargoes: updateArrayWithFilter(
                            ({ value: { associatedCargoId } }) => associatedCargoId === dischargeAssociatedCargoForm.associatedCargoId,
                            updateGroup<AssociatedCargoForm>({
                                quantity: setValue(newQuantity),
                                quantityUnit: setValue(newQuantityUnit)
                            })
                        )
                    })
                )(voyageState.form)
            };
        });
    }
};
