import { createAction, on, On, props } from "@ngrx/store";
import { removeArrayControl, setValue, updateArrayWithFilter, updateGroup } from "ngrx-forms";

import { updateActivityForm } from "../../activities/shared";
import { ActivityId, BerthId, DestinationId, FixturesState, LaytimeEventForm, LaytimeEventId } from "../../model";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const removeLaytimeEventAction = createAction(
    "[Voyage Form] Remove Laytime Event",
    props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId; laytimeEventId: LaytimeEventId }>()
);

/* REDUCER */
export const removeLaytimeEventReducer: On<FixturesState> = on(removeLaytimeEventAction, (state, action) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const voyageForm = updateActivityForm(action, (activityForm) => {
            const laytimeEvents = activityForm.value.laytimeEvents;
            const laytimeEventIndex = laytimeEvents.map((x) => x.laytimeEventId).indexOf(action.laytimeEventId);

            if (laytimeEventIndex < 0) {
                return activityForm;
            }

            const updatedForm = updateGroup(activityForm, {
                laytimeEvents: removeArrayControl(laytimeEventIndex)
            });

            if (laytimeEventIndex === 0 && laytimeEvents.length > 1) {
                const laytimeEvent: LaytimeEventForm = {
                    ...laytimeEvents[1],
                    percentage: null
                };
                return updateGroup(updatedForm, {
                    laytimeEvents: updateArrayWithFilter<LaytimeEventForm>((e) => e.value.laytimeEventId === laytimeEvent.laytimeEventId, setValue(laytimeEvent))
                });
            }

            return updatedForm;
        })(voyageState.form);

        if (voyageForm === voyageState.form) {
            return voyageState;
        }

        return {
            ...voyageState,
            form: voyageForm
        };
    })
);
