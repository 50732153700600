import { cancelNameNewWorksheetReducer, nameNewWorksheetReducer, gridRenameFormValueReducer } from "./form/grid-rename";
import {
    cancelEditSavedWorksheetNameReducer,
    editSavedWorksheetNameReducer,
    renameSavedWorksheetFailReducer,
    renameSavedWorksheetReducer,
    renameSavedWorksheetSuccessReducer
} from "./form/list-rename";
import { deleteWorksheetFailReducer, deleteWorksheetReducer, deleteWorksheetSuccessReducer } from "./functionality/delete-worksheet";
import { getAllWorksheetsFailReducer, getAllWorksheetsReducer, getAllWorksheetsSuccessReducer } from "./functionality/load-worksheets";
import {
    saveNewWorksheetFailReducer,
    saveNewWorksheetReducer,
    saveNewWorksheetSuccessReducer,
    updateWorksheetFailReducer,
    updateWorksheetReducer,
    updateWorksheetSuccessReducer
} from "./functionality/save-worksheets";
import {
    selectDefaultWorksheetReducer,
    selectWorksheetReducer,
    setDefaultWorksheetReducer,
    setInitialGridDisplayReducer,
    updateCurrentWorksheetColumnsReducer,
    updateCurrentWorksheetQueriesReducer,
    updateCurrentWorksheetSortReducer
} from "./functionality/set-worksheets";

export default [
    getAllWorksheetsReducer,
    getAllWorksheetsSuccessReducer,
    getAllWorksheetsFailReducer,
    setDefaultWorksheetReducer,
    setInitialGridDisplayReducer,
    updateCurrentWorksheetColumnsReducer,
    updateCurrentWorksheetSortReducer,
    updateCurrentWorksheetQueriesReducer,
    nameNewWorksheetReducer,
    cancelNameNewWorksheetReducer,
    saveNewWorksheetSuccessReducer,
    selectWorksheetReducer,
    selectDefaultWorksheetReducer,
    updateWorksheetReducer,
    updateWorksheetSuccessReducer,
    updateWorksheetFailReducer,
    deleteWorksheetReducer,
    deleteWorksheetSuccessReducer,
    deleteWorksheetFailReducer,
    editSavedWorksheetNameReducer,
    cancelEditSavedWorksheetNameReducer,
    renameSavedWorksheetReducer,
    renameSavedWorksheetSuccessReducer,
    renameSavedWorksheetFailReducer,
    gridRenameFormValueReducer,
    saveNewWorksheetReducer,
    saveNewWorksheetFailReducer
];
