<div class="card-header pl-0 row no-gutters align-items-center">
    <button
        data-cy="toggle-berth-button"
        type="button"
        class="has-icon btn-inverted"
        [class.icon--arrow-drop-right]="!expanded"
        [class.icon--arrow-drop-down]="expanded"
        (click)="toggle()"
        data-test-toggle-berth-button
    ></button>

    <div class="d-flex w-100 summary-container">
        <div class="col-3 berth-name-summary" data-test-berthnamesummary>{{ summary?.berthName }}</div>
        <div class="col-auto activity" *ngFor="let activity of summary?.activities" data-test-berthactivitiessummary>
            {{ activity.activity }}<ng-container *ngIf="activity.cargoes">: {{ activity.cargoes }}</ng-container>
        </div>
    </div>

    <div *ngIf="canOrder || canClone || canRemove" class="button-container">
        <div *ngIf="canOrder" class="btn-group" role="group">
            <button
                [disabled]="!moveUpEnabled"
                type="button"
                class="has-icon icon--chevron-up btn-inverted"
                (click)="moveUp.emit()"
                data-test-move-berth-up-button
            ></button>
            <button
                [disabled]="!moveDownEnabled"
                type="button"
                class="has-icon icon--chevron-down btn-inverted"
                (click)="moveDown.emit()"
                data-test-move-berth-down-button
            ></button>
        </div>

        <div class="clone-delete-container d-flex" *ngIf="canClone || canRemove">
            <button
                *ngIf="canClone"
                [disabled]="!cloneEnabled"
                (click)="clone.emit()"
                type="button"
                class="has-icon icon--clone btn-inverted"
                data-test-clone-berth-button
            ></button>
            <button
                *ngIf="canRemove"
                [disabled]="!removeEnabled"
                (click)="remove.emit()"
                type="button"
                class="btn-delete has-icon icon--delete ml-auto btn-inverted"
                data-test-delete-berth-button
            ></button>
        </div>
    </div>
</div>
