import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AppConfigService } from "../../core";

@Injectable({ providedIn: "root" })
export class WarningHttpService {
    private readonly warningsServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.warningsServiceUrl = appConfigService.config.warningsServiceUrl;
    }

    getWarningHashes(fixtureId: string): Observable<string[]> {
        return this.httpClient
            .get<{ warningHashes: string[] }>(`${this.warningsServiceUrl}/api/v1/fixtures/${fixtureId}/dismissed-warnings`)
            .pipe(map((response) => response.warningHashes));
    }

    saveWarningHashes(fixtureId: string, warningHashes: string[]): Observable<unknown> {
        return this.httpClient.put(`${this.warningsServiceUrl}/api/v1/fixtures/${fixtureId}/dismissed-warnings`, { warningHashes });
    }
}
