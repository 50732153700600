import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { dateRangeEquals } from "@ops/shared";
import { Observable, of } from "rxjs";
import { VoyageDataService } from "src/app/fixture/services/voyage-data.service";
import { AppConfigService } from "../../../../../core/app-config.service";
import { SuggestionService } from "../../../../../shared/components/autosuggest/suggestion.service";
import { Division, SeaNetLocation } from "../../../models";
import { SuggestedExistingLocation } from "../../../models/common/suggested-existing-location";

// TODO: (NGRX JC) Move LocationSuggestionService to ngrx

@Injectable({
    providedIn: "root"
})
export class LocationSuggestionService implements SuggestionService<SeaNetLocation> {
    private locationServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService, private voyageDataService: VoyageDataService) {
        this.locationServiceUrl = appConfigService.config.locationServiceUrl;
    }

    getSuggestions(term: string, division?: Division): Observable<SeaNetLocation[]> {
        if (!term || !division) {
            return of([]);
        }

        const url = `${this.locationServiceUrl}/api/v1.0/location/${Division[division]}/${term}`;
        return this.httpClient.get<SeaNetLocation[]>(url);
    }

    getExistingLocations(): SuggestedExistingLocation[] {
        if (!this.voyageDataService.currentVoyageSnapshot) {
            return [];
        }

        const existingLocationSuggestions = this.voyageDataService.currentVoyageSnapshot.destinations
            .map((d) => {
                return (
                    d.location &&
                    <SuggestedExistingLocation>{
                        ...d.location,
                        etaRange: d.etaRange ? { from: d.etaRange.from, to: d.etaRange.to } : null
                    }
                );
            })
            .filter((l, i, self) => l && self.findIndex((loc) => loc && loc.locationId === l.locationId && dateRangeEquals(l.etaRange, loc.etaRange)) === i);

        return existingLocationSuggestions;
    }
}
