import { Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Observable, Subject } from "rxjs";

import { AppConfigService, AuthService } from "@ops/core";

import { ActionDto } from "../models/action.dto";

@Injectable({
    providedIn: "root"
})
export class LiveUpdatesHubService {
    private hubConnection: HubConnection;
    private actionsUpdatedSubject = new Subject<ActionDto>();
    private actionsDeletedSubject = new Subject<ActionDto>();

    constructor(private authService: AuthService, private appConfigService: AppConfigService, private hubConnectionBuilder: HubConnectionBuilder) {}

    initialize() {
        this.createConnection();
        this.registerEvents();
        this.startConnection();
    }

    get actionsUpdated$(): Observable<ActionDto> {
        return this.actionsUpdatedSubject.asObservable();
    }

    get actionsDeleted$(): Observable<ActionDto> {
        return this.actionsDeletedSubject.asObservable();
    }

    private createConnection() {
        this.hubConnection = this.hubConnectionBuilder.withUrl(this.appConfigService.config.liveUpdatesHubUrl, { accessTokenFactory: () => this.authService.token }).build();
    }

    private startConnection() {
        this.hubConnection
            .start()
            .then(() => {
                console.log("[LiveUpdatesHub] Connected");
            })
            .catch((err) => {
                // Previous package @aspnet/signalr returned error:
                //     { statusCode: 401, message: "Unauthorized" }
                // Current package @microsoft/signalr (version 8.0.7) returns error:
                //     { message: "Failed to complete negotiation with the server: Error: Unauthorized: Status code '401'" }
                // To cover both cases we check for "Unauthorized" word in the message.
                if (err.message.includes("Unauthorized")) {
                    console.warn("[LiveUpdatesHub] Unauthorized error. No more attempts.", err);
                } else {
                    console.warn("[LiveUpdatesHub] Connection error. Restarting...", err);
                    this.restartWithDelay();
                }
            });
    }

    private registerEvents() {
        this.hubConnection.on("actionUpdated", (action: ActionDto) => {
            this.actionsUpdatedSubject.next(action);
        });

        this.hubConnection.on("actionDeleted", (action: ActionDto) => {
            this.actionsDeletedSubject.next(action);
        });

        this.hubConnection.onclose((err) => {
            console.warn("[LiveUpdatesHub] Disconnected. Restarting...");
            if (err) {
                console.error(err);
            }
            this.restartWithDelay();
        });
    }

    private restartWithDelay() {
        const restartDelay = 5000;
        setTimeout(() => this.startConnection(), restartDelay);
    }
}
