import { createAction, On, on, props } from "@ngrx/store";
import { box, FormState, removeArrayControl, setValue, updateArrayWithFilter, updateGroup } from "ngrx-forms";
import * as R from "ramda";

import { deepCopy } from "@ops/shared";
import { hasValue, opsAddArrayControl } from "@ops/state";

import { Division } from "../../../shared/models";
import { updateActivityForm } from "../../activities/shared";
import { updateBerthForm } from "../../berths/shared";
import { updateDestinationForm } from "../../destinations/shared";
import { orderLaytimeEventsSortFunc } from "../../laytime-events/form/order-laytime-events";
import {
    activityExpandedKey,
    ActivityForm,
    activityForm,
    ActivityId,
    associatedCargoExpandedKey,
    AssociatedCargoForm,
    AssociatedCargoId,
    berthExpandedKey,
    berthForm,
    BerthId,
    createActivityId,
    createAssociatedCargoId,
    createBerthId,
    createLaytimeEventId,
    DestinationId,
    FixturesState,
    getDefaultLaytimeEvents,
    getFixtureType,
    LaytimeEventForm,
    VoyageExpandedSections
} from "../../model";
import { getNextId } from "../../utils";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const moveAssociatedCargoAction = createAction(
    "[Voyage Form] Move Associated Cargo",
    props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId; associatedCargoId: AssociatedCargoId; berthToId?: BerthId }>()
);

/* REDUCERS */
export const moveAssociatedCargoReducer: On<FixturesState> = on(moveAssociatedCargoAction, (state, { destinationId, berthId, activityId, associatedCargoId, berthToId }) =>
    moveAssociatedCargo(state, destinationId, berthId, activityId, associatedCargoId, berthToId)
);

/* FUNCTIONS */
const moveAssociatedCargo = (
    state: FixturesState,
    destinationId: DestinationId,
    berthId: BerthId,
    activityId: ActivityId,
    associatedCargoId: AssociatedCargoId,
    berthToId?: BerthId
) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        const destination = voyageState.form.controls.destinations.value.find((d) => d.id === destinationId);
        const fromBerth = destination && destination.berths.find((b) => b.id === berthId);
        const toBerth = destination && berthToId && destination.berths.find((b) => b.id === berthToId);
        const activity = fromBerth && fromBerth.activities.find((a) => a.activityId === activityId);
        const associatedCargo = activity && activity.associatedCargoes.find((c) => c.associatedCargoId === associatedCargoId);

        if (!associatedCargo || (!!berthToId && !toBerth)) {
            return voyageState;
        }

        const fixtureType = getFixtureType(fixtureState);
        const activityType = activity.type.value;

        let associatedCargoClone: AssociatedCargoForm = deepCopy(associatedCargo);

        const laytimeEventsToUse = activity.laytimeEvents.filter((le) => le.cargoId === associatedCargo.cargoId);
        let laytimeEventClones = laytimeEventsToUse.map((le) => <LaytimeEventForm>deepCopy(le));

        const defaultLaytimeEvents = getDefaultLaytimeEvents(
            fixtureType,
            Division.specialisedProducts,
            activityType,
            false,
            destination.arrivalDateTime,
            (hasValue(destination.location) && destination.location.value.timeZone) || "utc"
        );

        let voyageForm = voyageState.form;
        let expandedSections = voyageState.expandedSections;

        if (toBerth) {
            let activityToMoveTo = toBerth.activities.find((a) => hasValue(a.type) && a.type.value.id === activityType.id);
            let updateExistingActivity = true;
            if (!activityToMoveTo) {
                const newActivityId = createActivityId();
                activityToMoveTo = {
                    ...activityForm(Division.specialisedProducts, newActivityId, getNextId(toBerth.activities, "legacyActivityId")),
                    type: box({ ...activityType, longName: null }),
                    laytimeEvents: defaultLaytimeEvents
                };
                updateExistingActivity = false;
            }

            const newAssociatedCargoId = createAssociatedCargoId();
            associatedCargoClone = {
                ...associatedCargoClone,
                associatedCargoId: newAssociatedCargoId,
                legacyAssociatedCargoId: getNextId(activityToMoveTo.associatedCargoes, "legacyAssociatedCargoId")
            };

            laytimeEventClones = laytimeEventClones.map((le) => ({ ...le, laytimeEventId: createLaytimeEventId() }));

            const sortedLaytimeEvents = [...activityToMoveTo.laytimeEvents, ...laytimeEventClones];
            sortedLaytimeEvents.sort(orderLaytimeEventsSortFunc);

            activityToMoveTo = {
                ...activityToMoveTo,
                associatedCargoes: [...activityToMoveTo.associatedCargoes, associatedCargoClone],
                laytimeEvents: sortedLaytimeEvents
            };

            voyageForm = updateBerthForm({ destinationId, berthId: berthToId }, (berth) =>
                updateGroup(berth, {
                    activities: updateExistingActivity
                        ? updateArrayWithFilter((a) => a.value.activityId === activityToMoveTo.activityId, setValue(activityToMoveTo))
                        : opsAddArrayControl(activityToMoveTo, { markAsTransient: true })
                })
            )(voyageForm);
            expandedSections = { ...expandedSections, [activityExpandedKey(destinationId, berthToId, activityToMoveTo.activityId)]: true };
        } else {
            associatedCargoClone = { ...associatedCargoClone, associatedCargoId: createAssociatedCargoId() };
            let newActivity = {
                ...activityForm(Division.specialisedProducts, createActivityId(), 1),
                type: box({ ...activityType, longName: null }),
                associatedCargoes: [associatedCargoClone],
                laytimeEvents: defaultLaytimeEvents
            };

            laytimeEventClones = laytimeEventClones.map((le) => ({ ...le, laytimeEventId: createLaytimeEventId() }));

            const sortedLaytimeEvents = [...newActivity.laytimeEvents, ...laytimeEventClones];
            sortedLaytimeEvents.sort(orderLaytimeEventsSortFunc);

            newActivity = { ...newActivity, laytimeEvents: sortedLaytimeEvents };

            const newBerthId = createBerthId();
            const newBerth = {
                ...berthForm(Division.specialisedProducts, newBerthId, getNextId(destination.berths, "berthId")),
                activities: [newActivity]
            };

            voyageForm = updateDestinationForm({ destinationId }, (destinationForm) =>
                updateGroup(destinationForm, {
                    berths: opsAddArrayControl(newBerth, { markAsTransient: true, scrollIntoView: true })
                })
            )(voyageForm);
            expandedSections = {
                ...expandedSections,
                [berthExpandedKey(destinationId, newBerthId)]: true,
                [activityExpandedKey(destinationId, newBerthId, newActivity.activityId)]: true
            };
        }

        if (fromBerth.activities.length === 1 && activity.associatedCargoes.length === 1) {
            const berthIndex = destination.berths.findIndex((b) => b.id === fromBerth.id);
            voyageForm = updateDestinationForm({ destinationId }, (destinationForm) =>
                updateGroup(destinationForm, {
                    berths: removeArrayControl(berthIndex)
                })
            )(voyageForm);
            expandedSections = <VoyageExpandedSections>R.omit([berthExpandedKey(destinationId, berthId)], expandedSections);
        } else if (activity.associatedCargoes.length === 1) {
            const activityIndex = fromBerth.activities.findIndex((a) => a.activityId === activity.activityId);
            voyageForm = updateBerthForm({ destinationId, berthId }, (berth) =>
                updateGroup(berth, {
                    activities: removeArrayControl(activityIndex)
                })
            )(voyageForm);
            expandedSections = <VoyageExpandedSections>R.omit([activityExpandedKey(destinationId, berthId, activityId)], expandedSections);
        } else {
            const associatedCargoIndex = activity.associatedCargoes.findIndex((c) => c.associatedCargoId === associatedCargo.associatedCargoId);
            voyageForm = updateActivityForm({ destinationId, berthId, activityId }, (a) =>
                updateGroup(a, {
                    associatedCargoes: removeArrayControl(associatedCargoIndex)
                })
            )(voyageForm);
            expandedSections = <VoyageExpandedSections>R.omit([associatedCargoExpandedKey(destinationId, berthId, activityId, associatedCargoId)], expandedSections);

            const laytimeEventIndex = (a: FormState<ActivityForm>, l: LaytimeEventForm) => a.value.laytimeEvents.map((le) => le.laytimeEventId).indexOf(l.laytimeEventId);
            laytimeEventsToUse.forEach((l) => {
                voyageForm = updateActivityForm({ destinationId, berthId, activityId }, (a) =>
                    updateGroup(a, {
                        laytimeEvents: removeArrayControl(laytimeEventIndex(a, l))
                    })
                )(voyageForm);
            });
        }

        return {
            ...voyageState,
            form: voyageForm,
            expandedSections
        };
    });
