import { Pipe, PipeTransform } from "@angular/core";
import { formatNumber } from "../../number-format";

@Pipe({
    name: "opsNumberStyledDecimal"
})
export class NumberFormatStyledDecimalPipe implements PipeTransform {
    transform(value: number, showTrailingZeros: boolean, minimumFractionDigits: number, precision: number): any {
        return this.styleDecimal(formatNumber(value, showTrailingZeros, minimumFractionDigits, precision));
    }

    styleDecimal = (formattedNumber: string): string => {
        const split = formattedNumber?.split(".");
        return split?.length > 1 ? `${split[0]}.<span class="decimal-text">${split[1]}</span>` : formattedNumber;
    };
}
