import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { setValue, updateArrayWithFilter } from "ngrx-forms";
import * as R from "ramda";
import { filter, map, tap, withLatestFrom } from "rxjs/operators";

import { updateActivityCargo, updateActivityCargoAction } from "./update-activity-cargo";
import {
    ActivityCargo,
    ActivityCargoForm,
    ActivityCargoId,
    ActivityLocationId,
    LaytimeCalculationCargoTermsLocation,
    LtcFeatureState,
    LtcId,
    LtcState,
    toActivityCargoForm
} from "../../../../model";
import { getDiff, equalByCargoAndProductIds } from "../../../../model/calculations/utils";
import { currentCalculationStateReducer } from "../../../reducer";
import { selectCurrentCalculationId } from "../../../selectors";
import { selectCurrentCalculationCargoTerms } from "../../../terms";
import { UpdateQueue } from "../../../update-queue";
import { selectCurrentActivityLocation } from "../../selectors";

/* ACTIONS */
const IMPORT_ACTION_NAME = "[Laytime Calculation Activity Location Cargoes Form] Import Activity Location Cargo Terms";
export const importActivityCargoTermsButtonClickAction = createAction(`${IMPORT_ACTION_NAME} Button Click`, props<{ activityCargoId: ActivityCargoId }>());
export const importActivityCargoTermsAction = createAction(
    IMPORT_ACTION_NAME,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; activityCargoId: ActivityCargoId; updatedCargo: ActivityCargo; diff: Partial<ActivityCargo> }>()
);

/* REDUCERS */
export const importActivityCargoTermsReducer: On<LtcState> = on(importActivityCargoTermsAction, (state, { activityCargoId, updatedCargo }) =>
    currentCalculationStateReducer(state, (ltcState) => ({
        ...ltcState,
        activityCargoForms: updateArrayWithFilter<ActivityCargoForm>(
            (f, _) => f.value.id === activityCargoId,
            setValue(toActivityCargoForm(updatedCargo))
        )(ltcState.activityCargoForms)
    }))
);

/* EFFECTS */
export const importActivityCargoTermsButtonClickEffect$ = (actions$: Actions, store: Store<LtcFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType(importActivityCargoTermsButtonClickAction),
            withLatestFrom(store.select(selectCurrentCalculationId), store.select(selectCurrentActivityLocation), store.select(selectCurrentCalculationCargoTerms)),
            map(([{ activityCargoId }, ltcId, activityLocation, cargoTerms]) => {
                const activityCargo = activityLocation.cargoes.find((c) => c.id === activityCargoId);
                const locationCargoTerms = (<ReadonlyArray<LaytimeCalculationCargoTermsLocation>>R.path(
                    [`${activityLocation.activity.toLocaleLowerCase()}Locations`],
                    cargoTerms.find((ct) => equalByCargoAndProductIds(ct, activityCargo))
                ))?.find((l) => l.locationId === activityLocation.locationId);
                const updatedCargo = {
                    ...activityCargo,
                    allowance: locationCargoTerms?.allowance ?? null,
                    allowanceUnit: locationCargoTerms?.allowanceUnit ?? null,
                    extraHours: locationCargoTerms?.extraHours ?? null,
                    reversible: locationCargoTerms?.reversible ?? null
                };
                const diff = getDiff(activityCargo, updatedCargo);

                return { ltcId, activityLocationId: activityLocation.id, activityCargoId, updatedCargo, diff };
            }),
            filter(({ diff }) => Object.getOwnPropertyNames(diff).length > 0),
            map(({ ltcId, activityLocationId, activityCargoId, updatedCargo, diff }) =>
                importActivityCargoTermsAction({ ltcId, activityLocationId, activityCargoId, updatedCargo, diff })
            )
        )
    );

export const importActivityCargoTermsEffect$ = (actions$: Actions, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(importActivityCargoTermsAction),
            map(({ ltcId, activityLocationId, activityCargoId, diff }) => updateActivityCargoAction({ ltcId, activityLocationId, activityCargoId, activityCargo: diff })),
            tap((action) =>
                updateQueue.enqueue(action, updateActivityCargo, (latest) =>
                    action.activityCargoId === latest.activityCargoId ? (R.mergeDeepLeft(action, latest) as ReturnType<typeof updateActivityCargoAction>) : [latest, action]
                )
            )
        )
    );
