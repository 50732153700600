import {
    cancelLiftingSummaryCommentsReducer,
    disableCommentsImportanceToggleReducer,
    editLiftingSummaryCommentsReducer,
    enableCommentsImportanceToggleReducer
} from "./form/edit-summary-comments";
import {
    saveLiftingSummaryCommentsFailReducer,
    saveLiftingSummaryCommentsReducer,
    saveLiftingSummaryCommentsSuccessReducer,
    saveLiftingSummaryCommentsImportanceReducer,
    saveLiftingSummaryCommentsImportanceSuccessReducer
} from "./form/save-summary-comments";

export default [
    editLiftingSummaryCommentsReducer,
    cancelLiftingSummaryCommentsReducer,
    disableCommentsImportanceToggleReducer,
    enableCommentsImportanceToggleReducer,
    saveLiftingSummaryCommentsReducer,
    saveLiftingSummaryCommentsSuccessReducer,
    saveLiftingSummaryCommentsFailReducer,
    saveLiftingSummaryCommentsImportanceReducer,
    saveLiftingSummaryCommentsImportanceSuccessReducer
];
