import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import {
    UpdateSuggestionTerm,
    UpdateSuggestionTermFail,
    updateSuggestionTermFailReducer,
    updateSuggestionTermReducer,
    UpdateSuggestionTermSuccess,
    updateSuggestionTermSuccessReducer
} from "@ops/state";

import { LaytimeCalculationHttpService } from "../../../services";
import { LtcState } from "../../model";

/* ACTIONS */
const ACTION_NAME = "[Laytime Calculation Grid] Update Suggestion Term";

export const updateLaytimeCalculationGridSuggestionTermAction = createAction(ACTION_NAME, props<UpdateSuggestionTerm>());
export const updateLaytimeCalculationGridSuggestionTermSuccessAction = createAction(`${ACTION_NAME} Success`, props<UpdateSuggestionTermSuccess>());
export const updateLaytimeCalculationGridSuggestionTermFailAction = createAction(`${ACTION_NAME} Fail`, props<UpdateSuggestionTermFail>());

/* REDUCERS */
export const updateLaytimeCalculationGridSuggestionTermReducer: On<LtcState> = on(updateLaytimeCalculationGridSuggestionTermAction, (state, action) =>
    updateSuggestionTermReducer(state, action, "grid")
);
export const updateLaytimeCalculationGridSuggestionTermSuccessReducer: On<LtcState> = on(updateLaytimeCalculationGridSuggestionTermSuccessAction, (state, action) =>
    updateSuggestionTermSuccessReducer(state, action, "grid")
);
export const updateLaytimeCalculationGridSuggestionTermFailReducer: On<LtcState> = on(updateLaytimeCalculationGridSuggestionTermFailAction, (state, action) =>
    updateSuggestionTermFailReducer(state, action, "grid")
);

/* EFFECTS */
export const getCalculationSuggestionsEffect$ = (actions$: Actions, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(updateLaytimeCalculationGridSuggestionTermAction),
            switchMap(({ suggestionTerm }) =>
                laytimeCalculationHttpService.getCalculationSuggestions(suggestionTerm).pipe(
                    map((items) => updateLaytimeCalculationGridSuggestionTermSuccessAction({ items })),
                    catchError((error) => of(updateLaytimeCalculationGridSuggestionTermFailAction({ error })))
                )
            )
        )
    );
