import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { AuthService } from "@ops/core";
import { Enumeration } from "@ops/shared/reference-data";
import { User } from "@ops/state";

import { FixtureSource } from "../../fixture/shared/models";
import { actionCalendarGoToCoASelect, actionCalendarGoToFixtureSelect } from "../../fixture/state";
import { Time } from "../../shared/components/time/time";
import { dateToISOString } from "../../shared/date-utils/date-utilities";
import { ActionFormModel } from "../models/action.model";

@Component({
    selector: "ops-edit-action-dialog",
    templateUrl: "./edit-action.component.html",
    styleUrls: ["./edit-action.component.scss"]
})
export class EditActionComponent implements OnInit {
    static componentName = "EditActionComponent";

    priorities: Enumeration[] = [
        { id: 1, name: "High" },
        { id: 2, name: "Medium" },
        { id: 3, name: "Low" }
    ];
    fixtureId: string;
    coaId: string;
    actionForm: UntypedFormGroup;
    fixtureSource: Enumeration;
    notificationView = false;
    isInvalidOnSave = false;
    isSaving = false;
    isNotificationsExpanded = true;
    defaultSelectTime = new Time(7, 30, 0);

    @Input() actionToEdit: ActionFormModel;
    @Output() cancelled = new EventEmitter();
    @Output() saveAction = new EventEmitter<ActionFormModel>();

    constructor(private formBuilder: UntypedFormBuilder, private router: Router, private route: ActivatedRoute, private authService: AuthService, private store: Store) {}

    ngOnInit(): void {
        this.fixtureId = this.actionToEdit.fixtureId;
        this.fixtureSource = this.actionToEdit.fixtureSource;
        this.coaId = this.actionToEdit.coaId;
        this.createForm();
    }

    cancel(): void {
        this.cancelled.emit();
    }

    get actionSummary(): string {
        return this.actionForm.get("summary").value;
    }

    get actionDueDate(): string {
        return this.actionForm.get("dueDate").value;
    }

    get actionPriority(): string {
        return this.actionForm.get("priority").value.name.toLowerCase();
    }

    get assignedToCount(): number {
        return this.actionForm.controls["assignedTo"].value.length;
    }

    get notificationDateCount(): number {
        return this.notificationDatesFormArray.value.length;
    }

    get notificationDatesFormArray(): UntypedFormArray {
        return this.actionForm.get("notificationDates") as UntypedFormArray;
    }

    get recipientCount(): number {
        return this.actionForm.controls["notificationRecipients"]?.value?.length ?? 0;
    }

    save(): void {
        if (!this.actionForm.valid) {
            this.markFormGroupTouched(this.actionForm);
            this.isInvalidOnSave = true;
            return;
        }

        if (this.isSaving) {
            return;
        }

        this.isSaving = true;
        const dates = this.notificationDatesFormArray.controls.map((control) => control.value.date);
        const actionModel = this.actionForm.value;
        const actionFormModel = <ActionFormModel>(<any>{
            actionId: this.actionToEdit.actionId,
            fixtureId: this.actionToEdit.fixtureId || actionModel.fixture?.fixtureId,
            coaId: this.actionToEdit.coaId,
            summary: actionModel.summary,
            description: actionModel.description,
            dueDate: dateToISOString(actionModel.dueDate),
            priority: actionModel.priority,
            assignedTo: actionModel.assignedTo,
            updatedDate: dateToISOString(new Date()),
            lastUpdatedBy: this.authService.user,
            isComplete: this.actionToEdit.isComplete,
            charterer: this.actionToEdit.charterer || actionModel.fixture?.charterer,
            type: this.actionToEdit.type,
            notificationDates: dates,
            notificationRecipients: actionModel.notificationRecipients
        });

        this.saveAction.emit(actionFormModel);
    }

    onGoToClick() {
        let link = "";
        if (this.actionToEdit.fixtureId) {
            link = "/fixture/" + this.actionToEdit.fixtureId;
            this.store.dispatch(actionCalendarGoToFixtureSelect());
        } else if (this.actionToEdit.coaId) {
            link = "/coa/" + this.actionToEdit.coaId;
            this.store.dispatch(actionCalendarGoToCoASelect());
        }
        link += `(toolbar:actions/${this.actionToEdit.actionId || "new"})`;
        this.router.navigateByUrl(link);
    }

    addNotification(): void {
        if (this.notificationDateCount === 0) {
            const defaultRecipient = this.getDefaultRecipient();
            this.actionForm.patchValue({ notificationRecipients: defaultRecipient });
        }

        const latestDate = this.getNextNotificationDate();
        this.notificationDatesFormArray.push(this.createNotificationDate(latestDate));
    }

    deleteNotification(index: number): void {
        this.notificationDatesFormArray.removeAt(index);
    }

    onFixtureChanged() {
        const fixture = this.actionForm.value.fixture;
        if (!fixture) {
            this.fixtureId = null;
            this.fixtureSource = null;
        } else {
            this.fixtureId = fixture.fixtureId;
            this.fixtureSource = fixture.fixtureSource === FixtureSource[FixtureSource.Ops] ? { id: 2, name: "ops" } : { id: 1, name: "gain" };
        }

        this.actionForm.patchValue({ assignedTo: this.getAssignedTo() });
        this.actionForm.patchValue({ notificationRecipients: this.getFixtureRecipient(fixture) });
    }

    private markFormGroupTouched(formGroup: UntypedFormGroup) {
        Object.values(formGroup.controls).forEach((control: UntypedFormGroup) => {
            control.markAsTouched();
            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    private createForm(): void {
        const assignedTo = this.getAssignedTo();
        const defaultPriority = { id: 2, name: "Medium" };
        this.actionForm = this.formBuilder.group({
            actionId: this.actionToEdit.actionId,
            summary: [this.actionToEdit.summary, Validators.required],
            description: [this.actionToEdit.description],
            dueDate: [this.actionToEdit.dueDate, Validators.required],
            priority: [this.actionToEdit.priority || defaultPriority, Validators.required],
            assignedTo: [assignedTo, Validators.required],
            fixture: [null, !this.actionToEdit.actionId && !this.actionToEdit.fixtureId ? Validators.required : null],
            coaId: [this.actionToEdit.coaId],
            notificationDates: this.formBuilder.array([]),
            notificationRecipients: [this.actionToEdit.notificationRecipients]
        });

        if (this.actionToEdit.notificationDates) {
            this.actionToEdit.notificationDates.forEach((date) => this.notificationDatesFormArray.push(this.createNotificationDate(date)));
        }
    }

    private getAssignedTo(): User[] {
        if (this.actionToEdit.assignedTo.length > 0) {
            return this.actionToEdit.assignedTo;
        }

        return [this.authService.user];
    }

    private getDefaultRecipient(): User[] {
        if (this.actionToEdit.defaultRecipients) {
            return this.actionToEdit.defaultRecipients;
        }
        return this.getFixtureRecipient(this.actionForm.value.fixture);
    }

    private getFixtureRecipient(fixture: any): User[] {
        if (!fixture) {
            return [];
        }
        const fixtureUsers = [...(fixture.operators || []), ...(fixture.brokers || []), ...(fixture.claims || [])];
        const defaultRecipents = fixtureUsers.map(
            (u) =>
                <User>{
                    userId: u.userId,
                    fullName: u.name,
                    userCode: u.code
                }
        );
        const uniqueDefaultRecipients: { [id: number]: User } = {};
        defaultRecipents.forEach((u) => {
            if (!uniqueDefaultRecipients[u.userId]) {
                uniqueDefaultRecipients[u.userId] = u;
            }
        });
        return Object.values(uniqueDefaultRecipients);
    }

    private createNotificationDate(date?: Date): UntypedFormGroup {
        return this.formBuilder.group({
            date: new UntypedFormControl(date ? date : "", null)
        });
    }

    private getNextNotificationDate(): Date {
        const notifications = this.notificationDatesFormArray.value as { date: Date }[];
        const notificationDates = notifications.map((d) => d.date as Date).filter((d) => d) as Date[];
        return notificationDates && notificationDates.length ? notificationDates[notificationDates.length - 1] : this.actionForm.value.dueDate;
    }
}
