import { On } from "@ngrx/store";
import { box, FormGroupState, setValue, SetValueAction, unbox, updateArray, updateArrayWithFilter, updateGroup } from "ngrx-forms";

import { shiftRangeTimeZone, shiftTimeZone } from "@ops/shared";
import { replaceValue } from "@ops/state";

import { SeaNetLocation } from "../../../shared/models";
import { getTimeZone } from "../../../shared/models/common/sea-net-location";
import { ActivityForm, BerthForm, DestinationForm, FixturesState, LaytimeEventForm, VoyageForm, VoyageState } from "../../model";
import { voyageStateReducer } from "../../voyage/reducer";

/* eslint-disable no-magic-numbers */
function shiftDestinationTimeZone(formState: FormGroupState<DestinationForm>, oldTimeZone: string, newTimeZone: string): FormGroupState<DestinationForm> {
    const form = formState.value;

    return updateGroup<DestinationForm>({
        etaRange: setValue(box(shiftRangeTimeZone(unbox(form.etaRange), oldTimeZone, newTimeZone))),
        etaFixedRange: setValue(box(shiftRangeTimeZone(unbox(form.etaFixedRange), oldTimeZone, newTimeZone))),
        arrivalDateTime: setValue(shiftTimeZone(form.arrivalDateTime, oldTimeZone, newTimeZone)),
        berths: updateArray(
            updateGroup<BerthForm>({
                activities: updateArray(
                    updateGroup<ActivityForm>({
                        laytimeEvents: updateArray(
                            updateGroup<LaytimeEventForm>({
                                eventDate: replaceValue((eventDate) => shiftTimeZone(eventDate, oldTimeZone, newTimeZone))
                            })
                        )
                    })
                )
            })
        )
    })(formState);
}

/**
 * Shifts all dates to treat the displayed value as the same local time as the new destination.
 */
export const setDestinationLocationReducer: On<FixturesState> = {
    types: [SetValueAction.TYPE],
    reducer: (state: FixturesState, action: SetValueAction<SeaNetLocation>): FixturesState => {
        const controlPath = action.controlId.split(".");
        if (controlPath.length !== 4 || controlPath[1] !== "destinations" || controlPath[3] !== "location") {
            return state;
        }

        return voyageStateReducer(state, controlPath[0], (voyageState) => {
            const destinationForm = voyageState.form.value.destinations[Number(controlPath[2])];
            const oldLocation = unbox(destinationForm.location);
            const newLocation = unbox(action.value) as SeaNetLocation;

            // If the time zone has changed
            const oldTimeZone = getTimeZone(oldLocation);
            const newTimeZone = getTimeZone(newLocation);

            if (oldTimeZone === newTimeZone) {
                return voyageState;
            }

            return <VoyageState>{
                ...voyageState,
                form: updateGroup<VoyageForm>({
                    destinations: updateArrayWithFilter<DestinationForm>(
                        (d) => d.value.id === destinationForm.id,
                        (d) => shiftDestinationTimeZone(d, oldTimeZone, newTimeZone)
                    )
                })(voyageState.form)
            };
        });
    }
};
