import { validateRequired, validateRequiredIf } from "@ops/state";
import { disable, enable, FormGroupState, updateGroup } from "ngrx-forms";
import { CoaHeaderForm, CoasState, CoaState } from "../../model";

const isCancelled = (form: FormGroupState<CoaHeaderForm>): boolean => form.value.contractStatus === "Cancelled";

export const validateCoaHeaderForm = (form: FormGroupState<CoaHeaderForm>, coaState: CoaState, coasState: CoasState) => {
    return updateGroup<CoaHeaderForm>(
        {
            driver: validateRequired(),
            contractStatus: validateRequired(),
            charterpartyDate: validateRequired(),
            currency: validateRequired(),
            cancellationReason: validateRequiredIf(isCancelled(form))
        },
        {
            cancellationReason: isCancelled(form) ? enable : disable
        },
        ...coaState.coa.controlledProperties.map((x) => ({ [x]: disable }))
    )(form);
};
