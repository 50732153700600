import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { isObservable, Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface CanDeactivateComponent {
    /**
     * Should return false, a string message to cancel component deactivation.
     */
    canDeactivate(): boolean | string | Observable<boolean | string> | null;
}

function handleCanDeactivate(canDeactivate: boolean | string | null): boolean {
    if (canDeactivate === null || canDeactivate === true) {
        return true;
    }

    const message = canDeactivate === false ? "Discard unsaved changes?" : canDeactivate;

    return confirm(message);
}

@Injectable({
    providedIn: "root"
})
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
    canDeactivate(component: CanDeactivateComponent): boolean | Observable<boolean> {
        if (typeof component.canDeactivate !== "function") {
            throw Error("Component must implement CanDeactivateComponent to be used with CanDeactivateGuard");
        }

        const canDeactivate = component.canDeactivate();

        if (isObservable(canDeactivate)) {
            return canDeactivate.pipe(map(handleCanDeactivate));
        }

        return handleCanDeactivate(canDeactivate);
    }
}
