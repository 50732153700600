<div class="activity-container" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <ng-container *ngIf="showLabel$ | async; else showDropdown">
        <div class="activity-label">{{ form.value.type.value.name }}</div>
    </ng-container>
    <ng-template #showDropdown>
        <ng-select class="activity-ng-select" [ngrxFormControlState]="form.controls.type" placeholder="Select Activity"
            [compareWith]="enumerationCompare" [items]="activityDropdownTypes" appendTo=".p-tabview-nav-container" [readonly]="false"
            (open)="onOpen()" (close)="onClose()" (mouseenter)="onMouseEnterDropdown()" [clearable]="false" bindLabel="name">
        </ng-select>
    </ng-template>
</div>