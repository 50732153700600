import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

import { CargoBerthActivityType, Enumeration, FreightType, ReferenceDataService } from "@ops/shared/reference-data";

import { Division, Voyage } from "../../shared/models";
import {
    ActivityForm,
    ActivityId,
    addAssociatedCargo,
    addAssociatedCargoExpense,
    addLaytimeEvent,
    AssociatedCargoId,
    AssociatedCargoState,
    BerthId,
    BerthItem,
    CargoItem,
    collapseAssociatedCargo,
    DestinationId,
    expandAssociatedCargo,
    FixtureFeatureState,
    LaytimeEventId,
    LaytimeEventState,
    moveAssociatedCargo,
    orderLaytimeEvents,
    removeAssociatedCargo,
    removeAssociatedCargoExpense,
    removeLaytimeEvent,
    selectCurrentDestinationActivityForm,
    selectCurrentDestinationActivityFormType,
    selectCurrentFixtureDivision,
    selectCurrentFixtureFreightType,
    selectCurrentVoyage,
    selectCurrentVoyageActivityAvailableActivityTypes,
    selectCurrentVoyageActivityIsBlAtDisportVisible,
    selectCurrentVoyageActivityIsTypeDropdownReadonly,
    selectCurrentVoyageActivityLaytimeEventsOrdered,
    selectCurrentVoyageActivityTotalLaytime,
    selectCurrentVoyageAssociatedCargoDropdownItems,
    selectCurrentVoyageAssociatedCargoes,
    selectCurrentVoyageBerthList,
    selectCurrentVoyageCargoItems,
    selectCurrentVoyageDestinationArrivalDateTime,
    selectCurrentVoyageDestinationLocationTimezone,
    selectCurrentVoyageLaytimeEvents,
    selectShowAssociatedCargoes
} from "../../state";
import { RemoveAssociatedCargoExpenseEvent } from "../associated-cargoes";

@Component({
    selector: "ops-activity-shell",
    templateUrl: "./activity-shell.component.html",
    styleUrls: ["./activity-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityShellComponent {
    readonly destinationId$ = new BehaviorSubject<DestinationId>(null);
    readonly berthId$ = new BehaviorSubject<BerthId>(null);
    readonly activityId$ = new BehaviorSubject<ActivityId>(null);

    readonly form$: Observable<FormGroupState<ActivityForm>>;
    readonly currentVoyage$: Observable<Voyage>;
    readonly activityType$: Observable<Readonly<CargoBerthActivityType>>;
    readonly locationTimezone$: Observable<string>;
    readonly isTypeDropdownReadonly$: Observable<boolean>;
    readonly isBlAtDisportVisible$: Observable<boolean>;
    readonly activityDropdownTypes$: Observable<ReadonlyArray<Enumeration>>;
    readonly showAssociatedCargoes$: Observable<boolean>;

    readonly associatedCargoes$: Observable<ReadonlyArray<AssociatedCargoState>>;
    readonly cargoes$: Observable<ReadonlyArray<CargoItem>>;
    readonly allCargoes$: Observable<ReadonlyArray<CargoItem>>;
    readonly berths$: Observable<ReadonlyArray<BerthItem>>;
    readonly arrivalDateTime$: Observable<string>;
    readonly freightType$: Observable<FreightType>;

    readonly laytimeEvents$: Observable<ReadonlyArray<LaytimeEventState>>;
    readonly totalLaytime$: Observable<number>;
    readonly laytimeEventsOrdered$: Observable<boolean>;
    readonly division$: Observable<Division>;

    @Input()
    set destinationId(value: DestinationId) {
        this.destinationId$.next(value);
    }
    get destinationId() {
        return this.destinationId$.value;
    }

    @Input()
    set berthId(value: BerthId) {
        this.berthId$.next(value);
    }
    get berthId() {
        return this.berthId$.value;
    }

    @Input()
    set activityId(value: ActivityId) {
        this.activityId$.next(value);
    }
    get activityId() {
        return this.activityId$.value;
    }

    @Input() readonly: boolean;

    constructor(private store: Store<FixtureFeatureState>, private referenceDataService: ReferenceDataService) {
        this.form$ = this.select(selectCurrentDestinationActivityForm);
        this.currentVoyage$ = this.select(selectCurrentVoyage);
        this.isBlAtDisportVisible$ = this.select(selectCurrentVoyageActivityIsBlAtDisportVisible);
        this.isTypeDropdownReadonly$ = this.select(selectCurrentVoyageActivityIsTypeDropdownReadonly);
        this.activityDropdownTypes$ = combineLatest([this.destinationId$, this.berthId$, this.activityId$, this.referenceDataService.getBerthActivityTypes()]).pipe(
            switchMap(([destinationId, berthId, activityId, activityTypes]) =>
                this.store.select(selectCurrentVoyageActivityAvailableActivityTypes, {
                    destinationId: destinationId,
                    berthId: berthId,
                    activityId: activityId,
                    allTypes: activityTypes
                })
            )
        );
        this.activityType$ = this.select(selectCurrentDestinationActivityFormType);
        this.showAssociatedCargoes$ = this.select(selectShowAssociatedCargoes);

        this.associatedCargoes$ = this.select(selectCurrentVoyageAssociatedCargoes);
        this.cargoes$ = this.select(selectCurrentVoyageAssociatedCargoDropdownItems);
        this.allCargoes$ = this.select(selectCurrentVoyageCargoItems);
        this.berths$ = this.select(selectCurrentVoyageBerthList);
        this.arrivalDateTime$ = this.destinationId$.pipe(switchMap((destinationId) => this.store.select(selectCurrentVoyageDestinationArrivalDateTime, { destinationId })));
        this.freightType$ = this.store.select(selectCurrentFixtureFreightType);

        this.laytimeEvents$ = this.select(selectCurrentVoyageLaytimeEvents);
        this.totalLaytime$ = this.select(selectCurrentVoyageActivityTotalLaytime);
        this.laytimeEventsOrdered$ = this.select(selectCurrentVoyageActivityLaytimeEventsOrdered);

        this.locationTimezone$ = this.destinationId$.pipe(switchMap((destinationId) => this.store.select(selectCurrentVoyageDestinationLocationTimezone, { destinationId })));

        this.division$ = this.select(selectCurrentFixtureDivision);
    }

    addLaytimeEvent(index: number) {
        this.store.dispatch(addLaytimeEvent({ destinationId: this.destinationId, berthId: this.berthId, activityId: this.activityId, index }));
    }

    addAssociatedCargo() {
        this.store.dispatch(addAssociatedCargo({ destinationId: this.destinationId, berthId: this.berthId, activityId: this.activityId }));
    }

    removeAssociatedCargo(index: number) {
        this.store.dispatch(removeAssociatedCargo({ destinationId: this.destinationId, berthId: this.berthId, activityId: this.activityId, index }));
    }

    addAssociatedCargoExpense(associatedCargoId: AssociatedCargoId) {
        this.store.dispatch(addAssociatedCargoExpense({ destinationId: this.destinationId, berthId: this.berthId, activityId: this.activityId, associatedCargoId }));
    }

    removeAssociatedCargoExpense(event: RemoveAssociatedCargoExpenseEvent) {
        this.store.dispatch(
            removeAssociatedCargoExpense({
                destinationId: this.destinationId,
                berthId: this.berthId,
                activityId: this.activityId,
                associatedCargoId: event.associatedCargoId,
                index: event.index
            })
        );
    }

    expandAssociatedCargo(associatedCargoId: AssociatedCargoId) {
        this.store.dispatch(expandAssociatedCargo({ destinationId: this.destinationId, berthId: this.berthId, activityId: this.activityId, associatedCargoId }));
    }

    collapseAssociatedCargo(associatedCargoId: AssociatedCargoId) {
        this.store.dispatch(collapseAssociatedCargo({ destinationId: this.destinationId, berthId: this.berthId, activityId: this.activityId, associatedCargoId }));
    }

    moveAssociatedCargo(moveParams: { associatedCargoId: AssociatedCargoId; berthId: BerthId }) {
        this.store.dispatch(
            moveAssociatedCargo({
                destinationId: this.destinationId,
                berthId: this.berthId,
                activityId: this.activityId,
                associatedCargoId: moveParams.associatedCargoId,
                berthToId: moveParams.berthId
            })
        );
    }

    orderLaytimeEvents() {
        this.store.dispatch(orderLaytimeEvents({ destinationId: this.destinationId, berthId: this.berthId, activityId: this.activityId }));
    }

    removeLaytimeEvent(laytimeEventId: LaytimeEventId) {
        this.store.dispatch(removeLaytimeEvent({ destinationId: this.destinationId, berthId: this.berthId, activityId: this.activityId, laytimeEventId }));
    }

    private select<K, Props extends { destinationId: DestinationId; berthId: BerthId; activityId: ActivityId }>(mapFn: (state: FixtureFeatureState, props: Props) => K) {
        return combineLatest([this.destinationId$, this.berthId$, this.activityId$]).pipe(
            switchMap(([destinationId, berthId, activityId]) => this.store.select(mapFn, { destinationId, berthId, activityId }))
        );
    }
}
