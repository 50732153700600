import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { AssociatedCargoesByVoyageActivity, AssociatedCargoSelection, isSelectAllEnabled, VoyageActivityId } from "../../../state";

@Component({
    selector: "ops-associated-cargoes-by-voyage-activity",
    templateUrl: "./associated-cargoes-by-voyage-activity.component.html",
    styleUrls: ["./associated-cargoes-by-voyage-activity.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociatedCargoesByVoyageActivityComponent {
    @Input() associatedCargoesByVoyageActivity: AssociatedCargoesByVoyageActivity;
    @Output() readonly selectAllByVoyageActivity = new EventEmitter<VoyageActivityId>();

    trackBy(index: number, item: AssociatedCargoSelection) {
        return item.associatedCargoId;
    }

    get isSelectAllEnabled() {
        return isSelectAllEnabled(this.associatedCargoesByVoyageActivity.associatedCargoes);
    }
}
