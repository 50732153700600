import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";

import { Nullable } from "../../../shared";
import { WorksheetView } from "../shared/worksheet.model";

type ValidationError = { fieldDisplayName: string; errorMessage: string };
type InputState = {
    value: string;
    dirty: boolean;
    error: Nullable<ValidationError>;
};

@Component({
    selector: "ops-legacy-worksheet-rename-form",
    templateUrl: "./legacy-worksheet-rename-form.component.html",
    styleUrls: ["./legacy-worksheet-rename-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegacyWorksheetRenameFormComponent {
    private currentWorksheetName = "";

    protected inputState: InputState = {
        value: "",
        dirty: false,
        error: null
    };

    worksheetId = "";

    @Input() set worksheet(value: WorksheetView) {
        this.currentWorksheetName = value.name;
        this.inputState.value = value.name;
        this.worksheetId = value.worksheetId;
    }
    @Input() worksheetsNames: string[];

    @Output() readonly cancel = new EventEmitter<{ id: string }>();
    @Output() readonly save = new EventEmitter<{ name: string; id: string }>();

    @ViewChild("nameInput") inputEl: ElementRef<HTMLInputElement>;
    @ViewChild(NgbPopover) popoverEl: NgbPopover;

    get name() {
        return this.inputState.value;
    }

    get showValidationMessage() {
        const { dirty: touched, error } = this.inputState;
        return touched && !!error;
    }

    focusInput() {
        this.inputEl.nativeElement.focus();
    }

    onInputFocus() {
        this.popoverEl.open();
    }

    onInputBlur() {
        this.updateValidationError();
        this.popoverEl.close();
    }

    processInputKeypress(event: KeyboardEvent) {
        this.updateValidationError();

        if (event.key === "Enter" && !this.inputState.error) {
            this.save.emit({ name: this.inputState.value, id: this.worksheetId });
            return;
        }

        if (event.key === "Escape") {
            this.cancel.emit({ id: this.worksheetId });
            return;
        }
    }

    private updateValidationError() {
        const error = this.getErrorMessage();
        this.inputState = { ...this.inputState, error, dirty: true };
    }

    private getErrorMessage() {
        if (!this.name.length) {
            return { fieldDisplayName: "Name", errorMessage: "is required" };
        }

        const otherWorksheetsNames = this.worksheetsNames.filter((name) => name !== this.currentWorksheetName);
        if (otherWorksheetsNames.includes(this.name)) {
            return { fieldDisplayName: "Name", errorMessage: "cannot be a duplicate of an existing worksheet" };
        }

        return null;
    }
}
