import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { SimpleChanges } from "@ops/shared";

import { Accordion } from "../../../shared/accordion";
import { Command } from "../../mediator";
import { CpSpeedAndConsumption, Delivery, Destination } from "../../shared/models";
import { AtSeaBunkerConsumption } from "../../shared/models/dtos/at-sea-bunker-consumption";
import { DestinationId } from "../../state/model";
import { InitBunkerCommand } from "./commands/init-bunker";

@Component({
    selector: "ops-voyage-bunker-consumption",
    templateUrl: "./voyage-bunker-consumption.component.html",
    styleUrls: ["./voyage-bunker-consumption.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoyageBunkerConsumptionComponent implements OnInit, OnChanges, OnDestroy {
    static componentName = "VoyageBunkerConsumptionComponent";

    form: UntypedFormArray;
    accordions = new Map<DestinationId, Accordion>();
    atSeaBunkersConsumptionMap = new Map<number, AtSeaBunkerConsumption>();

    @Input() readonly: boolean;
    @Input() isSimpleMode: boolean;
    @Input() parentForm: UntypedFormGroup;
    @Input() delivery: Delivery;
    @Input() destinations: Destination[];
    @Input() atSeaBunkersConsumption: AtSeaBunkerConsumption[];
    @Input() cpSpeedAndConsumption: CpSpeedAndConsumption;
    @Output() voyageBunkerConsumptionUpdated = new EventEmitter();

    constructor(private formBuilder: UntypedFormBuilder) {}

    get invalid() {
        return this.parentForm.invalid;
    }

    get touched() {
        return this.parentForm.touched;
    }

    ngOnInit() {
        this.form = this.formBuilder.array([]);
        this.voyageBunkerConsumptionUpdated.emit(new InitBunkerCommand(this.cpSpeedAndConsumption, this.delivery, false));

        this.parentForm.registerControl("voyageBunkerConsumptionForm", this.form);
        this.form[this.parentForm.disabled ? "disable" : "enable"]();
        this.updateControls(null, !!this.destinations);
    }

    ngOnChanges(changes: SimpleChanges<VoyageBunkerConsumptionComponent>) {
        this.updateControls(changes.atSeaBunkersConsumption?.previousValue, !!changes.destinations);
    }

    updateControls(previousAtSeaBunkersConsumption: AtSeaBunkerConsumption[], atSeaDestinationsChanged: boolean) {
        if (!this.form) {
            return;
        }

        this.addNeededAccordions(this.destinations, true);
        this.resizeList(this.isSimpleMode ? this.atSeaBunkersConsumption.length : this.destinations.length);

        previousAtSeaBunkersConsumption = previousAtSeaBunkersConsumption || this.atSeaBunkersConsumption;
        let atSeaDestinationsUpdated = !this.compareAtSeaDestinations(previousAtSeaBunkersConsumption, this.atSeaBunkersConsumption);

        this.atSeaBunkersConsumptionMap.clear();

        if (this.isSimpleMode) {
            this.atSeaBunkersConsumption.forEach((bunkerConsumption, i) => {
                this.atSeaBunkersConsumptionMap.set(i, bunkerConsumption);
            });
            // Required to detect changes to activity type
            atSeaDestinationsUpdated = atSeaDestinationsChanged;
        } else {
            for (let i = 0; i < this.destinations.length - 1; i++) {
                const destinationFromId = this.destinations[i].id;
                const destinationToId = this.destinations[i + 1].id;

                const bunkerConsumption = this.atSeaBunkersConsumption.find((atSea) => atSea.destinationFromId === destinationFromId && atSea.destinationToId === destinationToId);

                if (!bunkerConsumption) {
                    atSeaDestinationsUpdated = true;
                }

                this.atSeaBunkersConsumptionMap.set(i, bunkerConsumption);
            }
        }

        if (atSeaDestinationsUpdated || this.atSeaBunkersConsumption.some((x) => (x.bunkersConsumed || []).some((y) => y.quantityMt === undefined))) {
            this.voyageBunkerConsumptionUpdated.emit(new InitBunkerCommand(this.cpSpeedAndConsumption, this.delivery, false));
        }
    }

    getAtSeaConsumption(index: number): AtSeaBunkerConsumption {
        if (this.isSimpleMode) {
            return this.atSeaBunkersConsumption[index];
        }

        return this.atSeaBunkersConsumptionMap.get(index);
    }

    ngOnDestroy() {
        delete this.parentForm.controls.locationsForm;
    }

    handleUpdateCommand(event: Command) {
        // TODO: (NGRX) This is very bug prone
        this.voyageBunkerConsumptionUpdated.emit(event);
        this.voyageBunkerConsumptionUpdated.emit(new InitBunkerCommand(this.cpSpeedAndConsumption, this.delivery, false));
    }

    private compareAtSeaDestinations(oldAtSeaBunkersConsumption: AtSeaBunkerConsumption[], newAtSeaBunkersConsumption: AtSeaBunkerConsumption[]): boolean {
        for (const newItem of newAtSeaBunkersConsumption) {
            const oldItem = oldAtSeaBunkersConsumption.find((x) => x.id === newItem.id);
            if (oldItem && (newItem.destinationToId !== oldItem.destinationToId || newItem.destinationFromId !== oldItem.destinationFromId)) {
                return false;
            }
            if (!this.destinations.some((x) => x.id === newItem.destinationToId) || !this.destinations.some((x) => x.id === newItem.destinationFromId)) {
                return false;
            }
        }

        return true;
    }

    private resizeList(count: number): void {
        const formArray = <UntypedFormArray>this.form;

        if (formArray.length === count) {
            return;
        } else if (formArray.length > count) {
            formArray.removeAt(0);
        } else if (formArray.length < count) {
            formArray.push(this.createRow(this.parentForm.disabled));
        }

        return this.resizeList(count);
    }

    private createRow(disabled: boolean) {
        const group = this.formBuilder.group({}, { updateOn: "blur" });

        if (disabled) {
            group.disable({ emitEvent: false });
        }

        return group;
    }

    private addNeededAccordions(destinations: Destination[], shouldBeOpen: boolean) {
        for (const destination of destinations) {
            if (!this.accordions.has(destination.id)) {
                this.accordions.set(destination.id, new Accordion(shouldBeOpen));
            }
        }
    }
}
