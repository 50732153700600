import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroupState } from "ngrx-forms";

import { allowanceUnits, LaytimeCalculationCargoTermsLocationForm, reversibleLaytimeTypes, LaytimeCalculationCargoTermsLocationListItem, LocationId } from "../../state";

@Component({
    selector: "ops-laytime-calculation-cargo-term-location",
    templateUrl: "./laytime-calculation-cargo-term-location.component.html",
    styleUrls: ["./laytime-calculation-cargo-term-location.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationCargoTermLocationComponent {
    readonly allowanceUnits = allowanceUnits;
    readonly reversibleLaytimeTypes = reversibleLaytimeTypes;

    @Input() location: LaytimeCalculationCargoTermsLocationListItem;
    @Input() form: FormGroupState<LaytimeCalculationCargoTermsLocationForm>;
    @Input() isEditing: boolean;
    @Input() isLoadLaytime: boolean;

    @Output() readonly remove = new EventEmitter<LocationId>();

    get hoursText() {
        return this.location.extraHours === 1 ? "Hour" : "Hours";
    }

    get cpAllowanceText() {
        return `CP ${this.isLoadLaytime ? "Load" : "Discharge"} Allowance`;
    }
}
