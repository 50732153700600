import { Injectable } from "@angular/core";
import { CommandHandler } from "src/app/fixture/mediator/command-handlers/command-handler";
import { Fixture } from "src/app/fixture/shared/models";
import { EngineConsumptionFormModel } from "src/app/fixture/shared/models/form-models/engine-consumption.model";
import { AtSeaVesselStatus } from "src/app/shared/reference-data/at-sea-vessel-status";
import { BunkerSeaStatus } from "src/app/shared/reference-data/bunker-sea-status";
import { UpdateEngineConsumptionCommand } from "./update-engine-consumption.command";

@Injectable({
    providedIn: "root"
})
export class UpdateEngineConsumptionCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateEngineConsumptionCommand) {
        const payload = command.payload as EngineConsumptionFormModel;

        const engineConsumption = fixture.cpSpeedAndConsumption.engineConsumption || (fixture.cpSpeedAndConsumption.engineConsumption = {});

        if (payload.cargoStatus.name === AtSeaVesselStatus.Ballast.name && payload.seaStatus.name === BunkerSeaStatus.Full.name) {
            engineConsumption.ballastFullMain = payload.mainEngine;
            engineConsumption.ballastFullAuxiliary = payload.auxiliaryEngine;
            return;
        }

        if (payload.cargoStatus.name === AtSeaVesselStatus.Laden.name && payload.seaStatus.name === BunkerSeaStatus.Full.name) {
            engineConsumption.ladenFullMain = payload.mainEngine;
            engineConsumption.ladenFullAuxiliary = payload.auxiliaryEngine;
            return;
        }

        if (payload.cargoStatus.name === AtSeaVesselStatus.Ballast.name && payload.seaStatus.name === BunkerSeaStatus.Eco.name) {
            engineConsumption.ballastEcoMain = payload.mainEngine;
            engineConsumption.ballastEcoAuxiliary = payload.auxiliaryEngine;
            return;
        }

        if (payload.cargoStatus.name === AtSeaVesselStatus.Laden.name && payload.seaStatus.name === BunkerSeaStatus.Eco.name) {
            engineConsumption.ladenEcoMain = payload.mainEngine;
            engineConsumption.ladenEcoAuxiliary = payload.auxiliaryEngine;
            return;
        }
    }
}
