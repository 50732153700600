import { Inject, Injectable } from "@angular/core";
import { Fixture } from "../models";
import { FixtureWarningRule, FIXTURE_WARNING_RULE } from "./rules/fixture-warning-rule";

@Injectable({
    providedIn: "root"
})
export class FixtureWarningRules {
    constructor(@Inject(FIXTURE_WARNING_RULE) private rules: FixtureWarningRule[]) {}

    getRules(fixture: Fixture): FixtureWarningRule[] {
        return this.rules.filter(rule => rule.shouldEvaluate(fixture));
    }
}
