import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";

import { LaytimeCalculationUnit } from "@ops/shared/reference-data";

import { toNgbTimeStruct } from "../../../shared/date-utils/date-utilities";
import { Enumeration } from "../../../shared/reference-data/enumeration";
import { ReferenceDataService } from "../../../shared/reference-data/reference-data.service";
import { WeekDaysList } from "../../../shared/reference-data/week-day";
import { WorkingDayType } from "../../../shared/reference-data/workingday-type";
import { Command } from "../../mediator/commands/command";
import { FormComponentBase } from "../../shared/form-component-base";
import { Cargo, Destination, Fixture } from "../../shared/models";
import { CargoAllowedRate } from "../../shared/models/dtos/cargo-allowed-rate.dto";
import { StatementOfFactsContainerModel } from "../../shared/models/form-models/statement-of-facts-container-model";
import { DestinationId } from "../../state/model";
import { UpdateStatementOfFactsCommand } from "./commands/update-statement-of-facts.command";

@Component({
    selector: "ops-statement-of-facts-container",
    templateUrl: "./statement-of-facts-container.component.html",
    styleUrls: ["./statement-of-facts-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatementOfFactsContainerComponent extends FormComponentBase implements OnInit, OnDestroy, OnChanges {
    static componentName = "StatementOfFactsContainerComponent";

    readonly formName = "statementOfFactsForm";
    berthFormArray: UntypedFormArray;

    statementOfFactsForm: UntypedFormGroup;
    workingDayTypes$: Observable<Enumeration[]>;
    weekDays$: Observable<Enumeration[]>;

    @Input() destinationId: DestinationId;
    @Input() workingDayType: Enumeration;
    @Input() unlessUsed: boolean;
    @Input() excludingFromDay: Enumeration;
    @Input() excludingFromTime: string;
    @Input() excludingToDay: Enumeration;
    @Input() excludingToTime: string;
    @Input() destination: Destination;
    @Input() cargoes: Cargo[];
    @Input() locationTimezone: string;
    @Input() destinations: Destination[];
    @Input() defaultEventStartDate: string;
    @Input() cargoAllowedRates: CargoAllowedRate[];
    @Input() fixture: Fixture;
    @Input() parentForm: UntypedFormGroup;
    @Input() displayUnitType: LaytimeCalculationUnit;

    @Output() statementOfFactsUpdated = new EventEmitter();

    constructor(private formBuilder: UntypedFormBuilder, public referenceDataService: ReferenceDataService) {
        super();
    }

    ngOnInit() {
        this.berthFormArray = this.formBuilder.array([]);
        this.workingDayTypes$ = this.referenceDataService.getWorkingDayTypes();
        this.weekDays$ = this.referenceDataService.getWeekDays();

        this.createForm();
        this.parentForm.registerControl(this.formName, this.statementOfFactsForm);

        if (this.parentForm.disabled) {
            this.statementOfFactsForm.disable();
        } else {
            this.statementOfFactsForm.enable();
        }

        this.parentForm.registerControl("berthFormArray", this.berthFormArray);

        if (this.parentForm.disabled) {
            this.berthFormArray.disable();
        } else {
            this.berthFormArray.enable();
        }

        this.setStatementOfFactsForm();
        this.setBerthFormArray();
    }

    ngOnDestroy() {
        this.removeFormSubscriptions();
        delete this.parentForm.controls[this.formName];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes &&
            this.statementOfFactsForm &&
            ((changes.workingDayType && !changes.workingDayType.firstChange) ||
                (changes.unlessUsed && !changes.unlessUsed.firstChange) ||
                (changes.excludingFromDay && !changes.excludingFromDay.firstChange) ||
                (changes.excludingFromTime && !changes.excludingFromTime.firstChange) ||
                (changes.excludingToDay && !changes.excludingToDay.firstChange) ||
                (changes.excludingToTime && !changes.excludingToTime.firstChange))
        ) {
            this.setStatementOfFactsForm();
        }

        if (changes.destination && !changes.destination.firstChange) {
            this.setBerthFormArray();
        }
    }

    cargoStatementOfFactsUpdated(event: Command): void {
        this.statementOfFactsUpdated.emit(event);
    }

    private createForm() {
        this.statementOfFactsForm = this.formBuilder.group({
            workingDayType: [],
            excludingFromDay: [],
            excludingFromTime: [],
            excludingToDay: [],
            excludingToTime: [],
            unlessUsed: []
        });
    }

    private setStatementOfFactsForm() {
        let dataModel: StatementOfFactsContainerModel;
        if (this.workingDayType && this.workingDayType.id === WorkingDayType.Shex.id && !this.excludingFromDay && !this.excludingFromTime && !this.excludingToDay) {
            dataModel = {
                workingDayType: this.workingDayType,
                unlessUsed: this.unlessUsed,
                excludingFromDay: WeekDaysList.Friday,
                excludingFromTime: toNgbTimeStruct("17:00:00"),
                excludingToDay: WeekDaysList.Monday,
                excludingToTime: toNgbTimeStruct("08:00:00")
            };
            this.statementOfFactsUpdated.emit(new UpdateStatementOfFactsCommand(this.destinationId, dataModel));
        } else {
            dataModel = {
                workingDayType: this.workingDayType,
                excludingFromDay: this.excludingFromDay,
                unlessUsed: this.unlessUsed,
                excludingFromTime: toNgbTimeStruct(this.excludingFromTime),
                excludingToDay: this.excludingToDay,
                excludingToTime: toNgbTimeStruct(this.excludingToTime)
            };
        }

        this.removeFormSubscriptions();

        if ((this.workingDayType && this.workingDayType.id === WorkingDayType.Shinc.id) || this.parentForm.disabled) {
            this.statementOfFactsForm.controls.excludingFromDay.disable({ emitEvent: false });
            this.statementOfFactsForm.controls.excludingFromTime.disable({ emitEvent: false });
            this.statementOfFactsForm.controls.excludingToDay.disable({ emitEvent: false });
            this.statementOfFactsForm.controls.excludingToTime.disable({ emitEvent: false });
            this.statementOfFactsForm.controls.unlessUsed.disable({ emitEvent: false });
        } else if (this.parentForm.enable) {
            this.statementOfFactsForm.controls.excludingFromDay.enable({ emitEvent: false });
            this.statementOfFactsForm.controls.excludingFromTime.enable({ emitEvent: false });
            this.statementOfFactsForm.controls.excludingToDay.enable({ emitEvent: false });
            this.statementOfFactsForm.controls.excludingToTime.enable({ emitEvent: false });
            this.statementOfFactsForm.controls.unlessUsed.enable({ emitEvent: false });
        }

        this.statementOfFactsForm.patchValue(dataModel, { emitEvent: false });

        this.subscribeToFormValueChanges(this.statementOfFactsForm, (data: StatementOfFactsContainerModel) =>
            this.statementOfFactsUpdated.emit(new UpdateStatementOfFactsCommand(this.destinationId, data))
        );
    }

    private setBerthFormArray(): void {
        const formArray = <UntypedFormArray>this.berthFormArray;
        const count = this.destination.berths ? this.destination.berths.length : 0;

        while (formArray.length !== count) {
            if (formArray.length > count) {
                formArray.removeAt(0);
            } else if (formArray.length < count) {
                const newGroup = this.formBuilder.group({}, { updateOn: "blur" });
                if (this.parentForm.disabled) {
                    newGroup.disable();
                } else {
                    newGroup.enable();
                }
                formArray.push(newGroup);
            }
        }
    }
}
