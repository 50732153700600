import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { EMPTY, Observable, Subject, timer } from "rxjs";
import { catchError, filter, switchMap, takeUntil, tap } from "rxjs/operators";
import { diff } from "semver";

import { OPS_VERSION } from "../../../environments/environment";
import { AppConfigService } from "../../core/app-config.service";
import { NotificationService } from "../notification/notification.service";

const DEFAULT_VERSION_POLL_INTERVAL_MILLISECONDS = 60000;
const VERSION_CHECK_URI = "./version.json";
const VERSION_UPGRADE_MESSAGE = "A new version of Sea/Ops is available. <b>Refresh</b> to get the latest version";

@Injectable({
    providedIn: "root"
})
export class VersionService implements OnDestroy {
    private readonly destroy$ = new Subject();

    loadedVersion: string = OPS_VERSION;
    lastUpdateVersion: string;

    constructor(private http: HttpClient, private notificationService: NotificationService, private appConfig: AppConfigService) {}

    startPolling() {
        const versionPollInterval = this.appConfig.config.versionPollIntervalMilliSeconds ?? DEFAULT_VERSION_POLL_INTERVAL_MILLISECONDS;

        timer(0, versionPollInterval)
            .pipe(
                takeUntil(this.destroy$),
                switchMap((_) => this.getVersion()),
                filter((newVersion) => newVersion !== this.loadedVersion && newVersion !== this.lastUpdateVersion),
                tap((newVersion) => {
                    this.showNotification(newVersion);
                    this.lastUpdateVersion = newVersion;
                })
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    showNotification(newVersion: string) {
        const cleanedServerVersion = this.cleanVersionNumber(newVersion);
        const cleanedLoadedVersion = this.cleanVersionNumber(this.loadedVersion);

        const versionDiff = diff(cleanedLoadedVersion, cleanedServerVersion);
        switch (versionDiff) {
            case "major":
                this.notificationService.versionUpdate("", VERSION_UPGRADE_MESSAGE, false);
                return;
            case "minor":
            case "patch":
                this.notificationService.versionUpdate("", VERSION_UPGRADE_MESSAGE, true);
                return;
        }

        if (this.lastUpdateVersion && this.lastUpdateVersion !== newVersion) {
            this.notificationService.versionUpdate("", VERSION_UPGRADE_MESSAGE, true);
        }
    }

    cleanVersionNumber(versionNumber: string): string {
        if (versionNumber.indexOf("-") > 0) {
            versionNumber = versionNumber.substr(0, versionNumber.indexOf("-"));
        }
        const versionWith4Decimals = 4;
        if (versionNumber.split(".").length === versionWith4Decimals) {
            versionNumber = versionNumber.substr(0, versionNumber.lastIndexOf("."));
        }
        return versionNumber;
    }

    getVersion(): Observable<string> {
        return this.http.get<string>(VERSION_CHECK_URI).pipe(catchError((_) => EMPTY));
    }
}
