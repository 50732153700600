import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { SelectButtonModule } from "primeng/selectbutton";

import { OpsLoadAnimationModule } from "../load-animation";
import { ActionButtonComponent } from "./action-button.component";
import { IconButtonComponent } from "./icon-button.component";
import { SelectButtonComponent } from "./select-button.component";

@NgModule({
    imports: [CommonModule, FormsModule, OpsLoadAnimationModule, NgbTooltipModule, SelectButtonModule],
    declarations: [ActionButtonComponent, IconButtonComponent, SelectButtonComponent],
    exports: [ActionButtonComponent, IconButtonComponent, SelectButtonComponent]
})
export class OpsButtonModule {}
