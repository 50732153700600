import { createAction, on, On, props } from "@ngrx/store";

import { DismissedWarningRecord } from "../../../shared/warnings/fixture-warning.model";
import { FixturesState } from "../../../state";
import { currentFixtureStateReducer } from "../../../state/fixture/reducer";

/* ACTIONS */
const DISMISS_ACTION_NAME = "[Warnings] Dismiss Warning";

export const dismissWarningAction = createAction(DISMISS_ACTION_NAME, props<{ record: DismissedWarningRecord }>());

/* REDUCERS */
export const dismissWarningReducer: On<FixturesState> = on(dismissWarningAction, (state, { record }) =>
    currentFixtureStateReducer(state, (fixtureState) => ({
        ...fixtureState,
        dismissedWarnings: [...(fixtureState.dismissedWarnings || []), record]
    }))
);
