import { createAction, on, On, props } from "@ngrx/store";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { update } from "@ops/shared";
import { opsAddArrayControl } from "@ops/state";

import { LaytimeCalculationHttpService } from "../../../../../services";
import { ActivityCargo, activityCargoForm, ActivityCargoForm, ActivityLocation, ActivityLocationId, idEq, LaytimeCalculationState, LtcId, LtcState } from "../../../../model";
import { calculationStateReducer, currentCalculationStateReducer } from "../../../reducer";

/* ACTIONS */
export const ADD_ACTION_NAME = "[Laytime Calculation Activity Location Cargoes Form] Add Activity Location Cargo";
export const addActivityCargoFormAction = createAction(`${ADD_ACTION_NAME} Form`);
export const addActivityCargoAction = createAction(ADD_ACTION_NAME, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; activityCargo: ActivityCargo }>());
export const addActivityCargoSuccessAction = createAction(
    `${ADD_ACTION_NAME} Success`,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; activityCargo: ActivityCargo }>()
);
export const addActivityCargoFailAction = createAction(`${ADD_ACTION_NAME} Fail`, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; error: Error }>());

/* REDUCERS */
export const addActivityCargoFormReducer: On<LtcState> = on(addActivityCargoFormAction, (state) =>
    currentCalculationStateReducer(state, (ltcState) => ({
        ...ltcState,
        activityCargoForms: opsAddArrayControl<ActivityCargoForm>(activityCargoForm(), { focusControlName: "cargoProduct" })(ltcState.activityCargoForms)
    }))
);

export const addActivityCargoReducer: On<LtcState> = on(addActivityCargoAction, (state, { activityCargo, activityLocationId, ltcId }) => {
    const updateFns: Evolver<LaytimeCalculationState> = {
        calculation: { activityLocations: update(idEq(activityLocationId), (al: ActivityLocation) => ({ ...al, cargoes: [...al.cargoes, activityCargo] })) }
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

/* EFFECTS */
export const addActivityCargo = (
    { ltcId, activityLocationId, activityCargo }: ReturnType<typeof addActivityCargoAction>,
    laytimeCalculationHttpService: LaytimeCalculationHttpService
) =>
    laytimeCalculationHttpService.addActivityCargo(ltcId, activityLocationId, activityCargo).pipe(
        map(() => addActivityCargoSuccessAction({ ltcId, activityLocationId, activityCargo })),
        catchError((error) => of(addActivityCargoFailAction({ ltcId, activityLocationId, error })))
    );
