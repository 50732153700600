import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/core";

@Injectable({
    providedIn: "root"
})
export class TimeBarsHttpService {
    private fixtureServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.fixtureServiceUrl = appConfigService.config.fixtureServiceUrl;
    }

    put(fixtureId: string, date: Date): Observable<any> {
        return this.httpClient.put(`${this.fixtureServiceUrl}/api/v1.0/fixtures/${fixtureId}/demurrage/timebars/notice/date`, { date: date });
    }
}
