import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";

import { AddActivityCargo, AddActivityLocation, LaytimeCalculationHttpService } from "../../../../services/laytime-calculation-http.service";
import {
    createCargoTermId,
    LaytimeCalculation,
    LaytimeCalculationCargoTerms,
    LaytimeCalculationCargoTermsLocation,
    LocationId,
    LtcFeatureState,
    LtcId,
    LtcState,
    Voyage
} from "../../../model";
import { calculationStateReducer } from "../../reducer";
import { selectCurrentLaytimeCalculation } from "../../selectors";
import { UpdateQueue } from "../../update-queue";
import { addActivityLocationsAction } from "./add-activity-locations";

/* ACTIONS */
const UPSERT_CARGO_TERMS_ACTION_NAME = "[LTC Add Activity Locations] Upsert Cargo Terms";
export const upsertCargoTermsAction = createAction(UPSERT_CARGO_TERMS_ACTION_NAME, props<{ ltcId: LtcId; cargoTerms: LaytimeCalculationCargoTerms }>());
export const upsertCargoTermsSuccessAction = createAction(`${UPSERT_CARGO_TERMS_ACTION_NAME} Success`, props<{ ltcId: LtcId; cargoTerms: LaytimeCalculationCargoTerms }>());
export const upsertCargoTermsFailAction = createAction(`${UPSERT_CARGO_TERMS_ACTION_NAME} Fail`, props<{ ltcId: LtcId; error: Error }>());

/* REDUCERS */
export const upsertCargoTermsSuccessReducer: On<LtcState> = on(upsertCargoTermsSuccessAction, (state, { ltcId, cargoTerms }) => {
    const updateFns: Evolver = {
        calculation: mergeCargoTerms(cargoTerms, state.calculations.byId[ltcId].voyage)
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

/* EFFECTS */
export const upsertCargoTermsOnAddActivityLocationsEffect$ = (actions$: Actions, store: Store<LtcFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType(addActivityLocationsAction),
            withLatestFrom(store.select(selectCurrentLaytimeCalculation)),
            filter(([, calculation]) => calculation?.timeAllowance === "Non Fixed"),
            switchMap(([{ addActivityLocations }, calculation]) =>
                calculateCargoTerms(addActivityLocations, calculation).map((cargoTerms) => upsertCargoTermsAction({ ltcId: calculation.id, cargoTerms }))
            )
        )
    );

export const upsertCargoTermsActionEffect$ = (actions$: Actions, updateQueue: UpdateQueue) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(upsertCargoTermsAction),
                tap((action) => updateQueue.enqueue(action, upsertCargoTermsToCalculation))
            ),
        { dispatch: false }
    );

/* FUNCTIONS */
const mergeCargoTerms = R.curry((cargoTerms: LaytimeCalculationCargoTerms, voyage: Voyage, calculation: LaytimeCalculation) => {
    if (!cargoTerms) {
        return calculation;
    }
    const cargo = voyage.cargoes.find((c) => c.id === cargoTerms.cargoId);
    const patchedCargoTerms = {
        ...cargoTerms,
        name: cargo?.cargoProduct.name ?? "",
        loadLocations: patchLocations(cargoTerms.loadLocations, voyage),
        dischargeLocations: patchLocations(cargoTerms.dischargeLocations, voyage)
    };
    const finalCargoTerms = [...calculation.cargoTerms];
    const index = calculation.cargoTerms.findIndex((ct) => ct.id === cargoTerms.id);
    if (index >= 0) {
        finalCargoTerms[index] = patchedCargoTerms;
    } else {
        finalCargoTerms.push(patchedCargoTerms);
    }
    return { ...calculation, cargoTerms: finalCargoTerms };
});

const patchLocations = (locations: ReadonlyArray<LaytimeCalculationCargoTermsLocation>, voyage: Voyage) =>
    locations.map((location) => {
        const voyageLocation = voyage.destinations.find((d) => d.location.locationId === location.locationId);
        return {
            ...location,
            name: voyageLocation?.location.displayName,
            countryName: voyageLocation?.location.countryName,
            countryUnCode: voyageLocation?.location.countryUnCode
        };
    });

const calculateCargoTerms = (addActivityLocations: ReadonlyArray<AddActivityLocation>, calculation: LaytimeCalculation): ReadonlyArray<LaytimeCalculationCargoTerms> => {
    const cargoTermsByCargoId: { [cargoId: string]: LaytimeCalculationCargoTerms } = {};
    addActivityLocations
        .filter((a) => a.activity === "Load" || a.activity === "Discharge")
        .forEach((a) => {
            a.cargoes?.forEach((cargo) => {
                cargoTermsByCargoId[cargo.cargoId] = addOrReplaceLocation(
                    cargoTermsByCargoId[cargo.cargoId] ?? findCargoTerms(calculation.cargoTerms, cargo) ?? createCargoTerms(cargo),
                    `${a.activity.toLowerCase()}Locations`,
                    createLocation(cargo, a.locationId)
                );
            });
        });
    return R.values(cargoTermsByCargoId);
};

const addOrReplaceLocation = (cargoTerms: LaytimeCalculationCargoTerms, propertyName: string, location: LaytimeCalculationCargoTermsLocation) => {
    const existingLocations = cargoTerms[propertyName as keyof LaytimeCalculationCargoTerms] as ReadonlyArray<LaytimeCalculationCargoTermsLocation>;
    const index = existingLocations?.findIndex((l) => l.locationId === location.locationId);
    const newLocations = index >= 0 ? R.assocPath([index], location, existingLocations) : [...(existingLocations ?? []), location];
    return R.assoc(propertyName, newLocations, cargoTerms);
};

const findCargoTerms = (cargoTerms: ReadonlyArray<LaytimeCalculationCargoTerms>, cargo: AddActivityCargo) => {
    const result = cargoTerms.find((ct) => (ct.cargoId && cargo.cargoId ? ct.cargoId === cargo.cargoId : ct.productId === cargo.productId));
    return (
        result && {
            ...result,
            cargoId: result.cargoId ?? cargo.cargoId
        }
    );
};

const createCargoTerms = (cargo: AddActivityCargo) => ({
    id: createCargoTermId(),
    cargoId: cargo.cargoId,
    productId: cargo.productId,
    name: "",
    loadLocations: [] as ReadonlyArray<LaytimeCalculationCargoTermsLocation>,
    dischargeLocations: [] as ReadonlyArray<LaytimeCalculationCargoTermsLocation>
});

const createLocation = (cargo: AddActivityCargo, locationId: string): LaytimeCalculationCargoTermsLocation => ({
    locationId: locationId as LocationId,
    allowance: cargo.allowance,
    allowanceUnit: cargo.allowanceUnit,
    extraHours: cargo.extraHours,
    reversible: cargo.reversible,
    name: "",
    countryName: "",
    countryUnCode: ""
});

export const upsertCargoTermsToCalculation = ({ ltcId, cargoTerms }: ReturnType<typeof upsertCargoTermsAction>, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    laytimeCalculationHttpService.upsertCargoTerms(ltcId, cargoTerms.id, cargoTerms).pipe(
        map(() => upsertCargoTermsSuccessAction({ ltcId, cargoTerms })),
        catchError((error) => of(upsertCargoTermsFailAction({ ltcId, error })))
    );
