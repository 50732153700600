import { DateTime } from "luxon";

export enum NotificationType {
    Error,
    Warn,
    Success,
    Info,
    VersionUpdateMinor,
    VersionUpdateMajor
}

export class Notification {
    constructor(
        public title: string,
        public message: string,
        public type: NotificationType,
        public timeout: number = 0,
        public showLoadingAnimation = false,
        public createdDateTime?: DateTime,
        public dismissible?: boolean | undefined
    ) {}
}
