import { box, Boxed, unbox } from "ngrx-forms";
import { v4 as uuid } from "uuid";

import { deepCopy, Identity } from "@ops/shared";
import { CargoBerthActivityType, Enumeration, FreightRateUnit } from "@ops/shared/reference-data";

import { Nullable } from "../../../shared";
import { SeaNetLocation } from "../../shared/models/common/sea-net-location";
import { SuggestedExistingLocation } from "../../shared/models/common/suggested-existing-location";
import { Tolerance } from "../../shared/models/common/tolerance";
import { Cargo } from "../../shared/models/dtos/cargo.dto";
import { DateRange } from "../../shared/models/dtos/date-range.dto";
import { Destination } from "../../shared/models/dtos/destination.dto";
import { Division } from "../../shared/models/enums/division";
import { FixtureType } from "../../shared/models/enums/fixture-type";
import { QuantityUnit as QuantityUnitEnum } from "../../shared/models/enums/quantity-unit";

export type EnumerationType = Readonly<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    id: any;
    name: string;
    displayOrder?: number;
}>;

export type CargoId = Identity<string, "CargoId">;
export const createCargoId = (): CargoId => uuid() as CargoId;

export type CargoProduct = EnumerationType;
export type QuantityUnit = EnumerationType;
export type BaseFreightRate = Nullable<number>;
export type BaseFreightRateUnit = EnumerationType;
export type ToleranceFormUnit = EnumerationType;
export type ToleranceOption = EnumerationType;
export type OrderId = string;

export declare type CargoItem = Readonly<{
    cargoId: CargoId;
    name: string;
}>;

export type Location = Readonly<{
    location?: Boxed<SeaNetLocation | SuggestedExistingLocation>;
    eta?: Boxed<DateRange>;
}>;

export type ToleranceForm = Readonly<{
    min?: number;
    max?: number;
    unit: Boxed<ToleranceFormUnit>;
    option: Boxed<ToleranceOption>;
}>;

export type CargoForm = Readonly<{
    cargoId: CargoId;
    legacyCargoId: number;
    cargoProduct: Boxed<CargoProduct>;
    quantity: number;
    quantityUnit: Boxed<QuantityUnit>;
    tolerance?: ToleranceForm;
    baseFreightRate: BaseFreightRate;
    baseFreightRateUnit: Boxed<BaseFreightRateUnit>;
    itinerary?: string;
    details?: string;
    worldscaleRate?: number;
    orderId?: OrderId;
    loadLocation?: Location;
    dischargeLocation?: Location;
}>;

export const cargoForm = (fixtureType: FixtureType, division: Division, cargoId: CargoId, legacyCargoId: number): CargoForm => {
    let form: CargoForm = {
        cargoId,
        legacyCargoId,
        cargoProduct: box(null),
        baseFreightRate: null,
        baseFreightRateUnit: box(null),
        quantity: null,
        quantityUnit: box(null),
        details: null,
        itinerary: null
    };

    if (fixtureType === FixtureType.Voyage) {
        form = {
            ...form,
            tolerance: { min: null, max: null, unit: null, option: null }
        };
    }

    if (division === Division.specialisedProducts) {
        form = {
            ...form,
            orderId: null,
            loadLocation: { location: null, eta: box(null) },
            dischargeLocation: { location: null, eta: box(null) }
        };
    }

    if (division === Division.tankers) {
        form = {
            ...form,
            worldscaleRate: null
        };
    }

    return form;
};

export const toleranceToForm = (source: Tolerance): ToleranceForm => ({
    min: source.min,
    max: source.max,
    option: box(deepCopy(source.option)),
    unit: box(deepCopy(source.unit))
});

export const formToTolerance = (source: ToleranceForm): Tolerance => ({
    min: source.min,
    max: source.max,
    option: unbox(source.option),
    unit: unbox(source.unit)
});

export const cargoToForm = (fixtureType: FixtureType, division: Division, destinations: Destination[]) => (source: Cargo): CargoForm => {
    let form: CargoForm = {
        cargoId: source.id,
        legacyCargoId: source.cargoId,
        cargoProduct: box(deepCopy(source.cargoProduct)),
        quantity: source.quantity,
        quantityUnit: box(deepCopy(source.quantityUnit)),
        baseFreightRate: source.baseFreightRate,
        baseFreightRateUnit: box(deepCopy(source.baseFreightRateUnit)),
        itinerary: source.itinerary,
        details: source.details
    };

    if (fixtureType === FixtureType.Voyage) {
        form = {
            ...form,
            tolerance: source.tolerance
                ? toleranceToForm(source.tolerance)
                : toleranceToForm({
                    min: null,
                    max: null,
                    unit: null,
                    option: null
                })
        };
    }

    if (division === Division.specialisedProducts) {
        const locations = getCargoLocations(source.id, destinations);
        form = {
            ...form,
            orderId: source.orderId,
            loadLocation: locations.load,
            dischargeLocation: locations.discharge
        };
    }

    if (division === Division.tankers) {
        form = {
            ...form,
            worldscaleRate: source.worldscaleRate
        };
    }

    return form;
};

export const formToCargo = (source: CargoForm, current: Cargo): Cargo => ({
    ...current,
    id: source.cargoId,
    cargoId: source.legacyCargoId,
    cargoProduct: unbox(source.cargoProduct),
    quantity: source.quantity,
    quantityUnit: unbox(source.quantityUnit),
    tolerance: source.tolerance ? formToTolerance(source.tolerance) : undefined,
    baseFreightRate: source.baseFreightRate,
    baseFreightRateUnit: unbox(source.baseFreightRateUnit),
    itinerary: source.itinerary,
    details: source.details,
    worldscaleRate: source.worldscaleRate,
    orderId: source.orderId
});

const getCargoLocations = (cargoId: CargoId, destinations: Destination[]): { load: Location; discharge: Location } => {
    let locations: { load: Location; discharge: Location } = { load: null, discharge: null };

    if (destinations) {
        const load = findDestination(cargoId, CargoBerthActivityType.Load, destinations);
        const discharge = findDestination(cargoId, CargoBerthActivityType.Discharge, destinations);

        locations = {
            load: getLocation(load),
            discharge: getLocation(discharge)
        };
    }

    return locations;
};

const findDestination = (cargoId: CargoId, activityType: CargoBerthActivityType, destinations: Destination[]): Destination =>
    destinations.find(
        (dest) =>
            !!dest.berths.find((berth) =>
                berth.cargoBerthActivities.some((cba) => cba.type && cba.type.id === activityType.id && cba.associatedCargoes.some((cargo) => cargo.cargoId === cargoId))
            )
    );

const getLocation = (destination: Destination): Location => ({
    location: destination && destination.location && box(destination.location),
    eta: box(destination && destination.etaRange)
});

export function getCargoName(cargo: Cargo | CargoForm): string {
    const cargoProduct = unbox(cargo?.cargoProduct) as Enumeration | null | undefined;
    if (cargoProduct?.name) {
        if (cargo?.orderId) {
            return `${cargoProduct.name} - ${cargo.orderId}`;
        } else {
            return cargoProduct.name;
        }
    }
    return null;
}

/**
 * Calculates the freight spend (monetary value) of the cargo. If `cargo.baseFreightRateUnit` is not specified, the returned value will be `null`.
 *
 * When the `baseFreightRate` is `PerMT`, the `quantityUnit` must also be `MT` else `null` is returned.
 *
 * @param cargo The cargo with `baseFreightRate` populated.
 */
export const getBaseFreightSpend = (cargo: Cargo): number | null => {
    switch (cargo?.baseFreightRateUnit?.id) {
        case FreightRateUnit.LumpSum.id:
            return cargo.baseFreightRate;
        case FreightRateUnit.PerMT.id: {
            if (cargo.quantityUnit?.id === QuantityUnitEnum.MT) {
                return cargo.baseFreightRate * cargo.quantity;
            }

            return null;
        }
        case FreightRateUnit.Worldscale.id:
            throw Error("Worldscale not implemented");
        default:
            return null;
    }
};
