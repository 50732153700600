import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subject } from "rxjs";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";

import { AuthService, ConfigurationRole } from "@ops/core";

@Directive({
    selector: "[opsRole]"
})
export class RoleDirective implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();

    @Input() opsRole: ConfigurationRole;

    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private changeDetectorRef: ChangeDetectorRef,
        private authService: AuthService
    ) {}

    ngOnInit() {
        if (!this.opsRole) {
            throw new Error("OpsRoleDirective: Config Role is required");
        }

        this.authService
            .hasRole(this.opsRole)
            .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe((hasRole) => {
                if (hasRole) {
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainerRef.clear();
                }

                this.changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
