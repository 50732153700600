import { Injectable } from "@angular/core";
import { CommandHandler } from "src/app/fixture/mediator/command-handlers/command-handler";
import { Fixture } from "src/app/fixture/shared/models";
import { RemoveBunkerConsumptionCommand } from "./remove-bunker-consumption.command";

@Injectable({
    providedIn: "root"
})
export class RemoveBunkerConsumptionCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: RemoveBunkerConsumptionCommand) {
        const index = command.payload;
        if (index === undefined || index === null || isNaN(index)) {
            throw new Error("Given bunker consumption index is invalid");
        }

        fixture.cpSpeedAndConsumption.cpBunkersConsumption.splice(index, 1);
    }
}
