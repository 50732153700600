<div class="row no-gutters">
    <div class="col">
        <ops-cp-speed-and-consumption
            [parentForm]="speedAndConsumptionTabForm"
            [cpSpeedAndConsumption]="cpSpeedAndConsumption"
            (cpSpeedAndConsumptionUpdated)="updateCpSpeedAndConsumptionTab($event)"
        ></ops-cp-speed-and-consumption>

        <hr class="horizontal-line" />

        <div class="row no-gutters" *ngIf="form$ | async as form">
            <div class="form-group ml-auto order-12 ops-view-toggle p-inputswitch-labelled p-inputswitch-labelled--right">
                <label>Simple Consumption View</label>
                <p-inputSwitch
                    #toggle
                    [ngModel]="isSimpleMode"
                    [readonly]="readonly"
                    (onChange)="toggleMode($event.checked, toggle)"
                ></p-inputSwitch>
                <p-confirmDialog></p-confirmDialog>
            </div>

            <div class="form-group col-2" *opsFixtureType="'TimeCharter'">
                <label for="voyageId">TC Voyage Number</label>
                <ng-select
                    id="voyageId"
                    class="ops-select"
                    [items]="voyageNumbers$ | async"
                    [searchable]="false"
                    [clearable]="false"
                    [selectOnTab]="true"
                    [ngModel]="voyageId"
                    (change)="selectVoyage($event.voyageId)"
                    bindLabel="voyageNumber"
                    bindValue="voyageId"
                    data-test-voyage-number
                ></ng-select>
            </div>

            <div class="form-group col-2">
                <label for="cargoPercentage">% of Cargo<span class="has-icon icon--info-outline" ngbTooltip="% of cargo out of total cargo carried on vessel"></span></label>
                <input
                    id="cargoPercentage"
                    class="form-control text-right"
                    [ngrxFormControlState]="form.controls.cargoPercentage"
                    [opsNumber]="{ precision: 2, showTrailingZeros: false, minValue: 0, maxValue: 100 }"
                    [readonly]="readonly"
                    data-test-cargo-percentage
                />
                <ops-validation [form]="form.controls.cargoPercentage"></ops-validation>
            </div>

            <div class="form-group col-2">
                <label>Total Cargo Quantity Loaded (MT)</label>
                <input
                    class="form-control text-right"
                    [ngrxFormControlState]="form.controls.cargoQuantityLoadedMt"
                    [opsNumber]="{ precision: 3, showTrailingZeros: false, minimumFractionDigits: 3 }"
                    [readonly]="readonly"
                    data-test-cargo-quantity-loaded
                />
                <ops-validation [form]="form.controls.cargoQuantityLoadedMt"></ops-validation>
            </div>

            <div class="form-group col-2">
                <label>Vessel DWT</label>
                <input
                    class="form-control text-right"
                    [ngrxFormControlState]="form.controls.vesselDeadweight"
                    [opsNumber]="{ precision: 0 }"
                    [readonly]="readonly"
                    data-test-vessel-deadweight
                />
                <ops-validation [form]="form.controls.vesselDeadweight"></ops-validation>
            </div>

            <div class="form-group col-2">
                <label>Line of Business</label>
                <ng-select
                    class="ops-select"
                    [ngrxFormControlState]="form.controls.lineOfBusiness"
                    [items]="linesOfBusiness"
                    [searchable]="true"
                    [selectOnTab]="true"
                    [readonly]="readonly"
                    data-test-line-of-business
                ></ng-select>
                <ops-validation [form]="form.controls.lineOfBusiness"></ops-validation>
            </div>
        </div>

        <div class="row no-gutters scc-form" *ngIf="this.hasSccAccess$ | async"  [formGroup]="sccForm">
            <div class="form-group col-4">
                <label for="ownerEmail">Owner's Email</label>
                <input
                    id="ownerEmail"
                    class="form-control"
                    formControlName="ownerEmail"
                    [readonly]="readonly"
                    type="email"
                    data-test-owners-email
                />
                <ops-validation [form]="sccForm.controls.ownerEmail"></ops-validation>
            </div>

            <div class="form-group col-2">
                <label for="sccStatus">SCC Status<span class="has-icon icon--info-outline" [ngbTooltip]="sccStatusTooltip"></span></label>
                <input
                    id="sccStatus"
                    class="form-control"
                    [readonly]="true"
                    tabindex="-1"
                    data-test-scc-status
                />
                <ng-template #sccStatusTooltip>
                    <div class="scc-tooltip">
                        This status allows for the tracking of the SCC form through its lifecycle
                        <br /><br/>
                        <ul>
                            <li>Email sent</li>
                            <li>Received by Owner</li>
                            <li>Return with data</li>
                        </ul>
                    </div>
                </ng-template>
            </div>

            <div class="form-group col-2 scc-email-owner-section">
                <ops-action-button icon="file-email">Send SCC Form</ops-action-button>
                <label><span class="has-icon icon--info-outline" [ngbTooltip]="sccEmailButtonTooltip"></span></label>
                <ng-template #sccEmailButtonTooltip>
                    <div class="scc-tooltip">
                        Required fields to enable button:
                        <br /><br/>
                        <ul>
                            <li>A valid email address</li>
                            <li>Vessel Name</li>
                            <li>Charterer</li>
                            <li>Owner</li>
                            <li>CP Date</li>
                        </ul>
                        By clicking on this button, this will trigger a SCC form to be sent to the listed Owner’s email address
                    </div>
                </ng-template>
            </div>
        </div>

        <ops-voyage-bunker-consumption
            [readonly]="readonly"
            [isSimpleMode]="isSimpleMode"
            [parentForm]="speedAndConsumptionTabForm"
            [delivery]="delivery"
            [destinations]="destinations"
            [cpSpeedAndConsumption]="cpSpeedAndConsumption"
            [atSeaBunkersConsumption]="atSeaBunkersConsumption"
            (voyageBunkerConsumptionUpdated)="updateVoyageBunkerConsumptionTab($event)"
        ></ops-voyage-bunker-consumption>
    </div>
</div>
