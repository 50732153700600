import { createAction, on, On, props } from "@ngrx/store";
import { berthExpandedKey, BerthId, DestinationId, FixturesState } from "../../model";
import { currentVoyageStateExpansionReducer } from "../../voyage/reducer";

/* ACTIONS */
export const expandBerthAction = createAction("[Voyage Form] Expand Berth", props<{ destinationId: DestinationId; berthId: BerthId }>());
export const collapseBerthAction = createAction("[Voyage Form] Collapse Berth", props<{ destinationId: DestinationId; berthId: BerthId }>());

/* REDUCERS */
export const expandBerthReducer: On<FixturesState> = on(expandBerthAction, (state, action) =>
    currentVoyageStateExpansionReducer(state, berthExpandedKey(action.destinationId, action.berthId), true)
);
export const collapseBerthReducer: On<FixturesState> = on(collapseBerthAction, (state, action) =>
    currentVoyageStateExpansionReducer(state, berthExpandedKey(action.destinationId, action.berthId), false)
);
