<div class="side-panel" data-test-documents-panel>
    <span class="side-panel-title">
        Documents
        <div class="left-toolbar-expander" (click)="collapseLeftBar()">
            <div class="has-icon icon--close"></div>
        </div>
    </span>

    <div class="document-panel-wrapper">
        <div class="top-wrapper">
            <div class="upload-pane" [ngClass]="isFileOver ? 'file-over' : ''">
                <ngx-file-drop
                    contentClassName="file-drop"
                    dropZoneClassName="drop-zone"
                    (onFileDrop)="fileDropped($event)"
                    (onFileOver)="fileOver()"
                    (onFileLeave)="fileLeave()"
                >
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <div class="upload-icon has-icon icon--cloud-upload"></div>
                        <div class="upload-text">Drag and drop files to upload</div>
                        <div class="browse-wrapper">
                            <div class="browse-text">or</div>
                            <input hidden multiple #uploader type="file" (change)="onFileChange($event)" data-test-file-upload />
                            <input class="btn btn-primary" type="button" value="BROWSE" (click)="openFileSelector()" />
                        </div>
                        <div class="upload-max-size-text">[Max: {{ maxFileSize | fileSize }} per file]</div>
                    </ng-template>
                </ngx-file-drop>
            </div>
        </div>

        <div class="document-list">
            <div *ngIf="(isLoading$ | async)" class="loading-container">
                <ops-load-animation size="large" class="light-background loading"></ops-load-animation>
            </div>
            <div *ngIf="documents.length > 0 && !(isLoading$ | async)" class="documents-container">
                <ops-document *ngFor="let document of documents" [document]="document" (removeAction)="removeDocumentFromList($event)"></ops-document>
            </div>
            <div *ngIf="documents.length === 0 && !(isLoading$ | async)" class="no-documents-container">
                <ops-placeholder
                    [icon]="iconTemplate"
                    [title]="(loadingFailed$ | async) ? 'Could not connect to server' : 'No files have been uploaded'"
                    data-test-no-documents-placeholder
                >
                    <ng-template #iconTemplate>
                        <div class="ops-placeholder-icon-circle">
                            <span class="has-icon icon--lightbulb-outline" *ngIf="!(loadingFailed$ | async)"></span>
                            <span class="has-icon icon--withdrawn" *ngIf="(loadingFailed$ | async)"></span>
                        </div>
                    </ng-template>
                </ops-placeholder>
            </div>
        </div>
    </div>
</div>
