export function deepCopy<T>(obj: T): T {
    return deepCopyObject(obj);
}

export function deepCopyArray<T>(arr: Array<T>): Array<T> {
    const copy = new Array<T>();

    for (const item of arr) {
        if (item instanceof Date) {
            copy.push(item);
        } else if (item instanceof Array) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            copy.push(deepCopyArray(item) as any);
        } else if (typeof item === "object" && item !== null) {
            copy.push(deepCopyObject(item));
        } else {
            copy.push(item);
        }
    }

    return copy;
}

function deepCopyObject<T>(obj: T): T {
    if (!obj) {
        return obj;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const copy: any = {};

    const properties = Object.getOwnPropertyNames(obj);

    properties.forEach((name) => {
        // @ts-ignore
        const property = obj[name];

        if (property instanceof Date) {
            copy[name] = property;
        } else if (property instanceof Array) {
            copy[name] = deepCopyArray(property);
        } else if (typeof property === "object" && property !== null) {
            copy[name] = deepCopyObject(property);
        } else {
            copy[name] = property;
        }
    });

    return copy;
}
