<ng-select
    [readonly]="readonly || disabled"
    [items]="suggestions$ | async"
    [loading]="isLoading$ | async"
    [(ngModel)]="selection" bindLabel="displayName"
    (change)="onSelectionChange($event)"
    [selectOnTab]="true">
    <ng-template ng-label-tmp let-item="item">
        <span *ngIf="item.countryUnCode">
            <img [src]="getFlagImageUrl(item)" class="ops-location-flag" onerror="this.parent && this.parent.removeChild(this)" />
        </span>
        <span container="body" [ngbTooltip]="item.displayName" class="ng-value-label">
            <span>{{ item.displayName }}</span>
            <span *ngIf="!!item.countryName && item.countryName !== item.displayName"> ({{ item.countryName }})</span>
        </span>
        <button type="button" class="ng-value-icon has-icon icon--cancel" (click)="onClear(item)" (keyup.enter)="onClear(item)"></button>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div
            class="existing-locations-heading flex-row no-gutters w-100 d-flex"
            *ngIf="displayingExistingLocationSuggestions && getIndexOfSuggestion(item) === 0"
        >
            <span>Existing Voyage Locations</span>
        </div>
        <div class="location flex-row no-gutters w-100 d-flex">
            <img *ngIf="item.countryUnCode" class="flex-shrink-1 position-absolute ops-location-flag" [src]="getFlagImageUrl(item)" />
            <span class="display-name" [ngOptionHighlight]="search">{{ item.displayName }}</span>
            <span class="eta-range" *ngIf="item.etaRange">{{ item.etaRange | timezoneDateRange: "DD MMM YY, HH:mm":item.timeZone }}</span>
            <span class="country-name">{{ item.countryName || "-" }}</span>
            <span class="un-code" *ngIf="!item.etaRange">{{ item.unCode || item.countryUnCode || "-" }}</span>
            <span class="location-type">{{ item.locationType?.name }}</span>
        </div>
        <div class="aliases row no-gutters w-100">
            <span class="p-0" *ngFor="let alias of item.aliases; let i = index">
                <span class="p-0" [ngOptionHighlight]="search">{{ alias }}</span>
                <span class="p-0" *ngIf="i !== item.aliases.length - 1">,&nbsp;</span>
            </span>
        </div>
    </ng-template>
</ng-select>
