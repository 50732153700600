import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { toObjectMap } from "@ops/state";

import { VoyageHttpService } from "../../services";
import { Voyage } from "../../shared/models";
import { FixtureId, FixturesState, getDivision, getFixtureState, getFixtureType, getVoyageState, initialVoyageState } from "../model";
import { fixtureStateReducer } from "./reducer";

/* ACTIONS */
const LOAD_ACTION_NAME = "[Fixture Form] Load Voyages";

export const loadVoyagesAction = createAction(LOAD_ACTION_NAME, props<{ fixtureId: FixtureId }>());
export const loadVoyagesSuccessAction = createAction(`${LOAD_ACTION_NAME} Success`, props<{ fixtureId: FixtureId; voyages: ReadonlyArray<Voyage> }>());
export const loadVoyagesFailAction = createAction(`${LOAD_ACTION_NAME} Fail`, props<{ fixtureId: FixtureId; error: Error }>());

const RELOAD_ACTION_NAME = "[Fixture Form] Reload Voyages";

export const reloadVoyagesAction = createAction(RELOAD_ACTION_NAME, props<{ fixtureId: FixtureId }>());
export const reloadVoyagesSuccessAction = createAction(`${RELOAD_ACTION_NAME} Success`, props<{ fixtureId: FixtureId; voyages: ReadonlyArray<Voyage> }>());
export const reloadVoyagesFailAction = createAction(`${RELOAD_ACTION_NAME} Fail`, props<{ fixtureId: FixtureId; error: Error }>());

/* REDUCERS */
export const loadVoyagesReducer: On<FixturesState> = on(loadVoyagesAction, reloadVoyagesAction, (state, action) =>
    fixtureStateReducer(state, action.fixtureId, { voyagesLoadStatus: "loading" })
);

export const loadVoyagesSuccessReducer: On<FixturesState> = on(loadVoyagesSuccessAction, reloadVoyagesSuccessAction, (state, action) => {
    const fixtureState = getFixtureState(state, action.fixtureId);

    if (!fixtureState || !fixtureState.fixture) {
        return state;
    }

    const fixtureType = getFixtureType(fixtureState);
    const division = getDivision(fixtureState);
    const voyageIds = action.voyages.map((x) => x.voyageId);
    const voyageId = state.currentVoyageId && voyageIds.includes(state.currentVoyageId) ? state.currentVoyageId : voyageIds[0];

    return <FixturesState>{
        ...fixtureStateReducer(state, action.fixtureId, {
            voyagesLoadStatus: "loaded",
            voyageIds: voyageIds
        }),
        // Replace the entire voyage state map, we may want to change this in the future
        voyages: toObjectMap(action.voyages, "voyageId", (voyage) => {
            const voyageState = initialVoyageState(voyage, fixtureType, division);
            const currentVoyageState = getVoyageState(state, voyage.voyageId);

            if (!currentVoyageState) {
                return voyageState;
            }

            return {
                ...voyageState,
                expandedSections: {
                    ...voyageState.expandedSections,
                    ...currentVoyageState.expandedSections
                }
            };
        }),
        currentVoyageId: voyageId
    };
});

export const loadVoyagesFailReducer: On<FixturesState> = on(loadVoyagesFailAction, reloadVoyagesFailAction, (state, action) =>
    fixtureStateReducer(state, action.fixtureId, { voyagesLoadStatus: "failed" })
);

/* EFFECTS */
export const loadVoyagesEffect$ = (actions$: Actions, voyageService: VoyageHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(loadVoyagesAction),
            switchMap(({ fixtureId }) =>
                voyageService.getAll(fixtureId).pipe(
                    map((voyages: Voyage[]) => loadVoyagesSuccessAction({ fixtureId: fixtureId, voyages: voyages })),
                    catchError((error) => of(loadVoyagesFailAction({ fixtureId: fixtureId, error: error })))
                )
            )
        )
    );

export const reloadVoyagesEffect$ = (actions$: Actions, voyageService: VoyageHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(reloadVoyagesAction),
            switchMap(({ fixtureId }) =>
                voyageService.getAll(fixtureId).pipe(
                    map((voyages: Voyage[]) => reloadVoyagesSuccessAction({ fixtureId: fixtureId, voyages: voyages })),
                    catchError((error) => of(reloadVoyagesFailAction({ fixtureId: fixtureId, error: error })))
                )
            )
        )
    );

