import { On } from "@ngrx/store";

import { Boxed, formGroupReducer, SetValueAction, unbox } from "ngrx-forms";

import { hasValue } from "@ops/state";

import {
    ActivityType,
    FixturesState,
    getDefaultLaytimeEvents,
    getDivision,
    getFixtureType,
    VoyageState
} from "../../model";
import { voyageStateReducer } from "../../voyage/reducer";

/**
 * Prefills laytime events when the activity is set/changed.
 */
export const prefillLaytimeEventsReducer: On<FixturesState> = {
    types: [SetValueAction.TYPE],
    reducer: (state: FixturesState, action: SetValueAction<Boxed<ActivityType>>): FixturesState => {
        const controlPath = action.controlId.split(".");
        if (controlPath.length < 2 || controlPath[controlPath.length - 3] !== "activities" || controlPath[controlPath.length - 1] !== "type") {
            return state;
        }

        return voyageStateReducer(state, controlPath[0], (voyageState, fixtureState) => {
            const berthIndex = Number(controlPath[4]);
            const activityIndex = Number(controlPath[6]);

            const destinationForm = voyageState.form.value.destinations[Number(controlPath[2])];
            const berthForm = destinationForm.berths[berthIndex];
            const activityForm = berthForm.activities[Number(controlPath[6])];

            const activityType = unbox(activityForm.type);
            const newActivityType = unbox(action.value);
            const hasActivityType = hasValue(activityType);
            const hasNewActivityType = hasValue(newActivityType);

            if (hasActivityType === hasNewActivityType && activityType.id === newActivityType.id) {
                return voyageState;
            }

            const location = unbox(destinationForm.location);
            const timeZone = (location ? location.timeZone : null) || "utc";

            const laytimeEvents = getDefaultLaytimeEvents(
                getFixtureType(fixtureState),
                getDivision(fixtureState),
                newActivityType,
                berthIndex === 0 && activityIndex === 0,
                destinationForm.arrivalDateTime,
                timeZone
            );

            controlPath[controlPath.length - 1] = "laytimeEvents";

            const laytimeEventsControlPath = controlPath.join(".");

            return <VoyageState>{
                ...voyageState,
                form: formGroupReducer(voyageState.form, new SetValueAction(laytimeEventsControlPath, laytimeEvents))
            };
        });
    }
};
