import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AppConfigService, AppInsightsService } from "@ops/core";

import { DocumentDto } from "../shared/document.dto";
import { ResponseStatusMessage } from "../shared/response-status-message";

@Injectable({
    providedIn: "root"
})
export class DocumentHttpService {
    private readonly documentServiceUrl: string;

    constructor(private httpClient: HttpClient, private appInsightsMonitoringService: AppInsightsService, appConfigService: AppConfigService) {
        this.documentServiceUrl = appConfigService.config.documentServiceUrl;
    }

    get(fixtureId: string): Observable<DocumentDto[]> {
        return this.httpClient.get<DocumentDto[]>(`${this.documentServiceUrl}/api/v1.0/fixture/${fixtureId}/documents`);
    }

    upload(fixtureId: string, document: File): Observable<ResponseStatusMessage> {
        this.appInsightsMonitoringService.logInfo(`document uploaded, document: ${document}, fixtureId: ${fixtureId}`);
        const formData = new FormData();
        formData.append("file", document, document.name);
        return this.httpClient
            .post(`${this.documentServiceUrl}/api/v1.0/fixture/${fixtureId}/documents`, formData, {
                reportProgress: true,
                observe: "events"
            })
            .pipe(map((event) => this.handleEvent(event)));
    }

    download(fixtureId: string, documentId: string): Observable<ResponseStatusMessage> {
        this.appInsightsMonitoringService.logInfo(`document downloaded, documentId: ${documentId}, fixtureId: ${fixtureId}`);
        return this.httpClient
            .get(`${this.documentServiceUrl}/api/v1.0/fixture/${fixtureId}/documents/${documentId}/file`, {
                responseType: "blob",
                reportProgress: true,
                observe: "events"
            })
            .pipe(map((event) => this.handleEvent(event)));
    }

    downloadDocumentation(fileId: number): Observable<HttpResponse<Blob>> {
        this.appInsightsMonitoringService.logInfo(`documentation downloaded, fileId: ${fileId}`);
        return this.httpClient.get(`${this.documentServiceUrl}/api/v1.0/documentation/${fileId}`, {
            responseType: "blob",
            observe: "response"
        });
    }

    delete(fixtureId: string, documentId: string): Observable<any> {
        this.appInsightsMonitoringService.logInfo(`document deleted, documentId: ${documentId}, fixtureId: ${fixtureId}`);
        return this.httpClient.delete(`${this.documentServiceUrl}/api/v1.0/fixture/${fixtureId}/documents/${documentId}`);
    }

    addPermission(fixtureId: string, documentId: string, permission: string): Observable<any> {
        this.appInsightsMonitoringService.logInfo(`document permission added, permission: ${permission}, documentId: ${documentId}, fixtureId: ${fixtureId}`);
        return this.httpClient.post(`${this.documentServiceUrl}/api/v1.0/fixture/${fixtureId}/documents/${documentId}/permissions`, { permission });
    }

    deletePermission(fixtureId: string, documentId: string, permission: string): Observable<any> {
        this.appInsightsMonitoringService.logInfo(`document permission deleted, permission: ${permission}, documentId: ${documentId}, fixtureId: ${fixtureId}`);
        return this.httpClient.delete(`${this.documentServiceUrl}/api/v1.0/fixture/${fixtureId}/documents/${documentId}/permissions/${permission}`);
    }

    private handleEvent(event: HttpEvent<any>): ResponseStatusMessage {
        switch (event.type) {
            case HttpEventType.Sent:
                return { status: "sent" };
            case HttpEventType.DownloadProgress:
            case HttpEventType.UploadProgress:
                const progress = Math.round((100 * event.loaded) / event.total);
                return { status: "progress", message: progress };
            case HttpEventType.Response:
                return { status: "complete", message: event.body };
            default:
                return { status: "unhandled-event", message: event };
        }
    }
}
