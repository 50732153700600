import { Injectable } from "@angular/core";

import { isNullOrUndefined } from "@ops/shared";

import { Command, CommandHandler } from "../../../../mediator";
import { Voyage } from "../../../../shared/models";
import { AtSeaBunkerConsumptionId } from "../../../../shared/models/dtos/at-sea-bunker-consumption";
import { BunkerConsumed } from "../../../../shared/models/dtos/bunker-consumed";
import { BunkerConsumedFormModel } from "../../../../shared/models/form-models/bunker-consumed.model";

export class UpdateAtSeaBunkersConsumedCommand extends Command {
    constructor(readonly atSeaBunkerConsumptionId: AtSeaBunkerConsumptionId, readonly bunkerConsumed: BunkerConsumedFormModel[]) {
        super(UpdateAtSeaBunkersConsumedCommandHandler, null);
    }
}

@Injectable({
    providedIn: "root"
})
export class UpdateAtSeaBunkersConsumedCommandHandler implements CommandHandler {
    handle(voyage: Voyage, { atSeaBunkerConsumptionId, bunkerConsumed }: UpdateAtSeaBunkersConsumedCommand) {
        if (isNullOrUndefined(atSeaBunkerConsumptionId)) {
            throw new Error("Invalid command payload - missing atSeaBunkerConsumptionId");
        }

        if (isNullOrUndefined(bunkerConsumed)) {
            throw new Error("Invalid command payload - missing bunkerConsumed");
        }

        const atSeaBunkerConsumption = voyage.atSeaBunkersConsumption.find((x) => x.id === atSeaBunkerConsumptionId);

        if (isNullOrUndefined(atSeaBunkerConsumption)) {
            throw new Error(`AtSeaBunkerConsumption with Id=${atSeaBunkerConsumptionId} does not exist`);
        }

        atSeaBunkerConsumption.bunkersConsumed = this.mapBunkerConsumed(bunkerConsumed);
    }

    private mapBunkerConsumed(bunkerConsumed: BunkerConsumedFormModel[]) {
        return bunkerConsumed.map(
            (model: BunkerConsumedFormModel) =>
                <BunkerConsumed>{
                    quantityMt: model.quantityConsumed,
                    pricePerMt: model.pricePerMt,
                    type: model.bunkerType
                }
        );
    }
}
