<ng-select
    class="form-control"
    [items]="suggestions$ | async"
    [loading]="isLoading$ | async"
    [(ngModel)]="selection"
    [readonly]="disabled"
    bindLabel="vesselName"
    (change)="onSelectionChange($event)"
    [selectOnTab]="true"
>
    <ng-template ng-header-tmp>
        <div>
            <span class="item-header header-vessel">Vessel Name</span>
            <span class="item-header item-charterer">Charterer</span>
            <span class="item-header item-cp">CP Date</span>
            <span class="item-header item-fixture-ref">Fixture Ref</span>
        </div>
    </ng-template>

    <ng-template ng-label-tmp let-item="item" let-index="index" let-clear="clear">
        <span container="body" [ngbTooltip]="item.fixtureNumber" class="ng-value-label"
            >{{ item.vesselName }}, {{ item.charterer }}, {{ item.charterPartyDate | date: "dd MMM yy" }}
        </span>
        <button type="button" class="ng-value-icon has-icon icon--cancel" (click)="clear(item)" aria-hidden="true"></button>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div>
            <span class=" item-detail item-vessel" [ngOptionHighlight]="search">{{ item.vesselName }}</span>
            <span class=" item-detail item-charterer"> {{ item.charterer }}</span>
            <span class=" item-detail item-cp"> {{ item.charterPartyDate | date: "dd MMM yy" }}</span>
            <span class=" item-detail item-fixture-ref"> {{ item.fixtureNumber }}</span>
        </div>
        <div class="item-laycan">
            <span>Laycan From: {{ item.laycanDateFrom | date: "dd/MM/yy, hh:mm" }}</span>
            <span> To: {{ item.laycanDateTo | date: "dd/MM/yy, hh:mm" }}</span>
        </div>
    </ng-template>
</ng-select>
