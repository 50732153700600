import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "ops-section-header",
  templateUrl: "./section-header.component.html",
  styleUrls: ["./section-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionHeaderComponent {
    @Input() expanded: boolean;
    @Output() readonly expand = new EventEmitter();
    @Output() readonly collapse = new EventEmitter();

    toggle() {
        if (this.expanded) {
            this.collapse.emit();
        } else {
            this.expand.emit();
        }
    }
}
