import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";

import { AuthService } from "../../core";
import { dateToISOString, parseISODate } from "../../shared/date-utils/date-utilities";
import { deepFreeze } from "../../shared/deep-freeze";
import { deepCopy } from "../../shared/utils/deep-copy";
import { DemurrageItem } from "../shared/models";
import { CounterClaimDemurrageItemModel } from "../shared/models/dtos/counter-claim-demurrage-item.dto";
import { DemurrageItemRequestModel } from "../shared/models/dtos/demurrage-item-request-model.dto";
import { FixtureDemurrages } from "../shared/models/dtos/fixture-demurrages.dto";
import { DemurrageItemFormModel } from "../shared/models/form-models/demurrage-item.model";
import { DemurrageItemHttpService } from "./demurrage-item-http.service";

@Injectable({
    providedIn: "root"
})
export class DemurrageItemDataService {
    private _workingDemurrages: FixtureDemurrages;
    private _currentDemurrages$: Subject<FixtureDemurrages>;

    numberOfDemurrageClaims$: BehaviorSubject<number>;

    get currentDemurrageItems$(): Observable<FixtureDemurrages> {
        return this._currentDemurrages$.asObservable();
    }

    constructor(private demurrageItemHttpService: DemurrageItemHttpService, private authService: AuthService) {
        this._currentDemurrages$ = new Subject<FixtureDemurrages>();
        this.numberOfDemurrageClaims$ = new BehaviorSubject(0);
    }

    updateDemurrages(demurrageModels: DemurrageItemFormModel[]): Observable<any> {
        const observables: Observable<any>[] = [];

        demurrageModels.forEach((demurrageModel) => {
            const demurrageItem = this.mapDemurrageItem(demurrageModel);
            if (demurrageModel.valid && demurrageModel.dirty) {
                const updateObservable = this.demurrageItemHttpService.update(this._workingDemurrages.fixtureId, demurrageItem).pipe(tap(this.updateDemurrageItem));
                observables.push(updateObservable);
            }
        });

        return forkJoin(observables).pipe(tap(() => this.publishDemurrageItems(this._workingDemurrages)));
    }

    createDemurrageItem(demurrageModel: DemurrageItemFormModel): Observable<any> {
        const demurrageItem = this.mapDemurrageItem(demurrageModel);

        return this.demurrageItemHttpService.create(this._workingDemurrages.fixtureId, demurrageItem).pipe(
            tap((newDemurrageItem) => {
                this._workingDemurrages.demurrageItems.push(newDemurrageItem);
                this.publishDemurrageItems(this._workingDemurrages);
            })
        );
    }

    load(fixtureId: string) {
        this.demurrageItemHttpService.get(fixtureId).subscribe((serverDemurrageItems) => {
            const demurrageItemsArray: DemurrageItem[] = [];

            serverDemurrageItems.forEach((serverDemurrageItem) => {
                const demurrageItem = demurrageItemsArray.find((localDemurrageItem) => localDemurrageItem.demurrageItemId === serverDemurrageItem.demurrageItemId);

                if (!demurrageItem) {
                    demurrageItemsArray.push(serverDemurrageItem);
                } else if (parseISODate(demurrageItem.lastUpdatedDate).getTime() < parseISODate(serverDemurrageItem.lastUpdatedDate).getTime()) {
                    const index = demurrageItemsArray.indexOf(demurrageItem);
                    demurrageItemsArray[index] = serverDemurrageItem;
                }
            });

            const fixtureDemurrages = {
                fixtureId,
                demurrageItems: demurrageItemsArray
            };

            this.publishDemurrageItems(fixtureDemurrages);
        });
    }

    deleteDemurrageItem(demurrageItemId: string): Observable<any> {
        return this.demurrageItemHttpService.delete(this._workingDemurrages.fixtureId, demurrageItemId).pipe(
            tap(() => {
                const indexToRemove = this._workingDemurrages.demurrageItems.findIndex((x) => x.demurrageItemId === demurrageItemId);
                this._workingDemurrages.demurrageItems.splice(indexToRemove, 1);
                this.publishDemurrageItems(this._workingDemurrages);
            })
        );
    }

    submit(fixtureId: string, demurrageItemId: string, submitRequest: DemurrageItemRequestModel): Observable<any> {
        return this.demurrageItemHttpService.submit(fixtureId, demurrageItemId, submitRequest).pipe(tap(this.updateDemurrageItemAndPublish));
    }

    counter(fixtureId: string, demurrageItemId: string, counterRequest: CounterClaimDemurrageItemModel): Observable<any> {
        return this.demurrageItemHttpService.counter(fixtureId, demurrageItemId, counterRequest).pipe(tap(this.updateDemurrageItemAndPublish));
    }

    accept(fixtureId: string, demurrageItemId: string, acceptRequest: DemurrageItemRequestModel): Observable<any> {
        return this.demurrageItemHttpService.accept(fixtureId, demurrageItemId, acceptRequest).pipe(tap(this.updateDemurrageItemAndPublish));
    }

    withdraw(fixtureId: string, demurrageItemId: string, withdrawRequest: DemurrageItemRequestModel): Observable<any> {
        return this.demurrageItemHttpService.withdraw(fixtureId, demurrageItemId, withdrawRequest).pipe(tap(this.updateDemurrageItemAndPublish));
    }

    private publishDemurrageItems(fixtureDemurrages: FixtureDemurrages) {
        this._workingDemurrages = fixtureDemurrages;
        const immutableDemurrages = deepFreeze(deepCopy(fixtureDemurrages)) as FixtureDemurrages;
        this._currentDemurrages$.next(immutableDemurrages);
        this.numberOfDemurrageClaims$.next(immutableDemurrages.demurrageItems.length);
    }

    private mapDemurrageItem(demurrageItemModel: DemurrageItemFormModel): DemurrageItem {
        let demurrageItem = this._workingDemurrages.demurrageItems.find((x) => x.demurrageItemId === demurrageItemModel.demurrageItemId);

        if (!demurrageItem) {
            demurrageItem = <DemurrageItem>{};
            demurrageItemModel.lastUpdatedByUser = this.authService.user;
            demurrageItemModel.lastUpdatedDate = new Date();
        }

        if (demurrageItemModel.dirty) {
            demurrageItemModel.lastUpdatedByUser = this.authService.user;
            demurrageItemModel.lastUpdatedDate = new Date();
        }

        demurrageItem.demurrageItemId = demurrageItemModel.demurrageItemId;
        demurrageItem.type = demurrageItemModel.type;
        demurrageItem.status = demurrageItemModel.status;
        demurrageItem.currency = demurrageItemModel.currency;
        demurrageItem.receivedFromOwnerDate = dateToISOString(demurrageItemModel.receivedFromOwnerDate);
        demurrageItem.comments = demurrageItemModel.comments;
        demurrageItem.ownerInvoiceNumber = demurrageItemModel.ownerInvoiceNumber;
        demurrageItem.currentClaimValue = demurrageItemModel.claimValue;
        demurrageItem.initialClaimValue = demurrageItemModel.initialClaimValue;
        demurrageItem.estimatedClaimValue = demurrageItemModel.estimatedClaimValue;
        demurrageItem.lastUpdatedByUser = demurrageItemModel.lastUpdatedByUser;
        demurrageItem.lastUpdatedDate = dateToISOString(demurrageItemModel.lastUpdatedDate);
        demurrageItem.lastContactedDate = dateToISOString(demurrageItemModel.lastContactedDate);
        demurrageItem.chartererAcknowledgedReceiptDate = dateToISOString(demurrageItemModel.chartererAcknowledgedReceiptDate);
        demurrageItem.sentToChartererDate = dateToISOString(demurrageItemModel.sentToChartererDate);
        demurrageItem.agreedDate = dateToISOString(demurrageItemModel.agreedDate);
        demurrageItem.paidDate = dateToISOString(demurrageItemModel.paidDate);
        demurrageItem.commissionDate = dateToISOString(demurrageItemModel.commissionDate);

        demurrageItem.berthId = demurrageItemModel.berth ? demurrageItemModel.berth.berthId : null;
        demurrageItem.destinationId = demurrageItemModel.berth ? demurrageItemModel.berth.destinationId : null;

        return demurrageItem;
    }

    private updateDemurrageItemAndPublish = (updatedDemurrageItem: any) => {
        this.updateDemurrageItem(updatedDemurrageItem);
        this.publishDemurrageItems(this._workingDemurrages);
    };

    private updateDemurrageItem = (updatedDemurrageItem: any) => {
        const demurrageItem = this._workingDemurrages.demurrageItems.find((x) => x.demurrageItemId === updatedDemurrageItem.demurrageItemId);
        const demurrageItemIndex = this._workingDemurrages.demurrageItems.indexOf(demurrageItem);
        this._workingDemurrages.demurrageItems[demurrageItemIndex] = updatedDemurrageItem;
    };
}
