/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { createSelector } from "@ngrx/store";
import { DateTime } from "luxon";
import { FormArrayState, FormGroupState, unbox } from "ngrx-forms";
import * as R from "ramda";

import { isNumber } from "@ops/shared";
import { LaytimeEventType } from "@ops/shared/reference-data";
import { getEditingId } from "@ops/state";

import { Division } from "../../shared/models";
import { AssociatedCargoExpenseUnit } from "../../shared/models/enums/associated-cargo-expense-unit";
import { QuantityUnit } from "../../shared/models/enums/quantity-unit";
import { selectCurrentDestinationActivityForm, selectCurrentDestinationActivityFormValue } from "../activities";
import { BerthSummary, selectCurrentVoyageBerths } from "../berths";
import { selectCurrentVoyageCargoFormValues } from "../cargoes";
import { selectCurrentFixtureDivision } from "../fixture";
import {
    ActivityForm,
    ActivityId,
    associatedCargoExpandedKey,
    AssociatedCargoExpenseForm,
    AssociatedCargoExpenseId,
    AssociatedCargoForm,
    AssociatedCargoId,
    BerthId,
    CargoForm,
    CargoId,
    DestinationId,
    FixtureFeatureState,
    getCargoName,
    LaytimeEventForm,
    VoyageExpandedSections
} from "../model";
import { selectCurrentVoyageExpandedSections } from "../voyage";

export type BerthItem = Readonly<{
    berthId: BerthId;
    summary: BerthSummary;
}>;

export type AssociatedCargoState = Readonly<{
    associatedCargoId: AssociatedCargoId;
    totalFreightSpend: number;
    pumpingRate: number;
    cargoName: string;
    estimatedQuantity: number;
    estimatedQuantityUnit: string;
    freightRateUnit: string;
    form: FormGroupState<AssociatedCargoForm>;
    expenses: ReadonlyArray<AssociatedCargoExpenseState>;
    expanded: boolean;
    canMove: boolean;
    isEditing: boolean;
}>;

export type AssociatedCargoExpenseState = Readonly<{
    associatedCargoExpenseId: AssociatedCargoExpenseId;
    form: FormGroupState<AssociatedCargoExpenseForm>;
    freightSpend: number;
    canAdd: boolean;
    isEditing: boolean;
}>;

export const selectCurrentVoyageAssociatedCargoForms = createSelector(selectCurrentDestinationActivityForm, (activity) => (activity ? activity.controls.associatedCargoes : null));

export const selectCurrentVoyageBerthList = createSelector(
    selectCurrentVoyageBerths,
    (berths) => berths && berths.map((b) => <BerthItem>{ berthId: b.berthId, summary: b.summary })
);

export const selectCurrentVoyageAssociatedCargoes = createSelector<
    FixtureFeatureState,
    { destinationId: DestinationId; berthId: BerthId; activityId: ActivityId },
    ActivityForm,
    ReadonlyArray<CargoForm>,
    FormArrayState<AssociatedCargoForm>,
    VoyageExpandedSections,
    Division,
    ReadonlyArray<AssociatedCargoState>
>(
    selectCurrentDestinationActivityFormValue,
    selectCurrentVoyageCargoFormValues,
    selectCurrentVoyageAssociatedCargoForms,
    selectCurrentVoyageExpandedSections,
    selectCurrentFixtureDivision,
    (activity, cargoes, accForms, expandedSections, division, { destinationId, berthId, activityId }) => {
        if (!accForms) {
            return null;
        }

        const pumpingRates = calcPumpingRates(activity);

        return accForms.controls.map((associatedCargo) => {
            const cargo = cargoes.find((c) => c.cargoId === associatedCargo.value.cargoId);

            return <AssociatedCargoState>{
                associatedCargoId: associatedCargo.value.associatedCargoId,
                cargoName: getCargoName(cargo),
                estimatedQuantity: getCargoQuantity(cargo),
                estimatedQuantityUnit: getCargoQuantityUnit(cargo),
                freightRateUnit: getCargoFreightRateUnit(cargo),
                form: associatedCargo,
                expanded: expandedSections[associatedCargoExpandedKey(destinationId, berthId, activityId, associatedCargo.value.associatedCargoId)],
                totalFreightSpend: calculateTotalFreightSpend(associatedCargo.value.associatedCargoExpenses),
                pumpingRate: pumpingRates[associatedCargo.value.cargoId],
                expenses: getAssociatedCargoExpenses(associatedCargo),
                canMove: division === Division.specialisedProducts,
                isEditing: getEditingId(accForms) === associatedCargo.id
            };
        });
    }
);

function getAssociatedCargoExpenses(associatedCargo: FormGroupState<AssociatedCargoForm>) {
    const expensesForm = associatedCargo.controls.associatedCargoExpenses;

    return expensesForm.controls.map(
        (expenseForm) =>
            <AssociatedCargoExpenseState>{
                associatedCargoExpenseId: expenseForm.value.associatedCargoExpenseId,
                form: expenseForm,
                freightSpend: calcFreightSpend(expenseForm.value),
                canAdd: true,
                isEditing: getEditingId(expensesForm) === expenseForm.id || expensesForm.controls.length === 1
            }
    );
}

function getCargoQuantityUnit(cargo: CargoForm) {
    if (cargo && cargo.quantityUnit) {
        const unit = unbox(cargo.quantityUnit);
        return unit && unit.name;
    }
    return null;
}

function getCargoFreightRateUnit(cargo: CargoForm) {
    if (cargo && cargo.baseFreightRateUnit) {
        const unit = unbox(cargo.baseFreightRateUnit);
        return unit && unit.name;
    }
    return null;
}

function getCargoQuantity(cargo: CargoForm) {
    if (cargo) {
        return cargo.quantity;
    }
    return null;
}

function calcFreightSpend(expense: AssociatedCargoExpenseForm) {
    if (!unbox(expense.unit) || !isNumber(expense.value)) {
        return null;
    }

    if (expense.unit.value && expense.unit.value.id === AssociatedCargoExpenseUnit.LumpSum) {
        return expense.value;
    }

    if (!isNumber(expense.freightRate)) {
        return null;
    }

    return expense.freightRate * expense.value;
}

function calculateTotalFreightSpend(expenses: ReadonlyArray<AssociatedCargoExpenseForm>) {
    return expenses.reduce((total, current) => total + calcFreightSpend(current) || 0, 0);
}

function calcPumpingRates(activity: ActivityForm): { [cargoId: string]: number } {
    if (!activity) {
        return null;
    }
    const pumpingRates: { [cargoId: string]: number } = {};

    if (!activity.associatedCargoes || !activity.laytimeEvents) {
        return pumpingRates;
    }

    return R.uniqBy((ac) => ac.cargoId, activity.associatedCargoes).reduce(
        (acc, current) => ({
            ...acc,
            [current.cargoId]: calcPumpingRate(activity, current.cargoId)
        }),
        pumpingRates
    );
}

function calcPumpingRate(activity: ActivityForm, cargoId: CargoId) {
    const quantity = calcQuantity(activity, cargoId);
    if (!isNumber(quantity)) {
        return null;
    }

    const durationInHours = calcTotalTimeTaken(activity.laytimeEvents, cargoId);
    if (!durationInHours || durationInHours <= 0) {
        return null;
    }

    return quantity / durationInHours;
}

function calcTotalTimeTaken(events: ReadonlyArray<LaytimeEventForm>, cargoId: CargoId) {
    return calcOperationalTime(events, cargoId) - calcSuspendedTime(events, cargoId);
}

function calcOperationalTime(events: ReadonlyArray<LaytimeEventForm>, cargoId: CargoId) {
    return calcTimeTakenForEventPair(events, LaytimeEventType.CargoCommenced, LaytimeEventType.CargoCompleted, cargoId);
}

function calcSuspendedTime(events: ReadonlyArray<LaytimeEventForm>, cargoId: CargoId) {
    return (
        calcTimeTakenForEventPair(events, LaytimeEventType.LoadingSuspended, LaytimeEventType.LoadingCommenced, cargoId) +
        calcTimeTakenForEventPair(events, LaytimeEventType.DischargeSuspended, LaytimeEventType.DischargeCommenced, cargoId)
    );
}

function calcTimeTakenForEventPair(events: ReadonlyArray<LaytimeEventForm>, fromEventType: LaytimeEventType, toEventType: LaytimeEventType, cargoId: CargoId) {
    const filteredEvents = (events || [])
        .filter((x) => x.type && [fromEventType, toEventType].some((e) => e.id === x.type.value.id && !!x.eventDate))
        .filter((x) => !x.cargoId || !cargoId || x.cargoId === cargoId);

    let timeTaken = 0;
    for (let i = 0; i < filteredEvents.length - 1; i = i += 2) {
        if (filteredEvents[i].type.value.id === fromEventType.id && filteredEvents[i + 1].type.value.id === toEventType.id) {
            timeTaken += DateTime.fromISO(filteredEvents[i + 1].eventDate)
                .diff(DateTime.fromISO(filteredEvents[i].eventDate))
                .as("hours");
        }
    }
    return timeTaken;
}

function calcQuantity(activity: ActivityForm, cargoId: CargoId) {
    const quantities = (activity.associatedCargoes || [])
        .filter((x) => x.quantityUnit && x.quantityUnit.value)
        .filter((x) => !x.cargoId || !cargoId || x.cargoId === cargoId)
        .map((x) => (x.quantityUnit && x.quantityUnit.value && x.quantityUnit.value.id === QuantityUnit.MT ? x.quantity : x.mt));

    if (quantities.every((x) => !isNumber(x))) {
        return null;
    }

    return quantities.filter((x) => isNumber(x)).reduce((total, current) => total + current, 0);
}
