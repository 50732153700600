import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { FixtureLaytimeCalculation, LtcId } from "../../state";

@Component({
    selector: "ops-laytime-calculation-list",
    templateUrl: "./laytime-calculation-list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationListComponent {
    @Input() calculations: ReadonlyArray<FixtureLaytimeCalculation>;

    @Output() readonly remove = new EventEmitter<LtcId>();

    trackBy(_index: number, item: FixtureLaytimeCalculation) {
        return item.id;
    }
}
