import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MaritimeDateRange } from "@maritech/maritime-date";
import { DateTime } from "luxon";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { VoyageDataService } from "../../fixture/services/voyage-data.service";
import { Berth, Cargo, Destination, Voyage } from "../../fixture/shared/models";
import { convertTimeZone, parseISODate } from "../../shared/date-utils/date-utilities";
import { LeftBarStateService } from "../left-bar-state.service";
import { VoyageStatus } from "../shared/models/voyage-status-type.model";
import { TcVoyageModel } from "./shared/tc-voyage.model";
import { TcVoyageComponent } from "./tc-voyage/tc-voyage.component";

@Component({
    selector: "ops-tc-voyages",
    templateUrl: "./tc-voyages.component.html",
    styleUrls: ["./tc-voyages.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TcVoyagesComponent implements OnInit, OnDestroy, AfterViewChecked {
    static componentName = "TcVoyagesComponent";

    private currentFixtureSubscription: Subscription;
    private currentVoyageSubscription: Subscription;
    private routeSubscription: Subscription;
    private currentFixtureId: string;

    private firstLoad = true;

    voyages$: Observable<TcVoyageModel[]>;
    isLoaded = false;
    isLockedByCurrentUser$: Observable<boolean>;
    creatingVoyage$: Observable<boolean>;
    savingVoyages$: Observable<boolean>;

    voyages: Voyage[];
    isOpen = true;
    isClosed = true;

    @ViewChildren(TcVoyageComponent) private voyagesViewComponents: QueryList<TcVoyageComponent>;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private voyageDataService: VoyageDataService,
        public fixtureDataService: FixtureDataService,
        private route: ActivatedRoute,
        private router: Router,
        private leftBarStateService: LeftBarStateService
    ) {}

    ngOnInit() {
        this.routeSubscription = this.route.params.subscribe((params) => {
            const selectedVoyageId = params["voyageId"];
            if (selectedVoyageId) {
                this.leftBarStateService.open();
            }
        });

        this.currentVoyageSubscription = combineLatest([this.voyageDataService.current$, this.voyageDataService.voyages$]).subscribe(([current, voyages]) => {
            if (current && voyages) {
                this.isLoaded = true;
                this.voyages = voyages.sort((a, b) => (a.voyageNumber > b.voyageNumber ? 1 : -1));
                const index = Math.max(
                    0,
                    this.voyages.findIndex((v) => v.voyageId === current.voyageId)
                );
                this.voyages[index] = current;
                this.voyages$ = of(this.getVoyageModels(this.voyages, index));
                this.changeDetectorRef.markForCheck();
            }
        });

        this.currentFixtureSubscription = this.fixtureDataService.currentFixture$.pipe(filter((v) => v !== null)).subscribe((fixture) => {
            this.currentFixtureId = fixture.fixtureId;
        });

        this.isLockedByCurrentUser$ = this.fixtureDataService.isLockedByCurrentUser$;
        this.creatingVoyage$ = this.voyageDataService.creatingVoyage$;
        this.savingVoyages$ = this.voyageDataService.saving$;
    }

    ngOnDestroy(): void {
        if (this.currentFixtureSubscription) {
            this.currentFixtureSubscription.unsubscribe();
            this.currentFixtureSubscription = null;
        }

        if (this.currentVoyageSubscription) {
            this.currentVoyageSubscription.unsubscribe();
            this.currentVoyageSubscription = null;
        }

        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    ngAfterViewChecked(): void {
        if (this.voyagesViewComponents) {
            const selectedElement = this.voyagesViewComponents.find((v) => v.Voyage?.isSelected);
            if (selectedElement) {
                selectedElement.elementRef.nativeElement.lastChild.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                });
                this.firstLoad = false;
            }
        }
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }

    createVoyage(): void {
        this.voyageDataService.create();
    }

    voyageSelected(model: TcVoyageModel): void {
        const selected = this.voyages.find((v) => v.voyageId === model.voyageId);
        if (selected) {
            this.navigateToVoyage(selected.fixtureId, selected.voyageId);
            this.changeDetectorRef.markForCheck();
        }
    }

    private getVoyageModels(voyages: Voyage[], selectedIndex: number): TcVoyageModel[] {
        if (!voyages) {
            return null;
        }

        const models: TcVoyageModel[] = [];

        voyages.forEach((voyage) => {
            let nextEtaDate = "";
            let nextEtaPort = "";
            let nextEtbDate = "";
            let nextEtbPort = "";

            const { loads, discharges } = this.getBerthActivities(voyage.destinations);
            const nextEtaDestination = this.getEtaToNextPort(voyage.destinations);

            if (nextEtaDestination) {
                const nextEtbBerth = this.getEtbToNextPort(nextEtaDestination);
                const timeZone = nextEtaDestination.location && nextEtaDestination.location.timeZone ? nextEtaDestination.location.timeZone : "utc";
                nextEtaDate = nextEtaDestination && nextEtaDestination.etaRange ? convertTimeZone(nextEtaDestination.etaRange.to, timeZone, "DD MMM YY, H:mm") : "";
                nextEtaPort = nextEtaDestination && nextEtaDestination.location ? nextEtaDestination.location.displayName : "";
                nextEtbPort = nextEtbBerth && nextEtbBerth.name ? nextEtbBerth.name : nextEtaPort;
                nextEtbDate = nextEtbBerth ? convertTimeZone(nextEtbBerth.etb, timeZone, "DD MMM YY, H:mm") : "";
            }

            const voyageModel = new TcVoyageModel();
            voyageModel.cargoNames = this.getCargoNames(voyage.cargoes);
            voyageModel.nextEtaDate = nextEtaDate;
            voyageModel.nextEtaPort = nextEtaPort;
            voyageModel.nextEtbDate = nextEtbDate;
            voyageModel.nextEtbPort = nextEtbPort;
            voyageModel.voyageId = voyage.voyageId;
            voyageModel.isValid = voyage.isValid;
            voyageModel.isDirty = voyage.isDirty;
            voyageModel.voyageNumber = voyage.voyageNumberDisplay || "001";
            voyageModel.isOpen = voyage.voyageStatus && voyage.voyageStatus.id === VoyageStatus.Open;
            voyageModel.isClosed = voyage.voyageStatus && voyage.voyageStatus.id === VoyageStatus.Closed;
            voyageModel.loads = loads.join(", ");
            voyageModel.discharges = discharges.join(", ");
            voyageModel.isSelected = false;
            models.push(voyageModel);
        });

        if (models.length > selectedIndex) {
            models[selectedIndex].isSelected = true;
        }
        return models;
    }

    private navigateToVoyage(fixtureId: string, voyageId: string) {
        this.router.navigate([{ outlets: { primary: ["fixture", fixtureId, "voyage", voyageId] }, toolbar: ["tc-voyages", voyageId] }], {
            replaceUrl: true,
            queryParamsHandling: "merge"
        });
    }

    private getCargoNames(cargoes: Cargo[]): string {
        if (!cargoes || cargoes.length === 0) {
            return "";
        }

        return cargoes
            .map((cargo) => {
                if (cargo.cargoProduct) {
                    return cargo.cargoProduct.name;
                }
            })
            .join(", ");
    }

    private getEtaToNextPort(destinations: Destination[]): Destination {
        if (!destinations || destinations.length === 0) {
            return null;
        }

        let nextEtaDestination: Destination;
        let lastEtaDestination: Destination;

        const timeNow = DateTime.local();
        destinations.forEach((destination) => {
            const etaRange = destination.etaRange ? MaritimeDateRange.fromDates(destination.etaRange.from, destination.etaRange.to) : null;
            const eta = etaRange?.end;
            if (+eta > +timeNow && (!nextEtaDestination || (nextEtaDestination.etaRange && DateTime.fromISO(nextEtaDestination.etaRange.to) > eta))) {
                nextEtaDestination = destination;
            } else if (destination.etaRange && destination.etaRange.to) {
                lastEtaDestination = destination;
            }
        });

        return nextEtaDestination ? nextEtaDestination : lastEtaDestination;
    }

    private getEtbToNextPort(destination: Destination): Berth {
        if (!destination || !destination.berths || destination.berths.length === 0) {
            return null;
        }

        const timeNow = new Date();
        let nextEtb: Berth = destination.berths[destination.berths.length - 1];

        destination.berths.forEach((berth) => {
            const etb = parseISODate(berth.etb);
            if (etb > timeNow && (!nextEtb.etb || parseISODate(nextEtb.etb) > etb)) {
                nextEtb = berth;
            } else if (!nextEtb.etb && etb) {
                nextEtb = berth;
            }
        });

        return nextEtb;
    }

    private getBerthActivities(destinations: Destination[]): any {
        const loads: string[] = [];
        const discharges: string[] = [];
        destinations.forEach((destination) => {
            destination.berths.forEach((berth) => {
                berth.cargoBerthActivities.forEach((ac) => {
                    if (ac.type) {
                        if (ac.type.id === CargoBerthActivityType.Load.id && destination.location) {
                            loads.push(destination.location.displayName);
                        }
                        if (ac.type.id === CargoBerthActivityType.Discharge.id && destination.location) {
                            discharges.push(destination.location.displayName);
                        }
                    }
                });
            });
        });
        return { loads, discharges };
    }

    get tooltipContent(): string {
        let appliedFilters = "Filters Applied:";

        if (this.isOpen) {
            appliedFilters += " Open,";
        }
        if (this.isClosed) {
            appliedFilters += " Closed,";
        }
        if (!this.isOpen && !this.isClosed) {
            appliedFilters = "No filters applied ";
        }

        return appliedFilters.substr(0, appliedFilters.length - 1);
    }
}
