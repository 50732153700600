import { createSelector } from "@ngrx/store";
import { DateTime } from "luxon";
import { FormGroupState, unbox } from "ngrx-forms";

import { CargoBerthActivityType } from "@ops/shared/reference-data";
import { getIsTransient } from "@ops/state";

import { VerifiedTimestamp } from "../../../shared/components/timestamp/verified-timestamp.model";
import { Destination, Division, FixtureType, Voyage } from "../../shared/models";
import { selectCurrentVoyageCargoItems } from "../cargoes";
import { selectCurrentFixtureDivision, selectCurrentFixtureType } from "../fixture";
import { LayCan, selectCurrentFixtureLayCan } from "../laycan";
import {
    CargoItem,
    destinationExpandedKey,
    DestinationForm,
    destinationHasActivityTypes,
    DestinationId,
    destinationNotesExpandedKey,
    FixtureFeatureState,
    VoyageExpandedSections,
    VoyageForm,
    VoyageState
} from "../model";
import { selectCurrentVoyage, selectCurrentVoyageExpandedSections, selectCurrentVoyageForm, selectCurrentVoyageState } from "../voyage";
import { DestinationSummary, getDestinationSummary } from "./destination-summary";

export declare type DestinationState = Readonly<{
    destinationId: DestinationId;
    summary: DestinationSummary;
    expanded: boolean;
    isInvalid: boolean;
    canOrder: boolean;
    canCloneOrRemove: boolean;
}>;

export { DestinationSummary };

export const selectCurrentVoyageDestinationsExpanded = createSelector(selectCurrentVoyageExpandedSections, (expandedSections) => expandedSections && expandedSections.destinations);
export const selectCurrentVoyageDestinationNotesExpanded = createSelector<FixtureFeatureState, { destinationId: DestinationId }, VoyageExpandedSections, boolean>(
    selectCurrentVoyageExpandedSections,
    (expandedSections, { destinationId }) => expandedSections && expandedSections[destinationNotesExpandedKey(destinationId)]
);

export const selectCurrentVoyageDestinationIds = createSelector(selectCurrentVoyage, (voyage) => voyage?.destinations.map((destination) => destination.id) ?? []);

export const selectCurrentVoyageDestinations = createSelector<
    FixtureFeatureState,
    FormGroupState<VoyageForm>,
    VoyageExpandedSections,
    Division,
    ReadonlyArray<CargoItem>,
    DestinationState[]
>(
    selectCurrentVoyageForm,
    selectCurrentVoyageExpandedSections,
    selectCurrentFixtureDivision,
    selectCurrentVoyageCargoItems,
    (voyageForm, expandedSections, division, cargoes: ReadonlyArray<CargoItem>) =>
        voyageForm?.controls.destinations.controls.map(({ value: destination, isInvalid }) => {
            const canCloneOrRemove =
                division !== Division.specialisedProducts || !destinationHasActivityTypes(destination, CargoBerthActivityType.Load, CargoBerthActivityType.Discharge);

            return <DestinationState>{
                destinationId: destination.id,
                summary: getDestinationSummary(cargoes, destination),
                expanded: expandedSections[destinationExpandedKey(destination.id)],
                isInvalid,
                canOrder: !destination.etaRange?.value?.to,
                canCloneOrRemove
            };
        })
);

export const selectCurrentVoyageDestination = createSelector<FixtureFeatureState, { destinationId: DestinationId }, Voyage, Destination>(
    selectCurrentVoyage,
    (voyage, { destinationId }) => voyage?.destinations.find((destination) => destination.id === destinationId)
);

export const selectCurrentVoyageDestinationFormState = createSelector<FixtureFeatureState, { destinationId: DestinationId }, VoyageState, FormGroupState<DestinationForm>>(
    selectCurrentVoyageState,
    (voyage, { destinationId }) => voyage?.form.controls.destinations.controls.find((x) => x.value.id === destinationId)
);

export const selectCurrentVoyageDestinationForm = createSelector(selectCurrentVoyageDestinationFormState, (form) => form?.value);

export const selectCurrentVoyageDestinationEtaVerified = createSelector(selectCurrentVoyageDestination, (destination) => {
    if (!destination || (!destination.etaLastVerifiedBy && !destination.etaLastVerifiedDate)) {
        return null;
    }

    return <VerifiedTimestamp>{ user: destination.etaLastVerifiedBy, date: destination.etaLastVerifiedDate };
});

export const selectCurrentVoyageDestinationIsTransient = createSelector(
    selectCurrentVoyageDestinationFormState,
    (destinationForm) => destinationForm && getIsTransient(destinationForm)
);

export const selectCurrentVoyageDestinationHasEtaWarning = createSelector(
    selectCurrentFixtureType,
    selectCurrentVoyageDestinationIds,
    selectCurrentVoyageDestinationForm,
    selectCurrentFixtureLayCan,
    (fixtureType, destinationIds, destinationForm, layCan) => {
        // Only the first destination is applicable for LayCan
        if (!destinationForm || fixtureType === FixtureType.TimeCharter || destinationIds[0] !== destinationForm.id) {
            return false;
        }

        const etaRange = destinationForm.etaRange && unbox(destinationForm.etaRange);
        const eta = etaRange?.to;

        return layCan?.can && eta && DateTime.fromISO(eta) > layCan.can;
    }
);

export const selectCurrentVoyageDestinationEtas = createSelector(selectCurrentVoyageForm, (voyage) =>
    voyage.value.destinations.map((destination) => {
        const unboxedEtaRange = destination.etaRange && unbox(destination.etaRange);
        const eta = unboxedEtaRange?.to;

        return eta ? DateTime.fromISO(eta) : null;
    })
);

export const selectCurrentVoyageDestinationDefaultEtaFocusDate = createSelector<
    FixtureFeatureState,
    { destinationId: DestinationId },
    ReadonlyArray<DestinationId>,
    ReadonlyArray<DateTime>,
    LayCan,
    DateTime
>(selectCurrentVoyageDestinationIds, selectCurrentVoyageDestinationEtas, selectCurrentFixtureLayCan, (destinationIds, destinationEtas, layCan, { destinationId }) => {
    const index = destinationIds.indexOf(destinationId);

    if (index === 0) {
        return layCan.can;
    }

    return destinationEtas[index - 1];
});

export const selectCurrentVoyageDestinationLocationTimezone = createSelector(
    selectCurrentVoyageDestinationForm,
    (destination) => (destination && destination.location && destination.location.value ? destination.location.value.timeZone : null) || "utc"
);

export const selectCurrentVoyageDestinationEta = createSelector(selectCurrentVoyageDestinationForm, (destination) => destination?.etaRange?.value?.to);

export const selectCurrentVoyageDestinationAreLocationAndEtaReadonly = createSelector(selectCurrentVoyageDestination, selectCurrentFixtureDivision, (destination, division) =>
    division === Division.specialisedProducts && destination
        ? destination.berths.some((b) =>
              b.cargoBerthActivities.some((a) => a.type && (a.type.id === CargoBerthActivityType.Discharge.id || a.type.id === CargoBerthActivityType.Load.id))
          )
        : false
);

export const selectCurrentVoyageDestinationArrivalDateTime = createSelector(selectCurrentVoyageDestinationForm, (destination) => destination?.arrivalDateTime);
