import { Directive, forwardRef, HostBinding, Input, Optional } from "@angular/core";
import { ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";

import { Enumeration } from "../../reference-data/enumeration";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "label[formControlName]",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LabelFormControlNameDirective),
            multi: true
        }
    ]
})
export class LabelFormControlNameDirective {
    @Input() formControlName: string;

    constructor(@Optional() private parent: ControlContainer) {}

    @HostBinding("textContent")
    get controlValue() {
        const control = this.parent.control.get(this.formControlName);
        const value = control.value instanceof Enumeration ? control.value.name : control.value;
        return this.parent ? value : "";
    }

    writeValue() {}

    registerOnChange() {}

    registerOnTouched() {}
}
