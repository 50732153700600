<ops-time-charter-heading
    *ngIf="(fixture$ | async) as fixture"
    [parentForm]="timeCharterForm"
    [fixture]="fixture"
    (headingUpdated)="handleFixtureUpdateCommand($event)"
></ops-time-charter-heading>

<ngb-tabset class="tabs-sticky" justify="fill" [destroyOnHide]="false" *ngIf="(fixture$ | async) as fixture" #tabset (tabChange)="tabChange($event)">
    <ngb-tab #hireTab id="hireTab">
        <ng-template ngbTabTitle>
            <div class="wrapped-title" data-test-hire-tab>
                <span
                    class="validation-indicator pl-0 has-icon icon--warning"
                    [class.invalid]="!tabsValidity.hireTab"
                    container="body"
                    ngbTooltip="There are validation errors on this tab"
                    data-test-hire-tab-warning
                ></span>
                Hire
            </div>
        </ng-template>
        <ng-template ngbTabContent>
            <div class="row no-gutters">
                <ops-delivery
                    class="col-12 pr-xl-2 col-xl-6"
                    [parentForm]="timeCharterForm"
                    [delivery]="fixture?.delivery"
                    [periods]="fixture?.periods"
                    [laycan]="fixture?.laycan"
                    (deliveryUpdated)="handleFixtureUpdateCommand($event)"
                ></ops-delivery>
                <div class="d-xl-none d-block col-12 divider"></div>
                <ops-redelivery
                    class="col-12 pl-xl-2 col-xl-6"
                    [parentForm]="timeCharterForm"
                    [redelivery]="fixture?.redelivery"
                    [periods]="fixture?.periods"
                    (redeliveryUpdated)="handleFixtureUpdateCommand($event)"
                ></ops-redelivery>
            </div>

            <div class="row no-gutters col-12 divider"></div>

            <div class="row no-gutters">
                <div class="card col-12 hire">
                    <div class="card-header pl-0 row no-gutters align-items-center">
                        <button
                            type="button"
                            (click)="hideHire = !hideHire"
                            class="has-icon btn-inverted"
                            [ngClass]="{ 'icon--arrow-drop-right': hideHire, 'icon--arrow-drop-down': !hideHire }"
                        ></button>
                        <div
                            class="validation-indicator has-icon icon--warning"
                            [class.invalid]="isHireSectionInvalid"
                            [class.touched]="isHireSectionTouched"
                            container="body"
                            ngbTooltip="There are validation errors on this section"
                            data-test-hire-warning
                        ></div>
                        <span class="title">Hire</span>
                    </div>
                    <div [hidden]="hideHire">
                        <div class="card-body no-gutters">
                            <ops-periods
                                class="col-12"
                                [parentForm]="parentForm"
                                [periods]="fixture?.periods"
                                [fixtureSource]="fixture?.fixtureSource?.id"
                                [laycan]="fixture?.laycan"
                                (periodUpdated)="handleFixtureUpdateCommand($event)"
                                (periodAdded)="handleFixtureUpdateCommand($event)"
                                (periodCloned)="handleFixtureUpdateCommand($event)"
                                (periodDeleted)="handleFixtureUpdateCommand($event)"
                            ></ops-periods>
                        </div>
                        <div class="card-body no-gutters">
                            <ops-offhire
                                class="col-12"
                                [parentForm]="parentForm"
                                [periods]="fixture?.periods"
                                (offHireUpdated)="handleFixtureUpdateCommand($event)"
                            ></ops-offhire>
                        </div>
                        <div class="card-body no-gutters">
                            <ops-options
                                [options]="fixture?.options"
                                [fixtureSource]="fixtureSource"
                                [parentForm]="parentForm"
                                [periods]="fixture?.periods"
                                (optionUpdated)="handleFixtureUpdateCommand($event)"
                            ></ops-options>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-tab>
    <ngb-tab #voyageTab id="voyageTab" *opsRole="'TCVoyages'">
        <ng-template ngbTabTitle>
            <div class="wrapped-title" data-test-cargo-tab>
                <span
                    class="validation-indicator pl-0 has-icon icon--warning"
                    [class.invalid]="!tabsValidity.voyageTab"
                    container="body"
                    ngbTooltip="There are validation errors on this tab"
                    data-test-cargo-tab-warning
                ></span>
                Voyage
            </div>
        </ng-template>
        <ng-template ngbTabContent>
            <ops-voyage-shell [tabName]="'voyageTab'"></ops-voyage-shell>
        </ng-template>
    </ngb-tab>
    <ngb-tab id="speedAndConsumptionTab" *opsRole="'TCVoyages'">
        <ng-template ngbTabTitle>
            <div class="wrapped-title" data-test-speed-tab>
                <span
                    class="validation-indicator pl-0 has-icon icon--warning"
                    [class.invalid]="!tabsValidity.speedAndConsumptionTab"
                    container="body"
                    ngbTooltip="There are validation errors on this tab"
                    data-test-speed-tab-warning
                ></span>
                Speed and Consumption
            </div>
        </ng-template>
        <ng-template ngbTabContent>
            <div class="loading-panel-container fill" data-test-loading-panel-container>
                <div class="loading-panel" *ngIf="voyageBusy$ | async">
                    <ops-load-animation size="x-large" class="loading-animation"></ops-load-animation>
                </div>
                <div class="row no-gutters" *ngIf="voyage$ | async as voyage">
                    <ops-speed-and-consumption-tab
                        class="col-12"
                        [readonly]="voyageReadonly$ | async"
                        [tabName]="'speedAndConsumptionTab'"
                        [parentForm]="timeCharterForm"
                        [delivery]="fixture?.delivery"
                        [ownerEmail]="fixture?.ownerEmail"
                        [destinations]="voyage?.destinations"
                        [atSeaBunkerConsumptionMode]="voyage?.atSeaBunkerConsumptionMode"
                        [atSeaBunkersConsumption]="voyage?.atSeaBunkersConsumption"
                        [cpSpeedAndConsumption]="fixture?.cpSpeedAndConsumption"
                        (cpSpeedAndConsumptionTabUpdated)="handleFixtureUpdateCommand($event)"
                        (voyageBunkerConsumptionTabUpdated)="handleVoyageUpdateCommand($event)"
                        formName="speedAndConsumptionVoyageTab"
                        [voyages$]="voyages$"
                        [voyageId]="voyage?.voyageId"
                        [voyageCost]="voyageCost"
                    ></ops-speed-and-consumption-tab>
                </div>
            </div>
        </ng-template>
    </ngb-tab>
    <ngb-tab #expensesTab id="expensesTab">
        <ng-template ngbTabTitle>
            <div class="wrapped-title" data-test-expenses-tab>
                <span
                    class="validation-indicator pl-0 has-icon icon--warning"
                    [class.invalid]="!tabsValidity.expensesTab"
                    container="body"
                    ngbTooltip="There are validation errors on this tab"
                    data-test-expenses-tab-warning
                ></span>
                Expenses <span class="count-badge">({{ !!expenseTabComponent?.expensesCount ? expenseTabComponent?.expensesCount : 0 }})</span>
            </div>
        </ng-template>
        <ng-template ngbTabContent>
            <ops-expense-tab></ops-expense-tab>
        </ng-template>
    </ngb-tab>
    <ngb-tab #invoicesTab id="invoicesTab">
        <ng-template ngbTabTitle>
            <div class="wrapped-title" data-test-invoices-tab>
                <span
                    class="validation-indicator pl-0 has-icon icon--warning"
                    [class.invalid]="!tabsValidity.invoicesTab"
                    container="body"
                    ngbTooltip="There are validation errors on this tab"
                    data-test-invoices-tab-warning
                ></span>
                Invoices <span class="count-badge">({{ !!hireInvoicesComponent?.invoiceCount ? hireInvoicesComponent.invoiceCount : 0 }})</span>
            </div>
        </ng-template>
        <ng-template ngbTabContent>
            <div class="loading-panel-container" data-test-loading-panel-container>
                <div
                    class="loading-panel"
                    *ngIf="hireInvoicesComponent?.invoicesForm.disabled && hireInvoicesComponent.invoiceCount === 0"
                >
                    <ops-load-animation size="x-large" class="loading-animation"></ops-load-animation>
                </div>
                <div class="row no-gutters">
                    <ops-hire-invoices class="col-12" [fixture$]="fixture$"></ops-hire-invoices>
                </div>
                <div class="row no-gutters" *ngIf="isCommissionActive">
                    <ops-commission class="col-12"></ops-commission>
                </div>
            </div>
        </ng-template>
    </ngb-tab>
</ngb-tabset>
