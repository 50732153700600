import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CargoBerthActivityType, Enumeration } from "@ops/shared/reference-data";
import { CustomNgrxValueConverters } from "@ops/state";
import { FormGroupState } from "ngrx-forms";
import { ActivityForm } from "../../state";

@Component({
    selector: "ops-activity-form",
    templateUrl: "./activity-form.component.html",
    styleUrls: ["./activity-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityFormComponent {
    readonly dateRangeValueConverter = CustomNgrxValueConverters.dateRangeToISOString;
    readonly enumerationCompare = Enumeration.compare;

    @Input() form: FormGroupState<ActivityForm>;
    @Input() locationTimezone: string;
    @Input() isBlAtDisportVisible: boolean;
    @Input() isTypeDropdownReadonly: boolean;
    @Input() activityDropdownTypes: Enumeration[];
    @Input() readonly: boolean;

    get activityType() {
        return this.form.value.type && this.form.value.type.value;
    }

    get isDischargeActivity(): boolean {
        return this.activityType && this.activityType.id === CargoBerthActivityType.Discharge.id;
    }
}
