<ng-select
    [items]="suggestions$ | async"
    [loading]="isLoading$ | async"
    [(ngModel)]="selection"
    bindLabel="firstName"
    placeholder="Search Person"
    [disabled]="disabled"
    (change)="onSelectionChange($event)"
    [selectOnTab]="true"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span container="body" [ngbTooltip]="item.primaryEmail" class="ng-value-label"> {{ item.firstName }} {{ item.lastName }} </span>
        <span class="ng-value-icon has-icon icon--cancel right" (click)="clear(item)" aria-hidden="true"></span>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div class="row">
            <div class="col">
                <span container="body" [ngOptionHighlight]="search">{{ item.firstName }} {{ item.lastName }}</span>
                <span *ngIf="item.roles && item.roles.length > 0" class="float-right extra-info">{{ item.roles[0] }}</span>
            </div>
        </div>
        <div class="extra-info">
            <div class="row job-title">
                <div class="col">
                    <span class="extra-info">{{ item.jobTitle }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span class="extra-info">{{ item.companyName }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span *ngIf="personUtilities.getPrimaryEmail(item)" class="extra-info">{{ personUtilities.getPrimaryEmail(item) }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span *ngIf="personUtilities.getPrimaryPhone(item)" class="extra-info">{{ personUtilities.getPrimaryPhone(item) }}</span>
                </div>
            </div>
        </div>
    </ng-template>
</ng-select>
