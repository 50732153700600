import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";

import { OPS_VERSION } from "../../../environments/environment";
import { AuthService } from "../../core";
import { LeftBarStateService } from "../left-bar-state.service";
import { FavoriteDataService } from "../shared/favorite-data.service";
import { FavoriteViewModel } from "../shared/models";

@Component({
    selector: "ops-favorites",
    templateUrl: "./favorites.component.html",
    styleUrls: ["./favorites.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoritesComponent implements OnInit {
    static componentName = "FavoritesComponent";

    allSelected = false;
    anySelected = false;
    anyFavorites = false;
    buildVersionNumber = OPS_VERSION;

    constructor(
        private favoriteService: FavoriteDataService,
        private cd: ChangeDetectorRef,
        private leftBarStateService: LeftBarStateService,
        private authService: AuthService,
        @Inject("Window") private window: Window
    ) {}

    ngOnInit() {
        this.favoriteService.favoriteCategories$.subscribe((data) => {
            this.anySelected = data
                .map((category) => category.favorites)
                .reduce((a, b) => a.concat(b), [])
                .some((favorite) => favorite.selected);

            this.anyFavorites = data.some((c) => c.favorites && c.favorites.length > 0);
            this.cd.markForCheck();
        });
    }

    get favouritesLoading$() {
        return this.favoriteService.favouritesLoading$;
    }

    onToggleSelectAll(event: MouseEvent): void {
        event.stopPropagation();
        this.allSelected = !this.allSelected;
        this.favoriteService.toggleSelectAllCategories(this.allSelected);
    }

    onRemoved(): void {
        this.allSelected = false;
        this.anySelected = false;
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }

    openFavoriteInNewTab(favorite: FavoriteViewModel) {
        if (!favorite) {
            return;
        }
        const windowHandle = this.window.open("about:blank", "_blank");
        const fixtureUrl = `/fixture/${favorite.fixtureId}`;
        if (!favorite.isLockedByOtherUser && !favorite.isLockedByCurrentUser) {
            this.favoriteService
                .updateLockForFavorite(favorite.fixtureId, this.authService.systemUser.systemUserId)
                .subscribe(() => this.leftBarStateService.openWindowInNewTab(fixtureUrl, windowHandle));
        } else {
            this.leftBarStateService.openWindowInNewTab(fixtureUrl, windowHandle);
        }
    }

    openAll() {
        this.favoriteService.getSelectedFavorites().forEach((favorite) => this.openFavoriteInNewTab(favorite));
    }
}
