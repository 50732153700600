<div class="d-flex flex-wrap w-100">
    <div class="col">
        <div class="summary-value">
            Claim Type: {{demurrageClaim.type?.name}} <span *ngIf="onAccountIndex">– {{onAccountIndex}}</span>
        </div>
    </div>
    <div class="col">
        <div class="summary-value">Received from Owner: {{demurrageClaim.receivedFromOwnerDate | date:'d MMM yy'}}</div>
    </div>
    <div class="col">
        <div class="separator"></div>
        <div class="summary-value" warningActive ngrxFormControlId="demurrage.{{index}}.claimValue">
            Claim Value: {{claimValue | number: '1.2-2'}}
        </div>
    </div>
</div>
