import { createAction, on, On, props } from "@ngrx/store";
import { createFormGroupState } from "ngrx-forms";
import { CargoId, CoasState, getCurrentLiftingState, liftingCargoForm, liftingCargoToForm } from "../../../model";
import { currentLiftingStateReducer } from "../../reducer";

/* ACTIONS */
export const editLiftingCargoAction = createAction("[Lifting Cargo Form] Edit Cargo", props<{ cargoId?: CargoId }>());
export const cancelLiftingCargoEditAction = createAction("[Lifting Cargo Form] Cancel Edit Cargo");

/* REDUCERS */
export const editLiftingCargoReducer: On<CoasState> = on(editLiftingCargoAction, (state, { cargoId }) => {
    const currentLifting = getCurrentLiftingState(state);
    const cargo = currentLifting.lifting.cargoes.find((c) => c.cargoId === cargoId);

    if (!cargo && !!cargoId) {
        return state;
    }

    const form = !!cargoId ? liftingCargoToForm(cargo) : liftingCargoForm();

    return currentLiftingStateReducer(state, (liftingState) => ({
        ...liftingState,
        liftingCargoForm: createFormGroupState(form.cargoId + ".liftingCargoForm", form)
    }));
});

export const cancelLiftingCargoEditReducer: On<CoasState> = on(cancelLiftingCargoEditAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => ({ ...liftingState, liftingCargoForm: null }))
);
