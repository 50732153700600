import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ActionExpandStateService {
    private actionExpandState = new Set<string>();

    expand(actionIds: string[]): void {
        actionIds.forEach((actionId) => this.actionExpandState.add(actionId));
    }

    collapseAll(): void {
        this.actionExpandState.clear();
    }

    toggleExpansion(actionId: string) {
        if (!this.actionExpandState.delete(actionId)) {
            this.actionExpandState.add(actionId);
        }
    }

    expandAction(actionId: string) {
        this.actionExpandState.add(actionId);
    }

    isExpanded(actionId: string): boolean {
        return this.actionExpandState.has(actionId);
    }
}
