import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/core";
import { ExpenseClaim } from "../shared/models";

@Injectable({
    providedIn: "root"
})
export class ExpenseHttpService {
    private expensessServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.expensessServiceUrl = appConfigService.config.claimsServiceUrl;
    }

    get(fixtureId: string): Observable<ExpenseClaim[]> {
        return this.httpClient.get<ExpenseClaim[]>(`${this.expensessServiceUrl}/api/v1.0/fixture/${fixtureId}/expense`);
    }

    create(fixtureId: string, expense: ExpenseClaim): Observable<string> {
        return this.httpClient.post<string>(`${this.expensessServiceUrl}/api/v1.0/fixture/${fixtureId}/expense`, expense);
    }

    update(fixtureId: string, expense: ExpenseClaim): Observable<any> {
        return this.httpClient.put(`${this.expensessServiceUrl}/api/v1.0/fixture/${fixtureId}/expense/${expense.expenseClaimId}`, expense);
    }

    delete(fixtureId: string, expenseId: string): Observable<any> {
        return this.httpClient.delete(`${this.expensessServiceUrl}/api/v1.0/fixture/${fixtureId}/expense/${expenseId}`);
    }
}
