import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class BusyBoxService {
    private busyBoxSubject = new BehaviorSubject<boolean>(false);

    setApplicationBusy(value: boolean) {
        this.busyBoxSubject.next(value);
    }

    get isApplicationBusy$(): Observable<boolean> {
        return this.busyBoxSubject.asObservable();
    }
}
