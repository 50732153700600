<table class="table table-striped table-spaced">
    <thead>
        <tr>
            <th>Expenses ({{report.currency}})</th>
            <th>Estimated</th>
            <th>Actual</th>
            <th>Variance<span class="has-icon icon--info-outline" ngbTooltip="Displays the difference between Actual and Estimated"></span></th>
        </tr>
    </thead>
    <tbody *ngFor="let item of report.expenses">
        <tr>
            <th>{{item.name}}<span class="has-icon icon--warning" *ngIf="item.invalid?.currencyConversion" [ngbTooltip]="getInvalidTooltip(item)"></span></th>
            <th>{{item.estimated | opsNumber:true:2:2:"accounting"}}</th>
            <th>{{item.actual | opsNumber:true:2:2:"accounting"}}</th>
            <th>{{item.variance | opsNumber:true:2:2:"accounting"}}</th>
        </tr>

        <ng-container *ngIf="item.items">
            <tr *ngFor="let child of item.items; trackBy: trackBy">
                <td>{{child.name}}</td>
                <td>{{child.estimated | opsNumber:true:2:2:"accounting"}}</td>
                <td>{{child.actual | opsNumber:true:2:2:"accounting"}}</td>
                <td>{{child.variance | opsNumber:true:2:2:"accounting"}}</td>
            </tr>
        </ng-container>
    </tbody>
    <tfoot>
        <tr>
            <td>Total Expenses</td>
            <td>{{report.netEstimated | opsNumber:true:2:2:"accounting"}}</td>
            <td>{{report.netActual| opsNumber:true:2:2:"accounting"}}</td>
            <td>{{report.netVariance | opsNumber:true:2:2:"accounting"}}</td>
        </tr>
    </tfoot>
</table>
