import { formatNumber } from "@angular/common";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

import { parseISODate } from "../../../../shared/date-utils/date-utilities";
import { Enumeration } from "../../../../shared/reference-data/enumeration";
import { ReferenceDataService } from "../../../../shared/reference-data/reference-data.service";
import { Command } from "../../../mediator/commands/command";
import { getLastPeriodTo } from "../../../shared/fixture-destination.utils";
import { FormComponentBase } from "../../../shared/form-component-base";
import { Period } from "../../../shared/models";
import { Option } from "../../../shared/models/dtos/option.dto";
import { FixtureSource } from "../../../shared/models/enums/fixture-source";
import { OptionFormModel } from "../../../shared/models/form-models/option.model";
import { AddOptionCommand } from "./commands/add-option.command";
import { CloneOptionCommand } from "./commands/clone-option.command";
import { RemoveOptionCommand } from "./commands/remove-option.command";
import { UpdateOptionCommand } from "./commands/update-option.command";

@Component({
    selector: "ops-options",
    templateUrl: "./options.component.html",
    styleUrls: ["./options.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionsComponent extends FormComponentBase implements OnInit, OnChanges, OnDestroy {
    static componentName = "OptionsComponent";

    private fixtureSubscription: Subscription;
    private rowsChanged: boolean;

    hireRateUnits: Enumeration[];
    exerciseOptions: Enumeration[];
    optionsFormArray: UntypedFormArray;

    @Input() parentForm: UntypedFormGroup;
    @Input() options: Option[];
    @Input() fixtureSource: FixtureSource;
    @Input() periods: Period[];
    @Output() optionUpdated = new EventEmitter<Command>();
    @ViewChildren("periodFromPicker", { read: ElementRef }) periodFromPickers: QueryList<ElementRef>;

    get lastPeriodTo(): Date {
        return getLastPeriodTo(this.periods);
    }

    constructor(private formBuilder: UntypedFormBuilder, public referenceDataService: ReferenceDataService, private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.referenceDataService.getHireRateUnits().subscribe((hireRateUnits: Enumeration[]) => {
            this.hireRateUnits = hireRateUnits;
            this.changeDetectorRef.markForCheck();
        });

        this.referenceDataService.getExerciseOptions().subscribe((exerciseOptions: Enumeration[]) => {
            this.exerciseOptions = exerciseOptions;
            this.changeDetectorRef.markForCheck();
        });

        this.createForm();
        if (this.parentForm.disabled) {
            this.optionsFormArray.disable();
        } else {
            this.optionsFormArray.enable();
        }
        this.parentForm.registerControl("optionsFormArray", this.optionsFormArray);

        this.setForm();
        this.changeDetectorRef.detectChanges();
        this.setFocus();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes || !this.optionsFormArray) {
            return;
        }

        this.setForm();
        this.changeDetectorRef.detectChanges();
        this.setFocus();
    }

    ngOnDestroy(): void {
        this.removeFormSubscriptions();
        this.parentForm.removeControl("optionsFormArray");

        if (this.fixtureSubscription) {
            this.fixtureSubscription.unsubscribe();
            this.fixtureSubscription = null;
        }
    }

    addOption() {
        this.rowsChanged = true;
        this.optionUpdated.emit(new AddOptionCommand());
    }

    cloneOption(id: number) {
        this.rowsChanged = true;
        this.optionUpdated.emit(new CloneOptionCommand(this.toIndex(id)));
    }

    removeOption(id: number) {
        this.rowsChanged = true;
        this.optionUpdated.emit(new RemoveOptionCommand(this.toIndex(id)));
    }

    get canAddOption() {
        return this.fixtureSource === FixtureSource.Ops;
    }

    get noDataPanelDescription() {
        return this.canAddOption ? "To add an option, click the button." : "Options are added in Gain.";
    }

    get invalid() {
        return this.optionsFormArray.invalid;
    }

    get touched() {
        return this.optionsFormArray.controls.some((form: UntypedFormGroup) => {
            const controlKeys = Object.keys(form.controls);
            const isTouched = controlKeys.some((key) => form.controls[key].invalid && form.controls[key].touched);
            return isTouched;
        });
    }

    private toIndex(id: number) {
        return id - 1;
    }

    private toId(index: number) {
        return formatNumber(index + 1, "en", "2.0");
    }

    private createForm() {
        this.optionsFormArray = this.formBuilder.array([]);
    }

    private setForm() {
        const dataModel = this.createDataModel();

        this.removeFormSubscriptions();
        this.resizeList(dataModel.length);

        this.optionsFormArray.patchValue(dataModel, { emitEvent: false });
        if (!this.canAddOption) {
            this.optionsFormArray.disable();
        }
        this.subscribeToFormValueChanges(this.optionsFormArray, (data: OptionFormModel[]) => {
            this.optionUpdated.emit(new UpdateOptionCommand(data));
        });
    }

    private resizeList(count: number): void {
        if (this.optionsFormArray.length === count) {
            return;
        }

        if (this.optionsFormArray.length > count) {
            this.optionsFormArray.removeAt(0);
        } else if (this.optionsFormArray.length < count) {
            this.optionsFormArray.push(this.createRow());
        }

        this.resizeList(count);
    }

    private createRow(): UntypedFormGroup {
        const group = new UntypedFormGroup(
            {
                id: new UntypedFormControl(null, Validators.required),
                declarationDueDate: new UntypedFormControl(null, Validators.required),
                durationInDays: new UntypedFormControl(null, Validators.required),
                exerciseOption: new UntypedFormControl(null, Validators.required),
                hireRate: new UntypedFormControl(null, Validators.required),
                hireRateUnit: new UntypedFormControl(null, Validators.required),
                periodFrom: new UntypedFormControl(null, Validators.required),
                periodTo: new UntypedFormControl(null, Validators.required),
                exercised: new UntypedFormControl(null)
            },
            { updateOn: "blur" }
        );

        if (this.optionsFormArray.disabled) {
            group.disable();
        } else {
            group.enable();
        }
        return group;
    }

    private createDataModel(): OptionFormModel[] {
        const options = this.options.map(
            (o: Option, index: number) =>
                <OptionFormModel>{
                    id: this.toId(index),
                    declarationDueDate: parseISODate(o.declarationDueDate),
                    durationInDays: o.durationInDays,
                    exerciseOption: o.exerciseOption,
                    hireRate: o.hireRate,
                    hireRateUnit: o.hireRateUnit,
                    periodFrom: o.periodRange === null ? null : parseISODate(o.periodRange.from),
                    periodTo: o.periodRange === null ? null : parseISODate(o.periodRange.to),
                    exercised: o.exercised
                }
        );

        const sorter = (p1: OptionFormModel, p2: OptionFormModel) => {
            const fromTime1 = p1.periodFrom ? p1.periodFrom.getTime() : 0;
            const fromTime2 = p2.periodFrom ? p2.periodFrom.getTime() : 0;

            const fromDifference = fromTime1 - fromTime2;
            return !fromDifference || !fromTime1 || !fromTime2 ? +p1.id - +p2.id : fromDifference;
        };

        options.sort(sorter);
        return options;
    }

    private setFocus() {
        if (this.periodFromPickers && this.periodFromPickers.last && this.rowsChanged) {
            const datepicker = this.periodFromPickers.last.nativeElement.querySelector("input.form-control.date-input") as HTMLInputElement;
            datepicker.focus();
            this.rowsChanged = false;
        }
    }
}
