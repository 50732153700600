<div [formGroup]="bunkerform" class="row">
    <div class="col-8">
        <ops-voyage-bunkers-remaining
            [bunkers]="activity.bunkersRemainingOnboard"
            [activityForm]="activityForm"
            [activityName]="activity.type?.name"
            (bunkerUpdated)="bunkerUpdated($event)"
            (bunkerRemoved)="bunkerRemoved($event)"
            (bunkerAdded)="bunkerAdded()"
            (initialBunkersAdded)="initialBunkersAdded()"
        >
        </ops-voyage-bunkers-remaining>
    </div>
    <div class="form-group col-4">
        <div *ngIf="showDaysAboveForce" class="row">
            <div class="col-6">
                <label>Days Above Force {{ maxBeaufortForce }}</label>
                <input
                    class="form-control text-right"
                    formControlName="daysAboveForce"
                    [opsNumber]="{ precision: 6, minimumFractionDigits: 2, showTrailingZeros: false }"
                    data-test-daysAboveForce
                />
            </div>
        </div>
        <div class="row" [class.input-comments]="showDaysAboveForce">
            <div class="col">
                <label>Comments</label>
                <textarea class="form-control" formControlName="comments" rows="2" cols="75" maxlength="1000" data-test-comments></textarea>
            </div>
        </div>
    </div>
</div>
