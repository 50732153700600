import { Params, RouterStateSnapshot } from "@angular/router";
import { ofType } from "@ngrx/effects";
import { RouterStateSerializer } from "@ngrx/router-store";
import { RouterNavigatedAction, ROUTER_NAVIGATED } from "@ngrx/router-store";
import { pipe } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { AppState } from "./model";

export interface OpsRouterStateUrl {
    url: string;
    params: Params;
    queryParams: Params;
}

// TODO: (NGRX JC) OpsRouterStateSerializer tests
export class OpsRouterStateSerializer implements RouterStateSerializer<OpsRouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): OpsRouterStateUrl {
        let route = routerState.root;

        while (route.firstChild) {
            route = route.firstChild;
        }

        const {
            url,
            root: { queryParams }
        } = routerState;

        let { params } = route;

        let parent = route.parent;

        while (parent) {
            params = {
                ...parent.params,
                ...params
            };

            parent = parent.parent;
        }

        // Only return an object including the URL, params and query params instead of the entire snapshot
        return { url, params, queryParams };
    }
}

export const getRouteParam = (state: AppState, name: string) => {
    return state.router && state.router.state ? state.router.state.params[name] : null;
};

export const routeParamChange = (paramName: string) =>
    pipe(
        ofType<RouterNavigatedAction<OpsRouterStateUrl>>(ROUTER_NAVIGATED),
        map(({ payload: { routerState } }) => routerState.params[paramName]),
        distinctUntilChanged()
    );
