import * as R from "ramda";
import { Arity1Fn } from "ramda/tools";

import { ObjectReducer } from "@ops/state";

export const rScanNext = R.curry(<T, TResult>(fn: (acc: TResult, value: T, next: T) => TResult, acc: TResult, list: T[]) => {
    let idx = 0;
    const len = list.length;
    const result = [acc];
    while (idx < len) {
        acc = fn(acc, list[idx], list[idx + 1]);
        result[idx + 1] = acc;
        idx += 1;
    }
    return result;
});

export const rFilter = <T>(fn: (value: T) => boolean) => (list: readonly T[]): readonly T[] => list.filter(fn);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Null = (): null => null;

export const update = R.curry(<T>(pred: (value: T) => boolean, partialOrReducer: Partial<T> | ObjectReducer<T>, list: readonly T[]): T[] => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reducer: ObjectReducer<any> = typeof partialOrReducer === "function" ? partialOrReducer : R.mergeDeepLeft(partialOrReducer);
    return R.map(R.when(pred, reducer), list);
});

const isObject = (o: never) => R.equals(R.type(o), "Object");
const rKeys = (fn: (key: string) => string): Arity1Fn => R.ifElse(isObject, renameKeys(fn), R.ifElse(Array.isArray, R.map(renameKeys(fn)), R.identity));
const rValues = <S, D>(fn: (value: S) => S | D): Arity1Fn => R.ifElse(isObject, mapValues(fn), R.ifElse(Array.isArray, R.map(mapValues(fn)), R.identity));
const rKeysMapper = (fn: (key: string) => string) => R.map(([key, value]) => [fn(key), rKeys(fn)(value)]);
const rValuesMapper = <S, D>(fn: (value: S) => S | D) => R.map(([key, value]) => [key, rValues(fn)(fn(value))]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renameKeys = R.curry(<S, D = unknown>(fn: (key: string) => string, obj: S) => (R.compose(R.fromPairs, rKeysMapper(fn), R.toPairs)(obj as any) as unknown) as D);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapValues = R.curry(<S, D>(fn: (value: S) => S | D, obj: unknown) => R.compose(R.fromPairs, rValuesMapper(fn), R.toPairs)(obj as any));
