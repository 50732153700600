import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { ActionHttpService, FixtureId } from "../services/action-http.service";
import { LiveUpdatesHubService } from "../services/live-updates-hub.service";
import * as ActionApiActions from "./action-api.actions";
import * as BoardCardActions from "./board-card.actions";
import * as LiveUpdatesHubActions from "./live-updates-hub.actions";

@Injectable({
    providedIn: "root"
})
export class ActionEffects {
    constructor(private actions$: Actions, private actionHttpService: ActionHttpService, private liveUpdatesHubService: LiveUpdatesHubService) {}

    loadActions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardCardActions.loadActions),
            exhaustMap(({ fixtureId }) =>
                this.actionHttpService.getV2(<FixtureId>{ fixtureId, source: "Fixture" }).pipe(
                    map((actions) => ActionApiActions.loadActionsSuccess({ fixtureId, actions })),
                    catchError((error) => of(ActionApiActions.loadActionsFailure({ fixtureId, error })))
                )
            )
        )
    );

    updateComplete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardCardActions.updateComplete),
            exhaustMap(({ fixtureId, actionId }) =>
                this.actionHttpService.patchIsCompleteV2(<FixtureId>{ fixtureId, source: "Fixture" }, actionId, true).pipe(
                    map(() => ActionApiActions.updateCompleteSuccess({ fixtureId, actionId })),
                    catchError((error) => of(ActionApiActions.updateCompleteFailure({ fixtureId, actionId, error })))
                )
            )
        )
    );

    updateIncomplete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BoardCardActions.updateIncomplete),
            exhaustMap(({ fixtureId, actionId }) =>
                this.actionHttpService.patchIsCompleteV2(<FixtureId>{ fixtureId, source: "Fixture" }, actionId, false).pipe(
                    map(() => ActionApiActions.updateIncompleteSuccess({ fixtureId, actionId })),
                    catchError((error) => of(ActionApiActions.updateIncompleteFailure({ fixtureId, actionId, error })))
                )
            )
        )
    );

    updateAction$ = createEffect(() => this.liveUpdatesHubService.actionsUpdated$.pipe(map((action) => LiveUpdatesHubActions.updateAction({ action }))));

    deleteAction$ = createEffect(() => this.liveUpdatesHubService.actionsDeleted$.pipe(map((action) => LiveUpdatesHubActions.deleteAction({ action }))));
}
