import { addAssociatedCargoReducer } from "./form/add-associated-cargo";
import { addAssociatedCargoExpenseReducer } from "./form/add-associated-cargo-expense";
import { collapseAssociatedCargoReducer, expandAssociatedCargoReducer } from "./form/expand-associated-cargo";
import { moveAssociatedCargoReducer } from "./form/move-associated-cargo";
import { prefillAssociatedCargoReducer } from "./form/prefill-associated-cargo";
import { prefillAssociatedCargoDischargeReducer } from "./form/prefill-discharge-quantity";
import { prefillAssociatedCargoDischargeSpecialisedReducer } from "./form/prefill-discharge-quantity-specialised";
import { prefillAssociatedCargoExpenseFreightRateReducer } from "./form/prefill-expense-freight-rate";
import { removeAssociatedCargoReducer } from "./form/remove-associated-cargo";
import { removeAssociatedCargoExpenseReducer } from "./form/remove-associated-cargo-expense";
import { setFreightRatesGasReducer } from "./form/set-freight-rates-gas";

export default [
    addAssociatedCargoReducer,
    removeAssociatedCargoReducer,
    expandAssociatedCargoReducer,
    collapseAssociatedCargoReducer,
    addAssociatedCargoExpenseReducer,
    removeAssociatedCargoExpenseReducer,
    prefillAssociatedCargoReducer,
    prefillAssociatedCargoDischargeReducer,
    prefillAssociatedCargoDischargeSpecialisedReducer,
    setFreightRatesGasReducer,
    moveAssociatedCargoReducer,
    prefillAssociatedCargoExpenseFreightRateReducer
];
