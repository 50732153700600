import { Coa, CoaId, getNominatedVesselNameOrTbn, Lifting } from "../model";
import { getLiftingLaycan } from "./liftings-index-helper";

export type LiftingInfo = Readonly<{
    lay: string;
    can: string;
    coaName: string;
    coaReference: string;
    coaId: CoaId;
    cpDate: string;
    vesselName: string;
}>;

export const liftingInfo = (): LiftingInfo => ({
    lay: null,
    can: null,
    coaName: null,
    coaReference: null,
    coaId: null,
    cpDate: null,
    vesselName: null
});

export const getLiftingInfo = (lifting: Lifting, coa: Coa): LiftingInfo => {
    const laycan = getLiftingLaycan(lifting, coa.driver);
    return {
        coaName: coa.name,
        coaReference: coa.reference,
        coaId: coa.coaId,
        cpDate: coa.charterpartyDate,
        vesselName: getNominatedVesselNameOrTbn(lifting.vessels),
        lay: laycan?.from,
        can: laycan?.to
    };
};
