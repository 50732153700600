import { Injectable } from "@angular/core";

import { UpdateFixturePopupCommentsCommand } from "./update-fixture-popup-comments-command";
import { CommandHandler } from "../../mediator/command-handlers/command-handler";
import { Fixture } from "../../shared/models";

@Injectable({
    providedIn: "root"
})
export class UpdateFixturePopupCommentsCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateFixturePopupCommentsCommand) {
        fixture.comments = command.model.comments;
    }
}
