import { createAction, On, on, props } from "@ngrx/store";
import { updateGroup } from "ngrx-forms";

import { opsAddArrayControl } from "@ops/state";

import { updateActivityForm } from "../../activities/shared";
import { ActivityId, BerthId, createLaytimeEventId, DestinationId, FixturesState, getDivision, LaytimeEventForm, laytimeEventForm } from "../../model";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const addLaytimeEventAction = createAction(
    "[Voyage Form] Add Laytime Event",
    props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId; index: number }>()
);

/* REDUCERS */
export const addLaytimeEventReducer: On<FixturesState> = on(addLaytimeEventAction, (state, action) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        const division = getDivision(fixtureState);

        return {
            ...voyageState,
            form: updateActivityForm(action, (activityForm) => {
                const laytimeEvents = activityForm.value.laytimeEvents;
                const newLaytimeEventId = createLaytimeEventId();
                const newLaytimeEvent = <LaytimeEventForm>{
                    ...laytimeEventForm(division, newLaytimeEventId),
                    eventDate:
                        action.index - 1 >= 0 && laytimeEvents[action.index - 1].eventDate
                            ? laytimeEvents[action.index - 1].eventDate
                            : action.index >= 0 && action.index < laytimeEvents.length
                            ? laytimeEvents[action.index].eventDate
                            : null
                };

                return updateGroup(activityForm, {
                    laytimeEvents:
                        action.index >= 0
                            ? opsAddArrayControl(newLaytimeEvent, action.index, { markAsEditing: true, focusControlName: "type" })
                            : opsAddArrayControl(newLaytimeEvent, { markAsEditing: true, focusControlName: "type" })
                });
            })(voyageState.form)
        };
    })
);
