import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Operation } from "fast-json-patch";
import { Observable } from "rxjs";

import { AppConfigService, AppInsightsService } from "@ops/core";
import { CreateWorksheet } from "@ops/state";

import { CreateWorksheetModel, Worksheet } from "../shared/worksheet.model";

@Injectable({
    providedIn: "root"
})
export class WorksheetHttpService {
    private worksheetServiceUrl: string;

    constructor(private httpClient: HttpClient, private appInsightsService: AppInsightsService, private appConfigService: AppConfigService) {}

    get(): Observable<Worksheet[]> {
        this.appInsightsService.logInfo("Getting all worksheets");
        return this.httpClient.get<Worksheet[]>(`${this.getWorksheetServiceUrl()}/api/v1.0/worksheets`);
    }

    post(worksheet: CreateWorksheetModel | CreateWorksheet): Observable<HttpResponse<any>> {
        this.appInsightsService.logInfo(`Saving worksheet, worksheet: ${worksheet}`);
        return this.httpClient.post(`${this.getWorksheetServiceUrl()}/api/v1.0/worksheets`, worksheet, { observe: "response" });
    }

    patch(id: string, operations: Operation[]): Observable<any> {
        this.appInsightsService.logInfo(`Updating worksheet, operations: ${operations}`);
        return this.httpClient.patch(`${this.getWorksheetServiceUrl()}/api/v1.0/worksheets/${id}`, operations);
    }

    delete(id: string): Observable<any> {
        this.appInsightsService.logInfo(`Removing worksheet, id: ${id}`);
        return this.httpClient.delete(`${this.getWorksheetServiceUrl()}/api/v1.0/worksheets/${id}`);
    }

    rename(id: string, name: string): Observable<any> {
        this.appInsightsService.logInfo(`Renaming worksheet, id: ${id}, name: ${name}`);
        const patch = [{ op: "replace", path: "/name", value: name }];
        return this.httpClient.patch(`${this.getWorksheetServiceUrl()}/api/v1.0/worksheets/${id}/rename`, patch);
    }

    private getWorksheetServiceUrl() {
        if (!this.worksheetServiceUrl) {
            this.worksheetServiceUrl = this.appConfigService.config.worksheetServiceUrl;
        }
        return this.worksheetServiceUrl;
    }
}
