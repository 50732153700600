import { box, Boxed, createFormArrayState, FormArrayState, unbox } from "ngrx-forms";
import { v4 as uuid } from "uuid";

import { Identity } from "@ops/shared";

import { QuantityModel } from "../../../../fixture/shared/models/form-models";
import { CargoProduct } from "../../../../fixture/state";
import { isNullOrUndefined } from "../../../../shared/utils";
import { ActivityLocationId } from "./activity-location";
import { AllowanceUnit, QuantityUnit, Reversible } from "./laytime-calculation";
import { getDiff } from "./utils";
import { CargoId } from "./voyage";

export type ActivityCargoId = Identity<string, "ActivityCargoId">;
export const createActivityCargoId = (): ActivityCargoId => uuid() as ActivityCargoId;

export type ActivityCargo = Readonly<{
    id: ActivityCargoId;
    cargoId?: CargoId;
    productId: number;
    allowance?: string | null;
    allowanceUnit?: AllowanceUnit | null;
    extraHours?: string;
    reversible?: Reversible;
    quantity?: string;
    quantityUnit: QuantityUnit;
    name?: string;
    orderId?: string;
}>;
export const activityCargo = (id: string, cargoId: string, productId: number, values?: Partial<ActivityCargo>): ActivityCargo => ({
    quantityUnit: "MT",
    ...values,
    id: id as ActivityCargoId,
    cargoId: cargoId as CargoId,
    productId
});

export type ActivityCargoForm = Readonly<{
    id: ActivityCargoId;
    cargoId?: CargoId;
    cargoProduct: Boxed<CargoProduct>;
    quantity?: string;
    allowance: Boxed<QuantityModel>;
    reversible?: Reversible;
    extraHours?: string;
}>;

export const activityCargoForm = (): ActivityCargoForm => ({
    id: createActivityCargoId(),
    cargoId: null,
    cargoProduct: box(null),
    quantity: null,
    allowance: box({ value: null, unit: null }),
    reversible: null,
    extraHours: null
});

export const toActivityCargoForm = (source: ActivityCargo): ActivityCargoForm => ({
    id: source.id,
    cargoId: source.cargoId,
    cargoProduct: box(source.productId && source.name && { id: source.productId, name: source.name }),
    quantity: source.quantity,
    allowance: box({ value: source.allowance && Number(source.allowance), unit: source.allowanceUnit }),
    reversible: source.reversible,
    extraHours: source.extraHours
});

export const toActivityCargo = (source: ActivityCargoForm): ActivityCargo => {
    const product = unbox(source.cargoProduct);
    const allowance = unbox(source.allowance);

    return {
        id: source.id,
        cargoId: source.cargoId,
        productId: product?.id,
        allowance: isNullOrUndefined(allowance.value) ? null : String(allowance.value),
        allowanceUnit: <AllowanceUnit>allowance?.unit ?? null,
        extraHours: source.extraHours,
        reversible: source.reversible,
        quantity: source.quantity,
        quantityUnit: "MT",
        name: product?.name
    };
};

export const toActivityCargoDiff = (source: ActivityCargoForm, original: ActivityCargo): Partial<ActivityCargo> => getDiff(original, toActivityCargo(source));

export const activityCargoFormsKey = "activityCargoForms";
export const createActivityCargoFormsState = (activityLocationId: ActivityLocationId, activityLocationCargoes: ReadonlyArray<ActivityCargo>): FormArrayState<ActivityCargoForm> =>
    createFormArrayState(`${activityLocationId}.${activityCargoFormsKey}`, activityLocationCargoes.map(toActivityCargoForm));
