import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { Enumeration } from "@ops/shared/reference-data";

import { CargoSuggestionService } from "./cargo-suggestion.service";
import { AutosuggestComponent } from "../../../../../shared/components/autosuggest/autosuggest.component";

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CargoAutosuggestComponent),
    multi: true
};

@Component({
    selector: "ops-cargo-autosuggest",
    templateUrl: "./cargo-autosuggest.component.html",
    styleUrls: ["../../../../../shared/components/autosuggest/autosuggest.component.scss"],
    providers: [VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CargoAutosuggestComponent extends AutosuggestComponent<Enumeration> {
    static componentName = "CargoAutosuggestComponent";

    @Input() readonly: boolean;
    @Input() sector: string;

    constructor(protected cargoSuggestionService: CargoSuggestionService, protected element: ElementRef, protected changeDetector: ChangeDetectorRef) {
        super(cargoSuggestionService, element, changeDetector);
    }

    getSuggestions(term: string) {
        return this.cargoSuggestionService.getSuggestionsForDivision(term, this.sector);
    }
}
