<ops-popup [title]="model.title">
    <ng-container>
        <div class="form-row no-gutters" *ngFor="let row of editorRows; let i = index;">
            <div class="form-group row col pt-0" >
                <div class="col-5">
                    <label for="field">Field</label>
                    <ops-field-editor-dropdown
                        id="field"
                        [fields]="model.fields"
                        [prohibitedFieldIds]="selectedFields"
                        [placeholder]="placeholder"
                        [(ngModel)]="row.id"
                        (change)="fieldChange(row)"
                        data-test-field-editor-row-dropdown
                    ></ops-field-editor-dropdown>
                </div>
                <div class="col-6">
                    <label for="value">Value</label>
                    <ops-quantity-input
                        id="value"
                        [type]="row.field?.dataType"
                        placeholder="Select"
                        [inputNumberFormat]="row.field?.numberFormat || { precision: 2 }"
                        [allowClear]="true"
                        dropdownBindValue="name"
                        [(ngModel)]="row.quantity"
                        appendTo="body"
                        data-test-field-editor-row-quantity-input
                    ></ops-quantity-input>
                </div>
                <div class="row-controls col-1">
                    <ops-icon-button type="add" icon="plus" (click)="onAdd(i)" [disabled]="model.fields.length === editorRows.length"></ops-icon-button>
                    <ops-icon-button type="remove" icon="delete" (click)="onRemove(i)" [disabled]="editorRows.length <= 1"></ops-icon-button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container footer>
        <ops-action-button (click)="cancel()">CANCEL</ops-action-button>
        <ops-action-button (click)="next()" [disabled]="!enableNext()" type="primary">APPLY</ops-action-button>
    </ng-container>
</ops-popup>