import { AfterContentInit, Directive, ElementRef, Inject, Optional } from "@angular/core";
import { NgSelectComponent } from "@ng-select/ng-select";

/**
 * Focuses an element on creation.
 */
@Directive({
    selector: "[opsFocus]"
})
export class FocusDirective implements AfterContentInit {
    constructor(private element: ElementRef, @Optional() @Inject(NgSelectComponent) private ngSelect: NgSelectComponent) {}

    ngAfterContentInit() {
        setTimeout(() => {
            this.element.nativeElement.focus();
            if (this.ngSelect) {
                this.ngSelect.focus();
            }
        });
    }
}
