import { Injectable } from "@angular/core";

import { CommandHandler, Mediator } from "../../mediator";
import { Voyage } from "../../shared/models";
import { SyncAllDestinationLaytimeEventsCommand } from "./sync-all-destination-laytime-events-command";
import { SyncLaytimeEventsCommand } from "./sync-laytime-events-command";

@Injectable({
    providedIn: "root"
})
export class SyncAllDestinationLaytimeEventsCommandHandler implements CommandHandler {
    constructor(private mediator: Mediator) {}

    handle(voyage: Voyage, command: SyncAllDestinationLaytimeEventsCommand): void {
        command.destinations.forEach((d) => {
            d.berths.forEach((b) => {
                this.mediator.send(voyage, new SyncLaytimeEventsCommand(d.id, b.id));
            });
        });
    }
}
