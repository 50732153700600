import { Injectable } from "@angular/core";

import { dateToISOString } from "../../../shared/date-utils/date-utilities";
import { Division, Enumeration } from "../../../shared/reference-data";
import { hasItems } from "../../../shared/utils";
import { CommandHandler } from "../../mediator";
import { EstimatedClaimValueCalculator } from "../../services/estimated-claim-value-calculator";
import { DemurrageClaim, Destination, Fixture, User } from "../../shared/models";
import { DemurrageClaimType, DemurrageClaimTypeName } from "../../shared/models/enums/demurrage-claim-type";
import { LaytimeSummaryModel } from "../../shared/models/form-models/laytime-summary-aggregate.model";
import { TabNavigation } from "../../shared/navigation/tab-navigation.model";
import { TabNavigationService } from "../../shared/navigation/tab-navigation.service";
import { CreateClaimCommand } from "./create-claim.command";

@Injectable({
    providedIn: "root"
})
export class CreateClaimCommandHandler implements CommandHandler {
    constructor(private ecvCalculator: EstimatedClaimValueCalculator, private tabNavigationService: TabNavigationService) {}

    handle(fixture: Fixture, command: CreateClaimCommand) {
        if (!fixture.demurrage || !fixture.demurrage.claims) {
            fixture.demurrage.claims = [];
        }

        const laytimeSummary = this.ecvCalculator.createLaytimeSummaryModel(fixture, command.destinations);

        if (this.ecvCalculator.isLaytimeFixed(fixture)) {
            this.generateFixedEcv(laytimeSummary, fixture, command.currentUser, command.destinations);
        } else if (this.ecvCalculator.isLaytimeNonFixed(fixture)) {
            this.generateNonFixedEcv(laytimeSummary, fixture, command.currentUser, command.destinations);
        }
    }

    private generateFixedEcv(laytimeSummary: LaytimeSummaryModel, fixture: Fixture, currentUser: User, destinations: Destination[]) {
        const fixedClaim = laytimeSummary.destinations ? laytimeSummary.destinations.find((d) => d.berths && d.berths.find((b) => b.fixed === true)) : null;
        if (!fixedClaim) {
            return;
        }

        const berthFixedClaim = fixedClaim.berths.find((b) => b.fixed === true);
        if (berthFixedClaim.claimValue === 0) {
            return;
        }

        if (!hasItems(fixture.demurrage.claims)) {
            const claim = this.generateClaim(fixture, berthFixedClaim.claimType, currentUser, destinations, null, null);
            fixture.demurrage.claims.push(claim);
        } else {
            fixture.demurrage.claims[0].type = new Enumeration(berthFixedClaim.claimType, DemurrageClaimTypeName.get(berthFixedClaim.claimType));
            fixture.demurrage.claims[0].estimatedClaimValue = this.getEstimatedClaimValue(fixture, berthFixedClaim.claimType, destinations, null, null);
        }

        this.tabNavigationService.setCurrentTab(new TabNavigation("demurrageTab", "initialClaimValue"));
    }

    private generateNonFixedEcv(laytimeSummary: LaytimeSummaryModel, fixture: Fixture, currentUser: User, destinations: Destination[]) {
        const existingClaims = fixture.demurrage.claims;

        if (
            (fixture.division.id === Division.Specialised.id || fixture.division.id === Division.Gas.id || fixture.division.id === Division.Pcg.id) &&
            laytimeSummary.claimValue !== 0
        ) {
            const claimType = DemurrageClaimType.FullClaim;
            const existingClaim = existingClaims.find((c) => c.type && c.type.id === claimType);

            if (!existingClaim) {
                const claim = this.generateClaim(fixture, claimType, currentUser, destinations, null, null);
                fixture.demurrage.claims.push(claim);
            } else {
                existingClaim.estimatedClaimValue = this.getEstimatedClaimValue(fixture, claimType, destinations, existingClaim.berthId, existingClaim.destinationId);
            }
        }

        if (fixture.division.id === Division.DryCargo.id) {
            if (laytimeSummary.destinations) {
                laytimeSummary.destinations.forEach((destination) => {
                    if (destination.berths && destination.berths.length > 0) {
                        destination.berths.forEach((berth) => {
                            const berthDestinationClaim = existingClaims.find((c) => c.berthId === berth.berthId && c.destinationId === destination.destinationId);

                            let claim = null;
                            if (berth.claimValue > 0 && !berth.reversible) {
                                if (!berthDestinationClaim) {
                                    claim = this.generateClaim(fixture, berth.claimType, currentUser, destinations, berth.berthId, destination.destinationId);
                                    fixture.demurrage.claims.push(claim);
                                } else {
                                    berthDestinationClaim.type = new Enumeration(berth.claimType, DemurrageClaimTypeName.get(berth.claimType));
                                    berthDestinationClaim.estimatedClaimValue = this.getEstimatedClaimValue(
                                        fixture,
                                        berth.claimType,
                                        destinations,
                                        berthDestinationClaim.berthId,
                                        berthDestinationClaim.destinationId
                                    );
                                }
                            }

                            const existingReversibleClaim = existingClaims.find(
                                (c) =>
                                    (!c.berthId && !c.destinationId && c.type) ||
                                    (c.berthId && c.berthId === berth.berthId && c.destinationId && c.destinationId === destination.destinationId && c.type)
                            );
                            if (berth.reversible && berth.claimValue > 0) {
                                if (!existingReversibleClaim) {
                                    claim = this.generateClaim(fixture, berth.claimType, currentUser, destinations, berth.berthId, destination.destinationId);
                                    fixture.demurrage.claims.push(claim);
                                } else {
                                    existingReversibleClaim.type = new Enumeration(berth.claimType, DemurrageClaimTypeName.get(berth.claimType));
                                    existingReversibleClaim.estimatedClaimValue = this.getEstimatedClaimValue(
                                        fixture,
                                        berth.claimType,
                                        destinations,
                                        existingReversibleClaim.berthId,
                                        existingReversibleClaim.destinationId
                                    );
                                }
                            }
                        });
                    }
                });
            }
        }

        this.tabNavigationService.setCurrentTab(new TabNavigation("demurrageTab", "initialClaimValue"));
    }

    private generateClaim(fixture: Fixture, claimType: DemurrageClaimType, currentUser: User, destinations: Destination[], berthId: number, destinationId: number): DemurrageClaim {
        const maxId = fixture.demurrage.claims.reduce((max, current) => (current.demurrageClaimId > max ? current.demurrageClaimId : max), 0);
        const claimTypeName = DemurrageClaimTypeName.get(claimType);

        const claim = <DemurrageClaim>{
            demurrageClaimId: maxId + 1,
            gainId: null,
            comments: null,
            type: new Enumeration(claimType, claimTypeName),
            currency: fixture.currency,
            receivedFromOwnerDate: null,
            sentToChartererDate: null,
            chartererAcknowledgedReceiptDate: null,
            estimatedClaimValue: this.getEstimatedClaimValue(fixture, claimType, destinations, berthId, destinationId),
            initialClaimValue: null,
            interimClaimValue: null,
            finalClaimValue: null,
            destinationId: destinationId,
            berthId: berthId,
            agreedDate: null,
            datePaid: null,
            lastContactedDateTime: null,
            commissionDate: null,
            ownerInvoiceNumber: null,
            awaitingHardCopy: false,
            awaitingDocuments: false,
            complete: false,
            lastUpdatedByUser: currentUser,
            lastUpdatedDate: dateToISOString(new Date()),
            claimHandledBy: null
        };

        return claim;
    }

    private getEstimatedClaimValue(fixture: Fixture, claimType: DemurrageClaimType, destinations: Destination[], berthId: number, destinationId: number) {
        const placeholderClaim = <DemurrageClaim>(<any>{ destinationId, berthId, type: claimType });

        return this.ecvCalculator.calculateCurrentECV(fixture, destinations, placeholderClaim);
    }
}
