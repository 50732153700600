import { Injectable } from "@angular/core";

import { isNullOrUndefined } from "@ops/shared";
import { AtSeaVesselStatus, CargoBerthActivityType } from "@ops/shared/reference-data";

import { Destination } from "../shared/models";
import { AtSeaBunkerConsumption } from "../shared/models/dtos/at-sea-bunker-consumption";
import { DestinationForm } from "../state/model";

const LOAD_BUNKERING_TYPE_IDS = [CargoBerthActivityType.Load.id, CargoBerthActivityType.BallastLeg.id];

export function updateVesselStatuses(
    destinations: ReadonlyArray<Destination | DestinationForm>,
    atSeaBunkerConsumptions: ReadonlyArray<AtSeaBunkerConsumption>
): ReadonlyArray<AtSeaBunkerConsumption> {
    if (!destinations || destinations.length < 2) {
        return atSeaBunkerConsumptions;
    }

    let updatedAtSeaBunkerConsumptions: AtSeaBunkerConsumption[];

    let lastKnownCargoStatus: AtSeaVesselStatus = null;

    for (let i = 0; i < destinations.length - 1; i++) {
        const destination = destinations[i];
        const fromDestinationId = destinations[i].id;
        const toDestinationId = destinations[i + 1].id;

        const atSeaBunkerConsumption = atSeaBunkerConsumptions.find((x) => x.destinationFromId === fromDestinationId && x.destinationToId === toDestinationId);
        if (!atSeaBunkerConsumption) {
            continue;
        }

        if (!destination.berths) {
            continue;
        }

        const activityTypes = (<[]>destination.berths)
            .map((berth: any) => berth.activities || berth.cargoBerthActivities)
            .reduce((a, b) => a.concat(b), [])
            .map((activity: any) => activity.type)
            .filter((type: CargoBerthActivityType) => !isNullOrUndefined(type) && LOAD_BUNKERING_TYPE_IDS.includes(type.id));

        if (activityTypes.length) {
            const lastActivityType = activityTypes[activityTypes.length - 1];

            switch (lastActivityType.id) {
                case CargoBerthActivityType.Load.id:
                    lastKnownCargoStatus = AtSeaVesselStatus.Laden;
                    break;
                case CargoBerthActivityType.BallastLeg.id:
                    lastKnownCargoStatus = AtSeaVesselStatus.Ballast;
                    break;
            }
        }

        if (lastKnownCargoStatus && lastKnownCargoStatus !== atSeaBunkerConsumption.vesselStatus) {
            const atSeaBunkerConsumptionIndex = atSeaBunkerConsumptions.indexOf(atSeaBunkerConsumption);

            if (!updatedAtSeaBunkerConsumptions) {
                updatedAtSeaBunkerConsumptions = [...atSeaBunkerConsumptions];
            }

            updatedAtSeaBunkerConsumptions[atSeaBunkerConsumptionIndex] = { ...atSeaBunkerConsumption, vesselStatus: lastKnownCargoStatus };
        }
    }

    return updatedAtSeaBunkerConsumptions || atSeaBunkerConsumptions;
}

/**
 * @deprecated
 */
@Injectable({
    providedIn: "root"
})
export class VoyageVesselStatusCalculator {
    update(destinations: Destination[], atSeaBunkerConsumptions: AtSeaBunkerConsumption[]) {
        const updatedAtSeaBunkerConsumptions = updateVesselStatuses(destinations, atSeaBunkerConsumptions || []);

        if (updatedAtSeaBunkerConsumptions !== atSeaBunkerConsumptions) {
            updatedAtSeaBunkerConsumptions.forEach((atSeaBunkerConsumption, i) => {
                atSeaBunkerConsumptions[i].vesselStatus = atSeaBunkerConsumption.vesselStatus;
            });
        }
    }
}
