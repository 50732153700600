import { Action, On, createReducer } from "@ngrx/store";

import { validateCoaCargoForm } from "./cargo/form/validation";
import coaCargoReducers from "./cargo/reducers";
import coaReducers from "./coa/reducers";
import coaCompanyReducers from "./company/reducers";
import gridReducers from "./grid/reducers";
import { validateCoaHeaderForm } from "./header/form/validation";
import coaHeaderReducers from "./header/reducers";
import { validateLiftingCargoForm, validateLiftingCargoLaycanForm } from "./lifting/cargo/form/validation";
import liftingReducers from "./lifting/reducers";
import { validateVesselNominationForm } from "./lifting/vessel-nomination/form/validation";
import vesselNominationReducers from "./lifting/vessel-nomination/reducers";
import { validateLiftingHeaderForm } from "./lifting-header/form/validation";
import liftingHeaderReducers from "./lifting-header/reducers";
import { validateLocationForm } from "./locations/form/validation";
import locationReducers from "./locations/reducers";
import { CoasState, CoaState, LiftingState } from "./model";
import { CoaIndexItem } from "./model/coa-index-item";
import { validateNominationTaskForm } from "./nomination-tasks/form/validation";
import nominationTaskReducers from "./nomination-tasks/reducers";
import { ObjectMapUpdateFns, onObjectMapForms, wrapReducerWithObjectMapFormStatesUpdate } from "../../state/forms-reducer";
import { emptyGridState, emptyObjectMap, } from "../../state/utils";

export const initialCoasState: CoasState = {
    coas: emptyObjectMap<CoaState>(),
    liftings: emptyObjectMap<LiftingState>(),
    grid: emptyGridState<CoaIndexItem>()
};

const rawReducer = createReducer(
    initialCoasState,
    ...coaReducers,
    ...coaHeaderReducers,
    ...locationReducers,
    ...nominationTaskReducers,
    ...coaCargoReducers,
    ...liftingReducers,
    ...liftingHeaderReducers,
    ...gridReducers,
    ...coaCompanyReducers,
    ...vesselNominationReducers,
    onObjectMapForms((s) => s.coas) as On<CoasState>,
    onObjectMapForms((s) => s.liftings) as On<CoasState>
);

export const reducer = (state: CoasState | undefined, action: Action) => {
    const validateCoa = {
        coaHeaderForm: validateCoaHeaderForm,
        loadLocationForm: validateLocationForm,
        dischargeLocationForm: validateLocationForm,
        cargoForm: validateCoaCargoForm,
        nominationTaskForm: validateNominationTaskForm
    };

    const validateLifting: ObjectMapUpdateFns<CoasState, Required<LiftingState>> = {
        liftingHeaderForm: validateLiftingHeaderForm,
        vesselNominationForm: validateVesselNominationForm,
        liftingCargoLaycanForm: validateLiftingCargoLaycanForm,
        liftingCargoForm: validateLiftingCargoForm,
    };

    let reducedState = rawReducer(state, action);
    reducedState = wrapReducerWithObjectMapFormStatesUpdate((s: CoasState) => s.coas, validateCoa)(reducedState);
    reducedState = wrapReducerWithObjectMapFormStatesUpdate((s: CoasState) => s.liftings, validateLifting)(reducedState);

    return reducedState;
}
