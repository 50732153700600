<div class="tab-header">
    <div class="tab-icon">
        <span [ngClass]="{ 'tab-icon--hidden' : isValid$ | async }" class="has-icon icon--warning"></span>
    </div>
    <div class="tab-title" [ngbTooltip]="tab.title" container="body" [overflowOnlyTooltip]="true">
        {{ tab.title }}
    </div>
    <div class="tab-activity">
        <ops-fixture-popup-activity-tab-header-form [form]="form$ | async"
            [activityDropdownTypes]="activityDropdownTypes$ | async"
            [isReadOnly]="isReadOnly$ | async"></ops-fixture-popup-activity-tab-header-form>
    </div>
</div>