import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { AppConfigService } from "@ops/core";
import { Currency, IndexResponse } from "@ops/shared";
import { SearchToken, Sorting, Suggestion, SuggestionTerm, toSearchToken, toQueriesParam } from "@ops/state";

import {
    ActivityType,
    AddNominationTask,
    Cargo,
    CargoId,
    Coa,
    CancellationReason,
    CoaDriver,
    CoaHeaderForm,
    CoaId,
    CoaStatus,
    Company,
    LocationId,
    NominationTaskId,
    UpdateNominationTask,
    UserId
} from "../state";
import { CoaIndexItem } from "../state/model/coa-index-item";
import { CompletedVoyage, FixtureIndexSearchResults, mapSearchResultToCompletedVoyage } from "../state/model/completed-voyage";

export type UpdateCoaDetails = Readonly<{
    charterpartyDate: string;
    driver: CoaDriver;
    status: CoaStatus;
    currency: Currency;
    operators: ReadonlyArray<UserId>;
    cancellationReason: CancellationReason;
}>;

export const formToUpdateCoaDetails = (source: CoaHeaderForm): UpdateCoaDetails => ({
    charterpartyDate: source.charterpartyDate,
    driver: source.driver,
    status: source.contractStatus,
    currency: source.currency,
    cancellationReason: source.cancellationReason,
    operators: source.operators.value.map((x) => x.userId)
});

@Injectable({
    providedIn: "root"
})
export class CoaHttpService {
    private readonly coaServiceUrl: string;
    private readonly searchServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.coaServiceUrl = appConfigService.config.coaServiceUrl;
        this.searchServiceUrl = appConfigService.config.searchServiceUrl;
    }

    get(coaId: CoaId): Observable<Coa> {
        return this.httpClient.get<Coa>(`${this.coaServiceUrl}/api/v1/coa/${coaId}`);
    }

    updateDetails(coaId: CoaId, details: UpdateCoaDetails) {
        return this.httpClient.put(`${this.coaServiceUrl}/api/v1/coa/${coaId}/details`, details);
    }

    addLocation(coaId: CoaId, activity: ActivityType, locationId: LocationId): Observable<unknown> {
        return this.httpClient.put(`${this.coaServiceUrl}/api/v1/coa/${coaId}/${activity}/locations/${locationId}`, {});
    }

    removeLocation(coaId: CoaId, activity: ActivityType, locationId: LocationId): Observable<unknown> {
        return this.httpClient.delete(`${this.coaServiceUrl}/api/v1/coa/${coaId}/${activity}/locations/${locationId}`, {});
    }

    addCargo(coaId: CoaId, cargo: Cargo): Observable<unknown> {
        return this.httpClient.post(`${this.coaServiceUrl}/api/v1/coa/${coaId}/cargoes`, cargo);
    }

    updateCargo(coaId: CoaId, cargo: Cargo): Observable<unknown> {
        return this.httpClient.put(`${this.coaServiceUrl}/api/v1/coa/${coaId}/cargoes/${cargo.cargoId}`, cargo);
    }

    removeCargo(coaId: CoaId, cargoId: CargoId): Observable<unknown> {
        return this.httpClient.delete(`${this.coaServiceUrl}/api/v1/coa/${coaId}/cargoes/${cargoId}`);
    }

    addNominationTask(coaId: CoaId, nominationTask: AddNominationTask): Observable<unknown> {
        return this.httpClient.post(`${this.coaServiceUrl}/api/v1/coa/${coaId}/nominationTasks`, nominationTask);
    }

    updateNominationTask(coaId: CoaId, nominationTaskId: NominationTaskId, nominationTask: UpdateNominationTask): Observable<unknown> {
        return this.httpClient.put(`${this.coaServiceUrl}/api/v1/coa/${coaId}/nominationTasks/${nominationTaskId}`, nominationTask);
    }

    removeNominationTask(coaId: CoaId, nominationTaskId: NominationTaskId): Observable<unknown> {
        return this.httpClient.delete(`${this.coaServiceUrl}/api/v1/coa/${coaId}/nominationTasks/${nominationTaskId}`);
    }

    addCompany(coaId: CoaId, company: Company): Observable<unknown> {
        return this.httpClient.post(`${this.coaServiceUrl}/api/v1/coa/${coaId}/contacts`, company);
    }

    removeCompany(coaId: CoaId, company: Company): Observable<unknown> {
        return this.httpClient.request("delete", `${this.coaServiceUrl}/api/v1/coa/${coaId}/contacts`, { body: company });
    }

    getLatestCompletedVoyage(coaReference: string): Observable<CompletedVoyage> {
        const tokens: ReadonlyArray<SearchToken> = [toSearchToken("CoA-Number", coaReference), toSearchToken("Voyage-Status", "Complete")];
        const filterQuery = tokens.map(toQueriesParam).join("&");

        return this.httpClient
            .get(`${this.searchServiceUrl}/api/v1.0/fixtures/search?${filterQuery}&skip=0&take=100&sortOrder=desc&sortColumn=LatestHosesDisconnectedDate`)
            .pipe(map((response: FixtureIndexSearchResults) => (response.count > 0 ? mapSearchResultToCompletedVoyage(response.documents[0]) : null)));
    }

    searchIndex(criteria: ReadonlyArray<SearchToken>, skip: number, take: number, sorting: Sorting): Observable<IndexResponse<CoaIndexItem>> {
        let filterQuery = "";
        if (criteria && criteria.length > 0) {
            filterQuery = criteria.map(toQueriesParam).join("&") + "&";
        }

        let sortingQuery = "";
        if (sorting) {
            sortingQuery = `&sortOrder=${sorting.order}&sortColumn=${sorting.column}`;
        }

        const url = `${this.coaServiceUrl}/api/v1/coa/search?${filterQuery}skip=${skip}&take=${take}${sortingQuery}`;
        return this.httpClient.get<IndexResponse<CoaIndexItem>>(url);
    }

    getSuggestions(suggestionTerm: SuggestionTerm): Observable<ReadonlyArray<Suggestion>> {
        return !suggestionTerm ? of([]) : this.httpClient.get<ReadonlyArray<Suggestion>>(`${this.coaServiceUrl}/api/v1/coa/suggestions?query=${suggestionTerm}`);
    }
}
