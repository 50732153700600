import { ColumnState } from "@ag-grid-community/core/dist/cjs/columns/columnModel";
import * as R from "ramda";

import { WorksheetState } from "./state";
import { GridColumnPinDirection } from "../../fixture/shared/models/enums/grid-column-pin-direction";
import { GridType } from "../../fixture/shared/models/enums/grid-type";
import { SortOrder } from "../../fixture/shared/models/enums/sort-order";
import {
    ColumnDefinition,
    ColumnSort,
    ComparableWorksheet,
    CreateWorksheet,
    Sorting,
    Worksheet,
    WorksheetId,
    WorksheetListItem,
    WorksheetLocalData,
    WorksheetRenameForm
} from "../model";

export const toColumnDefinition = (columnState: ColumnState): ColumnDefinition => ({
    columnId: columnState.colId,
    hidden: columnState.hide === true,
    pin: mapPinnedFromColumnState(columnState.pinned as string),
    width: columnState.width
});

export const toColumnState = (columnDefinition: ColumnDefinition): ColumnState => ({
    colId: columnDefinition.columnId,
    width: columnDefinition.width,
    hide: columnDefinition.hidden,
    pinned: mapPinnedFromColumnDefinition(columnDefinition.pin)
});

export const toColumnSort = (sorting: Sorting): ColumnSort => ({
    columnId: sorting.column,
    order: toSortOrder(sorting.order)
});

export const getWorksheetTypeLocalStorageIdentifier = (gridType: GridType): string => `worksheetType${gridType}`;

export const WORKSHEET_GRID_RENAME_FORM_ID = "WorksheetGridRenameForm";

export const getWorksheetListRenameFormId = (id: WorksheetId) => `WorksheetListRenameForm.${id}`;

const toSortOrder = (order: string): SortOrder => (order ? (order === "asc" ? SortOrder.Ascending : SortOrder.Descending) : null);

const mapPinnedFromColumnState = (pinned: string): GridColumnPinDirection =>
    pinned ? (pinned === "right" ? GridColumnPinDirection.PinRight : GridColumnPinDirection.PinLeft) : GridColumnPinDirection.NoPin;

const mapPinnedFromColumnDefinition = (pinned: GridColumnPinDirection): string => {
    switch (pinned) {
        case GridColumnPinDirection.PinLeft: {
            return "left";
        }
        case GridColumnPinDirection.PinRight: {
            return "right";
        }
        default: {
            return null;
        }
    }
};

export const worksheetRenameForm = (name: string = null, worksheetId: WorksheetId = null): WorksheetRenameForm => ({
    worksheetId,
    name: name ?? "Untitled Worksheet"
});

export const toListItem = (state: WorksheetState): WorksheetListItem => ({
    id: state.worksheet.worksheetId,
    name: state.worksheet.name,
    updatedDate: state.worksheet.updatedDate,
    isSaving: state.saveStatus === "persisting",
    errorMessage: !state.error?.isRenameError && state.error?.message,
    renameForm: state.renameForm,
    hasRenameError: state.error?.isRenameError === true
});

export const toLocalData = (worksheet: Worksheet): WorksheetLocalData => ({
    selectedWorksheetId: worksheet.worksheetId,
    currentWorksheet: toCreateWorksheet(worksheet)
});

export const toCreateWorksheet = (worksheet: Worksheet): CreateWorksheet => ({
    name: worksheet.name,
    worksheetType: worksheet.worksheetType,
    queries: worksheet.queries,
    columns: worksheet.columns,
    sort: worksheet.sort
});

export const toComparableWorksheet = (worksheet: CreateWorksheet): ComparableWorksheet => ({
    columns: worksheet?.columns,
    queries: worksheet?.queries,
    sort: isSortStateEmpty(worksheet?.sort) ? null : worksheet?.sort
});

export const isSortStateEmpty = (sort: ColumnSort): boolean => R.equals(sort, { columnId: "", order: null });

export const createWorksheet = (): CreateWorksheet => ({
    name: "",
    queries: [],
    columns: [],
    sort: null,
    worksheetType: null
});
