import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { FormGroupState } from "ngrx-forms";
import { BehaviorSubject, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

import { VerifiedTimestamp } from "../../../shared/components/timestamp/verified-timestamp.model";
import {
    addBerth,
    BerthId,
    BerthState,
    cloneBerth,
    collapseBerth,
    collapseDestinationNotes,
    DestinationForm,
    DestinationId,
    expandBerth,
    expandDestinationNotes,
    FixtureFeatureState,
    LayCan,
    moveBerthDown,
    moveBerthUp,
    removeBerth,
    selectCurrentFixtureLayCan,
    selectCurrentVoyageBerths,
    selectCurrentVoyageDestinationAreLocationAndEtaReadonly,
    selectCurrentVoyageDestinationDefaultEtaFocusDate,
    selectCurrentVoyageDestinationEtaVerified,
    selectCurrentVoyageDestinationFormState,
    selectCurrentVoyageDestinationHasEtaWarning,
    selectCurrentVoyageDestinationIsTransient,
    selectCurrentVoyageDestinationNotesExpanded,
    verifyEta
} from "../../state";

@Component({
    selector: "ops-destination-shell",
    templateUrl: "./destination-shell.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationShellComponent {
    readonly destinationId$ = new BehaviorSubject<DestinationId>(null);

    @Input()
    set destinationId(value: DestinationId) {
        this.destinationId$.next(value);
    }
    get destinationId() {
        return this.destinationId$.value;
    }

    @Input() readonly: boolean;

    readonly form$: Observable<FormGroupState<DestinationForm>>;
    readonly notesExpanded$: Observable<boolean>;
    readonly etaVerifiedAudit$: Observable<VerifiedTimestamp>;
    readonly hasEtaWarning$: Observable<boolean>;
    readonly defaultEtaFocusDate$: Observable<DateTime>;
    readonly layCan$: Observable<LayCan>;
    readonly isTransient$: Observable<boolean>;
    readonly isLocationAndEtaReadonly$: Observable<boolean>;
    readonly berths$: Observable<ReadonlyArray<BerthState>>;

    constructor(private store: Store<FixtureFeatureState>) {
        this.form$ = this.destinationId$.pipe(
            switchMap((destinationId) => this.store.select(selectCurrentVoyageDestinationFormState, { destinationId: destinationId }))
        );
        this.notesExpanded$ = this.destinationId$.pipe(
            switchMap((destinationId) => this.store.select(selectCurrentVoyageDestinationNotesExpanded, { destinationId: destinationId }))
        );
        this.etaVerifiedAudit$ = this.destinationId$.pipe(
            switchMap((destinationId) => this.store.select(selectCurrentVoyageDestinationEtaVerified, { destinationId: destinationId }))
        );
        this.hasEtaWarning$ = this.destinationId$.pipe(
            switchMap((destinationId) => this.store.select(selectCurrentVoyageDestinationHasEtaWarning, { destinationId: destinationId }))
        );
        this.defaultEtaFocusDate$ = this.destinationId$.pipe(
            switchMap((destinationId) => this.store.select(selectCurrentVoyageDestinationDefaultEtaFocusDate, { destinationId: destinationId }))
        );
        this.layCan$ = this.destinationId$.pipe(switchMap((destinationId) => this.store.select(selectCurrentFixtureLayCan, { destinationId: destinationId })));
        this.isTransient$ = this.destinationId$.pipe(
            switchMap((destinationId) => this.store.select(selectCurrentVoyageDestinationIsTransient, { destinationId: destinationId }))
        );
        this.isLocationAndEtaReadonly$ = this.destinationId$.pipe(
            switchMap((destinationId) => this.store.select(selectCurrentVoyageDestinationAreLocationAndEtaReadonly, { destinationId: destinationId }))
        );
        this.berths$ = this.destinationId$.pipe(switchMap((destinationId) => this.store.select(selectCurrentVoyageBerths, { destinationId: destinationId })));
    }

    expandNotes() {
        this.store.dispatch(expandDestinationNotes({ destinationId: this.destinationId }));
    }

    collapseNotes() {
        this.store.dispatch(collapseDestinationNotes({ destinationId: this.destinationId }));
    }

    verifyEta() {
        this.store.dispatch(verifyEta({ destinationId: this.destinationId }));
    }

    expandBerth(berthId: BerthId) {
        this.store.dispatch(expandBerth({ destinationId: this.destinationId, berthId }));
    }

    collapseBerth(berthId: BerthId) {
        this.store.dispatch(collapseBerth({ destinationId: this.destinationId, berthId }));
    }

    moveBerthUp(berthId: BerthId) {
        this.store.dispatch(moveBerthUp({ destinationId: this.destinationId, berthId }));
    }

    moveBerthDown(berthId: BerthId) {
        this.store.dispatch(moveBerthDown({ destinationId: this.destinationId, berthId }));
    }

    addBerth() {
        this.store.dispatch(addBerth({ destinationId: this.destinationId }));
    }

    cloneBerth(berthId: BerthId) {
        this.store.dispatch(cloneBerth({ destinationId: this.destinationId, berthId }));
    }

    removeBerth(berthId: BerthId) {
        this.store.dispatch(removeBerth({ destinationId: this.destinationId, berthId }));
    }
}
