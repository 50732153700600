import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";

import { User } from "../../../state/model";
import { FixtureDataService } from "../../services/fixture-data.service";
import { FormComponentBase } from "../../shared/form-component-base";
import { Fixture } from "../../shared/models";
import { UpdateFixturePopupFixtureInfoCommand } from "../commands/update-fixture-popup-fixture-info-command";
import { FixturePopupFixtureInfoFormModel } from "../models/fixture-popup-fixture-info-form.model";

@Component({
    selector: "ops-fixture-popup-fixture-info",
    templateUrl: "./fixture-popup-fixture-info.component.html",
    styleUrls: ["./fixture-popup-fixture-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupFixtureInfoComponent extends FormComponentBase implements OnInit, OnDestroy {
    operatorCount$ = new BehaviorSubject<number>(0);
    leadOperator$ = new BehaviorSubject<User | undefined>(undefined);

    @Input() fixture: Fixture;
    @Input() form: UntypedFormGroup;

    constructor(private fixtureDataService: FixtureDataService) {
        super();
    }

    ngOnInit(): void {
        this.operatorCount$.next(this.fixture.operators.length);
        this.leadOperator$.next(this.fixture.operators[0]);

        this.subscribeToFormValueChanges(this.form, (model: FixturePopupFixtureInfoFormModel) => {
            this.operatorCount$.next(model.operators.length);
            this.leadOperator$.next(model.operators[0]);
            this.fixtureDataService.handleUpdateCommand(new UpdateFixturePopupFixtureInfoCommand(model));
        });
    }

    ngOnDestroy(): void {
        this.removeFormSubscriptions();
    }
}
