import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Boxed, unbox } from "ngrx-forms";

import { Enumeration } from "../../../../shared/reference-data";
import { AssociatedCargoExpenseState } from "../../../state/associated-cargoes";
import { AssociatedCargoExpenseId, isTemplateExpenseRecord } from "../../../state/model";
import { RemoveAssociatedCargoExpenseEvent } from "../associated-cargoes.component";

@Component({
    selector: "ops-associated-cargo-expenses",
    templateUrl: "./associated-cargo-expenses.component.html",
    styleUrls: ["./associated-cargo-expenses.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociatedCargoExpensesComponent {
    @Input() associatedCargoId: number;
    @Input() totalFreightSpend: number;
    @Input() expenses: ReadonlyArray<AssociatedCargoExpenseState>;
    @Input() readonly: boolean;
    @Input() canAdd: boolean;
    @Input() canRemove: boolean;

    @Output() readonly add = new EventEmitter<AssociatedCargoExpenseId>();
    @Output() readonly remove = new EventEmitter<RemoveAssociatedCargoExpenseEvent>();

    getValue(object: Boxed<Enumeration>) {
        return unbox(object);
    }

    trackBy(item: AssociatedCargoExpenseState) {
        return item.associatedCargoExpenseId;
    }

    isTemplate(i: number) {
        return isTemplateExpenseRecord(this.expenses[i].form.value);
    }

    addExpense(associatedCargoExpenseId: AssociatedCargoExpenseId, event: Event) {
        this.add.emit(associatedCargoExpenseId);
        event.stopPropagation();
    }
}
