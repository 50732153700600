import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { debounceTime, takeUntil } from "rxjs/operators";

import { Enumeration, ReferenceDataService } from "@ops/shared/reference-data";

import { BunkerConsumption } from "../../../shared/models/dtos/bunker-consumption.dto";
import { BunkerConsumptionFormModel } from "../../../shared/models/form-models/bunker-consumption.model";
import { AbstractSimpleGridComponent } from "../abstract-simple-grid/abstract-simple-grid.component";
import { AddBunkerConsumptionCommand } from "./commands/add-bunker-consumption.command";
import { CloneBunkerConsumptionCommand } from "./commands/clone-bunker-consumption.command";
import { RemoveBunkerConsumptionCommand } from "./commands/remove-bunker-consumption.command";
import { UpdateBunkerConsumptionCommand } from "./commands/update-bunker-consumption.command";

@Component({
    selector: "ops-bunker-consumption",
    templateUrl: "./bunker-consumption.component.html",
    styleUrls: ["./bunker-consumption.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BunkerConsumptionComponent extends AbstractSimpleGridComponent<BunkerConsumptionFormModel> implements OnChanges, AfterViewInit {
    static componentName = "BunkerConsumptionComponent";

    private columnDefs = [
        { headerName: "Type", field: "bunkerType", class: "type-column-fixed" },
        { headerName: "Ballast", field: "ballast", class: "text-right" },
        { headerName: "Laden", field: "laden", class: "text-right" },
        { headerName: "At Sea", field: "atSea", class: "text-right" },
        { headerName: "Port - Idle", field: "portIdle", class: "laden-column-fixed text-right" },
        { headerName: "Port - Working", field: "portWorking", class: "work-column-fixed text-right" }
    ];

    bunkerTypes: Enumeration[];

    @Output() updated = new EventEmitter();
    @Input() model: BunkerConsumption[] = [];

    @ViewChildren("bunkerTypeElement", { read: NgSelectComponent }) ngSelectElements: QueryList<NgSelectComponent>;

    constructor(public referenceDataService: ReferenceDataService, public changeDetectorRef: ChangeDetectorRef, protected formBuilder: UntypedFormBuilder) {
        super("bunkerConsumptionId", changeDetectorRef, formBuilder);

        this.columnDefs$.next(this.columnDefs);
        this.rowChanged$.pipe(takeUntil(this.destroy$)).subscribe((row) => this.updated.emit(new UpdateBunkerConsumptionCommand(row)));

        this.referenceDataService
            .getBunkerTypes()
            .pipe(takeUntil(this.destroy$))
            .subscribe((bunkerTypes: Enumeration[]) => {
                this.bunkerTypes = bunkerTypes;
                this.changeDetectorRef.markForCheck();
            });

        this.add$.pipe(takeUntil(this.destroy$)).subscribe(() => this.updated.emit(new AddBunkerConsumptionCommand()));
        this.copy$.pipe(takeUntil(this.destroy$)).subscribe(({ key }) => this.updated.emit(new CloneBunkerConsumptionCommand(key)));
        this.delete$.pipe(takeUntil(this.destroy$)).subscribe(({ index }) => this.updated.emit(new RemoveBunkerConsumptionCommand(index)));
    }

    ngAfterViewInit() {
        // HACK: debounceTime workaround for changed after checked error
        this.ngSelectElements.changes.pipe(takeUntil(this.destroy$), debounceTime(5)).subscribe((elements: QueryList<NgSelectComponent>) => {
            if (!this.formArray.disabled && elements.length) {
                elements.last.focus();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["ngSelectElements"]) {
            console.log("ngSelectElements changed:", changes["ngSelectElements"]);
        }

        if (changes["model"] === undefined) {
            return;
        }

        const modelChange = changes["model"];
        const currentValue = (modelChange.currentValue as any) as BunkerConsumption[];

        if (!currentValue || !currentValue.length) {
            this.rowData$.next([]);
            this.updated.emit(new AddBunkerConsumptionCommand());
            return;
        }

        const data = currentValue.map((item) => ({
            bunkerConsumptionId: item.bunkerConsumptionId,
            bunkerType: item.type,
            ballast: item.ballast,
            laden: item.laden,
            atSea: item.atSea,
            portIdle: item.portIdle,
            portWorking: item.portWorking
        }));

        this.rowData$.next(data);
    }
}
