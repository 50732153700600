import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { DestinationId, DestinationState } from "../../state";

@Component({
    selector: "ops-destinations",
    templateUrl: "./destinations.component.html",
    styleUrls: ["./destinations.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationsComponent {
    @Input() destinations: ReadonlyArray<DestinationState>;
    @Input() readonly: boolean;

    @Output() readonly expand = new EventEmitter<DestinationId>();
    @Output() readonly collapse = new EventEmitter<DestinationId>();
    @Output() readonly moveUp = new EventEmitter<DestinationId>();
    @Output() readonly moveDown = new EventEmitter<DestinationId>();
    @Output() readonly add = new EventEmitter();
    @Output() readonly clone = new EventEmitter<DestinationId>();
    @Output() readonly remove = new EventEmitter<DestinationId>();

    get canAdd() {
        return !this.readonly;
    }

    trackBy(index: number, item: DestinationState) {
        return item.destinationId;
    }
}
