import { FormArrayState, FormState, ProjectFn2, updateArrayWithFilter, updateGroup } from "ngrx-forms";

import { BerthFormContext, forEachBerth } from "../berths/shared";
import { ActivityForm, ActivityId, BerthForm, BerthId, DestinationForm, DestinationId, VoyageForm } from "../model";

export const updateActivityForm = (
    id: { destinationId: DestinationId; berthId: BerthId; activityId: ActivityId },
    updateFn: ProjectFn2<FormState<ActivityForm>, FormArrayState<ActivityForm>>
) =>
    updateGroup<VoyageForm>({
        destinations: updateArrayWithFilter(
            (d) => d.value.id === id.destinationId,
            updateGroup<DestinationForm>({
                berths: updateArrayWithFilter(
                    (b) => b.value.id === id.berthId,
                    updateGroup<BerthForm>({
                        activities: updateArrayWithFilter<ActivityForm>((a) => a.value.activityId === id.activityId, updateFn)
                    })
                )
            })
        )
    });

export type ActivityFormContext = BerthFormContext & { berth: BerthForm };

export const forEachActivity = (voyageForm: VoyageForm, fn: (activity: ActivityForm, { destination, berth }: ActivityFormContext) => void) => {
    forEachBerth(voyageForm, (berth, { destination }) => berth.activities.forEach((activity) => fn(activity, { destination, berth })));
};
