<div class="form-group notices-component">
    <label for="notices">{{ title }} ({{ notices?.length }})</label>
    <div
        id="notices"
        #noticesElement
        class="form-control notices-input"
        (focus)="openNoticesListPopover()"
        (click)="openNoticesListPopover()"
        [ngbPopover]="popContent"
        placement="bottom-right"
        triggers="manual"
        [autoClose]="false"
        [ngClass]="{ 'notice-validation': invalid() }"
        tabindex="0"
        data-test-notices-tags
    >
        <span *ngIf="!notices || notices.length === 0" class="w-100 arrow-down-container"> <span class="has-icon icon--arrow-drop-down"></span> </span>
        <span *ngFor="let notice of notices.slice(0, displayNoticesCount)" class="notice-tag {{ notice.noticeType.name.toLowerCase() }}">
            {{ notice.days }}{{ notice.noticeType.name === "Firm" ? " F" : " T" }}
        </span>
        <span *ngIf="notices && notices.length > displayNoticesCount" class="notice-tag ellips">...</span>
    </div>
    <div>
        <ng-template #popContent>
            <div *ngIf="notices && notices.length > 0" class="table-container">
                <table class="w-100">
                    <thead class="table-header">
                        <tr>
                            <th scope="col" class="text-right notice-days-header">Days</th>
                            <th>Firm / Tentative</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody #noticesTableBody>
                        <tr *ngFor="let notice of notices; let i = index; trackBy: trackByIndex">
                            <td class="notice-td">
                                <input
                                    #dayInput
                                    type="text"
                                    class="form-control notice-one-input text-right w-100"
                                    [opsNumber]="{ precision: 0, showTrailingZeros: false, minValue: 1 }"
                                    opsFocus
                                    maxlength="3"
                                    [value]="notice.days"
                                    (blur)="n.close(); updateNoticeDays(i, $event.target.value)"
                                    [ngClass]="{ 'notice-validation': notice && notice.days <= 0 }"
                                    [ngbPopover]="nn"
                                    #n="ngbPopover"
                                    placement="bottom-left"
                                    data-test-input-notice-days
                                />

                                <div>
                                    <ng-template #nn>
                                        <div *ngIf="!notice.days || notice.days <= 0" class="row no-gutters display">
                                            <span class="has-icon icon--warning col-2 text-center"></span>
                                            <span class="content col-10 w-100" data-test-notices-positive-no><b>Days</b> must be a positive number.</span>
                                        </div>
                                    </ng-template>
                                </div>
                            </td>
                            <td class="notice-td">
                                <ng-select
                                    class="ops-select"
                                    (ngModelChange)="updateNoticeType(i, $event)"
                                    [(ngModel)]="notice.noticeType.id"
                                    [items]="noticeTypes$ | async"
                                    [clearable]="false"
                                    bindLabel="name"
                                    bindValue="id"
                                    [selectOnTab]="true"
                                    data-test-input-notice-type
                                >
                                </ng-select>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="has-icon icon--add-circle-outline btn-notice btn-notice-add"
                                    (click)="addNew($event)"
                                    *ngIf="notices.length - 1 === i"
                                    data-test-add-notice
                                ></button>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="has-icon icon--delete btn-notice btn-notice-delete"
                                    (click)="deleteNotice(i, $event)"
                                    data-test-delete-notice
                                ></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row no-gutters no-notices-container" *ngIf="notices.length === 0">
                <span class="has-icon icon--lightbulb-outline no-notices-bulb"></span><span class="no-notices-text">No notices currently added</span>
            </div>
            <div *ngIf="notices.length === 0" class="divider-notice"></div>
            <div class="btn-add-container">
                <button type="button" class="btn btn-primary btn-add" *ngIf="notices.length === 0" (click)="addNew($event)" data-test-add-notice>
                    <span class="has-icon icon--add-circle-outline"></span> NOTICE
                </button>
            </div>
        </ng-template>
    </div>
</div>
