import { createEntityAdapter, EntityAdapter, EntityState, Update } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { parseISODate } from "src/app/shared/date-utils/date-utilities";
import { ActionDto } from "../models/action.dto";
import * as ActionApiActions from "./action-api.actions";
import { FixtureActions } from "./action.model";
import * as BoardCardActions from "./board-card.actions";
import * as LiveUpdatesHubActions from "./live-updates-hub.actions";

export interface ActionState extends EntityState<FixtureActions> {}

export const adapter: EntityAdapter<FixtureActions> = createEntityAdapter<FixtureActions>({
    selectId: (fixtureActions: FixtureActions) => fixtureActions.fixtureId
});

export const initialState: ActionState = adapter.getInitialState();

const reducer = createReducer(
    initialState,
    on(BoardCardActions.loadActions, (state, { fixtureId }) => {
        const fixtureActions: FixtureActions = { fixtureId, actions: null, loading: true, error: null };
        return adapter.upsertOne(fixtureActions, { ...state });
    }),
    on(ActionApiActions.loadActionsSuccess, (state, { fixtureId, actions }) => {
        const update: Update<FixtureActions> = { id: fixtureId, changes: { actions, loading: false, error: null } };
        return adapter.updateOne(update, { ...state });
    }),
    on(ActionApiActions.loadActionsFailure, (state, { fixtureId, error }) => {
        const update: Update<FixtureActions> = { id: fixtureId, changes: { loading: false, error } };
        return adapter.updateOne(update, { ...state });
    }),
    on(BoardCardActions.updateComplete, BoardCardActions.updateIncomplete, (state, { fixtureId, actionId }) => {
        const actions = state.entities[fixtureId].actions.map((x) => (x.actionId === actionId ? { ...x, error: null, loading: true } : x));
        const update: Update<FixtureActions> = { id: fixtureId, changes: { actions } };
        return adapter.updateOne(update, { ...state });
    }),
    on(ActionApiActions.updateCompleteSuccess, ActionApiActions.updateIncompleteSuccess, (state, { fixtureId, actionId, type }) => {
        const actions = state.entities[fixtureId].actions.map((x) =>
            x.actionId === actionId ? { ...x, isComplete: type === ActionApiActions.updateCompleteSuccess.type, error: null, loading: false } : x
        );
        const update: Update<FixtureActions> = { id: fixtureId, changes: { actions } };
        return adapter.updateOne(update, { ...state });
    }),
    on(ActionApiActions.updateCompleteFailure, ActionApiActions.updateIncompleteFailure, (state, { fixtureId, actionId, error }) => {
        const actions = state.entities[fixtureId].actions.map((x) => (x.actionId === actionId ? { ...x, error, loading: false } : x));
        const update: Update<FixtureActions> = { id: fixtureId, changes: { actions } };
        return adapter.updateOne(update, { ...state });
    }),
    on(LiveUpdatesHubActions.updateAction, (state, { action }) => {
        const fixtureActions = state.entities[action.fixtureId];
        if (fixtureActions) {
            const dueDateComparer = (a1: ActionDto, a2: ActionDto) => parseISODate(a1.dueDate).getTime() - parseISODate(a2.dueDate).getTime();
            const actions = [...fixtureActions.actions.filter((x) => x.actionId !== action.actionId), action].sort(dueDateComparer);
            const update: Update<FixtureActions> = { id: action.fixtureId, changes: { actions } };
            return adapter.updateOne(update, { ...state });
        }
        return state;
    }),
    on(LiveUpdatesHubActions.deleteAction, (state, { action }) => {
        const fixtureActions = state.entities[action.fixtureId];
        if (fixtureActions) {
            const actions = fixtureActions.actions.filter((x) => x.actionId !== action.actionId);
            const update: Update<FixtureActions> = { id: action.fixtureId, changes: { actions } };
            return adapter.updateOne(update, { ...state });
        }
        return state;
    })
);

export function actionReducer(state: ActionState, action: Action) {
    return reducer(state, action);
}
