import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import {
    CoaFeatureState,
    selectCurrentLiftingHistoryFilterOptions,
    selectCurrentLiftingHistoryFilterTooltip,
    selectIsAnyLiftingHistoryFilterOptionSelected
} from "../../coa/state";
import { LiftingHistoryFilterOptions, liftingHistoryToggleAllAction, liftingHistoryToggleKeyAction } from "../../coa/state/lifting/lifting-history";

@Component({
    selector: "ops-lifting-history-filter",
    templateUrl: "./lifting-history-filter.component.html",
    styleUrls: ["./lifting-history-filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiftingHistoryFilterComponent {
    isAnyFilterOptionSelected$: Observable<boolean>;
    filterOptions$: Observable<LiftingHistoryFilterOptions>;
    filterTooltip$: Observable<string>;

    constructor(private store: Store<CoaFeatureState>) {
        this.isAnyFilterOptionSelected$ = store.select(selectIsAnyLiftingHistoryFilterOptionSelected);
        this.filterOptions$ = store.select(selectCurrentLiftingHistoryFilterOptions);
        this.filterTooltip$ = store.select(selectCurrentLiftingHistoryFilterTooltip);
    }

    toggleAll() {
        this.store.dispatch(liftingHistoryToggleAllAction());
    }

    toggleLaycan() {
        this.store.dispatch(liftingHistoryToggleKeyAction({ key: "laycan" }));
    }

    toggleCargoNomination() {
        this.store.dispatch(liftingHistoryToggleKeyAction({ key: "cargoNomination" }));
    }

    toggleVesselNomination() {
        this.store.dispatch(liftingHistoryToggleKeyAction({ key: "vesselNomination" }));
    }
}
