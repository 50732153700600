import { createSelector } from "@ngrx/store";
import { FormGroupState, unbox } from "ngrx-forms";

import { CargoBerthActivityType, Enumeration } from "@ops/shared/reference-data";

import { Berth, CargoBerthActivity, Division } from "../../shared/models";
import { selectCurrentDestinationBerthFormState, selectCurrentVoyageBerth } from "../berths";
import { selectCurrentFixtureDivision } from "../fixture";
import { activityExpandedKey, ActivityForm, ActivityId, BerthForm, BerthId, DestinationId, FixtureFeatureState, VoyageExpandedSections } from "../model";
import { selectCurrentVoyageExpandedSections } from "../voyage";

export declare type ActivityState = Readonly<{
    activityId: ActivityId;
    berthId: BerthId;
    destinationId: DestinationId;
    expanded: boolean;
    canOrder: boolean;
    canRemove: boolean;
    type: CargoBerthActivityType;
}>;

export const selectCurrentVoyageActivitiesExpanded = createSelector(selectCurrentVoyageExpandedSections, (expandedSections) => expandedSections && expandedSections.activities);

export const selectCurrentVoyageActivities = createSelector<
    FixtureFeatureState,
    { destinationId: DestinationId; berthId: BerthId },
    Berth,
    VoyageExpandedSections,
    Division,
    ReadonlyArray<ActivityState>
>(
    selectCurrentVoyageBerth,
    selectCurrentVoyageExpandedSections,
    selectCurrentFixtureDivision,
    (berth, expandedSections, division, { destinationId, berthId }) =>
        berth &&
        berth.cargoBerthActivities.map((activity) => {
            const canRemove =
                division !== Division.specialisedProducts ||
                !(activity.type && (activity.type.id === CargoBerthActivityType.Load.id || activity.type.id === CargoBerthActivityType.Discharge.id));

            return <ActivityState>{
                activityId: activity.id,
                destinationId,
                berthId,
                expanded: expandedSections[activityExpandedKey(destinationId, berthId, activity.id)],
                canRemove,
                canOrder: true,
                type: activity.type
            };
        })
);

export const selectCurrentVoyageActivity = createSelector<
    FixtureFeatureState,
    { destinationId: DestinationId; berthId: BerthId; activityId: ActivityId },
    Berth,
    CargoBerthActivity
>(selectCurrentVoyageBerth, (berth, { activityId }) => berth && berth.cargoBerthActivities.find((a) => a.id === activityId));

export const selectCurrentDestinationActivityForm = createSelector<
    FixtureFeatureState,
    { destinationId: DestinationId; berthId: BerthId; activityId: ActivityId },
    FormGroupState<BerthForm>,
    FormGroupState<ActivityForm>
>(selectCurrentDestinationBerthFormState, (berthForm, { activityId }) => berthForm && berthForm.controls.activities.controls.find((x) => x.value.activityId === activityId));

export const selectCurrentDestinationActivityFormValue = createSelector<
    FixtureFeatureState,
    { destinationId: DestinationId; berthId: BerthId; activityId: ActivityId },
    FormGroupState<BerthForm>,
    ActivityForm
>(selectCurrentDestinationBerthFormState, (berthForm, { activityId }) => {
    const activityForm = berthForm && berthForm.controls.activities.controls.find((x) => x.value.activityId === activityId);
    return activityForm && activityForm.value;
});

export const selectCurrentDestinationActivityFormType = createSelector<
    FixtureFeatureState,
    { destinationId: DestinationId; berthId: BerthId; activityId: ActivityId },
    ActivityForm,
    Readonly<CargoBerthActivityType>
>(selectCurrentDestinationActivityFormValue, (activityForm, {}) => unbox(activityForm?.type));

export const selectShowAssociatedCargoes = createSelector<
    FixtureFeatureState,
    { destinationId: DestinationId; berthId: BerthId; activityId: ActivityId },
    Readonly<CargoBerthActivityType>,
    boolean
>(
    selectCurrentDestinationActivityFormType,
    (type, {}) =>
        !!type &&
        [
            CargoBerthActivityType.Load.id,
            CargoBerthActivityType.Discharge.id,
            CargoBerthActivityType.Provisional.id,
            CargoBerthActivityType.ProvisionalLoad.id,
            CargoBerthActivityType.ProvisionalDischarge.id
        ].includes(type.id)
);

export const selectCurrentVoyageActivityIsTypeDropdownReadonly = createSelector(selectCurrentVoyageActivity, selectCurrentFixtureDivision, (activity, division) =>
    division === Division.specialisedProducts
        ? activity && activity.type && (activity.type.id === CargoBerthActivityType.Discharge.id || activity.type.id === CargoBerthActivityType.Load.id)
        : false
);

export const selectCurrentVoyageActivityIsBlAtDisportVisible = createSelector(
    selectCurrentVoyageActivity,
    selectCurrentFixtureDivision,
    (activity, division) =>
        (division === Division.specialisedProducts || division === Division.pcg) && activity && activity.type && activity.type.id === CargoBerthActivityType.Discharge.id
);

export const selectCurrentVoyageActivityAvailableActivityTypes = createSelector<
    FixtureFeatureState,
    { destinationId: DestinationId; berthId: BerthId; activityId: ActivityId; allTypes: Enumeration[] },
    CargoBerthActivity,
    Division,
    Enumeration[]
>(selectCurrentVoyageActivity, selectCurrentFixtureDivision, (activity, division, { allTypes }) => {
    if (
        division === Division.specialisedProducts &&
        activity &&
        (!activity.type || (activity.type.id !== CargoBerthActivityType.Load.id && activity.type.id !== CargoBerthActivityType.Discharge.id))
    ) {
        allTypes = allTypes.filter((t) => t.id !== CargoBerthActivityType.Load.id && t.id !== CargoBerthActivityType.Discharge.id);
    }
    return allTypes;
});

export const selectCurrentVoyageAssociatedCargoIds = createSelector(selectCurrentDestinationActivityForm, (activityForm) =>
    activityForm ? activityForm.value.associatedCargoes.map((x) => x.cargoId) : null
);
