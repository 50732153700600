import { MaritimeDate } from "@maritech/maritime-date";
import { createSelector } from "@ngrx/store";
import { DateTime } from "luxon";

import { isNullOrUndefined } from "@ops/shared";

import { selectLtcFeature } from "../ltc.selectors";
import { LtcId, LaytimeCalculationState, getCurrentCalculationId, LtcFeatureState, LtcState, FixtureIndex } from "../model";
import { getCalculationResult } from "../model/calculations/utils";
import { getFixtureHeader, FixtureHeader } from "./fixture-header";

export const selectCurrentCalculationId = createSelector(selectLtcFeature, getCurrentCalculationId);
export const selectLaytimeCalculationState = createSelector<LtcFeatureState, { ltcId: LtcId }, LtcState, LaytimeCalculationState>(
    selectLtcFeature,
    (state: LtcState, { ltcId }) => ltcId && state.calculations.byId[ltcId]
);

export const selectCurrentLaytimeCalculationState = createSelector<LtcFeatureState, LtcState, LtcId, LaytimeCalculationState>(
    selectLtcFeature,
    selectCurrentCalculationId,
    (state, calculationId) => calculationId && state.calculations.byId[calculationId]
);
export const selectIsCurrentLaytimeCalculationLoaded = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.calculationLoadStatus === "loaded");
export const selectCurrentLaytimeCalculation = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.calculation);
export const selectCurrentLaytimeCalculationId = createSelector(selectCurrentLaytimeCalculation, (calculation) => calculation?.id);
export const selectCurrentLaytimeCalculationFixtureId = createSelector(selectCurrentLaytimeCalculation, (calculation) => calculation?.fixtureId);
export const selectCurrentLaytimeCalculationSector = createSelector(selectCurrentLaytimeCalculation, (calculation) => calculation?.sector);
export const selectCurrentLaytimeCalculationName = createSelector(selectCurrentLaytimeCalculation, (calculation) => calculation?.name ?? "Untitled Calculation");
export const selectCurrentLaytimeCalculationCurrency = createSelector(selectCurrentLaytimeCalculation, (calculation) => calculation?.currency);
export const selectCurrentLaytimeCalculationTimeAllowance = createSelector(selectCurrentLaytimeCalculation, (calculation) => calculation?.timeAllowance);
export const selectCurrentLaytimeCalculationTimeAllowanceIsFixed = createSelector(selectCurrentLaytimeCalculationTimeAllowance, (timeAllowance) => timeAllowance === "Fixed");
export const selectCurrentLaytimeCalculationIsAlwaysOnDemurrage = createSelector(
    selectCurrentLaytimeCalculation,
    (calculation) => calculation?.onceOnDemurrage === "Always On Demurrage"
);
export const selectCurrentLaytimeCalculationPersistenceStatus = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.calculationPersistenceStatus);
export const selectCurrentLaytimeCalculationPersisting = createSelector(
    selectCurrentLaytimeCalculationPersistenceStatus,
    (persistenceStatus) => persistenceStatus === "persisting"
);
export const selectCurrentLaytimeCalculationLastUpdated = createSelector(selectCurrentLaytimeCalculation, (calculation) =>
    !isNullOrUndefined(calculation)
        ? `Last updated by ${calculation.lastUpdatedBy.name} ${DateTime.fromISO(calculation.lastUpdatedAt).toLocaleString(MaritimeDate.DATETIME_FORMAT)}`
        : null
);

export const selectCurrentLaytimeCalculationSummaryTitle = createSelector(selectCurrentLaytimeCalculationName, (name) => name ?? "Untitled Calculation");

export const selectCurrentFixture = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.fixture);
export const selectCurrentFixtureHeader = createSelector<LtcFeatureState, { seaNetUrl: string }, FixtureIndex, FixtureHeader>(
    selectCurrentFixture,
    (fixture, { seaNetUrl }) => fixture && getFixtureHeader(fixture, seaNetUrl)
);

export const selectCurrentLaytimeCalculationResult = createSelector(selectCurrentLaytimeCalculation, (calculation) => getCalculationResult(calculation));

export const selectCurrentFixedOrReversibleDemurrageLaytimeEventId = createSelector(selectCurrentLaytimeCalculationResult, (result) => result?.demurrageLaytimeEventId);
