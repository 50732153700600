import { Enumeration } from "./enumeration";

export class CargoBerthActivityType extends Enumeration {
    static Load: CargoBerthActivityType = new CargoBerthActivityType(1, "Load", 1);
    static Discharge: CargoBerthActivityType = new CargoBerthActivityType(2, "Discharge", 2);
    static Bunkering: CargoBerthActivityType = new CargoBerthActivityType(3, "Bunkering", 3);
    static TransitInterim: CargoBerthActivityType = new CargoBerthActivityType(4, "Transit / Interim", 4);
    static ForOrders: CargoBerthActivityType = new CargoBerthActivityType(5, "For Orders", 5);
    static InterimLoad: CargoBerthActivityType = new CargoBerthActivityType(6, "Interim - Load", 6);
    static InterimDischarge: CargoBerthActivityType = new CargoBerthActivityType(7, "Interim - Discharge", 7);
    static Storage: CargoBerthActivityType = new CargoBerthActivityType(8, "Storage", 8);
    static Waiting: CargoBerthActivityType = new CargoBerthActivityType(9, "Waiting", 9);
    static Doping: CargoBerthActivityType = new CargoBerthActivityType(10, "Doping", 10);
    static DischargeReload: CargoBerthActivityType = new CargoBerthActivityType(11, "Discharge/Reload", 11);
    static Sampling: CargoBerthActivityType = new CargoBerthActivityType(12, "Sampling", 12);
    static InterimDischargeReload: CargoBerthActivityType = new CargoBerthActivityType(13, "Interim - Discharge/Reload", 13);
    static BallastLeg: CargoBerthActivityType = new CargoBerthActivityType(14, "Ballast leg", 14);
    static LoadForOrders: CargoBerthActivityType = new CargoBerthActivityType(15, "Load For Orders", 15);
    static DischargeForOrders: CargoBerthActivityType = new CargoBerthActivityType(16, "Discharge For Orders", 16);
    static Transit: CargoBerthActivityType = new CargoBerthActivityType(17, "Transit", 4);
    static Provisional: CargoBerthActivityType = new CargoBerthActivityType(18, "Provisional", 18);
    static ProvisionalLoad: CargoBerthActivityType = new CargoBerthActivityType(19, "Prov. Load", 19);
    static ProvisionalDischarge: CargoBerthActivityType = new CargoBerthActivityType(20, "Prov. Disch.", 20);

    static isLoadOrDischarge(type: CargoBerthActivityType): boolean {
        return !!type && [this.Load.id, this.Discharge.id].includes(type.id);
    }
}
