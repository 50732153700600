import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { LiftingHistoryEvent } from "./lifting-history";

@Component({
    selector: "ops-lifting-history-events",
    templateUrl: "./lifting-history-events.component.html",
    styleUrls: ["./lifting-history-events.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiftingHistoryEventsComponent {
    @Input() historyEvents: LiftingHistoryEvent[];

    trackBy(index: number) {
        return index;
    }

    getDotClass(historyEvent: LiftingHistoryEvent) {
        return historyEvent.eventType.toLowerCase();
    }
}
