import { Injectable } from "@angular/core";
import { BehaviorSubject, of, of as observableOf, Subject } from "rxjs";
import { tap } from "rxjs/operators";

import { AuthService } from "../../core";
import { SystemUser } from "../../core/system-user.model";
import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { FixtureHttpService } from "../../fixture/services/fixture-http.service";
import { Fixture } from "../../fixture/shared/models";
import { FavoriteHttpService } from "./favorite-http.service";
import { FixtureStatusService } from "./fixture-status.service";
import { Favorite, FavoriteCategoryViewModel, FavoriteViewModel, FixtureStatus } from "./models";
import { FavoritePost } from "./models/favorite-post.model";
import { createFavoriteFromFixture } from "./models/favorite.model";

@Injectable({
    providedIn: "root"
})
export class FavoriteDataService {
    private favoriteCategorySubject = new BehaviorSubject<FavoriteCategoryViewModel[]>([]);
    private favouritesRemovedSubject = new Subject<string[]>();
    private favouritesQueriedSubject = new BehaviorSubject<Favorite[]>([]);
    private favouritesLoadingSubject = new BehaviorSubject<boolean>(false);
    private favouritesLoaded = false;

    favoriteCategories$ = this.favoriteCategorySubject.asObservable();
    favouritesRemoved$ = this.favouritesRemovedSubject.asObservable();
    favouritesQueried$ = this.favouritesQueriedSubject.asObservable();
    favouritesLoading$ = this.favouritesLoadingSubject.asObservable();

    constructor(
        private fixtureDataService: FixtureDataService,
        private fixtureHttpService: FixtureHttpService,
        private favoriteHttpService: FavoriteHttpService,
        private authService: AuthService,
        private fixtureStatusService: FixtureStatusService
    ) {
        this.fixtureDataService.save$.subscribe(() => this.updateFavouriteDetails(this.fixtureDataService.currentFixtureSnapshot));
        this.fixtureDataService.lock$.subscribe(() => this.markAsLockedAndFavorite(this.fixtureDataService.currentFixtureSnapshot));
        this.fixtureDataService.unlock$.subscribe((fixtureId) => this.markAsUnlocked(fixtureId));
    }

    getCurrentFavoritesSnapshot(): Favorite[] {
        return this.favouritesQueriedSubject.getValue();
    }

    loadFavourites(): void {
        if (this.favouritesLoaded) {
            // Load favourites only once per user session
            // Favourites will be updated when the user adds/removes documents to/from favourites in the same session
            return;
        }
        this.favouritesLoadingSubject.next(true);
        this.favoriteHttpService.getAll().subscribe(
            (favourites) => {
                this.favouritesQueriedSubject.next(favourites);
                this.categoriseFavourites();
                this.favouritesLoaded = true;
            },
            (error) => observableOf(error)
        );
    }

    updateLockForFavorite(fixtureId: string, userId: number) {
        if (!fixtureId || !userId) {
            throw new Error("Favorite ID and User ID are mandatory");
        }

        const currentFavorites = this.getCurrentFavoritesSnapshot();
        const favorite = currentFavorites.find((f) => f.id === fixtureId);
        if (!favorite) {
            throw new Error("A favorite with given ID does not exist.");
        }

        if (favorite.lockedUserId === userId) {
            return of();
        }

        favorite.lockedUserId = userId;

        return this.fixtureHttpService.lock(fixtureId).pipe(
            tap(
                () => {
                    this.favouritesQueriedSubject.next(currentFavorites);
                    this.categoriseFavourites();
                },
                (error) => observableOf(error)
            )
        );
    }

    updateFavoriteColor(color: string, fixtureId: string): void {
        if (!fixtureId || !color) {
            throw new Error("Favorite ID and Color is mandatory");
        }

        const currentFavorites = this.getCurrentFavoritesSnapshot();
        const favorite = currentFavorites.find((f) => f.id === fixtureId);
        if (!favorite) {
            throw new Error("A favorite with given ID does not exist.");
        }

        if (favorite.color === color) {
            return;
        }

        favorite.color = color;

        this.favoriteHttpService.update(favorite.id, color).subscribe(
            () => {
                this.favouritesQueriedSubject.next(currentFavorites);
                this.categoriseFavourites();
            },
            (error) => observableOf(error)
        );
    }

    addFavorite(favorite: Favorite): void {
        if (!favorite) {
            throw new Error("Favorite is mandatory");
        }

        this.favouritesLoadingSubject.next(true);
        const favoritePost: FavoritePost = { fixtureId: favorite.id, userId: favorite.userId };

        this.favoriteHttpService.create(favoritePost).subscribe(
            () => {
                const currentFavorites = this.getCurrentFavoritesSnapshot();
                currentFavorites.push(favorite);
                this.favouritesQueriedSubject.next(currentFavorites);
                this.categoriseFavourites();
            },
            (error) => observableOf(error)
        );
    }

    removeFavorite(fixtureId: string): void {
        if (!fixtureId) {
            throw new Error("FixtureId is mandatory");
        }

        this.favouritesLoadingSubject.next(true);
        const currentFavorites = this.getCurrentFavoritesSnapshot();
        const favouriteToRemove = currentFavorites.find((f) => f.id === fixtureId);

        if (!favouriteToRemove) {
            throw new Error("Cannot find a favourite to remove.");
        }

        this.favoriteHttpService.delete(favouriteToRemove.id).subscribe(
            () => {
                const removedIndex = currentFavorites.findIndex((f) => f.id === fixtureId);

                currentFavorites.splice(removedIndex, 1);
                this.favouritesQueriedSubject.next(currentFavorites);
                this.favouritesRemovedSubject.next([fixtureId]);
                this.categoriseFavourites();
            },
            (error) => observableOf(error)
        );
    }

    removeSelectedFavorites(): void {
        const fixtureIds = this.getFlattenedFavorites(this.favoriteCategorySubject.getValue())
            .filter((favorite) => favorite.selected && favorite.canRemove)
            .map((favorite) => favorite.fixtureId);

        if (fixtureIds.length === 0) {
            return;
        }

        this.favoriteHttpService.deleteMultiple(fixtureIds).subscribe(
            () => {
                const currentFavorites = this.getCurrentFavoritesSnapshot();
                const removedFixtureIds = [];
                for (const fixtureId of fixtureIds) {
                    const favourite = currentFavorites.find((f) => f.id === fixtureId);
                    const removeIndex = currentFavorites.indexOf(favourite);
                    removedFixtureIds.push(favourite.id);
                    currentFavorites.splice(removeIndex, 1);
                }

                this.favouritesQueriedSubject.next(currentFavorites);
                this.favouritesRemovedSubject.next(removedFixtureIds);
                this.categoriseFavourites();
            },
            (error) => observableOf(error)
        );
    }

    toggleSelectFavorite(fixtureId: string): void {
        if (!fixtureId) {
            throw new Error("Favorite ID is mandatory");
        }

        const categories = this.favoriteCategorySubject.getValue();
        const favorite = this.getFlattenedFavorites(categories).find((f) => f.fixtureId === fixtureId);

        if (!favorite) {
            return;
        }

        favorite.selected = !favorite.selected;
        this.favoriteCategorySubject.next(categories);
    }

    toggleSelectCategory(fixtureStatusId: number): void {
        if (!fixtureStatusId) {
            throw new Error("Fixture status ID is mandatory");
        }

        const categories = this.favoriteCategorySubject.getValue();
        const category = categories.find((c) => c.fixtureStatusType === fixtureStatusId);

        if (!category) {
            return;
        }

        category.favorites.forEach((favorite) => (favorite.selected = !category.selected));
        category.selected = !category.selected;
        this.favoriteCategorySubject.next(categories);
    }

    toggleSelectAllCategories(select: boolean): void {
        const updated = this.favoriteCategorySubject.getValue();

        updated.forEach((category) => (category.selected = select));
        this.getFlattenedFavorites(updated).forEach((favorite) => (favorite.selected = select));

        this.favoriteCategorySubject.next(updated);
    }

    getSelectedFavorites(): FavoriteViewModel[] {
        return this.getFlattenedFavorites(this.favoriteCategorySubject.getValue()).filter((favorite) => favorite.selected);
    }

    private categoriseFavourites(): void {
        const favourites = this.favouritesQueriedSubject.getValue();
        const favoriteViewModels = this.buildFavoriteViewModels(favourites, this.authService.systemUser);
        const fixtureStatuses = this.fixtureStatusService.getFixtureStatuses();
        const categoryViewModels = this.buildFavoriteCategoryViewModels(fixtureStatuses, favoriteViewModels);
        this.favoriteCategorySubject.next(categoryViewModels);
        this.favouritesLoadingSubject.next(false);
    }

    private buildFavoriteCategoryViewModels(fixtureStatuses: FixtureStatus[], favoriteViewModels: FavoriteViewModel[]): FavoriteCategoryViewModel[] {
        return fixtureStatuses
            .map((fixtureStatus) => {
                const favorites = favoriteViewModels
                    .filter((favorite) => favorite.fixtureStatusType === fixtureStatus.type)
                    .sort((a, b) => {
                        if (a.fixtureName > b.fixtureName) {
                            return 1;
                        }
                        if (a.fixtureName < b.fixtureName) {
                            return -1;
                        }
                        return 0;
                    });
                return new FavoriteCategoryViewModel(fixtureStatus, favorites);
            })
            .sort((a, b) => {
                if (a.order > b.order) {
                    return 1;
                }
                if (a.order < b.order) {
                    return -1;
                }
                return 0;
            });
    }

    private buildFavoriteViewModels(favorites: Favorite[], currentUser: SystemUser): FavoriteViewModel[] {
        return favorites.map((favorite) => new FavoriteViewModel(favorite, currentUser));
    }

    private getFlattenedFavorites(categories: FavoriteCategoryViewModel[]): FavoriteViewModel[] {
        return categories.map((category) => category.favorites).reduce((a, b) => a.concat(b), []);
    }

    private updateFavouriteDetails(fixture: Fixture): void {
        const currentFavourites = this.getCurrentFavoritesSnapshot();
        const existingFavourite = currentFavourites.find((f) => f.id === fixture.fixtureId);
        if (!existingFavourite) {
            return;
        }

        existingFavourite.fixtureStatusId = fixture.fixtureStatus.id;
        existingFavourite.cpDate = fixture.charterParty?.charterPartyDate;
        existingFavourite.laycanDateStart = fixture.laycan?.date?.from;
        existingFavourite.laycanDateEnd = fixture.laycan?.date?.to;
        existingFavourite.lockedUserFullName = null;
        existingFavourite.lockedUserId = null;
        existingFavourite.voyageReference = fixture.voyageReference;
        existingFavourite.vesselName = fixture.vessel && fixture.vessel.name;

        this.favouritesQueriedSubject.next(currentFavourites);
        this.categoriseFavourites();
    }

    private markAsLockedAndFavorite(fixture: Fixture): void {
        const currentFavorites = this.getCurrentFavoritesSnapshot();
        let favorite = currentFavorites.find((f) => f.id === fixture.fixtureId);
        if (favorite) {
            favorite.lockedUserFullName = this.authService.systemUser.name;
            favorite.lockedUserId = this.authService.systemUser.systemUserId;

            this.favouritesQueriedSubject.next(currentFavorites);
            this.categoriseFavourites();
        } else {
            favorite = createFavoriteFromFixture(fixture, this.authService.systemUser.systemUserId);
            favorite.lockedUserFullName = this.authService.systemUser.name;
            favorite.lockedUserId = this.authService.systemUser.systemUserId;

            this.addFavorite(favorite);
        }
    }

    private markAsUnlocked(fixtureId: string): void {
        const currentFavorites = this.getCurrentFavoritesSnapshot();
        const favorite = currentFavorites.find((f) => f.id === fixtureId);

        if (favorite) {
            favorite.lockedUserFullName = null;
            favorite.lockedUserId = null;
            this.favouritesQueriedSubject.next(currentFavorites);
            this.categoriseFavourites();
        }
    }
}
