import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import * as R from "ramda";
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from "rxjs";
import { filter, map, takeUntil, withLatestFrom } from "rxjs/operators";

import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { VoyageNumber, VoyageNumberService } from "../../fixture/services/voyage-number.service";
import { FixtureType } from "../../fixture/shared/models";
import { FixtureWarning, FixtureWarningState } from "../../fixture/shared/warnings/fixture-warning.model";
import { FixtureWarningService } from "../../fixture/shared/warnings/fixture-warning.service";
import { FixtureWarningsInterop } from "../../fixture/state/_interop/fixture-warnings-interop.service";
import { LeftBarStateService } from "../left-bar-state.service";

declare type WarningsFilterOptions = {
    all: boolean;
    active: boolean;
    dismissed: boolean;
};

@Component({
    selector: "ops-warnings",
    templateUrl: "./fixture-warnings.component.html",
    styleUrls: ["./fixture-warnings.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixtureWarningsComponent implements OnInit, OnDestroy {
    static componentName = "FixtureWarningsComponent";

    private readonly destroy$ = new Subject();

    warningStates$: Observable<ReadonlyArray<FixtureWarningState>>;
    voyageNumbers$ = new BehaviorSubject<ReadonlyArray<VoyageNumber>>([]);
    isVoyageFixture$ = new BehaviorSubject<boolean>(true);
    parentTimeCharterWarningStates$ = new BehaviorSubject<FixtureWarningState[]>([]);
    timeCharterVoyageWarningStates$ = new BehaviorSubject<FixtureWarningState[]>([]);
    filteredTimeCharterVoyageWarningStates$ = new BehaviorSubject<FixtureWarningState[]>([]);
    filterOptions$ = new BehaviorSubject<WarningsFilterOptions>({ all: false, active: true, dismissed: false });

    selectedVoyageIds: string[] = [];
    allSelected = false;

    get voyagesSelectedText() {
        return `(${this.selectedVoyageIds.length}) Voyage${this.selectedVoyageIds.length === 1 ? "" : "s"} Selected`;
    }

    constructor(
        private warningService: FixtureWarningService,
        private leftBarStateService: LeftBarStateService,
        private fixtureDataService: FixtureDataService,
        private voyageNumberService: VoyageNumberService,
        private fixtureWarningsInterop: FixtureWarningsInterop
    ) {}

    ngOnInit() {
        this.fixtureWarningsInterop.init();

        this.warningStates$ = combineLatest([this.warningService.warningStates$, this.filterOptions$]).pipe(
            map(([warningStates, filterOptions]) =>
                warningStates?.filter((ws) => filterOptions.all || (filterOptions.active && !ws.isDismissed) || (filterOptions.dismissed && ws.isDismissed))
            )
        );

        this.fixtureDataService.currentFixture$
            .pipe(
                takeUntil(this.destroy$),
                filter((fixture) => !!fixture)
            )
            .subscribe((fixture) => this.isVoyageFixture$.next(fixture.fixtureType.id === FixtureType.Voyage));

        this.warningStates$
            .pipe(
                takeUntil(this.destroy$),
                withLatestFrom(this.isVoyageFixture$),
                filter(([, isVoyageFixture]) => !isVoyageFixture)
            )
            .subscribe(([warningStates]) => {
                this.parentTimeCharterWarningStates$.next(warningStates.filter((ws) => !this.isVoyageWarning(ws.warning)));
                const tcvWarnings = R.sortBy(
                    (ws) => this.getVoyageNumber(ws.warning),
                    warningStates.filter((ws) => this.isVoyageWarning(ws.warning))
                );
                this.timeCharterVoyageWarningStates$.next(tcvWarnings);
            });

        combineLatest([this.voyageNumberService.voyageNumbers$, this.timeCharterVoyageWarningStates$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([numbers, warningStates]) => {
                this.voyageNumbers$.next(numbers);
                if (!this.selectedVoyageIds.length) {
                    const voyageIds = warningStates.map((w) => this.getVoyageId(w.warning));
                    this.selectedVoyageIds = [...new Set(voyageIds.filter((voyageId) => numbers.find((n) => n.voyageId === voyageId)))];
                }

                if (this.selectedVoyageIds.length === numbers.length) {
                    this.allSelected = true;
                }

                this.filteredTimeCharterVoyageWarningStates$.next(this.getFilteredWarnings(warningStates));
            });

        // When the component is destroyed (panel switched) or the panel is collapsed on a bigger screen,
        // clear the current warning to null
        merge(this.leftBarStateService.isCollapsed$.pipe(filter((isCollapsed) => isCollapsed && this.leftBarStateService.displayMode === "inline")), this.destroy$).subscribe(
            () => {
                this.warningService.setCurrentWarning(null);
            }
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.fixtureWarningsInterop.destroy();
    }

    onActivate(warning: FixtureWarning) {
        this.warningService.activateWarning(warning);
    }

    onDismiss(warning: FixtureWarning) {
        this.warningService.dismissWarning(warning);
    }

    onSetCurrentWarning(warning: FixtureWarning) {
        this.warningService.setCurrentWarning(warning);

        if (warning) {
            this.leftBarStateService.collapseIfSmallWindow();
        }
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }

    toggleSelectAll(checked: boolean) {
        this.selectedVoyageIds = checked ? this.voyageNumbers$.value.map((n) => n.voyageId) : [];
    }

    onSelectionChange() {
        this.allSelected = this.selectedVoyageIds.length === this.voyageNumbers$.value.length;
        const filteredWarnings = this.getFilteredWarnings(this.timeCharterVoyageWarningStates$.value);
        this.filteredTimeCharterVoyageWarningStates$.next(filteredWarnings);
    }

    get warningsFilterOptions$() {
        return this.warningService.warningStates$.pipe(
            map((warningStates) => {
                const dismissedWarningsCount = warningStates?.filter((w) => w.isDismissed).length ?? 0;
                const allWarningsCount = warningStates?.length ?? 0;
                return {
                    all: `All (${allWarningsCount})`,
                    active: `Active (${allWarningsCount - dismissedWarningsCount})`,
                    dismissed: `Dismissed (${dismissedWarningsCount})`
                };
            })
        );
    }

    get tooltipContent$() {
        return combineLatest([this.warningsFilterOptions$, this.filterOptions$]).pipe(
            map(([options, filterOptions]) => {
                const selectedOptions = [];
                if (filterOptions.all) {
                    selectedOptions.push(options.all);
                }
                if (filterOptions.active) {
                    selectedOptions.push(options.active);
                }
                if (filterOptions.dismissed) {
                    selectedOptions.push(options.dismissed);
                }
                return `Filters applied: ${selectedOptions.length > 0 ? selectedOptions.join(", ") : "None"}`;
            })
        );
    }

    toggleAllWarnings() {
        const currentValue = this.filterOptions$.value;
        this.filterOptions$.next({
            all: !currentValue.all,
            active: !currentValue.all,
            dismissed: !currentValue.all
        });
    }

    toggleActiveWarnings() {
        const currentValue = this.filterOptions$.value;
        this.filterOptions$.next({
            all: !currentValue.active && currentValue.dismissed,
            active: !currentValue.active,
            dismissed: currentValue.dismissed
        });
    }

    toggleDismissedWarnings() {
        const currentValue = this.filterOptions$.value;
        this.filterOptions$.next({
            all: currentValue.active && !currentValue.dismissed,
            active: currentValue.active,
            dismissed: !currentValue.dismissed
        });
    }

    get anyFilterOptionSelected() {
        const currentValue = this.filterOptions$.value;
        return currentValue.all || currentValue.active || currentValue.dismissed;
    }

    get allWarningsSelected() {
        return this.filterOptions$.value.all;
    }

    get activeWarningsSelected() {
        return this.filterOptions$.value.active;
    }

    get dismissedWarningsSelected() {
        return this.filterOptions$.value.dismissed;
    }

    private getFilteredWarnings(warningStates: FixtureWarningState[]) {
        return warningStates.filter((warningState) => {
            const voyageId = this.getVoyageId(warningState.warning);
            return this.selectedVoyageIds.includes(voyageId);
        });
    }

    private isVoyageWarning(warning: FixtureWarning) {
        return warning.path[0].segment === "voyages";
    }

    private getVoyageNumber(warning: FixtureWarning) {
        return warning.path[1].name;
    }

    private getVoyageId(warning: FixtureWarning) {
        return warning.path[1].segment;
    }
}
