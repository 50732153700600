import { Directive, Input, OnChanges, OnDestroy, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subject } from "rxjs";

import { SimpleChanges } from "@ops/shared";

import { FixtureDataService } from "../../services/fixture-data.service";

@Directive({
    selector: "[opsDivisions], [opsDivisionsExclude]"
})
export class DivisionsDirective implements OnChanges, OnDestroy {
    private readonly destroy$ = new Subject();

    private previousState: boolean;

    @Input() opsDivisions?: Array<number> | number;
    @Input() opsDivisionsExclude?: Array<number> | number;

    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private fixtureDataService: FixtureDataService
    ) {}

    ngOnChanges(_changes: SimpleChanges<DivisionsDirective>) {
        if (!this.opsDivisions && !this.opsDivisionsExclude) {
            console.warn("DivisionsDirective: Division IDs are required");
            return;
        }

        if (this.opsDivisions && this.opsDivisionsExclude) {
            throw new Error("DivisionsDirective: *opsDivisions and *opsDivisionsExclude may not be specified on the same element");
        }

        let divisions;
        let exclude = false;

        if (this.opsDivisions) {
            divisions = this.opsDivisions;
        } else {
            divisions = this.opsDivisionsExclude;
            exclude = true;
        }

        const divisionsArray = Array.isArray(divisions) ? divisions : [Number(divisions)];
        const newState = divisionsArray.includes(this.fixtureDataService.division) !== exclude;

        if (newState === this.previousState) {
            return;
        }

        if (newState) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }

        this.previousState = newState;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
