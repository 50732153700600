import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { AppConfigService } from "@ops/core";

import { LaytimeCalculation, LtcFeatureState, selectCurrentLaytimeCalculation, selectCurrentFixtureHeader, FixtureHeader } from "../../state";

@Component({
    selector: "ops-laytime-calculation-container-shell",
    templateUrl: "./laytime-calculation-container-shell.component.html",
    styleUrls: ["./laytime-calculation-container-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationContainerShellComponent {
    readonly fixtureHeader$: Observable<FixtureHeader>;
    readonly laytimeCalculation$: Observable<LaytimeCalculation>;

    constructor(private store: Store<LtcFeatureState>, appConfigService: AppConfigService) {
        this.fixtureHeader$ = this.store.select(selectCurrentFixtureHeader, { seaNetUrl: appConfigService.config.seaNetUrl });
        this.laytimeCalculation$ = this.store.select(selectCurrentLaytimeCalculation);
    }
}
