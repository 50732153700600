import { Injectable } from "@angular/core";

import { AssociatedCargo, Fixture, Voyage } from "../../../models";
import { FreightUnit } from "../../../models/enums/freight-unit";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class FreightLargeActualFreightRateRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    private readonly warningMessage = "The Actual Freight Rate entered is more than 1,000 Per MT";
    private readonly largeFreightRate = 1000;

    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        voyages.forEach((voyage) =>
            voyage.destinations.forEach((destination, destinationIndex) =>
                destination.berths.forEach((berth, berthIndex) =>
                    berth.cargoBerthActivities.forEach((activity, activityIndex) =>
                        activity.associatedCargoes.forEach((associatedCargo, associatedCargoIndex) => {
                            const cargo = voyage.cargoes.find((c) => c.id === associatedCargo.cargoId);
                            if (cargo?.baseFreightRateUnit?.id === FreightUnit.PerMT && associatedCargo.freightRate >= this.largeFreightRate) {
                                const path = [
                                    ...this.getAssociatedCargoPath(
                                        fixture,
                                        voyage,
                                        destinationIndex,
                                        destination,
                                        berthIndex,
                                        berth,
                                        activityIndex,
                                        activity,
                                        associatedCargoIndex,
                                        associatedCargo
                                    ),
                                    FixtureWarningPathSegment.fromProperty<AssociatedCargo>("freightRate", "Actual Freight Rate")
                                ];
                                const dismissingRecord = dismissedWarningRecord(
                                    new WarningHashBuilder()
                                        .withVoyage(voyage.voyageId)
                                        .withDestination(destination.destinationId)
                                        .withBerth(berth.berthId)
                                        .withBerthActivity(activity.berthActivityId)
                                        .withAssociatedCargo(associatedCargo.associatedCargoId)
                                        .withCategory("freight")
                                        .withMessage(this.warningMessage)
                                        .build(),
                                    associatedCargo.freightRate
                                );
                                warnings.push(new FixtureWarning("freight", this.warningMessage, path, dismissingRecord));
                            }
                        })
                    )
                )
            )
        );

        return warnings;
    }
}
