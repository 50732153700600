<div *ngIf="notifications$ | async as notifications">
    <div class="notifications-container w-100" 
        [class.blocking]="blocking$ | async" [class.major-update-available]="majorUpdateVersion$ | async">
        <div class="notification w-100 {{getColourClass(notification.type)}}"
            *ngFor="let notification of notifications; let i = index;"
            [ngClass]="{ 'notification-left': i <= notificationIndex, 'notification-right': i > notificationIndex }"
            (click)="notificationBarClicked(notification)">
            <span class="notification-icon has-icon" [ngClass]="getIconClass(notification.type)"></span>
            <span class="notification-title">{{notification.title}}</span>
            <span class="notification-message" [innerHTML]="notification.message"></span>
            <button *ngIf="notification.dismissible" class="notification-close float-right has-icon icon--close"
                    (click)="remove(notification)"></button>
            <ops-load-animation *ngIf="notification.showLoadingAnimation" size="x-small" class="loading-animation">
            </ops-load-animation>
        </div>
    </div>

</div>
