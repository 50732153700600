import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";
import { Observable } from "rxjs";

import { PersistenceStatus } from "@ops/state";

import {
    ActivityLocation,
    ActivityLocationId,
    closeLaytimeCalculation,
    LaytimeCalculationNameForm,
    LtcFeatureState,
    LtcId,
    selectCurrentActivityLocationId,
    selectCurrentCalculationActivityLocations,
    selectCurrentLaytimeCalculationId,
    cancelLaytimeCalculationRename,
    updateLaytimeCalculationName,
    selectCurrentLaytimeCalculationNameForm,
    selectCurrentLaytimeCalculationPersistenceStatus,
    selectCurrentLaytimeCalculationLastUpdated,
    cloneLaytimeCalculation
} from "../../state";
import { exportLaytimeCalculation as exportLaytimeCalculation, selectIsExportLoading } from "../../state/calculations/export";

@Component({
    selector: "ops-laytime-calculation-shell",
    templateUrl: "./laytime-calculation-shell.component.html",
    styleUrls: ["./laytime-calculation-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationShellComponent {
    readonly ltcId$: Observable<LtcId>;
    readonly activityLocations$: Observable<ReadonlyArray<ActivityLocation>>;
    readonly currentActivityLocationId$: Observable<ActivityLocationId>;
    readonly nameForm$: Observable<FormGroupState<LaytimeCalculationNameForm>>;
    readonly persistenceStatus$: Observable<PersistenceStatus>;
    readonly lastUpdated$: Observable<string>;
    readonly isExportLoading$: Observable<boolean>;

    constructor(private store: Store<LtcFeatureState>) {
        this.ltcId$ = this.store.select(selectCurrentLaytimeCalculationId);
        this.activityLocations$ = this.store.select(selectCurrentCalculationActivityLocations);
        this.currentActivityLocationId$ = this.store.select(selectCurrentActivityLocationId);
        this.nameForm$ = this.store.select(selectCurrentLaytimeCalculationNameForm);
        this.persistenceStatus$ = this.store.select(selectCurrentLaytimeCalculationPersistenceStatus);
        this.lastUpdated$ = this.store.select(selectCurrentLaytimeCalculationLastUpdated);
        this.isExportLoading$ = this.store.select(selectIsExportLoading);
    }

    confirmRename() {
        this.store.dispatch(updateLaytimeCalculationName());
    }

    cancelRename() {
        this.store.dispatch(cancelLaytimeCalculationRename());
    }

    close() {
        this.store.dispatch(closeLaytimeCalculation());
    }

    exportExcel() {
        this.store.dispatch(exportLaytimeCalculation({ format: "xlsx" }));
    }

    exportPdf() {
        this.store.dispatch(exportLaytimeCalculation({ format: "pdf" }));
    }

    clone() {
        this.store.dispatch(cloneLaytimeCalculation());
    }
}
