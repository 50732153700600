import { Injectable } from "@angular/core";

import { deepFreeze } from "../shared/deep-freeze";

@Injectable({
    providedIn: "root"
})
export class AppConfigService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static _config: any = {};

    get config() {
        return AppConfigService._config;
    }

    static load() {
        return fetch("appsettings.json")
            .then((response) => {
                // eslint-disable-next-line no-magic-numbers
                if (response.status !== 200) {
                    console.error("Failed to load configuration with status code: ", response.status);
                    return;
                }

                return response.json().then((data) => {
                    AppConfigService._config = deepFreeze(data);
                });
            })
            .catch((err) => {
                console.error("Failed to load configuration with error: ", err);
                throw err;
            });
    }
}
