import { AgGridModule } from "@ag-grid-community/angular";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { NgrxFormsModule } from "ngrx-forms";
import { AutoSizeInputModule } from "ngx-autosize-input";
import { TableModule } from "primeng/table";

import {
    LaytimeCalculationNavComponent,
    LaytimeCalculationContainerShellComponent,
    LaytimeCalculationTermsShellComponent,
    LaytimeCalculationHeaderComponent,
    FixtureHeaderComponent,
    LaytimeCalculationListComponent,
    LaytimeCalculationListItemComponent,
    VoyageActivityFilterComponent,
    VoyageActivitiesFilterComponent,
    AddActivityLocationsFilterShellComponent,
    CargoFilterComponent,
    CargoesFilterComponent,
    AssociatedCargoesByVoyageActivityComponent,
    AssociatedCargoesByVoyageActivitiesComponent,
    AddActivityLocationsSelectionShellComponent,
    AssociatedCargoesByCargoComponent,
    AssociatedCargoesByCargoesComponent,
    AddActivityLocationsShellComponent,
    VoyageActivityComponent,
    LaytimeCalculationShellComponent,
    LaytimeCalculationListShellComponent,
    LaytimeCalculationActivityLocationShellComponent,
    LaytimeCalculationCargoTermRowComponent,
    LaytimeCalculationCargoTermsComponent,
    LaytimeCalculationCargoTermLocationComponent,
    LaytimeCalculationActivityLocationFormComponent,
    LaytimeCalculationActivityCargoesComponent,
    LaytimeCalculationLaytimeEventsComponent,
    LaytimeCalculationSummaryShellComponent,
    LaytimeCalculationSummaryLocationsComponent,
    LaytimeCalculationActivityLocationSummaryComponent,
    AnchorRendererComponent,
    LaytimeCalculationGridComponent,
    LaytimeCalculationGridSearchComponent,
    LaytimeCalculationDeductionsComponent,
    LaytimeCalculationEnterDeductionsComponent
} from "./components";
import { LtcRoutingModule } from "./ltc-routing.module";
import { LtcStoreModule } from "./state";
import { FixtureModule } from "../fixture/fixture.module";
import { SharedModule } from "../shared";
import { LoadingOverlayComponent } from "../shared/grid";
import { GridsSharedModule } from "../shared/grid/grids-shared.module";
import { WorksheetStoreModule } from "../state/worksheets/worksheet-store.module";

@NgModule({
    declarations: [
        LaytimeCalculationContainerShellComponent,
        FixtureHeaderComponent,
        LaytimeCalculationHeaderComponent,
        LaytimeCalculationNavComponent,
        LaytimeCalculationTermsShellComponent,
        LaytimeCalculationListShellComponent,
        LaytimeCalculationListComponent,
        LaytimeCalculationListItemComponent,
        LaytimeCalculationActivityLocationShellComponent,
        LaytimeCalculationActivityLocationFormComponent,
        LaytimeCalculationActivityLocationSummaryComponent,
        LaytimeCalculationCargoTermsComponent,
        LaytimeCalculationCargoTermRowComponent,
        LaytimeCalculationCargoTermLocationComponent,
        LaytimeCalculationListItemComponent,
        VoyageActivitiesFilterComponent,
        CargoesFilterComponent,
        VoyageActivityFilterComponent,
        CargoFilterComponent,
        AddActivityLocationsFilterShellComponent,
        AddActivityLocationsSelectionShellComponent,
        AddActivityLocationsShellComponent,
        AssociatedCargoesByCargoesComponent,
        AssociatedCargoesByCargoComponent,
        AssociatedCargoesByVoyageActivitiesComponent,
        AssociatedCargoesByVoyageActivityComponent,
        VoyageActivityComponent,
        LaytimeCalculationShellComponent,
        LaytimeCalculationActivityCargoesComponent,
        LaytimeCalculationLaytimeEventsComponent,
        LaytimeCalculationShellComponent,
        LaytimeCalculationCargoTermLocationComponent,
        LaytimeCalculationSummaryShellComponent,
        LaytimeCalculationSummaryLocationsComponent,
        AnchorRendererComponent,
        LaytimeCalculationGridComponent,
        LaytimeCalculationGridSearchComponent,
        LaytimeCalculationDeductionsComponent,
        LaytimeCalculationEnterDeductionsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgrxFormsModule,
        MatDialogModule,
        LtcRoutingModule,
        LtcStoreModule,
        TableModule,
        FixtureModule,
        AutoSizeInputModule,
        GridsSharedModule,
        AgGridModule.withComponents([AnchorRendererComponent, LoadingOverlayComponent]),
        WorksheetStoreModule
    ]
})
export class LtcModule {}
