import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { AppConfigService, AuthService } from "src/app/core";
import { ExportService } from "../../shared/export-service";
import { FavoriteDataService } from "../../shared/favorite-data.service";

@Component({
    selector: "ops-favorite-toolbar",
    templateUrl: "./favorite-toolbar.component.html",
    styleUrls: ["./favorite-toolbar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteToolbarComponent {
    static componentName = "FavoriteToolbarComponent";

    @Output() removed = new EventEmitter<any>();
    @Output() exported = new EventEmitter<any>();
    @Output() openAll = new EventEmitter<any>();

    @Input() allSelected: Boolean;
    @Input() anySelected: Boolean;

    isExportVisible = false;
    @Output() exportInProgress = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private favoriteService: FavoriteDataService,
        private exportService: ExportService,
        appConfigService: AppConfigService,
        authService: AuthService
    ) {
        this.isExportVisible =
            appConfigService.config.isExportVisible === "true"
            && (authService.systemUser.department === "Tanker" || authService.systemUser.department === "IT");
    }

    removeSelected(): void {
        if (confirm("Are you sure you want to remove the selected favorites?")) {
            this.favoriteService.removeSelectedFavorites();
            this.removed.emit();
        }
    }

    exportSelected(): void {
        this.exportInProgress = true;
        this.exportService.exportSelectedFavorites("VoyageReport");
        this.exported.emit();

        setTimeout(() => {
            this.exportInProgress = false;
            this.changeDetector.markForCheck();
        }, 1000);
    }

    openSelected() {
        this.openAll.emit();
    }
}
