import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";
import { Observable } from "rxjs";

import { AppConfigService } from "@ops/core";

import {
    LaytimeCalculationCargoTermsForm,
    laytimeCalculationRoundings,
    LaytimeCalculationTermsForm,
    laytimeCalculationDurationUnits,
    LtcFeatureState,
    selectCurrentLaytimeCalculationRateUnitLabel,
    selectCurrentLaytimeCalculationTermsForm,
    selectCargoTermsForm,
    selectCurrentCargoTermsId,
    timeAllowances,
    timesSaved,
    CargoTermsId,
    LaytimeCalculationCargoTermsListItem,
    LocationId,
    ActivityType,
    selectCargoTermsListItems,
    cancelCargoTermsEdit,
    updateCargoTerms,
    removeCargoTerms,
    removeCargoTermsLocation,
    editCargoTerms
} from "../../state";

@Component({
    selector: "ops-laytime-calculation-terms-shell",
    templateUrl: "./laytime-calculation-terms-shell.component.html",
    styleUrls: ["./laytime-calculation-terms-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationTermsShellComponent {
    readonly maxLength = 2500;
    readonly laytimeDurationUnits = laytimeCalculationDurationUnits;
    readonly timeAllowances = timeAllowances;
    readonly timesSaved = timesSaved;
    readonly laytimeCalculationRoundings = laytimeCalculationRoundings;
    readonly laytimeCalculationTermsForm$: Observable<FormGroupState<LaytimeCalculationTermsForm>>;
    readonly rateUnitLabel$: Observable<string>;
    readonly cargoTerms$: Observable<ReadonlyArray<LaytimeCalculationCargoTermsListItem>>;
    readonly currentCargoTermsId$: Observable<CargoTermsId>;
    readonly cargoTermsForm$: Observable<LaytimeCalculationCargoTermsForm>;

    constructor(private store: Store<LtcFeatureState>, appConfigService: AppConfigService) {
        this.laytimeCalculationTermsForm$ = this.store.select(selectCurrentLaytimeCalculationTermsForm);
        this.rateUnitLabel$ = this.store.select(selectCurrentLaytimeCalculationRateUnitLabel);
        this.cargoTerms$ = this.store.select(selectCargoTermsListItems, { cdnUrl: appConfigService.config.clarksonsCdnUrl });
        this.currentCargoTermsId$ = this.store.select(selectCurrentCargoTermsId);
        this.cargoTermsForm$ = this.store.select(selectCargoTermsForm);
    }

    editCargoTerms(cargoTermsId: CargoTermsId) {
        this.store.dispatch(editCargoTerms({ cargoTermsId }));
    }

    cancelEditCargoTerms() {
        this.store.dispatch(cancelCargoTermsEdit());
    }

    saveCargoTerms() {
        this.store.dispatch(updateCargoTerms());
    }

    removeCargoTerms(cargoTermsId: CargoTermsId) {
        this.store.dispatch(removeCargoTerms({ cargoTermsId }));
    }

    removeCargoTermsLocation(location: { activityType: ActivityType; locationId: LocationId }) {
        this.store.dispatch(removeCargoTermsLocation(location));
    }
}
