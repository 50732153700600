import { v4 as uuid } from "uuid";

import { Identity } from "@ops/shared";

export type AdditionalFreightExpenseId = Identity<string, "AdditionalFreightExpenseId">;
export const createAdditionalFreightExpenseId = (): AdditionalFreightExpenseId => uuid() as AdditionalFreightExpenseId;

export interface AdditionalFreightExpense {
    id: AdditionalFreightExpenseId;
    additionalFreightExpenseId: number;
    description: string;
    freightSpend?: number;
}

export const additionalFreightExpense = (id: AdditionalFreightExpenseId, additionalFreightExpenseId: number, description: string, freightSpend?: number) =>
    <AdditionalFreightExpense>{
        id,
        additionalFreightExpenseId,
        description,
        freightSpend
    };
