import { Injectable } from "@angular/core";

import { GridWebStorageServiceBase } from "../../../shared/grid/grid-webstorage.service.base";

@Injectable({
    providedIn: "root"
})
export class WorksheetLocalStorageService extends GridWebStorageServiceBase {
    constructor() {
        super("worksheet-local-storage-0_1_1");
    }
}
