import { Action, createReducer } from "@ngrx/store";

import { emptyGridState, emptyObjectMap, onObjectMapForms, wrapReducerWithObjectMapFormStatesUpdate } from "@ops/state";

import { validateActivityCargoForms } from "./calculations/activity-location/cargoes/form/validation";
import { validateDeductionForms } from "./calculations/activity-location/deductions/form/validation";
import { validateLaytimeEventForms } from "./calculations/activity-location/laytime-events/form/validation";
import activityLocationReducers from "./calculations/activity-location/reducers";
import addActivityLocationsReducers from "./calculations/add-activity-locations/reducers";
import cloneReducers from "./calculations/clone/reducers";
import createCalculationReducers from "./calculations/create-calculation/reducers";
import exportReducers from "./calculations/export/reducers";
import fixtureCalculationReducers from "./calculations/fixture-calculation/reducers";
import gridReducers from "./calculations/grid/reducers";
import { validateLaytimeCalculationNameForm } from "./calculations/name/form/validation";
import updateLtcNameReducers from "./calculations/name/reducers";
import calculationReducers from "./calculations/reducers";
import removeCalculationReducers from "./calculations/remove-calculation/reducers";
import { validateLaytimeCalculationCargoTermsForm } from "./calculations/terms/cargo-terms/form/validation";
import { validateLaytimeCalculationTermsForm } from "./calculations/terms/form/validation";
import calculationTermsReducers from "./calculations/terms/reducers";
import voyageReducers from "./calculations/voyage/reducers";
import { FixtureLaytimeCalculationsState, LaytimeCalculationIndex, LaytimeCalculationState, LtcState } from "./model";

export const initialLaytimeState: LtcState = {
    calculations: emptyObjectMap<LaytimeCalculationState>(),
    fixtureCalculations: emptyObjectMap<FixtureLaytimeCalculationsState>(),
    grid: emptyGridState<LaytimeCalculationIndex>()
};

const rawReducer = createReducer(
    initialLaytimeState,
    ...calculationReducers,
    ...calculationTermsReducers,
    ...fixtureCalculationReducers,
    ...createCalculationReducers,
    ...cloneReducers,
    ...addActivityLocationsReducers,
    ...updateLtcNameReducers,
    ...activityLocationReducers,
    ...exportReducers,
    ...gridReducers,
    ...voyageReducers,
    ...removeCalculationReducers,
    onObjectMapForms((s: LtcState) => s.calculations)
);

export const reducer = (state: LtcState | undefined, action: Action) => {
    const validateCalculation = {
        calculationNameForm: validateLaytimeCalculationNameForm,
        calculationTermsForm: validateLaytimeCalculationTermsForm,
        activityCargoForms: validateActivityCargoForms,
        laytimeEventForms: validateLaytimeEventForms,
        deductionForms: validateDeductionForms
    };

    let reducedState = rawReducer(state, action);
    reducedState = validateLaytimeCalculationCargoTermsForm(reducedState);
    reducedState = wrapReducerWithObjectMapFormStatesUpdate((s: LtcState) => s.calculations, validateCalculation)(reducedState);

    return reducedState;
};
