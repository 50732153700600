import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppConfigService } from "@ops/core";
import { Settings } from "luxon";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
    enableProdMode();
}

AppConfigService.load().then(() =>
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => {
            console.log(err);
        })
);

Settings.defaultLocale = "en-GB";
