import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { PersonUtilities } from "./person-utilities";
import { FixtureDataService } from "../../../fixture/services/fixture-data.service";
import { CompanyType } from "../../../fixture/shared/models";
import { PhoneType } from "../../../fixture/shared/models/enums/phone-type";
import { DateUtilities } from "../../../shared/date-utils/date-utilities";
import { ContactService } from "../contact.service";
import { Person } from "../models/person.model";

@Component({
    selector: "ops-person",
    templateUrl: "./person.component.html",
    styleUrls: ["./person.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonComponent implements OnInit, OnDestroy {
    static componentName = "PersonComponent";

    private readonly destroy$ = new Subject();

    phoneTypes = PhoneType;
    hidePersonExtraInfo = true;
    commentsForm: UntypedFormGroup;
    emailSubject: string;
    personUtilities: PersonUtilities = new PersonUtilities();

    @Input() person: Person;
    @Input() companyId: string;

    constructor(private fb: UntypedFormBuilder, private contactService: ContactService, private fixtureDataService: FixtureDataService) {}

    ngOnInit() {
        this.commentsForm = new UntypedFormGroup(
            {
                comments: this.fb.control(null)
            },
            { updateOn: "blur" }
        );

        this.setCommentsValues(this.person.comments);
        if (this.person.hidePersonExtraInfo !== undefined) {
            this.hidePersonExtraInfo = this.person.hidePersonExtraInfo;
        }
        this.commentsForm.valueChanges.subscribe((x) => this.saveComment(x.comments));

        combineLatest([this.fixtureDataService.currentFixture$, this.contactService.companies$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([fixture, companies]) => {
                if (fixture) {
                    const charterer = companies.filter((c) => c.typeId === CompanyType.Charterer)[0];
                    const chartererName = charterer ? charterer.name : "TBN";
                    const cpDate = DateUtilities.formatISODate(fixture.charterParty.charterPartyDate, "utc", "DD MMM YYYY");
                    this.emailSubject = `${fixture.vessel?.name ?? "TBN"} / ${chartererName} / ${cpDate}`;
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
    }

    removePerson(person: Person) {
        this.contactService.removePerson(person.id, this.companyId);
    }

    saveComment(comment: string) {
        this.contactService.saveComment(comment, this.person.id, this.companyId);
    }

    togglePersonExtraInfo(): void {
        this.hidePersonExtraInfo = !this.hidePersonExtraInfo;
        this.person.hidePersonExtraInfo = this.hidePersonExtraInfo;
    }

    private setCommentsValues(comments: string): void {
        const commentsFormValues = {
            comments: comments
        };

        this.commentsForm.patchValue(commentsFormValues, { emitEvent: false });
    }
}
