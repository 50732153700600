import { createAction, on, On } from "@ngrx/store";
import { addArrayControl, updateGroup } from "ngrx-forms";

import { createAdditionalFreightExpenseId } from "../../shared/models/dtos/additional-freight-expense.dto";
import { additionalFreightExpenseForm, FixturesState, VoyageForm } from "../model";
import { getNextId } from "../utils";
import { currentVoyageStateReducer } from "../voyage/reducer";

/* ACTIONS */
const ADD_ADDITIONAL_FREIGHT_EXPENSE = "[Voyage Form] Add Additional Freight Expense";

export const addAdditionalFreightExpenseAction = createAction(ADD_ADDITIONAL_FREIGHT_EXPENSE);

/* REDUCERS */
export const addAdditionalFreightExpenseReducer: On<FixturesState> = on(addAdditionalFreightExpenseAction, (state) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const additionalFreightExpenses = voyageState.form.value.additionalFreightExpenses;
        const newExpenseId = createAdditionalFreightExpenseId();
        const newExpense = additionalFreightExpenseForm(newExpenseId, getNextId(additionalFreightExpenses, "additionalFreightExpenseId"));

        return {
            ...voyageState,
            form: updateGroup<VoyageForm>({
                additionalFreightExpenses: addArrayControl(newExpense)
            })(voyageState.form)
        };
    })
);
