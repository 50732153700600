import { Injectable } from "@angular/core";

import { deepCopy } from "@ops/shared";

import { CommandHandler } from "../../../../mediator";
import { Fixture } from "../../../../shared/models";
import { CloneBunkerConsumptionCommand } from "./clone-bunker-consumption.command";

@Injectable({
    providedIn: "root"
})
export class CloneBunkerConsumptionCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: CloneBunkerConsumptionCommand) {
        const bunkerConsumptionId = command.payload as number;
        if (bunkerConsumptionId === undefined || bunkerConsumptionId === null || isNaN(bunkerConsumptionId)) {
            throw new Error("Given bunker consumption ID is invalid.");
        }

        const bunkerConsumption = fixture.cpSpeedAndConsumption.cpBunkersConsumption.find((b) => b.bunkerConsumptionId === bunkerConsumptionId);
        if (!bunkerConsumption) {
            throw new Error("Unable to find bunker consumption with a given ID.");
        }

        const maxId = fixture.cpSpeedAndConsumption.cpBunkersConsumption.reduce((max, current) => (current.bunkerConsumptionId > max ? current.bunkerConsumptionId : max), 0);
        const bunkerConsumptionClone = deepCopy(bunkerConsumption);

        bunkerConsumptionClone.bunkerConsumptionId = maxId + 1;
        fixture.cpSpeedAndConsumption.cpBunkersConsumption.push(bunkerConsumptionClone);
    }
}
