<div class="input-group h-100">
    <div class="weekday" *ngIf="weekday">
        <span>{{weekday}}</span>
    </div>

    <input
        class="form-control date-input"
        [placeholder]="placeholder"
        [readonly]="readonly || isDisabled"
        [disabled]="disabled"
        (keydown)="onInputKeyDown($event)"
        (keyup)="onInputKeyUp($event)"
        (blur)="onInputBlur()"
        (focus)="onInputFocus()"
        autocomplete="nope"
        opsSelectWords
    />
    <div class="autocompletion"
         *ngIf="autoComplete && !autoComplete.overflow"
         [class.right]="!autoComplete.partial"
         [style.left.px]="autoComplete.offset">
        <span>{{autoComplete.text}}</span>
    </div>

    <div class="input-group-append" *ngIf="showPickerButton">
        <button class="btn has-icon icon--date-range" (click)="togglePicker()" (blur)="onButtonBlur()" type="button"></button>
    </div>
</div>
