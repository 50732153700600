import { cloneLiftingCargoReducer } from "./form/clone-cargo";
import { cancelLiftingCargoEditReducer, editLiftingCargoReducer } from "./form/edit-cargo";
import { saveCargoLaycanFormReducer, saveCargoPlanStatusFailReducer, saveCargoPlanStatusSuccessReducer, saveLaycanFailReducer, saveLaycanSuccessReducer } from "./form/edit-laycan";
import { addDischargeLocationReducer, addLoadLocationReducer, removeDischargeLocationReducer, removeLoadLocationReducer } from "./form/locations";
import { removeCargoFailReducer, removeCargoSuccessReducer } from "./form/remove-cargo";
import { saveCargoFailReducer, saveCargoReducer, saveCargoSuccessReducer, savingCargoReducer } from "./form/save-cargo";
import { updateCargoPlanStatusReducer } from "./update-cargo-plan-status";

export default [
    saveCargoLaycanFormReducer,
    saveLaycanSuccessReducer,
    saveCargoPlanStatusSuccessReducer,
    saveLaycanFailReducer,
    saveCargoPlanStatusFailReducer,
    cloneLiftingCargoReducer,
    editLiftingCargoReducer,
    cancelLiftingCargoEditReducer,
    removeCargoSuccessReducer,
    removeCargoFailReducer,
    savingCargoReducer,
    saveCargoReducer,
    saveCargoFailReducer,
    saveCargoSuccessReducer,
    addLoadLocationReducer,
    addDischargeLocationReducer,
    removeLoadLocationReducer,
    removeDischargeLocationReducer,
    updateCargoPlanStatusReducer
];
