import { addAdditionalFreightExpenseReducer } from "./add-additional-freight-expense";
import { collapseCargoTotalsReducer, expandCargoTotalsReducer } from "./expand-totals";
import { removeAdditionalFreightExpenseReducer } from "./remove-additional-freight-expense";

export default [
    expandCargoTotalsReducer,
    collapseCargoTotalsReducer,
    addAdditionalFreightExpenseReducer,
    removeAdditionalFreightExpenseReducer
];
