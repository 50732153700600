import { Boxed, FormArrayState, FormGroupState, unbox, updateArray, updateGroup, ValidationErrors } from "ngrx-forms";

import { validateRequired, validateRequiredIf } from "@ops/state";

import { QuantityModel } from "../../../../../../fixture/shared/models/form-models";
import { ActivityCargoForm } from "../../../../model";

const validateActivityCargoForm = (form: FormGroupState<ActivityCargoForm>) =>
    updateGroup<ActivityCargoForm>({
        id: validateRequired(),
        cargoProduct: validateRequired(),
        allowance: validateRequiredIf(!!form.value.allowance, validateAllowance)
    })(form);

const validateAllowance = (value: Boxed<QuantityModel>): ValidationErrors => {
    const unboxedValue = unbox(value);
    if (!unboxedValue.value || unboxedValue.unit) {
        return {};
    }
    return { valueWithoutUnit: true };
};

export const validateActivityCargoForms = (forms: FormArrayState<ActivityCargoForm>) => updateArray<ActivityCargoForm>(validateActivityCargoForm)(forms);
