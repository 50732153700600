import { createSelector } from "@ngrx/store";

import { getGridRecordNumbers, GridPage, isFinalStatus, SearchCriteria } from "@ops/state";

import { selectCoasFeature } from "../coa.selectors";
import { CoaFeatureState, getGridState } from "../model";
import { CoaGridState } from "../model/grid";
import { CoaGridRow, getCoaGridRow, getCoaGridSorting } from "./grid-row";

export const selectCoaGridState = createSelector(selectCoasFeature, getGridState);

export const selectCoaSuggestions = createSelector(selectCoaGridState, (state) => state?.suggestions);
export const selectCoaSuggestionItems = createSelector(selectCoaSuggestions, (state) => state?.items);
export const selectCoaSuggestionsLoading = createSelector(selectCoaSuggestions, (state) => state?.loadStatus === "loading");

export const selectCoaGridCriteria = createSelector(selectCoaGridState, (state) => state?.criteria);
export const selectCoaGridSearchCriteria = createSelector<CoaFeatureState, CoaGridState, SearchCriteria>(
    selectCoaGridState,
    (state) => state && { criteria: state.criteria, sorting: getCoaGridSorting(state.sorting) }
);

export const selectCoaGridPage = createSelector<CoaFeatureState, { requestId: string }, CoaGridState, GridPage<CoaGridRow>>(selectCoaGridState, (coaGridState, { requestId }) => {
    const state = coaGridState.data[requestId];
    return (
        isFinalStatus(state?.loadStatus) && {
            loadStatus: state.loadStatus,
            items: state.items?.map(getCoaGridRow),
            error: state.error
        }
    );
});

export const selectCoaGridRecordNumbers = createSelector(selectCoaGridState, (coaGridState) => getGridRecordNumbers(coaGridState));
