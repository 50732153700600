import { addCargoReducer } from "./form/add-cargo";
import { applyAllCargoFreightRateReducer, applyCargoFreightRateReducer } from "./form/apply-cargo-freight-rate";
import { cloneCargoReducer } from "./form/clone-cargo";
import {
    collapseCargoesReducer,
    collapseCargoReducer,
    expandCargoesReducer,
    expandCargoReducer
} from "./form/expand-cargo";
import { removeCargoReducer } from "./form/remove-cargo";
import { verifySpecialisedCargoEtaSuccessReducer } from "./form/specialised/mark-specialised-cargo-eta-verified";
import { updateSpecialisedCargoDestinationsReducer } from "./form/specialised/specialised-cargo-change-handler";

export default [
    addCargoReducer,
    cloneCargoReducer,
    expandCargoReducer,
    expandCargoesReducer,
    collapseCargoReducer,
    collapseCargoesReducer,
    removeCargoReducer,
    verifySpecialisedCargoEtaSuccessReducer,
    updateSpecialisedCargoDestinationsReducer,
    applyCargoFreightRateReducer,
    applyAllCargoFreightRateReducer
];
