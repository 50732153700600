import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { Email } from "./email";
import { EmailPreviewComponent } from "./email-preview.component";
import { EmailPreviewModel } from "./email-preview.model";

@Injectable({
    providedIn: "root"
})
export class EmailPreviewService {
    private readonly maxMailToSize = 2000;

    /**
     * True if preview has been called, setting the location to `mailto:`. False immediately after, used for
     * ignoring beforeunload event.
     */
    locationChangePending: boolean;

    constructor(private dialog: MatDialog) {}

    stripBody(mailTo: string) {
        let indexBody = mailTo.indexOf("body=");
        if (mailTo[indexBody - 1] === "&") {
            indexBody--;
        }
        return mailTo.substring(0, indexBody);
    }

    preview(email: Email, title: string | string[], showModal: boolean = false) {
        let mailTo = email.mailTo;

        if (showModal || mailTo.length > this.maxMailToSize) {
            mailTo = this.stripBody(mailTo);

            const model: EmailPreviewModel = {
                subject: email.subject,
                body: email.body,
                title: Array.isArray(title) ? title : [title]
            };
            this.dialog.open(EmailPreviewComponent, {
                data: model
            });
        }

        if (!showModal) {
            this.locationChangePending = true;

            // This is synchronous, as is beforeunload
            window.location.href = mailTo;

            this.locationChangePending = false;
        }
    }
}
