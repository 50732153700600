<div class="side-panel" data-test-noon-reports-panel>
    <div class="d-flex flex-column h-100">
        <span class="side-panel-title">
            Noon Reports
            <div class="left-toolbar-expander" (click)="collapseLeftBar()">
                <div class="has-icon icon--close"></div>
            </div>
        </span>

        <div class="row form-group no-gutters">
            <label for="voyageNumberDisplay" class="col-4 col-form-label">TC Voyage Number</label>
            <div class="col-4">
                <ng-select
                    class="ops-select"
                    [items]="voyageNumbers$ | async"
                    [searchable]="false"
                    [clearable]="false"
                    (change)="voyageSelected($event)"
                    bindLabel="voyageNumber"
                    bindValue="voyageId"
                    [ngModel]="selectedVoyageId$ | async"
                    data-test-voyage-number
                    [selectOnTab]="true"
                >
                </ng-select>
            </div>
        </div>

        <div class="loading-panel" *ngIf="(loading$ | async)">
            <ops-load-animation size="small" class="loading-animation"></ops-load-animation>
        </div>

        <div class="scrollable-content" *ngIf="(noonReports$ | async) as noonReports">
            <ng-container *ngFor="let noonReport of noonReports">
                <ops-edit-noon-report *ngIf="isInEditMode(noonReport)" [noonReport]="noonReport"></ops-edit-noon-report>
                <ops-noon-report *ngIf="!isInEditMode(noonReport)" [noonReport]="noonReport"></ops-noon-report>
            </ng-container>

            <ops-placeholder
                *ngIf="!noonReports.length"
                title="No noon reports"
                description="To add a noon report click the Entry button below"
                data-test-no-noon-reports-placeholder
            ></ops-placeholder>
        </div>

        <div class="row no-gutters buttons">
            <div class="col d-flex justify-content-end p-0">
                <button type="button" class="btn btn-primary m-0" (click)="createNoonReport()" data-test-add-voyage>
                    <span class="has-icon icon--add-circle-outline"></span>
                    Entry
                </button>
            </div>
        </div>
    </div>
</div>
