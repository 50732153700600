<ops-berth-form
    [form]="form$ | async"
    [readonly]="readonly"
    [locationTimezone]="locationTimezone$ | async"
    [etbVerifiedAudit]="etbVerifiedAudit$ | async"
    [etbVerifyEnabled]="!(isTransient$ | async)"
    [portTimesEmailAudit]="portTimesEmailAudit$ | async"
    [etbDefaultFocusDate]="defaultEtbFocusDate$ | async"
    (verifyEtb)="verifyEtb()"
    (generatePortTimesEmail)="generatePortTimesEmail()"
>
</ops-berth-form>

<ops-activities
    [activities]="activities$ | async"
    [readonly]="readonly"
    (expand)="expandActivity($event)"
    (collapse)="collapseActivity($event)"
    (moveUp)="moveActivityUp($event)"
    (moveDown)="moveActivityDown($event)"
    (add)="addActivity()"
    (remove)="removeActivity($event)"
></ops-activities>
