import { OnInit, ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { FixturePopupTab } from "./models/fixture-popup-tab";

@Component({
    selector: "ops-fixture-popup-body",
    templateUrl: "./fixture-popup-body.component.html",
    styleUrls: ["./fixture-popup-body.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupBodyComponent implements OnInit {
    private selectionIndex = 0;

    headerStyleClasses$ = new BehaviorSubject<string[]>([]);

    @Input() tabs: ReadonlyArray<FixturePopupTab>;

    ngOnInit(): void {
        this.selectionIndex = Math.max(
            this.tabs.findIndex((t) => t.isActive),
            0
        );
        this.recalculateHeaderStyleClasses();
    }

    onTabChange(event: { index: number }) {
        this.selectionIndex = event.index;
        this.recalculateHeaderStyleClasses();
    }

    private recalculateHeaderStyleClasses() {
        // all activity tabs should be indented and therefore have thinner bottom border
        const newClasses: string[][] = this.tabs.map((tab) => (tab.type === "activity" ? ["tab-indented"] : []));
        let currentDestinationIndex = 0;
        this.tabs.forEach((tab, index) => {
            if (tab.type === "destination") {
                currentDestinationIndex = index;
            }
            // if a destination tab is selected we should highlight the first berth in this destination
            if (tab.type === "destination" && index === this.selectionIndex && index < this.tabs.length - 1) {
                newClasses[index + 1].push("tab-highlighted");
            }
            // if an activity tab is selected we should highlight the destination it belongs to
            if (tab.type === "activity" && index === this.selectionIndex) {
                newClasses[currentDestinationIndex].push("tab-highlighted");
            }
        });
        this.headerStyleClasses$.next(newClasses.map((array) => array.join(" ")));
    }
}
