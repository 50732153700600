import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { CargoFilter } from "../../../state";

@Component({
    selector: "ops-cargoes-filter",
    templateUrl: "./cargoes-filter.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CargoesFilterComponent {
    @Input() cargoes: ReadonlyArray<CargoFilter>;

    trackBy(index: number, item: CargoFilter) {
        return item.cargoId;
    }
}
