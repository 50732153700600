import { Injectable } from "@angular/core";
import { Client, createInstance } from "@optimizely/optimizely-sdk";

import { AppConfigService } from "./app-config.service";
import { AuthService } from "./authentication/auth.service";

const FEATURE_PREFIX = "Ops_";

export type FeatureName = "LaytimeCalculator" | "LaytimeCalculatorV2";

@Injectable()
export class FeatureToggleService {
    private readonly optimizelyClient: Client;

    constructor(private authService: AuthService, private appConfigService: AppConfigService) {
        this.optimizelyClient = createInstance({
            sdkKey: appConfigService.config.optimizelyKey,
            datafileOptions: { autoUpdate: true }
        });
    }

    initialize() {
        return this.optimizelyClient.onReady();
    }

    /**
     * @deprecated Use the new isFeatureEnabled method utilizing Optimizely Rollouts
     */
    isActive(toggleName: string): boolean {
        if (!this.authService.systemUser) {
            console.warn("FeatureToggleService: Call made to isActive when authService.systemUser is not set");
            return false;
        }

        const userIdsStr = this.appConfigService.config[toggleName];
        let isActive = userIdsStr === "0";

        if (!isActive && userIdsStr && userIdsStr.length > 0 && this.authService.systemUser && this.authService.systemUser.systemUserId) {
            const userIds = userIdsStr.split(",");
            userIds.forEach((userId: string) => {
                if (!isActive && userId.trim() === this.authService.systemUser.systemUserId.toString()) {
                    isActive = true;
                }
            });
        }
        return isActive;
    }

    isFeatureEnabled(feature: FeatureName) {
        const attributes = {
            userId: this.authService.systemUser.systemUserId
        };

        return this.optimizelyClient.isFeatureEnabled(`${FEATURE_PREFIX}${feature}`, this.authService.systemUser.systemUserId.toString(), attributes);
    }
}
