import { Command } from "../../../../mediator/commands/command";
import { AssociatedCargoAllowedRatesAggregate } from "../../../../shared/models/form-models/associated-cargo-allowed-rates.model";
import { DestinationId } from "../../../../state/model";
import { UpdateAssociatedCargoesStatementsOfFactsCommandHandler } from "./update-associated-cargoes-statement-of-facts.command-handler";

export class UpdateAssociatedCargoesStatementsOfFactsCommand extends Command {
    constructor(readonly data: AssociatedCargoAllowedRatesAggregate, readonly destinationId: DestinationId) {
        super(UpdateAssociatedCargoesStatementsOfFactsCommandHandler, null);
    }
}
