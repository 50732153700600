import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, withLatestFrom } from "rxjs/operators";

import { CoAHttpService } from "./coa-http.service";
import { FixtureCreateService } from "../../fixture/services/fixture-create.service";
import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { Fixture, FixtureType } from "../../fixture/shared/models";
import { FixtureCreateResponse } from "../../fixture/shared/models/common/fixture-create-response";
import { CoAFixtureSummary } from "../../fixture/shared/models/dtos/coa-fixture-summary.dto";
import { CoAFixtureSummaryModel } from "../../fixture/shared/models/form-models/coa-fixture-summary.model";

@Injectable({
    providedIn: "root"
})
export class CoADataService {
    private coaFixtureSummaries$: BehaviorSubject<CoAFixtureSummaryModel[]>;
    private currentCoaNumber: string;
    private currentFixtureId: string;

    constructor(private coaHttpService: CoAHttpService, private fixtureDataService: FixtureDataService, private fixtureCreateService: FixtureCreateService) {
        this.coaFixtureSummaries$ = new BehaviorSubject<CoAFixtureSummaryModel[]>(null);

        this.fixtureDataService.currentFixture$.pipe(filter((fixture: Fixture) => !!fixture)).subscribe((fixture) => {
            this.updateForFixturePublish(fixture);
        });

        this.fixtureDataService.save$.subscribe((fixture: Fixture) => {
            this.updateForFixtureSave(fixture.fixtureId);
        });

        this.fixtureCreateService.save$.subscribe(({ fixture, fixtureId }: FixtureCreateResponse) => {
            if (fixture.fixtureType.id === FixtureType.Voyage && fixture.coANumber === this.currentCoaNumber) {
                this.updateForFixtureSave(fixtureId);
            }
        });
    }

    get currentCoaFixtureSummaries$(): Observable<CoAFixtureSummaryModel[]> {
        return this.coaFixtureSummaries$;
    }

    private updateForFixturePublish(currentFixture: Fixture) {
        if (!currentFixture.coANumber) {
            this.currentCoaNumber = "";
            this.currentFixtureId = "";
            this.clear();
            return;
        }

        if (currentFixture.coANumber !== this.currentCoaNumber) {
            this.getAndPublishCoAFixtures(currentFixture.coANumber);
            return;
        }

        if (currentFixture.fixtureId !== this.currentFixtureId) {
            this.currentFixtureId = currentFixture.fixtureId;
            const updateFixtureDates = this.updateFixtureDates;
            const mappedData = this.coaFixtureSummaries$.value.map((fixture: CoAFixtureSummaryModel) => ({
                ...fixture,
                selected: fixture.fixtureId === currentFixture.fixtureId,
                ...updateFixtureDates(fixture, currentFixture)
            }));
            this.publishCoaFixtureSummary(mappedData);
        }
    }

    private updateForFixtureSave(fixtureId: string) {
        if (!fixtureId) {
            return;
        }

        if (!this.currentCoaNumber) {
            return;
        }

        this.getAndPublishCoAFixtures(this.currentCoaNumber);
    }

    private clear() {
        this.coaFixtureSummaries$.next(null);
        this.currentCoaNumber = null;
        this.currentFixtureId = null;
    }

    private getAndPublishCoAFixtures(coANumber: string): void {
        this.coaHttpService
            .get(coANumber)
            .pipe(
                withLatestFrom(this.fixtureDataService.currentFixture$),
                filter(([, currentFixture]) => !!currentFixture)
            )
            .subscribe(([data, currentFixture]) => {
                this.currentCoaNumber = coANumber;
                this.currentFixtureId = currentFixture.fixtureId;
                const mappedData = this.mapToCoaFixtureSummaryToModel(data, currentFixture);
                this.publishCoaFixtureSummary(mappedData);
            });
    }

    private mapToCoaFixtureSummaryToModel(coaFixtureSummaries: CoAFixtureSummary[], currentFixture: Fixture): CoAFixtureSummaryModel[] {
        return coaFixtureSummaries.map(
            (f: CoAFixtureSummary) =>
                <CoAFixtureSummaryModel>{
                    fixtureId: f.fixtureId,
                    fixtureNumber: f.fixtureNumber,
                    coAName: f.coaName,
                    coANumber: f.coaNumber,
                    vesselName: f.vesselName,
                    liftingNumber: f.liftingNumber,
                    selected: f.fixtureId === currentFixture.fixtureId,
                    ...this.updateFixtureDates(f, currentFixture)
                }
        );
    }

    private updateFixtureDates(fixture: CoAFixtureSummary | CoAFixtureSummaryModel, currentFixture: Fixture) {
        const fixtureIsSelected = fixture.fixtureId === currentFixture.fixtureId;
        return {
            laycanTo: fixtureIsSelected ? currentFixture.laycan.date.to : fixture.laycanTo,
            laycanFrom: fixtureIsSelected ? currentFixture.laycan.date.from : fixture.laycanFrom,
            liftingDate: fixtureIsSelected ? currentFixture.liftingDate : fixture.liftingDate
        };
    }

    private publishCoaFixtureSummary(coaFixtureSummaryModel: CoAFixtureSummaryModel[]) {
        this.coaFixtureSummaries$.next(coaFixtureSummaryModel);
    }
}
