<input
    type="text"
    class="worksheet-name"
    [class.invalid]="inputState.error"
    (keyup)="processInputKeypress($event)"
    [(ngModel)]="inputState.value"
    #nameInput
    [ngbPopover]="renameValidation"
    #renamePopover="ngbPopover"
    (blur)="onInputBlur()"
    (focus)="onInputFocus()"
    triggers="manual"
    [autoClose]="false"
    placement="bottom-left"
    data-test-edit-worksheet-name
/>
<ng-template #renameValidation>
    <ops-field-validation *ngIf="showValidationMessage" [fieldDisplayName]="inputState.error.fieldDisplayName" [errorMessage]="inputState.error.errorMessage">
    </ops-field-validation>
</ng-template>
<button
    type="button"
    class="has-icon icon--cancel cancel-worksheet-name-edit"
    (click)="cancel.emit({ id: worksheetId })"
></button>