import { ColumnApi } from "@ag-grid-community/all-modules";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";
import { forkJoin, Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";

import {
    cancelNameNewWorksheet,
    ColumnDefinition,
    CreateWorksheet,
    nameNewWorksheet,
    getAllWorksheets,
    saveNewWorksheet,
    selectCurrentWorksheet,
    selectCurrentWorksheetSavable,
    selectGridRenameFormSaveFailed,
    selectGridRenameFormSaving,
    selectGridRenameForm,
    Suggestion,
    updateWorksheet,
    WorksheetRenameForm
} from "@ops/state";

import { GridType } from "../../../fixture/shared/models/enums/grid-type";
import { setDefaultWorksheetAction } from "../functionality/set-worksheets";
import { WorksheetFeatureState } from "../state";
import { toColumnDefinition } from "../utils";

@Injectable()
export class WorksheetService {
    private defaultColumns$ = new Subject<ReadonlyArray<ColumnDefinition>>();
    private defaultSearch$ = new Subject<ReadonlyArray<Suggestion>>();

    readonly currentWorksheet$: Observable<CreateWorksheet>;
    readonly currentWorksheetSavable$: Observable<boolean>;
    readonly gridRenameForm$: Observable<FormGroupState<WorksheetRenameForm>>;
    readonly gridRenameFormSaving$: Observable<boolean>;
    readonly gridRenameFormSaveFailed$: Observable<boolean>;

    constructor(private store: Store<WorksheetFeatureState>) {
        forkJoin([this.defaultColumns$, this.defaultSearch$])
            .pipe(take(1))
            .subscribe(([columns, queries]) => this.store.dispatch(setDefaultWorksheetAction({ columns, queries, sort: null })));
        this.currentWorksheet$ = this.store.select(selectCurrentWorksheet);
        this.currentWorksheetSavable$ = this.store.select(selectCurrentWorksheetSavable);
        this.gridRenameForm$ = this.store.select(selectGridRenameForm);
        this.gridRenameFormSaving$ = this.store.select(selectGridRenameFormSaving);
        this.gridRenameFormSaveFailed$ = this.store.select(selectGridRenameFormSaveFailed);
    }

    getAllWorksheets(gridType: GridType) {
        this.store.dispatch(getAllWorksheets({ gridType }));
    }

    setDefaultGridOptions(columnApi: ColumnApi) {
        const defaultColumnState = columnApi.getColumnState();
        const columns = defaultColumnState.map(toColumnDefinition);
        this.defaultColumns$.next(columns);
        this.defaultColumns$.complete();
    }

    setDefaultSearchQuery(search: ReadonlyArray<Suggestion>) {
        this.defaultSearch$.next(search);
        this.defaultSearch$.complete();
    }

    nameNewWorksheet() {
        this.store.dispatch(nameNewWorksheet());
    }

    cancelNameNewWorksheet() {
        this.store.dispatch(cancelNameNewWorksheet());
    }

    saveNewWorksheet() {
        this.store.dispatch(saveNewWorksheet());
    }

    updateWorksheet() {
        this.store.dispatch(updateWorksheet());
    }
}
