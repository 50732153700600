import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbTimepicker, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

const TIME_PICKER_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimePickerComponent),
    multi: true
};

@Component({
    selector: "ops-timepicker",
    template: " <ngb-timepicker [readonlyInputs]=\"readonly\" [class.hide-spacer]=\"!showDelimiter\" [spinners]=\"spinners\" size=\"medium\"> </ngb-timepicker> ",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TIME_PICKER_VALUE_ACCESSOR]
})
export class TimePickerComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
    private _model: NgbTimeStruct;
    private _disabled: boolean;
    private _disposers: Array<() => void> = [];

    private hourInput: HTMLInputElement;
    private minuteInput: HTMLInputElement;

    @Input() spinners: boolean;
    @Input() fallback: NgbTimeStruct;

    @Input() readonly: boolean;

    @Input()
    get disabled() {
        return this._disabled;
    }
    set disabled(value: any) {
        this.setDisabledState(value === "" || (value && value !== "false"));
    }

    @Output() change = new EventEmitter<NgbTimeStruct>();
    @Output() focus = new EventEmitter();
    @Output() blur = new EventEmitter();

    @ViewChild(NgbTimepicker, { static: true }) timepicker: NgbTimepicker;

    get showDelimiter() {
        return !!this._model;
    }

    get model() {
        const model = this.timepicker?.model;
        if (!model || (Number.isNaN(model.hour) && Number.isNaN(model.minute))) {
            this.writeValue(this.fallback);
            return this.fallback;
        }
        if (Number.isNaN(model.hour)) {
            return { ...model, hour: 0 };
        }
        if (Number.isNaN(model.minute)) {
            return { ...model, minute: 0 };
        }
        return model;
    }

    constructor(private elRef: ElementRef, private renderer: Renderer2, private changeDetector: ChangeDetectorRef) {}

    ngAfterViewInit() {
        const inputs = this.elRef.nativeElement.querySelectorAll("input");

        this.hourInput = inputs[0];
        this.minuteInput = inputs[1];

        this.timepicker.registerOnChange(() => this.manualTimeChange());

        this.addFocusHandlersForInputs();
        this.clearTimepickerPlaceHolders();
        this.addHandlersForKeyboardEvents();
        this.setDisabledState(this._disabled);
    }

    ngOnDestroy() {
        this._disposers.forEach((disposer) => disposer());
        this._disposers.length = 0;
    }

    writeValue(value: NgbTimeStruct) {
        this._model = value;

        this.timepicker.writeValue(value);

        this.changeDetector.markForCheck();
    }

    registerOnChange(fn: (value: any) => any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;

        if (this.timepicker) {
            this.timepicker.setDisabledState(this._disabled);
        }
    }

    focusInput() {
        this.hourInput.focus();
        this.hourInput.select();
    }

    private _onChange = (_: any) => {};
    private _onTouched = () => {};

    private manualTimeChange() {
        this._model = this.model;

        this.change.emit(this._model);
        this._onChange(this._model);
        this._onTouched();
    }

    private addListener(input: HTMLInputElement, event: string, handler: () => void) {
        this._disposers.push(this.renderer.listen(input, event, handler));
    }

    private addHandlersForKeyboardEvents() {
        this._disposers.push(this.handleTwoDigitsForHours());
    }

    private addFocusHandlersForInputs() {
        [this.hourInput, this.minuteInput].forEach((input) => {
            this.addListener(input, "focus", () => this.focus.emit());
            this.addListener(input, "blur", () => this.blur.emit());
        });
    }

    private clearTimepickerPlaceHolders() {
        this.hourInput.placeholder = "";
        this.minuteInput.placeholder = "";
    }

    private handleTwoDigitsForHours() {
        return this.renderer.listen(this.hourInput, "keyup", (event: KeyboardEvent) => {
            const twoDigitRegex = /^[0-9]{2}$/;

            // If we have just typed a digit, and we now have two digits, move to minute input
            if (!isNaN(Number(event.key)) && twoDigitRegex.test(this.hourInput.value)) {
                this.minuteInput.focus();
                this.minuteInput.select();
            }
        });
    }
}
