import { createFormGroupState } from "ngrx-forms";
import * as R from "ramda";
import { v4 as uuid } from "uuid";

import { Currency, Identity, Sector } from "@ops/shared";

import { FixtureId } from "../../../../fixture/state";
import { ActivityLocation } from "./activity-location";
import { LaytimeCalculationCargoTerms } from "./laytime-calculation-cargo-terms";
import { LaytimeCalculationTerms } from "./laytime-calculation-terms";
import { User } from "./user";

export type LtcId = Identity<string, "LtcId">;
export const createLtcId = (): LtcId => uuid() as LtcId;

export const timeAllowances = ["Fixed", "Non Fixed"] as const;
export type TimeAllowance = typeof timeAllowances[number];

export const laytimeCalculationDurationUnits = ["Days", "Hours"] as const;
export type LaytimeCalculationDurationUnit = typeof laytimeCalculationDurationUnits[number];

export const laytimeCalculationRoundings = ["Exact", "Up", "Down"] as const;
export type LaytimeCalculationRounding = typeof laytimeCalculationRoundings[number];

export const timesSaved = ["All Time Saved", "Working Time Saved"] as const;
export type TimeSaved = typeof timesSaved[number];

export type OnceOnDemurrage = "Always On Demurrage" | "Not Always On Demurrage";

export const allowanceUnits = ["MT/Day", "MT/Hour", "Hours"] as const;
export type AllowanceUnit = typeof allowanceUnits[number];

export const activityTypes = ["Load", "Discharge", "Transit"] as const;
export type ActivityType = typeof activityTypes[number];

export const workingDays = ["SHINC", "SHEX", "SHEX UU"] as const;
export type WorkingDay = typeof workingDays[number];

export const reversibleLaytimeTypes = ["Reversible", "Non Reversible"];
export type Reversible = typeof reversibleLaytimeTypes[number];

export const quantityUnits = ["MT"] as const;
export type QuantityUnit = typeof quantityUnits[number];

export const idEq = R.propEq("id");

export type LaytimeCalculation = LaytimeCalculationTerms &
    Readonly<{
        id: LtcId;
        fixtureId: FixtureId;
        lastUpdatedAt: string;
        lastUpdatedBy: User;
        sector: Sector;
        name: string;
        currency: Currency;
        activityLocations: ReadonlyArray<ActivityLocation>;
        cargoTerms: ReadonlyArray<LaytimeCalculationCargoTerms>;
    }>;

export type LaytimeCalculationNameForm = Readonly<{
    name: string;
}>;

export const laytimeCalculationNameFormKey = "laytimeCalculationNameForm";
export const toLaytimeCalculationNameForm = (source: LaytimeCalculation): LaytimeCalculationNameForm => ({
    name: source.name
});
export const createLaytimeCalculationNameFormState = (calculation: LaytimeCalculation) =>
    createFormGroupState(`${calculation.id}.${laytimeCalculationNameFormKey}`, toLaytimeCalculationNameForm(calculation));
