import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

import { FormComponentBase } from "../../../shared/form-component-base";
import { AtSeaBunkerConsumptionId } from "../../../shared/models/dtos/at-sea-bunker-consumption";
import { BunkerConsumed } from "../../../shared/models/dtos/bunker-consumed";
import { BunkerConsumedFormModel } from "../../../shared/models/form-models/bunker-consumed.model";

@Component({
    selector: "ops-at-sea-bunker-consumed",
    templateUrl: "./at-sea-bunker-consumed.component.html",
    styleUrls: ["./at-sea-bunker-consumed.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtSeaBunkerConsumedComponent extends FormComponentBase implements OnDestroy, OnChanges {
    @Input() isSimpleMode: boolean;
    @Input() readonly: boolean;
    @Input() bunkersConsumed: BunkerConsumed[];
    @Input() atSeaBunkerConsumptionId: AtSeaBunkerConsumptionId;
    @Input() parentForm: UntypedFormGroup;

    @Output() bunkerConsumedUpdated = new EventEmitter();

    constructor(private formBuilder: UntypedFormBuilder) {
        super();
    }

    get form() {
        return this.parentForm?.controls.bunkersConsumed as UntypedFormArray;
    }

    get bunkersConsumedArray() {
        return this.form?.controls ?? [];
    }

    ngOnDestroy() {
        this.removeFormSubscriptions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.form) {
            const form = this.formBuilder.array([]);
            form[this.parentForm.disabled ? "disable" : "enable"]();
            this.parentForm.registerControl("bunkersConsumed", form);
        }
        if (changes && changes.bunkersConsumed) {
            this.setForm(changes.bunkersConsumed.currentValue);
        }
    }

    addBunkerConsumed() {
        this.form.push(this.createAtSeaRow(false));
    }

    deleteBunkerConsumed(index: number) {
        this.form.removeAt(index);
    }

    private resizeList(count: number) {
        const formArray = <UntypedFormArray>this.form;

        if (formArray.length === count) {
            return;
        } else if (formArray.length > count) {
            formArray.removeAt(0);
        } else if (formArray.length < count) {
            formArray.push(this.createAtSeaRow(this.parentForm.disabled));
        }

        this.resizeList(count);
    }

    private createAtSeaRow(disabled: boolean): UntypedFormGroup {
        const group = this.formBuilder.group(
            {
                atSeaBunkerConsumptionId: [this.atSeaBunkerConsumptionId],
                bunkerType: [],
                bunkerTypeName: [],
                quantityConsumed: [],
                pricePerMt: [null, Validators.min(0)]
            },
            { updateOn: "blur" }
        );

        if (disabled) {
            group.disable({ emitEvent: false });
        }

        return group;
    }

    private setForm(bunkersConsumed: BunkerConsumed[]) {
        const viewModel = bunkersConsumed.map(
            (bunker) =>
                <BunkerConsumedFormModel>{
                    atSeaBunkerConsumptionId: this.atSeaBunkerConsumptionId,
                    pricePerMt: bunker.pricePerMt ?? null,
                    bunkerType: bunker.type ?? null,
                    bunkerTypeName: bunker.type?.name ?? null,
                    quantityConsumed: bunker.quantityMt ?? null
                }
        );

        this.removeFormSubscriptions();
        this.resizeList(bunkersConsumed.length);
        this.form.setValue(viewModel, { emitEvent: false });
        this.subscribeToFormValueChanges(this.form, (data) => {
            this.bunkerConsumedUpdated.emit(data);
        });
    }
}
