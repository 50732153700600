import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "ops-popup",
    templateUrl: "./popup.component.html",
    styleUrls: ["./popup.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class PopupComponent {
    @Input() title: string[];
    @Input() showHeader = true;

    isLast(index: number) {
        return index === this.title.length - 1;
    }
}
