import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { DateTime } from "luxon";
import { FormGroupState, NgrxValueConverters, unbox } from "ngrx-forms";

import { CustomNgrxValueConverters, getIsRequired } from "@ops/state";

import { VerifiedTimestamp } from "../../../shared/components/timestamp/verified-timestamp.model"; // TODO: (NGRX JC) Move
import { getTimeZone } from "../../shared/models/common/sea-net-location";
import { DestinationForm } from "../../state/model";

@Component({
    selector: "ops-destination-form",
    templateUrl: "./destination-form.component.html",
    styleUrls: ["./destination-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationFormComponent {
    readonly dateValueConverter = NgrxValueConverters.dateToISOString;
    readonly dateRangeValueConverter = CustomNgrxValueConverters.dateRangeToISOString;

    @Input() form: FormGroupState<DestinationForm>;
    @Input() readonly: boolean;
    @Input() isBallastLeg: boolean;
    @Input() isEtaRange: boolean;
    @Input() etaVerifiedAudit: VerifiedTimestamp;
    @Input() etaVerifyEnabled: boolean;
    @Input() showEtaWarning: boolean;
    @Input() etaDefaultFocusDate: DateTime;
    @Input() canDate: DateTime;
    @Input() notesExpanded: boolean;
    @Input() isLocationAndEtaReadonly: boolean;

    @Output() readonly verifyEta = new EventEmitter();
    @Output() readonly expandNotes = new EventEmitter();
    @Output() readonly collapseNotes = new EventEmitter();

    get timeZone() {
        return getTimeZone(this.form && this.form.value.location && unbox(this.form.value.location));
    }

    get locationTitle() {
        return this.isBallastLeg ? "Location - Last port before ballast" : "Location";
    }

    get locationIsRequired(): boolean {
        return this.form && getIsRequired(this.form.controls.location);
    }

    get etaRangeMode(): "range" | "dynamic" {
        return this.isEtaRange ? "range" : "dynamic";
    }

    get etaDate(): string {
        if (this.form) {
            return unbox(this.form.value.etaRange)?.to;
        }
    }

    // TODO: (NGRX JC) DESTINATIONS unit tests
    toggleNotes() {
        if (this.notesExpanded) {
            this.collapseNotes.emit();
        } else {
            this.expandNotes.emit();
        }
    }
}
