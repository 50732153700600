import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";

import { isNullOrUndefined } from "@ops/shared";
import { emptyObjectMap, toObjectMap } from "@ops/state";

import { FixtureId } from "../../../../fixture/state";
import { LaytimeCalculationHttpService } from "../../../services";
import { initialFixtureLaytimeCalculationsState, LaytimeCalculationIndex, LtcState } from "../../model";
import { fixtureCalculationsStateReducer } from "../reducer";

const LOAD_FIXTURE_LAYTIME_CALCULATIONS_ACTION_NAME = "[Laytime Calculation List] Load Fixture Laytime Calculations";
export const loadFixtureLaytimeCalculationsAction = createAction(LOAD_FIXTURE_LAYTIME_CALCULATIONS_ACTION_NAME, props<{ fixtureId: FixtureId }>());
export const loadFixtureLaytimeCalculationsSuccessAction = createAction(
    `${LOAD_FIXTURE_LAYTIME_CALCULATIONS_ACTION_NAME} Success`,
    props<{ fixtureId: FixtureId; calculations: ReadonlyArray<LaytimeCalculationIndex> }>()
);
export const loadFixtureLaytimeCalculationsFailAction = createAction(`${LOAD_FIXTURE_LAYTIME_CALCULATIONS_ACTION_NAME} Fail`, props<{ fixtureId: FixtureId; error: Error }>());

/* REDUCERS */
export const loadFixtureLaytimeCalculationsReducer: On<LtcState> = on(loadFixtureLaytimeCalculationsAction, (state, { fixtureId }) => {
    if (isNullOrUndefined(fixtureId)) {
        return {
            ...state,
            fixtureCalculations: emptyObjectMap(),
            currentFixtureId: null
        };
    } else if (state.currentFixtureId !== fixtureId) {
        return {
            ...state,
            fixtureCalculations: toObjectMap([{ fixtureId }], "fixtureId", () => ({ loadStatus: "loading" })),
            currentFixtureId: fixtureId
        };
    }

    return state;
});

export const loadFixtureLaytimeCalculationsSuccessReducer: On<LtcState> = on(loadFixtureLaytimeCalculationsSuccessAction, (state, { fixtureId, calculations }) =>
    fixtureCalculationsStateReducer(state, fixtureId, { ...initialFixtureLaytimeCalculationsState(calculations) })
);

export const loadFixtureLaytimeCalculationsFailReducer: On<LtcState> = on(loadFixtureLaytimeCalculationsFailAction, (state, { fixtureId }) =>
    fixtureCalculationsStateReducer(state, fixtureId, { loadStatus: "failed" })
);

/* EFFECTS */
export const loadFixtureLaytimeCalculationsEffect$ = (actions$: Actions, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(loadFixtureLaytimeCalculationsAction),
            filter(({ fixtureId }) => !isNullOrUndefined(fixtureId)),
            switchMap(({ fixtureId }) =>
                laytimeCalculationHttpService.searchCalculationsForFixture(fixtureId).pipe(
                    map((calculations) => loadFixtureLaytimeCalculationsSuccessAction({ fixtureId, calculations })),
                    catchError((error) => of(loadFixtureLaytimeCalculationsFailAction({ fixtureId, error })))
                )
            )
        )
    );
