<div [formGroup]="form">
    <div class="form-group row no-gutters">
        <div class="form-group col-5">
            <label for="operators">Operator ({{ operatorCount$ | async }})</label>
            <ops-user-autosuggest [multiple]="true" [lead]="leadOperator$ | async" [fixtureId]="fixture.fixtureId"
                [fixtureSource]="fixture.fixtureSource" formControlName="operators"></ops-user-autosuggest>
        </div>
        <div class="form-group col-3">
            <label for="voyageReference">Voyage Reference</label>
            <input id="voyageReference" class="form-control" formControlName="voyageReference" />
        </div>
        <div class="form-group col-3">
            <label for="liftingNumber">Lifting Number</label>
            <input id="liftingNumber" formControlName="liftingNumber" class="form-control" />
        </div>
    </div>
</div>