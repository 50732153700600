<div class="preview-dialog">
    <ops-popup [showHeader]="false">
        <div class="row no-gutters">
            <div class="form-group col pt-0">
                <div class="ops-text-row">
                    <label for="subject">Subject</label>
                    <div class="copy-btn-container">
                        <ops-action-button
                            type="clone"
                            icon="content-copy"
                            tooltip="Copy to clipboard"
                            tooltipPlacement="left"
                            (btnClick)="copyToClipboard(subject)"
                        ></ops-action-button>
                    </div>
                </div>

                <input class="form-control" readonly="true" type="text" data-test-subject value="Daily updates" />
            </div>
        </div>
        <div class="row no-gutters">
            <div class="form-group col pb-0">
                <div class="ops-text-row">
                    <label for="body">Body</label>
                    <div class="copy-btn-container">
                        <ops-action-button
                            type="clone"
                            icon="content-copy"
                            tooltip="Copy to clipboard"
                            tooltipPlacement="left"
                            (btnClick)="copyToClipboard(voyageUpdate)"
                        ></ops-action-button>
                    </div>
                </div>
                <div class="form-control preview-text" readonly="true" type="text" [innerHtml]="voyageUpdate"></div>
            </div>
        </div>
    </ops-popup>
</div>
