<div 
    class="color-picker-container" 
    (click)="onColorPickerClick($event)"
    [ngClass]="currentColor.colorClass"
    data-test-fixturetype>
    {{favoriteIcon}}
    <div #colorPickerSelector [hidden]="!showColorSelector" class="color-picker-selector" (mouseleave)="onColorPickerMouseLeave()" data-test-colorpicker>
        <div 
            *ngFor="let color of colors" 
            class="color-picker-selector-item"
            [ngClass]="color.colorClass"
            (click)="onColorSelected($event, color.type)"
            data-test-fixturecolor>
        </div>
    </div>
</div>