import { Injectable } from "@angular/core";

import { additionalFreightExpenseToTotal, calculateTotals } from "../../../../state/totals/selectors";
import { Fixture, FixtureType, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class FreightLargeFreightSpendRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    private readonly largeFreightSpend = 5_000_000;

    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();
        if (fixture.fixtureType?.id !== FixtureType.Voyage || fixture.currency?.code !== "USD") {
            return warnings;
        }

        voyages.forEach((voyage) => {
            const totals = calculateTotals(voyage.cargoes, voyage.destinations);
            const additionalFreightExpenses = voyage.additionalFreightExpenses.map((expense) => additionalFreightExpenseToTotal(expense));
            const totalFreightSpend = totals
                .concat(additionalFreightExpenses)
                .map((item) => item.freightSpend || 0)
                .reduce((acc, value) => acc + value, 0);

            if (totalFreightSpend >= this.largeFreightSpend) {
                const message = "The Freight Cost is over 5,000,000 USD";
                const path = [
                    ...this.getVoyagePathSegment(fixture.fixtureType, voyage),
                    new FixtureWarningPathSegment("totals", "Totals"),
                    new FixtureWarningPathSegment("total", "Total"),
                    new FixtureWarningPathSegment("freightSpend", "Freight Spend")
                ];
                const dismissingRecord = dismissedWarningRecord(
                    new WarningHashBuilder().withVoyage(voyage.voyageId).withCategory("freight").withMessage(message).build(),
                    totalFreightSpend
                );
                warnings.push(new FixtureWarning("freight", message, path, dismissingRecord));
            }
        });

        return warnings;
    }
}
