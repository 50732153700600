import { createAction, On, on, props } from "@ngrx/store";

import { UpdateColumns, updateColumnsReducer } from "../../../state/grid.reducer";
import { CoasState } from "../model/state";

/* ACTIONS */
export const updateCoaGridColumnsAction = createAction("[Coa Grid] Update Columns", props<UpdateColumns>());

/* REDUCERS */
export const updateCoaGridColumnsReducer: On<CoasState> = on(updateCoaGridColumnsAction, (state, action) => updateColumnsReducer(state, action, "grid"));
