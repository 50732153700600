import { RouterReducerState } from "@ngrx/router-store";
import * as R from "ramda";

import { OpsRouterStateUrl } from "../router";
import { User } from "./user";

export declare type LoadStatus = "loaded" | "loading" | "failed";
export declare type PersistenceStatus = "persisted" | "persisting" | "invalid" | "failed";

export const loaded = R.always("loaded");
export const loading = R.always("loading");
export const failed = R.always("failed");
export const persisted = R.always<PersistenceStatus>("persisted");
export const persisting = R.always<PersistenceStatus>("persisting");
export const invalid = R.always("invalid");

export declare type StateObjectMap<TObject> = Readonly<{
    allIds: ReadonlyArray<string>;
    byId: {
        readonly [id: string]: TObject;
    };
}>;

export type AuthState = Readonly<{
    isAuthenticated: boolean;
    currentUser?: User;
}>;

export type LeftBarState = Readonly<{
    isCollapsed: boolean;
    currentPanel?: string;
}>;

export type AppState = Readonly<{
    auth: AuthState;
    router?: RouterReducerState<OpsRouterStateUrl>;
    leftBar: LeftBarState;
}>;

export const getCurrentUser = (state: AppState) => state.auth.currentUser;
