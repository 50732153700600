import { LicenseManager } from "@ag-grid-enterprise/all-modules";
import { NgModule } from "@angular/core";

import { SharedModule } from "../shared.module";
import { SimpleRendererComponent } from "./cell-renderers/simple-renderer.component";
import { LoadingOverlayComponent } from "./loading-overlay/loading-overlay.component";

LicenseManager.setLicenseKey(
    "CompanyName=MARITECH DEVELOPMENT LIMITED,LicensedGroup=Sea /Ops,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-021561,ExpiryDate=6_December_2022_[v2]_MTY3MDI4NDgwMDAwMA==7a9f52001deafa3dafa8f0241dbabeb4"
);

@NgModule({
    imports: [SharedModule],
    declarations: [LoadingOverlayComponent, SimpleRendererComponent],
    exports: [LoadingOverlayComponent, SimpleRendererComponent]
})
export class GridsSharedModule {}
