import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { setValue, SetValueAction, updateGroup } from "ngrx-forms";
import { of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { LiftingHttpService } from "../../../../services";

import { selectCurrentCoaId } from "../../../coa";
import { CoaFeatureState, CoaId, CoasState, createLiftingCommentsFormState, getCurrentLiftingState, getLiftingState, LiftingCommentsForm, LiftingId } from "../../../model";
import { currentLiftingStateReducer, liftingStateReducer } from "../../reducer";
import { selectCurrentLiftingId } from "../../selectors";
import { selectCurrentLiftingSummaryCommentsFormValue } from "../selectors";

/* ACTIONS */
const SAVE_COMMENTS_ACTION_NAME = "[Lifting Shell] Save Lifting Summary Comments";
export const saveLiftingSummaryCommentsAction = createAction(SAVE_COMMENTS_ACTION_NAME);
export const saveLiftingSummaryCommentsSuccessAction = createAction(`${SAVE_COMMENTS_ACTION_NAME} Success`, props<{ liftingId: LiftingId }>());
export const saveLiftingSummaryCommentsFailAction = createAction(`${SAVE_COMMENTS_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

const SAVE_COMMENTS_IMPORTANCE_ACTION_NAME = "[Lifting Shell] Save Lifting Summary Comments Importance";
export const saveLiftingSummaryCommentsImportanceAction = createAction(
    SAVE_COMMENTS_IMPORTANCE_ACTION_NAME,
    props<{ coaId: CoaId; liftingId: LiftingId; areCommentsImportant: boolean }>()
);
export const saveLiftingSummaryCommentsImportanceSuccessAction = createAction(`${SAVE_COMMENTS_IMPORTANCE_ACTION_NAME} Success`, props<{ liftingId: LiftingId }>());
export const saveLiftingSummaryCommentsImportanceFailAction = createAction(`${SAVE_COMMENTS_IMPORTANCE_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

/* REDUCERS */
export const saveLiftingSummaryCommentsReducer: On<CoasState> = on(saveLiftingSummaryCommentsAction, (state) =>
    currentLiftingStateReducer(state, { liftingSummaryCommentsFormSaveStatus: "persisting" })
);

export const saveLiftingSummaryCommentsSuccessReducer: On<CoasState> = on(saveLiftingSummaryCommentsSuccessAction, (state, action) => {
    const lifting = getLiftingState(state, action.liftingId);
    const form = lifting?.liftingSummaryCommentsForm;

    if (!form) {
        return state;
    }

    return liftingStateReducer(state, action.liftingId, (liftingState) => ({
        ...liftingState,
        lifting: {
            ...liftingState.lifting,
            summaryComments: form.value.comments,
            areSummaryCommentsImportant: form.value.areCommentsImportant
        },
        liftingSummaryCommentsForm: updateGroup<LiftingCommentsForm>({ isEditing: setValue(<boolean>false) })(liftingState.liftingSummaryCommentsForm),
        liftingSummaryCommentsFormSaveStatus: "persisted"
    }));
});

export const saveLiftingSummaryCommentsFailReducer: On<CoasState> = on(
    saveLiftingSummaryCommentsFailAction,
    saveLiftingSummaryCommentsImportanceFailAction,
    (state, { liftingId }) =>
        liftingStateReducer(state, liftingId, (liftingState) => ({
            ...liftingState,
            liftingSummaryCommentsForm: createLiftingCommentsFormState(liftingState.lifting, liftingState.liftingSummaryCommentsForm.value.isEditing),
            liftingSummaryCommentsFormSaveStatus: "failed"
        }))
);

export const saveLiftingSummaryCommentsImportanceReducer: On<CoasState> = on(saveLiftingSummaryCommentsImportanceAction, (state) => {
    const currentLifting = getCurrentLiftingState(state);
    return !currentLifting.liftingSummaryCommentsForm.value.isEditing ? currentLiftingStateReducer(state, { liftingSummaryCommentsFormSaveStatus: "persisting" }) : state;
});

export const saveLiftingSummaryCommentsImportanceSuccessReducer: On<CoasState> = on(saveLiftingSummaryCommentsImportanceSuccessAction, (state, action) => {
    const lifting = getLiftingState(state, action.liftingId);
    const form = lifting?.liftingSummaryCommentsForm;

    if (!form) {
        return state;
    }

    return liftingStateReducer(state, action.liftingId, (liftingState) => ({
        ...liftingState,
        lifting: {
            ...liftingState.lifting,
            areSummaryCommentsImportant: form.value.areCommentsImportant
        },
        liftingSummaryCommentsFormSaveStatus: "persisted"
    }));
});

/* EFFECTS */
export const saveLiftingSummaryCommentsEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveLiftingSummaryCommentsAction),
            withLatestFrom(store.select(selectCurrentCoaId), store.select(selectCurrentLiftingId), store.select(selectCurrentLiftingSummaryCommentsFormValue)),
            mergeMap(([, coaId, liftingId, { comments, areCommentsImportant }]) =>
                liftingService.updateSummaryComments(coaId, liftingId, { comments, areCommentsImportant }).pipe(
                    map(() => saveLiftingSummaryCommentsSuccessAction({ liftingId })),
                    catchError((err) => of(saveLiftingSummaryCommentsFailAction({ liftingId, error: err })))
                )
            )
        )
    );

export const liftingSummaryCommentsImportanceChangeEffect$ = (actions$: Actions, store: Store<CoaFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<LiftingCommentsForm>>(SetValueAction.TYPE),
            filter((action) => {
                const controlPath = action.controlId.split(".");
                // eslint-disable-next-line no-magic-numbers
                return controlPath.length === 3 && controlPath[1] === "liftingSummaryCommentsForm" && controlPath[2] === "areCommentsImportant";
            }),
            // eslint-disable-next-line no-magic-numbers
            debounceTime(300),
            withLatestFrom(store.select(selectCurrentCoaId), store.select(selectCurrentLiftingId), store.select(selectCurrentLiftingSummaryCommentsFormValue)),
            filter(([, , , { isEditing }]) => !isEditing),
            map(([, coaId, liftingId, { areCommentsImportant }]) => saveLiftingSummaryCommentsImportanceAction({ coaId, liftingId, areCommentsImportant }))
        )
    );

export const saveLiftingSummaryCommentsImportanceEffect$ = (actions$: Actions, liftingService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveLiftingSummaryCommentsImportanceAction),
            mergeMap(({ coaId, liftingId, areCommentsImportant }) =>
                liftingService.updateSummaryCommentsImportance(coaId, liftingId, { areCommentsImportant }).pipe(
                    map(() => saveLiftingSummaryCommentsImportanceSuccessAction({ liftingId })),
                    catchError((err) => of(saveLiftingSummaryCommentsImportanceFailAction({ liftingId, error: err })))
                )
            )
        )
    );
