import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import * as R from "ramda";
import { Evolver, F } from "ramda";
import { of } from "rxjs";
import { catchError, exhaustMap, map, withLatestFrom } from "rxjs/operators";

import { selectCurrentUser } from "@ops/state";

import { LiftingHttpService } from "../../services/lifting-http.service";
import { CoaFeatureState, CoasState, LiftingId, setSendingForApproval, toUser, User } from "../model";
import { currentLiftingStateReducer, liftingStateReducer } from "./reducer";
import { selectCurrentLifting } from "./selectors";

/* ACTIONS */
const ACTION_NAME = "[Lifting Progression] Create Fixture";

export const createFixtureAction = createAction(ACTION_NAME);
export const createFixtureSuccessAction = createAction(`${ACTION_NAME} Success`, props<{ liftingId: LiftingId; user: User }>());
export const createFixtureFailAction = createAction(`${ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

/* REDUCERS */
export const createFixtureReducer: On<CoasState> = on(createFixtureAction, (state) => currentLiftingStateReducer(state, { fixtureCreationPending: true }));

export const createFixtureSuccessReducer: On<CoasState> = on(createFixtureSuccessAction, (state, { liftingId }) => {
    // It's difficult to make a sensible optimistic state change for this, because creating a third party fixture would not require an external approval process.
    // When we have SignalR we should probably just remove this reducer completely and just let the state change happen as part of that update.
    const updateFns: Evolver = {
        fixtureCreationPending: F,
        lifting: setSendingForApproval
    };
    return liftingStateReducer(state, liftingId, R.evolve(updateFns));
});

export const createFixtureFailReducer: On<CoasState> = on(createFixtureFailAction, (state, { liftingId }) =>
    liftingStateReducer(state, liftingId, { fixtureCreationPending: false })
);

/* EFFECTS */
export const createFixtureEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(createFixtureAction),
            withLatestFrom(store.select(selectCurrentLifting), store.select(selectCurrentUser)),
            exhaustMap(([, { coaId, liftingId, declarationDate }, appUser]) =>
                liftingHttpService.fixLifting(coaId, liftingId, declarationDate).pipe(
                    map(() => createFixtureSuccessAction({ liftingId, user: toUser(appUser) })),
                    catchError((error) => of(createFixtureFailAction({ liftingId, error })))
                )
            )
        )
    );
