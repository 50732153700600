import { On } from "@ngrx/store";
import { isNullOrUndefined } from "@ops/shared";

import { box, formGroupReducer, SetValueAction, unbox } from "ngrx-forms";

import { hasValue } from "@ops/state";

import {
    CargoId,
    FixturesState,
    VoyageState
} from "../../model";
import { voyageStateReducer } from "../../voyage/reducer";

/**
 * Prefills an associated cargo quantity unit and freight rate from cargo on cargo id change.
 */
export const prefillAssociatedCargoReducer: On<FixturesState> = {
    types: [SetValueAction.TYPE],
    reducer: (state: FixturesState, action: SetValueAction<CargoId>): FixturesState => {
        const controlPath = action.controlId.split(".");
        if (controlPath.length < 2 || controlPath[controlPath.length - 3] !== "associatedCargoes" || controlPath[controlPath.length - 1] !== "cargoId") {
            return state;
        }

        return voyageStateReducer(state, controlPath[0], (voyageState, fixtureState) => {
            const berthIndex = Number(controlPath[4]);
            const activityIndex = Number(controlPath[6]);
            const associatedCargoIndex = Number(controlPath[8]);

            const voyageForm = voyageState.form.value;
            const destinationForm = voyageForm.destinations[Number(controlPath[2])];
            const berthForm = destinationForm.berths[berthIndex];
            const activityForm = berthForm.activities[activityIndex];
            const associatedCargoForm = activityForm.associatedCargoes[associatedCargoIndex];

            const cargoId = associatedCargoForm.cargoId;
            const newCargoId = action.value;
            const hasCargoId = hasValue(cargoId);
            const hasNewCargoId = hasValue(newCargoId);

            if (hasCargoId === hasNewCargoId && cargoId === newCargoId) {
                return voyageState;
            }

            const cargoForm = voyageForm.cargoes.find(cargo => cargo.cargoId === newCargoId);

            if (!cargoForm) {
                return voyageState;
            }

            const cargoQuantityUnit = unbox(cargoForm.quantityUnit);
            const baseFreightRate = cargoForm.baseFreightRate;
            const freightRate = associatedCargoForm.freightRate;
            const hasBaseFreightRate = !isNullOrUndefined(baseFreightRate);
            const hasFreightRate = !isNullOrUndefined(freightRate);

            if (!cargoQuantityUnit && (!hasBaseFreightRate || hasFreightRate)) {
                return voyageState;
            }

            let form = voyageState.form;

            if (cargoQuantityUnit) {
                controlPath[controlPath.length - 1] = "quantityUnit";

                const quantityUnitControlPath = controlPath.join(".");

                form = formGroupReducer(form, new SetValueAction(quantityUnitControlPath, box(cargoQuantityUnit)));
            }

            if (!hasFreightRate) {
                controlPath[controlPath.length - 1] = "freightRate";

                const freightRateControlPath = controlPath.join(".");

                form = formGroupReducer(form, new SetValueAction(freightRateControlPath, baseFreightRate));
            }

            return <VoyageState>{
                ...voyageState,
                form: form
            };
        });
    }
};
