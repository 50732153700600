import { Action, createReducer } from "@ngrx/store";

import { openFixturePopupLockSuccessReducer, openFixturePopupSuccessReducer } from "./open-fixture";
import { saveFixturePopupFailReducer, saveFixturePopupReducer, saveFixturePopupSuccessReducer } from "./save-fixture";
import { FixturePopupState, initialState } from "./state";
import { unlockFixturePopupReducer, unlockFixturePopupSuccessReducer } from "./unlock-fixture";

const reducer = createReducer(
    initialState,
    openFixturePopupSuccessReducer,
    openFixturePopupLockSuccessReducer,
    saveFixturePopupReducer,
    saveFixturePopupSuccessReducer,
    saveFixturePopupFailReducer,
    unlockFixturePopupReducer,
    unlockFixturePopupSuccessReducer
);

export const fixturePopupReducer = (state: FixturePopupState, action: Action) => reducer(state, action);
