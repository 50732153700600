import { createAction, on, On, props } from "@ngrx/store";
import { DateTime } from "luxon";
import { updateGroup } from "ngrx-forms";

import { sortArrayControls } from "@ops/state";

import { updateActivityForm } from "../../activities/shared";
import { ActivityId, BerthId, DestinationId, FixturesState, LaytimeEventForm } from "../../model";
import { compareValues } from "../../utils";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const orderLaytimeEventsAction = createAction("[Voyage Form] Order Laytime Events", props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId }>());

/* REDUCER */
export const orderLaytimeEventsReducer: On<FixturesState> = on(orderLaytimeEventsAction, (state, action) =>
    currentVoyageStateReducer(state, (voyageState) => ({
        ...voyageState,
        form: updateActivityForm(action, (activityForm) =>
            updateGroup(activityForm, {
                laytimeEvents: sortArrayControls<LaytimeEventForm>((a, b) => orderLaytimeEventsSortFunc(a.value, b.value))
            })
        )(voyageState.form)
    }))
);

/* FUNCTIONS */
export const orderLaytimeEventsSortFunc = (a: LaytimeEventForm, b: LaytimeEventForm) =>
    compareValues(a.eventDate && DateTime.fromISO(a.eventDate), b.eventDate && DateTime.fromISO(b.eventDate));
