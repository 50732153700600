import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { routerReducer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";

import { LeftBarEffects } from "./state/left-bar.effects";
import { leftBarReducer } from "./state/left-bar.reducer";
import { authReducer } from "./state/reducer";
import { OpsRouterStateSerializer } from "./state/router";

@NgModule({
    imports: [
        StoreModule.forRoot({
            auth: authReducer,
            router: routerReducer,
            leftBar: leftBarReducer
        }),
        StoreRouterConnectingModule.forRoot({
            serializer: OpsRouterStateSerializer
        }),
        EffectsModule.forRoot([LeftBarEffects])
    ]
})
export class AppStoreModule {}
