import {
    routerLoadLaytimeCalculationReducer,
    routerLoadLaytimeCalculationSuccessReducer,
    routerLoadLaytimeCalculationFailReducer,
    routerLoadFixtureSuccessReducer,
    routerLoadFixtureFailReducer,
    routerLoadLaytimeCalculationActivityLocationReducer
} from "./routing";
import { queueUpdateFailReducer, queueUpdateReducer, queueUpdateSuccessReducer } from "./update-queue";

export default [
    routerLoadLaytimeCalculationReducer,
    routerLoadLaytimeCalculationSuccessReducer,
    routerLoadLaytimeCalculationFailReducer,
    routerLoadFixtureSuccessReducer,
    routerLoadFixtureFailReducer,
    routerLoadFixtureFailReducer,
    queueUpdateReducer,
    queueUpdateSuccessReducer,
    queueUpdateFailReducer,
    routerLoadLaytimeCalculationActivityLocationReducer
];
