import { v4 as uuid } from "uuid";

import { LoadStatus } from "./state";
import { ColumnDefinition } from "./worksheet";
import { GridRecordNumbers } from "../../shared/grid";

export const toSearchToken = (token: string | null, value: string): SearchToken => (token ? `${token}: ${value}` : value);
export const toQueriesParam = (searchToken: SearchToken): string => `queries=${encodeURIComponent(searchToken)}`;

export type SearchToken = string;

export type SuggestionTerm = string;
export type Suggestion = string;

export type Sorting = Readonly<{ column: string; order: string }>;

export const createRequestId = () => "request::" + uuid();

export type GridPageState<T> = Readonly<{
    loadStatus: LoadStatus;
    skip: number;
    take: number;
    total?: number;
    items?: ReadonlyArray<T>;
    error?: Error | string;
}>;

export type GridState<T = unknown> = Readonly<{
    suggestionTerm: SuggestionTerm;
    suggestions: {
        loadStatus: LoadStatus;
        items?: ReadonlyArray<Suggestion>;
        error?: Error | string;
    };

    criteria: ReadonlyArray<SearchToken>;
    sorting: Sorting;
    columns: ReadonlyArray<ColumnDefinition>;
    currentRequestId: string;
    data: { [requestId: string]: GridPageState<T> };
}>;

export type SearchCriteria = Pick<GridState, "criteria" | "sorting">;
export type GridPage<T> = Pick<GridPageState<T>, "items" | "error" | "loadStatus">;

export const getGridRecordNumbers = (gridState: GridState): GridRecordNumbers => {
    const pageState = gridState.currentRequestId && gridState.data[gridState.currentRequestId];
    return (
        pageState?.loadStatus === "loaded" && {
            total: pageState.total,
            loaded: pageState.skip + pageState.items.length
        }
    );
};

export const isFinalStatus = (status: LoadStatus) => status === "loaded" || status === "failed";
