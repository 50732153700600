import { createFormGroupState, FormGroupState } from "ngrx-forms";

import { LoadStatus, PersistenceStatus } from "@ops/state";

import { LiftingHistoryFilterOptions } from "../../lifting/lifting-history";
import { CompanyForm } from "../company";
import { CoasState } from "../state";
import { LiftingAction } from "./action";
import { LiftingCargoForm, LiftingCargoLaycanForm, liftingToCargoLaycanForm } from "./cargo";
import { createLiftingCommentsFormState, LiftingCommentsForm } from "./comments";
import { CargoHistoricalEvent, VesselHistoricalEvent } from "./historical-event";
import { Lifting, LiftingId } from "./lifting";
import { LiftingHeaderForm } from "./lifting-header";
import { VesselNominationForm } from "./vessel-nomination";

export declare type LiftingState = Readonly<{
    lifting?: Lifting;
    loadStatus?: LoadStatus;
    persistenceStatus?: PersistenceStatus;
    liftingHeaderForm?: FormGroupState<LiftingHeaderForm>;
    liftingCargoLaycanForm?: FormGroupState<LiftingCargoLaycanForm>;
    liftingCargoLaycanFormSaveStatus?: PersistenceStatus;
    liftingCargoForm?: FormGroupState<LiftingCargoForm>;
    liftingCargoFormSaveStatus?: PersistenceStatus;
    vesselNominationForm?: FormGroupState<VesselNominationForm>;
    vesselNominationFormSaveStatus?: PersistenceStatus;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    erroredItems?: ReadonlyArray<{ itemType: keyof Lifting; id: any; error: Error }>;
    vesselNominationStatusSaveStatus?: PersistenceStatus;
    fixtureCreationPending?: boolean;
    liftingHistoryFilterOptions: LiftingHistoryFilterOptions;
    vesselNominationHistory?: ReadonlyArray<VesselHistoricalEvent>;
    vesselNominationHistoryLoadStatus?: LoadStatus;
    cargoNominationHistory?: ReadonlyArray<CargoHistoricalEvent>;
    cargoNominationHistoryLoadStatus?: LoadStatus;
    liftingSummaryCommentsForm?: FormGroupState<LiftingCommentsForm>;
    liftingSummaryCommentsFormSaveStatus?: PersistenceStatus;
    liftingCancellationPending?: boolean;
    actions?: ReadonlyArray<LiftingAction>;
    actionsLoadStatus?: LoadStatus;
    companyForm?: FormGroupState<CompanyForm>;
}>;

export const getCurrentLiftingId = (state: CoasState) => state.currentLiftingId;
export const getLiftingState = (state: CoasState, liftingId: LiftingId) => state.liftings.byId[liftingId];
export const getCurrentLiftingState = (state: CoasState) => state.liftings.byId[getCurrentLiftingId(state)];
export const initialLiftingState = (lifting: Lifting): LiftingState => ({
    lifting,
    loadStatus: "loaded",
    liftingHistoryFilterOptions: {
        all: true,
        laycan: true,
        cargoNomination: true,
        vesselNomination: true
    },
    liftingCargoLaycanForm: createFormGroupState(lifting.liftingId + ".liftingCargoLaycanForm", liftingToCargoLaycanForm(lifting)),
    liftingSummaryCommentsForm: createLiftingCommentsFormState(lifting)
});
