import { DateTime, Duration, Interval } from "luxon";

/**
 * Takes a From and To date and checks for overlapping intersections with the defined Window period, specified
 * in days and hours. Returns the total intersected duration with hours component.
 *
 * @param start The start of the range
 * @param end The end of the range
 * @param windowFromWeekday The starting weekday of the window (0 = Sunday, 1 = Monday etc.)
 * @param windowToWeekday The ending weekday of the window
 * @param windowStartTimeISO The start time of the window
 * @param windowEndTimeISO The end time of the window
 * @param timezone The time zone to calculate the duration within
 */
export const durationInWindow = (
    start: DateTime,
    end: DateTime,
    windowFromWeekday: number,
    windowToWeekday: number,
    windowStartTimeISO: string,
    windowEndTimeISO: string,
    timezone?: string
): Duration => {
    const windowFromTimeStruct = DateTime.fromISO(windowStartTimeISO, { zone: timezone });
    const windowToTimeStruct = DateTime.fromISO(windowEndTimeISO, { zone: timezone });

    let windowStart = start.set({
        weekday: windowFromWeekday,
        hour: windowFromTimeStruct.hour,
        minute: windowFromTimeStruct.minute,
        second: windowFromTimeStruct.second
    });

    let windowEnd = windowStart.endOf("day");
    if (windowToWeekday < windowFromWeekday) {
        windowEnd = windowEnd.endOf("week").plus({ second: 1 }).startOf("week");
    }

    windowEnd = windowEnd.set({
        weekday: windowToWeekday,
        hour: windowToTimeStruct.hour,
        minute: windowToTimeStruct.minute,
        second: windowToTimeStruct.second
    });

    const range = Interval.fromDateTimes(start, end);
    let window = Interval.fromDateTimes(windowStart, windowEnd);
    let duration = Duration.fromObject({});

    // Shift the window towards to the next available week until the window start date exceeds the available range
    while (window.overlaps(range)) {
        const intersection = range.intersection(window);
        duration = duration.plus(intersection.toDuration("hours"));

        windowStart = windowStart.plus({ week: 1 });
        windowEnd = windowStart.plus({ week: 1 });
        window = Interval.fromDateTimes(windowStart, windowEnd);
    }

    return duration;
};
