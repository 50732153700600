<div [formGroup]="parentForm" class="cargo-rates">
    <table class="table">
        <thead>
            <tr>
                <th>Cargo</th>
                <th>Activity</th>
                <th>B/L/Discharge Quantity(MT)</th>
                <th>Actual L/D Rate</th>
                <th>Laytime Type</th>
                <th>CP Load/Discharge Rate</th>
                <th>Unit</th>
                <th>Extra Hours</th>
                <th *ngIf="isMultipleCargo">Time Used</th>
                <th>Time Allowed</th>
                <th *opsDivisions="Division.dryCargo">Customary Quick Despatch</th>
            </tr>
        </thead>
        <tbody [formArrayName]="formName">
            <tr *ngFor="let row of formArray.controls; let i = index" [formGroupName]="i" class="form-group">
                <td>
                    <input type="hidden" formControlName="berthId" />
                    <input type="hidden" formControlName="activityId" />
                    <input type="hidden" formControlName="associatedCargoId" />
                    <span [ngbTooltip]="row.value.cargoProductName" >
                        <input type="text" class="form-control text-right input-sm" formControlName="cargoProductName" data-test-cargo-product-name readonly />
                    </span>
                </td>
                <td>
                    <input type="text" class="form-control text-right input-sm" formControlName="activity" data-test-cargo-product-name readonly />
                </td>
                <td>
                    <input
                        type="text"
                        class="form-control text-right input-sm"
                        [opsNumber]="{ precision: 3, minValue: 0, showTrailingZeros: false }"
                        formControlName="blQuantity"
                        data-test-cargo-product-name
                        readonly
                    />
                </td>
                <td>
                    <input
                        type="text"
                        class="form-control text-right input-sm"
                        formControlName="actualLoadAndDischargeRate"
                        data-test-actual-load-and-discharge-rates
                        readonly
                    />
                </td>
                <td>
                    <ng-select
                        class="ops-select input-sm form-control"
                        [items]="reversibleLaytimeType$ | async"
                        placeholder="Select"
                        formControlName="reversibleLaytimeType"
                        [clearable]="true"
                        bindLabel="name"
                        data-test-reversible-laytime-type
                    ></ng-select>
                </td>
                <td>
                    <input
                        type="text"
                        [opsNumber]="{ precision: 3, minValue: 0, showTrailingZeros: false }"
                        class="form-control text-right input-sm"
                        formControlName="cpRate"
                        data-test-cp-load-rate
                    />
                </td>
                <td>
                    <ng-select
                        class="ops-select input-sm form-control"
                        [items]="cpRateUnits$ | async"
                        placeholder="Select"
                        formControlName="cpRateUnit"
                        [clearable]="true"
                        bindLabel="name"
                        data-test-cp-load-rate-unit
                    ></ng-select>
                </td>
                <td>
                    <input
                        type="text"
                        [opsNumber]="{ precision: 3, minValue: 0, showTrailingZeros: false }"
                        class="form-control text-right input-sm"
                        formControlName="extraHours"
                        data-test-load-extra-hours
                    />
                </td>
                <td *ngIf="isMultipleCargo">
                    <span type="text" class="form-control text-right input-sm" data-test-time-used>
                        {{ row.get('cargoTimeUsed').value | durationFormat:displayUnitType }}
                    </span>
                </td>
                <td>
                    <span type="text" class="form-control text-right input-sm" data-test-time-allowed>
                        {{ row.get('timeAllowed').value | durationFormat:displayUnitType }}
                    </span>
                </td>
                <td *opsDivisions="Division.dryCargo">
                    <input
                        class="customary-quick-despatch"
                        id="customaryQuickDespatch"
                        type="checkbox"
                        name="customaryQuickDespatch"
                        formControlName="customaryQuickDespatch"
                        data-test-customaryQuickDespatch
                    />
                </td>
            </tr>
        </tbody>
    </table>
</div>
