import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { LtcAuthGuard } from "../ltc/authentication";
import { LaytimeCalculationListShellComponent } from "../ltc/components";
import { ActionsComponent } from "./actions/actions.component";
import { CoaContactsShellComponent } from "./coa-contacts/coa-contacts-shell.component";
import { LiftingContactsShellComponent } from "./coa-contacts/lifting-contacts/lifting-contacts-shell.component";
import { CoaComponent } from "./coa/coa.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { CreateFixtureComponent } from "./create-fixture/create-fixture.component";
import { DocumentsComponent } from "./documents/documents.component";
import { FavoritesComponent } from "./favorites/favorites.component";
import { LiftingHistoryShellComponent } from "./lifting-history/lifting-history-shell.component";
import { NoonReportsComponent } from "./noon-reports/noon-reports.component";
import { TemperatureLogComponent } from "./temperature-log/temperature-log.component";
import { TcVoyagesComponent } from "./voyages/tc-voyages.component";
import { FixtureWarningsComponent } from "./warnings/fixture-warnings.component";
import { WorksheetsVersionSwitcherComponent } from "./worksheets/worksheets-version-switcher.component";

const routes: Routes = [
    {
        path: "favorites",
        component: FavoritesComponent,
        outlet: "toolbar"
    },
    {
        path: "coa",
        component: CoaComponent,
        outlet: "toolbar"
    },
    {
        path: "coa-contacts",
        component: CoaContactsShellComponent,
        outlet: "toolbar"
    },
    {
        path: "lifting-contacts",
        component: LiftingContactsShellComponent,
        outlet: "toolbar"
    },
    {
        path: "lifting-history",
        component: LiftingHistoryShellComponent,
        outlet: "toolbar"
    },
    {
        path: "contacts",
        component: ContactsComponent,
        outlet: "toolbar"
    },
    {
        path: "tc-voyages",
        component: TcVoyagesComponent,
        outlet: "toolbar"
    },
    {
        path: "tc-voyages/:voyageId",
        component: TcVoyagesComponent,
        outlet: "toolbar"
    },
    {
        path: "actions",
        component: ActionsComponent,
        outlet: "toolbar"
    },
    {
        path: "actions/:actionId",
        component: ActionsComponent,
        outlet: "toolbar"
    },
    {
        path: "create-fixture",
        component: CreateFixtureComponent,
        outlet: "toolbar",
        children: [
            {
                path: "coa",
                component: CreateFixtureComponent
            }
        ]
    },
    {
        path: "documents",
        component: DocumentsComponent,
        outlet: "toolbar"
    },
    {
        path: "warnings",
        component: FixtureWarningsComponent,
        outlet: "toolbar"
    },
    {
        path: "tc-noon-reports",
        component: NoonReportsComponent,
        outlet: "toolbar"
    },
    {
        path: "worksheets",
        component: WorksheetsVersionSwitcherComponent,
        outlet: "toolbar"
    },
    {
        path: "temperature-log",
        component: TemperatureLogComponent,
        outlet: "toolbar"
    },
    {
        path: "ltc",
        component: LaytimeCalculationListShellComponent,
        canActivate: [LtcAuthGuard],
        outlet: "toolbar"
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LeftBarRoutingModule {}
