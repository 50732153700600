import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule, Optional, SkipSelf } from "@angular/core";

import { AppInsightsService } from "./app-insights.service";
import { AppStateInterceptor } from "./app-state/app-state.interceptor";
import { AppStateService } from "./app-state/app-state.service";
import { AuthGuard } from "./authentication/auth.guard";
import { AuthInterceptor } from "./authentication/auth.interceptor";
import { AuthService } from "./authentication/auth.service";
import { FeatureToggleService } from "./feature-toggle.service";
import { LoggerService } from "./logger.service";
import { MixpanelService } from "./mixpanel-service";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { WindowRefService } from "./window-ref.service";

@NgModule({
    imports: [CommonModule, HttpClientModule],
    providers: [
        LoggerService,
        ErrorHandler,
        WindowRefService,
        AuthService,
        AppStateService,
        FeatureToggleService,
        AppInsightsService,
        MixpanelService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        AppStateService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppStateInterceptor,
            multi: true,
            deps: [WindowRefService, AppStateService]
        }
    ],
    declarations: [PageNotFoundComponent],
    exports: [PageNotFoundComponent, HttpClientModule]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule, appStateService: AppStateService) {
        throwIfAlreadyLoaded(parentModule, "CoreModule");

        appStateService.initializeETag();
    }
}
