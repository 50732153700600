import { Component, Input } from "@angular/core";

@Component({
    selector: "ops-no-data",
    templateUrl: "./no-data.component.html",
    styleUrls: ["./no-data.component.scss"]
})
export class NoDataComponent {
    @Input() title: string;
    @Input() description: string;
}
