<div class="row" *ngIf="fixture" data-test-summary>
    <div class="col-auto vessel">
        <a *ngIf="fixture.vessel" href="{{ seaNetLink$ | async }}" target="_blank">
            <span class="vessel-name">{{ fixture.vessel && fixture.vessel.name }}</span>
        </a>
        <span *ngIf="!fixture.vessel" class="vessel-name">TBN</span>
    </div>
    <div class="col recap">
        <div class="row">
            <div class="col">
                <div *ngIf="fixture.fixtureSource.id === FixtureSource.Gain; then gainTemplate; else otherSourceTemplate"></div>
                <ng-template #gainTemplate>
                    <a href="{{ gainFixtureUrl }}{{ fixture.gainFixtureId }}" target="_blank">{{ fixture.fixtureNumber }}</a>
                </ng-template>
                <ng-template #otherSourceTemplate>
                    {{ fixture.fixtureNumber }}
                </ng-template>
                <span>&nbsp;|&nbsp;{{ fixture.charterParty.charterPartyDate | timezoneDate: "DATE":"utc":false }}&nbsp;|</span>
                <span>&nbsp;{{ fixture.currency.code }}&nbsp;</span>
                <span *ngIf="brokers">|&nbsp;{{ brokers }}&nbsp;</span>
                <span *ngIf="blDate">|&nbsp;B/L Date: {{ blDate | timezoneDate: "DATE":"utc":false }}&nbsp;</span>
                <span *ngIf="fixture.coANumber" data-test-coanumber>|&nbsp;{{ fixture.coANumber }}</span>
                <span class="duration-type-header"></span>
                <span *ngIf="fixture.timeCharterDurationType" class="duration-type">{{ fixture.timeCharterDurationType?.name.toUpperCase() }} </span>
            </div>
        </div>
        <div class="row">
            <div class="col">Owner: {{ owner$ | async }} | Charterer: {{ charterer$ | async }}</div>
        </div>
        <div class="row">
            <div class="col updated">
                Last updated by: {{ fixture.modifiedByUser?.fullName }} {{ fixture.modifiedByDateTime | timezoneDate: "dd MMM yy, HH:mm:ss" }}
            </div>
        </div>
    </div>
</div>
