import { MaritimeDate } from "@maritech/maritime-date";
import { DateTime } from "luxon";
import { chain } from "ramda";

import { Company } from "../../../left-bar/contacts/models/company.model";
import { formatDateRange } from "../../../shared/date-utils/date-utilities";
import { Email } from "../../../shared/email";
import { formatNumber } from "../../../shared/number-format";
import { CargoBerthActivityType, Division, FixtureType, LaytimeEventType, QuantityUnit, ToleranceUnit } from "../../../shared/reference-data";
import { isNullOrUndefined } from "../../../shared/utils";
import { Cargo, CargoBerthActivity, Destination, Fixture, Voyage } from "../models";
import { AbstractEmailGenerator } from "./email-generator";

export class EtaEmailGenerator extends AbstractEmailGenerator {
    generate(fixture: Fixture, voyages: readonly Voyage[], companies: readonly Company[]) {
        if (fixture.fixtureType.id === FixtureType.TimeCharter.id) {
            throw Error("FixtureType=TimeCharter not supported");
        }

        const voyage = voyages[0];

        const subject = this.generateSubject(fixture, voyage, companies);
        const body = this.generateBody(fixture, voyage, companies);

        return new Email(subject, body, null, null);
    }

    private generateBody(fixture: Fixture, voyage: Voyage, companies: readonly Company[]) {
        let body = this.generateBodySubject(fixture, voyage, companies) + "\r\n\r\n";

        for (const cargo of voyage.cargoes) {
            const quantity = this.getCargoQuantity(voyage, cargo);
            const locations = this.getAssociatedLocations(voyage, cargo);

            body += `${cargo.orderId ? cargo.orderId + " : " : ""}${quantity} ${cargo.cargoProduct ? cargo.cargoProduct.name : ""}`;

            if (locations) {
                body += ` - ${locations}`;
            }

            body += "\r\n";
        }

        body += "\r\n";
        body += `Laycan ${this.getFormattedLaycan(fixture)}\r\n`;
        body += "--------------------------------------------------------------------------------------\r\n";

        const { hasDestination, hasEtb, etaEtbs } = this.getEtaEtbs(fixture, voyage);
        if (hasDestination) {
            body += `Current ETA ${hasEtb ? "and ETB " : ""}Update is looking as follows:\r\n`;
            body += etaEtbs;
        }

        return body;
    }

    private getFormattedLaycan(fixture: Fixture) {
        if (fixture.laycan && !!fixture.laycan.extensionDate && !!fixture.laycan.extensionDate.from && !!fixture.laycan.extensionDate.to) {
            return this.formatEmailDateRange(fixture.laycan.extensionDate, this.DATE_TIME_FORMAT, "utc");
        }

        if (fixture.laycan && fixture.laycan.date) {
            return this.formatEmailDateRange(fixture.laycan.date, this.DATE_TIME_FORMAT, "utc");
        }

        return "";
    }

    private getCargoQuantity(voyage: Voyage, cargo: Cargo) {
        const bl = this.getTotalBL(voyage, cargo);

        if (!isNullOrUndefined(bl)) {
            const blFormatted = this.formatNumber(bl);

            return `${blFormatted} ${cargo.quantityUnit.name}`;
        }

        if (cargo.quantity) {
            const quantityFormatted = this.formatNumber(cargo.quantity);

            if (!isNullOrUndefined(cargo.tolerance?.min) && !isNullOrUndefined(cargo.tolerance?.max)) {
                const unit = cargo.tolerance.unit;
                let { min, max } = cargo.tolerance;

                if ((cargo.quantityUnit.id === QuantityUnit.MT.id || cargo.quantityUnit.id === QuantityUnit.LumpSum.id) && unit) {
                    if (unit.id === ToleranceUnit.Percentage.id) {
                        min = cargo.quantity * (min / 100);
                        max = cargo.quantity * (max / 100);
                    } else if (cargo.quantityUnit.id === QuantityUnit.MT && unit.id !== ToleranceUnit.MT.id) {
                        throw Error(`Invalid tolerance unit '${unit.name}' for quantity unit '${cargo.quantityUnit.name}'`);
                    }

                    const minFormatted = this.formatNumber(-min);
                    const maxFormatted = this.formatNumber(max);

                    return `${quantityFormatted} ${cargo.quantityUnit.name} (${minFormatted}/+${maxFormatted})`;
                }
            }

            return `${quantityFormatted} ${cargo.quantityUnit ? cargo.quantityUnit.name : null}`;
        }

        return "";
    }

    private getAssociatedLocations(voyage: Voyage, cargo: Cargo) {
        const locations = new Array<string>();

        for (const destination of voyage.destinations.filter((d) => d.location)) {
            for (const berth of destination.berths) {
                for (const activity of berth.cargoBerthActivities) {
                    if (activity.associatedCargoes.some((associatedCargo) => cargo.id === associatedCargo.cargoId)) {
                        locations.push(destination.location ? destination.location.displayName : "");
                    }
                }
            }
        }

        return locations.join(" - ");
    }

    private getEtaEtbs(fixture: Fixture, voyage: Voyage) {
        let etaEtbs = "";
        let hasDestination = false;
        let hasEtb = false;

        for (const destination of voyage.destinations.filter((d) => !this.shouldExcludeEtaEtb(d))) {
            const locationName = destination.location ? " " + destination.location.displayName : "";
            const timeZone = (destination.location ? destination.location.timeZone : null) || "utc";

            let etaFormatted = "";

            if (destination.etaRange && destination.etaRange.to) {
                etaFormatted =
                    fixture.division.id === Division.Specialised.id && destination.etaRange.from !== destination.etaRange.to
                        ? this.formatEmailDateRange(destination.etaRange, this.DATE_TIME_FORMAT, timeZone)
                        : DateTime.fromISO(destination.etaRange.to, { zone: timeZone }).toLocaleString(MaritimeDate.DATETIME_FORMAT);
            }

            etaEtbs += `ETA${locationName}: ${etaFormatted}\r\n`;

            for (const berth of destination.berths) {
                const etb = berth.etb ? DateTime.fromISO(berth.etb) : null;
                if (etb?.isValid) {
                    const etbFormatted = etb.setZone(timeZone).toLocaleString(MaritimeDate.DATETIME_FORMAT);
                    etaEtbs += `ETB${berth.name ? " " + berth.name : ""}: ${etbFormatted}\r\n`;
                    hasEtb = true;
                }
            }

            hasDestination = true;
        }

        return { hasDestination, hasEtb, etaEtbs };
    }

    private shouldExcludeEtaEtb(destination: Destination) {
        const lastBerth = destination.berths[destination.berths.length - 1];

        let shouldExclude = false;

        if (lastBerth) {
            const lastActivity = lastBerth.cargoBerthActivities[lastBerth.cargoBerthActivities.length - 1];

            if (lastActivity && lastActivity.laytimeEvents) {
                shouldExclude = lastActivity.laytimeEvents.some((laytimeEvent) =>
                    [LaytimeEventType.Sailed, LaytimeEventType.HosesDisconnected, LaytimeEventType.CargoCompleted].some(
                        (laytimeEventType) => laytimeEvent.type && laytimeEvent.type.id === laytimeEventType.id && !!laytimeEvent.eventDate
                    )
                );
            }
        }

        const allLaytimeEvents = chain(
            (d: CargoBerthActivity) => d.laytimeEvents,
            chain((d) => d.cargoBerthActivities, destination.berths)
        );
        const containsNorTendered = allLaytimeEvents.some(
            (laytimeEvent) =>
                laytimeEvent &&
                laytimeEvent.type &&
                [LaytimeEventType.NORTendered].some((laytimeEventType) => laytimeEvent.type && laytimeEvent.type.id === laytimeEventType.id && !!laytimeEvent.eventDate)
        );

        return shouldExclude || containsNorTendered;
    }

    private getTotalBL(voyage: Voyage, cargo: Cargo) {
        let bl: number = null;

        for (const destination of voyage.destinations.filter((d) => d.location && d.berths)) {
            for (const berth of destination.berths.filter((b) => b.cargoBerthActivities)) {
                for (const activity of berth.cargoBerthActivities.filter((cba) => cba.type && cba.type.id === CargoBerthActivityType.Load.id)) {
                    const associatedCargoes = activity.associatedCargoes.filter(
                        (associatedCargo) => cargo.id === associatedCargo.cargoId && !isNullOrUndefined(associatedCargo.quantity) && !isNaN(+associatedCargo.quantity)
                    );

                    if (associatedCargoes.length) {
                        bl = (bl || 0) + associatedCargoes.reduce((prev, curr) => prev + curr.quantity, 0);
                    }
                }
            }
        }

        return bl;
    }

    private formatNumber = (n: number) => formatNumber(n, false, 0, 3);

    private formatEmailDateRange(dateRange: { from?: string; to?: string }, format: string, timeZone?: string): string {
        if (dateRange.to && dateRange.to === dateRange.from) {
            return DateTime.fromISO(dateRange.to, { zone: timeZone }).toLocaleString(MaritimeDate.DATETIME_FORMAT);
        }
        return formatDateRange(dateRange, format, timeZone);
    }
}
