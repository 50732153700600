import { ChangeDetectorRef, Directive, ElementRef, Host, HostBinding, HostListener, Inject, Input, OnDestroy, OnInit, Optional, Self, SkipSelf } from "@angular/core";
import {
    AsyncValidator,
    AsyncValidatorFn,
    ControlContainer,
    ControlValueAccessor,
    FormControlName,
    NG_ASYNC_VALIDATORS,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator,
    ValidatorFn
} from "@angular/forms";
import { FormControlState, FormControlValueTypes } from "ngrx-forms";
import { combineLatest, of, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";

import { scrollIntoView } from "../../../shared/utils";
import { FixtureWarningPathMapper } from "./fixture-warning-path-mapper";
import { FixtureWarningService } from "./fixture-warning.service";
import { getFullPath, pathToControlId } from "./utils";

const CONTROL_SCROLL_OFFSET = -24;

@Directive({ selector: "[opsNgrxWarningFormControl]" })
export class NgrxFixtureWarningFormControlNameDirective<TStateValue extends FormControlValueTypes> implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();

    @HostBinding("class.ops-warning") hasWarning = false;
    @HostBinding("class.ops-warning--current") isCurrentWarning = false;

    @Input() ngrxFormControlState: FormControlState<TStateValue>;

    constructor(private warningService: FixtureWarningService, private elementRef: ElementRef, private changeDetector: ChangeDetectorRef) {}

    @HostListener("blur") onBlur() {
        if (this.isCurrentWarning) {
            this.warningService.setCurrentWarning(null);
        }
    }

    ngOnInit() {
        if (!this.ngrxFormControlState) {
            throw new Error("The form state must not be undefined");
        }

        this.warningService.warnings$
            .pipe(
                takeUntil(this.destroy$),
                map((warnings) => {
                    if (warnings.length === 0) {
                        return of([]);
                    }

                    return warnings.some((warning) => this.ngrxFormControlState.id === pathToControlId(warning.path));
                })
            )
            .subscribe((hasWarning: boolean) => {
                this.hasWarning = hasWarning;
                this.changeDetector.markForCheck();
            });

        this.warningService.currentWarning$
            .pipe(
                takeUntil(this.destroy$),
                map((warning) => warning && this.ngrxFormControlState.id === pathToControlId(warning.path))
            )
            .subscribe((match) => {
                this.isCurrentWarning = match;
                this.changeDetector.markForCheck();

                if (this.isCurrentWarning) {
                    scrollIntoView(this.elementRef.nativeElement, CONTROL_SCROLL_OFFSET);
                    this.elementRef.nativeElement.focus();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}

@Directive({ selector: "[warningFormControl]" }) // eslint-disable-line  @angular-eslint/directive-selector
export class FixtureWarningFormControlNameDirective extends FormControlName implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();

    @HostBinding("class.ops-warning") hasWarning = false;
    @HostBinding("class.ops-warning--current") isCurrentWarning = false;

    constructor(
        private warningService: FixtureWarningService,
        private warningPathMapper: FixtureWarningPathMapper,
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
        @Optional() @Host() @SkipSelf() parent: ControlContainer,
        @Optional() @Self() @Inject(NG_VALIDATORS) validators: Array<Validator | ValidatorFn>,
        @Optional() @Self() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<AsyncValidator | AsyncValidatorFn>,
        @Optional() @Self() @Inject(NG_VALUE_ACCESSOR) valueAccessors: ControlValueAccessor[]
    ) {
        // ngModelWarningConfig deprecated as of v6 so not required
        super(parent, validators, asyncValidators, valueAccessors, null);
    }

    @HostListener("blur") onBlur() {
        if (this.isCurrentWarning) {
            this.warningService.setCurrentWarning(null);
        }
    }

    ngOnInit() {
        this.warningService.warnings$
            .pipe(
                takeUntil(this.destroy$),
                switchMap((warnings) => {
                    if (warnings.length === 0) {
                        return of([]);
                    }

                    const fullPath = getFullPath(this);

                    return combineLatest(warnings.map((warning) => this.warningPathMapper.pathMatch(warning.path, fullPath)));
                })
            )
            .subscribe((hasWarnings: boolean[]) => {
                this.hasWarning = hasWarnings.some((x) => x);
                this.changeDetector.markForCheck();
            });

        this.warningService.currentWarning$
            .pipe(
                takeUntil(this.destroy$),
                switchMap((warning) => {
                    const fullPath = getFullPath(this);

                    if (!warning) {
                        return of({ fullPath: fullPath, match: false, warningPath: null });
                    }

                    return this.warningPathMapper.pathMatch(warning ? warning.path : null, fullPath).pipe(
                        map((match) => ({
                            fullPath: fullPath,
                            match: match,
                            warningPath: warning ? warning.path.filter((x) => x.segment).map((x) => x.segment) : null
                        }))
                    );
                })
            )
            .subscribe(({ fullPath, match, warningPath }) => {
                if (match && console.log) {
                    console.log("%cFixtureWarningFormControlNameDirective: Setting to current warning", "background:blue;color:white", fullPath, warningPath);
                }

                this.isCurrentWarning = match;
                this.changeDetector.markForCheck();

                if (this.isCurrentWarning) {
                    scrollIntoView(this.elementRef.nativeElement, CONTROL_SCROLL_OFFSET);
                    this.elementRef.nativeElement.focus();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
