<div>
    <div class="header">
        Contacts
        <div class="left-toolbar-expander" (click)="collapseLeftBar()">
            <div class="has-icon icon--close"></div>
        </div>
    </div>
    <ops-coa-contacts-form [form]="form$ | async" [companyRoles]="companyRoles$ | async"></ops-coa-contacts-form>
    <div class="spacer"></div>

    <div class="spacer line"></div>
</div>
<ops-coa-contacts-list [companies]="companies$ | async" (removeCompany)="removeCompany($event)"></ops-coa-contacts-list>
