import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "truncate"
})
export class TruncatePipe implements PipeTransform {
    private readonly defaultCutOffIndex = 20;

    transform(value: string, cutOffIndex: number, truncateIndicator?: string) {
        const limit = cutOffIndex ? cutOffIndex : this.defaultCutOffIndex;
        const trail = truncateIndicator ? truncateIndicator : "...";
        return value?.length > limit ? value.substring(0, limit) + trail : value;
    }
}
