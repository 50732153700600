<div class="favorite-toolbar">
    <div class="btn-toolbar" role="toolbar" aria-label="Favorite fixture toolbar">
        <!-- <div class="btn-group" role="group" aria-label="Open">
            <button class="btn btn-primary text-uppercase" [disabled]="!(anySelected || allSelected)" (click)="openSelected()" data-test-remove-button>Open</button>
        </div> -->
        <div class="btn-group" role="group" aria-label="Remove">
            <button class="btn btn-primary text-uppercase" [disabled]="!(anySelected || allSelected)"
                (click)="removeSelected()" data-test-remove-button>Remove</button>
        </div>
        <div *ngIf="isExportVisible" class="btn-group" role="group" aria-label="Export">
            <button class="btn btn-primary text-uppercase"
                [disabled]="!(anySelected || allSelected) || exportInProgress" (click)="exportSelected()"
                data-test-export-button>Export</button>
        </div>
    </div>
</div>