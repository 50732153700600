import { createSelector } from "@ngrx/store";

import { selectCurrentCoaState } from "../coa/selectors";
import { cargoToListItem } from "../model";

export const selectCurrentCoaCargoListItems = createSelector(selectCurrentCoaState, (state) => state?.coa?.cargoes.map(cargoToListItem));

export const selectCargoForm = createSelector(selectCurrentCoaState, (state) => state?.cargoForm);

export const selectCargoFormSaving = createSelector(selectCurrentCoaState, (state) => !state || state.cargoFormSaveStatus === "persisting");

export const selectCargoFormSaveFailed = createSelector(selectCurrentCoaState, (state) => !state || state.cargoFormSaveStatus === "failed");

export const selectErroredCargoes = createSelector(selectCurrentCoaState, (state) =>
    state.erroredItems
        ?.filter((e) => e.itemType === "cargoes")
        .map((e) => ({
            id: e.id,
            error: e.error
        }))
);
