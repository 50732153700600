import { box, Boxed } from "ngrx-forms";

import { CompanySearchResult } from "../../../fixture/shared/models/common/company-search-result.model";
import { CoaId } from "./coa";
import { LiftingId } from "./lifting";

export type CompanyId = number;

export type Company = Readonly<{
    accountId: CompanyId;
    name: string;
    groupName: string;
    role: CompanyRole;
    isReadonly?: boolean;
}>;

export type AddCompany = Pick<Company, "accountId" | "role">;

export const contactSearchTypes = ["company", "person"] as const;
export type ContactSearchType = typeof contactSearchTypes[number];

export const companyRoles = [
    "Beneficial Owner",
    "Buyer Affiliate",
    "Charterer",
    "Charterer Affiliate",
    "Charterer Broker",
    "Client Affiliate",
    "Commercial Manager",
    "Consultant",
    "Owner",
    "Owner Affiliate",
    "Owner Broker",
    "Port Agent",
    "Third Party Broker"
] as const;
export type CompanyRole = typeof companyRoles[number];

export type CompanyRoleOption = Readonly<{
    name: CompanyRole;
    disabled: boolean;
}>;

export type CompanyForm = Readonly<{
    searchType: ContactSearchType;
    companyRole?: CompanyRole;
    company: Boxed<CompanySearchResult>;
}>;

export const companyForm = (): CompanyForm => ({
    searchType: "company",
    companyRole: null,
    company: box(null)
});

export const getFormGroupId = (coaId: CoaId): string => `${coaId}.CompanyForm`;
export const getLiftingFormGroupId = (liftingId: LiftingId): string => `${liftingId}.CompanyForm`;

export const mapCompanySearchResultToCoaCompany = (searchResult: CompanySearchResult): Company => ({
    accountId: searchResult.accountId,
    groupName: searchResult.groupName,
    name: searchResult.companyName,
    role: null
});
