import { createAction, On, on, props } from "@ngrx/store";

import { UpdateSearchTokens, updateSearchTokensReducer } from "@ops/state";

import { LtcState } from "../../model";

/* ACTIONS */
export const updateLaytimeCalculationGridSearchTokensAction = createAction("[Laytime Calculation Grid] Update Search Tokens", props<UpdateSearchTokens>());

/* REDUCERS */
export const updateLaytimeCalculationGridSearchTokensReducer: On<LtcState> = on(updateLaytimeCalculationGridSearchTokensAction, (state, action) =>
    updateSearchTokensReducer(state, action, "grid")
);
