<div class="row no-gutters">
    <div class="form-group col-2">
        <label for="voyageId">TC Voyage Number</label>
        <ng-select
            id="voyageId"
            class="ops-select"
            [ngModel]="form.value.voyageId"
            [items]="voyages"
            [searchable]="false"
            [clearable]="false"
            (change)="voyageSelected.emit($event.voyageId)"
            [readonly]="readonly"
            bindLabel="voyageNumber"
            bindValue="voyageId"
            data-test-voyage-number
            [selectOnTab]="true"
        ></ng-select>
    </div>
    <div class="form-group col-2">
        <label for="tcVoyageReference">TC Voyage Reference</label>
        <input
            id="tcVoyageReference"
            class="form-control"
            [ngrxFormControlState]="form.controls.tcVoyageReference"
            [readonly]="readonly"
            data-test-tc-voyage-reference
        />
    </div>
    <div class="form-group col-2">
        <label for="voyageStatus">TC Voyage Status</label>
        <ops-refdata-dropdown
            id="voyageStatus"
            type="VoyageStatus"
            placeholder="Select status"
            [ngrxFormControlState]="form.controls.voyageStatus"
            [allowClear]="false"
            [readonly]="readonly"
            data-test-voyage-status
        ></ops-refdata-dropdown>
    </div>
    <div class="form-group col-2">
        <label for="voyageCost">Overall Voyage Cost</label>
        <input id="voyageCost" class="form-control text-right" [value]="cost?.overallVoyageCost | opsNumber: true:2:2" data-test-overall-voyage-cost readonly />
    </div>
    <div class="form-group col-2">
        <label for="overallEffectiveFreight">Overall Effective Freight</label>
        <input
            id="overallEffectiveFreight"
            class="form-control text-right"
            [value]="cost?.overallEffectiveFreight | opsNumber: true:2:2"
            data-test-overall-effective-freight
            readonly
        />
    </div>
</div>
