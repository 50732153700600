import { createFormArrayState, FormArrayState } from "ngrx-forms";
import { last } from "ramda";
import { v4 as uuid } from "uuid";

import { Identity, isNumber } from "@ops/shared";

import { ActivityCargo, ActivityCargoId } from "./activity-cargo";
import { ActivityLocationId } from "./activity-location";
import { LaytimeEventRemark } from "./laytime-event-remarks";
import { LaytimeEventType } from "./laytime-event-types";

export type LaytimeEventId = Identity<string, "LaytimeEventId">;
export const createLaytimeEventId = (): LaytimeEventId => uuid() as LaytimeEventId;

export type LaytimeEvent = Readonly<{
    id: LaytimeEventId;
    type?: LaytimeEventType;
    date?: string;
    percentage?: string;
    cargoId?: ActivityCargoId;
    remarks?: LaytimeEventRemark;
    comments?: string;
    cargoName?: string;
}>;

export type LaytimeEventForm = Readonly<{
    id: LaytimeEventId;
    type?: LaytimeEventType;
    date?: string;
    percentage?: string;
    cargoId?: ActivityCargoId;
    remarks?: LaytimeEventRemark;
    comments?: string;
}>;

export const createLaytimeEvent: {
    (id: string, date?: string, percentage?: string, cargoId?: ActivityCargoId): LaytimeEvent;
    (laytimeEvents: ReadonlyArray<LaytimeEvent>, index?: number): LaytimeEvent;
    (laytimeEvents: ReadonlyArray<LaytimeEvent>, index: number | undefined, percentage: string | undefined, type: string | undefined): LaytimeEvent;
} = (...args: unknown[]) => {
    if (Array.isArray(args[0])) {
        const [laytimeEvents, index, percentage, type] = args as [ReadonlyArray<LaytimeEvent>, number, string, LaytimeEventType];

        let date: string;
        if (isNumber(index) && laytimeEvents.length) {
            const previousEventDate = laytimeEvents[index - 1]?.date;
            const nextEventDate = laytimeEvents[index]?.date;
            date = previousEventDate ?? nextEventDate;
        } else {
            date = last(laytimeEvents)?.date;
        }

        return { id: createLaytimeEventId(), date, percentage: percentage ?? "100", type };
    }

    const [id, date, percentage, cargoId] = args as [LaytimeEventId, string, string, ActivityCargoId];

    return { id, date, percentage: percentage ?? "100", cargoId };
};

export const toLaytimeEventForm = (source: LaytimeEvent): LaytimeEventForm => ({
    id: source.id,
    type: source.type,
    date: source.date,
    percentage: source.percentage,
    cargoId: source.cargoId,
    remarks: source.remarks,
    comments: source.comments
});

export const toLaytimeEvent = (source: LaytimeEventForm, cargoes: ReadonlyArray<ActivityCargo>): LaytimeEvent => ({
    id: source.id,
    type: source.type,
    date: source.date,
    percentage: source.percentage,
    cargoId: source.cargoId,
    remarks: source.remarks,
    comments: source.comments,
    cargoName: source.cargoId && cargoes.find((c) => c.id === source.cargoId)?.name
});

export const activityLocationLaytimeEventsFormKey = "activityLocationLaytimeEventForms";
export const createActivityLocationLaytimeEventFormsState = (
    activityLocationId: ActivityLocationId,
    laytimeEvents: ReadonlyArray<LaytimeEvent>
): FormArrayState<LaytimeEventForm> => createFormArrayState(`${activityLocationId}.${activityLocationLaytimeEventsFormKey}`, laytimeEvents.map(toLaytimeEventForm));
