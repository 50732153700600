import { FormGroupState, updateArray, updateGroup, validate } from "ngrx-forms";
import { greaterThanOrEqualTo } from "ngrx-forms/validation";

import { validQuantityModel } from "@ops/state";

import { LaytimeCalculationCargoTermsLocationForm, LtcState } from "../../../../model";
import { currentCalculationStateReducer } from "../../../reducer";

const validateLaytimeCalculationCargoTermsLocationForm = (form: FormGroupState<LaytimeCalculationCargoTermsLocationForm>) =>
    updateGroup<LaytimeCalculationCargoTermsLocationForm>({
        allowance: validate(validQuantityModel),
        extraHours: validate(greaterThanOrEqualTo(0))
    })(form);

export const validateLaytimeCalculationCargoTermsForm = (state: LtcState): LtcState => {
    if (!state || !state.calculations || !state.currentCalculationId || !state.calculations.byId[state.currentCalculationId].cargoTermsForm) {
        return state;
    }

    return currentCalculationStateReducer(state, (ltcState) => ({
        ...ltcState,
        cargoTermsForm: {
            loadLocations: updateArray<LaytimeCalculationCargoTermsLocationForm>(validateLaytimeCalculationCargoTermsLocationForm)(ltcState.cargoTermsForm.loadLocations),
            dischargeLocations: updateArray<LaytimeCalculationCargoTermsLocationForm>(validateLaytimeCalculationCargoTermsLocationForm)(ltcState.cargoTermsForm.dischargeLocations)
        }
    }));
};
