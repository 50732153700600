<div class="row no-gutters">
    <div class="col">
        <ops-timebars [parentForm]="demurrageTabForm" [fixture]="fixture" [destinations]="destinations" (timebarsUpdated)="updateDemurrageTab($event)">
        </ops-timebars>
    </div>
</div>

<div class="row no-gutters">
    <div class="col">
        <ops-claims-notification
            [parentForm]="demurrageTabForm"
            [fixtureId]="fixture.fixtureId"
            [claimsNotification]="fixture.demurrage.claimNotification"
            [destinations]="destinations"
            (claimsNotificationUpdated)="updateDemurrageTab($event)"
        >
        </ops-claims-notification>
    </div>
</div>

<div class="row no-gutters">
    <div class="col">
        <ops-demurrage-claim-container [parentForm]="demurrageTabForm" [destinations]="destinations" [fixture]="fixture"></ops-demurrage-claim-container>
    </div>
</div>
