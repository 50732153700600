import { Injectable } from "@angular/core";
import { DateTime } from "luxon";

import { isNumber } from "@ops/shared";
import { AtSeaBunkerConsumptionMode, AtSeaVesselStatus, LaytimeEventType } from "@ops/shared/reference-data";

import { AtSeaBunkerConsumption, Destination } from "../shared/models";
import { getLaytimeEventDate } from "../state/model";

@Injectable({
    providedIn: "root"
})
export class AtSeaSpeedCalculator {
    calcAtSeaVoyageTime(atSea: AtSeaBunkerConsumption, mode: AtSeaBunkerConsumptionMode, destinations: Destination[]): number | null {
        if (!atSea.destinationFromId || !atSea.destinationToId) {
            return null;
        }

        const fromDestination = destinations.find((x) => x.id === atSea.destinationFromId);
        const toDestination = destinations.find((x) => x.id === atSea.destinationToId);

        if (!fromDestination || !toDestination) {
            return null;
        }

        let fromDate: string;
        let toDate: string;

        if (mode.id === AtSeaBunkerConsumptionMode.Simple.id) {
            if (!atSea.vesselStatus) {
                return;
            }

            if (atSea.vesselStatus.id === AtSeaVesselStatus.Ballast.id) {
                fromDate = getLaytimeEventDate(fromDestination, LaytimeEventType.Sailed);
                toDate = getLaytimeEventDate(toDestination, LaytimeEventType.NORTendered);
            } else {
                fromDate = getLaytimeEventDate(fromDestination, LaytimeEventType.NORTendered);
                toDate = getLaytimeEventDate(toDestination, LaytimeEventType.CargoCompleted);
            }
        } else {
            fromDate = getLaytimeEventDate(fromDestination, LaytimeEventType.Sailed);
            toDate = toDestination.arrivalDateTime;
        }

        const fromDateTime = DateTime.fromISO(fromDate);
        const toDateTime = DateTime.fromISO(toDate);
        const diff = toDateTime.diff(fromDateTime, "days");

        return diff.isValid ? diff.days : null;
    }

    calcAtSeaAverageSpeed(durationInDays: number, distance: number) {
        if (!isNumber(durationInDays) || !isNumber(distance)) {
            return null;
        }

        const HOURS_IN_DAY = 24;
        return distance / durationInDays / HOURS_IN_DAY;
    }
}
