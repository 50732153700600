import { Command } from "../../../mediator/commands/command";
import { StatementOfFactsContainerModel } from "../../../shared/models/form-models/statement-of-facts-container-model";
import { DestinationId } from "../../../state/model";
import { UpdateStatementOfFactsCommandHandler } from "./update-statement-of-facts.command-handler";

export class UpdateStatementOfFactsCommand extends Command {
    constructor(readonly destinationId: DestinationId, readonly statementsOfFact: StatementOfFactsContainerModel) {
        super(UpdateStatementOfFactsCommandHandler, null);
    }
}
