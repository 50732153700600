import * as R from "ramda";

import { Identity } from "@ops/shared";

import { Fixture } from "../../shared/models";

export type FixtureId = Identity<string, "FixtureId">;

export const getFixtureOperatorNames = (fixture: Fixture): string =>
    R.pipe(
        R.flatten,
        R.map((c) => c.fullName),
        R.uniq,
        R.join(",")
    )(fixture.operators) as string;

export const getFixtureClaimNames = (fixture: Fixture): string =>
    R.pipe(
        R.flatten,
        R.map((c) => c.fullName),
        R.uniq,
        R.join(",")
    )(fixture.claims) as string;
