import { on, On } from "@ngrx/store";
import { DateTime } from "luxon";
import * as R from "ramda";
import { Evolver } from "ramda";

import { CargoHistoricalState, LocationHistoricalState } from "../../../left-bar/lifting-history/lifting-history";
import {
    CargoHistoricalEvent,
    CargoHistoricalEventName,
    CargoId,
    CoasState,
    EntityChanges,
    HistoricalEventType,
    LiftingCargo,
    LiftingCargoLocation,
    LiftingCargoPlanStatus,
    LiftingId,
    User,
    validateLiftingCargoes
} from "../model";
import { defineProperty } from "../utils";
import { auditCargoPlanEmailActionSuccess } from "./cargo/cargo-plan-email";
import { saveCargoPlanStatusSuccessAction, saveLaycanSuccessAction } from "./cargo/form/edit-laycan";
import { removeCargoSuccessAction } from "./cargo/form/remove-cargo";
import { saveCargoSuccessAction, saveNewCargoSuccessAction } from "./cargo/form/save-cargo";
import { liftingStateReducer } from "./reducer";

const actionToEventMapping: { [action: string]: { eventName: CargoHistoricalEventName; eventType: HistoricalEventType } } = {
    [saveLaycanSuccessAction.type]: {
        eventName: "LiftingCargoLaycanChangedV1",
        eventType: "Update"
    },
    [saveCargoPlanStatusSuccessAction.type]: {
        eventName: "LiftingCargoPlanStatusChangedV1",
        eventType: "Update"
    },
    [saveNewCargoSuccessAction.type]: {
        eventName: "LiftingCargoOrderAddedV1",
        eventType: "Add"
    },
    [removeCargoSuccessAction.type]: {
        eventName: "LiftingCargoOrderRemovedV1",
        eventType: "Remove"
    },
    [saveCargoSuccessAction.type]: {
        eventName: "LiftingCargoOrderUpdatedV1",
        eventType: "Update"
    },
    [auditCargoPlanEmailActionSuccess.type]: {
        eventName: "LiftingCargoPlanEmailGeneratedV1",
        eventType: "Update"
    }
};

const updateCargoNominationHistory = (
    type: string,
    liftingId: LiftingId,
    state: CoasState,
    user: User,
    cargoId?: CargoId,
    changes?: EntityChanges,
    cargoPlanStatus?: LiftingCargoPlanStatus | undefined
) => {
    const date = DateTime.utc().toISO();
    const { eventName, eventType } = actionToEventMapping[type];
    const historicalEvent: CargoHistoricalEvent = {
        name: eventName,
        type: eventType,
        entityId: cargoId,
        date,
        user,
        changes
    };
    let cargoPlanStatusEvent: CargoHistoricalEvent;
    if (cargoPlanStatus) {
        cargoPlanStatusEvent = {
            name: "LiftingCargoPlanStatusChangedV1",
            type: "Update",
            changes: { "/cargoPlanStatus": cargoPlanStatus },
            date,
            user
        };
    }
    const updateFns: Evolver = {
        cargoNominationHistory: (events) => (cargoPlanStatusEvent ? [...events, historicalEvent, cargoPlanStatusEvent] : [...events, historicalEvent])
    };
    return liftingStateReducer(state, liftingId, R.evolve(updateFns));
};

export const updateCargoNominationHistoryByLaycanReducer: On<CoasState> = on(saveLaycanSuccessAction, (state: CoasState, { type, liftingId, laycan, user, changeReason }) => {
    const changes = { "/cargoLaycan": JSON.stringify(laycan) };
    const cargoPlanStatus = !laycan ? "Tentative" : undefined;
    if (changeReason) {
        defineProperty(changes, "_changeReason", JSON.stringify(changeReason));
    }
    return updateCargoNominationHistory(type, liftingId, state, user, undefined, changes, cargoPlanStatus);
});

export const updateCargoNominationHistoryByCargoPlanStatusReducer: On<CoasState> = on(saveCargoPlanStatusSuccessAction, (state: CoasState, { type, liftingId, status, user }) =>
    updateCargoNominationHistory(type, liftingId, state, user, undefined, { "/cargoPlanStatus": status })
);

export const updateCargoNominationHistoryByCargoReducer: On<CoasState> = on(
    saveCargoSuccessAction,
    saveNewCargoSuccessAction,
    (state: CoasState, { type, liftingId, cargo, user, changeReason }) => {
        const lifting = state.liftings.byId[state.currentLiftingId].lifting;
        let index = lifting.cargoes.findIndex((c) => c.cargoId === cargo.cargoId);
        if (index < 0) {
            index = lifting.cargoes.length;
        }
        const changes = {};
        defineProperty(changes, `/cargoes/${index}`, JSON.stringify(toCargoHistoricalState(cargo)));
        if (changeReason) {
            defineProperty(changes, "_changeReason", JSON.stringify(changeReason));
        }
        const updatedLifting = R.assocPath(["cargoes", index], cargo, lifting);
        const cargoPlanStatus = validateLiftingCargoes(updatedLifting).isInvalid ? "Tentative" : undefined;
        return updateCargoNominationHistory(type, liftingId, state, user, cargo.cargoId, changes, cargoPlanStatus);
    }
);

export const updateCargoNominationHistoryOnCargoRemovalReducer: On<CoasState> = on(
    removeCargoSuccessAction,
    (state: CoasState, { type, liftingId, cargoId, user, changeReason }) => {
        const cargoes = state.liftings.byId[state.currentLiftingId].lifting.cargoes;
        const changes = {};
        const cargoPlanStatus = cargoes.length === 0 ? "Tentative" : undefined;
        if (changeReason) {
            defineProperty(changes, "_changeReason", JSON.stringify(changeReason));
        }
        return updateCargoNominationHistory(type, liftingId, state, user, cargoId, changes, cargoPlanStatus);
    }
);

export const updateCargoPlanEmailAuditReducer: On<CoasState> = on(auditCargoPlanEmailActionSuccess, (state: CoasState, { type, liftingId, user }) =>
    updateCargoNominationHistory(type, liftingId, state, user)
);

export const toCargoHistoricalState = (cargo: LiftingCargo): CargoHistoricalState => ({
    ...cargo,
    loadLocations: cargo.loadLocations?.map(toLocationState),
    dischargeLocations: cargo.dischargeLocations?.map(toLocationState)
});

const toLocationState = (location: LiftingCargoLocation): LocationHistoricalState => ({
    name: location.name,
    estimatedTimeOfArrival: location.eta ?? null
});
