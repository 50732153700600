import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { LtcAuthGuard } from "./authentication";
import {
    AddActivityLocationsShellComponent,
    LaytimeCalculationActivityLocationShellComponent,
    LaytimeCalculationShellComponent,
    LaytimeCalculationContainerShellComponent,
    LaytimeCalculationSummaryShellComponent,
    LaytimeCalculationTermsShellComponent,
    LaytimeCalculationGridComponent
} from "./components";

const routes: Routes = [
    {
        path: "",
        component: LaytimeCalculationGridComponent
    },
    {
        path: ":ltcId",
        component: LaytimeCalculationContainerShellComponent,
        canActivate: [LtcAuthGuard],
        children: [
            {
                path: "",
                component: LaytimeCalculationShellComponent,
                children: [
                    { path: "", component: LaytimeCalculationSummaryShellComponent, data: { title: "Summary" } },
                    { path: "terms", component: LaytimeCalculationTermsShellComponent, data: { title: "Terms" } },
                    { path: "locations/:locationId", component: LaytimeCalculationActivityLocationShellComponent, data: { title: "Location" } }
                ]
            },
            {
                path: "add-locations",
                component: AddActivityLocationsShellComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LtcRoutingModule {}
