/* eslint-disable no-magic-numbers */
import { createAction, On, on, props } from "@ngrx/store";
import { Draft } from "immer";

import { activityExpandedKey, berthExpandedKey, cargoExpandedKey, CargoForm, destinationExpandedKey, FixturesState, VoyageExpandedSections } from "../model";
import { currentVoyageStateReducer } from "../voyage/reducer";

const APPLICABLE_CARGO_FIELDS: Array<keyof CargoForm> = ["loadLocation", "dischargeLocation"];

/* ACTIONS */
export const interopCurrentWarningChangeAction = createAction("[Fixture Warnings Interop] Current Warning Change", props<{ controlId: string }>());

/* REDUCERS */
export const expandSectionsReducer: On<FixturesState> = on(interopCurrentWarningChangeAction, (state, { controlId }) => {
    const cargoesSection = controlId.indexOf(".cargoes") >= 0;
    const destinationsSection = controlId.indexOf(".destinations") >= 0;
    const totalsSection = controlId.indexOf(".totals") >= 0;

    if (!cargoesSection && !destinationsSection && !totalsSection) {
        return state;
    }

    return currentVoyageStateReducer(state, (voyageState) => {
        const expandedSectionsPatch: Draft<Partial<VoyageExpandedSections>> = {};

        if (cargoesSection) {
            const regexMatch = /cargoes(?:\.(\d+)(?:\.(\w+))?)?/.exec(controlId);
            const cargoId = regexMatch[1] && voyageState.form.value.cargoes[Number(regexMatch[1])].cargoId;
            const cargoField = regexMatch[2];

            expandedSectionsPatch[cargoExpandedKey(cargoId)] = true;

            if (!cargoId || !cargoField || !APPLICABLE_CARGO_FIELDS.includes(cargoField as keyof CargoForm)) {
                return voyageState;
            }
        }

        if (destinationsSection) {
            const regexMatch = /destinations(?:\.(\d+)(?:\.(?:berths(?:\.(\d+)(?:\.(?:activities(?:\.(\d+))?)?)?)?)?)?)?/.exec(controlId);
            const destination = regexMatch[1] && voyageState.form.value.destinations[Number(regexMatch[1])];
            const berth = regexMatch[2] && destination.berths[Number(regexMatch[2])];
            const activity = regexMatch[3] && berth.activities[Number(regexMatch[3])];

            expandedSectionsPatch.destinations = true;

            if (destination) {
                expandedSectionsPatch[destinationExpandedKey(destination.id)] = true;
            }

            if (berth) {
                expandedSectionsPatch[berthExpandedKey(destination.id, berth.id)] = true;
            }

            if (activity) {
                expandedSectionsPatch[activityExpandedKey(destination.id, berth.id, activity.activityId)] = true;
            }

            if (Object.getOwnPropertyNames(expandedSectionsPatch).every((prop) => expandedSectionsPatch[prop] === voyageState.expandedSections[prop])) {
                return voyageState;
            }
        }

        if (totalsSection) {
            expandedSectionsPatch.totals = true;
        }

        return {
            ...voyageState,
            expandedSections: {
                ...voyageState.expandedSections,
                ...expandedSectionsPatch
            }
        };
    });
});
