<ops-laytime-calculation-header 
    *ngIf="nameForm$ | async as form" 
    [form]="form"
    [persistenceStatus]="persistenceStatus$ | async" 
    [lastUpdated]="lastUpdated$ | async"
    [isExportLoading]="isExportLoading$ | async" 
    (cancelRename)="cancelRename()" 
    (closeClick)="close()"
    (confirmRename)="confirmRename()"
    (exportExcel)="exportExcel()"
    (exportPdf)="exportPdf()"
    (cloneClick)="clone()">
</ops-laytime-calculation-header>

<ops-laytime-calculation-nav 
    [ltcId]="ltcId$ | async" 
    [activityLocations]="activityLocations$ | async"
    [currentActivityLocationId]="currentActivityLocationId$ | async">
</ops-laytime-calculation-nav>