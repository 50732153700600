import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "src/app/shared/shared.module";
import { CoaStoreModule } from "../../coa/state";
import { ActionComponent } from "./action/action.component";
import { ActionsComponent } from "./actions.component";
import { EditActionComponent } from "./edit-action/edit-action.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        ReactiveFormsModule,
        CoaStoreModule
    ],
    declarations: [ActionsComponent, EditActionComponent, ActionComponent],
    exports: [ActionsComponent]
})
export class ActionsModule {}
