import { Maritime, toDateTime } from "@ops/shared";

import { CargoId } from "../cargo";
import { User } from "../user";
import { VesselId } from "./vessel-nomination";

export type EntityChanges = Readonly<{ [jsonpath: string]: string }>;
export type HistoricalEventType = "Add" | "Update" | "Remove";

export type VesselHistoricalEventName =
    | "LiftingVesselNominationAddedV1"
    | "LiftingVesselNominationRemovedV1"
    | "LiftingVesselNominationUpdatedV1"
    | "LiftingVesselPlanStatusChangedV1"
    | "LiftingVesselNominationAcceptedV1"
    | "LiftingVesselNominationRejectedV1"
    | "LiftingVesselNominationUnderReviewV1"
    | "LiftingVesselNominationPreferredV1"
    | "LiftingVesselRenominatedV1";

export type CargoHistoricalEventName =
    | "LiftingCargoLaycanChangedV1"
    | "LiftingCargoPlanStatusChangedV1"
    | "LiftingCargoOrderAddedV1"
    | "LiftingCargoOrderRemovedV1"
    | "LiftingCargoOrderUpdatedV1"
    | "LiftingCargoPlanEmailGeneratedV1";

export type HistoricalEvent<TEventName = string, TEntityId = string> = Readonly<{
    name: TEventName;
    type: HistoricalEventType;
    entityId?: TEntityId;
    date: string;
    user: User;
    changes?: EntityChanges;
}>;

export type VesselHistoricalEvent = HistoricalEvent<VesselHistoricalEventName, VesselId>;
export type CargoHistoricalEvent = HistoricalEvent<CargoHistoricalEventName, CargoId>;

export const toSummaryDate = (event: HistoricalEvent) => (event ? toDateTime(event.date).toLocaleString(Maritime.DATETIME_SHORT) : "-");
