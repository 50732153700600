<form *ngIf="form" novalidate autocomplete="off">
    <!--
        These radio buttons are out of scope for #60531, but will be needed for subsequent stories
     -->
    <!-- <div class="container spacer">
        <div class="spacer padding"></div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" [ngrxFormControlState]="form.controls.searchType" id="personSearch" value="person" />
            <label for="personSearch" class="form-check-label">People</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" [ngrxFormControlState]="form.controls.searchType" id="companySearch" value="company" />
            <label for="companySearch" class="form-check-label">Company</label>
        </div>
    </div> -->
    <ops-person-search *ngIf="form.controls.searchType.value === 'person'"></ops-person-search>
    <ops-coa-company-search *ngIf="form.controls.searchType.value === 'company'" [form]="form" [roles]="companyRoles"></ops-coa-company-search>
</form>
