export const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"] as const;
export type DayOfWeek = typeof daysOfWeek[number];

export const toJSDateWeekday = (dayOfWeek: DayOfWeek): number => daysOfWeek.indexOf(dayOfWeek);

export const toLuxonWeekday = (dayOfWeek: DayOfWeek): number =>
    (<{ [w in DayOfWeek]: number }>{
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7
    })[dayOfWeek];
