import { DateTime, Duration, Zone } from "luxon";

import { DateRange } from "../../fixture/shared/models";
import { toMaritimeDateRange } from "../date-utils/date-utilities";
import { isNullOrUndefined } from "./check";
import { normalizeDuration } from "./duration-utils";

/**
 * <b>Moves the given date between the `from` and `to` timezones by changing the underlying point in time.</b>
 * <br>
 * If the `from` timezone is `"Europe/London"`, with a date of `"2017-02-08T00:45:00.000Z"` it is displayed as
 * `"2017-02-08T00:45:00"`.
 * <br>
 * If I shift the timezone to `"Europe/Paris"`, the result will be `"2017-02-07T23:45:00.000Z"` and it will still
 * be displayed as `"2017-02-08T00:45:00"`.
 *
 * @param date  ISO format date string.
 * @param from  The timezone the date is currently for.
 * @param to    The timezone the date should be in.
 */
export function shiftTimeZone(date: string, from: string, to: string): string;
/**
 * <b>Moves the given date between the `from` and `to` timezones by changing the underlying point in time.</b>
 * <br>
 * If the `from` timezone is `"Europe/London"`, with a date of `"2017-02-08T00:45:00.000Z"` it is displayed as
 * `"2017-02-08T00:45:00"`.
 * <br>
 * If I shift the timezone to `"Europe/Paris"`, the result will be `"2017-02-07T23:45:00.000Z"` and it will still
 * be displayed as `"2017-02-08T00:45:00"`.
 *
 * @param date  JS Date object.
 * @param from  The timezone the date is currently for.
 * @param to    The timezone the date should be in.
 */
export function shiftTimeZone(date: Date, from: string, to: string): Date;
/**
 * <b>Moves the given date between the `from` and `to` timezones by changing the underlying point in time.</b>
 * <br>
 * If the `from` timezone is `"Europe/London"`, with a date of `"2017-02-08T00:45:00.000Z"` it is displayed as
 * `"2017-02-08T00:45:00"`.
 * <br>
 * If I shift the timezone to `"Europe/Paris"`, the result will be `"2017-02-07T23:45:00.000Z"` and it will still
 * be displayed as `"2017-02-08T00:45:00"`.
 *
 * @param date  The Luxon DateTime.
 * @param from  The timezone the date is currently for.
 * @param to    The timezone the date should be in.
 */
export function shiftTimeZone(date: DateTime, from: string | Zone, to: string | Zone): DateTime;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function shiftTimeZone<T>(date: string | Date | DateTime, from: string | Zone, to: string | Zone): string | Date | DateTime {
    if (!date) {
        return date;
    }
    if (!from) {
        throw Error("From time zone must be defined");
    }
    if (!to) {
        throw Error("To time zone must be defined");
    }

    const fromDate = date instanceof DateTime ? date.setZone(from) : "string" === typeof date ? DateTime.fromISO(date, { zone: from }) : DateTime.fromJSDate(date, { zone: from });

    const toDate = DateTime.fromObject({
        ...fromDate.toObject(),
        zone: to
    });

    if (date instanceof DateTime) {
        return toDate;
    }

    return "string" === typeof date ? toDate.toUTC().toISO() : toDate.toJSDate();
}

/**
 * <b>Moves the given date range between the `from` and `to` timezones by changing the underlying point in time.</b>
 * <br>
 * If the `from` timezone is `"Europe/London"`, with a date of `"2017-02-08T00:45:00.000Z"` it is displayed as
 * `"2017-02-08T00:45:00"`.
 * <br>
 * If I shift the timezone to `"Europe/Paris"`, the result will be `"2017-02-07T23:45:00.000Z"` and it will still
 * be displayed as `"2017-02-08T00:45:00"`.
 *
 * @param range  The ISO format date range object.
 * @param from  The timezone the date is currently for.
 * @param to    The timezone the date should be in.
 */
export function shiftRangeTimeZone(range: { from?: string; to?: string }, from: string, to: string): { from?: string; to?: string } {
    if (range) {
        return { from: shiftTimeZone(range.from, from, to), to: shiftTimeZone(range.to, from, to) };
    }

    return range;
}

/**
 * Compares two date ranges and returns true if they are equal.
 *
 * @param d1 First date
 * @param d2 Second date
 */
export function dateRangeEquals(d1: DateRange, d2: DateRange): boolean {
    return (isNullOrUndefined(d1) && isNullOrUndefined(d2)) || (d1 && d2 && toMaritimeDateRange(d1)?.equals(toMaritimeDateRange(d2)));
}

/**
 * @deprecated Use `dateTimeFromIso()`
 */
export const toDateTime = (isoString: string | null | undefined): DateTime | null => (isNullOrUndefined(isoString) ? null : DateTime.fromISO(isoString));

/**
 * Creates a DateTime from an ISO 8601 string and returns it in UTC time zone.
 * If time zone is not specified it is defaulted to UTC.
 * If time zone is specified the time is converted to UTC.
 *
 * @param isoString ISO 8601 string
 */
export const toDateTimeAsUtc = (isoString: string): DateTime => DateTime.fromISO(isoString, { zone: "utc" });

/**
 * Parses an ISO 8601 string and returns javascript Date instance.
 * If time zone is not specified it is defaulted to UTC.
 * If time zone is specified the time is converted to UTC.
 *
 * @param isoString ISO 8601 string
 */
export const parseISODateAsUtc = (isoString: string): Date | null => {
    if (isoString) {
        const m = toDateTimeAsUtc(isoString);
        if (m.isValid) {
            return m.toJSDate();
        }
    }
    return null;
};

/**
 * Compares two DateTimes returning true if they represent the same moment in time.
 *
 * @param d1 First Date
 * @param d2 Second Date
 */
export const dateTimeEquals = (d1: DateTime, d2: DateTime): boolean => (d1 && d2 && +d1 === +d2) || (isNullOrUndefined(d1) && isNullOrUndefined(d2));

/**
 * Strips the Zulu specifier or offset from an ISO 8601 string.
 *
 * @param isoString ISO 8601 string.
 */
export const stripOffset = (isoString: string | null | undefined) => isoString?.match(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}(\.\d+)?)?)/)?.[0];

/**
 * Returns duration which equals to difference in timezones of DateTimes.
 *
 * @param firstDateTime DateTime.
 * @param secondDateTime DateTime.
 */
export const diffInOffsets = (firstDateTime: DateTime, secondDateTime: DateTime) => {
    if (firstDateTime && secondDateTime) {
        const diff = Duration.fromObject({ minutes: secondDateTime.offset - firstDateTime.offset });
        return normalizeDuration(diff);
    }

    return null;
};

/**
 * @param isoDateTime ISO string of one of the following formats.
 * @example
 * "2022-01-02T13:00:00"
 * "2022-01-02T13:00:00Z"
 * "2022-01-02T13:00:00+03:00"
 * "2022-01-02T13:00:00-07:00"
 *
 * @param zone Time zone of DateTime. Please do NOT make this param optional,
 * in most cases DateTime should be created with timezone,
 * in other cases use "utc" or "local" (I'd recomend avoiding "local")
 * @returns May return different DateTime values depending on given ISO string format.
 */
export const dateTimeFromISO = (isoDateTime: string | null | undefined, zone: string) => {
    isoDateTime ??= "";
    // eslint-disable-next-line no-restricted-properties
    const dateTime = DateTime.fromISO(isoDateTime, { zone });
    return dateTime.isValid ? dateTime : null;
};
