import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { SharedModule } from "../shared/shared.module";
import { EditActionComponent } from "./edit-action/edit-action.component";
import { ActionEffects, actionReducer, actionsFeatureKey } from "./store";

@NgModule({
    imports: [CommonModule, SharedModule, RouterModule, ReactiveFormsModule, StoreModule.forFeature(actionsFeatureKey, actionReducer), EffectsModule.forFeature([ActionEffects])],
    declarations: [EditActionComponent],
    exports: [EditActionComponent]
})
export class ActionModule {}
