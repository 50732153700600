import { Router } from "@angular/router";
import { from, Observable } from "rxjs";
import { CoaId } from "./state";
import { LiftingId } from "./state/model/lifting";

export function navigateToLifting(router: Router, coaId: CoaId, liftingId: LiftingId): Observable<boolean> {
    return from(
        router.navigate(
            [
                {
                    outlets: { primary: ["coa", coaId, "lifting", liftingId] }
                }
            ],
            {
                replaceUrl: true,
                queryParamsHandling: "merge"
            }
        )
    );
}
