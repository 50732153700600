import { Injectable } from "@angular/core";
import { CommandHandler } from "../../../mediator/command-handlers/command-handler";
import { Fixture } from "../../../shared/models";
import { RemoveDemurrageClaimCommand } from "./remove-demurrage-claim.command";

@Injectable({
    providedIn: "root"
})
export class RemoveDemurrageClaimCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: RemoveDemurrageClaimCommand) {
        const demurrageClaimId = command.payload;

        if (!demurrageClaimId) {
            throw new Error("Given demurrage claim index is invalid");
        }

        const index = fixture.demurrage.claims.findIndex((claim) => claim.demurrageClaimId === demurrageClaimId);

        fixture.demurrage.claims.splice(index, 1);
    }
}
