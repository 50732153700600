import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { NgbTabChangeEvent, NgbTabset } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";

import { AppInsightsService, FeatureToggleService } from "@ops/core";

import { SimpleChanges } from "../../shared";
import { ExpenseTabComponent } from "../expense-tab/expense-tab.component";
import { Command } from "../mediator/commands/command";
import { DemurrageItemDataService } from "../services/demurrage-item-data.service";
import { DataSourceCommand } from "../shared/datasource-command";
import { Fixture, FixtureSource, Voyage } from "../shared/models";
import { FixtureTabName } from "../shared/tab-validation/tab-validation-info";
import { FixtureWarningService } from "../shared/warnings/fixture-warning.service";
import { FixtureFeatureState, selectCurrentVoyageBusy, selectCurrentVoyageFormInvalid, selectCurrentVoyageReadonly } from "../state";
import { selectCurrentFixtureTabsValidity } from "../state/validation";
import { TabNavigationService } from "./../shared/navigation/tab-navigation.service";
import { FreightInvoicesComponent } from "./invoices-tab/freight-invoices/freight-invoices.component";

@Component({
    selector: "ops-voyage",
    templateUrl: "./voyage.component.html",
    styleUrls: ["./voyage.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoyageComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static componentName = "VoyageComponent";

    private readonly destroy$ = new Subject();

    readonly voyageInvalid$ = this.store.select(selectCurrentVoyageFormInvalid);
    readonly busy$ = this.store.select(selectCurrentVoyageBusy);
    readonly readonly$ = this.store.select(selectCurrentVoyageReadonly);

    tabsValidity: Partial<Record<FixtureTabName, boolean>> = {
        voyageHeader: true,
        cargoTab: true,
        speedAndConsTab: true,
        laytimeTab: true,
        demurrageTab: true,
        expensesTab: true,
        invoicesTab: true
    };

    voyageForm: UntypedFormGroup;

    @Input() parentForm: UntypedFormGroup;
    @Input() fixture$: Observable<Fixture | Voyage>;
    @Input() voyage: Voyage;
    @Input() voyages$: Observable<Voyage[]>;
    @Input() fixtureSave$: Observable<any>;
    @Input() voyageSave$: Observable<any>;
    @Input() fixtureSource: FixtureSource;

    @Output() voyageUpdated = new EventEmitter<DataSourceCommand>();

    @ViewChild("tabset") tabset: NgbTabset;
    @ViewChild(ExpenseTabComponent) expenseTabComponent: ExpenseTabComponent;
    @ViewChild(FreightInvoicesComponent) freightInvoicesComponent: FreightInvoicesComponent;

    constructor(
        private store: Store<FixtureFeatureState>,
        private formBuilder: UntypedFormBuilder,
        private featureToggleService: FeatureToggleService,
        private warningService: FixtureWarningService,
        private tabNavigationService: TabNavigationService,
        private applicationInsights: AppInsightsService,
        private demurrageItemDataService: DemurrageItemDataService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.voyageForm = this.formBuilder.group({});
        this.parentForm.registerControl("voyage", this.voyageForm);
        this.voyageForm[this.parentForm.disabled ? "disable" : "enable"]();

        this.subscribeToTabsValidityChanges();
    }

    ngOnChanges(changes: SimpleChanges<VoyageComponent>) {
        if (changes.voyage) {
            if (changes.voyage.currentValue) {
                this.applicationInsights.setGlobalProperty("voyageNumber", changes.voyage.currentValue.voyageNumberDisplay);
            } else {
                this.applicationInsights.removeGlobalProperty("voyageNumber");
            }
        }
    }

    ngAfterViewInit() {
        const warningPathTabMap = new Map<string, string>([
            ["cargoes", "cargoTab"],
            ["destinations", "cargoTab"],
            ["totals", "cargoTab"],
            ["demurrage", "demurrageTab"],
            ["expenses", "expensesTab"]
        ]);

        this.warningService.currentWarning$.pipe(takeUntil(this.destroy$)).subscribe((warning) => {
            if (!warning) {
                return;
            }

            const mappingSegmentIndex = warning.modelPath[0] === "voyages" ? 2 : 0;
            const tab = warningPathTabMap.get(warning.modelPath[mappingSegmentIndex]);

            if (tab) {
                this.tabset.select(tab);
            }
        });

        this.tabNavigationService.currentTab$.pipe(takeUntil(this.destroy$)).subscribe((tab) => {
            if (tab && tab.tabName) {
                this.tabset.select(tab.tabName);
            }
        });
    }

    ngOnDestroy() {
        delete this.parentForm.controls.voyage;
        this.applicationInsights.removeGlobalProperty("voyageNumber");
        this.destroy$.next();
    }

    subscribeToTabsValidityChanges() {
        this.store
            .select(selectCurrentFixtureTabsValidity)
            .pipe(takeUntil(this.destroy$))
            .subscribe((tabsValidity) => {
                this.tabsValidity = { ...this.tabsValidity, ...tabsValidity };
                this.changeDetectorRef.markForCheck();
            });
    }

    tabChange(event: NgbTabChangeEvent) {
        this.applicationInsights.logPageView(`${VoyageComponent.componentName}#${event.activeId}`);
    }

    handleFixtureUpdateCommand(event: Command): void {
        this.voyageUpdated.emit(new DataSourceCommand(event, "fixture"));
    }

    handleVoyageUpdateCommand(event: Command): void {
        this.voyageUpdated.emit(new DataSourceCommand(event, "voyage", true));
    }

    get isCommissionActive() {
        return this.fixtureSource !== FixtureSource.Ops;
    }

    get numberOfDemurrageClaims(): Observable<number> {
        if (this.featureToggleService.isActive("demurrageItemToggle")) {
            return this.demurrageItemDataService.numberOfDemurrageClaims$;
        } else {
            return this.fixture$.pipe(map((f: any) => (f && f.demurrage && f.demurrage.claims ? f.demurrage.claims.length : 0)));
        }
    }
}
