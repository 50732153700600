import { createSelector } from "@ngrx/store";

import { auditModel } from "../model";
import { selectCurrentVoyage, selectCurrentVoyageExpandedSections } from "../voyage";

export const selectCurrentVoyagePublicNotesExpanded = createSelector(selectCurrentVoyageExpandedSections, (expandedSections) => expandedSections && expandedSections.notes);

export const selectCurrentVoyagePublicNotesAudit = createSelector(
    selectCurrentVoyage,
    (voyage) => voyage && voyage.notes && voyage.notes.dateModified && voyage.notes.modifiedByUser && auditModel(voyage.notes.dateModified, voyage.notes.modifiedByUser)
);
