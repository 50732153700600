import { AbstractControl, ValidatorFn } from "@angular/forms";

export class VesselNameValidator {
    static validate(): ValidatorFn {
        return (control: AbstractControl): null => {
            const vesselControl = control.get("vessel");
            const isVesselTbnControl = control.get("isVesselTbn");

            if (vesselControl && isVesselTbnControl) {
                const vesselName = vesselControl.value;
                const isVesselTbn = isVesselTbnControl.value;

                if (!vesselName && !isVesselTbn) {
                    vesselControl.setErrors({ required: true });
                }
            }

            return null;
        };
    }
}
