import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { AssociatedCargoesByCargo, CargoId } from "../../../state";

@Component({
    selector: "ops-associated-cargoes-by-cargoes",
    templateUrl: "./associated-cargoes-by-cargoes.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociatedCargoesByCargoesComponent {
    @Input() associatedCargoesByCargoes: ReadonlyArray<AssociatedCargoesByCargo>;
    @Output() readonly selectAllByCargo = new EventEmitter<CargoId>();

    trackBy(index: number) {
        return index;
    }
}
