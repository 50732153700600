<ng-container *ngIf="(report$ | async) as report; else loading">
    <ops-profit-and-loss
        *ngIf="!report.invalid?.insufficientData; else insufficientData"
        [report]="report">
    </ops-profit-and-loss>
</ng-container>

<ng-template #loading>
    <ops-load-animation size="x-large" stripeColor="dark" class="loading-animation"></ops-load-animation>
</ng-template>

<ng-template #insufficientData>
    <ops-no-data-panel
        heading="Profit and Loss"
        title="Not enough data has been entered"
        description="To generate Profit and Loss, add Freight and Estimated quantities for at least one cargo.">
        <ops-action-button icon="arrow-forward" (click)="goToCargoTab()" class="no-data">GO TO CARGO TAB</ops-action-button>
    </ops-no-data-panel>
</ng-template>
