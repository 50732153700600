import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { box, setValue, unbox, updateGroup } from "ngrx-forms";
import { combineLatest, of } from "rxjs";
import { catchError, exhaustMap, filter, map, mapTo, mergeMap, withLatestFrom } from "rxjs/operators";

import { dateRangeEquals } from "@ops/shared";
import { selectCurrentUser } from "@ops/state";

import { getChangeReason, userCancelled } from "./change-reason";
import { DateRange } from "../../../../../fixture/shared/models";
import { LiftingHttpService } from "../../../../services";
import { selectCurrentCoaId } from "../../../coa";
import { coaLiftingsStateReducer } from "../../../coa/reducer";
import { ChangeReason, CoaFeatureState, CoaId, CoasState, CoaState, LiftingCargoLaycanForm, LiftingCargoPlanStatus, LiftingId, toUser, User } from "../../../model";
import { currentLiftingStateReducer, liftingStateReducer } from "../../reducer";
import { selectCurrentLifting, selectCurrentLiftingId } from "../../selectors";
import { selectLiftingCargoLaycanForm } from "../selectors";

/* ACTIONS */
const SAVE_LAYCAN_ACTION_NAME = "[Lifting Cargo Tab] Save Laycan";
export const saveLaycanAction = createAction(SAVE_LAYCAN_ACTION_NAME, props<{ changeReason: ChangeReason }>());
export const saveLaycanSuccessAction = createAction(
    `${SAVE_LAYCAN_ACTION_NAME} Success`,
    props<{ coaId: CoaId; liftingId: LiftingId; laycan: DateRange; user: User; changeReason?: ChangeReason }>()
);
export const saveLaycanFailAction = createAction(`${SAVE_LAYCAN_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());
export const savingLaycanAction = createAction(`${SAVE_LAYCAN_ACTION_NAME} Pending`);

const SAVE_CARGO_PLAN_STATUS_ACTION_NAME = "[Lifting Cargo Tab] Save Cargo Plan Status";
export const saveCargoPlanStatusAction = createAction(SAVE_CARGO_PLAN_STATUS_ACTION_NAME);
export const saveCargoPlanStatusSuccessAction = createAction(
    `${SAVE_CARGO_PLAN_STATUS_ACTION_NAME} Success`,
    props<{ coaId: CoaId; liftingId: LiftingId; status: LiftingCargoPlanStatus; user: User }>()
);
export const saveCargoPlanStatusFailAction = createAction(`${SAVE_CARGO_PLAN_STATUS_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());
export const savingCargoPlanStatusAction = createAction(`${SAVE_CARGO_PLAN_STATUS_ACTION_NAME} Pending`);

/* REDUCERS */
export const saveCargoLaycanFormReducer: On<CoasState> = on(saveLaycanAction, saveCargoPlanStatusAction, (state) =>
    currentLiftingStateReducer(state, { liftingCargoLaycanFormSaveStatus: "persisting" })
);

export const saveLaycanSuccessReducer: On<CoasState> = on(saveLaycanSuccessAction, (state, { liftingId, laycan, coaId }) => {
    const withUpdatedLiftingState = liftingStateReducer(state, liftingId, (liftingState) => ({
        ...liftingState,
        lifting: {
            ...liftingState.lifting,
            cargoLaycan: laycan
        },
        liftingCargoLaycanFormSaveStatus: "persisted"
    }));

    const getLiftings = (coaState: CoaState) => coaState.fetchedLiftings.map((l) => (l.documentId !== liftingId ? l : { ...l, cargoLaycan: laycan }));

    return coaLiftingsStateReducer(withUpdatedLiftingState, coaId, getLiftings);
});

export const saveCargoPlanStatusSuccessReducer: On<CoasState> = on(saveCargoPlanStatusSuccessAction, (state, { coaId, liftingId, status }) => {
    const withUpdatedLiftingState = liftingStateReducer(state, liftingId, (liftingState) => ({
        ...liftingState,
        lifting: { ...liftingState.lifting, cargoPlanStatus: status },
        liftingCargoLaycanFormSaveStatus: "persisted"
    }));

    const getLiftings = (coaState: CoaState) => coaState.fetchedLiftings.map((l) => (l.documentId !== liftingId ? l : { ...l, cargoPlanStatus: status }));

    return coaLiftingsStateReducer(withUpdatedLiftingState, coaId, getLiftings);
});

export const saveLaycanFailReducer: On<CoasState> = on(saveLaycanFailAction, (state, { liftingId, error }) =>
    liftingStateReducer(state, liftingId, (liftingState) => ({
        ...liftingState,
        liftingCargoLaycanForm: updateGroup<LiftingCargoLaycanForm>({ laycan: setValue(box(liftingState.lifting.cargoLaycan)) })(liftingState.liftingCargoLaycanForm),
        liftingCargoLaycanFormSaveStatus: "failed",
        erroredItems: [...(liftingState.erroredItems || []), { itemType: "cargoLaycan", id: liftingId, error }]
    }))
);

export const saveCargoPlanStatusFailReducer: On<CoasState> = on(saveCargoPlanStatusFailAction, (state, { liftingId, error }) =>
    liftingStateReducer(state, liftingId, (liftingState) => ({
        ...liftingState,
        liftingCargoLaycanForm: updateGroup<LiftingCargoLaycanForm>({ cargoPlanStatus: setValue(<string>liftingState.lifting.cargoPlanStatus) })(
            liftingState.liftingCargoLaycanForm
        ),
        liftingCargoLaycanFormSaveStatus: "failed",
        erroredItems: [...(liftingState.erroredItems || []), { itemType: "cargoPlanStatus", id: liftingId, error }]
    }))
);

/* EFFECTS */
export const saveLaycanEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveLaycanAction),
            withLatestFrom(store.select(selectCurrentCoaId), store.select(selectCurrentLiftingId), store.select(selectLiftingCargoLaycanForm), store.select(selectCurrentUser)),
            mergeMap(([{ changeReason }, coaId, liftingId, form, user]) =>
                liftingService.updateLiftingCargoLaycan(coaId, liftingId, { cargoLaycan: unbox(form.value.laycan), changeReason }).pipe(
                    map(() => saveLaycanSuccessAction({ coaId, liftingId, laycan: unbox(form.value.laycan), user: toUser(user), changeReason })),
                    catchError((error) => of(saveLaycanFailAction({ liftingId, error })))
                )
            )
        )
    );

export const saveCargoPlanStatusEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveCargoPlanStatusAction),
            withLatestFrom(store.select(selectCurrentCoaId), store.select(selectCurrentLiftingId), store.select(selectLiftingCargoLaycanForm), store.select(selectCurrentUser)),
            mergeMap(([, coaId, liftingId, form, user]) =>
                liftingService.updateLiftingCargoPlanStatus(coaId, liftingId, form.value.cargoPlanStatus).pipe(
                    map(() => saveCargoPlanStatusSuccessAction({ coaId, liftingId, status: form.value.cargoPlanStatus, user: toUser(user) })),
                    catchError((error) => of(saveCargoPlanStatusFailAction({ liftingId, error })))
                )
            )
        )
    );

export const savingLaycanEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, dialog: MatDialog) =>
    createEffect(() =>
        actions$.pipe(
            ofType(savingLaycanAction),
            withLatestFrom(store.select(selectLiftingCargoLaycanForm), store.select(selectCurrentLifting)),
            filter(([, form, { cargoLaycan }]) => form.controls.laycan.isValid && !dateRangeEquals(unbox(form.value.laycan), cargoLaycan)),
            exhaustMap(([, , { liftingId, cargoPlanStatus }]) => combineLatest([getChangeReason(cargoPlanStatus, dialog, "Update Cargo Laycan"), of(liftingId)])),
            map(([changeReason, liftingId]) => (changeReason !== undefined ? saveLaycanAction({ changeReason }) : saveLaycanFailAction({ liftingId, error: userCancelled() })))
        )
    );

export const savingCargoPlanStatusEffect$ = (actions$: Actions, store: Store<CoaFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType(savingCargoPlanStatusAction),
            withLatestFrom(store.select(selectLiftingCargoLaycanForm)),
            filter(([, form]) => form.controls.cargoPlanStatus.isValid),
            mapTo(saveCargoPlanStatusAction())
        )
    );
