import { Duration, Interval } from "luxon";

import { hasValue, zeroDuration, isNullOrUndefined } from "@ops/shared";

import { diffInOffsets } from "../../../shared/utils";
import { intersectionDuration } from "../../../shared/utils/interval-utils";
import { ActivityCargoId, LaytimeEventId, Reversible } from "../../state";
import { ActivityLocation, LaytimeEvent } from "../laytime-calculation";

export type ActivityLocationTimeCounted = Readonly<{
    cargoes?: ReadonlyArray<ActivityCargoTimeCounted>;
    laytimeEvents: ReadonlyArray<LaytimeEventTimeCounted>;

    /**
     * The total non reversible (not shared) laytime used for this activity location.
     */
    nonReversibleTimeUsed?: Duration;
    /**
     * The total reversible (shared) laytime used for this activity location.
     */
    reversibleTimeUsed?: Duration;
    /**
     * The total non reversible (not shared) laytime excluded for activity location.
     */
    nonReversibleTimeExcluded?: Duration;
    /**
     * The total reversible (shared) laytime excluded for this activity location.
     */
    reversibleTimeExcluded?: Duration;
    /**
     * Indicates whether any non reversible time has been counted.
     * `nonReversibleTimeUsed` and `nonReversibleTimeExcluded` will not be set when false.
     * Not applicable when cargoes is null (fixed calculations).
     */
    hasNonReversible?: boolean;
    /**
     * Indicates whether any reversible time has been counted.
     * `reversibleTimeUsed` and `reversibleTimeExcluded` will not be set when false.
     * Not applicable when cargoes is null (fixed calculations).
     */
    hasReversible?: boolean;
}>;

export type ActivityCargoTimeCounted = Readonly<{
    id: ActivityCargoId;
    timeUsed: Duration;
    timeExcluded: Duration;
}>;
const activityCargoTimeCounted = (id: ActivityCargoId, timeUsed?: Duration, timeExcluded?: Duration): ActivityCargoTimeCounted => ({
    id,
    timeUsed: timeUsed ?? zeroDuration(),
    timeExcluded: timeExcluded ?? zeroDuration()
});

export type LaytimeEventTimeCounted = Readonly<{
    id: LaytimeEventId;
    timeUsed?: Duration;
    timeExcluded?: Duration;
    timeAllowed?: Duration;
    nonReversibleTimeUsed?: Duration;
    reversibleTimeUsed?: Duration;
    nonReversibleTimeExcluded?: Duration;
    reversibleTimeExcluded?: Duration;
    cumulativeNonReversibleTimeUsed: Duration;
    cumulativeReversibleTimeUsed: Duration;
    cumulativeNonReversibleTimeExcluded: Duration;
    cumulativeReversibleTimeExcluded: Duration;
    offsetDiffWithPrevEvent?: Duration;
}>;

export const getTimeCounted = (location: ActivityLocation): ActivityLocationTimeCounted | null => {
    const cargoes = location.cargoes;
    const laytimeEvents = location.laytimeEvents;
    const hasReversibleCargoes = locationHasReversibleCargoes(location) || location.activity === "Transit";
    const hasNonReversibleCargoes = (!cargoes.length || locationHasNonReversibleCargoes(location)) && location.activity !== "Transit";

    let cumulativeNonReversibleTimeUsed = zeroDuration();
    let cumulativeReversibleTimeUsed = zeroDuration();
    let cumulativeNonReversibleTimeExcluded = zeroDuration();
    let cumulativeReversibleTimeExcluded = zeroDuration();
    const cargoesTimeCounted = new Map<string, ActivityCargoTimeCounted>(location.cargoes.map((x) => [x.id, activityCargoTimeCounted(x.id)]));
    const laytimeEventsTimeCounted = new Array<LaytimeEventTimeCounted>();

    for (let i = 0; i < laytimeEvents.length - 1; i++) {
        const prev = laytimeEvents[i];
        const curr = laytimeEvents[i + 1];
        const timeCountedResult = getLaytimeEventTimeCounted(location, prev, curr);

        // timeCountedResult is null if the laytime event is out of order / dates missing
        // in which case we return null for the laytime event
        if (!timeCountedResult) {
            const laytimeEventTimeCounted: LaytimeEventTimeCounted = {
                id: curr.id,
                timeUsed: null,
                timeExcluded: null,
                nonReversibleTimeUsed: null,
                reversibleTimeUsed: null,
                nonReversibleTimeExcluded: null,
                reversibleTimeExcluded: null,
                cumulativeNonReversibleTimeUsed,
                cumulativeReversibleTimeUsed,
                cumulativeNonReversibleTimeExcluded,
                cumulativeReversibleTimeExcluded,
                offsetDiffWithPrevEvent: Duration.fromMillis(0)
            };

            laytimeEventsTimeCounted.push(laytimeEventTimeCounted);

            continue;
        }

        const { timeUsed, timeExcluded, timeAllowed } = timeCountedResult;

        let nonReversibleTimeUsed: Duration = null;
        let reversibleTimeUsed: Duration = null;
        let nonReversibleTimeExcluded: Duration = null;
        let reversibleTimeExcluded: Duration = null;

        const cargo = prev.cargoId && curr.cargoId && prev.cargoId === curr.cargoId ? cargoes.find((x) => x.id === curr.cargoId) : null;

        if (cargo) {
            if (cargo.reversible === "Reversible") {
                // Count only as reversible
                reversibleTimeUsed = timeUsed;
                reversibleTimeExcluded = timeExcluded;
            } else {
                // Count against location non-reversible
                nonReversibleTimeUsed = timeUsed;
                nonReversibleTimeExcluded = timeExcluded;
            }

            const cargoTimeCounted = cargoesTimeCounted.get(cargo.id);
            cargoesTimeCounted.set(cargo.id, {
                ...cargoTimeCounted,
                timeUsed: cargoTimeCounted.timeUsed.plus(timeUsed),
                timeExcluded: cargoTimeCounted.timeExcluded.plus(timeExcluded)
            });
        } else {
            // Only count if we have a reversible or non-reversible cargo, but we can count against both
            if (hasReversibleCargoes) {
                reversibleTimeUsed = timeUsed;
                reversibleTimeExcluded = timeExcluded;
            }

            if (hasNonReversibleCargoes) {
                nonReversibleTimeUsed = timeUsed;
                nonReversibleTimeExcluded = timeExcluded;
            }

            for (const [cargoId, cargoTimeCounted] of cargoesTimeCounted.entries()) {
                cargoesTimeCounted.set(cargoId, {
                    ...cargoTimeCounted,
                    timeUsed: cargoTimeCounted.timeUsed.plus(timeUsed),
                    timeExcluded: cargoTimeCounted.timeExcluded.plus(timeExcluded)
                });
            }
        }

        cumulativeReversibleTimeUsed = hasValue(reversibleTimeUsed) ? cumulativeReversibleTimeUsed.plus(reversibleTimeUsed) : cumulativeReversibleTimeUsed;
        cumulativeReversibleTimeExcluded = hasValue(reversibleTimeExcluded) ? cumulativeReversibleTimeExcluded.plus(reversibleTimeExcluded) : cumulativeReversibleTimeExcluded;
        cumulativeNonReversibleTimeUsed = hasValue(nonReversibleTimeUsed) ? cumulativeNonReversibleTimeUsed.plus(nonReversibleTimeUsed) : cumulativeNonReversibleTimeUsed;
        cumulativeNonReversibleTimeExcluded = hasValue(nonReversibleTimeExcluded)
            ? cumulativeNonReversibleTimeExcluded.plus(nonReversibleTimeExcluded)
            : cumulativeNonReversibleTimeExcluded;

        const laytimeEventTimeCounted: LaytimeEventTimeCounted = {
            id: curr.id,
            timeUsed,
            timeExcluded,
            timeAllowed,
            nonReversibleTimeUsed,
            reversibleTimeUsed,
            nonReversibleTimeExcluded,
            reversibleTimeExcluded,
            cumulativeNonReversibleTimeUsed,
            cumulativeReversibleTimeUsed,
            cumulativeNonReversibleTimeExcluded,
            cumulativeReversibleTimeExcluded,
            offsetDiffWithPrevEvent: diffInOffsets(prev.date, curr.date) || zeroDuration()
        };

        laytimeEventsTimeCounted.push(laytimeEventTimeCounted);
    }

    return {
        cargoes: Array.from(cargoesTimeCounted.values()),
        laytimeEvents: laytimeEventsTimeCounted,
        nonReversibleTimeUsed: hasNonReversibleCargoes ? cumulativeNonReversibleTimeUsed : null,
        reversibleTimeUsed: hasReversibleCargoes ? cumulativeReversibleTimeUsed : null,
        nonReversibleTimeExcluded: hasNonReversibleCargoes ? cumulativeNonReversibleTimeExcluded : null,
        reversibleTimeExcluded: hasReversibleCargoes ? cumulativeReversibleTimeExcluded : null,
        hasNonReversible: hasNonReversibleCargoes,
        hasReversible: hasReversibleCargoes
    };
};

const getLaytimeEventTimeCounted = (location: ActivityLocation, prev: LaytimeEvent, curr: LaytimeEvent): { timeUsed: Duration; timeExcluded: Duration; timeAllowed?: Duration } => {
    if (!prev.date || !curr.date) {
        return { timeUsed: zeroDuration(), timeExcluded: zeroDuration(), timeAllowed: zeroDuration() };
    }
    if (+prev.date > +curr.date) {
        return null;
    }

    const start = prev.date.setZone(location.timeZone, { keepLocalTime: true });
    const end = curr.date.setZone(location.timeZone, { keepLocalTime: true });
    const interval = Interval.fromDateTimes(start, end);
    const duration = interval.toDuration();
    const percentage = (curr.percentage ?? 100) / 100;
    const timeCounted = duration.mapUnits((x) => x * percentage);
    const timeAllowed = duration;

    if (hasValue(location.exclusionStartDay) && hasValue(location.exclusionStartTime) && hasValue(location.exclusionEndDay) && hasValue(location.exclusionEndTime)) {
        const { exclusionStartDay, exclusionStartTime, exclusionEndDay, exclusionEndTime } = location;
        const timeExcluded = intersectionDuration(interval, { exclusionStartDay, exclusionStartTime, exclusionEndDay, exclusionEndTime });

        const timeUsed = isNullOrUndefined(curr.percentage) ? timeCounted.minus(timeExcluded) : timeCounted;

        return { timeUsed, timeExcluded, timeAllowed };
    }

    return { timeUsed: timeCounted, timeExcluded: zeroDuration(), timeAllowed };
};

const locationHasReversibleCargoesFn = (reversible: Reversible) => (location: ActivityLocation): boolean => location.cargoes.some((x) => x.reversible === reversible);

const locationHasReversibleCargoes = locationHasReversibleCargoesFn("Reversible");
const locationHasNonReversibleCargoes = locationHasReversibleCargoesFn("Non Reversible");
