import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { combineLatest, of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { getChangeReason, userCancelled } from "./change-reason";
import { selectCurrentUser } from "../../../../../state";
import { LiftingHttpService } from "../../../../services/lifting-http.service";
import { selectCurrentCoaId } from "../../../coa";
import { coaLiftingsStateReducer } from "../../../coa/reducer";
import { CargoId, ChangeReason, CoaFeatureState, CoaId, CoasState, CoaState, getCurrentLiftingState, LiftingId, toUser, User } from "../../../model";
import { liftingStateReducer } from "../../reducer";
import { selectCurrentLifting, selectCurrentLiftingId } from "../../selectors";

/* ACTIONS */
const REMOVE_CARGO_ACTION_NAME = "[Lifting Cargo Form] Remove Cargo";
export const removeCargoAction = createAction(REMOVE_CARGO_ACTION_NAME, props<{ cargoId: CargoId; changeReason: ChangeReason }>());
export const removeCargoSuccessAction = createAction(
    `${REMOVE_CARGO_ACTION_NAME} Success`,
    props<{ coaId: CoaId; liftingId: LiftingId; cargoId: CargoId; user: User; changeReason?: ChangeReason }>()
);
export const removeCargoFailAction = createAction(`${REMOVE_CARGO_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; cargoId: CargoId; error: Error }>());
export const removingCargoAction = createAction(`${REMOVE_CARGO_ACTION_NAME} Pending`, props<{ cargoId: CargoId }>());

/* REDUCERS */
export const removeCargoSuccessReducer: On<CoasState> = on(removeCargoSuccessAction, (state, { coaId, liftingId, cargoId }) => {
    const currentLifting = getCurrentLiftingState(state);
    const cargo = currentLifting.lifting.cargoes.find((c) => c.cargoId === cargoId);

    if (cargo) {
        const withUpdatedLiftingState = liftingStateReducer(state, liftingId, (liftingState) => ({
            ...liftingState,
            lifting: {
                ...liftingState.lifting,
                cargoes: currentLifting.lifting.cargoes.filter((c) => c.cargoId !== cargoId)
            }
        }));

        const getLiftings = (coaState: CoaState) =>
            coaState.fetchedLiftings.map((l) => (l.documentId !== liftingId ? l : { ...l, cargoes: withUpdatedLiftingState.liftings.byId[liftingId].lifting.cargoes }));

        return coaLiftingsStateReducer(withUpdatedLiftingState, coaId, getLiftings);
    }

    return state;
});

export const removeCargoFailReducer: On<CoasState> = on(removeCargoFailAction, (state, { liftingId, cargoId, error }) => {
    const currentLifting = getCurrentLiftingState(state);
    const cargo = currentLifting.lifting.cargoes.find((c) => c.cargoId === cargoId);

    return !cargo
        ? state
        : liftingStateReducer(state, liftingId, (liftingState) => ({
              ...liftingState,
              erroredItems: [...(liftingState.erroredItems || []), { itemType: "cargoes", id: cargoId, error }]
          }));
});

/* EFFECTS */
export const removeLiftingCargoEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeCargoAction),
            withLatestFrom(store.select(selectCurrentCoaId), store.select(selectCurrentLiftingId), store.select(selectCurrentUser)),
            mergeMap(([{ cargoId, changeReason }, coaId, liftingId, user]) =>
                liftingService.removeCargo(coaId, liftingId, cargoId, { changeReason }).pipe(
                    map(() => removeCargoSuccessAction({ coaId, liftingId, cargoId, user: toUser(user), changeReason })),
                    catchError((error) => of(removeCargoFailAction({ liftingId, cargoId, error })))
                )
            )
        )
    );

export const removingLiftingCargoEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, dialog: MatDialog) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removingCargoAction),
            withLatestFrom(store.select(selectCurrentLifting)),
            exhaustMap(([{ cargoId }, { cargoPlanStatus, liftingId }]) => combineLatest([getChangeReason(cargoPlanStatus, dialog, "Delete Cargo"), of({ cargoId, liftingId })])),
            map(([changeReason, { cargoId, liftingId }]) =>
                changeReason !== undefined ? removeCargoAction({ cargoId, changeReason }) : removeCargoFailAction({ cargoId, liftingId, error: userCancelled() })
            )
        )
    );
