import { MaritimeDate } from "@maritech/maritime-date";
import { DateTime } from "luxon";

import { toDateTime } from "@ops/shared";

import { FixtureIndex } from "../model";

export type FixtureHeader = Readonly<{
    vesselName: string;
    vesselUrl: string;
    fixtureReference: string;
    description: string;
    owner: string;
    charterer: string;
    fixtureId: string;
    voyageId: string;
}>;

const getVesselSeaNetUrl = (vesselCvn: number, charterpartyDate: string, seaNetUrl: string) => {
    if (!vesselCvn || !charterpartyDate || !seaNetUrl) {
        return null;
    }

    const dateFormat = "yyyyMMdd";
    const today = DateTime.local().toFormat(dateFormat);
    const formattedCharterPartyDate = toDateTime(charterpartyDate).toFormat(dateFormat);

    return `${seaNetUrl}/voyage/${vesselCvn}/${formattedCharterPartyDate}-${today}/false/false/false`;
};

export const getFixtureHeader = (fixture: FixtureIndex, seaNetUrl: string): FixtureHeader => ({
    vesselName: fixture.vesselName,
    vesselUrl: getVesselSeaNetUrl(fixture.vesselCvn, fixture.charterpartyDate, seaNetUrl),
    fixtureReference: fixture.fixtureNumber,
    fixtureId: fixture.fixtureId,
    voyageId: fixture.voyageId,
    description: [
        toDateTime(fixture.charterpartyDate)?.toLocaleString(MaritimeDate.DATE_FORMAT),
        fixture.currency?.code,
        fixture.brokers.map((x) => x.code).join(", "),
        fixture.blDate ? `B/L Date: ${toDateTime(fixture.blDate.civilDate).toLocaleString(MaritimeDate.DATE_FORMAT)}` : null,
        fixture.coANumber
    ]
        .filter((x) => !!x)
        .join(" | "),
    owner: fixture.owner,
    charterer: fixture.charterer
});
