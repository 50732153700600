import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";

import { Null, update } from "@ops/shared";
import { persisted, selectCurrentUser } from "@ops/state";

import { formToSaveVesselNomination, LiftingHttpService, RenominateNewVessel } from "../../../../services/lifting-http.service";
import { coaLiftingsStateReducer } from "../../../coa/reducer";
import {
    CoaFeatureState,
    CoaId,
    CoasState,
    CoaState,
    formToVesselNomination,
    isPreferred,
    LiftingId,
    setAccepted,
    setRenominationPending,
    setUnderReview,
    toUser,
    User,
    vesselIdEq,
    VesselNomination
} from "../../../model";
import { currentLiftingStateReducer, liftingStateReducer } from "../../reducer";
import { selectCurrentLifting } from "../../selectors";
import { selectCurrentVesselNominationFormValue } from "../selectors";

/* ACTIONS */
const SAVE_ACTION_NAME = "[Vessel Nomination Form] Save Vessel Nomination";
export const saveVesselNominationAction = createAction(SAVE_ACTION_NAME);
export const saveVesselNominationSuccessAction = createAction(
    `${SAVE_ACTION_NAME} Success`,
    props<{ coaId: CoaId; liftingId: LiftingId; vessel: Partial<VesselNomination>; user: User }>()
);
export const saveVesselNominationFailAction = createAction(`${SAVE_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

const UPDATE_ACTION_NAME = "[Vessel Nomination Form] Update Vessel Nomination";
export const updateVesselNominationAction = createAction(UPDATE_ACTION_NAME);
export const updateVesselNominationSuccessAction = createAction(
    `${UPDATE_ACTION_NAME} Success`,
    props<{ coaId: CoaId; liftingId: LiftingId; vessel: Partial<VesselNomination>; user: User }>()
);
export const updateVesselNominationFailAction = createAction(`${UPDATE_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

const RENOMINATE_NEW_ACTION_NAME = "[Vessel Nomination Form] Renominate New Vessel";
export const renominateNewVesselAction = createAction(RENOMINATE_NEW_ACTION_NAME, props<{ reason: string }>());
export const renominateNewVesselSuccessAction = createAction(
    `${RENOMINATE_NEW_ACTION_NAME} Success`,
    props<{ coaId: CoaId; liftingId: LiftingId; vessel: Partial<VesselNomination>; user: User; reason: string }>()
);
export const renominateNewVesselFailAction = createAction(`${RENOMINATE_NEW_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

/* REDUCERS */
export const saveVesselNominationReducer: On<CoasState> = on(saveVesselNominationAction, updateVesselNominationAction, renominateNewVesselAction, (state) =>
    currentLiftingStateReducer(state, { vesselNominationFormSaveStatus: "persisting" })
);

export const saveVesselNominationSuccessReducer: On<CoasState> = on(
    saveVesselNominationSuccessAction,
    updateVesselNominationSuccessAction,
    (state, { type, vessel, coaId, liftingId }) => {
        const liftingVessel = state.liftings.byId[liftingId].lifting?.vessels.find((v) => v.vesselId === vessel.vesselId);
        const shouldChangeToUnderReview =
            !vessel.laycan && ["Accepted", "Preferred"].includes(liftingVessel?.vesselNominationStatus) && state.coas.byId[coaId].coa?.driver === "Vessel";
        const updateFns: Evolver = {
            vesselNominationFormSaveStatus: persisted,
            vesselNominationForm: Null,
            lifting: {
                vessels:
                    type === saveVesselNominationSuccessAction.type
                        ? R.append(setUnderReview(vessel))
                        : update(vesselIdEq(vessel.vesselId), shouldChangeToUnderReview ? setUnderReview(vessel) : vessel)
            }
        };
        const withUpdatedLiftingState = liftingStateReducer(state, liftingId, R.evolve(updateFns));

        const getLiftings = (coaState: CoaState) =>
            coaState.fetchedLiftings.map((l) =>
                l.documentId !== liftingId ? l : { ...l, vessels: l.vessels.map((v) => (v.vesselId !== vessel.vesselId ? v : { ...v, laycan: vessel.laycan })) }
            );

        return coaLiftingsStateReducer(withUpdatedLiftingState, coaId, getLiftings);
    }
);

export const renominateNewVesselSuccessReducer: On<CoasState> = on(renominateNewVesselSuccessAction, (state, { vessel, liftingId }) => {
    const updateFns: Evolver = {
        vesselNominationFormSaveStatus: persisted,
        vesselNominationForm: Null,
        lifting: {
            vessels: R.pipe<VesselNomination, VesselNomination[], VesselNomination[]>(update(isPreferred, setAccepted), R.append(setRenominationPending(vessel)))
        }
    };
    return liftingStateReducer(state, liftingId, R.evolve(updateFns));
});

export const saveVesselNominationFailReducer: On<CoasState> = on(
    saveVesselNominationFailAction,
    updateVesselNominationFailAction,
    renominateNewVesselFailAction,
    (state, { liftingId }) => liftingStateReducer(state, liftingId, { vesselNominationFormSaveStatus: "failed" })
);

/* EFFECTS */
export const saveVesselNominationEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveVesselNominationAction, updateVesselNominationAction),
            withLatestFrom(store.select(selectCurrentLifting), store.select(selectCurrentVesselNominationFormValue), store.select(selectCurrentUser)),
            switchMap(([{ type }, { coaId, liftingId }, form, appUser]) => {
                const successAction = type === saveVesselNominationAction.type ? saveVesselNominationSuccessAction : updateVesselNominationSuccessAction;
                const failAction = type === saveVesselNominationAction.type ? saveVesselNominationFailAction : updateVesselNominationFailAction;
                let request = type === saveVesselNominationAction.type ? liftingHttpService.addVessel : liftingHttpService.updateVessel;
                request = request.bind(liftingHttpService);

                return request(coaId, liftingId, formToSaveVesselNomination(form)).pipe(
                    map(() => successAction({ coaId, liftingId, vessel: formToVesselNomination(form), user: toUser(appUser) })),
                    catchError((error) => of(failAction({ liftingId, error })))
                );
            })
        )
    );

export const renominateNewVesselEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(renominateNewVesselAction),
            withLatestFrom(store.select(selectCurrentLifting), store.select(selectCurrentVesselNominationFormValue), store.select(selectCurrentUser)),
            switchMap(([{ reason }, { coaId, liftingId }, form, appUser]) => {
                const vessel: RenominateNewVessel = { vessel: formToSaveVesselNomination(form), reason };
                return liftingHttpService.renominateNewVessel(coaId, liftingId, vessel).pipe(
                    map(() => renominateNewVesselSuccessAction({ coaId, liftingId, vessel: formToVesselNomination(form), user: toUser(appUser), reason })),
                    catchError((error) => of(renominateNewVesselFailAction({ liftingId, error })))
                );
            })
        )
    );
