import { ColumnState } from "@ag-grid-community/core/dist/cjs/columns/columnModel";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { auditTime, filter, mergeMap, tap } from "rxjs/operators";

import { createRequestId, SearchToken, Suggestion, SuggestionTerm, toColumnDefinition } from "@ops/state";

import { GridRecordNumbers, ISearchService } from "../../shared/grid";
import { removeCoaGridRequest, searchCoaGrid, updateCoaGridColumns, updateCoaGridSearchTokens, updateCoaGridSorting, updateCoaGridSuggestionTerm } from "../state/grid/actions";
import { CoaGridRow } from "../state/grid/grid-row";
import { selectCoaGridCriteria, selectCoaGridPage, selectCoaGridRecordNumbers, selectCoaSuggestionItems, selectCoaSuggestionsLoading } from "../state/grid/selectors";
import { CoaFeatureState } from "../state/model/state";

@Injectable({
    providedIn: "root"
})
export class CoaSearchService implements ISearchService<CoaGridRow> {
    private columnState$ = new BehaviorSubject<ColumnState[]>(null);

    readonly criteria$: Observable<ReadonlyArray<SearchToken>>;
    readonly gridRecordNumbers$: Observable<GridRecordNumbers>;
    readonly suggestions$: Observable<ReadonlyArray<Suggestion>>;
    readonly suggestionsLoading$: Observable<boolean>;

    constructor(private store: Store<CoaFeatureState>) {
        this.criteria$ = this.store.select(selectCoaGridCriteria);
        this.gridRecordNumbers$ = this.store.select(selectCoaGridRecordNumbers).pipe(filter((x) => !!x));
        this.suggestionsLoading$ = this.store.select(selectCoaSuggestionsLoading);
        this.suggestions$ = this.store.select(selectCoaSuggestionItems);

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.columnState$
            .pipe(
                filter((columnState) => !!columnState),
                auditTime(300)
            )
            .subscribe((columnState) => {
                this.store.dispatch(updateCoaGridColumns({ columns: columnState.map(toColumnDefinition) }));
            });
    }

    updateSuggestionTerm(suggestionTerm: SuggestionTerm) {
        this.store.dispatch(updateCoaGridSuggestionTerm({ suggestionTerm }));
    }

    updateSearchTokens(suggestions: ReadonlyArray<Suggestion>) {
        this.store.dispatch(updateCoaGridSearchTokens({ searchTokens: suggestions }));
    }

    updateSorting(sortColumn: string, sortOrder: string) {
        this.store.dispatch(updateCoaGridSorting({ sorting: { column: sortColumn, order: sortOrder } }));
    }

    updateColumns(columnState: ColumnState[]) {
        this.columnState$.next(columnState);
    }

    search(skip: number, take: number): Observable<ReadonlyArray<CoaGridRow>> {
        const requestId = createRequestId();

        this.store.dispatch(searchCoaGrid({ requestId, skip, take }));
        return this.store.select(selectCoaGridPage, { requestId }).pipe(
            filter((x) => !!x),
            tap(() => this.store.dispatch(removeCoaGridRequest({ requestId }))),
            mergeMap((x) => (x.loadStatus === "failed" ? throwError(x.error) : of(x.items)))
        );
    }
}
