import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AppConfigService } from "src/app/core";
import { CoAFixtureSummary } from "src/app/fixture/shared/models/dtos/coa-fixture-summary.dto";

@Injectable({
    providedIn: "root"
})
export class CoAHttpService {
    private fixtureServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.fixtureServiceUrl = appConfigService.config.fixtureServiceUrl;
    }

    get(coANumber: string): Observable<CoAFixtureSummary[]> {
        const encodedCoANumber = encodeURIComponent(coANumber);
        return this.httpClient.get<CoAFixtureSummary[]>(`${this.fixtureServiceUrl}/api/v1.0/fixture/coa?coanumber=${encodedCoANumber}`);
    }
}
