import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { AppConfigService } from "@ops/core";
import { Division, Enumeration, FixtureTemplateType, ReferenceDataService } from "@ops/shared/reference-data";

import { FixtureCreateService } from "../../fixture/services/fixture-create.service";
import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { EmailFactoryService } from "../../fixture/shared/email/email-factory.service";
import { Fixture } from "../../fixture/shared/models";
import { CompanySearchResult } from "../../fixture/shared/models/common/company-search-result.model";
import { ValidationTextFns } from "../../shared/components/validation/validation.component";
import { parseISODate } from "../../shared/date-utils/date-utilities";
import { Email } from "../../shared/email";
import { RangeValidator } from "../../shared/validators/range.validator";
import { ContactService } from "../contacts/contact.service";
import { Company } from "../contacts/models/company.model";
import { LeftBarStateService } from "../left-bar-state.service";
import { SharingGroupsViewModel } from "../shared/models/sharing-group.model";
import { SharingGroupsService } from "../shared/sharing-groups.service";
import { FixtureCreationMapper } from "./mappers/fixture-creation.mapper";
import { FixtureCreationFormModel } from "./shared/create-fixture.model";
import { CoAReferenceValidator } from "./validators/coa-reference-validator";
import { SharingGroupsValidator } from "./validators/sharing-groups-validator";
import { VesselNameValidator } from "./validators/vessel-name-validator";

@Component({
    selector: "ops-create-fixture",
    templateUrl: "./create-fixture.component.html",
    styleUrls: ["./create-fixture.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFixtureComponent implements OnInit, OnDestroy {
    private vesselRequestEmail: string;

    readonly destroy$ = new Subject<boolean>();
    readonly destroyFormSubscriptions$ = new Subject<boolean>();
    readonly sharingGroupsValidationFns: ValidationTextFns = {
        required: () => "At least one of your sharing groups must be selected"
    };
    readonly vessselNameValidationFns: ValidationTextFns = {
        required: () => "Required, or select TBN"
    };

    fixtureSectors$: BehaviorSubject<Enumeration[]> = new BehaviorSubject<Enumeration[]>([]);
    companyRoles$: Observable<Enumeration[]>;
    fixtureCreationForm: UntypedFormGroup;
    fixture: FixtureCreationFormModel;
    email: Email;
    companyRoles: Enumeration[];
    animatingCreateButton = false;
    fixtureTypes: Enumeration[] = [
        FixtureTemplateType.VoyageSpotType,
        FixtureTemplateType.VoyageCoAType,
        FixtureTemplateType.TimeCharterPeriodType,
        FixtureTemplateType.TimeCharterTripType
    ];

    constructor(
        private formBuilder: UntypedFormBuilder,
        public referenceDataService: ReferenceDataService,
        private emailFactoryService: EmailFactoryService,
        private sharingGroupsService: SharingGroupsService,
        private fixtureCreateService: FixtureCreateService,
        private fixtureCreationMapper: FixtureCreationMapper,
        private contactService: ContactService,
        private fixtureDataService: FixtureDataService,
        private router: Router,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef,
        private leftBarStateService: LeftBarStateService,
        appConfigService: AppConfigService
    ) {
        this.vesselRequestEmail = appConfigService.config.vesselRequestEmail;
    }

    ngOnInit(): void {
        this.createForm();

        this.referenceDataService
            .getFixtureSectors()
            .pipe(takeUntil(this.destroy$))
            .subscribe((fixtureSectors: Readonly<Division>[]) => {
                this.fixtureSectors$.next(fixtureSectors);
            });

        this.companyRoles$ = this.referenceDataService
            .getCompanyRoles()
            .pipe(takeUntil(this.destroy$))
            .subscribe((roles: Enumeration[]) => {
                this.companyRoles = roles;
                this.companyRoles.sort((a, b) => a.name.localeCompare(b.name));
            });

        this.route.url.pipe(takeUntil(this.destroy$)).subscribe(() => {
            const { firstChild } = this.route.snapshot;
            if (firstChild && firstChild.routeConfig.path === "coa") {
                this.prePopulatedDataForVoyageCoA();
            } else {
                this.sharingGroupsService.sharingGroups$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
                    this.setSharingGroups(data.sharingGroups);
                });

                this.fixture.fixtureType = FixtureTemplateType.TimeCharterPeriodType;
            }
        });
    }

    ngOnDestroy(): void {
        this.destroyFormSubscriptions$.next(true);
        this.destroy$.next(true);
    }

    get isCoANumberRequired(): boolean {
        return this.fixture.fixtureType.id === FixtureTemplateType.VoyageCoAType.id;
    }

    get sharingGroupsFormArray(): UntypedFormArray {
        return this.fixtureCreationForm.get("sharingGroups") as UntypedFormArray;
    }

    get noSharingGroups(): boolean {
        return this.sharingGroupsFormArray.length === 0;
    }

    removeIsVesselTbn(): void {
        const vessel = this.fixtureCreationForm.controls["vessel"].value;
        if (vessel) {
            this.fixtureCreationForm.patchValue({ isVesselTbn: false });
        }
    }

    removeVessel(): void {
        const isVesselTbn = this.fixtureCreationForm.controls["isVesselTbn"].value;
        if (isVesselTbn) {
            this.fixtureCreationForm.patchValue({ vessel: null });
        }
    }

    enableCoaReferenceNumber(): void {
        if (this.isCoANumberRequired) {
            this.fixtureCreationForm.controls["coANumber"].enable();
        } else {
            this.fixtureCreationForm.controls["coANumber"].disable();
            this.fixtureCreationForm.controls["coANumber"].setValue(null);
        }
    }

    onSelectionChange(selection: CompanySearchResult): void {
        if (selection) {
            this.sharingGroupsService.getSharingGroupsForCompany(selection.accountId);
        } else {
            this.setSharingGroups([]);
        }
    }

    sharingGroupSelected(event: MouseEvent, isGroupAllForCurrent: boolean): void {
        const checked = (<HTMLInputElement>event.target).checked;
        const disabled = checked && isGroupAllForCurrent;

        this.sharingGroupsFormArray.controls.forEach((formGroup) => {
            const isGroupAll = formGroup.get("isGroupAll").value;

            if (!isGroupAll && disabled) {
                formGroup.disable();
            } else {
                formGroup.enable();
            }
        });

        // https://github.com/angular/angular/issues/23308
        this.sharingGroupsFormArray.markAsTouched();
    }

    createFixture(): void {
        if (this.fixtureCreationForm.valid) {
            this.animatingCreateButton = true;
            const fixtureCreation = this.fixtureCreationMapper.map(this.fixture);
            this.fixtureCreateService
                .create(fixtureCreation)
                .pipe(takeUntil(this.destroy$))
                .subscribe((fixtureId) => {
                    this.router.navigate([{ outlets: { primary: ["fixture", fixtureId], toolbar: ["contacts"] } }], { queryParamsHandling: "merge" });
                });
        } else {
            this.validateAllFormFields(this.fixtureCreationForm);
        }
    }

    cancel(): void {
        this.createForm();
        this.leftBarStateService.collapse();
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }

    private validateAllFormFields(formGroup: UntypedFormGroup): void {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl || control instanceof UntypedFormArray) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    private resetForm() {
        this.destroyFormSubscriptions$.next(true);

        this.fixture = new FixtureCreationFormModel();
        this.email = this.emailFactoryService.requestNewVessel(this.vesselRequestEmail);
        this.fixture.fixtureType = FixtureTemplateType.VoyageSpotType;
    }

    private createForm(): void {
        this.resetForm();

        this.fixtureCreationForm = new UntypedFormGroup(
            {
                fixtureSector: new UntypedFormControl(null, Validators.required),
                fixtureType: new UntypedFormControl(this.fixture.fixtureType),
                charterPartyDate: new UntypedFormControl(this.fixture.charterPartyDate, Validators.required),
                laycanFrom: new UntypedFormControl(this.fixture.laycanFrom, Validators.required),
                laycanTo: new UntypedFormControl(this.fixture.laycanTo, Validators.required),
                vessel: new UntypedFormControl(this.fixture.vessel),
                isVesselTbn: new UntypedFormControl(this.fixture.isVesselTbn),
                coANumber: new UntypedFormControl(this.fixture.coANumber),
                companyRole: new UntypedFormControl(this.fixture.companyRole, Validators.required),
                company: new UntypedFormControl(this.fixture.company, Validators.required),
                sharingGroups: new UntypedFormArray([])
            },
            Validators.compose([
                RangeValidator.validate("laycanFrom", "laycanTo"),
                VesselNameValidator.validate(),
                CoAReferenceValidator.validate(),
                SharingGroupsValidator.validate()
            ])
        );

        this.fixtureCreationForm.controls["coANumber"].disable();
        this.fixtureCreationForm.valueChanges.pipe(takeUntil(this.destroyFormSubscriptions$)).subscribe((x: FixtureCreationFormModel) => {
            this.fixture = x;
        });
    }

    private setSharingGroups(companySharingGroups: SharingGroupsViewModel[], fixtureSharingGroupCodes: string[] = []): void {
        this.sharingGroupsFormArray.clear();
        const isGroupAllSelected = fixtureSharingGroupCodes.some((code) => code === companySharingGroups.find((c) => c.isGroupAll).code);
        companySharingGroups.forEach((sg) => {
            const isSharingGroupSelected = fixtureSharingGroupCodes.some((code) => code === sg.code) || companySharingGroups.length === 1;
            this.sharingGroupsFormArray.push(this.createSharingGroup(sg, isSharingGroupSelected, isGroupAllSelected));
        });

        this.cd.markForCheck();
    }

    private createSharingGroup(model: SharingGroupsViewModel, isSharingGroupSelected: boolean, isGroupAllSelected: boolean): UntypedFormGroup {
        const group = this.formBuilder.group({
            name: new UntypedFormControl(model.name),
            code: new UntypedFormControl(model.code),
            isGroupAll: new UntypedFormControl(model.isGroupAll),
            isCurrentUserPresent: new UntypedFormControl(model.isCurrentUserPresent),
            isSelected: new UntypedFormControl(isSharingGroupSelected, { updateOn: "change" })
        });

        if (!model.isGroupAll && isGroupAllSelected) {
            group.disable();
        } else {
            group.enable();
        }

        return group;
    }

    private prePopulatedDataForVoyageCoA(): void {
        this.fixtureDataService.currentFixture$
            .pipe(
                takeUntil(this.destroy$),
                filter((fixture) => !!fixture)
            )
            .subscribe((fixture: Fixture) => {
                this.fixture.fixtureSector = fixture.division;
                this.fixture.fixtureType = FixtureTemplateType.VoyageCoAType;
                this.fixture.coANumber = fixture.coANumber;
                this.fixture.charterPartyDate = parseISODate(fixture.charterParty && fixture.charterParty.charterPartyDate);
                this.fixtureCreationForm.patchValue(this.fixture);
                this.enableCoaReferenceNumber();
                this.contactService.loadCompanies(fixture.fixtureId, fixture.fixtureSource.id);
                this.contactService.getSharingGroupsForFixture(fixture.fixtureId);
            });

        this.contactService.companies$
            .pipe(
                takeUntil(this.destroy$),
                filter((x) => !!x.length)
            )
            .subscribe((companies: Company[]) => {
                const creatorCompany = companies.find((c) => c.isCreator);
                this.fixture.companyRole = new Enumeration(creatorCompany.typeId, creatorCompany.typeName);
                this.fixture.company = <CompanySearchResult>{
                    accountId: creatorCompany.accountId,
                    companyName: creatorCompany.name,
                    groupName: creatorCompany.groupName
                };

                this.fixtureCreationForm.patchValue(this.fixture);
                this.sharingGroupsService.getSharingGroupsForCompany(creatorCompany.accountId);
            });

        combineLatest([this.sharingGroupsService.sharingGroups$.pipe(filter((x) => !!x)), this.contactService.fixtureGroups$.pipe(filter((x) => !!x))])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([companySharingGroups, fixtureSharingGroups]) => {
                this.setSharingGroups(
                    companySharingGroups.sharingGroups,
                    fixtureSharingGroups.configurationRoles.map((x) => x.replace("opsg_", ""))
                );
            });
    }
}
