import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CanDeactivateGuard } from "@ops/core";
import { TcVoyageRouteGuard } from "./authentication/tc-voyage-route.guard";
import { FixtureComponent } from "./fixture.component";

const routes: Routes = [
    {
        path: "",
        component: FixtureComponent
    },
    {
        path: ":fixtureId",
        component: FixtureComponent,
        canDeactivate: [CanDeactivateGuard],
        children: [
            {
                path: "voyage/:voyageId",
                component: FixtureComponent,
                canActivate: [TcVoyageRouteGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FixtureRoutingModule {}
