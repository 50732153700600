<div *ngIf="(form$ | async) as form; else loading" class="loading-panel-container fill" [class.readonly]="readonly$ | async" data-test-loading-panel-container>
    <div class="loading-panel" *ngIf="(busy$ | async)">
        <ops-load-animation size="x-large" class="loading-animation"></ops-load-animation>
    </div>

    <div class="row">
        <div class="col">
            <ops-tc-voyage-form
                *ngIf="hasTimeCharterVoyages$ | async else cargoesHeader"
                [form]="form"
                [voyages]="voyages$ | async"
                [cost]="cost$ | async"
                [readonly]="readonly$ | async"
                (voyageSelected)="selectVoyage($event)"
            ></ops-tc-voyage-form>

            <ng-template #cargoesHeader>
                <ops-section-header [expanded]="cargoesExpanded$ | async" (expand)="expandCargoes()" (collapse)="collapseCargoes()">
                    Cargoes
                </ops-section-header>
            </ng-template>
        </div>
    </div>

    <div class="card" *ngIf="cargoesExpanded$ | async">
        <div class="card-body">
            <ops-cargoes
                [cargoes]="cargoes$ | async"
                [readonly]="readonly$ | async"
                [canAdd]="!(readonly$ | async)"
                [canClone]="!(readonly$ | async)"
                [canRemove]="!(readonly$ | async)"
                [canApplyCargoFreightRate]="!(readonly$ | async)"
                [showSpecialisedCargoControls]="showSpecialisedCargoControls$ | async"
                (expand)="expandCargo($event)"
                (collapse)="collapseCargo($event)"
                (add)="addCargo()"
                (clone)="cloneCargo($event)"
                (remove)="removeCargo($event)"
                (applyCargoFreightRate)="applyCargoFreightRate($event)"
                (applyAllCargoFreightRate)="applyAllCargoFreightRate()"
            ></ops-cargoes>
        </div>
    </div>
    <hr class="horizontal-line" />
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header pl-0 row no-gutters">
                    <ops-destinations-header
                        [etaEmailAudit]="etaEmailAudit$ | async"
                        [expanded]="destinationsExpanded$ | async"
                        [canGenerateEtaEmail]="(destinations$ | async)?.length"
                        (expand)="expandDestinations()"
                        (collapse)="collapseDestinations()"
                        (generateEtaEmail)="generateEtaEmail()"
                    ></ops-destinations-header>
                </div>
                <div class="card-body" *ngIf="destinationsExpanded$ | async">
                    <ops-destinations
                        [destinations]="destinations$ | async"
                        [readonly]="readonly$ | async"
                        (expand)="expandDestination($event)"
                        (collapse)="collapseDestination($event)"
                        (moveUp)="moveDestinationUp($event)"
                        (moveDown)="moveDestinationDown($event)"
                        (add)="addDestination()"
                        (clone)="cloneDestination($event)"
                        (remove)="removeDestination($event)"
                    ></ops-destinations>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="(isVoyageFixture$ | async)">
        <hr class="horizontal-line" />
        <div class="row ops-voyage-totals">
            <div class="col">
                <div class="card">
                    <div class="card-header pl-0 row no-gutters">
                        <ops-section-header [expanded]="cargoTotalsExpanded$ | async" (expand)="expandCargoTotals()" (collapse)="collapseCargoTotals()">
                            Totals
                        </ops-section-header>
                    </div>
                    <div class="card-body" *ngIf="cargoTotalsExpanded$ | async">
                        <ops-totals-form
                            [form]="form"
                            [totals]="cargoTotals$ | async"
                            [readonly]="readonly$ | async"
                            [canAddTotal]="!(readonly$ | async)"
                            (add)="addAdditionalFreightExpense()"
                            (remove)="removeAdditionalFreightExpense($event)"
                            (generateInvoice)="generateFreightInvoice($event)"
                        ></ops-totals-form>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!(isVoyageFixture$ | async)">
        <hr class="horizontal-line" />
        <div class="row ops-voyage-totals">
            <div class="col">
                <div class="card">
                    <div class="card-header pl-0 row no-gutters">
                        <ops-section-header [expanded]="costsExpanded$ | async" (expand)="expandVoyageCosts()" (collapse)="collapseVoyageCosts()">
                            Totals
                        </ops-section-header>
                    </div>
                    <div class="card-body" *ngIf="costsExpanded$ | async">
                        <ops-voyage-cost-breakdown
                            [breakdown]="costBreakdown$ | async"
                            [overallCost]="(cost$ | async)?.overallVoyageCost"
                        ></ops-voyage-cost-breakdown>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <hr class="horizontal-line" />
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header pl-0 row no-gutters">
                    <ops-section-header [expanded]="publicNotesExpanded$ | async" (expand)="expandPublicNotes()" (collapse)="collapsePublicNotes()">
                        Public Notes
                    </ops-section-header>
                </div>
                <div class="card-body" *ngIf="(publicNotesExpanded$ | async)">
                    <ops-public-notes-form [form]="form$ | async" [notesAudit]="publicNotesAudit$ | async" [readonly]="readonly$ | async"></ops-public-notes-form>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <ops-load-animation size="x-large" stripeColor="dark" class="loading-animation"></ops-load-animation>
</ng-template>
