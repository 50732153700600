import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Inject, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { fromEvent, Observable, pipe, race, Subject } from "rxjs";
import { filter, first, mergeAll, takeUntil } from "rxjs/operators";

import { Enumeration } from "../../../../shared/reference-data/enumeration";
import { ReferenceDataService } from "../../../../shared/reference-data/reference-data.service";
import { NoticeType } from "../../../shared/models/enums/notice-type";
import { NoticeFormModel } from "../../../shared/models/form-models/notice.model";

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NoticesComponent),
    multi: true
};

@Component({
    selector: "ops-notices",
    templateUrl: "./notices.component.html",
    styleUrls: ["./notices.component.scss"],
    providers: [VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticesComponent implements ControlValueAccessor, OnInit, OnDestroy {
    private readonly destroy$ = new Subject();
    private readonly removeDocumentOnClickListener$ = new Subject();

    private isTouched: boolean;

    static componentName = "NoticesComponent";

    notices: NoticeFormModel[];
    noticeTypes$: Observable<Enumeration[]>;
    displayNoticesCount: number;

    @ViewChild("noticesElement", { static: true }) noticesElement: ElementRef;
    @ViewChild(NgbPopover) popover: NgbPopover;
    @Input() title: string;
    @Input() parentForm: UntypedFormGroup;

    constructor(
        private elRef: ElementRef,
        private renderer: Renderer2,
        private changeDetector: ChangeDetectorRef,
        private referenceDataService: ReferenceDataService,
        @Inject("Window") private window: Window
    ) {}

    ngOnInit(): void {
        this.noticeTypes$ = this.referenceDataService.getNoticeTypes();

        this.displayNoticesCount = this.getDisplayNoticesCount();
        fromEvent(this.window, "resize")
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.displayNoticesCount = this.getDisplayNoticesCount();
                this.changeDetector.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
    }

    writeValue(notices: NoticeFormModel[]): void {
        this.notices = notices ? notices : [];
        this.changeDetector.markForCheck();
    }

    getDisplayNoticesCount(): number {
        const totalPadding = 16;
        const ellipsWidth = 20;
        const noticeWitdh = 40;

        const width = this.noticesElement.nativeElement.offsetWidth;
        return Math.floor((width - totalPadding - ellipsWidth) / noticeWitdh);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    touched() {
        this.onTouched();
        this.isTouched = true;
    }

    setDisabledState(isDisabled: boolean): void {
        const noticesElement = this.elRef.nativeElement.querySelector("div");
        if (isDisabled) {
            this.renderer.addClass(noticesElement, "notices-disabled");
        } else {
            this.renderer.removeClass(noticesElement, "notices-disabled");
        }
    }

    deleteNotice(index: number, event: Event) {
        event.cancelBubble = true;
        this.notices.splice(index, 1);
        this.setFocusForNoticeDay(index);
        this.onChange(this.notices);
    }

    updateNoticeDays(index: number, days: number) {
        if (this.notices[index] && days > 0) {
            this.notices[index].days = days;
            this.onChange(this.notices);
        }
    }

    updateNoticeType(index: number, noticeTypeId: number) {
        this.noticeTypes$.pipe(this.filterNoticeType(noticeTypeId)).subscribe((noticeType) => {
            this.notices[index].noticeType = noticeType;
            this.onChange(this.notices);
        });
    }

    addNew(event: Event) {
        event.cancelBubble = true;

        this.noticeTypes$.pipe(this.filterNoticeType(NoticeType.Firm.valueOf())).subscribe((firm) => {
            this.notices.push({ days: null, noticeType: firm });
            this.onChange(this.notices);
        });
    }

    openNoticesListPopover() {
        if (this.parentForm && this.parentForm.status === "DISABLED") {
            this.popover.disablePopover = true;
        } else {
            this.popover.disablePopover = false;
            this.popover.open();
            this.listenForClicksOutsideOfComponent();
        }
    }

    closePopoverWhenNoNotices() {
        if (this.notices.length === 0) {
            this.closePopup();
        }
    }

    invalid(): boolean {
        return this.isTouched && this.notices.length > 0 && this.notices.some((notice) => notice.days === null || notice.days <= 0);
    }

    private onChange = (_: any) => {};
    private onTouched = () => {};

    private setFocusForNoticeDay(index: number) {
        const indexToFocus = index < this.notices.length ? index : index - 1;
        if (indexToFocus >= 0) {
            const daysInput = <HTMLInputElement>this.elRef.nativeElement.querySelectorAll("input[type='text']").item(indexToFocus);
            daysInput.focus();
        }
    }

    private listenForClicksOutsideOfComponent() {
        this.removeDocumentOnClickListener();

        const popupElement = this.elRef.nativeElement.querySelector("ngb-popover-window");
        const inputElement = this.elRef.nativeElement.querySelector("div#notices");

        const filterer = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            return (
                !target.classList.contains("notice-tag") &&
                !target.classList.contains("ng-option") &&
                !target.classList.contains("ng-option-label") &&
                popupElement &&
                !popupElement.contains(target) &&
                inputElement &&
                !inputElement.contains(target)
            );
        };

        fromEvent<MouseEvent>(document, "click")
            .pipe(filter(filterer), takeUntil(race(this.removeDocumentOnClickListener$, this.destroy$)))
            .subscribe(() => {
                this.closePopup();
                this.changeDetector.markForCheck();
            });
    }

    private removeDocumentOnClickListener() {
        this.removeDocumentOnClickListener$.next(true);
    }

    private closePopup() {
        if (this.popover.isOpen()) {
            this.onChange(this.notices);
            this.popover.close();
            this.removeDocumentOnClickListener();
        }
        this.touched();
    }

    private trackByIndex(index: number) {
        return index;
    }

    private filterNoticeType(noticeTypeId: number) {
        return pipe(
            mergeAll(),
            first((notice: Enumeration) => notice.id === noticeTypeId)
        );
    }
}
