import { MatDialog } from "@angular/material/dialog";
import { Observable, of } from "rxjs";

import { ChangeReasonComponent } from "../../../../components/lifting/cargo/change-reason.component";
import { ChangeReason, LiftingCargoPlanStatus } from "../../../model";

/**
 * If the current cargoPlanStatus is Firmed, it will open the ChangeReasonComponent popup and return ChangeReason when Saved or undefined when Cancelled.
 * null is returned when cargoPlanStatus is not Firmed.
 */
export const getChangeReason = (cargoPlanStatus: LiftingCargoPlanStatus, dialog: MatDialog, title: string): Observable<ChangeReason | null | undefined> =>
    cargoPlanStatus === "Firmed" ? dialog.open(ChangeReasonComponent, { data: title }).afterClosed() : of(null);

/**
 * Error indicating update was cancelled by user.
 */
export const userCancelled = () => new Error("Cancelled by user");
