import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CargoBerthActivityType } from "@ops/shared/reference-data";

@Component({
    selector: "ops-activity-header",
    templateUrl: "./activity-header.component.html",
    styleUrls: ["./activity-header.component.scss"]
})
export class ActivityHeaderComponent {
    @Input() readonly: boolean;
    @Input() expanded: boolean;
    @Input() type: CargoBerthActivityType;

    @Input() canOrder: boolean;
    @Input() canRemove: boolean;

    @Input() removeEnabled: boolean;
    @Input() moveUpEnabled: boolean;
    @Input() moveDownEnabled: boolean;
    @Input() showInvalid: boolean;

    @Output() readonly expand = new EventEmitter();
    @Output() readonly collapse = new EventEmitter();
    @Output() readonly moveUp = new EventEmitter();
    @Output() readonly moveDown = new EventEmitter();
    @Output() readonly remove = new EventEmitter();

    get activityTypeText() {
        return this.type ? this.type.name : "No Activity";
    }

    get isDischargeActivity() {
        return this.type && this.type.id === CargoBerthActivityType.Discharge.id;
    }

    // TODO: (NGRX SB) ACTIVITIES unit tests
    toggle() {
        if (this.expanded) {
            this.collapse.emit();
        } else {
            this.expand.emit();
        }
    }
}
