import { Guid } from "guid-typescript";
import { DateTime } from "luxon";
import { box } from "ngrx-forms";

import {
    AddNominationTask,
    CargoNominationBinding,
    Coa,
    EventBasedBindingPosition,
    EventBasedBindingStage,
    EventBasedBindingType,
    FixedDateBinding,
    LiftingLaycanBinding,
    NominationTask,
    NominationTaskBinding,
    NominationTaskForm,
    NominationTaskFormType,
    NominationTaskId,
    NominationTaskListItem,
    NominationTaskMonthSeriesMode,
    NominationTaskNotification,
    RecurringDateBinding,
    RecurringIntervalType,
    toAppUser,
    UpdateNominationTask,
    User,
    VesselNominationBinding
} from "../model";

const defaultTimeOfDayHour = 7;
const defaultTimeOfDayMinutes = 30;
const defaultTimeOfDay = `0${defaultTimeOfDayHour}:${defaultTimeOfDayMinutes}:00`;

export const getNominationTaskFormGroupId = (id: NominationTaskId): string => `NominationTaskForm.${id}`;

export const getNewNominationTaskId = (): NominationTaskId => Guid.create().toString();

export const getNewNominationTaskForm = (parent: Coa): NominationTaskForm => ({
    nominationTaskId: getNewNominationTaskId(),
    name: null,
    dueDate: null,
    assignedUsers: box(parent.operators.map(toAppUser)),
    reminderDaysBefore: null,
    responsible: null,
    recurrenceDayOfMonth: null,
    recurrenceEndDate: null,
    type: "RecurringDate",
    nextNotificationDate: null,
    nextDueDate: null,
    eventType: null,
    eventOffset: null,
    eventOffsetIsPositive: false
});

export const constructFixedDateBinding = (dueDate: string): FixedDateBinding => ({ trigger: "FixedDate", dueDate });

export const constructRecurringDateBinding = (
    startDate: string,
    endDate: string,
    intervalType?: RecurringIntervalType,
    monthSeriesMode?: NominationTaskMonthSeriesMode
): RecurringDateBinding => ({ trigger: "RecurringDate", startDate, endDate, intervalType, monthSeriesMode });

export const constructLiftingLaycanBinding = (offset: number, position: EventBasedBindingPosition = "Start"): LiftingLaycanBinding => ({
    trigger: "LiftingLaycan",
    offset,
    position
});

export const constructCargoNominationBinding = (offset: number, stage: EventBasedBindingStage): CargoNominationBinding => ({ trigger: "CargoNomination", offset, stage });

export const constructVesselNominationBinding = (offset: number, stage: EventBasedBindingStage): VesselNominationBinding => ({ trigger: "VesselNomination", offset, stage });

export const getNextDueDateString = (recurrenceDayOfMonth: number, endDate: string, allowPastDate = false): string =>
    getNextDueDate(recurrenceDayOfMonth, endDate, allowPastDate).toISO({ includeOffset: false });
export const getStrictNotificationDateString = (dueDate: string, daysBefore: number): string => getStrictNotificationDate(dueDate, daysBefore).toISO({ includeOffset: false });
export const getNextNotificationDateString = (dueDate: string, daysBefore: number, endDate: string): string =>
    getNextNotificationDate(dueDate, daysBefore, endDate).toISO({ includeOffset: false });

const getNextDueDate = (recurrenceDayOfMonth: number, endDate: string, allowPastDate = false): DateTime => {
    const today = DateTime.local();
    return getNextViableDate(getValidDate(today.year, today.month, recurrenceDayOfMonth, 1), DateTime.fromISO(endDate), allowPastDate) ?? DateTime.fromISO(null);
};

const getStrictNotificationDate = (dueDate: string, daysBefore: number): DateTime => setCorrectTime(DateTime.fromISO(dueDate).plus({ days: -Math.abs(daysBefore) }));
const getNextNotificationDate = (dueDate: string, daysBefore: number, endDate: string): DateTime => {
    const end = DateTime.fromISO(endDate);
    const date = setCorrectTime(getNextViableDate(DateTime.fromISO(dueDate).plus({ days: -Math.abs(daysBefore) }), end));
    return date && end.diff(date, "days").toObject().days > -1 ? date : DateTime.fromISO(null);
};

const getNextViableDate = (proposedDate: DateTime, endDate: DateTime, allowPastDate = false): DateTime => {
    const today = DateTime.local();

    if (proposedDate.startOf("day").diff(today.startOf("day"), "days").toObject().days < 1) {
        // if proposed date is before today
        let lastViableDate = getValidDate(endDate.year, endDate.month, proposedDate.day, -1);
        if (lastViableDate.startOf("day").diff(endDate, "days").toObject().days > 0) {
            // if the last viable date as calculated here is after the end date
            lastViableDate = getValidDate(endDate.year, endDate.month - 1, proposedDate.day, -1);
        }

        if (lastViableDate.startOf("day").diff(today.startOf("day"), "days").toObject().days <= 0) {
            // if the last viable date is before today or is today
            return allowPastDate ? lastViableDate : null;
        }

        return getValidDate(proposedDate.year, proposedDate.month + 1, proposedDate.day, 1);
    }

    const isEndDateBeforeToday = endDate.isValid && endDate.startOf("day").diff(today.startOf("day"), "days").toObject().days < 1;
    const isEndDateBeforeProposedDate = endDate.isValid && endDate.startOf("day").diff(proposedDate.startOf("day"), "days").toObject().days < 1;

    return isEndDateBeforeToday || isEndDateBeforeProposedDate ? null : proposedDate;
};

const getValidDate = (year: number, month: number, day: number, addMonthsToValidate: number): DateTime => {
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
        return DateTime.fromISO(null);
    }

    let newMonth = month;
    let newYear = year;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (newMonth > 12) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        newMonth = newMonth - 12;
        newYear++;
    } else if (newMonth < 1) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        newMonth = 12 - newMonth;
        newYear--;
    }

    const proposedDate = DateTime.local(newYear, newMonth, day, defaultTimeOfDayHour, defaultTimeOfDayMinutes, 0, 0);

    return proposedDate.isValid ? proposedDate : getValidDate(newYear, newMonth + addMonthsToValidate, day, addMonthsToValidate);
};

const setCorrectTime = (date: DateTime): DateTime => (date ? DateTime.local(date.year, date.month, date.day, defaultTimeOfDayHour, defaultTimeOfDayMinutes, 0, 0).toLocal() : date);

const getNominationTaskFormType = (binding: NominationTaskBinding): NominationTaskFormType => {
    switch (binding.trigger) {
        case "FixedDate":
            return "FixedDate";
        case "RecurringDate":
            return "RecurringDate";
        case "CargoNomination":
        case "LiftingLaycan":
        case "VesselNomination":
            return "EventBased";
    }
};

const getNominationTaskTypeDisplayName = (binding: NominationTaskBinding): string => {
    switch (binding.trigger) {
        case "FixedDate":
            return "Fixed Date";
        case "RecurringDate":
            return "Recurring (Monthly)";
        case "CargoNomination":
        case "LiftingLaycan":
        case "VesselNomination":
            return "Event Based";
    }
};

const isEventOffsetPositive = (offset: number) => offset >= 0;

export const nominationTaskToForm = (source: NominationTask): NominationTaskForm => {
    let binding = source.binding;
    let dueDate: string = null;
    let nextNotificationDate: string = null;
    let recurrenceDayOfMonth: number = null;
    let recurrenceEndDate: string = null;
    let nextDueDate: string = null;
    let eventType: EventBasedBindingType = null;
    let eventOffset: number = null;
    let eventOffsetIsPositive = false;

    switch (source.binding.trigger) {
        case "FixedDate":
            dueDate = (<FixedDateBinding>binding).dueDate;
            break;
        case "RecurringDate":
            binding = <RecurringDateBinding>binding;
            dueDate = binding.startDate;
            recurrenceDayOfMonth = DateTime.fromISO(dueDate).day;
            recurrenceEndDate = binding.endDate;
            nextDueDate = getNextDueDate(recurrenceDayOfMonth, binding.endDate).toISO();
            if (source.notifications.length) {
                nextNotificationDate = getNextNotificationDate(binding.startDate, source.notifications[0].offset || 0, binding.endDate).toISO();
            }
            break;
        case "LiftingLaycan":
            binding = <LiftingLaycanBinding>binding;
            eventType = "Start of Laycan"; //only start of laycan is supported for now
            eventOffset = Math.abs(binding.offset || 0);
            eventOffsetIsPositive = isEventOffsetPositive(binding.offset || 0);
            break;
        case "CargoNomination":
            binding = <CargoNominationBinding>binding;
            eventType = binding.stage === "Final" ? "Final Cargo Confirmation" : "Initial Cargo Nomination";
            eventOffset = Math.abs(binding.offset || 0);
            eventOffsetIsPositive = isEventOffsetPositive(binding.offset || 0);
            break;
        case "VesselNomination":
            binding = <VesselNominationBinding>binding;
            eventType = binding.stage === "Final" ? "Final Vessel Confirmation" : "Initial Vessel Nomination";
            eventOffset = Math.abs(binding.offset || 0);
            eventOffsetIsPositive = isEventOffsetPositive(binding.offset || 0);
            break;
    }

    if (!nextNotificationDate && source.notifications.length) {
        nextNotificationDate = getStrictNotificationDate(dueDate, source.notifications[0].offset || 0).toISO();
    }

    return {
        nominationTaskId: source.nominationTaskId,
        name: source.name,
        dueDate,
        assignedUsers: box(source.assignedUsers.map(toAppUser)),
        responsible: source.responsible !== "Unspecified" ? source.responsible : null,
        reminderDaysBefore: source.notifications.length ? Math.abs(source.notifications[0].offset || 0) : null,
        type: getNominationTaskFormType(source.binding),
        nextNotificationDate,
        recurrenceDayOfMonth,
        recurrenceEndDate,
        nextDueDate,
        eventType,
        eventOffset,
        eventOffsetIsPositive
    };
};

export const nominationTaskToListItem = (source: NominationTask): NominationTaskListItem => {
    let binding = source.binding;
    let dueDate: string = null;
    let nextNotificationDate: string = null;
    let recurrenceEndDate: string = null;
    let eventType: EventBasedBindingType = null;
    let eventDetails: string = null;
    let eventReminderDescription: string = null;

    const getEventDetails = (offset: number, event: EventBasedBindingType | string) =>
        `${event} ${isEventOffsetPositive(offset) ? "+" : "-"} ${Math.abs(offset)} ${Math.abs(offset) === 1 ? "day" : "days"}`;

    switch (source.binding.trigger) {
        case "FixedDate": {
            dueDate = (<FixedDateBinding>binding).dueDate;
            if (source.notifications.length && dueDate) {
                nextNotificationDate = getStrictNotificationDateString(dueDate, source.notifications[0].offset || 0);
            }
            break;
        }
        case "RecurringDate": {
            binding = <RecurringDateBinding>binding;
            const endDate = DateTime.fromISO(binding.endDate);
            recurrenceEndDate = binding.endDate;
            if (endDate.diffNow("minutes").toObject().minutes > 0) {
                const startDate = DateTime.fromISO(binding.startDate);
                dueDate = getNextDueDateString(startDate.day, binding.endDate, true);
            }
            if (source.notifications.length && dueDate) {
                nextNotificationDate = getNextNotificationDateString(dueDate, Math.abs(source.notifications[0].offset || 0), binding.endDate);
            }
            break;
        }
        case "LiftingLaycan": {
            eventType = "Start of Laycan";
            eventDetails = getEventDetails((<LiftingLaycanBinding>binding).offset || 0, eventType);
            break;
        }
        case "CargoNomination": {
            binding = <CargoNominationBinding>binding;
            eventType = binding.stage === "Final" ? "Final Cargo Confirmation" : "Initial Cargo Nomination";
            eventDetails = getEventDetails(binding.offset || 0, eventType);
            break;
        }
        case "VesselNomination": {
            binding = <VesselNominationBinding>binding;
            eventType = binding.stage === "Final" ? "Final Vessel Confirmation" : "Initial Vessel Nomination";
            eventDetails = getEventDetails(binding.offset || 0, eventType);
            break;
        }
    }

    if (eventType && source.notifications.length) {
        // reminder dates are always before the event. Hence negative offset.
        eventReminderDescription = getEventDetails(-Math.abs(source.notifications[0].offset || 0), "Due Date");
    }

    return {
        nominationTaskId: source.nominationTaskId,
        name: source.name,
        dueDate,
        isVisible: true,
        assignedUsers: source.assignedUsers.map((u) => u.name).join(", "),
        responsible: source.responsible !== "Unspecified" ? source.responsible : null,
        type: getNominationTaskTypeDisplayName(source.binding),
        nextNotificationDate,
        recurrenceEndDate,
        eventDetails,
        eventReminderDescription
    };
};

export const formToNominationTask = (source: NominationTaskForm, user: User): NominationTask => {
    let binding: NominationTaskBinding;
    switch (source.type) {
        case "FixedDate": {
            binding = constructFixedDateBinding(source.dueDate);
            break;
        }
        case "RecurringDate": {
            const endDate = DateTime.fromISO(source.recurrenceEndDate).toISODate();
            binding = constructRecurringDateBinding(source.dueDate, endDate, "Monthly", "AddByDayOfMonth");
            break;
        }
        case "EventBased": {
            const offset = source.eventOffsetIsPositive ? source.eventOffset : -source.eventOffset;
            switch (source.eventType) {
                case "Start of Laycan": {
                    binding = constructLiftingLaycanBinding(offset);
                    break;
                }
                case "Initial Cargo Nomination": {
                    binding = constructCargoNominationBinding(offset, "Initial");
                    break;
                }
                case "Final Cargo Confirmation": {
                    binding = constructCargoNominationBinding(offset, "Final");
                    break;
                }
                case "Initial Vessel Nomination": {
                    binding = constructVesselNominationBinding(offset, "Initial");
                    break;
                }
                case "Final Vessel Confirmation": {
                    binding = constructVesselNominationBinding(offset, "Final");
                    break;
                }
            }
        }
    }

    const notifications: ReadonlyArray<NominationTaskNotification> =
        source.reminderDaysBefore !== null && source.reminderDaysBefore !== undefined
            ? [
                  {
                      offset: source.reminderDaysBefore,
                      recipients: [user],
                      timeOfDay: defaultTimeOfDay
                  }
              ]
            : [];

    return {
        nominationTaskId: source.nominationTaskId,
        name: source.name,
        priority: "Medium",
        binding,
        assignedUsers: source.assignedUsers.value.map((u) => ({ name: u.fullName, userCode: u.userCode, userId: u.userId })),
        notifications,
        responsible: source.responsible
    };
};

export const formToUpdateNominationTask = (source: NominationTaskForm, userId: number): UpdateNominationTask => {
    const nominationTask = formToNominationTask(source, null);
    return {
        name: nominationTask.name,
        priority: nominationTask.priority,
        binding: nominationTask.binding,
        assignedUserIds: nominationTask.assignedUsers.map((u) => u.userId),
        // back-end expects offsets to be negative numbers
        notifications: nominationTask.notifications.map((n) => ({ offset: -n.offset, timeOfDay: n.timeOfDay, recipientIds: [userId] })),
        responsible: nominationTask.responsible
    };
};

export const formToAddNominationTask = (source: NominationTaskForm, userId: number): AddNominationTask => ({
    nominationTaskId: source.nominationTaskId,
    ...formToUpdateNominationTask(source, userId)
});
