export class Accordion {
    private _isOpen = false;

    constructor(isOpen: boolean = false) {
        this._isOpen = isOpen;
    }

    get isOpen() {
        return this._isOpen;
    }

    get isClosed() {
        return !this._isOpen;
    }

    toggle() {
        this._isOpen = !this._isOpen;
    }

    open() {
        this._isOpen = true;
    }
}
