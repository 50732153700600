import { createAction, On, on } from "@ngrx/store";
import { createFormGroupState, focus, updateGroup } from "ngrx-forms";
import { CoaHeaderForm, coaToForm } from "../model/header";
import { CoasState } from "../model/state";
import { currentCoaStateReducer } from "./reducer";

/* ACTIONS */
export const editCoaHeaderAction = createAction("[Coa Shell] Edit Coa Header");

/* REDUCERS */
export const editCoaHeaderReducer: On<CoasState> = on(editCoaHeaderAction, (state) =>
    currentCoaStateReducer(state, (coaState) => {
        const initialForm = createFormGroupState(coaState.coa.coaId, coaToForm(coaState.coa));
        const form = updateGroup<CoaHeaderForm>({ driver: focus })(initialForm);

        return { ...coaState, coaHeaderForm: form };
    })
);
