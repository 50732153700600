import { capitalize, joinNamedTypeArray, Maritime, toDateTime } from "@ops/shared";
import { Sorting } from "@ops/state";
import { CoaId, CoaStatus } from "../model/coa";
import { CoaIndexItem } from "../model/coa-index-item";

export type CoaGridRow = Readonly<{
    coaId: CoaId;
    reference: string;
    name: string;
    charterpartyDate: string;
    owner: string;
    ownerGroup: string;
    charterer: string;
    chartererGroup: string;
    brokers: string;
    operators: string;
    loadLocations: string;
    dischargeLocations: string;
    cargoes: string;
    status: CoaStatus;
}>;

export const getCoaGridRow = (coaIndex: CoaIndexItem): CoaGridRow => {
    return {
        coaId: coaIndex.documentId,
        reference: coaIndex.reference,
        name: coaIndex.name,
        charterpartyDate: toDateTime(coaIndex.charterpartyDate)?.toLocaleString(Maritime.DATETIME_SHORT) ?? "",
        owner: coaIndex.owner,
        ownerGroup: coaIndex.ownerGroup,
        charterer: coaIndex.charterer,
        chartererGroup: coaIndex.chartererGroup,
        brokers: joinNamedTypeArray(coaIndex.brokers),
        operators: joinNamedTypeArray(coaIndex.operators),
        loadLocations: joinNamedTypeArray(coaIndex.loadLocations),
        dischargeLocations: joinNamedTypeArray(coaIndex.dischargeLocations),
        cargoes: joinNamedTypeArray(coaIndex.cargoes),
        status: coaIndex.status
    };
};

export const getCoaGridSorting = (sorting: Sorting): Sorting => {
    if (!sorting) {
        return sorting;
    }

    let sortColumn = capitalize(sorting.column);
    switch (sortColumn) {
        case "Operators":
            sortColumn = "Operators.Name";
            break;
        case "Brokers":
            sortColumn = "Brokers.Name";
            break;
        case "LoadLocations":
            sortColumn = "LoadLocations.Name";
            break;
        case "DischargeLocations":
            sortColumn = "DischargeLocations.Name";
            break;
        case "Cargoes":
            sortColumn = "Cargoes.Name";
            break;
        default:
            break;
    }

    return { column: sortColumn, order: sorting.order };
};

