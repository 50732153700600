import { createSelector } from "@ngrx/store";
import * as R from "ramda";

import { getLocationState, LocationState } from "../../locations";
import {
    CoaFeatureState,
    getNominatedVessel,
    hasMoreThanOneAcceptedVessel,
    hasNoPreferredVessel,
    hasPreferredVessel,
    isFirmedVesselPlanStatus,
    isTentativeVesselPlanStatus,
    Lifting,
    VesselNomination
} from "../../model";
import { selectCurrentLifting, selectCurrentLiftingState } from "../selectors";

export const selectCurrentVesselNominationForm = createSelector(selectCurrentLiftingState, (state) => state?.vesselNominationForm);
export const selectCurrentVesselNominationFormValue = createSelector(selectCurrentVesselNominationForm, (form) => form?.value);

export type VesselNominationState = Omit<VesselNomination, "locationPriorToVoyage"> &
    Readonly<{
        locationPriorToVoyage: LocationState;
    }>;

export const getVesselNominationState = (vesselNomination: VesselNomination, cdnUrl: string): VesselNominationState => ({
    ...vesselNomination,
    locationPriorToVoyage: vesselNomination.locationPriorToVoyage && getLocationState(vesselNomination.locationPriorToVoyage, cdnUrl)
});

export const selectCurrentVesselNominations = createSelector<CoaFeatureState, { cdnUrl: string }, Lifting, ReadonlyArray<VesselNominationState>>(
    selectCurrentLifting,
    (lifting, { cdnUrl }) => lifting?.vessels.map((x) => getVesselNominationState(x, cdnUrl))
);

export const selectCurrentVesselNominationFormSaving = createSelector(selectCurrentLiftingState, (state) => state?.vesselNominationFormSaveStatus === "persisting");

export const selectPreferVesselEnabled = createSelector(selectCurrentLifting, R.allPass([isFirmedVesselPlanStatus, hasNoPreferredVessel, hasMoreThanOneAcceptedVessel]));
export const selectRenominateVesselEnabled = createSelector(selectCurrentLifting, R.both(isFirmedVesselPlanStatus, hasPreferredVessel));
export const selectRenominateNewVesselEnabled = createSelector(selectCurrentLifting, isFirmedVesselPlanStatus);
export const selectAddVesselEnabled = createSelector(selectCurrentLifting, isTentativeVesselPlanStatus);

export const selectCurrentNominatedVesselLaycan = createSelector(selectCurrentLifting, (lifting) => lifting?.vessels && getNominatedVessel(lifting.vessels)?.laycan);

export const selectCurrentVesselPlanStatus = createSelector(selectCurrentLifting, (lifting) => lifting?.vesselPlanStatus);
