import { FormGroupState } from "ngrx-forms";

import { GridType } from "../../fixture/shared/models/enums/grid-type";
import { AppState, CreateWorksheet, LoadStatus, PersistenceStatus, StateObjectMap, Worksheet, WorksheetError, WorksheetId, WorksheetRenameForm } from "../model";

export declare type WorksheetState = Readonly<{
    worksheet?: Worksheet;
    saveStatus?: PersistenceStatus;
    error?: WorksheetError;
    renameForm?: FormGroupState<WorksheetRenameForm>;
}>;

export declare type WorksheetsState = Readonly<{
    worksheets: StateObjectMap<WorksheetState>;
    loadStatus?: LoadStatus;
    selectedWorksheetId?: WorksheetId;
    defaultWorksheet?: CreateWorksheet;
    currentWorksheet?: CreateWorksheet;
    worksheetTypeToShow?: GridType;
    gridRenameForm?: FormGroupState<WorksheetRenameForm>;
    gridRenameFormSaveStatus?: PersistenceStatus;
}>;

export type WorksheetFeatureState = AppState & Readonly<{ worksheets: WorksheetsState }>;

export const getSelectedWorksheetId = (state: WorksheetsState) => state?.selectedWorksheetId;
export const getWorksheetState = (state: WorksheetsState, worksheetId: WorksheetId) => state.worksheets.byId[worksheetId];
export const getSelectedWorksheetState = (state: WorksheetsState) => state.worksheets.byId[getSelectedWorksheetId(state)];
