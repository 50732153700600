<div class="container">
    <div class="row">
        <div class="col">
            <div class="row no-gutters">
                <div class="form-group col">
                    <label for="companyRole" class="search-label">Search</label>
                    <ng-select
                        id="role"
                        class="ops-select"
                        [ngrxFormControlState]="form.controls.companyRole"
                        [items]="roles"
                        bindLabel="name"
                        bindValue="name"
                        [clearable]="false"
                        placeholder="Select Company Role"
                        data-test-company-role
                        [selectOnTab]="true"
                        [ngrxEnableFocusTracking]="true"
                    >
                    </ng-select>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <ops-company-autosuggest [ngrxFormControlState]="form.controls.company" [readonly]="!form.controls.companyRole.value" data-test-company></ops-company-autosuggest>
        </div>
    </div>
</div>
