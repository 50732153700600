import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

import { selectCurrentWorksheetSavable, selectSelectedWorksheetId, WorksheetFeatureState } from "@ops/state";

import { LegacyWorksheetState } from "../../../left-bar/worksheets/store/legacy-worksheet.reducer";
import {
    selectCurrentWorksheetSaveable as selectCurrentLegacyWorksheetSavable,
    selectSelectedWorksheetId as selectSelectedLegacyWorksheetId
} from "../../../left-bar/worksheets/store/legacy-worksheet.selectors";

@Component({
    selector: "ops-grid-search-autocomplete",
    templateUrl: "./grid-search-autocomplete.component.html",
    styleUrls: ["./grid-search-autocomplete.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridSearchAutocompleteComponent implements OnInit, AfterViewInit {
    static componentName = "GridSearchAutocompleteComponent";

    defaultConfig: any;
    currentWorksheetSavable$: Observable<boolean>;
    hasSelectedWorksheet$: Observable<boolean>;

    @Input() selection: any;
    @Input() suggestedItems: Observable<any[]>;
    @Input() suggestionSubject: Subject<string>;
    @Input() isLoading: Observable<boolean>;
    @Input() optionsTemplate: TemplateRef<any>;
    @Input() namingNewWorksheet: boolean;
    @Input() useLegacyWorksheetStore: boolean;

    @Output() readonly selectionChange = new EventEmitter<any[]>();
    @Output() readonly save = new EventEmitter();
    @Output() readonly saveAsNew = new EventEmitter();
    @Output() readonly warningSearch = new EventEmitter();

    @ViewChild("ngSelectComponent", { static: true }) ngSelectComponent: ElementRef;

    constructor(private legacyStore: Store<LegacyWorksheetState>, private newStore: Store<WorksheetFeatureState>) {}

    ngOnInit(): void {
        this.defaultConfig = {
            addTag: true,
            multiSelect: true,
            closeOnSelect: true,
            hideSelected: true,
            selectOnTabClick: true,
            placeholderText: "Search"
        };

        if (this.useLegacyWorksheetStore) {
            this.currentWorksheetSavable$ = this.legacyStore.select(selectCurrentLegacyWorksheetSavable);
            this.hasSelectedWorksheet$ = this.legacyStore.select(selectSelectedLegacyWorksheetId).pipe(map((id) => !!id));
        } else {
            this.currentWorksheetSavable$ = this.newStore.select(selectCurrentWorksheetSavable);
            this.hasSelectedWorksheet$ = this.newStore.select(selectSelectedWorksheetId).pipe(map((id) => !!id));
        }
    }

    ngAfterViewInit(): void {
        (<any>this.ngSelectComponent).focus();
    }

    onSelectionChange(selection: any[]): void {
        const selectionList = selection.map((v) => (v.hasOwnProperty("label") ? v.label : v));
        this.selectionChange.emit(selectionList);
    }

    onSearchIconClick(): void {
        this.selectionChange.emit(this.selection);
    }

    handleSaveAsNew(): void {
        if (this.namingNewWorksheet) {
            this.save.emit();
        } else {
            this.saveAsNew.emit();
        }
    }
}
