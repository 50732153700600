import { removeLaytimeCalculationGridRequestReducer } from "./remove-request";
import { searchLaytimeCalculationGridFailReducer, searchLaytimeCalculationGridReducer, searchLaytimeCalculationGridSuccessReducer } from "./search";
import { updateLaytimeCalculationGridColumnsReducer } from "./update-columns";
import { updateLaytimeCalculationGridSearchTokensReducer } from "./update-search-tokens";
import { updateLaytimeCalculationGridSortingReducer } from "./update-sorting";
import {
    updateLaytimeCalculationGridSuggestionTermFailReducer,
    updateLaytimeCalculationGridSuggestionTermReducer,
    updateLaytimeCalculationGridSuggestionTermSuccessReducer
} from "./update-suggestion-term";

export default [
    updateLaytimeCalculationGridSuggestionTermReducer,
    updateLaytimeCalculationGridSuggestionTermSuccessReducer,
    updateLaytimeCalculationGridSuggestionTermFailReducer,
    updateLaytimeCalculationGridSearchTokensReducer,
    updateLaytimeCalculationGridSortingReducer,
    updateLaytimeCalculationGridColumnsReducer,
    searchLaytimeCalculationGridReducer,
    searchLaytimeCalculationGridSuccessReducer,
    searchLaytimeCalculationGridFailReducer,
    removeLaytimeCalculationGridRequestReducer
];
