import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfigService } from "src/app/core";
import { NoonReport } from "./noon-report.dto";

@Injectable({ providedIn: "root" })
export class NoonReportHttpService {
    private readonly voyageServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.voyageServiceUrl = appConfigService.config.voyageServiceUrl;
    }

    getAll(fixtureId: string, voyageId: string): Observable<NoonReport[]> {
        return this.httpClient.get<NoonReport[]>(`${this.voyageServiceUrl}/api/v1.0/fixture/${fixtureId}/voyage/${voyageId}/noonreports`);
    }

    update(fixtureId: string, voyageId: string, noonReportId: string, noonReport: NoonReport): Observable<NoonReport> {
        return this.httpClient.put(`${this.voyageServiceUrl}/api/v1.0/fixture/${fixtureId}/voyage/${voyageId}/noonreports/${noonReportId}`, noonReport).pipe(
            map(() => {
                noonReport.voyageId = voyageId;
                noonReport.fixtureId = fixtureId;
                return noonReport;
            })
        );
    }

    get(fixtureId: string, voyageId: string, noonReportId: string): Observable<NoonReport> {
        return this.httpClient.get<NoonReport>(`${this.voyageServiceUrl}/api/v1.0/fixture/${fixtureId}/voyage/${voyageId}/noonreports/${noonReportId}`);
    }
}
