import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { SetValueAction } from "ngrx-forms";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, filter, map, tap, withLatestFrom } from "rxjs/operators";

import { update } from "@ops/shared";

import { LaytimeCalculationHttpService } from "../../../../services";
import {
    ActivityLocation,
    ActivityLocationForm,
    activityLocationFormKey,
    ActivityLocationId,
    idEq,
    LtcFeatureState,
    LtcId,
    LtcState,
    toActivityLocationDiff
} from "../../../model";
import { calculationStateReducer } from "../../reducer";
import { selectCurrentCalculationId } from "../../selectors";
import { UpdateQueue } from "../../update-queue";
import { selectCurrentActivityLocation, selectCurrentActivityLocationForm } from "../selectors";

/* ACTIONS */
const UPDATE_ACTION_NAME = "[Laytime Calculation] Save Activity Location";
export const updateActivityLocationAction = createAction(
    UPDATE_ACTION_NAME,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; activityLocation: Partial<ActivityLocation> }>()
);
export const updateActivityLocationSuccessAction = createAction(
    `${UPDATE_ACTION_NAME} Success`,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; activityLocation: Partial<ActivityLocation> }>()
);
export const updateActivityLocationFailAction = createAction(`${UPDATE_ACTION_NAME} Fail`, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; error: Error }>());

/* REDUCERS */
export const updateActivityLocationReducer: On<LtcState> = on(updateActivityLocationAction, (state, { activityLocation, activityLocationId, ltcId }) => {
    const updateFns: Evolver = {
        calculation: { activityLocations: update(idEq(activityLocationId), activityLocation) }
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

/* EFFECTS */
export const updateActivityLocationFormEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<ActivityLocationForm>>(SetValueAction.TYPE),
            filter((action) => action.controlId.split(".")[1] === activityLocationFormKey),
            withLatestFrom(store.select(selectCurrentCalculationId), store.select(selectCurrentActivityLocation), store.select(selectCurrentActivityLocationForm)),
            filter(([, , , form]) => form.isValid),
            map(([, ltcId, activityLocation, form]) =>
                updateActivityLocationAction({ ltcId, activityLocationId: activityLocation.id, activityLocation: toActivityLocationDiff(form.value, activityLocation) })
            ),
            tap((action) =>
                updateQueue.enqueue(action, updateActivityLocation, (latest) =>
                    action.activityLocationId === latest.activityLocationId
                        ? (R.mergeDeepLeft(action, latest) as ReturnType<typeof updateActivityLocationAction>)
                        : [latest, action]
                )
            )
        )
    );

export const updateActivityLocation = (
    { ltcId, activityLocationId, activityLocation }: ReturnType<typeof updateActivityLocationAction>,
    laytimeCalculationHttpService: LaytimeCalculationHttpService
) =>
    laytimeCalculationHttpService.updateActivityLocation(ltcId, activityLocationId, activityLocation).pipe(
        map(() => updateActivityLocationSuccessAction({ ltcId, activityLocationId, activityLocation })),
        catchError((error) => of(updateActivityLocationFailAction({ ltcId, activityLocationId, error })))
    );
