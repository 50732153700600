import { Injectable } from "@angular/core";
import { DateTime } from "luxon";

import { CargoBerthActivityType, LaytimeEventType } from "@ops/shared/reference-data";

import { LaytimeEvent } from "../../../../../fixtures/models/laytime-event.model";
import { CargoBerthActivity, ExpenseClaim, Fixture, FixtureType, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class DataMissingNorTenderedDateRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    readonly requiredLocationActivityTypes = [CargoBerthActivityType.Load.id, CargoBerthActivityType.Discharge.id];

    evaluate(fixture: Fixture, voyages: Voyage[], _expenses: ExpenseClaim[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();
        const now = DateTime.local();

        voyages.forEach((voyage) =>
            voyage.destinations.forEach((destination, destinationIndex) => {
                if (!destination.etaRange) {
                    return;
                }

                const destinationTimezone = destination.location && destination.location.timeZone ? destination.location.timeZone : "utc";
                const eta = destination.etaRange && destination.etaRange.to && DateTime.fromISO(destination.etaRange.to, { zone: destinationTimezone });

                if (+eta >= +now) {
                    return;
                }

                destination.berths.forEach((berth, berthIndex) =>
                    berth.cargoBerthActivities.forEach((activity, activityIndex) => {
                        if (!activity.type || !this.requiredLocationActivityTypes.includes(activity.type?.id) || !activity.laytimeEvents || !activity.associatedCargoes?.length) {
                            return;
                        }

                        const norTenderedEventIndex = activity.laytimeEvents.findIndex((ev) => ev.type?.id === LaytimeEventType.NORTendered.id);
                        const norTenderedEvent = norTenderedEventIndex > -1 ? activity.laytimeEvents[norTenderedEventIndex] : null;
                        if (!(norTenderedEvent && norTenderedEvent.eventDate)) {
                            const message = "Missing NOR Tendered";
                            const path = this.getActivityPath(fixture, voyage, destinationIndex, destination, berthIndex, berth, activityIndex, activity);
                            if (norTenderedEvent) {
                                path.push(FixtureWarningPathSegment.fromProperty<CargoBerthActivity>("laytimeEvents", null));
                                path.push(FixtureWarningPathSegment.fromIndex(norTenderedEventIndex, "laytimeEvents", norTenderedEvent.type.name));
                                path.push(FixtureWarningPathSegment.fromProperty<LaytimeEvent>("eventDate", null));
                            } else {
                                path.push(FixtureWarningPathSegment.fromProperty<CargoBerthActivity>("type", null));
                            }
                            const dismissingRecord =
                                fixture.fixtureType.id !== FixtureType.Voyage
                                    ? dismissedWarningRecord(
                                          new WarningHashBuilder()
                                              .withVoyage(voyage.voyageId)
                                              .withDestination(destination.destinationId)
                                              .withBerth(berth.berthId)
                                              .withBerthActivity(activity.berthActivityId)
                                              .withCategory("data")
                                              .withMessage(message)
                                              .build()
                                      )
                                    : null;
                            const warning = new FixtureWarning("data", message, path, dismissingRecord);
                            warnings.push(warning);
                        }
                    })
                );
            })
        );

        return warnings;
    }
}
