import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { isNullOrUndefined } from "src/app/shared/utils";

@Injectable({ providedIn: "root" })
export class TemperatureLogCoolingTimeService {

    calculateDiff(arrival: Date, nor: Date): string {
        if (!isNullOrUndefined(arrival) && !isNullOrUndefined(nor)) {
            const arrivalDate = DateTime.fromJSDate(arrival);
            const norDate = DateTime.fromJSDate(nor);
            const timeDiff = norDate.diff(arrivalDate, ["hours", "minute"]).toObject();

            if (timeDiff.hours > 0 && timeDiff.minutes >= 0) {
                return `${timeDiff.hours}hr ${timeDiff.minutes}min`;
            }
            if (timeDiff.minutes > 0) {
                return `${timeDiff.minutes}min`;
            }
        }

        return "";
    }
}
