import { parseISODate } from "../../shared/date-utils/date-utilities";
import { LaytimeEvent } from "../shared/models";
import { compareValues } from "../state/utils";

export class LaytimeEventSortingUtility {
    static sortLaytimeEvents(laytimeEvents: LaytimeEvent[]) {
        return laytimeEvents.sort((a, b) => compareValues(parseISODate(a.eventDate), parseISODate(b.eventDate)));
    }

    static areInSameOrder(orderedLaytimeEvents: LaytimeEvent[], receivedLaytimeEvents: LaytimeEvent[]): boolean {
        for (let index = 0; index < orderedLaytimeEvents.length; index++) {
            if (orderedLaytimeEvents[index].id !== receivedLaytimeEvents[index].id) {
                return false;
            }
        }
        return true;
    }
}
