<div class="side-panel" data-test-worksheets-panel>
    <span class="side-panel-title">
        {{ selectedWorksheetTypeTitle$ | async }}
        <div class="left-toolbar-expander" (click)="collapseLeftBar()">
            <div class="has-icon icon--close"></div>
        </div>
    </span>

    <div class="header">
        <span class="name">Worksheet Name</span>
        <span class="last-updated">Last Updated</span>
    </div>

    <div class="scrollable-content" *ngIf="!(worksheetsLoadFailed$ | async) && !(areWorksheetsLoading$ | async) && (worksheetsOfSelectedType$ | async) as worksheets">
        <div class="default-grid-layout">
            <button type="button" (click)="performDefaultLayoutSearch()" data-test-default-grid-layout-search>
                <span class="settings-icon has-icon icon--settings"></span>
                <span class="text">Default Grid Layout &amp; Search</span>
            </button>
        </div>

        <table class="table table-hover table-spaced worksheets" *ngIf="worksheets.length">
            <tbody>
                <tr *ngFor="let worksheet of worksheets; trackBy: trackBy" class="worksheet-item" [class.selected]="(selectedWorksheetId$ | async) === worksheet.id">
                    <td *ngIf="worksheet.isSaving" colspan="3"><ops-load-animation size="large" class="light-background"></ops-load-animation></td>
                    <td
                        *ngIf="!worksheet.isSaving && !worksheet.renameForm && !!worksheet.errorMessage"
                        class="error"
                        (click)="selectWorksheet(worksheet.id)"
                    >
                        <div class="name">
                            <span class="text">{{ worksheet.name }}</span>
                        </div>
                        <div class="message">
                            <span class="error-icon has-icon icon--warning"></span>
                            <span>{{ worksheet.errorMessage }}</span>
                        </div>
                    </td>
                    <td
                        *ngIf="!worksheet.isSaving && !worksheet.errorMessage && !worksheet.renameForm"
                        class="name"
                        (click)="selectWorksheet(worksheet.id)"
                    >
                        {{ worksheet.name | truncate: 25:"..." }}
                    </td>
                    <td *ngIf="!worksheet.isSaving && worksheet.renameForm" class="name renaming-container">
                        <ops-worksheet-rename-form [form]="worksheet.renameForm" [hasRenameError]="worksheet.hasRenameError" (save)="confirmRename(worksheet.id)" (cancel)="cancelEditName(worksheet.id)"></ops-worksheet-rename-form>
                    </td>
                    <td *ngIf="!worksheet.isSaving" class="last-updated" (click)="selectWorksheet(worksheet.id)">
                        {{ worksheet.updatedDate | timezoneDate: "DATETIME" }}
                    </td>
                    <td *ngIf="!worksheet.isSaving" class="kebab-menu">
                        <button
                            type="button"
                            [ngbPopover]="kebabMenuContent"
                            #kebabMenu="ngbPopover"
                            (click)="kebabMenu.open()"
                            placement="bottom-right"
                            triggers="manual"
                            data-test-kebab-menu
                        >
                            <span class="has-icon icon--more-vert"></span>
                        </button>
                        <ng-template #kebabMenuContent>
                            <div class="kebab-menu list-group" (mouseleave)="kebabMenu.close()">
                                <div
                                    class="kebab-menu-item list-group-item list-group-item-action"
                                    (click)="editName(worksheet.id)"
                                >
                                    <span>Rename</span>
                                </div>
                                <div
                                    class="kebab-menu-item list-group-item list-group-item-action"
                                    (click)="delete(worksheet.id)"
                                >
                                    <span>Delete</span>
                                </div>
                            </div>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </table>

        <ops-placeholder *ngIf="!worksheets.length" title="No saved worksheets" data-test-no-worksheets-placeholder></ops-placeholder>
    </div>

    <div class="worksheets-loading-panel" *ngIf="(areWorksheetsLoading$ | async)">
        <ops-load-animation size="x-large" class="light-background"></ops-load-animation>
    </div>

    <div class="worksheets-error-panel" *ngIf="(worksheetsLoadFailed$ | async) && !(areWorksheetsLoading$ | async)">
        <ops-placeholder [icon]="iconTemplate" title="Could not connect to server" data-test-worksheets-error-placeholder>
            <ng-template #iconTemplate>
                <div class="ops-placeholder-icon-circle">
                    <span class="has-icon icon--withdrawn"></span>
                </div>
            </ng-template>
        </ops-placeholder>
    </div>
</div>
