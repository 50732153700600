import { Component, Input } from "@angular/core";
import { FormGroupState } from "ngrx-forms";
import { CompanyForm, CompanyRoleOption } from "src/app/coa/state";

@Component({
    selector: "ops-coa-contacts-form",
    templateUrl: "./contacts-form.component.html",
    styleUrls: ["./contacts-form.component.scss"]
})
export class CoaContactsFormComponent {
    @Input() form: FormGroupState<CompanyForm>;
    @Input() companyRoles: CompanyRoleOption[];
}
