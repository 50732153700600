import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { combineLatest } from "rxjs";
import { delay, filter, first, switchMap, tap, withLatestFrom } from "rxjs/operators";

import { AppInsightsService, MixpanelService } from "@ops/core";

import { bulkUpdateCargoAllowedRates } from "./cargoes/actions";
import {
    actionCalendarViewSelect,
    actionGridViewSelect,
    actionGridSearchSelect,
    interopFixtureLoadSuccess,
    lockFixtureSuccess,
    saveFixtureSuccess,
    selectCurrentFixture,
    selectCurrentFixtureVoyagesLoading,
    unlockFixtureSuccess
} from "./fixture";
import { favoriteFixtureSelect, laytimeCreateClaim } from "./fixture";
import {
    actionGridRowSelect,
    claimGridRowSelect,
    claimGridSearchSelect,
    fixtureBoardCardSelect,
    fixtureBoardButtonSelect,
    fixtureGridButtonSelect,
    fixtureGridRowSelect,
    fixtureGridSearchSelect,
    actionCalendarGoToCoASelect,
    actionCalendarGoToFixtureSelect
} from "./fixture/actions";
import { routeVoyageIdChange } from "./fixture-routing.effects";
import { FixtureFeatureState, getEarliestLaytimeEventDate, getFixtureClaimNames, getFixtureOperatorNames, getRecentLaytimeEventDate } from "./model";
import { selectCurrentVoyage } from "./voyage";
import { LaytimeEventType } from "../../shared/reference-data";
import { isNullOrUndefined } from "../../shared/utils";
import { openFixturePopupSuccessAction, saveFixturePopupAction, unlockFixturePopupAction } from "../../state/fixture-popup";
import { Fixture, Voyage } from "../shared/models";

const FIXTURE_EDITING_EVENT = "FixtureEditingEvent";
const FIXTURE_CANCELLED_EVENT = "FixtureCancelledEvent";

export enum MixpanelEvents {
    FixtureDisplay = "Fixture Display",
    FixtureScreenEdit = "Fixture Screen Edit",
    FixtureScreenCancel = "Fixture Screen Cancel",
    FixtureScreenSave = "Fixture Screen Save",
    FixturePopupEdit = "Fixture Grid Popup: Fixture Edit",
    FixturePopupCancel = "Fixture Grid Popup: Fixture Cancel",
    FixturePopupSave = "Fixture Grid Popup: Fixture Save",
    LaytimeCreateEvent = "Laytime Create Claim Select",
    FavoriteFixtureSelect = "Favorite Fixture Select",
    ActionGridSearchSelect = "Action Grid Search Select",
    ActionGridRowSelect = "Action Grid Row Select",
    ActionGridViewSelect = "Action Grid View Select",
    ActionCalendarViewSelect = "Action Calendar View Select",
    ClaimGridRowSelect = "Demurrage Grid Row Select",
    ClaimGridSearchSelect = "Demurrage Grid Search Select",
    FixtureGridSearchSelect = "Fixture Grid Search Select",
    FixtureGridRowSelect = "Fixture Grid Row Select",
    FixtureGridButtonSelect = "Fixture Grid Button Select",
    FixtureBoardButtonSelect = "Fixture Board Button Select",
    FixtureBoardCardSelect = "Fixture Board Card Select",
    ActionCalendarGoToCoASelect = "Action Calendar Go To CoA Select",
    ActionCalendarGoToFixtureSelect = "Action Calendar Go To Fixture Select",
    FixtureLaytimeBulkActionDisplay = "Fixture Laytime Bulk Action Display",
    FixturesExport = "Fixtures Export",
    FixtureWarningDismiss = "Fixture Warning Dismiss",
    FixtureWarningActivate = "Fixture Warning Activate",
    FixturesWarningTypeSearch = "Fixtures Warning Type Search"
}

@Injectable()
export class FixtureLoggingEffects {
    fixtureLoadedEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(interopFixtureLoadSuccess),
                tap(({ fixture }) => {
                    if (fixture) {
                        this.appInsights.setGlobalProperty("division", fixture.division.name);
                        this.appInsights.setGlobalProperty("fixtureSource", fixture.fixtureSource.name);
                        this.appInsights.setGlobalProperty("fixtureNumber", fixture.fixtureNumber);
                    } else {
                        this.appInsights.removeGlobalProperty("division");
                        this.appInsights.removeGlobalProperty("fixtureSource");
                        this.appInsights.removeGlobalProperty("fixtureNumber");
                    }
                }),
                switchMap(({ fixture }) =>
                    combineLatest([this.store.select(selectCurrentVoyage), this.store.select(selectCurrentFixtureVoyagesLoading)]).pipe(
                        filter(([voyage, loading]) => voyage && !loading),
                        first(),
                        tap(([voyage]) => this.trackMixpanelEvent(MixpanelEvents.FixtureDisplay, fixture, voyage))
                    )
                )
            ),
        { dispatch: false }
    );

    voyageSelectedEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                routeVoyageIdChange(),
                tap((voyageId) => {
                    this.appInsights.setGlobalProperty("voyageId", voyageId);
                })
            ),
        { dispatch: false }
    );

    startFixtureEditingEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(lockFixtureSuccess),
                tap(() => {
                    this.appInsights.startEvent(FIXTURE_EDITING_EVENT);
                }),
                switchMap(() =>
                    combineLatest([this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage), this.store.select(selectCurrentFixtureVoyagesLoading)]).pipe(
                        filter(([fixture, voyage, loading]) => fixture && voyage && !loading),
                        first(),
                        tap(([fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.FixtureScreenEdit, fixture, voyage))
                    )
                )
            ),
        { dispatch: false }
    );

    stopFixtureEditingEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveFixtureSuccess),
                tap(() => {
                    this.appInsights.stopEvent(FIXTURE_EDITING_EVENT);
                }),
                switchMap(() =>
                    combineLatest([this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage), this.store.select(selectCurrentFixtureVoyagesLoading)]).pipe(
                        filter(([fixture, voyage, loading]) => fixture && voyage && !loading),
                        first(),
                        tap(([fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.FixtureScreenSave, fixture, voyage))
                    )
                )
            ),
        { dispatch: false }
    );

    cancelFixtureEditingEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(unlockFixtureSuccess),
                tap(() => {
                    this.appInsights.logEvent(FIXTURE_CANCELLED_EVENT);
                    this.appInsights.stopEvent(FIXTURE_EDITING_EVENT, { cancelled: "true" });
                }),
                switchMap(() =>
                    combineLatest([this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage), this.store.select(selectCurrentFixtureVoyagesLoading)]).pipe(
                        filter(([fixture, voyage, loading]) => fixture && voyage && !loading),
                        first(),
                        tap(([fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.FixtureScreenCancel, fixture, voyage))
                    )
                )
            ),
        { dispatch: false }
    );

    laytimeCreateClaimClickEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(laytimeCreateClaim),
                switchMap(() =>
                    combineLatest([this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage), this.store.select(selectCurrentFixtureVoyagesLoading)]).pipe(
                        filter(([fixture, voyage, loading]) => fixture && voyage && !loading),
                        first(),
                        tap(([fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.LaytimeCreateEvent, fixture, voyage))
                    )
                )
            ),
        { dispatch: false }
    );

    favoriteFixtureSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(favoriteFixtureSelect),
                delay(100),
                switchMap(() =>
                    combineLatest([this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage), this.store.select(selectCurrentFixtureVoyagesLoading)]).pipe(
                        filter(([fixture, voyage, loading]) => fixture && voyage && !loading),
                        first(),
                        tap(([fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.FavoriteFixtureSelect, fixture, voyage))
                    )
                )
            ),
        { dispatch: false }
    );

    actionGridSearchSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionGridSearchSelect),
                tap(() => this.mixpanel.track(`Ops: ${MixpanelEvents.ActionGridSearchSelect}`))
            ),
        { dispatch: false }
    );

    actionGridRowSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionGridRowSelect),
                delay(100),
                switchMap(() =>
                    combineLatest([this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage), this.store.select(selectCurrentFixtureVoyagesLoading)]).pipe(
                        filter(([fixture, voyage, loading]) => fixture && voyage && !loading),
                        first(),
                        tap(([fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.ActionGridRowSelect, fixture, voyage))
                    )
                )
            ),
        { dispatch: false }
    );

    actionGridViewSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionGridViewSelect),
                tap(() => this.mixpanel.track(`Ops: ${MixpanelEvents.ActionGridViewSelect}`))
            ),
        { dispatch: false }
    );

    actionCalendarViewSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionCalendarViewSelect),
                tap(() => this.mixpanel.track(`Ops: ${MixpanelEvents.ActionCalendarViewSelect}`))
            ),
        { dispatch: false }
    );

    claimGridSearchSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(claimGridSearchSelect),
                tap(() => this.mixpanel.track(`Ops: ${MixpanelEvents.ClaimGridSearchSelect}`))
            ),
        { dispatch: false }
    );

    claimGridRowSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(claimGridRowSelect),
                delay(100),
                switchMap(() =>
                    combineLatest([this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage), this.store.select(selectCurrentFixtureVoyagesLoading)]).pipe(
                        filter(([fixture, voyage, loading]) => fixture && voyage && !loading),
                        first(),
                        tap(([fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.ClaimGridRowSelect, fixture, voyage))
                    )
                )
            ),
        { dispatch: false }
    );

    fixtureGridSearchSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fixtureGridSearchSelect),
                tap(() => this.mixpanel.track(`Ops: ${MixpanelEvents.FixtureGridSearchSelect}`))
            ),
        { dispatch: false }
    );

    fixtureGridRowSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fixtureGridRowSelect),
                delay(100),
                switchMap(() =>
                    combineLatest([this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage), this.store.select(selectCurrentFixtureVoyagesLoading)]).pipe(
                        filter(([fixture, voyage, loading]) => fixture && voyage && !loading),
                        first(),
                        tap(([fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.FixtureGridRowSelect, fixture, voyage))
                    )
                )
            ),
        { dispatch: false }
    );

    fixtureGridButtonSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fixtureGridButtonSelect),
                tap(() => this.mixpanel.track(`Ops: ${MixpanelEvents.FixtureGridButtonSelect}`))
            ),
        { dispatch: false }
    );

    fixtureBoardButtonSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fixtureBoardButtonSelect),
                tap(() => this.mixpanel.track(`Ops: ${MixpanelEvents.FixtureBoardButtonSelect}`))
            ),
        { dispatch: false }
    );

    cargoAllowedRatesBulkUpdateEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(bulkUpdateCargoAllowedRates),
                tap((props) =>
                    this.mixpanel.track(`Ops: ${MixpanelEvents.FixtureLaytimeBulkActionDisplay}`, {
                        /* eslint-disable @typescript-eslint/naming-convention */
                        "Number of cargoes selected": props.numberOfCargoesSelected,
                        "Field label": props.fieldLabel
                        /* eslint-enable @typescript-eslint/naming-convention */
                    })
                )
            ),
        { dispatch: false }
    );

    fixtureBoardCardSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fixtureBoardCardSelect),
                delay(100),
                switchMap(() =>
                    combineLatest([this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage), this.store.select(selectCurrentFixtureVoyagesLoading)]).pipe(
                        filter(([fixture, voyage, loading]) => fixture && voyage && !loading),
                        first(),
                        tap(([fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.FixtureBoardCardSelect, fixture, voyage))
                    )
                )
            ),
        { dispatch: false }
    );

    actionCalendarGoToCoASelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionCalendarGoToCoASelect),
                delay(100),
                switchMap(() =>
                    combineLatest([this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage), this.store.select(selectCurrentFixtureVoyagesLoading)]).pipe(
                        filter(([fixture, voyage, loading]) => fixture && voyage && !loading),
                        first(),
                        tap(([fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.ActionCalendarGoToCoASelect, fixture, voyage))
                    )
                )
            ),
        { dispatch: false }
    );

    actionCalendarGoToFixtureSelectEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionCalendarGoToFixtureSelect),
                delay(100),
                switchMap(() =>
                    combineLatest([this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage), this.store.select(selectCurrentFixtureVoyagesLoading)]).pipe(
                        filter(([fixture, voyage, loading]) => fixture && voyage && !loading),
                        first(),
                        tap(([fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.ActionCalendarGoToFixtureSelect, fixture, voyage))
                    )
                )
            ),
        { dispatch: false }
    );

    fixturePopupEditEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(openFixturePopupSuccessAction),
                tap(({ fixture, voyage }) => this.trackMixpanelEvent(MixpanelEvents.FixturePopupEdit, fixture, voyage))
            ),
        { dispatch: false }
    );

    fixturePopupCancelEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(unlockFixturePopupAction),
                withLatestFrom(this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage)),
                tap(([, fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.FixturePopupCancel, fixture, voyage))
            ),
        { dispatch: false }
    );

    fixturePopupSaveEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveFixturePopupAction),
                withLatestFrom(this.store.select(selectCurrentFixture), this.store.select(selectCurrentVoyage)),
                tap(([, fixture, voyage]) => this.trackMixpanelEvent(MixpanelEvents.FixturePopupSave, fixture, voyage))
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private appInsights: AppInsightsService, private mixpanel: MixpanelService, private store: Store<FixtureFeatureState>) {}

    private trackMixpanelEvent(event: MixpanelEvents, fixture: Fixture, voyage: Voyage) {
        const firstNorTenderedDate = getEarliestLaytimeEventDate(voyage.destinations, LaytimeEventType.NORTendered);
        const lastCargoCompletedDate = getRecentLaytimeEventDate(voyage.destinations, LaytimeEventType.CargoCompleted);

        this.mixpanel.track(`Ops: ${event}`, {
            /* eslint-disable @typescript-eslint/naming-convention */
            "Fixture Source": fixture.fixtureSource.name,
            "Fixture Type": fixture.fixtureType.name,
            "Fixture Division": fixture.division?.name,
            "Fixture Status": fixture.fixtureStatus.name,
            "Fixture Office Location": fixture.office?.name,
            "Fixture Operator": getFixtureOperatorNames(fixture),
            "Fixture Claims": getFixtureClaimNames(fixture),
            "Fixture Laycan": !isNullOrUndefined(fixture.laycan.date) ? `${fixture.laycan.date.from}-${fixture.laycan.date.to}` : "",
            "Fixture FIRST NOR T": !isNullOrUndefined(firstNorTenderedDate) ? firstNorTenderedDate : "",
            "Fixture LAST Cargo Completed": !isNullOrUndefined(lastCargoCompletedDate) ? lastCargoCompletedDate : ""
            /* eslint-enable @typescript-eslint/naming-convention */
        });
    }
}
