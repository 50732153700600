import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { EmailGenerationAudit, FixtureType } from "../../shared/models";

@Component({
    selector: "ops-destinations-header",
    templateUrl: "./destinations-header.component.html",
    styleUrls: ["./destinations-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationsHeaderComponent {
    FixtureType = FixtureType;

    @Input() etaEmailAudit: Readonly<EmailGenerationAudit>;
    @Input() expanded: boolean;
    @Input() canGenerateEtaEmail: boolean;

    @Output() readonly expand = new EventEmitter();
    @Output() readonly collapse = new EventEmitter();
    @Output() readonly generateEtaEmail = new EventEmitter();

    // TODO: (NGRX JC) DESTINATIONS unit tests
    toggle() {
        if (this.expanded) {
            this.collapse.emit();
        } else {
            this.expand.emit();
        }
    }
}
