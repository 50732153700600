import { Injectable } from "@angular/core";

import { CommandHandler } from "../../../../fixture/mediator/command-handlers/command-handler";
import { Fixture, Laycan } from "../../../../fixture/shared/models";
import { dateToISOString } from "../../../../shared/date-utils/date-utilities";
import { UpdateTimeCharterHeadingCommand } from "./update-time-charter-heading.command";

@Injectable({
    providedIn: "root"
})
export class UpdateTimeCharterHeadingCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateTimeCharterHeadingCommand) {
        const value = command.payload;

        fixture.operators = [...(value.operators || [])];
        fixture.leadOperator = fixture.operators[0] ? fixture.operators[0] : null;

        fixture.charterParty.charterPartyType = value.charterPartyType;
        fixture.charterParty.cpmLink = value.cpmLink;
        fixture.voyageReference = value.voyageReference;
        fixture.fixtureStatus = value.fixtureStatus;

        if (!fixture.laycan?.date) {
            fixture.laycan = new Laycan(null, null);
        }

        fixture.laycan.date.from = dateToISOString(value.laycanFrom);
        fixture.laycan.date.to = dateToISOString(value.laycanTo);
        fixture.comments = value.comments;
        fixture.tradingLimits = value.tradingLimits;
        fixture.isCommentsImportant = value.isCommentsImportant;
        fixture.cargo = value.cargo;
        fixture.lastCargoes = [...(value.lastCargoes || [])];
        fixture.managedBy = value.managedBy;

        fixture.laycan.extensionDate = {
            from: dateToISOString(value.laycanExtFrom),
            to: dateToISOString(value.laycanExtTo)
        };
        fixture.vessel = !value.vessel ? null : { name: value.vessel.name, imo: value.vessel.imoNumber, cvn: value.vessel.cvn };
        fixture.vesselId = value.vessel ? value.vessel.cvn : null;
        fixture.displayBroker = value.displayBroker;
        fixture.charterParty.charterPartyDate = dateToISOString(value.cpDate);
        fixture.isVesselTbn = value.isVesselTbn;
        fixture.currency = value.currency;
        fixture.brokerCommission = value.brokerCommission;
        fixture.addressCommission = value.addressCommission;
        fixture.ballastBonus = value.ballastBonus;
        fixture.presentationType = value.presentationType;
        fixture.chartererReference = value.chartererReference;
    }
}
