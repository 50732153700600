<div class="left-toolbar-container">
    <div class="left-toolbar-header-container">
        <ul class="left-toolbar-header fixture-agnostic">
            <li
                class="left-toolbar-icon has-icon icon--star-filled"
                (click)="navigate('favorites')"
                [routerLink]="[{ outlets: { toolbar: ['favorites'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Favourites"
                container="body"
                data-test-coa-favourite-icon
            ></li>
            <li
                class="left-toolbar-icon has-icon icon--add-circle-outline"
                *ngIf="isCreateFixtureVisible$ | async"
                (click)="navigate('create-fixture')"
                [routerLink]="[{ outlets: { toolbar: ['create-fixture'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Create Fixture"
                container="body"
                data-test-create-fixture-icon
            ></li>
            <li
                class="left-toolbar-icon has-icon icon--saved-search"
                *ngIf="!isFixtureToolbarVisible && !isCoaToolbarVisible && !isLtcToolbarVisible"
                (click)="navigate('worksheets')"
                [routerLink]="[{ outlets: { toolbar: ['worksheets'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Worksheets"
                container="body"
                data-test-worksheets-icon
            ></li>
        </ul>
        <ul class="left-toolbar-header" *ngIf="isFixtureToolbarVisible">
            <li
                *opsRole="'Actions'"
                class="left-toolbar-icon has-icon icon--notifications"
                [class.left-toolbar-icon-unseen-actions]="hasUnseenActions$ | async"
                (click)="navigate('actions')"
                [routerLink]="[{ outlets: { toolbar: ['actions'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Actions"
                data-test-actions-icon
            ></li>
            <li
                class="left-toolbar-icon has-icon icon--contact-dialer"
                *ngIf="displayContactsMenuItem"
                (click)="navigate('contacts')"
                [routerLink]="[{ outlets: { toolbar: ['contacts'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Contacts"
                container="body"
                data-test-contacts-listelement
            ></li>
            <li
                class="left-toolbar-icon has-icon icon--folder-special"
                *ngIf="isContractsVisible"
                (click)="navigate('coa')"
                [routerLink]="[{ outlets: { toolbar: ['coa'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Contracts"
                container="body"
                data-test-contracts-listelement
            >
                <span class="coa-count">{{ coACount }}</span>
            </li>
            <li
                class="left-toolbar-icon has-icon icon--fixtures"
                *ngIf="isTcVoyagesActive && (hasTcVoyageRole$ | async)"
                (click)="navigate('tc-voyages')"
                [routerLink]="[{ outlets: { toolbar: ['tc-voyages'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Time Charter Voyages"
                container="body"
                data-test-voyages-listelement
            ></li>
            <li
                class="left-toolbar-icon has-icon icon--file"
                (click)="navigate('documents')"
                [routerLink]="[{ outlets: { toolbar: ['documents'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Documents"
                container="body"
                data-test-documents-listelement
            ></li>
            <li
                class="left-toolbar-icon has-icon icon--flag"
                [class.left-toolbar-icon-notify]="hasWarnings$ | async"
                (click)="navigate('warnings')"
                [routerLink]="[{ outlets: { toolbar: ['warnings'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Fixture Warnings"
                container="body"
                data-test-warnings-listelement
            ></li>
            <li
                class="left-toolbar-icon has-icon icon--workbook"
                *ngIf="isTcNoonReportsActive && (hasTcVoyageRole$ | async)"
                (click)="navigate('tc-noon-reports')"
                [routerLink]="[{ outlets: { toolbar: ['tc-noon-reports'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Noon Reports"
                container="body"
                data-test-noon-reports-listelement
            ></li>
            <li
                class="left-toolbar-icon has-icon icon--statistics"
                *ngIf="isTemperatureLogActive"
                (click)="navigate('temperature-log')"
                [routerLink]="[{ outlets: { toolbar: ['temperature-log'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Temperature Log"
                container="body"
                data-test-temperature-log-listelement
            ></li>
            <ng-container *opsFeature="'LaytimeCalculatorV2'">
                <li
                    *ngIf="isVoyageFixtureType && (hasLtcRole$ | async)"
                    class="left-toolbar-icon has-icon icon--calculator"
                    (click)="navigate('ltc')"
                    [routerLink]="[{ outlets: { toolbar: ['ltc'] } }]"
                    queryParamsHandling="merge"
                    routerLinkActive="active"
                    ngbTooltip="Laytime Calculations"
                    data-cy="laytime-calculations"
                ></li>
            </ng-container>
        </ul>
        <ul class="left-toolbar-header" *ngIf="isCoaToolbarVisible">
            <li
                *opsRole="'Actions'"
                class="left-toolbar-icon has-icon icon--notifications"
                [class.left-toolbar-icon-unseen-actions]="hasUnseenActions$ | async"
                (click)="navigate('actions')"
                [routerLink]="[{ outlets: { toolbar: ['actions'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Actions"
                data-test-coa-actions-icon
            ></li>
            <li
                class="left-toolbar-icon has-icon icon--contact-dialer"
                *ngIf="!isLiftingActive"
                (click)="navigate('coa-contacts')"
                [routerLink]="[{ outlets: { toolbar: ['coa-contacts'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Contacts"
                container="body"
                data-test-coa-contacts-listelement
            ></li>
            <li
                class="left-toolbar-icon has-icon icon--contact-dialer"
                *ngIf="isLiftingActive"
                (click)="navigate('lifting-contacts')"
                [routerLink]="[{ outlets: { toolbar: ['lifting-contacts'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Contacts"
                container="body"
            ></li>
            <li class="left-toolbar-icon has-icon icon--time-update"
                *ngIf="isLiftingActive"
                (click)="navigate('lifting-history')"
                [routerLink]="[{ outlets: { toolbar: ['lifting-history'] } }]"
                queryParamsHandling="merge"
                routerLinkActive="active"
                ngbTooltip="Lifting History"
                container="body"
            ></li>
        </ul>
    </div>
</div>

<div [ngClass]="(isCollapsed$ | async) ? 'hide' : ''" class="left-toolbar-content-container">
    <div class="left-toolbar-content">
        <router-outlet name="toolbar"></router-outlet>
    </div>
</div>
