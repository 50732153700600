<ng-container *ngIf="activities">
    <div *ngFor="let activity of activities; first as isFirst; last as isLast; trackBy: trackBy" class="row activity-row">
        <div class="card">
            <ops-activity-header
                [expanded]="activity.expanded"
                [canOrder]="!readonly && activity.canOrder"
                [canRemove]="!readonly && activity.canRemove"
                [moveUpEnabled]="!isFirst"
                [moveDownEnabled]="!isLast"
                [removeEnabled]="activity.canRemove"
                [type]="activity.type"
                (moveUp)="moveUp.emit(activity.activityId)"
                (moveDown)="moveDown.emit(activity.activityId)"
                (remove)="remove.emit(activity.activityId)"
                (expand)="expand.emit(activity.activityId)"
                (collapse)="collapse.emit(activity.activityId)"
            >
            </ops-activity-header>

            <ops-activity-shell
                *ngIf="activity.expanded"
                [destinationId]="activity.destinationId"
                [berthId]="activity.berthId"
                [activityId]="activity.activityId"
                [readonly]="readonly"
            ></ops-activity-shell>
        </div>
    </div>
</ng-container>
<div class="d-flex flex-row-reverse add-activity-button-container" *ngIf="canAdd">
    <button type="button"
            class="btn btn-action align-bottom mr-0"
            [disabled]="readonly"
            (click)="add.emit()"
            warningActive
            ngrxFormControlId="activities"
            ngrxFormControlIdMatch="endsWith"
            [focusOnActive]="true"
            data-test-add-activity-button>
        <span class="has-icon icon--add-circle-outline"></span>
        <span>ACTIVITY</span>
    </button>
</div>
