<div class="ops-ltc-card">
    <div class="ops-ltc-card-header">
        <span class="ops-ltc-card-header-title flex-grow-1">Laytime Events</span>
        <div class="laytime-events-buttons">
            <ops-action-button (click)="enterDeductions.emit()" data-cy="enter-deductions">
                ENTER DEDUCTIONS
            </ops-action-button>
            <ops-action-button class="p-mx-3 import-port-times" (click)="importPortTimes.emit()" *ngIf="!isVoyageLoading" data-cy="import-port-times">
                IMPORT PORT TIMES
            </ops-action-button>
        </div>
        <ops-load-animation *ngIf="isVoyageLoading" class="p-mx-3 import-port-times light-background loading-animation" size="small"></ops-load-animation>
    </div>
    <ops-table-insert-overlay #overlayComponent excludeLastColumnsFromWidth="1" [disable]="false"
        [enablePlankAfterLastRow]="true">
        <div #buttonsBlock class="add-event-block">
            <div (click)="insertLaytimeEvent()" class="add-event" data-cy="add-laytime-event-dropdown">
                <span>
                    Add a Laytime Event
                </span>
            </div>

            <div (click)="insertDeduction()" class="add-event" data-cy="add-deduction-event-dropdown">
                <span>
                    Add a Deduction Event
                </span>
            </div>
        </div>
        <p-table
            [value]="laytimeEvents"
            [formGridReadonly]="false"
            dataKey="id"
            [rowTrackBy]="trackBy"
            [tableStyleClass]="gridClasses"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Time From</th>
                    <th>Time To</th>
                    <th></th>
                    <th class="text-right">
                        <span ngbTooltip="% value is on time counted" data-cy="percentage-message">%</span>
                    </th>
                    <th class="text-right">Used Laytime</th>
                    <th>Event</th>
                    <th *ngIf="cargoes">Cargo</th>
                    <th>Remarks</th>
                    <th>Additional Comments</th>
                    <th class="delete-column">
                        <ops-icon-button
							data-cy="clear-laytime-events"
                            type="delete"
                            tooltip="Clear All"
                            *ngIf="laytimeEvents.length"
                            (click)="clear.emit()"
                            (keyup.enter)="clear.emit()"
                        ></ops-icon-button>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr class="p-grid" formGridRow [attr.rowkey]="rowData.id" data-cy="laytime-event-row" [class.laytime-event-on-demurrage]="rowData.isDemurrageEvent">
                    <td class="ops-fg-cell-readonly" data-cy="time-from">
                        <span class="ops-fg-cell-value">{{rowData.fromDate | maritimeDate:zone}}</span>
                    </td>

                    <td pEditableColumn
                        [formGridCell]="rowData.form.controls.date"
                        data-cy="time-to"
                        [ngbTooltip]="getTimeConventionChangeTooltip(rowData.offsetDiffWithPrevEvent)"
                    >
                        <ops-cellEditor>
                            <ng-template pTemplate="input">
                                <ops-date-input
                                    [enableTime]="true"
                                    tabbingBehaviour="time"
                                    [ngrxFormControlState]="rowData.form.controls.date"
                                    [ngrxValueConverter]="dateValueConverter"
                                    [timeZone]="timezone"
                                    formGridInput
                                    (focus)="onEventDateFocus(rowData.form.controls.date.id, $event)"
                                    (closed)="onEventDateBlur(rowData.form.controls.date.id, $event)"
                                    (blur)="onEventDateBlur(rowData.form.controls.date.id, $event)"
                                ></ops-date-input>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="ops-fg-cell-value">
                                    {{rowData.form.value.date | maritimeDate:zone}}
                                </span>
                            </ng-template>
                        </ops-cellEditor>
                    </td>
                    <td class="icon-cell ops-fg-cell-readonly">
                        <span class="has-icon" [ngClass]="getStartStopClass(rowData.intervalPart)"></span>
                    </td>
                    <td *ngIf="rowIndex" pEditableColumn [formGridCell]="rowData.form.controls.percentage" data-cy="percentage">
                        <ops-cellEditor>
                            <ng-template pTemplate="input">
                                <input formGridInput type="text" class="p-inputtext text-right"
                                       [opsNumber]="{ precision: 2, type: 'string', showTrailingZeros: false }"
                                       [ngrxFormControlState]="rowData.form.controls.percentage" />
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="ops-fg-cell-value text-right">
                                    <span [innerHtml]="rowData.form.value.percentage | opsNumberStyledDecimal:false:0:2">
                                    </span>
                                </span>
                            </ng-template>
                        </ops-cellEditor>
                    </td>

                    <td *ngIf="!rowIndex" class="ops-fg-cell-readonly" data-cy="percentage"></td>

                    <td class="ops-fg-cell-readonly laytime-used" data-cy="duration" [ngbTooltip]="getDemurrageEventTooltip(rowData.isDemurrageEvent)">
                        <span class="ops-fg-cell-value text-right">{{rowData.laytimeUsed | durationFormat: durationUnit}}</span>
                    </td>

                    <td pEditableColumn [formGridCell]="rowData.form.controls.type" data-cy="event">
                        <ops-cellEditor>
                            <ng-template pTemplate="input">
                                <ng-select formGridInput class="ops-select"
                                           [ngrxFormControlState]="rowData.form.controls.type"
                                           [items]="laytimeEventTypes"
                                           [clearable]="true"
                                           [selectOnTab]="true"
                                           placeholder="Select">
                                </ng-select>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="ops-fg-cell-value">{{rowData.form.value.type}}</span>
                            </ng-template>
                        </ops-cellEditor>
                    </td>

                    <td *ngIf="cargoes" pEditableColumn [formGridCell]="rowData.form.controls.cargoId" data-cy="cargo">
                        <ops-cellEditor>
                            <ng-template pTemplate="input">
                                <ng-select
                                    class="ops-select"
                                    [items]="cargoes"
                                    [ngrxFormControlState]="rowData.form.controls.cargoId"
                                    placeholder="Select"
                                    [clearable]="true"
                                    bindLabel="name"
                                    bindValue="id"
                                    formGridInput
                                    [selectOnTab]="true">
                                    <ng-template ng-option-tmp let-item="item">
                                        <span title="{{item.name}}">{{item.name}}</span>
                                    </ng-template>
                                </ng-select>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="ops-fg-cell-value">{{rowData.cargoName}}</span>
                            </ng-template>
                        </ops-cellEditor>
                    </td>

                    <td pEditableColumn [formGridCell]="rowData.form.controls.remarks" data-cy="remarks">
                        <ops-cellEditor>
                            <ng-template pTemplate="input">
                                <ng-select formGridInput class="ops-select"
                                           [ngrxFormControlState]="rowData.form.controls.remarks"
                                           [items]="laytimeEventRemarks"
                                           [clearable]="true"
                                           [selectOnTab]="true"
                                           placeholder="Select">
                                </ng-select>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="ops-fg-cell-value">{{rowData.form.value.remarks}}</span>
                            </ng-template>
                        </ops-cellEditor>
                    </td>

                    <td pEditableColumn [formGridCell]="rowData.form.controls.comments" data-cy="additional-comments">
                        <ops-cellEditor>
                            <ng-template pTemplate="input">
                                <ops-textarea-editor [state]="rowData.form.controls.comments" [maxLength]="249">
                                </ops-textarea-editor>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="ops-fg-cell-value">
                                    {{ rowData.form.value.comments }}
                                </span>
                            </ng-template>
                        </ops-cellEditor>
                    </td>

                    <td pEditableColumn pFocusCellSelector="button" class="ops-fg-action-cell">
                        <ops-icon-button type="delete" class="delete-button" (click)="remove.emit(rowData.id)" data-cy="delete-laytime-event"
                                         (keyup.enter)="remove.emit(rowData.id)"></ops-icon-button>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="footer">
                <a class="ops-fg-add-row"
                    (click)="addLaytimeEvent.emit(laytimeEvents.length)"
                    (keydown.enter)="addLaytimeEvent.emit(laytimeEvents.length)"
                    (keydown.space)="addLaytimeEvent.emit(laytimeEvents.length)"
                    data-cy="add-laytime-event"
                    tabindex="0"
                >Add Laytime Event</a>
                <tr *ngIf="laytimeEvents.length">
                    <td></td>
                    <td>
                        <button
                            *ngIf="laytimeEventsOrderingEnabled"
                            (click)="order.emit()"
                            type="button"
                            class="has-icon icon--in-negotiation"
                            ngbTooltip="Re-order laytime events by date/time"
                            data-cy="reorder-laytime-events"
                        ></button>
                    </td>
                    <td></td>
                    <td></td>
                    <td class="text-right">
                        <div class="deductions-summary">
                            <span class="summary-titles">Time Counted:</span>
                            <span data-cy="time-counted-value">{{timeCounted | durationFormat:durationUnit}}</span>
                        </div>
                        <div class="deductions-summary">
                            <span class="summary-titles">Deductions:</span>
                            <span data-cy="deductions-value">{{deductions | durationFormat:durationUnit}}</span>
                        </div>
                        <div class="deductions-summary">
                            <span class="summary-titles">All Time:</span>
                            <span data-cy="all-time-value">{{allTime | durationFormat:durationUnit}}</span>
                        </div>
                    </td>
                    <td *ngIf="cargoes"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr class="ops-fg-no-data ops-fg-readonly">
                    <td class="ops-fg-no-content-cell">
                        <div>
                            <span class="has-icon icon--lightbulb-outline"></span>
                            <span data-cy="no-events">No laytime events are currently added</span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ops-table-insert-overlay>
</div>
