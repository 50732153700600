<ops-associated-cargoes
    *ngIf="(showAssociatedCargoes$ | async)"
    [associatedCargoes]="associatedCargoes$ | async"
    [cargoes]="allCargoes$ | async"
    [berths]="berths$ | async"
    [freightType]="freightType$ | async"
    [blDefaultFocusDate]="arrivalDateTime$ | async"
    [activityType]="activityType$ | async"
    [currentVoyage]="currentVoyage$ | async"
    [destinationId]="activityInfo.destinationId"
    [berthId]="activityInfo.berthId"
    [readonly]="isReadOnly"
    [timeZone]="locationTimezone$ | async"
    [canAdd]="!isReadOnly"
    [canRemove]="!isReadOnly"
    [division]="division$ | async"
    (add)="addAssociatedCargo()"
    (remove)="removeAssociatedCargo($event)"
>
</ops-associated-cargoes>

<ops-laytime-events
    [laytimeEvents]="laytimeEvents$ | async"
    [locationTimezone]="locationTimezone$ | async"
    [totalLaytime]="totalLaytime$ | async"
    [laytimeEventsOrdered]="laytimeEventsOrdered$ | async"
    [cargoes]="cargoes$ | async"
    [readonly]="isReadOnly"
    (add)="addLaytimeEvent($event)"
    (order)="orderLaytimeEvents()"
    (remove)="removeLaytimeEvent($event)"
    (dropdownOpen)="adjustDropdownPosition($event)"
>
</ops-laytime-events>
