import { MaritimeDate } from "@maritech/maritime-date";

import { capitalize, Currency, toDateTime, toDateTimeAsUtc } from "@ops/shared";
import { Sorting } from "@ops/state";

import { LaytimeCalculationIndex, LtcId } from "../../model";

export type LaytimeCalculationGridRow = Readonly<{
    id: LtcId;
    fixtureReference: string;
    name: string;
    lastUpdatedAt: string;
    lastUpdatedBy: string;
    cargoNames: string;
    activityLocationNames: string;
    calculationParty: string;
    currency: Currency;
    comments: string;
    operators: string;
    vesselName: string;
    chartererName: string;
    charterPartyDate: string;
}>;

export const getLaytimeCalculationGridRow = (laytimeCalculationIndex: LaytimeCalculationIndex): LaytimeCalculationGridRow => ({
    id: laytimeCalculationIndex.id,
    fixtureReference: laytimeCalculationIndex.fixtureReference,
    name: laytimeCalculationIndex.name ?? "",
    lastUpdatedAt: toDateTime(laytimeCalculationIndex.lastUpdatedAt)?.toLocaleString(MaritimeDate.DATETIME_FORMAT) ?? "",
    lastUpdatedBy: laytimeCalculationIndex.lastUpdatedBy?.name ?? "",
    cargoNames: laytimeCalculationIndex.cargoNames ?? "",
    activityLocationNames: laytimeCalculationIndex.activityLocationNames ?? "",
    calculationParty: null,
    currency: laytimeCalculationIndex.currency,
    comments: laytimeCalculationIndex.comments ?? "",
    operators: laytimeCalculationIndex.operators?.join(", ") ?? "",
    vesselName: laytimeCalculationIndex.vesselName,
    chartererName: laytimeCalculationIndex.chartererName,
    charterPartyDate: laytimeCalculationIndex.charterPartyDate ? toDateTimeAsUtc(laytimeCalculationIndex.charterPartyDate).toLocaleString(MaritimeDate.DATE_FORMAT) : ""
});

export const getLaytimeCalculationGridSorting = (sorting: Sorting): Sorting => {
    if (!sorting) {
        return sorting;
    }

    let sortColumn = capitalize(sorting.column);
    switch (sortColumn) {
        case "LastUpdatedBy":
            sortColumn = "LastUpdatedBy.Name";
            break;
        default:
            break;
    }

    return { column: sortColumn, order: sorting.order };
};
