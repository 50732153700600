import { createAction, on, On, props } from "@ngrx/store";
import { createFormGroupState } from "ngrx-forms";
import { currentCoaStateReducer } from "../../coa/reducer";
import { cargoForm, CargoId, cargoToForm, CoasState, getCurrentCoaState } from "../../model";

/* ACTIONS */
export const editCargoAction = createAction("[Coa Cargo Form] Edit Cargo", props<{ cargoId?: CargoId }>());
export const cancelCargoEditAction = createAction("[Coa Cargo Form] Cancel Edit Cargo");

/* REDUCERS */
export const editCargoReducer: On<CoasState> = on(editCargoAction, (state, { cargoId }) => {
    const currentCoa = getCurrentCoaState(state);
    const cargo = currentCoa.coa.cargoes.find((c) => c.cargoId === cargoId);

    if (!cargo && !!cargoId) {
        return state;
    }

    const form = !!cargoId ? cargoToForm(cargo) : cargoForm();

    return currentCoaStateReducer(state, (coaState) => ({
        ...coaState,
        cargoForm: createFormGroupState(form.cargoId, form)
    }));
});

export const cancelCargoEditReducer: On<CoasState> = on(cancelCargoEditAction, (state) => currentCoaStateReducer(state, (coaState) => ({ ...coaState, cargoForm: null })));
