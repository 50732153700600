export function formatBytes(size: number) {
    const thresh = 1024;

    if (Math.abs(size) < thresh) {
        return size + "B";
    }

    const units = ["KB", "MB", "GB"];
    let ui = -1;

    do {
        size /= thresh;
        ui++;
    } while (Math.abs(size) >= thresh && ui < units.length - 1);

    return size.toFixed(0) + units[ui];
}
