import { createEntityAdapter, EntityAdapter, EntityState, Update } from "@ngrx/entity";
import { DateTime } from "luxon";
import * as R from "ramda";

import { AppState } from "@ops/state";

import { GridType } from "../../../fixture/shared/models/enums/grid-type";
import { CreateWorksheetModel, Worksheet, WorksheetView } from "../shared/worksheet.model";
import { WorksheetUtilities } from "../shared/worksheet.utils";
import { LegacyWorksheetActionType } from "./legacy-worksheet-action-type";
import { WorksheetAction } from "./legacy-worksheet.actions";

export type LegacyWorksheetFeatureState = AppState &
    Readonly<{
        legacyWorksheets: LegacyWorksheetState;
    }>;

export interface LegacyWorksheetState extends EntityState<WorksheetView> {
    error?: Error;
    loading: boolean;
    firstLoadPerformed: boolean;
    selectedWorksheetId: string;
    defaultWorksheet: CreateWorksheetModel;
    defaultWorksheetGridOptionsSet: boolean;
    defaultWorksheetQueriesSet: boolean;
    currentWorksheet: CreateWorksheetModel;
    currentWorksheetSaveable: boolean;
    savingWorksheetId: string;
    savingWorksheetError?: Error;
    worksheetTypeToShow: GridType;
    fullLoadComplete: boolean;
    loadingEditedSavedWorksheet: boolean;
}

export const adapter: EntityAdapter<WorksheetView> = createEntityAdapter<WorksheetView>({
    selectId: (worksheet: WorksheetView) => worksheet.worksheetId
});

export const initialState: LegacyWorksheetState = adapter.getInitialState({
    loading: false,
    firstLoadPerformed: false,
    selectedWorksheetId: null,
    defaultWorksheet: new CreateWorksheetModel("", [], [], null, null),
    defaultWorksheetGridOptionsSet: false,
    defaultWorksheetQueriesSet: false,
    currentWorksheet: new CreateWorksheetModel("", [], [], null, null),
    currentWorksheetSaveable: false,
    savingWorksheetId: null,
    worksheetTypeToShow: null,
    fullLoadComplete: false,
    loadingEditedSavedWorksheet: false
});

export const legacyWorksheetReducer = (state = initialState, action: WorksheetAction): LegacyWorksheetState => {
    switch (action.type) {
        case LegacyWorksheetActionType.GetAll: {
            return {
                ...state,
                loading: !state.firstLoadPerformed,
                error: null,
                worksheetTypeToShow: action.payload.type,
                defaultWorksheetGridOptionsSet: false,
                defaultWorksheetQueriesSet: false,
                currentWorksheet: {
                    ...state.currentWorksheet,
                    worksheetType: action.payload.type
                },
                fullLoadComplete: false
            };
        }

        case LegacyWorksheetActionType.Save: {
            const savingWorksheetId = state.savingWorksheetId && state.savingWorksheetError ? state.savingWorksheetId : Math.random().toString();
            const worksheet = new Worksheet(
                savingWorksheetId,
                0,
                action.payload.name,
                state.currentWorksheet.queries,
                state.currentWorksheet.columns,
                state.currentWorksheet.sort,
                DateTime.local(),
                state.currentWorksheet.worksheetType
            );
            const worksheetView = new WorksheetView(worksheet);
            worksheetView.saving = true;
            worksheetView.error = null;
            return adapter.addOne(worksheetView, {
                ...state,
                savingWorksheetId,
                savingWorksheetError: null,
                currentWorksheet: { ...state.currentWorksheet, name: action.payload.name }
            });
        }

        case LegacyWorksheetActionType.Update: {
            const update: Update<WorksheetView> = { id: state.selectedWorksheetId, changes: { saving: true, error: null, updatedDate: DateTime.local() } };
            return adapter.updateOne(update, { ...state });
        }

        case LegacyWorksheetActionType.Remove:
        case LegacyWorksheetActionType.Rename: {
            const update: Update<WorksheetView> = { id: action.payload.id, changes: { saving: true } };
            return adapter.updateOne(update, { ...state });
        }

        case LegacyWorksheetActionType.GetAllSuccess: {
            const sortedWorksheets = [...action.payload.worksheets].sort((a, b) => a.name.localeCompare(b.name));
            return adapter.addMany(sortedWorksheets, { ...state, loading: false, firstLoadPerformed: true });
        }

        case LegacyWorksheetActionType.SaveSuccess: {
            const update: Update<WorksheetView> = {
                id: state.savingWorksheetId,
                changes: {
                    worksheetId: action.payload.worksheetId,
                    name: action.payload.worksheet.name,
                    columns: action.payload.worksheet.columns,
                    queries: action.payload.worksheet.queries,
                    sort: action.payload.worksheet.sort,
                    saving: false,
                    updatedDate: DateTime.local()
                }
            };

            // If the saved worksheet type does not match the current state worksheet type
            // then a grid switch happened after initiating this save.
            // Update state related properties if the grid type did not change
            if (action.payload.worksheet.worksheetType === state.worksheetTypeToShow) {
                return adapter.updateOne(update, {
                    ...state,
                    currentWorksheetSaveable: false,
                    selectedWorksheetId: action.payload.worksheetId,
                    savingWorksheetId: null,
                    savingWorksheetError: null
                });
            }

            return adapter.updateOne(update, {
                ...state
            });
        }

        case LegacyWorksheetActionType.UpdateSuccess: {
            const update: Update<WorksheetView> = {
                id: state.selectedWorksheetId,
                changes: {
                    columns: action.payload.columns,
                    queries: action.payload.queries,
                    sort: action.payload.sort,
                    saving: false
                }
            };

            // Update state related properties if the grid type did not change
            if (state.worksheetTypeToShow === action.payload.worksheetType) {
                return adapter.updateOne(update, { ...state, currentWorksheetSaveable: false });
            }

            return adapter.updateOne(update, { ...state });
        }

        case LegacyWorksheetActionType.RenameSuccess: {
            return adapter.updateOne(action.payload.update, { ...state });
        }

        case LegacyWorksheetActionType.RemoveSuccess: {
            const newState = adapter.removeOne(action.payload.id, state);
            if (state.selectedWorksheetId !== action.payload.id) {
                return newState;
            }
            const currentWorksheet = new CreateWorksheetModel(
                "",
                state.defaultWorksheet.queries,
                state.defaultWorksheet.columns,
                state.defaultWorksheet.sort,
                state.defaultWorksheet.worksheetType
            );
            return { ...newState, selectedWorksheetId: null, currentWorksheetSaveable: false, currentWorksheet };
        }

        case LegacyWorksheetActionType.GetAllFail: {
            return { ...state, error: action.payload.error, loading: false };
        }

        case LegacyWorksheetActionType.SaveFail: {
            const update: Update<WorksheetView> = {
                id: state.savingWorksheetId,
                changes: {
                    name: action.payload.worksheet.name,
                    columns: action.payload.worksheet.columns,
                    queries: action.payload.worksheet.queries,
                    sort: action.payload.worksheet.sort,
                    error: { source: action.type, error: action.payload.error },
                    saving: false
                }
            };

            // Update state related properties if the grid type did not change
            if (action.payload.worksheet.worksheetType === state.worksheetTypeToShow) {
                return adapter.updateOne(update, { ...state, savingWorksheetError: action.payload.error });
            }

            return adapter.updateOne(update, { ...state });
        }

        case LegacyWorksheetActionType.UpdateFail:
        case LegacyWorksheetActionType.RemoveFail:
        case LegacyWorksheetActionType.RenameFail: {
            const update: Update<WorksheetView> = {
                id: action.payload.id,
                changes: { error: { source: action.type, error: action.payload.error }, saving: false }
            };
            return adapter.updateOne(update, { ...state });
        }

        case LegacyWorksheetActionType.SelectSuccess: {
            const worksheet = state.entities[action.payload.id];

            if (!worksheet) {
                return {
                    ...state,
                    selectedWorksheetId: action.payload.id,
                    currentWorksheetSaveable: false,
                    savingWorksheetError: null
                };
            }

            // Set the selected worksheet as the current worksheet
            const currentWorksheet = new CreateWorksheetModel(worksheet.name, worksheet.queries, worksheet.columns, worksheet.sort, worksheet.worksheetType);
            return { ...state, selectedWorksheetId: action.payload.id, currentWorksheetSaveable: false, savingWorksheetError: null, currentWorksheet };
        }

        case LegacyWorksheetActionType.DeselectSuccess: {
            const currentWorksheet = new CreateWorksheetModel(
                "",
                state.defaultWorksheet.queries,
                state.defaultWorksheet.columns,
                state.defaultWorksheet.sort,
                state.defaultWorksheet.worksheetType
            );
            return { ...state, selectedWorksheetId: null, currentWorksheetSaveable: false, currentWorksheet };
        }

        case LegacyWorksheetActionType.SetDefaultWorksheetGridOptions: {
            return {
                ...state,
                defaultWorksheetGridOptionsSet: true,
                currentWorksheet: {
                    ...state.currentWorksheet,
                    columns: action.payload.columns,
                    sort: action.payload.sort,
                    worksheetType: state.worksheetTypeToShow
                },
                defaultWorksheet: {
                    ...state.defaultWorksheet,
                    columns: action.payload.columns,
                    sort: action.payload.sort,
                    worksheetType: state.worksheetTypeToShow
                }
            };
        }

        case LegacyWorksheetActionType.SetDefaultWorksheetQueries: {
            return {
                ...state,
                defaultWorksheetQueriesSet: true,
                currentWorksheet: {
                    ...state.currentWorksheet,
                    queries: action.payload.queries
                },
                defaultWorksheet: {
                    ...state.defaultWorksheet,
                    queries: action.payload.queries
                }
            };
        }

        case LegacyWorksheetActionType.UpdateCurrentWorksheetColumns: {
            if (!R.equals(state.currentWorksheet.columns, action.payload.columns)) {
                return {
                    ...state,
                    currentWorksheet: {
                        ...state.currentWorksheet,
                        columns: action.payload.columns
                    }
                };
            }
            return state;
        }

        case LegacyWorksheetActionType.UpdateCurrentWorksheetSort: {
            if (!R.equals(state.currentWorksheet.sort, action.payload.sort)) {
                return {
                    ...state,
                    currentWorksheet: {
                        ...state.currentWorksheet,
                        sort: action.payload.sort
                    }
                };
            }
            return state;
        }

        case LegacyWorksheetActionType.UpdateCurrentWorksheetQueries: {
            return {
                ...state,
                currentWorksheet: {
                    ...state.currentWorksheet,
                    queries: action.payload.queries
                }
            };
        }

        case LegacyWorksheetActionType.UpdateCurrentWorksheetSaveable: {
            return { ...state, currentWorksheetSaveable: action.payload.savable };
        }

        case LegacyWorksheetActionType.SetRename: {
            const update: Update<WorksheetView> = { id: action.payload.id, changes: { renaming: true } };
            return adapter.updateOne(update, { ...state });
        }

        case LegacyWorksheetActionType.CancelRename: {
            const update: Update<WorksheetView> = { id: action.payload.id, changes: { renaming: false, error: null } };
            return adapter.updateOne(update, { ...state });
        }

        case LegacyWorksheetActionType.RemoveErroredWorksheet: {
            const newState = adapter.removeOne(action.payload.id, state);
            return { ...newState, savingWorksheetId: null, savingWorksheetError: null };
        }

        case LegacyWorksheetActionType.PerformGridSetup: {
            const selectedWorksheet = state.entities[state.selectedWorksheetId];
            if (selectedWorksheet) {
                let selectedWorksheetId: string = null;
                let currentWorksheet: CreateWorksheetModel;

                if (selectedWorksheet.worksheetType === state.worksheetTypeToShow) {
                    currentWorksheet = new CreateWorksheetModel(
                        selectedWorksheet.name,
                        selectedWorksheet.queries,
                        selectedWorksheet.columns,
                        selectedWorksheet.sort,
                        selectedWorksheet.worksheetType
                    );
                    selectedWorksheetId = selectedWorksheet.worksheetId;
                } else {
                    currentWorksheet = new CreateWorksheetModel("", [], [], null, state.worksheetTypeToShow);
                }

                return { ...state, selectedWorksheetId, currentWorksheetSaveable: false, currentWorksheet };
            }

            return state;
        }

        case LegacyWorksheetActionType.LocalStorageQueryPerformed: {
            const selectedWorksheet = state.entities[action.payload.worksheetId];
            const currentWorksheet = action.payload.currentWorksheet;
            if (selectedWorksheet) {
                const currentWorksheetSaveable = !WorksheetUtilities.areWorksheetsSame(selectedWorksheet, currentWorksheet);

                return {
                    ...state,
                    selectedWorksheetId: action.payload.worksheetId,
                    currentWorksheet: action.payload.currentWorksheet,
                    currentWorksheetSaveable,
                    savingWorksheetError: null,
                    fullLoadComplete: true,
                    loadingEditedSavedWorksheet: currentWorksheetSaveable
                };
            } else if (currentWorksheet) {
                return { ...state, currentWorksheet: action.payload.currentWorksheet, fullLoadComplete: true };
            }

            return { ...state, fullLoadComplete: true };
        }

        case LegacyWorksheetActionType.EditedSavedWorksheetLoadCompleted: {
            return { ...state, loadingEditedSavedWorksheet: false };
        }

        default: {
            return state;
        }
    }
};

export const getSelectedWorksheetId = (state: LegacyWorksheetState) => state.selectedWorksheetId;
export const getloadingStatus = (state: LegacyWorksheetState) => state.loading;
export const getError = (state: LegacyWorksheetState) => state.error;
export const getCurrentWorksheetSaveable = (state: LegacyWorksheetState) => state.currentWorksheetSaveable;
export const getCurrentWorksheet = (state: LegacyWorksheetState) => state.currentWorksheet;
export const getFullLoadComplete = (state: LegacyWorksheetState) => state.fullLoadComplete;
export const getSavingWorksheetError = (state: LegacyWorksheetState) => state.savingWorksheetError;
export const getWorksheetTypeToShow = (state: LegacyWorksheetState) => state.worksheetTypeToShow;
export const getDefaultWorksheetSet = (state: LegacyWorksheetState) => state.defaultWorksheetQueriesSet && state.defaultWorksheetGridOptionsSet;
export const getLoadingEditedSavedWorksheetStatus = (state: LegacyWorksheetState) => state.loadingEditedSavedWorksheet;

export const { selectIds: selectWorksheetIds, selectEntities: selectWorksheetEntities, selectAll: selectAllWorksheets, selectTotal: selectWorksheetTotal } = adapter.getSelectors();
