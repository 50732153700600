import { Injectable } from "@angular/core";
import { CommandHandler } from "src/app/fixture/mediator/command-handlers/command-handler";
import { Fixture } from "src/app/fixture/shared/models";
import { Option } from "src/app/fixture/shared/models/dtos/option.dto";
import { deepCopy } from "src/app/shared/utils/deep-copy";
import { CloneOptionCommand } from "./clone-option.command";

@Injectable({
    providedIn: "root"
})
export class CloneOptionCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: CloneOptionCommand) {
        const index = command.payload as number;
        if (index === undefined || index === null || isNaN(index)) {
            throw new Error("Given option index is invalid.");
        }

        const option = fixture.options[index];
        if (!option) {
            throw new Error("Unable to find option with a given index.");
        }

        const copy = deepCopy(option) as Option;
        copy.exercised = false;

        fixture.options.push(copy);
    }
}
