<div [formGroup]="laytimeTermsForm">
    <div class="row no-gutters">
        <div class="col-1">
            <div class="radio">
                <input type="radio" formControlName="laytimeType" id="isNonFixedLaytime" name="laytimeType" value="Non-Fixed" />
                <label for="isNonFixedLaytime" class="radio-label radio-text">Non-fixed</label>
            </div>
            <div class="radio">
                <input type="radio" formControlName="laytimeType" id="isFixedLaytime" name="laytimeType" value="Fixed" />
                <label for="isFixedLaytime" class="radio-label radio-text">Fixed</label>
            </div>
        </div>
        <div class="col-3">
            <div class="row no-gutters">
                <div class="form-group d-flex align-items-end checkbox-container">
                    <input id="isOnDemurrage" type="checkbox" name="isOnDemurrage" formControlName="isOnDemurrage" data-test-is-on-demurrage />
                    <label for="isOnDemurrage">Once on demurrage, always on demurrage</label>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="form-group col-6">
                    <label for="demurrageRate">Demurrage Rate {{ displayFormat }}</label>
                    <input
                        formControlName="demurrageRate"
                        id="demurrageRate"
                        type="text"
                        [opsNumber]="{ precision: 3, minValue: 0 }"
                        class="form-control text-right"
                        data-test-demrrage-rate
                    />
                </div>
                <div class="form-group col-6" *opsDivisions="ratesDivisions">
                    <label for="detentionRate">Detention Rate {{ displayFormat }}</label>
                    <input
                        formControlName="detentionRate"
                        id="detentionRate"
                        type="text"
                        [opsNumber]="{ precision: 3, minValue: 0 }"
                        class="form-control text-right"
                        data-test-detention-rate
                    />
                </div>
            </div>
            <div class="row no-gutters">
                <div class="form-group col-6">
                    <div>
                        <label>Laytime Allowed (Hours)</label>
                        <input
                            [readonly]="isNonReversibleLaytime"
                            formControlName="fixedLaytime"
                            class="form-control text-right"
                            type="text"
                            [opsNumber]="{ precision: 2, minValue: 0 }"
                            data-test-fixed-laytime
                        />
                    </div>
                </div>
                <div class="form-group col-6 " [class.specialised-rounding]="fixture.division.id !== Division.dryCargo && fixture.division.id !== Division.gas">
                    <div><label for="Rounding">Rounding</label></div>
                    <div class="radio">
                        <input type="radio" formControlName="laytimeRounding" id="exact" name="laytimeRounding" value="exact" />
                        <label for="exact" class="radio-label radio-text">Exact</label>
                    </div>
                    <div class="radio">
                        <input type="radio" formControlName="laytimeRounding" id="up" name="laytimeRounding" value="up" />
                        <label for="up" class="radio-label radio-text">Up</label>
                    </div>
                    <div class="radio">
                        <input type="radio" formControlName="laytimeRounding" id="down" name="laytimeRounding" value="down" />
                        <label for="down" class="radio-label radio-text">Down</label>
                    </div>
                </div>
                <div class="form-group col-6 d-flex align-items-end checkbox-container" *opsDivisions="Division.pcg">
                    <input formControlName="demurrageBankEnabled" type="checkbox" id="demurrageBankEnabled" name="demurrageBankEnabled" data-test-demurrage-bank-enabled />
                    <label for="demurrageBankEnabled">Demurrage Bank</label>
                </div>
            </div>
        </div>
        <div class="col-2 despatch">
            <div class="row no-gutters">
                <div class="form-group col-12" *opsDivisions="ratesDivisions">
                    <label for="despatchRate">Despatch Rate {{ displayFormat }}</label>
                    <input
                        formControlName="despatchRate"
                        id="despatchRate"
                        type="text"
                        [opsNumber]="{ precision: 3, minValue: 0 }"
                        class="form-control text-right"
                        data-test-despatch-rate
                    />
                </div>
            </div>
            <div class="row no-gutters">
                <div class="form-group col-12" *opsDivisions="ratesDivisions">
                    <div><label for="timeSavedLabel">Calculate Time Saved</label></div>
                    <div class="radio">
                        <input type="radio" formControlName="timeSavedType" id="allTimeSaved" name="timeSavedType" value="allTimeSaved" />
                        <label for="allTimeSaved" class="radio-label radio-text">All Time Saved</label>
                    </div>
                    <div class="radio">
                        <input type="radio" formControlName="timeSavedType" id="workingTimeSaved" name="timeSavedType" value="workingTimeSaved" />
                        <label for="workingTimeSaved" class="radio-label radio-text">All Working Time Saved</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group col-6 d-flex flex-column">
            <label>Comments</label>
            <textarea formControlName="laytimeTermsComments" id="laytimeTermsComments" maxlength="1000" class="form-control flex-grow-1" data-test-laytimeterms-comments></textarea>
        </div>
    </div>
</div>
