<div class="search-bar-container">
    <div (click)="onSearchIconClick()" class="has-icon icon--search search-button" data-test-gridsearchbutton></div>
    <div class="search-button-separator"></div>
    <ng-select
        #ngSelectComponent
        [multiple]="defaultConfig.multiSelect"
        [addTag]="defaultConfig.addTag"
        [addTagText]="defaultConfig.addTagText"
        [placeholder]="defaultConfig.placeholderText"
        [selectOnTab]="defaultConfig.selectOnTabClick"
        [hideSelected]="defaultConfig.hideSelected"
        [closeOnSelect]="defaultConfig.closeOnSelect"
        [items]="suggestedItems | async"
        (change)="onSelectionChange($event)"
        [typeahead]="suggestionSubject"
        [markFirst]="false"
        [(ngModel)]="selection"
        [loading]="isLoading | async"
        data-cy="search-suggestions"
    >
        <ng-template *ngIf="optionsTemplate" ng-option-tmp let-item="item">
            <ng-container *ngTemplateOutlet="optionsTemplate; context: { item: item }"></ng-container>
        </ng-template>

        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span container="body" [ngbTooltip]="item" class="ng-value-label" data-test-gridsearchpill>
                {{ item }}
            </span>
            <span class="ng-value-icon has-icon icon--cancel right" (click)="clear(item)" aria-hidden="true" data-test-pillclosebutton></span>
        </ng-template>
    </ng-select>
    <ops-worksheet-save-button (save)="save.emit()" (saveAsNew)="handleSaveAsNew()" [disable]="!(currentWorksheetSavable$ | async)" [showDropdown]="!namingNewWorksheet && (hasSelectedWorksheet$ | async)"></ops-worksheet-save-button>
</div>
