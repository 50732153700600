<ngb-datepicker
    (dateSelect)="onDateSelectedOnCalendar($event)"
    [navigation]="actualNavigation"
    [displayMonths]="actualDisplayMonths"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dayTemplate]="rangeDay"
    [footerTemplate]="footer"
    [outsideDays]="outsideDays"
    [startDate]="defaultFocusDate"
    navigation="arrows"
>
</ngb-datepicker>

<ng-template #rangeDay let-date="date" let-currentMonth="currentMonth" let-disabled="disabled" let-focused="focused">
    <div
        opsDatePickerDayView
        [date]="date"
        [today]="today"
        [currentMonth]="currentMonth"
        [selected]="isBoundary(date)"
        [rangeStart]="fromDate"
        [rangeEnd]="toDate || hoveredDate"
        [disabled]="disabled"
        [focused]="focused"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
    ></div>
</ng-template>

<ng-template #footer>
    <div *ngIf="useTimepicker || canToggleRange" class="ops-dp-footer" [class.single]="isSingle">
        <ops-icon-button
            *ngIf="canToggleRange"
            type="action"
            icon="date-range"
            tooltip="Toggle Date Range"
            (click)="toggleRange()"
        ></ops-icon-button>

        <ng-container *ngIf="useTimepicker">
            <div class="ops-dp-time" data-test-from-date *ngIf="isRange">
                <span class="ops-dp-footer-label">From</span>
                <span class="ops-dp-footer-value">{{ fromDateFormatted }}</span>
                <ops-timepicker
                    [spinners]="false"
                    size="medium"
                    [ngModel]="fromTime"
                    (change)="onFromTimeChange($event)"
                    (click)="processFromTimeInputClick($event)"
                    (blur)="fromTimeSelection = null"
                ></ops-timepicker>
            </div>
            <div class="ops-dp-time" data-test-to-date>
                <span class="ops-dp-footer-label" *ngIf="isRange">To</span>
                <span class="ops-dp-footer-value">{{ toDateFormatted }}</span>
                <ops-timepicker
                    [spinners]="false"
                    size="medium"
                    [ngModel]="toTime"
                    (change)="onToTimeChange($event)"
                    (click)="processToTimeInputClick($event)"
                    (blur)="toTimeSelection = null"
                ></ops-timepicker>
            </div>
        </ng-container>

        <div class="flex-spacer"></div>

        <button class="btn btn-sm btn-action apply-button" type="button" (click)="close.emit()">
            <span>APPLY</span>
        </button>
    </div>
</ng-template>
