import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { SharedModule } from "@ops/shared";

import { LegacyWorksheetRenameFormComponent } from "./legacy-component/legacy-worksheet-rename-form.component";
import { WorksheetsLegacyComponent } from "./legacy-component/worksheets-legacy.component";
import { LegacyWorksheetEffects } from "./store/legacy-worksheet.effects";
import { legacyWorksheetReducer } from "./store/legacy-worksheet.reducer";
import { legacyWorksheetsFeatureKey } from "./store/legacy-worksheet.selectors";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        ReactiveFormsModule,
        StoreModule.forFeature(legacyWorksheetsFeatureKey, legacyWorksheetReducer),
        EffectsModule.forFeature([LegacyWorksheetEffects])
    ],
    declarations: [WorksheetsLegacyComponent, LegacyWorksheetRenameFormComponent],
    exports: [WorksheetsLegacyComponent, LegacyWorksheetRenameFormComponent]
})
export class WorksheetsModule {}
