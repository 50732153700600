import * as R from "ramda";

import { AssociatedCargoForm, BerthForm, CargoItem } from "../model";

export declare type BerthSummary = Readonly<{
    berthName: string;
    activities: { activity: string; cargoes: string }[];
}>;

function getGroupedCargoes(cargoes: ReadonlyArray<CargoItem>, associatedCargoes: ReadonlyArray<AssociatedCargoForm>, activity: { activity: string; cargoes: string[] }) {
    const cargoProducts = getCargoes(cargoes, associatedCargoes);
    const newCargoes: string[] = activity.cargoes;
    cargoProducts.forEach((cargo) => {
        if (newCargoes.every((c) => c !== cargo)) {
            newCargoes.push(cargo);
        }
    });

    return newCargoes;
}

function getCargoes(cargoes: ReadonlyArray<CargoItem>, associatedCargoes: ReadonlyArray<AssociatedCargoForm>) {
    const cargoList: string[] = [];
    if (associatedCargoes) {
        associatedCargoes.forEach((ac) => {
            if (ac.cargoId) {
                const foundCargo = cargoes.find((c) => c.cargoId === ac.cargoId); //had to parse int this, as it sometimes is a string when it comes in
                if (foundCargo && foundCargo.name) {
                    cargoList.push(foundCargo.name);
                }
            }
        });
    }

    return cargoList;
}

export const getBerthActivityList = (cargoes: ReadonlyArray<CargoItem>, berth: BerthForm) => {
    const activities: { activity: string; cargoes: string[] }[] = [];

    berth.activities
        .filter((cba) => cba.type && cba.type.value)
        .forEach((cba) => {
            const existingActivity = activities.find((a: { activity: string; cargoes: string[] }) => a.activity === cba.type.value.name);
            if (existingActivity) {
                existingActivity.cargoes = getGroupedCargoes(cargoes, cba.associatedCargoes, existingActivity);
            } else {
                activities.push({ activity: cba.type.value.name, cargoes: getCargoes(cargoes, cba.associatedCargoes) });
            }
        });

    return activities;
};

export const mapActivity = (source: { activity: string; cargoes: string[] }) => ({ activity: source.activity, cargoes: R.uniq(source.cargoes).join("/") });

function getActivities(cargoes: ReadonlyArray<CargoItem>, berth: BerthForm) {
    const activities = getBerthActivityList(cargoes, berth);
    return activities.map(mapActivity);
}

function getBerthName(berth: BerthForm, berthIndex: number, berthCount: number): string {
    const name = berth.name ? berth.name.trim() : "";
    return berthCount === 1 ? name : `${berthIndex + 1}. ${name.length ? name : "Untitled Berth"}`;
}

export const getBerthSummary = (cargoes: ReadonlyArray<CargoItem>, berth: BerthForm, berthIndex: number, berthCount: number): BerthSummary => ({
    berthName: getBerthName(berth, berthIndex, berthCount),
    activities: getActivities(cargoes, berth)
});
