<div class="quantity-input-wrapper input-group">
    <input
        #input
        [readonly]="readonly || disabled"
        type="text"
        class="form-control text-right value-input"
        (blur)="onBlur()"
        (focus)="focus.emit()"
        (change)="onInputChange()"
    />
    <ops-refdata-dropdown
        [type]="type"
        [options]="dropdownValues"
        [placeholder]="placeholder"
        [sector]="coaSector"
        [allowClear]="allowClear"
        [readonly]="readonly"
        [bindValue]="dropdownBindValue"
        [appendTo]="appendTo"
        isQuantityInputDropdown="true"
        (blur)="onBlur()"
        (focus)="focus.emit()"
        (change)="onDropdownChange($event)"
    >
    </ops-refdata-dropdown>
</div>
