import { on, On } from "@ngrx/store";
import { DateTime } from "luxon";
import * as R from "ramda";
import { Evolver } from "ramda";

import { VesselHistoricalState } from "../../../left-bar/lifting-history/lifting-history";
import {
    CoasState,
    EntityChanges,
    HistoricalEventType,
    isAcceptedOrPreferred,
    isPreferred,
    isRenominationPending,
    LiftingId,
    LiftingVesselPlanStatus,
    User,
    VesselHistoricalEvent,
    VesselHistoricalEventName,
    VesselId,
    VesselNomination,
    VesselNominationStatus
} from "../model";
import { defineProperty } from "../utils";
import { liftingStateReducer } from "./reducer";
import { removeVesselNominationSuccessAction } from "./vessel-nomination/form/remove-vessel-nomination";
import { renominateNewVesselSuccessAction, saveVesselNominationSuccessAction, updateVesselNominationSuccessAction } from "./vessel-nomination/form/save-vessel-nomination";
import {
    acceptVesselNominationSuccessAction,
    preferVesselNominationSuccessAction,
    rejectVesselNominationSuccessAction,
    renominateVesselSuccessAction,
    reviewVesselNominationSuccessAction
} from "./vessel-nomination/update-status";

const actionToEventMapping: { [action: string]: { eventName: VesselHistoricalEventName; eventType: HistoricalEventType } } = {
    [saveVesselNominationSuccessAction.type]: {
        eventName: "LiftingVesselNominationAddedV1",
        eventType: "Add"
    },
    [removeVesselNominationSuccessAction.type]: {
        eventName: "LiftingVesselNominationRemovedV1",
        eventType: "Remove"
    },
    [updateVesselNominationSuccessAction.type]: {
        eventName: "LiftingVesselNominationUpdatedV1",
        eventType: "Update"
    },
    [acceptVesselNominationSuccessAction.type]: {
        eventName: "LiftingVesselNominationAcceptedV1",
        eventType: "Update"
    },
    [rejectVesselNominationSuccessAction.type]: {
        eventName: "LiftingVesselNominationRejectedV1",
        eventType: "Update"
    },
    [reviewVesselNominationSuccessAction.type]: {
        eventName: "LiftingVesselNominationUnderReviewV1",
        eventType: "Update"
    },
    [preferVesselNominationSuccessAction.type]: {
        eventName: "LiftingVesselNominationPreferredV1",
        eventType: "Update"
    },
    [renominateNewVesselSuccessAction.type]: {
        eventName: "LiftingVesselRenominatedV1",
        eventType: "Add"
    },
    [renominateVesselSuccessAction.type]: {
        eventName: "LiftingVesselRenominatedV1",
        eventType: "Update"
    }
};

const nominationStatusByEventMapping: { [action: string]: VesselNominationStatus } = {
    [acceptVesselNominationSuccessAction.type]: "Accepted",
    [rejectVesselNominationSuccessAction.type]: "Rejected",
    [reviewVesselNominationSuccessAction.type]: "Under Review",
    [preferVesselNominationSuccessAction.type]: "Preferred",
    [renominateVesselSuccessAction.type]: "Preferred"
};

const updateVesselNominationHistory = (
    type: string,
    liftingId: LiftingId,
    state: CoasState,
    user: User,
    vesselId: VesselId,
    changes?: EntityChanges,
    vesselPlanStatus?: LiftingVesselPlanStatus | undefined
) => {
    const date = DateTime.utc().toISO();
    const { eventName, eventType } = actionToEventMapping[type];
    const historicalEvent: VesselHistoricalEvent = {
        name: eventName,
        type: eventType,
        entityId: vesselId,
        changes,
        date,
        user
    };
    let vesselPlanStatusEvent: VesselHistoricalEvent;
    if (vesselPlanStatus) {
        vesselPlanStatusEvent = {
            name: "LiftingVesselPlanStatusChangedV1",
            type: "Update",
            changes: { "/vesselPlanStatus": vesselPlanStatus },
            date,
            user
        };
    }
    const updateFns: Evolver = {
        vesselNominationHistory: (events) => (vesselPlanStatusEvent ? [...events, historicalEvent, vesselPlanStatusEvent] : [...events, historicalEvent])
    };
    return liftingStateReducer(state, liftingId, R.evolve(updateFns));
};

export const updateVesselNominationHistoryByVesselRemovalReducer: On<CoasState> = on(
    removeVesselNominationSuccessAction,
    (state: CoasState, { type, vesselId, liftingId, user }) => {
        const vessels = state.liftings.byId[state.currentLiftingId].lifting.vessels;
        const index = vessels.findIndex((v) => v.vesselId === vesselId);
        const updatedVessels: ReadonlyArray<VesselNomination> = R.remove(index, 1, vessels);
        return updateVesselNominationHistory(type, liftingId, state, user, vesselId, {}, getVesselPlanStatus(updatedVessels));
    }
);

export const updateVesselNominationHistoryByVesselIdReducer: On<CoasState> = on(
    acceptVesselNominationSuccessAction,
    reviewVesselNominationSuccessAction,
    (state: CoasState, { type, vesselId, liftingId, user }) => {
        const vessels = state.liftings.byId[state.currentLiftingId].lifting.vessels;
        const { changes, vesselPlanStatus } = getChangesWithVesselId(type, vessels, vesselId);
        return updateVesselNominationHistory(type, liftingId, state, user, vesselId, changes, vesselPlanStatus);
    }
);

export const updateVesselNominationHistoryByVesselRejectionReducer: On<CoasState> = on(
    rejectVesselNominationSuccessAction,
    (state: CoasState, { type, vesselId, liftingId, user, reason }) => {
        const vessels = state.liftings.byId[state.currentLiftingId].lifting.vessels;
        const { changes, vesselPlanStatus } = getChangesWithVesselId(type, vessels, vesselId, reason);
        return updateVesselNominationHistory(type, liftingId, state, user, vesselId, changes, vesselPlanStatus);
    }
);

export const updateVesselNominationHistoryWithPreferredVesselReducer: On<CoasState> = on(
    preferVesselNominationSuccessAction,
    renominateVesselSuccessAction,
    (state: CoasState, { type, vesselId, liftingId, user, reason }) => {
        const vessels = state.liftings.byId[state.currentLiftingId].lifting.vessels;
        const { changes, vesselPlanStatus } = getChangesWithVesselId(type, vessels, vesselId, reason);
        vessels.forEach((v, i) => {
            if (v.vesselNominationStatus === "Preferred") {
                defineProperty(changes, `/vessels/${i}/vesselNominationStatus`, "Accepted");
            }
        });
        return updateVesselNominationHistory(type, liftingId, state, user, vesselId, changes, vesselPlanStatus);
    }
);

export const saveVesselNominationHistoryByVesselReducer: On<CoasState> = on(saveVesselNominationSuccessAction, (state: CoasState, { type, vessel, liftingId, user }) => {
    const vessels = state.liftings.byId[state.currentLiftingId].lifting.vessels;
    const changes = getChangesWithVessel(vessels, {
        ...vessel,
        vesselNominationStatus: "Under Review"
    });
    return updateVesselNominationHistory(type, liftingId, state, user, vessel.vesselId, changes);
});

export const updateVesselNominationHistoryByVesselReducer: On<CoasState> = on(updateVesselNominationSuccessAction, (state: CoasState, { type, vessel, coaId, liftingId, user }) => {
    const liftingVessels = state.liftings.byId[liftingId].lifting.vessels;
    const liftingVessel = liftingVessels.find((v) => v.vesselId === vessel.vesselId);
    const shouldChangeVesselStatus = !vessel.laycan && ["Accepted", "Preferred"].includes(liftingVessel?.vesselNominationStatus) && state.coas.byId[coaId].coa?.driver === "Vessel";
    const changes = getChangedPropertiesWithVessel(liftingVessels, shouldChangeVesselStatus ? { ...vessel, vesselNominationStatus: "Under Review" } : vessel);
    const shouldResetPlan = shouldChangeVesselStatus && !liftingVessels.filter((v) => v.vesselId !== vessel.vesselId).some((v) => v.vesselNominationStatus !== "Under Review");
    return updateVesselNominationHistory(type, liftingId, state, user, vessel.vesselId, changes, shouldResetPlan ? "Tentative" : undefined);
});

export const updateVesselNominationHistoryByVesselRenominationReducer: On<CoasState> = on(
    renominateNewVesselSuccessAction,
    (state: CoasState, { type, vessel, liftingId, user, reason }) => {
        const vessels = state.liftings.byId[state.currentLiftingId].lifting.vessels;
        const changes = getChangesWithVessel(
            vessels,
            {
                ...vessel,
                vesselNominationStatus: "Renomination Pending"
            },
            reason
        );
        const preferredIndex = vessels.findIndex(isPreferred);
        if (preferredIndex >= 0) {
            defineProperty(changes, `/vessels/${preferredIndex}/vesselNominationStatus`, "Accepted");
        }
        return updateVesselNominationHistory(type, liftingId, state, user, vessel.vesselId, changes, "Renomination Pending");
    }
);

const getChangesWithVesselId = (type: string, vessels: ReadonlyArray<VesselNomination>, vesselId: VesselId, reason?: string) => {
    const newVesselNominationStatus = nominationStatusByEventMapping[type];
    const index = vessels.findIndex((v) => v.vesselId === vesselId);
    const changes = {};
    if (reason) {
        defineProperty(changes, "_changeReason", reason);
    }
    if (!newVesselNominationStatus || index < 0) {
        return { changes };
    }
    const updatedVessels = R.assocPath([index, "vesselNominationStatus"], newVesselNominationStatus, vessels);
    defineProperty(changes, `/vessels/${index}/vesselNominationStatus`, newVesselNominationStatus);
    return {
        changes,
        vesselPlanStatus: getVesselPlanStatus(updatedVessels)
    };
};

const getVesselPlanStatus = (vessels: ReadonlyArray<VesselNomination>): LiftingVesselPlanStatus =>
    vessels.some(isRenominationPending) ? "Renomination Pending" : vessels.some(isAcceptedOrPreferred) ? "Firmed" : "Tentative";

const getChangesWithVessel = (vessels: ReadonlyArray<VesselNomination>, vessel: Partial<VesselNomination>, reason?: string) => {
    let index = vessels.findIndex((v) => v.vesselId === vessel.vesselId);
    if (index < 0) {
        index = vessels.length;
    }
    const changes = {};
    defineProperty(changes, `/vessels/${index}`, JSON.stringify(toVesselHistoricalState(vessel)));
    if (reason) {
        defineProperty(changes, "_changeReason", reason);
    }
    return changes;
};

const getChangedPropertiesWithVessel = (vessels: ReadonlyArray<VesselNomination>, vessel: Partial<VesselNomination>) => {
    let index = vessels.findIndex((v) => v.vesselId === vessel.vesselId);
    if (index < 0) {
        index = vessels.length;
    }
    const changes = {};
    const vesselHistoricalState = toVesselHistoricalState(vessel);
    for (const key in vesselHistoricalState) {
        if (vesselHistoricalState.hasOwnProperty(key)) {
            const value = vesselHistoricalState[key as keyof VesselHistoricalState];
            const valueType = typeof value;
            defineProperty(changes, `/vessels/${index}/${key}`, valueType !== "string" ? JSON.stringify(value) : (value as string));
        }
    }
    return changes;
};

export const toVesselHistoricalState = (vessel: Partial<VesselNomination>) => {
    const result = {
        ...vessel,
        estimatedTimeOfDeparture: vessel.etd
    };
    delete result.etd;
    return result;
};
