import { isNullOrUndefined } from "@ops/shared";

import { computeMD5 } from "./utils";

export class WarningHashBuilder {
    private readonly separator = "::";

    private pathItems: Record<string, unknown> = {
        fixture: undefined,
        voyage: undefined,
        cargo: undefined,
        destination: undefined,
        berth: undefined,
        berthActivity: undefined,
        associatedCargo: undefined,
        associatedCargoExpense: undefined,
        hire: undefined,
        additionalFreightExpense: undefined,
        demurrageClaim: undefined,
        category: undefined,
        message: undefined
    };

    withFixture(fixtureId: string) {
        this.pathItems.fixture = fixtureId;
        return this;
    }

    withVoyage(voyageId: string) {
        this.pathItems.voyage = voyageId;
        return this;
    }

    withCargo(cargoId: number) {
        this.pathItems.cargo = cargoId;
        return this;
    }

    withDestination(destinationId: number) {
        this.pathItems.destination = destinationId;
        return this;
    }

    withBerth(berthId: number) {
        this.pathItems.berth = berthId;
        return this;
    }

    withBerthActivity(activityId: number) {
        this.pathItems.berthActivity = activityId;
        return this;
    }

    withAssociatedCargo(associatedCargoId: number) {
        this.pathItems.associatedCargo = associatedCargoId;
        return this;
    }

    withAssociatedCargoExpense(associatedCargoExpenseId: number) {
        this.pathItems.associatedCargoExpense = associatedCargoExpenseId;
        return this;
    }

    withHire(hireId: string) {
        this.pathItems.hire = hireId;
        return this;
    }

    withAdditionalFreightExpense(additionalFreightExpenseId: number) {
        this.pathItems.additionalFreightExpense = additionalFreightExpenseId;
        return this;
    }

    withDemurrageClaim(demurrageClaimId: number) {
        this.pathItems.demurrageClaim = demurrageClaimId;
        return this;
    }

    withCategory(category: string) {
        this.pathItems.category = category;
        return this;
    }

    withMessage(message: string) {
        this.pathItems.message = message;
        return this;
    }

    build() {
        const path = Object.keys(this.pathItems)
            .filter((key) => !isNullOrUndefined(this.pathItems[key]))
            .map((key) => `${key}${this.separator}${this.pathItems[key]}`)
            .join(this.separator)
            .toLowerCase();
        return computeMD5(path);
    }
}
