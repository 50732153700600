import { shareReplay } from "rxjs/operators";

export function memoizeObservable(fn: any) {
    const cache = new Map<string, any>();

    return (...a: any[]) => {
        const key = JSON.stringify(a);

        if (cache.has(key)) {
            return cache.get(key);
        } else {
            const obs = fn(...a).pipe(shareReplay(1));
            cache.set(key, obs);
            return obs;
        }
    };
}
