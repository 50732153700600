import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { FixtureHeader } from "../../state";

@Component({
    selector: "ops-fixture-header",
    templateUrl: "./fixture-header.component.html",
    styleUrls: ["./fixture-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixtureHeaderComponent {
    @Input() fixtureHeader: FixtureHeader;
}
