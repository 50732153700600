import { Command } from "../../../../mediator/commands/command";
import { ActivityId, BerthId, DestinationId } from "../../../../state/model";
import { DeleteBunkerCommandHandler } from "./delete-bunker.command-handler";

export class DeleteBunkerCommand extends Command {
    constructor(destinationId: DestinationId, berthId: BerthId, berthActivityId: ActivityId, index: number) {
        super(DeleteBunkerCommandHandler, {
            destinationId,
            berthId,
            berthActivityId,
            index
        });
    }
}
