import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DateTime } from "luxon";
import { BehaviorSubject, Observable } from "rxjs";

import { AppInsightsService } from "../../core/app-insights.service";
import { Notification, NotificationType } from "./notification.model";

/**
 * Generic (non feature/entity specific) error message for when the server returns
 * a validation error.
 */
export const DATA_VALIDATION_ERROR_MESSAGE = "The server returned a data validation error. Please contact Sea/Support.";
export const DATA_ACCESS_ERROR_MESSAGE = "You do not have access to open this application. Please contact Sea/Support.";
export const GENERIC_ERROR_MESSAGE = "Something went wrong. Please contact Sea/Support.";

@Injectable({ providedIn: "root" })
export class NotificationService {
    private notificationSubject = new BehaviorSubject<Notification[]>([]);

    get notifications$(): Observable<Notification[]> {
        return this.notificationSubject.asObservable();
    }

    constructor(router: Router, private appInsightsMonitoringService: AppInsightsService) {}

    error(title?: string, message?: string, dismissible?: boolean): void {
        this.addNotification(NotificationType.Error, title, message, undefined, undefined, dismissible ?? false);
    }

    warn(title?: string, message?: string): void {
        this.addNotification(NotificationType.Warn, title, message);
    }

    success(title?: string, message?: string, timeout?: number): void {
        this.addNotification(NotificationType.Success, title, message, timeout);
    }

    info(title?: string, message?: string, timeout?: number, showLoadingAnimation?: boolean): void {
        this.addNotification(NotificationType.Info, title, message, timeout, showLoadingAnimation);
    }

    versionUpdate(title?: string, message?: string, minor?: boolean): void {
        const minorVersionUpdate = minor ?? true;

        const currentNotifications = this.notificationSubject.getValue();
        const majorNotification = currentNotifications.find((n) => n.type === NotificationType.VersionUpdateMajor);
        const minorNotification = currentNotifications.find((n) => n.type === NotificationType.VersionUpdateMinor);

        if (majorNotification || (minorVersionUpdate && minorNotification)) {
            return;
        }

        if (!minorVersionUpdate && !majorNotification && minorNotification) {
            this.remove(minorNotification);
        }

        this.addNotification(
            minorVersionUpdate ? NotificationType.VersionUpdateMinor : NotificationType.VersionUpdateMajor,
            title,
            message,
            undefined,
            undefined,
            minorVersionUpdate
        );
        this.appInsightsMonitoringService.logInfo("Version Update Notification Displayed");
    }

    remove(notification: Notification) {
        let currentNotifications = this.notificationSubject.getValue();
        currentNotifications = currentNotifications.filter((n) => n !== notification);
        this.notificationSubject.next(currentNotifications);
    }

    private addNotification(type: NotificationType, title?: string, message?: string, timeout?: number, showLoadingAnimation?: boolean, dismissible: boolean = true) {
        const notification = new Notification(`${title}`, message, type, timeout, showLoadingAnimation, DateTime.utc(), dismissible);
        let currentNotifications = this.notificationSubject.getValue();

        // If we're adding an Info type, get rid of all the other Info notifications to avoid
        // stacking/doubling up
        if (type === NotificationType.Info) {
            currentNotifications = currentNotifications.filter((x) => !(x.type === NotificationType.Info && x.timeout > 0));
        }

        currentNotifications.push(notification);
        this.notificationSubject.next(currentNotifications);

        if (timeout) {
            setTimeout(() => {
                const currentNotificationsForRemoval = this.notificationSubject.getValue();
                const index = currentNotificationsForRemoval.indexOf(notification);
                if (index >= 0) {
                    currentNotificationsForRemoval.splice(index, 1);
                    this.notificationSubject.next(currentNotificationsForRemoval);
                }
            }, timeout);
        }
    }
}
