import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { ActivityType, CargoTermsId, LaytimeCalculationCargoTermsForm, LaytimeCalculationCargoTermsListItem, LocationId } from "../../state";

@Component({
    selector: "ops-laytime-calculation-cargo-terms",
    templateUrl: "./laytime-calculation-cargo-terms.component.html",
    styleUrls: ["./laytime-calculation-cargo-terms.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationCargoTermsComponent {
    @Input() cargoTerms: ReadonlyArray<LaytimeCalculationCargoTermsListItem>;
    @Input() currentCargoTermId: CargoTermsId;
    @Input() cargoTermsForm: LaytimeCalculationCargoTermsForm;

    @Output() readonly edit = new EventEmitter<CargoTermsId>();
    @Output() readonly cancelEdit = new EventEmitter();
    @Output() readonly save = new EventEmitter();
    @Output() readonly remove = new EventEmitter<CargoTermsId>();
    @Output() readonly removeLocation = new EventEmitter<{ activityType: ActivityType; locationId: LocationId }>();

    trackBy(_index: number, item: LaytimeCalculationCargoTermsListItem) {
        return item.id;
    }

    isEditing(id: CargoTermsId) {
        return id === this.currentCargoTermId;
    }

    handleEdit(id: CargoTermsId) {
        if (!this.currentCargoTermId) {
            this.edit.emit(id);
        }
    }

    getCargoTermsForm(id: CargoTermsId) {
        return id === this.currentCargoTermId ? this.cargoTermsForm : null;
    }
}
