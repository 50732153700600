import { Injectable, Injector, Type } from "@angular/core";
import { Voyage } from "src/app/fixture/shared/models";
import { Fixture } from "../shared/models";
import { CommandHandler } from "./command-handlers/command-handler";
import { Command } from "./commands/command";

@Injectable({
    providedIn: "root"
})
export class Mediator {
    /**
     *
     */
    constructor(private injector: Injector) {}

    send(fixture: Fixture | Voyage, command: Command): void | Promise<void> {
        if (!command) {
            return;
        }

        if (command.commandHandler) {
            const commandHandler = this.injector.get(<Type<CommandHandler>>command.commandHandler);
            return commandHandler.handle(fixture, command);
        }
    }
}
