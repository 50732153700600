import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroupState } from "ngrx-forms";

import { daysOfWeek, Sector } from "@ops/shared";
import { CustomNgrxValueConverters } from "@ops/state";

import { DateRange } from "../../../fixture/shared/models";
import { ActivityLocationForm, workingDays } from "../../state";

@Component({
    selector: "ops-laytime-calculation-activity-location-form",
    templateUrl: "./laytime-calculation-activity-location-form.component.html",
    styleUrls: ["./laytime-calculation-activity-location-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationActivityLocationFormComponent {
    readonly workingDays = workingDays;
    readonly daysOfWeek = daysOfWeek;
    readonly dateRangeValueConverter = CustomNgrxValueConverters.dateRangeToLocalDateTimeString;

    @Input() form: FormGroupState<ActivityLocationForm>;
    @Input() sector: Sector;
    @Input() hasNonReversibleCargoes: boolean;
    @Input() isFixed: boolean;
    @Input() isAlwaysOnDemurrage: boolean;
    @Input() laytimeEventRange: DateRange;

    @Output() readonly prefillLaytimeRange = new EventEmitter<DateRange>();

    get isExclusionVisible() {
        return this.isShex || this.isShexUU;
    }

    get isShex() {
        return this.form?.value.workingDay === "SHEX";
    }

    get isShexUU() {
        return this.form?.value.workingDay === "SHEX UU";
    }

    get isCustomaryQuickDespatchVisible() {
        return !this.isFixed && this.sector === "Dry Cargo" && this.hasNonReversibleCargoes;
    }
}
