<div class="ops-ltc-summary-container" *ngIf="summary$ | async as summary">
    <div class="ops-ltc-result">
        <div class="ops-ltc-result-value">
            <span class="label">{{summary.claimType || 'Claim'}} Value:</span>
            <span class="value" data-cy="claim-value-calculation">{{summary.claimValue | currency: summary.currency}}</span>
        </div>
        <div class="ops-ltc-result-value">
            <span class="label">Allowed:</span>
            <div class="value-wrapper">
                <span class="value" data-cy="laytime-allowed-calculation">{{summary.laytimeAllowed | durationFormat: (durationUnit$ | async)}}</span>
                <div class="laytime-type" *ngIf="summary.hasReversible && !!summary.laytimeAllowed">
                    <span class="has-icon icon--info-outline" ngbTooltip="Times displayed here are for Reversible laytime." container="body" data-cy="information"></span>
                </div>
            </div>
        </div>
        <div class="ops-ltc-result-value">
            <span class="label">Used:</span>
            <div class="value-wrapper">
                <span class="value" data-cy="laytime-used-calculation">{{summary.laytimeUsed | durationFormat: (durationUnit$ | async)}}</span>
            </div>
        </div>
        <div class="ops-ltc-result-value">
            <span class="label">Remaining:</span>
            <div class="value-wrapper">
                <span class="value" data-cy="laytime-remaining-calculation" [class.negative]="summary.laytimeRemaining < 0">{{summary.laytimeRemaining | durationFormat: (durationUnit$ | async)}}</span>
            </div>
        </div>
    </div>
    <div class="locations-block">
        <ops-laytime-calculation-summary-locations
            [ltcId]="ltcId$ | async"
            [isFixed]="summary.isFixed"
            [currency]="summary.currency"
            [locations]="summary.locations"
            [claimValue]="summary.claimValue"
            [claimType]="summary.claimType"
            [lastReversibleLocationId]="summary.lastReversibleLocationId"
            [hasDemurrage]="summary.hasDemurrage"
            [hasDespatch]="summary.hasDespatch"
            [hasDetention]="summary.hasDetention"
            [durationUnit]="durationUnit$ | async"
        ></ops-laytime-calculation-summary-locations>
    </div>
</div>
