import { on, On } from "@ngrx/store";
import { updateVesselStatuses } from "../../services/voyage-vessel-status-calculator";
import { FixtureType } from "../../shared/models";
import { addDestination, cloneDestination, moveDestinationDown, moveDestinationUp, removeDestination } from "../destinations";
import { FixturesState, isFixtureType, VoyageState } from "../model";
import { currentVoyageStateReducer } from "../voyage/reducer";

/**
 * Interop. Updates the cargo status of bunker consumptions on destination list change.
 *
 * Must appear in reducer after other action reducers.
 */
export const updateBunkerConsumptionsCargoStatusesReducer: On<FixturesState> = on(
    addDestination,
    cloneDestination,
    moveDestinationUp,
    moveDestinationDown,
    removeDestination,
    (state) =>
        currentVoyageStateReducer(state, (voyageState, fixtureState) => {
            const workingVoyage = voyageState.workingVoyage;

            if (!isFixtureType(fixtureState, FixtureType.TimeCharter) || !workingVoyage) {
                return voyageState;
            }

            const updatedAtSeaBunkerConsumptions = updateVesselStatuses(voyageState.form.value.destinations, workingVoyage.atSeaBunkersConsumption);

            if (updatedAtSeaBunkerConsumptions === workingVoyage.atSeaBunkersConsumption) {
                return voyageState;
            }

            return <VoyageState>{
                ...voyageState,
                workingVoyage: {
                    ...workingVoyage,
                    atSeaBunkersConsumption: updatedAtSeaBunkerConsumptions
                }
            };
        })
);
