import { createAction, on, On, props } from "@ngrx/store";
import { createFormGroupState } from "ngrx-forms";
import { currentCoaStateReducer } from "../../coa/reducer";
import { CargoId, cargoToForm, CoasState, getCurrentCoaState, getNewCargoId } from "../../model";

/* ACTIONS */
export const cloneCargoAction = createAction("[Coa Cargo Form] Clone Cargo", props<{ cargoId: CargoId }>());

/* REDUCERS */
export const cloneCargoReducer: On<CoasState> = on(cloneCargoAction, (state, { cargoId }) => {
    const currentCoa = getCurrentCoaState(state);
    const cargo = currentCoa.coa.cargoes.find((c) => c.cargoId === cargoId);

    if (!cargo) {
        return state;
    }

    const form = { ...cargoToForm(cargo), cargoId: getNewCargoId() };

    return currentCoaStateReducer(state, (coaState) => ({
        ...coaState,
        cargoForm: createFormGroupState(form.cargoId, form)
    }));
});
