import { parseISODate } from "src/app/shared/date-utils/date-utilities";
import { ExpenseClaim } from "../shared/models";

export class ExpenseSortingUtility {
    static sortExpenses(expenseArray: ExpenseClaim[]) {
        return expenseArray.sort((a, b) => {
            if (parseISODate(a.receivedFromOwnerDate) && parseISODate(b.receivedFromOwnerDate)) {
                if (parseISODate(a.receivedFromOwnerDate) < parseISODate(b.receivedFromOwnerDate)) {
                    return 1;
                }
                if (parseISODate(a.receivedFromOwnerDate) > parseISODate(b.receivedFromOwnerDate)) {
                    return -1;
                }
            } else if (parseISODate(a.receivedFromOwnerDate)) {
                return -1;
            } else if (parseISODate(b.receivedFromOwnerDate)) {
                return 1;
            }

            if (a.expenseClaimId > b.expenseClaimId) {
                return 1;
            } else {
                return -1;
            }
        });
    }
}
