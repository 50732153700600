<div [formGroup]="berthStatementOfFactsForm" class="card">
    <div class="card-header pl-0 pr-0 row no-gutters">
        <div class="d-flex w-100">
            <button
                type="button"
                (click)="toggleStatementOfFactsBerth()"
                class="has-icon"
                [class.icon--arrow-drop-right]="hideStatementOfFactsBerth"
                [class.icon--arrow-drop-down]="!hideStatementOfFactsBerth"
                data-test-toggle-berth-button
            ></button>
            <div class="d-flex w-100 berth-summary" data-test-berthnamesummary>
                <div class="ribbon-item berth">{{ berth.name }}</div>
                <div class="ribbon-item" [hidden]="isFixedLaytimeType || !statementOfFactsModel.allowedTime">
                    Allowed: {{ statementOfFactsModel.allowedTime | durationFormat: displayUnitType }}
                </div>
                <div class="ribbon-item" [hidden]="!statementOfFactsModel.usedTime">Used: {{ statementOfFactsModel.usedTime | durationFormat: displayUnitType }}</div>
                <div class="ribbon-item" [hidden]="isFixedLaytimeType || !statementOfFactsModel.remainingTime">
                    Remaining: {{ statementOfFactsModel.remainingTime | durationFormat: displayUnitType }}
                </div>
                <div class="ribbon-item" *ngIf="claim.claimValue > 0">
                    <span *ngIf="claim.claimType !== DemurrageClaimType.Despatch" class="demurrage-due">DEMURRAGE DUE</span>
                    <span *ngIf="claim.claimType === DemurrageClaimType.Despatch" class="demurrage-due">TIME SAVED</span>
                    <span class="ecv-display">{{ claim.claimValue | currency: fixture.currency?.code:symbol:"1.2-2" }}</span>
                </div>
                <div *ngIf="laytimeCommencedDate && laytimeCompletedDate" class="col text-right">Laytime Range: {{ laytimeCommencedDate }} - {{ laytimeCompletedDate }}</div>
            </div>
        </div>
    </div>

    <div class="card-body p-0" [hidden]="hideStatementOfFactsBerth">
        <ops-associated-cargoes-statement-of-facts
            [parentForm]="berthStatementOfFactsForm"
            [model]="statementOfFactsModel.cargoAllowedRates"
            [destinationId]="destinationId"
            [division]="fixture.division"
            [formName]="'cargo-allowed-rates-grid'"
            [displayUnitType]="displayUnitType"
            (associatedCargoAllowedRatesUpdated)="updateStatementOfFacts($event)"
        >
        </ops-associated-cargoes-statement-of-facts>

        <div *ngIf="loadAndDischargeActivities.length > 0" class="form-row">
            <div *ngFor="let control of activitiesForm.controls; let i = index" class="activity-row">
                <div class="col pl-0 pr-0">
                    <ops-laytime-event-facts
                        [berthActivityId]="loadAndDischargeActivities[i].id"
                        [fixture]="fixture"
                        [destinationId]="destinationId"
                        [berthId]="berth.id"
                        [parentForm]="control"
                        [locationTimezone]="locationTimezone"
                        [model]="loadAndDischargeActivities[i].laytimeEventFacts"
                        [cargoTabLaytimeEvents]="loadAndDischargeActivities[i].laytimeEvents"
                        [cargoes]="associatedCargoCargoes"
                        [formName]="'laytime-event-fact-grid'"
                        (laytimeEventsUpdated)="updateStatementOfFacts($event)"
                    >
                    </ops-laytime-event-facts>
                </div>
            </div>
        </div>
    </div>
</div>
