import { addLiftingCompanyFailReducer, addLiftingCompanyReducer } from "./form/add-company";
import { focusLiftingCompanyRoleReducer, initLiftingContactsPanelReducer } from "./form/contacts-panel";
import { removeLiftingCompanyFailReducer, removeLiftingCompanyReducer } from "./form/remove-company";

export default [
    initLiftingContactsPanelReducer,
    focusLiftingCompanyRoleReducer,
    addLiftingCompanyReducer,
    addLiftingCompanyFailReducer,
    removeLiftingCompanyReducer,
    removeLiftingCompanyFailReducer
];
