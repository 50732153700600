import { AbstractControl, ValidatorFn } from "@angular/forms";

export class ClaimValueValidator {
    static validate(demurrageBankEnabled?: boolean): ValidatorFn {
        return (control: AbstractControl): null => {
            if (!demurrageBankEnabled) {
                const initialClaimValueControl = control.get("initialClaimValue");
                const interimClaimValueControl = control.get("interimClaimValue");
                const finalClaimValueControl = control.get("finalClaimValue");

                if (initialClaimValueControl && interimClaimValueControl && finalClaimValueControl) {
                    const initialClaimValue = initialClaimValueControl.value;
                    const interimClaimValue = interimClaimValueControl.value;
                    const finalClaimValue = finalClaimValueControl.value;

                    if (initialClaimValue < 0) {
                        initialClaimValueControl.markAsTouched();
                        initialClaimValueControl.setErrors({ min: { min: 0, actual: initialClaimValue } });
                    }
                    if (interimClaimValue < 0) {
                        interimClaimValueControl.markAsTouched();
                        interimClaimValueControl.setErrors({ min: { min: 0, actual: interimClaimValue } });
                    }
                    if (finalClaimValue < 0) {
                        finalClaimValueControl.markAsTouched();
                        finalClaimValueControl.setErrors({ min: { min: 0, actual: finalClaimValue } });
                    }
                }
            }
            return null;
        };
    }
}
