import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { isNumber } from "@ops/shared";
import { CargoBerthActivityType, LaytimeEventType, TimeBarType } from "@ops/shared/reference-data";

import { parseISODate } from "../../../shared/date-utils/date-utilities";
import { Email } from "../../../shared/email";
import { getDefaultFocusDateForDemurrage } from "../../shared/fixture-destination.utils";
import { FormComponentBase } from "../../shared/form-component-base";
import { Destination, Division, Fixture, LaytimeEvent, Voyage } from "../../shared/models";
import { TimeBarsFormModel } from "../../shared/models/form-models/timebars.model";
import { UpdateTimebarsCommand } from "./commands/update-timebars.command";
import { TimeBarsHttpService } from "./timebars-http.service";

@Component({
    selector: "ops-timebars",
    templateUrl: "./timebars.component.html",
    styleUrls: ["./timebars.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimebarsComponent extends FormComponentBase implements OnInit, OnChanges, OnDestroy {
    static componentName = "TimebarsComponent";

    private demurrageNotifyFromDateSubscription: Subscription;
    private contactsSubscription: Subscription;
    private destinationsSubscription: Subscription;

    noticeGenerated: Date;
    email: Email;
    timeBarsForm: UntypedFormGroup;
    defaultTimeZone: string;
    Division = Division;
    demurrageNotifyExpiryDate: UntypedFormControl;
    demurragePresentExpiryDate: UntypedFormControl;
    expenseNotifyExpiryDate: UntypedFormControl;
    expensePresentExpiryDate: UntypedFormControl;

    @Input()
    parentForm: UntypedFormGroup;
    @Input()
    fixture: Fixture;
    @Input()
    destinations: Destination[];
    @Output()
    timebarsUpdated = new EventEmitter();

    get defaultFocusDate(): Date {
        return getDefaultFocusDateForDemurrage(this.destinations);
    }

    constructor(private timeBarsHttpService: TimeBarsHttpService, private formBuilder: UntypedFormBuilder, private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.setupTimeBarsForm();
        this.noticeGenerated = parseISODate(this.fixture.demurrage.noticeGenerated);
        this.setTimeBarsValues(this.fixture);
        this.parentForm.registerControl("timeBars", this.timeBarsForm);
        this.subscribeToFormValueChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.fixture || changes.fixture.firstChange) {
            return;
        }

        this.removeFormSubscriptions();
        this.setTimeBarsValues(changes.fixture.currentValue);
        this.subscribeToFormValueChanges();
    }

    ngOnDestroy() {
        this.removeFormSubscriptions();
        delete this.parentForm.controls.timeBars;
    }

    getDateFromLastEvent(laytimeEvents: LaytimeEvent[]): Date {
        if (laytimeEvents.length > 0) {
            const resultDate = laytimeEvents.reduce((max, current) => (current.eventDate > max ? current.eventDate : max), laytimeEvents[0].eventDate);
            if (resultDate) {
                return parseISODate(resultDate);
            }
        }
        return null;
    }

    getAllLaytimeEvents(voyage: Voyage, activityType: CargoBerthActivityType): LaytimeEvent[] {
        if (voyage.destinations) {
            const allCargoCompletedLaytimeEvents = voyage.destinations
                .filter((f) => f.berths)
                .map((x) => x.berths.filter((f) => f.cargoBerthActivities.filter((activity) => activity === activityType.id)))
                .reduce((a, b) => a.concat(b), [])
                .map((y) => y.cargoBerthActivities.filter((f) => f.laytimeEvents && f.type && f.type.id === activityType.id))
                .reduce((a, b) => a.concat(b), [])
                .map((z) => z.laytimeEvents)
                .reduce((a, b) => a.concat(b), []);

            return allCargoCompletedLaytimeEvents;
        }
        return [];
    }

    filterCargoCompletedEvents(laytimeEvents: LaytimeEvent[]): LaytimeEvent[] {
        return laytimeEvents.filter((f) => f.type && f.type.id === LaytimeEventType.CargoCompleted.id);
    }

    generateNotice(): void {
        const noticeGenerated = new Date();

        this.timeBarsHttpService.put(this.fixture.fixtureId, noticeGenerated).subscribe(() => {
            this.noticeGenerated = noticeGenerated;
            this.cd.markForCheck();
        });
    }

    protected removeFormSubscriptions(): void {
        super.removeFormSubscriptions();

        if (this.demurrageNotifyFromDateSubscription) {
            this.demurrageNotifyFromDateSubscription.unsubscribe();
            this.demurrageNotifyFromDateSubscription = null;
        }

        if (this.contactsSubscription) {
            this.contactsSubscription.unsubscribe();
            this.contactsSubscription = null;
        }

        if (this.destinationsSubscription) {
            this.destinationsSubscription.unsubscribe();
            this.destinationsSubscription = null;
        }
    }

    protected subscribeToFormValueChanges() {
        this.formValueSubscription = this.timeBarsForm.valueChanges.pipe(debounceTime(10)).subscribe((value) => this.timebarsUpdated.emit(new UpdateTimebarsCommand(value)));
    }

    private setupTimeBarsForm(): void {
        this.demurrageNotifyExpiryDate = new UntypedFormControl();
        this.demurrageNotifyExpiryDate.disable();

        this.demurragePresentExpiryDate = new UntypedFormControl();
        this.demurragePresentExpiryDate.disable();

        this.expenseNotifyExpiryDate = new UntypedFormControl();
        this.expenseNotifyExpiryDate.disable();

        this.expensePresentExpiryDate = new UntypedFormControl();
        this.expensePresentExpiryDate.disable();

        this.timeBarsForm = this.formBuilder.group({}, { updateOn: "blur" });
        this.timeBarsForm.registerControl("demurrageNotifyFromDate", this.formBuilder.control(""));
        this.timeBarsForm.registerControl("demurrageNotifyDaysForExpiring", this.formBuilder.control(""));
        this.timeBarsForm.registerControl("demurragePresentFromDate", this.formBuilder.control(""));
        this.timeBarsForm.registerControl("demurragePresentDaysForExpiring", this.formBuilder.control(""));
        this.timeBarsForm.registerControl("expenseNotifyFromDate", this.formBuilder.control(""));
        this.timeBarsForm.registerControl("expenseNotifyDaysForExpiring", this.formBuilder.control(""));
        this.timeBarsForm.registerControl("expensePresentFromDate", this.formBuilder.control(""));
        this.timeBarsForm.registerControl("expensePresentDaysForExpiring", this.formBuilder.control(""));

        this.timeBarsForm[this.parentForm.disabled ? "disable" : "enable"]();
    }

    private setTimeBarsValues(fixture: Fixture): void {
        const timebarsFormValues: TimeBarsFormModel = {};

        let demurrageNotify = fixture.demurrage.timeBars.find((x) => x.type.id === TimeBarType.DemurrageNotify.id);
        if (!demurrageNotify) {
            demurrageNotify = { type: TimeBarType.DemurrageNotify };
        }
        timebarsFormValues.demurrageNotifyFromDate = parseISODate(demurrageNotify.fromDate);
        timebarsFormValues.demurrageNotifyDaysForExpiring = demurrageNotify.numberOfDays;
        if (timebarsFormValues.demurrageNotifyFromDate && isNumber(timebarsFormValues.demurrageNotifyDaysForExpiring)) {
            this.demurrageNotifyExpiryDate.setValue(DateTime.fromJSDate(timebarsFormValues.demurrageNotifyFromDate).plus({ days: demurrageNotify.numberOfDays }).toJSDate());
        } else {
            this.demurrageNotifyExpiryDate.setValue(null);
        }

        let demurragePresent = fixture.demurrage.timeBars.find((x) => x.type.id === TimeBarType.DemurragePresent.id);
        if (!demurragePresent) {
            demurragePresent = { type: TimeBarType.DemurragePresent };
        }
        timebarsFormValues.demurragePresentFromDate = parseISODate(demurragePresent.fromDate);
        timebarsFormValues.demurragePresentDaysForExpiring = demurragePresent.numberOfDays;
        if (timebarsFormValues.demurragePresentFromDate && isNumber(timebarsFormValues.demurragePresentDaysForExpiring)) {
            this.demurragePresentExpiryDate.setValue(DateTime.fromJSDate(timebarsFormValues.demurragePresentFromDate).plus({ days: demurragePresent.numberOfDays }).toJSDate());
        } else {
            this.demurragePresentExpiryDate.setValue(null);
        }

        let expenseNotify = fixture.demurrage.timeBars.find((x) => x.type.id === TimeBarType.ExpenseNotify.id);
        if (!expenseNotify) {
            expenseNotify = { type: TimeBarType.ExpenseNotify };
        }
        timebarsFormValues.expenseNotifyFromDate = parseISODate(expenseNotify.fromDate);
        timebarsFormValues.expenseNotifyDaysForExpiring = expenseNotify.numberOfDays;
        if (timebarsFormValues.expenseNotifyFromDate && isNumber(timebarsFormValues.expenseNotifyDaysForExpiring)) {
            this.expenseNotifyExpiryDate.setValue(DateTime.fromJSDate(timebarsFormValues.expenseNotifyFromDate).plus({ days: expenseNotify.numberOfDays }).toJSDate());
        } else {
            this.expenseNotifyExpiryDate.setValue(null);
        }

        let expensePresent = fixture.demurrage.timeBars.find((x) => x.type.id === TimeBarType.ExpensePresent.id);
        if (!expensePresent) {
            expensePresent = { type: TimeBarType.ExpensePresent };
        }
        timebarsFormValues.expensePresentFromDate = parseISODate(expensePresent.fromDate);
        timebarsFormValues.expensePresentDaysForExpiring = expensePresent.numberOfDays;
        if (timebarsFormValues.expensePresentFromDate && isNumber(timebarsFormValues.expensePresentDaysForExpiring)) {
            this.expensePresentExpiryDate.setValue(DateTime.fromJSDate(timebarsFormValues.expensePresentFromDate).plus({ days: expensePresent.numberOfDays }).toJSDate());
        } else {
            this.expensePresentExpiryDate.setValue(null);
        }

        this.timeBarsForm.patchValue(timebarsFormValues, { emitEvent: false });
    }
}
