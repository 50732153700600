import { Injectable } from "@angular/core";
import { validate as validateUuid } from "uuid";

import { Enumeration } from "../../../../../shared/reference-data";
import { CommandHandler } from "../../../../mediator";
import { Voyage } from "../../../../shared/models";
import { ActivityId, BerthId, DestinationId } from "../../../../state/model";
import { AddBunkersCommand } from "./add-bunkers.command";

@Injectable({
    providedIn: "root"
})
export class AddBunkersCommandHandler implements CommandHandler {
    handle(voyage: Voyage, { payload }: AddBunkersCommand) {
        const destinationId = payload["destinationId"] as DestinationId;
        if (!destinationId || !validateUuid(destinationId)) {
            throw new Error("A given destination ID is invalid.");
        }

        if (!voyage.destinations) {
            throw new Error("Unable to update activity because no destinations exist.");
        }

        const destinationToUpdate = voyage.destinations.find((d) => d.id === destinationId);
        if (!destinationToUpdate) {
            throw new Error("A destination with given ID does not exist.");
        }

        const berthId = payload["berthId"] as BerthId;
        if (!berthId || !validateUuid(berthId)) {
            throw new Error("A given berth ID is invalid.");
        }

        if (!destinationToUpdate.berths) {
            throw new Error("Unable to update activity because no berths exist.");
        }

        const berthToUpdate = destinationToUpdate.berths.find((b) => b.id === berthId);
        if (!berthToUpdate) {
            throw new Error("A berth with given ID does not exist.");
        }

        const berthActivityId = payload["berthActivityId"] as ActivityId;
        if (!berthActivityId || !validateUuid(berthActivityId)) {
            throw new Error("A given berth activity ID is invalid.");
        }

        if (!berthToUpdate.cargoBerthActivities) {
            throw new Error("Unable to update activity because no activities exist.");
        }

        const activityToUpdate = berthToUpdate.cargoBerthActivities.find((a) => a.id === berthActivityId);
        if (!activityToUpdate) {
            throw new Error("An activity with given ID does not exist.");
        }

        activityToUpdate.bunkersRemainingOnboard = activityToUpdate.bunkersRemainingOnboard || [];

        if (payload.bunkerTypes.length) {
            payload.bunkerTypes.forEach((type: Enumeration) => {
                activityToUpdate.bunkersRemainingOnboard.push({
                    pricePerMt: null,
                    quantityAtArrival: null,
                    quantityAtSailing: null,
                    type,
                    isBunkered: null
                });
            });

            return;
        }

        activityToUpdate.bunkersRemainingOnboard.push({
            pricePerMt: null,
            quantityAtArrival: null,
            quantityAtSailing: null,
            type: null,
            isBunkered: null
        });
    }
}
