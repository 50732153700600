import { On, on } from "@ngrx/store";
import * as R from "ramda";

import { removeVesselNominationSuccessAction } from "./form/remove-vessel-nomination";
import { renominateNewVesselSuccessAction, updateVesselNominationSuccessAction } from "./form/save-vessel-nomination";
import { acceptVesselNominationSuccessAction, rejectVesselNominationSuccessAction, renominateVesselSuccessAction, reviewVesselNominationSuccessAction } from "./update-status";
import { coaLiftingsStateReducer } from "../../coa/reducer";
import { CoasState, CoaState, isAcceptedOrPreferred, isRenominationPending, LiftingVesselPlanStatus } from "../../model";
import { liftingStateReducer } from "../reducer";

/* REDUCERS */
export const updateVesselPlanStatusReducer: On<CoasState> = on(
    acceptVesselNominationSuccessAction,
    reviewVesselNominationSuccessAction,
    rejectVesselNominationSuccessAction,
    removeVesselNominationSuccessAction,
    renominateVesselSuccessAction,
    renominateNewVesselSuccessAction,
    updateVesselNominationSuccessAction,
    (state, { coaId, liftingId }) => {
        let vesselPlanStatus: LiftingVesselPlanStatus;
        const withUpdatedLiftingState = liftingStateReducer(state, liftingId, (liftingState) => {
            vesselPlanStatus = liftingState.lifting.vessels.some(isRenominationPending)
                ? "Renomination Pending"
                : liftingState.lifting.vessels.some(isAcceptedOrPreferred)
                ? "Firmed"
                : "Tentative";
            return R.assocPath(["lifting", "vesselPlanStatus"], vesselPlanStatus, liftingState);
        });

        const getLiftings = (coaState: CoaState) => coaState.fetchedLiftings.map((l) => (l.documentId !== liftingId ? l : { ...l, vesselPlanStatus }));

        return coaLiftingsStateReducer(withUpdatedLiftingState, coaId, getLiftings);
    }
);
