import { Injectable } from "@angular/core";
import { CommandHandler } from "src/app/fixture/mediator";
import { Voyage } from "src/app/fixture/shared/models";
import { getActivity } from "src/app/shared/voyage-utils/voyage-utilities";
import { RemoveAllLaytimeEventFactsCommand } from "./remove-all-laytime-event-facts.command";

@Injectable({
    providedIn: "root"
})
export class RemoveAllLaytimeEventFactsCommandHandler implements CommandHandler {
    handle(voyage: Voyage, command: RemoveAllLaytimeEventFactsCommand) {
        const activityToUpdate = getActivity(voyage, command.destinationId, command.berthId, command.berthActivityId);

        activityToUpdate.laytimeEventFacts = [];
        activityToUpdate.laytimeEvents.forEach((e) => {
            e.isSynced = false;
        });
    }
}
