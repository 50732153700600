import { Directive, ElementRef, Inject, Input, OnInit } from "@angular/core";
import { ActionsSubject } from "@ngrx/store";
import { Actions, FormControlState, FormControlValueTypes, SetUserDefinedPropertyAction } from "ngrx-forms";

import { SCROLL_INTO_VIEW_USER_PROPERTY } from "@ops/state";

import { scrollIntoView } from "../utils/scroll-helper";

@Directive({
    selector: "[opsNgrxScrollIntoView]"
})
export class NgrxScrollIntoViewDirective<TStateValue extends FormControlValueTypes> implements OnInit {
    private isInitialized = false;

    state: FormControlState<TStateValue>;

    @Input("opsNgrxScrollIntoView") set opsNgrxScrollIntoView(newState: FormControlState<TStateValue>) {
        if (typeof newState === "object") {
            this.ngrxFormControlState = newState;
        }
    }

    @Input() set ngrxFormControlState(newState: FormControlState<TStateValue>) {
        if (!newState) {
            throw new Error("The control state must not be undefined");
        }

        const oldState = this.state;
        this.state = newState;

        if (this.isInitialized) {
            this.scrollIfIsScrolledIntoViewChanged(newState, oldState);
        }
    }

    @Input() scrollOffset = -40;

    constructor(private el: ElementRef, @Inject(ActionsSubject) private actionsSubject: ActionsSubject | null) {}

    private scrollAndDispatch() {
        scrollIntoView(this.el.nativeElement, this.scrollOffset);
        this.dispatchAction(new SetUserDefinedPropertyAction(this.state.id, SCROLL_INTO_VIEW_USER_PROPERTY, undefined));
    }

    private scrollIfIsScrolledIntoViewChanged(newState: FormControlState<TStateValue>, oldState: FormControlState<TStateValue> | undefined) {
        if (oldState && newState.userDefinedProperties[SCROLL_INTO_VIEW_USER_PROPERTY] === oldState.userDefinedProperties[SCROLL_INTO_VIEW_USER_PROPERTY]) {
            return;
        }

        if (newState.userDefinedProperties[SCROLL_INTO_VIEW_USER_PROPERTY] === true) {
            this.scrollAndDispatch();
        }
    }

    private dispatchAction(action: Actions<TStateValue>) {
        if (this.actionsSubject !== null) {
            this.actionsSubject.next(action);
        } else {
            throw new Error("ActionsSubject must be present in order to dispatch actions");
        }
    }

    ngOnInit() {
        if (!this.state) {
            throw new Error("The form state must not be undefined");
        }

        this.isInitialized = true;

        this.scrollIfIsScrolledIntoViewChanged(this.state, undefined);
    }
}
