import { createAction, on, On } from "@ngrx/store";
import { createFormGroupState, focus, updateGroup } from "ngrx-forms";

import { CoasState, CompanyForm, companyForm, getLiftingFormGroupId } from "../../../model";
import { currentLiftingStateReducer } from "../../reducer";

/* ACTIONS */
export const initLiftingContactsPanelAction = createAction("[Lifting Contacts Panel] Open Panel");
export const focusLiftingCompanyRoleAction = createAction("[Lifting Contacts Panel] Focus Company Role");

/* REDUCERS */
export const initLiftingContactsPanelReducer: On<CoasState> = on(initLiftingContactsPanelAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => {
        const initialForm = liftingState.companyForm || createFormGroupState(getLiftingFormGroupId(state.currentLiftingId), companyForm());
        const form = updateGroup<CompanyForm>({ companyRole: focus })(initialForm);

        return {
            ...liftingState,
            companyForm: form
        };
    })
);

export const focusLiftingCompanyRoleReducer: On<CoasState> = on(focusLiftingCompanyRoleAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => ({
        ...liftingState,
        companyForm: updateGroup<CompanyForm>({ companyRole: focus })(liftingState.companyForm)
    }))
);
