<div [formGroup]="headingForm" class="time-charter-heading">
    <div class="row no-gutters">
        <div class="form-group col-4">
            <label for="operators">Operator ({{ operatorCount }})</label>
            <ops-user-autosuggest
                [multiple]="true"
                [lead]="leadOperator"
                formControlName="operators"
                [fixtureId]="fixture.fixtureId"
                [fixtureSource]="fixture.fixtureSource"
                data-test-operators
            ></ops-user-autosuggest>
        </div>
        <div class="form-group col-2">
            <label for="displayBroker">Gateway Broker</label>
            <ops-user-autosuggest
                [multiple]="false"
                [returnAll]="true"
                [fixtureId]="fixture.fixtureId"
                [fixtureSource]="fixture.fixtureSource"
                formControlName="displayBroker"
                data-test-display-broker
            ></ops-user-autosuggest>
        </div>
        <div class="form-group required" [ngClass]="{ 'col-3': isPcgFixture, 'col-4': !isPcgFixture }">
            <label>Vessel Name</label>
            <div class="d-flex ml-auto flex-row vessel-name-wrap">
                <ops-vessel-autosuggest
                    [multiple]="false"
                    [selection]="fixture.vessel"
                    class="vessel-name"
                    formControlName="vessel"
                    [opsValidationPopover]="vesselValidation"
                    validationPopoverContainer="body"
                    (blur)="removeIsVesselTbn()"
                    data-test-vessel-search
                ></ops-vessel-autosuggest>
                <div class="d-flex">
                    <input
                        id="isVesselTbn"
                        type="checkbox"
                        class="tbn-checkbox"
                        name="isVesselTbn"
                        formControlName="isVesselTbn"
                        (change)="removeVessel()"
                        data-test-is-vessel-tbn
                    />
                    <label for="isVesselTbn" class="tbn-label">TBN</label>
                </div>
            </div>
            <ng-template #vesselValidation>
                <ops-simple-field-validation
                    [userMessages]="{ required: 'Required, or select TBN' }"
                    fieldDisplayName="Vessel Name"
                    [control]="headingForm.controls['vessel']">
                </ops-simple-field-validation>
            </ng-template>
        </div>
        <div class="form-group col-1" *opsDivisions="Division.pcg">
            <label title="Presentation" for="presentationType">Presentation</label>
            <ng-select
                id="presentationType"
                class="ops-select"
                formControlName="presentationType"
                [compareWith]="referenceDataService.compare"
                [items]="presentationTypes$ | async"
                [clearable]="true"
                bindLabel="name"
                [selectOnTab]="true"
                data-test-presentation-type
            >
            </ng-select>
        </div>
        <div class="form-group col-2 required">
            <label for="fixtureStatus">Fixture Status</label>
            <ng-select
                id="fixtureStatus"
                class="ops-select"
                formControlName="fixtureStatus"
                warningFormControl
                [compareWith]="referenceDataService.compare"
                [items]="fixtureStatuses$ | async"
                [clearable]="false"
                bindLabel="name"
                data-test-fixture-status
                [selectOnTab]="true"
            >
            </ng-select>
        </div>
    </div>

    <div class="row no-gutters">
        <div class="form-group col-2 required">
            <label for="laycanFrom">Laycan From</label>
            <ops-date-input
                formControlName="laycanFrom"
                [enableTime]="true"
                timeZone="utc"
                [defaultFocusDate]="fixture.fixtureSource.id === FixtureSource.Ops ? headingForm.controls['cpDate'].value : null"
                [opsValidationPopover]="laycanFromValidation"
                data-test-laycan-from
            ></ops-date-input>
            <ng-template #laycanFromValidation>
                <ops-simple-field-validation
                    fieldDisplayName="Laycan From"
                    [control]="headingForm.controls['laycanFrom']">
                </ops-simple-field-validation>
            </ng-template>
        </div>
        <div class="form-group col-2 required">
            <label for="laycanTo">Laycan To</label>
            <ops-date-input
                formControlName="laycanTo"
                [enableTime]="true"
                timeZone="utc"
                [defaultFocusDate]="fixture.fixtureSource.id === FixtureSource.Ops ? headingForm.controls['cpDate'].value : null"
                [opsValidationPopover]="laycanToValidation"
                data-test-laycan-to
            ></ops-date-input>
            <ng-template #laycanToValidation>
                <ops-simple-field-validation
                    fieldDisplayName="Laycan To"
                    [control]="headingForm.controls['laycanTo']">
                </ops-simple-field-validation>
            </ng-template>
        </div>
        <div class="form-group col-2"
             [ngClass]="{ required: !!headingForm.value.laycanExtFrom || !!headingForm.value.laycanExtTo }">
            <label for="laycanExtFrom">Laycan Ext. From</label>
            <ops-date-input
                timeZone="utc"
                formControlName="laycanExtFrom"
                [enableTime]="true"
                [defaultFocusDate]="headingForm.controls['laycanFrom'].value"
                [opsValidationPopover]="laycanExtFromValidation"
                data-test-laycan-ext-from
            ></ops-date-input>
            <ng-template #laycanExtFromValidation>
                <ops-simple-field-validation
                    fieldDisplayName="Laycan Ext. From"
                    [control]="headingForm.controls['laycanExtFrom']">
                </ops-simple-field-validation>
            </ng-template>
        </div>
        <div class="form-group col-2"
             [ngClass]="{ required: !!headingForm.value.laycanExtFrom || !!headingForm.value.laycanExtTo }">
            <label for="laycanExtTo">Laycan Ext. To</label>
            <ops-date-input
                timeZone="utc"
                formControlName="laycanExtTo"
                [enableTime]="true"
                [defaultFocusDate]="headingForm.controls['laycanTo'].value"
                [opsValidationPopover]="laycanExtToValidation"
                data-test-laycan-ext-to
            ></ops-date-input>
            <ng-template #laycanExtToValidation>
                <ops-simple-field-validation
                    fieldDisplayName="Laycan Ext. To"
                    [control]="headingForm.controls['laycanExtTo']">
                </ops-simple-field-validation>
            </ng-template>
        </div>
        <div class="form-group col-2 required">
            <label for="lastCargoes">Last Cargoes ({{ lastCargoCount }})</label>
            <ops-cargo-autosuggest
                [multiple]="true"
                formControlName="lastCargoes"
                data-test-last-cargoes
            ></ops-cargo-autosuggest>
            </div>
        <div class="form-group col-2">
            <label for="cargo">Cargo</label>
            <ops-cargo-autosuggest
                formControlName="cargo"
                data-test-cargo
            ></ops-cargo-autosuggest>
        </div>
    </div>

    <div class="row no-gutters">
        <div class="form-group col-1 required">
            <label for="cpDate">CP Date</label>
            <ops-date-input
                id="cpDate"
                formControlName="cpDate"
                timeZone="utc"
                [opsValidationPopover]="cpDateValidation"
                data-test-charterparty-date>
            </ops-date-input>
            <ng-template #cpDateValidation>
                <ops-simple-field-validation
                    fieldDisplayName="CP Date"
                    [control]="headingForm.controls['cpDate']">
                </ops-simple-field-validation>
            </ng-template>
        </div>
        <div class="form-group col-1">
            <label for="currency">Currency</label>
            <ng-select
                id="currency"
                class="ops-select"
                formControlName="currency"
                [compareWith]="referenceDataService.compareCurrencies"
                [items]="currencies$ | async"
                [clearable]="false"
                bindLabel="code"
                [selectOnTab]="true"
                data-test-currency
            >
            </ng-select>
        </div>
        <div class="form-group col-2">
            <label for="cpType">CP Type</label>
            <ng-select
                class="ops-select"
                [items]="cpTypes$ | async"
                placeholder="Select CP Type"
                formControlName="charterPartyType"
                [clearable]="true"
                [selectOnTab]="true"
                bindLabel="name"
                id="cpType"
                data-test-charterparty-type
            >
            </ng-select>
        </div>
        <div class="form-group col-4">
            <label for="cpmLink">Charter Party Link</label>
            <input
                id="cpmLink"
                class="form-control"
                formControlName="cpmLink"
                [opsValidationPopover]="cpmLinkValidation"
                data-test-cpmLink/>
            <ng-template #cpmLinkValidation>
                <ops-simple-field-validation
                    [userMessages]="{ pattern: 'Must be a CPM or Recap Manager link. Please include https://' }"
                    fieldDisplayName="Charter Party Link"
                    [control]="headingForm.controls['cpmLink']">
                </ops-simple-field-validation>
            </ng-template>
        </div>
        <div class="form-group col-2">
            <label for="chartererReference">Charterer Ref</label>
            <input
                id="chartererReference"
                class="form-control"
                formControlName="chartererReference"
                data-test-charterer-reference />
        </div>
        <div class="form-group col-2">
            <label for="voyageReference">Voyage Ref</label>
            <input id="voyageReference"
                class="form-control"
                formControlName="voyageReference"
                data-test-voyage-reference />
        </div>
    </div>

    <div class="row no-gutters">
        <div class="col-4">
            <div class="row no-gutters">
                <div class="form-group col-6">
                    <label for="brokerCommission" class="no-space-break">Broker Comm. (%)</label>
                    <input
                        id="brokerCommission"
                        class="form-control text-right"
                        formControlName="brokerCommission"
                        [opsNumber]="{ precision: 3 }"
                        data-test-broker-commission/>
                </div>
                <div class="form-group col-6">
                    <label for="ballastBonus" class="no-space-break">Ballast Bonus</label>
                    <input
                        id="ballastBonus"
                        class="form-control text-right"
                        formControlName="ballastBonus"
                        [opsNumber]="{ precision: 3 }"
                        data-test-ballast-bonus/>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="form-group col-6">
                    <label for="addressCommission" class="no-space-break">Address Comm. (%)</label>
                    <input
                        id="addressCommission"
                        class="form-control text-right"
                        formControlName="addressCommission"
                        [opsNumber]="{ precision: 3 }"
                        data-test-address-commission/>
                </div>
                <div class="form-group col-6 required">
                    <label class="no-space-break" for="managedBy">Ops Managed By</label>
                    <ng-select
                        id="managedBy"
                        class="ops-select"
                        formControlName="managedBy"
                        [compareWith]="referenceDataService.compare"
                        [items]="managedByTypes$ | async"
                        [clearable]="false"
                        bindLabel="name"
                        [selectOnTab]="true"
                        data-test-managed-by
                    >
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="form-group col-4 trading-limits">
            <label class="row no-gutters" for="tradingLimits">Trading Limits</label>
            <textarea
                class="form-control text-field"
                id="tradingLimits"
                rows="2"
                cols="75"
                name="tradingLimits"
                formControlName="tradingLimits"
                maxlength="3000"
                data-test-trading-limits
            ></textarea>
        </div>
        <div class="form-group col-4 d-flex flex-column comments">
            <div class="no-gutters comments-controls"
                 [ngClass]="{ 'important-notice-visible': headingForm.controls.isCommentsImportant.value }">
                <div class="important-notice d-flex" data-test-important-notice>
                    <div class="comments-important-icon has-icon icon--info-outline"></div>
                    <span>IMPORTANT!</span>
                </div>
                <textarea class="form-control"
                          id="comments"
                          rows="2"
                          name="comments"
                          formControlName="comments"
                          maxlength="3000" data-test-comments>
                    </textarea>
            </div>
            <div class="row no-gutters d-flex comments-labels order-first">
                <label for="comments">Comments</label>
                <div class="d-flex ml-auto comments-important">
                    <label for="isCommentsImportant">Important</label>
                    <input
                        type="checkbox"
                        id="isCommentsImportant"
                        name="isCommentsImportant"
                        formControlName="isCommentsImportant"
                        data-test-is-comments-important
                    />
                </div>
            </div>
        </div>
    </div>
</div>
