import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "ops-voyage-update-preview",
    templateUrl: "./voyage-update-preview.component.html",
    styleUrls: ["./voyage-update-preview.component.scss"]
})
export class VoyageUpdatePreviewComponent {
    subject = "Daily updates";

    constructor(@Inject(MAT_DIALOG_DATA) public voyageUpdate: string) {}

    copyToClipboard(content: string): void {
        const listener = (e: ClipboardEvent) => {
            e.clipboardData.setData("text/html", content);
            e.preventDefault();
        };

        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
    }
}
