import { Period } from "src/app/fixture/shared/models";
import { PeriodFormModel } from "src/app/fixture/shared/models/form-models/period.model";
import { parseISODate } from "src/app/shared/date-utils/date-utilities";

export function sortPeriodsByFromDate(p1: Period, p2: Period) {
    const p1periodFrom = parseISODate(p1.periodRange.from);
    const p2periodFrom = parseISODate(p2.periodRange.from);

    const period1Time = p1periodFrom ? p1periodFrom.getTime() : Number.MIN_SAFE_INTEGER;
    const period2Time = p2periodFrom ? p2periodFrom.getTime() : Number.MIN_SAFE_INTEGER;

    const fromDifference = period1Time - period2Time;
    return fromDifference;
}

export function sortPeriodFormsByFromDate(p1: PeriodFormModel, p2: PeriodFormModel) {
    const period1Time = p1.periodFrom ? p1.periodFrom.getTime() : Number.MIN_SAFE_INTEGER;
    const period2Time = p2.periodFrom ? p2.periodFrom.getTime() : Number.MIN_SAFE_INTEGER;

    const fromDifference = period1Time - period2Time;
    return fromDifference;
}

export function sortPeriods(periods: Period[]): Period[] {
    return periods
        .sort(sortPeriodsByFromDate);
}
