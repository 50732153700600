import { FormGroupState, unbox, updateArray, updateGroup, validate } from "ngrx-forms";
import { required } from "ngrx-forms/validation";

import { validateAssociatedCargoesForm } from "../../associated-cargoes/form/validation";
import { validateLaytimeEventsForm } from "../../laytime-events/form/validation";
import { ActivityForm, FixtureState, VoyageState } from "../../model";

export const validateActivityForm = (voyageState: VoyageState, fixtureState: FixtureState) => (form: FormGroupState<ActivityForm>) =>
    updateGroup<ActivityForm>(form, {
        activityId: validate(required),
        type: validate(required),
        laytimeEvents: validateLaytimeEventsForm(voyageState, fixtureState),
        associatedCargoes: validateAssociatedCargoesForm(voyageState, fixtureState, unbox(form.value.type))
    });

export const validateActivitiesForm = (voyageState: VoyageState, fixtureState: FixtureState) =>
    updateArray<ActivityForm>(validateActivityForm(voyageState, fixtureState));
