import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";

import { IndexResponse, isNullOrUndefined } from "@ops/shared";
import { emptyObjectMap } from "@ops/state";

import { coaStateReducer } from "./reducer";
import { CoaHttpService, LiftingHttpService } from "../../services";
import { getEarliestPlannedLifting, sortAndMapLiftingIndexItemsToGridRows } from "../lifting/liftings-index-helper";
import { Coa, CoaDriver, CoaId, CoasState, initialCoaState, LiftingIndexItem, PlannedLifting, CompletedVoyage } from "../model";

/* ACTIONS */

const LOAD_ACTION_NAME = "[Router] Load COA";

export const routerLoadCoaAction = createAction(LOAD_ACTION_NAME, props<{ coaId: CoaId }>());
export const routerLoadCoaSuccessAction = createAction(`${LOAD_ACTION_NAME} Success`, props<{ coa: Coa }>());
export const routerLoadCoaFailAction = createAction(`${LOAD_ACTION_NAME} Fail`, props<{ coaId: CoaId; error: Error }>());
export const routerLoadLatestCompletedVoyageSuccessAction = createAction(
    `${LOAD_ACTION_NAME} Latest Completed Voyage Success`,
    props<{ coaId: CoaId; completedVoyage: CompletedVoyage }>()
);
export const routerLoadLatestCompletedVoyageFailAction = createAction(`${LOAD_ACTION_NAME} Latest Completed Voyage Fail`, props<{ coaId: CoaId; error: Error }>());

export const routerLoadEarliestPlannedLiftingSuccessAction = createAction(
    `${LOAD_ACTION_NAME} Planned Liftings Load Success`,
    props<{ coaId: CoaId; plannedLifting: PlannedLifting }>()
);
export const routerLoadEarliestPlannedLiftingFailAction = createAction(`${LOAD_ACTION_NAME} Planned Liftings Load Fail`, props<{ coaId: CoaId; error: Error }>());

export const routerLoadCoaLiftingsSuccessAction = createAction(
    `${LOAD_ACTION_NAME} Liftings Load Success`,
    props<{ coaId: CoaId; driver: CoaDriver; liftings: IndexResponse<LiftingIndexItem>; fetchedLiftings: ReadonlyArray<LiftingIndexItem> }>()
);
export const routerLoadCoaLiftingsFailAction = createAction(`${LOAD_ACTION_NAME} Liftings Load Fail`, props<{ coaId: CoaId; error: Error }>());

/* REDUCERS */
export const routerLoadCoaReducer: On<CoasState> = on(routerLoadCoaAction, (state, action) => {
    if (isNullOrUndefined(action.coaId)) {
        return {
            ...state,
            coas: emptyObjectMap(),
            liftings: emptyObjectMap(),
            currentCoaId: null,
            currentLiftingId: null
        };
    } else if (state.currentCoaId !== action.coaId) {
        return {
            ...state,
            ...coaStateReducer(state, action.coaId, { loadStatus: "loading" }),
            currentCoaId: action.coaId
        };
    }

    return state;
});

export const routerLoadCoaSuccessReducer: On<CoasState> = on(routerLoadCoaSuccessAction, (state, action) =>
    coaStateReducer(state, action.coa.coaId, {
        ...initialCoaState(action.coa),
        mostRecentCompletedVoyageLoadStatus: "loading",
        earliestPlannedLiftingLoadStatus: "loading",
        indexedLiftingsLoadStatus: "loading"
    })
);

export const routerLoadCoaFailReducer: On<CoasState> = on(routerLoadCoaFailAction, (state, action) => coaStateReducer(state, action.coaId, { loadStatus: "failed" }));

export const routerLoadLatestCompletedVoyageSuccessReducer: On<CoasState> = on(routerLoadLatestCompletedVoyageSuccessAction, (state, action) =>
    coaStateReducer(state, action.coaId, { mostRecentCompletedVoyage: action.completedVoyage, mostRecentCompletedVoyageLoadStatus: "loaded" })
);

export const routerLoadLatestCompletedVoyageFailReducer: On<CoasState> = on(routerLoadLatestCompletedVoyageFailAction, (state, action) =>
    coaStateReducer(state, action.coaId, { mostRecentCompletedVoyageLoadStatus: "failed" })
);

export const routerLoadEarliestPlannedLiftingSuccessReducer: On<CoasState> = on(routerLoadEarliestPlannedLiftingSuccessAction, (state, action) =>
    coaStateReducer(state, action.coaId, { earliestPlannedLifting: action.plannedLifting, earliestPlannedLiftingLoadStatus: "loaded" })
);

export const routerLoadEarliestPlannedLiftingFailReducer: On<CoasState> = on(routerLoadEarliestPlannedLiftingFailAction, (state, action) =>
    coaStateReducer(state, action.coaId, { earliestPlannedLiftingLoadStatus: "failed" })
);

export const routerLoadCoaLiftingsSuccessReducer: On<CoasState> = on(routerLoadCoaLiftingsSuccessAction, (state, { coaId, driver, liftings, fetchedLiftings }) =>
    coaStateReducer(state, coaId, {
        indexedLiftings: { ...liftings, documents: sortAndMapLiftingIndexItemsToGridRows(liftings.documents, driver) },
        indexedLiftingsLoadStatus: "loaded",
        fetchedLiftings
    })
);

export const routerLoadCoaLiftingsFailReducer: On<CoasState> = on(routerLoadCoaLiftingsFailAction, (state, action) =>
    coaStateReducer(state, action.coaId, { indexedLiftingsLoadStatus: "failed" })
);

/* EFFECTS */
export const routerLoadCoaEffect$ = (actions$: Actions, coaHttpService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(routerLoadCoaAction),
            filter(({ coaId }) => !isNullOrUndefined(coaId)),
            switchMap(({ coaId }) =>
                coaHttpService.get(coaId).pipe(
                    map((coa: Coa) => routerLoadCoaSuccessAction({ coa })),
                    catchError((error) => of(routerLoadCoaFailAction({ coaId, error })))
                )
            )
        )
    );

export const routerLoadLatestCompletedVoyageEffect$ = (actions$: Actions, coaHttpService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(routerLoadCoaSuccessAction),
            switchMap(({ coa }) =>
                coaHttpService.getLatestCompletedVoyage(coa.reference).pipe(
                    map((completedVoyage: CompletedVoyage) => routerLoadLatestCompletedVoyageSuccessAction({ coaId: coa.coaId, completedVoyage })),
                    catchError((error) => of(routerLoadLatestCompletedVoyageFailAction({ coaId: coa.coaId, error })))
                )
            )
        )
    );

export const routerLoadPlannedLiftingEffect$ = (actions$: Actions, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(routerLoadCoaSuccessAction),
            switchMap(({ coa }) =>
                liftingHttpService.getPlannedLiftingsForCoa(coa.coaId).pipe(
                    map((response: IndexResponse<LiftingIndexItem>) =>
                        routerLoadEarliestPlannedLiftingSuccessAction({
                            coaId: coa.coaId,
                            plannedLifting: response.total > 0 ? getEarliestPlannedLifting(response.documents, coa.driver) : null
                        })
                    ),
                    catchError((error) => of(routerLoadEarliestPlannedLiftingFailAction({ coaId: coa.coaId, error })))
                )
            )
        )
    );

export const routerLoadCoaLiftingsEffect$ = (actions$: Actions, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(routerLoadCoaSuccessAction),
            switchMap(({ coa }) =>
                liftingHttpService.getLiftingsForCoa(coa.coaId).pipe(
                    map((liftings: IndexResponse<LiftingIndexItem>) =>
                        routerLoadCoaLiftingsSuccessAction({ coaId: coa.coaId, driver: coa.driver, liftings, fetchedLiftings: liftings.documents })
                    ),
                    catchError((error) => of(routerLoadCoaLiftingsFailAction({ coaId: coa.coaId, error })))
                )
            )
        )
    );
