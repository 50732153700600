import { createAction, on, On, props } from "@ngrx/store";
import { removeArrayControl, updateGroup } from "ngrx-forms";

import { AdditionalFreightExpenseId } from "../../shared/models/dtos/additional-freight-expense.dto";
import { FixturesState, VoyageForm } from "../model";
import { currentVoyageStateReducer } from "../voyage/reducer";

/* ACTIONS */
const REMOVE_ADDITIONAL_FREIGHT_EXPENSE = "[Voyage Form] Remove Additional Freight Expense";

export const removeAdditionalFreightExpenseAction = createAction(REMOVE_ADDITIONAL_FREIGHT_EXPENSE, props<{ expenseId: AdditionalFreightExpenseId }>());

/* REDUCERS */
export const removeAdditionalFreightExpenseReducer: On<FixturesState> = on(removeAdditionalFreightExpenseAction, (state, { expenseId }) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const additionalFreightExpense = voyageState.form.value.additionalFreightExpenses.find((a) => a.id === expenseId);
        const index = voyageState.form.value.additionalFreightExpenses.indexOf(additionalFreightExpense);
        return {
            ...voyageState,
            form: updateGroup<VoyageForm>({
                additionalFreightExpenses: removeArrayControl(index)
            })(voyageState.form)
        };
    })
);
