import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { first } from "rxjs/operators";

import { Command } from "../../../mediator";
import { FormComponentBase } from "../../../shared/form-component-base";
import { CargoBerthActivity } from "../../../shared/models";
import { BunkerRemainingOnboardFormModel } from "../../../shared/models/form-models";
import { VoyageBunkerFormModel } from "../../../shared/models/form-models/voyage-bunker-form.model";
import { selectInitialBunkerTypesRemainingOnboard } from "../../../state";
import { BerthId, DestinationId } from "../../../state/model";
import { AddBunkersCommand } from "./commands/add-bunkers.command";
import { DeleteBunkerCommand } from "./commands/delete-bunker.command";
import { UpdateBunkerCommand } from "./commands/update-bunker.command";
import { UpdateVoyageBunkerCommand } from "./commands/update-voyage-bunker.command";

@Component({
    selector: "ops-voyage-bunker-consumption-activity",
    templateUrl: "./voyage-bunker-consumption-activity.component.html",
    styleUrls: ["./voyage-bunker-consumption-activity.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoyageBunkerConsumptionActivityComponent extends FormComponentBase implements OnInit, OnDestroy, OnChanges {
    static componentName = "VoyageBunkerConsumptionActivityComponent";

    bunkerform: UntypedFormGroup;

    @Input() activity: CargoBerthActivity;
    @Input() berthId: BerthId;
    @Input() activityForm: UntypedFormGroup;
    @Input() readonly: boolean;
    @Input() destinationId: DestinationId;
    @Input() maxBeaufortForce: number;
    @Input() showDaysAboveForce: boolean;
    @Output() voyageBunkerConsumptionActivityUpdated = new EventEmitter<Command>();

    constructor(protected store: Store) {
        super(store);
    }

    ngOnInit() {
        this.createForm();

        this.activityForm.registerControl("bunkerform", this.bunkerform);
        this.bunkerform[this.readonly ? "disable" : "enable"]();

        this.setForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.activity && !changes.activity.firstChange) {
            this.setForm();
        }

        if (changes.readonly && !changes.readonly.firstChange) {
            this.bunkerform[this.readonly ? "disable" : "enable"]();
        }
    }

    ngOnDestroy() {
        this.removeFormSubscriptions();
        delete this.activityForm.controls.bunkerform;
    }

    bunkerUpdated(event: BunkerRemainingOnboardFormModel[]): void {
        this.voyageBunkerConsumptionActivityUpdated.emit(new UpdateBunkerCommand(this.destinationId, this.berthId, this.activity.id, event));
    }

    initialBunkersAdded() {
        this.store
            .select(selectInitialBunkerTypesRemainingOnboard, { activityId: this.activity.id })
            .pipe(first())
            .subscribe((bunkerTypes) => {
                this.voyageBunkerConsumptionActivityUpdated.emit(new AddBunkersCommand(this.destinationId, this.berthId, this.activity.id, bunkerTypes));
            });
    }

    bunkerAdded() {
        this.voyageBunkerConsumptionActivityUpdated.emit(new AddBunkersCommand(this.destinationId, this.berthId, this.activity.id));
    }

    bunkerRemoved(index: number) {
        this.voyageBunkerConsumptionActivityUpdated.emit(new DeleteBunkerCommand(this.destinationId, this.berthId, this.activity.id, index));
    }

    private createForm() {
        this.bunkerform = new UntypedFormGroup(
            {
                daysAboveForce: new UntypedFormControl(null),
                comments: new UntypedFormControl(null)
            },
            { updateOn: "blur" }
        );
    }

    private setForm() {
        const dataModel: VoyageBunkerFormModel = {
            daysAboveForce: this.activity.daysAboveForce,
            comments: this.activity.comments
        };

        this.removeFormSubscriptions();
        this.bunkerform.patchValue(dataModel, { emitEvent: false });
        this.subscribeToFormValueChanges(this.bunkerform, (data) =>
            this.voyageBunkerConsumptionActivityUpdated.emit(new UpdateVoyageBunkerCommand(this.destinationId, this.berthId, this.activity.id, data))
        );
    }
}
