import { createSelector } from "@ngrx/store";
import * as R from "ramda";

import { FixtureTabName, FixtureTabsValidationInfo } from "../../shared/tab-validation/tab-validation-info";
import { selectCurrentFixtureState } from "../fixture";
import { selectCurrentFixtureVoyagesAnyValid } from "../voyage";

export const selectCurrentFixtureValidationTabs = createSelector(selectCurrentFixtureState, (fixture) => fixture?.validationByTabs);

export const selectCurrentFixtureReadyForSave = createSelector(
    selectCurrentFixtureValidationTabs,
    selectCurrentFixtureVoyagesAnyValid,
    (validationByTabs: FixtureTabsValidationInfo, anyVoyageIsValid: boolean) => {
        let isReadyForSave = true;

        R.forEachObjIndexed((tab) => {
            if (tab?.isValid || tab?.allowSaveIfInvalid) {
                return;
            }

            isReadyForSave = false;
        }, validationByTabs);

        return isReadyForSave && anyVoyageIsValid;
    }
);

export const selectCurrentFixtureTabsValidity = createSelector(selectCurrentFixtureValidationTabs, (validationByTabs: FixtureTabsValidationInfo) => {
    const tabsValidity: Partial<Record<FixtureTabName, boolean>> = {};

    R.forEachObjIndexed(({ tabName, isValid }) => {
        tabsValidity[tabName] = isValid;
    }, validationByTabs);

    return tabsValidity;
});
