<div class="fixture-popup-container">
    <div class="fixture-popup-header">
        <ops-fixture-popup-header [header]="header" [isReadOnly]="isReadOnly" [lockedBy]="lockedBy"
            (cancel)="onCancel()"></ops-fixture-popup-header>
    </div>
    <div class="fixture-popup-body">
        <ops-fixture-popup-body [tabs]="tabs"></ops-fixture-popup-body>
    </div>
    <div class="fixture-popup-footer text-right">
        <ops-fixture-popup-footer [isReadOnly]="isReadOnly" [animatingSaveButton]="animatingSaveButton$ | async"
            (save)="onSave()" (cancel)="onCancel()"></ops-fixture-popup-footer>
    </div>
</div>