import { Injectable } from "@angular/core";
import { CommandHandler } from "src/app/fixture/mediator/command-handlers/command-handler";
import { LaytimeEventFact } from "src/app/fixture/shared/models";
import { Voyage } from "src/app/fixture/shared/models";
import { getActivity } from "src/app/shared/voyage-utils/voyage-utilities";
import { OrderLaytimeEventFactsCommand } from "./order-laytime-events.command";

@Injectable({
    providedIn: "root"
})
export class OrderLaytimeEventFactsCommandHandler implements CommandHandler {
    handle(voyage: Voyage, command: OrderLaytimeEventFactsCommand) {
        const activityToUpdate = getActivity(voyage, command.destinationId, command.berthId, command.berthActivityId);

        activityToUpdate.laytimeEventFacts.sort(LaytimeEventFact.compare);
    }
}
