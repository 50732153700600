import { FormArrayState, FormState, ProjectFn2, updateArrayWithFilter, updateGroup } from "ngrx-forms";

import { ActivityForm, ActivityId, AssociatedCargoForm, AssociatedCargoId, BerthForm, BerthId, DestinationForm, DestinationId, VoyageForm } from "../../model";

export function updateAssociatedCargoForm(
    id: { destinationId: DestinationId; berthId: BerthId; activityId: ActivityId; associatedCargoId: AssociatedCargoId },
    updateFn: ProjectFn2<FormState<AssociatedCargoForm>, FormArrayState<AssociatedCargoForm>>
) {
    return updateGroup<VoyageForm>({
        destinations: updateArrayWithFilter(
            (d) => d.value.id === id.destinationId,
            updateGroup<DestinationForm>({
                berths: updateArrayWithFilter(
                    (b) => b.value.id === id.berthId,
                    updateGroup<BerthForm>({
                        activities: updateArrayWithFilter<ActivityForm>(
                            (a) => a.value.activityId === id.activityId,
                            updateGroup<ActivityForm>({
                                associatedCargoes: updateArrayWithFilter<AssociatedCargoForm>((asc) => asc.value.associatedCargoId === id.associatedCargoId, updateFn)
                            })
                        )
                    })
                )
            })
        )
    });
}
