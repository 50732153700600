import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector } from "@angular/core";

import { HttpStatusCodes, NotificationService, NotificationType } from "@ops/shared";

import { AppConfigService } from "./app-config.service";
import { AppInsightsService } from "./app-insights.service";
import { AuthService } from "./authentication/auth.service";
import { ErrorMapppings } from "./error-mappings";
import { HttpErrorResponseAnalyzer } from "./http-error-response-analyzer";

const LOG_IGNORE_ERROR_PHRASES = ["cloudheader"];
const NOTIFICATION_IGNORE_ERROR_PHRASES = ["LiveUpdatesHub", "handshake", "connection"].concat(LOG_IGNORE_ERROR_PHRASES);

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
    constructor(private injector: Injector) {
        super();
    }

    handleError(error: Error) {
        const monitoringService = this.injector.get(AppInsightsService);

        if (error instanceof HttpErrorResponse) {
            if (error.status === HttpStatusCodes.Unauthorized) {
                const authService = this.injector.get(AuthService);
                authService.redirectToLogin();
                return;
            }

            const errorMessageId = this.httpErrorResponseAnalyzer.getErrorMessageId(error);
            if (errorMessageId > 0) {
                this.handleNotification(errorMessageId);
            }
        }

        try {
            const errorStr = error.toString() + error.stack;

            if (!LOG_IGNORE_ERROR_PHRASES.some((phrase) => errorStr.includes(phrase))) {
                monitoringService.logError(error);
            }

            if (!(error instanceof HttpErrorResponse)) {
                const appConfig = this.injector.get(AppConfigService).config;
                const showNotification = Boolean(appConfig.unhandledErrorNotificationEnabled);

                if (showNotification && !NOTIFICATION_IGNORE_ERROR_PHRASES.some((phrase) => errorStr.includes(phrase))) {
                    this.notificationService.error("Error occurred", error.name + ": " + error.message);
                }
            }

            super.handleError(error);
        } catch (e) {
            console.error("Error (fallback):", error);
        }
    }

    handleNotification(errorMessageId: number): void {
        if (ErrorMapppings.notificationMappings[errorMessageId] !== null) {
            if (ErrorMapppings.notificationMappings[errorMessageId].type === NotificationType.Warn) {
                this.notificationService.warn(ErrorMapppings.notificationMappings[errorMessageId].title, ErrorMapppings.notificationMappings[errorMessageId].message);
            } else if (ErrorMapppings.notificationMappings[errorMessageId].type === NotificationType.Error) {
                this.notificationService.error(ErrorMapppings.notificationMappings[errorMessageId].title, ErrorMapppings.notificationMappings[errorMessageId].message);
            }
        }
    }

    private get notificationService(): NotificationService {
        return this.injector.get(NotificationService);
    }

    private get httpErrorResponseAnalyzer(): HttpErrorResponseAnalyzer {
        return this.injector.get(HttpErrorResponseAnalyzer);
    }
}
