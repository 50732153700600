import { Command } from "../../../../mediator/commands/command";
import { BunkerRemainingOnboardFormModel } from "../../../../shared/models/form-models/bunker-remaining-onboard.model";
import { ActivityId, BerthId, DestinationId } from "../../../../state/model";
import { UpdateBunkerCommandHandler } from "./update-bunker.command-handler";

export class UpdateBunkerCommand extends Command {
    constructor(destinationId: DestinationId, berthId: BerthId, berthActivityId: ActivityId, bunkers: BunkerRemainingOnboardFormModel[]) {
        super(UpdateBunkerCommandHandler, {
            destinationId,
            berthId,
            berthActivityId,
            bunkers
        });
    }
}
