import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Company } from "src/app/coa/state";

@Component({
    selector: "ops-coa-contacts-list",
    templateUrl: "./contacts-list.component.html",
    styleUrls: ["./contacts-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoaContactsListComponent {
    static componentName = "CoaContactsListComponent";
    @Input() companies: Company[];
    @Output() readonly removeCompany = new EventEmitter<Company>();
}
