import { StateUpdateFns, updateGroup } from "ngrx-forms";

import { ActivityForm, AssociatedCargoForm, BerthForm, DestinationForm, VoyageForm } from "../..";
import { updateArrayOfGroups } from "../../../../shared/utils/ngrx-forms-utils";

export const updateDestinationForms = (updateFn: StateUpdateFns<Readonly<DestinationForm>>) =>
    updateGroup<VoyageForm>({ destinations: updateArrayOfGroups<DestinationForm>(updateFn) });

export const updateBerthForms = (updateFn: StateUpdateFns<Readonly<BerthForm>>) => updateDestinationForms({ berths: updateArrayOfGroups<BerthForm>(updateFn) });

export const updateActivityForms = (updateFn: StateUpdateFns<Readonly<ActivityForm>>) => updateBerthForms({ activities: updateArrayOfGroups<ActivityForm>(updateFn) });

export const updateAssociatedCargoForms = (updateFn: StateUpdateFns<Readonly<AssociatedCargoForm>>) =>
    updateActivityForms({ associatedCargoes: updateArrayOfGroups<AssociatedCargoForm>(updateFn) });
