<form [formGroup]="noonReportForm" class="noon-report-container" autocomplete="off" [ngClass]="{ collapsed: collapsed }">
    <div class="row no-gutters">
        <div class="col-1">
            <button
                (click)="toggle()"
                type="button"
                class="has-icon p-0"
                [class.icon--arrow-drop-right]="collapsed"
                [class.icon--arrow-drop-down]="!collapsed"
                data-test-noon-report-toggle
            ></button>
        </div>
        <div class="col-7 ml-2">
            <div class="toggle" (click)="toggle()">
                <span class="title" data-test-noon-report-title> {{ noonReport.date | date: dateFormat }} </span>
            </div>
        </div>
        <div class="col d-flex justify-content-end">
            <span *ngIf="noonReport.inPort" class="in-port" data-test-in-port>IN PORT</span>
            <button type="button" class="btn-add has-icon icon--edit" (click)="edit()" data-test-edit-noon-report></button>
        </div>
    </div>

    <div *ngIf="!collapsed">
        <div class="row no-gutters divider"></div>
        <div class="row no-gutters daily-average">
            <div class="col-6 ml-2 d-flex justify-content-between">
                <span>Av. Daily Speed (Knots):</span>
                <span data-test-average-speed> {{ noonReport.averageSpeed }} </span>
            </div>
        </div>
        <div class="row no-gutters daily-average">
            <div class="col-6 ml-2 d-flex justify-content-between">
                <span>Av. Daily Beaufort value:</span>
                <span data-test-average-beaufort> {{ noonReport.averageBeaufort }} </span>
            </div>
        </div>

        <div class="row mb-2 mt-2 no-gutters">
            <ops-engine-consumption
                title="Main Engine Consumption (MT)"
                formName="mainEngineConsumptionForm"
                [parentForm]="noonReportForm"
                [model]="noonReport.mainEngineConsumption"
            ></ops-engine-consumption>
        </div>

        <div class="row no-gutters">
            <ops-engine-consumption
                title="Auxiliary Engine Consumption (MT)"
                formName="auxiliaryEngineConsumptionForm"
                [parentForm]="noonReportForm"
                [model]="noonReport.auxiliaryEngineConsumption"
            ></ops-engine-consumption>
        </div>
    </div>
</form>
