import { createAction, on, On, props } from "@ngrx/store";

import { actionDtoToLiftingAction, ActionDtoWithLiftingId, CoasState } from "../../model";
import { currentLiftingStateReducer } from "../reducer";

/* ACTIONS */
export const setLiftingNominationTaskActionsLoadingAction = createAction("[Lifting Nomination Task Actions] Loading Actions");
export const setLiftingNominationTaskActionsAction = createAction(
    "[Lifting Nomination Task Actions] Set Actions",
    props<{ liftingActionDtos?: ReadonlyArray<ActionDtoWithLiftingId> }>()
);

/* REDUCERS */
export const setLiftingNominationTaskActionsLoadingReducer: On<CoasState> = on(setLiftingNominationTaskActionsLoadingAction, (state) =>
    currentLiftingStateReducer(state, (liftingState) => ({
        ...liftingState,
        actionsLoadStatus: "loading"
    }))
);

export const setLiftingNominationTaskActionsReducer: On<CoasState> = on(setLiftingNominationTaskActionsAction, (state, { liftingActionDtos }) =>
    currentLiftingStateReducer(state, (liftingState) => ({
        ...liftingState,
        actions: liftingActionDtos.filter((la: ActionDtoWithLiftingId) => la.liftingId === liftingState.lifting.liftingId).map(actionDtoToLiftingAction),
        actionsLoadStatus: "loaded"
    }))
);
