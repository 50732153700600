import { Enumeration } from "./enumeration";

export class QuantityUnit extends Enumeration {
    static readonly MT = new QuantityUnit(1, "MT", 1);
    static readonly JasCbm = new QuantityUnit(2, "JAS CBM", 2);
    static readonly LumpSum = new QuantityUnit(3, "Lump Sum", 3);
    static readonly BBLS = new QuantityUnit(4, "BBLS", 4);
    static readonly CBM = new QuantityUnit(5, "CBM", 5);

    constructor(id: number, name: string, displayOrder?: number) {
        super(id, name, displayOrder);
    }
}
