import { NgModule } from "@angular/core";
import { FixtureWarningActivatedDirective } from "./fixture-warning-activated.directive";
import { FixtureWarningActiveDirective } from "./fixture-warning-active.directive";
import {
    FixtureWarningFormControlNameDirective,
    NgrxFixtureWarningFormControlNameDirective
} from "./fixture-warning-form-control-name.directive";
import { FIXTURE_WARNING_PATH_TRANSFORM } from "./fixture-warning-path-mapper";
import { FormStrippingWarningPathTransform } from "./path-transforms/form-stripping-warning-path-transform";
import { VoyagesFixtureWarningPathTransform } from "./path-transforms/voyages-warning-path-transform";
import { FixtureWarningRulesModule } from "./rules";

@NgModule({
    imports: [FixtureWarningRulesModule],
    declarations: [
        FixtureWarningFormControlNameDirective,
        NgrxFixtureWarningFormControlNameDirective,
        FixtureWarningActivatedDirective,
        FixtureWarningActiveDirective
    ],
    providers: [
        { provide: FIXTURE_WARNING_PATH_TRANSFORM, useClass: FormStrippingWarningPathTransform, multi: true },
        { provide: FIXTURE_WARNING_PATH_TRANSFORM, useClass: VoyagesFixtureWarningPathTransform, multi: true }
    ],
    exports: [
        FixtureWarningRulesModule,

        FixtureWarningFormControlNameDirective,
        NgrxFixtureWarningFormControlNameDirective,
        FixtureWarningActivatedDirective,
        FixtureWarningActiveDirective
    ]
})
export class FixtureWarningsModule { }
