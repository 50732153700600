import { HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { saveAs } from "file-saver";

import { HttpStatusCodes } from "./http-status-codes";

export function getFileName(httpHeaders: HttpHeaders): string {
    try {
        const contentDispositionHeader = httpHeaders.get("content-disposition");
        const result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
        return result.replace(/"/g, "");
    } catch {
        return "Download";
    }
}

export function downloadFile(response: HttpResponse<Blob>) {
    const fileName = getFileName(response.headers);
    const blob = new Blob([response.body], { type: "application/octet-stream" });
    saveAs(blob, fileName);
}

/**
 * Returns true if the error is a `HttpErrorResponse` with status code of 400.
 *
 * @param error The error to check.
 */
export function isBadRequest(error: Error): error is HttpErrorResponse {
    return error instanceof HttpErrorResponse && error.status === HttpStatusCodes.BadRequest;
}

/**
 * Returns true if the error is a `HttpErrorResponse` with status code of 403.
 *
 * @param error The error to check.
 */
export function isForbidden(error: Error): error is HttpErrorResponse {
    return error instanceof HttpErrorResponse && error.status === HttpStatusCodes.Forbidden;
}

/**
 * Returns true if the error is a `HttpErrorResponse` with status code of 404.
 *
 * @param error The error to check.
 */
export function isNotFound(error: Error): error is HttpErrorResponse {
    return error instanceof HttpErrorResponse && error.status === HttpStatusCodes.NotFound;
}
