import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { ActivityType } from "../../../state";

@Component({
    selector: "ops-voyage-activity",
    templateUrl: "./voyage-activity.component.html",
    styleUrls: ["./voyage-activity.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoyageActivityComponent {
    @Input() name: string;
    @Input() activity: ActivityType;
    @Input() flagImageUrl: string;
    @Input() type: "header" | "item";
}
