import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { DateTime } from "luxon";
import { FormGroupState, unbox } from "ngrx-forms";
import { BehaviorSubject } from "rxjs";

import { VerifiedTimestamp } from "../../../shared/components/timestamp/verified-timestamp.model";
import { CustomNgrxValueConverters } from "../../../state";
import { DestinationForm } from "../../state";

@Component({
    selector: "ops-fixture-popup-destination-tab-header-form",
    templateUrl: "./fixture-popup-destination-tab-header-form.component.html",
    styleUrls: ["./fixture-popup-destination-tab-header-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupDestinationTabHeaderFormComponent implements OnChanges {
    private isHovered = false;
    private isOpened = false;
    private isFocused = false;

    isInvalid = false;
    hasValue = false;

    readonly dateRangeValueConverter = CustomNgrxValueConverters.dateRangeToISOString;

    showDatePicker$ = new BehaviorSubject<boolean>(false);

    @Input() form: FormGroupState<DestinationForm>;
    @Input() isReadOnly: boolean;
    @Input() timeZone: string;
    @Input() hasEtaWarning: boolean;
    @Input() etaVerifiedAudit: VerifiedTimestamp;
    @Input() etaVerifyEnabled: boolean;
    @Input() etaDefaultFocusDate: DateTime;
    @Output() verifyEta = new EventEmitter();

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.form) {
            const etaRangeControl = changes.form.currentValue.controls.etaRange;
            this.isInvalid = etaRangeControl?.isInvalid ?? false;
            this.hasValue = !!unbox(etaRangeControl?.value);
            this.updateShowLabel();
        }
    }

    onMouseEnter() {
        if (!this.isReadOnly) {
            this.isHovered = true;
            this.updateShowLabel();
        }
    }

    onMouseLeave() {
        this.isHovered = false;
        this.updateShowLabel();
    }

    onOpen() {
        this.isOpened = true;
        this.updateShowLabel();
    }

    onClose() {
        this.isOpened = false;
        this.updateShowLabel();
    }

    onFocus() {
        this.isFocused = true;
        this.updateShowLabel();
    }

    onBlur() {
        this.isFocused = false;
        this.updateShowLabel();
    }

    private updateShowLabel() {
        this.showDatePicker$.next(this.isHovered || this.isOpened || this.isFocused || this.isInvalid || !this.hasValue);
    }
}
