<div class="reference-data-dropdown-wrapper" [class.clearable]="showClearButton">
    <ng-select
        class="ops-select"
        bindLabel="name"
        [bindValue]="bindValue"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [items]="fieldsInternal$ | async"
        [clearable]="showClearButton"
        [selectOnTab]="true"
        (change)="change.emit($event)"
        container="body"
    >
    </ng-select>
</div>
