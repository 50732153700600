import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { Draft, produce } from "immer";
import { DateTime } from "luxon";
import { box, setValue, SetValueAction, unbox } from "ngrx-forms";
import { filter, map, withLatestFrom } from "rxjs/operators";

import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { findAllSpecialisedCargoBerthElements, findAllSpecialisedCargoDestinationElements } from "./specialised-cargo-functions";
import { AtSeaBunkerConsumption, DateRange, Division, FixtureType } from "../../../../shared/models";
import { SuggestedExistingLocation } from "../../../../shared/models/common/suggested-existing-location";
import { selectCurrentFixtureDivision } from "../../../fixture";
import { orderLaytimeEventsSortFunc } from "../../../laytime-events/form/order-laytime-events";
import {
    activityForm,
    ActivityForm,
    AssociatedCargoForm,
    BerthForm,
    CargoForm,
    createActivityId,
    createAssociatedCargoId,
    createDestinationId,
    createLaytimeEventId,
    DestinationForm,
    destinationForm,
    DestinationId,
    FixtureFeatureState,
    FixturesState,
    getDefaultLaytimeEvents,
    getFixtureType,
    LaytimeEventForm
} from "../../../model";
import { compareValues, getNextId } from "../../../utils";
import { selectCurrentVoyageFormValue } from "../../../voyage";
import { currentVoyageStateReducer } from "../../../voyage/reducer";

/* ACTIONS */
export const updateSpecialisedCargoDestinationsAction = createAction("[Voyage Form] Update Specialised Cargo Destinations", props<{ cargoForm: CargoForm }>());

/* REDUCERS */
export const updateSpecialisedCargoDestinationsReducer: On<FixturesState> = on(updateSpecialisedCargoDestinationsAction, (state, { cargoForm }) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        const updatedFormValue = produce(voyageState.form.value, (draftState) => {
            const updatedDestinations = updateDestinationsOnVoyage(cargoForm, draftState.destinations, draftState.atSeaBunkersConsumption, getFixtureType(fixtureState));

            draftState.destinations = updatedDestinations;

            draftState.cargoes.forEach((c) => {
                const loadDestination = findAllSpecialisedCargoDestinationElements(c.cargoId, CargoBerthActivityType.Load, updatedDestinations)?.destination;
                const dischargeDestination = findAllSpecialisedCargoDestinationElements(c.cargoId, CargoBerthActivityType.Discharge, updatedDestinations)?.destination;

                function throwDataError(type: CargoBerthActivityType) {
                    throw Error(
                        `updateSpecialisedCargoDestinationsReducer: Unable to find ${type.name} destination for cargoId=${c.cargoId}. ` +
                            "This is likely a data error - is this an automation test fixture?"
                    );
                }

                if (!loadDestination) {
                    throwDataError(CargoBerthActivityType.Load);
                }
                if (!dischargeDestination) {
                    throwDataError(CargoBerthActivityType.Discharge);
                }

                c.loadLocation = {
                    location: c.loadLocation && c.loadLocation.location && box({ ...c.loadLocation.location.value, etaRange: null }),
                    eta: loadDestination.etaRange
                };
                c.dischargeLocation = {
                    location: c.dischargeLocation && c.dischargeLocation.location && box({ ...c.dischargeLocation.location.value, etaRange: null }),
                    eta: dischargeDestination.etaRange
                };
            });
        });

        return {
            ...voyageState,
            form: setValue(voyageState.form, updatedFormValue)
        };
    })
);

/* EFFECTS */
export const specialisedCargoFormChangeEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<CargoForm>>(SetValueAction.TYPE),
            map((action) => action.controlId.split(".")),
            filter((controlPath) => controlPath.length === 5 && controlPath[1] === "cargoes" && (controlPath[3] === "loadLocation" || controlPath[3] === "dischargeLocation")),
            withLatestFrom(store.select(selectCurrentVoyageFormValue), store.select(selectCurrentFixtureDivision)),
            filter(([, , division]) => division === Division.specialisedProducts),
            map(([[, , cargoIndex], { cargoes }]) =>
                updateSpecialisedCargoDestinationsAction({
                    cargoForm: cargoes[Number(cargoIndex)]
                })
            )
        )
    );

/* FUNCTIONS */
const updateDestinationsOnVoyage = (
    cargoForm: CargoForm,
    destinationForms: Draft<DestinationForm[]>,
    atSeaBunkersConsumption: Draft<AtSeaBunkerConsumption[]>,
    fixtureType: FixtureType
) => {
    getUpdatedDestinations(cargoForm, CargoBerthActivityType.Load, destinationForms, atSeaBunkersConsumption, fixtureType);
    getUpdatedDestinations(cargoForm, CargoBerthActivityType.Discharge, destinationForms, atSeaBunkersConsumption, fixtureType);
    orderDestinations(destinationForms);

    return destinationForms;
};

const getUpdatedDestinations = (
    cargoForm: CargoForm,
    activityType: CargoBerthActivityType,
    destinationForms: Draft<DestinationForm[]>,
    atSeaBunkersConsumption: Draft<AtSeaBunkerConsumption[]>,
    fixtureType: FixtureType
) => {
    const cargoDestinationElements = findAllSpecialisedCargoDestinationElements(cargoForm.cargoId, activityType, destinationForms);

    if (cargoDestinationElements) {
        const destination = makeDraft(cargoDestinationElements.destination);
        const berth = cargoDestinationElements.berth;
        const cba = cargoDestinationElements.activity;
        const associatedCargo = makeDraft(cargoDestinationElements.associatedCargo);

        const loc = activityType.id === CargoBerthActivityType.Load.id ? cargoForm.loadLocation : cargoForm.dischargeLocation;
        const location = loc.location && <SuggestedExistingLocation>loc.location.value;
        const etaRange = (location && location.etaRange) || (loc.eta && loc.eta.value);

        if (cargoForm.baseFreightRate && !associatedCargo.freightRate) {
            associatedCargo.freightRate = cargoForm.baseFreightRate;
        }

        const onDestinationReplaced = (oldDestinationId: DestinationId, newDestinationId: DestinationId) => {
            updateAtSeaBunkersConsumption(atSeaBunkersConsumption, activityType, oldDestinationId, newDestinationId);
        };

        if (cba.associatedCargoes.length > 1 || berth.activities.length > 1 || destination.berths.length > 1) {
            if (!location) {
                // split out the cargo if the location isn't set and there is more than one cargo or berth activity on the voyage destination
                splitDestination(cargoForm, destination, activityType, etaRange, destinationForms, fixtureType, onDestinationReplaced);
            } else if (etaRange) {
                destination.etaRange = box(etaRange);
            }
        } else {
            // set the information on the destination on the voyage from the form
            destination.etaRange = box(etaRange);
            destination.location = box(location);

            if (etaRange && location) {
                // find duplicate locations and eta's
                let dupes = destinationForms.filter((dest) => {
                    const destEtaRange = unbox(dest.etaRange);
                    return (
                        destEtaRange &&
                        +DateTime.fromISO(destEtaRange.from) === +DateTime.fromISO(etaRange.from) &&
                        +DateTime.fromISO(destEtaRange.to) === +DateTime.fromISO(etaRange.to) &&
                        dest.location?.value?.locationId === location.locationId
                    );
                });
                if (dupes.length > 1) {
                    // mergeDestinations assumes that the first destination in dupes is not the one being updated
                    dupes = [...dupes.filter((x) => x.id !== destination.id), destination];

                    // merge duplicate locations and eta's
                    mergeDestinations(cargoForm, activityType, dupes, destinationForms, fixtureType, onDestinationReplaced);
                }
            }
        }
    }
};

const mergeDestinations = (
    cargoForm: CargoForm,
    activityType: CargoBerthActivityType,
    destinationsToMerge: Draft<DestinationForm[]>,
    allDestinations: Draft<DestinationForm[]>,
    fixtureType: FixtureType,
    onDestinationReplaced: (oldDestinationId: string, newDestinationId: string) => void
) => {
    // merge into top destination in the list
    let activityToMergeInto = destinationsToMerge[0].berths[0].activities.find((cba) => unbox(cba.type).id === activityType.id);
    if (!activityToMergeInto) {
        // if there isn't a cba of the correct type to merge into, create it
        const newCbaId = createActivityId();
        const newCbaLegacyId = getNextId(destinationsToMerge[0].berths[0].activities, "legacyActivityId");
        const newCba = makeDraft(activityForm(Division.specialisedProducts, newCbaId, newCbaLegacyId));
        newCba.laytimeEvents = getDefaultLaytimeEvents(
            fixtureType,
            Division.specialisedProducts,
            activityType,
            true,
            destinationsToMerge[0].arrivalDateTime,
            destinationsToMerge[0].location?.value?.timeZone || "utc"
        );
        newCba.type = box({ ...activityType, longName: null });
        destinationsToMerge[0].berths[0].activities.push(newCba);
        activityToMergeInto = newCba;
    }

    for (let i = 1; i < destinationsToMerge.length; i++) {
        // find elements that need to be copied
        const destinationToUse = destinationsToMerge[i];
        const berthToUse = makeDraft(findAllSpecialisedCargoBerthElements(cargoForm.cargoId, activityType, destinationToUse.berths).berth);

        if (!berthToUse) {
            continue;
        }

        const cbaToUse = makeDraft(berthToUse.activities.find((cba) => unbox(cba.type).id === activityType.id));
        const cargoesToUse = cbaToUse.associatedCargoes.filter((ac) => ac.cargoId === cargoForm.cargoId);
        const newLegacyAssociatedCargoId = getNextId(activityToMergeInto.associatedCargoes, "legacyAssociatedCargoId");
        const cargoesToMerge = makeDraft(
            cargoesToUse.map((ac, aci) => ({
                ...ac,
                associatedCargoId: createAssociatedCargoId(),
                legacyAssociatedCargoId: newLegacyAssociatedCargoId + aci
            }))
        );
        const laytimeEventsToUse = cbaToUse.laytimeEvents.filter((le) => cargoesToMerge.find((c) => c.cargoId === le.cargoId));
        const laytimeEventsToMerge = laytimeEventsToUse.map((le) => ({ ...le, laytimeEventId: createLaytimeEventId() }));

        //Add all elements to top destination
        mergeCargoesAndLaytimeEvents(activityToMergeInto, cargoesToMerge, laytimeEventsToMerge);

        // remove the elements from where they were before
        removeCargoesAndLaytimeEvents(cargoesToMerge, cbaToUse, destinationToUse, berthToUse, cargoesToUse, laytimeEventsToUse, allDestinations, (form) =>
            onDestinationReplaced(form.id, destinationsToMerge[0].id)
        );
    }
};

const mergeCargoesAndLaytimeEvents = (activityToMergeInto: Draft<ActivityForm>, cargoesToMerge: Draft<AssociatedCargoForm[]>, laytimeEventsToMerge: Draft<LaytimeEventForm[]>) => {
    // do not add a cargo if it already exists on the activity we're merging into
    const filteredCargoes =
        activityToMergeInto.associatedCargoes.length > 0
            ? cargoesToMerge.filter((cm) => activityToMergeInto.associatedCargoes.find((ac) => ac.cargoId !== cm.cargoId))
            : cargoesToMerge;

    // insert them into the correct places
    activityToMergeInto.associatedCargoes.push(...filteredCargoes);
    activityToMergeInto.laytimeEvents.push(...laytimeEventsToMerge);

    if (activityToMergeInto.laytimeEvents.length > 0) {
        // order the laytime events if there are any
        activityToMergeInto.laytimeEvents.sort((a, b) => orderLaytimeEventsSortFunc(a, b));
    }
};

const removeCargoesAndLaytimeEvents = (
    cargoesToMerge: Draft<AssociatedCargoForm[]>,
    cbaToUse: Draft<ActivityForm>,
    destinationToUse: Draft<DestinationForm>,
    berthToUse: Draft<BerthForm>,
    cargoesToUse: Draft<AssociatedCargoForm[]>,
    laytimeEventsToUse: Draft<LaytimeEventForm[]>,
    allDestinations: Draft<DestinationForm[]>,
    onDestinationRemoved: (form: DestinationForm) => void
) => {
    if (cargoesToMerge.length === cbaToUse.associatedCargoes.length) {
        if (destinationToUse.berths.length === 1 && berthToUse.activities.length === 1) {
            // if this was the only cargo in the only activity on the only berth, remove the location
            const indexInDestinations = allDestinations.findIndex((d) => d.id === destinationToUse.id);
            onDestinationRemoved(allDestinations[indexInDestinations]);
            allDestinations.splice(indexInDestinations, 1);
        } else if (destinationToUse.berths.length > 1 && berthToUse.activities.length === 1) {
            // if this was the only cargo in the only activity on a location with multiple berths, remove the berth
            const indexInBerths = destinationToUse.berths.findIndex((b) => b.id === berthToUse.id);
            allDestinations.find((d) => d.id === destinationToUse.id).berths.splice(indexInBerths, 1);
        } else {
            // if there were multiple berth activities (load and discharge), remove the correct one
            const indexInCba = berthToUse.activities.findIndex((cba) => cba.activityId === cbaToUse.activityId);
            berthToUse.activities.splice(indexInCba, 1);
        }
    } else {
        // if there were other associated cargoes, remove the correct one and its laytime events
        cargoesToUse.forEach((c) => {
            const indexInAc = cbaToUse.associatedCargoes.findIndex((ac) => ac.associatedCargoId === c.associatedCargoId);
            cbaToUse.associatedCargoes.splice(indexInAc, 1);
        });
        laytimeEventsToUse.forEach((e) => {
            const indexInLe = cbaToUse.laytimeEvents.findIndex((le) => le.laytimeEventId === e.laytimeEventId);
            cbaToUse.laytimeEvents.splice(indexInLe, 1);
        });
    }
};

const splitDestination = (
    cargoForm: CargoForm,
    destinationToSplit: Draft<DestinationForm>,
    activityType: CargoBerthActivityType,
    etaRange: DateRange,
    destinations: Draft<DestinationForm[]>,
    fixtureType: FixtureType,
    onDestinationReplaced: (oldDestinationId: string, newDestinationId: string) => void
) => {
    // create new location to split into
    const newLocId = createDestinationId();
    const newLoc = makeDraft(destinationForm(Division.specialisedProducts, newLocId, getNextId(destinations, "destinationId")));
    newLoc.etaRange = box(etaRange);
    newLoc.berths[0].activities[0].laytimeEvents = getDefaultLaytimeEvents(fixtureType, Division.specialisedProducts, activityType, true, null, "utc");
    // longName is needed so that this serialises correctly
    newLoc.berths[0].activities[0].type = box({ ...activityType, longName: null });
    destinations.push(newLoc);
    onDestinationReplaced(destinationToSplit.id, newLocId);

    // merge into the new location
    const dupes = [destinations[destinations.length - 1], destinationToSplit];
    mergeDestinations(cargoForm, activityType, dupes, destinations, fixtureType, onDestinationReplaced);
};

const orderDestinations = (destinations: Draft<DestinationForm[]>) =>
    destinations.sort((a, b) => {
        const eta1 = a.etaRange?.value && DateTime.fromISO(a.etaRange.value.from);
        const eta2 = b.etaRange?.value && DateTime.fromISO(b.etaRange.value.from);
        return compareValues(eta1, eta2);
    });

const updateAtSeaBunkersConsumption = (
    atSeaBunkersConsumption: Draft<AtSeaBunkerConsumption[]>,
    activityType: CargoBerthActivityType,
    oldDestinationId: DestinationId,
    newDestinationId: DestinationId
) => {
    switch (activityType) {
        case CargoBerthActivityType.Load:
            atSeaBunkersConsumption?.forEach((item) => {
                if (item.destinationFromId === oldDestinationId) {
                    item.destinationFromId = newDestinationId;
                }
            });
            break;
        case CargoBerthActivityType.Discharge:
            atSeaBunkersConsumption?.forEach((item) => {
                if (item.destinationToId === oldDestinationId) {
                    item.destinationToId = newDestinationId;
                }
            });
            break;
    }
};

const makeDraft = <T>(obj: T) => <Draft<T>>obj;
