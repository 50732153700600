<div [formGroup]="deliveryForm" class="card h-100">
    <div class="card-header row no-gutters align-items-center">
        <span class="title">Delivery</span> <span ngbTooltip="{{ headerDate.tooltip }}">{{ headerDate.header }}</span>
    </div>
    <div class="card-body">
        <div class="row no-gutters">
            <div class="form-group col">
                <label>Delivery ({{ deliveryLocationCount }})</label>
                <ops-location-autosuggest
                    [class.invalid]="deliveryForm.get('deliveryLocations').invalid"
                    [multiple]="true"
                    formControlName="deliveryLocations"
                    warningFormControl
                    data-test-delivery-locations
                >
                </ops-location-autosuggest>
                <ops-validation [form]="deliveryForm.get('deliveryLocations')" [upsertTextFns]="locationsValidationFns">
                </ops-validation>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="form-group col-6">
                <ops-notices formControlName="deliveryNotices" title="Delivery Notice" [parentForm]="deliveryForm"></ops-notices>
                <ops-validation [form]="deliveryForm.get('deliveryNotices')"></ops-validation>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="form-group col-3">
                <label for="estimatedDeliveryDate">Estimated Delivery (UTC)</label>
                <ops-date-input
                    timeZone="utc"
                    [enableTime]="true"
                    formControlName="estimatedDeliveryDate"
                    [defaultFocusDate]="laycanToDate"
                    data-test-estimated-delivery-date
                ></ops-date-input>
                <ops-warning>
                    <div *ngIf="isEstimatedDeliveryDateWarningVisible()">
                        <span>Later than Laycan To</span>
                        <span class="has-icon icon--flag"></span>
                    </div>
                </ops-warning>
            </div>
            <div class="form-group col-3">
                <div class="row no-gutters d-flex actual-delivery-labels">
                    <label for="actualDeliveryDate">Actual Delivery (UTC)</label>
                    <div class="d-flex ml-auto actual-delivery-icon">
                        <span
                            class="has-icon icon--info-outline"
                            ngbTooltip="This field affects the From and To dates within the Hire block."
                            container="body"
                        ></span>
                    </div>
                </div>
                <ops-date-input
                    timeZone="utc"
                    [enableTime]="true"
                    [class.invalid]="deliveryForm.get('actualDeliveryDate').invalid"
                    formControlName="actualDeliveryDate"
                    [defaultFocusDate]="laycanToDate"
                    data-test-actual-delivery-date
                ></ops-date-input>
                <ops-validation [form]="deliveryForm.get('actualDeliveryDate')" [upsertTextFns]="actualDateValidationFns"></ops-validation>
            </div>
        </div>
        <div>
            <ops-bunkers
                title="Bunker On Delivery"
                [bunkers]="delivery.bunkers"
                [parentForm]="deliveryForm"
                (bunkerUpdated)="bunkerUpdated($event)"
                (bunkerRemoved)="bunkerRemoved($event)"
                (bunkerAdded)="bunkerAdded()"
            ></ops-bunkers>
        </div>
        <div class="comments">
            <div class="row no-gutters align-items-center toggle-comments">
                <div class="form-group col d-flex flex-column p-0">
                    <label class="row no-gutters" for="comments" [ngClass]="{ 'mb-0': hideComments }">
                        <button
                            type="button"
                            (click)="hideComments = !hideComments"
                            class="has-icon"
                            [ngClass]="{ 'icon--arrow-drop-right': hideComments, 'icon--arrow-drop-down': !hideComments }"
                        ></button>
                        <span class="col">Delivery Comments</span>
                    </label>
                    <textarea
                        formControlName="comments"
                        *ngIf="!hideComments"
                        class="form-control"
                        rows="2"
                        cols="75"
                        maxlength="3000"
                        data-test-delivery-comments
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
