import { Directive, ElementRef, HostListener, Input, Renderer2 } from "@angular/core";
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";

import { formatNumber, normaliseNumber, parseNumber } from "../../number-format";
import { hasValue, isWhitespace } from "../../utils";

export type NumberInputOptions = {
    precision?: number;
    showTrailingZeros?: boolean;
    minimumFractionDigits?: number;
    minValue?: number;
    maxValue?: number;
    type?: "string" | "number";
};

@Directive({
    selector: "[opsNumber]",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: NumberInputDirective,
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: NumberInputDirective,
            multi: true
        }
    ]
})
export class NumberInputDirective implements ControlValueAccessor, Validator {
    private readonly nativeElement: HTMLInputElement;

    private _configuration: NumberInputOptions = {
        precision: 2,
        showTrailingZeros: true,
        minimumFractionDigits: 0,
        minValue: null,
        maxValue: null,
        type: "number"
    };

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("opsNumber")
    set configuration(value: NumberInputOptions) {
        Object.assign(this._configuration, value);
    }

    constructor(elementRef: ElementRef<HTMLInputElement>, private renderer: Renderer2) {
        this.nativeElement = elementRef.nativeElement;
    }

    @HostListener("change", ["$event"]) inputChange() {
        const textFromInput = this.nativeElement.value;

        if (hasValue(textFromInput) && !isWhitespace(textFromInput)) {
            const normalizedNumber = parseNumber(textFromInput, this._configuration.precision);
            this.setValueOnView(normalizedNumber);

            const { showTrailingZeros, minimumFractionDigits, precision } = this._configuration;
            // When configured for a string, take the value that's just been formatted without the thousand separators
            const value = this._configuration?.type === "string" ? formatNumber(normalizedNumber, showTrailingZeros, minimumFractionDigits, precision, false) : normalizedNumber;

            this.onChange(value);
            return;
        }

        this.onChange(null);
    }

    @HostListener("blur", ["$event"]) inputBlur() {
        const textFromInput = this.nativeElement.value;
        const normalizedNumber = parseNumber(textFromInput, this._configuration.precision);
        this.setValueOnView(normalizedNumber);
        this.onTouched();
    }

    writeValue(model: number): void {
        const normalizedNumber = normaliseNumber(model, this._configuration.precision);
        this.setValueOnView(normalizedNumber);
    }

    registerOnChange(fn: (_: number | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.nativeElement, "disabled", isDisabled);
    }

    validate(control: UntypedFormControl): ValidationErrors {
        if (this._configuration.type === "string") {
            const normalisedNumber = parseNumber(control.value, this._configuration.precision);
            return this.validateNumber(normalisedNumber);
        }

        return this.validateNumber(control.value);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange = (_: any) => {};
    onTouched = () => {};

    private validateNumber(normalizedNumber: number, configuration = this._configuration): ValidationErrors {
        if (configuration.minValue !== null) {
            const minValue = configuration.minValue;
            if (normalizedNumber < minValue) {
                return {
                    minValue: {
                        valid: false
                    }
                };
            }
        }

        if (configuration.maxValue !== null) {
            const maxValue = configuration.maxValue;
            if (normalizedNumber > maxValue) {
                return {
                    maxValue: {
                        valid: false
                    }
                };
            }
        }

        return null;
    }

    private setValueOnView(value: number) {
        const { showTrailingZeros, minimumFractionDigits, precision } = this._configuration;
        const requiredValue = formatNumber(value, showTrailingZeros, minimumFractionDigits, precision);

        if (this.nativeElement.value !== requiredValue) {
            this.nativeElement.value = requiredValue;
        }
    }
}
