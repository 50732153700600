import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AppConfigService } from "../../../core";

@Injectable({
    providedIn: "root"
})
export class DisclaimerHttpService {
    private readonly apiGatewayUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.apiGatewayUrl = appConfigService.config.apiGatewayUrl;
    }

    get(format: "markdown" | "richtext" = "markdown"): Observable<string> {
        const url = `${this.apiGatewayUrl}/api/v1.0/disclaimer?format=${format}`;
        return this.httpClient.get(url, { responseType: "text" });
    }
}
