import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { deepFreeze } from "../../../app/shared/deep-freeze";
import { deepCopy } from "../../../app/shared/utils";
import { LaytimeSummaryModel } from "../shared/models/form-models/laytime-summary-aggregate.model";

@Injectable({
    providedIn: "root"
})
export class LaytimeCalculationService {
    private currentLaytimeSummary: BehaviorSubject<LaytimeSummaryModel>;
    private laytimeSummary: LaytimeSummaryModel;

    constructor() {
        this.currentLaytimeSummary = new BehaviorSubject<LaytimeSummaryModel>(this.laytimeSummary);
    }

    get currentLaytimeSummary$(): Observable<LaytimeSummaryModel> {
        return this.currentLaytimeSummary.asObservable();
    }

    setLaytimeSummary(summary: LaytimeSummaryModel) {
        this.laytimeSummary = summary;
        const immutableLaytimeSummary = deepFreeze(deepCopy(summary)) as LaytimeSummaryModel;
        this.currentLaytimeSummary.next(immutableLaytimeSummary);
    }
}
