import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { compare } from "fast-json-patch";
import { DateTime } from "luxon";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, withLatestFrom } from "rxjs/operators";

import { WorksheetHttpService } from "../../../left-bar/worksheets/service/worksheet-http.service";
import { Worksheet, WorksheetId } from "../../model";
import { addToObjectMap } from "../../utils";
import { validateWorksheetGridRenameForm } from "../form/validation";
import { selectedWorksheetStateReducer, worksheetStateReducer } from "../reducer";
import { selectCurrentWorksheet, selectGridRenameForm, selectSelectedWorksheet } from "../selectors";
import { getSelectedWorksheetState, getWorksheetState, WorksheetFeatureState, WorksheetsState } from "../state";
import { isSortStateEmpty, toComparableWorksheet } from "../utils";

/* ACTIONS */
const SAVE_NEW_ACTION_NAME = "[Worksheets] Save New Worksheet";
const UPDATE_ACTION_NAME = "[Worksheets] Update";

export const saveNewWorksheetAction = createAction(SAVE_NEW_ACTION_NAME);
export const saveNewWorksheetSuccessAction = createAction(`${SAVE_NEW_ACTION_NAME} Success`, props<{ worksheet: Worksheet }>());
export const saveNewWorksheetFailAction = createAction(`${SAVE_NEW_ACTION_NAME} Fail`, props<{ error: Error }>());

export const updateWorksheetAction = createAction(UPDATE_ACTION_NAME);
export const updateWorksheetSuccessAction = createAction(`${UPDATE_ACTION_NAME} Success`, props<{ worksheet: Worksheet }>());
export const updateWorksheetFailAction = createAction(`${UPDATE_ACTION_NAME} Fail`, props<{ error: Error; worksheetId: WorksheetId }>());

/* REDUCERS */
export const saveNewWorksheetReducer: On<WorksheetsState> = on(saveNewWorksheetAction, (state) => {
    const gridRenameForm = validateWorksheetGridRenameForm(state);

    return gridRenameForm.isValid ? { ...state, gridRenameFormSaveStatus: "persisting" } : { ...state, gridRenameForm };
});

export const saveNewWorksheetSuccessReducer: On<WorksheetsState> = on(saveNewWorksheetSuccessAction, (state, { worksheet }) => ({
    ...state,
    gridRenameForm: null,
    gridRenameFormSaveStatus: "persisted",
    currentWorksheet: worksheet,
    selectedWorksheetId: worksheet.worksheetId,
    worksheets: addToObjectMap(state.worksheets, worksheet, "worksheetId", (w) => ({ worksheet: w }))
}));

export const saveNewWorksheetFailReducer: On<WorksheetsState> = on(saveNewWorksheetFailAction, (state) => ({ ...state, gridRenameFormSaveStatus: "failed" }));

export const updateWorksheetReducer: On<WorksheetsState> = on(updateWorksheetAction, (state) => {
    if (!getSelectedWorksheetState(state)) {
        return state;
    }

    return selectedWorksheetStateReducer(state, (worksheetState) => ({
        ...worksheetState,
        saveStatus: "persisting"
    }));
});

export const updateWorksheetSuccessReducer: On<WorksheetsState> = on(updateWorksheetSuccessAction, (state, { worksheet }) => {
    if (!getWorksheetState(state, worksheet.worksheetId)) {
        return state;
    }

    return worksheetStateReducer(state, worksheet.worksheetId, (worksheetState) => ({
        ...worksheetState,
        worksheet,
        saveStatus: "persisted",
        error: null
    }));
});

export const updateWorksheetFailReducer: On<WorksheetsState> = on(updateWorksheetFailAction, (state, { error, worksheetId }) => {
    if (!getWorksheetState(state, worksheetId)) {
        return state;
    }

    return worksheetStateReducer(state, worksheetId, (worksheetState) => ({
        ...worksheetState,
        saveStatus: "failed",
        error: { error, message: "Unable to save worksheet" }
    }));
});

/* EFFECTS */
export const saveNewWorksheetEffect$ = (actions$: Actions, store: Store<WorksheetFeatureState>, worksheetHttpService: WorksheetHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveNewWorksheetAction),
            withLatestFrom(store.select(selectGridRenameForm), store.select(selectCurrentWorksheet)),
            filter(([, form]) => form.isValid),
            exhaustMap(([, form, currentWorksheet]) => {
                const createWorksheet = { ...currentWorksheet, name: form.value.name, sort: !isSortStateEmpty(currentWorksheet.sort) ? currentWorksheet.sort : null };

                return worksheetHttpService.post(createWorksheet).pipe(
                    map((response) => {
                        const location = response.headers.get("Location");
                        const worksheetId = location.substring(location.lastIndexOf("/") + 1);
                        const worksheet: Worksheet = { ...createWorksheet, worksheetId, updatedDate: DateTime.local(), userId: null };

                        return saveNewWorksheetSuccessAction({ worksheet });
                    }),
                    catchError((error) => of(saveNewWorksheetFailAction({ error })))
                );
            })
        )
    );

export const updateWorksheetEffect$ = (actions$: Actions, store: Store<WorksheetFeatureState>, worksheetHttpService: WorksheetHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(updateWorksheetAction),
            withLatestFrom(store.select(selectCurrentWorksheet), store.select(selectSelectedWorksheet)),
            filter(([, , selectedWorksheet]) => !!selectedWorksheet),
            exhaustMap(([, currentWorksheet, selectedWorksheet]) => {
                const savedUpdate = toComparableWorksheet(selectedWorksheet);
                const changedUpdate = toComparableWorksheet(currentWorksheet);
                const patch = compare(savedUpdate, changedUpdate);

                return worksheetHttpService.patch(selectedWorksheet.worksheetId, patch).pipe(
                    map(() =>
                        updateWorksheetSuccessAction({
                            worksheet: { ...currentWorksheet, worksheetId: selectedWorksheet.worksheetId, updatedDate: DateTime.local(), userId: null }
                        })
                    ),
                    catchError((error) => of(updateWorksheetFailAction({ error, worksheetId: selectedWorksheet.worksheetId })))
                );
            })
        )
    );
