import { AbstractControl, ValidatorFn } from "@angular/forms";

import { parseISODate } from "../../../../../shared/date-utils/date-utilities";
import { Period } from "../../../../shared/models";

export class ActualDeliveryDateValidator {
    static validate(getPeriods: () => Period[]): ValidatorFn {
        return (control: AbstractControl): null => {
            const actualDeliveryDateControl = control.get("actualDeliveryDate");
            const periods = getPeriods();

            if (actualDeliveryDateControl && actualDeliveryDateControl.value && periods && periods.length > 1) {
                const actualDeliveryDate = actualDeliveryDateControl.value;
                const periodFromDate = parseISODate(periods[1].periodRange.from);

                if (periodFromDate && actualDeliveryDate.getTime() > periodFromDate.getTime()) {
                    actualDeliveryDateControl.setErrors({ laterThanSecondHirePeriod: true });
                }
            }

            return null;
        };
    }
}
