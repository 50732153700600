import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mapTo, mergeMap, tap, withLatestFrom } from "rxjs/operators";

import { selectCurrentUser, User } from "@ops/state";

import { EmailPreviewService } from "../../../../shared/email";
import { VoyageHttpService } from "../../../services";
import { EmailGenerationService, LaytimeEmailOptions, PortTimesEmailGenerator } from "../../../shared/email";
import { Voyage } from "../../../shared/models";
import { selectCurrentFixtureId } from "../../fixture";
import { FixtureFeatureState, FixtureId, FixturesState, VoyageId } from "../../model";
import { BerthId, DestinationId } from "../../model";
import { selectCurrentVoyage } from "../../voyage";
import { voyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
const GENERATE_ACTION_NAME = "[Voyage Form] Generate Port Times Email";
const AUDIT_ACTION_NAME = "[Voyage Form] Audit Generate Port Times Email";

export const generatePortTimesEmailAction = createAction(GENERATE_ACTION_NAME, props<{ destinationId: DestinationId; berthId: BerthId }>());
export const auditGeneratePortTimesEmailAction = createAction(
    AUDIT_ACTION_NAME,
    props<{ fixtureId: FixtureId; voyageId: VoyageId; destinationId: DestinationId; berthId: BerthId }>()
);
export const auditGeneratePortTimesEmailSuccessAction = createAction(
    `${AUDIT_ACTION_NAME} Success`,
    props<{ fixtureId: FixtureId; voyageId: VoyageId; destinationId: DestinationId; berthId: BerthId; date: string; user: User }>()
);
export const auditGeneratePortTimesEmailFailAction = createAction(
    `${AUDIT_ACTION_NAME} Fail`,
    props<{ fixtureId: FixtureId; voyageId: VoyageId; destinationId: DestinationId; berthId: BerthId; error: Error }>()
);

/* REDUCERS */
export const auditGeneratePortTimesEmailSuccessReducer: On<FixturesState> = on(
    auditGeneratePortTimesEmailSuccessAction,
    (state, { voyageId, destinationId, berthId, date, user }) => {
        function updateVoyage(voyage: Voyage) {
            return {
                ...voyage,
                destinations: voyage.destinations.map((destination) => {
                    if (destination.id === destinationId) {
                        return {
                            ...destination,
                            berths: destination.berths.map((berth) => {
                                if (berth.id === berthId) {
                                    return {
                                        ...berth,
                                        laytimeEmailGenerated: { date, user }
                                    };
                                }

                                return berth;
                            })
                        };
                    }

                    return destination;
                })
            };
        }

        return voyageStateReducer(state, voyageId, (voyageState) => ({
            ...voyageState,
            voyage: updateVoyage(voyageState.voyage),
            workingVoyage: voyageState.workingVoyage ? updateVoyage(voyageState.workingVoyage) : voyageState.workingVoyage
        }));
    }
);

/* EFFECTS */
export const generatePortTimesEmailEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, emailGeneration: EmailGenerationService, emailPreviewer: EmailPreviewService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(generatePortTimesEmailAction),
            withLatestFrom(store.select(selectCurrentFixtureId), store.select(selectCurrentVoyage)),
            mergeMap(([{ destinationId, berthId }, fixtureId, voyage]) => {
                const options: LaytimeEmailOptions = { destinationId, berthId };
                return emailGeneration.generate(PortTimesEmailGenerator, options).pipe(
                    tap((email) => {
                        const destination = voyage && voyage.destinations && voyage.destinations.find((x) => x.id === destinationId);
                        const destinationName = (destination && destination.location && destination.location.displayName) || "Unknown location";
                        const berth = destination && destination.berths.find((x) => x.id === berthId);
                        const berthName = (berth && berth.name) || "Untitled berth";

                        return emailPreviewer.preview(email, [destinationName, berthName, "Port Times"]);
                    }),
                    mapTo(auditGeneratePortTimesEmailAction({ fixtureId, voyageId: voyage.voyageId, destinationId, berthId }))
                );
            })
        )
    );

export const auditGeneratePortTimesEmailEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, voyageService: VoyageHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(auditGeneratePortTimesEmailAction),
            withLatestFrom(store.select(selectCurrentUser)),
            mergeMap(([{ fixtureId, voyageId, destinationId, berthId }, user]) =>
                voyageService.updateLaytimeEmailGenerated(fixtureId, voyageId, destinationId, berthId).pipe(
                    map((date) =>
                        auditGeneratePortTimesEmailSuccessAction({
                            fixtureId,
                            voyageId,
                            destinationId,
                            berthId,
                            date,
                            user
                        })
                    ),
                    catchError((err) =>
                        of(
                            auditGeneratePortTimesEmailFailAction({
                                fixtureId,
                                voyageId,
                                destinationId,
                                berthId,
                                error: err
                            })
                        )
                    )
                )
            )
        )
    );
