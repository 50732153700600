<div>
    <div *ngFor="let destination of laytimeSummary?.destinations; let i = index">
        <div class="row summary-divider" *ngIf="displayDestination(destination)">
            <div class="col-2">
                <span class="flag" *ngIf="destination?.location">
                    <img class="flex-shrink-1 position-absolute" [src]="getFlagImageUrl(destination?.location)" />
                </span>
                <span class="location summary-display" *ngIf="destination?.location"> {{ destination?.location?.displayName }} ({{ destination?.location?.countryName }}) </span>
            </div>
            <div class="col-10 justify-content-end">
                <div *ngFor="let berth of destination.berths; let b = index" class="berth-row">
                    <div class="row">
                        <div class="col-1 summary-display">
                            {{ berth?.name }}
                        </div>
                        <div class="col-4">
                            <div *ngFor="let berthActivity of berth.cargoBerthActivities; let a = index">
                                <div class="row no-gutters" *ngIf="isLoadOrDischarge(berthActivity)">
                                    <div class="col-4 summary-display activity">
                                        <span class="tag {{ berthActivity?.type?.name.toLowerCase() }}">{{ setLoadOrDischarge(berthActivity?.type) }} </span>
                                        <span class="cargoes-display">
                                            {{ getCargoes(berthActivity) }}
                                        </span>
                                    </div>
                                    <div class="col-4 summary-body">
                                        {{ getLatestEvent(berthActivity, destination, berth) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="laytime-type">
                            <span class="tag discharge" *ngIf="!berth.hasMixedLaytimeTypes && !isFixed && berth.reversible !== null && !berth.reversible">N-R</span>
                            <span class="tag load" *ngIf="!berth.hasMixedLaytimeTypes && !isFixed && berth.reversible !== null && berth.reversible">R</span>
                        </div>
                        <div class="col-3">
                            <div class="row no-gutters d-table">
                                <div *ngIf="!berth.hasMixedLaytimeTypes && !isFixed" class="d-table-row">
                                    <span class="summary-display summary-label d-table-cell">Allowed:</span>
                                    <span class="summary-body d-table-cell">{{ berth?.timeAllowed | durationFormat: displayUnitType }}</span>
                                </div>
                                <div *ngIf="!berth.hasMixedLaytimeTypes" class="d-table-row">
                                    <span class="summary-display summary-label d-table-cell">Used:</span>
                                    <span class="summary-body d-table-cell">{{ berth?.timeUsed | durationFormat: displayUnitType }}</span>
                                </div>
                                <div *ngIf="!berth.hasMixedLaytimeTypes && !isFixed" class="d-table-row">
                                    <span class="summary-display summary-label d-table-cell">Remaining:</span>
                                    <span class="summary-body d-table-cell">{{ berth?.timeRemaining | durationFormat: displayUnitType }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 summary-body" *ngIf="berth?.claimValue > 0">
                            <span class="claim-tag" *ngIf="berth?.claimType === DemurrageClaimType.Despatch">TIME SAVED</span>
                            <span class="claim-tag" *ngIf="berth?.claimType !== DemurrageClaimType.Despatch" [ngbTooltip]="berth?.demurrageLaytimeEventType?.name || ''"
                                >VESSEL ON DEMURRAGE</span
                            >
                            <span class="ecv-value">{{ berth?.claimValue | currency: fixture.currency?.code:"symbol":"1.2-2" }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
