<div *ngIf="invoicesExist" [formGroup]="invoicesForm">
    <div formArrayName="invoices">
        <div *ngFor="let invoice of invoices; let i = index; let isFirst = first" class="invoice">
            <div class="card" [formGroupName]="i">
                <input type="hidden" formControlName="originalInvoiceIndex" />
                <div class="invoice-summary card-header row pl-0 no-gutters align-items-center" [ngClass]="{ collapsed: !invoice.accordion.isOpen }">
                    <div>
                        <button
                            type="button"
                            (click)="toggleAccordion(invoice)"
                            class="has-icon btn-inverted"
                            [ngClass]="{ 'icon--arrow-drop-right': !invoice.accordion.isOpen, 'icon--arrow-drop-down': invoice.accordion.isOpen }"
                            data-test-toggle-invoiceaccordion
                        ></button>
                    </div>
                    <div
                        class="validation-indicator has-icon icon--warning"
                        [class.invalid]="invoice.invalid"
                        [class.touched]="invoice.touched"
                        container="body"
                        ngbTooltip="There are validation errors on this invoice"
                        data-test-invoices-warning
                    ></div>
                    <ops-invoice-summary
                        [invoiceNumber]="invoice.get('externalInvoiceNumber').value"
                        [receivedFromOwnerDate]="invoice.get('receivedFromOwnerDate').value"
                        [showCoveringPeriod]="true"
                        [coveringPeriod]="invoice.get('associatedPeriod').value?.description"
                        [value]="invoice.get('grossValue')?.value | currency: invoice.get('currency').value.code + ' ':'code'"
                        [status]="invoice.get('allocatedStatus').value?.name"
                    >
                    </ops-invoice-summary>
                    <div class="align-right last">
                        <ops-deletion-confirmation
                            buttonClass="btn-inverted"
                            [disabled]="invoicesForm.disabled"
                            (deleteConfirmed)="deleteInvoice(i)"
                            data-test-invoicedelete
                        ></ops-deletion-confirmation>
                    </div>
                </div>
                <div class="card-body" [hidden]="!invoice.accordion.isOpen" data-test-invoiceaccordion>
                    <div class="row no-gutters">
                        <div class="col-9 invoice-details">
                            <div class="row no-gutters">
                                <div class="form-group col-xl-3">
                                    <label>Hire Period</label>
                                    <ng-select
                                        class="ops-select"
                                        formControlName="associatedPeriodHireId"
                                        [items]="periods"
                                        [clearable]="true"
                                        bindLabel="description"
                                        bindValue="hireId"
                                        [selectOnTab]="true"
                                        data-test-invoiceassociatedperiod
                                    ></ng-select>
                                </div>
                                <div class="form-group col-xl-3">
                                    <label>Covering Period</label>
                                    <ops-date-input
                                        mode="range"
                                        timeZone="utc"
                                        [minDate]="invoice.value.associatedPeriod?.period?.start"
                                        [maxDate]="invoice.value.associatedPeriod?.period?.end"
                                        formControlName="coveringPeriod"
                                        [enableTime]="true"
                                        [defaultFocusDate]="isFirst ? null : invoices[i - i].value.coveringPeriod?.end"
                                        [opsValidationPopover]="coveringValidation"
                                        data-test-covering-period
                                    ></ops-date-input>
                                    <ng-template #coveringValidation>
                                        <ops-field-validation
                                            *ngIf="invoice.controls.coveringPeriod.hasError('date')"
                                            fieldDisplayName="Covering Period" [errorMessage]="invoice.controls.coveringPeriod.errors.date.invalid"
                                        ></ops-field-validation>
                                        <ops-field-validation
                                            *ngIf="invoice.controls.coveringPeriod.hasError('minDate') || invoice.controls.coveringPeriod.hasError('maxDate')"
                                            fieldDisplayName="Covering Period" errorMessage="must be within a hire period date range"
                                        ></ops-field-validation>
                                    </ng-template>
                                </div>
                                <div class="form-group col-xl">
                                    <label>Currency</label>
                                    <ng-select
                                        id="currency"
                                        class="ops-select"
                                        formControlName="currency"
                                        [compareWith]="referenceDataService.compareCurrencies"
                                        [items]="currencies$ | async"
                                        [clearable]="false"
                                        bindLabel="code"
                                        [selectOnTab]="true"
                                        data-test-invoicecurrency
                                    ></ng-select>
                                </div>
                                <div class="form-group col-xl required">
                                    <label>Value</label>
                                    <input class="form-control text-right" formControlName="grossValue" [opsNumber]="{ precision: 2 }" data-test-invoicevalue />
                                    <ops-validation [form]="invoice.get('grossValue')"></ops-validation>
                                </div>
                                <div class="form-group col-xl">
                                    <label>Rec. From Owner</label>
                                    <ops-date-input
                                        timeZone="utc"
                                        formControlName="receivedFromOwnerDate"
                                        [defaultFocusDate]="invoice.value.coveringPeriod?.start"
                                        data-test-invoicereceivedfromowner
                                    ></ops-date-input>
                                </div>
                                <div class="form-group col-xl pr-2">
                                    <label>Sent To Charterer</label>
                                    <ops-date-input
                                        timeZone="utc"
                                        formControlName="sentToChartererDate"
                                        [defaultFocusDate]="invoice.value.coveringPeriod?.start"
                                        data-test-invoicesenttocharterer
                                    ></ops-date-input>
                                </div>
                            </div>
                            <div class="row no-gutters">
                                <div class="form-group col-xl">
                                    <label>Sent To Accounts</label>
                                    <ops-date-input
                                        timeZone="utc"
                                        formControlName="sentToAccountsDate"
                                        [defaultFocusDate]="invoice.value.coveringPeriod?.start"
                                        data-test-senttoaccountsdate
                                    ></ops-date-input>
                                </div>

                                <div class="form-group col-xl">
                                    <label>Invoice Number</label>
                                    <input class="form-control" formControlName="externalInvoiceNumber" data-test-invoiceexternalinvoicenumber />
                                </div>

                                <div class="form-group col-xl">
                                    <label>Invoice Date</label>
                                    <ops-date-input
                                        timeZone="utc"
                                        formControlName="invoiceDate"

                                        data-test-invoicedate
                                    ></ops-date-input>
                                </div>
                                <div class="form-group col-xl">
                                    <label>Allocated Status</label>
                                    <ng-select
                                        class="ops-select form-control"
                                        [items]="allocatedStatuses"
                                        placeholder="Select"
                                        formControlName="allocatedStatus"
                                        [clearable]="true"
                                        bindLabel="name"
                                        [compareWith]="referenceDataService.compare"
                                        data-test-invoiceallocatedstatus
                                        [selectOnTab]="true"
                                    >
                                    </ng-select>
                                </div>
                                <div class="form-group col-xl">
                                    <label>Amount Paid</label>
                                    <input
                                        class="form-control text-right"
                                        formControlName="amountPaid"
                                        [opsNumber]="{ precision: 2 }"
                                        data-test-invoiceamountpaid
                                    />
                                </div>
                                <div class="form-group col-xl">
                                    <label>Date Paid</label>
                                    <ops-date-input timeZone="utc" formControlName="datePaid"  data-test-datepaid></ops-date-input>
                                </div>

                                <div class="form-group col-xl">
                                    <label>Base Hire Rate</label>
                                    <input
                                        class="form-control input-sm text-right"
                                        formControlName="baseHireRate"
                                        [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                                        readonly
                                        data-test-hire-rate
                                    />
                                </div>
                                <div class="form-group col-xl pr-2">
                                    <label>Actual Hire Rate</label>
                                    <input
                                        class="form-control text-right"
                                        formControlName="actualHireRate"
                                        [opsNumber]="{ precision: 6, showTrailingZeros: false }"
                                        data-test-actual-hire-rate
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-3 form-group d-flex flex-column">
                            <label>Comments</label>
                            <textarea
                                formControlName="comments"
                                rows="5"
                                cols="75"
                                id="deliveryNotes"
                                class="form-control flex-grow-1"
                                data-test-invoicecomments
                            ></textarea>
                            <ops-validation [form]="invoice.get('comments')"></ops-validation>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col text-right last-updated">Last Saved by:&nbsp;</div>
                        <div class="col-auto pull-right last-updated" *ngIf="!invoice.disabled" data-test-lastupdated>
                            {{ invoice.controls["lastUpdatedByUser"].value?.fullName }}
                            {{ invoice.controls["lastUpdatedDate"].value | date: "dd MMM yy, HH:mm:ss" }}
                        </div>
                        <ops-load-animation class="col-auto pull-right light-background" size="small" *ngIf="invoice.disabled"></ops-load-animation>
                    </div>
                </div>
            </div>
        </div>
        <div class="row newinvoice">
            <div class="col text-right">
                <button type="button" class="btn btn-primary btn-add m-0" [disabled]="invoicesForm.disabled" (click)="addInvoice()" data-test-add-invoice>
                    <span class="has-icon icon--add-circle-outline"></span> INVOICE
                </button>
            </div>
        </div>
    </div>
</div>
<ops-no-data-panel
    *ngIf="!invoicesExist"
    heading="Invoices"
    title="No invoices have been added"
    description="To add an invoice, click the button"
    [useNewButton]="true"
    newButtonText="INVOICE"
    [disabled]="invoicesForm.disabled"
    (addNew)="addInvoice()"
>
</ops-no-data-panel>
