import { ChangeDetectionStrategy, EventEmitter, OnDestroy, Output } from "@angular/core";
import { Input } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { LaytimeCalculationUnit } from "@ops/shared/reference-data";

import { Destination } from "../../shared/models";
import { CargoAllowedRate } from "../../shared/models/dtos/cargo-allowed-rate.dto";
import { Command } from "./../../mediator/commands/command";
import { Cargo } from "./../../shared/models/dtos/cargo.dto";
import { Fixture } from "./../../shared/models/dtos/fixture.dto";

@Component({
    selector: "ops-laytime-calculation",
    templateUrl: "./laytime-calculation.component.html",
    styleUrls: ["./laytime-calculation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationComponent implements OnInit, OnDestroy {
    laytimeCalculationForm: UntypedFormGroup;
    isAccordionClosed: boolean;

    @Output() laytimeCalculationUpdated = new EventEmitter<Command>();
    @Input() destination: Destination;
    @Input() parentForm: UntypedFormGroup;
    @Input() fixture: Fixture;
    @Input() cargoes: Cargo[];
    @Input() destinations: Destination[];
    @Input() cargoAllowedRates: CargoAllowedRate[];
    @Input() displayUnitType: LaytimeCalculationUnit;

    constructor(private formBuilder: UntypedFormBuilder) {}

    ngOnInit() {
        this.laytimeCalculationForm = this.formBuilder.group({});
        this.parentForm.registerControl("laytimeCalculationForm", this.laytimeCalculationForm);
        if (this.parentForm.disabled) {
            this.laytimeCalculationForm.disable();
        } else {
            this.laytimeCalculationForm.enable();
        }
    }

    ngOnDestroy() {
        delete this.parentForm.controls.laytimeCalculationForm;
    }

    get locationName() {
        return this.destination.location ? this.destination.location.displayName : "";
    }

    toggleAccordion(): void {
        this.isAccordionClosed = !this.isAccordionClosed;
    }

    updateStatementOfFacts(event: Command): void {
        this.laytimeCalculationUpdated.emit(event);
    }
}
