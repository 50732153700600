import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { FormControlState, FormControlValueTypes } from "ngrx-forms";

import { getIsVisible } from "@ops/state";

@Directive({
    selector: "[opsNgrxVisibility]"
})
export class NgrxVisibilityDirective<TStateValue extends FormControlValueTypes> {
    private hasView = false;

    constructor(private templateRef: TemplateRef<unknown>, private viewContainer: ViewContainerRef) {}

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("opsNgrxVisibility") set opsNgrxVisibility(state: FormControlState<TStateValue>) {
        if (state) {
            if (getIsVisible(state) && !this.hasView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.hasView = true;
            } else if (!getIsVisible(state) && this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
            }
        }
    }
}
