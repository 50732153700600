import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { AuthService } from "../../../core";
import { FixtureDataService } from "../../services/fixture-data.service";
import { DemurrageClaim, Destination, Fixture } from "../../shared/models";
import { DemurrageClaimType } from "../../shared/models/enums/demurrage-claim-type";
import { AddDemurrageClaimCommand } from "./commands/add-demurrage-claim.command";

@Component({
    selector: "ops-demurrage-claim-container",
    templateUrl: "./demurrage-claim-container.component.html",
    styleUrls: ["./demurrage-claim-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemurrageClaimContainerComponent implements OnInit, OnDestroy {
    static componentName = "DemurrageClaimContainerComponent";

    private adding = false;
    private dataSubscription: Subscription;

    demurrageClaims: DemurrageClaim[];
    demurrageClaimContainerFormArray: UntypedFormArray;
    newlyAddedId: number;
    onAccountIndexMap: Map<number, number>;
    fixtureId: string;

    @Input() parentForm: UntypedFormGroup;
    @Input() destinations: Destination[];
    @Input() fixture: Fixture;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private fixtureDataService: FixtureDataService,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.demurrageClaimContainerFormArray = this.formBuilder.array([]);

        this.parentForm.registerControl("demurrageClaimContainerFormArray", this.demurrageClaimContainerFormArray);

        if (this.parentForm.disabled) {
            this.demurrageClaimContainerFormArray.disable();
        } else {
            this.demurrageClaimContainerFormArray.enable();
        }

        this.dataSubscription = this.fixtureDataService.currentFixture$.pipe(filter((fixture) => !!fixture)).subscribe((fixture) => {
            if (this.adding) {
                this.newlyAddedId = Math.max(...fixture.demurrage.claims.map((claim) => claim.demurrageClaimId));
            } else {
                this.newlyAddedId = null;
            }
            this.fixtureId = fixture.fixtureId;
            this.adding = false;
            this.resizeList(fixture.demurrage.claims.length);

            if (this.parentForm.disabled) {
                this.demurrageClaimContainerFormArray.disable();
            } else {
                this.demurrageClaimContainerFormArray.enable();
            }

            this.demurrageClaims = fixture.demurrage.claims;
            this.onAccountIndexMap = this.buildOnAccountIndexMap(fixture.demurrage.claims || []);
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy() {
        delete this.parentForm.controls.demurrageClaimContainerFormArray;

        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
            this.dataSubscription = null;
        }
    }

    addDemurrageClaim(): void {
        this.adding = true;
        this.fixtureDataService.handleUpdateCommand(new AddDemurrageClaimCommand(this.authService.user, this.destinations));
    }

    private resizeList(count: number): void {
        const formArray = <UntypedFormArray>this.demurrageClaimContainerFormArray;

        if (formArray.length === count) {
            return;
        } else if (formArray.length > count) {
            formArray.removeAt(0);
        } else if (formArray.length < count) {
            const newRow = this.formBuilder.group({}, { updateOn: "blur" });
            if (this.parentForm.disabled) {
                newRow.disable();
            } else {
                newRow.enable();
            }
            formArray.push(newRow);
        }

        return this.resizeList(count);
    }

    private buildOnAccountIndexMap(claims: DemurrageClaim[]): Map<number, number> {
        const map = claims
            .map((claim, sequence) => ({ type: claim.type, index: sequence }))
            .filter((claim) => claim.type && claim.type.id === DemurrageClaimType.OnAccount)
            .map((claim, sequence) => [claim.index, sequence + 1] as [number, number]);

        return new Map<number, number>(map);
    }
}
