<div [formGroup]="statementOfFactsForm" class="card">
    <div class="card-body">
        <div class="form-row col-9 m-0">
            <div class="form-group col-3">
                <label for="workingDay">Working Day</label>
                <ng-select
                    class="ops-select"
                    [items]="workingDayTypes$ | async"
                    placeholder="Select"
                    formControlName="workingDayType"
                    [clearable]="true"
                    bindLabel="name"
                    id="workingDayType"
                    data-test-working-day-type
                >
                </ng-select>
            </div>

            <div class="form-group d-flex align-items-end checkbox-container">
                <input id="unlessUsed_{{ destinationId }}" type="checkbox" name="unlessUsed" formControlName="unlessUsed" data-test-working-day-unless-used />
                <label for="unlessUsed_{{ destinationId }}">Unless Used</label>
            </div>

            <div class="form-group col-2">
                <label for="excludingFromDay">Excluding From</label>
                <ng-select
                    class="ops-select"
                    [items]="weekDays$ | async"
                    placeholder="Select"
                    formControlName="excludingFromDay"
                    [clearable]="true"
                    bindLabel="name"
                    id="excludingFromDayType"
                    data-test-excluding-from-type
                >
                </ng-select>
            </div>
            <div class="form-group col-1">
                <div class="ops-dp-footer-time">
                    <div class="ops-dp-time" data-test-from-date>
                        <ops-timepicker [spinners]="false" size="medium" formControlName="excludingFromTime"></ops-timepicker>
                    </div>
                </div>
            </div>

            <div class="form-group col-2">
                <label for="excludingToDay">Excluding To</label>
                <ng-select
                    class="ops-select"
                    [items]="weekDays$ | async"
                    placeholder="Select"
                    formControlName="excludingToDay"
                    [clearable]="true"
                    bindLabel="name"
                    id="excludingToDayType"
                    data-test-excluding-to-type
                >
                </ng-select>
            </div>
            <div class="form-group col-1">
                <div class="ops-dp-footer-time">
                    <div class="ops-dp-time" data-test-to-date>
                        <ops-timepicker [spinners]="false" size="medium" formControlName="excludingToTime"></ops-timepicker>
                    </div>
                </div>
            </div>
        </div>

        <div class="berth-statement-of-facts" *ngIf="destination.berths && destination.berths.length > 0">
            <div *ngFor="let control of berthFormArray.controls; let i = index" class="berth-statement-of-facts-row">
                <ops-berth-statement-of-facts
                    [parentForm]="control"
                    [berth]="destination.berths[i]"
                    [cargoes]="cargoes"
                    [destination]="destination"
                    [destinationId]="destination.id"
                    [locationTimezone]="destination.location && destination.location.timeZone ? destination.location.timeZone : 'utc'"
                    [presetEventStartDates]="!i"
                    [defaultEventStartDate]="destination.arrivalDateTime"
                    [fixture]="fixture"
                    [cargoAllowedRates]="cargoAllowedRates"
                    [displayUnitType]="displayUnitType"
                    (berthStatementOfFactsUpdated)="cargoStatementOfFactsUpdated($event)"
                >
                </ops-berth-statement-of-facts>
            </div>
        </div>
    </div>
</div>
