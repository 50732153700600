import { DOCUMENT } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, Output, ViewChild } from "@angular/core";
import { fromEvent, Subject } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";

import { WindowRefService } from "../../../core/window-ref.service";
import { isEventInsideElement } from "../../utils/html-utils";

export enum RowInsertPosition {
    NONE = 0,
    TOP = 1,
    BOTTOM = 2
}

export class TableInsertPosition {
    id: string;
    position: RowInsertPosition;
}

@Component({
    selector: "ops-table-insert-overlay",
    templateUrl: "./table-insert-overlay.component.html",
    styleUrls: ["./table-insert-overlay.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableInsertOverlayComponent implements AfterViewInit, OnDestroy {
    private readonly destroy$ = new Subject();
    private readonly maxTrDepth = 8;

    selectedKey: string | null = "";
    selectedInsertPosition: RowInsertPosition = RowInsertPosition.NONE;

    wrapTableRect: DOMRect;
    firstTdOfHoveredTrRect: DOMRect;
    plankWidth: number;
    mouseInsideTable = false;

    @Input() tolerancePixels = 2;
    @Input() disable = true;
    @Input() excludeLastColumnsFromWidth = 0;
    @Input() enablePlankAfterLastRow = false;

    @Output() insert: EventEmitter<TableInsertPosition> = new EventEmitter();

    @ViewChild("wrapTable") wrapTable: ElementRef;
    @ViewChild("plusOverlay") plusOverlay: ElementRef;
    @ViewChild("plusOverlayPlank") plusOverlayPlank: ElementRef;
    @ViewChild("overlayPlusButton") overlayPlusButton: ElementRef;

    @ContentChild("buttonsBlock", { static: false }) buttonsBlock: ElementRef | undefined;

    constructor(private elRef: ElementRef, private window: WindowRefService, @Inject(DOCUMENT) private document: Document, private zone: NgZone) {}

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            this.addDocumentListeners();
            this.addWrapTableElListeners();
            this.addPlusOverlayElListeners();
        });
    }

    ngOnDestroy() {
        this.destroy$.complete();
    }

    updateRects($event: MouseEvent) {
        this.wrapTableRect = this.getBoundingClientRect(this.wrapTable.nativeElement);
        this.updatePlankWidth();
        const hoveredTrEl = this.findParentTr($event.target as HTMLElement);
        if (hoveredTrEl?.firstChild) {
            this.firstTdOfHoveredTrRect = this.getBoundingClientRect(hoveredTrEl.firstChild as HTMLElement);
        }
    }

    plusOverlayClick(e: MouseEvent) {
        if (this.buttonsBlock) {
            this.toggleButtonsBlockVisibility(e);
        } else {
            this.addNewRow();
        }
    }

    mouseLeavePlusOverlay($event: MouseEvent) {
        if (!this.buttonsBlockIsVisible()) {
            this.clearOverlayRow();
            this.mouseLeaveTable($event);
        }
    }

    mouseEnterTable($event: MouseEvent) {
        if (!this.wrapTableRect) {
            this.updateRects($event);
        }

        if (this.disable || this.mouseInsideTable) {
            return;
        }

        this.mouseInsideTable = true;
        this.showPlaceholderDots();
    }

    mouseLeaveTable($event: MouseEvent) {
        if (!this.wrapTableRect) {
            this.updateRects($event);
        }

        if (isEventInsideElement($event, this.wrapTableRect)) {
            $event.preventDefault();
            return;
        }

        this.mouseInsideTable = false;
        this.clearPlaceholderDots();
        if (!this.buttonsBlockIsVisible()) {
            this.clearOverlayRow();
        }
    }

    getBoundingClientRect(element: Element) {
        return element.getBoundingClientRect();
    }

    getButtonsBlockDimensions() {
        return {
            width: this.buttonsBlock?.nativeElement.offsetWidth,
            height: this.buttonsBlock?.nativeElement.offsetHeight
        };
    }

    private addDocumentListeners() {
        fromEvent<MouseEvent>(this.document, "click")
            .pipe(
                filter(() => this.buttonsBlockIsVisible()),
                filter((e) => this.isOutsidePlusOverlay(e.target as Element)),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this.hideButtonsBlockAndOverlay());
    }

    private addPlusOverlayElListeners() {
        const plusOverlayEl = this.plusOverlay.nativeElement;

        fromEvent<MouseEvent>(plusOverlayEl, "mouseleave")
            .pipe(takeUntil(this.destroy$))
            .subscribe((e) => this.mouseLeavePlusOverlay(e));
        fromEvent<MouseEvent>(plusOverlayEl, "click")
            .pipe(takeUntil(this.destroy$))
            .subscribe((e) => this.plusOverlayClick(e));
    }

    private addWrapTableElListeners() {
        const wrapTableEl = this.wrapTable.nativeElement;
        const debounceTimeMs = 40;

        fromEvent<MouseEvent>(wrapTableEl, "mouseenter")
            .pipe(takeUntil(this.destroy$))
            .subscribe((e) => this.mouseEnterTable(e));
        fromEvent<MouseEvent>(wrapTableEl, "mouseleave")
            .pipe(takeUntil(this.destroy$))
            .subscribe((e) => this.mouseLeaveTable(e));
        fromEvent<MouseEvent>(wrapTableEl, "mousemove")
            .pipe(takeUntil(this.destroy$), debounceTime(debounceTimeMs))
            .subscribe((e) => this.mouseMoveOverTable(e));
    }

    private isOutsidePlusOverlay(target: Element) {
        return !(target?.closest && target.closest(".plus-overlay"));
    }

    private buttonsBlockIsVisible() {
        return this.buttonsBlock?.nativeElement?.style?.visibility === "visible";
    }

    private addNewRow() {
        if (this.selectedKey && this.selectedInsertPosition !== RowInsertPosition.NONE) {
            this.insert.emit({ id: this.selectedKey, position: this.selectedInsertPosition });
        }
    }

    private clearPlaceholderDots() {
        const table = this.elRef.nativeElement.querySelector("table");
        table.classList.remove("table-focus");
    }

    private showPlaceholderDots() {
        const table = this.elRef.nativeElement.querySelector("table");
        table.classList.add("table-focus");
    }

    private showOverlayRow(tdRect: DOMRect, rowInsertPosition: RowInsertPosition) {
        const adjustLeftByPixels = 22;
        const adjustTopByPixels = 18.5;
        const wrapTableRect = this.wrapTableRect;

        const left = tdRect.left - adjustLeftByPixels - wrapTableRect.left;
        const top = rowInsertPosition === RowInsertPosition.TOP ? tdRect.top - adjustTopByPixels - wrapTableRect.top : tdRect.bottom - adjustTopByPixels - wrapTableRect.top;

        this.selectedInsertPosition = rowInsertPosition;

        this.plusOverlay.nativeElement.style.top = top + "px";
        this.plusOverlay.nativeElement.style.left = left + "px";
        this.plusOverlay.nativeElement.style.visibility = "visible";
        this.plusOverlayPlank.nativeElement.style.width = this.plankWidth + "px";
    }

    private hideButtonsBlockAndOverlay() {
        this.hideButtonsBlock();
        this.clearOverlayRow();
    }

    private toggleButtonsBlockVisibility(e: MouseEvent) {
        if (!this.buttonsBlockIsVisible()) {
            this.showButtonsBlock(e);
        } else {
            this.hideButtonsBlock();
        }
    }

    private showButtonsBlock(e: MouseEvent) {
        const plankRect = this.getBoundingClientRect(this.plusOverlayPlank.nativeElement);
        const wrapTableRect = this.getBoundingClientRect(this.wrapTable.nativeElement);
        const windowHeight = this.window.nativeWindow.innerHeight;

        const buttonsBlock = this.buttonsBlock?.nativeElement;
        const buttonsBlockDimensions = this.getButtonsBlockDimensions();
        const buttonsBlockTop =
            windowHeight - plankRect.bottom < buttonsBlockDimensions.height
                ? plankRect.top - wrapTableRect.top - buttonsBlockDimensions.height
                : plankRect.bottom - wrapTableRect.top;
        const buttonsBlockLeft = Math.min(e.clientX - wrapTableRect.left, wrapTableRect.width - buttonsBlockDimensions.width);

        buttonsBlock.setAttribute("selectedKey", this.selectedKey);
        buttonsBlock.setAttribute("selectedInsertPosition", `${this.selectedInsertPosition}`);
        buttonsBlock.style.top = buttonsBlockTop + "px";
        buttonsBlock.style.left = buttonsBlockLeft + "px";
        buttonsBlock.style.visibility = "visible";
    }

    private hideButtonsBlock() {
        if (this.buttonsBlock) {
            this.buttonsBlock.nativeElement.style.visibility = "hidden";
        }
    }

    private findParentTr(element: HTMLElement): HTMLElement | null {
        let depth = this.maxTrDepth;
        do {
            element = element.parentNode as HTMLElement;
            depth--;
        } while (element && element.tagName !== "TR" && element.tagName !== "TABLE" && depth >= 0);

        return element?.tagName === "TR" ? element : null;
    }

    private findNextTr(element: HTMLElement): HTMLElement | null {
        do {
            element = element.nextSibling as HTMLElement;
        } while (element && element.tagName !== "TR");

        return element?.tagName === "TR" ? element : null;
    }

    private updatePlankWidth() {
        const ele = this.elRef.nativeElement;
        const target = ele.querySelector("tr[rowkey]") as HTMLElement;
        if (!target) {
            return;
        }
        const firstChild = target.children[0];
        const lastChild = target.children[target.children.length - this.excludeLastColumnsFromWidth - 1];
        this.plankWidth = this.getBoundingClientRect(lastChild).right - this.getBoundingClientRect(firstChild).left;
    }

    private anyPopoverOpen(target?: HTMLElement) {
        if (target?.classList.contains("show-no-overlay")) {
            this.clearOverlayRow();
            return true;
        }

        const ele = this.elRef.nativeElement;
        return ele.querySelector("ng-dropdown-panel") !== null || ele.querySelector("ngb-datepicker") !== null;
    }

    private mouseMoveOverTable($event: MouseEvent) {
        if (this.disable) {
            return;
        }

        const tr = this.findParentTr($event.target as HTMLElement);
        const target = $event.target as HTMLElement;

        if (tr === null || this.anyPopoverOpen(target)) {
            return;
        }

        this.selectedKey = tr.getAttribute("rowkey");
        if (!this.selectedKey) {
            return;
        }

        this.updateRects($event);
        const tdRect = this.firstTdOfHoveredTrRect;
        const diffTop = $event.clientY - tdRect.top;
        const diffBottom = tdRect.bottom - $event.clientY;
        if (!this.plankWidth || $event.clientX > tdRect.left + this.plankWidth) {
            return;
        }

        if (diffTop <= this.tolerancePixels) {
            this.showOverlayRow(tdRect, RowInsertPosition.TOP);
        } else if (diffBottom <= this.tolerancePixels && (this.findNextTr(tr) || this.enablePlankAfterLastRow)) {
            this.showOverlayRow(tdRect, RowInsertPosition.BOTTOM);
        } else {
            this.clearOverlayRow();
        }
    }

    private clearOverlayRow() {
        if (this.plusOverlay.nativeElement.style.visibility === "visible") {
            this.selectedKey = null;
            this.selectedInsertPosition = RowInsertPosition.NONE;
            this.plusOverlay.nativeElement.style.visibility = "hidden";
        }
    }
}
