import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as R from "ramda";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";

import { isNullOrUndefined } from "@ops/shared";

import { selectCurrentLifting } from "../../..//coa/state/lifting/selectors";
import { ActionDto, ActionHttpService, LiveUpdatesHubService, CoaId, FixtureId, OriginId } from "../../../action";
import { LiftingId } from "../../../action/services/action-http.service";
import { selectCurrentCoa } from "../../../coa/state/coa/selectors";
import { CoaFeatureState } from "../../../coa/state/model";
import { Coa } from "../../../coa/state/model/coa";
import { Lifting } from "../../../coa/state/model/lifting/lifting";
import { FixtureDataService } from "../../../fixture/services/fixture-data.service";
import { Fixture, User } from "../../../fixture/shared/models";
import { parseISODate } from "../../../shared/date-utils/date-utilities";
import { Nullable } from "../../../shared/models/types";

@Injectable({
    providedIn: "root"
})
export class ActionDataService {
    private actionsSubject = new BehaviorSubject<ActionDto[]>(null);
    private sourceSubject = new BehaviorSubject<Nullable<Fixture | Coa | Lifting>>(null);
    private originSubject = new BehaviorSubject<OriginId>(null);
    private editingActionIdSubject = new BehaviorSubject<string>(null);

    private newActionIds = new Array<string>();

    constructor(
        private actionHttpService: ActionHttpService,
        private fixtureDataService: FixtureDataService,
        private liveUpdatesHubService: LiveUpdatesHubService,
        private store: Store<CoaFeatureState>
    ) {
        combineLatest([
            this.fixtureDataService.currentFixture$,
            this.store.select(selectCurrentCoa),
            this.store.select(selectCurrentLifting)
        ]).subscribe(([fixture, coa, lifting]) => this.addToSource(fixture, coa, lifting));

        this.source$
            .pipe(
                map((item: any) => this.getOrigin(item)),
                distinctUntilChanged((a, b) => R.equals(a, b)),
                tap((origin) => {
                    if (origin) {
                        this.originSubject.next(origin);
                    } else {
                        this.actionsSubject.next([]);
                        this.originSubject.next(null);
                    }
                }),
                switchMap((origin) => (origin?.source ? this.actionHttpService.getV2(origin) : of([])))
            )
            .subscribe((actions) => {
                this.actionsSubject.next(actions);
            });

        this.liveUpdatesHubService.actionsUpdated$.pipe(distinctUntilChanged(ActionDataService.distinctUntilUpdated)).subscribe((action) => this.onActionUpdated(action));

        this.liveUpdatesHubService.actionsDeleted$.pipe(distinctUntilChanged(ActionDataService.distinctUntilUpdated)).subscribe((action) => this.onActionsDeleted(action));
    }

    static distinctUntilUpdated(a1: ActionDto, a2: ActionDto): boolean {
        return a1.actionId === a2.actionId && a1.updatedDate === a2.updatedDate;
    }

    private static sortActions(a1: ActionDto, a2: ActionDto) {
        const a1Time = (parseISODate(a1.dueDate) && parseISODate(a1.dueDate).getTime()) || 0;
        const a2Time = (parseISODate(a2.dueDate) && parseISODate(a2.dueDate).getTime()) || 0;
        const a1Priority = (a1.priority && a1.priority.id) || 0;
        const a2Priority = (a2.priority && a2.priority.id) || 0;

        if (a1Time === a2Time) {
            if (a1Priority === a2Priority) {
                return a1.summary < a2.summary ? -1 : 1;
            }
            return a1Priority - a2Priority;
        }
        return a1Time - a2Time;
    }

    get actions$() {
        return this.actionsSubject.asObservable();
    }

    get source$() {
        return this.sourceSubject.asObservable();
    }

    get origin$() {
        return this.originSubject.asObservable();
    }

    get editingActionId$() {
        return this.editingActionIdSubject.asObservable();
    }

    setEditingActionIdValue(actionId: string) {
        this.editingActionIdSubject.next(actionId);
    }

    createAction(action: ActionDto): Observable<string> {
        return this.actionHttpService.createV2(this.originSubject.value, action).pipe(
            tap((actionIds) => this.newActionIds.push(actionIds[0])),
            tap(() => this.loadActions()),
            map((createdActionIds: string[]) => createdActionIds[0])
        );
    }

    deleteAction(actionId: string) {
        this.actionHttpService.deleteV2(this.originSubject.value, actionId).subscribe(() => {
            this.loadActions();
        });
    }

    updateAction(action: ActionDto) {
        this.actionHttpService.updateV2(this.originSubject.value, action).subscribe(() => {
            this.loadActions();
        });
    }

    updateActionComplete(actionId: string, complete: boolean) {
        this.actionHttpService.patchIsCompleteV2(this.originSubject.value, actionId, complete).subscribe(() => {
            this.loadActions();
        });
    }

    getDefaultAssignedTo(): User[] | null {
        const source = this.sourceSubject.value;

        if (!source) {
            return null;
        }

        if ("fixtureSource" in source) {
            return [...source.operators, ...source.claims.filter((c) => !source.operators.find((u) => u.userId === c.userId))];
        } else if ("coaId" in source) {
            return source.operators.map(
                (u) =>
                    <User>{
                        userId: u.userId,
                        userCode: u.userCode,
                        fullName: u.name
                    }
            );
        }

        throw Error("Unknown source");
    }

    getNewActionIds(): string[] {
        const ids = [...this.newActionIds];
        this.newActionIds = [];
        return ids;
    }

    private addToSource(fixture: Nullable<Fixture>, coa: Nullable<Coa>, lifting: Nullable<Lifting>) {
        if (lifting) {
            this.sourceSubject.next(lifting);
        } else if (coa) {
            this.sourceSubject.next(coa);
        } else if (fixture) {
            this.sourceSubject.next(fixture);
        } else {
            this.sourceSubject.next(null);
        }
    }

    private getOrigin(item: Fixture | Coa | Lifting | null) {
        if (item && "liftingId" in item) {
            return <LiftingId>{ liftingId: item.liftingId, coaId: item.coaId, source: "Lifting" };
        }
        if (item && "coaId" in item) {
            return <CoaId>{ coaId: item.coaId, source: "Coa" };
        }
        if (item && "fixtureId" in item) {
            return <FixtureId>{ fixtureId: item.fixtureId, source: "Fixture" };
        }
    }

    private loadActions() {
        return this.actionHttpService.getV2(this.originSubject.value).subscribe((actions: ActionDto[]) => {
            this.actionsSubject.next(actions);
        });
    }

    private onActionUpdated(action: ActionDto) {
        const actions = this.actionsSubject.value;
        const origin = this.originSubject.value;

        if (isNullOrUndefined(actions) || isNullOrUndefined(origin)) {
            return;
        }

        const index = actions.findIndex((x) => x.actionId === action.actionId);

        if (index !== -1) {
            actions[index] = action;
            this.actionsSubject.next(actions.sort(ActionDataService.sortActions));
        } else if (
            (origin.source === "Fixture" && action.fixtureId === origin.fixtureId) ||
            (origin.source === "Coa" && action.coaId === origin.coaId) ||
            (origin.source === "Lifting" && action.liftingId === origin.liftingId)
        ) {
            actions.push(action);
            this.actionsSubject.next(actions.sort(ActionDataService.sortActions));
        }
    }

    private onActionsDeleted(action: ActionDto) {
        const actions = this.actionsSubject.getValue();
        const index = actions.findIndex((x) => x.actionId === action.actionId);
        if (index !== -1) {
            actions.splice(index, 1);
            this.actionsSubject.next(actions);
        }
    }
}
