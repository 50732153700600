import { createAction, On, on, props } from "@ngrx/store";
import { FormGroupState, markAsPristine, updateGroup } from "ngrx-forms";
import * as R from "ramda";

import { markAsTransient } from "@ops/state";

import { updateActivityForms, updateDestinationForms } from "./form-updaters";
import { dateTimeFromISO, Nullable } from "../../../../shared";
import { MAX_SUPPORTED_DATE } from "../../../../shared/components/date/utils";
import { sortArrayControls } from "../../../../state";
import { forEachBerth } from "../../berths/shared";
import { forEachLaytimeEvent } from "../../laytime-events/laytime-calculation";
import { BerthForm, DestinationForm, FixturesState, getDestinationStartDate, getDestinationTimeZone, LaytimeEventForm, VoyageForm, VoyageId } from "../../model";
import { voyageStateReducer } from "../reducer";

/* ACTIONS */
const SAVE_ACTION_NAME = "[Fixture Form] Save Voyage";

export const saveVoyageAction = createAction(SAVE_ACTION_NAME, props<{ voyageId: VoyageId }>());
export const saveVoyageSuccessAction = createAction(`${SAVE_ACTION_NAME} Success`, props<{ voyageId: VoyageId }>());
export const saveVoyageFailAction = createAction(`${SAVE_ACTION_NAME} Fail`, props<{ voyageId: VoyageId; error: Error }>());

/* REDUCERS */
export const updateVoyagePersistenceStatusReducer: On<FixturesState> = on(saveVoyageAction, (state, action) =>
    voyageStateReducer(state, action.voyageId, (voyageState) => ({
        ...voyageState,
        persistenceStatus: voyageState.form.isValid ? "persisting" : "invalid"
    }))
);

export const sortDestinationsReducer: On<FixturesState> = on(saveVoyageAction, (state, action) =>
    voyageStateReducer(state, action.voyageId, (voyageState) => {
        const sorter = R.ascend<FormGroupState<DestinationForm>>(({ value }) =>
            getDestinationStartDate(value) ? dateTimeFromISO(getDestinationStartDate(value), getDestinationTimeZone(value) ?? "utc") : MAX_SUPPORTED_DATE
        );

        return {
            ...voyageState,
            form: updateGroup<VoyageForm>({
                destinations: sortArrayControls(sorter)
            })(voyageState.form)
        };
    })
);

export const sortBerthsReducer: On<FixturesState> = on(saveVoyageAction, (state, action) =>
    voyageStateReducer(state, action.voyageId, (voyageState) => {
        const berthTimeZoneMap = new Map<string, Nullable<string>>();

        forEachBerth(voyageState.form.value, (berth, { destination }) => berthTimeZoneMap.set(berth.id, getDestinationTimeZone(destination)));

        const sorter = R.ascend<FormGroupState<BerthForm>>(({ value }) => (value.etb ? dateTimeFromISO(value.etb, berthTimeZoneMap.get(value.id) ?? "utc") : MAX_SUPPORTED_DATE));

        return {
            ...voyageState,
            form: updateDestinationForms({
                berths: sortArrayControls(sorter)
            })(voyageState.form)
        };
    })
);

export const sortLaytimeEventsReducer: On<FixturesState> = on(saveVoyageAction, (state, action) =>
    voyageStateReducer(state, action.voyageId, (voyageState) => {
        const laytimeEventTimeZoneMap = new Map<string, Nullable<string>>();

        forEachLaytimeEvent(voyageState.form.value, (laytimeEvent, { destination }) => {
            laytimeEventTimeZoneMap.set(laytimeEvent.laytimeEventId, getDestinationTimeZone(destination));
        });

        const sorter = R.ascend<FormGroupState<LaytimeEventForm>>(({ value }) =>
            value.eventDate ? dateTimeFromISO(value.eventDate, laytimeEventTimeZoneMap.get(value.laytimeEventId) ?? "utc") : MAX_SUPPORTED_DATE
        );

        return {
            ...voyageState,
            form: updateActivityForms({
                laytimeEvents: sortArrayControls(sorter)
            })(voyageState.form)
        };
    })
);

export const saveVoyageSuccessReducer: On<FixturesState> = on(saveVoyageSuccessAction, (state, action) =>
    voyageStateReducer(state, action.voyageId, (voyageState) => ({
        ...voyageState,
        form: markAsTransient(false)(markAsPristine(voyageState.form)),
        persistenceStatus: "persisted"
    }))
);

export const saveVoyageFailReducer: On<FixturesState> = on(saveVoyageFailAction, (state, action) => voyageStateReducer(state, action.voyageId, { persistenceStatus: "failed" }));

/* EFFECTS */
/* See fixture/save-fixture.effects.ts */
