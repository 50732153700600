import { createAction, on, On } from "@ngrx/store";
import { createFormGroupState, focus, formStateReducer, SetValueAction, updateGroup } from "ngrx-forms";

import { WorksheetRenameForm } from "../../model";
import { WorksheetsState } from "../state";
import { WORKSHEET_GRID_RENAME_FORM_ID, worksheetRenameForm } from "../utils";

/* ACTIONS */
const NAME_NEW_WORKSHEET_ACTION_NAME = "[Worksheets] Name New Worksheet";

export const nameNewWorksheetAction = createAction(NAME_NEW_WORKSHEET_ACTION_NAME);
export const cancelNameNewWorksheetAction = createAction(`${NAME_NEW_WORKSHEET_ACTION_NAME} Cancel`);

/* REDUCERS */
export const nameNewWorksheetReducer: On<WorksheetsState> = on(nameNewWorksheetAction, (state) => {
    const groupState = createFormGroupState(WORKSHEET_GRID_RENAME_FORM_ID, worksheetRenameForm(state.currentWorksheet.name));
    const focused = updateGroup<WorksheetRenameForm>({ name: focus })(groupState);

    return { ...state, gridRenameForm: focused };
});

export const cancelNameNewWorksheetReducer: On<WorksheetsState> = on(cancelNameNewWorksheetAction, (state) => ({ ...state, gridRenameForm: null }));

export const gridRenameFormValueReducer: On<WorksheetsState> = {
    types: [SetValueAction.TYPE],
    reducer: (state: WorksheetsState, action: SetValueAction<string>): WorksheetsState => {
        const controlPath = action.controlId.split(".");
        if (controlPath.length < 2 || controlPath[0] !== WORKSHEET_GRID_RENAME_FORM_ID || controlPath[1] !== "name") {
            return state;
        }

        return {
            ...state,
            gridRenameForm: formStateReducer(state.gridRenameForm, new SetValueAction(WORKSHEET_GRID_RENAME_FORM_ID, { name: action.value }))
        };
    }
};
