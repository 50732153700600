import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";
import { ConfirmationService } from "primeng/api";
import { InputSwitch } from "primeng/inputswitch";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

import { AuthService } from "@ops/core";
import { AtSeaBunkerConsumptionMode } from "@ops/shared/reference-data";

import { UpdateOwnerEmailCommand } from "./commands/update-owner-email";
import { InitBunkerCommand } from "./voyage-bunker-consumption/commands/init-bunker";
import { Command } from "../mediator";
import { VoyageCost } from "../services/voyage-cost";
import { FormComponentBase } from "../shared/form-component-base";
import { AtSeaBunkerConsumption, CpSpeedAndConsumption, Delivery, Destination, FixtureType, Voyage } from "../shared/models";
import { FixtureTabName } from "../shared/tab-validation/tab-validation-info";
import {
    FixtureFeatureState,
    formSelectVoyage,
    selectCurrentFixtureVoyageNumbers,
    selectCurrentVoyageForm,
    VoyageForm,
    VoyageId,
    VoyageNumber,
    selectCurrentFixtureType,
    selectSpeedAndConsTabIsValid
} from "../state";

// This lovely addition to our codebase is Anglo specific lines of business as a "very short term solution" (but you'll
// likely be reading this in 2022).
const LINES_OF_BUSINESS = [
    "Biodiesel",
    "Bitumen/R",
    "BizDev",
    "Coal",
    "Copper",
    "Crude Americas",
    "Crude Asia",
    "Extpty",
    "Fuel Oil",
    "Fuel Oil Dubai",
    "Fuel Oil Singapore",
    "Gasoil",
    "IOB",
    "Indipendent Belgium Refinarary",
    "Kumba",
    "LPG S.T.T.",
    "Liquid Petroleum Gas",
    "New Shipping",
    "Other",
    "Speciality Ores",
    "Veg Oil"
];

@Component({
    selector: "ops-speed-and-consumption-tab",
    templateUrl: "./speed-and-consumption-tab.component.html",
    styleUrls: ["./speed-and-consumption-tab.component.scss"],
    providers: [ConfirmationService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpeedAndConsumptionTabComponent extends FormComponentBase implements OnInit, OnChanges, OnDestroy {
    static componentName = "SpeedAndConsumptionTabComponent";

    readonly linesOfBusiness = LINES_OF_BUSINESS;
    form$: Observable<FormGroupState<VoyageForm>>;
    voyageNumbers$: Observable<ReadonlyArray<VoyageNumber>>;
    hasSccAccess$: Observable<boolean>;
    speedAndConsumptionTabForm: UntypedFormGroup;
    sccForm: UntypedFormGroup;

    @Input() readonly: boolean;
    @Input() tabName: FixtureTabName;
    @Input() parentForm: UntypedFormGroup;
    @Input() delivery: Delivery;
    @Input() ownerEmail: string;
    @Input() destinations: Destination[];
    @Input() atSeaBunkersConsumption: AtSeaBunkerConsumption[];
    @Input() atSeaBunkerConsumptionMode: AtSeaBunkerConsumptionMode;
    @Input() cpSpeedAndConsumption: CpSpeedAndConsumption;
    @Input() formName: string;
    @Input() voyages$: Observable<Voyage[]>;
    @Input() voyageId: string;
    @Input() voyageCost?: VoyageCost;

    @Output() cpSpeedAndConsumptionTabUpdated = new EventEmitter<Command>();
    @Output() voyageBunkerConsumptionTabUpdated = new EventEmitter<Command>();

    get isSimpleMode() {
        return this.atSeaBunkerConsumptionMode?.id === AtSeaBunkerConsumptionMode.Simple.id;
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        protected store: Store<FixtureFeatureState>,
        private confirmationService: ConfirmationService,
        private authService: AuthService
    ) {
        super(store);
    }

    ngOnInit() {
        this.form$ = this.store.select(selectCurrentVoyageForm);
        this.voyageNumbers$ = this.store.select(selectCurrentFixtureVoyageNumbers);
        this.speedAndConsumptionTabForm = this.formBuilder.group({});
        this.parentForm.registerControl(this.formName, this.speedAndConsumptionTabForm);
        this.speedAndConsumptionTabForm[this.parentForm.disabled ? "disable" : "enable"]();

        const fixtureType$: Observable<FixtureType> = this.store.select(selectCurrentFixtureType);
        const hasSpeedAndConsumptionRole$: Observable<boolean> = this.authService.hasRole("SpeedAndConsumption");
        this.hasSccAccess$ = combineLatest([fixtureType$, hasSpeedAndConsumptionRole$]).pipe(
            map(([fixtureType, hasRole]) => fixtureType === FixtureType.TimeCharter || (fixtureType === FixtureType.Voyage && hasRole))
        );

        this.createForm();
        this.parentForm.registerControl("sccForm", this.sccForm);
        this.sccForm[this.parentForm.disabled ? "disable" : "enable"]();
        this.setForm();
        this.subscribeToFormStatusChangesAndNgrxFormChanges([this.speedAndConsumptionTabForm, this.sccForm], this.store.select(selectSpeedAndConsTabIsValid), this.tabName);
    }

    ngOnDestroy() {
        this.removeStatusChangesSubscrition();
        delete this.parentForm.controls.speedAndConsumptionTab;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["ownerEmail"] === undefined) {
            return;
        }

        if (this.sccForm) {
            this.setForm();
        }
    }

    selectVoyage(voyageId: VoyageId) {
        this.store.dispatch(formSelectVoyage({ voyageId }));
    }

    toggleMode(isSimple: boolean, inputSwitch: InputSwitch) {
        this.confirmationService.confirm({
            header: "Are you sure?",
            message: "Changing this Speed and Consumption view will cause you to lose your changes.",
            acceptLabel: "OK",
            rejectLabel: "CANCEL",
            acceptIcon: "none",
            rejectIcon: "none",
            accept: () => {
                this.updateVoyageBunkerConsumptionTab(new InitBunkerCommand(this.cpSpeedAndConsumption, this.delivery, true, isSimple));
            },
            reject: () => {
                // We have to reset the switch value here directly on the component as changes won't be detected for
                // the unchanged value (even calling next on an async pipe).
                inputSwitch.writeValue(this.isSimpleMode);
            }
        });
    }

    updateCpSpeedAndConsumptionTab(event: Command) {
        this.cpSpeedAndConsumptionTabUpdated.emit(event);
    }

    updateVoyageBunkerConsumptionTab(event: Command) {
        this.voyageBunkerConsumptionTabUpdated.emit(event);
    }

    private createForm() {
        this.sccForm = new UntypedFormGroup(
            {
                ownerEmail: new UntypedFormControl(this.ownerEmail, [Validators.email])
            },
            { updateOn: "blur" }
        );
    }

    private setForm() {
        const dataModel = {
            ownerEmail: this.ownerEmail
        };

        this.removeFormSubscriptions();
        this.sccForm.patchValue(dataModel);
        this.subscribeToFormValueChanges(this.sccForm, (data) => this.updateCpSpeedAndConsumptionTab(new UpdateOwnerEmailCommand(data.ownerEmail)));
    }
}
