import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import * as FileSaver from "file-saver";
import { DateTime } from "luxon";
import { of } from "rxjs";
import { catchError, exhaustMap, switchMap, tap, withLatestFrom } from "rxjs/operators";

import { LaytimeCalculationHttpService } from "../../../services/laytime-calculation-http.service";
import { LtcFeatureState, LtcState } from "../../model/state";
import { currentCalculationStateReducer } from "../reducer";
import { selectCurrentCalculationId, selectCurrentFixture, selectCurrentLaytimeCalculationName } from "../selectors";
import { selectCurrentUser } from "../../../../state";

export type ExportFormat = "xlsx" | "pdf";

/* ACTIONS */
export const exportLaytimeCalculationAction = createAction("[LTC] Export Calculation", props<{ format: ExportFormat }>());
export const exportLaytimeCalculationSuccessAction = createAction("[LTC] Export Calculation Success", props<{ data: Blob; format: ExportFormat }>());
export const exportLaytimeCalculationFailAction = createAction("[LTC] Export Calculation Fail", props<{ ltcId: string; error: unknown }>());

/* REDUCERS */
export const exportLaytimeCalculationReducer: On<LtcState> = on(exportLaytimeCalculationAction, (state) => currentCalculationStateReducer(state, { exportStatus: "exporting" }));

export const exportLaytimeCalculationSuccessReducer: On<LtcState> = on(exportLaytimeCalculationSuccessAction, (state) =>
    currentCalculationStateReducer(state, { exportStatus: null })
);

export const exportLaytimeCalculationFailReducer: On<LtcState> = on(exportLaytimeCalculationFailAction, (state) =>
    currentCalculationStateReducer(state, { exportStatus: "failed" })
);

/* EFFECTS */
export const exportLaytimeCalculationEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(exportLaytimeCalculationAction),
                withLatestFrom(store.select(selectCurrentCalculationId)),
                exhaustMap(([actionProps, ltcId]) =>
                    laytimeCalculationHttpService.exportCalculation(ltcId, actionProps.format).pipe(
                        switchMap((data) => [exportLaytimeCalculationSuccessAction({ data, format: actionProps.format })]),
                        catchError((error) => of(exportLaytimeCalculationFailAction({ ltcId, error })))
                    )
                )
            ),
        { dispatch: true }
    );

export const exportLaytimeCalculationSuccessEffect$ = (actions$: Actions, store: Store<LtcFeatureState>) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(exportLaytimeCalculationSuccessAction),
                withLatestFrom(store.select(selectCurrentLaytimeCalculationName), store.select(selectCurrentFixture), store.select(selectCurrentUser)),
                tap(([data, calculationName, fixture, user]) => {
                    const fileType = data.format === "xlsx" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "application/pdf";
                    const blob = new Blob([data.data], { type: fileType });
                    const date = DateTime.utc().setZone(user.timeZone || "utc").toFormat("dd-MM-yyyy");
                    const filename = `Laytime Calculation Report - ${fixture.vesselName} - ${calculationName} - ${date}.${data.format}`;
                    FileSaver.saveAs(blob, filename);
                })
            ),
        { dispatch: false }
    );
