export enum Key {
    Tab = "Tab",
    Enter = "Enter",
    Escape = "Escape",
    Backspace = "Backspace",
    Delete = "Delete",
    Shift = "Shift",
    Space = "Space",
    PageUp = "PageUp",
    PageDown = "PageDown",
    End = "End",
    Home = "Home",
    ArrowLeft = "ArrowLeft",
    ArrowUp = "ArrowUp",
    ArrowRight = "ArrowRight",
    ArrowDown = "ArrowDown"
}
