import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import { WarningHttpService } from "../../../services/warning-http.service";
import { FixtureId, FixturesState } from "../../../state";
import { fixtureStateReducer } from "../../../state/fixture/reducer";

/* ACTIONS */
const LOAD_ACTION_NAME = "[Warnings] Load Dismissed Warnings";

export const loadDismissedWarningsAction = createAction(LOAD_ACTION_NAME, props<{ fixtureId: FixtureId }>());
export const loadDismissedWarningsSuccessAction = createAction(`${LOAD_ACTION_NAME} Success`, props<{ fixtureId: FixtureId; warningHashes: string[] }>());
export const loadDismissedWarningsFailAction = createAction(`${LOAD_ACTION_NAME} Fail`, props<{ fixtureId: FixtureId; error: Error }>());

/* REDUCERS */
export const loadDismissedWarningsSuccessReducer: On<FixturesState> = on(loadDismissedWarningsSuccessAction, (state, { fixtureId, warningHashes }) =>
    fixtureStateReducer(state, fixtureId, (fixtureState) => ({
        ...fixtureState,
        dismissedWarnings: warningHashes.map((warningHash) => ({ warningHash }))
    }))
);

/* EFFECTS */
export const loadDismissedWarningsEffect$ = (actions$: Actions, warningHttpService: WarningHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(loadDismissedWarningsAction),
            mergeMap(({ fixtureId }) =>
                warningHttpService.getWarningHashes(fixtureId).pipe(
                    map((warningHashes) => loadDismissedWarningsSuccessAction({ fixtureId, warningHashes })),
                    catchError((err) => of(loadDismissedWarningsFailAction({ fixtureId, error: err })))
                )
            )
        )
    );
