import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subject } from "rxjs";
import { distinctUntilChanged, map, takeUntil } from "rxjs/operators";

import { FixtureDataService } from "../../../fixture/services/fixture-data.service";
import { FixtureType } from "../models";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[opsFixtureType]"
})
export class FixtureTypeDirective implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();
    private fixtureType: FixtureType;

    @Input() set opsFixtureType(value: "Voyage" | "TimeCharter") {
        switch (value) {
            case "Voyage":
                this.fixtureType = FixtureType.Voyage;
                break;
            case "TimeCharter":
                this.fixtureType = FixtureType.TimeCharter;
                break;
            default:
                throw Error(`FixtureTypeDirective: Invalid fixture type '${value}'`);
        }
    }

    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private changeDetectorRef: ChangeDetectorRef,
        private fixtureDataService: FixtureDataService
    ) {}

    ngOnInit() {
        if (!this.fixtureType) {
            throw new Error("FixtureTypeDirective: Fixture Type is required");
        }

        this.fixtureDataService.currentFixture$
            .pipe(
                map((fixture) => fixture.fixtureType?.id === this.fixtureType),
                distinctUntilChanged(),
                takeUntil(this.destroy$)
            )
            .subscribe((match) => {
                if (match) {
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainerRef.clear();
                }

                this.changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
