import { Duration } from "luxon";

import { LaytimeCalculationDurationUnit, LaytimeCalculationRounding } from "../../state";

export const ceilDuration = (duration: Duration, durationUnit: LaytimeCalculationDurationUnit): Duration => {
    switch (durationUnit) {
        case "Days":
            return Duration.fromObject({ days: Math.ceil(duration.as("days")) });
        case "Hours":
            return Duration.fromObject({ hours: Math.ceil(duration.as("hours")) });
        default:
            throw Error(`Unknown duration unit '${durationUnit}'`);
    }
};

export const floorDuration = (duration: Duration, durationUnit: LaytimeCalculationDurationUnit): Duration => {
    switch (durationUnit) {
        case "Days":
            return Duration.fromObject({ days: Math.floor(duration.as("days")) });
        case "Hours":
            return Duration.fromObject({ hours: Math.floor(duration.as("hours")) });
        default:
            throw Error(`Unknown duration unit '${durationUnit}'`);
    }
};

export const roundDuration = (duration: Duration, durationUnit: LaytimeCalculationDurationUnit, rounding: LaytimeCalculationRounding): Duration => {
    switch (rounding) {
        case "Exact":
            return duration;
        case "Up":
            return ceilDuration(duration, durationUnit);
        case "Down":
            return floorDuration(duration, durationUnit);
        default:
            throw Error(`Unknown duration unit '${durationUnit}'`);
    }
};
