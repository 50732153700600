import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaustMap, map } from "rxjs/operators";

import { WorksheetHttpService } from "../../../left-bar/worksheets/service/worksheet-http.service";
import { WorksheetId } from "../../model";
import { removeFromObjectMap } from "../../utils";
import { worksheetStateReducer } from "../reducer";
import { getWorksheetState, WorksheetsState } from "../state";

/* ACTIONS */
const DELETE_WORKSHEET_NAME = "[Worksheets] Delete";

export const deleteWorksheetAction = createAction(DELETE_WORKSHEET_NAME, props<{ worksheetId: WorksheetId }>());
export const deleteWorksheetSuccessAction = createAction(`${DELETE_WORKSHEET_NAME} Success`, props<{ worksheetId: WorksheetId }>());
export const deleteWorksheetFailAction = createAction(`${DELETE_WORKSHEET_NAME} Fail`, props<{ error: Error; worksheetId: WorksheetId }>());

/* REDUCERS */
export const deleteWorksheetReducer: On<WorksheetsState> = on(deleteWorksheetAction, (state, { worksheetId }) => {
    if (!getWorksheetState(state, worksheetId)) {
        return state;
    }

    return worksheetStateReducer(state, worksheetId, (worksheetState) => ({
        ...worksheetState,
        saveStatus: "persisting"
    }));
});

export const deleteWorksheetSuccessReducer: On<WorksheetsState> = on(deleteWorksheetSuccessAction, (state, { worksheetId }) => {
    if (!getWorksheetState(state, worksheetId)) {
        return state;
    }

    const currentWorksheet = state.selectedWorksheetId === worksheetId ? { ...state.defaultWorksheet } : state.currentWorksheet;
    const selectedWorksheetId = state.selectedWorksheetId === worksheetId ? null : state.selectedWorksheetId;

    return {
        ...state,
        worksheets: removeFromObjectMap(state.worksheets, worksheetId),
        currentWorksheet,
        selectedWorksheetId
    };
});

export const deleteWorksheetFailReducer: On<WorksheetsState> = on(deleteWorksheetFailAction, (state, { error, worksheetId }) => {
    if (!getWorksheetState(state, worksheetId)) {
        return state;
    }

    return worksheetStateReducer(state, worksheetId, (worksheetState) => ({
        ...worksheetState,
        saveStatus: "failed",
        error: { error, message: "Unable to delete worksheet" }
    }));
});

/* EFFECTS */
export const deleteWorksheetEffect$ = (actions$: Actions, worksheetHttpService: WorksheetHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(deleteWorksheetAction),
            exhaustMap(({ worksheetId }) =>
                worksheetHttpService.delete(worksheetId).pipe(
                    map(() => deleteWorksheetSuccessAction({ worksheetId })),
                    catchError((error) => of(deleteWorksheetFailAction({ error, worksheetId })))
                )
            )
        )
    );
