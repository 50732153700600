import { Pipe, PipeTransform } from "@angular/core";
import { ActionFormModel } from "src/app/action";

@Pipe({ name: "actionStatus" })
export class ActionStatusPipe implements PipeTransform {
    transform(actions: ActionFormModel[], isOverdue: boolean, isPending: boolean, isCompleted: boolean) {
        const filteredActions: ActionFormModel[] = [];

        for (const action of actions) {
            if (action.isOverdue && !isOverdue) {
                continue;
            }

            if (action.isPending && !isPending) {
                continue;
            }

            if (action.isComplete && !isCompleted) {
                continue;
            }

            filteredActions.push(action);
        }

        return filteredActions;
    }
}
