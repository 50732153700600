import { anyPass, assoc, isNil, propEq } from "ramda";
import { v4 as uuid } from "uuid";

import { Currency, Sector } from "@ops/shared";

import { DateRange } from "../../../../fixture/shared/models";
import { FixtureId } from "../../../../fixture/state";
import { CoaId, CoaSource } from "../coa";
import { Company } from "../company";
import { User } from "../user";
import { LiftingCargo } from "./cargo";
import { VesselNomination } from "./vessel-nomination";

export type LiftingId = string;
export const createLiftingId = (): LiftingId => uuid();

export type LiftingControlledProperty = keyof Lifting;

export const liftingStatuses = ["Planning", "Fixed", "Cancelled", "Sending for Approval", "Pending Approval", "Sending for Approval Failed"] as const;
export type LiftingStatus = typeof liftingStatuses[number];

const setLiftingStatus = assoc("liftingStatus");
export const setCancelled = setLiftingStatus("Cancelled");
export const setSendingForApproval = setLiftingStatus("Sending for Approval");

const isLiftingStatus = propEq("liftingStatus");
export const isFixed = isLiftingStatus("Fixed");
export const isPlanning = isLiftingStatus("Planning");
export const isCancelled = isLiftingStatus("Cancelled");
export const isSendingForApproval = isLiftingStatus("Sending for Approval");
export const isPendingApproval = isLiftingStatus("Pending Approval");
export const isSendingForApprovalFailed = isLiftingStatus("Sending for Approval Failed");

export const isReadonlyLifting = anyPass([isNil, isFixed, isCancelled, isSendingForApproval, isPendingApproval]);

export const liftingCargoPlanStatuses = ["Tentative", "Firmed"] as const;
export type LiftingCargoPlanStatus = typeof liftingCargoPlanStatuses[number];

export const liftingVesselPlanStatuses = ["Tentative", "Firmed", "Renomination Pending"] as const;
export type LiftingVesselPlanStatus = typeof liftingVesselPlanStatuses[number];

const isInVesselPlanStatus = propEq("vesselPlanStatus");
export const isFirmedVesselPlanStatus = isInVesselPlanStatus("Firmed");
export const isTentativeVesselPlanStatus = isInVesselPlanStatus("Tentative");

const isInCargoPlanStatus = propEq("cargoPlanStatus");
export const isFirmedCargoPlanStatus = isInCargoPlanStatus("Firmed");
export const isTentativeCargoPlanStatus = isInCargoPlanStatus("Tentative");

export type Lifting = Readonly<{
    liftingId: LiftingId;
    coaId: CoaId;
    sector: Sector;
    tenantId: number;
    contractSource: CoaSource;
    reference: string;
    liftingNumber: string;
    lastUpdatedAt: string;
    lastUpdatedBy: User;
    lastEventNumber: number;
    controlledProperties: ReadonlyArray<LiftingControlledProperty>;
    declarationDate: string;
    liftingStatus: LiftingStatus;
    currency: Currency;
    brokers: ReadonlyArray<User>;
    operators: ReadonlyArray<User>;
    contacts: ReadonlyArray<Company>;
    cargoLaycan: DateRange;
    cargoPlanStatus: LiftingCargoPlanStatus;
    vesselPlanStatus: LiftingVesselPlanStatus;
    cargoes: ReadonlyArray<LiftingCargo>;
    vessels: ReadonlyArray<VesselNomination>;
    summaryComments: string;
    areSummaryCommentsImportant: boolean;
    sendForApprovalFailureMessage?: string;
    /**
     * The unique identifier of the fixture. Applicable when the lifting is fixed.
     */
    fixtureId?: FixtureId;
    /**
     * The customer reference of the fixture. Applicable when the lifting is fixed.
     */
    fixtureReference?: string;
}>;
