import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, map, tap, withLatestFrom } from "rxjs/operators";

import { LaytimeCalculationHttpService } from "../../../../services";
import { ActivityLocation, ActivityLocationId, LaytimeCalculationCargoTerms, LaytimeCalculationState, LtcFeatureState, LtcId, LtcState } from "../../../model";
import { calculationStateReducer } from "../../reducer";
import { navigateToLaytimeCalculation } from "../../router";
import { selectCurrentCalculationId } from "../../selectors";
import { UpdateQueue } from "../../update-queue";

/* ACTIONS */
const REMOVE_ACTION_NAME = "[Laytime Calculation] Remove Activity Location";
export const removeActivityLocationConfirmationAction = createAction(`${REMOVE_ACTION_NAME} User Confirmation`, props<{ activityLocation: ActivityLocation }>());
export const removeActivityLocationAction = createAction(REMOVE_ACTION_NAME, props<{ ltcId: LtcId; activityLocation: ActivityLocation }>());
export const removeActivityLocationSuccessAction = createAction(`${REMOVE_ACTION_NAME} Success`, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId }>());
export const removeActivityLocationFailAction = createAction(`${REMOVE_ACTION_NAME} Fail`, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; error: Error }>());

/* REDUCERS */
export const removeActivityLocationReducer: On<LtcState> = on(removeActivityLocationAction, (state, { ltcId, activityLocation }) => {
    const updateFns: Evolver<LaytimeCalculationState> = {
        calculation: {
            activityLocations: R.filter<ActivityLocation, "array">((al) => al.id !== activityLocation.id),
            cargoTerms: R.pipe(
                R.map(
                    R.when(
                        (ct: LaytimeCalculationCargoTerms) => activityLocation.cargoes.some((c) => c.cargoId === ct.cargoId),
                        R.over(
                            R.lensProp(activityLocation.activity === "Load" ? "loadLocations" : "dischargeLocations"),
                            R.filter(R.propSatisfies(R.complement(R.equals(activityLocation.locationId)), "locationId"))
                        )
                    )
                ),
                R.filter((ct: LaytimeCalculationCargoTerms) => ct.loadLocations.length > 0 || ct.dischargeLocations.length > 0)
            )
        },
        currentActivityLocationId: () => null,
        activityLocationForm: () => null,
        activityCargoForms: () => null,
        cargoTermsForm: () => null
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

/* EFFECTS */
export const removeActivityLocationEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeActivityLocationConfirmationAction),
            withLatestFrom(store.select(selectCurrentCalculationId)),
            map(([{ activityLocation }, ltcId]) => removeActivityLocationAction({ ltcId, activityLocation })),
            tap((action) => updateQueue.enqueue(action, removeActivityLocation, (latest) => (action.activityLocation === latest.activityLocation ? action : [latest, action])))
        )
    );

export const navigateOnActivityLocationRemovalSuccessEffect$ = (actions$: Actions, router: Router) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(removeActivityLocationSuccessAction),
                tap(({ ltcId, activityLocationId }) => {
                    if (router.url === `/ltc/${ltcId}/locations/${activityLocationId}`) {
                        navigateToLaytimeCalculation(router, ltcId);
                    }
                })
            ),
        { dispatch: false }
    );

export const removeActivityLocation = (
    { ltcId, activityLocation }: ReturnType<typeof removeActivityLocationAction>,
    laytimeCalculationHttpService: LaytimeCalculationHttpService
) =>
    laytimeCalculationHttpService.removeActivityLocation(ltcId, activityLocation.id).pipe(
        map(() => removeActivityLocationSuccessAction({ ltcId, activityLocationId: activityLocation.id })),
        catchError((error) => of(removeActivityLocationFailAction({ ltcId, activityLocationId: activityLocation.id, error })))
    );
