import { LaycanExtensionReason } from "src/app/shared/reference-data";
export class Laycan {
    constructor(from: string, to: string, public extensionDate?: { from?: string; to?: string }, public extensionReason?: LaycanExtensionReason) {
        this.date = { from: from, to: to };
    }

    date: {
        from: string;
        to: string;
    };
}
