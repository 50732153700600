import { Action, createReducer } from "@ngrx/store";

import { emptyObjectMap, onObjectMapForms, wrapReducerWithObjectMapFormStatesUpdate } from "@ops/state";

import activityReducers from "./activities/reducers";
import associatedCargoReducers from "./associated-cargoes/reducers";
import berthReducers from "./berths/reducers";
import { updateBunkerConsumptionsCargoStatusesReducer } from "./bunker-consumption/update-cargo-statuses";
import cargoReducers from "./cargoes/reducers";
import destinationReducers from "./destinations/reducers";
import { interopFixtureLoadSuccessReducer, interopFixturePublishSuccessReducer, resetActualFreightRateReducer } from "./fixture/interop";
import { loadVoyagesFailReducer, loadVoyagesReducer, loadVoyagesSuccessReducer } from "./fixture/load-voyages";
import { routerSetCurrentFixtureReducer } from "./fixture/routing";
import { savedFixtureReducer, saveFixtureReducer } from "./fixture/save-fixture";
import laytimeEventReducers from "./laytime-events/reducers";
import { FixturesState, FixtureState, VoyageState } from "./model";
import { collapsePublicNotesReducer, expandPublicNotesReducer } from "./public-notes";
import totalReducers from "./totals/reducers";
import { updateValidationTabReducer } from "./validation";
import { resetValidationReducer } from "./validation/reset-validation-tabs";
import { validateVoyageForm } from "./voyage/form/validation";
import { formToVoyageReducer } from "./voyage/interop";
import voyageReducers from "./voyage/reducers";
import warningsReducers from "./warnings/reducers";

export const initialFixturesState: FixturesState = {
    fixtures: emptyObjectMap<FixtureState>(),
    voyages: emptyObjectMap<VoyageState>()
};

const rawReducer = createReducer(
    initialFixturesState,
    routerSetCurrentFixtureReducer,

    saveFixtureReducer,
    savedFixtureReducer,
    interopFixtureLoadSuccessReducer,
    resetActualFreightRateReducer,
    interopFixturePublishSuccessReducer,
    loadVoyagesReducer,
    loadVoyagesSuccessReducer,
    loadVoyagesFailReducer,
    resetValidationReducer,

    expandPublicNotesReducer,
    collapsePublicNotesReducer,
    updateValidationTabReducer,

    ...voyageReducers,
    ...destinationReducers,
    ...berthReducers,
    ...cargoReducers,
    ...totalReducers,
    ...activityReducers,
    ...associatedCargoReducers,
    ...laytimeEventReducers,
    ...warningsReducers,

    updateBunkerConsumptionsCargoStatusesReducer,

    onObjectMapForms((s: FixturesState) => s.fixtures),
    onObjectMapForms((s: FixturesState) => s.voyages)
);

// Required for AOT compile
export const reducer = (state: FixturesState | undefined, action: Action) => {
    const validateVoyage = {
        form: validateVoyageForm
    };

    let reducedState = rawReducer(state, action);
    reducedState = wrapReducerWithObjectMapFormStatesUpdate((s: FixturesState) => s.voyages, validateVoyage)(reducedState);

    return formToVoyageReducer(state as FixturesState, reducedState);
};
