import { On, on } from "@ngrx/store";
import * as R from "ramda";

import { saveLaycanSuccessAction } from "./form/edit-laycan";
import { removeCargoSuccessAction } from "./form/remove-cargo";
import { saveCargoSuccessAction, saveNewCargoSuccessAction } from "./form/save-cargo";
import { coaLiftingsStateReducer } from "../../coa/reducer";
import { CoasState, CoaState, LiftingCargoPlanStatus, validateLiftingCargoes } from "../../model";
import { liftingStateReducer } from "../reducer";

/* REDUCERS */
export const updateCargoPlanStatusReducer: On<CoasState> = on(
    saveLaycanSuccessAction,
    saveCargoSuccessAction,
    saveNewCargoSuccessAction,
    removeCargoSuccessAction,
    (state, { coaId, liftingId }) => {
        let cargoPlanStatus: LiftingCargoPlanStatus;
        const withUpdatedLiftingState = liftingStateReducer(state, liftingId, (liftingState) => {
            cargoPlanStatus = validateLiftingCargoes(liftingState.lifting).isInvalid ? "Tentative" : liftingState.lifting.cargoPlanStatus;
            return R.assocPath(["lifting", "cargoPlanStatus"], cargoPlanStatus, liftingState);
        });

        const getLiftings = (coaState: CoaState) => coaState.fetchedLiftings.map((l) => (l.documentId !== liftingId ? l : { ...l, cargoPlanStatus }));

        return coaLiftingsStateReducer(withUpdatedLiftingState, coaId, getLiftings);
    }
);
