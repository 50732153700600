import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction } from "@ngrx/store";
import { tap } from "rxjs/operators";

import { LaytimeCalculationEnterDeductionsComponent } from "../../../components/deductions/laytime-calculation-enter-deductions.component";

const ENTER_DEDUCTIONS_ACTION_NAME = "[Laytime Calculation List] Enter Deductions";
export const enterDeductionsAction = createAction(ENTER_DEDUCTIONS_ACTION_NAME);

/* EFFECTS */
export const enterDeductionsEffect$ = (actions$: Actions, dialog: MatDialog) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(enterDeductionsAction),
                tap(() => {
                    dialog.open(LaytimeCalculationEnterDeductionsComponent);
                })
            ),
        { dispatch: false }
    );
