import { Action, createReducer } from "@ngrx/store";
import { interopUserAuthenticatedReducer } from "./auth/interop";
import { AuthState } from "./model";

export const initialAuthState: AuthState = {
    isAuthenticated: false
};

const reducer = createReducer(
    initialAuthState,
    interopUserAuthenticatedReducer
);

// Required for AOT compile
export function authReducer(state: AuthState | undefined, action: Action) {
    return reducer(state, action);
}
