<ng-container *ngIf="(expenses$ | async) as expenses; else loading">
    <div *ngIf="expenses?.length > 0; else noData">
        <div *ngFor="let expenseForm of expensesForm.controls; let i = index" class="row expense-row">
            <div class="col">
                <ops-expense [expenseForm]="expenseForm" [expense]="expenses[i]" [destinations]="destinations" (expenseRemoved)="removeExpense(i)"></ops-expense>
            </div>
        </div>
        <div class="guttering"></div>
        <div class="row">
            <div class="col">
                <ops-action-button
                    class="float-right"
                    type="primary"
                    icon="add-circle-outline"
                    [disabled]="expensesForm.disabled"
                    (click)="addExpense()">
                    EXPENSE
                </ops-action-button>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loading>
    <ops-load-animation size="x-large" stripeColor="dark" class="loading-animation"></ops-load-animation>
</ng-template>

<ng-template #noData>
    <ops-no-data-panel
        heading="Expenses"
        title="No expenses have been added"
        description="To add an expense, click the button"
        [useNewButton]="true"
        newButtonText="EXPENSE"
        [disabled]="expensesForm.disabled"
        (addNew)="addExpense()">
    </ops-no-data-panel>
</ng-template>
