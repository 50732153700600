import { Injectable } from "@angular/core";

import { UpdateFixturePopupFixtureInfoCommand } from "./update-fixture-popup-fixture-info-command";
import { CommandHandler } from "../../mediator/command-handlers/command-handler";
import { Fixture } from "../../shared/models";

@Injectable({
    providedIn: "root"
})
export class UpdateFixturePopupFixtureInfoCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateFixturePopupFixtureInfoCommand) {
        fixture.operators = command.model.operators;
        [fixture.leadOperator] = fixture.operators;
        fixture.voyageReference = command.model.voyageReference;
        fixture.liftingNumber = command.model.liftingNumber;
    }
}
