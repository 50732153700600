<div *ngIf="bunkersArray.length" [formGroup]="parentForm" class="card embedded-card">
    <div class="card-header pl-0 row no-gutters align-items-center">
        <button
            type="button"
            (click)="hideBunkers = !hideBunkers"
            class="has-icon btn-inverted"
            [ngClass]="{ 'icon--arrow-drop-right': hideBunkers, 'icon--arrow-drop-down': !hideBunkers }"
            data-test-hide-bunker-on-delivery
        ></button>
        <span
            >{{ title }} <span class="bunker-count">({{ bunkers?.length }})</span></span
        >
        <span class="ml-auto total-quantity">Total Quantity: {{ totalQuantity | number: 0 }}</span>
    </div>
    <div class="card-body p-0" [hidden]="hideBunkers">
        <table class="table table-striped m-0">
            <thead>
                <tr>
                    <th scope="col" class="py-0 bunker-type align-middle">Type</th>
                    <th scope="col" class="py-0 bunker-quantity align-middle text-right">Quantity</th>
                    <th scope="col" class="py-0 bunker-price align-middle text-right">Price</th>
                    <th scope="col" class="p-0 bunker-action"></th>
                </tr>
            </thead>
            <tbody formArrayName="bunkers">
                <tr *ngFor="let bunker of bunkersArray; let i = index" [formGroupName]="i" class="form-group" data-test-bunker-row>
                    <td class="p-0">
                        <ng-select
                            class="ops-select"
                            [items]="allBunkerTypes"
                            formControlName="bunkerType"
                            [clearable]="false"
                            bindLabel="name"
                            [compareWith]="referenceDataService.compare"
                            data-test-bunker-type
                            [selectOnTab]="true"
                            #bunkerTypeElement
                        >
                        </ng-select>
                    </td>
                    <td class="p-0 text-right">
                        <input
                            type="text"
                            class="form-control input-sm text-right"
                            [opsNumber]="{ precision: 3, showTrailingZeros: false }"
                            formControlName="quantity"
                            [ngbPopover]="quantity"
                            #q="ngbPopover"
                            (blur)="q.close()"
                            (focus)="q.open()"
                            triggers="manual"
                            placement="bottom-left"
                            [autoClose]="false"
                            data-test-quantity
                        />
                        <ng-template #quantity>
                            <div *ngIf="!bunker.controls.quantity.valid && bunker.get('quantity').touched" class="row no-gutters display">
                                <span class="has-icon icon--warning col-1"></span>
                                <span class="content col-11" data-test-quantity-validation
                                    >Please ensure that the <b>Quantity</b> value is a positive number.</span
                                >
                            </div>
                        </ng-template>
                    </td>
                    <td class="p-0 text-right">
                        <input
                            type="text"
                            class="form-control input-sm text-right"
                            [opsNumber]="{ precision: 3 }"
                            formControlName="price"
                            [ngbPopover]="price"
                            #p="ngbPopover"
                            (blur)="p.close()"
                            (focus)="p.open()"
                            triggers="manual"
                            placement="bottom-left"
                            [autoClose]="false"
                            data-test-price
                        />
                        <ng-template #price>
                            <div *ngIf="!bunker.controls.price.valid && bunker.get('price').touched" class="row no-gutters display">
                                <span class="has-icon icon--warning col-1"></span>
                                <span class="content col-11" data-test-price-validation>Please ensure that the <b>Price</b> value is a positive number.</span>
                            </div>
                        </ng-template>
                    </td>
                    <td class="p-0 ops-table-actions">
                        <button
                            type="button"
                            *ngIf="bunkersArray.length === i + 1"
                            class="has-icon icon--add-circle-outline btn-add"
                            [disabled]="bunkersForm.disabled"
                            (click)="addNew()"
                            data-test-add-bunker
                        ></button>
                        <button
                            type="button"
                            [disabled]="bunkersForm.disabled"
                            (click)="deleteBunker(i)"
                            class="has-icon icon--delete btn-delete float-right"
                            data-test-delete
                        ></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ops-no-data-panel
    *ngIf="!bunkersArray.length"
    heading="Bunkers"
    title="No bunkers have been added"
    description="To add a bunker, click the button."
    [useNewButton]="true"
    newButtonText="BUNKER"
    [disabled]="bunkersForm.disabled"
    (addNew)="addNew()"
>
</ops-no-data-panel>
