import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { hasValue, Sector } from "@ops/shared";

import {
    ActivityType,
    allowanceUnits,
    reversibleLaytimeTypes,
    ActivityCargoId,
    ActivityLocationCargoState,
    ActivityCargoForm,
    LaytimeCalculationDurationUnit
} from "../../../state";

@Component({
    selector: "ops-laytime-calculation-activity-location-cargoes",
    templateUrl: "./laytime-calculation-activity-cargoes.component.html",
    styleUrls: ["./laytime-calculation-activity-cargoes.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationActivityCargoesComponent {
    readonly allowanceUnits = allowanceUnits;
    readonly reversibleLaytimeTypes = reversibleLaytimeTypes;
    readonly hasValue = hasValue;

    @Input() cargoes: ReadonlyArray<ActivityLocationCargoState>;
    @Input() activityType: ActivityType;
    @Input() sector: Sector;
    @Input() durationUnit: LaytimeCalculationDurationUnit;
    @Input() isFixed: boolean;

    @Output() readonly add = new EventEmitter();
    @Output() readonly remove = new EventEmitter<ActivityCargoId>();
    @Output() readonly importTerms = new EventEmitter<ActivityCargoId>();

    get gridClasses() {
        return `ops-form-grid ops-ltc-form-grid ${this.isFixed ? "fixed-activity-location-cargoes" : "activity-location-cargoes"}`;
    }

    get activityTypeDisplayName() {
        switch (this.activityType) {
            case "Load":
            case "Discharge":
                return `${this.activityType} `;
            default:
                return null;
        }
    }

    trackBy(cargoForm: ActivityCargoForm) {
        return cargoForm.id;
    }

    getHoursText(extraHours: number) {
        return Number(extraHours) === 1 ? "Hour" : "Hours";
    }
}
