import { formatNumber } from "@angular/common";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { DateTime } from "luxon";
import * as R from "ramda";
import { combineLatest, Observable } from "rxjs";
import { filter, map, withLatestFrom } from "rxjs/operators";

import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { VoyageDataService } from "../../fixture/services/voyage-data.service";
import { Berth, Destination, Fixture, Voyage } from "../../fixture/shared/models";
import { FixtureWarning, FixtureWarningState } from "../../fixture/shared/warnings/fixture-warning.model";
import { FixtureWarningService } from "../../fixture/shared/warnings/fixture-warning.service";
import { CargoTotalState, FixtureFeatureState, selectCurrentVoyageTotalsForm } from "../../fixture/state";
import { dateTimeFromISO, Nullable } from "../../shared";
import { CargoBerthActivityType } from "../../shared/reference-data";
import { formatDateTime, mapArrayToCommaSeparatedString } from "../mappers/formatters";
import { mapNextPortInfo } from "../mappers/laytime-events.mapper";
import { FixtureRow } from "../models/fixture-row.model";

@Injectable({
    providedIn: "root"
})
export class FixtureRowUpdatesService {
    constructor(
        private fixtureDataService: FixtureDataService,
        private voyageDataService: VoyageDataService,
        private fixtureWarningService: FixtureWarningService,
        private store: Store<FixtureFeatureState>
    ) {}

    get fixtureRowUpdates$(): Observable<Partial<FixtureRow>> {
        return combineLatest([this.fixtureDataService.currentFixture$, this.voyageDataService.current$]).pipe(
            filter(([fixture, voyage]) => !!fixture && !!voyage),
            withLatestFrom(this.fixtureWarningService.warningStates$, this.store.select(selectCurrentVoyageTotalsForm)),
            map(([[fixture, voyage], warningStates, totals]) => this.getFixtureRowUpdates(fixture, voyage, warningStates, totals))
        );
    }

    private getFixtureRowUpdates(fixture: Fixture, voyage: Voyage, warningStates: FixtureWarningState[], totalsForm: CargoTotalState[]) {
        const nextPortInfo = mapNextPortInfo(fixture.fixtureType.name, fixture.fixtureStatus.name, fixture.division.name, fixture.laycan?.date?.to, voyage.destinations);
        const warnings = this.getWarnings(warningStates);
        return {
            operator: mapArrayToCommaSeparatedString(fixture.operators, (item) => item.fullName),
            operatorCode: mapArrayToCommaSeparatedString(fixture.operators, (item) => item.userCode),
            voyageReference: fixture.voyageReference,
            liftingNumber: fixture.liftingNumber,
            comments: fixture.comments,
            nextPort: nextPortInfo?.name,
            isNextPortMissing: !!nextPortInfo?.isNextPortMissing,
            nextPortOperation: nextPortInfo?.operation,
            nextPortETA: nextPortInfo?.eta,
            isNextPortEtaHighlighted: !!nextPortInfo?.isEtaHighlighted,
            lastLaytimeEvent: nextPortInfo?.lastLaytimeEvent,
            totalBlQuantity: this.calculateTotalBlQuantity(voyage),
            blDate: this.calculateBlDate(voyage),
            totalFreight: this.calculateTotalFreight(totalsForm),
            hasWarnings: warnings.length > 0,
            warningType: this.getWarningsBreakdown(warnings),
            warningMessages: this.getWarningMessages(warnings)
        };
    }

    private getWarnings(warningStates: FixtureWarningState[]) {
        const warnings = warningStates?.filter((ws) => !ws.isDismissed).map((ws) => ws.warning) ?? [];
        return R.sortBy(R.prop("category"), warnings);
    }

    private getWarningsBreakdown(warnings: FixtureWarning[]) {
        return R.pipe(
            R.groupBy((warning: FixtureWarning) => warning.category),
            R.mapObjIndexed(R.length),
            R.toPairs,
            R.map(([category, count]) => `${this.getCategoryTitle(category)} (${count})`),
            R.join(", ")
        )(warnings);
    }

    private getWarningMessages(warnings: FixtureWarning[]) {
        return R.pipe(
            R.map((warning: FixtureWarning) => `${this.getCategoryTitle(warning.category)}: ${warning.message}`),
            R.groupBy((message) => message),
            R.values,
            R.map((messages: string[]) => (messages.length > 1 ? `${messages[0]} (${messages.length})` : messages[0])),
            R.join("\r\n")
        )(warnings);
    }

    private getCategoryTitle(category: string) {
        switch (category) {
            case "claim":
                return "Claim";
            case "data":
                return "Data";
            case "eta":
                return "ETA";
            case "freight":
                return "Freight";
        }
    }

    // calculates B/L Quantity the same way as in indexers function
    private calculateTotalBlQuantity(voyage: Voyage) {
        let result = 0;
        let currentUnit = 0;
        for (const d of voyage.destinations) {
            for (const ac of this.getAssociatedCargoes(d, [CargoBerthActivityType.Load])) {
                if (currentUnit > 0 && ac.quantityUnit && ac.quantityUnit.id !== currentUnit) {
                    return "";
                }
                if (currentUnit === 0 && ac.quantityUnit) {
                    currentUnit = ac.quantityUnit.id;
                }
                result += ac.quantity ?? 0;
            }
        }
        return result ? formatNumber(result, "en", "1.3-6") : "";
    }

    // calculated B/L Date the same way as in indexers function
    private calculateBlDate(voyage: Voyage) {
        let result: Nullable<DateTime>;
        let timeZone: string | undefined;
        for (const d of voyage.destinations) {
            for (const ac of this.getAssociatedCargoes(d, [CargoBerthActivityType.Load, CargoBerthActivityType.InterimLoad])) {
                if (ac.date) {
                    const blDate = dateTimeFromISO(ac.date, "utc");
                    if (!result || (blDate && blDate > result)) {
                        result = blDate;
                        timeZone = d.location?.timeZone;
                    }
                }
            }
        }
        return result ? formatDateTime(result.toISO(), timeZone || "utc") : "";
    }

    private getAssociatedCargoes(destination: Destination, activityTypes: CargoBerthActivityType[]) {
        return R.pipe(
            R.map((b: Berth) => b.cargoBerthActivities.filter((a) => activityTypes.find((at) => at.id === a.type?.id))),
            R.flatten,
            R.map((a) => a.associatedCargoes),
            R.flatten
        )(destination.berths);
    }

    private calculateTotalFreight(totals: CargoTotalState[]) {
        const result = totals.filter((t) => t.total.freightSpend).length ? totals.reduce((a, b) => a + (b.total.freightSpend || 0), 0) : null;
        return result ? formatNumber(result, "en", "1.2-6") : "";
    }
}
