/**
 * Returns a value indicating whether the given argument has a value, being not
 * null, undefined, an empty string or NaN.
 *
 * @param arg The value to check.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNullOrUndefined = (arg: any): arg is null | undefined => typeof arg === "undefined" || arg === null;

/**
 * Returns a value indicating whether the given argument has a value, being not
 * null, undefined, an empty string or NaN.
 *
 * @param arg The value to check.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasValue = <T>(arg: T | any): boolean => !isNullOrUndefined(arg) && (typeof arg === "number" ? !isNaN(arg) : arg !== "");

export const isNumber = <T>(arg: T): boolean => !isNullOrUndefined(arg) && !isNaN(+arg);

export const isWhitespace = (text: string): boolean => /\s/.test(text);

export const hasItems = <T>(arr: Array<T>): boolean => arr && arr.length > 0;

/**
 * Like `Array.isArray` but retains typing of array.
 *
 * @param arg The value to check.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isArray = <T>(arg: any | T[] | readonly T[]): arg is T[] | readonly T[] => Array.isArray(arg);
