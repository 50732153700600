import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Observable } from "rxjs";

import { BusyBoxService } from "./busy-box.service";

@Component({
    selector: "ops-busy-box",
    templateUrl: "./busy-box.component.html",
    styleUrls: ["./busy-box.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusyBoxComponent {
    constructor(private busyBoxService: BusyBoxService) {}

    get isApplicationBusy$(): Observable<boolean> {
        return this.busyBoxService.isApplicationBusy$;
    }
}
