<div class="ops-popup-header" *ngIf="showHeader">
    <ul>
        <li *ngFor="let item of title; let i = index">
            <span>{{ item | truncate: 30:"..." }}</span>
        </li>
    </ul>
</div>
<div class="ops-popup-body">
    <ng-content></ng-content>
</div>
<div class="ops-popup-footer text-right">
    <ng-content select="[footer]"></ng-content>
</div>