import { FormGroupState, unbox, updateArray, updateGroup, validate } from "ngrx-forms";
import { required } from "ngrx-forms/validation";

import { validateRequiredIf, validateRequiredNotEmptyOrWhitespaceIf } from "@ops/state";

import { validateBerthsForm } from "../../berths/form/validation";
import { DestinationForm, destinationHasLaytimeEventDate, FixtureState, VoyageState } from "../../model";

function locationRequired(destination: DestinationForm): boolean {
    const etaRange = unbox(destination.etaRange);

    return (!!etaRange && !!etaRange.to) || destinationHasLaytimeEventDate(destination);
}

export function validateDestinationForm(voyageState: VoyageState, fixtureState: FixtureState) {
    return (form: FormGroupState<DestinationForm>) =>
        updateGroup<DestinationForm>({
            id: validate(required),
            location: validateRequiredIf(locationRequired(form.value)),
            portCostStatus: validateRequiredIf(() => !!form.value.portCosts || form.value.portCosts === 0),
            comments: validateRequiredNotEmptyOrWhitespaceIf(form.value.commentsImportant),
            instructions: validateRequiredNotEmptyOrWhitespaceIf(form.value.instructionsImportant),
            berths: validateBerthsForm(voyageState, fixtureState)
        })(form);
}

export function validateDestinationsForm(voyageState: VoyageState, fixtureState: FixtureState) {
    return updateArray<DestinationForm>(validateDestinationForm(voyageState, fixtureState));
}
