import { createAction, on, On, props } from "@ngrx/store";
import { unbox, updateGroup } from "ngrx-forms";

import { isNullOrUndefined } from "@ops/shared";
import { CargoBerthActivityType } from "@ops/shared/reference-data";
import { opsAddArrayControl } from "@ops/state";

import { Division } from "../../../shared/models";
import { updateActivityForm } from "../../activities/shared";
import {
    ActivityId,
    associatedCargoExpandedKey,
    associatedCargoForm,
    AssociatedCargoForm,
    BerthId,
    CargoId,
    createAssociatedCargoId,
    DestinationId,
    FixturesState,
    getActivitiesWithCargo,
    getDivision,
    VoyageForm
} from "../../model";
import { getNextId } from "../../utils";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const addAssociatedCargoAction = createAction("[Voyage Form] Add Associated Cargo", props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId }>());

/* REDUCERS */
export const addAssociatedCargoReducer: On<FixturesState> = on(addAssociatedCargoAction, (state, action) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        const destination = voyageState.form.controls.destinations.controls.find((d) => d.value.id === action.destinationId);
        const berth = destination && destination.controls.berths.controls.find((b) => b.value.id === action.berthId);
        const activity = berth && berth.controls.activities.controls.find((a) => a.value.activityId === action.activityId);

        if (!activity) {
            return voyageState;
        }

        const division = getDivision(fixtureState);
        const voyage = voyageState.form.value;
        const newAssociatedCargoId = createAssociatedCargoId();
        const newLegacyAssociatedCargoId = getNextId(activity.value.associatedCargoes, "legacyAssociatedCargoId");
        return {
            ...voyageState,
            form: updateActivityForm(action, (activityForm) => {
                const defaultCargo = voyage.cargoes && voyage.cargoes.length === 1 ? voyage.cargoes[0] : null;
                const defaultCargoId = (defaultCargo && defaultCargo.cargoId) || null;
                const defaultQuantityUnit = (defaultCargo && defaultCargo.quantityUnit) || null;

                let freightRate: number;
                if (defaultCargo) {
                    freightRate = defaultCargo.baseFreightRate;
                }

                if (division === Division.gas && activityForm.value.associatedCargoes.length) {
                    freightRate = getAssociatedCargoWithFreightRate(voyage);
                }

                let defaultQuantity: number;
                if (division !== Division.specialisedProducts && unbox(activityForm.value.type)?.id === CargoBerthActivityType.Discharge.id) {
                    defaultQuantity = defaultCargoId ? getDischargeQuantity(voyage, defaultCargoId) : null;
                }

                const newAssociatedCargo = <AssociatedCargoForm>{
                    ...associatedCargoForm(division, newAssociatedCargoId, newLegacyAssociatedCargoId, defaultCargoId),
                    date: null,
                    quantityUnit: defaultQuantityUnit,
                    quantity: defaultQuantity,
                    freightRate
                };

                return updateGroup(activityForm, {
                    associatedCargoes: opsAddArrayControl(newAssociatedCargo, { markAsEditing: true, focusControlName: "cargoId" })
                });
            })(voyageState.form),
            expandedSections: {
                ...voyageState.expandedSections,
                [associatedCargoExpandedKey(action.destinationId, action.berthId, action.activityId, newAssociatedCargoId)]: false
            }
        };
    })
);

const getAssociatedCargoWithFreightRate = (voyage: VoyageForm) => {
    for (const destination of voyage.destinations) {
        for (const berth of destination.berths) {
            for (const activity of berth.activities) {
                for (const associatedCargo of activity.associatedCargoes) {
                    if (!isNullOrUndefined(associatedCargo.freightRate)) {
                        return associatedCargo.freightRate;
                    }
                }
            }
        }
    }
};

const getDischargeQuantity = (voyageForm: VoyageForm, cargoId: CargoId) => {
    const loadActivities = getActivitiesWithCargo(voyageForm, cargoId, CargoBerthActivityType.Load);
    const dischargeActivities = getActivitiesWithCargo(voyageForm, cargoId, CargoBerthActivityType.Discharge);

    // There must only be one load/discharge activity and one associated cargo in Load per cargo and no cargo lines on Discharge to prefill
    if (
        loadActivities.length !== 1 ||
        loadActivities[0].associatedCargoes.filter((c) => c.cargoId === cargoId).length > 1 ||
        (dischargeActivities.length === 1 && dischargeActivities[0].associatedCargoes.filter((c) => c.cargoId === cargoId).length > 0)
    ) {
        return null;
    }

    const loadAssociatedCargoForm = loadActivities[0].associatedCargoes.find((c) => c.cargoId === cargoId);
    return loadAssociatedCargoForm.quantity;
};
