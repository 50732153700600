import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { FixturePopupTab } from "../models/fixture-popup-tab";

@Component({
    selector: "ops-fixture-popup-tab-header",
    templateUrl: "./fixture-popup-tab-header.component.html",
    styleUrls: ["./fixture-popup-tab-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupTabHeaderComponent {
    @Input() tab: FixturePopupTab;
}
