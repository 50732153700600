import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";

import { parseISODate } from "../../../shared/date-utils/date-utilities";
import { Enumeration } from "../../../shared/reference-data/enumeration";
import { ReferenceDataService } from "../../../shared/reference-data/reference-data.service";
import { Destination, Division, ExpenseClaim } from "../../shared/models";
import { Currency } from "../../shared/models/dtos/currency.dto";
import { ExpenseClaimFormModel } from "../../shared/models/form-models/expense.model";
import { ExpenseTabComponent } from "../expense-tab.component";

@Component({
    selector: "ops-expense",
    templateUrl: "./expense.component.html",
    styleUrls: ["./expense.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpenseComponent implements OnInit, OnChanges {
    static componentName = "ExpenseComponent";

    Division = Division;
    commentsMaxLength = ExpenseTabComponent.commentsMaxLength;
    hideExpense: boolean;
    expenseTypes$: Observable<Enumeration[]>;
    currencies$: Observable<Currency[]>;
    lastUpdatedByUser: string;
    lastUpdatedDate: Date;

    @Input() expense: ExpenseClaim;
    @Input() expenseForm: UntypedFormGroup;
    @Input() destinations: Destination[];
    @Output() expenseRemoved = new EventEmitter();
    @ViewChild("scrollToPlaceholder", { static: true }) scrollToPlaceholder: ElementRef;

    constructor(public referenceDataService: ReferenceDataService) {}

    ngOnInit() {
        this.expenseTypes$ = this.referenceDataService.getExpenseTypes();
        this.currencies$ = this.referenceDataService.getCurrencies();
        this.setExpenseValues(this.expense);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.expense || changes.expense.firstChange) {
            return;
        }

        this.setExpenseValues(changes.expense.currentValue);
    }

    toggleExpense(): void {
        this.hideExpense = !this.hideExpense;
    }

    removeExpense(event: MouseEvent): void {
        event.preventDefault();
        this.expenseRemoved.emit();
    }

    private get isNew(): boolean {
        return this.expenseForm.controls.isNew ? <boolean>this.expenseForm.controls.isNew.value : false;
    }

    private setExpenseValues(expense: ExpenseClaim): void {
        if (this.isNew) {
            this.scrollToPlaceholder.nativeElement.scrollIntoView();
        }

        const expenseFormValues = <ExpenseClaimFormModel>{
            expenseClaimId: expense.expenseClaimId,
            type: expense.type,
            ownerInvoiceNumber: expense.ownerInvoiceNumber,
            invoiceDate: parseISODate(expense.invoiceDate),
            finalClaimValue: expense.finalClaimValue,
            currency: expense.currency,
            receivedFromOwnerDate: parseISODate(expense.receivedFromOwnerDate),
            sentToChartererDate: parseISODate(expense.sentToChartererDate),
            paidDate: parseISODate(expense.paidDate),
            sentToAccountsDate: parseISODate(expense.sentToAccountsDate),
            commissionDate: parseISODate(expense.commissionDate),
            chartererAcknowledgedReceiptDate: parseISODate(expense.chartererAcknowledgedReceiptDate),
            agreedDate: parseISODate(expense.agreedDate),
            commissionable: expense.commissionable,
            comments: expense.comments,
            claimTypeDescription: expense.claimTypeDescription,
            initialClaimValue: expense.initialClaimValue,
            grossCommissionableAmount: expense.grossCommissionableAmount,
            lastContacted: parseISODate(expense.lastContacted),
            awaitingHardCopy: expense.awaitingHardCopy,
            awaitingDocuments: expense.awaitingDocuments,
            complete: expense.complete,
            lastUpdatedByUser: expense.lastUpdatedByUser,
            lastUpdatedDate: parseISODate(expense.lastUpdatedDate),
            isNew: false,
            claimHandledBy: expense.claimHandledBy
        };

        this.lastUpdatedByUser = expense.lastUpdatedByUser ? expense.lastUpdatedByUser.fullName : "";
        this.lastUpdatedDate = parseISODate(expense.lastUpdatedDate);
        this.expenseForm.patchValue(expenseFormValues, { emitEvent: false });
    }
}
