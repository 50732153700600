import { Enumeration } from "./enumeration";
import { EnumerationOption, Nullable } from "../models";
import { isNullOrUndefined } from "../utils";

export type FreightTypeOption = EnumerationOption<FreightType, typeof FreightType>;

export class FreightType extends Enumeration {
    static readonly PerCargo = new FreightType(1, "PerCargo", 1);
    static readonly LumpSum = new FreightType(2, "Lump Sum", 2);

    constructor(id: number, name: string, displayOrder: number) {
        super(id, name, displayOrder);
    }

    static is(name: FreightTypeOption, freightType: Nullable<FreightType>) {
        const requiredFreightTypeId = this[name]?.id;

        if (isNullOrUndefined(requiredFreightTypeId)) {
            return false;
        }

        return requiredFreightTypeId === freightType?.id;
    }
}
