<ops-popup [title]="title">
    <div>
        <div>Use the daterange fields to enter all deductions</div>
        <div>Once applied to the calculation, the system will automatically fill in the laytime resumed</div>
        <div class="enter-deductions-grid">
            <ops-laytime-calculation-deductions class="p-d-block p-mt-4" [deductions]="deductions$ | async"
                [durationUnit]="durationUnit$ | async" (add)="addDeduction()" (remove)="removeDeduction($event)"
                (clear)="clearDeductions()">
            </ops-laytime-calculation-deductions>
        </div>
    </div>
    <ng-container footer>
        <ops-action-button (click)="cancel()" data-cy="cancel-deductions">CANCEL</ops-action-button>
        <ops-action-button (click)="applyDeductions()" type="primary" [disabled]="!(deductions$ | async)?.length" data-cy="apply-deductions">
            APPLY DEDUCTIONS
        </ops-action-button>
    </ng-container>
</ops-popup>
