import { createFormGroupState } from "ngrx-forms";

import { LaytimeCalculation, LaytimeCalculationRounding, LaytimeCalculationDurationUnit, OnceOnDemurrage, TimeAllowance, TimeSaved } from "./laytime-calculation";

export type LaytimeCalculationTerms = Readonly<{
    timeAllowance?: TimeAllowance;
    fixedAllowanceHours?: string;
    durationUnit?: LaytimeCalculationDurationUnit;
    rounding?: LaytimeCalculationRounding;
    timeSaved?: TimeSaved;
    onceOnDemurrage?: OnceOnDemurrage;
    demurrageBank?: boolean;
    demurrageRate?: string;
    detentionRate?: string;
    despatchRate?: string;
    comments?: string;
}>;

export type LaytimeCalculationTermsForm = Readonly<{
    timeAllowance: TimeAllowance;
    fixedAllowanceHours: string;
    durationUnit: LaytimeCalculationDurationUnit;
    rounding: LaytimeCalculationRounding;
    timeSaved: TimeSaved;
    alwaysOnDemurrage: boolean;
    demurrageBank: boolean;
    demurrageRate: string;
    detentionRate: string;
    despatchRate: string;
    comments: string;
}>;

export const toLaytimeCalculationTermsForm = (source: LaytimeCalculation): LaytimeCalculationTermsForm => ({
    timeAllowance: source.timeAllowance,
    fixedAllowanceHours: source.fixedAllowanceHours,
    durationUnit: source.durationUnit,
    rounding: source.rounding,
    timeSaved: source.timeSaved,
    alwaysOnDemurrage: source.onceOnDemurrage === "Always On Demurrage",
    demurrageBank: source.demurrageBank,
    demurrageRate: source.demurrageRate,
    detentionRate: source.detentionRate,
    despatchRate: source.despatchRate,
    comments: source.comments
});

export const toLaytimeCalculationTerms = (source: LaytimeCalculationTermsForm): LaytimeCalculationTerms => ({
    timeAllowance: source.timeAllowance,
    fixedAllowanceHours: source.fixedAllowanceHours,
    durationUnit: source.durationUnit,
    rounding: source.rounding,
    timeSaved: source.timeSaved,
    onceOnDemurrage: source.alwaysOnDemurrage ? "Always On Demurrage" : "Not Always On Demurrage",
    demurrageBank: source.demurrageBank,
    demurrageRate: source.demurrageRate,
    detentionRate: source.detentionRate,
    despatchRate: source.despatchRate,
    comments: source.comments
});

export const laytimeCalculationTermsFormKey = "laytimeCalculationTermsForm";
export const createLaytimeCalculationTermsFormState = (calculation: LaytimeCalculation) =>
    createFormGroupState(`${calculation.id}.${laytimeCalculationTermsFormKey}`, toLaytimeCalculationTermsForm(calculation));
