import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

@Injectable()
export class OpsRouteReuseStrategy implements RouteReuseStrategy {
    constructor() {}

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {}

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return null;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (future.outlet === "primary") {
            if (future.routeConfig && future.routeConfig === curr.routeConfig) {
                if (future.routeConfig.path === "fixture") {
                    return this.shouldReuseFixtureRoute(future, curr);
                }
            }
        }

        return future.routeConfig === curr.routeConfig;
    }

    private shouldReuseFixtureRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        const futureFixtureId = future.children[0].params["fixtureId"];
        const currentFixtureId = current.children[0].params["fixtureId"];

        return futureFixtureId === currentFixtureId;
    }
}
