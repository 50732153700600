<div *ngIf="false || (offHires && offHires.length)" [formGroup]="offHiresForm" class="card embedded-card">
    <div class="card-header row no-gutters align-items-center">
        Off Hires
    </div>
    <div class="card-body">
        <table class="table m-0">
            <thead>
                <tr>
                    <th scope="col" class="expander-column"></th>
                    <th scope="col" class="hire-period-column">Hire Period</th>
                    <th scope="col" class="off-hire-from-column">
                        <span class="required">Off Hire From</span>
                    </th>
                    <th scope="col" class="off-hire-to-column">
                        <span class="required">Off Hire To</span>
                    </th>
                    <th scope="col" class="duration-column text-right">
                        <span class="required">Duration (Days)</span>
                    </th>
                    <th scope="col" class="hire-rate-column text-right">
                        <span class="required">Hire Rate</span>
                    </th>
                    <th scope="col" class="value-column text-right">Value</th>
                    <th scope="col" class="buttons-column"></th>
                </tr>
            </thead>
            <tbody formArrayName="offHires">
                <ng-template ngFor let-offHire [ngForOf]="offhiresFormArray" let-i="index">
                    <tr [formGroupName]="i" class="form-group">
                        <td class="p-0">
                            <button
                                (click)="toggleCommentsSection(i)"
                                type="button"
                                class="has-icon"
                                [ngClass]="{
                                    'icon--arrow-drop-right': !isCommentsSectionVisible(i),
                                    'icon--arrow-drop-down': isCommentsSectionVisible(i)
                                }"
                            ></button>
                        </td>
                        <td class="p-0">
                            <input type="hidden" formControlName="originalHireId" />
                            <input type="hidden" formControlName="periodFrom" />
                            <input type="hidden" formControlName="periodTo" />
                            <ng-select
                                class="ops-select"
                                [items]="offHirePeriods"
                                formControlName="hireId"
                                [clearable]="false"
                                bindLabel="name"
                                bindValue="id"
                                [selectOnTab]="true"
                                data-test-hire-period
                                #offHirePeriodsElement
                            >
                            </ng-select>
                        </td>
                        <td class="p-0">
                            <ops-date-input
                                timeZone="utc"
                                [minDate]="offHire.controls.periodFrom.value"
                                [maxDate]="offHire.controls.periodTo.value"
                                [enableTime]="true"
                                formControlName="offHireFrom"
                                warningFormControl
                                [ngbPopover]="offHireFrom"
                                #hf="ngbPopover"
                                triggers="manual"
                                (blur)="hf.close()"
                                (focus)="hf.open()"
                                [defaultFocusDate]="offHire.controls.periodFrom.value"
                                data-test-off-hire-from
                            ></ops-date-input>
                            <ng-template #offHireFrom>
                                <div
                                    *ngIf="offHire.controls.offHireFrom.hasError('required') && offHire.controls.offHireFrom.touched"
                                    class="row no-gutters display"
                                >
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-off-hire-from-date-required> <b>Off Hire From</b> date is required.</span>
                                </div>
                                <div
                                    *ngIf="offHire.controls.offHireFrom.hasError('invalidRange') && offHire.controls.offHireFrom.touched"
                                    class="row no-gutters display"
                                >
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-off-hire-from-cannot-be-later>
                                        <b>Off Hire From</b> cannot be later than <b>Off Hire To</b>.</span
                                    >
                                </div>
                                <div
                                    *ngIf="offHire.controls.offHireFrom.hasError('notInRange') && offHire.controls.offHireFrom.touched"
                                    class="row no-gutters display"
                                >
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-off-hire-from-date-range>
                                        <b>Off Hire</b> date range must be within the <b>Hire Period</b> date range.</span
                                    >
                                </div>
                            </ng-template>
                        </td>
                        <td class="p-0">
                            <ops-date-input
                                timeZone="utc"
                                [minDate]="offHire.controls.periodFrom.value"
                                [maxDate]="offHire.controls.periodTo.value"
                                [enableTime]="true"
                                formControlName="offHireTo"
                                warningFormControl
                                [ngbPopover]="offHireTo"
                                #ht="ngbPopover"
                                triggers="manual"
                                (blur)="ht.close()"
                                (focus)="ht.open()"
                                [defaultFocusDate]="offHire.controls.periodFrom.value"
                                data-test-off-hire-to
                            ></ops-date-input>
                            <ng-template #offHireTo>
                                <div
                                    *ngIf="offHire.controls.offHireTo.hasError('required') && offHire.controls.offHireTo.touched"
                                    class="row no-gutters display"
                                >
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-off-hire-to-date-required> <b>Off Hire To</b> date is required.</span>
                                </div>
                                <div *ngIf="offHire.controls.offHireTo.hasError('invalidRange')" class="row no-gutters display">
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-off-hire-to-cannot-be-earlier>
                                        <b>Off Hire To</b> cannot be earlier than <b>Off Hire From</b>.</span
                                    >
                                </div>
                                <div *ngIf="offHire.controls.offHireTo.hasError('notInRange')" class="row no-gutters display">
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-off-hire-to-date-range>
                                        <b>Off Hire</b> date range must be within the <b>Hire Period</b> date range.</span
                                    >
                                </div>
                            </ng-template>
                        </td>
                        <td class="p-0">
                            <input
                                type="text"
                                class="form-control input-sm text-right"
                                formControlName="durationInDays"
                                [opsNumber]="{ precision: 6, showTrailingZeros: false }"
                                [ngbPopover]="duration"
                                #d="ngbPopover"
                                placement="bottom-left"
                                triggers="manual"
                                (blur)="d.close()"
                                (focus)="d.open()"
                                [autoClose]="false"
                                data-test-duration-days
                            />
                            <ng-template #duration>
                                <div *ngIf="!offHire.controls.durationInDays.valid && offHire.controls.durationInDays.touched" class="row no-gutters display">
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-duration-required> Please specify <b>Duration (Days)</b>.</span>
                                </div>
                            </ng-template>
                        </td>
                        <td class="p-0">
                            <input
                                type="text"
                                class="form-control input-sm text-right"
                                formControlName="hireRate"
                                warningFormControl
                                [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                                [ngbPopover]="hireRateContent"
                                #hr="ngbPopover"
                                placement="bottom-left"
                                triggers="manual"
                                (blur)="hr.close()"
                                (focus)="hr.open()"
                                [autoClose]="false"
                                data-test-hire-rate
                            />
                            <ng-template #hireRateContent>
                                <div *ngIf="!offHire.controls.hireRate.valid && offHire.controls.hireRate.touched" class="row no-gutters display">
                                    <span class="has-icon icon--warning col-1"></span>
                                    <span class="content col-11" data-test-duration-required> Please specify <b>Hire Rate</b>.</span>
                                </div>
                            </ng-template>
                        </td>
                        <td class="p-0">
                            <input
                                type="text"
                                class="form-control input-sm text-right"
                                readonly
                                formControlName="value"
                                [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                                data-test-value
                            />
                        </td>
                        <td class="p-0 btn-delete">
                            <button
                                type="button"
                                [disabled]="offHiresForm.disabled"
                                (click)="deleteOffHire(i)"
                                class="has-icon icon--delete btn-delete float-right"
                                data-test-delete
                            ></button>
                            <button
                                type="button"
                                *ngIf="offHires.length === i + 1"
                                class="has-icon icon--add-circle-outline btn-add float-right"
                                [disabled]="offHiresForm.disabled"
                                (click)="addNew()"
                                data-test-add-off-hire-row
                            ></button>
                        </td>
                    </tr>
                    <tr [formGroupName]="i" class="form-group" [ngClass]="{ 'd-none': !isCommentsSectionVisible(i) }">
                        <td class="reason-type-column" colspan="2">
                            <label for="details">Reason for Off Hire</label>
                            <ng-select
                                class="ops-select"
                                [items]="expenseTypes$ | async"
                                formControlName="reasonType"
                                placeholder="Select"
                                [clearable]="true"
                                bindLabel="name"
                                [compareWith]="referenceDataService.compare"
                                data-test-reasontype
                                [selectOnTab]="true"
                            ></ng-select>
                        </td>
                        <td class="comments-column" colspan="6">
                            <label for="comments">Comments</label>
                            <textarea formControlName="comments" class="form-control" maxlength="3000" rows="2" data-test-comments></textarea>
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>
<ops-no-data-panel
    *ngIf="!offHires.length"
    heading="Off Hires"
    title="No off hires have been added"
    description="To add an off hire, click the button."
    [useNewButton]="true"
    newButtonText="OFF HIRE"
    [disabled]="offHiresForm.disabled"
    (addNew)="addNew()"
>
</ops-no-data-panel>
