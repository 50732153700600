import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import * as URLParse from "url-parse";
import { WindowRefService } from "../window-ref.service";
import { AppStateService } from "./app-state.service";

@Injectable({
    providedIn: "root"
})
export class AppStateInterceptor implements HttpInterceptor {
    constructor(private windowRefService: WindowRefService, private appStateService: AppStateService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method !== "GET") {
            return this.skipInterception(req, next);
        }

        if (req.headers.has("Skip-App-State-Interceptor")) {
            const headers = req.headers.delete("Skip-App-State-Interceptor");
            const clonedReq = req.clone({ headers });
            return this.skipInterception(clonedReq, next);
        }

        const origin = this.windowRefService.nativeWindow.origin;

        if (!this.isUrlValid(req.url, origin)) {
            return this.skipInterception(req, next);
        }

        return this.handleInterception(req, next);
    }

    isUrlValid(url: string, origin: string): boolean {
        const parsedUrl = new URLParse(url, origin);
        return parsedUrl.origin === origin && parsedUrl.pathname.endsWith(".js");
    }

    handleInterception(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse && err.status === 404) {
                    this.appStateService.checkIndexInSync().subscribe();
                }
                return throwError(err);
            })
        ) as Observable<HttpEvent<any>>;
    }

    skipInterception(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req);
    }
}
