import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { worksheetsReducer } from "../worksheet.reducer";
import { WorksheetEffects } from "./effects";
import { worksheetsFeatureKey } from "./selectors";

@NgModule({
    imports: [StoreModule.forFeature(worksheetsFeatureKey, worksheetsReducer), EffectsModule.forFeature([WorksheetEffects])]
})
export class WorksheetStoreModule {}
