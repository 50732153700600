import { AfterContentInit, ChangeDetectionStrategy, Component, NgZone, ViewEncapsulation } from "@angular/core";

import { AppConfigService, AuthService } from "../core";

@Component({
    selector: "ops-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements AfterContentInit {
    constructor(private authService: AuthService, private appConfigService: AppConfigService, private ngZone: NgZone) {}

    /**
     * Initializes navigation bar. See more information about configuration parameters here:
     * https://maritech-group.atlassian.net/wiki/spaces/CLOUD/pages/13070729603/Header+Configuration+-+The+Deep+Dive
     * Module id 31 is proprietary for Sea/ops, same for all environments.
     */
    ngAfterContentInit() {
        this.headerScript().onload = () =>
            this.ngZone.runOutsideAngular(() => {
                const headerConfig = {
                    moduleId: 31,
                    container: document.getElementById("navbar"),
                    authority: this.appConfigService.config.authorityUrl,
                    applicationCode: this.appConfigService.config.applicationCode,
                    notificationHubUrl: this.appConfigService.config.notificationHubUrl,
                    getTokenFailureCallback: () => {
                        console.log("No token produced upon request!");
                        window.location.href = "https://www.sea.live";
                    },
                    logoutCallback: (event: any) => {
                        this.authService.logout();
                        event.preventDefault?.();
                        event.returnValue = false;
                    },
                    getTokenCallback: () => this.authService.token,
                    tracking: {
                        isEnabled: true
                    }
                };

                // @ts-ignore
                this.headerRef = new Sea.HeaderComponent.Header(headerConfig);
            });
    }

    private headerScript(): HTMLScriptElement {
        const cloudCdnLocation = this.appConfigService.config.cloudHeaderCdnLocation;
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `${cloudCdnLocation}/v3/latest/clk-header.js`;
        script.id = "SeaHeaderScript";

        const head = document.head || document.getElementsByTagName("head")[0];
        head.insertBefore(script, head.firstChild);

        return script;
    }
}
