<div class="card embedded-card-1">
    <div class="card-header row no-gutters align-items-center">
        {{ title }}
    </div>
    <div [formGroup]="parentForm">
        <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let column of (columnDefs$ | async)" [ngClass]="column.class">{{ column.headerName }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let engineConsumption of model">
                    <td>{{ engineConsumption.type?.name }}</td>
                    <td class="text-right">{{ engineConsumption.quantityConsumed | opsNumber: true:2:2 }}</td>
                    <td class="text-right">{{ engineConsumption.quantityRemaining | opsNumber: true:2:2 }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="text-right total">Totals</td>
                    <td class="text-right">{{ totalQuantityConsumed | opsNumber: true:2:2 }}</td>
                    <td class="text-right">{{ totalQuantityRemaining | opsNumber: true:2:2 }}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
