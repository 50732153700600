import { Enumeration } from "./enumeration";

export class CpRateUnit extends Enumeration {
    static MtPerDay: CpRateUnit = new CpRateUnit(1, "MT/Day");
    static MtPerHour: CpRateUnit = new CpRateUnit(2, "MT/Hour");
    static Hours: CpRateUnit = new CpRateUnit(3, "Hours");

    static isMtPerDay(cpRateUnit: CpRateUnit): boolean {
        return cpRateUnit && cpRateUnit.id === this.MtPerDay.id;
    }

    static isMtPerHour(cpRateUnit: CpRateUnit): boolean {
        return cpRateUnit && cpRateUnit.id === this.MtPerHour.id;
    }
}
