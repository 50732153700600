import { createAction, On, on, props } from "@ngrx/store";
import { unbox, updateGroup } from "ngrx-forms";

import { LaytimeEventType } from "@ops/shared/reference-data";
import { opsAddArrayControl } from "@ops/state";

import { updateDestinationForm } from "../../destinations/shared";
import { activityForm, berthExpandedKey, berthForm, BerthId, createBerthId, DestinationId, FixturesState, getDivision, laytimeEventForm, LaytimeEventForm } from "../../model";
import { getNextId } from "../../utils";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const cloneBerthAction = createAction("[Voyage Form] Clone Berth", props<{ destinationId: DestinationId; berthId: BerthId }>());

/* REDUCERS */
export const cloneBerthReducer: On<FixturesState> = on(cloneBerthAction, (state, { destinationId, berthId }) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        const division = getDivision(fixtureState);
        const destination = voyageState.form.controls.destinations.controls.find((d) => d.value.id === destinationId);
        if (!destination) {
            throw Error(`Unable to clone berth. Invalid destinationId=${destinationId}`);
        }
        const berths = destination.controls.berths;
        const sourceBerth = berths.value.find((x) => x.id === berthId);

        if (!sourceBerth) {
            throw Error(`Unable to clone berth. Invalid berthId=${berthId}`);
        }

        const sourceBerthIndex = berths.value.indexOf(sourceBerth);
        const newBerthId = createBerthId();

        const cloneLaytimeEvent = (source: LaytimeEventForm) => {
            const shouldCloneDateAndComments = [LaytimeEventType.ETA, LaytimeEventType.Arrival, LaytimeEventType.NORTendered, LaytimeEventType.NORAccepted].some(
                (x) => x.id === unbox(source.type).id
            );

            return {
                ...laytimeEventForm(division, source.laytimeEventId),
                type: source.type,
                eventDate: shouldCloneDateAndComments ? source.eventDate : null,
                comments: shouldCloneDateAndComments ? source.comments : null
            };
        };

        const berthClone = {
            ...berthForm(division, newBerthId, getNextId(berths.value, "berthId")),
            activities: sourceBerth.activities.map((a) => ({
                ...activityForm(division, a.activityId, a.legacyActivityId),
                type: a.type,
                associatedCargoes: a.associatedCargoes,
                laytimeEvents: a.laytimeEvents ? a.laytimeEvents.map(cloneLaytimeEvent) : null
            }))
        };

        return {
            ...voyageState,
            form: updateDestinationForm({ destinationId }, (destinationForm) =>
                updateGroup(destinationForm, {
                    berths: opsAddArrayControl(berthClone, sourceBerthIndex + 1, { markAsTransient: true, scrollIntoView: true })
                })
            )(voyageState.form),
            expandedSections: {
                ...voyageState.expandedSections,
                [berthExpandedKey(destinationId, newBerthId)]: true
            }
        };
    })
);
