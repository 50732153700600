import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, createAction, Store } from "@ngrx/store";
import { DateTime } from "luxon";
import * as R from "ramda";
import { switchMap, withLatestFrom } from "rxjs/operators";

import { roundAndStringifyNumber } from "@ops/shared";

import { ActivityLocation, createLaytimeEventId, Deduction, LaytimeCalculation, LaytimeEvent, LaytimeEventId, LtcFeatureState } from "../../../model";
import { LaytimeEventType } from "../../../model/calculations/laytime-event-types";
import { isLaytimeEventAfterDemurrageEvent, getLaytimeEventPercentage, hasExclusion } from "../../../model/calculations/utils";
import { selectCurrentLaytimeCalculation } from "../../selectors";
import { preAddLaytimeEventAction } from "../laytime-events/form/add-laytime-event";
import { removeLaytimeEventFormAction } from "../laytime-events/form/remove-laytime-event";
import { updateLaytimeEventFormAction } from "../laytime-events/form/update-laytime-event";
import { selectCurrentActivityLocation } from "../selectors";
import { clearDeductionsAction } from "./form/remove-deduction";
import { selectCurrentDeductions } from "./selectors";

const LAYTIME_SUSPENDED_TYPE: LaytimeEventType = "Laytime Suspended";
const LAYTIME_RESUMED_TYPE: LaytimeEventType = "Laytime Resumed";

const DEDUCTION_START_TYPE = "Deduction Start";
const DEDUCTION_END_TYPE = "Deduction End";
const LAYTIME_EVENT_START_TYPE = "Laytime Event Start";
const LAYTIME_EVENT_END_TYPE = "Laytime Event End";

/* ACTIONS */
const APPLY_DEDUCTIONS_ACTION_NAME = "[Enter Deductions Form] Apply Deductions";
export const applyDeductionsAction = createAction(APPLY_DEDUCTIONS_ACTION_NAME);

/* EFFECTS */
export const applyDeductionsEffect$ = (actions$: Actions, store: Store<LtcFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType(applyDeductionsAction),
            withLatestFrom(store.select(selectCurrentLaytimeCalculation), store.select(selectCurrentActivityLocation), store.select(selectCurrentDeductions)),
            switchMap(([, calculation, activityLocation, deductions]) => [
                ...createLaytimeEventActions(
                    calculation,
                    activityLocation,
                    deductions.map((d) => d.form.value),
                    activityLocation.laytimeEvents
                ),
                clearDeductionsAction()
            ])
        )
    );

/**
 * Creates all actions to be applied to the existing laytime events table. The algorithm first preprocesses the data by mixing deductions and laytime events
 * in a collection of so called consolidated entries. Then it loops through these entries and according to an entry type performs certain actions (changes its internal state and/or creates NgRx actions).
 * The algorithm can be imagined as a timeline where we place all events (laytime events and deduction start/ends) and track where we are,
 * what laytime event or deduction is currently active, and making decisions on every step.
 */
export const createLaytimeEventActions = (
    calculation: LaytimeCalculation,
    activityLocation: ActivityLocation,
    deductions: ReadonlyArray<Deduction>,
    laytimeEvents: ReadonlyArray<LaytimeEvent>
): Action[] => {
    const entries = prepareConsolidatedEntries(deductions, laytimeEvents);
    const result: Action[] = [];
    const processedLaytimeEventIds: LaytimeEventId[] = [];
    const pushPreAddAction = (laytimeEvent: LaytimeEvent, index: number) => {
        result.push(preAddLaytimeEventAction({ laytimeEvent, index }));
    };
    const pushUpdateAction = (laytimeEvent: LaytimeEvent, timeFrom: string, timeTo: string) => {
        const laytimeEventId = laytimeEvent.id;
        const changes = createLaytimeEventChanges(calculation, activityLocation, laytimeEvent, timeFrom, timeTo);
        result.push(updateLaytimeEventFormAction({ ltcId: calculation.id, activityLocationId: activityLocation.id, laytimeEventId, laytimeEvent: changes }));
        processedLaytimeEventIds.push(laytimeEventId);
    };
    const pushRemoveAction = (laytimeEventId: LaytimeEventId) => {
        result.push(removeLaytimeEventFormAction({ laytimeEventId }));
        processedLaytimeEventIds.push(laytimeEventId);
    };

    // Whether at the current moment a deduction is active.
    let inDeduction = false;
    // If the first deduction is before the first laytime event, we need to insert an empty laytime event at the start of the first deduction,
    // and when real laytime events start we need to shift current index by 1 to adjust for the inserted event.
    let shouldApplyInitialLaytimeEventAdjustment = false;
    // At every moment indicates the laytime event that is in progress. Initially set as the first laytime event.
    let activeLaytimeEvent: LaytimeEvent = laytimeEvents.find((laytimeEvent) => !!laytimeEvent.date);
    // Start date of the active laytime event. As the first laytime event always has empty start date, this value should initialized as empty.
    let activeLaytimeEventStartDate: string = null;
    // Whether the active laytime event should be retained after deductions are applied. Normally it means that at some point there is no deduction that covers a part of the current event so that it retains.
    let activeLaytimeEventUsed = true;
    // Current index continuously mainteined to add new laytime events.
    let laytimeEventIndex = activeLaytimeEvent ? laytimeEvents.indexOf(activeLaytimeEvent) : 0;

    for (let i = 0; i < entries.length; i++) {
        const item = entries[i];
        switch (item.type) {
            // Indicates that we are going to switch to new active laytime event.
            case LAYTIME_EVENT_START_TYPE:
                // Once we are switching to new laytime event and the current one was not used anywhere, and we are currently in active deduction, just delete the current laytime event.
                if (activeLaytimeEvent && !activeLaytimeEventUsed && inDeduction) {
                    pushRemoveAction(activeLaytimeEvent.id);
                    laytimeEventIndex--;
                }
                // Switching to a new laytime event and setting variables.
                activeLaytimeEvent = item.laytimeEvent;
                activeLaytimeEventStartDate = entries[i].dateTime;
                laytimeEventIndex++;
                if (shouldApplyInitialLaytimeEventAdjustment) {
                    laytimeEventIndex++;
                    shouldApplyInitialLaytimeEventAdjustment = false;
                }
                activeLaytimeEventUsed = !inDeduction;
                break;
            // Indicated that a deduction starts.
            case DEDUCTION_START_TYPE:
                // Handles special case when first deduction is before the earliest laytime event. In this case we need to add a fake laytime event to set the start of the deduction.
                if (i === 0) {
                    pushPreAddAction(createInitialLaytimeEvent(entries[i].dateTime), laytimeEventIndex);
                    shouldApplyInitialLaytimeEventAdjustment = true;
                }
                // Further processing only makes sense if currently there is no deduction active. Deductions cannot overlap, this is checked via form validation.
                if (!inDeduction) {
                    // Increasing laytime event index to arrange position for Laytime Suspended event. However, if the current laytime event is not yet used
                    // (which can happen if another deduction overlapped the start of the event), we don't need to increase the index because that event will be positioned after the deduction (if not deleted at all)
                    if (activeLaytimeEventUsed) {
                        laytimeEventIndex++;
                    }
                    // We need to handle active laytime event (update or remove) if it exists (in the case if there are no laytime events in the table, activeLaytimeEvent will be null), not yet processed and
                    // if the deduction start date is before the laytime event end date (in the latter case the laytime event will be retained as zero-duration event).
                    if (activeLaytimeEvent && !processedLaytimeEventIds.includes(activeLaytimeEvent.id) && entries[i].luxonDateTime < DateTime.fromISO(activeLaytimeEvent.date)) {
                        // Non-empty activeLaytimeEventStartDate means that this is not the first laytime event, so if a part of the laytime event is already not covered by a deduction (i.e. activeLaytimeEventUsed = true)
                        // we update the end date of the active laytime event, setting it to the start of the deduction. The rest will be covered by Laytime Suspended and Laytime Resumed newly created events.
                        if (activeLaytimeEventStartDate && activeLaytimeEventUsed) {
                            pushUpdateAction(activeLaytimeEvent, activeLaytimeEventStartDate, entries[i].dateTime);
                        } else if (DateTime.fromISO(activeLaytimeEvent.date) < DateTime.fromISO(entries[i].deduction.timeTo)) {
                            // This line handles a special case when a deduction starts before the first laytime event, but ends after the first laytime event date.
                            // Normally we set activeLaytimeEventUsed for the first laytime event as true, but in this case we need to reset it to false so that it will be removed when the current deduction ends.
                            activeLaytimeEventUsed = false;
                        }
                    }
                    // This condition handles a special case when current deduction starts at the very moment when first laytime event starts.
                    // Normally a laytime event start is ordered after a deduction start. However, for the first laytime event we need to skip it, so we increade laytimeEventIndex once again.
                    if (activeLaytimeEvent && entries[i].luxonDateTime.equals(DateTime.fromISO(activeLaytimeEvent.date)) && shouldApplyInitialLaytimeEventAdjustment) {
                        laytimeEventIndex++;
                        shouldApplyInitialLaytimeEventAdjustment = false;
                    }
                    pushPreAddAction(createLaytimeSuspendedEvent(entries[i].deduction), laytimeEventIndex);
                    inDeduction = true;
                }
                break;
            // Indicates that a deduction ends.
            case DEDUCTION_END_TYPE:
                // Further processing only makes sense if currently there is a deduction active.
                if (inDeduction) {
                    // We don't need to create a Laytime Resumed event if this is the last entry or the next entry starts at the same date.
                    // Normally the last entry is a LAYTIME_EVENT_END_TYPE entry, but if the current deduction ends after the last laytime event this is not the case.
                    if (i < entries.length - 1 && !entries[i].luxonDateTime.equals(entries[i + 1].luxonDateTime)) {
                        // If the current laytime event is not yet used (or doesn't exist at all), we don't need to create Laytime Resumed event, because
                        // the current laytime event will go right after the deduction ends.
                        if (activeLaytimeEventUsed) {
                            laytimeEventIndex++;
                            const newLaytimeEvent = createLaytimeResumedEvent(calculation, activityLocation, activeLaytimeEvent, entries[i].dateTime, entries[i + 1].dateTime);
                            pushPreAddAction(newLaytimeEvent, laytimeEventIndex);
                        } else {
                            activeLaytimeEventUsed = true;
                        }
                    }
                    // If the current laytime event was not used (i.e. fully covered by deductions) we need to remove it.
                    if (activeLaytimeEvent && !activeLaytimeEventUsed && DateTime.fromISO(activeLaytimeEvent.date) < entries[i].luxonDateTime) {
                        pushRemoveAction(activeLaytimeEvent.id);
                        laytimeEventIndex--;
                    }
                    inDeduction = false;
                }
                break;
        }
    }

    return result;
};

/**
 * Returns a fake laytime event to be created when there is a deduction before the first laytime event.
 */
const createInitialLaytimeEvent = (date: string) => ({
    id: createLaytimeEventId(),
    date
});

/**
 * Creates changes to be applied to an existing laytime event. Normally changes include only date, but if SHEX / SHEX UU policy is applied, percentage may also change.
 */
const createLaytimeEventChanges = (
    calculation: LaytimeCalculation,
    activityLocation: ActivityLocation,
    laytimeEvent: LaytimeEvent,
    timeFrom: string,
    timeTo: string
): Partial<LaytimeEvent> => {
    if (timeFrom && !isLaytimeEventAfterDemurrageEvent(calculation, activityLocation, laytimeEvent) && hasExclusion(activityLocation)) {
        const percentageValue = getLaytimeEventPercentage(laytimeEvent.type, timeFrom, timeTo, activityLocation);
        const percentage = roundAndStringifyNumber(percentageValue, 2);
        return laytimeEvent.percentage !== percentage ? { date: timeTo, percentage } : { date: timeTo };
    } else {
        return { date: timeTo };
    }
};

/**
 * Creates Laytime Suspended event.
 */
const createLaytimeSuspendedEvent = (deduction: Deduction) => ({
    id: createLaytimeEventId(),
    type: LAYTIME_SUSPENDED_TYPE,
    date: deduction.timeTo,
    percentage: "0",
    remarks: deduction.remarks
});

/**
 * Creates Laytime Resumed event and calculates percentage according to SHEX / SHEX UU policy.
 */
const createLaytimeResumedEvent = (calculation: LaytimeCalculation, activityLocation: ActivityLocation, laytimeEvent: LaytimeEvent, timeFrom: string, timeTo: string) => {
    const percentage =
        laytimeEvent && !isLaytimeEventAfterDemurrageEvent(calculation, activityLocation, laytimeEvent) && hasExclusion(activityLocation)
            ? getLaytimeEventPercentage(LAYTIME_RESUMED_TYPE, timeFrom, timeTo, activityLocation)
            : 100;

    return {
        id: createLaytimeEventId(),
        type: LAYTIME_RESUMED_TYPE,
        date: timeTo,
        percentage: roundAndStringifyNumber(percentage, 2)
    };
};

declare type ConsolidatedEntry = Readonly<{
    order: number;
    type: string;
    dateTime: string;
    luxonDateTime: DateTime;
    deduction: Deduction;
    laytimeEvent: LaytimeEvent;
}>;

const isZeroDuration = (deduction: Deduction) => DateTime.fromISO(deduction.timeFrom).equals(DateTime.fromISO(deduction.timeTo));

/**
 * Combines laytime events and deductions into consolidated entries and orders them. Ordering is very important, because later the algorithm will
 * process them in one loop and it cannot step backwards. Consolidated entries are created:
 * 1) For a deduction start
 * 2) For a deduction end
 * 3) For a laytime event start - in this case we set start date as the date for the consolidated entry (as opposed to end date stored in the laytime event itself)
 * 4) When the last laytime event with non-empty date ends - the date for the consolidated entry is the end date of last such event.
 * First of all we order entries by date. If dates are the same there is another property named order. The order is generated dynamically in the following way:
 * 1) A deduction end comes first.
 * 2) All deductions with zero duration should go one by one with deduction end immediately following same deduction start.
 * 3) Next comes a laytime event if it has zero duration.
 * 4) Next comes a deduction start.
 * 5) And a non-zero duration laytime event comes last.
 */
const prepareConsolidatedEntries = (deductions: ReadonlyArray<Deduction>, laytimeEvents: ReadonlyArray<LaytimeEvent>): ConsolidatedEntry[] => {
    let order = 1;
    const result = [];
    const nonZeroDurationDeductionEndOrder = 0;
    let zeroDurationLaytimeEventOrder = 1;
    let nonZeroDurationDeductionStartOrder = 2;
    let nonZeroDurationLaytimeEventOrder = 3;
    const pushDeduction = (item: Deduction) => {
        result.push({
            order: isZeroDuration(item) ? order++ : nonZeroDurationDeductionStartOrder,
            type: DEDUCTION_START_TYPE,
            dateTime: item.timeFrom,
            luxonDateTime: DateTime.fromISO(item.timeFrom),
            deduction: item
        });
        result.push({
            order: isZeroDuration(item) ? order++ : nonZeroDurationDeductionEndOrder,
            type: DEDUCTION_END_TYPE,
            dateTime: item.timeTo,
            luxonDateTime: DateTime.fromISO(item.timeTo),
            deduction: item
        });
    };
    deductions.filter((item) => isZeroDuration(item)).forEach((item) => pushDeduction(item));
    zeroDurationLaytimeEventOrder = order++;
    nonZeroDurationDeductionStartOrder = order++;
    nonZeroDurationLaytimeEventOrder = order++;
    deductions.filter((item) => !isZeroDuration(item)).forEach((item) => pushDeduction(item));
    order++;
    let previousLaytimeEvent: LaytimeEvent;
    let lastValidLaytimeEvent: LaytimeEvent;
    laytimeEvents.forEach((laytimeEvent) => {
        if (laytimeEvent.date) {
            // Here we only consider laytime events that have the start date (i.e. excluding first laytime event).
            // First laytime event will be tracked as the initial value for activeLaytimeEvent variable in the main algorithm.
            if (previousLaytimeEvent?.date) {
                const startDate = DateTime.fromISO(previousLaytimeEvent.date);
                const endDate = DateTime.fromISO(laytimeEvent.date);
                result.push({
                    order: !startDate.equals(endDate) ? nonZeroDurationLaytimeEventOrder : zeroDurationLaytimeEventOrder,
                    type: LAYTIME_EVENT_START_TYPE,
                    dateTime: previousLaytimeEvent.date,
                    luxonDateTime: startDate,
                    laytimeEvent
                });
            }
            lastValidLaytimeEvent = laytimeEvent;
        }
        previousLaytimeEvent = laytimeEvent;
    });
    if (lastValidLaytimeEvent) {
        result.push({
            order: nonZeroDurationLaytimeEventOrder + 1,
            type: LAYTIME_EVENT_END_TYPE,
            dateTime: lastValidLaytimeEvent.date,
            luxonDateTime: DateTime.fromISO(lastValidLaytimeEvent.date),
            laytimeEvent: lastValidLaytimeEvent
        });
    }

    return R.sortWith([R.ascend(R.prop("luxonDateTime")), R.ascend(R.prop("order"))], result) as ConsolidatedEntry[];
};
