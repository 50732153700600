import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";
import { FreightType } from "src/app/shared/reference-data/freight-type";

export class HeaderVoyageValidator {
    static validate(): ValidatorFn {
        return (control: AbstractControl): null => {
            const lumpsumValue = control.get("lumpsumValue");
            const freightType = control.get("freightType");

            if (!lumpsumValue || !freightType) {
                return null;
            }

            if (freightType.value && freightType.value.id === FreightType.LumpSum.id && !lumpsumValue.value) {
                lumpsumValue.setErrors({ required: true });
            } else {
                lumpsumValue.setErrors(null);
            }

            return null;
        };
    }
}
