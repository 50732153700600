import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfigService } from "src/app/core/app-config.service";
import { SuggestedFixture } from "src/app/fixture/shared/models/common/suggested-fixture";
import { SuggestedFixtureDocument } from "src/app/fixture/shared/models/common/suggested-fixture-document";
import { SuggestionService } from "../suggestion.service";

@Injectable({
    providedIn: "root"
})
export class FixtureAutosuggestService implements SuggestionService<SuggestedFixtureDocument> {
    private searchServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.searchServiceUrl = appConfigService.config.searchServiceUrl;
    }

    getSuggestions(term: string): Observable<SuggestedFixtureDocument[]> {
        if (!term) {
            return of([]);
        }

        const url = this.buildSearchQueryUrl(term);
        return this.httpClient.get<SuggestedFixture>(url).pipe(
            map((data: SuggestedFixture) => {
               return data.documents.filter((fixture) => {
                    return fixture.type !== "TC Voyage";
                  });
            })
        );
    }

    private buildSearchQueryUrl(term: string): string {
        const vesselToken = `Vessel: ${term}`;
        const query = `Queries=${encodeURIComponent(vesselToken)}&Is:Open`;
        const sortingPart = "&SortOrder=desc&SortColumn=CharterPartyDate";

        return `${this.searchServiceUrl}/api/v1.0/Fixtures/search?${query}&Skip=0&Take=300${sortingPart}&IsFreeTextTokenSearch=true`;
    }
}
