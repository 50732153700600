import { Injectable } from "@angular/core";
import { CommandHandler } from "src/app/fixture/mediator/command-handlers/command-handler";
import { EstimatedClaimValueCalculator } from "src/app/fixture/services/estimated-claim-value-calculator";
import { Fixture } from "src/app/fixture/shared/models";
import { dateToISOString } from "src/app/shared/date-utils/date-utilities";
import { Division } from "src/app/shared/reference-data";
import { DemurrageStatus } from "src/app/shared/reference-data/demurrage-status";
import { isNullOrUndefined } from "src/app/shared/utils/check";
import { DemurrageClaimFormModel } from "../../../shared/models/form-models/demurrage-claim.model";
import { UpdateDemurrageClaimCommand } from "./update-demurrage-claim.command";

@Injectable({
    providedIn: "root"
})
export class UpdateDemurrageClaimCommandHandler implements CommandHandler {
    constructor(private ecvCalculator: EstimatedClaimValueCalculator) {}

    handle(fixture: Fixture, command: UpdateDemurrageClaimCommand) {
        const claimForm = command.payload.data as DemurrageClaimFormModel;
        if (!claimForm) {
            throw new Error("Given demurrage claim form model is invalid.");
        }

        const demurrageClaimToUpdate = fixture.demurrage.claims.find((e) => e.demurrageClaimId === claimForm.demurrageClaimId);
        if (!demurrageClaimToUpdate) {
            throw new Error("Cannot update the demurrage claim because the given claim id is invalid or doesnt exist.");
        }

        if (this.shouldSetDemurrageStatus(fixture, claimForm.initialClaimValue)) {
            fixture.demurrage.status = DemurrageStatus.Demurrage;
        }

        demurrageClaimToUpdate.type = claimForm.type;
        demurrageClaimToUpdate.gainId = claimForm.gainId;
        demurrageClaimToUpdate.receivedFromOwnerDate = dateToISOString(claimForm.receivedFromOwnerDate);
        demurrageClaimToUpdate.sentToChartererDate = dateToISOString(claimForm.sentToChartererDate);
        demurrageClaimToUpdate.chartererAcknowledgedReceiptDate = dateToISOString(claimForm.chartererAcknowledgedReceiptDate);
        demurrageClaimToUpdate.initialClaimValue = claimForm.initialClaimValue;
        demurrageClaimToUpdate.interimClaimValue = claimForm.interimClaimValue;
        demurrageClaimToUpdate.finalClaimValue = claimForm.finalClaimValue;
        demurrageClaimToUpdate.currency = claimForm.currency;
        demurrageClaimToUpdate.agreedDate = dateToISOString(claimForm.agreedDate);
        demurrageClaimToUpdate.datePaid = dateToISOString(claimForm.datePaid);
        demurrageClaimToUpdate.lastContactedDateTime = dateToISOString(claimForm.lastContactedDateTime);
        demurrageClaimToUpdate.awaitingHardCopy = claimForm.awaitingHardCopy;
        demurrageClaimToUpdate.awaitingDocuments = claimForm.awaitingDocuments;
        demurrageClaimToUpdate.complete = claimForm.complete;
        demurrageClaimToUpdate.ownerInvoiceNumber = claimForm.ownerInvoiceNumber;
        demurrageClaimToUpdate.commissionDate = dateToISOString(claimForm.commissionDate);
        demurrageClaimToUpdate.claimHandledBy = claimForm.claimHandledBy;

        let estimatedClaimValue = null;
        estimatedClaimValue = this.ecvCalculator.calculateCurrentECV(fixture, command.payload.destinations, demurrageClaimToUpdate);

        demurrageClaimToUpdate.estimatedClaimValue = estimatedClaimValue;

        if (claimForm.berth) {
            demurrageClaimToUpdate.destinationId = claimForm.berth.destinationId;
            demurrageClaimToUpdate.berthId = claimForm.berth.berthId;
        } else {
            demurrageClaimToUpdate.destinationId = demurrageClaimToUpdate.berthId = null;
        }

        demurrageClaimToUpdate.comments = claimForm.comments;

        demurrageClaimToUpdate.lastUpdatedByUser = command.payload.user;
        demurrageClaimToUpdate.lastUpdatedDate = dateToISOString(new Date());
    }

    private shouldSetDemurrageStatus(fixture: Fixture, initialClaimValue: number): boolean {
        return (
            !isNullOrUndefined(initialClaimValue) &&
            fixture.division &&
            (fixture.division.id === Division.Gas.id || fixture.division.id === Division.Specialised.id || fixture.division.id === Division.Pcg.id) &&
            fixture.demurrage &&
            fixture.demurrage.status &&
            (fixture.demurrage.status.id === DemurrageStatus.Active.id || fixture.demurrage.status.id === DemurrageStatus.Fixed.id) &&
            fixture.demurrage.claims &&
            fixture.demurrage.claims.every((c) => isNullOrUndefined(c.initialClaimValue))
        );
    }
}
