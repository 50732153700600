import { Injectable } from "@angular/core";

import { isNullOrUndefined } from "@ops/shared";

import { Fixture, FixtureType } from "../../../models";
import { FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class ExternalVisibilityDeliveryRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        if (fixture.fixtureType.id !== FixtureType.TimeCharter) {
            return warnings;
        }

        if (isNullOrUndefined(fixture.delivery) || isNullOrUndefined(fixture.delivery.deliveryLocations) || !fixture.delivery.deliveryLocations.length) {
            const message = "Missing Delivery Location";
            const path = [
                new FixtureWarningPathSegment(null, "Hire"),
                new FixtureWarningPathSegment("delivery", "Delivery"),
                new FixtureWarningPathSegment("deliveryLocations", "Location")
            ];
            warnings.push(new FixtureWarning("data", message, path));
        }

        return warnings;
    }
}
