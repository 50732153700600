import { Injectable } from "@angular/core";

import { CommandHandler } from "../../../../mediator";
import { Fixture } from "../../../../shared/models";
import { AddBunkerConsumptionCommand } from "./add-bunker-consumption.command";

@Injectable({
    providedIn: "root"
})
export class AddBunkerConsumptionCommandHandler implements CommandHandler {
    handle(fixture: Fixture, _command: AddBunkerConsumptionCommand) {
        fixture.cpSpeedAndConsumption.cpBunkersConsumption = fixture.cpSpeedAndConsumption.cpBunkersConsumption || [];

        const maxId = fixture.cpSpeedAndConsumption.cpBunkersConsumption.reduce((max, current) => (current.bunkerConsumptionId > max ? current.bunkerConsumptionId : max), 0);

        fixture.cpSpeedAndConsumption.cpBunkersConsumption.push({
            bunkerConsumptionId: maxId + 1,
            type: null,
            ballast: null,
            laden: null,
            atSea: null,
            portIdle: null,
            portWorking: null
        });
    }
}
