import { createSelector } from "@ngrx/store";
import * as R from "ramda";

import { StateObjectMap } from "@ops/state";

import { Nullable } from "../../../shared";
import { calcVoyageCost } from "../../services/voyage-cost-calculator";
import { Bunker, CargoBerthActivity } from "../../shared/models";
import { BunkerConsumption } from "../../shared/models/dtos/bunker-consumption.dto";
import {
    selectCurrentCpBunkersConsumption,
    selectCurrentDeliveryBunkers,
    selectCurrentFixture,
    selectCurrentFixtureLockedByCurrentUser,
    selectCurrentFixtureState,
    selectCurrentFixtureVoyageIds,
    selectCurrentFixtureVoyagesLoading,
    selectFixtureVoyageIds
} from "../fixture";
import { selectFixturesFeature } from "../fixture.selectors";
import { ActivityId, FixtureFeatureState, getCurrentVoyageId, speedAndConsTabFormKeys, VoyageId, VoyageState } from "../model";

export type VoyageNumber = Readonly<{
    voyageId: string;
    fixtureId: string;
    voyageNumber: string;
}>;

export const selectCurrentVoyageId = createSelector(selectFixturesFeature, getCurrentVoyageId);

export const selectVoyagesState = createSelector(selectFixturesFeature, (state) => state.voyages);

export const selectVoyageState = createSelector<FixtureFeatureState, { voyageId: VoyageId }, StateObjectMap<VoyageState>, VoyageState>(
    selectVoyagesState,
    (state, { voyageId }) => state.byId[voyageId]
);

export const selectCurrentVoyageState = createSelector(selectVoyagesState, selectCurrentFixtureVoyageIds, selectCurrentVoyageId, (state, voyageIds, currentVoyageId) => {
    if (!voyageIds || !voyageIds.length) {
        return null;
    }

    const voyageId = currentVoyageId || voyageIds[0];

    return voyageId ? state.byId[voyageId] : null;
});

export const selectCurrentVoyageBusy = createSelector(
    selectCurrentFixtureVoyagesLoading,
    selectCurrentVoyageState,
    (voyagesLoading, voyageState) => voyagesLoading || !voyageState || voyageState.loadStatus === "loading" || voyageState.persistenceStatus === "persisting"
);

export const selectCurrentVoyageReadonly = createSelector(
    selectCurrentVoyageBusy,
    selectCurrentFixtureLockedByCurrentUser,
    (busy, lockedByCurrentUser) => busy || !lockedByCurrentUser
);

export const selectCurrentVoyageForm = createSelector(selectCurrentVoyageState, (state) => state?.form);
export const selectCurrentVoyageFormValue = createSelector(selectCurrentVoyageForm, (form) => form?.value);

/**
 * Selects the voyage form values combined with the working voyage as a (legacy) voyage dto.
 */
export const selectVoyage = createSelector(selectVoyageState, (state) => state?.workingVoyage);

/**
 * Selects the current voyage form values combined with the working voyage as a (legacy) voyage dto.
 */
export const selectCurrentVoyage = createSelector(selectCurrentVoyageState, (state) => state?.workingVoyage);

export const selectVoyageIsValid = createSelector(selectVoyageState, (state) => state?.form?.isValid);

/**
 * Selects true if the current voyage was invalid when a save was attempted.
 */
export const selectCurrentVoyageInvalidOnSave = createSelector(selectCurrentVoyageState, (state) => state?.persistenceStatus === "invalid");

function projectVoyageStates(state: StateObjectMap<VoyageState>, voyageIds: ReadonlyArray<VoyageId>): ReadonlyArray<VoyageState> {
    if (!voyageIds) {
        return [];
    }

    return voyageIds.map((voyageId) => state.byId[<string>voyageId]);
}

export const selectFixtureVoyageStates = createSelector(selectVoyagesState, selectFixtureVoyageIds, projectVoyageStates);
export const selectCurrentFixtureVoyageStates = createSelector(selectVoyagesState, selectCurrentFixtureVoyageIds, projectVoyageStates);

export const selectCurrentFixtureVoyages = createSelector(selectCurrentFixtureVoyageStates, (voyageStates) => voyageStates.map((x) => x.voyage));

/**
 * Selects the current fixture voyages form values combined with the working voyages as a (legacy) voyage dto.
 */
export const selectCurrentFixtureCurrentVoyages = createSelector(selectCurrentFixtureVoyageStates, (voyageStates) => voyageStates.map((x) => x.workingVoyage));

export const selectVoyageTabIsValid = createSelector(selectCurrentFixtureLockedByCurrentUser, selectCurrentFixtureVoyageStates, (lockedByCurrentUser, voyageStates) =>
    lockedByCurrentUser
        ? voyageStates.every((voyageState) => {
              const voyageTabControls = R.omit(speedAndConsTabFormKeys)(voyageState.form.controls);

              return R.values(voyageTabControls).every((control) => control?.isValid);
          })
        : true
);

export const selectSpeedAndConsTabIsValid = createSelector(selectCurrentFixtureVoyageStates, (voyageStates) =>
    voyageStates.every((voyageState) => {
        const voyageTabControls = R.pick(speedAndConsTabFormKeys)(voyageState.form.controls);

        return R.values(voyageTabControls).every((control) => control?.isValid);
    })
);

export const selectCurrentFixtureVoyagesAnyValid = createSelector(selectCurrentFixtureVoyageStates, (voyageStates) => voyageStates.some((x) => x.form.isValid));

export const selectCurrentFixtureVoyagesAreDirty = createSelector(selectCurrentFixtureVoyageStates, (voyageStates) => voyageStates.some((x) => x.form.isDirty));

export const selectCurrentFixtureVoyagesPersisting = createSelector(selectCurrentFixtureVoyageStates, (voyageStates) =>
    voyageStates.some((x) => x.persistenceStatus === "persisting")
);

export const selectCurrentFixtureVoyageCreating = createSelector(selectCurrentFixtureState, (fixtureState) => fixtureState && fixtureState.voyageCreationPending);

export const selectCurrentFixtureVoyageNumbers = createSelector(selectCurrentFixture, selectCurrentFixtureVoyages, (fixture, voyages) =>
    fixture && voyages
        ? R.sortBy(R.prop("voyageNumber"), voyages).map(
              (x) =>
                  <VoyageNumber>{
                      voyageId: x.voyageId,
                      fixtureId: fixture.fixtureId,
                      voyageNumber: fixture.fixtureNumber.split("-")[0] + x.voyageNumberDisplay
                  }
          )
        : []
);

// TODO (NGRX Future Perf): Optimize voyage-cost calculator (chain selectors)
export const selectCurrentVoyageCost = createSelector(selectCurrentFixture, selectCurrentVoyage, (fixture, voyage) =>
    fixture && voyage ? calcVoyageCost(voyage, fixture.periods) : null
);

export const selectCurrentVoyageEtaEmailAudit = createSelector(selectCurrentVoyage, (voyage) => (voyage ? voyage.etaEmailGenerated : null));

export const selectCurrentVoyageFormInvalid = createSelector(selectCurrentVoyageForm, (voyage) => (voyage ? { isInvalid: voyage.isInvalid, isTouched: voyage.isTouched } : null));

export const selectCurrentVoyageExpandedSections = createSelector(selectCurrentVoyageState, (voyageState) => voyageState?.expandedSections);

export const selectCargoBerthActivities = createSelector(selectCurrentVoyage, (voyage) => voyage?.destinations.flatMap((d) => d.berths).flatMap((b) => b.cargoBerthActivities));

export const selectInitialBunkerTypesRemainingOnboard = createSelector(
    selectCargoBerthActivities,
    selectCurrentDeliveryBunkers,
    selectCurrentCpBunkersConsumption,
    (
        cargoBerthActivities: Nullable<CargoBerthActivity[]>,
        deliveryBunkers: Nullable<Bunker[]>,
        cpBunkersConsumption: Nullable<BunkerConsumption[]>,
        { activityId }: { activityId: ActivityId }
    ) => {
        const previousActivityIndex = (cargoBerthActivities?.findIndex((x) => x.id === activityId) ?? -1) - 1;
        if (previousActivityIndex >= 0) {
            return cargoBerthActivities?.[previousActivityIndex].bunkersRemainingOnboard.map((x) => x.type).filter((x) => !!x);
        }

        if (deliveryBunkers?.some((x) => x.bunkerType)) {
            return deliveryBunkers.map((x) => x.bunkerType).filter((x) => !!x);
        }

        if (cpBunkersConsumption?.some((x) => x.type)) {
            return cpBunkersConsumption.map((x) => x.type).filter((x) => !!x);
        }

        return [];
    }
);
