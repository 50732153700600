import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AppConfigService, AuthService } from "src/app/core";
import { SuggestionService } from "src/app/shared/components/autosuggest/suggestion.service";
import { CompanySearchResult } from "../../../fixture/shared/models/common/company-search-result.model";

@Injectable({
    providedIn: "root"
})
export class ExternalCompanySuggestionService implements SuggestionService<CompanySearchResult> {
    private contactsServiceUrl: string;

    constructor(private httpClient: HttpClient, private authService: AuthService, appConfigService: AppConfigService) {
        this.contactsServiceUrl = appConfigService.config.contactsServiceUrl;
    }

    getSuggestions(term: string): Observable<CompanySearchResult[]> {
        if (!term) {
            return of([]);
        }

        const url = `${this.contactsServiceUrl}/api/v1.0/sharinggroups/search?companyId=${this.authService.user.companyId}&searchTerm=${term}`;

        return this.httpClient.get<CompanySearchResult[]>(url);
    }
}
