import { UntypedFormArray, ValidatorFn } from "@angular/forms";

import { Company } from "../../contacts/models/company.model";
import { SharingGroupsViewModel } from "../../shared/models/sharing-group.model";

export interface AbstractControlWarn extends UntypedFormArray {
    warnings: any;
}

export class SharingGroupsEditorValidator {
    static validate(company: Company): ValidatorFn {
        return (
            control: AbstractControlWarn
        ): {
            [key: string]: boolean;
        } | null => {
            const sharingGroupsFormArray = control as UntypedFormArray;
            if (sharingGroupsFormArray) {
                // This does not return disabled values from the controls,
                // use getRawValue() for all values (including disabled ones)
                const allSharingGroups = sharingGroupsFormArray.getRawValue() as SharingGroupsViewModel[];
                control.warnings = undefined;
                // To share this fixture with this Company, select one or more sharing groups
                if (!allSharingGroups.some((x) => x.isSelected) && company.isNew) {
                    control.warnings = { newCompanyInstructions: true };
                    return null;
                }
                // At least one sharing group must be selected
                if (!allSharingGroups.some((x) => x.isSelected) && company.isCreator && allSharingGroups.length > 0) {
                    return { required: true };
                }
                // One sharing group must be selected for the current user
                if (allSharingGroups.filter((c) => c.isSelected && c.isCurrentUserPresent).length === 1) {
                    control.warnings = { lastSharingGroupForUser: true };
                    return null;
                }
            }
            return null;
        };
    }
}
