import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { SetValueAction } from "ngrx-forms";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { selectCurrentUser, User } from "@ops/state";

import { VoyageHttpService } from "../../../services";
import { Voyage } from "../../../shared/models";
import { selectCurrentFixtureId } from "../../fixture";
import { DestinationForm, DestinationId, FixtureFeatureState, FixtureId, FixturesState, VoyageId } from "../../model";
import { selectCurrentVoyageFormValue, selectCurrentVoyageId } from "../../voyage";
import { voyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
const VERIFY_ACTION_NAME = "[Voyage Form] Verify ETA";

export const verifyEtaAction = createAction(VERIFY_ACTION_NAME, props<{ destinationId: DestinationId }>());
export const verifyEtaSuccessAction = createAction(
    `${VERIFY_ACTION_NAME} Success`,
    props<{ fixtureId: FixtureId; voyageId: VoyageId; destinationId: DestinationId; date: string; user: User }>()
);
export const verifyEtaFailAction = createAction(`${VERIFY_ACTION_NAME} Fail`, props<{ fixtureId: FixtureId; voyageId: VoyageId; destinationId: DestinationId; error: Error }>());

export const markEtaVerifiedAction = createAction(
    "[Voyage Form] Mark ETA Verified",
    props<{ fixtureId: FixtureId; voyageId: VoyageId; destinationId: DestinationId; date: string; user: User }>()
);

/* REDUCERS */
export const verifyEtaSuccessReducer: On<FixturesState> = on(verifyEtaSuccessAction, markEtaVerifiedAction, (state, { type, voyageId, destinationId, date, user }) =>
    voyageStateReducer(state, voyageId, (voyageState) => {
        // Handle slow request, voyage being removed from state
        if (!voyageState) {
            return voyageState;
        }

        return {
            ...voyageState,
            voyage: type === verifyEtaSuccessAction.type ? updateEtaVerifiedOnVoyage(voyageState.voyage, destinationId, date, user) : voyageState.voyage,
            workingVoyage: voyageState.workingVoyage ? updateEtaVerifiedOnVoyage(voyageState.workingVoyage, destinationId, date, user) : voyageState.workingVoyage
        };
    })
);

/* FUNCTIONS */
export const updateEtaVerifiedOnVoyage = (voyage: Voyage, destinationId: DestinationId, date: string, user: User): Voyage => ({
    ...voyage,
    destinations: voyage.destinations.map((destination) => {
        if (destination.id === destinationId) {
            return {
                ...destination,
                etaLastVerifiedDate: date,
                etaLastVerifiedBy: user
            };
        }

        return destination;
    })
});

/* EFFECTS */
export const verifyEtaEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, voyageService: VoyageHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(verifyEtaAction),
            withLatestFrom(store.select(selectCurrentFixtureId), store.select(selectCurrentVoyageId), store.select(selectCurrentUser)),
            mergeMap(([{ destinationId }, fixtureId, voyageId, user]) =>
                voyageService.verifyEta(fixtureId, voyageId, destinationId).pipe(
                    map((date) =>
                        verifyEtaSuccessAction({
                            fixtureId: fixtureId,
                            voyageId: voyageId,
                            destinationId: destinationId,
                            date: date,
                            user: user
                        })
                    ),
                    catchError((err) =>
                        of(
                            verifyEtaFailAction({
                                fixtureId: fixtureId,
                                voyageId: voyageId,
                                destinationId: destinationId,
                                error: err
                            })
                        )
                    )
                )
            )
        )
    );

export const markEtaVerifiedOnEtaChangeEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<DestinationForm>>(SetValueAction.TYPE),
            map((action) => action.controlId.split(".")),
            filter(
                // eslint-disable-next-line no-magic-numbers
                (controlPath) => controlPath.length === 4 && controlPath[1] === "destinations" && (controlPath[3] === "eta" || controlPath[3] === "etaRange")
            ),
            withLatestFrom(store.select(selectCurrentFixtureId), store.select(selectCurrentVoyageFormValue), store.select(selectCurrentUser)),
            map(([[, , destinationIndex], fixtureId, { voyageId, destinations }, user]) =>
                markEtaVerifiedAction({
                    fixtureId: fixtureId,
                    voyageId: voyageId,
                    destinationId: destinations[Number(destinationIndex)].id,
                    date: DateTime.utc().toISO(),
                    user: user
                })
            )
        )
    );
