import { createAction, On, on, props } from "@ngrx/store";
import { moveArrayControl, updateGroup } from "ngrx-forms";

import { updateDestinationForm } from "../../destinations/shared";
import { BerthId, DestinationId, FixturesState } from "../../model";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const moveBerthUpAction = createAction("[Voyage Form] Move Berth Up", props<{ destinationId: DestinationId; berthId: BerthId }>());
export const moveBerthDownAction = createAction("[Voyage Form] Move Berth Down", props<{ destinationId: DestinationId; berthId: BerthId }>());

/* REDUCERS */
const moveBerth = (state: FixturesState, destinationId: DestinationId, berthId: BerthId, offset: number) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const destination = voyageState.form.controls.destinations.controls.find((d) => d.value.id === destinationId);
        if (!destination) {
            return voyageState;
        }
        const berths = destination.controls.berths;
        const fromIndex = berths.value.findIndex((b) => b.id === berthId);
        const toIndex = fromIndex + offset;

        if (toIndex < 0 || toIndex > berths.value.length - 1) {
            return voyageState;
        }

        return {
            ...voyageState,
            form: updateDestinationForm({ destinationId }, (destinationForm) =>
                updateGroup(destinationForm, {
                    berths: moveArrayControl(fromIndex, toIndex)
                })
            )(voyageState.form)
        };
    });

export const moveBerthUpReducer: On<FixturesState> = on(moveBerthUpAction, (state, { destinationId, berthId }) => moveBerth(state, destinationId, berthId, -1));

export const moveBerthDownReducer: On<FixturesState> = on(moveBerthDownAction, (state, { destinationId, berthId }) => moveBerth(state, destinationId, berthId, 1));
