import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { DateTime } from "luxon";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { AppInsightsService } from "../../core/app-insights.service";
import { Notification, NotificationType } from "./notification.model";
import { NotificationService } from "./notification.service";

@Component({
    selector: "ops-notifications",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit {
    static componentName = "NotificationComponent";

    private _notificationIconMappings: any = {
        [NotificationType.Error]: "icon--warning",
        [NotificationType.Warn]: "icon--flag",
        [NotificationType.Success]: "icon--done",
        [NotificationType.Info]: "icon--lightbulb-outline",
        [NotificationType.VersionUpdateMajor]: "icon--new",
        [NotificationType.VersionUpdateMinor]: "icon--new"
    };

    notificationIndex = 0;
    notifications$: Observable<Notification[]>;
    blocking$: Observable<boolean>;
    majorUpdateVersion$: Observable<boolean>;

    constructor(private notificationService: NotificationService, @Inject("Window") private window: Window, private appInsights: AppInsightsService) {}

    ngOnInit() {
        this.notifications$ = this.notificationService.notifications$.pipe(
            tap((data) => {
                this.notificationIndex = data.length - 1;
            })
        );
        this.blocking$ = this.notifications$.pipe(map((x) => x.length > 0 && !x[x.length - 1].dismissible && x[x.length - 1].type !== NotificationType.VersionUpdateMajor));
        this.majorUpdateVersion$ = this.notifications$.pipe(map((x) => x.length > 0 && x[x.length - 1].type === NotificationType.VersionUpdateMajor));
    }

    remove(notification: Notification) {
        switch (notification.type) {
            case NotificationType.VersionUpdateMinor:
                const dismissedInSeconds = DateTime.utc().diff(notification.createdDateTime, "seconds").seconds;
                this.appInsights.logEvent(`Version Update Notification Dismissed After ${dismissedInSeconds} Seconds`);
                break;
        }
        this.notificationService.remove(notification);
    }

    increaseIndex() {
        this.notificationIndex++;
    }

    decreaseIndex() {
        this.notificationIndex--;
    }

    getColourClass(type: NotificationType) {
        return `notification-${NotificationType[type].toLowerCase()}`;
    }

    getIconClass(type: NotificationType) {
        return this._notificationIconMappings[type];
    }

    notificationBarClicked(notification: Notification) {
        switch (notification.type) {
            case NotificationType.VersionUpdateMajor:
            case NotificationType.VersionUpdateMinor:
                const dismissedInSeconds = DateTime.utc().diff(notification.createdDateTime, "seconds").seconds;
                this.appInsights.logEvent(`Version Update Notification Clicked After ${dismissedInSeconds} Seconds`);
                this.window.location.reload();
                break;
        }
    }
}
