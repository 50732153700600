import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { UserManager } from "oidc-client";
import { Observable, of } from "rxjs";

import { User } from "../../state";
import { AppConfigService } from "../app-config.service";
import { InitialParamsService } from "../initial-params.service";
import { SystemUser } from "../system-user.model";
import { WindowRefService } from "../window-ref.service";

export const LOGIN_CALLBACK = "login-callback";
export const SILENT_REFRESH = "silent-refresh";

const ROLE_PREFIX = "Ops.";

export type ConfigurationRole = "Actions" | "AddFixture" | "Admin" | "Board" | "Coa" | "DuplicateFixtures" | "TCVoyages" | "ProfitAndLoss" | "SpeedAndConsumption" | "Laytime";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private userManager: UserManager;

    systemUser: SystemUser;
    user: User;
    token: string;

    constructor(
        private windowRef: WindowRefService,
        private http: HttpClient,
        private appConfigService: AppConfigService,
        private injector: Injector,
        private initialParamsService: InitialParamsService
    ) {
        if (windowRef && appConfigService) {
            this.userManager = this.createUserManager();
            this.userManager.events.addUserLoaded((user) => {
                this.token = user.access_token;
            });
        }
    }

    isAuthenticated(): Promise<boolean> {
        return Promise.resolve(!!this.systemUser);
    }

    hasRole(role: ConfigurationRole): Observable<boolean> {
        return of(this.systemUser?.configurationRoles.includes(`${ROLE_PREFIX}${role}`) ?? false);
    }

    setupAuthentication(): Promise<void> {
        switch (this.getCurrentPage()) {
            case `/${LOGIN_CALLBACK}`:
                return this.userManager.signinRedirectCallback().then((user) =>
                    this.getSystemUser().then(() => {
                        this.initialParamsService.setQueryParams(user.state.queryParams);
                        this.navigateByUrl(user.state.currentPage);
                    })
                );
            case `/${SILENT_REFRESH}`:
                this.userManager.signinSilentCallback();
                return this.getEmptyPromise();
            default:
                this.redirectToLogin();
                return this.getEmptyPromise();
        }
    }

    redirectToLogin(): void {
        const currentPage = this.getCurrentPage();
        const queryParams = Object.fromEntries(new URLSearchParams(this.getSearchParams()).entries());
        this.userManager.signinRedirect({ state: { currentPage, queryParams } });
    }

    logout(): void {
        this.userManager.signoutRedirect();
    }

    private createUserManager(): UserManager {
        const origin = this.windowRef.nativeWindow.location.origin;
        const settings = {
            authority: this.appConfigService.config.authorityUrl,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            client_id: "sea-ops-web",
            // eslint-disable-next-line @typescript-eslint/naming-convention
            redirect_uri: `${origin}/${LOGIN_CALLBACK}`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            post_logout_redirect_uri: origin,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            silent_redirect_uri: `${origin}/${SILENT_REFRESH}`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            response_type: "code",
            scope: "openid profile email",
            loadUserInfo: true,
            automaticSilentRenew: true,
            revokeAccessTokenOnSignout: true,
            filterProtocolClaims: false,
            monitorSession: true,
            checkSessionInterval: 2000
        };
        return new UserManager(settings);
    }

    private getEmptyPromise(): Promise<void> {
        return new Promise<void>(() => {});
    }

    private getSearchParams() {
        return this.windowRef.nativeWindow.location.search;
    }

    private getCurrentPage() {
        return this.windowRef.nativeWindow.location.pathname;
    }

    private navigateByUrl(url: string) {
        const router = this.injector.get(Router);
        router.navigateByUrl(url, { replaceUrl: true });
    }

    private getSystemUser(): Promise<SystemUser | void> {
        return this.getCurrentUser()
            .toPromise()
            .then((data) => {
                this.systemUser = data;
                this.user = {
                    userId: data.systemUserId,
                    userCode: data.samAccountName,
                    fullName: data.name,
                    department: data.department,
                    companyName: data.companyName,
                    companyId: data.companyId,
                    timeZone: data.timeZone,
                    sharingGroups: data.sharingGroups,
                    configurationRoles: data.configurationRoles
                };

                return data;
            });
    }

    private getCurrentUser(): Observable<SystemUser> {
        const gatewayUrl: string = this.appConfigService.config.apiGatewayUrl;
        return this.http.get<SystemUser>(`${gatewayUrl}/api/v1.0/user`);
    }
}
