import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { CoaHttpService } from "../../../../coa/services/coa-http.service";
import { selectCurrentCoaId } from "../../coa";
import { coaStateReducer, currentCoaStateReducer } from "../../coa/reducer";
import { CoaFeatureState, CoaId, CoasState, Company } from "../../model";

/* ACTIONS */
const REMOVE_COMPANY_ACTION_NAME = "[Coa Contacts Panel] Remove Company";
export const removeCompanyAction = createAction(REMOVE_COMPANY_ACTION_NAME, props<{ company: Company }>());
export const removeCompanySuccessAction = createAction(`${REMOVE_COMPANY_ACTION_NAME} Success`, props<{ coaId: CoaId; company: Company }>());
export const removeCompanyFailAction = createAction(`${REMOVE_COMPANY_ACTION_NAME} Fail`, props<{ coaId: CoaId; company: Company; error: Error }>());

/* REDUCERS */
export const removeCompanyReducer: On<CoasState> = on(removeCompanyAction, (state, { company }) =>
    currentCoaStateReducer(state, (coaState) => ({
        ...coaState,
        coa: {
            ...coaState.coa,
            contacts: coaState.coa.contacts.filter((c) => !(c.accountId === company.accountId && c.role === company.role))
        }
    }))
);

export const removeCompanyFailReducer: On<CoasState> = on(removeCompanyFailAction, (state, { coaId, company, error }) =>
    coaStateReducer(state, coaId, (coaState) => ({
        ...coaState,
        coa: {
            ...coaState.coa,
            contacts: [...coaState.coa.contacts, company]
        },
        erroredItems: [...(coaState.erroredItems || []), { itemType: "contacts", id: company.accountId, error }]
    }))
);

/* EFFECTS */
export const removeCompanyEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, coaService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeCompanyAction),
            withLatestFrom(store.select(selectCurrentCoaId)),
            mergeMap(([action, coaId]) =>
                coaService.removeCompany(coaId, action.company).pipe(
                    map(() => removeCompanySuccessAction({ coaId, company: action.company })),
                    catchError((err) => of(removeCompanyFailAction({ ...action, coaId, error: err })))
                )
            )
        )
    );
