import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from "@angular/core";

import { LiftingHistoryEvent } from "./lifting-history";

@Component({
    selector: "ops-lifting-history-event",
    templateUrl: "./lifting-history-event.component.html",
    styleUrls: ["./lifting-history-event.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiftingHistoryEventComponent {
    @Input() historyEvent: LiftingHistoryEvent;

    @ViewChild("headerWrapper") headerWrapper: ElementRef<HTMLDivElement>;

    get statusClass() {
        return this.historyEvent.status?.replace(" ", "-").toLowerCase();
    }

    formatValue(value: string | undefined) {
        return value && value.length ? value : "-";
    }
}
