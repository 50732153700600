import { Boxed } from "ngrx-forms";

import { User as AppUser } from "@ops/state";

import { User } from "./user";

/* ENUMS */
export const nominationTaskTypes = ["RecurringDate", "FixedDate", "LiftingLaycan", "CargoNomination", "VesselNomination"] as const;
export type NominationTaskType = typeof nominationTaskTypes[number];

export const nominationTaskFormTypes = ["RecurringDate", "FixedDate", "EventBased"] as const;
export type NominationTaskFormType = typeof nominationTaskFormTypes[number];

export const nominationTaskResponsibleCompanies = ["Unspecified", "Owner", "Charterer"] as const;
export type NominationTaskResponsibleCompanyType = typeof nominationTaskResponsibleCompanies[number];

export const intervals = ["Daily", "Weekly", "Monthly"] as const;
export type RecurringIntervalType = typeof intervals[number];

export const priorities = ["Low", "Medium", "High"] as const;
export type NominationTaskPriority = typeof priorities[number];

export const nominationTaskMonthSeriesModes = ["AddByMonth", "AddByDayOfMonth"] as const;
export type NominationTaskMonthSeriesMode = typeof nominationTaskMonthSeriesModes[number];

export const eventBasedBindingPositions = ["Start", "End"] as const;
export type EventBasedBindingPosition = typeof eventBasedBindingPositions[number];

export const eventBasedBindingStages = ["Initial", "Final"] as const;
export type EventBasedBindingStage = typeof eventBasedBindingStages[number];

export const eventBasedBindingTypes = [
    "Start of Laycan",
    "Initial Cargo Nomination",
    "Final Cargo Confirmation",
    "Initial Vessel Nomination",
    "Final Vessel Confirmation"
] as const;
export type EventBasedBindingType = typeof eventBasedBindingTypes[number];

/* MODELS */
export type NominationTaskId = string;

export type NominationTask = Readonly<{
    nominationTaskId: NominationTaskId;
    name: string;
    priority: NominationTaskPriority;
    binding: NominationTaskBinding;
    assignedUsers: ReadonlyArray<User>;
    responsible?: NominationTaskResponsibleCompanyType;
    notifications: ReadonlyArray<NominationTaskNotification>;
}>;

export type NominationTaskBinding = FixedDateBinding | RecurringDateBinding | LiftingLaycanBinding | CargoNominationBinding | VesselNominationBinding;

type BindingTrigger = Readonly<{
    trigger: NominationTaskType;
}>;

export type FixedDateBinding = BindingTrigger &
    Readonly<{
        dueDate: string;
    }>;

export type RecurringDateBinding = BindingTrigger &
    Readonly<{
        startDate: string;
        endDate: string;
        intervalType?: RecurringIntervalType;
        monthSeriesMode?: NominationTaskMonthSeriesMode;
    }>;

export type NominationTaskNotification = Readonly<{
    recipients: ReadonlyArray<User>;
    offset: number;
    timeOfDay: string;
}>;

type EventBasedBinding = Readonly<{
    offset: number;
}>;

export type LiftingLaycanBinding = BindingTrigger &
    EventBasedBinding &
    Readonly<{
        position: EventBasedBindingPosition;
    }>;

export type CargoNominationBinding = BindingTrigger &
    EventBasedBinding &
    Readonly<{
        stage: EventBasedBindingStage;
    }>;

export type VesselNominationBinding = BindingTrigger &
    EventBasedBinding &
    Readonly<{
        stage: EventBasedBindingStage;
    }>;

export type NominationTaskForm = Readonly<{
    nominationTaskId: NominationTaskId;
    name: string;
    dueDate: string;
    responsible?: NominationTaskResponsibleCompanyType;
    assignedUsers: Boxed<ReadonlyArray<AppUser>>;
    reminderDaysBefore?: number;
    recurrenceDayOfMonth: number;
    recurrenceEndDate: string;
    type: NominationTaskFormType;
    nextNotificationDate?: string;
    nextDueDate?: string;
    eventType?: EventBasedBindingType;
    eventOffset?: number;
    eventOffsetIsPositive: boolean;
}>;

export type NominationTaskListItem = Readonly<{
    nominationTaskId: NominationTaskId;
    name: string;
    dueDate: string;
    isVisible: boolean;
    error?: Error;
    assignedUsers: string;
    responsible?: NominationTaskResponsibleCompanyType;
    nextNotificationDate?: string;
    type: string;
    recurrenceEndDate?: string;
    eventDetails?: string;
    eventReminderDescription?: string;
}>;

export type UpdateNominationTask = Readonly<{
    name: string;
    priority: NominationTaskPriority;
    binding: NominationTaskBinding;
    assignedUserIds: ReadonlyArray<number>;
    responsible?: NominationTaskResponsibleCompanyType;
    notifications: ReadonlyArray<UpdateNominationTaskNotification>;
}>;

type UpdateNominationTaskNotification = Readonly<{
    recipientIds: ReadonlyArray<number>;
    offset: number;
    timeOfDay: string;
}>;

export type AddNominationTask = UpdateNominationTask &
    Readonly<{
        nominationTaskId: NominationTaskId;
    }>;
