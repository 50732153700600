import { createAction, on, On, props } from "@ngrx/store";

/* ACTIONS */
import { RemoveRequest, removeRequestReducer } from "@ops/state";

import { LtcState } from "../../model";

export const removeLaytimeCalculationGridRequestAction = createAction("[Laytime Calculation Grid] Remove Request", props<RemoveRequest>());

/* REDUCERS */
export const removeLaytimeCalculationGridRequestReducer: On<LtcState> = on(removeLaytimeCalculationGridRequestAction, (state, action) =>
    removeRequestReducer(state, action, "grid")
);
