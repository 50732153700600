import { createSelector } from "@ngrx/store";
import { DateTime, Duration } from "luxon";
import { FormGroupState } from "ngrx-forms";
import { v4 as uuid } from "uuid";

import { isNullOrUndefined, diffInOffsets, zeroDuration } from "../../../shared/utils";
import { getEditingId } from "../../../state/forms-reducer";
import { selectCurrentDestinationActivityForm, selectCurrentVoyageAssociatedCargoIds } from "../activities";
import { selectCurrentVoyageCargoItems } from "../cargoes";
import { selectCurrentVoyageDestinationLocationTimezone } from "../destinations/selectors";
import { selectCurrentFixtureDivision, selectCurrentFixtureLockedByCurrentUser } from "../fixture";
import { LaytimeEventForm, LaytimeEventId } from "../model";
import { getLaytimeEventIntervalParts, IntervalPart, sumLaytimeEventIntervalParts } from "./laytime-calculation";

export declare type LaytimeEventState = Readonly<{
    laytimeEventId: LaytimeEventId;
    form: FormGroupState<LaytimeEventForm>;
    intervalPart?: IntervalPart;
    elapsedHours?: number;
    cargoName?: string;
    isEditing: boolean;
    offsetDiffWithPrevEvent?: Duration;
}>;

export const selectCurrentVoyageLaytimeEventsForm = createSelector(selectCurrentDestinationActivityForm, (activityForm) => activityForm?.controls.laytimeEvents);

export const selectCurrentVoyageLaytimeEventForms = createSelector(selectCurrentVoyageLaytimeEventsForm, (laytimeEventsForm) => laytimeEventsForm?.controls);

export const selectCurrentVoyageLaytimeEventFormValues = createSelector(selectCurrentVoyageLaytimeEventForms, (laytimeEventForms) =>
    laytimeEventForms ? laytimeEventForms.map((x) => x.value) : null
);

export const selectCurrentVoyageLaytimeEventIntervalParts = createSelector(selectCurrentVoyageLaytimeEventFormValues, (laytimeEvents) =>
    laytimeEvents ? getLaytimeEventIntervalParts(laytimeEvents) : null
);

export const selectCurrentVoyageActivityTotalLaytime = createSelector(selectCurrentVoyageLaytimeEventIntervalParts, sumLaytimeEventIntervalParts);

/**
 * Returns true if the laytime events are considered to be ordered - that is, nulls last and dates in order, no gaps
 */
export const selectCurrentVoyageActivityLaytimeEventsOrdered = createSelector(selectCurrentVoyageLaytimeEventIntervalParts, (intervalParts) =>
    intervalParts !== null ? intervalParts.every((x) => x[1] !== null) : null
);

export const selectCurrentVoyageLaytimeEvents = createSelector(
    selectCurrentVoyageLaytimeEventsForm,
    selectCurrentVoyageLaytimeEventIntervalParts,
    selectCurrentFixtureLockedByCurrentUser,
    selectCurrentFixtureDivision,
    selectCurrentVoyageCargoItems,
    selectCurrentVoyageDestinationLocationTimezone,
    (laytimeEventsForm, intervalParts, editing, division, cargoes, zone) => {
        if (laytimeEventsForm === null || intervalParts === null) {
            return null;
        }

        const newId = uuid();

        return laytimeEventsForm.controls.map((form: FormGroupState<LaytimeEventForm>, i: number, arr: ReadonlyArray<FormGroupState<LaytimeEventForm>>) => {
            const prevEvent = arr[i - 1]?.value;
            const currEvent = form.value;
            const prevEventDate = prevEvent?.eventDate ? DateTime.fromISO(prevEvent?.eventDate, { zone }) : null;
            const currEventDate = currEvent?.eventDate ? DateTime.fromISO(currEvent?.eventDate, { zone }) : null;

            const intervalPart = intervalParts[i];
            const cargoName = isNullOrUndefined(currEvent.cargoId) ? null : cargoes.find((c) => c.cargoId === currEvent.cargoId)?.name;
            return <LaytimeEventState>{
                laytimeEventId: currEvent.laytimeEventId,
                form,
                intervalPart: intervalPart ? intervalPart[0] : null,
                elapsedHours: intervalPart ? intervalPart[1] : null,
                cargoName,
                isEditing: getEditingId(laytimeEventsForm) === form.id || currEvent.laytimeEventId === newId,
                offsetDiffWithPrevEvent: diffInOffsets(prevEventDate, currEventDate) || zeroDuration()
            };
        });
    }
);

export const selectCurrentVoyageAssociatedCargoDropdownItems = createSelector(
    selectCurrentVoyageCargoItems,
    selectCurrentVoyageAssociatedCargoIds,
    (cargoes, associatedCargoIds) => {
        if (cargoes === null || associatedCargoIds === null) {
            return null;
        }

        return cargoes.filter((cargo) => associatedCargoIds.includes(cargo.cargoId));
    }
);
export { IntervalPart };
