import { MaritimeDateRange } from "@maritech/maritime-date";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { DateTime } from "luxon";
import * as moment from "moment-timezone";
import { DateRange } from "./date-range";

/**
 * @deprecated Use utils/date-utils, refactor methods to use Luxon where missing.
 */
export class DateUtilities {
    static get secondsInADay() {
        return 86400;
    }

    /**
     * @deprecated use luxon's DateTime
     */
    static parseISODate(isoDate: string): Date {
        if (isoDate) {
            const m = DateTime.fromISO(isoDate);
            if (m.isValid) {
                return m.toJSDate();
            }
        }
        return null;
    }

    static toNgbTimeStruct(value: string): NgbTimeStruct {
        if (!value) {
            return null;
        }
        const split = value.split(":");
        return {
            hour: parseInt(split[0], 10),
            minute: parseInt(split[1], 10),
            second: parseInt(split[2], 10)
        };
    }

    static toStringTime(time: NgbTimeStruct): string {
        if (!time) {
            return null;
        }
        return `${this.pad(time.hour)}:${this.pad(time.minute)}:${this.pad(time.second)}`;
    }

    private static pad(i: number): string {
        return i < 10 ? `0${i}` : `${i}`;
    }

    static formatHours(totalHours: number): string {
        if (!totalHours) {
            return "";
        }
        return `${this.pad(this.getDays(totalHours))}d ${this.pad(this.getHours(totalHours))}h ${this.pad(this.getMinutes(totalHours))}m`;
    }

    static getDays(totalHours: number): number {
        if (totalHours) {
            return Math.floor(totalHours / 24);
        }
        return 0;
    }

    static getHours(totalHours: number): number {
        if (totalHours) {
            return Math.floor(totalHours % 24);
        }
        return 0;
    }

    static getMinutes(totalHours: number): number {
        if (totalHours) {
            const hours = Math.floor(totalHours % 24);
            return Math.round(60 * ((totalHours % 24) - hours));
        }
        return 0;
    }

    static dateToISOString(date: Date): string {
        if (date && date instanceof Date) {
            const m = moment(date);

            if (m.isValid()) {
                return m.format();
            }
        }

        return null;
    }

    static notEquals(a: Date, b: Date): boolean {
        if (a && b) {
            return a.getTime() !== b.getTime();
        } else {
            return (a && !b) || (b && !a);
        }
    }

    static calculateDurationInDays = (to: Date, from: Date): number => {
        if (to && from) {
            const durationInSeconds = moment(to).diff(from, "seconds", true);
            return durationInSeconds / DateUtilities.secondsInADay;
        }
        return null;
    };

    static getDifferenceInHours(date1: Date, date2: Date): number {
        const start = moment(date1);
        const end = moment(date2);
        const duration = moment.duration(start.diff(end));
        return duration.asHours();
    }

    static getDurationFromMinutes(hours: number): moment.Duration {
        return moment.duration(hours, "minutes");
    }

    static formatISODate(isoDate: string, timeZone: string, format: string): string {
        const date = parseISODate(isoDate);
        return !!date
            ? moment(date)
                  .tz(timeZone)
                  .format(format)
            : "";
    }

    static shiftTimeZone(dateRange: DateRange, fromTimeZone: string, toTimeZone: string): DateRange;
    static shiftTimeZone(date: Date, fromTimeZone: string, toTimeZone: string): Date;
    static shiftTimeZone(dateOrRange: Date | DateRange, fromTimeZone: string, toTimeZone: string): Date | DateRange {
        function shiftDate(date: Date) {
            if (!date || !fromTimeZone || !toTimeZone) {
                return null;
            }

            const initialPointInTime = moment(date).tz(fromTimeZone);

            if (!initialPointInTime.isValid()) {
                return null;
            }

            const s = {
                year: initialPointInTime.year(),
                month: initialPointInTime.month(),
                day: initialPointInTime.date(),
                hours: initialPointInTime.hour(),
                minutes: initialPointInTime.minute()
            };

            const newDate = moment.tz(s, toTimeZone);

            if (!newDate.isValid()) {
                return null;
            }

            return newDate.toDate();
        }

        if (dateOrRange instanceof DateRange) {
            return new DateRange(shiftDate(dateOrRange.from), shiftDate(dateOrRange.to));
        }

        return shiftDate(<Date>dateOrRange);
    }

    static convertTimeZone(isoDate: string, toTimeZone: string, format: string): string {
        if (!isoDate || isoDate.length === 0) {
            return "";
        }
        const date = DateUtilities.parseISODate(isoDate);

        const m = moment.tz(date, toTimeZone);

        if (m.isValid()) {
            return m.format(format);
        }
    }

    static getCurrentUtcDate(): moment.Moment {
        return moment.utc();
    }
}

export function convertTimeZone(isoDate: string, toTimeZone: string, format: string): string {
    return DateUtilities.convertTimeZone(isoDate, toTimeZone, format);
}

export function parseISODate(isoDate: string): Date {
    return DateUtilities.parseISODate(isoDate);
}

export function toNgbTimeStruct(time: string): NgbTimeStruct {
    return DateUtilities.toNgbTimeStruct(time);
}

export function toStringTime(time: NgbTimeStruct): string {
    return DateUtilities.toStringTime(time);
}

export function formatHours(totalHours: number): string {
    return DateUtilities.formatHours(totalHours);
}

export function dateToISOString(date: Date): string {
    return DateUtilities.dateToISOString(date);
}

/**
 * @deprecated
 */
export function parseISODateRange(isoDateRange: { from?: string; to?: string }): DateRange {
    return isoDateRange ? new DateRange(parseISODate(isoDateRange.from), parseISODate(isoDateRange.to)) : null;
}

export function dateRangeToISOString(dateRange: DateRange | MaritimeDateRange): { from?: string; to?: string } {
    if (dateRange) {
        if ("from" in dateRange) {
            return { from: dateRange.from.toISOString(), to: dateRange.to.toISOString() };
        } else {
            return { from: dateRange.start.toISO(), to: dateRange.end.toISO() };
        }
    }

    return null;
}

export const toMaritimeDateRange = (isoDateRange: { from?: string; to?: string }) =>
    isoDateRange && (isoDateRange.from || isoDateRange.to) ? MaritimeDateRange.exact(isoDateRange.from, isoDateRange.to) : null;
export const fromMaritimeDateRange = (dateRange: MaritimeDateRange) =>
    dateRange ? { from: dateRange.start?.toISO(), to: dateRange.end?.toISO() } : null;

export function sortDates(dates: Date[], asc: boolean = true): Date[] {
    if (asc) {
        return dates.sort(function(date1: Date, date2: Date) {
            if (date1 < date2) {
                return -1;
            }
            if (date1 > date2) {
                return 1;
            }
            return 0;
        });
    }

    if (!asc) {
        return dates.sort(function(date1: Date, date2: Date) {
            if (date2 < date1) {
                return -1;
            }
            if (date2 > date1) {
                return 1;
            }
            return 0;
        });
    }
}

export function formatDateRange(dateRange: { from?: string; to?: string }, format: string, timeZone?: string): string {
    if (dateRange) {
        const { from, to } = dateRange;
        let fromMoment = from ? moment(from) : null;
        let toMoment = to ? moment(to) : null;

        if (timeZone) {
            fromMoment = fromMoment.tz(timeZone);
            toMoment = toMoment.tz(timeZone);
        }

        const fromFormatted = fromMoment.format(format);
        const toFormatted = toMoment.format(format);

        return `${fromFormatted} - ${toFormatted}`;
    }

    return "";
}

export function isWithinOuterRange(
    fromDate: Date,
    toDate: Date,
    outerRangeFromId: number,
    outerRangeToId: number,
    outerRangeFromTime: string,
    outerRangeToTime: string,
    timeZone: string
): boolean {
    timeZone = timeZone || "utc";

    const momentFromDate = moment(fromDate).tz(timeZone);
    const momentToDate = moment(toDate).tz(timeZone);

    let dayFromId = momentFromDate.day();
    let dayToId = momentToDate.day();

    if (outerRangeFromId > outerRangeToId) {
        outerRangeToId += 7;
        dayFromId = dayFromId < outerRangeFromId ? dayFromId + 7 : dayFromId;
        dayToId = dayToId < outerRangeFromId ? dayToId + 7 : dayToId;
    }

    let isFromDayPassed = false;
    let isToDayPassed = false;

    for (let i = outerRangeFromId; i <= outerRangeToId; i++) {
        isFromDayPassed = isFromDayPassed || dayFromId === i;
        isToDayPassed = isToDayPassed || dayToId === i;
    }

    if (dayFromId === outerRangeFromId) {
        const fromTime = toNgbTimeStruct(outerRangeFromTime);
        const rangeFromDate = moment(fromDate)
            .tz(timeZone)
            .set({ hour: fromTime.hour, minute: fromTime.minute, second: fromTime.second });
        isFromDayPassed = momentFromDate > rangeFromDate;
    }

    if (dayToId === outerRangeToId || dayToId === outerRangeToId - 7) {
        const toTime = toNgbTimeStruct(outerRangeToTime);
        const rangeToDate = moment(toDate)
            .tz(timeZone)
            .set({ hour: toTime.hour, minute: toTime.minute, second: toTime.second });
        isToDayPassed = momentToDate < rangeToDate;
    }

    const isRangeLessThanAWeek = momentToDate.diff(momentFromDate, "days") <= outerRangeToId - outerRangeFromId;

    return isFromDayPassed && isToDayPassed && isRangeLessThanAWeek && momentToDate >= momentFromDate;
}

export function isRangeOverWeekend(fromDate: Date, toDate: Date, timeZone: string): boolean {
    if (!fromDate || !toDate) {
        return false;
    }
    timeZone = timeZone || "utc";
    const dayFromId = moment(fromDate)
        .tz(timeZone)
        .day();
    const dayToId = moment(toDate)
        .tz(timeZone)
        .day();
    const weekendDayIds = [0, 6];

    const durationInSeconds = moment(fromDate).diff(toDate, "seconds", true);
    const eventRangeInDays = durationInSeconds / DateUtilities.secondsInADay;

    return weekendDayIds.includes(dayFromId) && weekendDayIds.includes(dayToId) && eventRangeInDays <= 2;
}
