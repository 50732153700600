import { createAction, on, On, props } from "@ngrx/store";
import { activityExpandedKey, ActivityId, BerthId, DestinationId, FixturesState } from "../../model";
import { currentVoyageStateExpansionReducer } from "../../voyage/reducer";

/* ACTIONS */
export const expandActivityAction = createAction(
    "[Voyage Form] Expand Activity",
    props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId }>()
);
export const collapseActivityAction = createAction(
    "[Voyage Form] Collapse Activity",
    props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId }>()
);

/* REDUCERS */
export const expandActivityReducer: On<FixturesState> = on(expandActivityAction, (state, action) =>
    currentVoyageStateExpansionReducer(state, activityExpandedKey(action.destinationId, action.berthId, action.activityId), true)
);
export const collapseActivityReducer: On<FixturesState> = on(collapseActivityAction, (state, action) =>
    currentVoyageStateExpansionReducer(state, activityExpandedKey(action.destinationId, action.berthId, action.activityId), false)
);
