import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { markAsTouched } from "ngrx-forms";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
// eslint-disable-next-line no-restricted-imports
import { CoaHttpService } from "src/app/coa/services/coa-http.service";

import { selectCurrentUser } from "@ops/state";

import { selectCurrentCoa } from "../../coa";
import { coaStateReducer, currentCoaStateReducer } from "../../coa/reducer";
import { AddNominationTask, CoaFeatureState, CoaId, CoasState, getCoaState, UpdateNominationTask, User } from "../../model";
import { NominationTask } from "../../model";
import { selectNominationTaskForm } from "../selectors";
import { formToAddNominationTask, formToNominationTask, formToUpdateNominationTask } from "../utils";

/* ACTIONS */
const SAVE_NOMINATIONTASK_ACTION_NAME = "[Coa Nomination Task Form] Save Nomination Task";
const SAVE_NOMINATIONTASK_SUCCESS_ACTION_NAME = `${SAVE_NOMINATIONTASK_ACTION_NAME} Success`;
const SAVE_NEW_NOMINATIONTASK_ACTION_NAME = "[Coa Nomination Task Form] Save New Nomination Task";
export const saveNominationTaskAction = createAction(SAVE_NOMINATIONTASK_ACTION_NAME);
export const saveNewNominationTaskAction = createAction(SAVE_NEW_NOMINATIONTASK_ACTION_NAME);
export const saveNominationTaskSuccessAction = createAction(
    SAVE_NOMINATIONTASK_SUCCESS_ACTION_NAME,
    props<{ coaId: CoaId; user: User; updateNominationTask: UpdateNominationTask }>()
);
export const saveNewNominationTaskSuccessAction = createAction(
    `${SAVE_NEW_NOMINATIONTASK_ACTION_NAME} Success`,
    props<{ coaId: CoaId; user: User; addNominationTask: AddNominationTask }>()
);
export const saveNominationTaskFailAction = createAction(`${SAVE_NOMINATIONTASK_ACTION_NAME} Fail`, props<{ coaId: CoaId; error: Error }>());

/* REDUCERS */
export const saveNominationTaskReducer: On<CoasState> = on(saveNominationTaskAction, saveNewNominationTaskAction, (state) =>
    currentCoaStateReducer(state, (coaState) => {
        const form = markAsTouched(coaState.nominationTaskForm);
        return { ...coaState, nominationTaskForm: form, nominationTaskFormSaveStatus: form.isValid ? "persisting" : "invalid" };
    })
);

export const saveNominationTaskFailReducer: On<CoasState> = on(saveNominationTaskFailAction, (state, { coaId }) =>
    coaStateReducer(state, coaId, (coaState) => ({ ...coaState, nominationTaskFormSaveStatus: "failed" }))
);

export const saveNominationTaskSuccessReducer: On<CoasState> = on(saveNominationTaskSuccessAction, saveNewNominationTaskSuccessAction, (state, action) => {
    const coa = getCoaState(state, action.coaId);
    const form = coa?.nominationTaskForm;

    if (!form) {
        return state;
    }

    const coaTasks = coa.coa.nominationTasks;
    let updatedTasks: NominationTask[] = [];
    if (action.type === SAVE_NOMINATIONTASK_SUCCESS_ACTION_NAME) {
        const existingTask = coaTasks.find((c) => c.nominationTaskId === form.value.nominationTaskId);
        const existingIndex = coaTasks.indexOf(existingTask);
        updatedTasks = [...coaTasks.slice(0, existingIndex), formToNominationTask(form.value, action.user), ...coaTasks.slice(existingIndex + 1)];
    } else {
        updatedTasks = [...coaTasks, formToNominationTask(coa.nominationTaskForm.value, action.user)];
    }

    return coaStateReducer(state, action.coaId, (coaState) => ({
        ...coaState,
        coa: { ...coaState.coa, nominationTasks: updatedTasks },
        nominationTaskForm: null,
        nominationTaskFormSaveStatus: "persisted"
    }));
});

/* EFFECTS */
export const saveNominationTaskEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, coaService: CoaHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveNominationTaskAction, saveNewNominationTaskAction),
            withLatestFrom(store.select(selectCurrentCoa), store.select(selectNominationTaskForm), store.select(selectCurrentUser)),
            filter(([, , form]) => form.isValid),
            mergeMap(([action, { coaId }, form, { userId, userCode, fullName }]) => {
                const user: User = { name: fullName, userCode, userId };
                const updateTask = formToUpdateNominationTask(form.value, userId);
                const addTask = formToAddNominationTask(form.value, userId);
                return action.type === SAVE_NOMINATIONTASK_ACTION_NAME
                    ? coaService.updateNominationTask(coaId, form.value.nominationTaskId, updateTask).pipe(
                          map(() => saveNominationTaskSuccessAction({ coaId, user, updateNominationTask: updateTask })),
                          catchError((err) => of(saveNominationTaskFailAction({ coaId, error: err })))
                      )
                    : coaService.addNominationTask(coaId, addTask).pipe(
                          map(() => saveNewNominationTaskSuccessAction({ coaId, user, addNominationTask: addTask })),
                          catchError((err) => of(saveNominationTaskFailAction({ coaId, error: err })))
                      );
            })
        )
    );
