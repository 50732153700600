import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { AppConfigService } from "@ops/core";

import {
    addActivityLocationsSelectionSelectAll,
    addActivityLocationsSelectionSelectAllByCargo,
    addActivityLocationsSelectionSelectAllByVoyageActivity,
    AddActivityLocationsSelectionState,
    CargoId,
    LtcFeatureState,
    selectCurrentAddActivityLocationsSelectionState,
    VoyageActivityId
} from "../../../state";

@Component({
    selector: "ops-add-activity-locations-selection-shell",
    templateUrl: "./add-activity-locations-selection-shell.component.html",
    styleUrls: ["./add-activity-locations-selection-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddActivityLocationsSelectionShellComponent {
    readonly addActivityLocationsSelection$: Observable<AddActivityLocationsSelectionState>;

    constructor(private store: Store<LtcFeatureState>, appConfigService: AppConfigService) {
        this.addActivityLocationsSelection$ = this.store.select(selectCurrentAddActivityLocationsSelectionState, { cdnUrl: appConfigService.config.clarksonsCdnUrl });
    }

    selectAll() {
        this.store.dispatch(addActivityLocationsSelectionSelectAll());
    }

    selectAllByVoyageActivity(voyageActivityId: VoyageActivityId) {
        this.store.dispatch(addActivityLocationsSelectionSelectAllByVoyageActivity({ voyageActivityId }));
    }

    selectAllByCargo(cargoId: CargoId) {
        this.store.dispatch(addActivityLocationsSelectionSelectAllByCargo({ cargoId }));
    }
}
