import { Injectable } from "@angular/core";
import * as localforage from "localforage";
import { from, Observable } from "rxjs";

// N.b. Wrap all localForage access with .ready()
// See https://github.com/localForage/localForage/issues/800
//
export class WebStorageService<T> {
    private store: LocalForage;

    constructor(storeName: string) {
        this.store = localforage.createInstance({
            name: "ops",
            storeName
        });
    }

    get(id: string): Observable<T> {
        const promise = this.makeCall(() => this.store.getItem(id));
        return from(promise) as Observable<T>;
    }

    set(id: string, object: T): Observable<T> {
        const promise = this.makeCall(() => this.store.setItem(id, object));
        return from(promise);
    }

    remove(id: string): Observable<void> {
        const promise = this.makeCall(() => this.store.removeItem(id));
        return from(promise);
    }

    private makeCall<TResult>(func: () => Promise<TResult>): Promise<TResult> {
        return this.store
            .ready()
            .then(func)
            .then((res) => {
                this.closeConnection();
                return res;
            });
    }

    private closeConnection() {
        // Workaround for https://github.com/localForage/localForage/issues/1006
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>this.store)._dbInfo?.db?.close();
    }
}

@Injectable({
    providedIn: "root"
})
export class WebStorageServiceFactory {
    private static cache: Map<string, any> = new Map<string, any>();

    createStorage<T>(storageName: string): WebStorageService<T> {
        const cachedItem = WebStorageServiceFactory.cache.get(storageName) as WebStorageService<T>;
        if (cachedItem) {
            return cachedItem;
        }

        const item = new WebStorageService<T>(storageName);
        WebStorageServiceFactory.cache.set(storageName, item);

        return item;
    }
}
