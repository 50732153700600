import { createAction, props } from "@ngrx/store";

const BULK_UPDATE_CARGO_ALLOWED_RATES_NAME = "[Cargo Allowed Rates] Bulk update";

export const bulkUpdateCargoAllowedRatesAction = createAction(
    BULK_UPDATE_CARGO_ALLOWED_RATES_NAME,
    props<{
        numberOfCargoesSelected: number;
        fieldLabel: string;
    }>()
);
