import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { FixtureDataService } from "src/app/fixture/services/fixture-data.service";
import { DocumentView } from "../shared/document-view.model";
import { DocumentDto } from "../shared/document.dto";
import { ResponseStatusMessage } from "../shared/response-status-message";
import { DocumentHttpService } from "./document-http.service";

@Injectable({
    providedIn: "root"
})
export class DocumentDataService {
    private fixtureId: string = null;
    private documentsSubject = new BehaviorSubject<DocumentView[]>([]);

    isLoading$ = new BehaviorSubject<boolean>(false);
    loadingFailed$ = new BehaviorSubject<boolean>(false);

    get documents$() {
        return this.documentsSubject.asObservable();
    }

    constructor(private documentHttpService: DocumentHttpService, private fixtureDataService: FixtureDataService) {
        this.fixtureDataService.currentFixture$.subscribe((fixture) => {
            if (fixture) {
                if (fixture.fixtureId !== this.fixtureId) {
                    this.fixtureId = fixture.fixtureId;
                    this.documentsSubject.next([]);
                    this.loadDocuments();
                }
            } else {
                this.fixtureId = null;
                this.documentsSubject.next([]);
            }
        });
    }

    uploadDocument(document: File): Observable<ResponseStatusMessage> {
        return this.documentHttpService.upload(this.fixtureId, document);
    }

    downloadDocument(document: DocumentView): Observable<ResponseStatusMessage> {
        return this.documentHttpService.download(this.fixtureId, document.documentId);
    }

    deleteDocument(document: DocumentView): Observable<void> {
        return this.documentHttpService.delete(this.fixtureId, document.documentId);
    }

    addPermission(document: DocumentView, permission: string): Observable<any> {
        document.permissions.push(permission);

        return this.documentHttpService.addPermission(this.fixtureId, document.documentId, permission).pipe(
            catchError((err) => {
                const permIndex = document.permissions.findIndex((p) => p === permission);
                document.permissions.splice(permIndex, 1);
                return throwError(err);
            })
        );
    }

    deletePermission(document: DocumentView, permission: string): Observable<any> {
        const permIndex = document.permissions.findIndex((p) => p === permission);
        document.permissions.splice(permIndex, 1);

        return this.documentHttpService.deletePermission(this.fixtureId, document.documentId, permission).pipe(
            catchError((err) => {
                document.permissions.push(permission);
                return throwError(err);
            })
        );
    }

    private loadDocuments() {
        this.isLoading$.next(true);
        this.loadingFailed$.next(false);
        return this.documentHttpService.get(this.fixtureId).subscribe(
            (documents: DocumentDto[]) => {
                this.documentsSubject.next(documents.map((d) => new DocumentView(d)));
                this.isLoading$.next(false);
            },
            () => {
                this.isLoading$.next(false);
                this.loadingFailed$.next(true);
            }
        );
    }
}
