import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";

import { SimpleChanges } from "@ops/shared";
import { AtSeaBunkerConsumptionMode, AtSeaVesselStatus } from "@ops/shared/reference-data";

import { Accordion } from "../../../../shared/accordion";
import { AtSeaSpeedCalculator } from "../../../services/at-sea-speed-calculator";
import { VoyageDataService } from "../../../services/voyage-data.service";
import { FormComponentBase } from "../../../shared/form-component-base";
import { CpSpeedAndConsumption } from "../../../shared/models";
import { AtSeaBunkerConsumption } from "../../../shared/models/dtos/at-sea-bunker-consumption";
import { AtSeaConsumptionFormModel } from "../../../shared/models/form-models/at-sea-consumption.model";
import { BunkerConsumedFormModel } from "../../../shared/models/form-models/bunker-consumed.model";
import { UpdateAtSeaBunkersConsumedCommand } from "../at-sea-bunker-consumed/commands/update-at-sea-bunkers-consumed";
import { UpdateAtSeaConsumptionCommand } from "./commands/update-at-sea-consumption.command";

@Component({
    selector: "ops-at-sea-consumption",
    templateUrl: "./at-sea-consumption.component.html",
    styleUrls: ["./at-sea-consumption.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtSeaConsumptionComponent extends FormComponentBase implements OnInit, OnChanges, OnDestroy {
    static componentName = "AtSeaConsumptionComponent";

    private voyageSubscription: Subscription;

    accordion = new Accordion(true);
    form: UntypedFormGroup;

    maxBeaufortForce: number;
    durationInDays: number;
    averageSpeed: number;
    vesselStatus: AtSeaVesselStatus;

    @Input() readonly: boolean;
    @Input() isSimpleMode: boolean;
    @Input() atSea: AtSeaBunkerConsumption;
    @Input() parentForm: UntypedFormGroup;
    @Input() cpSpeedAndConsumption: CpSpeedAndConsumption;
    @Output() atSeaUpdated = new EventEmitter();

    get title() {
        if (this.vesselStatus) {
            return this.isSimpleMode ? `${this.vesselStatus.name} Leg` : `At Sea - ${this.vesselStatus.name}`;
        }

        return "";
    }

    get durationTooltip() {
        if (this.isSimpleMode) {
            return this.vesselStatus?.id === AtSeaVesselStatus.Ballast.id
                ? 'Time taken between "Sailed" date of ballast port to "NOR Tendered" date of the first load port'
                : 'Time taken between "NOR Tendered" date of the first load port to the "Cargo Completed" of the final discharge port';
        }

        return 'Time taken between "Sailed" date of the preceding port to the "NOR Tendered" or "Arrival" date of the succeeding port';
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private voyageDataService: VoyageDataService,
        private atSeaSpeedCalculator: AtSeaSpeedCalculator
    ) {
        super();
    }

    ngOnInit() {
        this.createForm(this.parentForm.disabled);
        this.form[this.parentForm.disabled ? "disable" : "enable"]();
        this.setForm(this.atSea);
        this.subscribeToVoyageChanges();
        this.parentForm.registerControl("atSeaForm", this.form);
    }

    ngOnChanges(changes: SimpleChanges<AtSeaConsumptionComponent>) {
        if (!changes?.atSea) {
            return;
        }

        this.vesselStatus = changes.atSea.currentValue?.vesselStatus ?? null;

        if (!changes.atSea.firstChange) {
            this.maxBeaufortForce = this.cpSpeedAndConsumption?.maxBeaufortForce;
            this.setForm(changes.atSea.currentValue);
        }
    }

    ngOnDestroy() {
        this.removeVoyageSubscription();
    }

    handleAtSeaBunkerConsumedUpdate(event: BunkerConsumedFormModel[]): void {
        this.atSeaUpdated.emit(new UpdateAtSeaBunkersConsumedCommand(this.atSea.id, event));
    }

    accordionToggle() {
        this.accordion.toggle();
        setTimeout(() => {
            this.changeDetectorRef.markForCheck();
        }, 0);
    }

    private createForm(disabled: boolean) {
        const group = this.formBuilder.group(
            {
                comments: new UntypedFormControl("", { updateOn: "blur" }),
                daysAboveForce: new UntypedFormControl("", { updateOn: "blur" }),
                durationInDays: [],
                averageSpeed: [],
                distance: new UntypedFormControl("", { updateOn: "blur" }),
                atSeaBunkerConsumptionId: []
            },
            { updateOn: "blur" }
        );

        if (disabled) {
            group.disable({ emitEvent: false });
        }

        this.form = group;
    }

    private setForm(atSea: AtSeaBunkerConsumption): void {
        const viewModel = <AtSeaConsumptionFormModel>{
            comments: atSea.comments || "",
            daysAboveForce: atSea.daysAboveForce,
            distance: atSea.distance,
            atSeaBunkerConsumptionId: atSea.id
        };

        this.removeFormSubscriptions();
        this.form.patchValue(viewModel, { emitEvent: false });
        this.subscribeToFormValueChanges(this.form, (data) => {
            this.atSeaUpdated.next(new UpdateAtSeaConsumptionCommand(data, data));
        });
    }

    private subscribeToVoyageChanges(): void {
        this.voyageSubscription = this.voyageDataService.current$
            .pipe(
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                debounceTime(200),
                filter((voyage) => !!voyage)
            )
            .subscribe((voyage) => {
                const mode = this.isSimpleMode ? AtSeaBunkerConsumptionMode.Simple : AtSeaBunkerConsumptionMode.Default;
                this.durationInDays = this.atSeaSpeedCalculator.calcAtSeaVoyageTime(this.atSea, mode, voyage.destinations);
                this.averageSpeed = this.atSeaSpeedCalculator.calcAtSeaAverageSpeed(this.durationInDays, this.atSea.distance);

                this.changeDetectorRef.markForCheck();
            });
    }

    private removeVoyageSubscription() {
        if (this.voyageSubscription) {
            this.voyageSubscription.unsubscribe();
            this.voyageSubscription = null;
        }
    }
}
