import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, publishReplay, refCount, tap } from "rxjs/operators";
import { WindowRefService } from "../window-ref.service";

@Injectable({
    providedIn: "root"
})
export class AppStateService {
    private _eTag$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _isIndexInSync$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private _currentInSyncRequest: Observable<boolean>;

    get eTag$(): Observable<string> {
        return this._eTag$;
    }

    get isIndexInSync$(): Observable<boolean> {
        return this._isIndexInSync$;
    }

    constructor(private http: HttpClient, private windowRefService: WindowRefService) {
        this._isIndexInSync$.subscribe((state) => {
            if (!state) {
                console.error("404 error accessing JS asset. Probable cause: version mismatch after update. Did you empty the cache and reload this window?");
            }
        });
    }

    initializeETag(value?: string) {
        if (value) {
            this._eTag$.next(value);
            return;
        }

        this.http
            .head("/", {
                headers: new HttpHeaders({
                    "Skip-App-State-Interceptor": "true"
                }),
                observe: "response",
                responseType: "text"
            })
            .subscribe((x) => {
                const eTag = x.headers.get("ETag");
                this._eTag$.next(eTag);
            });
    }

    setIsInSync(isInSync: boolean) {
        this._isIndexInSync$.next(isInSync);
        return Promise.resolve(isInSync);
    }

    checkIndexInSync(): Observable<boolean> {
        if (!this._eTag$.getValue()) {
            throw new Error("Call initializeETag before checkIndexInSync");
        }

        if (!this._isIndexInSync$.getValue()) {
            return this._isIndexInSync$;
        }

        return this.inSyncCheck(this.windowRefService.nativeWindow.origin);
    }

    private inSyncCheck(url: string): Observable<boolean> {
        return this._currentInSyncRequest
            ? this._currentInSyncRequest
            : (this._currentInSyncRequest = this.http
                  .head(url, {
                      headers: new HttpHeaders({
                          "Skip-App-State-Interceptor": "true"
                      }),
                      observe: "response",
                      responseType: "text"
                  })
                  .pipe(
                      map((response) => response.headers.get("ETag") === this._eTag$.getValue()),
                      publishReplay(1),
                      refCount(),
                      tap((isInSync) => {
                          this._isIndexInSync$.next(isInSync);
                          this._currentInSyncRequest = null;
                      })
                  ));
    }
}
