import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { CoaFeatureState, Company, CompanyForm, CompanyRoleOption, selectCompanyForm, selectCurrentCoaCompanies, selectCurrentCoaCompanyRoles } from "../../coa/state";
import { focusCompanyRole, initContactsPanel, removeCompany } from "../../coa/state/company/actions";
import { LeftBarStateService } from "../left-bar-state.service";

@Component({
    selector: "ops-coa-contacts-shell",
    templateUrl: "./coa-contacts-shell.component.html",
    styleUrls: ["./coa-contacts-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoaContactsShellComponent implements OnInit, OnDestroy {
    static componentName = "CoaContactsComponent";
    private readonly destroy$ = new Subject();

    form$: Observable<FormGroupState<CompanyForm>>;
    companies$: Observable<ReadonlyArray<Company>>;
    companyRoles$: Observable<ReadonlyArray<CompanyRoleOption>>;

    constructor(private leftBarStateService: LeftBarStateService, private store: Store<CoaFeatureState>) {
        this.form$ = this.store.select(selectCompanyForm);
        this.companies$ = this.store.select(selectCurrentCoaCompanies);
        this.companyRoles$ = this.store.select(selectCurrentCoaCompanyRoles);
    }

    ngOnInit() {
        this.store.dispatch(initContactsPanel());

        this.leftBarStateService.isCollapsed$
            .pipe(
                takeUntil(this.destroy$),
                filter((isCollapsed) => !isCollapsed)
            )
            .subscribe(() => {
                this.store.dispatch(focusCompanyRole());
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    removeCompany(company: Company) {
        this.store.dispatch(removeCompany({ company: company }));
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }
}
