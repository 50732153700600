<div class="cargo-block">
    <div class="ops-card">
        <div class="ops-card-body">
            <table class="table ops-table">
                <thead>
                    <tr>
                        <th class="cargo-name-column">Cargo</th>
                        <th class="laytime-column">Load Laytime</th>
                        <th class="laytime-column">Discharge Laytime</th>
                        <th class="button-column"></th>
                    </tr>
                </thead>
                <tbody class="form-group" [class.editing]="!!currentCargoTermId" [class.not-editing]="!currentCargoTermId">
                    <ng-template ngFor let-cargoTerm [ngForOf]="cargoTerms" [ngForTrackBy]="trackBy" let-i="index">
                        <tr
                            ops-laytime-calculation-cargo-term-row
                            [class.even-row]="i % 2 !== 0"
                            [cargoTerm]="cargoTerm"
                            [form]="getCargoTermsForm(cargoTerm.id)"
                            [isEditing]="isEditing(cargoTerm.id)"
                            (edit)="handleEdit(cargoTerm.id)"
                            (remove)="remove.emit(cargoTerm.id)"
                            (removeLocation)="removeLocation.emit($event)"
                        ></tr>
                        <tr *ngIf="isEditing(cargoTerm.id)" [class.even-row]="i % 2 !== 0">
                            <td colspan="4">
                                <div class="actions">
                                    <ops-action-button (click)="cancelEdit.emit()" class="cancel-button" data-cy="cancel-changes">CANCEL</ops-action-button>
                                    <ops-action-button type="primary" (click)="save.emit()" data-cy="save-changes">SAVE</ops-action-button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>
    </div>
</div>
