import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { FormControlState } from "ngrx-forms";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import {
    addActivityLocationsToCalculation,
    addActivityLocationsCancel,
    loadVoyageForAddActivityLocations,
    LtcFeatureState,
    selectAddActivityLocationsEnabled,
    selectCurrentAddActivityLocationsImportPortTimes,
    selectCurrentIsAddingActivityLocations,
    selectCurrentLaytimeCalculationSummaryTitle,
    selectCurrentVoyageHasCargoesWithoutCargoProduct,
    selectCurrentVoyageLoaded
} from "../../state";

@Component({
    selector: "ops-add-activity-locations-shell",
    templateUrl: "./add-activity-locations-shell.component.html",
    styleUrls: ["./add-activity-locations-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddActivityLocationsShellComponent implements OnDestroy {
    private readonly destroy$ = new Subject();

    readonly loaded$: Observable<boolean>;
    readonly isAddingActivityLocations$: Observable<boolean>;
    readonly importPortTimes$: Observable<FormControlState<boolean>>;
    readonly addActivityLocationsEnabled$: Observable<boolean>;
    readonly calculationName$: Observable<string>;
    readonly hasCargoesWithoutCargoProduct$: Observable<boolean>;

    constructor(private store: Store<LtcFeatureState>, private titleService: Title) {
        this.loaded$ = this.store.select(selectCurrentVoyageLoaded);
        this.isAddingActivityLocations$ = this.store.select(selectCurrentIsAddingActivityLocations);
        this.importPortTimes$ = this.store.select(selectCurrentAddActivityLocationsImportPortTimes);
        this.addActivityLocationsEnabled$ = this.store.select(selectAddActivityLocationsEnabled);
        this.calculationName$ = this.store.select(selectCurrentLaytimeCalculationSummaryTitle);
        this.hasCargoesWithoutCargoProduct$ = this.store.select(selectCurrentVoyageHasCargoesWithoutCargoProduct);
        this.store.dispatch(loadVoyageForAddActivityLocations());
        this.setupTitle(this.calculationName$);
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    setupTitle(calculationName$: Observable<string>) {
        calculationName$.pipe(takeUntil(this.destroy$)).subscribe((calculationName) => {
            this.titleService.setTitle(`Add Locations - ${calculationName}`);
        });
    }

    addToCalculation() {
        this.store.dispatch(addActivityLocationsToCalculation());
    }

    cancel() {
        this.store.dispatch(addActivityLocationsCancel());
    }
}
