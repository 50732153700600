import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

import { ValidationTextFns } from "../../../../shared/components/validation/validation.component";
import { parseISODate } from "../../../../shared/date-utils/date-utilities";
import { Command } from "../../../mediator";
import { DeliveryHeaderDate } from "../../../services/delivery-header-date";
import { DeliveryHeaderDateService } from "../../../services/delivery-header-date.service";
import { getLatestLaycanTo } from "../../../shared/fixture-destination.utils";
import { FormComponentBase } from "../../../shared/form-component-base";
import { Delivery, Laycan, Period } from "../../../shared/models";
import { Bunker } from "../../../shared/models/dtos/bunker.dto";
import { Notice } from "../../../shared/models/dtos/notice.dto";
import { DeliveryFormModel } from "../../../shared/models/form-models/delivery.model";
import { NoticeFormModel } from "../../../shared/models/form-models/notice.model";
import { AddBunkerCommand } from "./commands/add-bunker.command";
import { DeleteBunkerCommand } from "./commands/delete-bunker.command";
import { UpdateBunkerCommand } from "./commands/update-bunker.command";
import { UpdateDeliveryCommand } from "./commands/update-delivery.command";
import { ActualDeliveryDateValidator } from "./validators/actual-delivery-date-validator";
import { DeliveryLocationsValidator } from "./validators/delivery-locations-validator";
import { DeliveryNoticesValidator } from "./validators/delivery-notice-validator";

@Component({
    selector: "ops-delivery",
    templateUrl: "./delivery.component.html",
    styleUrls: ["./delivery.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryComponent extends FormComponentBase implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    static componentName = "DeliveryComponent";
    private _ready = false;

    readonly locationsValidationFns: ValidationTextFns = {
        exactPortIsNotSpecified: () => "Specify the exact port for Delivery"
    };
    readonly actualDateValidationFns: ValidationTextFns = {
        laterThanSecondHirePeriod: () => "Cannot be later than the 2nd Hire Period"
    };

    headerDate: DeliveryHeaderDate;
    hideComments: boolean;
    deliveryForm: UntypedFormGroup;
    deliveryLocationCount: number;

    @Input() delivery: Delivery;
    @Input() laycan: Laycan;
    @Input() periods: Period[];
    @Input() parentForm: UntypedFormGroup;
    @Output() deliveryUpdated = new EventEmitter<Command>();

    get laycanToDate(): Date {
        return getLatestLaycanTo(this.laycan);
    }

    constructor(private formBuilder: UntypedFormBuilder, private deliveryHeaderDateService: DeliveryHeaderDateService) {
        super();
    }

    ngOnInit(): void {
        this.createForm();
        this.parentForm.registerControl("delivery", this.deliveryForm);

        if (this.parentForm.disabled) {
            this.deliveryForm.disable();
        } else {
            this.deliveryForm.enable();
        }

        this.headerDate = {
            header: null,
            tooltip: null
        };

        this.setForm();
    }

    ngAfterViewInit(): void {
        this._ready = true;
        this.subscribeToFormValueChanges(this.deliveryForm, (value: DeliveryFormModel) => {
            this.deliveryUpdated.next(new UpdateDeliveryCommand(value));
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes || !this.deliveryForm || !changes.delivery || changes.delivery.firstChange) {
            return;
        }
        this.setForm();
    }

    ngOnDestroy(): void {
        this.removeFormSubscriptions();
        this.parentForm.removeControl("delivery");
    }

    bunkerUpdated = (event: Bunker[]) => this.deliveryUpdated.next(new UpdateBunkerCommand(event));

    bunkerAdded = () => this.deliveryUpdated.next(new AddBunkerCommand());

    bunkerRemoved = (index: number) => this.deliveryUpdated.next(new DeleteBunkerCommand(index));

    get invalid() {
        return this.deliveryForm.invalid;
    }

    get touched() {
        return Object.keys(this.deliveryForm.controls).some((key) => this.deliveryForm.controls[key].invalid && this.deliveryForm.controls[key].touched);
    }

    isEstimatedDeliveryDateWarningVisible(): boolean {
        const laycan = this.laycan;
        const laycanDate = parseISODate(laycan?.extensionDate?.to ?? laycan?.date?.to);
        const estimatedDeliveryDate = this.deliveryForm.get("estimatedDeliveryDate").value as Date;

        return laycanDate && estimatedDeliveryDate && estimatedDeliveryDate > laycanDate;
    }

    private createForm() {
        this.deliveryForm = this.formBuilder.group(
            {
                deliveryLocations: [],
                estimatedDeliveryDate: [],
                actualDeliveryDate: [],
                deliveryNotices: [],
                comments: []
            },
            {
                validator: Validators.compose([
                    DeliveryLocationsValidator.validate(),
                    DeliveryNoticesValidator.validate(),
                    ActualDeliveryDateValidator.validate(() => this.periods)
                ])
            }
        );
    }

    private setForm() {
        const deliveryNotices: NoticeFormModel[] = this.delivery.deliveryNotices.map(
            (notice: Notice) =>
                ({
                    days: notice.days,
                    noticeType: notice.noticeType
                } as NoticeFormModel)
        );

        const dataModel: DeliveryFormModel = {
            deliveryLocations: this.delivery.deliveryLocations,
            estimatedDeliveryDate: parseISODate(this.delivery.estimatedDeliveryDate),
            actualDeliveryDate: parseISODate(this.delivery.actualDeliveryDate),
            deliveryDate: parseISODate(this.delivery.estimatedDeliveryDate),
            deliveryNotices,
            comments: this.delivery.comments
        };

        this.removeFormSubscriptions();

        this.deliveryForm.patchValue(dataModel, { emitEvent: false });

        if (this._ready) {
            this.subscribeToFormValueChanges(this.deliveryForm, (value: DeliveryFormModel) => {
                this.deliveryUpdated.next(new UpdateDeliveryCommand(value));
            });
        }

        this.deliveryLocationCount = this.getDeliveryLocationCount();
        this.headerDate = this.getHeaderDate();
    }

    private getHeaderDate(): DeliveryHeaderDate {
        const { actualDeliveryDate, estimatedDeliveryDate, deliveryLocations } = this.delivery;
        return this.deliveryHeaderDateService.getHeaderDate(actualDeliveryDate, estimatedDeliveryDate, "Actual Delivery", "Estimated Delivery", deliveryLocations);
    }

    private getDeliveryLocationCount() {
        return (this.delivery && this.delivery.deliveryLocations && this.delivery.deliveryLocations.length) || 0;
    }
}
