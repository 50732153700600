<div [formGroup]="expenseForm" class="card embedded-card-1">
    <div class="card-header pl-0 row no-gutters align-items-center">
        <button
            type="button"
            (click)="toggleExpense()"
            class="has-icon btn-inverted"
            [class.icon--arrow-drop-right]="hideExpense"
            [class.icon--arrow-drop-down]="!hideExpense"
            data-test-toggleexpense
        ></button>
        <div
            class="validation-indicator has-icon icon--warning"
            [class.invalid]="expenseForm.invalid"
            container="body"
            ngbTooltip="There are validation errors on this expense"
            data-test-expenses-warning
        ></div>
        <ops-expense-summary
            [expenseType]="expense.type?.name || ''"
            [receivedFromOwnerDate]="expense.receivedFromOwnerDate"
            [currency]="expense.currency?.code || ''"
            [value]="expense.finalClaimValue"
            [paidDate]="expense.paidDate"
            [commissionable]="expense.commissionable"
        >
        </ops-expense-summary>
        <button
            [disabled]="expenseForm.disabled"
            (click)="removeExpense($event)"
            type="button"
            class="btn-delete has-icon icon--delete btn-inverted"
            data-test-deleteexpense
        ></button>
    </div>
    <div class="card-body" [hidden]="hideExpense">
        <div class="row no-gutters">
            <div class="col-9 expenses-rows">
                <div class="row no-gutters">
                    <div class="form-group col-4 required">
                        <label>Expense Type</label>
                        <ng-select
                            class="ops-select form-control"
                            [items]="expenseTypes$ | async"
                            placeholder="Select Expense Type"
                            formControlName="type"
                            [clearable]="true"
                            bindLabel="name"
                            [compareWith]="referenceDataService.compare"
                            data-test-expensetype
                            [selectOnTab]="true"
                        >
                        </ng-select>
                        <ops-validation [form]="expenseForm.get('type')"></ops-validation>
                    </div>
                    <div class="form-group col-4">
                        <label>Claim Type Description</label>
                        <input formControlName="claimTypeDescription" class="form-control" type="text" data-test-claimtypedescription />
                    </div>
                    <div class="form-group col-2">
                        <label>Owner Invoice Number</label>
                        <input formControlName="ownerInvoiceNumber" class="form-control" type="text" data-test-ownerinvoicenumber />
                    </div>
                    <div class="form-group col-2">
                        <label>Invoice Date</label>
                        <ops-date-input
                            formControlName="invoiceDate"
                            timeZone="local"
                            data-test-invoicedate
                        ></ops-date-input>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="form-group col-2 required">
                        <label>Initial Claim Value</label>
                        <input
                            formControlName="initialClaimValue"
                            class="form-control text-right"
                            type="text"
                            [opsNumber]="{ precision: 2, minValue: 0 }"
                            data-test-initialclaimvalue
                        />
                        <ops-validation [form]="expenseForm.get('initialClaimValue')"></ops-validation>
                    </div>
                    <div class="form-group col-2">
                        <label>Final Claim Value</label>
                        <input
                            formControlName="finalClaimValue"
                            class="form-control text-right"
                            type="text"
                            [opsNumber]="{ precision: 2, minValue: 0 }"
                            data-test-finalclaimvalue
                        />
                        <ops-validation [form]="expenseForm.get('finalClaimValue')"></ops-validation>
                    </div>
                    <div class="form-group col-2">
                        <label>Currency</label>
                        <ng-select
                            id="currency"
                            class="ops-select"
                            formControlName="currency"
                            [compareWith]="referenceDataService.compareCurrencies"
                            [items]="currencies$ | async"
                            [clearable]="false"
                            bindLabel="code"
                            [selectOnTab]="true"
                            data-test-expensecurrency
                        >
                        </ng-select>
                    </div>
                    <div class="form-group col-2">
                        <label>Gross Com. Amount</label>
                        <input
                            formControlName="grossCommissionableAmount"
                            class="form-control text-right"
                            type="text"
                            [opsNumber]="{ precision: 2, minValue: 0 }"
                            data-test-grosscommissionableamount
                        />
                        <ops-validation [form]="expenseForm.get('grossCommissionableAmount')"></ops-validation>
                    </div>
                    <div class="form-group col-2">
                        <label>Last Contacted</label>
                        <ops-date-input formControlName="lastContacted" timeZone="local" data-test-lastcontacted></ops-date-input>
                    </div>
                    <div class="form-group col-2">
                        <label>Sent To Accounts</label>
                        <ops-date-input
                            formControlName="sentToAccountsDate"
                            timeZone="local"
                            data-test-senttoaccounts
                        ></ops-date-input>
                    </div>
                </div>
            </div>
            <div class="form-group col-3 d-flex flex-column">
                <label>Comments</label>
                <textarea formControlName="comments" class="form-control flex-grow-1" data-test-expensecomments></textarea>
                <ops-validation [form]="expenseForm.get('comments')"></ops-validation>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-12 expenses-rows">
                <div class="row no-gutters">
                    <div class="form-group col">
                        <label>Received From Owner</label>
                        <ops-date-input
                            formControlName="receivedFromOwnerDate"
                            timeZone="local"
                            data-test-receivedfromowner
                        ></ops-date-input>
                    </div>
                    <div class="form-group col">
                        <label>Sent To Charterer</label>
                        <ops-date-input
                            formControlName="sentToChartererDate"
                            timeZone="local"
                            data-test-senttocharterer
                        ></ops-date-input>
                    </div>
                    <div class="form-group col">
                        <label>Charterer Ack. Receipt</label>
                        <ops-date-input
                            formControlName="chartererAcknowledgedReceiptDate"
                            timeZone="local"
                            data-test-chartereracknowledgedreceipt
                        ></ops-date-input>
                    </div>
                    <div class="form-group col">
                        <label>Agreed Date</label>
                        <ops-date-input
                            formControlName="agreedDate"
                            timeZone="local"
                            data-test-agreeddate
                        ></ops-date-input>
                    </div>
                    <div class="form-group col">
                        <label>Date Paid</label>
                        <ops-date-input
                            formControlName="paidDate"
                            timeZone="local"
                            data-test-paiddate
                        ></ops-date-input>
                    </div>
                    <div class="form-group col">
                        <label>Commission Date</label>
                        <ops-date-input
                            formControlName="commissionDate"
                            timeZone="local"
                            data-test-expense-commission-date
                        ></ops-date-input>
                    </div>
                    <div class="form-group col-3">
                        <label>Claim Handled By</label>
                        <input formControlName="claimHandledBy" class="form-control" type="text" data-test-claimhandledby />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <hr class="horizontal-line" />
                    </div>
                </div>
                <div class="row flex-row no-gutters justify-content-start">
                    <div class="form-group d-flex align-items-end checkbox-container">
                        <input
                            id="commisionable_{{ expense.expenseClaimId }}"
                            type="checkbox"
                            name="commissionable"
                            formControlName="commissionable"
                            data-test-commissionable
                        />
                        <label for="commisionable_{{ expense.expenseClaimId }}">Commissionable</label>
                    </div>
                    <div class="form-group" *opsDivisions="[Division.tankers]">
                        <div class="d-flex align-items-end checkbox-container">
                            <input
                                id="awaitingHardCopy_{{ expense.expenseClaimId }}"
                                type="checkbox"
                                name="awaitingHardCopy"
                                formControlName="awaitingHardCopy"
                                data-test-awaitinghardcopy
                            />
                            <label for="awaitingHardCopy_{{ expense.expenseClaimId }}">Awaiting Hard Copy</label>
                        </div>
                    </div>
                    <div class="form-group" *opsDivisions="[Division.tankers]">
                        <div class="d-flex align-items-end checkbox-container">
                            <input
                                id="awaitingDocuments_{{ expense.expenseClaimId }}"
                                type="checkbox"
                                name="awaitingDocuments"
                                formControlName="awaitingDocuments"
                                data-test-awaitingdocuments
                            />
                            <label for="awaitingDocuments_{{ expense.expenseClaimId }}">Awaiting Documents</label>
                        </div>
                    </div>
                    <div class="form-group" *opsDivisions="[Division.tankers]">
                        <div class="d-flex align-items-end checkbox-container">
                            <input id="complete_{{ expense.expenseClaimId }}" type="checkbox" name="complete" formControlName="complete" data-test-complete />
                            <label for="complete_{{ expense.expenseClaimId }}">Complete</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters" *ngIf="lastUpdatedByUser">
            <div class="col text-right last-updated">Last Updated by:&nbsp;</div>
            <div class="col-auto pull-right last-updated" *ngIf="!expenseForm.disabled" data-test-lastupdated>
                {{ lastUpdatedByUser }} {{ lastUpdatedDate | date: "dd MMM yy, HH:mm:ss" }}
            </div>
            <ops-load-animation class="col-auto pull-right light-background" size="small" *ngIf="expenseForm.disabled"></ops-load-animation>
        </div>
    </div>
</div>
<div #scrollToPlaceholder></div>
