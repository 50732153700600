import { Injectable } from "@angular/core";

import { ReversibleLaytimeType } from "../../../../shared/reference-data/reversible-laytime-type";
import { CommandHandler } from "../../../mediator/command-handlers/command-handler";
import { Division, Voyage } from "../../../shared/models";
import { CargoAllowedRate, createCargoAllowedRateId } from "../../../shared/models/dtos/cargo-allowed-rate.dto";
import { CargoId } from "../../../state/model";
import { SyncCargoAllowedRatesCommand } from "./sync-cargo-allowed-rates.command";

@Injectable({
    providedIn: "root"
})
export class SyncCargoAllowedRatesCommandHandler implements CommandHandler {
    handle(voyage: Voyage, command: SyncCargoAllowedRatesCommand): void {
        if (!command.cargoIds) {
            throw new Error("Given voyage form model is invalid.");
        }

        const reversibleLaytimeType = command.division === Division.dryCargo ? ReversibleLaytimeType.NonReversible : ReversibleLaytimeType.Reversible;
        const existingCargoAllowedRates = [...(voyage.cargoAllowedRates || [])];

        voyage.cargoAllowedRates = command.cargoIds.map(
            (cargoId) => existingCargoAllowedRates.find((x) => x.cargoId === cargoId) || this.createCargoAlowedRate(cargoId, reversibleLaytimeType)
        );
    }

    private createCargoAlowedRate(cargoId: CargoId, reversibleLaytimeType: ReversibleLaytimeType): CargoAllowedRate {
        return {
            id: createCargoAllowedRateId(),
            cargoId,
            loadCargoRateActivity: {
                cpRate: null,
                cpRateUnit: null,
                extraHours: null,
                reversibleLaytimeType
            },
            dischargeCargoRateActivity: {
                cpRate: null,
                cpRateUnit: null,
                extraHours: null,
                reversibleLaytimeType
            }
        };
    }
}
