import { Enumeration } from "./enumeration";

export class InvoiceType extends Enumeration {
    static VOYCommission = new InvoiceType(1, "VOY Commission", 1);
    static SNPCommission = new InvoiceType(2, "S & P Commission", 2);
    static TCCommission = new InvoiceType(3, "TC Commission", 3);
    static FreightInvoice = new InvoiceType(4, "Freight Invoice", 4);
    static HireInvoice = new InvoiceType(5, "Hire Invoice", 5);
    static VOYCommissionAdvice = new InvoiceType(6, "VOY Commission Advice", 6);
    static TCCommissionAdvice = new InvoiceType(7, "TC Commission Advice", 7);
    static FreightAdvice = new InvoiceType(8, "Freight Advice", 8);
    static HireAdvice = new InvoiceType(9, "Hire Advice", 9);
    static DEMCommission = new InvoiceType(10, "DEM Commission", 10);
    static NBGCommission = new InvoiceType(11, "NBG Commission", 11);
    static SHDCommission = new InvoiceType(12, "SHD Commission", 12);
    static FeeCommission = new InvoiceType(13, "Fee Commission", 13);
    static PROCommission = new InvoiceType(14, "PRO Commission", 14);
}
