import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, take } from "rxjs/operators";

import { DeductionState, selectCurrentDeductions, selectCurrentLaytimeCalculationDurationUnit } from "../../state/calculations";
import { applyDeductionsAction } from "../../state/calculations/activity-location/deductions/apply-deductions";
import { addDeductionAction } from "../../state/calculations/activity-location/deductions/form/add-deduction";
import { clearDeductionsAction, removeDeductionAction } from "../../state/calculations/activity-location/deductions/form/remove-deduction";
import { LaytimeCalculationDurationUnit, LtcFeatureState } from "../../state/model";

@Component({
    selector: "ops-laytime-calculation-enter-deductions",
    templateUrl: "./laytime-calculation-enter-deductions.component.html",
    styleUrls: ["./laytime-calculation-enter-deductions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationEnterDeductionsComponent {
    readonly deductions$: Observable<ReadonlyArray<DeductionState>>;
    readonly durationUnit$: Observable<LaytimeCalculationDurationUnit>;

    constructor(private dialogRef: MatDialogRef<LaytimeCalculationEnterDeductionsComponent>, private store: Store<LtcFeatureState>) {
        this.deductions$ = this.store.select(selectCurrentDeductions);
        this.durationUnit$ = this.store.select(selectCurrentLaytimeCalculationDurationUnit);
    }

    get title() {
        return ["Enter Deductions"];
    }

    addDeduction() {
        this.store.dispatch(addDeductionAction());
    }

    removeDeduction(id: string) {
        this.store.dispatch(removeDeductionAction({ id }));
    }

    clearDeductions() {
        this.store.dispatch(clearDeductionsAction());
    }

    applyDeductions() {
        this.deductions$
            .pipe(
                take(1),
                filter((deductions) => deductions.every((d) => d.form.isValid))
            )
            .subscribe(() => {
                this.store.dispatch(applyDeductionsAction());
                this.dialogRef.close();
            });
    }

    cancel() {
        this.store.dispatch(clearDeductionsAction());
        this.dialogRef.close();
    }
}
