import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";

import { InvoiceType, ReferenceDataService } from "../../../../shared/reference-data";
import { InvoiceDataService } from "../../../services/invoice-data.service";
import { AbstractInvoicesComponent } from "../../../shared/invoices/abstract-invoices.component";

@Component({
    selector: "ops-hire-invoices",
    templateUrl: "./hire-invoices.component.html",
    styleUrls: ["../../../shared/invoices/abstract-invoices.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HireInvoicesComponent extends AbstractInvoicesComponent {
    static componentName = "HireInvoicesComponent";

    constructor(
        cd: ChangeDetectorRef,
        formBuilder: UntypedFormBuilder,
        referenceDataService: ReferenceDataService,
        datePipe: DatePipe,
        invoiceDataService: InvoiceDataService,
        store: Store
    ) {
        super(InvoiceType.HireInvoice, cd, formBuilder, referenceDataService, datePipe, invoiceDataService, store);
    }
}
