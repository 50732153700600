import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, takeUntil } from "rxjs/operators";

import { Suggestion, SuggestionTerm } from "@ops/state";

import { WorksheetService } from "../../../state/worksheets/services/worksheet.service";
import { equalsByProps } from "../../utils/utils";
import { ISearchService } from "../datasource/search.service";

@Directive() // eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class GridSearchComponent implements OnInit, OnDestroy {
    protected readonly destroy$ = new Subject();

    readonly suggestionSubject = new Subject<SuggestionTerm>();
    readonly suggestedItems$: Observable<ReadonlyArray<Suggestion>>;
    readonly suggestionsLoading$: Observable<boolean>;

    selection: ReadonlyArray<Suggestion> = [];
    namingNewWorksheet = false;

    protected constructor(protected searchService: ISearchService, protected worksheetService: WorksheetService, protected changeDetector: ChangeDetectorRef) {
        this.suggestedItems$ = this.searchService.suggestions$;
        this.suggestionsLoading$ = this.searchService.suggestionsLoading$;
        this.worksheetService.gridRenameForm$.pipe(takeUntil(this.destroy$)).subscribe((form) => {
            this.namingNewWorksheet = !!form;
            this.changeDetector.markForCheck();
        });
    }

    ngOnInit() {
        this.worksheetService.setDefaultSearchQuery([]);

        this.suggestionSubject
            .pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(50))
            .subscribe((suggestionTerm) => this.searchService.updateSuggestionTerm(suggestionTerm));

        this.worksheetService.currentWorksheet$
            .pipe(
                takeUntil(this.destroy$),
                filter((worksheet) => !!worksheet),
                distinctUntilChanged(equalsByProps(["sort", "queries"]))
            )
            .subscribe(({ queries }) => {
                this.onSelectionChange(queries || []);
                this.changeDetector.markForCheck();
            });

        this.worksheetService.currentWorksheetSavable$.pipe(takeUntil(this.destroy$)).subscribe(() => this.changeDetector.markForCheck());
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    onSelectionChange(selection: ReadonlyArray<Suggestion>) {
        this.selection = selection;
        this.searchService.updateSearchTokens(selection);
    }

    saveWorksheet() {
        if (this.namingNewWorksheet) {
            this.worksheetService.saveNewWorksheet();
        } else {
            this.worksheetService.updateWorksheet();
        }
    }

    saveAsNewWorksheet() {
        this.worksheetService.nameNewWorksheet();
    }
}
