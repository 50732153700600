import { User as AppUser } from "@ops/state";

export type UserId = number;

export type User = Readonly<{
    userId: UserId;
    userCode: string;
    name: string;
}>;

export const toUser = (appUser: AppUser): User => ({ userId: appUser.userId, userCode: appUser.userCode, name: appUser.fullName });
export const toAppUser = (user: User): AppUser => <AppUser>{ userId: user.userId, userCode: user.userCode, fullName: user.name };
