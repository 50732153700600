import { Berth, Destination, LaytimeEventFact, Voyage } from "../../fixture/shared/models";
import { CargoBerthActivity } from "../../fixture/shared/models/dtos/cargo-berth-activity.dto";
import { ActivityId, BerthId, DestinationId, LaytimeEventId } from "../../fixture/state";

// This should be in shared!

/**
 * @deprecated
 */
export function getDestination(voyage: Voyage, destinationId: DestinationId): Destination {
    const destinationToUpdate = voyage.destinations.find((d) => d.id === destinationId);
    if (!destinationToUpdate) {
        throw new Error(`destination with Id: ${destinationId} does not exist.`);
    }
    return destinationToUpdate;
}

/**
 * @deprecated
 */
export function getBerth(voyage: Voyage, destinationId: DestinationId, berthId: BerthId): Berth {
    const destinationToUpdate = getDestination(voyage, destinationId);
    if (!destinationToUpdate.berths) {
        throw new Error(`there are no berths on destinationId: ${destinationId}.`);
    }

    const berthToUpdate = destinationToUpdate.berths.find((b: Berth) => b.id === berthId);
    if (!berthToUpdate) {
        throw new Error(`berth with Id: ${berthId} does not exist.`);
    }

    return berthToUpdate;
}

/**
 * @deprecated
 */
export function getActivity(voyage: Voyage, destinationId: DestinationId, berthId: BerthId, berthActivityId: ActivityId): CargoBerthActivity {
    const berthToUpdate = getBerth(voyage, destinationId, berthId);

    const activityToUpdate = berthToUpdate.cargoBerthActivities.find((a: CargoBerthActivity) => a.id === berthActivityId);
    if (!activityToUpdate) {
        throw new Error(`activity with Id: ${berthActivityId} does not exist.`);
    }

    return activityToUpdate;
}

/**
 * @deprecated
 */
export function getLaytimeEvent(voyage: Voyage, destinationId: DestinationId, berthId: BerthId, berthActivityId: ActivityId, laytimeEventId: LaytimeEventId): LaytimeEventFact {
    const activityToUpdate = getActivity(voyage, destinationId, berthId, berthActivityId);
    if (!activityToUpdate.laytimeEventFacts) {
        throw new Error(`there are no laytimeEventFacts on activity with Id: ${berthActivityId}`);
    }

    const laytimeEventToUpdate = activityToUpdate.laytimeEventFacts.find((l: LaytimeEventFact) => l.id === laytimeEventId);
    if (!laytimeEventToUpdate) {
        throw new Error(`laytimeEventFact with Id: ${laytimeEventId} does not exist.`);
    }

    return laytimeEventToUpdate;
}
