import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { Boxed, createFormGroupState, SetValueAction } from "ngrx-forms";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { LiftingHttpService } from "../../../../services";
import { selectCurrentCoaId } from "../../../coa";
import { LiftingId, Company, CoasState, getLiftingFormGroupId, companyForm, CoaFeatureState, CompanyForm, mapCompanySearchResultToCoaCompany } from "../../../model";
import { currentLiftingStateReducer, liftingStateReducer } from "../../reducer";
import { selectCurrentLiftingId } from "../../selectors";
import { selectLiftingCompanyForm } from "../selectors";

/* ACTIONS */
const ADD_COMPANY_ACTION_NAME = "[Lifting Contacts Panel] Add Company";
export const addLiftingCompanyAction = createAction(ADD_COMPANY_ACTION_NAME, props<{ company: Company }>());
export const addLiftingCompanySuccessAction = createAction(`${ADD_COMPANY_ACTION_NAME} Success`, props<{ liftingId: LiftingId; company: Company }>());
export const addLiftingCompanyFailAction = createAction(`${ADD_COMPANY_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; company: Company; error: Error }>());

/* REDUCERS */
export const addLiftingCompanyReducer: On<CoasState> = on(addLiftingCompanyAction, (state, { company }) =>
    currentLiftingStateReducer(state, (liftingState) => ({
        ...liftingState,
        companyForm: createFormGroupState(getLiftingFormGroupId(state.currentLiftingId), companyForm()),
        lifting: {
            ...liftingState.lifting,
            contacts: [...liftingState.lifting.contacts, company]
        }
    }))
);

export const addLiftingCompanyFailReducer: On<CoasState> = on(addLiftingCompanyFailAction, (state, { liftingId, company, error }) =>
    liftingStateReducer(state, liftingId, (liftingState) => ({
        ...liftingState,
        lifting: {
            ...liftingState.lifting,
            contacts: liftingState.lifting.contacts.filter((c) => !(c.accountId === company.accountId && c.role === company.role))
        },
        erroredItems: [...(liftingState.erroredItems || []), { itemType: "contacts", id: company.accountId, error }]
    }))
);

/* EFFECTS */
export const addLiftingCompanyEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(addLiftingCompanyAction),
            withLatestFrom(store.select(selectCurrentCoaId), store.select(selectCurrentLiftingId)),
            mergeMap(([{ company }, coaId, liftingId]) =>
                liftingService.addCompany(coaId, liftingId, company).pipe(
                    map(() => addLiftingCompanySuccessAction({ liftingId, company })),
                    catchError((err) => of(addLiftingCompanyFailAction({ liftingId, company, error: err })))
                )
            )
        )
    );

export const addLiftingCompanyOnCompanyChangeEffect$ = (actions$: Actions, store: Store<CoaFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<CompanyForm>>(SetValueAction.TYPE),
            withLatestFrom(store.select(selectCurrentLiftingId)),
            filter(([action, liftingId]) => {
                const controlPath = action.controlId.split(".");
                // eslint-disable-next-line no-magic-numbers
                const boxedCompany =
                    controlPath[0] === liftingId && controlPath[1] === "CompanyForm" && controlPath[2] === "company" ? <Boxed<Company>>(<unknown>action.value) : null;
                return !!boxedCompany?.value;
            }),
            withLatestFrom(store.select(selectLiftingCompanyForm)),
            map(([, form]) => addLiftingCompanyAction({ company: { ...mapCompanySearchResultToCoaCompany(form.value.company.value), role: form.value.companyRole } }))
        )
    );
