import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FixtureWarningPathTransform } from "./fixture-warning-path-transform";

@Injectable()
export class FormStrippingWarningPathTransform implements FixtureWarningPathTransform {
    transform(path: string[]): string[] | Observable<string[]> {
        return path.map(segment => {
            if (segment.endsWith("Form")) {
                return segment.substr(0, segment.length - 4);
            }

            return segment;
        });
    }

}
