import { Injectable } from "@angular/core";

import { CargoBerthActivityType } from "../../../../../shared/reference-data/cargo-berth-activity-type";
import { Fixture, FixtureType, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "./../fixture-warning-rule";

// Section rule
@Injectable()
export class ExternalVisibilityCargoActivityTypeRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    private readonly requiredActivityTypes = [CargoBerthActivityType.Load, CargoBerthActivityType.Discharge];

    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        voyages.forEach((voyage) => {
            const voyagePath = this.getVoyagePathSegment(fixture.fixtureType, voyage);

            this.requiredActivityTypes
                .filter((activityType) => this.hasActivityWithAllMissingAssociatedCargo(voyage, activityType))
                .forEach((activityType) => {
                    const message = `Missing Associated ${activityType.name} Cargo`;
                    const path = [...voyagePath, ...this.firstActivityTypePath(voyage, activityType)];
                    const dismissingRecord =
                        fixture.fixtureType.id !== FixtureType.Voyage
                            ? dismissedWarningRecord(new WarningHashBuilder().withVoyage(voyage.voyageId).withCategory("data").withMessage(message).build())
                            : null;
                    warnings.push(new FixtureWarning("data", message, path, dismissingRecord));
                });
        });

        return warnings;
    }

    private hasActivityWithAllMissingAssociatedCargo(voyage: Voyage, activityType: CargoBerthActivityType) {
        let hasActivity = false;

        for (const destination of voyage.destinations) {
            for (const berth of destination.berths) {
                for (const activity of berth.cargoBerthActivities) {
                    if (activity.type && activity.type.id === activityType.id) {
                        hasActivity = true;

                        if (activity.associatedCargoes.some((associatedCargo) => voyage.cargoes.some((cargo) => associatedCargo.cargoId === cargo.id))) {
                            return false;
                        }
                    }
                }
            }
        }

        return hasActivity;
    }

    private firstActivityTypePath(voyage: Voyage, activityType: CargoBerthActivityType) {
        for (let destinationIndex = 0; destinationIndex < voyage.destinations.length; destinationIndex++) {
            const destination = voyage.destinations[destinationIndex];

            for (let berthIndex = 0; berthIndex < destination.berths.length; berthIndex++) {
                const berth = destination.berths[berthIndex];

                for (let activityIndex = 0; activityIndex < berth.cargoBerthActivities.length; activityIndex++) {
                    const activity = berth.cargoBerthActivities[activityIndex];

                    if (activity.type && activity.type.id === activityType.id) {
                        return [
                            new FixtureWarningPathSegment("destinations", "Locations"),
                            FixtureWarningPathSegment.fromIndex(destinationIndex, "Location", destination.location ? destination.location.displayName : null),
                            new FixtureWarningPathSegment("berths", null),
                            FixtureWarningPathSegment.fromIndex(berthIndex, "Berth", berth.name),
                            new FixtureWarningPathSegment("cargoBerthActivities", null),
                            FixtureWarningPathSegment.fromIndex(activityIndex, "Activity", activityType.name),
                            new FixtureWarningPathSegment("associatedCargoes", "Associated Cargo")
                        ];
                    }
                }
            }
        }

        return [];
    }
}
