import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AppConfigService } from "../../../../../core/app-config.service";
import { SuggestionService } from "../../../../../shared/components/autosuggest/suggestion.service";
import { Enumeration } from "../../../../../shared/reference-data/enumeration";
import { FixtureDataService } from "../../../../services/fixture-data.service";

@Injectable({
    providedIn: "root"
})
export class CargoSuggestionService implements SuggestionService<Enumeration> {
    private cargoServiceUrl: string;
    private divisionName: string;

    constructor(private fixtureDataService: FixtureDataService, private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.cargoServiceUrl = appConfigService.config.cargoServiceUrl;

        this.fixtureDataService.currentFixture$.subscribe((fixture) => {
            if (fixture) {
                if (fixture.division) {
                    this.divisionName = fixture.division.name;
                }
            }
        });
    }

    getSuggestions(term: string): Observable<Enumeration[]> {
        return this.getSuggestionsForDivision(term);
    }

    getSuggestionsForDivision(term: string, division?: string): Observable<Enumeration[]> {
        const params = new HttpParams().set("division", division || this.divisionName).set("query", term);
        return this.httpClient.get<Enumeration[]>(`${this.cargoServiceUrl}/api/v1.0/cargoes`, { params });
    }
}
