import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";
import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, map, takeUntil } from "rxjs/operators";

import { VoyageCost } from "../services/voyage-cost";
import { Division, EmailGenerationAudit } from "../shared/models";
import { AdditionalFreightExpenseId } from "../shared/models/dtos/additional-freight-expense.dto";
import { FixtureTabName } from "../shared/tab-validation/tab-validation-info";
import {
    addAdditionalFreightExpense,
    addCargo,
    addDestination,
    applyAllCargoFreightRate,
    applyCargoFreightRate,
    Audit,
    CargoId,
    CargoState,
    CargoTotalState,
    cloneCargo,
    cloneDestination,
    collapseCargo,
    collapseCargoes,
    collapseCargoTotals,
    collapseDestination,
    collapseDestinations,
    collapsePublicNotes,
    DestinationId,
    DestinationState,
    expandCargo,
    expandCargoes,
    expandCargoTotals,
    expandDestination,
    expandDestinations,
    expandPublicNotes,
    FixtureFeatureState,
    formSelectVoyage,
    generateEtaEmail,
    generateFreightInvoice,
    moveDestinationDown,
    moveDestinationUp,
    removeAdditionalFreightExpense,
    removeCargo,
    removeDestination,
    selectCurrentFixtureDivision,
    selectCurrentFixtureHasTCVoyages,
    selectCurrentFixtureVoyageNumbers,
    selectVoyageTabIsValid,
    selectCurrentVoyageBusy,
    selectCurrentVoyageCargoes,
    selectCurrentVoyageCargoesExpanded,
    selectCurrentVoyageCost,
    selectCurrentVoyageCostBreakdown,
    selectCurrentVoyageCostsExpanded,
    selectCurrentVoyageDestinations,
    selectCurrentVoyageDestinationsExpanded,
    selectCurrentVoyageEtaEmailAudit,
    selectCurrentVoyageForm,
    selectCurrentVoyagePublicNotesAudit,
    selectCurrentVoyagePublicNotesExpanded,
    selectCurrentVoyageReadonly,
    selectCurrentVoyageTotalsExpanded,
    selectCurrentVoyageTotalsForm,
    selectIsVoyageFixture,
    VoyageCostBreakdown,
    VoyageForm,
    VoyageId,
    VoyageNumber
} from "../state";
import { updateValidationTabAction } from "../state/validation";
import { collapseVoyageCostBreakdown, expandVoyageCostBreakdown } from "../state/voyage-cost-breakdown/actions";

@Component({
    selector: "ops-voyage-shell",
    templateUrl: "./voyage-shell.component.html",
    styleUrls: ["./voyage-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoyageShellComponent implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();
    readonly busy$: Observable<boolean>;
    readonly hasTimeCharterVoyages$: Observable<boolean>;
    readonly isVoyageFixture$: Observable<boolean>;
    readonly voyages$: Observable<ReadonlyArray<VoyageNumber>>;
    readonly form$: Observable<FormGroupState<VoyageForm>>;
    readonly readonly$: Observable<boolean>;

    readonly cargoesExpanded$: Observable<boolean>;
    readonly cargoes$: Observable<ReadonlyArray<CargoState>>;
    readonly cost$: Observable<VoyageCost>;
    readonly costBreakdown$: Observable<ReadonlyArray<VoyageCostBreakdown>>;
    readonly showSpecialisedCargoControls$: Observable<boolean>;

    readonly etaEmailAudit$: Observable<EmailGenerationAudit>;

    readonly destinationsExpanded$: Observable<boolean>;
    readonly destinations$: Observable<DestinationState[]>;

    readonly cargoTotals$: Observable<ReadonlyArray<CargoTotalState>>;
    readonly cargoTotalsExpanded$: Observable<boolean>;
    readonly publicNotesExpanded$: Observable<boolean>;
    readonly costsExpanded$: Observable<boolean>;
    readonly publicNotesAudit$: Observable<Audit>;

    @Input() tabName: FixtureTabName;

    constructor(private store: Store<FixtureFeatureState>) {
        this.busy$ = this.store.select(selectCurrentVoyageBusy);
        this.hasTimeCharterVoyages$ = this.store.select(selectCurrentFixtureHasTCVoyages);
        this.isVoyageFixture$ = this.store.select(selectIsVoyageFixture);
        this.voyages$ = this.store.select(selectCurrentFixtureVoyageNumbers);
        this.form$ = this.store.select(selectCurrentVoyageForm);
        this.readonly$ = this.store.select(selectCurrentVoyageReadonly);
        this.cost$ = this.store.select(selectCurrentVoyageCost);

        this.cargoesExpanded$ = this.store.select(selectCurrentVoyageCargoesExpanded);
        this.cargoes$ = this.store.select(selectCurrentVoyageCargoes);
        this.showSpecialisedCargoControls$ = this.store.select(selectCurrentFixtureDivision).pipe(map((x) => x && x === Division.specialisedProducts));

        this.etaEmailAudit$ = this.store.select(selectCurrentVoyageEtaEmailAudit);
        this.destinationsExpanded$ = this.store.select(selectCurrentVoyageDestinationsExpanded);
        this.destinations$ = this.store.select(selectCurrentVoyageDestinations);

        this.cargoTotals$ = this.store.select(selectCurrentVoyageTotalsForm);
        this.cargoTotalsExpanded$ = this.store.select(selectCurrentVoyageTotalsExpanded);

        this.publicNotesExpanded$ = this.store.select(selectCurrentVoyagePublicNotesExpanded);
        this.publicNotesAudit$ = this.store.select(selectCurrentVoyagePublicNotesAudit);

        this.costsExpanded$ = this.store.select(selectCurrentVoyageCostsExpanded);
        this.costBreakdown$ = this.store.select(selectCurrentVoyageCostBreakdown);
    }

    ngOnInit() {
        this.store
            .select(selectVoyageTabIsValid)
            .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe((isValid) => {
                const tabName = this.tabName;
                this.store.dispatch(updateValidationTabAction({ tabName, isValid }));
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    selectVoyage(voyageId: VoyageId) {
        this.store.dispatch(formSelectVoyage({ voyageId }));
    }

    expandCargoes() {
        this.store.dispatch(expandCargoes());
    }

    collapseCargoes() {
        this.store.dispatch(collapseCargoes());
    }

    generateEtaEmail() {
        this.store.dispatch(generateEtaEmail());
    }

    expandDestinations() {
        this.store.dispatch(expandDestinations());
    }

    collapseDestinations() {
        this.store.dispatch(collapseDestinations());
    }

    expandDestination(destinationId: DestinationId) {
        this.store.dispatch(expandDestination({ destinationId }));
    }

    collapseDestination(destinationId: DestinationId) {
        this.store.dispatch(collapseDestination({ destinationId }));
    }

    moveDestinationUp(destinationId: DestinationId) {
        this.store.dispatch(moveDestinationUp({ destinationId }));
    }

    moveDestinationDown(destinationId: DestinationId) {
        this.store.dispatch(moveDestinationDown({ destinationId }));
    }

    addDestination() {
        this.store.dispatch(addDestination());
    }

    cloneDestination(destinationId: DestinationId) {
        this.store.dispatch(cloneDestination({ destinationId }));
    }

    removeDestination(destinationId: DestinationId) {
        this.store.dispatch(removeDestination({ destinationId }));
    }

    addCargo() {
        this.store.dispatch(addCargo());
    }

    cloneCargo(cargoId: CargoId) {
        this.store.dispatch(cloneCargo({ cargoId: cargoId }));
    }

    removeCargo(cargoId: CargoId) {
        this.store.dispatch(removeCargo({ cargoId: cargoId }));
    }

    applyCargoFreightRate(cargoId: CargoId) {
        this.store.dispatch(applyCargoFreightRate({ cargoId: cargoId }));
    }

    applyAllCargoFreightRate() {
        this.store.dispatch(applyAllCargoFreightRate());
    }

    expandCargo(cargoId: CargoId) {
        this.store.dispatch(expandCargo({ cargoId: cargoId }));
    }

    collapseCargo(cargoId: CargoId) {
        this.store.dispatch(collapseCargo({ cargoId: cargoId }));
    }

    addAdditionalFreightExpense() {
        this.store.dispatch(addAdditionalFreightExpense());
    }

    removeAdditionalFreightExpense(expenseId: AdditionalFreightExpenseId) {
        this.store.dispatch(removeAdditionalFreightExpense({ expenseId: expenseId }));
    }

    collapseCargoTotals() {
        this.store.dispatch(collapseCargoTotals());
    }

    expandCargoTotals() {
        this.store.dispatch(expandCargoTotals());
    }

    collapsePublicNotes() {
        this.store.dispatch(collapsePublicNotes());
    }

    expandPublicNotes() {
        this.store.dispatch(expandPublicNotes());
    }

    collapseVoyageCosts() {
        this.store.dispatch(collapseVoyageCostBreakdown());
    }

    expandVoyageCosts() {
        this.store.dispatch(expandVoyageCostBreakdown());
    }

    generateFreightInvoice(freightSpendTotal: number) {
        this.store.dispatch(generateFreightInvoice({ freightSpendTotal: freightSpendTotal }));
    }
}
