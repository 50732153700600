import { GridApi } from "@ag-grid-community/core";
import { Injectable } from "@angular/core";
import * as R from "ramda";

import { ColumnHeader, ColumnType } from "./column-header";
import { ExcelExportService } from "./excel-export.service";
import { DisclaimerHttpService } from "./services/disclaimer-http.service";
import { AuthService, NotificationService } from "../../core";
import { parseNumber } from "../number-format";

@Injectable({
    providedIn: "root"
})
export class GridXlsxExporter {
    private readonly clarksonsCompanyId = 1;
    constructor(
        private excelExportService: ExcelExportService,
        private authService: AuthService,
        private disclaimerService: DisclaimerHttpService,
        private notificationService: NotificationService
    ) {}

    collectLoadedRows(gridApi: GridApi, totalCount: number) {
        let count = gridApi.getDisplayedRowCount();
        // if not all rows loaded, the last displayed row is a "Loading..." row, so we exclude it.
        if (count < totalCount) {
            count--;
        }
        const data = <any>[];
        for (let i = 0; i < count; i++) {
            const row = gridApi.getDisplayedRowAtIndex(i)?.data;
            if (row) {
                data.push(row);
            }
        }
        return data;
    }

    generate(filename: string, headingObjects: ColumnHeader[], headingIdsToIgnore: string[], data: any[], sheetHeader: string): void {
        const formattedData = <any>[];
        const headingsToUse = headingObjects.filter((h) => h.caption && h.caption.length > 0 && !headingIdsToIgnore.find((i) => h.field === i));

        data.forEach((d) => {
            const fd = <any>{};
            // gridApi implementation displays null value fields as empty. This one displays the text "null" by default.
            // to emulate the old functionality, set the field value to an empty string if it is null.
            headingsToUse.forEach((h) => (fd[h.field] = this.getValue(d[h.field], h.type)));
            formattedData.push(fd);
        });
        const addDisclaimer = this.authService.user.companyId === this.clarksonsCompanyId;

        const disclaimer = "";

        if (addDisclaimer) {
            this.disclaimerService.get("richtext").subscribe({
                next: (response) => {
                    this.excelExportService.export(filename, headingsToUse, formattedData, sheetHeader, response);
                },
                error: (error) => {
                    this.notificationService.error("Export Failed", error.message, true);
                }
            });
        } else {
            this.excelExportService.export(filename, headingsToUse, formattedData, sheetHeader, disclaimer);
        }
    }

    private getValue(value: any, type: ColumnType) {
        if (value === undefined || value === "") {
            return value;
        }
        if (value === null) {
            return "";
        }
        switch (type) {
            case "number":
                if (R.is(String)(value)) {
                    const numberValue = parseNumber(value);
                    return !isNaN(numberValue) && isFinite(numberValue) ? numberValue : value;
                } else {
                    return value;
                }
            case "date":
            case "datetime":
                const normalisedValue = value.replace(/,/g, "");
                if (!isNaN(Date.parse(normalisedValue))) {
                    const date = new Date(normalisedValue);
                    const result = new Date();
                    result.setUTCFullYear(date.getFullYear());
                    result.setUTCMonth(date.getMonth());
                    result.setUTCDate(date.getDate());
                    result.setUTCHours(date.getHours());
                    result.setUTCMinutes(date.getMinutes());
                    result.setUTCSeconds(date.getSeconds());
                    result.setUTCMilliseconds(date.getMilliseconds());
                    return result;
                } else {
                    return value;
                }
            default:
                return value;
        }
    }
}
