import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Enumeration, LaytimeCalculationUnit, ReferenceDataService } from "@ops/shared/reference-data";

import { Division } from "../../../shared/models";
import { AssociatedCargoAllowedRatesAggregate } from "../../../shared/models/form-models/associated-cargo-allowed-rates.model";
import { AbstractSimpleGridComponent } from "../../../speed-and-consumption-tab/cp-speed-and-consumption/abstract-simple-grid/abstract-simple-grid.component";
import { DestinationId } from "../../../state/model";
import { UpdateAssociatedCargoesStatementsOfFactsCommand } from "./commands/update-associated-cargoes-statement-of-facts.command";

@Component({
    selector: "ops-associated-cargoes-statement-of-facts",
    templateUrl: "./associated-cargoes-statement-of-facts.component.html",
    styleUrls: ["./associated-cargoes-statement-of-facts.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociatedCargoesStatementOfFactsComponent
    extends AbstractSimpleGridComponent<AssociatedCargoAllowedRatesAggregate>
    implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    static componentName = "AssociatedCargoesStatementOfFactsComponent";

    private readonly columnDefs = <any>[
        { field: "berthId" },
        { field: "activityId" },
        { field: "associatedCargoId" },
        { field: "cargoProductName" },
        { field: "activity" },
        { field: "blQuantity" },
        { field: "actualLoadAndDischargeRate" },
        { field: "reversibleLaytimeType" },
        { field: "cpRate" },
        { field: "cpRateUnit" },
        { field: "extraHours" },
        { field: "cargoTimeUsed" },
        { field: "timeAllowed" },
        { field: "customaryQuickDespatch" }
    ];

    readonly Division = Division;

    cpRateUnits$: Observable<Enumeration[]>;
    reversibleLaytimeType$: Observable<Enumeration[]>;

    @Input() parentForm: UntypedFormGroup;
    @Input() model: AssociatedCargoAllowedRatesAggregate[];
    @Input() division: Division;
    @Input() destinationId: DestinationId;
    @Input() displayUnitType: LaytimeCalculationUnit;
    @Output() associatedCargoAllowedRatesUpdated = new EventEmitter();

    @ViewChildren("cpRateUnitElement", { read: NgSelectComponent }) ngSelectElements: QueryList<NgSelectComponent>;

    constructor(public changeDetectorRef: ChangeDetectorRef, protected formBuilder: UntypedFormBuilder, public referenceDataService: ReferenceDataService) {
        super("associatedCargoAllowedRateId", changeDetectorRef, formBuilder);
        this.columnDefs$.next(this.columnDefs);
        this.cpRateUnits$ = this.referenceDataService.getCpRateUnits();
        this.reversibleLaytimeType$ = this.referenceDataService.getReversibleLaytimeType();

        this.rowChanged$
            .pipe(takeUntil(this.destroy$))
            .subscribe((row) => this.associatedCargoAllowedRatesUpdated.emit(new UpdateAssociatedCargoesStatementsOfFactsCommand(row, this.destinationId)));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["model"] === undefined) {
            return;
        }
        const currentValue = changes["model"].currentValue as AssociatedCargoAllowedRatesAggregate[];
        if (!currentValue) {
            return;
        }
        this.rowData$.next(currentValue);
    }

    ngAfterViewInit() {
        this.ngSelectElements.changes.pipe(takeUntil(this.destroy$)).subscribe((elements: QueryList<NgSelectComponent>) => {
            if (!this.formArray.disabled && elements.length) {
                elements.last.focus();
            }
        });
    }

    get isMultipleCargo(): boolean {
        return this.division === Division.specialisedProducts && this.formArray.controls.length > 1;
    }
}
