<div class="p-side-panel">
    <span class="p-side-panel-title">
        Laytime Calculations
        <div class="left-toolbar-expander" (click)="collapseLeftBar()">
            <div class="has-icon icon--close"></div>
        </div>
    </span>
    <ops-laytime-calculation-list
        *ngIf="calculations$ | async as calculations; else loading"
        class="ops-ltc-scrollable-list"
        [calculations]="calculations"
        (remove)="removeLaytimeCalculation($event)"
    ></ops-laytime-calculation-list>
    <div *ngIf="{ busy: busy$ | async } as data" class="p-d-flex p-ai-center">
        <button [disabled]="data.busy" class="p-link-button" type="button" data-cy="new-calculation" (click)="createLaytimeCalculation()">＋ NEW CALCULATION</button>
        <ops-load-animation *ngIf="data.busy" class="light-background" size="small"></ops-load-animation>
    </div>
</div>

<ng-template #loading>
    <ops-load-animation size="small" class="light-background p-mt-3"></ops-load-animation>
</ng-template>




