import { DOCUMENT } from "@angular/common";
import {
    ApplicationRef,
    ChangeDetectorRef,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    HostListener,
    Inject,
    Injector,
    Input,
    NgZone,
    OnChanges,
    Renderer2,
    TemplateRef,
    ViewContainerRef
} from "@angular/core";
import { NgbPopover, NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { FormState } from "ngrx-forms";

import { isNullOrUndefined } from "../../utils";

@Directive({
    selector: "[opsValidationPopover]"
})
export class ValidationPopoverDirective implements OnChanges {
    #validationPopoverEnabled = true;

    ngb: NgbPopover;
    topPlacementTags: string[] = ["NG_SELECT", "OPS-VESSEL-AUTOSUGGEST"];
    state: FormState<never>;

    @Input() opsValidationPopover: TemplateRef<never>;
    @Input() validationPopoverContainer: string;

    @Input() set validationPopoverEnabled(value: string | boolean) {
        this.#validationPopoverEnabled = isNullOrUndefined(value) || !!value;
    }

    @Input() set ngrxFormControlState(newState: FormState<never>) {
        this.state = newState;
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        renderer: Renderer2,
        injector: Injector,
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        config: NgbPopoverConfig,
        ngZone: NgZone,
        @Inject(DOCUMENT) document: Document,
        changeDetector: ChangeDetectorRef,
        applicationRef: ApplicationRef
    ) {
        this.ngb = new NgbPopover(elementRef, renderer, injector, componentFactoryResolver, viewContainerRef, config, ngZone, document, changeDetector, applicationRef);
    }

    @HostListener("blur")
    onBlur() {
        this.ngb.close();
    }

    @HostListener("focus")
    onFocus() {
        this.ngb.open();
    }

    @HostListener("mouseover")
    onMouseOver() {
        this.ngb.open();
    }

    @HostListener("mouseout")
    onMouseOut() {
        this.ngb.close();
    }

    ngOnChanges() {
        // If we haven't been supplied ngrxFormControlState on the same element, or it is invalid, render the popover
        // This prevents expensive calls to positionElements() when the popover renders nothing
        if (this.#validationPopoverEnabled && (!this.state || this.state.isInvalid)) {
            this.ngb.ngbPopover = this.opsValidationPopover;
            const isTopPlacement = this.topPlacementTags.find((t) => t === this.elementRef.nativeElement.tagName);
            this.ngb.placement = isTopPlacement ? "top-left" : "bottom-left";

            if (this.validationPopoverContainer) {
                this.ngb.container = this.validationPopoverContainer;
            }
        } else {
            this.ngb.ngbPopover = null;
            this.ngb.container = null;
        }
    }
}
