import { createFeatureSelector, createSelector } from "@ngrx/store";

import { FixturePopupFeatureState, FixturePopupState } from "./state";

export const fixturePopupFeatureKey = "fixturePopup";
const selectFixturePopupFeature = createFeatureSelector<FixturePopupFeatureState, FixturePopupState>(fixturePopupFeatureKey);

export const selectFixturePopupData = createSelector(selectFixturePopupFeature, (state) => state?.data);
export const selectAnimatingSaveButton = createSelector(selectFixturePopupFeature, (state) => state?.animatingSaveButton);
export const selectUnlockOnClose = createSelector(selectFixturePopupFeature, (state) => state?.unlockOnClose);
