import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";

import { update } from "@ops/shared";
import { opsAddArrayControl } from "@ops/state";

import { AddActivityLocation, AddLaytimeEvent, LaytimeCalculationHttpService } from "../../../../../services";
import { selectLtcFeature } from "../../../../ltc.selectors";
import {
    ActivityLocation,
    ActivityLocationId,
    createActivityLocationLaytimeEventFormsState,
    getLaytimeCalculationState,
    idEq,
    LaytimeEvent,
    LaytimeEventId,
    LtcFeatureState,
    LtcId,
    LtcState,
    toLaytimeEventForm
} from "../../../../model";
import { toVoyageActivities, VoyageActivity } from "../../../add-activity-locations";
import { getDefaultLaytimeEventsForActivity, importPortTimesToAddActivityLocations, toAddActivityLocation } from "../../../add-activity-locations/form/add-activity-locations";
import { calculationStateReducer } from "../../../reducer";
import { UpdateQueue } from "../../../update-queue";
import { isAwaitingVoyageLoad, loadVoyageFailAction, loadVoyageSuccessAction, removeAwaitingVoyageLoadEntry, VoyageLoadAwaiter } from "../../../voyage/load-voyage";

/* ACTIONS */
const IMPORT_PORT_TIMES_ACTION_NAME = "[LTC Laytime Events Form] Import Port Times";
export const importPortTimesAction = createAction(
    IMPORT_PORT_TIMES_ACTION_NAME,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; laytimeEvents: ReadonlyArray<LaytimeEvent> }>()
);
export const importPortTimesSuccessAction = createAction(
    `${IMPORT_PORT_TIMES_ACTION_NAME} Success`,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; laytimeEvents: ReadonlyArray<LaytimeEvent> }>()
);
export const importPortTimesFailAction = createAction(`${IMPORT_PORT_TIMES_ACTION_NAME} Fail`, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; error: Error }>());

/* REDUCERS */
const VOYAGE_LOAD_AWAITER_TYPE: VoyageLoadAwaiter = "ImportPortTimes";
export const removeImportPortTimesAwaiterReducer: On<LtcState> = on(importPortTimesAction, loadVoyageFailAction, (state, { ltcId }) =>
    isAwaitingVoyageLoad(state, ltcId, VOYAGE_LOAD_AWAITER_TYPE) ? removeAwaitingVoyageLoadEntry(state, ltcId, VOYAGE_LOAD_AWAITER_TYPE) : state
);

export const importPortTimesReducer: On<LtcState> = on(importPortTimesAction, (state, { ltcId, activityLocationId, laytimeEvents }) =>
    calculationStateReducer(state, ltcId, (ltcState) => {
        if (ltcState.currentActivityLocationId !== activityLocationId) {
            return ltcState;
        }

        let laytimeEventForms = ltcState.laytimeEventForms;

        laytimeEvents.forEach((laytimeEvent) => {
            laytimeEventForms = opsAddArrayControl(toLaytimeEventForm(laytimeEvent))(laytimeEventForms);
        });

        return { ...ltcState, laytimeEventForms };
    })
);

export const importPortTimesSuccessReducer: On<LtcState> = on(importPortTimesSuccessAction, (state, { ltcId, activityLocationId, laytimeEvents }) =>
    calculationStateReducer(state, ltcId, R.evolve(updateActivityLocationLaytimeEventsFns(activityLocationId, laytimeEvents)))
);

export const importPortTimesFailReducer: On<LtcState> = on(importPortTimesFailAction, (state, { ltcId, activityLocationId }) =>
    calculationStateReducer(state, ltcId, (ltcState) => ({
        ...ltcState,
        laytimeEventForms: createActivityLocationLaytimeEventFormsState(
            activityLocationId,
            ltcState.calculation.activityLocations.find((al) => al.id === activityLocationId).laytimeEvents
        )
    }))
);

/* EFFECTS */
export const processVoyageLoadSuccessForImportPortTimesEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(loadVoyageSuccessAction),
            withLatestFrom(store.select(selectLtcFeature)),
            filter(([{ ltcId }, state]) => isAwaitingVoyageLoad(state, ltcId, VOYAGE_LOAD_AWAITER_TYPE)),
            map(([{ ltcId, voyage }, state]) => {
                const ltcState = getLaytimeCalculationState(state, ltcId);

                const activityLocationIdsToImportPortTimesFor = ltcState.awaitingVoyageLoad
                    .filter((a) => a.startsWith(VOYAGE_LOAD_AWAITER_TYPE))
                    .map((a) => a.replace(`${VOYAGE_LOAD_AWAITER_TYPE}::`, ""));
                const calculation = ltcState.calculation;

                const addActivityLocations: { [voyageActivityId: string]: AddActivityLocation } = {};
                const voyageActivities = R.pipe(
                    R.map((va: VoyageActivity) => ({ [va.voyageActivityId]: va })),
                    R.mergeAll
                )(toVoyageActivities(voyage));

                const importedLaytimeEventsByActivityLocation: { [activityLocationId: string]: string[] } = {};
                activityLocationIdsToImportPortTimesFor.forEach((alId) => {
                    const activityLocation = calculation.activityLocations.find((a) => a.id === alId);
                    if (!voyageActivities[activityLocation.voyageActivityId].laytimeEvents) {
                        voyageActivities[activityLocation.voyageActivityId] = {
                            ...voyageActivities[activityLocation.voyageActivityId],
                            laytimeEvents: getDefaultLaytimeEventsForActivity(voyageActivities[activityLocation.voyageActivityId], ltcState.fixture, voyage)
                        };
                    }
                    const voyageActivity = {
                        ...voyageActivities[activityLocation.voyageActivityId],
                        laytimeEvents: voyageActivities[activityLocation.voyageActivityId].laytimeEvents.filter(
                            (l) => !activityLocation.laytimeEvents.map((le) => le.id).includes(l.id as LaytimeEventId)
                        )
                    };

                    if (voyageActivity.laytimeEvents.length) {
                        importedLaytimeEventsByActivityLocation[activityLocation.id] = voyageActivity.laytimeEvents.map((l) => l.id);
                        addActivityLocations[voyageActivity.voyageActivityId] = toAddActivityLocation(voyageActivity, activityLocation.id);
                    }
                });

                importPortTimesToAddActivityLocations(addActivityLocations, voyageActivities, voyage, calculation, ltcState?.fixture);

                const cargoes = voyage.cargoes.filter((x) => x.cargoProduct).map((c) => c.cargoProduct);

                return {
                    ltcId,
                    laytimeEventsByActivityLocationId: R.values(addActivityLocations)
                        .filter(({ id }) => importedLaytimeEventsByActivityLocation[id])
                        .map(({ id, laytimeEvents }) => ({
                            activityLocationId: id,
                            laytimeEvents: laytimeEvents
                                .filter((l) => importedLaytimeEventsByActivityLocation[id].includes(l.id))
                                .map((ale: AddLaytimeEvent): LaytimeEvent => ({ ...ale, cargoName: !ale.cargoId ? null : cargoes.find((c) => c.id).name }))
                        }))
                };
            }),
            mergeMap(({ ltcId, laytimeEventsByActivityLocationId }) =>
                laytimeEventsByActivityLocationId
                    .filter((x) => x.laytimeEvents.length)
                    .map((x) => importPortTimesAction({ ltcId, activityLocationId: x.activityLocationId, laytimeEvents: x.laytimeEvents }))
            ),
            tap((action) => updateQueue.enqueue(action, importPortTimes))
        )
    );

export const importPortTimes = (
    { ltcId, activityLocationId, laytimeEvents }: ReturnType<typeof importPortTimesAction>,
    laytimeCalculationHttpService: LaytimeCalculationHttpService
) =>
    laytimeCalculationHttpService.addLaytimeEvents(ltcId, activityLocationId, laytimeEvents).pipe(
        map(() => importPortTimesSuccessAction({ ltcId, activityLocationId, laytimeEvents })),
        catchError((error) => of(importPortTimesFailAction({ ltcId, activityLocationId, error })))
    );

/* FUNCTIONS */
const updateActivityLocationLaytimeEventsFns = (activityLocationId: ActivityLocationId, laytimeEvents: ReadonlyArray<LaytimeEvent>): Evolver => ({
    calculation: { activityLocations: update(idEq(activityLocationId), (al: ActivityLocation) => ({ ...al, laytimeEvents: [...al.laytimeEvents, ...laytimeEvents] })) }
});
