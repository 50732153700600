import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroupState } from "ngrx-forms";
import { VoyageForm } from "../../state/model";
import { Audit } from "../../state/model";

@Component({
  selector: "ops-public-notes-form",
  templateUrl: "./public-notes-form.component.html",
  styleUrls: ["./public-notes-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublicNotesFormComponent {
    @Input() form: FormGroupState<VoyageForm>;
    @Input() notesAudit: Audit;
    @Input() readonly: boolean;
}
