import { On } from "@ngrx/store";
import { disable, enable, setValue, SetValueAction, StateUpdateFns, updateGroup } from "ngrx-forms";
import { coaStateReducer } from "../../coa/reducer";
import { CoaStatus } from "../../model/coa";
import { CoaHeaderForm } from "../../model/header";
import { CoasState, CoaState } from "../../model/state";

/**
 * Enables cancellation reason dropdown when status is changed to 'Cancelled', and disables it when status is Open / Closed.
 */
export const changeCoaStatusReducer: On<CoasState> = {
    types: [SetValueAction.TYPE],
    reducer: (state: CoasState, action: SetValueAction<CoaStatus>): CoasState => {
        const controlPath = action.controlId.split(".");
        if (controlPath.length !== 2 || controlPath[1] !== "contractStatus") {
            return state;
        }

        return coaStateReducer(state, controlPath[0], (coaState) => {
            const updateFns: StateUpdateFns<CoaHeaderForm>[] =
                action.value === "Cancelled"
                    ? [{ cancellationReason: enable }]
                    : [{ cancellationReason: disable }, { cancellationReason: setValue<string>(null) }];

            return <CoaState>{
                ...coaState,
                coaHeaderForm: updateGroup<CoaHeaderForm>(updateFns)(coaState.coaHeaderForm)
            };
        });
    }
};
