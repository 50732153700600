import { AbstractControl, ValidatorFn } from "@angular/forms";
import { FixtureTemplateType } from "src/app/shared/reference-data/fixture-template-type";

export class CoAReferenceValidator {
    static validate(): ValidatorFn {
        return (control: AbstractControl): null => {
            const coANumberControl = control.get("coANumber");
            const fixtureTypeControl = control.get("fixtureType");

            if (coANumberControl && fixtureTypeControl) {
                const coANumber = coANumberControl.value;
                const fixtureType = fixtureTypeControl.value;

                if (!coANumber && fixtureType.id === FixtureTemplateType.VoyageCoAType.id) {
                    coANumberControl.setErrors({ required: true });
                }
            }

            return null;
        };
    }
}
