import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { FormGroupState } from "ngrx-forms";
import { Observable } from "rxjs";

import { VerifiedTimestamp } from "../../../shared/components/timestamp/verified-timestamp.model";
import {
    DestinationForm,
    FixtureFeatureState,
    selectCurrentVoyageDestinationDefaultEtaFocusDate,
    selectCurrentVoyageDestinationEtaVerified,
    selectCurrentVoyageDestinationFormState,
    selectCurrentVoyageDestinationHasEtaWarning,
    selectCurrentVoyageDestinationIsTransient,
    selectCurrentVoyageDestinationLocationTimezone,
    verifyEta
} from "../../state";
import { FixturePopupTab } from "../models/fixture-popup-tab";

@Component({
    selector: "ops-fixture-popup-destination-tab-header",
    templateUrl: "./fixture-popup-destination-tab-header.component.html",
    styleUrls: ["./fixture-popup-destination-tab-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupDestinationTabHeaderComponent implements OnInit {
    form$: Observable<FormGroupState<DestinationForm>>;
    timeZone$: Observable<string>;
    hasEtaWarning$: Observable<boolean>;
    etaVerifiedAudit$: Observable<VerifiedTimestamp>;
    isTransient$: Observable<boolean>;
    etaDefaultFocusDate$: Observable<DateTime>;

    @Input() tab: FixturePopupTab;

    constructor(private store: Store<FixtureFeatureState>) {}

    ngOnInit() {
        this.form$ = this.store.select(selectCurrentVoyageDestinationFormState, this.tab.activityInfo);
        this.timeZone$ = this.store.select(selectCurrentVoyageDestinationLocationTimezone, this.tab.activityInfo);
        this.hasEtaWarning$ = this.store.select(selectCurrentVoyageDestinationHasEtaWarning, this.tab.activityInfo);
        this.etaVerifiedAudit$ = this.store.select(selectCurrentVoyageDestinationEtaVerified, this.tab.activityInfo);
        this.isTransient$ = this.store.select(selectCurrentVoyageDestinationIsTransient, this.tab.activityInfo);
        this.etaDefaultFocusDate$ = this.store.select(selectCurrentVoyageDestinationDefaultEtaFocusDate, this.tab.activityInfo);
    }

    verifyEta() {
        this.store.dispatch(verifyEta({ destinationId: this.tab.activityInfo?.destinationId }));
    }
}
