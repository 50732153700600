import { Component, Input } from "@angular/core";
import { VerifiedTimestamp } from "./verified-timestamp.model";

@Component({
    selector: "ops-verified-timestamp",
    templateUrl: "./verified-timestamp.component.html",
    styleUrls: ["./verified-timestamp.component.scss"]
})
export class VerifiedTimestampComponent {
    @Input() verified: VerifiedTimestamp;
}
