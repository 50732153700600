import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: "ops-deletion-confirmation",
    templateUrl: "./deletion-confirmation.component.html",
    styleUrls: ["./deletion-confirmation.component.scss"]
})
export class DeletionConfirmationComponent {
    displayConfirmation = false;
    animating = false;

    @ViewChild("toggle", { static: true }) toggleButton: ElementRef;
    @ViewChild("confirmationWrapper", { static: true }) confirmationWrapper: ElementRef;

    @Input() disabled: boolean;
    @Input() buttonClass: string;
    @Output() readonly deleteConfirmed = new EventEmitter();
    @Output() readonly displayStatus = new EventEmitter<boolean>();

    get showBg() {
        return !(!this.animating && !this.displayConfirmation);
    }

    toggleConfirmation() {
        if (!this.disabled) {
            this.animating = true;
            this.displayConfirmation = !this.displayConfirmation;
            this.displayStatus.emit(this.displayConfirmation);
        }
    }

    animationEnded() {
        this.animating = false;
    }

    confirmDelete() {
        this.deleteConfirmed.emit();
    }

    clickOutside() {
        if (!this.disabled && this.displayConfirmation) {
            this.toggleConfirmation();
        }
    }

    handleTab(event: KeyboardEvent) {
        if (this.displayConfirmation) {
            event.stopPropagation();

            setTimeout(() =>
                <HTMLElement>event.target === this.toggleButton.nativeElement ? this.confirmationWrapper.nativeElement.children[0].focus() : this.toggleButton.nativeElement.focus()
            );
        }
    }
}
