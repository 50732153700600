import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { SetValueAction } from "ngrx-forms";
import { filter, map, withLatestFrom } from "rxjs/operators";

import { CargoBerthActivityType } from "@ops/shared/reference-data";
import { selectCurrentUser, User } from "@ops/state";

import { updateEtaVerifiedOnVoyage } from "../../../destinations/form/verify-eta";
import { selectCurrentFixtureId } from "../../../fixture";
import { CargoForm, DestinationId, FixtureFeatureState, FixtureId, FixturesState, VoyageId } from "../../../model";
import { selectCurrentVoyageFormValue } from "../../../voyage";
import { voyageStateReducer } from "../../../voyage/reducer";
import { findAllSpecialisedCargoDestinationElements } from "./specialised-cargo-functions";

/* ACTIONS */
export const markSpecialisedCargoEtaVerifiedAction = createAction(
    "[Voyage Form] Mark Specialised Cargo ETA Verified",
    props<{ fixtureId: FixtureId; voyageId: VoyageId; destinationId: DestinationId; date: string; user: User }>()
);

/* REDUCERS */
export const verifySpecialisedCargoEtaSuccessReducer: On<FixturesState> = on(markSpecialisedCargoEtaVerifiedAction, (state, { voyageId, destinationId, date, user }) =>
    voyageStateReducer(state, voyageId, (voyageState) => ({
        ...voyageState,
        workingVoyage: voyageState.workingVoyage ? updateEtaVerifiedOnVoyage(voyageState.workingVoyage, destinationId, date, user) : voyageState.workingVoyage
    }))
);

/* EFFECTS */
export const markSpecialisedCargoEtaVerifiedOnEtaChangeEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<CargoForm>>(SetValueAction.TYPE),
            map((action) => action.controlId.split(".")),
            filter((controlPath) => controlPath.length === 5 && controlPath[1] === "cargoes" && controlPath[4] === "eta"),
            withLatestFrom(store.select(selectCurrentFixtureId), store.select(selectCurrentVoyageFormValue), store.select(selectCurrentUser)),
            map(([[, , cargoIndex, locationType], fixtureId, { voyageId, destinations, cargoes }, user]) => {
                const cargoId = cargoes[Number(cargoIndex)].cargoId;
                const type = locationType === "loadLocation" ? CargoBerthActivityType.Load : CargoBerthActivityType.Discharge;
                const { destination } = findAllSpecialisedCargoDestinationElements(cargoId, type, destinations);
                return markSpecialisedCargoEtaVerifiedAction({
                    fixtureId: fixtureId,
                    voyageId: voyageId,
                    destinationId: destination.id,
                    date: DateTime.utc().toISO(),
                    user: user
                });
            })
        )
    );
