import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { removeArrayControl, updateGroup } from "ngrx-forms";
import * as R from "ramda";
import { of } from "rxjs";
import { switchMap, withLatestFrom } from "rxjs/operators";

import { FixtureDataService } from "../../../services/fixture-data.service";
import { FixtureType } from "../../../shared/models/enums/fixture-type";
import { selectCurrentFixtureType } from "../../fixture";
import { destinationExpandedKey, DestinationId, FixtureFeatureState, FixturesState, VoyageExpandedSections, VoyageForm } from "../../model";
import { selectCurrentVoyageState } from "../../voyage";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const removeDestinationAction = createAction("[Voyage Form] Remove Destination", props<{ destinationId: DestinationId }>());
export const removeDestinationFromVoyageAction = createAction("[Voyage Form] Remove Destination From Voyage", props<{ destinationId: DestinationId }>());

/* REDUCERS */
export const removeDestinationReducer: On<FixturesState> = on(removeDestinationFromVoyageAction, (state, { destinationId }) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const destinationIndex = voyageState.form.value.destinations.map((x) => x.id).indexOf(destinationId);
        const availableAtSeaBunkersConsumption = voyageState.workingVoyage.atSeaBunkersConsumption.filter(
            (r) => r.destinationFromId !== destinationId && r.destinationToId !== destinationId
        );

        if (destinationIndex < 0) {
            return voyageState;
        }

        return {
            ...voyageState,
            form: updateGroup<VoyageForm>({
                destinations: removeArrayControl(destinationIndex)
            })(voyageState.form),
            workingVoyage: {
                ...voyageState.workingVoyage,
                atSeaBunkersConsumption: availableAtSeaBunkersConsumption
            },
            expandedSections: <VoyageExpandedSections>R.omit([destinationExpandedKey(destinationId)], voyageState.expandedSections)
        };
    })
);

/* EFFECTS */
export const interopRemoveDestinationFromDemurrageEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, fixtureService: FixtureDataService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeDestinationAction),
            withLatestFrom(store.select(selectCurrentVoyageState), store.select(selectCurrentFixtureType)),
            switchMap(([{ destinationId }, currentVoyageState, fixtureType]) => {
                if (fixtureType === FixtureType.Voyage) {
                    const destination = currentVoyageState.workingVoyage.destinations.find((d) => d.id === destinationId);
                    fixtureService.removeLocationFromDemurrageClaim(destination.destinationId);
                }
                return of(removeDestinationFromVoyageAction({ destinationId }));
            })
        )
    );
