import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { combineLatest, Observable, Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";

import { isNullOrUndefined } from "@ops/shared";

import { VoyageDataService } from "../../fixture/services/voyage-data.service";
import { VoyageNumber, VoyageNumberService } from "../../fixture/services/voyage-number.service";
import { LeftBarStateService } from "../left-bar-state.service";
import { NoonReportDataService } from "./services/noon-report-data.service";
import { NoonReportEditStateService } from "./services/noon-report-edit-state.service";
import { NoonReport } from "./services/noon-report.dto";

@Component({
    selector: "ops-noon-reports",
    templateUrl: "./noon-reports.component.html",
    styleUrls: ["./noon-reports.component.scss"],
    providers: [NoonReportEditStateService]
})
export class NoonReportsComponent implements OnInit, OnDestroy {
    static componentName = "NoonReportsComponent";
    private readonly destroy$ = new Subject();

    voyageNumbers$: Observable<ReadonlyArray<VoyageNumber>>;
    selectedVoyageId$: Observable<string>;
    noonReports$: Observable<NoonReport[]>;
    loading$: Observable<boolean>;

    constructor(
        private voyageDataService: VoyageDataService,
        private noonReportDataService: NoonReportDataService,
        private noonReportEditStateService: NoonReportEditStateService,
        private voyageNumberService: VoyageNumberService,
        private router: Router,
        private leftBarStateService: LeftBarStateService
    ) {}

    ngOnInit() {
        this.voyageNumbers$ = this.voyageNumberService.voyageNumbers$.pipe(takeUntil(this.destroy$));
        this.selectedVoyageId$ = this.voyageDataService.current$.pipe(
            takeUntil(this.destroy$),
            filter((voyage) => !isNullOrUndefined(voyage)),
            map((voyage) => voyage.voyageId)
        );
        this.noonReports$ = this.noonReportDataService.noonReports$.pipe(takeUntil(this.destroy$));
        this.loading$ = combineLatest([this.voyageDataService.loading$, this.noonReportDataService.loading$]).pipe(
            takeUntil(this.destroy$),
            map(([voyageLoading, noonReportsLoading]: [boolean, boolean]) => voyageLoading || noonReportsLoading)
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    voyageSelected(selected: VoyageNumber) {
        this.navigateToVoyage(selected.fixtureId, selected.voyageId);
    }

    createNoonReport() {
        this.noonReportDataService
            .add()
            .pipe(takeUntil(this.destroy$))
            .subscribe((noonReport) => {
                this.noonReportEditStateService.setInEditMode(noonReport.noonReportId);
            });
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }

    isInEditMode(noonReport: NoonReport) {
        return !noonReport.voyageId || this.noonReportEditStateService.isInEditMode(noonReport.noonReportId);
    }

    private navigateToVoyage(fixtureId: string, voyageId: string) {
        return this.router.navigate([{ outlets: { primary: ["fixture", fixtureId, "voyage", voyageId] } }], {
            replaceUrl: true,
            queryParamsHandling: "merge"
        });
    }
}
