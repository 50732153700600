import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { ActivityType, LaytimeCalculationCargoTermsForm, LaytimeCalculationCargoTermsListItem, LaytimeCalculationCargoTermsLocationListItem, LocationId } from "../../state";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "tr[ops-laytime-calculation-cargo-term-row]",
    templateUrl: "./laytime-calculation-cargo-term-row.component.html",
    styleUrls: ["./laytime-calculation-cargo-term-row.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationCargoTermRowComponent {
    @Input() cargoTerm: LaytimeCalculationCargoTermsListItem;
    @Input() isEditing: boolean;
    @Input() form?: LaytimeCalculationCargoTermsForm;
    @Input() error: Error;

    @Output() readonly edit = new EventEmitter();
    @Output() readonly remove = new EventEmitter();
    @Output() readonly removeLocation = new EventEmitter<{ activityType: ActivityType; locationId: LocationId }>();

    trackBy(_index: number, item: LaytimeCalculationCargoTermsLocationListItem) {
        return item.locationId;
    }

    getVisibleLocations(locations: ReadonlyArray<LaytimeCalculationCargoTermsLocationListItem>, activityType: ActivityType) {
        if (!this.form || ["Load", "Discharge"].indexOf(activityType) < 0) {
            return locations;
        }

        const formLocations = activityType === "Load" ? this.form.loadLocations : this.form.dischargeLocations;
        return locations.filter((l) => formLocations.value.map((fl) => fl.locationId).indexOf(l.locationId) > -1);
    }

    handleSelect() {
        if (!this.isEditing) {
            this.edit.emit();
        }
    }
}
