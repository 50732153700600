import {
    addActivityLocationsFailReducer,
    addActivityLocationsReducer,
    addActivityLocationsSuccessReducer,
    loadVoyageForAddActivityLocationsReducer,
    loadVoyageForAddActivityLocationsSuccessReducer
} from "./form/add-activity-locations";
import {
    addActivityLocationsChangeFilterForCargoReducer,
    addActivityLocationsChangeFilterForVoyageActivityReducer,
    addActivityLocationsChangeFilterReducer
} from "./form/change-filter";
import {
    addActivityLocationsFilterSelectAllReducer,
    addActivityLocationsSelectionSelectAllByCargoReducer,
    addActivityLocationsSelectionSelectAllByVoyageActivityReducer,
    addActivityLocationsSelectionSelectAllReducer
} from "./form/select-all";
import { upsertCargoTermsSuccessReducer } from "./form/upsert-cargo-terms";

export default [
    addActivityLocationsFilterSelectAllReducer,
    addActivityLocationsSelectionSelectAllReducer,
    addActivityLocationsReducer,
    addActivityLocationsSuccessReducer,
    addActivityLocationsFailReducer,
    addActivityLocationsChangeFilterReducer,
    addActivityLocationsSelectionSelectAllByCargoReducer,
    addActivityLocationsSelectionSelectAllByVoyageActivityReducer,
    addActivityLocationsChangeFilterForVoyageActivityReducer,
    addActivityLocationsChangeFilterForCargoReducer,
    loadVoyageForAddActivityLocationsSuccessReducer,
    loadVoyageForAddActivityLocationsReducer,
    upsertCargoTermsSuccessReducer
];
