import { createSelector } from "@ngrx/store";

import { getGridRecordNumbers, GridPage, isFinalStatus, SearchCriteria } from "@ops/state";

import { selectLtcFeature } from "../../ltc.selectors";
import { getGridState, LaytimeCalculationGridState, LtcFeatureState } from "../../model";
import { getLaytimeCalculationGridRow, getLaytimeCalculationGridSorting, LaytimeCalculationGridRow } from "./laytime-calculation-grid-row";

export const selectLaytimeCalculationGridState = createSelector(selectLtcFeature, getGridState);

export const selectLaytimeCalculationSuggestions = createSelector(selectLaytimeCalculationGridState, (state) => state?.suggestions);
export const selectLaytimeCalculationSuggestionItems = createSelector(selectLaytimeCalculationSuggestions, (state) => state?.items);
export const selectLaytimeCalculationSuggestionsLoading = createSelector(selectLaytimeCalculationSuggestions, (state) => state?.loadStatus === "loading");

export const selectLaytimeCalculationGridCriteria = createSelector(selectLaytimeCalculationGridState, (state) => state?.criteria);
export const selectLaytimeCalculationGridSearchCriteria = createSelector<LtcFeatureState, LaytimeCalculationGridState, SearchCriteria>(
    selectLaytimeCalculationGridState,
    (state) => state && { criteria: state.criteria, sorting: getLaytimeCalculationGridSorting(state.sorting) }
);

export const selectLaytimeCalculationGridPage = createSelector<LtcFeatureState, { requestId: string }, LaytimeCalculationGridState, GridPage<LaytimeCalculationGridRow>>(
    selectLaytimeCalculationGridState,
    (laytimeCalculationGridState, { requestId }) => {
        const state = laytimeCalculationGridState.data[requestId];
        return (
            isFinalStatus(state?.loadStatus) && {
                loadStatus: state.loadStatus,
                items: state.items?.map(getLaytimeCalculationGridRow),
                error: state.error
            }
        );
    }
);

export const selectLaytimeCalculationGridRecordNumbers = createSelector(selectLaytimeCalculationGridState, (laytimeCalculationGridState) =>
    getGridRecordNumbers(laytimeCalculationGridState)
);
