import { AfterViewInit, Directive, ElementRef, HostListener, NgZone, OnDestroy } from "@angular/core";

import { Key, measureTextWidth } from "../../utils";
import { getWordBounds, selectWord } from "./select-words.handler";
import { Subject, fromEvent } from "rxjs";
import { filter, mapTo, takeUntil } from "rxjs/operators";

/**
 * Handles selecting words in an input when the field is focused or a word is clicked on.
 */
@Directive({ selector: "input[opsSelectWords]" })
export class SelectWordsDirective implements AfterViewInit, OnDestroy {
    private readonly destroy$ = new Subject();

    private selection?: readonly [start: number, end: number];
    private isMousePressed = false;
    private isShiftPressed = false;

    private get disabled() {
        const target = this.element.nativeElement;

        return target.readOnly || target.disabled;
    }

    constructor(private element: ElementRef<HTMLInputElement>, private ngZone: NgZone) {}

    ngAfterViewInit() {
        this.ngZone.runOutsideAngular(() => {
            const isShiftEvent = (event: KeyboardEvent) => event.key === Key.Shift;
            const updateIsShiftPressed = (isShiftPressed: boolean) => (this.isShiftPressed = isShiftPressed);

            fromEvent<KeyboardEvent>(window, "keydown").pipe(filter(isShiftEvent), mapTo(true), takeUntil(this.destroy$)).subscribe(updateIsShiftPressed);

            fromEvent<KeyboardEvent>(window, "keyup").pipe(filter(isShiftEvent), mapTo(false), takeUntil(this.destroy$)).subscribe(updateIsShiftPressed);
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
    }

    @HostListener("mousedown")
    handleMouseDown() {
        this.isMousePressed = true;
    }

    /**
     * Handles clicking on a word in the input.
     *
     * @private
     */
    @HostListener("mouseup", ["$event"])
    handleMouseUp(event: MouseEvent) {
        if (this.disabled) {
            return;
        }

        this.isMousePressed = false;

        const target = this.element.nativeElement;

        if (target.value && (!this.selection || !(target.selectionStart >= this.selection[0] && target.selectionStart <= this.selection[1]))) {
            this.selection = selectWord(target);
            event.preventDefault();
        }
    }

    /**
     * Handles tabbing into the input (including reverse).
     *
     * @private
     */
    @HostListener("focus")
    handleFocus() {
        if (this.disabled) {
            return;
        }

        const target = this.element.nativeElement;

        // Ignore if focused by mouse
        if (!this.isMousePressed && target.value && !this.selection) {
            // If the shift key is pressed, focus the last word
            const targetPosition = this.isShiftPressed ? target.value.length : 0;

            this.selection = selectWord(target, targetPosition);
        }
    }

    @HostListener("blur")
    handleBlur() {
        this.selection = null;
    }

    @HostListener("keydown", ["$event"])
    handleKeyDown(event: KeyboardEvent) {
        if (this.disabled || event.key !== Key.Tab) {
            return;
        }

        const target = this.element.nativeElement;
        if (!target.value) {
            return;
        }

        const text = target.value;
        const bounds = getWordBounds(text);
        if (!bounds) {
            return;
        }

        const currentBoundIndex = bounds.findIndex(([start, end]) => target.selectionStart >= start && target.selectionStart <= end);
        const targetBoundIndex = currentBoundIndex + (event.shiftKey ? -1 : 1);

        if (-1 < targetBoundIndex && targetBoundIndex < bounds.length) {
            //Stoppropagation is needed for making tabbing work with PrimeNg table
            event.stopPropagation();
            event.preventDefault();

            const [start, end] = (this.selection = bounds[targetBoundIndex]);

            target.setSelectionRange(start, end);

            // If the input scrolling, we need to ensure that the selected text is in view
            if (target.scrollWidth <= target.clientWidth) {
                return;
            }

            const inputStyle = getComputedStyle(target, null);
            const font = inputStyle.getPropertyValue("font");

            target.scrollLeft = measureTextWidth(text.substr(0, start > 0 ? start - 1 : start), font);
        }
    }
}
