export declare type FixtureWarningState = Readonly<{
    isEditMode: boolean;
    warning: FixtureWarning;
    isDismissed: boolean;
}>;

export class FixtureWarning {
    get modelPath(): string[] {
        return this.path.filter((x) => x.segment).map((x) => x.segment);
    }

    constructor(public category: "data" | "eta" | "freight" | "claim", public message: string, public path: FixtureWarningPath, public record?: DismissedWarningRecord) {}
}

export declare type FixtureWarningPath = Array<FixtureWarningPathSegment>;

export class FixtureWarningPathSegment {
    constructor(public segment: string, public name: string) {}

    static fromIndex(index: number, typeName: string, name?: string) {
        if (!name && typeName) {
            name = `${typeName} ${index + 1}`;
        }

        return new FixtureWarningPathSegment(`[${index}]`, name);
    }

    static fromProperty<T>(property: keyof T, name?: string) {
        return new FixtureWarningPathSegment(<string>property, name);
    }
}

export declare type DismissedWarningRecord = Readonly<{
    warningHash: string;
    fieldValue?: unknown;
}>;

export const dismissedWarningRecord = (warningHash: string, fieldValue?: unknown) => ({
    warningHash,
    fieldValue
});
