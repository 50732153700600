import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { AssociatedCargoesByCargo, AssociatedCargoSelection, CargoId, isSelectAllEnabled } from "../../../state";

@Component({
    selector: "ops-associated-cargoes-by-cargo",
    templateUrl: "./associated-cargoes-by-cargo.component.html",
    styleUrls: ["./associated-cargoes-by-cargo.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociatedCargoesByCargoComponent {
    @Input() associatedCargoesByCargo: AssociatedCargoesByCargo;
    @Output() readonly selectAllByCargo = new EventEmitter<CargoId>();

    trackBy(index: number, item: AssociatedCargoSelection) {
        return item.associatedCargoId;
    }
    get isSelectAllEnabled() {
        return isSelectAllEnabled(this.associatedCargoesByCargo.associatedCargoes);
    }
}
