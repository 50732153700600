import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";

import { LiftingHttpService } from "../../../../services";
import { selectCurrentCoaId } from "../../../coa";
import { CoaFeatureState, CoasState, Company, LiftingId } from "../../../model";
import { currentLiftingStateReducer, liftingStateReducer } from "../../reducer";
import { selectCurrentLiftingId } from "../../selectors";

/* ACTIONS */
const REMOVE_COMPANY_ACTION_NAME = "[Lifting Contacts Panel] Remove Company";
export const removeLiftingCompanyAction = createAction(REMOVE_COMPANY_ACTION_NAME, props<{ company: Company }>());
export const removeLiftingCompanySuccessAction = createAction(`${REMOVE_COMPANY_ACTION_NAME} Success`, props<{ liftingId: LiftingId; company: Company }>());
export const removeLiftingCompanyFailAction = createAction(`${REMOVE_COMPANY_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; company: Company; error: Error }>());

/* REDUCERS */
export const removeLiftingCompanyReducer: On<CoasState> = on(removeLiftingCompanyAction, (state, { company }) =>
    currentLiftingStateReducer(state, (liftingState) => ({
        ...liftingState,
        lifting: {
            ...liftingState.lifting,
            contacts: liftingState.lifting.contacts.filter((c) => !(c.accountId === company.accountId && c.role === company.role))
        }
    }))
);

export const removeLiftingCompanyFailReducer: On<CoasState> = on(removeLiftingCompanyFailAction, (state, { liftingId, company, error }) =>
    liftingStateReducer(state, liftingId, (liftingState) => ({
        ...liftingState,
        lifting: {
            ...liftingState.lifting,
            contacts: [...liftingState.lifting.contacts, company]
        },
        erroredItems: [...(liftingState.erroredItems || []), { itemType: "contacts", id: company.accountId, error }]
    }))
);

/* EFFECTS */
export const removeLiftingCompanyEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeLiftingCompanyAction),
            withLatestFrom(store.select(selectCurrentCoaId), store.select(selectCurrentLiftingId)),
            mergeMap(([action, coaId, liftingId]) =>
                liftingService.removeCompany(coaId, liftingId, action.company).pipe(
                    map(() => removeLiftingCompanySuccessAction({ liftingId, company: action.company })),
                    catchError((err) => of(removeLiftingCompanyFailAction({ ...action, liftingId, error: err })))
                )
            )
        )
    );
