import { Dictionary } from "@ngrx/entity";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FixtureActions } from "./action.model";
import { ActionState, adapter } from "./action.reducer";

export const actionsFeatureKey = "actions";
export const selectActionState = createFeatureSelector<ActionState>(actionsFeatureKey);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllActions = createSelector(
    selectActionState,
    selectAll
);

export const selectActionIds = createSelector(
    selectActionState,
    selectIds
);

export const selectActionEntities = createSelector(
    selectActionState,
    selectEntities
);

export const selectActionTotal = createSelector(
    selectActionState,
    selectTotal
);

const selectFixtureActions = (fixtureId: string) =>
    createSelector(
        selectActionEntities,
        (entities: Dictionary<FixtureActions>) => fixtureId && entities[fixtureId]
    );

export const selectActions = (fixtureId: string) =>
    createSelector(
        selectFixtureActions(fixtureId),
        (fixtureActions) => fixtureActions && fixtureActions.actions
    );

export const selectLoadingStatus = (fixtureId: string) =>
    createSelector(
        selectFixtureActions(fixtureId),
        (fixtureActions) => fixtureActions && fixtureActions.loading
    );

export const selectError = (fixtureId: string) =>
    createSelector(
        selectFixtureActions(fixtureId),
        (fixtureActions) => fixtureActions && fixtureActions.error
    );
