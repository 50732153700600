import { Injectable } from "@angular/core";

import { hasValue } from "@ops/shared";

import { DemurrageClaim, ExpenseClaim, Fixture, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class DataDuplicatedClaimRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture, voyages: Voyage[], expenses: ExpenseClaim[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        if (this.anyDuplicateClaims(fixture.demurrage?.claims)) {
            const message = "Duplicate Demurrage Claim";
            const path = [new FixtureWarningPathSegment("demurrage", "Demurrage")];
            const warningHash = new WarningHashBuilder().withFixture(fixture.fixtureId).withCategory("data").withMessage(message).build();
            warnings.push(new FixtureWarning("data", message, path, dismissedWarningRecord(warningHash)));
        }

        if (this.anyDuplicateClaims(expenses)) {
            const message = "Duplicate Expense Claim";
            const path = [new FixtureWarningPathSegment("expenses", "Expenses")];
            const warningHash = new WarningHashBuilder().withFixture(fixture.fixtureId).withCategory("data").withMessage(message).build();
            warnings.push(new FixtureWarning("data", message, path, dismissedWarningRecord(warningHash)));
        }

        return warnings;
    }

    private anyDuplicateClaims(claims: Array<ExpenseClaim | DemurrageClaim>) {
        const checkedClaims = new Map<string, ExpenseClaim | DemurrageClaim>();

        return claims && claims.some((claim) => this.isDuplicateClaim(claim, checkedClaims));
    }

    private isDuplicateClaim(claim: ExpenseClaim | DemurrageClaim, checkedClaims: Map<string, ExpenseClaim | DemurrageClaim>): boolean {
        // Compare currency in hash, but ignore if it's the only field set
        if (
            !hasValue(claim.type?.id) &&
            !hasValue(claim.sentToChartererDate) &&
            !hasValue(claim.chartererAcknowledgedReceiptDate) &&
            !hasValue(claim.initialClaimValue) &&
            !hasValue(claim.finalClaimValue)
        ) {
            return false;
        }

        const claimHash = this.getClaimHash(claim);

        if (!claimHash) {
            return false;
        }

        if (checkedClaims.has(claimHash)) {
            return true;
        }

        checkedClaims.set(claimHash, claim);
        return false;
    }

    private getClaimHash(claim: ExpenseClaim | DemurrageClaim): string {
        return [claim.type?.id, claim.sentToChartererDate, claim.chartererAcknowledgedReceiptDate, claim.currency?.code, claim.initialClaimValue, claim.finalClaimValue]
            .filter((x) => !!x)
            .join("");
    }
}
