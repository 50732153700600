import { Injectable } from "@angular/core";
import { partition } from "ramda";
import { combineLatest } from "rxjs";
import { distinctUntilChanged, filter, pairwise } from "rxjs/operators";

import { deepCopy, isNumber } from "@ops/shared";
import { CargoBerthActivityType, FixtureType, FreightRateUnit, FreightType } from "@ops/shared/reference-data";

import { FixtureDataService } from "./fixture-data.service";
import { VoyageDataService } from "./voyage-data.service";
import { AssociatedCargo, CargoBerthActivity, Fixture, Voyage } from "../shared/models";

@Injectable({
    providedIn: "root"
})
export class LumpSumFreightRateService {
    constructor(fixtureService: FixtureDataService, voyageService: VoyageDataService) {
        combineLatest([fixtureService.currentFixture$, voyageService.current$])
            .pipe(
                filter(([fixture, voyage]) => fixture && fixture.fixtureType.id === FixtureType.Voyage.id && voyage && fixture.fixtureId === voyage.fixtureId),
                distinctUntilChanged((prev, next) => this.sameData(prev, next)),
                pairwise(),
                filter(this.calcNeeded)
            )
            .subscribe(([[oldFixture], [newFixture, newVoyage]]) => {
                const workingVoyage = deepCopy(newVoyage);

                this.updateWorkingVoyage(workingVoyage, oldFixture, newFixture);

                // https://github.com/ReactiveX/rxjs/issues/2155
                setTimeout(() => voyageService.publishUpdate(workingVoyage));
            });
    }

    calcActualFreightRate(voyage: Voyage, lumpsum: number) {
        const activities = voyage.destinations
            .map((x) => x.berths)
            .reduce((a, b) => a.concat(b), [])
            .map((x) => x.cargoBerthActivities)
            .reduce((a, b) => a.concat(b), [])
            .filter((x) => x.type && (x.type.id === CargoBerthActivityType.Load.id || x.type.id === CargoBerthActivityType.Discharge.id));

        const [load, discharge] = partition((x) => x.type.id === CargoBerthActivityType.Load.id, activities);

        this.calcActualFreightRateForActivity(load, lumpsum);
        this.calcActualFreightRateForActivity(discharge, lumpsum);
    }

    calcActualFreightRateForActivity(activities: CargoBerthActivity[], lumpsum: number) {
        const associatedCargoes = activities
            .map((x) => x.associatedCargoes)
            .reduce((a, b) => a.concat(b), [])
            .filter((x) => isNumber(x.mt) || isNumber(x.quantity));

        const quantity = (x: AssociatedCargo) => (isNumber(x.mt) ? x.mt : x.quantity);
        const totalQuantity = associatedCargoes.map(quantity).reduce((prev, cur) => prev + cur, 0);

        associatedCargoes.forEach((x) => (x.freightRate = totalQuantity ? (quantity(x) / totalQuantity) * lumpsum : null));
    }

    resetActualFreightRate(voyage: Voyage) {
        voyage.destinations
            .flatMap((x) => x.berths)
            .flatMap((x) => x.cargoBerthActivities)
            .filter((x) => CargoBerthActivityType.isLoadOrDischarge(x.type))
            .flatMap((x) => x.associatedCargoes)
            .forEach((x) => (x.freightRate = null));
    }

    resetBaseFreightRate(voyage: Voyage, unit: FreightRateUnit) {
        voyage.cargoes.forEach((cargo) => {
            cargo.baseFreightRate = null;
            cargo.baseFreightRateUnit = unit;
        });
    }

    calcNeeded([[prevFixture], [nextFixture]]: [[Fixture, Voyage], [Fixture, Voyage]]) {
        const calcNeeded = prevFixture.freightType.id === FreightType.LumpSum.id || nextFixture.freightType.id === FreightType.LumpSum.id;
        return nextFixture.fixtureId === prevFixture.fixtureId && calcNeeded;
    }

    sameData([prevFixture, prevVoyage]: [Fixture, Voyage], [nextFixture, nextVoyage]: [Fixture, Voyage]) {
        return this.sameFixture(prevFixture, nextFixture) && this.sameVoyage(prevVoyage, nextVoyage);
    }

    sameFixture(prev: Fixture, next: Fixture) {
        return prev.freightType.id === next.freightType.id && prev.lumpsumValue === next.lumpsumValue;
    }

    sameVoyage(prev: Voyage, next: Voyage) {
        return this.mapAssociatedCargoQuantity(prev) === this.mapAssociatedCargoQuantity(next);
    }

    mapAssociatedCargoQuantity(voyage: Voyage) {
        const result: string[] = [];
        for (const destination of voyage.destinations) {
            for (const berth of destination.berths) {
                for (const activity of berth.cargoBerthActivities) {
                    for (const ac of activity.associatedCargoes) {
                        result.push(`${destination.id}/${berth.id}/${activity.id}/${ac.id}/${ac.quantityUnit && ac.quantityUnit.id}/${ac.mt}/${ac.quantity}`);
                    }
                }
            }
        }
        return result.join();
    }

    private updateWorkingVoyage(workingVoyage: Voyage, oldFixture: Fixture, newFixture: Fixture) {
        if (FreightType.is("LumpSum", oldFixture.freightType) && FreightType.is("PerCargo", newFixture.freightType)) {
            this.resetBaseFreightRate(workingVoyage, FreightRateUnit.PerMT);
            this.resetActualFreightRate(workingVoyage);
            return;
        }

        this.resetBaseFreightRate(workingVoyage, FreightRateUnit.LumpSum);
        if (isNumber(newFixture.lumpsumValue)) {
            this.calcActualFreightRate(workingVoyage, newFixture.lumpsumValue);
            return;
        }

        this.resetActualFreightRate(workingVoyage);
    }
}
