<div [formGroup]="parentForm" class="card embedded-card-1">
    <div class="card-header row no-gutters align-items-center">
        <span class="title">Demurrage</span>
    </div>
    <div class="card-body">
        <div *ngIf="demurrageClaims && demurrageClaims.length > 0; else noDemurrageClaims">
            <div formArrayName="demurrageClaimContainerFormArray">
                <div *ngFor="let demurrageClaimForm of demurrageClaimContainerFormArray.controls; let i = index" class="row">
                    <div class="col">
                        <ops-demurrage-claim
                            [parentForm]="parentForm"
                            [fixtureId]="fixtureId"
                            [demurrageClaimForm]="demurrageClaimForm"
                            [demurrageClaims]="demurrageClaims"
                            [index]="i"
                            [destinations]="destinations"
                            [fixture]="fixture"
                            [newlyAdded]="newlyAddedId === demurrageClaims[i].demurrageClaimId"
                            [onAccountIndex]="onAccountIndexMap.has(i) ? onAccountIndexMap.get(i) : null"
                        >
                        </ops-demurrage-claim>
                    </div>
                </div>
            </div>

            <div class="guttering"></div>
            <div class="row add-button-row">
                <div class="col">
                    <button
                        type="button"
                        [disabled]="demurrageClaimContainerFormArray.disabled"
                        (click)="addDemurrageClaim()"
                        class="btn btn-primary float-right"
                        data-test-adddemurrageclaimbutton
                    >
                        <span class="has-icon icon--add-circle-outline"></span>
                        <span>CLAIM</span>
                    </button>
                </div>
            </div>
        </div>

        <ng-template #noDemurrageClaims>
            <div class="no-demurrageclaims-container">
                <div class="no-demurrageclaims-inner">
                    <div class="no-demurrageclaims-header">Claims</div>
                    <div class="no-demurrageclaims-content d-flex">
                        <div class="has-icon icon--lightbulb-outline"></div>
                        <div>
                            <div class="no-demurrageclaims-subheader">No claims are currently added</div>
                            <div class="no-demurrageclaims-footnote">Select the add button to add claims entry</div>
                        </div>
                        <div class="d-flex ml-auto align-items-end">
                            <button
                                type="button"
                                [disabled]="demurrageClaimContainerFormArray.disabled"
                                (click)="addDemurrageClaim()"
                                class="btn btn-primary align-bottom"
                                data-test-adddemurrageclaimbutton
                            >
                                <span class="has-icon icon--add-circle-outline"></span>
                                <span>CLAIM</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
