import { createAction, on, On, props } from "@ngrx/store";
import { box, setValue, updateGroup } from "ngrx-forms";

import { DateRange } from "../../../../../fixture/shared/models";
import { ActivityLocationForm, LtcState } from "../../../model";
import { currentCalculationStateReducer } from "../../reducer";

/* ACTIONS */
const PREFILL_ACTION_NAME = "[Laytime Calculation] Prefill Laytime Range";
export const prefillLaytimeRangeAction = createAction(PREFILL_ACTION_NAME, props<{ laytimeRange: DateRange }>());

/* REDUCERS */
export const prefillLaytimeRangeReducer: On<LtcState> = on(prefillLaytimeRangeAction, (state, { laytimeRange }) =>
    currentCalculationStateReducer(state, (calculationState) => ({
        ...calculationState,
        activityLocationForm: updateGroup<ActivityLocationForm>({
            laytimeRange: setValue(box(laytimeRange ?? { from: null, to: null }))
        })(calculationState.activityLocationForm)
    }))
);
