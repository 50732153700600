import { User } from "src/app/fixture/shared/models";
import { Command } from "../../../mediator/commands/command";
import { ClaimsNotificationFormModel } from "../../../shared/models/form-models/claims-notification.model";
import { UpdateClaimsNotificationCommandHandler } from "./update-claims-notification.command-handler";

export class UpdateClaimsNotificationCommand extends Command {
    constructor(user: User, data: ClaimsNotificationFormModel) {
        super(UpdateClaimsNotificationCommandHandler, { user: user, data: data });
    }
}
