import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { NgrxValueConverters } from "ngrx-forms";

import { CargoBerthActivityType, FreightType, QuantityUnit } from "../../../shared/reference-data";
import { Division, Voyage } from "../../shared/models";
import { AssociatedCargoState, BerthItem } from "../../state/associated-cargoes";
import { AssociatedCargoId, BerthId, CargoId, CargoItem, DestinationId } from "../../state/model";

@Component({
    selector: "ops-associated-cargoes",
    templateUrl: "./associated-cargoes.component.html",
    styleUrls: ["./associated-cargoes.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociatedCargoesComponent {
    private activityTypeName: string;

    readonly Division = Division;
    readonly pumpingRateDivisions = [Division.gas, Division.pcg, Division.dryCargo, Division.specialisedProducts];
    readonly QuantityUnit = QuantityUnit;
    readonly FreightType = FreightType;
    readonly dateValueConverter = NgrxValueConverters.dateToISOString;

    dateColumnName: string;
    quantityColumnName: string;
    rateColumnName: string;

    @Input() set activityType(activityType: CargoBerthActivityType) {
        const isLoadType = this.activityIsLoadType(activityType);
        const isDischargeReloadType = this.activityIsDischargeReloadType(activityType);

        this.activityTypeName = activityType.name;
        this.dateColumnName = isLoadType ? "B/L" : isDischargeReloadType ? "" : "Discharge";
        this.quantityColumnName = isLoadType ? "B/L" : isDischargeReloadType ? "B/L /" : "Discharge";
        this.rateColumnName = isLoadType ? "Loading" : isDischargeReloadType ? "" : "Discharge";
    }
    @Input() associatedCargoes: ReadonlyArray<AssociatedCargoState>;
    @Input() cargoes: ReadonlyArray<CargoItem>;
    @Input() berths: ReadonlyArray<BerthItem>;
    /**
     * The freight type of the fixture (lumpsum or per cargo)
     */
    @Input() freightType: FreightType;
    @Input() blDefaultFocusDate: string;
    @Input() currentVoyage: Voyage;
    @Input() destinationId: DestinationId;
    @Input() berthId: BerthId;
    @Input() timeZone: string;
    @Input() readonly: boolean;
    @Input() canAdd: boolean;
    @Input() canRemove: boolean;
    @Input() division: Division;

    @Output() readonly expand = new EventEmitter<AssociatedCargoId>();
    @Output() readonly collapse = new EventEmitter<AssociatedCargoId>();
    @Output() readonly add = new EventEmitter();
    @Output() readonly clone = new EventEmitter<CargoId>();
    @Output() readonly remove = new EventEmitter<number>();
    @Output() readonly addExpense = new EventEmitter<AssociatedCargoId>();
    @Output() readonly removeExpense = new EventEmitter<RemoveAssociatedCargoExpenseEvent>();
    @Output() readonly move = new EventEmitter<{ associatedCargoId: AssociatedCargoId; berthId: BerthId }>();

    get hasAssociatedCargoes() {
        return this.associatedCargoes && this.associatedCargoes.length > 0;
    }

    get canMove(): boolean {
        return !this.readonly && this.hasAssociatedCargoes && this.associatedCargoes[0].canMove;
    }

    get showMoveBerthButton(): boolean {
        const hasMultipleActivitiesOrAcs = () => {
            const berth = this.berths.find((b) => b.berthId === this.berthId);
            return berth.summary.activities.length > 1 || this.associatedCargoes.length > 1;
        };
        return this.division === Division.specialisedProducts && this.canMove && (this.berths.length > 1 || hasMultipleActivitiesOrAcs());
    }

    get isLumpSum(): boolean {
        return this.freightType && this.freightType.id === FreightType.LumpSum.id;
    }

    get showAddButton(): boolean {
        return this.canAdd && this.division !== Division.specialisedProducts;
    }

    get gridClasses(): string {
        let gridClasses = "ops-form-grid associated-cargo-table";

        if (this.readonly) {
            gridClasses += " ops-fg-readonly";
        }

        switch (this.division) {
            case Division.dryCargo:
                return `${gridClasses} associated-cargo-dry`;
            case Division.specialisedProducts:
                return this.showMoveBerthButton ? `${gridClasses} associated-cargo-st-multiple-berths` : `${gridClasses} associated-cargo-st-single-berth`;
            case Division.pcg:
                return `${gridClasses} associated-cargo-pcg`;
            case Division.gas:
                return `${gridClasses} associated-cargo-gas`;
            default:
                return gridClasses;
        }
    }

    get addCargoControlId() {
        const destinations = this.currentVoyage?.destinations;
        if (destinations) {
            const destinationIndex = destinations.findIndex((d) => d.id === this.destinationId);
            const berths = destinations[destinationIndex]?.berths;
            if (berths) {
                const berthIndex = berths.findIndex((b) => b.id === this.berthId);
                const activityIndex = berths[berthIndex]?.cargoBerthActivities?.findIndex((a) => a.type.name === this.activityTypeName);
                if (berthIndex >= 0 && activityIndex >= 0) {
                    return `destinations.${destinationIndex}.berths.${berthIndex}.activities.${activityIndex}.associatedCargoes`;
                }
            }
        }
        return "associatedCargoes";
    }

    trackBy(item: AssociatedCargoState) {
        return item.associatedCargoId;
    }

    toggle(expanded: boolean, associatedCargoId: AssociatedCargoId, event: any): void {
        if (expanded) {
            this.collapse.emit(associatedCargoId);
            event.stopPropagation();
        } else {
            this.expand.emit(associatedCargoId);
        }
    }

    isFirst(associatedCargoId: AssociatedCargoId) {
        return this.associatedCargoes.length > 0 && associatedCargoId === this.associatedCargoes[0].associatedCargoId;
    }

    isLast(associatedCargoId: AssociatedCargoId) {
        return this.associatedCargoes.length > 0 && associatedCargoId === this.associatedCargoes[this.associatedCargoes.length - 1].associatedCargoId;
    }

    addAssociatedCargo(event: Event) {
        this.add.emit(event);
        event.stopPropagation();
    }

    private activityIsLoadType(activityType: CargoBerthActivityType): boolean {
        return (
            activityType &&
            [CargoBerthActivityType.Load.id, CargoBerthActivityType.InterimLoad.id, CargoBerthActivityType.Provisional.id, CargoBerthActivityType.ProvisionalLoad.id].includes(
                activityType.id
            )
        );
    }

    private activityIsDischargeReloadType(activityType: CargoBerthActivityType): boolean {
        return activityType && (activityType.id === CargoBerthActivityType.DischargeReload.id || activityType.id === CargoBerthActivityType.InterimDischargeReload.id);
    }
}

export type RemoveAssociatedCargoExpenseEvent = Readonly<{
    index: number;
    associatedCargoId: AssociatedCargoId;
}>;
