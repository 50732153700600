import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { DATA_VALIDATION_ERROR_MESSAGE, isBadRequest, isNotFound, NotificationService } from "@ops/shared";

import { VoyageDataService } from "src/app/fixture/services/voyage-data.service";
import { Voyage } from "../../../fixture/shared/models";
import { TemperatureLogHttpService } from "./temperature-log-http.service";
import { TemperatureLog } from "./temperature-log.dto";

@Injectable({
    providedIn: "root"
})
export class TemperatureLogDataService {
    private fixtureId: string = null;
    private voyageId: string = null;
    private loadingSubject = new BehaviorSubject<boolean>(true);
    private currentSubject = new BehaviorSubject<TemperatureLog>(undefined);

    get loading$() {
        return this.loadingSubject.asObservable();
    }

    get current$(): Observable<TemperatureLog> {
        return this.currentSubject.asObservable();
    }

    constructor(
        private temperatureLogHttpService: TemperatureLogHttpService,
        private voyageDataService: VoyageDataService,
        private notificationService: NotificationService
    ) { }

    load() {
        this.loadingSubject.next(true);

        this.voyageDataService.current$.pipe(
            switchMap((voyage: Voyage) => {
                if (voyage) {
                    this.fixtureId = voyage.fixtureId;
                    this.voyageId = voyage.voyageId;

                    return this.temperatureLogHttpService.get(voyage.fixtureId, voyage.voyageId);
                }

                return of(undefined);
            }),
            tap(() => this.loadingSubject.next(false), () => this.loadingSubject.next(false)),
            map(temperatureLog => temperatureLog ?? this.createTemperatureLog())
        ).subscribe(temperatureLog => {
            this.currentSubject.next(temperatureLog);
        });
    }

    persist(temperatureLog: TemperatureLog): Observable<void> {
        this.loadingSubject.next(true);

        return this.temperatureLogHttpService.update(this.fixtureId, this.voyageId, temperatureLog).pipe(
            tap(
                () => {
                    this.loadingSubject.next(false);
                    this.currentSubject.next(temperatureLog);
                },
                (error) => {
                    this.loadingSubject.next(false);

                    this.notificationService.error(
                        "Failed to save temperature log.", isBadRequest(error) ? DATA_VALIDATION_ERROR_MESSAGE : null, true
                    );
                }
            ),
            map(_ => { })
        );
    }

    private createTemperatureLog(): TemperatureLog {
        return {
            events: []
        };
    }
}
