import moize from "moize";
import * as moment from "moment-timezone";
import { unbox } from "ngrx-forms";

import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { DateRange } from "../../../shared/date-utils/date-range";
import { parseISODateRange } from "../../../shared/date-utils/date-utilities";
import { getTimeZone } from "../../shared/models/common/sea-net-location";
import { getBerthActivityList, mapActivity } from "../berths/berth-summary";
import { getTotalLaytime } from "../laytime-events/laytime-calculation";
import { BerthForm, CargoItem, DestinationForm } from "../model";

// TODO: (NGRX JC)  This has been quickly adapted from the original implementation - it needs memoizing and tidying and luxon (leaving as UI may change)

export declare type DestinationSummary = Readonly<{
    locationName: string;
    timeZone: string;
    activities: { activity: string; cargoes: string }[];
    dateRangeToDisplay: DateRange;
    dateLabelToDisplay: string;
    totalBl: number;
    totalQty: number;
    totalLaytime: number;
    showTotalLaytime: boolean;
    showTotalBl: boolean;
    showTotalQty: boolean;
    activitiesSummary: string;
    lastEvent: string;
}>;

const mGetTotalLaytime = moize.deep(getTotalLaytime);

function getDestinationTotalLaytime(destination: DestinationForm) {
    let totalLaytime = 0;

    for (const berth of destination.berths) {
        for (const activity of berth.activities) {
            const laytimeEventData = activity.laytimeEvents.map((laytimeEvent) => ({
                eventDate: laytimeEvent.eventDate,
                isStartOrStop: laytimeEvent.isStartOrStop,
                percentage: laytimeEvent.percentage
            }));

            totalLaytime += mGetTotalLaytime(laytimeEventData);
        }
    }

    return totalLaytime;
}

function getDisplayDate(etaRange: { from?: string; to?: string }, arrival: string) {
    return {
        dateRangeToDisplay: arrival
            ? parseISODateRange({ from: arrival, to: arrival })
            : etaRange && etaRange.from && etaRange.to
            ? parseISODateRange(etaRange)
            : null,
        dateLabelToDisplay: arrival ? "Arrived" : "ETA"
    };
}

function getActivitySummary(cargoes: ReadonlyArray<CargoItem>, berths: ReadonlyArray<BerthForm>) {
    let activities: { activity: string; cargoes: string[] }[] = [];

    berths.forEach((berth) => {
        activities = [...activities, ...getBerthActivityList(cargoes, berth)];
    });

    const activityList = activities.map(mapActivity);

    const activitySummary = activityList
        .map((x) => {
            if (x.cargoes) {
                return `${x.activity}: ${x.cargoes}`;
            }

            return x.activity;
        })
        .join(", ");

    return {
        activities: activityList,
        activitiesSummary: activitySummary
    };
}

function getLoadDischargeTotals(destination: DestinationForm): [number | null, number | null] {
    let totalBl: number = null;
    let totalDischarge: number = null;

    for (const berth of destination.berths) {
        for (const activity of berth.activities) {
            if (!activity.type) { continue; }

            const isLoad = activity.type.value.id === CargoBerthActivityType.Load.id;
            const isDischarge = activity.type.value.id === CargoBerthActivityType.Discharge.id;

            if (isLoad || isDischarge) {
                const quantity = activity.associatedCargoes.reduce((acc, ac) => acc + (ac.quantity ? ac.quantity : 0), 0);

                if (isLoad) {
                    totalBl = (totalBl || 0) + quantity;
                } else {
                    totalDischarge = (totalDischarge || 0) + quantity;
                }
            }
        }
    }

    return [totalBl, totalDischarge];
}

function getShowTotalLaytime(destination: DestinationForm) {
    return destination.berths.some((berth) => {
        return berth.activities.some((cba) => cba.laytimeEvents && cba.laytimeEvents.some((le) => le.isStartOrStop));
    });
}

function getLastDischargeActivity(destination: DestinationForm) {
    const laytimeEvents = destination.berths
        .map((b) => b.activities)
        .reduce((a, b) => a.concat(b), [])
        .map((cba) => cba.laytimeEvents)
        .reduce((a, b) => a.concat(b), [])
        .filter((e) => e && e.eventDate)
        .sort((a, b) => (a.eventDate > b.eventDate ? 1 : b.eventDate > a.eventDate ? -1 : 0));

    if (laytimeEvents && laytimeEvents.length > 0) {
        const lastEvent = laytimeEvents[laytimeEvents.length - 1];

        if (lastEvent.type && lastEvent.type.value) {
            const timeZone = getTimeZone(destination.location && destination.location.value);

            const formattedEventDate = moment(lastEvent.eventDate)
                .tz(timeZone || "utc")
                .format("DD MMM YY, HH:mm");

            return `${lastEvent.type.value.name}: ${formattedEventDate}`;
        }
    }

    return null;
}

export const getDestinationSummary = (cargoes: ReadonlyArray<CargoItem>, destination: DestinationForm): DestinationSummary => {
    const location = unbox(destination.location);
    const showTotalLaytime = getShowTotalLaytime(destination);
    const [blQuantity, dischargeQuantity] = showTotalLaytime ? getLoadDischargeTotals(destination) : [null, null];

    return {
        locationName: location ? location.displayName : "",
        timeZone: location ? location.timeZone : "utc",
        ...getActivitySummary(cargoes, destination.berths),
        ...getDisplayDate(destination.etaRange && destination.etaRange.value, destination.arrivalDateTime),
        showTotalLaytime: showTotalLaytime,
        showTotalBl: blQuantity !== null,
        showTotalQty: dischargeQuantity !== null,
        totalLaytime: showTotalLaytime ? getDestinationTotalLaytime(destination) : null,
        totalBl: blQuantity,
        totalQty: dischargeQuantity,
        lastEvent: getLastDischargeActivity(destination)
    };
};
