import { createAction, On, on, props } from "@ngrx/store";
import { removeArrayControl, updateGroup } from "ngrx-forms";
import * as R from "ramda";

import { updateBerthForm } from "../../berths/shared";
import { activityExpandedKey, ActivityId, BerthId, DestinationId, FixturesState, VoyageExpandedSections } from "../../model";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
export const removeActivityAction = createAction("[Voyage Form] Remove Activity", props<{ destinationId: DestinationId; berthId: BerthId; activityId: ActivityId }>());

/* REDUCERS */
export const removeActivityReducer: On<FixturesState> = on(removeActivityAction, (state, { destinationId, berthId, activityId }) =>
    currentVoyageStateReducer(state, (voyageState) => {
        const destination = voyageState.form.controls.destinations.controls.find((d) => d.value.id === destinationId);
        if (!destination) {
            return voyageState;
        }
        const berth = destination.controls.berths.controls.find((b) => b.value.id === berthId);
        if (!berth) {
            return voyageState;
        }
        const activityIndex = berth.value.activities.findIndex((x) => x.activityId === activityId);
        if (activityIndex < 0) {
            return voyageState;
        }

        return {
            ...voyageState,
            form: updateBerthForm({ destinationId, berthId }, (berthForm) =>
                updateGroup(berthForm, {
                    activities: removeArrayControl(activityIndex)
                })
            )(voyageState.form),
            expandedSections: <VoyageExpandedSections>R.omit([activityExpandedKey(destinationId, berthId, activityId)], voyageState.expandedSections)
        };
    })
);
