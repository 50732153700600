import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { DestinationSummary } from "../../state";

@Component({
    selector: "ops-destination-header",
    templateUrl: "./destination-header.component.html",
    styleUrls: ["./destination-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationHeaderComponent {
    @Input() summary: DestinationSummary;
    @Input() expanded: boolean;

    @Input() canOrder: boolean;
    @Input() canClone: boolean;
    @Input() canRemove: boolean;

    @Input() cloneEnabled: boolean;
    @Input() removeEnabled: boolean;
    @Input() moveUpEnabled: boolean;
    @Input() moveDownEnabled: boolean;
    @Input() showInvalid: boolean;

    @Output() readonly expand = new EventEmitter();
    @Output() readonly collapse = new EventEmitter();
    @Output() readonly moveUp = new EventEmitter();
    @Output() readonly moveDown = new EventEmitter();
    @Output() readonly clone = new EventEmitter();
    @Output() readonly remove = new EventEmitter();

    toggle() {
        if (this.expanded) {
            this.collapse.emit();
        } else {
            this.expand.emit();
        }
    }
}
