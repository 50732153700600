import { FixtureStatusType } from ".";
import { FavoriteViewModel } from "./favorite.view-model";
import { FixtureStatus } from "./fixture-status.model";

export class FavoriteCategoryViewModel {
    selected = false;

    constructor(private fixtureStatus: FixtureStatus, private items: FavoriteViewModel[]) {
        if (!fixtureStatus) {
            throw Error("Given favorite category is invalid");
        }
        if (items === null || items === undefined) {
            throw Error("Given items collection is invalid");
        }
    }

    get fixtureStatusType(): FixtureStatusType {
        return this.fixtureStatus.type;
    }

    get order(): number {
        return this.fixtureStatus.displayOrder;
    }

    get name(): string {
        return this.fixtureStatus.name;
    }

    get favorites(): FavoriteViewModel[] {
        return this.items;
    }
}
