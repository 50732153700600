import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props } from "@ngrx/store";
import { setValue } from "ngrx-forms";
import { map } from "rxjs/operators";

import { emptyObjectMap, toObjectMap } from "@ops/state";

import { loadVoyagesAction } from "./load-voyages";
import { fixtureStateReducer } from "./reducer";
import { Nullable } from "../../../shared";
import { FreightType } from "../../../shared/reference-data";
import { Fixture } from "../../shared/models";
import { FixtureId, FixturesState } from "../model";
import { updateAssociatedCargoForms } from "../voyage/form/form-updaters";
import { currentVoyageStateReducer } from "../voyage/reducer";
import { loadDismissedWarningsAction } from "../warnings/dismiss/load-dismissed-warnings";

/* ACTIONS */
export const interopFixtureLoadSuccessAction = createAction("[FixtureDataService Interop] Fixture Load Success", props<{ fixture: Fixture }>());
export const interopFixtureReloadSuccessAction = createAction("[FixtureDataService Interop] Fixture Reload Success", props<{ fixture: Fixture }>());
export const interopFixturePublishSuccessAction = createAction("[FixtureDataService Interop] Fixture Publish Success", props<{ fixture: Fixture }>());
export const interopLockFixtureSuccessAction = createAction("[FixtureDataService Interop] Lock Fixture Success", props<{ fixtureId: FixtureId }>());
export const interopUnlockFixtureSuccessAction = createAction("[FixtureDataService Interop] Unlock Fixture Success", props<{ fixtureId: FixtureId }>());

/* REDUCERS */
export const interopFixtureLoadSuccessReducer: On<FixturesState> = on(interopFixtureLoadSuccessAction, interopFixtureReloadSuccessAction, (state, action) => {
    // If we don't have the fixture, replace the entire fixture state
    if (!state.fixtures.byId[action.fixture.fixtureId]) {
        return {
            ...state,
            // Replace the entire voyage state map, we may want to change this in the future
            fixtures: toObjectMap([action.fixture], "fixtureId", () => ({
                loadStatus: "loaded",
                fixture: action.fixture
            })),
            voyages: emptyObjectMap()
        };
    }

    return fixtureStateReducer(state, action.fixture.fixtureId as FixtureId, {
        loadStatus: "loaded",
        fixture: action.fixture
    });
});

export const resetActualFreightRateReducer: On<FixturesState> = on(interopFixturePublishSuccessAction, (state, action) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        if (!voyageState?.form) {
            return voyageState;
        }

        const oldFreightType = fixtureState.workingFixture?.freightType;
        const newFreightType = action.fixture.freightType;
        const changedFromLumSumToPerCargo = FreightType.is("LumpSum", oldFreightType) && FreightType.is("PerCargo", newFreightType);

        if (!changedFromLumSumToPerCargo) {
            return voyageState;
        }

        return {
            ...voyageState,
            form: updateAssociatedCargoForms({
                freightRate: setValue<Nullable<number>>(null)
            })(voyageState.form)
        };
    })
);

export const interopFixturePublishSuccessReducer: On<FixturesState> = on(interopFixturePublishSuccessAction, (state, action) =>
    fixtureStateReducer(state, action.fixture.fixtureId as FixtureId, {
        workingFixture: action.fixture
    })
);

/* EFFECTS */

// We have to load the voyages after the fixture as we require division to construct the form
export const loadVoyagesOnFixtureLoadEffect$ = (actions$: Actions) =>
    createEffect(() =>
        actions$.pipe(
            ofType(interopFixtureLoadSuccessAction),
            map(({ fixture }) => loadVoyagesAction({ fixtureId: fixture.fixtureId as FixtureId }))
        )
    );

export const loadDismissedWarningsOnFixtureLoadEffect$ = (actions$: Actions) =>
    createEffect(() =>
        actions$.pipe(
            ofType(interopFixtureLoadSuccessAction, interopFixtureReloadSuccessAction),
            map(({ fixture }) => loadDismissedWarningsAction({ fixtureId: fixture.fixtureId as FixtureId }))
        )
    );
