import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { setValue, SetValueAction, StateUpdateFns, updateGroup } from "ngrx-forms";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, filter, map, tap, withLatestFrom } from "rxjs/operators";

import { LaytimeCalculationHttpService } from "../../../../services";
import {
    LaytimeCalculationState,
    LaytimeCalculationTerms,
    LaytimeCalculationTermsForm,
    laytimeCalculationTermsFormKey,
    LaytimeCalculationDurationUnit,
    LtcFeatureState,
    LtcId,
    LtcState,
    toLaytimeCalculationTerms
} from "../../../model";
import { recalculateRate } from "../../../utils";
import { calculationStateReducer } from "../../reducer";
import { selectCurrentCalculationId } from "../../selectors";
import { UpdateQueue } from "../../update-queue";
import { selectCurrentLaytimeCalculationTermsForm } from "../selectors";

/* ACTIONS */
const UPDATE_ACTION_NAME = "[Laytime Calculation Terms Form] Save Terms";
export const updateTermsAction = createAction(UPDATE_ACTION_NAME, props<{ ltcId: LtcId; terms: LaytimeCalculationTerms }>());
export const updateTermsSuccessAction = createAction(`${UPDATE_ACTION_NAME} Success`, props<{ ltcId: LtcId; terms: LaytimeCalculationTerms }>());
export const updateTermsFailAction = createAction(`${UPDATE_ACTION_NAME} Fail`, props<{ ltcId: LtcId; error: Error }>());

/* REDUCERS */
export const updateTermsReducer: On<LtcState> = on(updateTermsAction, (state, { terms, ltcId }) => {
    const updateFns: Evolver = {
        calculation: R.mergeDeepLeft(terms)
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

export const changeLaytimeCalculationUnitReducer: On<LtcState> = {
    types: [SetValueAction.TYPE],
    reducer: (state: LtcState, action: SetValueAction<LaytimeCalculationDurationUnit>): LtcState => {
        const controlPath = action.controlId.split(".");
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (controlPath.length !== 3 || controlPath[1] !== laytimeCalculationTermsFormKey || controlPath[2] !== "durationUnit") {
            return state;
        }

        return calculationStateReducer(state, controlPath[0] as LtcId, (calculationState) => {
            const formValue = calculationState.calculationTermsForm.value;
            const updateFns: StateUpdateFns<LaytimeCalculationTermsForm> = {
                demurrageRate: setValue(recalculateRate(formValue.demurrageRate, action.value)),
                detentionRate: setValue(recalculateRate(formValue.detentionRate, action.value)),
                despatchRate: setValue(recalculateRate(formValue.despatchRate, action.value))
            };

            return <LaytimeCalculationState>{
                ...calculationState,
                calculationTermsForm: updateGroup<LaytimeCalculationTermsForm>(updateFns)(calculationState.calculationTermsForm)
            };
        });
    }
};

/* EFFECTS */
export const updateTermsFormEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<LaytimeCalculationTermsForm>>(SetValueAction.TYPE),
            filter((action) => action.controlId.split(".")[1] === laytimeCalculationTermsFormKey),
            withLatestFrom(store.select(selectCurrentCalculationId), store.select(selectCurrentLaytimeCalculationTermsForm)),
            filter(([, , form]) => form.isValid),
            map(([, ltcId, form]) => updateTermsAction({ ltcId, terms: toLaytimeCalculationTerms(form.value) })),
            tap((action) => updateQueue.enqueue(action, updateTerms, "replace"))
        )
    );

export const updateTerms = ({ ltcId, terms }: ReturnType<typeof updateTermsAction>, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    laytimeCalculationHttpService.updateTerms(ltcId, terms).pipe(
        map(() => updateTermsSuccessAction({ ltcId, terms })),
        catchError((error) => of(updateTermsFailAction({ ltcId, error })))
    );
