import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { filter, pairwise, takeUntil } from "rxjs/operators";

import { FixtureDataService } from "../../services/fixture-data.service";
import { interopFixtureLoadSuccess, interopFixturePublishSuccess, interopFixtureReloadSuccess, lockFixtureSuccess, unlockFixtureSuccess } from "../fixture";
import { FixtureFeatureState, FixtureId } from "../model";

@Injectable({
    providedIn: "root"
})
export class FixtureDataInterop {
    private destroy$: Subject<void>;

    constructor(private fixtureDataService: FixtureDataService, private store: Store<FixtureFeatureState>) {}

    init() {
        this.destroy();
        this.destroy$ = new Subject<void>();

        this.fixtureDataService.currentFixture$
            .pipe(
                filter((x) => !!x),
                takeUntil(this.destroy$)
            )
            .subscribe((fixture) => {
                this.store.dispatch(interopFixturePublishSuccess({ fixture }));
            });

        this.fixtureDataService.currentServerFixture$.pipe(pairwise(), takeUntil(this.destroy$)).subscribe(([a, b]) => {
            if (!b) {
                return;
            }

            const isReload = a?.fixtureId === b?.fixtureId;

            this.store.dispatch((isReload ? interopFixtureReloadSuccess : interopFixtureLoadSuccess)({ fixture: b }));
        });

        this.fixtureDataService.lock$
            .pipe(
                filter((x) => !!x),
                takeUntil(this.destroy$)
            )
            .subscribe((fixtureId) => {
                this.store.dispatch(lockFixtureSuccess({ fixtureId: fixtureId as FixtureId }));
            });

        this.fixtureDataService.unlock$
            .pipe(
                filter((x) => !!x),
                takeUntil(this.destroy$)
            )
            .subscribe((fixtureId) => {
                this.store.dispatch(unlockFixtureSuccess({ fixtureId: fixtureId as FixtureId }));
            });
    }

    destroy() {
        if (this.destroy$) {
            this.destroy$.next();
            this.destroy$.complete();
        }
    }
}
