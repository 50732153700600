<ng-container *ngIf="destinations?.length; else noDestinations">
    <div *ngFor="let destination of destinations; first as isFirst; last as isLast; trackBy: trackBy" class="row location-row">
        <div class="card">
            <ops-destination-header
                [summary]="destination.summary"
                [expanded]="destination.expanded"
                [canOrder]="!readonly && destination.canOrder"
                [canClone]="!readonly && destination.canCloneOrRemove"
                [canRemove]="!readonly && destination.canCloneOrRemove"
                [moveUpEnabled]="!isFirst"
                [moveDownEnabled]="!isLast"
                [cloneEnabled]="destination.canCloneOrRemove"
                [removeEnabled]="destination.canCloneOrRemove"
                [showInvalid]="destination.isInvalid"
                (moveUp)="moveUp.emit(destination.destinationId)"
                (moveDown)="moveDown.emit(destination.destinationId)"
                (clone)="clone.emit(destination.destinationId)"
                (remove)="remove.emit(destination.destinationId)"
                (expand)="expand.emit(destination.destinationId)"
                (collapse)="collapse.emit(destination.destinationId)">
            </ops-destination-header>

            <ops-destination-shell
                *ngIf="destination.expanded"
                [destinationId]="destination.destinationId"
                [readonly]="readonly">
            </ops-destination-shell>
        </div>
    </div>
    <div class="d-flex flex-row-reverse add-location-button-container" *ngIf="canAdd">
        <button
            type="button"
            class="btn btn-primary align-bottom mr-0"
            [disabled]="readonly"
            (click)="add.emit()"
            warningActive
            ngrxFormControlId="destinations"
            ngrxFormControlIdMatch="endsWith"
            [focusOnActive]="true"
            data-test-add-location-button>
            <span class="has-icon icon--add-circle-outline"></span>
            <span>LOCATION</span>
        </button>
    </div>
</ng-container>
<ng-template #noDestinations>
    <ops-no-data-panel
        #panel="noDataPanel"
        heading="Locations"
        title="No locations are currently added"
        description="Select the add button to add location entry"
        [useNewButton]="true"
        newButtonText="LOCATION"
        [disabled]="readonly"
        (addNew)="add.emit()"
        warningActive
        ngrxFormControlId="destinations"
        ngrxFormControlIdMatch="endsWith"
        (warningActivated)="panel.focusNewButton()">
    </ops-no-data-panel>
</ng-template>
