import { createAction, on, On } from "@ngrx/store";
import { FixturesState } from "../model";
import { currentVoyageStateExpansionReducer } from "../voyage/reducer";

/* ACTIONS */
export const expandCargoTotalsAction = createAction("[Fixture Form] Expand Cargo Totals");
export const collapseCargoTotalsAction = createAction("[Fixture Form] Collapse Cargo Totals");

const TOTALS_EXPANDED_KEY = "totals";

/* REDUCERS */
export const expandCargoTotalsReducer: On<FixturesState> = on(expandCargoTotalsAction, (state) =>
    currentVoyageStateExpansionReducer(state, TOTALS_EXPANDED_KEY, true)
);
export const collapseCargoTotalsReducer: On<FixturesState> = on(collapseCargoTotalsAction, (state) =>
    currentVoyageStateExpansionReducer(state, TOTALS_EXPANDED_KEY, false)
);
