import { cloneCargoReducer } from "./form/clone-cargo";
import { cancelCargoEditReducer, editCargoReducer } from "./form/edit-cargo";
import { removeCargoFailReducer, removeCargoSuccessReducer } from "./form/remove-cargo";
import { saveCargoFailReducer, saveCargoReducer, saveCargoSuccessReducer } from "./form/save-cargo";

export default [
    editCargoReducer,
    cancelCargoEditReducer,
    saveCargoSuccessReducer,
    saveCargoFailReducer,
    saveCargoReducer,
    cloneCargoReducer,
    removeCargoSuccessReducer,
    removeCargoFailReducer
];
