import { Injectable } from "@angular/core";
import { CommandHandler } from "src/app/fixture/mediator/command-handlers/command-handler";
import { Fixture } from "src/app/fixture/shared/models";
import { UpdateLaytimeUnitEnumCommand } from "./update-laytime-unit-enum.command";

@Injectable({
    providedIn: "root"
})
export class UpdateLaytimeUnitEnumCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateLaytimeUnitEnumCommand) {
        fixture.laytimeUnit = command.laytimeUnit;
    }
}
