import { ObjectReducer, reduceObjectMap } from "@ops/state";

import { FixtureId } from "../../../fixture/state";
import { LtcId, LaytimeCalculationState, getCurrentCalculationId, LtcState, FixtureLaytimeCalculationsState, getCurrentFixtureId } from "../model";

export const calculationStateReducer = (state: LtcState, calculationId: LtcId, partialOrReducer: Partial<LaytimeCalculationState> | ObjectReducer<LaytimeCalculationState>) =>
    reduceObjectMap(state, (s) => s.calculations, calculationId, partialOrReducer);

export const currentCalculationStateReducer = (state: LtcState, partialOrReducer: Partial<LaytimeCalculationState> | ObjectReducer<LaytimeCalculationState>) =>
    calculationStateReducer(state, getCurrentCalculationId(state), partialOrReducer);

export const fixtureCalculationsStateReducer = (
    state: LtcState,
    fixtureId: FixtureId,
    partialOrReducer: Partial<FixtureLaytimeCalculationsState> | ObjectReducer<FixtureLaytimeCalculationsState>
) => reduceObjectMap(state, (s) => s.fixtureCalculations, fixtureId, partialOrReducer);

export const currentFixtureCalculationsStateReducer = (
    state: LtcState,
    partialOrReducer: Partial<FixtureLaytimeCalculationsState> | ObjectReducer<FixtureLaytimeCalculationsState>
) => fixtureCalculationsStateReducer(state, getCurrentFixtureId(state), partialOrReducer);
