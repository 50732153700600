import { createSelector } from "@ngrx/store";

import { selectCurrentLiftingState } from "../selectors";

export const selectCurrentLiftingSummaryCommentsForm = createSelector(selectCurrentLiftingState, (state) => state?.liftingSummaryCommentsForm);
export const selectCurrentLiftingSummaryCommentsFormValue = createSelector(selectCurrentLiftingSummaryCommentsForm, (form) => form?.value);

export const selectCurrentLiftingSummaryCommentsFormSaving = createSelector(
    selectCurrentLiftingState,
    (state) => !state || state.liftingSummaryCommentsFormSaveStatus === "persisting"
);

export const selectCurrentLiftingSummaryCommentsFormSaveFailed = createSelector(
    selectCurrentLiftingState,
    (state) => !state || state.liftingSummaryCommentsFormSaveStatus === "failed"
);
