<div class="side-panel" data-test-temperature-log-panel *ngIf="(temperatureLog$ | async)">
    <div class="row no-gutters temperature-log-summary-title">
        <div class="col-11 side-panel-title">
            Temperature Log
            <div class="left-toolbar-expander" (click)="collapseLeftBar()">
                <div class="has-icon icon--close"></div>
            </div>
        </div>
        <div class="col d-flex justify-content-end">
            <button class="has-icon icon--mode-edit float-right" *ngIf="!(edit$ | async)" (click)="edit()"></button>
        </div>
    </div>

    <div class="d-flex flex-column h-100">
        <div class="loading-panel" *ngIf="(loading$ | async)">
            <ops-load-animation size="small" class="loading-animation"></ops-load-animation>
        </div>

        <div class="scrollable-content">
            <table class="table">
                <thead>
                    <tr>
                        <th>Event</th>
                        <th class="quantity-column-fixed text-right">Temperature &deg;C</th>
                        <th class="quantity-column-fixed text-right">Date and Time</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let temperatureLogEventType of temperatureLogEventTypes">
                        <td>
                            <span class="middle-text">{{ temperatureLogEventType }}</span>
                        </td>
                        <td class="text-right">
                            <span *ngIf="!(edit$ | async)" class="middle-text">
                                {{ getTemperature(temperatureLogEventType) | opsNumber: true:2:2 }}
                            </span>
                            <span *ngIf="(edit$ | async)">
                                <input
                                    class="form-control text-right"
                                    [formControl]="eventsFormGroup.get(temperatureLogEventType + '-temperature')"
                                    [opsNumber]="{ precision: 2, minValue: -100, maxValue: 100, type: 'string' }"
                                    [opsValidationPopover]="temperatureValidation"
                                    validationPopoverContainer="body"
                                    data-test-temperature />
                                <ng-template #temperatureValidation>
                                    <ops-field-validation
                                        *ngIf="eventsFormGroup.get(temperatureLogEventType + '-temperature').invalid"
                                        fieldDisplayName="Temperature"
                                        errorMessage="must be between -100 and 100">
                                    </ops-field-validation>
                                </ng-template>
                            </span>
                        </td>
                        <td class="text-right">
                            <span *ngIf="!(edit$ | async)" class="middle-text">
                                {{ getDate(temperatureLogEventType) }}
                            </span>
                            <span *ngIf="(edit$ | async)">
                                <ops-date-input
                                    class="form-control text-right event-date"
                                    [formControl]="eventsFormGroup.get(temperatureLogEventType + '-date')"
                                    [enableTime]="true"
                                    timeZone="local"
                                    container="body"
                                    [opsValidationPopover]="dateValidation"
                                    validationPopoverContainer="body"
                                    data-test-date
                                ></ops-date-input>
                                <ng-template #dateValidation>
                                    <ops-field-validation
                                        *ngIf="eventsFormGroup.get(temperatureLogEventType + '-date').invalid"
                                        errorMessage="NOR Date and Time cannot be before Arrival time">
                                    </ops-field-validation>
                                </ng-template>
                            </span>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td class="cooling-title" colspan="2" >Time spent cooling at anchor</td>
                        <td class="text-right">{{coolingTime$ | async}}</td>
                    </tr>
                </tfoot>
            </table>
            <div class="row no-gutters text-right footer-command-row" *ngIf="(edit$ | async)">
                <div class="col-12">
                    <button type="button" (click)="cancel()" class="btn btn-link mr-0 ml-0" data-test-temperature-log-cancel>CANCEL</button>
                    <button type="button" (click)="save()" class="btn btn-primary m-0" data-test-temperature-log-save>SAVE</button>
                </div>
            </div>
        </div>
    </div>
</div>
