import { Router } from "@angular/router";
import { from, Observable } from "rxjs";

import { Voyage } from "./shared/models";
import { FixtureId, VoyageId } from "./state/model";

export function navigateToVoyage(router: Router, voyage: Voyage): Observable<boolean>;
export function navigateToVoyage(router: Router, fixtureId: FixtureId, voyageId: VoyageId): Observable<boolean>;
// tslint:disable-next-line:no-any
export function navigateToVoyage(router: Router, ...input: any[]): Observable<boolean> {
    const fixtureId = (input[0] as Voyage).fixtureId || input[0];
    const voyageId = (input[0] as Voyage).voyageId || input[1];

    return from(
        router.navigate(
            [
                {
                    outlets: { primary: ["fixture", fixtureId, "voyage", voyageId] }
                }
            ],
            {
                replaceUrl: true,
                queryParamsHandling: "merge"
            }
        )
    );
}
