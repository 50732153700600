import { ChangeDetectorRef, Directive, ElementRef, Host, HostBinding, Input, OnDestroy, OnInit, Optional } from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { FormState } from "ngrx-forms";
import { of, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";

import { scrollIntoView } from "../../../shared/utils";
import { FixtureWarningPathMapper } from "./fixture-warning-path-mapper";
import { FixtureWarningService } from "./fixture-warning.service";
import { getFullPath, pathToControlId } from "./utils";

const SECTION_SCROLL_OFFSET = -24;

@Directive({ selector: "[warningActive]" }) // eslint-disable-line  @angular-eslint/directive-selector
export class FixtureWarningActiveDirective implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();
    private activatedTimeoutHandle: any;

    state: FormState<never>;

    @Input() activatedDuration = 2000;
    @Input() focusOnActive = false;

    @Input() set warningActive(newState: FormState<never>) {
        if (typeof newState === "object") {
            this.ngrxFormControlState = newState;
        }
    }

    @Input() set ngrxFormControlState(newState: FormState<never>) {
        this.state = newState;
    }

    @Input() ngrxFormControlId: string;

    @Input() ngrxFormControlIdMatch: "equals" | "startsWith" | "includes" | "endsWith" = "equals";

    @HostBinding("class.ops-warning--active") isCurrentWarning = false;
    @HostBinding("class.ops-warning--activated") isActivated = false;

    constructor(
        @Optional() @Host() private parent: ControlContainer,
        private warningService: FixtureWarningService,
        private warningPathMapper: FixtureWarningPathMapper,
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (!this.parent && !this.state && !this.ngrxFormControlId) {
            throw new Error("warningActive directive must have ngrxFormControlState, ngrxFormControlId or parent ControlContainer");
        }

        this.warningService.currentWarning$
            .pipe(
                takeUntil(this.destroy$),
                switchMap((warning) => {
                    if (!warning) {
                        return of(false);
                    }

                    if (this.ngrxFormControlId || this.state) {
                        const controlId = pathToControlId(warning.path);
                        const matchControlId = this.ngrxFormControlId || this.state.id;

                        return of(this.ngrxFormControlIdMatch === "equals" ? controlId === matchControlId : controlId[this.ngrxFormControlIdMatch](matchControlId));
                    }

                    const fullPath = getFullPath(this.parent);

                    return this.warningPathMapper.pathMatch(warning.path, fullPath);
                })
            )
            .subscribe((pathMatch) => this.onCurrentWarningChange(pathMatch));
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    private onCurrentWarningChange(pathMatch: boolean) {
        this.isCurrentWarning = pathMatch;

        if (this.activatedTimeoutHandle) {
            clearTimeout(this.activatedTimeoutHandle);
        }

        if (this.isCurrentWarning) {
            if (this.elementRef && this.elementRef.nativeElement) {
                scrollIntoView(this.elementRef.nativeElement, SECTION_SCROLL_OFFSET);

                if (this.focusOnActive) {
                    this.elementRef.nativeElement.focus();
                }
            }

            if (this.activatedDuration) {
                this.isActivated = true;

                this.activatedTimeoutHandle = setTimeout(() => {
                    this.isActivated = false;
                    this.changeDetector.markForCheck();
                }, this.activatedDuration);
            } else {
                this.isActivated = false;
            }
        } else {
            this.isActivated = false;
        }

        this.changeDetector.markForCheck();
    }
}
