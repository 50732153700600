import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DateTime } from "luxon";
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";

import { Maritime } from "@ops/shared";

import { VoyageDataService } from "../../fixture/services/voyage-data.service";
import { RangeValidator } from "../../shared/validators/range.validator";
import { LeftBarStateService } from "../left-bar-state.service";
import { TemperatureLogCoolingTimeService } from "./services/temperature-log-cooling-time.service";
import { TemperatureLogDataService } from "./services/temperature-log-data.service";
import { ArrivalDateType, NORDateType, TemperatureLogEventType, temperatureLogEventTypes } from "./services/temperature-log-event-names";
import { TemperatureLog } from "./services/temperature-log.dto";

const MIN_TEMPERATURE = -100;
const MAX_TEMPERATURE = 100;

@Component({
    selector: "ops-temperature-log",
    templateUrl: "./temperature-log.component.html",
    styleUrls: ["./temperature-log.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemperatureLogComponent implements OnInit, OnDestroy {
    static componentName = "TemperatureLogComponent";

    private readonly destroy$ = new Subject();

    readonly edit$ = new BehaviorSubject<boolean>(false);
    readonly loading$: Observable<boolean>;

    readonly temperatureLog$ = this.temperatureLogDataService.current$;
    readonly temperatureLogEventTypes = temperatureLogEventTypes;

    temperatureLog: TemperatureLog;

    eventsFormGroup = new UntypedFormGroup({});
    coolingTime$ = new Observable<string>();

    constructor(
        private voyageDataService: VoyageDataService,
        private temperatureLogDataService: TemperatureLogDataService,
        private temperatureLogCoolingTimeService: TemperatureLogCoolingTimeService,
        private leftBarStateService: LeftBarStateService
    ) {
        this.createForm();

        this.loading$ = combineLatest([this.voyageDataService.loading$, this.temperatureLogDataService.loading$]).pipe(
            map(([voyageLoading, temperatureLogLoading]: [boolean, boolean]) => voyageLoading || temperatureLogLoading)
        );

        this.coolingTime$ = merge(this.loading$, this.eventsFormGroup.valueChanges).pipe(
            map((_) => {
                const arrivalValue = this.eventsFormGroup.get(`${ArrivalDateType}-date`).value as Date;
                const norValue = this.eventsFormGroup.get(`${NORDateType}-date`).value as Date;
                return this.temperatureLogCoolingTimeService.calculateDiff(arrivalValue, norValue);
            })
        );

        this.temperatureLog$.pipe(takeUntil(this.destroy$)).subscribe((temperatureLog) => {
            this.temperatureLog = temperatureLog;
            this.setForm();
        });
    }

    ngOnInit() {
        this.temperatureLogDataService.load();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getTemperature(eventName: TemperatureLogEventType) {
        return this.temperatureLog?.events?.find((x) => x.type === eventName)?.temperature;
    }

    getDate(eventName: TemperatureLogEventType) {
        const eventDate = this.temperatureLog?.events?.find((x) => x.type === eventName)?.date;
        return eventDate ? DateTime.fromISO(eventDate)?.toLocaleString(Maritime.DATETIME_FULL) : null;
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }

    edit() {
        this.edit$.next(true);
    }

    save() {
        if (!this.eventsFormGroup.valid) {
            return;
        }

        const saveTemperatureLog: TemperatureLog = {
            events: []
        };

        this.temperatureLogEventTypes.forEach((eventName) => {
            const eventDate = this.eventsFormGroup.get(`${eventName}-date`).value;
            saveTemperatureLog.events.push({
                type: eventName,
                temperature: this.eventsFormGroup.get(`${eventName}-temperature`).value ?? "",
                date: eventDate ? DateTime.fromJSDate(eventDate).toISO({ includeOffset: false }) : null
            });
        });

        this.temperatureLogDataService
            .persist(saveTemperatureLog)
            .pipe(
                takeUntil(this.destroy$),
                tap((_) => this.edit$.next(false))
            )
            .subscribe();
    }

    cancel() {
        this.eventsFormGroup.reset();
        this.setForm();
        this.edit$.next(false);
    }

    private createForm(): void {
        this.temperatureLogEventTypes.forEach((eventName) => {
            const controlTemperature: UntypedFormControl = new UntypedFormControl("", Validators.compose([Validators.min(MIN_TEMPERATURE), Validators.max(MAX_TEMPERATURE)]));
            this.eventsFormGroup.addControl(`${eventName}-temperature`, controlTemperature);

            const controlDate: UntypedFormControl = new UntypedFormControl(new Date(""));
            this.eventsFormGroup.addControl(`${eventName}-date`, controlDate);
        });

        this.eventsFormGroup.setValidators([RangeValidator.validate(`${ArrivalDateType}-date`, `${NORDateType}-date`)]);
    }

    private setForm(): void {
        const events = this.temperatureLog?.events;

        this.temperatureLogEventTypes.forEach((eventName) => {
            const temperature = events?.find((x) => x.type === eventName)?.temperature;
            this.eventsFormGroup.get(`${eventName}-temperature`).setValue(temperature);

            const date = events?.find((x) => x.type === eventName)?.date;
            this.eventsFormGroup.get(`${eventName}-date`).setValue(date ? new Date(date) : null);
        });
    }
}
