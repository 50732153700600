import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { debounceTime, withLatestFrom } from "rxjs/operators";

import { FixtureSource } from "../../../fixture/shared/models/enums/fixture-source";
import { ValidationTextFns } from "../../../shared/components/validation/validation.component";
import { AbstractControlWarn, SharingGroupsEditorValidator } from "../../create-fixture/validators/sharing-groups-editor-validator";
import { ConfigurationRolesFixtureViewModel, SharingGroupsViewModel } from "../../shared/models/sharing-group.model";
import { SharingGroupsService } from "../../shared/sharing-groups.service";
import { ContactService } from "../contact.service";
import { Company } from "../models/company.model";

@Component({
    selector: "ops-sharing-groups",
    templateUrl: "./sharing-groups.component.html",
    styleUrls: ["./sharing-groups.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SharingGroupsService]
})
export class SharingGroupsComponent implements OnInit, OnDestroy {
    static componentName = "SharingGroupsComponent";

    private sharingGroups = new Subject<SharingGroupsViewModel[]>();
    private dataSubscriptions: Subscription[] = [];
    private fixtureId: string;

    readonly sharingGroupsValidationFns: ValidationTextFns = {
        required: () => "At least one sharing group must be selected"
    };

    FixtureSource = FixtureSource;
    collapsed = false;
    sharingGroupsForm: UntypedFormGroup;
    isLoading = true;

    @Input() company: Company;
    @Input() fixtureSource: FixtureSource;

    constructor(
        private sharingGroupsService: SharingGroupsService,
        private contactService: ContactService,
        private formBuilder: UntypedFormBuilder,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (this.company && this.company.collapsed !== undefined) {
            this.collapsed = this.company.collapsed;
        }

        this.createForm();

        this.dataSubscriptions.push(
            this.sharingGroups.pipe(debounceTime(500)).subscribe((allGroups) => {
                if (this.sharingGroupsForm.valid && this.fixtureId) {
                    this.sharingGroupsService.updateSharingGroups(this.fixtureId, this.company.id, allGroups);
                }
            })
        );

        this.dataSubscriptions.push(
            this.sharingGroupsService.sharingGroups$.pipe(withLatestFrom(this.contactService.fixtureGroups$)).subscribe(([sharingGroupsData, configurationRolesData]) => {
                if (sharingGroupsData && sharingGroupsData.companyId === this.company.accountId) {
                    this.fixtureId = configurationRolesData && configurationRolesData.fixtureId;
                    this.initSharingGroups(sharingGroupsData.sharingGroups);
                    this.updateSelectedSharingGroups(configurationRolesData);
                    this.cd.markForCheck();
                    this.isLoading = false;
                }
            })
        );

        this.sharingGroupsService.getSharingGroupsForCompany(this.company && this.company.accountId);
    }

    ngOnDestroy() {
        this.dataSubscriptions.forEach((s) => s && s.unsubscribe());
    }

    noSharingGroups(): boolean {
        return this.sharingGroupsFormArray.length === 0;
    }

    sharingGroupSelected() {
        this.company.isNew = false;
        this.updateSharingGroupRules();

        const allGroups = this.sharingGroupsFormArray.controls.map((x) => <SharingGroupsViewModel>x.value);
        this.sharingGroups.next(allGroups);
    }

    get sharingGroupsFormArray(): UntypedFormArray {
        return this.sharingGroupsForm.get("sharingGroups") as UntypedFormArray;
    }

    get sharingGroupsControlWarnings(): AbstractControlWarn {
        return this.sharingGroupsForm.get("sharingGroups") as AbstractControlWarn;
    }

    private createForm(): void {
        this.sharingGroupsForm = this.formBuilder.group({
            sharingGroups: this.formBuilder.array([], SharingGroupsEditorValidator.validate(this.company))
        });
    }

    private initSharingGroups(sharingGroups: SharingGroupsViewModel[]) {
        const formArray = this.sharingGroupsFormArray;
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
        sharingGroups.forEach((sg) => formArray.push(this.createSharingGroup(sg)));
    }

    private updateSelectedSharingGroups(selected: ConfigurationRolesFixtureViewModel) {
        for (const control of this.sharingGroupsFormArray.controls) {
            if (control instanceof UntypedFormGroup) {
                control.patchValue({
                    isSelected: selected && selected.configurationRoles && selected.configurationRoles.findIndex((c) => c === `opsg_${<string>control.value.code}`) > -1
                });
            }
        }
        this.updateSharingGroupRules();
    }

    private updateSharingGroupRules() {
        const selected = this.sharingGroupsFormArray.controls.filter((x) => (<SharingGroupsViewModel>x.value).isSelected && (<SharingGroupsViewModel>x.value).isCurrentUserPresent);
        if (selected.length === 1) {
            selected[0].disable();
        } else {
            this.sharingGroupsFormArray.controls.forEach((c) => c.enable());
        }

        this.sharingGroupsFormArray.markAsTouched();
    }

    private createSharingGroup(model: SharingGroupsViewModel) {
        const group = this.formBuilder.group({
            name: new UntypedFormControl(model.name),
            isGroupAll: new UntypedFormControl(model.isGroupAll),
            isCurrentUserPresent: new UntypedFormControl(model.isCurrentUserPresent),
            isSelected: new UntypedFormControl(false, { updateOn: "change" }),
            code: [model.code]
        });

        return group;
    }
}
