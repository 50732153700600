import { Pipe, PipeTransform } from "@angular/core";
import { formatNumber } from "../../number-format";

// TODO: Gap analysis vs number pipe, showTrailingZeros/precision can be the same...

/**
 * Converts a number to a string.
 *
 * @see `formatNumber()`
 */
@Pipe({
    name: "opsNumber"
})
export class NumberFormatPipe implements PipeTransform {
    /**
     * Converts a number to a string.
     *
     * @param value
     * The number to format
     * @param showTrailingZeros
     * True to show trailing fraction zeros.
     * @param minimumFractionDigits
     * The minimum number of fraction digits to use. Possible values are from 0 to 20.
     * @param precision
     * The maximum number of fraction digits to use, or the exact number to use when showTrailingZeros=true.
     * Possible values are from 0 to 20.
     * @param style
     * In many locales, accounting format means to wrap the number with parentheses instead of appending a minus sign.
     * You can enable this formatting by setting the currencySign option to "accounting". The default value is "standard".
     */
    transform(value: number, showTrailingZeros: boolean, minimumFractionDigits: number, precision: number, style?: "standard" | "accounting"): string {
        return formatNumber(value, showTrailingZeros, minimumFractionDigits, precision, undefined, style);
    }
}
