import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

@Directive({ selector: "[overflowOnlyTooltip]" })
export class OverflowOnlyTooltipDirective {
    @Input() overflowOnlyTooltip: boolean;

    constructor(private elementRef: ElementRef<HTMLElement>, private ngbTooltip: NgbTooltip) {}

    @HostListener("mouseenter")
    onMouseEnter() {
        if (this.overflowOnlyTooltip) {
            const element = this.elementRef.nativeElement;
            this.ngbTooltip.disableTooltip = element.offsetWidth >= element.scrollWidth;
        }
    }
}
