import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { setValue, SetValueAction, updateArrayWithFilter, updateGroup } from "ngrx-forms";
import { filter, map, mapTo, withLatestFrom } from "rxjs/operators";

import {
    VoyageActivityFilterForm,
    AddActivityLocationsForm,
    AssociatedCargoSelectionForm,
    CargoFilterForm,
    LtcState,
    addActivityLocationsFormKey,
    LtcFeatureState,
    CargoId,
    VoyageActivityId
} from "../../../model";
import { currentCalculationStateReducer } from "../../reducer";
import { selectCurrentAddActivityLocationsForm } from "../selectors";

/* ACTIONS */
const CHANGE_FILTER_ACTION_NAME = "[LTC Add Activity Locations] Change Filter";
export const addActivityLocationsChangeFilterAction = createAction(CHANGE_FILTER_ACTION_NAME);
export const addActivityLocationsChangeFilterForVoyageActivityAction = createAction(
    `${CHANGE_FILTER_ACTION_NAME} For Voyage Activity`,
    props<{ voyageActivityId: VoyageActivityId }>()
);
export const addActivityLocationsChangeFilterForCargoAction = createAction(`${CHANGE_FILTER_ACTION_NAME} For Cargo`, props<{ cargoId: CargoId }>());

/* REDUCERS */
export const addActivityLocationsChangeFilterReducer: On<LtcState> = on(addActivityLocationsChangeFilterAction, (state) =>
    currentCalculationStateReducer(state, (calculationState) => ({
        ...calculationState,
        addActivityLocationsForm: updateGroup<AddActivityLocationsForm>({
            voyageActivities: updateArrayWithFilter(
                (va) => !va.value.imported,
                updateGroup<VoyageActivityFilterForm>({ selected: setValue<boolean>(false) })
            ),
            cargoes: updateArrayWithFilter(
                (c) => !c.value.imported,
                updateGroup<CargoFilterForm>({ selected: setValue<boolean>(false) })
            ),
            associatedCargoes: updateArrayWithFilter(
                (ac) => !ac.value.imported,
                updateGroup<AssociatedCargoSelectionForm>({ selected: setValue<boolean>(false) })
            )
        })(calculationState.addActivityLocationsForm)
    }))
);

export const addActivityLocationsChangeFilterForVoyageActivityReducer: On<LtcState> = on(addActivityLocationsChangeFilterForVoyageActivityAction, (state, { voyageActivityId }) =>
    currentCalculationStateReducer(state, (calculationState) => ({
        ...calculationState,
        addActivityLocationsForm: updateGroup<AddActivityLocationsForm>({
            associatedCargoes: updateArrayWithFilter(
                (ac) => ac.value.voyageActivityId === voyageActivityId && !ac.value.imported,
                updateGroup<AssociatedCargoSelectionForm>({ selected: setValue<boolean>(false) })
            )
        })(calculationState.addActivityLocationsForm)
    }))
);

export const addActivityLocationsChangeFilterForCargoReducer: On<LtcState> = on(addActivityLocationsChangeFilterForCargoAction, (state, { cargoId }) =>
    currentCalculationStateReducer(state, (calculationState) => ({
        ...calculationState,
        addActivityLocationsForm: updateGroup<AddActivityLocationsForm>({
            associatedCargoes: updateArrayWithFilter(
                (ac) => ac.value.cargoId === cargoId && !ac.value.imported,
                updateGroup<AssociatedCargoSelectionForm>({ selected: setValue<boolean>(false) })
            )
        })(calculationState.addActivityLocationsForm)
    }))
);

/* EFFECTS */
export const addActivityLocationsChangeFilterEffect$ = (actions$: Actions) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<AddActivityLocationsForm>>(SetValueAction.TYPE),
            filter(({ controlId }) => controlId.split(".")[1] === addActivityLocationsFormKey && controlId.split(".")[2] === "filter"),
            mapTo(addActivityLocationsChangeFilterAction())
        )
    );

export const addActivityLocationsChangeFilterSelectionEffect$ = (actions$: Actions, store: Store<LtcFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<AddActivityLocationsForm>>(SetValueAction.TYPE),
            map(({ controlId, value }) => {
                const parts = controlId.split(".");
                return { formKey: parts[1], filterKey: parts[2], index: +parts[3], selected: value };
            }),
            filter(({ formKey, filterKey, selected }) => formKey === addActivityLocationsFormKey && ["voyageActivities", "cargoes"].includes(filterKey) && !selected),
            withLatestFrom(store.select(selectCurrentAddActivityLocationsForm)),
            map(([{ filterKey, index }, { value }]) =>
                filterKey === "voyageActivities"
                    ? addActivityLocationsChangeFilterForVoyageActivityAction({ voyageActivityId: value.voyageActivities[index].voyageActivityId })
                    : addActivityLocationsChangeFilterForCargoAction({ cargoId: value.cargoes[index].cargoId })
            )
        )
    );
