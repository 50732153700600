import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject, Subscription } from "rxjs";
import { debounceTime, withLatestFrom } from "rxjs/operators";

import { AuthService } from "../../../core";
import { parseISODate } from "../../../shared/date-utils/date-utilities";
import { FixtureHttpService } from "../../services/fixture-http.service";
import { FormComponentBase } from "../../shared/form-component-base";
import { ClaimNotification, Destination } from "../../shared/models";
import { ClaimsNotificationFormModel } from "../../shared/models/form-models/claims-notification.model";
import { UpdateClaimsNotificationCommand } from "./commands/update-claims-notification.command";

@Component({
    selector: "ops-claims-notification",
    templateUrl: "./claims-notification.component.html",
    styleUrls: ["./claims-notification.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClaimsNotificationComponent extends FormComponentBase implements OnInit, OnChanges, OnDestroy {
    static componentName = "ClaimsNotificationComponent";

    private formValues$: BehaviorSubject<ClaimsNotificationFormModel> = new BehaviorSubject<ClaimsNotificationFormModel>(null);
    private subscriptions: Subscription[] = [];

    lastUpdatedByUser: string;
    lastUpdatedDate: Date;
    claimsNotificationForm: UntypedFormGroup;

    @Input() parentForm: UntypedFormGroup;
    @Input() fixtureId: string;
    @Input() claimsNotification: ClaimNotification;
    @Input() destinations: Destination[];
    @Output() claimsNotificationUpdated = new EventEmitter();

    constructor(private formBuilder: UntypedFormBuilder, private fixtureService: FixtureHttpService, private authService: AuthService) {
        super();
    }

    ngOnInit() {
        this.setupClaimsNotificationForm();
        this.setClaimsNotificationValues(this.claimsNotification);
        this.parentForm.registerControl("claimsNotification", this.claimsNotificationForm);
        this.subscribeToFormValueChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.claimsNotification || changes.claimsNotification.firstChange) {
            return;
        }

        this.removeFormSubscriptions();
        this.setClaimsNotificationValues(changes.claimsNotification.currentValue);
        this.subscribeToFormValueChanges();
    }

    updateLastContacted(): void {
        const sub = this.fixtureService
            .updateLastContacted(this.fixtureId)
            .pipe(withLatestFrom(this.formValues$))
            .subscribe(([date, formValue]) => {
                formValue.lastContactedDate = parseISODate(date);
                this.claimsNotificationUpdated.emit(new UpdateClaimsNotificationCommand(this.authService.user, formValue));
            });
        this.subscriptions.push(sub);
    }

    ngOnDestroy() {
        this.removeFormSubscriptions();
        delete this.parentForm.controls.claimsNotification;
        this.subscriptions.forEach((sub) => sub.unsubscribe);
    }

    protected subscribeToFormValueChanges() {
        this.formValueSubscription = this.claimsNotificationForm.valueChanges.pipe(debounceTime(10)).subscribe((value) => {
            this.claimsNotificationUpdated.emit(new UpdateClaimsNotificationCommand(this.authService.user, value));
            this.formValues$.next(value);
        });
    }

    private setupClaimsNotificationForm(): void {
        this.claimsNotificationForm = this.formBuilder.group({});
        this.claimsNotificationForm.registerControl("receivedFromOwnerDate", this.formBuilder.control(""));
        this.claimsNotificationForm.registerControl("sentToChartererDate", this.formBuilder.control(""));
        this.claimsNotificationForm.registerControl("chartererAcknowledgedReceiptDate", this.formBuilder.control(""));
        this.claimsNotificationForm.registerControl("lastContactedDate", this.formBuilder.control(""));

        if (this.parentForm.disabled) {
            this.claimsNotificationForm.disable();
        } else {
            this.claimsNotificationForm.enable();
        }
    }

    private setClaimsNotificationValues(claimsNotification: ClaimNotification): void {
        const claimsNotificationFormValues: ClaimsNotificationFormModel = {
            receivedFromOwnerDate: parseISODate(claimsNotification.receivedFromOwnerDate),
            sentToChartererDate: parseISODate(claimsNotification.sentToChartererDate),
            chartererAcknowledgedReceiptDate: parseISODate(claimsNotification.chartererAcknowledgedReceiptDate),
            lastContactedDate: parseISODate(claimsNotification.lastContactedDate)
        };

        this.lastUpdatedByUser = claimsNotification.lastUpdatedByUser ? claimsNotification.lastUpdatedByUser.fullName : "";
        this.lastUpdatedDate = parseISODate(claimsNotification.lastUpdatedDate);

        this.claimsNotificationForm.patchValue(claimsNotificationFormValues, { emitEvent: false });
        this.formValues$.next(claimsNotificationFormValues);
    }
}
