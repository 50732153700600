import * as localforage from "localforage";
import { from, Observable } from "rxjs";

export class GridWebStorageServiceBase {
    private store: LocalForage;

    constructor(storeName: string) {
        this.store = localforage.createInstance({
            name: "ops",
            storeName: storeName
        });
    }

    get(settingName: string): Observable<any> {
        return from(this.store.getItem(settingName)) as Observable<any>;
    }

    set(settingName: string, setting: any): void {
        this.store.setItem(settingName, setting);
    }
}
