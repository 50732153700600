import { Component, Input } from "@angular/core";

@Component({
    selector: "ops-description-icon",
    template: '<span class="ops-description-icon" [class]="cssClass" [title]="type"></span>',
    styleUrls: ["./description-icon.component.scss"]
})
export class DescriptionIconComponent {
    @Input() type: DescriptorType;

    get cssClass() {
        switch (this.type) {
            case "Reversible":
                return "ops-reversible-tag";
            case "Non Reversible":
                return "ops-non-reversible-tag";
            case "Load":
                return "ops-load-tag";
            case "Discharge":
                return "ops-discharge-tag";
            case "Bunkering":
                return "ops-bunkering-tag";
            case "Transit":
                return "ops-transit-tag";
            default:
                return null;
        }
    }
}

const reversibleLaytimeTypes = ["Reversible", "Non Reversible"];
const activityTypes = ["Load", "Discharge", "Bunkering", "Transit"];
const descriptorTypes = [...reversibleLaytimeTypes, ...activityTypes];
export type DescriptorType = typeof descriptorTypes[number];
