import { LaytimeCalculationUnit } from "@ops/shared/reference-data";
import { Command } from "../../mediator";
import { Destination, User } from "../../shared/models";
import { CreateClaimCommandHandler } from "./create-claim.command-handler";

export class CreateClaimCommand extends Command {
    constructor(readonly currentUser: User, readonly destinations: Destination[]) {
        super(CreateClaimCommandHandler, null);
    }
}
