import { box, Boxed, unbox } from "ngrx-forms";
import * as R from "ramda";
import { v4 as uuid } from "uuid";

import { deepCopy, Identity, isNullOrUndefined } from "@ops/shared";
import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { Berth, CargoBerthActivity, Destination, Division, FixtureType } from "../../shared/models";
import { DateRange } from "../../shared/models/dtos/date-range.dto";
import { AssociatedCargoForm, associatedCargoToForm, formToAssociatedCargo } from "./associated-cargo";
import { formToLaytimeEvent, getDefaultLaytimeEvents, LaytimeEventForm, laytimeEventToForm } from "./laytime-event";

export type ActivityId = Identity<string, "ActivityId">;
export const createActivityId = (): ActivityId => uuid() as ActivityId;

export type ActivityType = Readonly<CargoBerthActivityType>;

export type ActivityForm = Readonly<{
    activityId: ActivityId;
    legacyActivityId: number;
    type?: Boxed<ActivityType>;
    associatedCargoes: ReadonlyArray<AssociatedCargoForm>;
    laytimeEvents?: ReadonlyArray<LaytimeEventForm>;
    blAtDisport?: boolean;
    deliveryWindow?: Boxed<DateRange>;
    /* Not in NGRX */
    // laytimeEventFacts?: ReadonlyArray<LaytimeEventFactForm>;
    // bunkersRemainingOnboard?: ReadonlyArray<BunkerRemainingOnboard>;
}>;

export const activityForm = (division: Division, activityId: ActivityId, legacyActivityId: number): ActivityForm => ({
    activityId,
    legacyActivityId,
    type: null,
    associatedCargoes: [],
    laytimeEvents: [],
    blAtDisport: null,
    deliveryWindow: null
});

export function activityToForm(fixtureType: FixtureType, division: Division, destination: Destination, berth: Berth) {
    const location = unbox(destination.location);
    const timeZone = (location ? location.timeZone : null) || "utc";
    const berthIndex = destination.berths.indexOf(berth);

    return (source: CargoBerthActivity, index: number): ActivityForm => {
        let laytimeEvents: LaytimeEventForm[];

        if (source.laytimeEvents === null) {
            laytimeEvents = getDefaultLaytimeEvents(fixtureType, division, source.type, berthIndex === 0 && index === 0, destination.arrivalDateTime, timeZone);
        } else {
            laytimeEvents = source.laytimeEvents.map(laytimeEventToForm(division));
        }

        return {
            activityId: source.id,
            legacyActivityId: source.berthActivityId,
            type: box(deepCopy(source.type)),
            blAtDisport: source.blAtDisport,
            deliveryWindow: source.deliveryWindow ? box(source.deliveryWindow) : null,
            associatedCargoes: source.associatedCargoes.map(associatedCargoToForm(division)),
            laytimeEvents
        };
    };
}

export const formToActivity = (source: ActivityForm, current: CargoBerthActivity): CargoBerthActivity => ({
    ...current,
    id: source.activityId,
    berthActivityId: source.legacyActivityId,
    type: unbox(source.type),
    blAtDisport: source.blAtDisport,
    deliveryWindow: source.deliveryWindow ? unbox(source.deliveryWindow) : null,
    associatedCargoes: source.associatedCargoes.map((ac) => formToAssociatedCargo(ac, current ? current.associatedCargoes.find((x) => x.id === ac.associatedCargoId) : null)),
    laytimeEvents: source.laytimeEvents
        ? source.laytimeEvents.map((le) => formToLaytimeEvent(le, current && current.laytimeEvents ? current.laytimeEvents.find((x) => x.id === le.laytimeEventId) : null))
        : null
});

/**
 * Returns true if the activity has one or more laytime events with the event date set.
 */
export const activityLastLaytimeEvent = (activity: ActivityForm): LaytimeEventForm =>
    R.pipe(
        R.last,
        R.ifElse(isNullOrUndefined, () => null, R.last)
    )(activity.laytimeEvents ? activity.laytimeEvents : []);
