import { AbstractControl, ValidatorFn } from "@angular/forms";

import { isNullOrUndefined } from "../utils/check";

export class RangeValidator {
    static validate(fromField: string, toField: string, allowEqual: boolean = false): ValidatorFn {
        const errorKey = "invalidRange";

        return (control: AbstractControl): null => {
            const fromControl = control.get(fromField);
            const toControl = control.get(toField);

            const hasFromValue = !isNullOrUndefined(fromControl.value);
            const hasToValue = !isNullOrUndefined(toControl.value);

            if (fromControl && toControl && hasFromValue && hasToValue && (fromControl.value > toControl.value || (allowEqual && fromControl.value >= toControl.value))) {
                fromControl.setErrors({ [errorKey]: true });
                toControl.setErrors({ [errorKey]: true });
            } else {
                if (fromControl.errors && fromControl.hasError(errorKey)) {
                    delete fromControl.errors[errorKey];
                    fromControl.updateValueAndValidity({ onlySelf: true });
                }
                if (toControl.errors && toControl.hasError(errorKey)) {
                    delete toControl.errors[errorKey];
                    toControl.updateValueAndValidity({ onlySelf: true });
                }
            }

            return null;
        };
    }
}
