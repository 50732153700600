import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "@ops/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class TcVoyageRouteGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  Observable<boolean | UrlTree> {
        return this.authService.hasRole("TCVoyages").pipe(
            map((hasRole: boolean) => {
                return hasRole ? hasRole : this.router.createUrlTree(["/fixture", route.parent.params["fixtureId"]]);
            })
        );
    }
}
