import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import { FormArrayState, FormGroupState, removeArrayControl, setValue, updateArray, updateArrayWithFilter, updateGroup } from "ngrx-forms";
import * as R from "ramda";
import { filter, tap, withLatestFrom } from "rxjs/operators";

import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { findAllSpecialisedCargoDestinationElements } from "./specialised/specialised-cargo-functions";
import { FixtureDataService } from "../../../services/fixture-data.service";
import { AtSeaBunkerConsumption, Division, FixtureType } from "../../../shared/models";
import { selectCurrentFixtureDivision, selectCurrentFixtureType } from "../../fixture";
import {
    activityExpandedKey,
    ActivityForm,
    AssociatedCargoForm,
    berthExpandedKey,
    BerthForm,
    cargoExpandedKey,
    CargoId,
    destinationExpandedKey,
    DestinationForm,
    DestinationId,
    FixtureFeatureState,
    FixturesState,
    isDivision,
    VoyageExpandedSections,
    VoyageForm
} from "../../model";
import { selectCurrentVoyageFormValue } from "../../voyage";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
const REMOVE_CARGO_NAME = "[Voyage Form] Remove Cargo";

export const removeCargoAction = createAction(REMOVE_CARGO_NAME, props<{ cargoId: CargoId }>());

/* REDUCERS */
export const removeCargoReducer: On<FixturesState> = on(removeCargoAction, (state, { cargoId }) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        const cargoIndex = voyageState.form.controls.cargoes.controls.findIndex((c) => c.value.cargoId === cargoId);
        if (cargoIndex < 0) {
            return voyageState;
        }

        let destinations = voyageState.form.controls.destinations;
        let atSeaBunkersConsumption = voyageState.form.controls.atSeaBunkersConsumption;
        let expandedSections = <VoyageExpandedSections>R.omit([cargoExpandedKey(cargoId)], voyageState.expandedSections);

        if (isDivision(fixtureState, Division.specialisedProducts)) {
            const updates = getAllUpdatedDestinationsAndExpandedSections(cargoId, destinations, expandedSections, atSeaBunkersConsumption);
            destinations = updates.updatedDestinationForms;
            expandedSections = updates.expandedSections;
            atSeaBunkersConsumption = updates.updatedAtSeaBunkersConsumption;
        }

        return {
            ...voyageState,
            form: updateGroup<VoyageForm>({
                cargoes: removeArrayControl(cargoIndex),
                destinations: () => updateArray(updateDestination(cargoId))(destinations),
                atSeaBunkersConsumption: () => atSeaBunkersConsumption
            })(voyageState.form),
            workingVoyage: {
                ...voyageState.workingVoyage,
                cargoAllowedRates: voyageState.workingVoyage.cargoAllowedRates.filter((r) => r.cargoId !== cargoId)
            },
            expandedSections
        };
    })
);

/* EFFECTS */
export const interopRemoveSpecialisedDestinationsFromDemurrageEffect$ = (actions$: Actions, store: Store<FixtureFeatureState>, fixtureService: FixtureDataService) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(removeCargoAction),
                withLatestFrom(store.select(selectCurrentVoyageFormValue), store.select(selectCurrentFixtureType), store.select(selectCurrentFixtureDivision)),
                filter(([, , fixtureType, division]) => fixtureType === FixtureType.Voyage && division === Division.specialisedProducts),
                tap(([{ cargoId }, voyageForm]) => {
                    const load = findAllSpecialisedCargoDestinationElements(cargoId, CargoBerthActivityType.Load, voyageForm.destinations)?.destination;
                    const discharge = findAllSpecialisedCargoDestinationElements(cargoId, CargoBerthActivityType.Discharge, voyageForm.destinations)?.destination;

                    if (load && discharge) {
                        fixtureService.removeLocationFromDemurrageClaim(load.destinationId);
                        fixtureService.removeLocationFromDemurrageClaim(discharge.destinationId);
                    }
                })
            ),
        { dispatch: false }
    );

/* FUNCTIONS */
const updateAssociatedCargo = (cargoId: CargoId) => (cargo: FormGroupState<AssociatedCargoForm>) => {
    if (cargo.value.cargoId === cargoId) {
        return updateGroup<AssociatedCargoForm>({
            cargoId: setValue(null as string)
        })(cargo);
    }
    return cargo;
};

const updateDestination = (cargoId: CargoId) => (destination: FormGroupState<DestinationForm>) =>
    updateGroup<DestinationForm>({
        berths: updateArray(
            updateGroup<BerthForm>({
                activities: updateArray(
                    updateGroup<ActivityForm>({
                        associatedCargoes: updateArray(updateAssociatedCargo(cargoId))
                    })
                )
            })
        )
    })(destination);

const getAllUpdatedDestinationsAndExpandedSections = (
    cargoId: CargoId,
    destinationForms: FormArrayState<DestinationForm>,
    expandedSections: VoyageExpandedSections,
    atSeaBunkersConsumption: FormArrayState<AtSeaBunkerConsumption>
) => {
    let updatedDestinationForms = destinationForms;
    let updatedAtSeaBunkersConsumption = atSeaBunkersConsumption;

    const removeAtSeaBunkersConsumption = (id: DestinationId) => {
        while (true) {
            const index = updatedAtSeaBunkersConsumption?.controls.findIndex((item) => item.value.destinationFromId === id || item.value.destinationFromId === id);
            if (index >= 0) {
                updatedAtSeaBunkersConsumption = removeArrayControl(index)(updatedAtSeaBunkersConsumption);
            } else {
                break;
            }
        }
    };

    const removeDestination = (type: CargoBerthActivityType) => {
        const allElements = findAllSpecialisedCargoDestinationElements(cargoId, type, updatedDestinationForms.value);

        if (!!allElements) {
            const { destination, berth, activity } = allElements;

            if (destination.berths.length === 1 && berth.activities.length === 1 && activity.associatedCargoes.length === 1) {
                const di = updatedDestinationForms.controls.findIndex((dest) => dest.value.id === destination.id);
                updatedDestinationForms = removeArrayControl(di)(updatedDestinationForms);
                removeAtSeaBunkersConsumption(destination.id);
                expandedSections = <VoyageExpandedSections>R.omit([destinationExpandedKey(destination.id)], expandedSections);
            } else if (berth.activities.length === 1 && activity.associatedCargoes.length === 1) {
                const bi = destination.berths.findIndex((b) => b.id === berth.id);
                updatedDestinationForms = updateArrayWithFilter(
                    (dest) => dest.value.id === destination.id,
                    updateGroup<DestinationForm>({
                        berths: removeArrayControl(bi)
                    })
                )(updatedDestinationForms);
                expandedSections = <VoyageExpandedSections>R.omit([berthExpandedKey(destination.id, berth.id)], expandedSections);
            } else if (activity.associatedCargoes.length === 1) {
                const ai = berth.activities.findIndex((cba) => cba.activityId === activity.activityId);
                updatedDestinationForms = updateArrayWithFilter(
                    (dest) => dest.value.id === destination.id,
                    updateGroup<DestinationForm>({
                        berths: updateArrayWithFilter(
                            (b) => b.value.id === berth.id,
                            updateGroup<BerthForm>({
                                activities: removeArrayControl(ai)
                            })
                        )
                    })
                )(updatedDestinationForms);
                expandedSections = <VoyageExpandedSections>R.omit([activityExpandedKey(destination.id, berth.id, activity.activityId)], expandedSections);
            } else {
                const ci = activity.associatedCargoes.findIndex((ac) => ac.cargoId === cargoId);
                const updatedLaytimeEvents = activity.laytimeEvents.filter((l) => l.cargoId !== cargoId);
                updatedDestinationForms = updateArrayWithFilter(
                    (dest) => dest.value.id === destination.id,
                    updateGroup<DestinationForm>({
                        berths: updateArrayWithFilter(
                            (b) => b.value.id === berth.id,
                            updateGroup<BerthForm>({
                                activities: updateArrayWithFilter(
                                    (a) => a.value.activityId === activity.activityId,
                                    updateGroup<ActivityForm>({
                                        associatedCargoes: removeArrayControl(ci),
                                        laytimeEvents: setValue(updatedLaytimeEvents)
                                    })
                                )
                            })
                        )
                    })
                )(updatedDestinationForms);
            }
        }
    };

    removeDestination(CargoBerthActivityType.Load);
    removeDestination(CargoBerthActivityType.Discharge);

    return { updatedDestinationForms, expandedSections, updatedAtSeaBunkersConsumption };
};
