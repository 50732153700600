import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { NgSelectComponent } from "@ng-select/ng-select";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { CargoBerthActivityType, FreightType } from "../../../shared/reference-data";
import { Division, Voyage } from "../../shared/models";
import {
    addAssociatedCargo,
    addLaytimeEvent,
    AssociatedCargoState,
    BerthItem,
    CargoItem,
    FixtureFeatureState,
    LaytimeEventId,
    LaytimeEventState,
    orderLaytimeEvents,
    removeAssociatedCargo,
    removeLaytimeEvent,
    selectCurrentDestinationActivityFormType,
    selectCurrentFixtureDivision,
    selectCurrentFixtureFreightType,
    selectCurrentVoyage,
    selectCurrentVoyageActivityLaytimeEventsOrdered,
    selectCurrentVoyageActivityTotalLaytime,
    selectCurrentVoyageAssociatedCargoDropdownItems,
    selectCurrentVoyageAssociatedCargoes,
    selectCurrentVoyageBerthList,
    selectCurrentVoyageCargoItems,
    selectCurrentVoyageDestinationArrivalDateTime,
    selectCurrentVoyageDestinationLocationTimezone,
    selectCurrentVoyageLaytimeEvents
} from "../../state";
import { FixturePopupActivityInfo } from "../models/fixture-popup-tab";

@Component({
    selector: "ops-fixture-popup-laytime-events",
    templateUrl: "./fixture-popup-laytime-events.component.html",
    styleUrls: ["./fixture-popup-laytime-events.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupLaytimeEventsComponent implements OnInit {
    currentVoyage$: Observable<Voyage>;
    activityType$: Observable<Readonly<CargoBerthActivityType>>;
    locationTimezone$: Observable<string>;
    showAssociatedCargoes$: Observable<boolean>;
    associatedCargoes$: Observable<ReadonlyArray<AssociatedCargoState>>;
    cargoes$: Observable<ReadonlyArray<CargoItem>>;
    allCargoes$: Observable<ReadonlyArray<CargoItem>>;
    berths$: Observable<ReadonlyArray<BerthItem>>;
    arrivalDateTime$: Observable<string>;
    freightType$: Observable<FreightType | null>;
    division$: Observable<Division | null>;
    laytimeEvents$: Observable<ReadonlyArray<LaytimeEventState>>;
    totalLaytime$: Observable<number | null>;
    laytimeEventsOrdered$: Observable<boolean | null>;

    @Input() activityInfo: FixturePopupActivityInfo;
    @Input() isReadOnly: boolean;

    constructor(private store: Store<FixtureFeatureState>) {}

    ngOnInit(): void {
        this.currentVoyage$ = this.store.select(selectCurrentVoyage, this.activityInfo);
        this.activityType$ = this.store.select(selectCurrentDestinationActivityFormType, this.activityInfo);
        this.locationTimezone$ = this.store.select(selectCurrentVoyageDestinationLocationTimezone, this.activityInfo);
        this.showAssociatedCargoes$ = this.store
            .select(selectCurrentDestinationActivityFormType, this.activityInfo)
            .pipe(map((type) => type.id === CargoBerthActivityType.Load.id));
        this.associatedCargoes$ = this.store.select(selectCurrentVoyageAssociatedCargoes, this.activityInfo);
        this.cargoes$ = this.store.select(selectCurrentVoyageAssociatedCargoDropdownItems, this.activityInfo);
        this.allCargoes$ = this.store.select(selectCurrentVoyageCargoItems, this.activityInfo);
        this.berths$ = this.store.select(selectCurrentVoyageBerthList, this.activityInfo);
        this.arrivalDateTime$ = this.store.select(selectCurrentVoyageDestinationArrivalDateTime, this.activityInfo);
        this.freightType$ = this.store.select(selectCurrentFixtureFreightType, this.activityInfo);
        this.division$ = this.store.select(selectCurrentFixtureDivision, this.activityInfo);
        this.laytimeEvents$ = this.store.select(selectCurrentVoyageLaytimeEvents, this.activityInfo);
        this.totalLaytime$ = this.store.select(selectCurrentVoyageActivityTotalLaytime, this.activityInfo);
        this.laytimeEventsOrdered$ = this.store.select(selectCurrentVoyageActivityLaytimeEventsOrdered, this.activityInfo);
    }

    addAssociatedCargo() {
        this.store.dispatch(addAssociatedCargo(this.activityInfo));
    }

    removeAssociatedCargo(index: number) {
        this.store.dispatch(removeAssociatedCargo({ ...this.activityInfo, index }));
    }

    addLaytimeEvent(index: number) {
        this.store.dispatch(addLaytimeEvent({ ...this.activityInfo, index }));
    }

    orderLaytimeEvents() {
        this.store.dispatch(orderLaytimeEvents(this.activityInfo));
    }

    removeLaytimeEvent(laytimeEventId: LaytimeEventId) {
        this.store.dispatch(removeLaytimeEvent({ ...this.activityInfo, laytimeEventId }));
    }

    // adjusts dropdown position and height to avoid going off the visible frame
    adjustDropdownPosition(ngSelect: NgSelectComponent) {
        const parentEl = ngSelect.element.closest(".tab-panel-container");
        if (!parentEl) {
            throw new Error("Can't find .tab-panel-container element");
        }
        const ngSelectRect = ngSelect.element.getBoundingClientRect();
        const parentElRect = parentEl.getBoundingClientRect();
        const maxDropdownHeight = 240;
        const minDropdownHeight = 200;
        const itemHeight = 33;
        // for Cargo dropdown we can calculate dropdown height, for the rest we take fixed value
        const dropdownHeight = ngSelect.bindValue === "cargoId" ? (ngSelect.items?.length ?? 1) * itemHeight + 1 : minDropdownHeight;
        // if we can't open the dropdown downwards, open it upwards
        if (ngSelectRect.bottom + dropdownHeight >= parentElRect.bottom) {
            ngSelect.dropdownPosition = "top";
            // if there is no enough space upwards, shrink the dropdown
            if (ngSelectRect.top - maxDropdownHeight <= parentElRect.top) {
                this.adjustDropdownPanelHeight(parentEl, ngSelectRect.top - parentElRect.top - 1);
            }
        } else {
            ngSelect.dropdownPosition = "bottom";
            // if there is no enough space downwards, shrink the dropdown
            if (ngSelectRect.bottom + maxDropdownHeight >= parentElRect.bottom) {
                this.adjustDropdownPanelHeight(parentEl, parentElRect.bottom - ngSelectRect.bottom - 1);
            }
        }
    }

    // adjusts the max height for the dropdown panel
    private adjustDropdownPanelHeight(parentEl: Element, maxHeight: number) {
        setTimeout(() => {
            const dropdownEl = <HTMLDivElement>parentEl.querySelector(".ng-dropdown-panel-items");
            if (dropdownEl) {
                dropdownEl.style.maxHeight = `${maxHeight}px`;
            }
        });
    }
}
