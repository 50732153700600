import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { removeArrayControl, setValue, updateArrayWithFilter, updateGroup } from "ngrx-forms";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, map, tap, withLatestFrom } from "rxjs/operators";

import { update } from "@ops/shared";

import { LaytimeCalculationHttpService } from "../../../../../services";
import { ActivityCargoId, ActivityLocation, ActivityLocationId, idEq, LtcFeatureState, LtcId, LtcState, LaytimeEventForm } from "../../../../model";
import { calculationStateReducer, currentCalculationStateReducer } from "../../../reducer";
import { selectCurrentCalculationId } from "../../../selectors";
import { UpdateQueue } from "../../../update-queue";
import { selectCurrentActivityLocationId } from "../../selectors";

/* ACTIONS */
const REMOVE_ACTION_NAME = "[Laytime Calculation Activity Location Cargoes Form] Remove Activity Location Cargo";
export const removeActivityCargoFormButtonClickedAction = createAction(`${REMOVE_ACTION_NAME} Button Clicked`, props<{ activityCargoId: ActivityCargoId }>());
export const removeActivityCargoAction = createAction(REMOVE_ACTION_NAME, props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; activityCargoId: ActivityCargoId }>());
export const removeActivityCargoSuccessAction = createAction(
    `${REMOVE_ACTION_NAME} Success`,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; activityCargoId: ActivityCargoId }>()
);
export const removeActivityCargoFailAction = createAction(
    `${REMOVE_ACTION_NAME} Fail`,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; activityCargoId: ActivityCargoId; error: Error }>()
);

/* REDUCERS */
export const removeActivityCargoFormButtonClickedReducer: On<LtcState> = on(removeActivityCargoFormButtonClickedAction, (state, { activityCargoId }) =>
    currentCalculationStateReducer(state, (ltcState) => {
        const activityCargoIndex = ltcState.activityCargoForms.value.findIndex((f) => f.id === activityCargoId);
        const activityCargoForms = removeArrayControl(activityCargoIndex)(ltcState.activityCargoForms);

        const laytimeEventForms = updateArrayWithFilter<LaytimeEventForm>(
            (f) => f.value.cargoId === activityCargoId,
            updateGroup<LaytimeEventForm>({ cargoId: setValue<string>(null) })
        )(ltcState.laytimeEventForms);

        return { ...ltcState, activityCargoForms, laytimeEventForms };
    })
);

export const removeActivityCargoReducer: On<LtcState> = on(removeActivityCargoAction, (state, { activityCargoId, activityLocationId, ltcId }) => {
    const updateFns: Evolver = {
        calculation: { activityLocations: update(idEq(activityLocationId), (al: ActivityLocation) => ({ ...al, cargoes: al.cargoes.filter((c) => c.id !== activityCargoId) })) }
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

/* EFFECTS */
export const removeActivityCargoFormEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(removeActivityCargoFormButtonClickedAction),
            withLatestFrom(store.select(selectCurrentCalculationId), store.select(selectCurrentActivityLocationId)),
            map(([{ activityCargoId }, ltcId, activityLocationId]) => removeActivityCargoAction({ ltcId, activityLocationId, activityCargoId })),
            tap((action) => updateQueue.enqueue(action, removeActivityCargo, (latest) => (action.activityCargoId === latest.activityCargoId ? action : [latest, action])))
        )
    );

export const removeActivityCargo = (
    { ltcId, activityLocationId, activityCargoId }: ReturnType<typeof removeActivityCargoAction>,
    laytimeCalculationHttpService: LaytimeCalculationHttpService
) =>
    laytimeCalculationHttpService.removeActivityCargo(ltcId, activityLocationId, activityCargoId).pipe(
        map(() => removeActivityCargoSuccessAction({ ltcId, activityLocationId, activityCargoId })),
        catchError((error) => of(removeActivityCargoFailAction({ ltcId, activityLocationId, activityCargoId, error })))
    );
