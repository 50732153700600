<div *ngIf="cargoes && cargoes.length > 0; else noCargoes">
    <table class="table">
        <thead>
            <tr>
                <th scope="col" class="expander-column"></th>
                <th scope="col" class="cargo-product-column">
                    <span [class.required]="isCargoProductRequired">Cargo</span>
                </th>
                <th scope="col" class="estimated-quantity-column text-right">
                    <span>Estimated Quantity</span>
                </th>
                <th scope="col" class="quantity-unit-column">
                    <span>Unit</span>
                </th>
                <th *ngIf="hasTolerance" scope="col" class="tolerance-column text-right">
                    Tol. Min
                </th>
                <th *ngIf="hasTolerance" scope="col" class="tolerance-column text-right">
                    Tol. Max
                </th>
                <th *ngIf="hasTolerance" scope="col" class="tolerance-unit-column">
                    Tol. Unit
                </th>
                <th *ngIf="hasTolerance" scope="col" class="tolerance-option-column">
                    Tol. Opt
                </th>
                <th scope="col" class="base-freight-rate-column text-right">
                    <span>Freight Rate</span>
                </th>
                <th scope="col" class="freight-unit-column freight-unit-fixed">
                    <span>Unit</span>
                </th>
                <th *ngIf="hasWorldscaleRate" scope="col" class="world-scale-rate text-right">Worldscale Rate</th>
                <th *ngIf="hasOrderId" scope="col" class="order-id-column">Order Id</th>
                <th scope="col" class="more-menu-column text-right" *ngIf="!readonly">
                    <button
                        type="button"
                        class="has-icon icon--more-vert p-0"
                        [disabled]="!canApplyCargoFreightRate"
                        [ngbPopover]="tableMoreMenuContent"
                        #tableMoreMenu="ngbPopover"
                        (click)="tableMoreMenu.open()"
                        placement="bottom-right"
                        triggers="manual"
                    ></button>
                    <ng-template #tableMoreMenuContent>
                        <div class="more-menu">
                            <button class="btn btn-action" type="button" [disabled]="!canApplyCargoFreightRate" (click)="applyAllCargoFreightRate.emit()">
                                APPLY ALL CARGO FREIGHT RATES TO VOYAGE
                            </button>
                        </div>
                    </ng-template>
                </th>
            </tr>
        </thead>
        <tbody
            ops-cargo-form
            *ngFor="let cargo of cargoes; last as isLast; trackBy: trackBy"
            [form]="cargo.form"
            [expanded]="cargo.expanded"
            [showAddButton]="isLast"
            [readonly]="readonly"
            [canAdd]="canAdd"
            [canClone]="canClone"
            [canRemove]="canRemove"
            [canApplyCargoFreightRate]="canApplyCargoFreightRate"
            [showSpecialisedCargoControls]="showSpecialisedCargoControls"
            (add)="add.emit()"
            (clone)="clone.emit(cargo.cargoId)"
            (remove)="remove.emit(cargo.cargoId)"
            (applyCargoFreightRate)="applyCargoFreightRate.emit(cargo.cargoId)"
            (expand)="expand.emit(cargo.cargoId)"
            (collapse)="collapse.emit(cargo.cargoId)"
        ></tbody>
    </table>
</div>
<ng-template #noCargoes>
    <ops-no-data-panel
        #panel="noDataPanel"
        heading="Cargoes"
        title="No cargoes are currently added"
        description="Select the add button to add cargo entry"
        [useNewButton]="true"
        newButtonText="CARGO"
        [disabled]="!canAdd"
        (addNew)="add.emit()"
        warningActive
        ngrxFormControlId="cargoes"
        ngrxFormControlIdMatch="endsWith"
        (warningActivated)="panel.focusNewButton()"
    >
    </ops-no-data-panel>
</ng-template>
