import { Destination, User } from "src/app/fixture/shared/models";
import { Command } from "../../../mediator/commands/command";
import { DemurrageClaimFormModel } from "../../../shared/models/form-models/demurrage-claim.model";
import { UpdateDemurrageClaimCommandHandler } from "./update-demurrage-claim.command-handler";

export class UpdateDemurrageClaimCommand extends Command {
    constructor(user: User, data: DemurrageClaimFormModel, destinations: Destination[]) {
        super(UpdateDemurrageClaimCommandHandler, { user, data, destinations });
    }
}
