import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { FormComponentBase } from "../../../fixture/shared/form-component-base";
import { dateToISOString, parseISODate } from "../../../shared/date-utils/date-utilities";
import { ReferenceDataService } from "../../../shared/reference-data/reference-data.service";
import { isNullOrUndefined, isNumber } from "../../../shared/utils";
import { EngineConsumption } from "../services/engine-consumption.dto";
import { NoonReportDataService } from "../services/noon-report-data.service";
import { NoonReportEditStateService } from "../services/noon-report-edit-state.service";
import { NoonReport } from "../services/noon-report.dto";
import { NoonReportModel } from "../services/noon-report.model";

@Component({
    selector: "ops-edit-noon-report",
    templateUrl: "./edit-noon-report.component.html",
    styleUrls: ["./edit-noon-report.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditNoonReportComponent extends FormComponentBase implements OnInit, OnDestroy {
    static componentName = "EditNoonReportComponent";
    private readonly destroy$ = new Subject();

    beaufortForces$: Observable<string[]>;
    noonReportForm: UntypedFormGroup;
    isSaveDisabled = true;

    @Input() noonReport: NoonReport;

    constructor(
        private referenceDataService: ReferenceDataService,
        private noonReportEditStateService: NoonReportEditStateService,
        private noonReportDataService: NoonReportDataService
    ) {
        super();
    }

    ngOnInit() {
        this.createForm();
        this.beaufortForces$ = this.referenceDataService.getBeaufortScales().pipe(
            takeUntil(this.destroy$),
            map((all) => all.map((e) => e.id.toString()))
        );
        this.setForm();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.removeFormSubscriptions();
    }

    cancel() {
        if (!this.noonReport.voyageId) {
            this.noonReportDataService.remove(this.noonReport.noonReportId).pipe(takeUntil(this.destroy$)).subscribe();
        } else {
            this.noonReportDataService
                .get(this.noonReport.noonReportId)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.noonReportEditStateService.setInReadMode(this.noonReport.noonReportId);
                });
        }
    }

    save() {
        this.noonReportDataService
            .persist(this.getDataModel())
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.noonReportEditStateService.setInReadMode(this.noonReport.noonReportId);
            });
    }

    updateMainEngineConsumption(data: EngineConsumption[]) {
        this.noonReportDataService
            .update({ ...this.getDataModel(), mainEngineConsumption: data })
            .pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    updateAuxiliaryEngineConsumption(data: EngineConsumption[]) {
        this.noonReportDataService
            .update({ ...this.getDataModel(), auxiliaryEngineConsumption: data })
            .pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    private createForm(): void {
        this.noonReportForm = new UntypedFormGroup(
            {
                noonReportId: new UntypedFormControl(),
                voyageId: new UntypedFormControl(),
                date: new UntypedFormControl(),
                inPort: new UntypedFormControl(),
                averageSpeed: new UntypedFormControl(),
                averageBeaufort: new UntypedFormControl()
            },
            { updateOn: "blur" }
        );
    }

    private setForm(): void {
        this.subscribeToFormValueChanges(this.noonReportForm, (data: NoonReportModel) => {
            this.isSaveDisabled = !this.isSaveEnabled(data);
        });
        this.noonReportForm.patchValue(this.getFormModel());
    }

    private getFormModel(): NoonReportModel {
        return {
            noonReportId: this.noonReport.noonReportId,
            voyageId: this.noonReport.voyageId,
            date: parseISODate(this.noonReport.date),
            inPort: this.noonReport.inPort,
            averageSpeed: this.noonReport.averageSpeed,
            averageBeaufort: this.noonReport.averageBeaufort
        };
    }

    private getDataModel(): NoonReport {
        const formModel = <NoonReportModel>this.noonReportForm.value;

        return <NoonReport>{
            noonReportId: formModel.noonReportId,
            voyageId: formModel.voyageId,
            date: dateToISOString(formModel.date),
            inPort: formModel.inPort,
            averageSpeed: formModel.averageSpeed,
            averageBeaufort: formModel.averageBeaufort,
            mainEngineConsumption: [...this.noonReport.mainEngineConsumption],
            auxiliaryEngineConsumption: [...this.noonReport.auxiliaryEngineConsumption]
        };
    }

    private isSaveEnabled(noonReport: NoonReportModel) {
        return isNumber(noonReport.averageBeaufort) && isNumber(noonReport.averageSpeed) && !isNullOrUndefined(noonReport.date);
    }
}
