import { Injectable } from "@angular/core";

import { AppConfigService } from "./app-config.service";

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        hj: any;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _hjSettings: { hjid: number; hjsv: number };
    }
}

const initializeHotjar = (hotjarId: number) => {
    window.hj =
        window.hj ??
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        function () {
            (window.hj.q = window.hj.q ?? []).push(arguments);
        };

    const settings = (window._hjSettings = { hjid: hotjarId, hjsv: 6 });
    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://static.hotjar.com/c/hotjar-${settings.hjid}.js?sv=${settings.hjsv}`;

    head.appendChild(script);
};

@Injectable({
    providedIn: "root"
})
export class HotjarService {
    constructor(private appConfigService: AppConfigService) {}

    initialize() {
        const hotjarId = this.appConfigService.config.hotjarId;
        if (!hotjarId) {
            console.warn("Hotjar is not configured");
        } else {
            initializeHotjar(hotjarId);
        }
    }
}
