import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { formStateReducer, SetValueAction } from "ngrx-forms";
import { of } from "rxjs";
import { catchError, filter, map, tap, withLatestFrom } from "rxjs/operators";

import { LaytimeCalculationHttpService } from "../../../../services";
import { LtcFeatureState, LtcId, LtcState } from "../../../model";
import { LaytimeCalculationState } from "../../../model";
import { calculationStateReducer } from "../../reducer";
import { selectCurrentCalculationId, selectCurrentLaytimeCalculationName } from "../../selectors";
import { UpdateQueue } from "../../update-queue";
import { selectCurrentLaytimeCalculationNameForm } from "../selectors";

const UPDATE_LAYTIME_CALCULATION_NAME_ACTION_NAME = "[Laytime Calculation] Save Name";
export const cancelLaytimeCalculationRenameAction = createAction("[Laytime Calculation] Cancel Rename");
export const updateLaytimeCalculationNameAction = createAction(UPDATE_LAYTIME_CALCULATION_NAME_ACTION_NAME);
export const updateLaytimeCalculationNameSuccessAction = createAction(`${UPDATE_LAYTIME_CALCULATION_NAME_ACTION_NAME} Success`, props<{ ltcId: LtcId; name: string }>());
export const updateLaytimeCalculationNameFailAction = createAction(`${UPDATE_LAYTIME_CALCULATION_NAME_ACTION_NAME} Fail`, props<{ ltcId: LtcId; error: Error }>());
export const queueUpdateLaytimeCalculationNameAction = createAction("[Laytime Calculation] Queue Save Name", props<{ ltcId: LtcId; name: string }>());

/* REDUCERS */
export const updateLaytimeCalculationNameSuccessReducer: On<LtcState> = on(updateLaytimeCalculationNameSuccessAction, (state, { ltcId, name }) =>
    calculationStateReducer(
        state,
        ltcId,
        (calculationState: LaytimeCalculationState) =>
            <LaytimeCalculationState>{
                ...calculationState,
                calculation: {
                    ...calculationState.calculation,
                    name
                }
            }
    )
);

export const cancelLaytimeCalculationRenameReducer: On<LtcState> = on(cancelLaytimeCalculationRenameAction, (state) =>
    calculationStateReducer(
        state,
        state.currentCalculationId,
        (calculationState: LaytimeCalculationState) =>
            <LaytimeCalculationState>{
                ...calculationState,
                calculationNameForm: formStateReducer(
                    calculationState.calculationNameForm,
                    new SetValueAction(calculationState.calculationNameForm.controls.name.id, calculationState.calculation.name)
                )
            }
    )
);

/* EFFECTS */
export const queueUpdateLaytimeCalculationNameEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(updateLaytimeCalculationNameAction),
            withLatestFrom(store.select(selectCurrentCalculationId), store.select(selectCurrentLaytimeCalculationName), store.select(selectCurrentLaytimeCalculationNameForm)),
            filter(([, , currentName, form]) => form.isValid && form.value.name !== currentName),
            map(([, ltcId, , form]) =>
                queueUpdateLaytimeCalculationNameAction({
                    ltcId,
                    name: form.value.name
                })
            ),
            tap((a) => updateQueue.enqueue(a, updateLaytimeCalculationName, "replace"))
        )
    );

export const updateLaytimeCalculationName = (
    { ltcId, name }: ReturnType<typeof queueUpdateLaytimeCalculationNameAction>,
    laytimeCalculationHttpService: LaytimeCalculationHttpService
) =>
    laytimeCalculationHttpService.updateLaytimeCalculationName(ltcId, name).pipe(
        map(() => updateLaytimeCalculationNameSuccessAction({ ltcId, name })),
        catchError((error) => of(updateLaytimeCalculationNameFailAction({ ltcId, error })))
    );
