import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props } from "@ngrx/store";
import { createFormGroupState, setValue, SetValueAction, updateGroup } from "ngrx-forms";
import { filter, map } from "rxjs/operators";

import { currentCoaStateReducer } from "../../coa/reducer";
import { CoasState, getCurrentCoaState, NominationTaskForm, NominationTaskId } from "../../model";
import {
    getNewNominationTaskForm,
    getNextDueDateString,
    getNextNotificationDateString,
    getNominationTaskFormGroupId,
    getStrictNotificationDateString,
    nominationTaskToForm
} from "../utils";

const MIN_RECURRENCE_DAY = 1;
const MAX_RECURRENCE_DAY = 31;

/* ACTIONS */
export const editNominationTaskAction = createAction("[Coa Nomination Task Form] Edit Nomination Task", props<{ nominationTaskId?: NominationTaskId }>());
export const cancelNominationTaskEditAction = createAction("[Coa Nomination Task Form] Cancel Edit Nomination Task");
export const processNominationTaskFormChangeAction = createAction("[Coa Nomination Task Form] Process Nomination Task Form Change");

/* REDUCERS */
export const editNominationTaskReducer: On<CoasState> = on(editNominationTaskAction, (state, { nominationTaskId }) => {
    const currentCoa = getCurrentCoaState(state);
    const task = currentCoa.coa.nominationTasks.find((c) => c.nominationTaskId === nominationTaskId);

    if (!task && !!nominationTaskId) {
        return state;
    }

    const form = !!nominationTaskId ? nominationTaskToForm(task) : getNewNominationTaskForm(currentCoa.coa);

    return currentCoaStateReducer(state, (coaState) => ({
        ...coaState,
        nominationTaskForm: createFormGroupState(getNominationTaskFormGroupId(form.nominationTaskId), form)
    }));
});

export const cancelNominationTaskEditReducer: On<CoasState> = on(cancelNominationTaskEditAction, (state) =>
    currentCoaStateReducer(state, (coaState) => ({ ...coaState, nominationTaskForm: null }))
);

export const processNominationTaskFormChangeReducer: On<CoasState> = on(processNominationTaskFormChangeAction, (state) =>
    currentCoaStateReducer(state, (coaState) => {
        const { type, dueDate, reminderDaysBefore, recurrenceDayOfMonth, recurrenceEndDate } = coaState.nominationTaskForm.value;
        let newDueDate: string = dueDate;
        let nextDueDate: string | null = null;
        let nextNotificationDate: string | null = null;
        switch (type) {
            case "FixedDate": {
                if (dueDate !== null && reminderDaysBefore !== null) {
                    nextNotificationDate = getStrictNotificationDateString(dueDate, reminderDaysBefore);
                }
                break;
            }
            case "RecurringDate": {
                nextDueDate =
                    recurrenceDayOfMonth >= MIN_RECURRENCE_DAY && recurrenceDayOfMonth <= MAX_RECURRENCE_DAY ? getNextDueDateString(recurrenceDayOfMonth, recurrenceEndDate) : null;
                newDueDate = nextDueDate;

                if (newDueDate !== null && reminderDaysBefore !== null) {
                    nextNotificationDate = getNextNotificationDateString(newDueDate, reminderDaysBefore, recurrenceEndDate);
                }
                break;
            }
            case "EventBased":
                return coaState;
        }

        return {
            ...coaState,
            nominationTaskForm: updateGroup<NominationTaskForm>({
                dueDate: setValue(newDueDate),
                nextDueDate: setValue(nextDueDate),
                nextNotificationDate: setValue(nextNotificationDate)
            })(coaState.nominationTaskForm)
        };
    })
);

/* EFFECTS */
export const nominationTaskFormChangeEffect$ = (actions$: Actions) =>
    createEffect(() =>
        actions$.pipe(
            ofType<SetValueAction<NominationTaskForm>>(SetValueAction.TYPE),
            filter((action) => action.controlId.split(".")[0] === "NominationTaskForm"),
            map(() => processNominationTaskFormChangeAction())
        )
    );
