<div class="reference-data-dropdown-wrapper" [class.quantity-dropdown]="isQuantityInputDropdown" [class.clearable]="showClearButton">
    <ng-select
        class="ops-select"
        bindLabel="name"
        [bindValue]="bindValue"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [items]="(referenceData$ | async) || options || [selectedValue]"
        [clearable]="showClearButton"
        [selectOnTab]="true"
        [appendTo]="appendTo"
        (change)="change.emit($event)"
    >
    </ng-select>
</div>
