import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class LeftBarStateService {
    #overlayScreenSizeBreakpoint = 1600;
    #toggleSubject = new BehaviorSubject<boolean>(true);
    isCollapsed$ = this.#toggleSubject.asObservable();

    constructor(@Inject("Window") private window: Window) {}

    get isCollapsedSnapshot() {
        return this.#toggleSubject.getValue();
    }

    get overlayScreenSizeBreakpoint() {
        return this.#overlayScreenSizeBreakpoint;
    }

    get displayMode(): "overlay" | "inline" {
        return this.window.innerWidth <= this.#overlayScreenSizeBreakpoint ? "overlay" : "inline";
    }

    collapse() {
        this.#toggleSubject.next(true);
    }

    open() {
        this.#toggleSubject.next(false);
    }

    openInNewTab(relativeUrl: string) {
        this.window.open(relativeUrl, "_blank");
    }

    openWindowInNewTab(url: string, windowHandle: Window) {
        windowHandle.location.href = url;
    }

    collapseIfSmallWindow() {
        if (this.displayMode === "overlay") {
            this.collapse();
        }
    }

    toggle() {
        this.#toggleSubject.next(!this.isCollapsedSnapshot);
    }
}
