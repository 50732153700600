import { createAction, On, on, props } from "@ngrx/store";

import { UpdateSorting, updateSortingReducer } from "../../../state/grid.reducer";
import { CoasState } from "../model/state";

/* ACTIONS */
export const updateCoaGridSortingAction = createAction("[Coa Grid] Update Sorting", props<UpdateSorting>());

/* REDUCERS */
export const updateCoaGridSortingReducer: On<CoasState> = on(updateCoaGridSortingAction, (state, action) => updateSortingReducer(state, action, "grid"));
