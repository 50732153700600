import { Enumeration } from "./enumeration";

export class ReversibleLaytimeType extends Enumeration {
    static Reversible: ReversibleLaytimeType = new ReversibleLaytimeType(1, "Reversible");
    static NonReversible: ReversibleLaytimeType = new ReversibleLaytimeType(2, "Non Reversible");

    static isReversible(laytimeType: ReversibleLaytimeType): boolean {
        return laytimeType && laytimeType.id === this.Reversible.id;
    }

    static isNonReversible(laytimeType: ReversibleLaytimeType): boolean {
        return laytimeType && laytimeType.id === this.NonReversible.id;
    }
}
