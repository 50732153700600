import { Pipe, PipeTransform } from "@angular/core";
import { MaritimeDate, MaritimeDateRange } from "@maritech/maritime-date";
import { DateTime } from "luxon";

import { toMaritimeDateRange } from "../../date-utils/date-utilities";
import { dateTimeFromISO } from "../../utils";

type MaritimeDatePipeValue = string | DateTime | MaritimeDateRange | { from?: string; to?: string };

@Pipe({
    name: "maritimeDate"
})
export class MaritimeDatePipe implements PipeTransform {
    transform(value: MaritimeDatePipeValue, includeTimeOrTimezone?: string | boolean, timezone?: string) {
        if (!value) {
            return null;
        }

        if (typeof includeTimeOrTimezone === "string") {
            timezone = includeTimeOrTimezone;
        }

        if (typeof value === "string") {
            const dateTime = dateTimeFromISO(value, timezone || "local");
            return this.handleDateTime(dateTime, includeTimeOrTimezone, timezone);
        }

        if (DateTime.isDateTime(value)) {
            return this.handleDateTime(value, includeTimeOrTimezone, timezone);
        }

        if (value instanceof MaritimeDateRange) {
            return this.handleMaritimeDateRange(value, includeTimeOrTimezone, timezone);
        }

        const range = toMaritimeDateRange(value as { from?: string; to?: string });
        return this.handleMaritimeDateRange(range, includeTimeOrTimezone, timezone);
    }

    private handleDateTime(value: DateTime | null, includeTimeOrTimezone?: string | boolean, timezone?: string) {
        const dateTime = timezone ? value?.setZone(timezone) : value;
        const args = includeTimeOrTimezone === false ? MaritimeDate.DATE_FORMAT : MaritimeDate.DATETIME_FORMAT;

        return dateTime?.toLocaleString(args);
    }

    private handleMaritimeDateRange(value: MaritimeDateRange | null, includeTimeOrTimezone?: string | boolean, timezone?: string) {
        const range = timezone ? value?.setZone(timezone) : value;

        return includeTimeOrTimezone === false ? range?.toMaritimeDateString() : range?.toMaritimeString();
    }
}
