import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, first, map } from "rxjs/operators";
import { VoyageDataService } from "../../../services/voyage-data.service";
import { FixtureWarningPathTransform } from "./fixture-warning-path-transform";

/**
 * Transforms the form voyage path to the model voyages path based on the index of the current voyage.
 *
 * As we only display a single voyage but warnings across voyages, we have to find out whether the index (or guid)
 * of the displayed voyage matches the warning (and the returned observable must update based on the current index).
 */
@Injectable()
export class VoyagesFixtureWarningPathTransform implements FixtureWarningPathTransform {
    private readonly rootPaths = ["cargoes", "destinations", "atSeaBunkersConsumption"];

    private readonly currentVoyageIndex$: Observable<number>;

    constructor(voyageService: VoyageDataService) {
        this.currentVoyageIndex$ = combineLatest([voyageService.current$, voyageService.voyages$]).pipe(
            map(([current, voyages]) => (current && voyages ? voyages.findIndex((voyage) => voyage.voyageId === current.voyageId) : null)),
            distinctUntilChanged()
        );
    }

    transform(path: string[]): string[] | Observable<string[]> {
        if (this.rootPaths.includes(path[0])) {
            return this.currentVoyageIndex$.pipe(map((currentIndex) => ["voyages", `[${currentIndex}]`, ...path]));
        }

        return path;
    }
}
