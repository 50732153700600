import { validateRequiredIf } from "@ops/state";
import { disable, FormGroupState, updateGroup } from "ngrx-forms";
import { CoasState } from "../../model";
import { LiftingHeaderForm, LiftingState } from "../../model/lifting";

export const validateLiftingHeaderForm = (form: FormGroupState<LiftingHeaderForm>, liftingState: LiftingState, coasState: CoasState) => {
    return updateGroup<LiftingHeaderForm>(
        {
            operators: validateRequiredIf(liftingState.lifting.liftingStatus === "Cancelled"),
            declarationDate: validateRequiredIf(liftingState.lifting.liftingStatus === "Fixed")
        },
        ...liftingState.lifting.controlledProperties.map((x) => ({ [x]: disable }))
    )(form);
};
