import { joinNamedTypeArray } from "@ops/shared";

import { DateRange } from "../../../fixture/shared/models";
import { FixtureId, VoyageId } from "../../../fixture/state";

export type CompletedVoyage = Readonly<{
    liftingNumber: string;
    fixtureReference: string;
    vesselName: string;
    laycan: DateRange;
    loadDestinations: string;
    dischargeDestinations: string;
    cargoes: string;
    fixtureId: string;
    voyageId: string;
}>;

export type FixtureIndexSearchResults = Readonly<{
    count: number;
    documents: FixtureIndexDocument[];
}>;

type FixtureIndexDocument = Readonly<{
    liftingNumber: string;
    fixtureNumber: string;
    vesselName: string;
    laycanDateFrom: string;
    laycanDateTo: string;
    loadDestinations: ReadonlyArray<NamedObject>;
    dischargeDestinations: ReadonlyArray<NamedObject>;
    cargoes: ReadonlyArray<NamedObject>;
    fixtureId: FixtureId;
    voyageId: VoyageId;
}>;

type NamedObject = Readonly<{
    name: string;
}>;

export const mapSearchResultToCompletedVoyage = (searchResult: FixtureIndexDocument): CompletedVoyage => ({
    liftingNumber: searchResult.liftingNumber,
    fixtureReference: searchResult.fixtureNumber,
    vesselName: searchResult.vesselName,
    laycan: {
        from: searchResult.laycanDateFrom,
        to: searchResult.laycanDateTo
    },
    loadDestinations: joinNamedTypeArray(searchResult.loadDestinations),
    dischargeDestinations: joinNamedTypeArray(searchResult.dischargeDestinations),
    cargoes: joinNamedTypeArray(searchResult.cargoes),
    fixtureId: searchResult.fixtureId,
    voyageId: searchResult.voyageId
});
