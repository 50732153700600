import * as R from "ramda";

import { Division } from "@ops/shared/reference-data";

export const sectors = ["Dry Cargo", "Tankers", "Gas", "Specialised Product", "PCG"] as const;
export type Sector = typeof sectors[number];

export const sectorToDivision = (sector: Sector) => {
    const division: Division = Object.values(Division).find((x: Division) => x.name === sector);
    return division?.id;
};

export const isInSector = R.propEq("sector");
export const isDryCargoSector = isInSector("Dry Cargo");
export const isTankersSector = isInSector("Tankers");
export const isGasSector = isInSector("Gas");
export const isSpecialisedSector = isInSector("Specialised Products");
export const isPCGSector = isInSector("PCG");
