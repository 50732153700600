import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { DemurrageClaimValueService } from "../../../services/demurrage-claim-value.service";
import { DemurrageClaim } from "../../../shared/models";

@Component({
    selector: "ops-demurrage-claim-summary",
    templateUrl: "./demurrage-claim-summary.component.html",
    styleUrls: ["./demurrage-claim-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemurrageClaimSummaryComponent implements OnChanges {
    static componentName = "DemurrageClaimSummaryComponent";

    claimValue: number;

    @Input() estimatedClaimValue: number;
    @Input() demurrageClaim: DemurrageClaim;
    @Input() onAccountIndex: number | null;
    @Input() index: number;

    constructor(private claimValueService: DemurrageClaimValueService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.demurrageClaim) {
            this.claimValue = this.claimValueService.resolveClaimValue(changes.demurrageClaim.currentValue, this.estimatedClaimValue);
        }
    }
}
