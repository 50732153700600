export class TcVoyageModel {
    voyageId: string;
    voyageNumber: string;
    cargoNames: string;
    loads: string;
    discharges: string;
    isOpen: boolean;
    isClosed: boolean;
    isSelected: boolean;
    nextEtaDate: string;
    nextEtaPort: string;
    nextEtbDate: string;
    nextEtbPort: string;
    isValid: boolean;
    isDirty: boolean;
}
