import { Injectable } from "@angular/core";
import { Fixture } from "src/app/fixture/shared/models";
import { LaytimeTermsViewModel } from "src/app/fixture/shared/models/form-models/laytime-terms.model";
import { Division } from "src/app/shared/reference-data";
import { LaytimeRounding } from "src/app/shared/reference-data/laytime-rounding";
import { LaytimeType } from "src/app/shared/reference-data/laytime-type";
import { TimeSavedType } from "src/app/shared/reference-data/time-saved-type";
import { CommandHandler } from "../../../mediator/command-handlers/command-handler";
import { UpdateLaytimeTermsCommand } from "./update-laytime-terms.command";

@Injectable({
    providedIn: "root"
})
export class UpdateLaytimeTermsCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateLaytimeTermsCommand) {
        const value = command.payload as LaytimeTermsViewModel;
        if (!command) {
            throw new Error("Given fixture form model is invalid.");
        }

        fixture.laytimeType = value.laytimeType.toLowerCase() === LaytimeType.Fixed.name.toLowerCase() ? LaytimeType.Fixed : LaytimeType.NonFixed;
        fixture.fixedLaytime = value.fixedLaytime;
        fixture.demurrage.rate = value.demurrageRate;
        fixture.despatchRate = value.despatchRate;
        fixture.detentionRate = value.detentionRate;
        fixture.isOnDemurrage = value.isOnDemurrage;
        fixture.laytimeTermsComments = value.laytimeTermsComments;
        fixture.timeSavedType = value.timeSavedType.toLowerCase() === "allTimeSaved".toLowerCase() ? TimeSavedType.AllTimeSaved : TimeSavedType.WorkingTimeSaved;

        if (this.shouldSetDemurrageBank(fixture)) {
            fixture.demurrageBankEnabled = value.demurrageBankEnabled;
        }

        fixture.laytimeRounding = this.setRoundingType(value.laytimeRounding);
    }

    private setRoundingType(ltcRounding: string) {
        if (ltcRounding.toLowerCase() === "up") {
            return LaytimeRounding.Up;
        } else if (ltcRounding.toLowerCase() === "down") {
            return LaytimeRounding.Down;
        }
        return LaytimeRounding.Exact;
    }

    private shouldSetDemurrageBank(fixture: Fixture): boolean {
        return fixture.division.id === Division.Pcg.id;
    }
}
