<form [formGroup]="actionForm" autocomplete="off" data-test-calendar-edit-action>
    <div class="header row no-gutters">
        <div class="col align-self-center title" *ngIf="!actionToEdit.actionId">Create Action</div>
        <div class="col align-self-center title" *ngIf="actionToEdit.actionId">Edit Action</div>
        <div class="update-by-lbl" *ngIf="actionToEdit.actionId">
            Updated by {{ actionToEdit.lastUpdatedBy.fullName }} on {{ actionToEdit.updatedDate | date: "E dd MMM yy HH:mm" }}
        </div>
        <span class="has-icon icon--close btn-close" (click)="cancel()"></span>
    </div>

    <div class="view-tab">
        <div (click)="notificationView = false" class="action-view-btn" [ngClass]="{ 'disabled-view': notificationView, 'enabled-view': !notificationView }">
            <div class="ops-validation" *ngIf="isInvalidOnSave && !actionForm.valid">
                <span class="has-icon icon--warning"></span>
            </div>
            <span>Action</span>
        </div>
        <div (click)="notificationView = true" class="notification-view-btn" [ngClass]="{ 'disabled-view': !notificationView, 'enabled-view': notificationView }">
            <span class="notifications-text">Notifications</span><span class="notifications-count">({{ notificationDateCount }})</span>
        </div>
    </div>

    <div class="action-edit-container" [hidden]="notificationView">
        <div class="row no-gutters">
            <div class="form-group col required">
                <label for="summary">Summary</label>
                <input id="summary" class="form-control" type="text" formControlName="summary" opsFocus data-test-summary />
                <ops-validation [form]="actionForm.get('summary')" [showBeforeTouch]="false"></ops-validation>
            </div>
        </div>

        <div class="row no-gutters ">
            <div class="col-4 required form-group">
                <label for="priority">Priority</label>
                <ng-select
                    class="ops-select"
                    [items]="priorities"
                    [searchable]="false"
                    formControlName="priority"
                    [clearable]="false"
                    bindLabel="name"
                    data-test-priority
                    [selectOnTab]="true"
                >
                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                        <div [ngClass]="item.name.toLowerCase()" class="priority">
                            {{ item.name }}
                        </div>
                    </ng-template>
                </ng-select>
            </div>

            <div class="col-4 required form-group">
                <label for="dueDate">Due Date</label>
                <ops-date-input
                    formControlName="dueDate"
                    [enableTime]="true"
                    timeZone="local"
                    container=".action-edit-container"
                    [defaultTime]="defaultSelectTime"
                    data-test-due-date
                ></ops-date-input>
                <ops-validation [form]="actionForm.get('dueDate')" [showBeforeTouch]="false"></ops-validation>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="form-group col">
                <label for="description">Description</label>
                <textarea id="description" class="form-control action-description" type="text" rows="6" formControlName="description" data-test-description></textarea>
            </div>
        </div>

        <div class="row no-gutters" *ngIf="!actionToEdit.actionId && !actionToEdit.fixtureId">
            <div class="form-group col required">
                <label>Assign to Fixture</label>
                <ops-fixture-autosuggest [multiple]="false" formControlName="fixture" (change)="onFixtureChanged()" data-test-assigned-to></ops-fixture-autosuggest>
                <ops-validation [form]="actionForm.get('fixture')" [showBeforeTouch]="false"></ops-validation>
            </div>
        </div>

        <div class="row no-gutters" *ngIf="actionToEdit.actionId || actionToEdit.fixtureId">
            <div class="form-group col ">
                <label>Assign to Fixture</label>
                <div class="fixture-summary">
                    <div class="row fixture-row">
                        <div class="col-4 fixture-col">
                            {{ actionToEdit.vesselName }}
                        </div>
                        <div class="col-7">
                            {{ actionToEdit.charterer }}
                        </div>
                    </div>
                    <div class="row fixture-row">
                        <div class="col-4 fixture-col">CP Date: {{ actionToEdit.cpDate | date: "dd MMM yy":"utc" }}</div>
                        <div class="col-7">
                            {{ actionToEdit.fixtureNumber }}
                        </div>
                    </div>
                    <div class="row fixture-row">
                        <div class="col-4 fixture-col">Laycan From: {{ actionToEdit.laycanFrom | date: "dd MMM yy, HH:mm":"utc" }}</div>
                        <div class="col-5">Laycan To: {{ actionToEdit.laycanTo | date: "dd MMM yy, HH:mm":"utc" }}</div>
                        <div class="col-3">
                            <button class="goto-btn" type="button" (click)="onGoToClick($event)">
                                <span class="has-icon icon--arrow-forward"></span>
                                <span>GO TO {{ actionToEdit.fixtureId ? "FIXTURE" : "COA" }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="form-group col required">
                <label>Assigned To ({{ assignedToCount }})</label>
                <ops-user-autosuggest
                    [multiple]="true"
                    [fixtureId]="fixtureId"
                    [fixtureSource]="fixtureSource"
                    [readonly]="!fixtureId"
                    formControlName="assignedTo"
                    [lead]="actionForm.controls['assignedTo'].value[0]"
                    data-test-assigned-to
                ></ops-user-autosuggest>
                <ops-validation [form]="actionForm.get('assignedTo')" [showBeforeTouch]="false"></ops-validation>
            </div>
        </div>
    </div>

    <div class="notifications-view" [hidden]="!notificationView">
        <div class="row no-gutters notification-edit-container">
            <div class="form-group col ">
                <label>Associated Action Summary</label>
                <div class="action-summary" [ngClass]="[actionPriority]">
                    <div class="row fixture-row">
                        <div class="col-9 fixture-col">Due on: {{ actionDueDate | date: "E dd MMM yy HH:mm" }}</div>
                    </div>
                    <div class="row fixture-row">
                        <div class="col-9 fixture-col">Summary: {{ actionSummary }}</div>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="notification-body">
            <div *ngIf="notificationDateCount > 0; else noNotifications" class="col p-0">
                <div class="notification-dates notification-edit-container">
                    <div formArrayName="notificationDates" *ngFor="let notificationDates of notificationDatesFormArray.controls; let i = index">
                        <div [formGroupName]="i" class="row no-gutters notification-date">
                            <ops-date-input
                                opsFocus
                                formControlName="date"
                                [enableTime]="true"
                                timeZone="local"
                                container="notification-body"
                                data-test-notification-date
                                class="col-7"
                            >
                            </ops-date-input>
                            <div class="col-1 btn-notifications">
                                <button type="button" *ngIf="i === notificationDateCount - 1" class="btn-add has-icon icon--add-circle-outline"
                                    (click)="addNotification()"></button>
                            </div>
                            <div class="col-1 btn-notifications">
                                <button type="button" class="btn-delete has-icon icon--delete m-0" (click)="deleteNotification(i)"
                                    data-test-delete-notification-date></button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row no-gutters notification-edit-container">
                    <div class="form-group col">
                        <label>Notification Recipients ({{ recipientCount }})</label>
                        <ops-user-autosuggest
                            [multiple]="true"
                            [returnAll]="true"
                            [fixtureId]="fixtureId"
                            [fixtureSource]="fixtureSource"
                            [readonly]="!fixtureId"
                            formControlName="notificationRecipients"
                            data-test-recipients
                        ></ops-user-autosuggest>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #noNotifications>
            <div class="notification-edit-container">
                <ops-no-data-panel
                    title="No notifications have been added to this action"
                    description="To create a notification, click the button"
                    [useNewButton]="true"
                    newButtonText="NOTIFICATION"
                    (addNew)="addNotification()"
                >
                </ops-no-data-panel>
            </div>
        </ng-template>
    </div>

    <hr />
    <div class="row no-gutters action-edit-buttons">
        <div class="col d-flex justify-content-start p-0">
            <button type="button" (click)="cancel()" class="btn btn-link m-0" data-test-cancel>CANCEL</button>
        </div>
        <div class="col d-flex justify-content-end p-0">
            <button type="button" (click)="save()" class="btn btn-primary m-0" data-test-save>SAVE ACTION</button>
        </div>
    </div>
</form>
