import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { takeUntil } from "rxjs/operators";

import { CpSpeedAndConsumption } from "../../../shared/models/dtos/cp-speed-and-consumption.dto";
import { CpSpeedFormModel } from "../../../shared/models/form-models/cp-speed.model";
import { AbstractSimpleGridComponent } from "../abstract-simple-grid/abstract-simple-grid.component";
import { UpdateCpSpeedCommand } from "./commands/update-cp-speed.command";

@Component({
    selector: "ops-cp-speed",
    templateUrl: "./cp-speed.component.html",
    styleUrls: ["./cp-speed.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CpSpeedComponent extends AbstractSimpleGridComponent<CpSpeedFormModel> implements OnChanges {
    static componentName = "CpSpeedComponent";

    private columnDefs = [
        { headerName: "Sea Status", field: "seaStatus", class: "sea-status-column-fixed" },
        { headerName: "Ballast (Kn)", field: "ballast", class: "ballast-column-fixed text-right" },
        { headerName: "Laden (Kn)", field: "laden", class: "laden-column-fixed text-right" }
    ];

    @Output() updated = new EventEmitter();
    @Input() model: CpSpeedAndConsumption;

    constructor(public changeDetectorRef: ChangeDetectorRef, protected formBuilder: UntypedFormBuilder) {
        super("seaStatus", changeDetectorRef, formBuilder);

        this.columnDefs$.next(this.columnDefs);
        this.rowChanged$.pipe(takeUntil(this.destroy$)).subscribe((row) => this.rowChanged(row));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["model"] === undefined) {
            return;
        }

        const modelChange = changes["model"];
        const currentValue = (modelChange.currentValue as any) as CpSpeedAndConsumption;

        if (!currentValue) {
            this.rowData$.next([]);
            return;
        }

        this.rowData$.next([
            {
                seaStatus: "Full",
                laden: currentValue.ladenFull,
                ballast: currentValue.ballastFull
            },
            {
                seaStatus: "Eco",
                laden: currentValue.ladenEco,
                ballast: currentValue.ballastEco
            }
        ]);
    }

    private rowChanged(row: CpSpeedFormModel) {
        const currentModel = this.model;

        const full =
            row.seaStatus === "Full"
                ? row
                : {
                      seaStatus: "Full",
                      laden: currentModel.ladenFull,
                      ballast: currentModel.ballastFull
                  };

        const eco =
            row.seaStatus === "Eco"
                ? row
                : {
                      seaStatus: "Eco",
                      laden: currentModel.ladenEco,
                      ballast: currentModel.ballastEco
                  };

        this.updated.emit(new UpdateCpSpeedCommand(full, eco));
    }
}
