<div>
    <div class="p-d-flex">
        <div class="ops-ltc-cargo-name" data-cy="cargo-in-location-select-panel">{{associatedCargoesByCargo.cargoName}}</div>
        <div class="p-field p-field-checkbox" *ngIf="isSelectAllEnabled">
            <input id="{{associatedCargoesByCargo.cargoId}}_all_selected" type="checkbox"
                   [checked]="associatedCargoesByCargo.selectAll" class="p-checkbox"
                   (change)="selectAllByCargo.emit(associatedCargoesByCargo.cargoId)"
                    data-cy="select-all-locations"/>
            <label for="{{associatedCargoesByCargo.cargoId}}_all_selected">Select All</label>
        </div>
    </div>
    <div *ngIf="associatedCargoesByCargo.associatedCargoes; else noAssociatedCargoes"
         class="ops-ltc-associated-cargoes-container">
        <div *ngFor="let associatedCargo of associatedCargoesByCargo.associatedCargoes; trackBy: trackBy"
             (click)="selected.click()"
             class="ops-ltc-card" [class.ops-ltc-imported]="associatedCargo.imported" data-cy="cargo-location-in-calculation-panel">
            <input type="checkbox" class="p-checkbox"
                   [ngrxFormControlState]="associatedCargo.selected" #selected (click)="$event.stopPropagation()"
                   data-cy="select-location" />
            <ops-voyage-activity
                [name]="associatedCargo.voyageActivityName"
                [activity]="associatedCargo.activity"
                [flagImageUrl]="associatedCargo.flagImageUrl"
                [type]="'item'"
                class="ops-ltc-voyage-activity"
            ></ops-voyage-activity>
            <div data-cy="quantity">{{associatedCargo.quantity}}</div>
        </div>
    </div>
    <ng-template #noAssociatedCargoes>
        <div class="p-p-3">
            <span class="has-icon icon--lightbulb-outline"></span>
            <span class="p-ml-2">This cargo does not have any associated cargoes. Please add them on the cargo tab.</span>
        </div>
    </ng-template>
</div>
