import { GridApi, IServerSideDatasource, IServerSideGetRowsParams } from "@ag-grid-community/all-modules";
import { of, Subject } from "rxjs";
import { catchError, map, mergeMap, takeUntil, tap } from "rxjs/operators";

import { IGridSearchService } from "./grid-search-service";

export class GridServerSideDatasource implements IServerSideDatasource {
    private readonly destroy$ = new Subject();
    private params$: Subject<IServerSideGetRowsParams> = new Subject<IServerSideGetRowsParams>();

    constructor(private readonly searchService: IGridSearchService, private readonly gridApi: GridApi) {
        this.searchService.gridItemsLoading$.pipe(takeUntil(this.destroy$)).subscribe((loading: boolean) => {
            if (!!loading && this.gridApi) {
                this.gridApi.setServerSideDatasource(this);
            }
        });

        this.params$
            .pipe(
                takeUntil(this.destroy$),
                tap(() => this.setLoading(true)),
                mergeMap((params) =>
                    this.searchService.search(this.searchService.tokens, params?.request.startRow ?? 0, this.searchService.rowTakeCount).pipe(
                        map((rowData: any[]) => {
                            if (params) {
                                this.onSearchSuccess(rowData, params);
                            }
                        }),
                        catchError((error) => {
                            if (params) {
                                console.log(`GridServiceSideDataSource: Failed to load rows ${params.request.startRow} to ${params.request.endRow} with error:`, error);
                                params.failCallback();
                                return of({ error: new Error(error.Message) });
                            }
                        })
                    )
                )
            )
            .subscribe();
    }

    removeSubscriptions() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getRows(params: IServerSideGetRowsParams) {
        this.params$.next(params);
    }

    private setLoading(loading: boolean): void {
        if (!this.destroy$.isStopped) {
            if (loading) {
                this.gridApi.showLoadingOverlay();
            } else {
                this.gridApi.hideOverlay();
            }
        }
    }

    private onSearchSuccess(d: any[], params: IServerSideGetRowsParams) {
        const success = params.successCallback;
        const startRow = params.request.startRow;
        const endRow = params.request.endRow;
        const totalRowsRequested = endRow - startRow;

        let lastRow = d.length < totalRowsRequested ? endRow - (totalRowsRequested - d.length) : -1;

        if (startRow === 0 && lastRow > d.length) {
            lastRow = d.length;
        }

        setTimeout(() => {
            this.setLoading(false);
            success(d, lastRow || d.length);

            if (d.length === 0) {
                this.gridApi.showNoRowsOverlay();
            }
        }, 0);
    }
}
