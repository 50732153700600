import { DateTime } from "luxon";
import * as R from "ramda";

import { ActivityFormContext, forEachActivity } from "../activities/shared";
import { ActivityForm, LaytimeEventForm, VoyageForm } from "../model";

export declare type InferredLaytimeEvent = Readonly<{
    eventDate?: string;
    isStartOrStop: boolean;
    percentage?: number;
}>;

export declare type LaytimeEventIntervalPart = Readonly<{
    intervalPart: IntervalPart;
    elapsedHours: number;
}>;

export declare type LaytimeEvent = Readonly<{
    eventDate?: string;
    isStartOrStop: boolean;
    percentage?: number;
    intervalPart?: IntervalPart;
}>;

export enum IntervalPart {
    Start = "start",
    Stop = "stop",
    During = "during",
    Outside = "outside",
    ImplicitStop = "implicitStop"
}

declare type ElapsedHours = number;
export declare type IntervalPartTuple = [IntervalPart, ElapsedHours];

const getLaytimeHours = (prevEventDate: DateTime, currEventDate: DateTime, elapsedHours: number, percentage: number) => {
    if (!prevEventDate || !currEventDate) {
        return 0;
    }

    const difference = currEventDate.diff(prevEventDate, "hours").hours;
    // eslint-disable-next-line no-magic-numbers
    percentage = percentage === null || isNaN(percentage) ? 1 : percentage / 100;

    return difference * percentage + elapsedHours;
};

const getIsOrdered = (wasOrdered: boolean, prevEventDate: DateTime, currEventDate: DateTime, eventIndex: number) => {
    if (!eventIndex) {
        return true;
    }

    if (prevEventDate === null && currEventDate === null) {
        return true;
    }

    if (wasOrdered && prevEventDate !== null) {
        if (prevEventDate <= currEventDate) {
            return true;
        }

        if (currEventDate === null) {
            return true;
        }
    }

    return false;
};

const getIntervalPart = (isStartStop: boolean, isEven: boolean, nextLaytimeEvent: InferredLaytimeEvent) => {
    if (isStartStop && !isEven) {
        return IntervalPart.Start;
    }

    if (!isStartStop && isEven) {
        return IntervalPart.Outside;
    }

    if (!isStartStop && !isEven) {
        return nextLaytimeEvent ? IntervalPart.During : IntervalPart.ImplicitStop;
    }

    if (isStartStop && isEven) {
        return IntervalPart.Stop;
    }
};

const toIntervalPart = () => {
    let elapsedHours = 0;
    let wasEven = true;
    let wasOrdered = true;
    let prevEventDate: DateTime = null;
    return (laytimeEvent: InferredLaytimeEvent, i: number, arr: ReadonlyArray<InferredLaytimeEvent>) => {
        const nextLaytimeEvent = arr[i + 1];
        const currEventDate = laytimeEvent?.eventDate ? DateTime.fromISO(laytimeEvent.eventDate) : null;
        const percentage = laytimeEvent.percentage;

        const isStartStop = laytimeEvent.isStartOrStop;
        const isEven = isStartStop ? !wasEven : wasEven;
        const isOrdered = getIsOrdered(wasOrdered, prevEventDate, currEventDate, i);

        if ((isStartStop && isEven) || (!isStartStop && !isEven)) {
            elapsedHours = isOrdered ? getLaytimeHours(prevEventDate, currEventDate, elapsedHours, percentage) : null;
        } else {
            elapsedHours = isOrdered ? 0 : null;
        }

        const intervalPart = getIntervalPart(isStartStop, isEven, nextLaytimeEvent);
        [wasOrdered, wasEven, prevEventDate] = [isOrdered, isEven, currEventDate];

        return { intervalPart, elapsedHours };
    };
};

export const getLaytimeEventIntervalParts = (laytimeEvents: ReadonlyArray<InferredLaytimeEvent>): IntervalPartTuple[] => {
    const intervalPartLaytimeEvents = R.dropLastWhile((laytimeEvent) => !laytimeEvent.eventDate, laytimeEvents).map(toIntervalPart());

    return R.ifElse(
        R.any(({ elapsedHours }) => elapsedHours === null),
        R.map(({ intervalPart }) => [intervalPart, null]),
        R.map(({ elapsedHours, intervalPart }) => [intervalPart, elapsedHours])
    )(intervalPartLaytimeEvents);
};

export const sumLaytimeEventIntervalParts = (intervalParts: IntervalPartTuple[]) => {
    if (!intervalParts) {
        return null;
    }

    return intervalParts.reduce(
        (total, [intervalPart, elapsedHours]) => total + (intervalPart === IntervalPart.Stop || intervalPart === IntervalPart.ImplicitStop ? elapsedHours : 0),
        0
    );
};

export const getTotalLaytime = (laytimeEvents: ReadonlyArray<InferredLaytimeEvent>): number => {
    const intervalParts = getLaytimeEventIntervalParts(laytimeEvents);
    return sumLaytimeEventIntervalParts(intervalParts);
};

type LaytimeEventFormContext = ActivityFormContext & { activity: ActivityForm };

export const forEachLaytimeEvent = (voyageForm: VoyageForm, fn: (laytimeEvent: LaytimeEventForm, { destination, berth, activity }: LaytimeEventFormContext) => void) => {
    forEachActivity(voyageForm, (activity, { destination, berth }) => activity.laytimeEvents?.forEach((laytimeEvent) => fn(laytimeEvent, { destination, berth, activity })));
};
