import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { NoonReportDataService } from "../services/noon-report-data.service";
import { NoonReportEditStateService } from "../services/noon-report-edit-state.service";
import { NoonReport } from "../services/noon-report.dto";

@Component({
    selector: "ops-noon-report",
    templateUrl: "./noon-report.component.html",
    styleUrls: ["./noon-report.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoonReportComponent implements OnInit, OnDestroy {
    static componentName = "NoonReportComponent";
    private readonly destroy$ = new Subject();

    noonReportForm: UntypedFormGroup;
    collapsed = true;
    dateFormat = "dd MMM yy";

    @Input() noonReport: NoonReport;

    constructor(private noonReportEditStateService: NoonReportEditStateService, private noonReportDataService: NoonReportDataService) {}

    ngOnInit() {
        this.createForm();
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    toggle() {
        this.collapsed = !this.collapsed;
    }

    edit() {
        this.noonReportDataService
            .get(this.noonReport.noonReportId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.noonReportEditStateService.setInEditMode(this.noonReport.noonReportId);
            });
    }

    private createForm(): void {
        this.noonReportForm = new UntypedFormGroup({});
    }
}
