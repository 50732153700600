import { createAction, On, on, props } from "@ngrx/store";

import { UpdateSorting, updateSortingReducer } from "@ops/state";

import { LtcState } from "../../model";

/* ACTIONS */
export const updateLaytimeCalculationGridSortingAction = createAction("[Laytime Calculation Grid] Update Sorting", props<UpdateSorting>());

/* REDUCERS */
export const updateLaytimeCalculationGridSortingReducer: On<LtcState> = on(updateLaytimeCalculationGridSortingAction, (state, action) =>
    updateSortingReducer(state, action, "grid")
);
