import { Injectable } from "@angular/core";
import { dateToISOString } from "../../../../shared/date-utils/date-utilities";
import { CommandHandler } from "../../../mediator/command-handlers/command-handler";
import { Fixture } from "../../../shared/models";
import { ClaimNotification } from "../../../shared/models/dtos/claim-notification.dto";
import { ClaimsNotificationFormModel } from "../../../shared/models/form-models/claims-notification.model";
import { UpdateClaimsNotificationCommand } from "./update-claims-notification.command";

@Injectable({
    providedIn: "root"
})
export class UpdateClaimsNotificationCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateClaimsNotificationCommand) {
        const claimsNotificationForm = command.payload.data as ClaimsNotificationFormModel;
        if (!claimsNotificationForm) {
            throw new Error("Given claims notification form model is invalid.");
        }

        const claimsNotificationtoUpdate: ClaimNotification = fixture.demurrage.claimNotification;
        if (!claimsNotificationtoUpdate) {
            throw new Error("Cannot update the claims notification because is invalid or doesn't exist.");
        }

        claimsNotificationtoUpdate.receivedFromOwnerDate = dateToISOString(claimsNotificationForm.receivedFromOwnerDate);
        claimsNotificationtoUpdate.sentToChartererDate = dateToISOString(claimsNotificationForm.sentToChartererDate);
        claimsNotificationtoUpdate.chartererAcknowledgedReceiptDate = dateToISOString(claimsNotificationForm.chartererAcknowledgedReceiptDate);
        claimsNotificationtoUpdate.lastContactedDate = dateToISOString(claimsNotificationForm.lastContactedDate);
        claimsNotificationtoUpdate.lastUpdatedByUser = command.payload.user;
        claimsNotificationtoUpdate.lastUpdatedDate = dateToISOString(new Date());
    }
}
