import { FormGroupState, updateGroup, validate, ValidationErrors } from "ngrx-forms";
import { required } from "ngrx-forms/validation";

import { Nullable } from "../../../shared";
import { WorksheetId, WorksheetRenameForm } from "../../model";
import { WorksheetsState, WorksheetState } from "../state";

export const validateWorksheetGridRenameForm = (worksheetsState: WorksheetsState) => {
    if (!worksheetsState?.gridRenameForm) {
        return;
    }

    return updateGroup<WorksheetRenameForm>({
        name: validate(required, uniqueName(getAllNames(worksheetsState)))
    })(worksheetsState.gridRenameForm);
}

export const validateWorksheetListRenameForm = (form: Nullable<FormGroupState<WorksheetRenameForm>>, worksheetState: WorksheetState, worksheetsState: WorksheetsState) => {
    if (!form) {
        return;
    }

    return updateGroup<WorksheetRenameForm>({
        name: validate(required, uniqueName(getAllNames(worksheetsState, form.value.worksheetId)))
    })(form);
}

const getAllNames = (worksheetsState: WorksheetsState, ignoreId: WorksheetId = null): ReadonlyArray<string> =>
    worksheetsState.worksheets.allIds
        .map((id) => worksheetsState.worksheets.byId[id])
        .filter((w) => w.worksheet.worksheetType === worksheetsState.worksheetTypeToShow && w.worksheet.worksheetId !== ignoreId)
        .map((w) => w.worksheet.name.toLowerCase());

const uniqueName = <T extends string | null | undefined>(otherNames: ReadonlyArray<string>) => (name: T): ValidationErrors =>
    name && otherNames.some((n) => n === name.toLowerCase()) ? { duplicateName: true } : {};
