import { ContentChildren, Directive, ElementRef, HostBinding, QueryList } from "@angular/core";
import { CellEditor, Table } from "primeng/table";

@Directive({ selector: "[formGridRow]" })
export class FormGridRowDirective {
    @HostBinding("class.ops-fg-row-editing")
    get isEditing() {
        return this.el.nativeElement.contains(this.dt.editingCell);
    }

    @ContentChildren(CellEditor)
    cellEditors: QueryList<CellEditor>;

    constructor(private el: ElementRef, public dt: Table) {}
}
