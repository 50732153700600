import { Enumeration } from "./enumeration";

export class DemurrageReason extends Enumeration {
    static readonly AwaitDaylight = new DemurrageReason(1, "Await daylight");
    static readonly AwaitDischargeNomination = new DemurrageReason(2, "Await discharge nomination");
    static readonly AwaitLighteringVessel = new DemurrageReason(3, "Await lightering vessel");
    static readonly AwaitLoadNomination = new DemurrageReason(4, "Await load nomination");
    static readonly AwaitUnPermissiontoEnterPort = new DemurrageReason(5, "Await UN permission to enter port");
    static readonly AwaitingCharterersOrders = new DemurrageReason(6, "Awaiting charterers orders");
    static readonly BLIssues = new DemurrageReason(7, "B/L issues");
    static readonly BerthCongestion = new DemurrageReason(8, "Berth congestion");
    static readonly BlowingLines = new DemurrageReason(9, "Blowing lines");
    static readonly CommenceSqueegingSweeping = new DemurrageReason(10, "Commence squeeging/sweeping");
    static readonly CompleteSqueegingSweeping = new DemurrageReason(11, "Complete squeeging/sweeping");
    static readonly ConfigurationofBerthAvailabilityofLines = new DemurrageReason(12, "Configuration of berth/Availability of lines");
    static readonly ContaminationOffSpecTerminalRelated = new DemurrageReason(13, "Contamination/off spec terminal related");
    static readonly DraftIssuesDueTideWind = new DemurrageReason(14, "Draft issues due tide/wind");
    static readonly FinancialHoldLocIssues = new DemurrageReason(15, "Financial hold/LoC issues");
    static readonly FireExplosion = new DemurrageReason(16, "Fire/explosion");
    static readonly FloatingStorage = new DemurrageReason(17, "Floating storage");
    static readonly IceDelay = new DemurrageReason(18, "Ice delay");
    static readonly JettyMaintenance = new DemurrageReason(19, "Jetty maintenance");
    static readonly LoadingOneGradeataTime = new DemurrageReason(20, "Loading one grade at a time");
    static readonly LoiIssues = new DemurrageReason(21, "LOI issues");
    static readonly MachineryBreakdownShipSide = new DemurrageReason(22, "Machinery breakdown ship side");
    static readonly MachineryBreakdownShoreSide = new DemurrageReason(23, "Machinery breakdown shore side");
    static readonly MultipleBerthsUtilized = new DemurrageReason(24, "Multiple berths utilized");
    static readonly NoNightTimeNavigation = new DemurrageReason(25, "No night time navigation");
    static readonly OperationsExceedingLaytimeAllowance = new DemurrageReason(26, "Operations exceeding laytime allowance");
    static readonly OwnersCargoWrongTemperatureOffSpec = new DemurrageReason(27, "Owners – cargo wrong temperature/off spec");
    static readonly PIIssues = new DemurrageReason(28, "P&I issues");
    static readonly PortClosureDueBadWeather = new DemurrageReason(29, "Port closure due bad weather");
    static readonly PumpingIssue = new DemurrageReason(30, "Pumping issue");
    static readonly Purging = new DemurrageReason(31, "Purging");
    static readonly Resampling = new DemurrageReason(32, "Re-sampling");
    static readonly Sampling = new DemurrageReason(33, "Sampling");
    static readonly ShipsProblem = new DemurrageReason(34, "Ship’s problem");
    static readonly ShoreTankChanges = new DemurrageReason(35, "Shore tank changes");
    static readonly ShoreTankMaintenance = new DemurrageReason(36, "Shore tank maintenance");
    static readonly SlowDischargeTerminalRelated = new DemurrageReason(37, "Slow discharge terminal related");
    static readonly SlowLoadingTerminalRelated = new DemurrageReason(38, "Slow loading terminal related");
    static readonly StormBarrierMaintenance = new DemurrageReason(39, "Storm barrier maintenance");
    static readonly SupplyIssue = new DemurrageReason(40, "Supply issue");
    static readonly SurveyorIssues = new DemurrageReason(41, "Surveyor issues: late arrival/incorrect measurement/quality inspection delays");
    static readonly TankTops = new DemurrageReason(42, "Tank tops");
    static readonly TechnicalIssuesTechnicalBreakdownClause = new DemurrageReason(43, "Technical issues (Technical Breakdown Clause)");
    static readonly TerminalAnalysisDelays = new DemurrageReason(44, "Terminal analysis delays");
    static readonly TerminalOperationalDelays = new DemurrageReason(45, "Terminal operational delays");
    static readonly UnplannedShutdownForceMajeure = new DemurrageReason(46, "Unplanned shutdown/Force Majeure");
    static readonly VesselMovementSuspendedDueBadWeather = new DemurrageReason(47, "Vessel movement suspended due bad weather");
    static readonly VesselSafetyInspectionIsps = new DemurrageReason(48, "Vessel safety inspection (ISPS)");
    static readonly WaitingCustomDocsAndOrClearance = new DemurrageReason(49, "Waiting custom docs and/or clearance");
    static readonly WaitingForBbBarge = new DemurrageReason(50, "Waiting for b/b barge");
    static readonly WaitingForBbCoaster = new DemurrageReason(51, "Waiting for b/b coaster");
    static readonly WaitingForCargotoBeReady = new DemurrageReason(52, "Waiting for cargo to be ready");
    static readonly WaitingForDocs = new DemurrageReason(53, "Waiting for docs");
    static readonly WaitingForExporter = new DemurrageReason(54, "Waiting for exporter");
    static readonly WaitingForImporter = new DemurrageReason(55, "Waiting for importer");
    static readonly WaitingForLoadMaster = new DemurrageReason(56, "Waiting for Load Master");
    static readonly WaitingForPrewashFacilities = new DemurrageReason(57, "Waiting for prewash facilities");
    static readonly WaitingForRailwayBridgetoOpen = new DemurrageReason(58, "Waiting for Railway Bridge to open");
    static readonly WaitingForShoreLabour = new DemurrageReason(59, "Waiting for shore labour");
    static readonly WaitingForTide = new DemurrageReason(60, "Waiting for tide");
    static readonly WaitingForTrucksRailcars = new DemurrageReason(61, "Waiting for trucks/railcars");
    static readonly WaitingForVoyageOrders = new DemurrageReason(62, "Waiting for voyage orders");
    static readonly WaitingImmigrationCustomsClearance = new DemurrageReason(63, "Waiting immigration/customs clearance");
    static readonly WaitingLocks = new DemurrageReason(64, "Waiting locks");
    static readonly WaitingPilot = new DemurrageReason(65, "Waiting pilot");
    static readonly WaitingProductAvailability = new DemurrageReason(66, "Waiting product availability");
    static readonly WaitingUllage = new DemurrageReason(67, "Waiting ullage");
    static readonly WeatherDelay = new DemurrageReason(68, "Weather delay");
    static readonly WwtFailed = new DemurrageReason(69, "WWT failed");
    static readonly WeekendHoliday = new DemurrageReason(70, "Weekend/Holiday");
    static readonly TimeNotCounted = new DemurrageReason(71, "Time Not Counted");
    static readonly TimeToCount = new DemurrageReason(72, "Time to Count");
    static readonly Crane = new DemurrageReason(73, "Crane");
}
