import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfigService } from "../../../core";
import { SuggestionService } from "../../../shared/components/autosuggest/suggestion.service";
import { ContactService } from "../contact.service";
import { Person } from "../models/person.model";

@Injectable({
    providedIn: "root"
})
export class PersonAutosuggestService implements SuggestionService<Person> {
    private personServiceUrl: string;
    private personSearchRequest: any = { gainAccountIds: [], searchTerm: "" };
    private companiesInfo: CompanyInfo[] = [];

    constructor(private contactService: ContactService, private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.personServiceUrl = appConfigService.config.apiGatewayUrl;
        this.contactService.companies$.subscribe((companies) => {
            this.personSearchRequest.gainAccountIds = Array.from(new Set(companies.filter((f) => f.accountId).map((x) => x.accountId)));
            this.companiesInfo = Array.from(new Set(companies.filter((f) => f.accountId).map((x) => new CompanyInfo(x.accountId, x.name))));
        });
    }

    getSuggestions(term: string): Observable<Person[]> {
        if (term.length > 1) {
            this.personSearchRequest.searchTerm = term;

            return this.addCompanyName(
                this.httpClient.post<Person[]>(`${this.personServiceUrl}/api/v1.0/person/search?searchTerm=${term}&take=10`, this.personSearchRequest),
                this.companiesInfo
            );
        } else {
            return of([]);
        }
    }

    private addCompanyName(persons: Observable<Person[]>, companiesInfo: CompanyInfo[]): Observable<Person[]> {
        return persons.pipe(
            map((x: Person[]) =>
                x.map((person) => {
                    person.companyName = companiesInfo.filter((y: any) => y.accountId === person.accountId)[0].companyName;
                    return person;
                })
            )
        );
    }
}

class CompanyInfo {
    constructor(public accountId: number, public companyName: string) {}
}
