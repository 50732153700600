import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { DateTime } from "luxon";
import { FormGroupState, unbox } from "ngrx-forms";

import { CustomNgrxValueConverters } from "@ops/state";

import { VerifiedTimestamp } from "../../../../shared/components/timestamp/verified-timestamp.model";
import { Division } from "../../../shared/models";
import { CargoForm, DestinationId } from "../../../state/model";

@Component({
    selector: "ops-specialised-cargo-form",
    templateUrl: "./specialised-cargo-form.component.html",
    styleUrls: ["./specialised-cargo-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpecialisedCargoFormComponent {
    readonly dateRangeValueConverter = CustomNgrxValueConverters.dateRangeToISOString;
    readonly Division = Division;

    @Input() form: FormGroupState<CargoForm>;
    @Input() laycanToDate: DateTime;
    @Input() loadEtaVerifiedAudit: VerifiedTimestamp;
    @Input() loadEtaVerifyEnabled: boolean;
    @Input() showLoadEtaWarning: boolean;
    @Input() dischargeEtaVerifiedAudit: VerifiedTimestamp;
    @Input() dischargeEtaVerifyEnabled: boolean;
    @Input() showDischargeEtaWarning: boolean;
    @Input() loadDestinationId: DestinationId;
    @Input() dischargeDestinationId: DestinationId;
    @Input() readonly: boolean;

    @Output() readonly verifyEta = new EventEmitter();

    get loadLocationTimezone(): string {
        return this.getLocationTimeZone(true);
    }

    get dischargeLocationTimezone(): string {
        return this.getLocationTimeZone(false);
    }

    get dischargeEtaStartDate(): string {
        return this.form.value.loadLocation && this.form.value.loadLocation.eta && this.form.value.loadLocation.eta.value && unbox(this.form.value.loadLocation.eta).to;
    }

    private getLocationTimeZone(getLoadLocation: boolean): string {
        let timeZone: string;

        if (this.form) {
            const location = getLoadLocation ? this.form.value.loadLocation : this.form.value.dischargeLocation;
            const locationValue = location && location.location && location.location.value;

            timeZone = locationValue && locationValue.timeZone;
        }

        return timeZone || "utc";
    }
}
