import { Injectable } from "@angular/core";

/**
 * Given a string returns the start and end positions of words exclusive of punctuation.
 *
 * Note the start position is inclusive (the starting index) and the end position exclusive (index after the word ends).
 *
 * @example
 * // returns [[0,2], [3,5]]
 * getWordBounds('18:00')
 *
 * @param text The text to find the word bounds of.
 */
export function getWordBounds(text: string): readonly [start: number, end: number][] {
    const regex = /\b\w+\b/g;
    const bounds = new Array<[start: number, end: number]>();

    while (true) {
        const match = regex.exec(text);

        if (!match) {
            break;
        }

        bounds.push([match.index, match.index + match[0].length]);
    }

    return bounds;
}

/**
 * Selects the word nearest to the current selection start (cursor position).
 *
 * @param target The input element.
 * @param position The in the word to select.
 */
export function selectWord(target: HTMLInputElement, position?: number): readonly [start: number, end: number] | undefined {
    const bounds = getWordBounds(target.value);

    position = position ?? target.selectionStart;

    // Find the word to select - favours the previous word
    const bound = bounds.find(([start, end]) => position >= start && position <= end);

    if (bound) {
        const [start, end] = bound;

        target.setSelectionRange(start, end);

        return [start, end];
    }
}

/**
 * @deprecated use selectWord function
 */
@Injectable({
    providedIn: "root"
})
export class SelectWordsHandler {
    handleSelect = selectWord;
}
