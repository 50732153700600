import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, takeUntil, withLatestFrom } from "rxjs/operators";

import { AuthService } from "@ops/core";
import { SimpleChanges } from "@ops/shared";
import { Enumeration, ReferenceDataService } from "@ops/shared/reference-data";

import { parseISODate } from "../../../shared/date-utils/date-utilities";
import { EstimatedClaimValueCalculator } from "../../services/estimated-claim-value-calculator";
import { FixtureDataService } from "../../services/fixture-data.service";
import { FixtureHttpService } from "../../services/fixture-http.service";
import { FormComponentBase } from "../../shared/form-component-base";
import { Currency, DemurrageClaim, Destination, Fixture } from "../../shared/models";
import { DemurrageClaimFormModel } from "../../shared/models/form-models/demurrage-claim.model";
import { TabNavigationService } from "../../shared/navigation/tab-navigation.service";
import { RemoveDemurrageClaimCommand } from "../demurrage-claim-container/commands/remove-demurrage-claim.command";
import { Berth } from "../shared/berth.model";
import { UpdateDemurrageClaimCommand } from "./commands/update-demurrage-claim.command";
import { ClaimValueValidator } from "./validators/claim-value.validator";

@Component({
    selector: "ops-demurrage-claim",
    templateUrl: "./demurrage-claim.component.html",
    styleUrls: ["./demurrage-claim.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemurrageClaimComponent extends FormComponentBase implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static componentName = "DemurrageClaimComponent";

    private readonly destroy$ = new Subject();
    private formValues$: BehaviorSubject<DemurrageClaimFormModel> = new BehaviorSubject<DemurrageClaimFormModel>(null);
    private subscriptions: Subscription[] = [];

    commentsMaxLength = 6000;
    availableBerths: Berth[];
    berthIsRequired: boolean;
    hideDemurrageClaim: boolean;
    demurrageClaimTypes: Enumeration[] = [];
    currencies$: Observable<Currency[]>;
    lastUpdatedByUser: string;
    lastUpdatedDate: Date;
    estimatedClaimValue: number;

    @ViewChild("scrollToPlaceholder", { static: true }) scrollToPlaceholder: ElementRef;
    @Input() demurrageClaims: DemurrageClaim[];
    @Input() index: number;
    @Input() demurrageClaimForm: UntypedFormGroup;
    @Input() newlyAdded: boolean;
    @Input() destinations: Destination[];
    @Input() parentForm: UntypedFormGroup;
    @Input() onAccountIndex: number | null;
    @Input() fixtureId: string;
    @Input() fixture: Fixture;

    get demurrageClaim() {
        return this.demurrageClaims[this.index];
    }

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private fixtureDataService: FixtureDataService,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        public referenceDataService: ReferenceDataService,
        private fixtureService: FixtureHttpService,
        private tabNavigationService: TabNavigationService,
        private ecvCalculator: EstimatedClaimValueCalculator
    ) {
        super();
    }

    ngOnInit() {
        this.referenceDataService.getDemurrageClaimTypes().subscribe((demurrageClaimTypes: Enumeration[]) => {
            this.demurrageClaimTypes = demurrageClaimTypes;
            this.setDefaultClaimTypeIfNecessary();
            this.changeDetectorRef.markForCheck();
        });

        this.currencies$ = this.referenceDataService.getCurrencies();
        this.setupDemurrageClaimForm(this.demurrageClaims[this.index]);
        this.setDemurrageClaimValues(this.demurrageClaims[this.index], this.fixture);
        this.setAvailableBerths();
        this.subscribeToFormValueChanges();
    }

    ngAfterViewInit() {
        if (this.newlyAdded) {
            this.scrollToPlaceholder.nativeElement.scrollIntoView();
        }
        this.tabNavigationService.currentTab$.pipe(takeUntil(this.destroy$)).subscribe((tab) => {
            if (tab && tab.tabName === "demurrageTab") {
                this.scrollToElement(tab.elementName);
                this.changeDetectorRef.markForCheck();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges<DemurrageClaimComponent>) {
        if (changes.demurrageClaims && !changes.demurrageClaims?.firstChange && this.fixture) {
            this.removeFormSubscriptions();
            this.setDemurrageClaimValues(this.demurrageClaim, changes.fixture.currentValue);
            this.subscribeToFormValueChanges();
        }

        if (changes.fixture || changes.destinations || changes.demurrageClaims) {
            this.setEstimatedClaimValue();
        }

        if (changes.destinations) {
            this.setAvailableBerths();
        }
    }

    ngOnDestroy() {
        this.removeFormSubscriptions();
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.destroy$.next();
    }

    toggleDemurrageClaim() {
        this.hideDemurrageClaim = !this.hideDemurrageClaim;
    }

    removeDemurrageClaim(event: MouseEvent) {
        event.preventDefault();
        this.fixtureDataService.handleUpdateCommand(new RemoveDemurrageClaimCommand(this.demurrageClaim.demurrageClaimId));
    }

    updateLastContacted() {
        const demurrageClaimId = this.demurrageClaim.demurrageClaimId;
        const sub = this.fixtureService
            .updateDemurageClaimLastContacted(this.fixtureId, demurrageClaimId)
            .pipe(withLatestFrom(this.formValues$))
            .subscribe(([date, formValue]) => {
                formValue.lastContactedDateTime = parseISODate(date);
                this.fixtureDataService.handleUpdateCommand(new UpdateDemurrageClaimCommand(this.authService.user, formValue, this.destinations));
            });

        this.subscriptions.push(sub);
    }

    compareBerth(b1: Berth, b2: Berth): boolean {
        return b1 && b2 ? b1.destinationId === b2.destinationId && b1.berthId === b2.berthId : b1 === b2;
    }

    protected subscribeToFormValueChanges() {
        this.formValueSubscription = this.demurrageClaimForm.valueChanges.pipe(debounceTime(10)).subscribe((value) => {
            this.fixtureDataService.handleUpdateCommand(new UpdateDemurrageClaimCommand(this.authService.user, value, this.destinations));
            this.formValues$.next(value);
        });
    }

    private scrollToElement(elementName: string) {
        const elements = document.getElementsByName(elementName);
        if (elements && elements.length > 0) {
            elements[0].scrollIntoView({
                behavior: "smooth"
            });
            setTimeout(() => {
                elements[0].focus();
            }, 0);
        }
    }

    private setupDemurrageClaimForm(demurrageClaim: DemurrageClaim) {
        this.demurrageClaimForm.registerControl("demurrageClaimId", this.formBuilder.control(demurrageClaim.demurrageClaimId));
        this.demurrageClaimForm.registerControl("type", this.formBuilder.control(demurrageClaim.type || this.demurrageClaimTypes[0], Validators.required));
        this.demurrageClaimForm.registerControl("receivedFromOwnerDate", this.formBuilder.control(parseISODate(demurrageClaim.receivedFromOwnerDate)));
        this.demurrageClaimForm.registerControl("sentToChartererDate", this.formBuilder.control(parseISODate(demurrageClaim.sentToChartererDate)));
        this.demurrageClaimForm.registerControl("chartererAcknowledgedReceiptDate", this.formBuilder.control(demurrageClaim.chartererAcknowledgedReceiptDate));
        this.demurrageClaimForm.registerControl("initialClaimValue", this.formBuilder.control(demurrageClaim.initialClaimValue));
        this.demurrageClaimForm.registerControl("interimClaimValue", this.formBuilder.control(demurrageClaim.interimClaimValue));
        this.demurrageClaimForm.registerControl("finalClaimValue", this.formBuilder.control(demurrageClaim.finalClaimValue));
        this.demurrageClaimForm.registerControl("currency", this.formBuilder.control(demurrageClaim.currency));
        this.demurrageClaimForm.registerControl("comments", this.formBuilder.control(demurrageClaim.comments, Validators.maxLength(this.commentsMaxLength)));
        this.demurrageClaimForm.registerControl("agreedDate", this.formBuilder.control(parseISODate(demurrageClaim.agreedDate)));
        this.demurrageClaimForm.registerControl("datePaid", this.formBuilder.control(parseISODate(demurrageClaim.datePaid)));
        this.demurrageClaimForm.registerControl("lastContactedDateTime", this.formBuilder.control(parseISODate(demurrageClaim.lastContactedDateTime)));
        this.demurrageClaimForm.registerControl("ownerInvoiceNumber", this.formBuilder.control(demurrageClaim.ownerInvoiceNumber));
        this.demurrageClaimForm.registerControl("commissionDate", this.formBuilder.control(parseISODate(demurrageClaim.commissionDate)));
        this.demurrageClaimForm.registerControl("awaitingHardCopy", this.formBuilder.control(demurrageClaim.awaitingHardCopy));
        this.demurrageClaimForm.registerControl("awaitingDocuments", this.formBuilder.control(demurrageClaim.awaitingDocuments));
        this.demurrageClaimForm.registerControl("complete", this.formBuilder.control(demurrageClaim.complete));
        this.demurrageClaimForm.registerControl("claimHandledBy", this.formBuilder.control(demurrageClaim.claimHandledBy));

        if (demurrageClaim.destinationId) {
            this.demurrageClaimForm.registerControl(
                "berth",
                this.formBuilder.control({
                    description: "",
                    destinationId: demurrageClaim.destinationId,
                    berthId: demurrageClaim.berthId
                })
            );
        } else {
            this.demurrageClaimForm.registerControl("berth", this.formBuilder.control(null));
        }

        if (this.parentForm.disabled) {
            this.demurrageClaimForm.disable();
        } else {
            this.demurrageClaimForm.enable();
        }
    }

    private setDefaultClaimTypeIfNecessary() {
        if (this.demurrageClaimForm && this.demurrageClaimForm.get("type") && !this.demurrageClaimForm.get("type").value) {
            this.demurrageClaimForm.get("type").setValue(this.demurrageClaimTypes[0]);
        }
    }

    private setAvailableBerths() {
        const berths: Berth[] = [];

        if (this.destinations) {
            for (const destination of this.destinations) {
                if (destination.berths && destination.berths.length) {
                    for (const berth of destination.berths) {
                        let description = destination.location ? destination.location.displayName : "";
                        if (berth.name) {
                            description = `${description} - ${berth.name}`;
                        }

                        berths.push({ description, destinationId: destination.destinationId, berthId: berth.berthId });
                    }
                } else {
                    berths.push({
                        description: destination.location ? destination.location.displayName : "",
                        destinationId: destination.destinationId,
                        berthId: null
                    });
                }
            }
        }

        this.availableBerths = berths.filter((berth) => this.berthIsAvailable(berth));
    }

    private setDemurrageClaimValues(demurrageClaim: DemurrageClaim, fixture: Fixture) {
        const demurrageClaimFormValues: DemurrageClaimFormModel = {
            demurrageClaimId: demurrageClaim.demurrageClaimId,
            type: demurrageClaim.type || this.demurrageClaimTypes[0],
            gainId: demurrageClaim.gainId,
            receivedFromOwnerDate: parseISODate(demurrageClaim.receivedFromOwnerDate),
            sentToChartererDate: parseISODate(demurrageClaim.sentToChartererDate),
            chartererAcknowledgedReceiptDate: parseISODate(demurrageClaim.chartererAcknowledgedReceiptDate),
            estimatedClaimValue: demurrageClaim.estimatedClaimValue,
            initialClaimValue: demurrageClaim.initialClaimValue,
            interimClaimValue: demurrageClaim.interimClaimValue,
            finalClaimValue: demurrageClaim.finalClaimValue,
            currency: demurrageClaim.currency,
            berth: null,
            comments: demurrageClaim.comments,
            agreedDate: parseISODate(demurrageClaim.agreedDate),
            datePaid: parseISODate(demurrageClaim.datePaid),
            lastContactedDateTime: parseISODate(demurrageClaim.lastContactedDateTime),
            ownerInvoiceNumber: demurrageClaim.ownerInvoiceNumber,
            commissionDate: parseISODate(demurrageClaim.commissionDate),
            awaitingHardCopy: demurrageClaim.awaitingHardCopy,
            awaitingDocuments: demurrageClaim.awaitingDocuments,
            complete: demurrageClaim.complete,
            claimHandledBy: demurrageClaim.claimHandledBy
        };

        if (demurrageClaim.destinationId) {
            demurrageClaimFormValues.berth = {
                description: "",
                destinationId: demurrageClaim.destinationId,
                berthId: demurrageClaim.berthId
            };
        }

        this.demurrageClaimForm.setValidators([ClaimValueValidator.validate(fixture.demurrageBankEnabled)]);

        this.demurrageClaimForm.patchValue(demurrageClaimFormValues, { emitEvent: false });
        this.formValues$.next(demurrageClaimFormValues);

        this.lastUpdatedByUser = demurrageClaim.lastUpdatedByUser ? demurrageClaim.lastUpdatedByUser.fullName : "";
        this.lastUpdatedDate = parseISODate(demurrageClaim.lastUpdatedDate);
    }

    private setEstimatedClaimValue() {
        this.estimatedClaimValue = this.ecvCalculator.calculateCurrentECV(this.fixture, this.destinations, this.demurrageClaim);
    }

    private berthIsAvailable({ destinationId, berthId }: Berth): boolean {
        const thisClaim = this.demurrageClaims[this.index];

        if (thisClaim.destinationId === destinationId && thisClaim.berthId === berthId) {
            return true;
        }

        return this.demurrageClaims.every((claim) => claim.destinationId !== destinationId || claim.berthId !== berthId);
    }
}
