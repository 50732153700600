import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, withLatestFrom } from "rxjs/operators";

import { GridType } from "../../../fixture/shared/models/enums/grid-type";
import { WorksheetHttpService } from "../../../left-bar/worksheets/service/worksheet-http.service";
import { Worksheet } from "../../model";
import { toObjectMap } from "../../utils";
import { selectWorksheetsFeature } from "../selectors";
import { WorksheetFeatureState, WorksheetsState } from "../state";

/* ACTIONS */
const GET_ALL_ACTION_NAME = "[Worksheets] Get All";

export const getAllWorksheetsAction = createAction(GET_ALL_ACTION_NAME, props<{ gridType: GridType }>());
export const getAllWorksheetsSuccessAction = createAction(`${GET_ALL_ACTION_NAME} Success`, props<{ worksheets: ReadonlyArray<Worksheet> }>());
export const getAllWorksheetsFailAction = createAction(`${GET_ALL_ACTION_NAME} Fail`, props<{ error: Error }>());

/* REDUCERS */
export const getAllWorksheetsReducer: On<WorksheetsState> = on(getAllWorksheetsAction, (state, { gridType }) => ({
    ...state,
    worksheetTypeToShow: gridType,
    loadStatus: state.worksheets.allIds.length ? state.loadStatus : "loading"
}));

export const getAllWorksheetsSuccessReducer: On<WorksheetsState> = on(getAllWorksheetsSuccessAction, (state, { worksheets }) => ({
    ...state,
    worksheets: toObjectMap(worksheets, "worksheetId", (worksheet) => ({
        worksheet
    })),
    loadStatus: "loaded"
}));

export const getAllWorksheetsFailReducer: On<WorksheetsState> = on(getAllWorksheetsFailAction, (state) => ({
    ...state,
    loadStatus: "failed"
}));

/* EFFECTS */
export const getAllWorksheetsEffect$ = (actions$: Actions, store: Store<WorksheetFeatureState>, worksheetHttpService: WorksheetHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(getAllWorksheetsAction),
            withLatestFrom(store.select(selectWorksheetsFeature)),
            filter(([, { loadStatus }]) => loadStatus !== "loaded"),
            exhaustMap(() =>
                worksheetHttpService.get().pipe(
                    map((worksheets) => getAllWorksheetsSuccessAction({ worksheets })),
                    catchError((error) => of(getAllWorksheetsFailAction({ error })))
                )
            )
        )
    );
