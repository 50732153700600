import { DatePipe } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, QueryList, ViewChildren } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

import { InvoiceType, ReferenceDataService } from "../../../../shared/reference-data";
import { InvoiceDataService } from "../../../services/invoice-data.service";
import { AbstractInvoicesComponent } from "../../../shared/invoices/abstract-invoices.component";
import { Division } from "../../../shared/models";

@Component({
    selector: "ops-freight-invoices",
    templateUrl: "./freight-invoices.component.html",
    styleUrls: ["../../../shared/invoices/abstract-invoices.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreightInvoicesComponent extends AbstractInvoicesComponent implements AfterViewInit {
    static componentName = "FreightInvoicesComponent";

    Division = Division;

    @ViewChildren("currency", { read: NgSelectComponent }) ngSelectElements: QueryList<NgSelectComponent>;

    constructor(
        cd: ChangeDetectorRef,
        formBuilder: UntypedFormBuilder,
        referenceDataService: ReferenceDataService,
        datePipe: DatePipe,
        invoiceDataService: InvoiceDataService,
        store: Store
    ) {
        super(InvoiceType.FreightInvoice, cd, formBuilder, referenceDataService, datePipe, invoiceDataService, store);
    }

    ngAfterViewInit() {
        let count = this.invoiceCount;

        this.ngSelectElements.changes.pipe(takeUntil(this.destroy$)).subscribe((elements: QueryList<NgSelectComponent>) => {
            if (!this.invoicesForm.disabled && elements.length && elements.length !== count) {
                elements.last.focus();
                count = elements.length;
            }
        });
    }
}
