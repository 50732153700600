import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

import { VerifiedTimestamp } from "src/app/shared/components/timestamp/verified-timestamp.model";
import { EmailGenerationAudit } from "../../shared/models";
import {
    ActivityId,
    ActivityState,
    addActivity,
    BerthForm,
    BerthId,
    collapseActivity,
    DestinationId,
    expandActivity,
    FixtureFeatureState,
    generatePortTimesEmail,
    moveActivityDown,
    moveActivityUp,
    removeActivity,
    selectCurrentDestinationBerthFormState,
    selectCurrentVoyageActivities,
    selectCurrentVoyageBerthEtbVerified,
    selectCurrentVoyageBerthIsTransient,
    selectCurrentVoyageBerthPortTimesEmailAudit,
    selectCurrentVoyageDestinationEta,
    selectCurrentVoyageDestinationLocationTimezone,
    verifyEtb
} from "../../state";

@Component({
    selector: "ops-berth-shell",
    templateUrl: "./berth-shell.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BerthShellComponent {
    readonly destinationId$ = new BehaviorSubject<DestinationId>(null);
    readonly berthId$ = new BehaviorSubject<BerthId>(null);

    readonly form$: Observable<FormGroupState<BerthForm>>;
    readonly locationTimezone$: Observable<string>;
    readonly activities$: Observable<ReadonlyArray<ActivityState>>;
    readonly etbVerifiedAudit$: Observable<VerifiedTimestamp>;
    readonly portTimesEmailAudit$: Observable<EmailGenerationAudit>;
    readonly defaultEtbFocusDate$: Observable<string>;
    readonly isTransient$: Observable<boolean>;

    @Input()
    set destinationId(value: DestinationId) {
        this.destinationId$.next(value);
    }
    get destinationId() {
        return this.destinationId$.value;
    }

    @Input()
    set berthId(value: BerthId) {
        this.berthId$.next(value);
    }
    get berthId() {
        return this.berthId$.value;
    }

    @Input() readonly: boolean;

    constructor(private store: Store<FixtureFeatureState>) {
        this.form$ = combineLatest([this.destinationId$, this.berthId$]).pipe(
            switchMap(([destinationId, berthId]) =>
                this.store.select(selectCurrentDestinationBerthFormState, { destinationId: destinationId, berthId: berthId })
            )
        );
        this.locationTimezone$ = this.destinationId$.pipe(
            switchMap((destinationId) => this.store.select(selectCurrentVoyageDestinationLocationTimezone, { destinationId: destinationId }))
        );
        this.activities$ = combineLatest([this.destinationId$, this.berthId$]).pipe(
            switchMap(([destinationId, berthId]) => this.store.select(selectCurrentVoyageActivities, { destinationId: destinationId, berthId: berthId }))
        );
        this.etbVerifiedAudit$ = combineLatest([this.destinationId$, this.berthId$]).pipe(
            switchMap(([destinationId, berthId]) => this.store.select(selectCurrentVoyageBerthEtbVerified, { destinationId: destinationId, berthId: berthId }))
        );
        this.portTimesEmailAudit$ = combineLatest([this.destinationId$, this.berthId$]).pipe(
            switchMap(([destinationId, berthId]) =>
                this.store.select(selectCurrentVoyageBerthPortTimesEmailAudit, { destinationId: destinationId, berthId: berthId })
            )
        );
        this.isTransient$ = combineLatest([this.destinationId$, this.berthId$]).pipe(
            switchMap(([destinationId, berthId]) => this.store.select(selectCurrentVoyageBerthIsTransient, { destinationId: destinationId, berthId: berthId }))
        );
        this.defaultEtbFocusDate$ = this.destinationId$.pipe(
            switchMap((destinationId) => this.store.select(selectCurrentVoyageDestinationEta, { destinationId: destinationId }))
        );
    }

    expandActivity(activityId: ActivityId) {
        this.store.dispatch(expandActivity({ destinationId: this.destinationId, berthId: this.berthId, activityId }));
    }

    collapseActivity(activityId: ActivityId) {
        this.store.dispatch(collapseActivity({ destinationId: this.destinationId, berthId: this.berthId, activityId }));
    }

    moveActivityUp(activityId: ActivityId) {
        this.store.dispatch(moveActivityUp({ destinationId: this.destinationId, berthId: this.berthId, activityId }));
    }

    moveActivityDown(activityId: ActivityId) {
        this.store.dispatch(moveActivityDown({ destinationId: this.destinationId, berthId: this.berthId, activityId }));
    }

    addActivity() {
        this.store.dispatch(addActivity({ destinationId: this.destinationId, berthId: this.berthId }));
    }

    removeActivity(activityId: ActivityId) {
        this.store.dispatch(removeActivity({ destinationId: this.destinationId, berthId: this.berthId, activityId }));
    }

    verifyEtb() {
        this.store.dispatch(verifyEtb({ destinationId: this.destinationId, berthId: this.berthId }));
    }

    generatePortTimesEmail() {
        this.store.dispatch(generatePortTimesEmail({ destinationId: this.destinationId, berthId: this.berthId }));
    }
}
