import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, takeUntil, tap } from "rxjs/operators";

import { hasValue } from "@ops/shared";

import { selectCurrentFixtureId } from "../../../fixture/state";
import { LeftBarStateService } from "../../../left-bar/left-bar-state.service";
import {
    LtcFeatureState,
    selectCurrentFixtureLaytimeCalculations,
    FixtureLaytimeCalculation,
    loadFixtureLaytimeCalculations,
    createLaytimeCalculation,
    selectCurrentLaytimeCalculationPersisting,
    selectCurrentLaytimeCalculationFixtureId,
    LtcId,
    removeLaytimeCalculation
} from "../../state";

@Component({
    selector: "ops-laytime-calculation-list-shell",
    templateUrl: "./laytime-calculation-list-shell.component.html",
    styleUrls: ["./laytime-calculation-list-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationListShellComponent implements OnDestroy {
    readonly calculations$: Observable<ReadonlyArray<FixtureLaytimeCalculation>>;
    readonly busy$: Observable<boolean>;
    readonly destroy$ = new Subject();

    constructor(private store: Store<LtcFeatureState>, private leftBarStateService: LeftBarStateService) {
        combineLatest([this.store.select(selectCurrentFixtureId), this.store.select(selectCurrentLaytimeCalculationFixtureId)])
            .pipe(
                map(([fixtureId, calculationFixtureId]) => fixtureId ?? calculationFixtureId),
                distinctUntilChanged(),
                filter(hasValue),
                tap((fixtureId) => this.store.dispatch(loadFixtureLaytimeCalculations({ fixtureId }))),
                takeUntil(this.destroy$)
            )
            .subscribe();

        this.calculations$ = this.store.select(selectCurrentFixtureLaytimeCalculations);
        this.busy$ = this.store.select(selectCurrentLaytimeCalculationPersisting);
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    createLaytimeCalculation() {
        this.store.dispatch(createLaytimeCalculation());
    }

    removeLaytimeCalculation(ltcId: LtcId) {
        this.store.dispatch(removeLaytimeCalculation({ ltcId }));
    }

    collapseLeftBar() {
        this.leftBarStateService.collapse();
    }
}
