import { Injectable } from "@angular/core";

import { Division, Fixture, FixtureType, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class ExternalVisibilityDestinationLocationRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    shouldEvaluate(fixture: Fixture) {
        return fixture.division.id !== Division.specialisedProducts;
    }

    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        voyages.forEach((voyage) =>
            voyage.destinations.forEach((destination, destinationIndex) => {
                if (!destination.location) {
                    const message = "Missing Location";
                    const path = [
                        ...this.getVoyagePathSegment(fixture.fixtureType, voyage),
                        new FixtureWarningPathSegment("destinations", "Locations"),
                        FixtureWarningPathSegment.fromIndex(destinationIndex, "Location"),
                        new FixtureWarningPathSegment("location", null)
                    ];
                    const dismissingRecord =
                        fixture.fixtureType.id !== FixtureType.Voyage
                            ? dismissedWarningRecord(
                                  new WarningHashBuilder().withVoyage(voyage.voyageId).withDestination(destination.destinationId).withCategory("data").withMessage(message).build()
                              )
                            : null;
                    warnings.push(new FixtureWarning("data", message, path, dismissingRecord));
                }
            })
        );

        return warnings;
    }
}
