import { FormGroupState } from "ngrx-forms";

import { IndexResponse } from "@ops/shared";
import { AppState, LoadStatus, PersistenceStatus, StateObjectMap } from "@ops/state";

import { CargoForm } from "./cargo";
import { Coa, CoaId } from "./coa";
import { CompanyForm } from "./company";
import { CompletedVoyage } from "./completed-voyage";
import { CoaGridState } from "./grid";
import { CoaHeaderForm } from "./header";
import { LiftingGridRow, LiftingId, PlannedLifting, LiftingState, LiftingIndexItem } from "./lifting";
import { LocationForm } from "./location";
import { NominationTaskForm } from "./nomination-task";

export type LocationFormKey = keyof Pick<CoaState, "loadLocationForm" | "dischargeLocationForm">;

export declare type CoaState = Readonly<{
    coa?: Coa;
    loadStatus?: LoadStatus;
    persistenceStatus?: PersistenceStatus;
    coaHeaderForm?: FormGroupState<CoaHeaderForm>;
    loadLocationForm?: FormGroupState<LocationForm>;
    dischargeLocationForm?: FormGroupState<LocationForm>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    erroredItems?: ReadonlyArray<{ itemType: keyof Coa; id: any; error: Error }>;
    cargoForm?: FormGroupState<CargoForm>;
    cargoFormSaveStatus?: PersistenceStatus;
    nominationTaskForm?: FormGroupState<NominationTaskForm>;
    nominationTaskFormSaveStatus?: PersistenceStatus;
    liftingCreationPending?: boolean;
    companyForm?: FormGroupState<CompanyForm>;
    mostRecentCompletedVoyage?: CompletedVoyage;
    mostRecentCompletedVoyageLoadStatus?: LoadStatus;
    earliestPlannedLifting?: PlannedLifting;
    earliestPlannedLiftingLoadStatus?: LoadStatus;
    indexedLiftings?: IndexResponse<LiftingGridRow>;
    fetchedLiftings?: ReadonlyArray<LiftingIndexItem>;
    indexedLiftingsLoadStatus?: LoadStatus;
}>;

export declare type CoasState = Readonly<{
    coas: StateObjectMap<CoaState>;
    currentCoaId?: CoaId;
    liftings: StateObjectMap<LiftingState>;
    currentLiftingId?: LiftingId;
    grid: CoaGridState;
}>;

export type CoaFeatureState = AppState & Readonly<{ coas: CoasState }>;

export const getCurrentCoaId = (state: CoasState) => state.currentCoaId;
export const getCoaState = (state: CoasState, coaId: CoaId) => state.coas.byId[coaId];
export const getCurrentCoaState = (state: CoasState) => state.coas.byId[getCurrentCoaId(state)];
export const initialCoaState = (coa: Coa): CoaState => ({ coa, loadStatus: "loaded" });

export const getGridState = (state: CoasState) => state.grid;
