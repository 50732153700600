import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NgSelectComponent } from "@ng-select/ng-select";
import { Store } from "@ngrx/store";
import * as R from "ramda";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CascadeCargoAllowedRatesCommand } from "./commands/cascade-cargo-allowed-rates.command";
import { UpdateCargoAllowedRatesCommand } from "./commands/update-cargo-allowed-rates.command";
import { FieldEditorModalComponent } from "../../../shared/components/field-editor-modal/field-editor-modal.component";
import { FieldEditorModel } from "../../../shared/components/field-editor-modal/field-editor.model";
import { CargoBerthActivityType, CpRateUnit, Enumeration, LaytimeType, ReferenceDataService } from "../../../shared/reference-data";
import { Cargo, Destination, Fixture, SeaNetLocation } from "../../shared/models";
import { CargoAllowedRate } from "../../shared/models/dtos/cargo-allowed-rate.dto";
import { QuantityModel } from "../../shared/models/form-models";
import { CargoAllowedRatesViewModel } from "../../shared/models/form-models/cargo-allowed-rates.model";
import { FieldEditorFieldModel } from "../../shared/models/form-models/field-editor-field.model";
import { FieldEditorRowModel } from "../../shared/models/form-models/field-editor-row.model";
import { NumberFormatModel } from "../../shared/models/form-models/number-format.model";
import { AbstractSimpleGridComponent } from "../../speed-and-consumption-tab/cp-speed-and-consumption/abstract-simple-grid/abstract-simple-grid.component";
import { bulkUpdateCargoAllowedRates } from "../../state/cargoes/actions";
import { CargoId, getCargoName } from "../../state/model";

@Component({
    selector: "ops-cargo-allowed-rates",
    templateUrl: "./cargo-allowed-rates.component.html",
    styleUrls: ["./cargo-allowed-rates.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CargoAllowedRatesComponent extends AbstractSimpleGridComponent<CargoAllowedRatesViewModel> implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    static componentName = "CargoAllowedRatesComponent";

    private cpRateNumberFormat: NumberFormatModel = { precision: 3, minValue: 0, showTrailingZeros: false };
    private cpRateUnits = <CpRateUnit[]>Object.values(CpRateUnit);
    private activeTooltip = false;
    private readonly columnDefs = [
        { headerName: "", field: "cargoId", class: "d-none" },
        { headerName: "Cargo", field: "cargoProductName", class: "" },
        { headerName: "Load Location", field: "loadLocation", class: "" },
        { headerName: "CP Load Rate", field: "cpLoadRate", class: "" },
        { headerName: "Load Laytime Type", field: "loadReversibleLaytimeType", class: "" },
        { headerName: "Extra Hours", field: "loadExtraHours", class: "" },
        { headerName: "Discharge Location", field: "dischargeLocation", class: "" },
        { headerName: "CP Discharge Rate", field: "cpDischargeRate", class: "" },
        { headerName: "Discharge Laytime Type", field: "dischargeReversibleLaytimeType", class: "" },
        { headerName: "Extra Hours", field: "dischargeExtraHours", class: "" }
    ];
    private popupModelData: FieldEditorModel = {
        title: ["Edit selected rows"],
        fields: [
            <FieldEditorFieldModel>{ id: "cpLoadRate", name: "CP Load Rate", dataType: "CpRateUnit", numberFormat: this.cpRateNumberFormat },
            <FieldEditorFieldModel>{ id: "cpDischargeRate", name: "CP Discharge Rate", dataType: "CpRateUnit", numberFormat: this.cpRateNumberFormat }
        ]
    };

    reversibleLaytimeType$: Observable<Enumeration[]>;
    selectedRows: any[] = [];

    @Input() parentForm: UntypedFormGroup;
    @Input() model: CargoAllowedRate[];
    @Input() cargoes: Cargo[];
    @Input() fixture: Fixture;
    @Input() destinations: Destination[];
    @Output() cargoAllowedRatesUpdated = new EventEmitter();

    get isFixedLaytimeType(): boolean {
        return this.fixture && this.fixture.laytimeType && this.fixture.laytimeType.id === LaytimeType.Fixed.id;
    }

    get isActiveTooltip(): boolean {
        return this.activeTooltip;
    }

    @ViewChildren("cpRateUnitsElement", { read: NgSelectComponent }) ngSelectElements: QueryList<NgSelectComponent>;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        protected formBuilder: UntypedFormBuilder,
        public referenceDataService: ReferenceDataService,
        private dialog: MatDialog,
        private store: Store
    ) {
        super("cargoId", changeDetectorRef, formBuilder);

        this.columnDefs$.next(this.columnDefs);
        this.reversibleLaytimeType$ = this.referenceDataService.getReversibleLaytimeType();
        this.rowChanged$.pipe(takeUntil(this.destroy$)).subscribe((row) => this.cargoAllowedRatesUpdated.emit(new UpdateCargoAllowedRatesCommand(this.toDomainModel(row))));
    }

    ngOnChanges(): void {
        if (this.isCargoAllowedRatesInSync) {
            const data = this.model.map((item) => this.toViewModel(item));
            this.rowData$.next(data);
        }
    }

    ngAfterViewInit() {
        this.ngSelectElements.changes.pipe(takeUntil(this.destroy$)).subscribe((elements: QueryList<NgSelectComponent>) => {
            if (!this.formArray.disabled && elements.length) {
                elements.last.focus();
            }
        });
    }

    trackBy(item: AbstractControl) {
        return item;
    }

    handleApplyToAllTables(controls: AbstractControl[]) {
        this.cargoAllowedRatesUpdated.emit(new CascadeCargoAllowedRatesCommand(controls.map((control) => this.toDomainModel(control.value))));
    }

    toggleTooltip(element: any) {
        if (element.children[0].offsetWidth <= element.children[0].scrollWidth) {
            this.activeTooltip = true;
        } else {
            this.activeTooltip = false;
        }
    }

    openModal() {
        this.dialog
            .open(FieldEditorModalComponent, { data: this.popupModelData })
            .afterClosed()
            .subscribe((rows: FieldEditorRowModel[]) => {
                if (rows && rows.length > 0) {
                    rows.forEach((row: FieldEditorRowModel) => {
                        this.selectedRows.forEach((selectedRow: UntypedFormGroup) => {
                            if (selectedRow) {
                                const requiredCtrl = selectedRow.controls[row.id];
                                const ctrlValue: QuantityModel = requiredCtrl.value;
                                ctrlValue.unit = row.quantity.unit ?? ctrlValue.unit;
                                ctrlValue.value = row.quantity.value ?? ctrlValue.value;
                                requiredCtrl.setValue(ctrlValue);
                            }
                        });
                    });

                    this.store.dispatch(
                        bulkUpdateCargoAllowedRates({
                            numberOfCargoesSelected: this.selectedRows.length,
                            fieldLabel: rows.map((r) => r.field.name).join(", ")
                        })
                    );
                }

                this.selectedRows = [];
                this.changeDetectorRef.detectChanges();
            });
    }

    private get isCargoAllowedRatesInSync(): boolean {
        const cargoes = this.cargoes || [];
        const cargoAllowedRates = this.model || [];
        return R.equals(cargoes.map((x) => x.id).sort(), cargoAllowedRates.map((x) => x.cargoId).sort());
    }

    private getLocationsForCargo(cargoId: CargoId, type: CargoBerthActivityType): string {
        const locations: SeaNetLocation[] = [];
        if (this.destinations) {
            for (const destination of this.destinations.filter((x) => x.location)) {
                for (const berth of destination.berths) {
                    for (const activities of berth.cargoBerthActivities) {
                        if (activities.type?.id === type.id && activities.associatedCargoes.some((c) => c.cargoId === cargoId)) {
                            locations.push(destination.location);
                        }
                    }
                }
            }
        }
        return locations.map((a) => a.displayName).join(", ");
    }

    private toDomainModel(viewModel: CargoAllowedRatesViewModel): CargoAllowedRate {
        return <CargoAllowedRate>{
            id: viewModel.cargoAllowedRateId,
            cargoId: viewModel.cargoId,
            loadCargoRateActivity: {
                cpRate: viewModel.cpLoadRate.value,
                cpRateUnit: this.cpRateUnits.find((u) => u.name === viewModel.cpLoadRate.unit),
                extraHours: viewModel.loadExtraHours,
                reversibleLaytimeType: viewModel.loadReversibleLaytimeType
            },
            dischargeCargoRateActivity: {
                cpRate: viewModel.cpDischargeRate.value,
                cpRateUnit: this.cpRateUnits.find((u) => u.name === viewModel.cpDischargeRate.unit),
                extraHours: viewModel.dischargeExtraHours,
                reversibleLaytimeType: viewModel.dischargeReversibleLaytimeType
            }
        };
    }

    private toViewModel(domainModel: CargoAllowedRate): CargoAllowedRatesViewModel {
        const cargo = this.cargoes.find((c) => c.id === domainModel.cargoId);

        return <CargoAllowedRatesViewModel>{
            cargoAllowedRateId: domainModel.id,
            cargoId: domainModel.cargoId,
            cargoProductName: getCargoName(cargo) || "",
            loadLocation: this.getLocationsForCargo(domainModel.cargoId, CargoBerthActivityType.Load),
            cpLoadRate: domainModel.loadCargoRateActivity
                ? <QuantityModel>{ value: domainModel.loadCargoRateActivity.cpRate, unit: domainModel.loadCargoRateActivity.cpRateUnit?.name }
                : null,
            loadReversibleLaytimeType: domainModel.loadCargoRateActivity ? domainModel.loadCargoRateActivity.reversibleLaytimeType : null,
            loadExtraHours: domainModel.loadCargoRateActivity ? domainModel.loadCargoRateActivity.extraHours : null,
            dischargeLocation: this.getLocationsForCargo(domainModel.cargoId, CargoBerthActivityType.Discharge),
            cpDischargeRate: domainModel.dischargeCargoRateActivity
                ? <QuantityModel>{ value: domainModel.dischargeCargoRateActivity.cpRate, unit: domainModel.dischargeCargoRateActivity.cpRateUnit?.name }
                : null,
            dischargeReversibleLaytimeType: domainModel.loadCargoRateActivity ? domainModel.dischargeCargoRateActivity.reversibleLaytimeType : null,
            dischargeExtraHours: domainModel.dischargeCargoRateActivity ? domainModel.dischargeCargoRateActivity.extraHours : null
        };
    }
}
