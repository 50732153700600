import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaustMap, switchMap, withLatestFrom } from "rxjs/operators";

import { selectCurrentUser } from "@ops/state";

import { selectCurrentFixture } from "../../../../fixture/state";
import { LaytimeCalculationHttpService, toCreateLaytimeCalculation, toLaytimeCalculation } from "../../../services";
import { createLtcId, initialLaytimeCalculationState, LaytimeCalculation, LtcFeatureState, LtcId, LtcState, toUser } from "../../model";
import { calculationStateReducer } from "../reducer";
import { navigateToLaytimeCalculationAction } from "../router";
import { selectCurrentCalculationId } from "../selectors";

const ADD_LAYTIME_CALCULATION_ACTION_NAME = "[Laytime Calculation List] Add Laytime Calculation";
export const createLaytimeCalculationAction = createAction(ADD_LAYTIME_CALCULATION_ACTION_NAME);
export const createLaytimeCalculationSuccessAction = createAction(`${ADD_LAYTIME_CALCULATION_ACTION_NAME} Success`, props<{ calculation: LaytimeCalculation }>());
export const createLaytimeCalculationFailAction = createAction(`${ADD_LAYTIME_CALCULATION_ACTION_NAME} Fail`, props<{ ltcId: LtcId; error: Error }>());

/* REDUCERS */
export const createLaytimeCalculationReducer: On<LtcState> = on(createLaytimeCalculationAction, (state) => {
    const ltcId = createLtcId();
    return {
        ...state,
        ...calculationStateReducer(state, ltcId, { calculationPersistenceStatus: "persisting" }),
        currentCalculationId: ltcId
    };
});

export const createLaytimeCalculationSuccessReducer: On<LtcState> = on(createLaytimeCalculationSuccessAction, (state, { calculation }) =>
    calculationStateReducer(state, calculation.id, { ...initialLaytimeCalculationState(calculation), calculationPersistenceStatus: "persisted" })
);

export const createLaytimeCalculationFailReducer: On<LtcState> = on(createLaytimeCalculationFailAction, (state, { ltcId, error }) =>
    calculationStateReducer(state, ltcId, { calculationPersistenceStatus: "failed", calculationPersistenceError: error })
);

/* EFFECTS */
export const createLaytimeCalculationEffect$ = (actions$: Actions, store: Store<LtcFeatureState>, laytimeCalculationHttpService: LaytimeCalculationHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(createLaytimeCalculationAction),
            withLatestFrom(store.select(selectCurrentCalculationId), store.select(selectCurrentFixture), store.select(selectCurrentUser)),
            exhaustMap(([, ltcId, fixture, appUser]) => {
                const createLaytimeCalculation = toCreateLaytimeCalculation(ltcId, fixture);
                return laytimeCalculationHttpService.createLaytimeCalculation(createLaytimeCalculation).pipe(
                    switchMap(() => [
                        createLaytimeCalculationSuccessAction({ calculation: toLaytimeCalculation(createLaytimeCalculation, toUser(appUser)) }),
                        navigateToLaytimeCalculationAction({ ltcId })
                    ]),
                    catchError((error) => of(createLaytimeCalculationFailAction({ ltcId, error })))
                );
            })
        )
    );
