import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/core";
import { DemurrageItem } from "../shared/models";

import { CounterClaimDemurrageItemModel } from "../shared/models/dtos/counter-claim-demurrage-item.dto";
import { DemurrageItemRequestModel } from "../shared/models/dtos/demurrage-item-request-model.dto";

@Injectable({
    providedIn: "root"
})
export class DemurrageItemHttpService {
    private demurrageItemsServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.demurrageItemsServiceUrl = appConfigService.config.demurrageServiceUrl;
    }

    get(fixtureId: string): Observable<DemurrageItem[]> {
        return this.httpClient.get<DemurrageItem[]>(`${this.demurrageItemsServiceUrl}/api/v1.0/fixture/${fixtureId}/demurrage`);
    }

    create(fixtureId: string, demurrageItem: DemurrageItem): Observable<DemurrageItem> {
        return this.httpClient.post<DemurrageItem>(`${this.demurrageItemsServiceUrl}/api/v1.0/fixture/${fixtureId}/demurrage`, demurrageItem);
    }

    update(fixtureId: string, demurrageItem: DemurrageItem): Observable<any> {
        return this.httpClient.put(`${this.demurrageItemsServiceUrl}/api/v1.0/fixture/${fixtureId}/demurrage/${demurrageItem.demurrageItemId}`, demurrageItem);
    }

    submit(fixtureId: string, demurrageItemId: string, submitRequest: DemurrageItemRequestModel): Observable<any> {
        return this.httpClient.put(`${this.demurrageItemsServiceUrl}/api/v1.0/fixture/${fixtureId}/demurrage/${demurrageItemId}/submit`, submitRequest);
    }

    counter(fixtureId: string, demurrageItemId: string, counterRequest: CounterClaimDemurrageItemModel): Observable<any> {
        return this.httpClient.put(`${this.demurrageItemsServiceUrl}/api/v1.0/fixture/${fixtureId}/demurrage/${demurrageItemId}/counter`, counterRequest);
    }

    accept(fixtureId: string, demurrageItemId: string, counterRequest: DemurrageItemRequestModel): Observable<any> {
        return this.httpClient.put(`${this.demurrageItemsServiceUrl}/api/v1.0/fixture/${fixtureId}/demurrage/${demurrageItemId}/accept`, counterRequest);
    }

    withdraw(fixtureId: string, demurrageItemId: string, withdrawRequest: DemurrageItemRequestModel): Observable<any> {
        return this.httpClient.put(`${this.demurrageItemsServiceUrl}/api/v1.0/fixture/${fixtureId}/demurrage/${demurrageItemId}/withdraw`, withdrawRequest);
    }

    delete(fixtureId: string, demurrageItemId: string): Observable<any> {
        return this.httpClient.delete(`${this.demurrageItemsServiceUrl}/api/v1.0/fixture/${fixtureId}/demurrage/${demurrageItemId}`);
    }
}
