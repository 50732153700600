import { Directive, ElementRef } from "@angular/core";
import { NgSelectComponent } from "@ng-select/ng-select";

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: "ng-select" })
export class NgrxNgSelectFocusDirective {
    constructor(private el: ElementRef, private selectElement: NgSelectComponent) {
        this.el.nativeElement.focus = () => setTimeout(() => this.selectElement.focus());
    }
}
