import { Injectable } from "@angular/core";
import { DateTime } from "luxon";

import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { Destination, Division, Fixture, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class EtaTimeBetweenPortsRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();
        const daysDifferenceAllowed = 50;

        voyages.forEach((voyage) =>
            voyage.destinations.forEach((destination, destinationIndex) => {
                if (destinationIndex > 0) {
                    const previousDestination = voyage.destinations[destinationIndex - 1];
                    const previousDestinationTimezone = previousDestination.location && previousDestination.location.timeZone ? previousDestination.location.timeZone : "utc";
                    const previousEta =
                        previousDestination.etaRange && previousDestination.etaRange.to && DateTime.fromISO(previousDestination.etaRange.to, { zone: previousDestinationTimezone });
                    const currentDestinationTimezone = destination.location && destination.location.timeZone ? destination.location.timeZone : "utc";
                    const currentEta = destination.etaRange && destination.etaRange.to && DateTime.fromISO(destination.etaRange.to, { zone: currentDestinationTimezone });
                    if (previousEta && currentEta && currentEta.diff(previousEta, "days").days >= daysDifferenceAllowed) {
                        const path = [...this.getVoyagePathSegment(fixture.fixtureType, voyage)];

                        if (fixture.division.id === Division.specialisedProducts) {
                            const cargoIdsOnDestinationWithType = destination.berths
                                .map((b) =>
                                    b.cargoBerthActivities
                                        .filter((a) => a.type && (a.type.id === CargoBerthActivityType.Load.id || a.type.id === CargoBerthActivityType.Discharge.id))
                                        .map((a) => a.associatedCargoes.map((c) => ({ cargoId: c.cargoId, type: a.type })))
                                )
                                .reduce((a, b) => a.concat(b), [])
                                .reduce((a, b) => a.concat(b), [])
                                .filter((a) => a !== undefined);

                            const cargoToWarn = voyage.cargoes.find((c) => cargoIdsOnDestinationWithType.map((i) => i.cargoId).indexOf(c.id) > -1);

                            if (cargoToWarn) {
                                const cargoToWarnIndex = voyage.cargoes.indexOf(cargoToWarn);
                                const type = cargoIdsOnDestinationWithType.find((i) => i.cargoId === cargoToWarn.id).type;
                                path.push(
                                    FixtureWarningPathSegment.fromProperty<Voyage>("cargoes", "Cargoes"),
                                    FixtureWarningPathSegment.fromIndex(
                                        cargoToWarnIndex,
                                        "Cargo",
                                        cargoToWarn.cargoProduct && cargoToWarn.cargoProduct.name ? cargoToWarn.cargoProduct.name : null
                                    ),
                                    new FixtureWarningPathSegment(`${type.name.toLowerCase()}Location`, type.name)
                                );
                            }
                        } else {
                            path.push(
                                FixtureWarningPathSegment.fromProperty<Voyage>("destinations", "Locations"),
                                FixtureWarningPathSegment.fromIndex(
                                    destinationIndex,
                                    "Location",
                                    destination.location && destination.location.displayName ? destination.location.displayName : null
                                ),
                                FixtureWarningPathSegment.fromProperty<Destination>("location", null)
                            );
                        }

                        const message = `The ETA is ${daysDifferenceAllowed} days or more after the previous port`;
                        const warningHash = new WarningHashBuilder()
                            .withVoyage(voyage.voyageId)
                            .withDestination(destination.destinationId)
                            .withCategory("eta")
                            .withMessage(message)
                            .build();
                        warnings.push(new FixtureWarning("eta", message, path, dismissedWarningRecord(warningHash)));
                    }
                }
            })
        );

        return warnings;
    }
}
