<ng-container *ngIf="berths; else noBerths">
    <div *ngFor="let berth of berths; first as isFirst; last as isLast; trackBy: trackBy; let i = index" class="row berth-row">
        <div class="card">
            <ops-berth-header
                [summary]="berth.summary"
                [expanded]="berth.expanded"
                [canOrder]="!readonly && berth.canOrder"
                [canClone]="!readonly && berth.canCloneOrRemove"
                [canRemove]="!readonly && berth.canCloneOrRemove"
                [moveUpEnabled]="!isFirst"
                [moveDownEnabled]="!isLast"
                [cloneEnabled]="berth.canCloneOrRemove"
                [removeEnabled]="berth.canCloneOrRemove"
                (moveUp)="moveUp.emit(berth.berthId)"
                (moveDown)="moveDown.emit(berth.berthId)"
                (clone)="clone.emit(berth.berthId)"
                (remove)="remove.emit(berth.berthId)"
                (expand)="expand.emit(berth.berthId)"
                (collapse)="collapse.emit(berth.berthId)"
            >
            </ops-berth-header>

            <ops-berth-shell *ngIf="berth.expanded" [berthId]="berth.berthId" [destinationId]="berth.destinationId" [readonly]="readonly"> </ops-berth-shell>
        </div>
    </div>
</ng-container>
<div class="d-flex flex-row-reverse add-berth-button-container" *ngIf="canAdd">
    <button type="button"
            class="btn btn-primary align-bottom mr-0"
            [disabled]="readonly"
            (click)="add.emit()"
            warningActive
            ngrxFormControlId="berths"
            ngrxFormControlIdMatch="endsWith"
            [focusOnActive]="true"
            data-test-add-berth-button>
        <span class="has-icon icon--add-circle-outline"></span>
        <span>BERTH</span>
    </button>
</div>
<ng-template #noBerths>
    <ops-no-data-panel
        #panel="noDataPanel"
        heading="Berths"
        title="No berths are currently added"
        description="Select the add button to add berth entry"
        [useNewButton]="true"
        newButtonText="BERTH"
        [disabled]="readonly"
        (addNew)="add.emit()"
        warningActive
        ngrxFormControlId="berths"
        ngrxFormControlIdMatch="endsWith"
        (warningActivated)="panel.focusNewButton()">
    </ops-no-data-panel>
</ng-template>
