import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, on, On, props, Store } from "@ngrx/store";
import { FormGroupState, NgrxFormControlId, setValue, SetValueAction, StateUpdateFns, updateArrayWithFilter, updateGroup } from "ngrx-forms";
import * as R from "ramda";
import { Evolver } from "ramda";
import { of } from "rxjs";
import { catchError, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";

import { roundAndStringifyNumber, update } from "@ops/shared";
import { hasValue } from "@ops/state";

import { isNullOrUndefined } from "../../../../../../shared/utils";
import { LaytimeCalculationHttpService } from "../../../../../services";
import {
    ActivityLocation,
    ActivityLocationId,
    activityLocationLaytimeEventsFormKey,
    idEq,
    LaytimeEvent,
    LaytimeEventForm,
    LaytimeEventId,
    LtcFeatureState,
    LtcId,
    LtcState,
    toLaytimeEvent
} from "../../../../model";
import { getDiff, getLaytimeEventPercentage, hasExclusion, isLaytimeEventAfterDemurrageEvent } from "../../../../model/calculations/utils";
import { calculationStateReducer } from "../../../reducer";
import { selectCurrentLaytimeCalculation } from "../../../selectors";
import { UpdateQueue } from "../../../update-queue";
import { selectCurrentActivityLocation } from "../../selectors";
import { selectCurrentLaytimeEvents } from "../selectors";
import { orderLaytimeEventsUpdatePercentageAction } from "./order-laytime-events";

/* ACTIONS */
const UPDATE_ACTION_NAME = "[LTC Laytime Events Form] Update Laytime Event";
export const updateLaytimeEventAction = createAction(
    UPDATE_ACTION_NAME,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; laytimeEventId: LaytimeEventId; laytimeEvent: Partial<LaytimeEvent> }>()
);
export const processedPercentageLaytimeEventUpdateAction = createAction(
    "[LTC Laytime Events Form] Processed Percentage Laytime Event Update",
    props<{ ltcId: LtcId; activityLocation: ActivityLocation; laytimeEvent: LaytimeEvent; form: FormGroupState<LaytimeEventForm>; eventIndex: number }>()
);
export const updateLaytimeEventFormAction = createAction(
    "[LTC Laytime Events Form] Update Laytime Event Form",
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; laytimeEventId: LaytimeEventId; laytimeEvent: Partial<LaytimeEvent> }>()
);
export const updateLaytimeEventSuccessAction = createAction(
    `${UPDATE_ACTION_NAME} Success`,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; laytimeEventId: LaytimeEventId; laytimeEvent: Partial<LaytimeEvent> }>()
);
export const updateLaytimeEventFailAction = createAction(
    `${UPDATE_ACTION_NAME} Fail`,
    props<{ ltcId: LtcId; activityLocationId: ActivityLocationId; laytimeEventId: LaytimeEventId; error: Error }>()
);
export const updateEventFormPercentageAction = createAction(`${UPDATE_ACTION_NAME} Form Percentage`, props<{ controlId: NgrxFormControlId }>());

/* REDUCERS */
export const processedLaytimeEventUpdateReducer: On<LtcState> = on(processedPercentageLaytimeEventUpdateAction, (state, { ltcId, activityLocation, form, eventIndex }) =>
    calculationStateReducer(state, ltcId, (ltcState) => {
        if (ltcState.currentActivityLocationId !== activityLocation.id) {
            return ltcState;
        }

        const laytimeEventForms = updateArrayWithFilter<LaytimeEventForm>(
            (_, i) => i === eventIndex,
            updateGroup<LaytimeEventForm>({ percentage: setValue(form.value.percentage) })
        )(ltcState.laytimeEventForms);

        return { ...ltcState, laytimeEventForms };
    })
);

export const updateLaytimeEventReducer: On<LtcState> = on(updateLaytimeEventAction, (state, { laytimeEvent, laytimeEventId, activityLocationId, ltcId }) => {
    const updateFns: Evolver = {
        calculation: { activityLocations: update(idEq(activityLocationId), R.evolve({ laytimeEvents: update(idEq(laytimeEventId), R.mergeDeepLeft(laytimeEvent)) })) }
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

export const updateLaytimeEventFormReducer: On<LtcState> = on(updateLaytimeEventFormAction, (state, { ltcId, laytimeEventId, laytimeEvent }) => {
    const updateFormFns: StateUpdateFns<LaytimeEventForm> = Object.fromEntries(Object.entries(laytimeEvent).map(([key, value]) => [key, setValue(value)]));
    const updateFns: Evolver = {
        laytimeEventForms: updateArrayWithFilter((form) => form.value.id === laytimeEventId, updateGroup<LaytimeEventForm>(updateFormFns))
    };
    return calculationStateReducer(state, ltcId, R.evolve(updateFns));
});

/* EFFECTS */
export const processLaytimeEventPercentageEffect$ = (actions$: Actions, store: Store<LtcFeatureState>) =>
    createEffect(() =>
        actions$.pipe(
            ofType(SetValueAction.TYPE, orderLaytimeEventsUpdatePercentageAction, updateEventFormPercentageAction),
            filter(({ controlId }) => controlId.split(".")[1] === activityLocationLaytimeEventsFormKey),
            withLatestFrom(store.select(selectCurrentLaytimeCalculation), store.select(selectCurrentActivityLocation), store.select(selectCurrentLaytimeEvents)),
            map(([{ controlId, type }, calculation, activityLocation, laytimeEvents]) => {
                const [, , eventIndexString, control] = controlId.split(".");
                const eventIndex = +eventIndexString;
                let form = laytimeEvents[eventIndex].form;
                const previousForm = laytimeEvents[eventIndex - 1]?.form;
                const laytimeEvent = activityLocation.laytimeEvents.find((c) => c.id === form.value.id);

                if (hasExclusion(activityLocation) && (["date", "type"].includes(control) || SetValueAction.TYPE !== (type as string))) {
                    let percentage = isLaytimeEventAfterDemurrageEvent(calculation, activityLocation, laytimeEvent) ? 100 : null;

                    const previousDate = eventIndex === 0 ? form.value.date : previousForm.value.date;
                    if (!hasValue(percentage) && hasValue(previousDate) && hasValue(form.value.date)) {
                        percentage = getLaytimeEventPercentage(form.value.type, previousDate, form.value.date, activityLocation);
                    }

                    form = updateGroup(form, { percentage: setValue(roundAndStringifyNumber(percentage, 2)) });
                }

                if (isNullOrUndefined(form.value.percentage) || (eventIndex === 0 && !hasExclusion(activityLocation))) {
                    form = updateGroup(form, { percentage: setValue("100") });
                }

                return processedPercentageLaytimeEventUpdateAction({ ltcId: calculation.id, activityLocation, laytimeEvent, form, eventIndex });
            })
        )
    );

export const processedPercentageLaytimeEventUpdateEffect$ = (actions$: Actions, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(processedPercentageLaytimeEventUpdateAction),
            filter(({ form }) => form.isValid),
            map(({ ltcId, activityLocation, laytimeEvent, form }) =>
                updateLaytimeEventAction({
                    ltcId,
                    activityLocationId: activityLocation.id,
                    laytimeEventId: laytimeEvent.id,
                    laytimeEvent: getDiff(laytimeEvent, toLaytimeEvent(form.value, activityLocation.cargoes))
                })
            ),
            tap((action) =>
                updateQueue.enqueue(action, updateLaytimeEvent, (latest) =>
                    action.laytimeEventId === latest.laytimeEventId ? (R.mergeDeepLeft(action, latest) as ReturnType<typeof updateLaytimeEventAction>) : [latest, action]
                )
            )
        )
    );

export const updateLaytimeEventFormEffect$ = (actions$: Actions, updateQueue: UpdateQueue) =>
    createEffect(() =>
        actions$.pipe(
            ofType(updateLaytimeEventFormAction),
            switchMap(({ ltcId, activityLocationId, laytimeEventId, laytimeEvent }) => [updateLaytimeEventAction({ ltcId, activityLocationId, laytimeEventId, laytimeEvent })]),
            tap((action) => updateQueue.enqueue(action, updateLaytimeEvent))
        )
    );

export const updateLaytimeEvent = (
    { ltcId, activityLocationId, laytimeEventId, laytimeEvent }: ReturnType<typeof updateLaytimeEventAction>,
    laytimeCalculationHttpService: LaytimeCalculationHttpService
) =>
    laytimeCalculationHttpService.updateLaytimeEvent(ltcId, activityLocationId, laytimeEventId, laytimeEvent).pipe(
        map(() => updateLaytimeEventSuccessAction({ ltcId, activityLocationId, laytimeEventId, laytimeEvent })),
        catchError((error) => of(updateLaytimeEventFailAction({ ltcId, activityLocationId, laytimeEventId, error })))
    );
