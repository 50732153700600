import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AppConfigService } from "src/app/core";
import { FixtureCreation } from "src/app/left-bar/create-fixture/shared/create-fixture.dto";
import { deepCopy } from "src/app/shared/utils";
import { FixtureMapper } from "../fixture.mapper";
import { Fixture } from "../shared/models";

@Injectable({
    providedIn: "root"
})
export class FixtureHttpService {
    private readonly fixtureServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService, private fixtureMapper: FixtureMapper) {
        this.fixtureServiceUrl = appConfigService.config.fixtureServiceUrl;
    }

    get(fixtureId: string): Observable<Fixture> {
        return this.httpClient.get<Fixture>(`${this.fixtureServiceUrl}/api/v1.0/fixture/${fixtureId}`).pipe(map((fixture) => this.fixtureMapper.updateFixture(fixture)));
    }

    save(fixture: Fixture, unlock: boolean, resetDemurrageStatus = false): Observable<Fixture> {
        this.fixtureMapper.removeEmptyNotices(fixture);

        // Saves demurrage status as null if not changed. This is to sort out concurrency issue, when demurrage status is automatically set to 'Closed' when a Demurrage invoice is imported
        // When demurrage status is submitted as null, fixture service knows not to override the currently saved value
        const fixtureCopy = <Fixture>deepCopy(fixture);
        if (resetDemurrageStatus) {
            fixtureCopy.demurrage.status = null;
        }

        return this.httpClient.put(`${this.fixtureServiceUrl}/api/v2.0/fixture/${fixture.fixtureId}?unlock=${unlock.toString()}`, fixtureCopy).pipe(map((_) => fixture));
    }

    create(fixture: FixtureCreation): Observable<Object> {
        return this.httpClient.post(`${this.fixtureServiceUrl}/api/v2.0/fixture`, fixture);
    }

    lock(fixtureId: string): Observable<string> {
        return this.httpClient.put(`${this.fixtureServiceUrl}/api/v1.0/fixture/${fixtureId}/lock`, {}).pipe(map((_) => fixtureId));
    }

    unlock(fixtureId: string): Observable<string> {
        return this.httpClient.put(`${this.fixtureServiceUrl}/api/v1.0/fixture/${fixtureId}/unlock`, {}).pipe(map((_) => fixtureId));
    }

    updateLastContacted(fixtureId: string): Observable<string> {
        return this.httpClient.put<string>(`${this.fixtureServiceUrl}/api/v1.0/fixture/${fixtureId}/demurrage/claimNotification/updateLastContacted`, {});
    }

    updateDemurageClaimLastContacted(fixtureId: string, demurrageId: number): Observable<string> {
        return this.httpClient.put<string>(`${this.fixtureServiceUrl}/api/v1.0/fixture/${fixtureId}/demurrage/${demurrageId}/demurrageclaim/updateLastContacted`, {});
    }

    clone(fixtureId: string): Observable<string> {
        return this.httpClient.post<string>(`${this.fixtureServiceUrl}/api/v2.0/fixture/${fixtureId}/clone`, {});
    }

    delete(fixtureId: string): Observable<any> {
        return this.httpClient.delete(`${this.fixtureServiceUrl}/api/v1.0/fixture/${fixtureId}`);
    }
}
