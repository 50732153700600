import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { WorksheetFeatureState } from "@ops/state";

import { removeCargoEffect$ } from "./cargo/form/remove-cargo";
import { saveCargoEffect$ } from "./cargo/form/save-cargo";
import { addLiftingEffect$, showNewLiftingEffect$ } from "./coa/add-lifting";
import { routerLoadCoaEffect$, routerLoadCoaLiftingsEffect$, routerLoadLatestCompletedVoyageEffect$, routerLoadPlannedLiftingEffect$ } from "./coa/routing";
import { addCompanyEffect$, addCompanyOnCompanyChangeEffect$ } from "./company/form/add-company";
import { removeCompanyEffect$ } from "./company/form/remove-company";
import { searchEffect$, updateCurrentWorksheetEffect$ } from "./grid/search";
import { updateSuggestionTermEffect$ } from "./grid/update-suggestion-term";
import { saveCoaHeaderEffect$ } from "./header/form/save-header";
import { cancelLiftingEffect$ } from "./lifting";
import { cargoPlanEmailAuditEffect$, cargoPlanEmailEffect$ } from "./lifting/cargo/cargo-plan-email";
import { saveCargoPlanStatusEffect$, saveLaycanEffect$, savingCargoPlanStatusEffect$, savingLaycanEffect$ } from "./lifting/cargo/form/edit-laycan";
import { removeLiftingCargoEffect$, removingLiftingCargoEffect$ } from "./lifting/cargo/form/remove-cargo";
import { saveLiftingCargoEffect$, savingLiftingCargoEffect$ } from "./lifting/cargo/form/save-cargo";
import { cloneLiftingEffect$, cloneLiftingSuccessEffect$ } from "./lifting/clone-lifting";
import { addLiftingCompanyEffect$, addLiftingCompanyOnCompanyChangeEffect$ } from "./lifting/company/form/add-company";
import { removeLiftingCompanyEffect$ } from "./lifting/company/form/remove-company";
import { createFixtureEffect$ } from "./lifting/create-fixture";
import { editLiftingNominationTaskActionEffect$ } from "./lifting/lifting-actions/edit-lifting-actions";
import { routerLoadCargoNominationHistoryEffect$, routerLoadLiftingEffect$, routerLoadLiftingSuccessEffect$, routerLoadVesselNominationHistoryEffect$ } from "./lifting/routing";
import { changeLiftingSummaryCommentTextEffect$ } from "./lifting/summary-comments/form/edit-summary-comments";
import {
    liftingSummaryCommentsImportanceChangeEffect$,
    saveLiftingSummaryCommentsEffect$,
    saveLiftingSummaryCommentsImportanceEffect$
} from "./lifting/summary-comments/form/save-summary-comments";
import { removeVesselNominationEffect$ } from "./lifting/vessel-nomination/form/remove-vessel-nomination";
import { renominateNewVesselEffect$, saveVesselNominationEffect$ } from "./lifting/vessel-nomination/form/save-vessel-nomination";
import {
    acceptVesselNominationEffect$,
    preferVesselNominationEffect$,
    rejectVesselNominationEffect$,
    renominateVesselEffect$,
    reviewVesselNominationEffect$
} from "./lifting/vessel-nomination/update-status";
import { saveLiftingHeaderEffect$ } from "./lifting-header/form/save-header";
import { addLocationEffect$ } from "./locations/form/add-location";
import { removeLocationEffect$ } from "./locations/form/remove-location";
import { CoaFeatureState } from "./model";
import { nominationTaskFormChangeEffect$ } from "./nomination-tasks/form/edit-nomination-task";
import { removeNominationTaskEffect$ } from "./nomination-tasks/form/remove-nomination-task";
import { saveNominationTaskEffect$ } from "./nomination-tasks/form/save-nomination-task";
import { ActionDataService } from "../../left-bar/actions/services/action-data.service";
import { LeftBarStateService } from "../../left-bar/left-bar-state.service";
import { WorksheetLocalStorageService } from "../../left-bar/worksheets/service/worksheet-local-storage.service";
import { EmailPreviewService } from "../../shared/email";
import { CoaHttpService, LiftingHttpService } from "../services";

@Injectable()
export class CoaEffects {
    loadCoa$ = routerLoadCoaEffect$(this.actions$, this.coaHttpService);

    routerLoadLatestCompletedVoyage$ = routerLoadLatestCompletedVoyageEffect$(this.actions$, this.coaHttpService);

    routerLoadEarliestPlannedLifting = routerLoadPlannedLiftingEffect$(this.actions$, this.liftingHttpService);

    routerLoadCoaLiftings$ = routerLoadCoaLiftingsEffect$(this.actions$, this.liftingHttpService);

    saveCoaHeader$ = saveCoaHeaderEffect$(this.actions$, this.store, this.coaHttpService);

    addLocation$ = addLocationEffect$(this.actions$, this.store, this.coaHttpService);

    removeLocation$ = removeLocationEffect$(this.actions$, this.store, this.coaHttpService);

    saveCargo$ = saveCargoEffect$(this.actions$, this.store, this.coaHttpService);

    removeCargo$ = removeCargoEffect$(this.actions$, this.store, this.coaHttpService);

    saveNominationTask$ = saveNominationTaskEffect$(this.actions$, this.store, this.coaHttpService);

    removeNominationTask$ = removeNominationTaskEffect$(this.actions$, this.store, this.coaHttpService);

    loadLifting$ = routerLoadLiftingEffect$(this.actions$, this.store, this.liftingHttpService);

    routerLoadLiftingSuccess$ = routerLoadLiftingSuccessEffect$(this.actions$, this.store, this.liftingHttpService);

    saveLiftingHeader$ = saveLiftingHeaderEffect$(this.actions$, this.store, this.liftingHttpService);

    addLifting$ = addLiftingEffect$(this.actions$, this.store, this.liftingHttpService);

    showNewLifting$ = showNewLiftingEffect$(this.actions$, this.router);

    addCompanyOnCompanyChange$ = addCompanyOnCompanyChangeEffect$(this.actions$, this.store);

    addLiftingCompanyOnCompanyChange$ = addLiftingCompanyOnCompanyChangeEffect$(this.actions$, this.store);

    addCompany$ = addCompanyEffect$(this.actions$, this.store, this.coaHttpService);

    addLiftingCompany$ = addLiftingCompanyEffect$(this.actions$, this.store, this.liftingHttpService);

    removeCompany$ = removeCompanyEffect$(this.actions$, this.store, this.coaHttpService);

    removeLiftingCompany$ = removeLiftingCompanyEffect$(this.actions$, this.store, this.liftingHttpService);

    updateSuggestionTerm$ = updateSuggestionTermEffect$(this.actions$, this.coaHttpService);

    search$ = searchEffect$(this.actions$, this.store, this.coaHttpService);

    saveVesselNomination$ = saveVesselNominationEffect$(this.actions$, this.store, this.liftingHttpService);

    removeVesselNomination$ = removeVesselNominationEffect$(this.actions$, this.store, this.liftingHttpService);

    saveLaycan$ = saveLaycanEffect$(this.actions$, this.store, this.liftingHttpService);

    saveCargoPlanStatus$ = saveCargoPlanStatusEffect$(this.actions$, this.store, this.liftingHttpService);

    saveLiftingCargo$ = saveLiftingCargoEffect$(this.actions$, this.store, this.liftingHttpService);

    removeLiftingCargo$ = removeLiftingCargoEffect$(this.actions$, this.store, this.liftingHttpService);

    acceptVesselNomination$ = acceptVesselNominationEffect$(this.actions$, this.store, this.liftingHttpService);

    rejectVesselNomination$ = rejectVesselNominationEffect$(this.actions$, this.store, this.liftingHttpService);

    reviewVesselNomination$ = reviewVesselNominationEffect$(this.actions$, this.store, this.liftingHttpService);

    preferVesselNomination$ = preferVesselNominationEffect$(this.actions$, this.store, this.liftingHttpService);

    nominationTaskFormChange$ = nominationTaskFormChangeEffect$(this.actions$);

    savingLaycan$ = savingLaycanEffect$(this.actions$, this.store, this.dialog);

    removingLiftingCargo$ = removingLiftingCargoEffect$(this.actions$, this.store, this.dialog);

    savingLiftingCargo$ = savingLiftingCargoEffect$(this.actions$, this.store, this.dialog);

    savingCargoPlanStatus$ = savingCargoPlanStatusEffect$(this.actions$, this.store);

    renominateVessel$ = renominateVesselEffect$(this.actions$, this.store, this.liftingHttpService);

    renominateNewVessel$ = renominateNewVesselEffect$(this.actions$, this.store, this.liftingHttpService);

    createFixture$ = createFixtureEffect$(this.actions$, this.store, this.liftingHttpService);

    routerLoadVesselNominationHistory$ = routerLoadVesselNominationHistoryEffect$(this.actions$, this.store, this.liftingHttpService);

    routerLoadCargoNominationHistory$ = routerLoadCargoNominationHistoryEffect$(this.actions$, this.store, this.liftingHttpService);

    changeLiftingSummaryCommentText$ = changeLiftingSummaryCommentTextEffect$(this.actions$, this.store);

    saveLiftingSummaryComments$ = saveLiftingSummaryCommentsEffect$(this.actions$, this.store, this.liftingHttpService);

    liftingSummaryCommentsImportanceChange$ = liftingSummaryCommentsImportanceChangeEffect$(this.actions$, this.store);

    saveLiftingSummaryCommentsImportance$ = saveLiftingSummaryCommentsImportanceEffect$(this.actions$, this.liftingHttpService);

    cancelLifting$ = cancelLiftingEffect$(this.actions$, this.store, this.liftingHttpService);

    cloneLifting$ = cloneLiftingEffect$(this.actions$, this.store, this.liftingHttpService);

    cloneLiftingSuccess$ = cloneLiftingSuccessEffect$(this.actions$, this.router);

    editLiftingNominationTaskAction$ = editLiftingNominationTaskActionEffect$(this.actions$, this.leftBarStateService, this.actionDataService);

    emailCargoPlan$ = cargoPlanEmailEffect$(this.actions$, this.store, this.emailPreviewer);

    auditEmailCargoPlan$ = cargoPlanEmailAuditEffect$(this.actions$, this.store, this.liftingHttpService);

    updateCurrentWorksheet$ = updateCurrentWorksheetEffect$(this.actions$, this.worksheetStore, this.worksheetLocalStorage);

    constructor(
        private actions$: Actions,
        private store: Store<CoaFeatureState>,
        private coaHttpService: CoaHttpService,
        private liftingHttpService: LiftingHttpService,
        private router: Router,
        private dialog: MatDialog,
        private actionDataService: ActionDataService,
        private leftBarStateService: LeftBarStateService,
        private emailPreviewer: EmailPreviewService,
        private worksheetStore: Store<WorksheetFeatureState>,
        private worksheetLocalStorage: WorksheetLocalStorageService
    ) {}
}
