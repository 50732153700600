import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidatorFn } from "@angular/forms";

import { SharingGroupsViewModel } from "../../shared/models/sharing-group.model";

export class SharingGroupsValidator {
    static validate(): ValidatorFn {
        return (control: AbstractControl): null => {
            const sharingGroupsFormArray = control.get("sharingGroups") as UntypedFormArray;

            if (sharingGroupsFormArray) {
                const sharingGroups = sharingGroupsFormArray.controls.map((formGroup: UntypedFormGroup) => formGroup.value as SharingGroupsViewModel);

                // At least one of your sharing groups must be selected
                if (!sharingGroups.some((x) => x.isSelected && x.isCurrentUserPresent)) {
                    sharingGroupsFormArray.setErrors({ required: true });
                }
            }

            return null;
        };
    }
}
