import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, On, on, props, Store } from "@ngrx/store";
import * as R from "ramda";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";

import { selectCurrentUser } from "@ops/state";

import { formToUpdateLiftingDetails, LiftingHttpService } from "../../../services/lifting-http.service";
import { selectCurrentLifting } from "../../lifting";
import { currentLiftingStateReducer, liftingStateReducer } from "../../lifting/reducer";
import { CoaFeatureState, CoasState, Lifting, LiftingHeaderForm, LiftingId, toUser, User } from "../../model/";
import { selectCurrentLiftingHeaderFormValue } from "../selectors";

/* ACTIONS */
const SAVE_ACTION_NAME = "[Lifting Header Form] Save Lifting Header";

export const saveLiftingHeaderAction = createAction(SAVE_ACTION_NAME);
export const saveLiftingHeaderSuccessAction = createAction(`${SAVE_ACTION_NAME} Success`, props<{ liftingId: LiftingId; data: LiftingHeaderData; user: User }>());
export const saveLiftingHeaderFailAction = createAction(`${SAVE_ACTION_NAME} Fail`, props<{ liftingId: LiftingId; error: Error }>());

/* REDUCERS */
export const saveLiftingHeaderReducer: On<CoasState> = on(saveLiftingHeaderAction, (state) => currentLiftingStateReducer(state, { persistenceStatus: "persisting" }));
export const saveLiftingHeaderSuccessReducer: On<CoasState> = on(saveLiftingHeaderSuccessAction, (state, { data, liftingId }) =>
    liftingStateReducer(state, liftingId, (liftingState) => ({
        ...liftingState,
        persistenceStatus: "persisted",
        liftingHeaderForm: null,
        lifting: R.isEmpty(data) ? liftingState.lifting : { ...liftingState.lifting, ...data }
    }))
);
export const saveLiftingHeaderFailReducer: On<CoasState> = on(saveLiftingHeaderFailAction, (state, { liftingId }) =>
    liftingStateReducer(state, liftingId, { persistenceStatus: "failed" })
);

/* EFFECTS */
export type LiftingHeaderData = Partial<Pick<Lifting, "liftingNumber" | "declarationDate" | "operators">>;

export const formToLiftingHeaderData = (source: LiftingHeaderForm): LiftingHeaderData => ({
    declarationDate: source.declarationDate,
    liftingNumber: source.liftingNumber,
    operators: source.operators.value.map(toUser)
});

export const saveLiftingHeaderEffect$ = (actions$: Actions, store: Store<CoaFeatureState>, liftingHttpService: LiftingHttpService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveLiftingHeaderAction),
            withLatestFrom(store.select(selectCurrentLifting), store.select(selectCurrentLiftingHeaderFormValue), store.select(selectCurrentUser)),
            switchMap(([, { coaId, liftingId }, form, appUser]) =>
                liftingHttpService.updateDetails(coaId, liftingId, formToUpdateLiftingDetails(form)).pipe(
                    map(() => saveLiftingHeaderSuccessAction({ liftingId, data: formToLiftingHeaderData(form), user: toUser(appUser) })),
                    catchError((error) => of(saveLiftingHeaderFailAction({ liftingId, error })))
                )
            )
        )
    );
