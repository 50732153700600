import { FormArray } from "@angular/forms";
import { Command } from "src/app/fixture/mediator/commands/command";
import { PeriodFormModel } from "src/app/fixture/shared/models/form-models/period.model";
import { UpdatePeriodCommandHandler } from "./update-period.command-handler";

export class UpdatePeriodCommand extends Command {
    constructor(readonly period: PeriodFormModel) {
        super(UpdatePeriodCommandHandler, null);
    }
}
