import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { of } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from "rxjs/operators";

import { User } from "@ops/state";

import { UserSuggestionService } from "./user-suggestion.service";
import { AppConfigService } from "../../../../core/app-config.service";
import { Enumeration } from "../../../reference-data/enumeration";
import { AutosuggestComponent } from "../autosuggest.component";

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UserAutosuggestComponent),
    multi: true
};

@Component({
    selector: "ops-user-autosuggest",
    templateUrl: "./user-autosuggest.component.html",
    styleUrls: ["../autosuggest.component.scss", "./user-autosuggest.component.scss"],
    providers: [VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAutosuggestComponent extends AutosuggestComponent<User> {
    static componentName = "UserAutosuggestComponent";
    private cloudUrl: string;

    @Input() lead: User;
    @Input() returnAll: boolean;
    @Input() fixtureId: string;
    @Input() fixtureSource: Enumeration;
    @Input() disabled: boolean;
    @Input() readonly: boolean;

    constructor(
        private userSuggestionService: UserSuggestionService,
        protected appConfigService: AppConfigService,
        protected element: ElementRef,
        protected changeDetector: ChangeDetectorRef
    ) {
        super(userSuggestionService, element, changeDetector);
        this.cloudUrl = appConfigService.config.cloudUrl;
    }

    getUserImageUrl(userCode: string): string {
        return `${this.cloudUrl}/API/1_2/FileHandler/GetUserImageOrDefault?username=${userCode || ""}`;
    }

    protected buildSearchTermSubject(): void {
        this.searchTermSubject
            .pipe(
                distinctUntilChanged(),
                tap(() => this.isLoadingSubject.next(true)),
                debounceTime(200),
                switchMap((term) => (!term ? of([]) : this.userSuggestionService.getSuggestions(term, this.returnAll, this.fixtureId, this.fixtureSource))),
                takeUntil(this.destroy$)
            )
            .subscribe(
                (suggestions) => {
                    this.isLoadingSubject.next(false);
                    this.suggestionsSubject.next(suggestions);
                },
                (error) => {
                    this.isLoadingSubject.next(false);
                    throw error;
                }
            );
    }
}
