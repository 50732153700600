export class SharingGroupsCompanyViewModel {
    companyId: number;
    sharingGroups: SharingGroupsViewModel[] = [];
}

export class ConfigurationRolesFixtureViewModel {
    constructor(public fixtureId: string = null, public configurationRoles: string[] = []) {}
}

export class SharingGroupsViewModel {
    name: string;
    code: string;
    isGroupAll: boolean;
    isCurrentUserPresent: boolean;
    isSelected: boolean;
    parentCode: string;
}
