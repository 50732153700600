import { Injectable } from "@angular/core";
import { DateTime } from "luxon";

import { CargoBerthActivityType, LaytimeEventType } from "@ops/shared/reference-data";

import { CargoBerthActivity, Fixture, FixtureType, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class EtaMissingLoadEventRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();
        const hoursDifferenceAllowed = 24;
        const loadEvent = LaytimeEventType.NORTendered;

        voyages.forEach((voyage) => {
            voyage.destinations.forEach((destination, destinationIndex) =>
                destination.berths.forEach((berth, berthIndex) =>
                    berth.cargoBerthActivities.forEach((activity, activityIndex) => {
                        if (!activity.type || activity.type.id !== CargoBerthActivityType.Load.id || !activity.laytimeEvents) {
                            return;
                        }

                        if (
                            activity.laytimeEvents.every((e) => !e.type || e.type.id !== loadEvent.id) ||
                            activity.laytimeEvents.filter((e) => e.type && e.type.id === loadEvent.id && !e.eventDate).length
                        ) {
                            const destinationTimezone = destination.location && destination.location.timeZone ? destination.location.timeZone : "utc";
                            const eta = destination.etaRange && destination.etaRange.to && DateTime.fromISO(destination.etaRange.to, { zone: destinationTimezone });
                            const now = DateTime.local();

                            if (eta && now.diff(eta, "hours").hours >= hoursDifferenceAllowed) {
                                const message =
                                    "It has been longer than 24 hours since the ETA on the load port and there is no data entered for NOR Tendered. " +
                                    "Please enter the relevant data for NOR Tendered in the laytime events.";
                                const path = [
                                    ...this.getActivityPath(fixture, voyage, destinationIndex, destination, berthIndex, berth, activityIndex, activity),
                                    FixtureWarningPathSegment.fromProperty<CargoBerthActivity>("type", null)
                                ];
                                const dismissingRecord =
                                    fixture.fixtureType.id !== FixtureType.Voyage
                                        ? dismissedWarningRecord(
                                              new WarningHashBuilder()
                                                  .withVoyage(voyage.voyageId)
                                                  .withDestination(destination.destinationId)
                                                  .withBerth(berth.berthId)
                                                  .withBerthActivity(activity.berthActivityId)
                                                  .withCategory("eta")
                                                  .withMessage(message)
                                                  .build()
                                          )
                                        : null;
                                warnings.push(new FixtureWarning("eta", message, path, dismissingRecord));
                            }
                        }
                    })
                )
            );
        });

        return warnings;
    }
}
