import { AllModules, GridOptions } from "@ag-grid-enterprise/all-modules";

import { LoadingOverlayComponent } from "../loading-overlay/loading-overlay.component";

export class GridSettings {
    readonly rowModelType = "serverSide";
    readonly modules = AllModules;
    readonly loadingOverlay = "loadingOverlay";
    readonly blockLoadDebounceMillis = 70;
    readonly rowHeight = 32;
    readonly colResizeDefault = "shift";
    readonly suppressContextMenu = true;
    readonly frameworkComponents = {
        loadingOverlay: LoadingOverlayComponent
    };
    readonly cacheBlockSize = 300;
    readonly gridOptions: GridOptions = {
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressRowGroups: true,
                        suppressValues: true
                    }
                }
            ]
        },
        defaultColDef: {
            sortable: true,
            resizable: true
        },
        allowDragFromColumnsToolPanel: true,
        serverSideStoreType: "partial"
    };
}
