import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { FavoriteDataService } from "../left-bar/shared/favorite-data.service";
import { currentPanelEffect$, leftBarSetCurrentPanelEffect$ } from "./left-bar/interop";
import { AppState } from "./model";

@Injectable()
export class LeftBarEffects {
    currentPanel$ = currentPanelEffect$(this.actions$, this.store);

    leftBarSetCurrentPanelEffect$ = leftBarSetCurrentPanelEffect$(this.actions$, this.favouriteDataService);

    constructor(private actions$: Actions, private store: Store<AppState>, private favouriteDataService: FavoriteDataService) {}
}
