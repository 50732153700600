import { Directive, forwardRef, HostListener } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

/**
 * Simple directive allowing buttons to be bound to booleans.
 */
@Directive({
    selector: "button[opsFormToggle]",
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormToggleDirective), multi: true }
    ]
})
export class FormToggleDirective implements ControlValueAccessor {
    toggled: boolean;

    private _onChange = (_: boolean) => {};
    private _onTouched = () => {};

    @HostListener("click")
    toggle() {
        this.toggled = !this.toggled;

        if (this._onTouched) { this._onTouched(); }
        if (this._onChange) { this._onChange(this.toggled); }
    }

    registerOnChange(fn: (_: boolean) => {}) {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => {}) {
        this._onTouched = fn;
    }

    writeValue(obj: boolean) {
        this.toggled = obj;
    }
}
