import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroupState } from "ngrx-forms";

import { PersistenceStatus } from "@ops/state";

import { LaytimeCalculationNameForm } from "../../state";

@Component({
    selector: "ops-laytime-calculation-header",
    templateUrl: "./laytime-calculation-header.component.html",
    styleUrls: ["./laytime-calculation-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationHeaderComponent implements OnInit {
    private calculationName: string;

    showCloneNotification = false;

    @Input() form: FormGroupState<LaytimeCalculationNameForm>;
    @Input() persistenceStatus: PersistenceStatus;
    @Input() lastUpdated: string;
    @Input() isExportLoading: boolean;

    @Output() readonly cancelRename = new EventEmitter();
    @Output() readonly confirmRename = new EventEmitter();
    @Output() readonly closeClick = new EventEmitter();
    @Output() readonly cloneClick = new EventEmitter();
    @Output() readonly exportExcel = new EventEmitter();
    @Output() readonly exportPdf = new EventEmitter();

    get isNameChanged() {
        return this.calculationName !== this.form.value.name;
    }

    get showPersistingIndicator() {
        return this.persistenceStatus === "persisting";
    }

    get showPersistedIndicator() {
        return this.persistenceStatus === "persisted";
    }

    get showFailedIndicator() {
        return this.persistenceStatus === "failed";
    }

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {
        this.setCalculationName();
    }

    onKeyup(event: KeyboardEvent) {
        if (event.key === "Escape") {
            this.cancelRename.emit();
        }
    }

    updateName() {
        if (this.form.isValid && !this.form.controls.name.errors.required && this.isNameChanged) {
            this.confirmRename.emit();
            this.setCalculationName();
        }
    }

    clone() {
        this.cloneClick.emit();
        this.showNotification();
    }

    private showNotification() {
        const resetShowNotificationTimeout = 5000;
        this.showCloneNotification = true;
        this.changeDetector.detectChanges();
        setTimeout(() => {
            this.showCloneNotification = false;
            this.changeDetector.detectChanges();
        }, resetShowNotificationTimeout);
    }

    private setCalculationName() {
        this.calculationName = this.form.value.name;
    }
}
