import { isFirmedCargoPlanStatus, isFirmedVesselPlanStatus, isFixed, isPlanning, isSendingForApproval, isSendingForApprovalFailed, Lifting } from "../model";

export type ProgressionStatus = "Cargo Nominated" | "Cargo Firmed" | "Vessel Nominated" | "Vessel Firmed" | "Decl. Date Stated" | "Fixed";
export type LiftingProgression = Readonly<{
    statuses: Readonly<{ [status in ProgressionStatus]: ProgressionStatusDetail }>;
    canFix: boolean;
    isFixed: boolean;
}>;

export type ProgressionStatusNavigateTo = "Cargo" | "Vessel" | "Decl. Date";
export type ProgressionStatusDetail = {
    done: boolean;
    navigateTo?: ProgressionStatusNavigateTo;
};

export const getLiftingProgression = (source: Lifting): LiftingProgression => {
    const cargoFirmed = isFirmedCargoPlanStatus(source);
    const vesselFirmed = isFirmedVesselPlanStatus(source);
    const declarationDateStated = !!source.declarationDate;
    const isLiftingFixed = isFixed(source);
    const isLiftingSendingForApproval = isSendingForApproval(source);
    const isLiftingPlanning = isPlanning(source);
    const hasSendForApprovalFailed = isSendingForApprovalFailed(source);
    const canFix = (isLiftingPlanning || hasSendForApprovalFailed) && cargoFirmed && vesselFirmed && declarationDateStated;

    return {
        statuses: {
            ["Cargo Nominated"]: { done: !!source.cargoes.length, navigateTo: "Cargo" },
            ["Cargo Firmed"]: { done: cargoFirmed, navigateTo: "Cargo" },
            ["Vessel Nominated"]: { done: !!source.vessels.length, navigateTo: "Vessel" },
            ["Vessel Firmed"]: { done: vesselFirmed, navigateTo: "Vessel" },
            ["Decl. Date Stated"]: { done: declarationDateStated, navigateTo: !isLiftingFixed && !isLiftingSendingForApproval ? "Decl. Date" : undefined },
            ["Fixed"]: { done: isLiftingFixed }
        },
        canFix,
        isFixed: isLiftingFixed
    };
};
