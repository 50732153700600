import { createSelector } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";
import * as R from "ramda";

import { isNullOrUndefined } from "@ops/shared";
import { CargoBerthActivityType } from "@ops/shared/reference-data";

import { AdditionalFreightExpense, AssociatedCargo, Berth, Cargo, CargoTotalType, Destination } from "../../shared/models";
import { CargoTotalsViewModel } from "../../shared/models/common/cargo-totals.model";
import { AssociatedCargoExpenseUnit } from "../../shared/models/enums/associated-cargo-expense-unit";
import { getCargoName, getExpenseValue, getFreightSpend } from "../model";
import { selectCurrentVoyage, selectCurrentVoyageExpandedSections, selectCurrentVoyageForm } from "../voyage";

export type CargoTotalState = Readonly<{
    total: CargoTotalsViewModel;
    form: FormGroupState<AdditionalFreightExpense>;
}>;

export const selectCurrentVoyageTotalsExpanded = createSelector(selectCurrentVoyageExpandedSections, (expandedSections) => expandedSections && expandedSections.totals);

export const selectCurrentVoyageTotals = createSelector(selectCurrentVoyage, (voyage) => (voyage ? calculateTotals(voyage.cargoes, voyage.destinations) : []));

export const selectCurrentVoyageTotalsForm = createSelector(selectCurrentVoyage, selectCurrentVoyageForm, (voyage, voyageForm) => {
    if (!voyage) {
        return [];
    }
    const totals = calculateTotals(voyage.cargoes, voyage.destinations);

    const cargoTotalsState = totals.map(
        (total) =>
            <CargoTotalState>{
                total,
                form: null
            }
    );

    const additionalFreightExpensesState = voyageForm.controls.additionalFreightExpenses.controls.map(
        (expense) =>
            <CargoTotalState>{
                total: additionalFreightExpenseToTotal(expense.value),
                form: expense
            }
    );

    return cargoTotalsState.concat(additionalFreightExpensesState);
});

export const additionalFreightExpenseToTotal = (source: AdditionalFreightExpense): CargoTotalsViewModel => ({
    type: CargoTotalType.AdditionalFreight,
    description: source.description,
    freightSpend: source.freightSpend,
    additionalFreightExpenseId: source.id,
    legacyAdditionalFreightExpenseId: source.additionalFreightExpenseId,
    cargoTotalId: `${CargoTotalType.AdditionalFreight}::${source.id}`
});

const getCargoBerthActivities = (activityType: CargoBerthActivityType) => (destination: Destination) =>
    R.pipe(
        R.map((b: Berth) => b.cargoBerthActivities.filter((a) => a.type && a.type.id === activityType.id)),
        R.flatten
    )(destination.berths);

const getAllLoadAssociatedCargoes = (destinations: Destination[]) =>
    R.pipe(
        R.map(getCargoBerthActivities(CargoBerthActivityType.Load)),
        R.flatten,
        R.map((a) => a.associatedCargoes.filter((ac) => !isNullOrUndefined(ac.cargoId))),
        R.flatten
    )(destinations);

export const calculateTotals = (cargoes: Cargo[], destinations: Destination[]): CargoTotalsViewModel[] => {
    const allAssociatedCargoes = getAllLoadAssociatedCargoes(destinations);

    const totals = R.pipe(
        R.groupBy((x: AssociatedCargo) => `${x.cargoId}_${x.freightRate || 0}`),
        R.values,
        R.map((associatedCargoes) => {
            const cargoId = associatedCargoes[0].cargoId;
            const cargo = cargoes.find((c) => cargoId === c.id && c.cargoProduct);

            if (isNullOrUndefined(cargo)) {
                return null;
            }

            const freightRate = associatedCargoes[0].freightRate;

            let freightSpend: number = null;
            let bl: number = null;

            for (const ac of associatedCargoes) {
                if (ac.mt) {
                    bl += ac.mt;
                } else if (ac.quantity) {
                    bl += ac.quantity;
                }

                if (ac.freightRate && cargo && cargo.baseFreightRateUnit) {
                    freightSpend = (freightSpend ?? 0) + getFreightSpend(ac, cargo.baseFreightRateUnit);
                }
            }

            return <CargoTotalsViewModel>{
                cargoTotalId: `${CargoTotalType.Cargo}::${cargoId}`,
                type: CargoTotalType.Cargo,
                description: getCargoName(cargo),
                bl,
                actualFreightRate: freightRate,
                freightRateUnit: freightRate ? cargo.baseFreightRateUnit?.name : null,
                freightSpend,
                cargoId: cargo.id,
                legacyCargoId: cargo.cargoId
            };
        }),
        R.filter((x) => !isNullOrUndefined(x))
    )(allAssociatedCargoes);

    const additionalRateAssociatedCargoesWithType = destinations
        .map((d) => d.berths)
        .reduce((a, b) => a.concat(b), [])
        .map((b) =>
            b.cargoBerthActivities.map((a) => ({
                type: a.type,
                associatedCargoes: a.associatedCargoes.filter((ac) => ac.associatedCargoExpenses && ac.associatedCargoExpenses.length)
            }))
        )
        .reduce((a, b) => a.concat(b), [])
        .filter((a) => a.associatedCargoes.length);

    const additionalRateTotals = additionalRateAssociatedCargoesWithType
        .map((act) =>
            act.associatedCargoes.map((ac) => {
                const cargo = cargoes.find((c) => c.id === ac.cargoId);
                return ac.associatedCargoExpenses
                    .filter((e) => e.rateDescription && e.unit && e.value && cargo && cargo.cargoProduct)
                    .map(
                        (e) =>
                            <CargoTotalsViewModel>{
                                cargoTotalId: `${CargoTotalType.CargoAdditionalRate}::${ac.cargoId}::${e.id}`,
                                type: CargoTotalType.CargoAdditionalRate,
                                description: `${cargo.cargoProduct.name} (${act.type.name}) : ${e.rateDescription.name}`,
                                bl: e.unit.id === AssociatedCargoExpenseUnit.PerMT ? e.value : null,
                                actualFreightRate: e.unit.id === AssociatedCargoExpenseUnit.PerMT ? e.freightRate : null,
                                freightRateUnit: e.unit.id !== AssociatedCargoExpenseUnit.LumpSum ? e.unit.name : null,
                                freightSpend: getExpenseValue(e),
                                cargoId: cargo.id,
                                legacyCargoId: cargo.cargoId,
                                associatedCargoExpenseId: e.id,
                                legacyAssociatedCargoExpenseId: e.associatedCargoExpenseId
                            }
                    );
            })
        )
        .reduce((a, b) => a.concat(b), []);

    for (const arts of additionalRateTotals) {
        if (arts && arts.length) {
            const index = totals
                .slice()
                .reverse()
                .findIndex((t) => t.cargoId === arts[0].cargoId);

            totals.splice(totals.length - index, 0, ...arts);
        }
    }
    return totals;
};
