<div #headerWrapper class="history-event-header">
    <span class="field-name">{{ historyEvent.title }}</span>
    <span class="spacer"></span>
    <span class="ops-coa-status" [ngClass]="statusClass">
        {{ historyEvent.status | uppercase }}
    </span>
</div>
<div class="date-field">
    <span class="field-name">{{ historyEvent.date }}</span>
</div>
<div>
    <span class="field-name">Updated By:</span>
    <span>{{ historyEvent.user }}</span>
</div>
<div class="history-event-changes">
    <div *ngFor="let change of historyEvent.changes">
        <div *ngIf="change.type === 'expandable-text'; else historyEventChange">
            <ops-lifting-history-expandable-text [name]="change.name"
                [text]="formatValue(change.value)"></ops-lifting-history-expandable-text>
        </div>
        <ng-template #historyEventChange>
            <span class="field-name">{{ change.name }}:</span>
            <span class="field-value" *ngIf="change.type === 'amount'">
                <span [innerHtml]="change.value.amount | opsNumberStyledDecimal: false:2:2"></span>
                {{ change.value.unit }}
            </span>
            <span class="field-value" *ngIf="change.type === 'tolerance'">(+<span
                    [innerHtml]="change.value.min | opsNumberStyledDecimal: false:2:4"></span>
                -<span [innerHtml]="change.value.max | opsNumberStyledDecimal: false:2:4"></span>
                {{ change.value.unit }}) {{ change.value.option }}
            </span>
            <span class="field-value" *ngIf="change.type === 'text'">{{ formatValue(change.value) }}</span>
        </ng-template>
    </div>
    <div *ngIf="historyEvent.changeReason">
        <ops-lifting-history-expandable-text name="Reason"
            [text]="historyEvent.changeReason.reason"></ops-lifting-history-expandable-text>
        <div *ngIf="historyEvent.changeReason.responsibleForChange">
            <span class="field-name">Responsible For Change:</span>
            <span class="field-value">{{ historyEvent.changeReason.responsibleForChange }}</span>
        </div>
    </div>
</div>