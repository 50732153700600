import { createSelector } from "@ngrx/store";

import { LaytimeCalculation, LtcFeatureState, LaytimeCalculationCargoTermsListItem, laytimeCalculationCargoTermsToListItem } from "../../../model";
import { selectCurrentLaytimeCalculation, selectCurrentLaytimeCalculationState } from "../../selectors";

export const selectCurrentCargoTermsId = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.currentCargoTermsId);

export const selectCargoTermsForm = createSelector(selectCurrentLaytimeCalculationState, (state) => state?.cargoTermsForm);

export const selectCurrentCalculationCargoTerms = createSelector(selectCurrentLaytimeCalculation, (calculation) => calculation?.cargoTerms);

export const selectCargoTermsListItems = createSelector<LtcFeatureState, { cdnUrl: string }, LaytimeCalculation, ReadonlyArray<LaytimeCalculationCargoTermsListItem>>(
    selectCurrentLaytimeCalculation,
    (calculation, { cdnUrl }) => calculation?.cargoTerms?.map(laytimeCalculationCargoTermsToListItem(cdnUrl))
);
