<ops-navbar *ngIf="showSeaHeader"></ops-navbar>

<ops-notifications></ops-notifications>
<ops-busy-box></ops-busy-box>

<div class="grid-nav row">
    <a class="col" [routerLink]="fixturesLink" queryParamsHandling="merge" routerLinkActive="active">Fixtures</a>
    <a *opsRole="'Coa'" class="col" [routerLink]="coasLink" queryParamsHandling="merge" routerLinkActive="active">Contracts</a>
    <a class="col" [routerLink]="claimsLink" queryParamsHandling="merge" routerLinkActive="active">Demurrage/Claims</a>
    <a *opsRole="'Actions'" class="col" [routerLink]="actionsLink" queryParamsHandling="merge" routerLinkActive="active">Actions</a>
    <ng-container *opsFeature="'LaytimeCalculatorV2'">
        <a *opsRole="'Laytime'" class="col" [routerLink]="ltcLink" queryParamsHandling="merge" routerLinkActive="active">Calculations</a>
    </ng-container>
</div>

<div class="app-body d-flex flex-fill">
    <ops-left-toolbar></ops-left-toolbar>
    <div class="d-flex flex-fill app-router-container">
        <div class="app-router-overlay"
             [class.app-router-overlay-closed]="isLeftToolbarCollapsed$ | async"
             (click)="onOverlayClick()"></div>
        <router-outlet></router-outlet>
    </div>
</div>
