import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";
import { DropdownPosition, NgSelectComponent } from "@ng-select/ng-select";
import { Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from "rxjs/operators";

import { SuggestionService } from "./suggestion.service";

@Directive() // eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AutosuggestComponent<T> implements OnInit, OnDestroy, ControlValueAccessor {
    protected readonly destroy$ = new Subject();

    protected isLoadingSubject = new Subject<boolean>();
    protected searchTermSubject = new Subject<string>();
    protected suggestionsSubject = new Subject<T[]>();
    protected disabled = false;

    suggestions$: Observable<T[]>;
    isLoading$: Observable<boolean>;

    @Input() selection: Array<any>;
    @Input() multiple: boolean;
    @Input() dropdownPosition: DropdownPosition;

    @Output() focus = new EventEmitter();
    @Output() blur = new EventEmitter();
    @Output() change = new EventEmitter();

    @ViewChild(NgSelectComponent, { static: true }) ngSelectComponent: NgSelectComponent;

    protected constructor(private suggestionService: SuggestionService<T>, private elementRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) {}

    @HostListener("focus")
    onFocus() {
        setTimeout(() => this.ngSelectComponent.focus(), 0);
    }

    ngOnInit() {
        this.suggestions$ = this.suggestionsSubject.asObservable();
        this.isLoading$ = this.isLoadingSubject.asObservable();

        this.buildSearchTermSubject();
        this.setupSelectComponent();

        // Required for NGRX forms.
        this.elementRef.nativeElement.focus = () => setTimeout(() => this.ngSelectComponent.focus());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    onClear(item: any) {
        this.ngSelectComponent.clearItem(item);

        if (!this.ngSelectComponent.hasValue) {
            this.ngSelectComponent.handleClearClick();
        }
    }

    onSelectionChange(selection: any[]): void {
        this._onChange(selection);
        setTimeout(() => this.change.emit(), 0);
    }

    writeValue(obj: any): void {
        const isEmpty = !obj || obj.length === 0;

        this.setIsEmptyClass(isEmpty);
        this.selection = obj;
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.changeDetectorRef.detectChanges();
    }

    protected buildSearchTermSubject(): void {
        this.searchTermSubject
            .pipe(
                distinctUntilChanged(),
                tap(() => this.isLoadingSubject.next(true)),
                debounceTime(200),
                switchMap((term) => {
                    if (!term) {
                        return of([]);
                    }

                    return this.getSuggestions(term).pipe(
                        catchError((err) => {
                            // Swallows the error so the observable is not completed
                            console.error(err);
                            return of(null);
                        })
                    );
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((suggestions) => {
                this.isLoadingSubject.next(false);
                this.suggestionsSubject.next(suggestions);
            });
    }

    protected getSuggestions(term: string) {
        return this.suggestionService.getSuggestions(term);
    }

    private setIsEmptyClass(isEmpty: boolean): void {
        if (isEmpty) {
            this.ngSelectComponent.searchInput.nativeElement.classList.remove("ops-has-value");
        } else {
            this.ngSelectComponent.searchInput.nativeElement.classList.add("ops-has-value");
        }
    }

    private setupSelectComponent(): void {
        this.ngSelectComponent.typeahead = this.searchTermSubject;
        this.ngSelectComponent.dropdownPosition = this.dropdownPosition || "bottom";
        this.ngSelectComponent.markFirst = false;
        this.ngSelectComponent.multiple = this.multiple;
        this.ngSelectComponent.removeEvent.pipe(takeUntil(this.destroy$)).subscribe(() => this.onFocus());
        this.ngSelectComponent.focusEvent.pipe(takeUntil(this.destroy$)).subscribe(() => this.focus.emit());
        this.ngSelectComponent.addEvent.pipe(takeUntil(this.destroy$)).subscribe(this.resetSuggestions);
        this.ngSelectComponent.blurEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.blur.emit();
            this._onTouched();
        });
        this.ngSelectComponent.closeEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.resetSuggestions();
            this._onTouched();
        });
        this.ngSelectComponent.clearEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.resetSuggestions();
            this._onTouched();
        });
    }

    private resetSuggestions = (): void => {
        this.suggestionsSubject.next([]);
        this.searchTermSubject.next(null);
    };

    private _onChange = (_: any) => {};
    private _onTouched = () => {};
}
