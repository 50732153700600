<ng-template #ContextButtons>
    <ops-icon-button type="edit" (click)="edit()"></ops-icon-button>
    <ops-icon-button type="clone" (click)="clone()"></ops-icon-button>
    <ops-icon-button type="done" tooltip="Complete" tooltipContainer="body" (click)="toggleComplete()" [toggled]="action.isComplete"></ops-icon-button>
</ng-template>

<div class="actions">
    <div class="action" [ngClass]="[action.priority?.name?.toLowerCase(), action.isOverdue ? 'overdue' : '']" data-test-action>
        <div class="row no-gutters">
            <div class="col-1 expanded-icon">
                <button
                    type="button"
                    (click)="toggle()"
                    class="has-icon"
                    data-test-expand
                    [ngClass]="{ 'icon--arrow-drop-right': !isExpanded, 'icon--arrow-drop-down': isExpanded }"
                ></button>
            </div>
            <div class="action-body col-11" [ngClass]="[action.isComplete ? 'complete' : '']">
                <div class="action-body-row justify-content-between row row-title no-gutters toggle" (click)="toggle()">
                    <span class="body-text" data-text-due-date>Due on: {{ action.dueDate | date: "E dd MMM yy, HH:mm" }}</span>
                    <a id="firstnew" *ngIf="isFirstNew"></a>
                    <span *ngIf="isNew" class="message new">NEW</span><span *ngIf="action.isOverdue" class="message warning">OVERDUE</span>
                </div>
                <div class="action-body-row justify-content-between row no-gutters action-summary">
                    <span data-text-subject class="col pr-1">Summary: {{ action.summary }} </span>
                    <ng-template *ngIf="!isExpanded" [ngTemplateOutlet]="ContextButtons"></ng-template>
                </div>
                <div *ngIf="isExpanded">
                    <div class="action-body-row row no-gutters row-title action-description" *ngIf="action.description">Description</div>
                    <div data-test-description class="action-body-row row no-gutters action-description" *ngIf="action.description">
                        <span class="description-text">{{ action.description }}</span>
                    </div>
                    <div class="action-body-row row no-gutters row-title action-assignees">Assignees</div>
                    <div class="row no-gutters">
                        <ops-user-autosuggest
                            [readonly]="true"
                            [multiple]="true"
                            [fixtureId]="fixtureId"
                            [fixtureSource]="fixtureSource"
                            [(ngModel)]="action.assignedTo"
                            [lead]="action.assignedTo[0]"
                            data-test-assigned-to
                        ></ops-user-autosuggest>
                    </div>
                    <span *ngIf="action.notificationDates.length > 0">
                        <div class="action-body-row row no-gutters row-title notification-area">Notification Date Times</div>
                        <div class="action-body-row no-gutters action-notification" *ngFor="let notificationDate of action.notificationDates">
                            <span data-text-notification-dates>{{ notificationDate | date: "E dd MMM yy, HH:mm" }}</span>
                        </div>
                    </span>
                    <span *ngIf="action.notificationRecipients.length > 0">
                        <div class="action-body-row row no-gutters row-title notification-recipients">Notification Recipients</div>
                        <div class="row no-gutters">
                            <ops-user-autosuggest
                                [readonly]="true"
                                [multiple]="true"
                                [fixtureId]="fixtureId"
                                [fixtureSource]="fixtureSource"
                                [(ngModel)]="action.notificationRecipients"
                                data-test-notification-recipients
                            ></ops-user-autosuggest>
                        </div>
                    </span>
                    <div class="action-body-row row no-gutters">
                        <div class="updated">
                            {{ isAutoAction(action.type.id) ? "Auto action" : "" }}
                            {{ action.isComplete ? "Completed" : "Updated" }} by {{ action.lastUpdatedBy?.fullName || "Unknown" }} on
                            {{ action.updatedDate | date: "E dd MMM yy HH:mm" }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isExpanded" class="action-edit justify-content-between row no-gutters">
            <span class="m-0"></span>
            <div class="d-flex align-self-end m-0">
                <ng-template [ngTemplateOutlet]="ContextButtons"></ng-template>
            </div>
        </div>
    </div>
</div>
