import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { Accordion } from "../../../../shared/accordion";
import { Command } from "../../../mediator/commands/command";
import { CargoBerthActivity, Destination } from "../../../shared/models";
import { CpSpeedAndConsumption } from "../../../shared/models/dtos/cp-speed-and-consumption.dto";
import { BerthId } from "../../../state/model";

@Component({
    selector: "ops-voyage-bunker-consumption-location",
    templateUrl: "./voyage-bunker-consumption-location.component.html",
    styleUrls: ["./voyage-bunker-consumption-location.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoyageBunkerConsumptionLocationComponent implements OnInit, OnDestroy, OnChanges {
    static componentName = "VoyageBunkerConsumptionLocationComponent";

    activitiesForm: UntypedFormArray;
    activities: { activity: CargoBerthActivity; berthId: BerthId }[];

    @Input() destination: Destination;
    @Input() parentForm: UntypedFormGroup;
    @Input() accordion: Accordion;
    @Input() cpSpeedAndConsumption: CpSpeedAndConsumption;
    @Output() voyageBunkerConsumptionLocationUpdated = new EventEmitter<Command>();

    constructor(private formBuilder: UntypedFormBuilder, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.mapActivities();
        this.activitiesForm = this.formBuilder.array([]);
        this.resizeList(this.activities.length);
        if (this.parentForm.disabled) {
            this.activitiesForm.disable({ emitEvent: false });
        } else {
            this.activitiesForm.enable({ emitEvent: false });
        }
        this.parentForm.registerControl("activities", this.activitiesForm);
    }

    ngOnDestroy() {
        delete this.parentForm.controls.activities;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.destination && !changes.destination.firstChange) {
            this.mapActivities();
            this.resizeList(this.activities.length);
            this.changeDetectorRef.markForCheck();
        }
    }

    accordionToggle() {
        this.accordion.toggle();

        setTimeout(() => {
            this.changeDetectorRef.markForCheck();
        }, 0);
    }

    handleUpdateCommand(event: Command): void {
        this.voyageBunkerConsumptionLocationUpdated.emit(event);
    }

    private mapActivities() {
        this.activities = this.destination.berths.map((b) => b.cargoBerthActivities.map((cba) => ({ activity: cba, berthId: b.id }))).reduce((a, b) => a.concat(b), []);
    }

    private resizeList(count: number): void {
        const formArray = <UntypedFormArray>this.activitiesForm;

        if (formArray.length === count) {
            return;
        } else if (formArray.length > count) {
            formArray.removeAt(0);
        } else if (formArray.length < count) {
            formArray.push(this.createRow(this.parentForm.disabled));
        }

        return this.resizeList(count);
    }

    private createRow(disabled: boolean): UntypedFormGroup {
        const group = this.formBuilder.group({}, { updateOn: "blur" });

        if (disabled) {
            group.disable({ emitEvent: false });
        }

        return group;
    }
}
