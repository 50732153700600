import { createAction, On, on, props } from "@ngrx/store";
import { addArrayControl, Boxed, FormArrayState, unbox, updateArrayWithFilter, updateGroup } from "ngrx-forms";
import { v4 as uuid } from "uuid";

import { deepCopy } from "@ops/shared";
import { opsAddArrayControl, sortArrayControls } from "@ops/state";

import { getNewDestination } from "./specialised/specialised-cargo-functions";
import { CargoBerthActivityType } from "../../../../shared/reference-data";
import { FixtureType } from "../../../shared/models";
import { Division } from "../../../shared/models/enums/division";
import { forEachActivity } from "../../activities/shared";
import { orderLaytimeEventsSortFunc } from "../../laytime-events/form/order-laytime-events";
import {
    activityForm,
    ActivityForm,
    ActivityId,
    AssociatedCargoForm,
    BerthForm,
    BerthId,
    cargoExpandedKey,
    CargoForm,
    CargoId,
    createActivityId,
    createAssociatedCargoId,
    createCargoId,
    createDestinationId,
    defaultCargoExpanded,
    destinationExpandedKey,
    DestinationForm,
    DestinationId,
    FixturesState,
    getDivision,
    getFixtureType,
    isDivision,
    LaytimeEventForm,
    VoyageForm
} from "../../model";
import { getNextId } from "../../utils";
import { currentVoyageStateReducer } from "../../voyage/reducer";

/* ACTIONS */
const CLONE_CARGO_NAME = "[Voyage Form] Clone Cargo";

export const cloneCargoAction = createAction(CLONE_CARGO_NAME, props<{ cargoId: CargoId }>());

/* REDUCERS */
export const cloneCargoReducer: On<FixturesState> = on(cloneCargoAction, (state, { cargoId }) =>
    currentVoyageStateReducer(state, (voyageState, fixtureState) => {
        const cargoes = voyageState.form.value.cargoes;
        const sourceCargo = cargoes.find((c) => c.cargoId === cargoId);
        let destinations = voyageState.form.controls.destinations;

        if (!sourceCargo) {
            throw Error(`Unable to clone cargo. Invalid cargoId=${cargoId}`);
        }

        const newCargoId = createCargoId();
        const newCargo: CargoForm = {
            ...deepCopy(sourceCargo),
            cargoId: newCargoId,
            legacyCargoId: getNextId(cargoes, "legacyCargoId")
        };
        let updatedExpandedSections = {
            ...voyageState.expandedSections,
            [cargoExpandedKey(newCargoId)]: defaultCargoExpanded(getDivision(fixtureState))
        };

        if (isDivision(fixtureState, Division.specialisedProducts)) {
            const specialisedCloneChanges = getSpecialisedCargoCloneChanges(cargoId, newCargoId, voyageState.form.value, getFixtureType(fixtureState));

            specialisedCloneChanges.newDestinations.forEach((d) => {
                destinations = opsAddArrayControl(d, { markAsTransient: true })(destinations);
                updatedExpandedSections = {
                    ...updatedExpandedSections,
                    [destinationExpandedKey(d.id)]: true
                };
            });

            specialisedCloneChanges.newActivityComponents.forEach((c) => {
                destinations = updateDestination(c, destinations);
            });
        }

        return {
            ...voyageState,
            form: updateGroup<VoyageForm>({
                cargoes: addArrayControl(newCargo),
                destinations: () => destinations
            })(voyageState.form),
            expandedSections: updatedExpandedSections
        };
    })
);

/* FUNCTIONS */
const getSpecialisedCargoCloneChanges = (originalCargoId: CargoId, newCargoId: CargoId, voyageForm: Readonly<VoyageForm>, fixtureType: FixtureType) => {
    let nextDestinationId = getNextId(voyageForm.destinations, "destinationId");
    const newDestinations: DestinationForm[] = [];

    const newActivityComponents: NewActivityComponents[] = [];

    getActivitiesToUse(voyageForm, originalCargoId).forEach((activityToUse) => {
        const { destination, activity } = activityToUse;
        const etaRange = destination.etaRange && destination.etaRange.value;
        const location = destination.location && destination.location.value;
        if (!(etaRange && location)) {
            newDestinations.push(getNewDestination(createDestinationId(), nextDestinationId, newCargoId, unbox(activity.type), fixtureType, location, etaRange));
            nextDestinationId++;
        } else {
            newActivityComponents.push(getNewActivityComponents(activityToUse, originalCargoId, newCargoId));
        }
    });

    return {
        newDestinations,
        newActivityComponents
    };
};

const getActivitiesToUse = (voyageForm: Readonly<VoyageForm>, originalCargoId: CargoId): ActivityToUse[] => {
    const result: ActivityToUse[] = [];

    forEachActivity(voyageForm, (activity, { destination, berth }) => {
        if (activity.associatedCargoes.some(({ cargoId }) => cargoId === originalCargoId)) {
            result.push({ destination, berth, activity });
        }
    });

    return result;
};

const getNewActivityComponents = (activityToUse: ActivityToUse, originalCargoId: CargoId, newCargoId: CargoId): NewActivityComponents => {
    const { destination, berth, activity } = activityToUse;
    const firstBerth = destination.berths[0];
    const targetActivity = berth.id !== firstBerth.id ? firstBerth.activities.find((a) => a.type?.value.id === activity.type?.value.id) : activity;
    const targetActivityId = targetActivity?.activityId ?? createActivityId();
    const isNewActivity = !targetActivity;

    const acToClone = activity.associatedCargoes.find((ac) => ac.cargoId === originalCargoId);
    const acClone = <AssociatedCargoForm>{
        ...deepCopy(acToClone),
        associatedCargoId: createAssociatedCargoId(),
        legacyAssociatedCargoId: targetActivity ? getNextId(targetActivity.associatedCargoes, "legacyAssociatedCargoId") : 1,
        cargoId: newCargoId
    };
    return {
        isNewActivity,
        path: { destinationId: destination.id, berthId: firstBerth.id, activityId: targetActivityId },
        legacyActivityId: isNewActivity ? Math.max(...firstBerth.activities.map((a) => a.legacyActivityId), 0) + 1 : undefined,
        activityType: isNewActivity ? activity.type : undefined,
        associatedCargo: acClone,
        laytimeEvents: []
    };
};

const updateDestination = (components: NewActivityComponents, destinations: FormArrayState<DestinationForm>): FormArrayState<DestinationForm> =>
    updateArrayWithFilter(
        (d) => d.value.id === components.path.destinationId,
        updateGroup<DestinationForm>({
            berths: updateArrayWithFilter(
                (b) => b.value.id === components.path.berthId,
                updateGroup<BerthForm>(components.isNewActivity ? getNewActivitiesObject(components) : getUpdateActivitiesObject(components))
            )
        })
    )(destinations);

const getUpdateActivitiesObject = (components: NewActivityComponents) => ({
    activities: updateArrayWithFilter<ActivityForm>(
        (a) => a.value.activityId === components.path.activityId,
        (activity) => {
            let laytimeEventForms = activity.controls.laytimeEvents;

            components.laytimeEvents.forEach((le) => {
                const newLaytimeEventId = uuid();
                const newLaytimeEvent = <LaytimeEventForm>{
                    ...le,
                    laytimeEventId: newLaytimeEventId
                };
                laytimeEventForms = addArrayControl(newLaytimeEvent)(laytimeEventForms);
            });
            laytimeEventForms = sortArrayControls<LaytimeEventForm>((a, b) => orderLaytimeEventsSortFunc(a.value, b.value))(laytimeEventForms);

            return updateGroup(activity, {
                associatedCargoes: addArrayControl(components.associatedCargo),
                laytimeEvents: () => laytimeEventForms
            });
        }
    )
});

const getNewActivitiesObject = (components: NewActivityComponents) => ({
    activities: addArrayControl(<ActivityForm>{
        ...activityForm(Division.specialisedProducts, components.path.activityId, components.legacyActivityId),
        type: components.activityType,
        associatedCargoes: [components.associatedCargo],
        laytimeEvents: components.laytimeEvents
    })
});

/* LOCAL TYPES */
type NewActivityComponents = {
    isNewActivity: boolean;
    path: { destinationId: DestinationId; berthId: BerthId; activityId: ActivityId };
    legacyActivityId?: number;
    activityType?: Boxed<CargoBerthActivityType>;
    associatedCargo: AssociatedCargoForm;
    laytimeEvents: LaytimeEventForm[];
};

type ActivityToUse = {
    destination: DestinationForm;
    berth: BerthForm;
    activity: ActivityForm;
};
