export const laytimeEventTypes = [
    "1st Foot Analysis Passed",
    "1st Foot Loading Completed",
    "1st Foot Sample Taken",
    "1st Foots Failed",
    "2nd Foots Commenced",
    "2nd Foots Completed",
    "2nd Foots Failed",
    "2nd Foots Passed",
    "All Clear",
    "All Fast",
    "All Fast + 6 Hours",
    "All Fast At Layby Berth",
    "Analysis Approved",
    "Analysis Commenced",
    "Analysis Completed",
    "Anchor Aweigh",
    "Anchor Up",
    "Anchored",
    "Arrival",
    "Awaiting Daylight",
    "Bad Weather Ends",
    "Bad Weather Starts",
    "Barge Alongside",
    "Barge Left",
    "Berth Congestion",
    "Berthed",
    "Bunker Barge Alongside",
    "Bunker Barge Left",
    "Bunker Ops Commenced",
    "Bunker Ops Completed",
    "Cargo Commenced",
    "Cargo Completed",
    "Commenced 1st Foot Loading",
    "Commenced Cooling Down",
    "Commenced Gassing Up",
    "Commenced N2 Purging",
    "Commenced Ullaging And Sampling",
    "Commenced Working For Others",
    "Complete Working For Others",
    "Completed Cooling Down",
    "Completed Gassing Up",
    "Completed N2 Purging",
    "Completed Ullaging And Sampling",
    "Deballasting Commenced",
    "Deballasting Completed",
    "Discharge Commenced",
    "Discharge Completed",
    "Discharge Resumed",
    "Discharge Suspended",
    "Document Allowance",
    "Documents On Board",
    "Drifting Commenced",
    "Drifting Completed",
    "Empty Tank Certificate",
    "End DW Procedure",
    "End of Shift ",
    "EOSP",
    "ETA",
    "ETB",
    "ETC",
    "ETS",
    "Expect To Commence Discharge",
    "Expect To Commence Loading",
    "First Line Ashore",
    "Free Pratique Granted",
    "Gangway Down",
    "Hoses Connected",
    "Hoses Disconnected",
    "Laytime Commenced",
    "Laytime Completed",
    "Laytime Resumed",
    "Laytime Stopped",
    "Laytime Suspended",
    "Loading Commenced",
    "Loading Completed",
    "Loading Master Onboard",
    "Loading Resumed",
    "Loading Suspended",
    "Locks",
    "Mooring",
    "N2 Blanket End",
    "N2 Blanket Start",
    "N2 Hose Off",
    "N2 Hose On",
    "NOR + 6 Hours",
    "NOR Accepted",
    "NOR Retendered",
    "NOR Tendered",
    "Notice Time Expired",
    "Operations Resumed",
    "Operations Suspended",
    "Other",
    "Outturn Qtys",
    "P+I Club Onboard",
    "Pilot Disembarked",
    "Pilot On Board",
    "POB",
    "POB Shifting Time",
    "Pre-purging Commenced",
    "Pre-purging Completed",
    "Prewash Commenced",
    "Prewash Completed",
    "Prewash Hose Off",
    "Prewash Hose On",
    "Pumping Time",
    "Rain Commenced",
    "Rain Ended",
    "Sailed",
    "Sailed From Previous Berth",
    "Sailed From Previous Port",
    "Samples Analysed",
    "Samples Taken",
    "Sampling, Gauging And Calculations Commenced",
    "Sampling, Gauging And Calculations Completed",
    "Shifting",
    "Shifting At Commencement/During Discharge",
    "Shore Line Blowing Commenced",
    "Shore Line Blowing Completed",
    "Slop Discharge Commenced",
    "Slop Discharge Completed",
    "SOSP",
    "Start DW Procedure",
    "Start of Laycan",
    "Start of Shift",
    "Start Shifting",
    "Stop Shifting",
    "Surveyor",
    "Surveyor Onboard",
    "Surveyor Times At Commencement",
    "Tanks Failed",
    "Tanks Inspected",
    "Tanks Passed",
    "Tanks Reinspected",
    "Terminal Delay",
    "Unberthed",
    "Vessel Delay",
    "Weather Delay"
] as const;
export type LaytimeEventType = typeof laytimeEventTypes[number];

export const UNLESS_USED_LAYTIME_EVENT_TYPES: ReadonlyArray<LaytimeEventType> = [
    "Cargo Commenced",
    "Hoses Connected",
    "Laytime Resumed",
    "Laytime Commenced",
    "Start Shifting",
    "Operations Resumed",
    "Shifting",
    "Loading Commenced"
];
