import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { getIsRequired } from "@ops/state";

import { Division } from "../../shared/models";
import { CargoId, CargoState } from "../../state";

@Component({
    selector: "ops-cargoes",
    styleUrls: ["./cargoes.component.scss"],
    templateUrl: "./cargoes.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CargoesComponent {
    Division = Division;

    @Input() cargoes: ReadonlyArray<CargoState>;
    @Input() readonly: boolean;
    @Input() canAdd: boolean;
    @Input() canClone: boolean;
    @Input() canRemove: boolean;
    @Input() canApplyCargoFreightRate: boolean;
    @Input() showSpecialisedCargoControls: boolean;

    @Output() readonly expand = new EventEmitter<CargoId>();
    @Output() readonly collapse = new EventEmitter<CargoId>();
    @Output() readonly add = new EventEmitter();
    @Output() readonly clone = new EventEmitter<CargoId>();
    @Output() readonly remove = new EventEmitter<CargoId>();
    @Output() readonly applyCargoFreightRate = new EventEmitter<CargoId>();
    @Output() readonly applyAllCargoFreightRate = new EventEmitter();

    get isBaseFreightUnitRequired(): boolean {
        return this.cargoes && getIsRequired(this.cargoes[0].form.controls.baseFreightRateUnit);
    }

    get isBaseFreightRateRequired(): boolean {
        return this.cargoes && getIsRequired(this.cargoes[0].form.controls.baseFreightRate);
    }

    get isQuantityUnitRequired(): boolean {
        return this.cargoes && getIsRequired(this.cargoes[0].form.controls.quantityUnit);
    }

    get isQuantityRequired(): boolean {
        return this.cargoes && getIsRequired(this.cargoes[0].form.controls.quantity);
    }

    get isCargoProductRequired(): boolean {
        return this.cargoes && getIsRequired(this.cargoes[0].form.controls.cargoProduct);
    }

    get hasTolerance(): boolean {
        return this.cargoes && !!this.cargoes[0].form.controls.tolerance;
    }

    get hasWorldscaleRate(): boolean {
        return this.cargoes && !!this.cargoes[0].form.controls.worldscaleRate;
    }

    get hasOrderId(): boolean {
        return this.cargoes && !!this.cargoes[0].form.controls.orderId;
    }

    trackBy(index: number, item: CargoState) {
        return item.cargoId;
    }
}
