import { Injectable } from "@angular/core";
import { isNullOrUndefined } from "src/app/shared/utils";

@Injectable()
export class NoonReportEditStateService {
    private readonly editState = new Set<string>();

    setInReadMode(noonReportId: string) {
        if (!isNullOrUndefined(noonReportId)) {
            this.editState.delete(noonReportId);
        }
    }

    setInEditMode(noonReportId: string) {
        if (!isNullOrUndefined(noonReportId)) {
            this.editState.add(noonReportId);
        }
    }

    isInEditMode(noonReportId: string): boolean {
        if (!isNullOrUndefined(noonReportId)) {
            return this.editState.has(noonReportId);
        }
    }
}
