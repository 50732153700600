import { SystemUser } from "../../../core/system-user.model";
import { isNullOrUndefined, toDateTimeAsUtc } from "../../../shared/utils";
import { Favorite } from "./favorite.model";
import { FixtureStatusType } from "./fixture-status-type.model";

export class FavoriteViewModel {
    selected = false;

    constructor(private favorite: Favorite, private currentUser: SystemUser) {
        if (!favorite || !currentUser) {
            throw new Error(`Unable to create an instance of favorite view model because
            either given favorite or given user is invalid.`);
        }
    }

    get color(): string {
        return this.favorite.color;
    }

    get fixtureId(): string {
        return this.favorite.id;
    }

    get tooltipContent(): string {
        return `CP Date: ${this.formatDate(this.favorite.cpDate)};
            Laycan: ${this.formatDate(this.favorite.laycanDateStart)} - ${this.formatDate(this.favorite.laycanDateEnd)};
            ${isNullOrUndefined(this.favorite.voyageReference) || this.favorite.voyageReference === "" ? "" : `Voyage Ref: ${this.favorite.voyageReference}`}`;
    }

    get fixtureName(): string {
        return this.favorite.vesselName ? this.favorite.vesselName : "TBN";
    }

    get fixtureType(): number {
        return this.favorite.fixtureTypeId;
    }

    get fixtureStatusType(): FixtureStatusType {
        return this.favorite.fixtureStatusId;
    }

    get isLockedByCurrentUser(): boolean {
        return this.favorite.lockedUserId && this.favorite.lockedUserId === this.currentUser.systemUserId;
    }

    get isLockedByOtherUser(): boolean {
        return this.favorite.lockedUserId && !this.isLockedByCurrentUser;
    }

    get isLocked(): boolean {
        return this.favorite.lockedUserId && true;
    }

    get canRemove(): boolean {
        return !this.isLockedByCurrentUser;
    }

    get lockedBy(): string {
        return this.favorite.lockedUserFullName;
    }

    private formatDate(date: string) {
        const format = "dd LLL yyyy";
        return date ? toDateTimeAsUtc(date).toFormat(format) : "NA";
    }
}
