<div [formGroup]="claimsNotificationForm" class="card embedded-card-1">
    <div class="card-header row no-gutters align-items-center">
        <span class="title">Claims Notifications</span>
    </div>
    <div class="card-body">
        <div class="form-row row-header">
            <div class="form-group col-2">
                <label>Received From Owner</label>
            </div>
            <div class="form-group col-2">
                <label>Sent To Charterer</label>
            </div>
            <div class="form-group col-2">
                <label>Charterer Ack. Receipt</label>
            </div>
            <div class="form-group col-2">
                <label>Last Contacted</label>
            </div>
        </div>
        <div class="form-row row-body">
            <div class="form-group col-2">
                <ops-date-input
                    formControlName="receivedFromOwnerDate"
                    timeZone="local"
                    data-test-receivedfromownerdate
                ></ops-date-input>
            </div>
            <div class="form-group col-2">
                <ops-date-input
                    formControlName="sentToChartererDate"
                    timeZone="local"
                    data-test-senttochartererdate
                ></ops-date-input>
            </div>
            <div class="form-group col-2">
                <ops-date-input
                    formControlName="chartererAcknowledgedReceiptDate"
                    timeZone="local"
                    data-test-chartereracknowledgedreceiptdate
                ></ops-date-input>
            </div>
            <div class="form-group col-2">
                <div class="form-control p-0 d-flex justify-content-between" readonly>
                    <span class="update-last-contacted-content" data-test-update-last-contacted>{{
                        claimsNotificationForm.controls.lastContactedDate.value | date: "dd MMM yy"
                    }}</span>
                    <button class="update-last-contacted-button" (click)="updateLastContacted()" type="button" data-test-update-last-contacted-button>
                        <span class="has-icon icon--tag-tick"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row no-gutters" *ngIf="lastUpdatedByUser">
            <div class="col text-right last-updated">Updated by:&nbsp;</div>
            <div class="col-auto pull-right last-updated" data-test-lastupdated>{{ lastUpdatedByUser }} {{ lastUpdatedDate | date: "dd MMM yy" }}</div>
        </div>
    </div>
</div>
<div #scrollToPlaceholder></div>
