import { box, Boxed, unbox } from "ngrx-forms";
import { v4 as uuid } from "uuid";

import { deepCopy, hasValue, Identity, Nullable } from "@ops/shared";
import { FreightRateUnit, QuantityUnit } from "@ops/shared/reference-data";

import {
    associatedCargoExpenseForm,
    AssociatedCargoExpenseForm,
    associatedCargoExpenseToForm,
    createAssociatedCargoExpenseId,
    formToAssociatedCargoExpense,
    isTemplateExpenseRecord
} from "./associated-cargo-expense";
import { CargoId } from "./cargo";
import { AssociatedCargo } from "../../shared/models/dtos/associated-cargo.dto";
import { Division } from "../../shared/models/enums/division";

export type AssociatedCargoId = Identity<string, "AssociatedCargoId">;
export const createAssociatedCargoId = (): AssociatedCargoId => uuid() as AssociatedCargoId;

export type AssociatedCargoForm = Readonly<{
    associatedCargoId: AssociatedCargoId;
    legacyAssociatedCargoId: number;
    cargoId?: CargoId;
    quantity?: number;
    quantityUnit?: Boxed<Readonly<QuantityUnit>>;
    freightRate: Nullable<number>;
    date?: string;
    temperature?: number;
    mt?: number;
    specificGravity?: number;
    associatedCargoExpenses?: ReadonlyArray<AssociatedCargoExpenseForm>;

    // NOTE: LTC fields not here!
}>;

export const associatedCargoForm = (division: Division, associatedCargoId: AssociatedCargoId, legacyAssociatedCargoId: number, cargoId: CargoId): AssociatedCargoForm => {
    let form: AssociatedCargoForm = {
        associatedCargoId,
        legacyAssociatedCargoId,
        cargoId,
        quantity: null,
        quantityUnit: box(null),
        freightRate: null,
        date: null,
        mt: null,
        associatedCargoExpenses: [associatedCargoExpenseForm(createAssociatedCargoExpenseId(), 1)]
    };

    if (division === Division.specialisedProducts) {
        form = {
            ...form,
            specificGravity: null
        };
    } else if (division === Division.gas) {
        form = {
            ...form,
            temperature: null
        };
    }

    return form;
};

export const associatedCargoToForm = (division: Division) => (source: AssociatedCargo): AssociatedCargoForm => {
    let form: AssociatedCargoForm = {
        associatedCargoId: source.id,
        legacyAssociatedCargoId: source.associatedCargoId,
        cargoId: source.cargoId,
        quantity: source.quantity,
        quantityUnit: box(deepCopy(source.quantityUnit)),
        freightRate: source.freightRate,
        date: source.date,
        mt: source.mt,
        associatedCargoExpenses:
            source.associatedCargoExpenses && source.associatedCargoExpenses.length > 0
                ? source.associatedCargoExpenses.map(associatedCargoExpenseToForm)
                : [associatedCargoExpenseForm(createAssociatedCargoExpenseId(), 1)]
    };

    if (division === Division.specialisedProducts) {
        form = {
            ...form,
            specificGravity: source.specificGravity
        };
    } else if (division === Division.gas) {
        form = {
            ...form,
            temperature: source.temperature
        };
    }

    return form;
};

export const formToAssociatedCargo = (source: AssociatedCargoForm, current: AssociatedCargo): AssociatedCargo => ({
    ...current,
    id: source.associatedCargoId,
    associatedCargoId: source.legacyAssociatedCargoId,
    cargoId: source.cargoId,
    quantity: source.quantity,
    quantityUnit: unbox(source.quantityUnit),
    freightRate: source.freightRate,
    date: source.date,
    mt: source.mt,
    specificGravity: source.specificGravity,
    temperature: source.temperature,
    associatedCargoExpenses: source.associatedCargoExpenses.filter((r) => !isTemplateExpenseRecord(r)).map(formToAssociatedCargoExpense)
});

/**
 * Calculates the freight spend (monetary value) of the cargo. If `unit` or `associatedCargo.quantityUnit` is not
 * specified, the returned value will be `null`.
 *
 * @param associatedCargo The associated cargo. `freightRate` and `mt` (if not Lump Sum) must be populated.
 * @param unit The unit of the freight rate. Should be the `baseFreightRateUnit` from the cargo.
 */
export const getFreightSpend = (associatedCargo: AssociatedCargo, unit?: FreightRateUnit): number | null => {
    if (!associatedCargo) {
        return null;
    }

    switch (unit?.id) {
        case FreightRateUnit.LumpSum.id:
            return associatedCargo.freightRate;
        case FreightRateUnit.PerMT.id: {
            if (!associatedCargo.quantityUnit || !hasValue(associatedCargo.quantity)) {
                return null;
            }

            if (hasValue(associatedCargo.mt)) {
                return associatedCargo.freightRate * associatedCargo.mt;
            }

            if (associatedCargo.quantityUnit?.id === QuantityUnit.MT.id) {
                return associatedCargo.freightRate * associatedCargo.quantity;
            }

            return null;
        }
        case FreightRateUnit.Worldscale.id:
            throw Error("getFreightSpend: Worldscale not implemented");
        default:
            return null;
    }
};
