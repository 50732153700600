import { FormArrayState, FormGroupState } from "ngrx-forms";

import { AppState, LoadStatus, PersistenceStatus, StateObjectMap } from "@ops/state";

import { FixtureId, FixturesState } from "../../../fixture/state";
import {
    createActivityLocationFormState,
    createActivityCargoFormsState,
    createLaytimeCalculationTermsFormState,
    LaytimeCalculation,
    LaytimeCalculationTermsForm,
    LtcId,
    FixtureIndex,
    LaytimeCalculationIndex,
    ActivityLocationForm,
    ActivityLocationId,
    LaytimeCalculationCargoTermsForm,
    CargoTermsId,
    ActivityCargoForm,
    LaytimeEventForm,
    Deduction,
    createActivityLocationLaytimeEventFormsState,
    Voyage,
    AddActivityLocationsForm,
    LaytimeCalculationNameForm,
    createLaytimeCalculationNameFormState,
    LaytimeCalculationGridState,
    LaytimeCalculationIndexState,
    createDeductionFormsState
} from "./calculations";

export declare type ExportStatus = "exporting" | "failed";

export type LaytimeCalculationState = Readonly<{
    calculation?: LaytimeCalculation;
    calculationLoadStatus?: LoadStatus;
    calculationTermsForm?: FormGroupState<LaytimeCalculationTermsForm>;
    calculationNameForm?: FormGroupState<LaytimeCalculationNameForm>;
    fixture?: FixtureIndex;
    fixtureLoadStatus?: LoadStatus;
    calculationPersistenceStatus?: PersistenceStatus;
    calculationPersistenceError?: Error;
    currentActivityLocationId?: ActivityLocationId;
    activityLocationForm?: FormGroupState<ActivityLocationForm>;
    activityCargoForms?: FormArrayState<ActivityCargoForm>;
    currentCargoTermsId?: CargoTermsId;
    cargoTermsForm?: LaytimeCalculationCargoTermsForm;
    laytimeEventForms?: FormArrayState<LaytimeEventForm>;
    deductionForms?: FormArrayState<Deduction>;
    voyage?: Voyage;
    voyageLoadStatus?: LoadStatus;
    addActivityLocationsForm?: FormGroupState<AddActivityLocationsForm>;
    isAddingActivityLocations?: boolean;
    exportStatus?: ExportStatus;
    awaitingVoyageLoad: ReadonlyArray<string>;
}>;

export type FixtureLaytimeCalculationsState = Readonly<{
    calculations?: ReadonlyArray<LaytimeCalculationIndexState>;
    loadStatus?: LoadStatus;
}>;

export type LtcState = Readonly<{
    calculations: StateObjectMap<LaytimeCalculationState>;
    currentCalculationId?: LtcId;
    fixtureCalculations: StateObjectMap<FixtureLaytimeCalculationsState>;
    currentFixtureId?: FixtureId;
    grid: LaytimeCalculationGridState;
}>;

export type LtcFeatureState = AppState & Readonly<{ ltc: LtcState }> & Readonly<{ fixtures: FixturesState }>;

export const getCurrentCalculationId = (state: LtcState) => state?.currentCalculationId;
export const getCurrentFixtureId = (state: LtcState) => state.currentFixtureId;
export const getLaytimeCalculationState = (state: LtcState, ltcId: LtcId) => state.calculations.byId[ltcId];
export const getCurrentLaytimeCalculationState = (state: LtcState) => state.calculations.byId[getCurrentCalculationId(state)];
export const getGridState = (state: LtcState) => state.grid;

export const initialLaytimeCalculationState = (calculation: LaytimeCalculation): LaytimeCalculationState => ({
    calculation,
    calculationLoadStatus: "loaded",
    calculationTermsForm: createLaytimeCalculationTermsFormState(calculation),
    calculationNameForm: createLaytimeCalculationNameFormState(calculation),
    calculationPersistenceStatus: "persisted",
    awaitingVoyageLoad: []
});

export const initialFixtureLaytimeCalculationsState = (calculations: ReadonlyArray<LaytimeCalculationIndex>): FixtureLaytimeCalculationsState => ({
    calculations,
    loadStatus: "loaded"
});

export const initLaytimeCalculationActivityLocationState = (ltcState: LaytimeCalculationState): LaytimeCalculationState => {
    let activityLocationForm: FormGroupState<ActivityLocationForm> = null;
    let activityLocationCargoForms: FormArrayState<ActivityCargoForm> = null;
    let laytimeEventForms: FormArrayState<LaytimeEventForm> = null;
    let deductionForms: FormArrayState<Deduction> = null;

    if (ltcState.currentActivityLocationId) {
        const activityLocation = ltcState.calculation.activityLocations.find((al) => al.id === ltcState.currentActivityLocationId);

        if (activityLocation) {
            activityLocationForm = createActivityLocationFormState(activityLocation);
            activityLocationCargoForms = createActivityCargoFormsState(activityLocation.id, activityLocation.cargoes);
            laytimeEventForms = createActivityLocationLaytimeEventFormsState(activityLocation.id, activityLocation.laytimeEvents);
            deductionForms = createDeductionFormsState(activityLocation.id, []);
        }
    }

    return { ...ltcState, activityLocationForm, activityCargoForms: activityLocationCargoForms, laytimeEventForms, deductionForms };
};
