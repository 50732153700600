import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { AssociatedCargoesByVoyageActivity, VoyageActivityId } from "../../../state";

@Component({
    selector: "ops-associated-cargoes-by-voyage-activities",
    templateUrl: "./associated-cargoes-by-voyage-activities.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociatedCargoesByVoyageActivitiesComponent {
    @Input() associatedCargoesByVoyageActivities: ReadonlyArray<AssociatedCargoesByVoyageActivity>;
    @Output() readonly selectAllByVoyageActivity = new EventEmitter<VoyageActivityId>();

    trackBy(index: number) {
        return index;
    }
}
