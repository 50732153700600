import { Directive, Inject, Input, OnInit, Optional, Self } from "@angular/core";
import { MaritimeDateRange } from "@maritech/maritime-date";
import { ActionsSubject } from "@ngrx/store";
import { DateTime } from "luxon";
import { Boxed, ClearAsyncErrorAction, FormControlState, SetAsyncErrorAction } from "ngrx-forms";

import { DateInputComponent } from "./date-input.component";

// @ts-ignore
declare module "ngrx-forms/src/state" {
    export interface ValidationErrors {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $date?: string;
    }
}

/**
 * Integrates the invalid reason provided by the MaritimeDate and MaritimeDateRange parsing into NGRX by abusing
 * async error actions.
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "ops-date-input[ngrxFormControlState]"
})
export class NgrxDateInputDirective implements OnInit {
    private state: FormControlState<string | Boxed<{ from?: string; to?: string }>>;

    @Input() set ngrxFormControlState(newState: FormControlState<string | Boxed<{ from?: string; to?: string }>>) {
        if (!newState) {
            throw new Error("The control state must not be undefined");
        }

        this.state = newState;
    }

    constructor(@Self() @Inject(DateInputComponent) private input: DateInputComponent, @Optional() @Inject(ActionsSubject) private actionsSubject: ActionsSubject | null) {}

    ngOnInit() {
        this.input.change.subscribe((value: DateTime | MaritimeDateRange) => {
            const errors = this.state.errors;

            if (value?.invalidReason) {
                if (value.invalidReason !== errors.$date) {
                    this.actionsSubject.next(new SetAsyncErrorAction(this.state.id, "date", value.invalidReason));
                }
            } else {
                if (errors.$date) {
                    this.actionsSubject.next(new ClearAsyncErrorAction(this.state.id, "date"));
                }
            }
        });
    }
}
