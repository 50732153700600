<div class="location-header-section form-row m-0 pb-0" [opsNgrxScrollIntoView]="form">
    <div class="form-group col-3" [class.required]="locationIsRequired">
        <label>{{ locationTitle }}</label>
        <ops-location-autosuggest
            [multiple]="false"
            [ngrxFormControlState]="form.controls.location"
            [readonly]="isLocationAndEtaReadonly || readonly"
            opsNgrxWarningFormControl
            data-test-location
        >
        </ops-location-autosuggest>
        <ops-validation [form]="form.controls.location"></ops-validation>
    </div>
    <div class="form-group col eta-form-group">
        <label>Fixing ETA</label>
        <ops-date-input
            [mode]="etaRangeMode"
            [enableTime]="true"
            [timeZone]="timeZone"
            [ngrxFormControlState]="form.controls.etaFixedRange"
            [ngrxValueConverter]="dateRangeValueConverter"
            [readonly]="readonly"
            [startDate]="canDate"
            date-test-eta-fixed-range
        ></ops-date-input>
        <ops-validation [form]="form.controls.etaFixedRange"></ops-validation>
    </div>
    <div class="form-group col eta-form-group">
        <label class="eta-label">ETA</label>
        <ops-verified-timestamp [verified]="etaVerifiedAudit"> </ops-verified-timestamp>
        <ops-date-input
            *ngIf="form.controls.etaRange"
            [mode]="etaRangeMode"
            [enableTime]="true"
            [timeZone]="timeZone"
            [ngrxFormControlState]="form.controls.etaRange"
            [ngrxValueConverter]="dateRangeValueConverter"
            [startDate]="etaDefaultFocusDate"
            [readonly]="isLocationAndEtaReadonly || readonly"
            date-test-eta-range
        >
        </ops-date-input>
        <ops-validation [form]="form.controls.etaRange">
            <ops-warning *ngIf="showEtaWarning">
                <span>Later than Laycan To</span>
                <span class="has-icon icon--flag"></span>
            </ops-warning>
        </ops-validation>
    </div>
    <div>
        <label></label>
        <div>
            <ops-action-button
                type="action"
                icon="tag-tick"
                tooltip="Verify ETA"
                [disabled]="!etaVerifyEnabled"
                (click)="verifyEta.emit()">
            </ops-action-button>
        </div>
    </div>
    <div class="break-lg"></div>
    <div class="form-group col">
        <label>Arrival Date/Time</label>
        <ops-date-input
            [enableTime]="true"
            [timeZone]="timeZone"
            [ngrxFormControlState]="form.controls.arrivalDateTime"
            [ngrxValueConverter]="dateValueConverter"
            [readonly]="readonly"
            [defaultFocusDate]="etaDate"
            data-test-arrival-date
        ></ops-date-input>
    </div>
    <div class="form-group col">
        <label>ETD</label>
        <ops-date-input
            [enableTime]="true"
            [timeZone]="timeZone"
            [ngrxFormControlState]="form.controls.etd"
            [ngrxValueConverter]="dateValueConverter"
            [readonly]="readonly"
            [defaultFocusDate]="etaDate"
            data-test-arrival-date
        ></ops-date-input>
    </div>
    <div class="break-xxl"></div>
    <div class="form-group col">
        <label>Port Costs (&#36;)</label>
        <input
            type="text"
            class="form-control text-right"
            [opsNumber]="{ precision: 2 }"
            [ngrxFormControlState]="form.controls.portCosts"
            [readonly]="readonly"
            data-test-port-costs
        />
    </div>
    <div class="form-group col port-cost-form-group">
        <label>Port Costs Status</label>
        <ops-refdata-dropdown type="PortCostStatus" [ngrxFormControlState]="form.controls.portCostStatus" [readonly]="readonly" data-test-port-cost-status> </ops-refdata-dropdown>
        <ops-validation [form]="form.controls.portCostStatus"></ops-validation>
    </div>
    <div class="form-group col">
        <label>Port Agent</label>
        <input
            type="text"
            class="form-control"
            [ngrxFormControlState]="form.controls.portAgent"
            ngrxUpdateOn="blur"
            [readonly]="readonly"
            [ngbTooltip]="form.value.portAgent"
            data-test-port-agent
        />
    </div>
</div>
<div class="comments-section">
    <div class="comments-toggle d-flex w-100" [class.section-open]="notesExpanded" data-test-toggle-location-notes>
        <button type="button" (click)="toggleNotes()" class="has-icon" [class.icon--arrow-drop-right]="!notesExpanded" [class.icon--arrow-drop-down]="notesExpanded"></button>
        Notes / Instructions
    </div>
    <div *ngIf="notesExpanded" class="form-row m-0 comments-section-form">
        <div class="form-group col-6 d-flex flex-column comments">
            <div class="row no-gutters d-flex comments-labels">
                <label for="comments">Notes</label>
                <div class="d-flex ml-auto comments-important">
                    <label for="areCommentsImportant">Important</label>
                    <input
                        type="checkbox"
                        id="areCommentsImportant"
                        [ngrxFormControlState]="form.controls.commentsImportant"
                        [class.inactive]="readonly"
                        data-test-location-important-notes
                    />
                </div>
            </div>
            <div class="no-gutters comments-controls" [class.important-notice-visible]="form.value.commentsImportant">
                <div class="important-notice d-flex">
                    <div class="comments-important-icon has-icon icon--info-outline"></div>
                    <span>IMPORTANT!</span>
                </div>
                <textarea
                    id="comments"
                    class="form-control"
                    [ngrxFormControlState]="form.controls.comments"
                    ngrxUpdateOn="blur"
                    autocomplete="off"
                    [readonly]="readonly"
                    maxlength="3000"
                    rows="2"
                    data-test-location-notes
                >
                </textarea>
                <ops-validation [form]="form.controls.comments"></ops-validation>
            </div>
        </div>
        <div class="form-group col-6 d-flex flex-column comments">
            <div class="row no-gutters d-flex comments-labels">
                <label for="instructions">Instructions</label>
                <div class="d-flex ml-auto comments-important">
                    <label for="areInstructionsImportant">Important</label>
                    <input
                        type="checkbox"
                        id="areInstructionsImportant"
                        [ngrxFormControlState]="form.controls.instructionsImportant"
                        [disabled]="readonly"
                        data-test-location-important-instructions
                    />
                </div>
            </div>
            <div class="no-gutters comments-controls" [class.important-notice-visible]="form.value.instructionsImportant">
                <div class="important-notice d-flex">
                    <div class="comments-important-icon has-icon icon--info-outline"></div>
                    <span>IMPORTANT!</span>
                </div>
                <textarea
                    id="instructions"
                    class="form-control"
                    [ngrxFormControlState]="form.controls.instructions"
                    ngrxUpdateOn="blur"
                    autocomplete="off"
                    [readonly]="readonly"
                    maxlength="3000"
                    rows="2"
                    data-test-location-instructions
                >
                </textarea>
                <ops-validation [form]="form.controls.instructions"></ops-validation>
            </div>
        </div>
    </div>
</div>
