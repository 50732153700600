<p-table
    [tableStyleClass]="gridClasses"
    [value]="associatedCargoes"
    dataKey="associatedCargoId"
    [formGridReadonly]="readonly"
    [rowTrackBy]="trackBy">
    <ng-template pTemplate="header">
        <tr [class.no-cargoes]="!hasAssociatedCargoes" class="header-row">
            <th data-hide-on-popup scope="col"></th>
            <th>Cargo</th>
            <th data-hide-on-popup class="text-right"
                *opsDivisions="Division.specialisedProducts">
                Estimated Quantity
            </th>
            <th data-hide-on-popup *opsDivisions="Division.specialisedProducts">
                Unit
            </th>
            <th>
                {{ dateColumnName }} Date
            </th>
            <th class="text-right">
                {{ quantityColumnName }} Quantity
            </th>
            <th>Unit</th>
            <th class="text-right">Actual Freight Rate</th>
            <th>Unit</th>
            <th data-hide-on-popup class="text-right" *opsDivisions="pumpingRateDivisions">
                {{ rateColumnName }} Rate (MT/Hr)
            </th>
            <th data-hide-on-popup class="text-right" *opsDivisions="Division.gas">
                Temp &#8451;
            </th>
            <th data-hide-on-popup class="text-right"
                *opsDivisions="Division.specialisedProducts">Specific Gravity
            </th>
            <th data-hide-on-popup class="text-right" *opsDivisions="[Division.dryCargo, Division.specialisedProducts]">
                MT Equivalent
            </th>
            <th data-hide-on-popup></th>
            <th *ngIf="showAddButton"></th>
            <th *opsDivisionsExclude="Division.specialisedProducts"></th>
            <th data-hide-on-popup *ngIf="showMoveBerthButton"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-associatedCargo let-rowIndex="rowIndex">
        <tr class="form-group associated-cargo-row"
            formGridRow
            [attr.rowkey]="associatedCargo.associatedCargoId">
            <td data-hide-on-popup class="ops-fg-action-cell">
                <ops-icon-button
                    [icon]="associatedCargo.expanded ? 'arrow-drop-down' : 'arrow-drop-right'"
                    (click)="toggle(associatedCargo.expanded, associatedCargo.associatedCargoId, $event)"
                    data-test-toggle-additional-rates
                ></ops-icon-button>
            </td>
            <td *opsDivisionsExclude="Division.specialisedProducts"
                pEditableColumn
                [formGridCell]="associatedCargo.form.controls.cargoId">
                <ops-cellEditor>
                    <ng-template pTemplate="input">
                        <ng-select
                            class="form-control ops-select cargo-container"
                            [items]="cargoes"
                            [ngrxFormControlState]="associatedCargo.form.controls.cargoId"
                            [ngrxEnableFocusTracking]="true"
                            [clearable]="false"
                            [ngbPopover]="cargoContent"
                            placeholder="Select"
                            bindLabel="name"
                            bindValue="cargoId"
                            #cargo="ngbPopover"
                            (blur)="cargo.close()"
                            (focus)="cargo.open()"
                            formGridInput
                            triggers="manual"
                            [autoClose]="false"
                            [selectOnTab]="true"
                            data-test-associated-cargo
                        >
                        </ng-select>
                        <ng-template #cargoContent>
                            <div
                                *ngIf="associatedCargo.form.controls.cargoId.errors.required"
                                class="row no-gutters display"
                            >
                                <span class="has-icon icon--warning col-1"></span>
                                <span class="content col-11" data-test-assoc-cargo-required><b>Cargo</b> is required.</span>
                            </div>
                        </ng-template>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span class="ops-fg-cell-value">{{ associatedCargo.cargoName }}</span>
                    </ng-template>
                </ops-cellEditor>
            </td>
            <td class="ops-fg-cell-readonly" *opsDivisions="Division.specialisedProducts">
                <span class="ops-fg-cell-value">{{ associatedCargo.cargoName }}</span>
            </td>
            <td data-hide-on-popup class="ops-fg-cell-readonly text-right" *opsDivisions="Division.specialisedProducts">
                <span class="ops-fg-cell-value">{{ associatedCargo.estimatedQuantity | number: '1.2-2'}}</span>
            </td>
            <td data-hide-on-popup class="ops-fg-cell-readonly" *opsDivisions="Division.specialisedProducts">
                <span class="ops-fg-cell-value">{{ associatedCargo.estimatedQuantityUnit }}</span>
            </td>
            <td pEditableColumn
                [formGridCell]="associatedCargo.form.controls.date"
                warningActive
                [ngrxFormControlId]="associatedCargo.form.controls.date.id"
                ngrxFormControlIdMatch="equals">
                <ops-cellEditor>
                    <ng-template pTemplate="input">
                        <ops-date-input
                            [timeZone]="timeZone"
                            [ngrxFormControlState]="associatedCargo.form.controls.date"
                            [ngrxValueConverter]="dateValueConverter"
                            [defaultFocusDate]="blDefaultFocusDate"
                            formGridInput
                            data-test-eta
                        ></ops-date-input>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span class="ops-fg-cell-value">{{ associatedCargo.form.value.date | maritimeDate:false:timeZone }}</span>
                    </ng-template>
                </ops-cellEditor>
            </td>
            <td pEditableColumn
                [formGridCell]="associatedCargo.form.controls.quantity"
                warningActive
                [ngrxFormControlId]="associatedCargo.form.controls.quantity.id"
                ngrxFormControlIdMatch="equals"
                class="text-right fixture-tab-cell">
                <ops-cellEditor>
                    <ng-template pTemplate="input">
                        <input
                            type="text"
                            [opsNumber]="{ precision: 3, showTrailingZeros: false, minimumFractionDigits: 3 }"
                            class="form-control input-sm"
                            [ngrxFormControlState]="associatedCargo.form.controls.quantity"
                            [readonly]="readonly"
                            [ngbPopover]="mtquantity"
                            #quantityPopover="ngbPopover"
                            (blur)="quantityPopover.close()"
                            (focus)="quantityPopover.open()"
                            formGridInput
                            triggers="manual"
                            [autoClose]="false"
                            placement="top"
                            data-test-associated-cargo-quantity
                        />
                        <ng-template #mtquantity>
                            <ops-field-validation *ngIf="associatedCargo.form.controls.quantity.errors.greaterThanOrEqualTo"
                                [fieldDisplayName]="quantityColumnName + ' Quantity'" errorMessage="must be greater than or equal to 0">
                            </ops-field-validation>
                        </ng-template>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span class="ops-fg-cell-value">{{ associatedCargo.form.value.quantity | opsNumber: false:3:3 }}</span>
                    </ng-template>
                </ops-cellEditor>
            </td>
            <td pEditableColumn
                [formGridCell]="associatedCargo.form.controls.quantityUnit"
                warningActive
                [ngrxFormControlId]="associatedCargo.form.controls.quantityUnit.id"
                data-cy="bl-quantity-unit"
                class="fixture-cell"
                ngrxFormControlIdMatch="equals">
                <ops-cellEditor>
                    <ng-template pTemplate="input">
                        <ops-refdata-dropdown
                            type="QuantityUnit"
                            class="unit-container ops-select"
                            [readonly]="readonly"
                            placeholder="Select"
                            [ngrxFormControlState]="associatedCargo.form.controls.quantityUnit"
                            [ngbPopover]="quantityUnitContent"
                            formGridInput
                            data-test-associated-cargo-unit
                            #qu="ngbPopover"
                            (blur)="qu.close()"
                            (focus)="qu.open()"
                        >
                        </ops-refdata-dropdown>
                        <ng-template #quantityUnitContent>
                            <div
                                *ngIf="associatedCargo.form.controls.quantityUnit.errors.required"
                                class="row no-gutters display"
                            >
                                <span class="has-icon icon--warning col-1"></span>
                                <span class="content col-11" data-test-assoc-quantityUnit-required><b>Unit</b> is required.</span>
                            </div>
                        </ng-template>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span class="ops-fg-cell-value">{{ associatedCargo.form.value.quantityUnit?.value?.name }}</span>
                    </ng-template>
                </ops-cellEditor>
            </td>
            <td pEditableColumn
                [formGridCell]="associatedCargo.form.controls.freightRate"
                [formGridCellReadonly]="isLumpSum"
                warningActive
                [ngrxFormControlId]="associatedCargo.form.controls.freightRate.id"
                ngrxFormControlIdMatch="equals"
                class="text-right"
                data-cy="actual-freight-rate">
                <ops-cellEditor>
                    <ng-template pTemplate="input">
                        <input
                            [opsNumber]="{ precision: 4, minimumFractionDigits: 2, showTrailingZeros: false }"
                            type="text"
                            class="form-control input-sm"
                            [ngrxFormControlState]="associatedCargo.form.controls.freightRate"
                            formGridInput
                            data-test-associated-cargo-freight-rate
                        />
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span class="ops-fg-cell-value">{{ associatedCargo.form.value.freightRate | opsNumber: false:2:4 }}</span>
                    </ng-template>
                </ops-cellEditor>
            </td>
            <td class="ops-fg-cell-readonly">
                <span class="ops-fg-cell-value" data-cy="actual-freight-rate-unit">{{ associatedCargo.freightRateUnit }}</span>
            </td>
            <td data-hide-on-popup *opsDivisions="pumpingRateDivisions" class="ops-fg-cell-readonly text-right">
                <span class="ops-fg-cell-value">{{ associatedCargo.pumpingRate | opsNumber: false:2:2 }}</span>
            </td>
            <td
                data-hide-on-popup
                *ngIf="associatedCargo.form.controls.temperature"
                pEditableColumn
                [formGridCell]="associatedCargo.form.controls.temperature"
                class="text-right">
                <ops-cellEditor>
                    <ng-template pTemplate="input">
                        <input
                            type="text"
                            [opsNumber]="{ showTrailingZeros: false, minimumFractionDigits: 2, precision: 2 }"
                            class="form-control input-sm"
                            [ngrxFormControlState]="associatedCargo.form.controls.temperature"
                            data-test-associated-cargo-temperature />
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span class="ops-fg-cell-value">{{ associatedCargo.form.value.temperature | opsNumber: false:2:2 }}</span>
                    </ng-template>
                </ops-cellEditor>
            </td>
            <td
                data-hide-on-popup
                *ngIf="associatedCargo.form.controls.specificGravity"
                pEditableColumn
                [formGridCell]="associatedCargo.form.controls.specificGravity"
                class="text-right">
                <ops-cellEditor *ngIf="!associatedCargo.form.controls.specificGravity.isDisabled">
                    <ng-template pTemplate="input">
                        <input
                            type="text"
                            [opsNumber]="{ precision: 6, minValue: 0, showTrailingZeros: false }"
                            class="form-control input-sm"
                            [ngrxFormControlState]="associatedCargo.form.controls.specificGravity"
                            [readonly]="readonly"
                            [ngbPopover]="specificGravityContent"
                            #specificGravity="ngbPopover"
                            (blur)="specificGravity.close()"
                            (focus)="specificGravity.open()"
                            formGridInput
                            triggers="manual"
                            [autoClose]="false"
                            placement="top-left"
                            data-test-associated-cargo-specific-gravity
                        />
                        <ng-template #specificGravityContent>
                            <div
                                *ngIf="associatedCargo.form.controls.specificGravity.errors.required"
                                class="row no-gutters display"
                            >
                                <span class="has-icon icon--warning col-1"></span>
                                <span class="content col-11" data-test-assoc-specific-gravity-required><b>Specific Gravity</b> is required.</span>
                            </div>
                            <div
                                *ngIf="
                                        associatedCargo.form.controls.specificGravity.errors.greaterThanOrEqualTo
                                    "
                            >
                                <span class="has-icon icon--warning"></span>
                                <span class="content col-11" data-test-assoc-specific-gravity-negative-value>
                                        <b>Specific Gravity</b> cannot be negative.
                                    </span>
                            </div>
                        </ng-template>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span class="ops-fg-cell-value">{{ associatedCargo.form.value.specificGravity | opsNumber:false:2:6 }}</span>
                    </ng-template>
                </ops-cellEditor>
            </td>
            <td
                data-hide-on-popup
                *opsDivisions="[Division.dryCargo, Division.specialisedProducts]"
                pEditableColumn
                [formGridCell]="associatedCargo.form.controls.mt"
                class="text-right">
                <ops-cellEditor>
                    <ng-template pTemplate="input">
                        <input
                            type="text"
                            [opsNumber]="{ precision: 2, minValue: 0 }"
                            class="form-control input-sm"
                            [ngrxFormControlState]="associatedCargo.form.controls.mt"
                            [ngbPopover]="mtContent"
                            #mt="ngbPopover"
                            (blur)="mt.close()"
                            (focus)="mt.open()"
                            formGridInput
                            triggers="manual"
                            [autoClose]="false"
                            placement="top-left"
                            data-test-associated-cargo-mt
                            [readonly]="readonly"
                        />
                        <ng-template #mtContent>
                            <div
                                *ngIf="associatedCargo.form.controls.mt.errors.required"
                                class="row no-gutters display"
                            >
                                <span class="has-icon icon--warning col-1"></span>
                                <span class="content col-11" data-test-assoc-mt-required><b>MT Equivalent</b> is required.</span>
                            </div>
                            <div
                                *ngIf="associatedCargo.form.controls.mt.errors.lessThan">
                                <span class="has-icon icon--warning"></span>
                                <span class="content col-11" data-test-assoc-mt-negative-value> <b>MT Equivalent</b> cannot be negative. </span>
                            </div>
                        </ng-template>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span class="ops-fg-cell-value">{{ associatedCargo.form.value.mt | opsNumber: false:2:2 }}</span>
                    </ng-template>
                </ops-cellEditor>
            </td>
            <td data-hide-on-popup class="ops-fg-action-cell rates-cell">
                <button
                    (click)="toggle(associatedCargo.expanded, associatedCargo.associatedCargoId, $event)"
                    type="button"
                    data-test-add-associated-cargo-button
                >
                        <span *ngIf="associatedCargo.expanded; else expandCargoRates"
                              ngbTooltip="Hide cargo rates"
                              container="body"
                        >CARGO RATES</span>
                    <ng-template #expandCargoRates>
                            <span
                                [ngbTooltip]="
                                    !readonly && associatedCargo.form.value.associatedCargoExpenses.length === 0 ? 'Add cargo rates' : 'Show cargo rates'
                                "
                            >CARGO RATES</span>
                    </ng-template>
                </button>
            </td>
            <td class="ops-fg-action-cell" *opsDivisionsExclude="[Division.specialisedProducts]">
                <ops-icon-button
                    *ngIf="rowIndex === associatedCargoes.length - 1 && !readonly"
                    type="button"
                    [disabled]="!canAdd"
                    icon="add-circle-outline"
                    (click)="addAssociatedCargo($event)"
                    data-test-add-associated-cargo-button
                ></ops-icon-button>
            </td>
            <ng-container *ngIf="!readonly">
                <td class="ops-fg-action-cell" *opsDivisionsExclude="Division.specialisedProducts">
                    <ops-icon-button
                        type="delete"
                        [disabled]="!canRemove"
                        (click)="remove.emit(rowIndex)"
                        data-test-remove-associated-cargo-button
                    ></ops-icon-button>
                </td>
                <td
                    data-hide-on-popup
                    class="ops-fg-action-cell move-berth-container"
                    ngbTooltip="Move to berth"
                    container="body"
                    *ngIf="showMoveBerthButton">
                    <ops-icon-button
                        class="btn-move-berth"
                        icon="split"
                        [disabled]="readonly"
                        [ngbPopover]="moveBerthMenuContent"
                        #moveBerthMenu="ngbPopover"
                        (click)="moveBerthMenu.open()"
                        triggers="manual"
                        placement="bottom-right"
                        data-test-associated-cargo-move-berth-button
                    ></ops-icon-button>
                    <ng-template #moveBerthMenuContent>
                        <div class="move-berth-menu list-group">
                            <div class="move-berth-menu-title list-group-item">
                                <span>Move to Berth</span>
                            </div>
                            <div
                                class="move-berth-menu-item list-group-item"
                                [ngClass]="{ disabled: berth.berthId === berthId }"
                                (click)="move.emit({ associatedCargoId: associatedCargo.associatedCargoId, berthId: berth.berthId })"
                                *ngFor="let berth of berths; let j = index"
                            >
                                <span class="move-berth-menu-item-number" *ngIf="berths.length === 1">1.</span>
                                <span class="move-berth-menu-item-name"
                                >{{ (berth.summary && berth.summary.berthName) || "Untitled berth" | truncate: 30
                                    }}{{ berth.berthId === berthId ? " (current berth)" : "" }}</span
                                >
                            </div>
                            <div
                                class="move-berth-menu-item new-berth-item list-group-item"
                                (click)="move.emit({ associatedCargoId: associatedCargo.associatedCargoId, berthId: null })"
                            >
                                <span class="has-icon icon--plus"></span>
                                <span class="new-berth-item-text">New berth</span>
                            </div>
                        </div>
                    </ng-template>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="associatedCargo.expanded" class="cargo-rates-row ops-fg-readonly-row">
            <td class="ops-fg-subgrid">
                <ops-associated-cargo-expenses
                    [associatedCargoId]="associatedCargo.associatedCargoId"
                    [expenses]="associatedCargo.expenses"
                    [totalFreightSpend]="associatedCargo.totalFreightSpend"
                    [readonly]="readonly"
                    [canAdd]="canAdd"
                    [canRemove]="canRemove"
                    (add)="addExpense.emit($event)"
                    (remove)="removeExpense.emit($event)"
                >
                </ops-associated-cargo-expenses>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td class="no-cargoes ops-fg-action-cell">
                <div class="d-flex w-100">
                    <div class="d-flex no-cargoes-text align-items-center pl-2">
                        <span class="has-icon icon--lightbulb-outline"></span>
                        <span>No cargoes are currently added</span>
                    </div>
                    <div class="d-flex ml-auto flex-row-reverse add-associated-cargo-button-container">
                        <button
                            type="button"
                            class="btn btn-action align-bottom mr-0"
                            [disabled]="readonly"
                            (click)="add.emit()"
                            warningActive
                            [ngrxFormControlId]="addCargoControlId"
                            ngrxFormControlIdMatch="endsWith"
                            [focusOnActive]="true"
                            data-test-add-associated-cargo-button
                        >
                            <span class="has-icon icon--add-circle-outline"></span>
                            <span>CARGO</span>
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
