import { parseISODate } from "../../../../shared/date-utils/date-utilities";
import { Enumeration, LaytimeEventType } from "../../../../shared/reference-data";
import { CargoId, LaytimeEventId } from "../../../state/model";

export class LaytimeEventFact {
    comments: string;
    percentage?: number;
    cargoId?: CargoId;
    demurrageReason?: Enumeration;

    constructor(public id: LaytimeEventId, public type: LaytimeEventType, public fromDate?: string, public toDate?: string) {}

    static compare(a: LaytimeEventFact, b: LaytimeEventFact): number {
        const fromDateA = parseISODate(a.fromDate);
        const fromDateB = parseISODate(b.fromDate);

        return fromDateA > fromDateB ? 1 : fromDateB > fromDateA ? -1 : 0;
    }
}
