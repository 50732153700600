<div [formGroup]="timeBarsForm" class="card embedded-card-1">
    <div class="card-header row no-gutters align-items-center">
        <span class="title">Demurrage Time Bars</span>
    </div>

    <div class="card-body row no-gutters demurage-time-bars">
        <div *opsDivisions="[Division.tankers]" class="row no-gutters col-lg-12 help-text">
            <span class="has-icon icon--lightbulb-outline p-0"></span>
            <span class="d-flex align-items-center ml-1">Changes made to time bars will alter the actions associated with them.</span>
        </div>
        <div class="row no-gutters col-lg-12 col-md-12 col-sm-12">
            <div class="col-lg-3 col-md-6 col-sm-6 col-6 time-bar">
                <div class="row no-gutters">
                    <label class="time-bar-header">Demurrage Notify</label>
                </div>
                <div class="time-bar-body row no-gutters">
                    <div class="form-group col-5">
                        <label for="demurrageNotifyFromDate">From Date</label>
                        <ops-date-input
                            formControlName="demurrageNotifyFromDate"
                            timeZone="local"
                            [defaultFocusDate]="defaultFocusDate"
                            data-test-demurrageNotifyFromDate
                        ></ops-date-input>
                    </div>
                    <div class="form-group col-2">
                        <label for="demurrageNotifyDaysForExpiring">Days</label>
                        <input
                            formControlName="demurrageNotifyDaysForExpiring"
                            class="form-control text-right"
                            [opsNumber]="{ precision: 0 }"
                            type="text"
                            data-test-demurrageNotifyDaysForExpiring
                        />
                    </div>
                    <div class="form-group col-5">
                        <label for="demurrageNotifyExpiryDate">Expiry Date</label>
                        <ops-date-input [formControl]="demurrageNotifyExpiryDate" timeZone="local" data-test-demurrageNotifyExpiryDate></ops-date-input>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6 time-bar">
                <div class="row no-gutters">
                    <label class="time-bar-header">Demurrage Present</label>
                </div>

                <div class="time-bar-body row no-gutters">
                    <div class="form-group col-5">
                        <label for="expenseNotifyFromDate">From Date</label>
                        <ops-date-input
                            formControlName="demurragePresentFromDate"
                            timeZone="local"
                            [defaultFocusDate]="defaultFocusDate"
                            data-test-demurragePresentFromDate
                        ></ops-date-input>
                    </div>
                    <div class="form-group col-2">
                        <label for="expenseNotifyDaysForExpiring">Days</label>
                        <input
                            formControlName="demurragePresentDaysForExpiring"
                            class="form-control text-right"
                            [opsNumber]="{ precision: 0 }"
                            type="text"
                            data-test-demurragePresentDaysForExpiring
                        />
                    </div>
                    <div class="form-group col-5">
                        <label for="expensePresentExpiryDate">Expiry Date</label>
                        <ops-date-input [formControl]="demurragePresentExpiryDate" timeZone="local" data-test-demurragePresentExpiryDate></ops-date-input>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6 time-bar">
                <div class="row no-gutters">
                    <label class="time-bar-header">Expense Notify</label>
                </div>

                <div class="time-bar-body row no-gutters">
                    <div class="form-group col-5">
                        <label for="expenseNotifyFromDate">From Date</label>
                        <ops-date-input
                            formControlName="expenseNotifyFromDate"
                            timeZone="local"
                            [defaultFocusDate]="defaultFocusDate"
                            data-test-expenseNotifyFromDate
                        ></ops-date-input>
                    </div>
                    <div class="form-group col-2">
                        <label for="expenseNotifyDaysForExpiring">Days</label>
                        <input
                            formControlName="expenseNotifyDaysForExpiring"
                            class="form-control text-right"
                            [opsNumber]="{ precision: 0 }"
                            type="text"
                            data-test-expenseNotifyDaysForExpiring
                        />
                    </div>
                    <div class="form-group col-5">
                        <label for="expenseNotifyExpiryDate">Expiry Date</label>
                        <ops-date-input [formControl]="expenseNotifyExpiryDate" timeZone="local" data-test-expenseNotifyExpiryDate></ops-date-input>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6 time-bar">
                <div class="row no-gutters">
                    <label class="time-bar-header">Expense Present</label>
                </div>

                <div class="time-bar-body row no-gutters">
                    <div class="form-group col-5">
                        <label for="expensePresentFromDate">From Date</label>
                        <ops-date-input
                            formControlName="expensePresentFromDate"
                            timeZone="local"
                            [defaultFocusDate]="defaultFocusDate"
                            data-test-expensePresentFromDate
                        ></ops-date-input>
                    </div>
                    <div class="form-group col-2">
                        <label for="expensePresentDaysForExpiring">Days</label>
                        <input
                            formControlName="expensePresentDaysForExpiring"
                            class="form-control text-right"
                            [opsNumber]="{ precision: 0 }"
                            type="text"
                            data-test-expensePresentDaysForExpiring
                        />
                    </div>
                    <div class="form-group col-5">
                        <label for="expensePresentExpiryDate">Expiry Date</label>
                        <ops-date-input [formControl]="expensePresentExpiryDate" timeZone="local" data-test-expensePresentExpiryDate></ops-date-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
