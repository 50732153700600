import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, props } from "@ngrx/store";
import { InvoiceType } from "@ops/shared/reference-data";
import { tap } from "rxjs/operators";
import { InvoiceDataService } from "../../services/invoice-data.service";
import { TabNavigation } from "../../shared/navigation/tab-navigation.model";
import { TabNavigationService } from "../../shared/navigation/tab-navigation.service";

/* ACTIONS */
const GENERATE_FREIGHT_INVOICE = "[Voyage Form] Generate Freight Invoice";
export const generateFreightInvoiceAction = createAction(GENERATE_FREIGHT_INVOICE, props<{ freightSpendTotal: number }>());

export const generateFreightInvoiceEffect = (actions$: Actions,
                                             invoiceDataService: InvoiceDataService,
                                             tabNavigationService: TabNavigationService) =>
    createEffect(() =>
        actions$.pipe(
            ofType(generateFreightInvoiceAction),
            tap(({ freightSpendTotal }) => {
                invoiceDataService.addInvoice(freightSpendTotal, InvoiceType.FreightInvoice);
                tabNavigationService.setCurrentTab(new TabNavigation("invoicesTab", "grossValue"));
            })
        ),
        { dispatch: false }
);
