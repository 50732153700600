import { Boxed, unbox, ValidationErrors } from "ngrx-forms";

// TODO: Contributed to ngrx-forms open source, remove when merged

// @ts-ignore
declare module "ngrx-forms/src/state" {
    export interface ValidationErrors {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inclusiveBetween?: {
            min: number;
            max: number;
            actual: number;
        };
    }
}

/**
 * A validation function that requires the value to be between or equal to the given min and max values.
 * Considers `null`, `undefined` and non-numeric values as valid. Combine this function with the `required`
 * validation function if `null` or `undefined` should be considered invalid.
 *
 * The validation error returned by this validation function has the following shape:
 *
 ```typescript
 {
  inclusiveBetween: {
    min: number;
    max: number;
    actual: number;
  };
}
 ```
 *
 * Usually you would use this validation function in conjunction with the `validate`
 * update function to perform synchronous validation in your reducer:
 *
 ```typescript
 updateGroup<MyFormValue>({
  amount: validate(inclusiveBetween(0, 100)),
})
 ```
 *
 * Note that this function is generic to allow the compiler to properly infer the type
 * of the `validate` function for both optional and non-optional controls.
 */
export function inclusiveBetween(min: number, max: number) {
    // eslint-disable-next-line
    if (min === null || min === undefined || max === null || max === undefined) {
        throw new Error(`The inclusiveBetween Validation function requires the min and max parameters to be a non-null number, got ${min} and ${max}!`);
    }

    return <T extends number | Boxed<number> | null | undefined>(value: T): ValidationErrors => {
        value = (unbox(value) as number | null | undefined) as T;

        if (value === null || value === undefined || typeof value !== "number") {
            return {};
        }

        if (min <= value && value <= max) {
            return {};
        }

        return {
            inclusiveBetween: {
                min,
                max,
                actual: value as number
            }
        };
    };
}
