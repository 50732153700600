import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { User } from "@ops/state";

import { AppConfigService } from "../../../../core/app-config.service";
import { FixtureSource } from "../../../../fixture/shared/models/enums/fixture-source";
import { Enumeration } from "../../../reference-data";
import { SuggestionService } from "../suggestion.service";

@Injectable({
    providedIn: "root"
})
export class UserSuggestionService implements SuggestionService<User> {
    private readonly userServiceUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.userServiceUrl = appConfigService.config.userServiceUrl;
    }

    getSuggestions(term: string, returnAll?: boolean, fixtureId?: string, fixtureSource?: Enumeration): Observable<User[]> {
        if (!term) {
            return of([]);
        }

        let url: string;

        if (fixtureSource && fixtureSource.id === FixtureSource.Ops) {
            url = this.opsFixtureUrl(term, fixtureId);
        } else {
            url = this.gainFixtureUrl(term, returnAll);
        }

        return this.httpClient.get<User[]>(url);
    }

    private gainFixtureUrl(term: string, returnAll?: boolean): string {
        let url = `${this.userServiceUrl}/api/v1/users/search/${term}`;

        if (returnAll !== null && returnAll) {
            url += "?showOnlyOpsUsers=false";
        }

        return url;
    }

    private opsFixtureUrl(term: string, fixtureId: string): string {
        return `${this.userServiceUrl}/api/v1/users/search-by-sharing-groups/${fixtureId}/${term}`;
    }
}
