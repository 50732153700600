import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroupState } from "ngrx-forms";
import { ConfirmationService } from "primeng/api";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

import { Currency, Sector } from "@ops/shared";

import { DateRange } from "../../../fixture/shared/models";
import {
    ActivityCargo,
    ActivityCargoId,
    ActivityLocation,
    ActivityLocationCargoState,
    ActivityLocationSummary,
    ActivityLocationForm,
    ActivityType,
    addActivityCargoForm,
    LaytimeCalculationDurationUnit,
    LaytimeEventId,
    LaytimeEventState,
    LtcFeatureState,
    orderLaytimeEventForms,
    removeActivityLocationConfirmation,
    clearLaytimeEventsForm,
    removeLaytimeEventForm,
    removeActivityCargoForm,
    selectCurrentActivityLocation,
    selectCurrentActivityLocationCargoes,
    selectCurrentActivityLocationForm,
    selectCurrentActivityLocationHasNonReversibleCargoes,
    selectCurrentActivityLocationHasReversibleCargoes,
    selectCurrentActivityLocationResult,
    selectCurrentActivityLocationType,
    selectCurrentLaytimeCalculationCurrency,
    selectCurrentLaytimeCalculationDurationUnit,
    selectCurrentLaytimeCalculationSector,
    selectCurrentLaytimeCalculationTimeAllowanceIsFixed,
    selectCurrentLaytimeEventCargoes,
    selectCurrentLaytimeEvents,
    selectCurrentLaytimeEventsOrderingEnabled,
    selectCurrentVoyageLoading,
    loadVoyageForImportPortTimes,
    selectCurrentLaytimeEventRange,
    prefillLaytimeRange,
    importActivityCargoTermsButtonClick,
    createLaytimeEventId,
    LaytimeEventType,
    selectCurrentLaytimeCalculationIsAlwaysOnDemurrage,
    selectCurrentActivityTimezone,
    preAddLaytimeEvent
} from "../../state";
import { enterDeductionsAction } from "../../state/calculations/deductions/enter-deductions";

const LAYTIME_SUSPENDED_TYPE: LaytimeEventType = "Laytime Suspended";
const LAYTIME_RESUMED_TYPE: LaytimeEventType = "Laytime Resumed";

@Component({
    selector: "ops-laytime-calculation-activity-location-shell",
    templateUrl: "./laytime-calculation-activity-location-shell.component.html",
    styleUrls: ["./laytime-calculation-activity-location-shell.component.scss"],
    providers: [ConfirmationService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeCalculationActivityLocationShellComponent implements OnDestroy {
    private readonly destroy$ = new Subject();
    readonly activityLocation$ = new BehaviorSubject<ActivityLocation>(null);

    readonly sector$: Observable<Sector>;
    readonly currency$: Observable<Currency>;
    readonly calculationResult$: Observable<ActivityLocationSummary>;
    readonly form$: Observable<FormGroupState<ActivityLocationForm>>;
    readonly activityLocationType$: Observable<ActivityType>;
    readonly activityLocationHasNonReversibleCargoes$: Observable<boolean>;
    readonly activityLocationHasReversibleCargoes$: Observable<boolean>;
    readonly activityLocationCargoForms$: Observable<ReadonlyArray<ActivityLocationCargoState>>;
    readonly laytimeEventCargoes$: Observable<ReadonlyArray<ActivityCargo>>;
    readonly laytimeEvents$: Observable<ReadonlyArray<LaytimeEventState>>;
    readonly timezone$: Observable<string | undefined>;
    readonly firstLaytimeEventIndex$: Observable<number>;
    readonly lastLaytimeEventIndex$: Observable<number>;
    readonly laytimeEventsOrderingEnabled$: Observable<boolean>;
    readonly isFixed$: Observable<boolean>;
    readonly durationUnit$: Observable<LaytimeCalculationDurationUnit>;
    readonly isVoyageLoading$: Observable<boolean>;
    readonly laytimeEventRange$: Observable<DateRange>;
    readonly isAlwaysOnDemurrage$: Observable<boolean>;

    constructor(private store: Store<LtcFeatureState>, private confirmationService: ConfirmationService) {
        this.store.select(selectCurrentActivityLocation).pipe(takeUntil(this.destroy$)).subscribe(this.activityLocation$);

        this.sector$ = this.store.select(selectCurrentLaytimeCalculationSector);
        this.currency$ = this.store.select(selectCurrentLaytimeCalculationCurrency);
        this.calculationResult$ = this.store.select(selectCurrentActivityLocationResult);
        this.form$ = this.store.select(selectCurrentActivityLocationForm);
        this.activityLocationType$ = this.store.select(selectCurrentActivityLocationType);
        this.activityLocationCargoForms$ = this.store.select(selectCurrentActivityLocationCargoes);
        this.laytimeEvents$ = this.store.select(selectCurrentLaytimeEvents);
        this.timezone$ = this.store.select(selectCurrentActivityTimezone);
        this.laytimeEventCargoes$ = this.store.select(selectCurrentLaytimeEventCargoes);
        this.laytimeEventsOrderingEnabled$ = this.store.select(selectCurrentLaytimeEventsOrderingEnabled);
        this.activityLocationHasNonReversibleCargoes$ = this.store.select(selectCurrentActivityLocationHasNonReversibleCargoes);
        this.activityLocationHasReversibleCargoes$ = this.store.select(selectCurrentActivityLocationHasReversibleCargoes);
        this.isFixed$ = this.store.select(selectCurrentLaytimeCalculationTimeAllowanceIsFixed);
        this.durationUnit$ = this.store.select(selectCurrentLaytimeCalculationDurationUnit);
        this.isVoyageLoading$ = this.store.select(selectCurrentVoyageLoading);
        this.laytimeEventRange$ = this.store.select(selectCurrentLaytimeEventRange);
        this.isAlwaysOnDemurrage$ = this.store.select(selectCurrentLaytimeCalculationIsAlwaysOnDemurrage);
    }

    get showCargoes() {
        return this.activityLocation$.value?.activity !== "Transit";
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    addCargo() {
        this.store.dispatch(addActivityCargoForm());
    }

    removeCargo(activityCargoId: ActivityCargoId) {
        this.store.dispatch(removeActivityCargoForm({ activityCargoId }));
    }

    importCargoTerms(activityCargoId: ActivityCargoId) {
        this.store.dispatch(importActivityCargoTermsButtonClick({ activityCargoId }));
    }

    addLaytimeEvent(index: number) {
        this.store.dispatch(preAddLaytimeEvent({ index, setFocus: true }));
    }

    addDeduction({ index, date }: { index: number; date: string }) {
        const deductionEvent = { id: createLaytimeEventId(), date, percentage: "0", type: LAYTIME_SUSPENDED_TYPE };
        const laytimeEvent = { id: createLaytimeEventId(), date, percentage: "100", type: LAYTIME_RESUMED_TYPE };
        this.store.dispatch(preAddLaytimeEvent({ laytimeEvent: deductionEvent, index, setFocus: true }));
        this.store.dispatch(preAddLaytimeEvent({ laytimeEvent, index: ++index, setFocus: false }));
    }

    removeLaytimeEvent(laytimeEventId: LaytimeEventId) {
        this.store.dispatch(removeLaytimeEventForm({ laytimeEventId }));
    }

    clearLaytimeEvents() {
        this.store.dispatch(clearLaytimeEventsForm());
    }

    orderLaytimeEvent() {
        this.laytimeEvents$.pipe(take(1)).subscribe((laytimeEvents) => this.store.dispatch(orderLaytimeEventForms({ laytimeEvents })));
    }

    onDeleteLocationButtonClick() {
        const activityLocation = this.activityLocation$.value;
        this.confirmationService.confirm({
            header: `Deleting ${name}`,
            message: `Are you sure you want to delete ${name}?`,
            acceptLabel: "OK",
            rejectLabel: "CANCEL",
            acceptIcon: "none",
            rejectIcon: "none",
            accept: () => this.store.dispatch(removeActivityLocationConfirmation({ activityLocation }))
        });
    }

    importPortTimes() {
        this.store.dispatch(loadVoyageForImportPortTimes());
    }

    prefillLaytimeRange(laytimeRange: DateRange) {
        this.store.dispatch(prefillLaytimeRange({ laytimeRange }));
    }

    enterDeductions() {
        this.laytimeEventsOrderingEnabled$.pipe(take(1)).subscribe((orderingEnabled) => {
            if (orderingEnabled) {
                this.confirmationService.confirm({
                    header: "Enter deductions",
                    message: "Laytime events should be re-ordered before entering deductions. Would you like to re-order laytime events and proceed?",
                    acceptLabel: "OK",
                    rejectLabel: "CANCEL",
                    acceptIcon: "none",
                    rejectIcon: "none",
                    accept: () => {
                        this.orderLaytimeEvent();
                        this.store.dispatch(enterDeductionsAction());
                    }
                });
            } else {
                this.store.dispatch(enterDeductionsAction());
            }
        });
    }
}
