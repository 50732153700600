import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { ProfitAndLossReport } from "../services/profit-and-loss";
import { ProfitAndLossService } from "../services/profit-and-loss.service";

@Component({
    selector: "ops-profit-and-loss-shell",
    templateUrl: "./profit-and-loss-shell.component.html",
    styleUrls: ["./profit-and-loss-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfitAndLossShellComponent implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();

    report$: Observable<ProfitAndLossReport>;

    @Output() readonly goToCargoTabClick = new EventEmitter();

    constructor(private profitAndLossService: ProfitAndLossService) { }

    goToCargoTab() {
        this.goToCargoTabClick.emit();
    }

    ngOnInit() {
        this.report$ = this.profitAndLossService.getReport("charterer").pipe(
            takeUntil(this.destroy$)
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
