import { Injectable } from "@angular/core";

import { CommandHandler } from "../../../../mediator";
import { Fixture } from "../../../../shared/models";
import { BunkerConsumptionFormModel } from "../../../../shared/models/form-models/bunker-consumption.model";
import { UpdateBunkerConsumptionCommand } from "./update-bunker-consumption.command";

@Injectable({
    providedIn: "root"
})
export class UpdateBunkerConsumptionCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: UpdateBunkerConsumptionCommand) {
        const bunkerConsumptionFormModel = command.payload as BunkerConsumptionFormModel;
        if (!bunkerConsumptionFormModel) {
            throw new Error("Given bunker consumption form model is invalid.");
        }

        const bunkerConsumptionToUpdate = fixture.cpSpeedAndConsumption.cpBunkersConsumption.find((e) => e.bunkerConsumptionId === bunkerConsumptionFormModel.bunkerConsumptionId);
        if (!bunkerConsumptionToUpdate) {
            throw new Error("Cannot find bunker consumption with the given ID.");
        }

        bunkerConsumptionToUpdate.type = bunkerConsumptionFormModel.bunkerType;
        bunkerConsumptionToUpdate.atSea = bunkerConsumptionFormModel.atSea;
        bunkerConsumptionToUpdate.laden = bunkerConsumptionFormModel.laden;
        bunkerConsumptionToUpdate.portIdle = bunkerConsumptionFormModel.portIdle;
        bunkerConsumptionToUpdate.portWorking = bunkerConsumptionFormModel.portWorking;
        bunkerConsumptionToUpdate.ballast = bunkerConsumptionFormModel.ballast;
    }
}
