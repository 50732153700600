export const laytimeEventRemarks = [
    "Await Daylight",
    "Await Discharge Nomination",
    "Await Lightering Vessel",
    "Await Load Nomination",
    "Await Surveyor",
    "Await UN Permission To Enter Port",
    "Awaiting Charterers Orders",
    "Awaiting Tide",
    "B/L Issues",
    "Berth Congestion",
    "Blowing Lines",
    "Commence Squeeging/sweeping",
    "Complete Squeeging/sweeping",
    "Configuration of Berth/Availability of Lines",
    "Contamination/Off Spec Terminal Related",
    "Crane",
    "Departed Locks",
    "Draft Issues Due Tide/Wind",
    "End of Day",
    "Entered Locks",
    "Financial Hold/LoC Issues",
    "Fire/explosion",
    "Floating Storage",
    "Ice Delay",
    "Jetty Maintenance",
    "Loading One Grade At A Time",
    "LOI Issues",
    "Machinery Breakdown Ship Side",
    "Machinery Breakdown Shore Side",
    "Multiple Berths Utilized",
    "No Night Time Navigation",
    "Operations Exceeding Laytime Allowance",
    "Owners – Cargo Wrong Temperature/Off Spec",
    "P&I Issues",
    "Port Closure Due Bad Weather",
    "Pumping Issue",
    "Purging",
    "Rain Ceased",
    "Rain Commenced",
    "Re-Sampling",
    "Sampling",
    "Ship’s Problem",
    "Shore Stoppage",
    "Shore Tank Changes",
    "Shore Tank Maintenance",
    "Slow Discharge Terminal Related",
    "Slow Loading Terminal Related",
    "Storm Barrier Maintenance",
    "Supply Issue",
    "Surveyor Issues: Late Arrival/Incorrect Measurement/Quality Inspection Delays",
    "Tank Tops",
    "Technical Issues (Technical Breakdown Clause)",
    "Terminal Analysis Delays",
    "Terminal Operational Delays",
    "Time Not Counted",
    "Time To Count",
    "Unplanned Shutdown/Force Majeure",
    "Vessel Movement Suspended Due Bad Weather",
    "Vessel Safety Inspection (ISPS)",
    "Waiting Custom Docs And/or Clearance",
    "Waiting For B/B Barge",
    "Waiting For B/B Coaster",
    "Waiting For Cargo To Be Ready",
    "Waiting For Docs",
    "Waiting For Exporter",
    "Waiting For Importer",
    "Waiting For Load Master",
    "Waiting For Prewash Facilities",
    "Waiting For Railway Bridge To Open",
    "Waiting For Shore Labour",
    "Waiting For Tide",
    "Waiting For Trucks/Railcars",
    "Waiting For Voyage Orders",
    "Waiting Immigration/customs Clearance",
    "Waiting Locks",
    "Waiting Pilot",
    "Waiting Product Availability",
    "Waiting Start Laycan",
    "Waiting Ullage",
    "Weather Delay",
    "Weekend/Holiday",
    "WWT Failed"
] as const;
export type LaytimeEventRemark = typeof laytimeEventRemarks[number];
