import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import {
    addActivityLocationsFilter,
    addActivityLocationsFilterSelectAll,
    AddActivityLocationsFilterState,
    LtcFeatureState,
    selectCurrentAddActivityLocationsFilterState
} from "../../../state";

@Component({
    selector: "ops-add-activity-locations-filter-shell",
    templateUrl: "./add-activity-locations-filter-shell.component.html",
    styleUrls: ["./add-activity-locations-filter-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddActivityLocationsFilterShellComponent {
    readonly addActivityLocationsFilter = addActivityLocationsFilter;
    readonly addActivityLocationsFilter$: Observable<AddActivityLocationsFilterState>;

    constructor(private store: Store<LtcFeatureState>) {
        this.addActivityLocationsFilter$ = this.store.select(selectCurrentAddActivityLocationsFilterState);
    }

    selectAll() {
        this.store.dispatch(addActivityLocationsFilterSelectAll());
    }
}
