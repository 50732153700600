import { Injectable } from "@angular/core";

import { isNullOrUndefined } from "@ops/shared";

import { CargoBerthActivityType } from "../../../../../shared/reference-data/cargo-berth-activity-type";
import { AssociatedCargo, AssociatedCargoExpenseRateDescription, Fixture, FixtureType, Voyage } from "../../../models";
import { dismissedWarningRecord, FixtureWarning, FixtureWarningPathSegment } from "../../fixture-warning.model";
import { WarningHashBuilder } from "../../warning-hash-builder";
import { AbstractFixtureWarningRule, FixtureWarningRule } from "../fixture-warning-rule";

@Injectable()
export class FreightMissingBlRule extends AbstractFixtureWarningRule implements FixtureWarningRule {
    evaluate(fixture: Fixture, voyages: Voyage[]): FixtureWarning[] {
        const warnings = new Array<FixtureWarning>();

        voyages.forEach((voyage) =>
            voyage.destinations.forEach((destination, destinationIndex) =>
                destination.berths.forEach((berth, berthIndex) =>
                    berth.cargoBerthActivities.forEach((activity, activityIndex) => {
                        if (!activity.type || activity.type.id !== CargoBerthActivityType.Load.id) {
                            return;
                        }

                        activity.associatedCargoes.forEach((associatedCargo, associatedCargoIndex) => {
                            const associatedCargoPath = () =>
                                this.getAssociatedCargoPath(
                                    fixture,
                                    voyage,
                                    destinationIndex,
                                    destination,
                                    berthIndex,
                                    berth,
                                    activityIndex,
                                    activity,
                                    associatedCargoIndex,
                                    associatedCargo
                                );

                            if (
                                !isNullOrUndefined(associatedCargo.associatedCargoExpenses) &&
                                associatedCargo.associatedCargoExpenses.some((e) => e.rateDescription && e.rateDescription.id === AssociatedCargoExpenseRateDescription.DeadFreight)
                            ) {
                                return;
                            }

                            const createDismissingRecord = (message: string) =>
                                dismissedWarningRecord(
                                    new WarningHashBuilder()
                                        .withVoyage(voyage.voyageId)
                                        .withDestination(destination.destinationId)
                                        .withBerth(berth.berthId)
                                        .withBerthActivity(activity.berthActivityId)
                                        .withAssociatedCargo(associatedCargo.associatedCargoId)
                                        .withCategory("freight")
                                        .withMessage(message)
                                        .build()
                                );

                            if (associatedCargo.quantity && !associatedCargo.date) {
                                const message = "Missing B/L Date";
                                const path = [...associatedCargoPath(), FixtureWarningPathSegment.fromProperty<AssociatedCargo>("date", "B/L Date")];
                                warnings.push(new FixtureWarning("freight", message, path, createDismissingRecord(message)));
                            } else if (!associatedCargo.quantity && associatedCargo.date) {
                                const message = "Missing B/L";
                                const path = [...associatedCargoPath(), FixtureWarningPathSegment.fromProperty<AssociatedCargo>("quantity", "B/L")];
                                const dismissingRecord = fixture.fixtureType.id !== FixtureType.Voyage ? createDismissingRecord(message) : null;
                                warnings.push(new FixtureWarning("freight", message, path, dismissingRecord));
                            }
                        });
                    })
                )
            )
        );

        return warnings;
    }
}
