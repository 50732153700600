import { createAction, on, On, props } from "@ngrx/store";
import {
    destinationExpandedKey,
    DestinationId,
    destinationNotesExpandedKey,
    DESTINATIONS_EXPANDED_KEY,
    FixturesState
} from "../../model";
import { currentVoyageStateExpansionReducer } from "../../voyage/reducer";

/* ACTIONS */
export const expandDestinationsAction = createAction("[Voyage Form] Expand Destinations");
export const collapseDestinationsAction = createAction("[Voyage Form] Collapse Destinations");

export const expandDestinationAction = createAction("[Voyage Form] Expand Destination", props<{ destinationId: DestinationId }>());
export const collapseDestinationAction = createAction("[Voyage Form] Collapse Destination", props<{ destinationId: DestinationId }>());

export const expandDestinationNotesAction = createAction("[Voyage Form] Expand Destination Notes", props<{ destinationId: DestinationId }>());
export const collapseDestinationNotesAction = createAction("[Voyage Form] Collapse Destination Notes", props<{ destinationId: DestinationId }>());

/* REDUCERS */
export const expandDestinationsReducer: On<FixturesState> = on(expandDestinationsAction, (state) =>
    currentVoyageStateExpansionReducer(state, DESTINATIONS_EXPANDED_KEY, true)
);
export const collapseDestinationsReducer: On<FixturesState> = on(collapseDestinationsAction, (state) =>
    currentVoyageStateExpansionReducer(state, DESTINATIONS_EXPANDED_KEY, false)
);

export const expandDestinationReducer: On<FixturesState> = on(expandDestinationAction, (state, action) =>
    currentVoyageStateExpansionReducer(state, destinationExpandedKey(action.destinationId), true)
);
export const collapseDestinationReducer: On<FixturesState> = on(collapseDestinationAction, (state, action) =>
    currentVoyageStateExpansionReducer(state, destinationExpandedKey(action.destinationId), false)
);

export const expandDestinationNotesReducer: On<FixturesState> = on(expandDestinationNotesAction, (state, action) =>
    currentVoyageStateExpansionReducer(state, destinationNotesExpandedKey(action.destinationId), true)
);
export const collapseDestinationNotesReducer: On<FixturesState> = on(collapseDestinationNotesAction, (state, action) =>
    currentVoyageStateExpansionReducer(state, destinationNotesExpandedKey(action.destinationId), false)
);
