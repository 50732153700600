import { Pipe, PipeTransform } from "@angular/core";
import { formatBytes } from "../../utils";

@Pipe({
    name: "fileSize"
})
export class FileSizePipe implements PipeTransform {
    transform(value: number): string {
        return formatBytes(value);
    }
}
