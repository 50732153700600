import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Favorite } from "./models";
import { FavoritePost } from "./models/favorite-post.model";
import { AppConfigService } from "../../core";

@Injectable({
    providedIn: "root"
})
export class FavoriteHttpService {
    private apiGatewayUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.apiGatewayUrl = appConfigService.config.apiGatewayUrl;
    }

    getAll(): Observable<Favorite[]> {
        return this.httpClient.get<Favorite[]>(`${this.apiGatewayUrl}/api/v2.0/favourite?division=None&division=DryCargo&division=Gas&division=SpecialisedProducts&division=PCG`);
    }

    create(favorite: FavoritePost): Observable<string> {
        return this.httpClient.post(`${this.apiGatewayUrl}/api/v2.0/favourite`, favorite, { responseType: "text" });
    }

    update(fixtureId: string, color: string): Observable<any> {
        return this.httpClient.patch(`${this.apiGatewayUrl}/api/v2.0/favourite/${fixtureId}?color=${color}`, null);
    }

    delete(fixtureId: string): Observable<any> {
        return this.httpClient.delete(`${this.apiGatewayUrl}/api/v2.0/favourite/${fixtureId}`);
    }

    deleteMultiple(fixtureIds: string[]): Observable<any> {
        return this.httpClient.post(`${this.apiGatewayUrl}/api/v2.0/favourite/deleteMultiple`, fixtureIds);
    }
}
