import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import * as moment from "moment-timezone";
import { CommandHandler } from "src/app/fixture/mediator/command-handlers/command-handler";
import { Fixture, Period } from "src/app/fixture/shared/models";
import { Option } from "src/app/fixture/shared/models/dtos/option.dto";
import { OptionFormModel } from "src/app/fixture/shared/models/form-models/option.model";
import { dateToISOString, DateUtilities } from "src/app/shared/date-utils/date-utilities";
import { Enumeration } from "src/app/shared/reference-data/enumeration";
import { UpdateOptionCommand } from "./update-option.command";

@Injectable({
    providedIn: "root"
})
export class UpdateOptionCommandHandler implements CommandHandler {
    static executedExercisedOption = new Enumeration(1, "Exercised");

    handle = (fixture: Fixture, command: UpdateOptionCommand) => {
        const optionsModel = <OptionFormModel[]>command.payload;

        if (!optionsModel) {
            throw new Error("A given options model is invalid.");
        }

        optionsModel.forEach((updatedOption) => {
            const matchingOption = fixture.options[this.toIndex(updatedOption.id)];
            if (matchingOption) {
                const existingPeriodFrom = matchingOption.periodRange ? DateUtilities.parseISODate(matchingOption.periodRange.from) : null;
                const existingPeriodTo = matchingOption.periodRange ? DateUtilities.parseISODate(matchingOption.periodRange.to) : null;

                if (DateUtilities.notEquals(existingPeriodFrom, updatedOption.periodFrom)) {
                    this.handlePeriodFromChange(updatedOption, existingPeriodTo, updatedOption.periodFrom);
                } else if (DateUtilities.notEquals(existingPeriodTo, updatedOption.periodTo)) {
                    this.handlePeriodToChange(updatedOption, existingPeriodFrom, updatedOption.periodTo);
                } else if (matchingOption.durationInDays !== updatedOption.durationInDays) {
                    this.handleDurationChange(updatedOption, existingPeriodFrom, existingPeriodTo, updatedOption.durationInDays);
                }

                this.handleExerciseOptionChange(updatedOption, matchingOption.exerciseOption, matchingOption.exercised, fixture);
            }
        });

        fixture.options = optionsModel.map(
            (o) =>
                <Option>{
                    periodRange: { from: dateToISOString(o.periodFrom), to: dateToISOString(o.periodTo) },
                    hireRate: o.hireRate,
                    durationInDays: o.durationInDays,
                    hireRateUnit: o.hireRateUnit,
                    declarationDueDate: dateToISOString(o.declarationDueDate),
                    exerciseOption: o.exerciseOption,
                    exercised: o.exercised
                }
        );
    };

    private toIndex(id: string) {
        return +id - 1;
    }

    private handlePeriodFromChange(updatedModel: OptionFormModel, existingPeriodTo: Date, newPeriodFrom: Date) {
        if (newPeriodFrom && existingPeriodTo) {
            updatedModel.durationInDays = DateUtilities.calculateDurationInDays(existingPeriodTo, newPeriodFrom);
        }
    }

    private handlePeriodToChange(updatedModel: OptionFormModel, existingPeriodFrom: Date, newPeriodTo: Date) {
        if (newPeriodTo && existingPeriodFrom) {
            updatedModel.durationInDays = DateUtilities.calculateDurationInDays(newPeriodTo, existingPeriodFrom);
        }
    }

    private handleDurationChange(updatedModel: OptionFormModel, existingPeriodFrom: Date, existingPeriodTo: Date, updatedDuration: number) {
        if (updatedDuration !== null && updatedDuration !== undefined) {
            const secondsInADay = 86400;
            const durationInSeconds = updatedDuration * secondsInADay;

            if (existingPeriodFrom) {
                updatedModel.periodTo = moment(existingPeriodFrom)
                    .add(durationInSeconds, "seconds")
                    .toDate();
            } else if (existingPeriodTo) {
                updatedModel.periodFrom = moment(existingPeriodTo)
                    .subtract(durationInSeconds, "seconds")
                    .toDate();
            }
        }
    }

    private handleExerciseOptionChange(updatedModel: OptionFormModel, existingExerciseOption: Enumeration, wasExercised: boolean, fixture: Fixture) {
        const exercisingNow =
            updatedModel.exerciseOption.id === UpdateOptionCommandHandler.executedExercisedOption.id &&
            updatedModel.exerciseOption.id !== existingExerciseOption.id;

        if (exercisingNow && !wasExercised) {
            updatedModel.exercised = true;
            this.addPeriod(fixture, updatedModel);
        }
    }

    private addPeriod(fixture: Fixture, updatedModel: OptionFormModel) {
        fixture.periods = fixture.periods || [];

        const period: Period = {
            hireId: Guid.create().toString(),
            hireRateUnit: updatedModel.hireRateUnit,
            hireRate: updatedModel.hireRate,
            durationInDays: updatedModel.durationInDays,
            minDuration: null,
            maxDuration: null,
            durationType: null,
            toleranceInDays: null,
            periodRange: { from: dateToISOString(updatedModel.periodFrom), to: dateToISOString(updatedModel.periodTo) },
            invoicesPaidUpTo: null,
            offHires: []
        };

        fixture.periods.push(period);
    }
}
