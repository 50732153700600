import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ReferenceDataService } from "@ops/shared/reference-data";

import { Command } from "../../mediator";
import { FormComponentBase } from "../../shared/form-component-base";
import { CpSpeedAndConsumption } from "../../shared/models";
import { CpSpeedAndConsumptionModel } from "../../shared/models/form-models/cp-speed-and-consumption.model";
import { UpdateCpSpeedAndConsumptionCommand } from "./commands/update-cp-speed-and-consumption.command";

@Component({
    selector: "ops-cp-speed-and-consumption",
    templateUrl: "./cp-speed-and-consumption.component.html",
    styleUrls: ["./cp-speed-and-consumption.component.scss"]
})
export class CpSpeedAndConsumptionComponent extends FormComponentBase implements OnInit, OnDestroy {
    static componentName = "CpSpeedAndConsumptionComponent";

    cpSpeedAndConsumptionForm: UntypedFormGroup;
    beaufortForceAndCommentsForm: UntypedFormGroup;
    beaufortForces$: Observable<string[]>;

    @Input() parentForm: UntypedFormGroup;
    @Input() cpSpeedAndConsumption: CpSpeedAndConsumption;
    @Output() cpSpeedAndConsumptionUpdated = new EventEmitter();

    constructor(public referenceDataService: ReferenceDataService) {
        super();
    }

    ngOnInit() {
        this.createForm();
        this.beaufortForces$ = this.referenceDataService.getBeaufortScales().pipe(map((all) => all.map((e) => e.id.toString())));

        this.parentForm.registerControl("cpSpeedAndConsumption", this.cpSpeedAndConsumptionForm);
        this.cpSpeedAndConsumptionForm[this.parentForm.disabled ? "disable" : "enable"]();

        this.setForm();
    }

    ngOnDestroy() {
        this.removeFormSubscriptions();
        delete this.parentForm.controls.cpSpeedAndConsumption;
    }

    get invalid() {
        return this.cpSpeedAndConsumptionForm.invalid;
    }

    get touched() {
        return Object.keys(this.cpSpeedAndConsumptionForm.controls).some(
            (key) => this.cpSpeedAndConsumptionForm.controls[key].invalid && this.cpSpeedAndConsumptionForm.controls[key].touched
        );
    }

    updateCpSpeedAndConsumption(event: Command) {
        this.cpSpeedAndConsumptionUpdated.emit(event);
    }

    private createForm() {
        this.beaufortForceAndCommentsForm = new UntypedFormGroup(
            {
                maxBeaufortForce: new UntypedFormControl(null),
                comments: new UntypedFormControl(null)
            },
            { updateOn: "blur" }
        );

        this.cpSpeedAndConsumptionForm = new UntypedFormGroup({
            beaufortForceAndComments: this.beaufortForceAndCommentsForm
        });
    }

    private setForm() {
        const currentMaxForce = this.cpSpeedAndConsumption.maxBeaufortForce;
        const dataModel: CpSpeedAndConsumptionModel = {
            maxBeaufortForce: currentMaxForce && currentMaxForce.toString(),
            comments: this.cpSpeedAndConsumption.comments
        };

        this.removeFormSubscriptions();
        this.beaufortForceAndCommentsForm.patchValue(dataModel, { emitEvent: false });
        this.subscribeToFormValueChanges(this.beaufortForceAndCommentsForm, (data) => this.updateCpSpeedAndConsumption(new UpdateCpSpeedAndConsumptionCommand(data)));
    }
}
