import { Enumeration } from "./enumeration";

export class LaytimeEventType extends Enumeration {
    static readonly ETA = new LaytimeEventType(5, "ETA", 1);
    static readonly Arrival = new LaytimeEventType(29, "Arrival", 2);
    static readonly NORTendered = new LaytimeEventType(4, "NOR Tendered", 3);
    static readonly NORAccepted = new LaytimeEventType(44, "NOR Accepted", 4);
    static readonly ETB = new LaytimeEventType(46, "ETB", 5);
    static readonly Berthed = new LaytimeEventType(2, "Berthed", 6);
    static readonly LaytimeCommenced = new LaytimeEventType(49, "Laytime Commenced", 7);
    static readonly HosesConnected = new LaytimeEventType(12, "Hoses Connected", 8);
    static readonly CargoCommenced = new LaytimeEventType(13, "Cargo Commenced", 9);
    static readonly ETC = new LaytimeEventType(45, "ETC", 10);
    static readonly CargoCompleted = new LaytimeEventType(14, "Cargo Completed", 11);
    static readonly HosesDisconnected = new LaytimeEventType(15, "Hoses Disconnected", 12);
    static readonly ETS = new LaytimeEventType(47, "ETS", 13);
    static readonly Sailed = new LaytimeEventType(3, "Sailed", 14);
    static readonly Anchored = new LaytimeEventType(18, "Anchored", 15);
    static readonly AnchorAweigh = new LaytimeEventType(27, "Anchor Aweigh", 16);
    static readonly AwaitingDaylight = new LaytimeEventType(48, "Awaiting Daylight", 17);
    static readonly StartShifting = new LaytimeEventType(9, "Start Shifting", 18);
    static readonly StopShifting = new LaytimeEventType(10, "Stop Shifting", 19);
    static readonly NORPlus6Hrs = new LaytimeEventType(6, "NOR + 6 Hours", 20);
    static readonly AllFast = new LaytimeEventType(7, "All Fast", 21);
    static readonly GangwayDown = new LaytimeEventType(28, "Gangway Down", 22);
    static readonly OperationsSuspended = new LaytimeEventType(38, "Operations Suspended", 23);
    static readonly OperationsResumed = new LaytimeEventType(39, "Operations Resumed", 24);
    static readonly DocsOnBoard = new LaytimeEventType(16, "Documents On Board", 25);
    static readonly CommencedFirstFootLoading = new LaytimeEventType(30, "Commenced 1st Foot Loading", 26);
    static readonly FirstFootLoadingCompleted = new LaytimeEventType(31, "1st Foot Loading Completed", 27);
    static readonly SurveyorOnboard = new LaytimeEventType(8, "Surveyor Onboard", 28);
    static readonly FirstFootAnalysisPassed = new LaytimeEventType(32, "1st foot Analysis Passed", 29);
    static readonly AnalysisApproved = new LaytimeEventType(11, "Analysis Approved", 30);
    static readonly OutturnQtys = new LaytimeEventType(23, "Outturn Qtys", 31);
    static readonly AllClear = new LaytimeEventType(37, "All Clear", 32);
    static readonly CommencedN2Purging = new LaytimeEventType(40, "Commenced N2 Purging", 33);
    static readonly CompletedN2Purging = new LaytimeEventType(41, "Completed N2 Purging", 34);
    static readonly CommencedGassingUp = new LaytimeEventType(42, "Commenced Gassing Up", 35);
    static readonly CompletedGassingUp = new LaytimeEventType(43, "Completed Gassing Up", 36);
    static readonly N2BlanketStart = new LaytimeEventType(35, "N2 Blanket Start", 37);
    static readonly N2BlanketEnd = new LaytimeEventType(36, "N2 Blanket End", 38);
    static readonly POB = new LaytimeEventType(25, "POB", 39);
    static readonly POBShiftingTime = new LaytimeEventType(26, "POB Shifting Time", 40);
    static readonly PumpingTime = new LaytimeEventType(22, "Pumping Time", 41);
    static readonly TanksPassed = new LaytimeEventType(24, "Tanks Passed", 42);
    static readonly NoticeTimeExp = new LaytimeEventType(17, "Notice Time Expired", 43);
    static readonly LaytimeStopped = new LaytimeEventType(33, "Laytime Stopped", 44);
    static readonly LaytimeResumed = new LaytimeEventType(34, "Laytime Resumed", 45);
    static readonly Shifting = new LaytimeEventType(20, "Shifting", 46);
    static readonly EOSP = new LaytimeEventType(19, "EOSP", 47);
    static readonly Surveyor = new LaytimeEventType(21, "Surveyor", 48);
    static readonly SamplesTaken = new LaytimeEventType(50, "Samples Taken", 49);
    static readonly SamplesAnalysed = new LaytimeEventType(51, "Samples Analysed", 50);
    static readonly DischargeSuspended = new LaytimeEventType(52, "Discharge Suspended", 51);
    static readonly DischargeCommenced = new LaytimeEventType(53, "Discharge Commenced", 52);
    static readonly LoadingSuspended = new LaytimeEventType(54, "Loading Suspended", 53);
    static readonly LoadingCommenced = new LaytimeEventType(55, "Loading Commenced", 54);
    static readonly Other = new LaytimeEventType(1, "Other", 55);
}
