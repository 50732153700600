import { DateTime } from "luxon";

import { LtcId, LaytimeCalculationIndexState } from "../../model";

export type FixtureLaytimeCalculation = Readonly<{
    id: LtcId;
    name: string;
    lastUpdatedAt: DateTime;
    lastUpdatedBy: string;
    isPersisting: boolean;
}>;

export const getFixtureLaytimeCalculation = (calculation: LaytimeCalculationIndexState): FixtureLaytimeCalculation => ({
    id: calculation.id,
    name: calculation.name ?? "Untitled Calculation",
    lastUpdatedAt: DateTime.fromISO(calculation.lastUpdatedAt),
    lastUpdatedBy: calculation.lastUpdatedBy.name,
    isPersisting: calculation.persistenceStatus === "persisting"
});
