import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { TabNavigation } from "./tab-navigation.model";

@Injectable({ providedIn: "root" })
export class TabNavigationService {
    private readonly _currentTab$ = new BehaviorSubject<TabNavigation>(null);

    get currentTab$(): Observable<TabNavigation> {
        return this._currentTab$.asObservable();
    }

    setCurrentTab(tabNavigation: TabNavigation) {
        this._currentTab$.next(tabNavigation);
    }
}
